import * as React from "react";
import { memo } from "react";
const SvgIconArrowTrangle = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 7 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.02174 3.5L0.0869609 6.9641L0.0869612 0.0358982L6.02174 3.5Z"
      fill="#D4D6DB"
    />
  </svg>
);
const Memo = memo(SvgIconArrowTrangle);
export default Memo;
