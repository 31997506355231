import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.7208 13.409C2.55256 13.6782 2.63668 13.9138 2.70397 14.0315C2.75445 14.1325 2.92269 14.3344 3.24236 14.3344H13.7408C14.0604 14.3344 14.2119 14.1493 14.2792 14.0315C14.3296 13.9306 14.4306 13.6951 14.2623 13.409L11.7891 9.45531H5.19399L2.7208 13.409Z"
      fill="currentColor"
    />
    <path
      d="M15.6419 12.551L11.2508 5.48475C11.0993 5.23239 11.0152 4.9632 11.0152 4.66036V0.706625H11.8396C12.0247 0.706625 12.1929 0.538381 12.1929 0.353312C12.1929 0.168244 12.0247 0 11.8396 0H10.6787C10.4768 0 10.3254 0.15142 10.3254 0.353312V4.66036C10.3254 5.08097 10.4432 5.48475 10.6619 5.83807L15.0531 12.9043C15.3559 13.3922 15.3727 13.9811 15.0867 14.469C14.8175 14.9737 14.3128 15.2597 13.7408 15.2597H3.24236C2.67033 15.2597 2.16559 14.9569 1.8964 14.469C1.62721 13.9642 1.62721 13.3922 1.93005 12.9043L6.32122 5.83807C6.53994 5.48475 6.65771 5.06414 6.65771 4.66036V0.353312C6.65771 0.15142 6.50629 0 6.3044 0H5.14351C4.95844 0 4.7902 0.15142 4.7902 0.353312C4.7902 0.555205 4.94162 0.706625 5.14351 0.706625H5.96791V4.67718C5.96791 4.9632 5.88379 5.24921 5.73237 5.50158L1.3412 12.5678C0.903764 13.2744 0.88694 14.1157 1.29073 14.8391C1.69451 15.5626 2.43478 16 3.25918 16H13.7576C14.582 16 15.3223 15.5626 15.7261 14.8391C16.0962 14.0988 16.0794 13.2576 15.6419 12.551Z"
      fill="currentColor"
    />
    <path
      d="M7.36433 8.49632C7.75459 8.49632 8.07096 8.17995 8.07096 7.7897C8.07096 7.39944 7.75459 7.08307 7.36433 7.08307C6.97407 7.08307 6.65771 7.39944 6.65771 7.7897C6.65771 8.17995 6.97407 8.49632 7.36433 8.49632Z"
      fill="currentColor"
    />
    <path
      d="M9.6188 8.49632C10.0091 8.49632 10.3254 8.17995 10.3254 7.7897C10.3254 7.39944 10.0091 7.08307 9.6188 7.08307C9.22854 7.08307 8.91218 7.39944 8.91218 7.7897C8.91218 8.17995 9.22854 8.49632 9.6188 8.49632Z"
      fill="currentColor"
    />
    <path
      d="M8.49157 6.56151C8.88182 6.56151 9.19819 6.24515 9.19819 5.85489C9.19819 5.46463 8.88182 5.14826 8.49157 5.14826C8.10131 5.14826 7.78494 5.46463 7.78494 5.85489C7.78494 6.24515 8.10131 6.56151 8.49157 6.56151Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
