import * as React from "react";
import { memo } from "react";
const SvgData = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.866 6.55273C8.52201 6.55273 9.1633 6.7418 9.70876 7.09601C10.2542 7.45023 10.6793 7.95369 10.9304 8.54273C11.1814 9.13178 11.2471 9.77994 11.1191 10.4053C10.9912 11.0306 10.6753 11.605 10.2114 12.0558C9.74751 12.5067 9.1565 12.8137 8.51309 12.9381C7.86968 13.0624 7.20276 12.9986 6.59668 12.7546C5.9906 12.5106 5.47258 12.0974 5.10811 11.5673C4.74365 11.0372 4.54912 10.4139 4.54912 9.77636C4.54912 8.9214 4.89857 8.10146 5.52061 7.49691C6.14265 6.89236 6.98631 6.55273 7.866 6.55273ZM7.80457 3.32685C7.80457 3.25215 7.81971 3.17818 7.84912 3.10917C7.87854 3.04016 7.92165 2.97746 7.97599 2.92464C8.03034 2.87182 8.09486 2.82992 8.16587 2.80133C8.23687 2.77275 8.31298 2.75804 8.38984 2.75804H15.4147C15.4916 2.75804 15.5677 2.77275 15.6387 2.80133C15.7097 2.82992 15.7742 2.87182 15.8286 2.92464C15.8829 2.97746 15.926 3.04016 15.9554 3.10917C15.9849 3.17818 16 3.25215 16 3.32685V10.1559C15.9998 10.3068 15.9381 10.4514 15.8284 10.558C15.7187 10.6646 15.5699 10.7246 15.4147 10.7248H12.3812C12.5267 10.0871 12.5231 9.42576 12.3707 8.7896C12.2182 8.15344 11.9208 7.55865 11.5003 7.04908C11.0798 6.53952 10.547 6.12818 9.94118 5.84538C9.33535 5.56258 8.67194 5.41555 7.99985 5.41511C7.93437 5.41511 7.86947 5.41511 7.80457 5.41905V3.32685ZM0.585552 9.20755C0.479963 9.2076 0.376327 9.17989 0.285626 9.12736C0.194925 9.07482 0.120538 8.99941 0.0703476 8.90912C0.0201571 8.81884 -0.00396709 8.71704 0.00053153 8.61451C0.00503015 8.51198 0.037984 8.41255 0.095901 8.32674L4.19159 2.25681C4.24484 2.17791 4.31745 2.11313 4.40288 2.0683C4.4883 2.02347 4.58386 2 4.68095 2C4.77803 2 4.87359 2.02347 4.95902 2.0683C5.04445 2.11313 5.11706 2.17791 5.17031 2.25681L7.33404 5.46073C6.37059 5.60174 5.48038 6.04326 4.79757 6.71876C4.11476 7.39426 3.67634 8.26714 3.54838 9.20587L0.585552 9.20755Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgData);
export default Memo;
