import React from "react";
import { AnimatePresence, motion } from "framer-motion";

import "./styles.scss";
import { useAppStore } from "../../../store/AppStore/AppStore";
import AnimateLoader from "../../../common/components/AnimateLoader";

const variants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

const LoopLoader = () => {
  const isShow = useAppStore((state) => state.isLoading);
  const loadingContent = useAppStore((state) => state.loadingContent);

  return (
    <AnimatePresence exitBeforeEnter>
      {isShow ? (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={variants}
          className="loop__loader__wrapper"
        >
          <AnimateLoader text={loadingContent} />
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};

export default LoopLoader;
