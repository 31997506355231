import * as React from "react";
import { memo } from "react";
const SvgHome = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.7161 9.0634V14.3012C13.714 14.4858 13.6463 14.6622 13.5276 14.7928C13.4089 14.9233 13.2485 14.9977 13.0806 15H9.26914V10.8098H6.7297V15H2.91822C2.75034 14.9977 2.58993 14.9233 2.47122 14.7928C2.3525 14.6622 2.28488 14.4858 2.28278 14.3012V9.0634C2.28163 9.0524 2.28163 9.0413 2.28278 9.03031C2.28393 9.01931 2.28393 9.00821 2.28278 8.99721L7.989 3.82432L13.7033 8.99721C13.7113 9.01811 13.7149 9.04075 13.7137 9.0634H13.7161ZM15.9291 8.30987L15.3145 9.11686C15.2604 9.18296 15.1864 9.22543 15.1062 9.23651H15.0761C15.0004 9.24032 14.9262 9.21311 14.8677 9.16014L7.99942 2.86459L1.13227 9.16141C1.06324 9.21648 0.978963 9.24347 0.89384 9.23778C0.813581 9.2267 0.739629 9.18423 0.685499 9.11813L0.0708934 8.30987C0.0184339 8.23822 -0.00648765 8.14687 0.00144647 8.0553C0.00335531 8.00999 0.0140506 7.96565 0.0328153 7.92526C0.05158 7.88486 0.0779805 7.84935 0.110247 7.82109L7.24592 1.28243C7.46137 1.09933 7.72597 1 7.99826 1C8.27055 1 8.53515 1.09933 8.75061 1.28243L11.1755 3.50993V1.38044C11.174 1.33421 11.1812 1.28814 11.1966 1.24511C11.2121 1.20207 11.2354 1.16298 11.2651 1.13027C11.2949 1.09756 11.3304 1.07193 11.3695 1.05497C11.4087 1.03801 11.4506 1.03008 11.4926 1.03168H13.3978C13.44 1.02989 13.4821 1.03772 13.5215 1.05468C13.5608 1.07164 13.5966 1.09736 13.6265 1.13022C13.6563 1.16309 13.6797 1.2024 13.6951 1.24567C13.7106 1.28895 13.7177 1.33527 13.7161 1.38171V5.83671L15.8898 7.82364C15.9221 7.85184 15.9485 7.88734 15.9673 7.92775C15.9861 7.96815 15.9967 8.01252 15.9986 8.05784C16.0065 8.14941 15.9816 8.24077 15.9291 8.31242V8.30987Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgHome);
export default Memo;
