import React from "react";
import { Form } from "./styled";
import IconSend from "../../../../../../common/components/icons/Fill/Send/02";
import IconButton from "../../../../../../common/components/Buttons/IconButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  useChatBaseStore,
  setGenaralChatGroups,
  setMemberChatGroups,
  setCurrentChatData,
  setUpdateChatData,
} from "../../../../Store/ChatBaseStore";
import API_Common from "../../../../../../services/api-common";
import API_ENDPOINTS from "../../../../../../services/api-endpoints";
import { useGlobalStore } from "../../../../../../store/GlobalStore";
import { useAppStore } from "../../../../../../store/AppStore/AppStore";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Connector from "../../../../../../services/signalRConnection";

const initialValues = {
  message: "",
};

const validationSchema = Yup.object().shape({
  message: Yup.string().required(),
});

const MessageForm = ({ scrollToDown }) => {
  const inputRef = React.useRef();
  const { newMessage } = Connector();

  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);

  const chatData = useChatBaseStore((state) => state.chatData);
  const projectId = useGlobalStore((state) => state.selectedProjectId);
  let USER = JSON.parse(localStorage.getItem("user"));
  let Dategroups = [];
  let groupArray = [];

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onSubmit,
  });

  function onSubmit(values, { resetForm }) {
    console.log("sent message: ", values.message);
    try {
      const endpoints = new API_ENDPOINTS();
      //showLoader(); //Loading Indicator on
      let userId = USER.result.userId;
      let userName = USER.result.givenName + " " + USER.result.surName;

      var now = new Date();
      var dd = String(now.getDate()).padStart(2, "0");
      var mm = String(now.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = now.getFullYear();
      var HHH = now.getHours();
      var MM = now.getMinutes();
      var SS = now.getSeconds();
      var ms = now.getMilliseconds();

      now = yyyy + "-" + mm + "-" + dd + "T" + HHH + ":" + MM + ":" + SS;

      const msg_obj = {
        messageId: HHH + MM + SS + ms + 999,
        groupId: chatData?.groupId,
        userId: userId,
        fullName: userName,
        profileImage: "",
        content: values.message,
        timeStamp: now,
        chatUserMessageStatus: null,
        isActive: true,
        isDeleted: false,
        sysCreatedBy: "",
        sysCreatedOn: now,
        sysUpdatedBy: "",
        sysUpdatedOn: now,
        sysDeletedBy: "",
        sysDeletedOn: "1901-01-01T00:00:00",
      };
      newMessage(chatData?.groupName, JSON.stringify(msg_obj));

      const chatData_object = structuredClone(chatData);
      const messageInput = values.message;

      if (chatData_object?.chatMessage) {
        chatData_object?.chatMessage.push(msg_obj);
        console.log("chatData_object:: ", chatData_object);
        filterAndCategorizedChatWithUpdatedData(chatData_object);
      }
      resetForm();
      API_Common.post(endpoints.SEND_NEW_MESSAGE, {
        messageId: 0,
        groupId: chatData?.groupId,
        userId: userId,
        content: messageInput,
        timeStamp: new Date(),
      }).then((response) => {
        console.log("sent message response::", response);
        if (response.data.isSuccess === true) {
          getChatGroups();

          //func();
        } else {
          toast.error(response.data.displayMessage);
        }

        //hideLoader(); //Loading Indicator off
      });
    } catch (err) {
      console.log(err);
      toast.error(err);
      //hideLoader(); //Loading Indicator off
    }
  }

  const filterAndCategorizedChatWithUpdatedData = React.useCallback(
    (dataObj) => {
      let userId = USER.result.userId;
      console.log("chatData", chatData);

      if (dataObj?.chatMessage) {
        var sortedData = [...dataObj?.chatMessage].sort((a, b) =>
          a.timeStamp < b.timeStamp ? -1 : 1
        );

        Dategroups = sortedData.reduce((Dategroups, obj) => {
          const date = obj?.timeStamp?.split("T")[0];
          if (!Dategroups[date]) {
            Dategroups[date] = [];
          }
          Dategroups[date].push(obj);

          return Dategroups;
        }, {});

        groupArray = Object.keys(Dategroups).map((date) => {
          return {
            date,
            messages: Dategroups[date],
          };
        });

        console.log("groupArrays: ", groupArray);

        setCurrentChatData(groupArray);
      }
    },
    [chatData, scrollToDown]
  );

  const getChatGroups = React.useCallback(() => {
    let userId = USER.result.userId;
    const endpoints = new API_ENDPOINTS();
    API_Common.get(
      endpoints.GET_ALL_CHAT_GROUPS_BY_PROJECT +
        "?projectId=" +
        projectId +
        "&userId=" +
        userId
    ).then((response) => {
      if (response.data.isSuccess === true) {
        console.log("MF :: get Chat groupe :chat-groups:  ", response.data);

        const updatedChatdata = response.data.result.filter((element) => {
          return element.groupId == chatData?.groupId;
        });
        console.log("updated chat obj", updatedChatdata[0]);
        if (updatedChatdata !== null) {
          setUpdateChatData(updatedChatdata[0]);
          filterAndCategorizedChat(updatedChatdata[0]);
        }

        const memberChatdata = response.data.result.filter((element) => {
          return element.groupType == 1;
        });
        const groupChatdata = response.data.result.filter((element) => {
          return element.groupType == 2;
        });
        console.log("chat-groups:  ", groupChatdata);
        console.log("member chat-groups:  ", memberChatdata);
        setGenaralChatGroups(groupChatdata);
        setMemberChatGroups(memberChatdata);
        scrollToDown();
      }
    });
  }, [projectId, scrollToDown]);
  const filterAndCategorizedChat = React.useCallback(
    (dataObj) => {
      let userId = USER.result.userId;
      console.log("MF:: chatData dataObj", dataObj);

      if (dataObj !== null) {
        var sortedData = [...dataObj?.chatMessage].sort((a, b) =>
          a.timeStamp < b.timeStamp ? -1 : 1
        );

        Dategroups = sortedData.reduce((Dategroups, obj) => {
          const date = obj?.timeStamp?.split("T")[0];
          if (!Dategroups[date]) {
            Dategroups[date] = [];
          }
          Dategroups[date].push(obj);

          return Dategroups;
        }, {});

        groupArray = Object.keys(Dategroups).map((date) => {
          return {
            date,
            messages: Dategroups[date],
          };
        });

        console.log("groupArrays: ", groupArray);

        setCurrentChatData(groupArray);

        console.log("sort: ", sortedData);
        console.log("Dategroups: ", Dategroups);
      }
    },
    [chatData]
  );
  const {
    handleChange,
    handleSubmit,
    values,
    dirty,
    resetForm,
    status,
    isValid,
  } = formik;

  React.useEffect(() => {
    resetForm();
    inputRef?.current?.focus();
  }, [resetForm]);

  const handleKeyDown = React.useCallback(
    (e) => {
      if (e.keyCode !== 13) return;

      if (e.shiftKey) {
        return;
      }

      if (String(e.target.value).trim().length === 0) {
        e.preventDefault();
        resetForm();
        return;
      }

      e.preventDefault();

      handleSubmit();
    },
    [handleSubmit, resetForm]
  );

  return (
    <Form>
      <div className="form__input">
        <textarea
          name="message"
          ref={inputRef}
          value={values.message}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        ></textarea>

        <IconButton
          className="send__button"
          type="button"
          icon={<IconSend />}
          onClick={handleSubmit}
          disabled={!dirty || !String(values.message).trim().length}
        />
      </div>
    </Form>
  );
};

export default MessageForm;
