import React from "react";
import FullLogo from "../../../../assets/logo-full.svg";
import * as Styled from "./styled";

const Logo = ({isFull=false, to}) => {
  return (
    <Styled.LogoWrapper isFull={isFull} to={to}>
       <img className="logo_img" src={FullLogo} alt="logo" />
    </Styled.LogoWrapper>
  );
};

export default Logo;
