import React from "react";
import * as Styled from "./styled";
import Typography from "../../../Typography";
import IconClose from "../../../icons/Line/Close/01";
import { IconBlock } from "../../../../../styled/Common/Icons";
import Steps from "./components/Steps";
import BodyContainer from "./components/BodyContainer";

const WizardModal = (props) => {
  const {
    steps,
    children,
    visibility,
    onRootClose,
    onClose,
    title,
    bodyTitle,
    bodySubTitle,
    bodyFooterSlots,
    showEditButton = false,
    handleEdit,
    cardClassName,
    modalWidth,
    ...otherProps
  } = props;

  return (
    <Styled.Modal
      visibility={visibility}
      onRootClose={onRootClose}
      outerClassName="preview__modal__outer"
      $mWidth={modalWidth}
      {...otherProps}
    >
      {/* 
        Modal Header
    */}
      <Styled.ModalHeader>
        {/* Title and close btn */}
        <div className="header__inner">
          <Typography
            color={Typography.font_color.color_shade_primary}
            fontWeight={Typography.font_weight.bold}
            variant={Typography.font_variant.h5}
          >
            {title}
          </Typography>

          <div className="btn__close" role="button" onClick={onClose}>
            <IconBlock>
              <IconClose />
            </IconBlock>
          </div>
        </div>

        {/*
         Steps 
         */}
        <Steps steps={steps} />
      </Styled.ModalHeader>

      {/* 
         Body Container 
      */}

      <BodyContainer
        mainHeader={bodyTitle}
        subHeader={bodySubTitle}
        slots={bodyFooterSlots}
        showEditButton={showEditButton}
        handleEdit={handleEdit}
        cardClassName={cardClassName}
      >
        {children}
      </BodyContainer>
    </Styled.Modal>
  );
};

export default WizardModal;
