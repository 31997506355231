import * as React from "react";
import { memo } from "react";
const SvgAgent = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.49986 0C5.8477 0 4.52069 1.30668 4.52069 2.93336V3.5467C4.52069 4.24004 4.62902 4.90671 4.84569 5.57338L4.92694 5.81338C5.03527 6.13339 5.22486 6.42672 5.44152 6.64006L6.17277 7.36006C6.41652 7.60007 6.76861 7.76007 7.12069 7.76007H7.85194C8.20402 7.76007 8.55611 7.62673 8.79986 7.36006L9.53111 6.64006C9.77486 6.40006 9.93736 6.10672 10.0457 5.81338L10.1269 5.57338C10.3436 4.90671 10.4519 4.24004 10.4519 3.5467V2.93336C10.479 1.30661 9.15201 0 7.49986 0Z"
      fill="currentColor"
    />
    <path
      d="M3.70819 13.8668C4.00611 13.8668 4.24986 13.6268 4.24986 13.3335C4.24986 13.0401 4.00611 12.8001 3.70819 12.8001V13.8668Z"
      fill="currentColor"
    />
    <path
      d="M2.62486 11.7334C2.62486 12.0268 2.86861 12.2668 3.16652 12.2668V11.2001C2.86861 11.2001 2.62486 11.4401 2.62486 11.7334Z"
      fill="currentColor"
    />
    <path
      d="M12.1853 8.93334L9.39583 8.26667L7.5 11.0934L5.60417 8.26667L2.84167 8.93334C1.75833 9.20001 1 10.16 1 11.2533V16H14V11.2267C14 10.16 13.2686 9.20001 12.1853 8.93334ZM4.79152 13.3335C4.79152 13.9201 4.30402 14.4001 3.70819 14.4001V14.9335H3.16652V14.4001C2.57069 14.4001 2.08319 13.9201 2.08319 13.3335H2.62486C2.62486 13.6268 2.86861 13.8668 3.16652 13.8668V12.8001C2.57069 12.8001 2.08319 12.3201 2.08319 11.7334C2.08319 11.1468 2.57069 10.6668 3.16652 10.6668V10.1334H3.70819V10.6668C4.30402 10.6668 4.79152 11.1468 4.79152 11.7334H4.24986C4.24986 11.4401 4.00611 11.2001 3.70819 11.2001V12.2668C4.30402 12.2668 4.79152 12.7468 4.79152 13.3335Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgAgent);
export default Memo;
