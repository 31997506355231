import React from "react";

const useFiles = ({
  onFileUpload,
  allowTypes = [
    "image/png", 
    "image/jpeg", 
    "image/tiff", 
    "image/x-tiff", 
    "application/tiff", 
    "video/mp4", 
    "video/mov",    
    "video/webm",   
    "application/pdf",  
    "application/vnd.ms-powerpoint",        // ppt
    "application/vnd.openxmlformats-officedocument.presentationml.presentation", // pptx
    "application/vnd.apple.keynote",        // Keynote
    "application/msword",                   // doc
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" // docx
  ],
  maxFileSizeImages = 5,  // images = 5MB limit
  maxFileSizeVideos = 50, // vid = 50MB limit
  maxFileSizeDocs = 15, // doc = 15MB limit
  isUploading = false,
}) => {
  const [isDrop, setIsDrop] = React.useState(false);
  const [isDragStart, setIsDragStart] = React.useState(false);
  const [isDragOver, setIsDragOver] = React.useState(false);
  const [error, setError] = React.useState("");
  const [uploadedFiles, setUploadedFiles] = React.useState([]);

  const maxAllowedSizeImages = Math.abs(maxFileSizeImages) * 1024 * 1024;
  const maxAllowedSizeVideos = Math.abs(maxFileSizeVideos) * 1024 * 1024;
  const maxAllowedSizeDocs = Math.abs(maxFileSizeDocs) * 1024 * 1024;

  const handleFiles = React.useCallback(
    async (files, onError, onDone) => {
      setError("");
      setUploadedFiles([]);

      const fileList = Array.from(files);

      console.log("File Type is: ", fileList.map(file => file.type));

      // const matchFilesWithAllowType = fileList.filter(
      //   (file) => allowTypes.indexOf(file.type) > -1
      // );

      const matchFilesWithAllowType = fileList.filter((file) => {
        // Allow tiff files even if their MIME type is something unexpected
        return (
          allowTypes.indexOf(file.type) > -1 || 
          file.name.endsWith(".tiff") || 
          file.name.endsWith(".tif") ||
          file.name.endsWith(".mp4") || 
          file.name.endsWith(".mov") ||
          file.name.endsWith(".webm") ||
          file.name.endsWith(".pdf") ||
          file.name.endsWith(".ppt") || file.name.endsWith(".pptx") ||
          file.name.endsWith(".key") ||
          file.name.endsWith(".doc") || file.name.endsWith(".docx")
        );});

      // Check if the file type is allowed
      if (matchFilesWithAllowType.length !== fileList.length) {
        let types = allowTypes.map((type) => type.split("/")[1]).join(", ");
        setError(`Invalid file type. Allowed file types are: ${types}`);
        onError && onError("FILE_NOT_ALLOWED");
        return;
      }

      // File size chk based on file type=img,vid,doc
      const matchFilesSizeWithMaxAllowSize = fileList.filter((file) => {
        if (file.type.startsWith("image/")) {
          return file.size <= maxAllowedSizeImages;
        } else if (file.type.startsWith("video/")) {
          return file.size <= maxAllowedSizeVideos;
        } else if (
          file.type === "application/pdf" ||
          file.type === "application/vnd.ms-powerpoint" ||
          file.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
          file.type === "application/vnd.apple.keynote" ||
          file.type === "application/msword" ||
          file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          return file.size <= maxAllowedSizeDocs;
        }
        return false;
      });

        if (matchFilesSizeWithMaxAllowSize.length !== fileList.length) 
        {
          let oversizedFile = fileList.find((file) => {
            if (file.type.startsWith("image/")) {
              return file.size > maxAllowedSizeImages;
            } else if (file.type.startsWith("video/")) {
              return file.size > maxAllowedSizeVideos;
            } else if (
              file.type === "application/pdf" ||
              file.type === "application/vnd.ms-powerpoint" ||
              file.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
              file.type === "application/vnd.apple.keynote" ||
              file.type === "application/msword" ||
              file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ) {
              return file.size > maxAllowedSizeDocs;
            }
            return false;
          });

          if (oversizedFile) {
            const isImage = oversizedFile.type.startsWith("image/");
            const isVideo = oversizedFile.type.startsWith("video/");
            const isDoc = oversizedFile.type.startsWith("application/");
            const maxSize = isImage
              ? maxFileSizeImages
              : isVideo
              ? maxFileSizeVideos
              : isDoc
              ? maxFileSizeDocs
              : 0;
              const fileType = isImage
              ? "an image"
              : isVideo
              ? "a video"
              : isDoc
              ? "a document"
              : "a file";
            setError(
              `The file "${oversizedFile.name}" is too large. Allowed maximum size is ${maxSize}MB for ${fileType}`
            );
            onError && onError("MAX_FILE_SIZE");
          }
          return;
        }
    

      let __files = Array.from(files).map((file) => {
        // Define a new file reader
        let reader = new FileReader();
        let base64Text = ";base64,";

        // Create a new promise
        return new Promise((resolve) => {
          // Resolve the promise after reading file
          reader.onload = () => {
            resolve({
              file: file,
              fileUrl: reader.result,
              fileData: reader.result.substring(
                reader.result.indexOf(base64Text) + base64Text.length
              ),
            });
          };

          // Read the file as a image
          reader.readAsDataURL(file);
        });
      });

      let res = await Promise.all(__files);

      setUploadedFiles(res);
      onDone && onDone();
    },
    [allowTypes, maxAllowedSizeImages, maxAllowedSizeVideos, maxAllowedSizeDocs]
  );

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter") {
      setIsDragStart(true);
    } else if (e.type === "dragover") {
      setIsDragOver(true);
    } else if (e.type === "dragleave") {
      setIsDragStart(false);
      setIsDragOver(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setIsDragStart(false);
    setIsDragOver(false);
    setIsDrop(true);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files, null, () => {
        e.dataTransfer.clearData();
      });
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(
        e.target.files,
        () => {
          e.target.value = "";
        },
        () => {
          e.target.value = "";
        }
      );
    }
  };

  React.useEffect(() => {
    if (uploadedFiles.length > 0) {
      onFileUpload && onFileUpload(uploadedFiles);
      setUploadedFiles([]);
    }
  }, [onFileUpload, uploadedFiles]);

  return {
    error,
    uploadedFiles,
    isDragStart,
    isDrop,
    isDragOver,
    // functions
    handleDrag: isUploading ? null : handleDrag,
    handleDrop: isUploading ? null : handleDrop,
    handleChange: isUploading ? null : handleChange,
  };
};

export default useFiles;
