import React from "react";
import Header from "./components/Header";
import SchedulerPanel from "./components/SchedulerPanel";
import ScheduleContextProvider from "./components/SchedulerPanel/context/ScheduleContext";
import * as Styled from "./styled";
import SchedulerLegendsModal from "./components/Modals/SchedulerLegendsModal";
import ActivityBlockModal from "./components/Modals/ActivityBlockModal";
import { useGlobalStore } from "../../../../store/GlobalStore";
import { getEpisodeIdsByProjectId } from "../../API/common";

// const startDate = "2023-9-1";
// const endDate = "2025-9-26";

const Schedule = () => {
  const currentSelectedProject = useGlobalStore(
    (state) => state.selectedProject
  );

  const [episodeId, setEpisodeId] = React.useState(0);

  const { startDate, endDate, projectId, txDate } =
    currentSelectedProject?.projectDetails;

  React.useEffect(() => {
    getEpisodeIdsByProjectId(projectId).then((epId) => {
      setEpisodeId(epId);
    });
  }, [projectId]);

  if (
    !currentSelectedProject ||
    !currentSelectedProject?.projectDetails?.projectId ||
    !episodeId
  ) {
    return <></>;
  }

  return (
    <Styled.Container className="layout">
      <div className="layout__header">
        <Header projectData={currentSelectedProject?.projectDetails} />
      </div>
      <div className="layout__body" id="main__container">
        <ScheduleContextProvider
          startDate={new Date(startDate)}
          endDate={new Date(endDate)}
          projectId={projectId}
          episode={episodeId}
          txDate={new Date(txDate)}
        >
          <SchedulerPanel />

          {/* 
            Activity Block Modal
          */}
          <ActivityBlockModal />

          {/* 
              Scheduler Legends Modal
          */}
          <SchedulerLegendsModal />
        </ScheduleContextProvider>
      </div>
    </Styled.Container>
  );
};

export default Schedule;
