import React from "react";
import * as Styled from "./styled";
import BlockItem from "./BlockItem";
import { useSchedulerStore } from "../../../../../../../store/SchedulerStore";
import { ScheduleItemType } from "../../../../../../../../../Constants";

function OtherActivites({ onClick }) {
  const __selectedColumnType = useSchedulerStore(
    (state) => state.selectedColumnType
  );
  const legendScheduleItemTypes = useSchedulerStore(
    (state) => state.legendScheduleItemTypes
  );
  const [activityItemTypes, setActivityItemTypes] = React.useState([]);
  const [blockTypes, setBlockTypes] = React.useState([]);

  React.useEffect(() => {
    setActivityItemTypes([]);
    setBlockTypes([]);
    const nonSelectdColumnTypes =
      legendScheduleItemTypes?.filter(
        (item) => item.columnTypeId !== __selectedColumnType?.value
      ) || [];

    const allActivities = [];
    const allBlockes = [];
    nonSelectdColumnTypes.forEach((element) => {
      console.log("nonSelectdColumnTypes::: ", element);
      if (element?.activityItemTypes !== null) {
        allActivities.push(...element?.activityItemTypes);
      }
      if (element?.blockTypes?.length !== 0) {
        allBlockes.push(...element?.blockTypes);
      }
    });
    setActivityItemTypes(allActivities);
    setBlockTypes(allBlockes);
  }, [__selectedColumnType?.value, legendScheduleItemTypes]);

  return (
    <Styled.OtherActivitiesContainer>
      <div className="titleBlock">Others</div>

      {activityItemTypes?.map((d, i) => {
        return (
          <BlockItem
            key={i}
            itemName={d?.itemType?.itemName}
            onClick={() => onClick(ScheduleItemType.Activity, d)}
            backgroundColor="var(--color-accent-50, #EEF8F8)"
            textColor="#404A5F"
          />
        );
      })}
      {blockTypes?.map((d, i) => {
        return (
          <BlockItem
            key={i}
            itemName={d?.blockName}
            onClick={() => onClick(ScheduleItemType.Block, d)}
            backgroundColor="var(--color-accent-50, #EEF8F8)"
            textColor="#404A5F"
          />
        );
      })}

      {/* <BlockItem
        onClick={onClick}
        itemName="INSURANCE"
        backgroundColor="var(--color-accent-50, #EEF8F8)"
        textColor="#404A5F"
      />
      <BlockItem
        itemName="INSURANCE"
        backgroundColor="var(--color-accent-50, #EEF8F8)"
        textColor="#404A5F"
      />
      <BlockItem
        itemName="INSURANCE"
        backgroundColor="var(--color-accent-50, #EEF8F8)"
        textColor="#404A5F"
      />
      <BlockItem
        itemName="INSURANCE"
        backgroundColor="var(--color-accent-50, #EEF8F8)"
        textColor="#404A5F"
      />

      <BlockItem
        itemName="RESEARCH & DEVELOPMENT"
        backgroundColor="var(--color-secondary-50, #FDF3F0)"
        textColor="#404A5F"
      />
      <BlockItem
        itemName="RESEARCH & DEVELOPMENT"
        backgroundColor="var(--color-secondary-50, #FDF3F0)"
        textColor="#404A5F"
      />
      <BlockItem
        itemName="RESEARCH & DEVELOPMENT"
        backgroundColor="var(--color-secondary-50, #FDF3F0)"
        textColor="#404A5F"
      />
      <BlockItem
        itemName="RESEARCH & DEVELOPMENT"
        backgroundColor="var(--color-secondary-50, #FDF3F0)"
        textColor="#404A5F"
      />

      <BlockItem
        itemName="HAIR & MAKEUP (H&M)"
        backgroundColor="var(--color-info-50, #E9F7FE)"
        textColor="#404A5F"
      />
      <BlockItem
        itemName="HAIR & MAKEUP (H&M)"
        backgroundColor="var(--color-info-50, #E9F7FE)"
        textColor="#404A5F"
      />
      <BlockItem
        itemName="HAIR & MAKEUP (H&M)"
        backgroundColor="var(--color-info-50, #E9F7FE)"
        textColor="#404A5F"
      />
      <BlockItem
        itemName="HAIR & MAKEUP (H&M)"
        backgroundColor="var(--color-info-50, #E9F7FE)"
        textColor="#404A5F"
      />

      <BlockItem
        itemName="RECCE"
        backgroundColor="var(--color-success-50, #F2F9EA)"
        textColor="#404A5F"
      />
      <BlockItem
        itemName="RECCE"
        backgroundColor="var(--color-success-50, #F2F9EA)"
        textColor="#404A5F"
      />
      <BlockItem
        itemName="RECCE"
        backgroundColor="var(--color-success-50, #F2F9EA)"
        textColor="#404A5F"
      />
      <BlockItem
        itemName="RECCE"
        backgroundColor="var(--color-success-50, #F2F9EA)"
        textColor="#404A5F"
      /> */}
    </Styled.OtherActivitiesContainer>
  );
}

export default OtherActivites;
