import React, { useRef, useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import Checkbox from "../../../../../../controls/Checkbox";
import FromGroup from "../../../../../../controls/FromGroup";
import Button from "../../../../../Buttons/Button";
import { useSettingRoleStore } from "../../../../../../../store/Setting/Permission";
import { toast } from "react-toastify";

const formValues = {
  role: "",
  description: "",
  roleStatus: false,
};

// Validation Schema
const validationSchema = Yup.object().shape({
  role: Yup.string()
    .required("This field is required")
    .max(10, "Please enter a Maximum of 10 characters"),
  description: Yup.string()
    .required("This field is required")
    .max(50, "Please enter a Maximum of 50 characters"),
});

const RoleForm = (props) => {
  // const { variant } = props;

  const formData = useSettingRoleStore((state) => state.formData);
  const isEditMode = useSettingRoleStore((state) => state.isEditMode);
  const variant = useSettingRoleStore((state) => state.variant);
  const clearForm = useSettingRoleStore((state) => state.clearForm);
  const onFormSubmit = useSettingRoleStore((state) => state.onFormSubmit);
  const onToggleForm = useSettingRoleStore((state) => state.onToggleForm);
  const onRevalidate = useSettingRoleStore((state) => state.onRevalidate);

  useEffect(() => {}, []);
  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    onSubmit,
  });

  const {
    errors,
    values,
    handleChange,
    resetForm,
    handleSubmit,
    touched,
    setFieldValue,
  } = formik;

  function onSubmit(values, actions) {
    onFormSubmit(values)
      .then((res) => {
        if (res.isSuccess === true) {
          console.log("done", res);
          if (isEditMode === false) {
            // toast.success(res.displayMessage);
          } else {
            // toast.success("Role is updated successfully");
          }

          onRevalidate();
        } else {
          toast.error(res.displayMessage);
        }
      })
      .catch(() => {
        console.log("error");
      });
  }

  const onFormClearHandler = React.useCallback(() => {
    clearForm(() => {
      resetForm();
      onToggleForm();
    });
  }, [resetForm, clearForm, onToggleForm]);

  React.useEffect(() => {
    if (!isEditMode) {
      resetForm();
    }
  }, [isEditMode, resetForm, variant]);

  // Set values to form
  React.useEffect(() => {
    if (isEditMode) {
      console.log("formData ", formData);
      setFieldValue("role", formData?.role || "");
      setFieldValue("description", formData?.description || "");
      setFieldValue(
        "roleStatus",
        formData?.roleStatus === 1 ? true : false || false
      );
    }
  }, [isEditMode, formData, setFieldValue]);

  return (
    <div className="setting-form setting__roles--form">
      <form onSubmit={handleSubmit} className="row ">
        <div className="col-md-4">
          <FromGroup
            label="Role Name"
            inputHeightAuto
            error={touched.role && errors.role}
          >
            <input
              className="form-control"
              type="text"
              id="role"
              name="role"
              placeholder="Enter Role Name"
              aria-label="Role Name"
              value={values.role}
              onChange={handleChange}
              autoFocus
            />
          </FromGroup>
        </div>

        <div className="col-md-12">
          <FromGroup
            label="Role Description"
            inputHeightAuto
            error={touched.description && errors.description}
          >
            <textarea
              className="form-control"
              id="description"
              name="description"
              placeholder="Enter Role Description"
              aria-label="Role Description"
              value={values.description}
              onChange={handleChange}
            />
          </FromGroup>
        </div>

        <div className="col-md-12">
          <FromGroup inputHeightAuto>
            <Checkbox
              id="roleStatus"
              label="Is Active"
              name="roleStatus"
              // defaultChecked={values.roleStatus}
              checked={values.roleStatus}
              onChange={handleChange}
            />
          </FromGroup>
        </div>

        <div className="col-12">
          <FromGroup type="button-inline-left">
            <Button variant="secondary" onClick={onFormClearHandler}>
              Cancel
            </Button>
            <Button type="submit">{isEditMode ? "Edit" : "Add"}</Button>
          </FromGroup>
        </div>
      </form>
    </div>
  );
};

export default RoleForm;
