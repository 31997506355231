import React from "react";
import { TextSm } from "../../styled";
import TwoColumnCell from "./TwoColumnCell";
import IconButton from "../../../../../../../../common/components/Buttons/IconButton";
import IconDelete from "../../../../../../../../common/components/icons/Line/Delete/01";
import { useMilestone } from "../../../../context/MilestoneContext";

const DueCell = (props) => {
  const { table, cell, column, row, getValue } = props;
  const { deleteRowHandler } = useMilestone();
  const isRowHover = row.getIsRowHovered();

  const label = getValue().label || "Select Due";

  return (
    <TwoColumnCell className="due-cell">
      <TwoColumnCell.Left
        onClick={() => {
          table.options.meta.onDueCellHandler({
            // table,
            cell,
            column,
            row,
            value: getValue(),
          });
        }}
      >
        <TwoColumnCell.TextContent>
          <TextSm>{label}</TextSm>
        </TwoColumnCell.TextContent>
      </TwoColumnCell.Left>

      <TwoColumnCell.Right>
        {isRowHover ? (
          <IconButton
            className="delete-btn icon-hover"
            icon={<IconDelete />}
            btnIconSize={12}
            btnSize={20}
            onClick={(e) => {
              e.preventDefault();

              deleteRowHandler(row);
            }}
          />
        ) : null}
      </TwoColumnCell.Right>
    </TwoColumnCell>
  );
};

export default DueCell;
