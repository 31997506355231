import { generatePath, useMatch } from "react-router";
import { ROUTES_PATH } from "../../../../../routes/paths";
import { useGlobalStore } from "../../../../../store/GlobalStore";
import React from "react";

const useIsTenantPathMatch = () => {
  // const navigate = useNavigate();
  const currentTenant = useGlobalStore((state) => state.currentTenant);
  const mainPath = ROUTES_PATH.SettingRoutePaths;
  let tenantPath = mainPath.children.tenant.path;

  tenantPath = `${mainPath.path}/${tenantPath}`;

  // React.useEffect(() => {
  //   if (!currentTenant) {
  //     navigate(ROUTES_PATH.PublicRoutePath.Login.path);
  //     return;
  //   }
  // }, [currentTenant, navigate]);

  const genPath = React.useMemo(() => {
    return generatePath(tenantPath, {
      id: currentTenant?.tenantId || "0",
    });
  }, [currentTenant?.tenantId, tenantPath]);

  const path = useMatch(genPath);

  if (!currentTenant) return false;

  return !!path || false;
};

export default useIsTenantPathMatch;
