import { useNavigate, useParams } from "react-router-dom";
import TextControllerViewer from "../../../pages/Template/components/TXTextController/components/TextControllerViewer";
import React from "react";
import { getDocumentBase64ByDocId } from "../../../Actions/Document";
import {
  getProjectMemberByProjectMemberId,
  setProjectMemberStatus,
} from "../../../Actions/Project";
import { ONBOARDING_STATUS } from "../../../constant/Status";
import { useAppStore } from "../../../store/AppStore/AppStore";
import Alert from "../../../utility/alert";
import { toast } from "react-toastify";
import { useProjectStore } from "../../../store/ProjectStore";

const SignContract = () => {
  // const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);
  const navigate = useNavigate();
  const onReloadList = useProjectStore((state) => state.onReloadList);
  const { contractId, projectMemberId } = useParams();

  const [docBase64Data, setDocBase64Data] = React.useState(null);
  const [member, setMember] = React.useState(null);

  const getInitialData = React.useCallback(async () => {
    getDocumentBase64ByDocId(contractId).then((res) => {
      if (!res?.isSuccess) {
        return;
      }
      setDocBase64Data(res?.result);
    });
    await getProjectMemberByProjectMemberId(projectMemberId).then((result) => {
      if (result.isSuccess && result.result?.length > 0) {
        setMember(result.result[0]);
      }
    });
  }, [contractId, projectMemberId]);

  const submitSignature = (callback) => {
    hideLoader();
    // Assumptions:
    //   1. Contract always contain at least one signature box to sign.
    // TODO:
    //   2. Needs to find a way to add signing logs at the end of the contract
    //   3. Needs to restrist signing those who not allow to sign in the sign boxes.
    console.log("Submit callback: ", callback);
    const callbackState = callback?.state ? callback.state() : undefined;
    if (callbackState) {
      Alert.confirm({
        title: "Sign Contract",
        text: "Contract was " + callback.state() + "..!",
        confirmButtonText: "OK",
        showCancelButton: false,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(-1);
        }
      });
    } else {
      Alert.confirm({
        title: "Sign Contract",
        text: "Contract was signed successfully..!",
        confirmButtonText: "OK",
        showCancelButton: false,
      }).then((result) => {
        if (result.isConfirmed) {
          setProjectMemberStatus({
            projectMemberId: member?.projectMemberId,
            projectMemberStatusId: ONBOARDING_STATUS.ContractExecuted,
          })
            .then((res) => {
              if (!res.isSuccess) {
                toast.error(res?.result?.message);
                return;
              }

              toast.success(res?.displayMessage);
              onReloadList(true);
            })
            .catch((err) => {
              toast.error("Oops. Something went wrong. Please try again");
            });
          navigate(-1);
        }
      });
    }
  };

  React.useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  React.useEffect(() => {
    if (member) {
      console.log("Member state updated:", member);
    }
  }, [member]);

  return (
    <>
      {member && (
        <TextControllerViewer
          documentName={"Sign Contract"}
          documentData={docBase64Data}
          submitSignature={submitSignature}
          member={member}
        />
      )}
    </>
  );
};

export default SignContract;
