import styled from "styled-components";
import ModalBackdrop from "../../../../../components/ui-components/ModalBackdrop";

export const StyledModalBackdrop = styled(ModalBackdrop)`
  backdrop-filter: blur(0px);

  --modal-width: 100%;
  --modal-max-width: ${({ modalMaxWidth = undefined }) =>
    modalMaxWidth ? modalMaxWidth : `783px`};

  ${({ cssStyle }) => cssStyle};
`;

export const StyledModalOuter = styled.div`
  display: flex;
  align-items: center;
  max-width: var(--modal-max-width);
  width: var(--modal-width);
  z-index: 1;
  margin: 24px auto;
  min-height: calc(100% - (24px * 2));
`;

export const StyledModalContent = styled.div`
  background-color: #fff;
  border-radius: ${({ theme }) => theme.rounded("xl")};
  min-height: 200px;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  z-index: 1;
`;
