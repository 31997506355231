import styled from "styled-components";
import { fontStyle } from "../../../../../styled/utility";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;

  .event__inner {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    .event__head {
      padding-bottom: var(--layout-spacing-8);

      .date {
        ${fontStyle("h6-bold")}
        color: var(--font-color-shade-accent);
      }

      .day {
        ${fontStyle("base-medium")}
        color: var(--font-tint-04);
      }
    }

    .event__body {
      width: 100%;
      overflow-y: auto;
    }
  }
`;

