import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import UIButton from "../../../../../common/components/Buttons/UIButton";
import FieldGroup from "../../../../../common/components/Form/FieldGroup";
import InputField from "../../../../../common/components/Form/InputField";
import * as LineIcon from "../../../../../common/components/icons/Line";
import * as FillIcon from "../../../../../common/components/icons/Fill";
import { ValidationMessage } from "../../../../../utility/validation-message";
import EndIcon from "../../../components/EndIcon";

const initialValues = {
  email: "",
  password: "",
  confirm: "",
};

const validatePassword = (password) => {
  if (!password) return false;

  const lengthCheck = /.{8,64}/.test(password);
  const uppercaseCheck = /[A-Z]/.test(password);
  const lowercaseCheck = /[a-z]/.test(password);
  const numberCheck = /[0-9]/.test(password);
  const symbolCheck = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password);

  const checksPassed = [
    uppercaseCheck,
    lowercaseCheck,
    numberCheck,
    symbolCheck,
  ].filter(Boolean).length;

  return lengthCheck && symbolCheck && checksPassed >= 3;
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required(ValidationMessage.required)
    .email(ValidationMessage.email),
  password: Yup.string()
    .required(ValidationMessage.required)
    .min(8, ValidationMessage.min(8))
    .max(64, ValidationMessage.max(64))
    .test(
      "validatePassword",
      ValidationMessage.validatePasswordRegex,
      validatePassword
    ),
  confirm: Yup.string()
    .required(ValidationMessage.required)
    .oneOf([Yup.ref("password"), null], ValidationMessage.confirmPassword),
});

const CreateAccountForm = ({ handleSubmitForm }) => {
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleEndIconClick = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  const { errors, values, handleChange, touched, handleSubmit } = formik;

  function onSubmit(values) {
    if (!values?.email) return;

    handleSubmitForm && handleSubmitForm(values, formik);
  }

  return (
    <form onSubmit={handleSubmit}>
      {/*
        --------------------------------
          Form fields 
        --------------------------------
      */}
      <div className="form__content">
        <FieldGroup label="Email" error={touched.email && errors.email}>
          <InputField
            name="email"
            startIcon={<FillIcon.Email />}
            type="email"
            value={values.email}
            onChange={handleChange}
          />
        </FieldGroup>

        <FieldGroup
          label="Password"
          error={touched.password && errors.password}
        >
          <InputField
            name="password"
            startIcon={<LineIcon.Access1 />}
            endIcon={<EndIcon handleOnClick={handleEndIconClick} />}
            type={isPasswordVisible ? "text" : "password"}
            value={values.password}
            onChange={handleChange}
            isEndIconActive={isPasswordVisible}
          />
        </FieldGroup>

        <FieldGroup
          label="Re-type password"
          error={touched.confirm && errors.confirm}
        >
          <InputField
            {...formik.getFieldProps("confirm", {
              validateOnChange: true,
            })}
            name="confirm"
            startIcon={<LineIcon.Access1 />}
            endIcon={<EndIcon handleOnClick={handleEndIconClick} />}
            type={isPasswordVisible ? "text" : "password"}
            value={values.confirm}
            onChange={handleChange}
            isEndIconActive={isPasswordVisible}
          />
        </FieldGroup>
      </div>

      {/*
        --------------------------------
          Form Action 
        --------------------------------
      */}
      <div className="form__actions">
        <UIButton type="submit" fullWidth>
          Signup
        </UIButton>
      </div>
    </form>
  );
};

export default CreateAccountForm;
