import * as React from "react";
import { memo } from "react";
const SvgProp = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2507_15854)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8456 3.2394L9.15426 0.279608V0.278578C8.80532 0.0962432 8.40644 0 8 0C7.59351 0 7.19471 0.0962391 6.84568 0.278578L1.1543 3.23837C0.440017 3.61036 3.67469e-05 4.29727 3.67469e-05 5.04019V10.9598C-0.00225736 11.3218 0.102901 11.6782 0.305225 11.994C0.507541 12.3099 0.800142 12.5744 1.1543 12.7616L6.84568 15.7214C7.19469 15.9038 7.5935 16 8 16C8.40643 16 8.8053 15.9038 9.15426 15.7214L14.8456 12.7616C15.5599 12.3896 16 11.7027 16 10.9598V5.04023C16.002 4.67832 15.8968 4.32218 15.6944 4.0065C15.4921 3.69087 15.1997 3.42651 14.8456 3.23936V3.2394ZM12.2971 5.06427L7.99999 7.20718L3.70399 5.06427C3.63796 5.03156 3.56504 5.01084 3.48964 5.00346C3.41425 4.99608 3.33792 5.00216 3.26531 5.02131C3.19264 5.04051 3.12525 5.07236 3.06703 5.11494C3.00887 5.15758 2.96119 5.21004 2.92684 5.26928C2.8533 5.38933 2.83569 5.52968 2.87767 5.66085C2.91965 5.79202 3.01792 5.90394 3.15197 5.97321L7.42971 8.10501V12.9816C7.42729 13.1165 7.48607 13.2468 7.59315 13.3439C7.70023 13.4409 7.84693 13.4969 8.00113 13.4995C8.15534 13.4969 8.30204 13.4409 8.40918 13.3439C8.51626 13.2468 8.57504 13.1165 8.57256 12.9816V8.10501L12.8468 5.97321C12.9809 5.90394 13.0792 5.79202 13.1211 5.66085C13.1631 5.52967 13.1455 5.38933 13.0719 5.26928C13.0376 5.21031 12.99 5.15807 12.932 5.11565C12.874 5.07323 12.8068 5.04149 12.7345 5.02234C12.6621 5.00319 12.586 4.99701 12.5109 5.00422C12.4357 5.01144 12.363 5.03188 12.2971 5.06427Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2507_15854">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgProp);
export default Memo;
