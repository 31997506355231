import React from "react";
import { styleClass } from "../../../utility/common";
import "./styles.scss";

const TableListHeader = (props) => {
  const { columns = [], onSortHandler, sortOrder, sortField } = props;

  const onClickHandler = React.useCallback(
    (name, sortOrder) => {
      onSortHandler && onSortHandler(name, sortOrder);
    },
    [onSortHandler]
  );

  const genColumns = React.useMemo(() => {
    return (
      columns &&
      columns.map((col, i) => {
        if (col.invisible) return null;

        let isSortable = col.sortable ? true : false;
        let width = col.width ? col.width : "auto";
        let label = col.label ? col.label : "";
        let cls = isSortable ? "sortable" : "";

        let sortCls = col.sortable
          ? sortField === col.name && sortOrder === "asc"
            ? "up"
            : sortField === col.name && sortOrder === "desc"
            ? "down"
            : "default"
          : "";

        return (
          <div
            key={i}
            className={styleClass("column", cls, sortCls)}
            style={{ width }}
            onClick={isSortable ? () => onClickHandler(col.name) : undefined}
          >
            <div className="text">{label}</div>
            {isSortable ? (
              <i className="sort__icon icon icon-lp-arrow-down-full"></i>
            ) : null}
          </div>
        );
      })
    );
  }, [columns, sortOrder, sortField, onClickHandler]);

  return (
    <div className="table__list__header">
      <div className="columns">{genColumns}</div>
    </div>
  );
};

export default TableListHeader;
