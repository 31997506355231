import styled from "styled-components";
import { StyledBaseButton } from "../../../../../../styled/Common/Buttons";
import { fontStyle, responsive } from "../../../../../../styled/utility";

export const StyledButton = styled(StyledBaseButton)`
  --rd-btn-rounded: var(--button-border-radius-xxxl, 24px);
  --rd-btn-bg-color: transparent;
  --rd-btn-border-color: var(--button-secondary-active, #ef8b6d);

  border: 1px solid var(--rd-btn-border-color);
  display: flex;
  align-items: center;
  border-radius: var(--rd-btn-rounded);
  background-color: var(--rd-btn-bg-color);
  padding: var(--layout-spacing-4) var(--layout-spacing-12);

  &:hover {
    --rd-btn-bg-color: var(--layout-background-white);
  }

  .icon {
  }

  .text {
    ${fontStyle("small-medium")};
    font-weight: 500;
    margin-left: var(--spacing-2);
    display: none;
    color: var(--button-secondary-active, #ef8b6d);

    @media ${responsive.xl} {
      display: block;
    }
  }
`;
