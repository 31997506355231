import React from "react";
import {
  addMonths,
  format,
  isBefore,
  isValid,
  setDate,
  subMonths,
} from "date-fns";

import { useScheduleContext } from "../../context/ScheduleContext";
import MonthAndYearPopover from "./MonthAndYearPopover";
import { Wrapper } from "./styled";

import IconButton from "../../../../../../../../common/components/Buttons/IconButton";
import IconArrowLeft from "../../../../../../../../common/components/icons/Line/Arrow/left";
import IconArrowRight from "../../../../../../../../common/components/icons/Line/Arrow/right";

const DateSelector = () => {
  const { dataFetchDate, startDate, endDate, handleDateChange } =
    useScheduleContext();

  const [monthYearBtn, setMonthYearBtn] = React.useState();

  const dateNavigateHandler = React.useCallback(
    (isNext) => {
      if (!isValid(dataFetchDate)) return;

      let result = dataFetchDate;

      if (isNext === 1) {
        result = addMonths(dataFetchDate, 1);
      } else {
        result = subMonths(dataFetchDate, 1);
      }

      handleDateChange(setDate(result, 1));
    },
    [dataFetchDate, handleDateChange]
  );

  // const isValidRange = React.useMemo(() => {
  //   return isWithinInterval(dataFetchDate, {
  //     start: startDate,
  //     end: endDate,
  //   });
  // }, [dataFetchDate, endDate, startDate]);

  const isNextMonthDisabled = React.useMemo(() => {
    if (!isValid(dataFetchDate)) return true;

    return !isBefore(getMonthAndYear(dataFetchDate), getMonthAndYear(endDate));
  }, [dataFetchDate, endDate]);

  const isPrevMonthDisabled = React.useMemo(() => {
    if (!isValid(dataFetchDate)) return true;

    return !isBefore(
      getMonthAndYear(startDate),
      getMonthAndYear(dataFetchDate)
    );
  }, [dataFetchDate, startDate]);

  const displayDate = React.useMemo(() => {
    if (!isValid(dataFetchDate)) return new Date();

    return dataFetchDate;
  }, [dataFetchDate]);

  return (
    <>
      <Wrapper>
        <div className="selector">
          <IconButton
            icon={<IconArrowLeft />}
            btnIconSize={8}
            title="Previous"
            onClick={() => dateNavigateHandler(0)}
            disabled={isPrevMonthDisabled}
          />

          <button
            className="month-year-button"
            title="Month & Year"
            onClick={(e) => {
              setMonthYearBtn((prev) =>
                !prev ? document.querySelector(".selector") : null
              );
            }}
          >
            <span>{format(displayDate, "MMM")}</span>
            <span className="dashed-line">-</span>
            <span>{format(displayDate, "yyyy")}</span>
          </button>

          <IconButton
            icon={<IconArrowRight />}
            btnIconSize={8}
            title="Next"
            onClick={() => dateNavigateHandler(1)}
            disabled={isNextMonthDisabled}
          />
        </div>
      </Wrapper>

      <MonthAndYearPopover
        anchorEl={monthYearBtn}
        onOutsideClick={() => {
          setMonthYearBtn(null);
        }}
        onChange={(d) => {
          handleDateChange(setDate(d, 1));
        }}
      />
    </>
  );
};

export default DateSelector;

// Utility FC
function getMonthAndYear(d) {
  return new Date(format(d, "yyyy-MM"));
}
