import styled from "styled-components";
import { fontStyle } from "../../../../../../styled/utility";

export const PreviewWrapper = styled.div`
  .preview__top {
    padding: var(--layout-spacing-12) 0;

    .main__title {
      font-weight: 500;
    }
  }

  .preview__content {
    display: flex;
    flex-direction: column;
    gap: var(--layout-spacing-16);
  }
`;

export const Section = styled.div`
  .__title {
    ${fontStyle("x-small-medium")};
    color: var(--font-default-black);
    margin-bottom: var(--layout-spacing-12);
    font-weight: 500;
  }

  .__items {
  }

  .file__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: var(--layout-spacing-4);
  }

  .empty__content {
    background-color: var(--layout-background-n10);
    border: 1px dashed var(--layout-background-n40);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--layout-border-radius-8);
    padding: var(--layout-spacing-24) var(--layout-spacing-24);

    .text {
      ${fontStyle("x-small-medium")};
      color: var(--font-tint-04);
    }
  }
`;
