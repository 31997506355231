import styled from "styled-components";
import { fontStyle } from "../../../../styled/utility";

export const SkipHolidayContainer = styled.div`
    display: inline-flex;
    padding: var(--layout-spacing-12, 12px);
    flex-direction: column;
    justify-content: center;
    gap: var(--layout-spacing-4, 4px);

    border-radius: var(--layout-border-radius-4, 4px);
    border: 1px solid var(--color-accent-75, #BAE1E4);
    background: var(--color-white, #FFF);

    box-shadow: -3px 8px 15px 0px rgba(45, 89, 102, 0.08);

    .text{
        ${fontStyle("x-small-regular")};
        color: var(--font-tint-04, #969BAC);
    }
`
