export const PublicRoutePath = {
  Login: {
    path: "/login",
    id: "login",
    label: "Login",
  },
  
  CommunityPlatform: {
    path: "/community-platform",
    id: "community",
    label: "Community Platform"
  },

  PaymentProgress: {
    path: "/payment-progress",
    id: "payment",
    label: "Payment",
  },

  Verification: {
    path: "/verification",
    id: "verification",
    label: "Email Verification",
  },

  CreateAccountDetails: {
    path: "/new-account-user-details",
    id: "new-account-user-details",
    label: "New Account Details",
  },

  NewPassword: {
    path: "/new-password",
    id: "new-password",
    label: "New Password",
  },

  CreateAccount: {
    path: "/create-account",
    id: "createAccount",
    label: "New Password",
  },

  ResetPassword: {
    path: "/reset-password",
    id: "reset-password",
    label: "Reset Password",
  },

  ChangePassword: {
    path: "/change-pwd",
    id: "change-pwd",
    label: "Change Password",
  }, // New UI

  LoginUI: {
    path: "/login-ui",
    id: "login-ui",
    label: "Login",
  },

  ResetPasswordUI: {
    path: "/reset-pws-ui",
    id: "resetPassword",
    label: "Reset Password",
  },

  EmailVerificationUI: {
    path: "/emailverify-ui",
    id: "EmailVerificationUI",
    label: "Email Verification",
  },

  CreateAccountUI: {
    path: "/create-account-UI",
    id: "CreateAccountUI",
    label: "Create Account UI",
  },

  NewPasswordUI: {
    path: "/new-password-ui",
    id: "newPassword",
    label: "New Password",
  },

  CreateAccountDetailsUI: {
    path: "/user-details-ui",
    id: "user-details-ui",
    label: "New Account Details",
  },
};
