import styled from "styled-components";

// import { StyledBoxButton } from "../../../../styled/Common/Buttons";
import BaseLayout from "../../Components/BaseLayout";

export const ChatLayout = styled(BaseLayout)``;

export const ChatHeader = styled.div`
  display: flex;
  gap: var(--layout-spacing-16);
  min-height: 100%;
  align-items: center;
  justify-content: space-between;
  transition: var(--animate-1);

  .head__text {
    font-weight: 500;
    /* cursor: pointer; */
    flex: 1;
    display: flex;
    align-items: center;

    .icon__button__element {
      margin-right: var(--layout-spacing-12);
      margin-top: 3px;
    }

    span {
      display: flex;
    }

    &:hover {
      /* opacity: var(--layout-opacity-50); */
    }
  }

  .add__button {
    margin-right: var(--layout-spacing-minus-4);
    --icon-button-icon-color: var(--color-accent-400);

    &:hover {
      --icon-button-icon-color: var(--color-accent-200);
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: var(--layout-spacing-8);
  }
`;

export const ChatContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0px;
  overflow-y: auto;
  margin-bottom: var(--layout-spacing-4);

  .search__input_wrapper {
    display: flex;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
    padding: var(--layout-spacing-8) var(--layout-spacing-20);
    padding-bottom: var(--layout-spacing-12);
    background-color: var(--color-white);

    .chat__search__input {
      --search-height: var(--layout-size-32);
    }
  }
`;

export const ChatLists = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: var(--layout-spacing-16);
  margin-top: var(--layout-spacing-12);
`;

export const GroupList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: var(--layout-spacing-8);
`;

export const MemberList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: var(--layout-spacing-16);
`;


