import styled from "styled-components";
import { VIEW_CONTRACT_SOURCES } from "../../../../../../constant/Status";

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: ${(props) => props.containerHeight};
  padding-top: var(--layout-spacing-8);

  #txDocumentEditorContainer,
  #txTemplateDesignerContainer {
    background-color: var(--layout-background-n10) !important;
  }

  #txTemplateDesignerContainer #ribbonbar {
    height: 115px;
    --font-color: #245a6c;
    --font-family: var(--bs-body-font-family);
  }

  #txTemplateDesignerContainer ul.tabs {
    --main-menu-font-size: var(--x-small-regular-font-size);
  }

  #commentsSidebar .closebtn {
    --main-menu-font-size: var(--x-small-regular-font-size);
  }

  ${(props) =>
    (props.source === VIEW_CONTRACT_SOURCES.onboarding ||
      props.source === VIEW_CONTRACT_SOURCES.outside) &&
    `
    #txTemplateDesignerContainer ul.tabs li a {
      --font-color: white !important;
    }

    #txRibbonContentArea {
      display: none;
    }

    #commentsSidebar .closebtn {
      display: none;
    }
  `}

  #txRibbonTabContentContainer div .ribbon-group-footer {
    display: none;
  }

  #txBtnRibbonHide {
    display: none;
  }

  #horRulerCanvas {
    top: 116px;
  }

  #txTglBtnSideBars {
    font-size: var(--x-small-regular-font-size);
    font-family: var(--bs-body-font-family);
    font-weight: normal;
  }

  #fieldNavigatorSidebar, #commentsSidebar{
    background-color: white !important;
  }
`;
