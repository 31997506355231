import React from "react";
import * as Styled from "./styled";

function BlockItem(props) {
  const {
    itemName,
    backgroundColor,
    textColor = "black",
    ...otherProps
  } = props;

  return (
    <Styled.Item
      $textColor={textColor}
      $backgroundColor={backgroundColor}
      {...otherProps}
    >
      <div>{itemName}</div>
    </Styled.Item>
  );
}

export default BlockItem;
