import React, { useState, useEffect } from "react";
import { useRouteLoaderData, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirm } from "react-confirm-box";

import API from "../../../services/api";
import API_Project from "../../../services/api-project";
import API_ENDPOINTS from "../../../services/api-endpoints";
import Modal from "../../controls/modals/modal";
import close_icon from "../../img/close-circle.png";
import Container from "../../ui-components/Container";
import TabMenuItems, { TabNames } from "./components/TabMenuItems";
import ContractRequestModal from "../../ui-components/Modals/ContractRequestModal";
import { ROUTE_OPTIONS } from "../../../constant/route-options";
import {
  ONBOARDING_STATUS,
  VIEW_CONTRACT_SOURCES,
} from "../../../constant/Status";

import "../../assets/css/onboarding.b2df17d8cd6cdf17a1d8fde4e23c2c97.css";
import "../../assets/css/contract_genaration_request.a1de4eefc49f3ad2f4453a0dd8de4e6d.css";
import "../../assets/js/onboarding.31d6cfe0.js";
import "../../../components/sass/layout/common.css";
import useGeneratePath from "../../../hooks/useGeneratePath";
import { useProjectStore } from "../../../store/ProjectStore";
import Avatar from "../../ui-components/Avatar";
import Alert from "../../../utility/alert";
import OnboardingItems from "./components/OnboardingList";
import { OnboardingActionType } from "../../../constant/ActionTypes";

const OnboardingList = () => {
  const { permission } = useRouteLoaderData(
    ROUTE_OPTIONS.projects.children.onboarding.children["onboarding-list"].id
  );

  console.log("permission ", permission);

  // const moduleType = PROJECT_PERMISSION_MODULES.ProjectBrief;

  // const permissions = new Permission(permission);

  // const joinProjectAction = permissions.findByName(moduleType.JoinProject.key);

  let USER = JSON.parse(localStorage.getItem("user"));
  let _PROJECT_ = JSON.parse(localStorage.getItem("selected_project"));
  var inputtext = "";
  let MemberList = [];
  let ShortList = [];
  let OnBoardingList = [];
  let HR_List = [];
  let JobTitleList = [];
  const [allMembers, setAllMembers] = useState([]);
  const [show, setShow] = useState(false); //Loading Indicator
  const [allShortLIst, setAllShortList] = useState([]);
  const [allOnBoadingListSearch, setAllOnBoadingListSearch] = useState([]);
  const [allOnBoadingMembers, setAllOnBoadingMembers] = useState([]);
  const [allHRListSearch, setAllHRListSearch] = useState([]);
  const [showContractGenModal, setShowsContractGenModal] = useState(false);
  const [showAddToProjectDialog, setShowAddToProjectDialog] = useState(false);
  const [senderNote, setSenderNote] = useState("");
  const [jobTitleOptionList, setJobTitleOptionList] = useState([]);
  const [selectMember, setSelectMember] = useState(null);
  const navigate = useNavigate();
  const { genViewContractPath, genSignContractPath } = useGeneratePath();

  const isListReload = useProjectStore((state) => state.isListReload);
  const onReloadList = useProjectStore((state) => state.onReloadList);

  const openOnboardingModal = useProjectStore(
    (state) => state.openOnboardingModal
  );

  const setProjectSelectedMember = useProjectStore(
    (state) => state.setProjectSelectedMember
  );

  useEffect(() => {
    console.log("selected_project", _PROJECT_);
    searchAllMembers();
    getShortList(_PROJECT_.projectId);
    getOnBoardingList(_PROJECT_.projectId);
    getHRList(_PROJECT_.projectId);
    getUserMillstones(13);
    getAndBindJobTitleList();
  }, []);

  useEffect(() => {
    if (isListReload) {
      getOnBoardingList(_PROJECT_.projectId);
      getHRList(_PROJECT_.projectId);
      onReloadList(false);
    }
  }, [isListReload, onReloadList, _PROJECT_.projectId]);

  const actions = async (member, action) => {
    try {
      console.log("act:", action);
      console.log("member:", member);

      setSelectMember(member);
      setProjectSelectedMember(member);

      if (action === OnboardingActionType.RE_INVITE) {
        setSenderNote("");
        setShowAddToProjectDialog(true);
      }

      if (action === OnboardingActionType.CONTRACT_REQUESTED) {
        setShowsContractGenModal(true);
      }

      if (action === OnboardingActionType.VIEW_CONTRACT) {
        const { userId, contractId, contractRequestId } = member;

        const path = genViewContractPath({
          userId,
          contractId,
          contractRequestId,
          source: VIEW_CONTRACT_SOURCES.onboarding, // hr-list | onboarding-list | outside
        });

        navigate(path, { replace: true });
      }

      if (action === OnboardingActionType.SIGN_CONTRACT) {
        Alert.confirm({
          title: "Sign Contract",
          text: "Would you like to Sign the Contract ?.",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            const { projectMemberId, contractId, contractRequestId } = member;
            const path = genSignContractPath({
              projectMemberId,
              contractId,
              contractRequestId,
              source: VIEW_CONTRACT_SOURCES.onboarding,
            });
            console.log("Navigating with member:", member);
            navigate(path, { replace: true });
          }
        });
      }

      // if (action === "View or Change Request") {
      //   const { userId, contractId } = member;

      //   const path = genViewContractPath({
      //     userId,
      //     contractId,
      //     source: VIEW_CONTRACT_SOURCES.onboarding, // hr-list | onboarding-list | outside
      //   });

      //   navigate(path, { replace: true });
      // }
    } catch (err) {
      console.log(err);
    }
  };

  const searchInputKeyDown = async (e) => {
    inputtext = e.target.value;
    if (e.key.length === 1) {
      inputtext = inputtext + e.key;
    } else {
      if (e.key === "Backspace") {
        inputtext = inputtext.slice(0, -1);
      }
    }

    console.log(inputtext);
    searchMembersByName(inputtext);
    try {
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
    } catch (err) {
      console.log(err);
    }
  };

  const searchAllMembers = async () => {
    try {
      setShow(true);
      let tenantId = USER.result.tenantId;
      const endpoints = new API_ENDPOINTS();
      const response = API.get(endpoints.GETALLUSER_BYTenant + "/" + 0).then(
        (response) => {
          console.log(response);
          if (response.data.isSuccess === true) {
            MemberList = response.data.result;
            console.log("Allmembers", MemberList);
            const member_list = MemberList.map((item) => {
              // let isAddedToShortList = containsObject(item, ShortList);
              return <div></div>;
            });
            setAllMembers(member_list);
            setShow(false);
          } else {
            setShow(false);
            return;
          }
        }
      );
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const getShortList = async (projectId) => {
    try {
      setShow(true);
      let tenantId = USER.result.tenantId;
      const endpoints = new API_ENDPOINTS();
      const response = API_Project.get(
        endpoints.PROJECT_GET_PROJECT_MEMBER_SHORTLIST_BY_PROJECT +
          "/" +
          projectId
      ).then((response) => {
        console.log(response);
        if (response.data.isSuccess === true) {
          //toast.success(response.data.displayMessage);
          ShortList = response.data.result;
          console.log("Short List", ShortList);
          const short_list = ShortList.map((item) => {
            return (
              <div className="shortlisted__member">
                <div className="shortlisted__member__iW mb-2">
                  <Avatar size="size-60" src={item.profileImage} />
                </div>
                <div className="shortlisted__member__iW">
                  <div className="shortlisted__member__name">
                    {item.surName}
                  </div>
                </div>
                <div className="shortlisted__member__iW">
                  <div className="shortlisted__member__designation">
                    {item.jobTitleName}
                  </div>
                </div>
              </div>
            );
          });
          setAllShortList(short_list);
          setShow(false);
        } else {
          setShow(false);
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const Card = ({ member }) => {
    let skillList = member.skillList;
    let skillListString = "";
    let statusColor = "#000000";
    let action = "None";
    let statusName = "None";
    // ONBOARDING_STATUS

    // status = 1
    if (member.statusId === ONBOARDING_STATUS.ShortListed) {
      statusColor = "rgb(45 81 92)";
      action = "Short Listed";
      statusName = "Short Listed";
    }

    // status = 2
    if (member.statusId === ONBOARDING_STATUS.ProjectAdded) {
      statusColor = "rgb(45 81 92)";
      action = "Invite";
      statusName = "A. Project";
    }

    // status = 3
    if (member.statusId === ONBOARDING_STATUS.InvitationReceived) {
      statusColor = "#45a15d";
      action = "Accept or Reject"; // should be going to NDA
      statusName = "Invited";
    }

    // status = 4
    if (member.statusId === ONBOARDING_STATUS.InvitationAccepted) {
      statusColor = "#45a15d";
      action = "View Contract";
      statusName = "Invitation Accepted";
    }

    // status = 5
    if (member.statusId === ONBOARDING_STATUS.InvitationRejected) {
      statusColor = "#45a15d";
      action = "None";
      statusName = "Invitation Rejected";
    }

    // status = 6
    if (member.statusId === ONBOARDING_STATUS.ContractRequested) {
      statusColor = "#45a15d";
      action = "None";
      statusName = "Contract Requested";
    }

    // status =  7
    if (member.statusId === ONBOARDING_STATUS.ContractChangeRequested) {
      statusColor = "#e89f7a";
      action = "View Contract";
      statusName = "Contract Change Requested";
    }

    // status =  8
    if (member.statusId === ONBOARDING_STATUS.ContractGenerated) {
      statusColor = "#e89f7a";
      action = "View Contract";
      statusName = "Contract Generated";
    }

    // status =  9
    if (member.statusId === ONBOARDING_STATUS.ContractSent) {
      statusColor = "#e89f7a";
      action = "View Contract";
      statusName = "Contract Sent";
    }

    // status =  10
    if (member.statusId === ONBOARDING_STATUS.ContractSigned) {
      statusColor = "#e89f7a";
      action = "";
      statusName = "Contract Signed";
    }

    // status =  11
    if (member.statusId === ONBOARDING_STATUS.ContractAccepted) {
      statusColor = "#e89f7a";
      action = "Sign Contract";
      statusName = "Contract Accepted";
    }
    // status =  12
    if (member.statusId === ONBOARDING_STATUS.ContractRejected) {
      statusColor = "#e89f7a";
      action = "None";
      statusName = "Contract Rejected";
    }

    // status =  13
    if (member.statusId === ONBOARDING_STATUS.ContractExecuted) {
      statusColor = "#e89f7a";
      action = "";
      statusName = "Contract Executed";
    }

    // status =  14
    if (member.statusId === ONBOARDING_STATUS.ContractRejectedIsAck) {
      statusColor = "#e89f7a";
      action = "None";
      statusName = "Contract Rejected IsAck";
    }

    // status =  15
    if (member.statusId === ONBOARDING_STATUS.Onboarded) {
      statusColor = "#e89f7a";
      action = "View Contract";
      statusName = "Onboarded";
    }

    // status =  16
    if (member.statusId === ONBOARDING_STATUS.ContractAcceptingByHR) {
      statusColor = "#e89f7a";
      action = "";
      statusName = "Contract In Progress";
    }

    // status =  17
    if (member.statusId === ONBOARDING_STATUS.ContractAcceptedByHR) {
      statusColor = "#e89f7a";
      action = "View Contract";
      statusName = "Contract Accepted (HR)";
    }

    // status =  18
    if (member.statusId === ONBOARDING_STATUS.ContractApproved) {
      statusColor = "#e89f7a";
      action = "View Contract";
      statusName = "Contract Approved";
    }

    // status =  19
    if (member.statusId === ONBOARDING_STATUS.MemberRequestedContract) {
      statusColor = "#18bd94";
      action = "Request Contract";
      statusName = "Member Requested Contract";
    }

    // status =  20
    if (member.statusId === ONBOARDING_STATUS.MemberContractDeclined) {
      statusColor = "#e89f7a";
      action = "None";
      statusName = "Member Contract Declined";
    }

    // console.log("skills", skillList);
    skillList?.forEach((skill) => {
      skillListString = skillListString + skill.skillName + ", ";
    });

    return (
      <div className="onb__member">
        <span
          style={{
            width: "35%",
            position: "absolute",
            top: "-11px",
            backgroundColor: statusColor,
            borderRadius: 5,
            left: "60%",
            border: "solid gray 1px",
            color: "gainsboro",
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;{statusName}
        </span>

        <div className="onb__member__iW">
          <div className="onb__member__pic_n_menu">
            <div className="onb__member__pic_n_menu__iW">
              <div className="onb__member__pic">
                <a
                  href="javascript:void(0)"
                  className="event-pointer icon icon-lp-star "
                >
                  {" "}
                </a>
                <div className="onb__member__pic__iW">
                  {" "}
                  <img alt="" src={member.profileImage} />
                </div>
              </div>
              <div className="onb__member__menu">
                <span className="event-pointer icon icon-lp-dot-menu" />
              </div>
            </div>
          </div>
          <div className="onb__member__name">
            <div className="onb__member__name__iW">
              <div className="onb__member__nickname">{member.displayName}</div>
              <div className="onb__member__fullname">{member.fullName}</div>
            </div>
          </div>
          <div className="onb__member__primary__skill">
            {member.projectRoleName}
          </div>
          <div className="onb__member__other__skills">{skillListString} </div>
        </div>

        <div className="onb__member__iW">
          <div className="onb__member__other__info">
            <div className="onb__member__other__info__iW">
              {" "}
              <span className="event-pointer icon icon-lp-profile profile" />
              <div className="city_location">
                <div className="city_location_iW">
                  {" "}
                  <span className="event-pointer icon icon-lp-city city">
                    Węgliniec
                  </span>
                  <span className="event-pointer icon icon-lp-location location">
                    Poland
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="onb__member__iW">
          <div className="onb__member__actions">
            <div className="onb__member__actions__iW">
              <span className="event-pointer onb__member__chat icon icon-lp-chat">
                {" "}
              </span>

              <span
                className="event-pointer onb__member__remove icon icon-lp-delete-2"
                // onClick={() => removeUserFromShortlist(member)}
              >
                {" "}
              </span>
              <span
                className="event-pointer onb__member__addToShortlist"
                onClick={() => actions(member, action)}
              >
                {action}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getOnBoardingList = async (projectId) => {
    try {
      setShow(true);
      let tenantId = USER.result.tenantId;
      const endpoints = new API_ENDPOINTS();
      const response = API_Project.get(
        endpoints.PROJECT_GET_PROJECT_MEMBER_ONBOARDINGLIST_BY_PROJECT +
          "/" +
          projectId
      ).then((response) => {
        console.log("onboarding API Res: ", response);
        if (response.data.isSuccess === true) {
          //toast.success(response.data.displayMessage);
          OnBoardingList = response.data.result;
          setAllOnBoadingMembers(OnBoardingList);
          console.log("Onboarding List", OnBoardingList);
          ////////////////////////////////////////////
          let categorizedMembers = [];
          OnBoardingList.forEach((member) => {
            let members = OnBoardingList.filter(
              (m) => m.projectRoleId === member.projectRoleId
            );

            members.forEach((member) => {
              member.jobTitleId = member.projectRoleId;
              member.jobTitleName = member.projectRoleName;
            });

            members.forEach((member) => {
              member.jobTitleId = member.projectRoleId;
              member.jobTitleName = member.projectRoleName;
            });

            categorizedMembers.push({
              jobTitleId: member.projectRoleId,
              jobTitleName: member.projectRoleName,
              members: members,
            });
          });

          categorizedMembers = categorizedMembers.filter(
            (ele, ind) =>
              ind ===
              categorizedMembers.findIndex(
                (elem) => elem.jobTitleId === ele.jobTitleId
              )
          );
          console.log("cat-Allmembers", categorizedMembers);
          //////////////////////////////////////////////////////////////////////
          const onboarding_list = OnBoardingList.map((item) => {
            return (
              <div className="shortlisted__member">
                <div className="shortlisted__member__iW mb-2">
                  <Avatar size="size-60" src={item.profileImage} />
                </div>
                <div className="shortlisted__member__iW">
                  <div className="shortlisted__member__name">
                    {item.surName}
                  </div>
                </div>
                <div className="shortlisted__member__iW">
                  <div className="shortlisted__member__designation">
                    {item.projectRoleName}
                  </div>
                </div>
              </div>
            );
          });
          /////////////////////////////////////////////Search////////////////////////////////////////////////
          const onboarding_list_search = categorizedMembers.map((item) => {
            let skillList = item.skillList;

            let skillListString = "";
            console.log("skills", skillList);
            skillList?.forEach((skill) => {
              skillListString = skillListString + skill.skillName + ", ";
            });
            return (
              <div>
                <div className="onb__page__container__iW shrink_to_fit">
                  <div class="onb__page__section__title">
                    {item.jobTitleName}
                    <span>({item.members.length})</span>
                  </div>
                </div>
                <div class="onb__page__container__iW shrink_to_fit no_top_margin">
                  {item.members.map((member, i) => {
                    return <Card key={i} member={member} />;
                  })}
                </div>
              </div>
            );
          });

          //setAllShortList(short_list);
          setAllOnBoadingListSearch(categorizedMembers);
          setShow(false);
        } else {
          setShow(false);
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const getHRList = async (projectId) => {
    try {
      setShow(true);
      let tenantId = USER.result.tenantId;
      const endpoints = new API_ENDPOINTS();
      const response = API_Project.get(
        endpoints.PROJECT_GET_PROJECT_HR_LIST + "/" + projectId
      ).then((response) => {
        console.log(response);
        if (response.data.isSuccess === true) {
          //toast.success(response.data.displayMessage);
          HR_List = response.data.result;
          console.log("HR List", HR_List);
          setAllHRListSearch(HR_List);
          ////////////////////////////////////////////

          setShow(false);
        } else {
          setShow(false);
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const getOnBoardingListByStatus = async (projectId, statusId) => {
    try {
      setShow(true);
      let tenantId = USER.result.tenantId;
      const endpoints = new API_ENDPOINTS();
      const response = API_Project.get(
        endpoints.PROJECT_GET_PROJECT_MEMBER_ONBOARDINGLIST_BY_PROJECT +
          "/" +
          projectId
      ).then((response) => {
        console.log(response);
        if (response.data.isSuccess === true) {
          //toast.success(response.data.displayMessage);
          OnBoardingList = response.data.result;
          console.log("Onboarding List", OnBoardingList);
          ////////////////////////////////////////////
          let categorizedMembers = [];
          let StatusBaseList = OnBoardingList.filter(
            (m) => m.statusId === statusId
          );
          StatusBaseList.forEach((member) => {
            let members = OnBoardingList.filter(
              (m) =>
                m.projectRoleId === member.projectRoleId &&
                m.statusId === statusId
            );

            members.forEach((member) => {
              member.jobTitleId = member.projectRoleId;
              member.jobTitleName = member.projectRoleName;
            });

            categorizedMembers.push({
              jobTitleId: member.projectRoleId,
              jobTitleName: member.projectRoleName,
              members: members,
            });
          });

          categorizedMembers = categorizedMembers.filter(
            (ele, ind) =>
              ind ===
              categorizedMembers.findIndex(
                (elem) => elem.jobTitleId === ele.jobTitleId
              )
          );
          console.log("cat-Allmembers", categorizedMembers);
          //////////////////////////////////////////////////////////////////////
          const onboarding_list = OnBoardingList.map((item) => {
            return (
              <div className="shortlisted__member">
                <div className="shortlisted__member__iW mb-2">
                  <Avatar size="size-60" src={item.profileImage} />
                </div>
                <div className="shortlisted__member__iW">
                  <div className="shortlisted__member__name">
                    {item.surName}
                  </div>
                </div>
                <div className="shortlisted__member__iW">
                  <div className="shortlisted__member__designation">
                    {item.jobTitleName}
                  </div>
                </div>
              </div>
            );
          });
          /////////////////////////////////////////////Search////////////////////////////////////////////////
          const onboarding_list_search = categorizedMembers.map((item) => {
            let skillList = item.skillList;

            let skillListString = "";
            console.log("skills", skillList);
            skillList?.forEach((skill) => {
              skillListString = skillListString + skill.skillName + ", ";
            });
            return (
              <div>
                <div className="onb__page__container__iW shrink_to_fit">
                  <div class="onb__page__section__title">
                    {item.jobTitleName}
                    <span>({item.members.length})</span>
                  </div>
                </div>
                <div class="onb__page__container__iW shrink_to_fit no_top_margin">
                  {item.members.map((member, i) => {
                    return <Card key={i} member={member} />;
                  })}
                </div>
              </div>
            );
          });
          //setAllShortList(short_list);
          setAllOnBoadingListSearch(categorizedMembers);
          setShow(false);
        } else {
          setShow(false);
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const onStatusChange = async (statusId) => {
    try {
      console.log("statusId", statusId);
      if (statusId !== 0) {
        getOnBoardingListByStatus(_PROJECT_.projectId, statusId);
      } else {
        getOnBoardingList(_PROJECT_.projectId);
      }
      let element = document.getElementById("status_filter_options");
      element.style.display = "none";
    } catch (err) {
      console.log(err);
    }
  };

  const onFilter = async () => {
    try {
      let element = document.getElementById("status_filter_options");
      element.style.display = "flex";
    } catch (err) {
      console.log(err);
    }
  };

  const searchMembersByName = async (searchText) => {
    try {
      if (searchText.length === 0) {
        getOnBoardingList(_PROJECT_.projectId);
        return;
      }
      setShow(true);
      const endpoints = new API_ENDPOINTS();
      const response = API_Project.get(
        endpoints.PROJECT_GET_PROJECT_MEMBER_ONBOARDINGLIST_BY_NAME +
          "/" +
          _PROJECT_.projectId +
          "/" +
          searchText
      ).then((response) => {
        console.log(response);
        if (response.data.isSuccess === true) {
          OnBoardingList = response.data.result;
          console.log("OnBoardingListSearch", OnBoardingList);
          ////////////////////////////////////////////
          let categorizedMembers = [];
          OnBoardingList.forEach((member) => {
            let members = OnBoardingList.filter(
              (m) => m.jobTitleId === member.jobTitleId
            );

            members.forEach((member) => {
              member.jobTitleId = member.projectRoleId;
              member.jobTitleName = member.projectRoleName;
            });

            categorizedMembers.push({
              jobTitleId: member.projectRoleId,
              jobTitleName: member.projectRoleName,
              members: members,
            });
          });

          categorizedMembers = categorizedMembers.filter(
            (ele, ind) =>
              ind ===
              categorizedMembers.findIndex(
                (elem) => elem.jobTitleId === ele.jobTitleId
              )
          );
          console.log("cat-Allmembers", categorizedMembers);
          //////////////////////////////////////////////////////////////////////
          const onboard_list_search = categorizedMembers.map((item) => {
            let skillList = item.skillList;
            let skillListString = "";
            console.log("skills", skillList);
            skillList?.forEach((skill) => {
              skillListString = skillListString + skill.skillName + ", ";
            });
            return (
              <div>
                <div className="onb__page__container__iW shrink_to_fit">
                  <div class="onb__page__section__title">
                    {item.jobTitleName}
                    <span>({item.members.length})</span>
                  </div>
                </div>
                <div class="onb__page__container__iW shrink_to_fit no_top_margin">
                  {item.members.map((member, i) => {
                    return <Card key={i} member={member} />;
                  })}
                </div>
              </div>
            );
          });

          setAllOnBoadingListSearch(categorizedMembers);
          setShow(false);
        } else {
          setShow(false);
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const SendInvitaion = async (member, __projectMemberId) => {
    try {
      var base_url = window.location.origin;
      if (senderNote.length > 150) {
        toast.warning("Maximum characters of message is 150 characters.");
        return;
      }

      const confirmation = await confirm(
        "Do you want to send invitation to this member?"
      );

      if (!confirmation) {
        return;
      }
      setShow(true); //Loading Indicator on
      const endpoints = new API_ENDPOINTS();
      const response = API_Project.post(
        endpoints.PROJECT_SEND_PROJECT_ADDED_USER_INVITAION,
        {
          projectId: _PROJECT_.projectId,
          projectMemberId: __projectMemberId,
          invitationUrl: base_url + "/confirm-project-member/{invitationId}",
          senderNote: senderNote,
          // templateId: 0,
          userDocumentId: 0,
          //fromDate: new Date(),
          isProjectDocumentRequired: false,
        }
      ).then((response) => {
        console.log("Send Invitaion: ", response);
        if (response.data.isSuccess === true) {
          // toast.success("Invitaion sent successfully");
          setSenderNote("");
          setShowAddToProjectDialog(false);
          getOnBoardingList(_PROJECT_.projectId);
          setShow(false); //Loading Indicator off
        } else {
          console.log(response.data.result.message);
          toast.error(response.data.result.message + " in Project Invitaion");
          setShow(false); //Loading Indicator off

          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };

  const getUserMillstones = async (__projectMemberId) => {
    try {
      setShow(true);
      let tenantId = USER.result.tenantId;
      const endpoints = new API_ENDPOINTS();
      const response = API_Project.get(
        endpoints.GET_USER_STATUS_CHANGE_HISTORY + "/" + __projectMemberId
      ).then((response) => {
        console.log("Millestones List RESPONSE", response);
        if (response.data.isSuccess === true) {
          console.log("Millestones List", response.data.result);

          setShow(false);
        } else {
          setShow(false);
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const genarateContractRequest = async (projectMemberId) => {
    try {
      setShow(true);
      const endpoints = new API_ENDPOINTS();
      const response = API_Project.get(
        endpoints.CONTRACT_GENARATION_REQUEST +
          "?projectMemberId=" +
          projectMemberId
      ).then((response) => {
        console.log(response);
        if (response.data.isSuccess === true) {
          // toast.success(response.data.displayMessage);
          console.log("CONTRACT_GENARATION_REQUEST", response.data.result);
          setShowsContractGenModal(false);

          setShow(false);
        } else {
          setShow(false);
          toast.error(response.data.displayMessage);
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const getAndBindJobTitleList = async (e) => {
    try {
      setShow(true); //Loading Indicator
      const endpoints = new API_ENDPOINTS();
      const response = API.get(endpoints.GETALLJOBTITLES).then((response) => {
        console.log("job titles:", response);
        if (response.data.isSuccess === true) {
          JobTitleList = response.data.result;

          const jobTitle_list_dropdown = JobTitleList.map((item) => {
            return <option value={item.jobTitleId}>{item.jobTitleName}</option>;
          });

          setJobTitleOptionList(jobTitle_list_dropdown);

          setShow(false); //Loading Indicator
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const actionHandler = (action) => {
    actions(action.payload.member, action.type);
  };

  return (
    <>
      <Container withGutterY={false} pageContentClassName="onb_fullpage">
        <div className="onb__page__container top__info">
          {/* <div className="onb__page__container__iW">
            <div className="onb__page__title">
              OnBoarding - {_PROJECT_.projectName}
            </div>
          </div> */}

          <div className="onb__page__container__iW extended">
            <div className="onb__page__actionbar">
              <div className="onb__page__actionbar__iW">
                <div className="onb__page__actionbar__left">
                  <div className="onb__page__actionbar__left__iW">
                    <div className="onb__page__actionbar__search">
                      <div className="input-group line-inputs icon icon-lp-search">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Search"
                          aria-label="find member"
                          onKeyDown={searchInputKeyDown}
                        />
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          All Members
                        </button>
                        <div className="dropdown-menu dropdown-menu-end custom_dropdown_style">
                          <div className="dropdown_iW">
                            <ul>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Director of Photography
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Director of Photography
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Director of Photography
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="onb__page__actionbar__search-filter">
                      <div className="dropdown icon_only_btn_dropdown">
                        {" "}
                        <button
                          className="btn dropdown-toggle icon icon-lp-filter-1 icon-20"
                          id="filter_search"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        />
                        <div
                          className="dropdown-menu custom_dropdown_style"
                          aria-labelledby="filter_search"
                        >
                          <div className="dropdown_iW">
                            <div className="dropdown__title">
                              Job Categories
                            </div>
                            <ul>
                              <li>
                                {" "}
                                <a className="dropdown-item" href="#">
                                  Director of Photography
                                </a>
                              </li>
                              <li>
                                {" "}
                                <a className="dropdown-item" href="#">
                                  Director of Photography
                                </a>
                              </li>
                              <li>
                                {" "}
                                <a className="dropdown-item" href="#">
                                  Director of Photography
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="onb__page__actionbar__right">
                  <div className="onb__page__actionbar__right__iW">
                    <div className="onb__page__actionbar__sort">
                      <div className="dropdown icon_only_btn_dropdown">
                        {" "}
                        <button
                          className="btn dropdown-toggle icon icon-lp-sorting icon-20"
                          id="filter_search"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={() => onFilter()}
                        />
                        <div
                          className="dropdown-menu custom_dropdown_style"
                          aria-labelledby="filter_search"
                          id="status_filter_options"
                        >
                          <div className="dropdown_iW">
                            <div className="dropdown__title">Filter by</div>
                            <ul>
                              <li>
                                {" "}
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() => onStatusChange(0)}
                                >
                                  All
                                </a>
                              </li>
                              <li>
                                {" "}
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() => onStatusChange(2)}
                                >
                                  Add to Project
                                </a>
                              </li>
                              <li>
                                {" "}
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() => onStatusChange(3)}
                                >
                                  Invited
                                </a>
                              </li>
                              <li>
                                {" "}
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() => onStatusChange(4)}
                                >
                                  NDA
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="onb__page__actionbar__viewSwitch">
                      <ul>
                        <li className="active">
                          <a className="icon icon-lp-grid-small" href="#" />
                        </li>
                        <li>
                          <a className="icon icon-lp-th-list" href="#" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="onb__page__global__save me-2">
              <button
                className="btn btn-orange text-white btn-lg icon icon-lp-add-user lp-org-btn"
                type="button"
              ></button>
            </div> */}
            {/* <div className="onb__page__global__save">
              <button
                className="btn btn-green-light text-white btn-lg icon icon-lp-add-user"
                type="button"
              >
                Add Shortlisted to Project
              </button>
            </div> */}
          </div>

          <div className="onb__page__container__iW">
            <div className="onb__page__tabs">
              <div className="onb__page__tabs__iW">
                <div className="onb__page__tabs__left">
                  <TabMenuItems
                    memberCount={allMembers.length}
                    shortListCount={allShortLIst.length}
                    onboardingListCount={allOnBoadingMembers.length}
                    hrListCount={allHRListSearch.length}
                    activeTab={TabNames.onboarding}
                  />
                </div>
                <div className="onb__page__tabs__right">
                  <div className="onb__page__tabs__right__iW">
                    <div className="onb__page__tabs__right__additional">
                      <ul className="onb__page__tabs__right__additional__iW">
                        <li>
                          <a href="#">
                            Request Sent<span>(2)</span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            Offer Rejected<span>(32)</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="onb__page__pagination">
                      <nav aria-label="Page navigation example">
                        <ul className="pagination">
                          <li className="page-item disabled">
                            <a
                              className="page-link"
                              href="#"
                              tabIndex={-1}
                              aria-disabled="true"
                            >
                              Previous
                            </a>
                          </li>
                          <li className="page-item active">
                            <a className="page-link" href="#">
                              1
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              2
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              3
                            </a>
                          </li>
                          <li className="page-item disabled">
                            <a
                              className="page-link"
                              href="#"
                              tabIndex={-1}
                              aria-disabled="true"
                            >
                              Next
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="onb__page__container shortlisted"
          style={{ display: "none" }}
        >
          <div className="onb__page__container__iW">
            <div className="onb__page__shortlisted">
              <div className="onb__page__shortlisted__iW">
                <div className="onb__page__shortlisted__description">
                  Shortlisted
                  <br />
                  <span>({allShortLIst.length})</span>
                </div>
                <div className="onb__page__shortlisted__list">
                  <div className="onb__page__shortlisted__list__iW">
                    {/* Shortlist */}
                    {allShortLIst}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Search */}
        <div className="onb__page__container top_bottom_gap">
          {/* {allOnBoadingListSearch} */}

          <OnboardingItems viewType="card" layoutType="group">
            {/* {allMembers} */}

            {allOnBoadingListSearch.map((data, i) => {
              return (
                <OnboardingItems.ItemGroup
                  key={i}
                  title={data?.jobTitleName}
                  count={data?.members.length}
                >
                  {data.members.map((member, i) => {
                    const memberData = { ...member };
                    console.log(memberData);
                    return (
                      <OnboardingItems.Item
                        key={i}
                        data={memberData}
                        hasActionPermission={true}
                        pageType="onboarding"
                        onActionClick={actionHandler}
                      />
                    );
                  })}
                </OnboardingItems.ItemGroup>
              );
            })}
          </OnboardingItems>
        </div>
      </Container>

      {/* Invite */}
      <React.Fragment>
        <Modal
          show={showAddToProjectDialog}
          onClose={() => setShowAddToProjectDialog(false)}
        >
          <div className="content">
            <div class="menu__content" style={{ "min-height": "90vh" }}>
              <div class="menu__content__panel" style={{ width: "45%" }}>
                <div hidden={true}>
                  <span
                    style={{
                      float: "right",
                      width: "0px",
                      cursor: "pointer",
                      marginTop: "5px",
                    }}
                    onClick={() => setShowAddToProjectDialog(false)}
                  >
                    <img src={close_icon} height={30} width={30} />
                  </span>
                </div>
                <div
                  class="bigicon"
                  style={{ marginLeft: "calc(80%/2)", marginTop: "-33px" }}
                >
                  {" "}
                  <i
                    class="icon icon-lp-add-user-1 icon-35"
                    style={{
                      border: "0px",
                      borderRadius: "30px",
                      color: "#E89F7A",
                    }}
                  ></i>
                </div>
                <div
                  class="menu__content__panel__text"
                  style={{ marginTop: "0px" }}
                >
                  <h4
                    style={{
                      textAlign: "center",
                      marginBottom: "15px",
                      fontSize: "18px",
                      color: "#245A6C",
                    }}
                  >
                    Invite to Project
                  </h4>
                  <form class="row g-3">
                    <div class="col-md-12">
                      <input
                        class="form-control"
                        type="text"
                        id="prject_name"
                        name="project_name"
                        placeholder="Name your project"
                        aria-label="project Name"
                        // onChange={(e) => setProjectName(e.target.value)}
                        value={_PROJECT_.projectName}
                        readOnly
                      />
                    </div>
                    {/* <div class="col-md-12">
                      <select
                        className="form-select"
                        aria-label="Select JobTitle"
                        value={jobTitle}
                        onChange={(e) => setJobTitle(e.target.value)}
                      >
                        <option selected value={0}>
                          - Select Person's Role in Project -
                        </option>
                        {jobTitleOptionList}
                      </select>
                    </div> */}
                  </form>
                  <form class="row g-3">
                    <div class="col-md-12">
                      <textarea
                        style={{
                          color: "#6A7282",
                          width: "100%",
                          marginTop: "15px",
                          height: "200px",
                        }}
                        placeholder="You can add your message here."
                        onChange={(e) => setSenderNote(e.target.value)}
                        value={senderNote}
                      ></textarea>
                    </div>
                  </form>
                </div>
                <div
                  class="menu__content__panel__btns"
                  style={{ justifyContent: "center" }}
                >
                  <button
                    type="button"
                    class="btn btn-secondary"
                    onClick={() => setShowAddToProjectDialog(false)}
                  >
                    Cancel
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() =>
                      SendInvitaion(selectMember, selectMember?.projectMemberId)
                    }
                  >
                    Send Invitation
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>

      {/* Genarate Contract */}

      <ContractRequestModal
        member={selectMember}
        project={_PROJECT_}
        projectName={_PROJECT_?.projectName || ""}
        projectId={_PROJECT_?.projectId || ""}
        show={showContractGenModal}
        onClose={() => setShowsContractGenModal(false)}
        afterSubmited={() => getOnBoardingList(_PROJECT_.projectId)}
        // onGenerateContract={() => genarateContractRequest(9)}
      />
    </>
  );
};

export default OnboardingList;
