import React from "react";

import { IconFavorite } from "../icons";
import { StyledFavoriteButton } from "./styled";

const FavoriteButton = (props) => {
  const { isFavorite, ...otherProps } = props;

  return (
    <StyledFavoriteButton isFavorite={isFavorite} {...otherProps}>
      <IconFavorite />
    </StyledFavoriteButton>
  );
};

export default FavoriteButton;
