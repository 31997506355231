import styled from "styled-components";
import { StyledBaseButton } from "../../../styled/Common/Buttons";

export const StyledFavoriteButton = styled(StyledBaseButton)`
  --fv-size: ${({ theme }) => theme.sizes(6)};
  --fv-icon-size: ${({ theme }) => theme.sizes(8)};
  --fv-bg-color: ${({ isFavorite, theme }) => {
    return isFavorite ? theme.colors.primary(50) : theme.colors.primary(20);
  }};

  &:hover {
    opacity: 0.7;
  }

  font-size: var(--fv-icon-size);
  color: var(--fv-bg-color);
  background-color: var(--fv-bg-color);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--fv-size);
  height: var(--fv-size);
  border-radius: ${({ theme }) => theme.rounded("full")};
`;
