import React from "react";
import { useRouteLoaderData } from "react-router-dom";

const withRouteLoadData = (id, WrapperComponent) => {
  return (props) => {
    // Hooks
    const routeData = useRouteLoaderData(id);

    return <WrapperComponent {...props} routerLoadData={routeData} />;
  };
};

export default withRouteLoadData;
