import React from "react";
import { ColorBtnItem, ColorButton, ColorPopoverPanel } from "./styled";

const _defaultColors = [
  "#E4F5F2",
  "#C0D0C9",
  "#94BDC0",
  "#67A2AE",
  "#41656E",
  "#29527A",
  "#5082A5",
  "#6AA2B9",
  "#97C3CF",
  "#F68A33",
  "#F0D373",
  "#F6E68B",
  "#FFF2CC",
  "#EFEFEB",
  "#E5DAAE",
  "#B8B8AA",
  "#DCE5D9",
  "#C2CFBD",
  "#92A48D",
  "#778971",
  "#384C3F",
  "#2F4157",
  "#5F7B8C",
  "#7F95A1",
  "#AABCCB",
  "#D4DEE3",
  "#E9EFF2",
  "#EFEFEF",
  "#C7635F",
  "#F36A6C",
  "#E89692",
  "#E7D5CD",
  "#F6C8A8",
  "#DF886D",
];

const ColorPickerContext = React.createContext({
  currentColor: _defaultColors[0],
  colors: _defaultColors,
  colorBtnElement: null,
  setColorBtnElement: (el) => {},
  setColors: (colors) => {},
  setCurrentColor: (color) => {},
  onChange: (color) => {},
});

const useColor = () => {
  return React.useContext(ColorPickerContext);
};

const ColorPickerProvider = ({
  children,
  defaultColor,
  defaultColorPalette,
  onChange,
}) => {
  const [currentColor, setCurrentColor] = React.useState(null);
  const [colors, setColors] = React.useState(defaultColorPalette);
  const [colorBtnElement, setColorBtnElement] = React.useState(null);

  React.useEffect(() => {
    setCurrentColor(defaultColor);
  }, [defaultColor]);

  const values = {
    currentColor,
    colors,
    colorBtnElement,
    setColors,
    setCurrentColor,
    setColorBtnElement,
    onChange,
  };

  return (
    <ColorPickerContext.Provider value={values}>
      {children}
    </ColorPickerContext.Provider>
  );
};

const CustomColorPicker = (props) => {
  const {
    value = _defaultColors[0],
    colorPalette = _defaultColors,
    onChange,
    ...otherProps
  } = props;

  return (
    <ColorPickerProvider
      defaultColor={value}
      defaultColorPalette={colorPalette}
      onChange={onChange}
      {...otherProps}
    >
      <ColorPickerButton />
      <ColorPopover />
    </ColorPickerProvider>
  );
};

export default CustomColorPicker;

function ColorPickerButton() {
  const { setColorBtnElement, currentColor } = useColor();

  return (
    <ColorButton
      type="button"
      onClick={(e) => {
        setColorBtnElement(e.currentTarget);
      }}
      $color={currentColor}
    />
  );
}

function ColorPopover() {
  const {
    colorBtnElement,
    colors,
    currentColor,
    setColorBtnElement,
    setCurrentColor,
    onChange,
  } = useColor();

  if (!colorBtnElement) return null;

  return (
    <ColorPopoverPanel
      anchorEl={colorBtnElement}
      appendTo={document.body}
      onOutsideClick={() => setColorBtnElement(null)}
      //   hideOverlay
    >
      <div className="color-popover">
        {colors.map((color, i) => {
          return (
            <ColorBtnItem
              type="button"
              key={i}
              $color={color}
              $isActive={currentColor === color}
              onClick={() => {
                setCurrentColor(color);
                onChange(color);
              }}
            />
          );
        })}
      </div>
    </ColorPopoverPanel>
  );
}
