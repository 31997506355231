import * as React from "react";
import { memo } from "react";
const SvgFloorStart = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 0H8.03225V4.97784H13V6.04447H6.9679V1.06662H3.06451V16H2.00005L2 0ZM6.96775 9.95569H13V11.0221H8.03225V16H6.96779L6.96775 9.95569Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgFloorStart);
export default Memo;
