import styled from "styled-components";

export const DocumentViewerContainer = styled.div`
  height: 100%;
  width: 100%;

  #txViewer {
    font-family: var(--bs-body-font-family);

    #tx-documentViewer {
      font-family: var(--bs-body-font-family);
      font-weight: normal;
    }
  }

  #tx-signature {
    background-color: #245a6c;
  }
`;
