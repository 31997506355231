import React from "react";
import { useTheme } from "styled-components";
import { StyledButton, StyledIconWrapper } from "./styled";
import PropTypes from "prop-types";

const Button = (props) => {
  const theme = useTheme();
  const {
    variant,
    size,
    children,
    customBtnStyles,
    iconSize,
    rounded,
    ...otherProps
  } = props;

  const _children = React.Children.toArray(children);

  const components = React.useMemo(() => {
    return _children.map((child, i) => {
      if (
        React.isValidElement(child) &&
        child.type.displayName === "LeftIcon"
      ) {
        return (
          <div key={i} className="btn__ic btn__ic--left">
            {child}
          </div>
        );
      }

      return child;
    });
  }, [_children]);

  const buttonTheme = React.useMemo(() => {
    switch (variant) {
      case "primary":
        return {
          bgColor: theme.colors.primary(),
          bgColorHover: theme.colors.primary(80),
          bgColorDisabled: theme.colors.primary(60),
          textColor: theme.colors.white(),
          textColorHover: theme.colors.white(),
          textColorDisabled: theme.colors.white(),
        };

      case "primary-light":
        return {
          bgColor: theme.colors.primary_light(10),
          bgColorHover: theme.colors.primary_light(20),
          bgColorDisabled: theme.colors.primary_light(10),
          textColor: theme.colors.primary_light(80),
          textColorHover: theme.colors.primary_light(90),
          textColorDisabled: theme.colors.primary_light(40),
        };

      case "custom": {
        return customBtnStyles;
      }

      default:
        return {
          bgColor: theme.colors.white(),
          bgColorHover: theme.colors.white(),
          bgColorDisabled: theme.colors.white(),
          textColor: theme.colors.black(),
          textColorHover: theme.colors.black(),
          textColorDisabled: theme.colors.black(),
        };
    }
  }, [variant, theme, customBtnStyles]);

  const {
    bgColor,
    bgColorDisabled,
    bgColorHover,
    textColor,
    textColorDisabled,
    textColorHover,
  } = buttonTheme;

  return (
    <StyledButton
      bgColor={bgColor}
      bgColorDisabled={bgColorDisabled}
      bgColorHover={bgColorHover}
      textColor={textColor}
      textColorDisabled={textColorDisabled}
      textColorHover={textColorHover}
      size={size}
      iconSize={iconSize}
      rounded={rounded}
      {...otherProps}
    >
      {components}

      {/* {children} */}
    </StyledButton>
  );
};

Button.LeftIcon = ({ children }) => {
  return <StyledIconWrapper>{children}</StyledIconWrapper>;
};

Button.LeftIcon.displayName = "LeftIcon";

Button.propTypes = {
  variant: PropTypes.oneOf(["primary", "primary-light", "custom"]),
  size: PropTypes.oneOf(["sm", "lg"]),
};

export default Button;
//  React.memo();
