import * as React from "react";
import { memo } from "react";
const SvgLegal = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.44561 6.90356C4.9686 6.67888 4.69432 6.11515 7.5469 6.06178V13.5614H5.6085V14.3944H10.3919V13.5614H8.45345L8.45352 6.06145C11.3066 6.11481 11.0323 6.67855 12.5551 6.90322C14.1543 7.13943 14.9542 5.40687 14.5986 5.24912C14.243 5.09175 13.8657 6.21403 12.8881 5.97776C11.9105 5.74155 11.555 4.55993 9.64486 4.55993H8.45332V3.66318L9.18853 2.9911L8.00018 1L6.81175 2.9916L7.54696 3.66368V4.56083H6.35582C4.44546 4.56083 4.09017 5.74244 3.11262 5.97865C2.13465 6.21533 1.7573 5.09304 1.40179 5.25002C1.04627 5.40739 1.84637 7.13985 3.44561 6.90356Z"
      fill="currentColor"
    />
    <path d="M4.75495 14.869H11.2451V16H4.75495V14.869Z" fill="currentColor" />
    <path
      d="M5.6085 10.9429H4.98839L2.96311 7.36303C2.89739 7.2467 2.71105 7.2467 2.64489 7.36303L0.61961 10.9429H0C0.318225 12.2248 1.45296 13.1735 2.80394 13.1735C4.15497 13.1735 5.28952 12.2249 5.6085 10.9429ZM1.04494 10.9429L2.80359 7.83346L4.56223 10.9429H1.04494Z"
      fill="currentColor"
    />
    <path
      d="M15.3799 10.9429L13.3546 7.36303C13.2889 7.2467 13.1022 7.2467 13.0364 7.36303L11.0116 10.9429H10.3911C10.7097 12.2248 11.8441 13.1735 13.1956 13.1735C14.547 13.1735 15.6813 12.2249 16 10.9429H15.3799ZM11.4365 10.9429L13.1951 7.83346L14.9538 10.9429H11.4365Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgLegal);
export default Memo;
