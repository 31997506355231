import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.9046 3.25785C15.8446 1.90385 15.3626 1.03984 14.1526 0.055841C14.0406 -0.0321601 13.8786 -0.0141668 13.7906 0.0958316C13.7406 0.159833 13.7226 0.241841 13.7426 0.319832C13.9146 1.00585 13.6866 1.47384 13.0386 1.73384C11.7126 2.25985 12.1146 4.15187 12.2246 4.57787L11.9146 5.46987L11.0566 8.3859L10.7546 8.2139C10.1046 7.84789 9.43459 7.47189 9.12458 6.90189C8.57458 5.60388 9.27259 5.13587 10.0806 4.59187C10.6266 4.22587 11.1886 3.84786 11.2446 3.18786C11.3106 2.44186 10.0246 1.33985 9.12059 0.957837C5.67656 -0.382163 1.79853 1.32184 0.458527 4.76387C-0.883487 8.2059 0.820529 12.0839 4.26255 13.4259C6.01256 14.1079 7.96858 14.0219 9.65259 13.1899L9.02658 15.328C8.98858 15.4579 9.06059 15.5959 9.18858 15.6439L10.1566 15.986C10.1826 15.996 10.2106 16 10.2406 16C10.3446 16.002 10.4386 15.938 10.4786 15.84L14.3346 6.32388L14.6406 5.44387C15.2066 5.09987 15.9666 4.63787 15.9046 3.25785ZM9.85659 12.4959C6.92657 14.2419 3.13654 13.2839 1.38853 10.3539C-0.357482 7.42589 0.60052 3.63386 3.53055 1.88785C4.48655 1.31785 5.58056 1.01585 6.69457 1.01585C7.45658 1.01785 8.21458 1.15984 8.92458 1.43785C10.0706 1.91984 10.7506 2.90785 10.7326 3.14385C10.6966 3.55786 10.2786 3.83987 9.79259 4.17186C9.01659 4.69187 7.93058 5.41388 8.65458 7.11789L8.66458 7.13789C9.04259 7.84589 9.81859 8.28189 10.5026 8.6679C10.6386 8.74389 10.7766 8.8219 10.9066 8.8999L9.85659 12.4959ZM13.9286 5.89988L12.8066 5.50987L12.4826 5.40188L12.6546 4.91987L13.7786 5.31187L14.1006 5.41788L13.9286 5.89988Z"
      fill="currentColor"
    />
    <path
      d="M9.8106 11.2659C9.8106 11.9599 9.24858 12.5219 8.55458 12.5219C8.40058 12.5219 8.24658 12.4919 8.10058 12.4359C7.45658 12.1819 7.13657 11.4559 7.38657 10.8099V10.8059C7.57657 10.3259 8.04058 10.0099 8.55659 10.0099C9.24858 10.0099 9.8106 10.5719 9.8106 11.2659Z"
      fill="currentColor"
    />
    <path
      d="M5.79457 11.4339C5.60857 11.9159 5.14256 12.2339 4.62655 12.2339C4.12855 12.2299 3.68055 11.9359 3.47854 11.4819C3.34455 11.1759 3.33654 10.8299 3.45855 10.5179C3.71055 9.87391 4.43855 9.55591 5.08456 9.80991C5.72856 10.0619 6.04657 10.7879 5.79457 11.4339Z"
      fill="currentColor"
    />
    <path
      d="M3.66655 7.8299C3.66855 8.5239 3.10654 9.0879 2.41253 9.0899C2.25654 9.0899 2.10253 9.0619 1.95654 9.00391C1.64654 8.8839 1.39653 8.64389 1.26252 8.3399C0.984532 7.7039 1.27253 6.96389 1.90854 6.68388C2.54254 6.40589 3.28455 6.69588 3.56254 7.32989C3.63055 7.48789 3.66655 7.65789 3.66655 7.8299Z"
      fill="currentColor"
    />
    <path
      d="M4.13255 5.40987C3.82855 5.54387 3.48255 5.55188 3.17255 5.42988C2.86254 5.30987 2.61454 5.07187 2.48054 4.76788C2.20253 4.13386 2.48853 3.39385 3.12255 3.11386C3.75655 2.83585 4.49656 3.12386 4.77655 3.75587C5.05456 4.38987 4.76655 5.12988 4.13255 5.40987Z"
      fill="currentColor"
    />
    <path
      d="M8.29858 3.00186C8.17658 3.30986 7.93857 3.55587 7.63458 3.68786C7.47657 3.75786 7.30457 3.79586 7.13057 3.79786C6.97657 3.79586 6.82457 3.76787 6.68057 3.71186C6.03856 3.46186 5.72057 2.73786 5.97057 2.09585C6.22057 1.45385 6.94457 1.13384 7.58657 1.38384C8.23058 1.63384 8.54858 2.35785 8.29858 3.00186Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
