import * as React from "react";
import { memo } from "react";
const SvgSave = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1178_2476)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5778 4.14471V14.5777H12.4444V8.88883C12.4444 8.39792 12.0465 7.99995 11.5556 7.99995H4.44444C3.95353 7.99995 3.55556 8.39792 3.55556 8.88883V14.5777H1.42222V1.42221H11.8553L14.5778 4.14471ZM4.97778 14.5777H11.0222V9.42216H4.97778V14.5777ZM3.55556 15.9999H0.888889C0.39797 15.9999 0 15.6019 0 15.111V0.888883C0 0.397968 0.39797 0 0.888889 0H11.929C12.2591 0 12.5756 0.131134 12.809 0.364472L15.6355 3.19112C15.8689 3.42445 16 3.741 16 4.07104V15.1111C16 15.602 15.602 16 15.1111 16L3.55556 15.9999ZM5.68889 10.9333C5.68889 10.6878 5.88785 10.4888 6.13333 10.4888H9.86667C10.1121 10.4888 10.3111 10.6878 10.3111 10.9333C10.3111 11.1787 10.1121 11.3777 9.86667 11.3777H6.13333C5.88785 11.3777 5.68889 11.1787 5.68889 10.9333ZM6.13333 12.6221C5.88785 12.6221 5.68889 12.8211 5.68889 13.0666C5.68889 13.3121 5.88785 13.511 6.13333 13.511H9.86667C10.1121 13.511 10.3111 13.3121 10.3111 13.0666C10.3111 12.8211 10.1121 12.6221 9.86667 12.6221H6.13333Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1178_2476">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgSave);
export default Memo;
