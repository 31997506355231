import React from "react";
import {
  useRouteLoaderData,
  useLocation,
  useParams,
  useSearchParams,
  useNavigation,
} from "react-router-dom";

const withRouter = (WrapperComponent, { routeId = "" }) => {
  return (props) => {
    // Hooks
    const routeData = useRouteLoaderData(routeId);
    const location = useLocation();
    const searchParams = useSearchParams();
    const params = useParams();
    const navigation = useNavigation();

    return (
      <WrapperComponent
        {...props}
        routerLoadData={routeData || null}
        location={location}
        searchParams={searchParams}
        navigation={navigation}
        params={params}
      />
    );
  };
};

export default withRouter;
