import React from "react";
import * as CellStyled from "./styled";

const TwoColumnCell = ({ children, ...rest }) => {
  return (
    <CellStyled.TwoColumnCell {...rest}>{children}</CellStyled.TwoColumnCell>
  );
};

TwoColumnCell.Left = function Left({ children, ...rest }) {
  return (
    <div className="left" {...rest}>
      {children}
    </div>
  );
};

TwoColumnCell.Right = function Right({ children }) {
  return <div className="right">{children}</div>;
};

TwoColumnCell.TextContent = function TextContent({ children }) {
  return <div className="text-content">{children}</div>;
};

TwoColumnCell.IconContent = function IconContent({ children }) {
  return <div className="icon-content">{children}</div>;
};

export default TwoColumnCell;
