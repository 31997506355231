export const TEMPLATE_PERMISSION_MODULES = {
  AllTemplates: {
    key: "all-templates",
    actions: {
      CreateTemplate: {
        key: "create_new_template",
      },
    },
  },
};
