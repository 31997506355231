import styled from "styled-components";
import { fontStyle } from "../../../../../../../../styled/utility";

export const ItemRowWrapper = styled.div`
  display: grid;
  gap: var(--layout-spacing-16);
  grid-template-rows: 1fr;

  .item__list {
    display: grid;
    gap: var(--layout-spacing-8);
    grid-template-rows: 0fr;
  }

  .item__action {
    button {
      outline: none;
      padding: var(--layout-spacing-8);
      border-radius: var(--layout-border-radius-4);
      border: 1px dashed var(--color-accent-300, #56b5bc);
      background: var(--color-accent-50, #eef8f8);
      ${fontStyle("x-small-medium")};
      font-weight: 500;
      color: var(--font-color-shade-accent);
      display: flex;
      align-items: center;
      gap: var(--layout-spacing-8);
      justify-content: center;
      width: 100%;

      &:hover {
        opacity: 0.6;
      }

      .icon__button__element {
        --icon-button-icon-color: var(--font-color-shade-accent);
      }
    }
  }
`;

export const ItemRow = styled.div`
  border: 1px dashed var(--color-neutral-50);
  border-radius: var(--layout-border-radius-4);
  padding: var(--layout-spacing-8) var(--layout-spacing-16);
  justify-content: space-between;
  display: flex;
  align-items: center;
  background-color: var(--color-neutral-10);

  .split {
    margin: 0 var(--layout-spacing-16);
    padding-block: var(--layout-spacing-8);
    width: 0.5px;
    background-color: var(--color-neutral-100);
  }

  input[type="text"] {
    border: none;
    outline: none;
    ${fontStyle("small-regular")};
    color: var(--font-default-black);
    background-color: transparent;
    height: var(--layout-size-24);

    &::placeholder {
      color: #c1c4ce;
    }
  }

  .icon__button__element {
    --icon-button-icon-color: var(--font-tint-04);
  }

  .inputs {
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    justify-content: flex-start;
  }

  .controller {
    display: flex;
    align-items: center;
    gap: var(--layout-spacing-4);

    .icon__button__element {
      &:hover {
        opacity: 0.6;
      }
    }
  }
`;

export const ColorButton = styled.button`
  background-color: #ccc;
  width: var(--layout-size-32);
  height: var(--layout-size-32);
  border-radius: 999px;
  outline: none;
  border: none;
`;
