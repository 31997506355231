import * as React from "react";
import { memo } from "react";
const Svg02 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.19948 6.39954H5.5996V8.79937H3.19977V10.3993H5.5996V12.7991H7.19948V10.3993H9.59931V8.79937H7.19948V6.39954Z"
      fill="currentColor"
    />
    <path
      d="M1.59989 16H11.1992C11.6234 15.9997 12.0302 15.831 12.3301 15.5311C12.6301 15.2311 12.7988 14.8243 12.7991 14.4001V4.79966C12.7988 4.37563 12.6303 3.96905 12.3306 3.66911C12.0308 3.36917 11.6244 3.20038 11.2003 3.19977H1.59989C1.17566 3.20007 0.768903 3.36873 0.468932 3.6687C0.168961 3.96867 0.00030443 4.37543 0 4.79966V14.399C-1.09362e-07 14.8234 0.168522 15.2304 0.468526 15.5307C0.768529 15.8309 1.17546 15.9997 1.59989 16ZM1.59989 4.79966H11.1992V14.399H1.59989V4.79966Z"
      fill="currentColor"
    />
    <path
      d="M14.399 0H4.79966V1.59989H14.399V11.1992H15.9989V1.59989C15.9985 1.17566 15.8299 0.768903 15.5299 0.468932C15.2299 0.168961 14.8232 0.00030443 14.399 0Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg02);
export default Memo;
