import styled, { css } from "styled-components";

const outlinedStyles = css`
  ${({ variant }) => `
    background-color: transparent;
    color: var(--button-${variant}-active);

    &:hover {
      background-color: var(--button-${variant}-hover-alt);
      color: var(--button-${variant}-hover);
    }

    &:focus {
      background-color: var(--button-${variant}-click);
      color: var(--button-${variant}-hover);
    }
  `}
`;

const filledStyle = css`
  color: var(--button-text-white);

  ${({ variant, $shade, $hoverShade }) => {
    if ($shade && !!Number.parseInt($shade)) {
      $hoverShade = $hoverShade ? $hoverShade : Number.parseInt($shade) * 2;

      return `
        background-color: var(--color-${variant}-${$shade});
      
        &:hover {
          background-color:var(--color-${variant}-${$hoverShade});
        }
    `;
    }

    return `
      background-color: var(--button-${variant}-active);

      &:hover {
        background-color:var(--button-${variant}-hover);
      }
    `;
  }}
`;

export const ButtonElement = styled.button`
  outline: none;

  border-radius: ${({ rounded }) =>
    rounded
      ? `var(--button-border-radius-xxxl)`
      : `var(--button-border-radius-sm)`};
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: var(--animate-1);
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  gap: var(--btn-spacing-8);

  .button__ic {
    --icon-font-size: var(--layout-size-12);
    --icon-size: var(--layout-size-12);
  }

  ${({ variant, $shade, $hoverShade }) => {
    if ($shade && !!Number.parseInt($shade)) {
      $hoverShade = $hoverShade ? $hoverShade : Number.parseInt($shade) * 2;

      return `
        border-width: var(--button-border-${variant}-active-width, 1px);
        border-style: var(--button-border-${variant}-active-style);
        border-color: var(--color-${variant}-${$shade});

        &:hover {
          border-color: var(--color-${variant}-${$hoverShade});
        }
      `;
    }

    return `
      border-width: var(--button-border-${variant}-active-width, 1px);
      border-color: var(--button-border-${variant}-active-color);
      border-style: var(--button-border-${variant}-active-style);

      &:hover {
        border-width: var(--button-border-${variant}-hover-width, 1px);
        border-color: var(--button-border-${variant}-hover-color);
        border-style: var(--button-border-${variant}-hover-style);
      }
    `;
  }};

  ${({ btnSize }) => {
    if (btnSize === "sm") {
      return `
        font-size: var(--small-medium-font-size);
        font-family: var(--small-medium-font-family);
        font-weight:var(--small-medium-font-weight);
        line-height:var(--small-medium-line-height);
        padding: 0 var(--btn-spacing-12);
        height: var(--layout-size-32); 
      `;
    }

    if (btnSize === "md") {
      return `
        font-size: var(--base-medium-font-size);
        font-family: var(--base-medium-font-family);
        font-weight:var(--base-medium-font-weight);
        line-height:var(--base-medium-line-height);
        padding: 0 var(--btn-spacing-12);
        height: var(--layout-size-40);
      `;
    }

    if (btnSize === "lg") {
      return `
        font-size: var(--large-medium-font-size);
        font-family: var(--large-medium-font-family);
        font-weight:var(--large-medium-font-weight);
        line-height:var(--large-medium-line-height);
        padding: 0 var(--btn-spacing-16);
        height: var(--layout-size-48);

        .button__ic {
          --icon-font-size: var(--layout-size-16);
          --icon-size: var(--layout-size-16);
        }
      `;
    }
  }};

  ${({ isOutlined = false, isFilled = true }) => {
    if (isOutlined) {
      return outlinedStyles;
    }

    if (isFilled) {
      return filledStyle;
    }
  }};

  &:disabled {
    border-color: var(--button-disabled);
    color: var(--button-text-white);
    background-color: var(--button-disabled);
  }
`;
