import * as React from "react";
import { memo } from "react";
const Svg02 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Line/Deliverables/02">
      <g id="icon">
        <path
          d="M34.4444 0.5H5.55556C4.08266 0.501729 2.67059 1.0876 1.62909 2.12909C0.5876 3.17059 0.00172908 4.58266 0 6.05556V34.9444C0.0015471 36.4174 0.58736 37.8296 1.62889 38.8711C2.67043 39.9126 4.08261 40.4985 5.55556 40.5H21.1111C21.4058 40.5 21.6884 40.3829 21.8968 40.1746C22.1052 39.9662 22.2222 39.6836 22.2222 39.3889C22.2222 39.0942 22.1052 38.8116 21.8968 38.6032C21.6884 38.3948 21.4058 38.2778 21.1111 38.2778H5.55556C4.6718 38.2768 3.82451 37.9253 3.1996 37.3004C2.57469 36.6755 2.22319 35.8282 2.22222 34.9444V6.05556C2.22319 5.1718 2.57469 4.32451 3.1996 3.6996C3.82451 3.07469 4.6718 2.72319 5.55556 2.72222H13.3333V14.9444C13.3332 15.0904 13.3619 15.2349 13.4177 15.3698C13.4735 15.5046 13.5554 15.6271 13.6586 15.7303C13.7618 15.8335 13.8843 15.9153 14.0191 15.9711C14.154 16.027 14.2985 16.0556 14.4444 16.0556H25.5556C25.7015 16.0556 25.846 16.027 25.9809 15.9711C26.1157 15.9153 26.2382 15.8335 26.3414 15.7303C26.4446 15.6271 26.5265 15.5046 26.5823 15.3698C26.6381 15.2349 26.6668 15.0904 26.6667 14.9444V2.72222H34.4444C35.3282 2.72319 36.1755 3.07469 36.8004 3.6996C37.4253 4.32451 37.7768 5.1718 37.7778 6.05556V23.8333C37.7778 24.128 37.8948 24.4106 38.1032 24.619C38.3116 24.8274 38.5942 24.9444 38.8889 24.9444C39.1836 24.9444 39.4662 24.8274 39.6746 24.619C39.8829 24.4106 40 24.128 40 23.8333V6.05556C39.9983 4.58266 39.4124 3.17059 38.3709 2.12909C37.3294 1.0876 35.9173 0.501729 34.4444 0.5ZM24.4444 13.8333H15.5556V2.72222H24.4444V13.8333Z"
          fill="currentColor"
        />
        <path
          d="M39.6731 35.7314L36.3412 39.0634C36.1316 39.2658 35.8509 39.3778 35.5596 39.3752C35.2683 39.3727 34.9896 39.2559 34.7836 39.0498C34.5776 38.8438 34.4607 38.5652 34.4582 38.2738C34.4557 37.9825 34.5676 37.7018 34.77 37.4923L36.2067 36.0556H27.7778C27.4831 36.0556 27.2005 35.9385 26.9921 35.7301C26.7837 35.5217 26.6667 35.2391 26.6667 34.9444C26.6667 34.6498 26.7837 34.3671 26.9921 34.1588C27.2005 33.9504 27.4831 33.8333 27.7778 33.8333H36.2067L34.77 32.3967C34.5676 32.1872 34.4556 31.9065 34.4582 31.6152C34.4607 31.3238 34.5776 31.0451 34.7836 30.8391C34.9896 30.6331 35.2682 30.5163 35.5596 30.5137C35.8509 30.5112 36.1316 30.6232 36.3411 30.8256L39.6731 34.1576C39.8815 34.3665 39.9985 34.6495 39.9985 34.9445C39.9985 35.2396 39.8815 35.5225 39.6731 35.7314Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
const Memo = memo(Svg02);
export default Memo;
