import styled from "styled-components";
import BaseFlexModal from "../../../../../../../common/components/UI/Modal/BaseFlexModal";

export const Modal = styled(BaseFlexModal)`
  --modal-max-width: 650px;
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
