import styled from "styled-components";
import {
  fontStyle,
  layoutBoxShadow,
} from "../../../../../../../../styled/utility";
import { StyledBoxButton } from "../../../../../../../../styled/Common/Buttons";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: var(--layout-border-radius-8);
  background: var(--color-white);
  /* padding: var(--layout-spacing-16) var(--layout-spacing-12); */
  /* ${layoutBoxShadow("primary-10")}; */
  box-shadow: -3px 8px 15px 0px rgba(45, 89, 102, 0.08);
  /* gap: var(--layout-spacing-12); */

  /* justify-content: flex-start; */
  position: relative;
  height: ${({ cardW }) => (cardW ? `${Math.floor(cardW + 2)}px` : "auto")};

  .card__header,
  .card__contents,
  .card__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .card__header {
    pointer-events: none;
    margin: var(--layout-spacing-16) var(--layout-spacing-12)
      var(--layout-spacing-4);

    .option__menu {
      pointer-events: auto;
      margin-right: var(--layout-spacing-minus-8);
    }
  }

  .card__contents,
  .card__footer {
    justify-content: center;
  }

  .card__contents {
    cursor: ${({ isClickable }) => (isClickable ? `pointer` : `default`)};
    padding: var(--layout-spacing-8) var(--layout-spacing-12)
      var(--layout-spacing-8);
    flex: 1;
    align-items: flex-start;

    &__title {
      font-weight: 500;
      /* white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; */

      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .start {
      width: 100%;
      height: var(--layout-size-24);
      display: flex;
      align-items: center;
      justify-content: center;

      .doc_icon {
        --icon-button-size: var(--layout-size-24);
        --icon-button-icon-size: var(--layout-size-24);
        --icon-button-icon-color: var(--font-tint-01);
      }
    }
  }

  .card__footer {
    visibility: hidden;
    opacity: 0;
    transition: var(--animate-1);
    height: var(--layout-size-16);
  }

  &:hover {
    .card__footer {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const CardStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--layout-spacing-4) var(--layout-spacing-12);
  max-width: 100px;
  align-self: flex-end;
  border-radius: var(--layout-border-radius-12);
  background: var(--color-neutral-20);

  .label {
    ${fontStyle("x-small-regular")};
    color: var(--color-neutral-80, #969bac);
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const CardActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--layout-spacing-12);
`;

export const CardActionButton = styled(StyledBoxButton)`
  color: var(--color-neutral-50);
  width: var(--layout-size-16);
  height: var(--layout-size-16);

  .icon {
    --icon-size: var(--layout-size-16);
    --icon-font-size: var(--layout-size-16);
  }

  &:hover {
    color: var(--color-neutral-70);
  }
`;

export const CardInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--layout-spacing-4);
  align-items: center;
  flex: 1;

  .doc_icon {
        --icon-button-size: var(--layout-size-20);
        --icon-button-icon-size: var(--layout-size-20);
        --icon-button-icon-color: var(--font-tint-01);
      }
`;
