import React from "react";
import { format, isToday, isWeekend } from "date-fns";

import { useScheduleContext } from "../../../../context/ScheduleContext";
import * as Styled from "./styled";

const DayItem = React.forwardRef((props, ref) => {
  const {
    isHoliday = false,
    isToday = false,
    date,
    isWeekLastDate,
    ...otherProps
  } = props;
  return (
    <Styled.DateItem
      ref={ref}
      $isToday={isToday}
      $isHoliday={isHoliday}
      $isWeekend={isWeekend(date)}
      isWeekLastDate={isWeekLastDate}
      {...otherProps}
    >
      {format(date, "dd")}
    </Styled.DateItem>
  );
});

const DateColumn = () => {
  const {
    dates,
    //  handleLoadData, scrollContainer, datesQuery
  } = useScheduleContext();

  const dayItemRef = React.useRef();
  // const topTargetRef = React.useRef();
  // const bottomTargetRef = React.useRef();

  // const getDateData = React.useCallback(
  //   (id) => {
  //     return dates.find((d) => d.dateId === id);
  //   },
  //   [dates]
  // );

  // React.useEffect(() => {
  //   if (dates.length && scrollContainer && !datesQuery.isLoading) {
  //     const topObserve = new IntersectionObserver(
  //       (entries) => {
  //         const entry = entries[0];

  //         if (!entry.isIntersecting) {
  //           return;
  //         }

  //         topTargetRef.current = entry.target;
  //         const id = Number(topTargetRef.current.getAttribute("data-row-id"));
  //         const data = getDateData(id);

  //         const dataParams = {
  //           data,
  //           direction: 0,
  //         };

  //         handleLoadData(dataParams, (params) => {
  //           const size =
  //             params.datesPacketSize >= 14 ? params.datesPacketSize - 7 : 7;

  //           scrollContainer.scrollTop = Math.max(size) * 32;
  //         });
  //       },
  //       { threshold: 1, rootMargin: "0px 0px 96% 0px" }
  //     );

  //     // Bottom Loading
  //     const bottomObserve = new IntersectionObserver(
  //       (entries) => {
  //         const entry = entries[0];

  //         if (!entry.isIntersecting) {
  //           return;
  //         }

  //         bottomTargetRef.current = entry.target;
  //         const id = Number(
  //           bottomTargetRef?.current?.getAttribute("data-row-id")
  //         );
  //         const data = getDateData(id);

  //         const dataParams = {
  //           data,
  //           direction: 1,
  //         };

  //         handleLoadData(dataParams, () => {
  //           console.log("done");
  //         });
  //       },
  //       {
  //         threshold: 1,
  //         rootMargin: "-90% 0px 0px 0px",
  //       }
  //     );

  //     !!topTargetRef?.current && topObserve.unobserve(topTargetRef?.current);
  //     !!bottomTargetRef?.current &&
  //       bottomObserve.unobserve(bottomTargetRef?.current);

  //     topObserve.observe(dayItemRef?.current?.firstChild);
  //     bottomObserve.observe(dayItemRef?.current?.lastChild);

  //     return () => {
  //       topObserve.disconnect();
  //       bottomObserve.disconnect();
  //     };
  //   }
  // }, [
  //   dates.length,
  //   datesQuery.isLoading,
  //   getDateData,
  //   handleLoadData,
  //   scrollContainer,
  // ]);

  return (
    <Styled.DateColumn className="date__column" label="&nbsp;">
      <Styled.DateItems ref={dayItemRef} className="dates">
        {dates.map((d, i) => {
          return (
            <DayItem
              className="date"
              key={i}
              date={new Date(d?.date)}
              isToday={isToday(new Date(d?.date))}
              data-row-index={i}
              data-row-id={d?.dateId}
              data-date={format(new Date(d?.date), "yyyy-MM-dd")}
              // isWeekLastDate={i+1 % 7 === 0 ? true : false}
            />
          );
        })}
      </Styled.DateItems>
    </Styled.DateColumn>
  );
};

export default DateColumn;
