import * as React from "react";
import { memo } from "react";
const SvgInvite = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.558 6.70978C13.9433 5.94204 13.0308 5.50163 12.0546 5.50163L7.94191 5.50158C7.72976 5.50158 7.55713 5.67358 7.55713 5.88494C7.55713 6.09631 7.72976 6.26831 7.94191 6.26831H9.8265C9.97951 6.26831 10.1034 6.39199 10.1034 6.54444C10.1034 6.69688 9.97951 6.82057 9.8265 6.82057H6.74837C6.58552 6.82057 6.4467 6.92239 6.39069 7.06536C6.39402 7.06536 6.39714 7.06483 6.40047 7.06483C6.73466 7.06483 7.04616 7.24278 7.21335 7.52918C7.28249 7.64691 7.32276 7.77397 7.33593 7.90172L7.62679 7.73278C7.76975 7.64976 7.93259 7.60578 8.09829 7.60578C8.43247 7.60578 8.74398 7.78345 8.91117 8.06933C8.98058 8.18814 9.02058 8.31589 9.03375 8.44444C9.16305 8.38016 9.30653 8.3462 9.45181 8.3462C9.78626 8.3462 10.0977 8.52388 10.265 8.80976C10.4776 9.17432 10.4154 9.62324 10.1424 9.91716C10.2652 9.99558 10.37 10.1026 10.4456 10.2311C10.5718 10.4471 10.6059 10.699 10.5418 10.9413C10.4776 11.1837 10.3232 11.3861 10.107 11.5113L9.47293 11.88H12.0542C13.1693 11.88 14.1881 11.311 14.7793 10.3569C14.8299 10.2757 14.919 10.2263 15.0149 10.2263H16V6.81452H14.7745C14.6903 6.81377 14.6106 6.77534 14.558 6.70978Z"
      fill="currentColor"
    />
    <path
      d="M6.30629 13.0794L7.61052 12.3228V12.3222L9.82804 11.0335C9.91654 10.9824 9.97955 10.8996 10.0058 10.8001C10.0324 10.7001 10.0185 10.5973 9.96713 10.5088C9.8652 10.3356 9.6182 10.2677 9.44122 10.3713V10.3718L6.80929 11.8998C6.76542 11.9255 6.71741 11.9374 6.66994 11.9374C6.57445 11.9374 6.48182 11.8882 6.43049 11.8003C6.35334 11.6687 6.39823 11.4995 6.53038 11.4227L9.16203 9.89467C9.16257 9.89413 9.16332 9.89413 9.16359 9.89365L9.64719 9.61238C9.83096 9.50594 9.8931 9.27041 9.78627 9.08712C9.68487 8.91383 9.43734 8.84698 9.25982 8.94957L6.10291 10.7835C6.05904 10.8087 6.01103 10.8208 5.96356 10.8208C5.86808 10.8208 5.77545 10.7722 5.72411 10.6843C5.64696 10.5521 5.69158 10.3834 5.82373 10.3066L8.2934 8.87141H8.29442C8.4771 8.76498 8.53903 8.52972 8.43222 8.34663C8.33055 8.17335 8.08309 8.10624 7.90605 8.20908L5.2465 9.75405C5.20263 9.77923 5.15462 9.79133 5.10742 9.79133C5.01194 9.79133 4.91931 9.74275 4.8677 9.6548C4.79055 9.52265 4.83544 9.35397 4.96759 9.27711L6.59522 8.33196C6.59522 8.33196 6.59549 8.33169 6.59576 8.33169C6.77925 8.22445 6.84167 7.98919 6.73484 7.80691C6.63263 7.63207 6.3857 7.56523 6.20915 7.66807L2.65563 9.73195C1.81253 10.222 1.24683 11.0602 1.1037 12.0318C1.09155 12.1151 1.04203 12.1879 0.969237 12.2305L0.000170656 12.7925L1.2773 16L2.48577 15.2984C2.56889 15.2505 2.67082 15.2482 2.75545 15.2932C3.71876 15.8049 4.93705 15.7889 5.87956 15.2415L8.4457 13.751C8.70892 13.5977 8.79849 13.2593 8.64543 12.9971C8.49888 12.7459 8.14426 12.651 7.88986 12.7988L6.58537 13.556C6.5415 13.5818 6.49349 13.5936 6.44602 13.5936C6.35054 13.5936 6.25791 13.5445 6.20657 13.4566C6.12942 13.3254 6.17388 13.156 6.30629 13.0794Z"
      fill="currentColor"
    />
    <path
      d="M0 4.20669L0.969011 4.76975C1.04181 4.81218 1.09132 4.88492 1.10347 4.96799C1.24669 5.93912 1.81238 6.77737 2.65568 7.26814L4.22648 8.17986L5.81323 7.25812C5.81221 7.23985 5.80909 7.22159 5.80909 7.20338C5.80909 6.6876 6.23027 6.26777 6.74789 6.26777H7.0852C7.03203 6.15078 7.00262 6.02121 7.00262 5.8844C7.00262 5.36863 7.4238 4.94905 7.94142 4.94905H8.07922L7.61138 4.67727C7.61111 4.677 7.61085 4.677 7.61036 4.67652L6.30614 3.92008C6.17377 3.84348 6.1291 3.67427 6.20603 3.54267C6.28291 3.41079 6.45275 3.36628 6.58483 3.44293L7.88932 4.19959L7.89115 4.20061C8.0176 4.27362 8.16523 4.29317 8.30819 4.25562C8.45141 4.21807 8.57113 4.1276 8.64522 4.00109C8.71877 3.87484 8.73866 3.72652 8.7007 3.58355C8.66302 3.44059 8.57221 3.3213 8.44549 3.24802L5.8788 1.75623C4.91441 1.19648 3.74647 1.17746 2.75497 1.70481C2.67034 1.75007 2.56863 1.74798 2.48529 1.69993L1.27698 1L0 4.20669Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgInvite);
export default Memo;
