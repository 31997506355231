import styled from "styled-components";
import { fontStyle } from "../../../../../../../../styled/utility";

export const BlockContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--layout-spacing-2, 2px);

    .title{
        ${fontStyle("x-small-regular")};
        color: #949AA6;
    }

    .content{
        ${fontStyle("large-regular")};
        color: #404A5F;
    }
`
