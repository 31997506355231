import * as React from "react";
import { memo } from "react";
const SvgIconDataColor = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4891 6.84018C12.4473 6.84018 13.3841 7.12432 14.1808 7.6567C14.9776 8.18907 15.5986 8.94577 15.9653 9.83109C16.332 10.7164 16.4279 11.6906 16.241 12.6304C16.0541 13.5702 15.5926 14.4335 14.915 15.1111C14.2374 15.7887 13.3741 16.2501 12.4343 16.4371C11.4945 16.624 10.5203 16.5281 9.63501 16.1614C8.7497 15.7947 7.99302 15.1737 7.46064 14.3769C6.92826 13.5802 6.64411 12.6434 6.64411 11.6852C6.64411 10.4002 7.15457 9.16786 8.06318 8.25924C8.97179 7.35063 10.2041 6.84018 11.4891 6.84018ZM11.4001 1.99517C11.4001 1.76841 11.4902 1.55093 11.6505 1.39059C11.8109 1.23026 12.0283 1.14017 12.2551 1.14017H22.5151C22.7419 1.14017 22.9593 1.23026 23.1197 1.39059C23.28 1.55093 23.3701 1.76841 23.3701 1.99517V12.2532C23.3701 12.4799 23.28 12.6974 23.1197 12.8578C22.9593 13.0181 22.7419 13.1082 22.5151 13.1082H18.0841C18.2966 12.1498 18.2913 11.1559 18.0685 10.1998C17.8458 9.24368 17.4113 8.34974 16.7971 7.58391C16.1829 6.81808 15.4047 6.19988 14.5197 5.77486C13.6348 5.34983 12.6658 5.12885 11.6841 5.12818C11.5841 5.12818 11.4941 5.12818 11.3991 5.13418L11.4001 1.99517ZM0.855113 10.8302C0.700885 10.8302 0.549523 10.7885 0.417062 10.7095C0.284602 10.6305 0.175975 10.5172 0.102691 10.3815C0.0294062 10.2458 -0.00580747 10.0928 0.000780548 9.93869C0.00736856 9.78458 0.0555129 9.63509 0.140113 9.50619L6.12511 0.386175C6.2029 0.267535 6.30898 0.170127 6.4338 0.102712C6.55862 0.035298 6.69825 0 6.84011 0C6.98197 0 7.1216 0.035298 7.24642 0.102712C7.37124 0.170127 7.47732 0.267535 7.55511 0.386175L10.7151 5.20118C9.3077 5.41306 8.00728 6.07664 7.00984 7.09194C6.0124 8.10723 5.37199 9.41918 5.18511 10.8302H0.855113Z"
      fill="#EB6161"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4001 1.99517C11.4001 1.88289 11.4221 1.77167 11.4651 1.66794C11.5081 1.5642 11.5712 1.47 11.6505 1.39059C11.7299 1.3112 11.8241 1.24818 11.9278 1.20521C12.0316 1.16225 12.1428 1.14017 12.2551 1.14017H22.5151C22.6274 1.14017 22.7385 1.16225 22.8422 1.20521C22.946 1.24818 23.0403 1.3112 23.1197 1.39059C23.1991 1.47 23.262 1.5642 23.3049 1.66794C23.3479 1.77167 23.3701 1.88289 23.3701 1.99517V12.2532C23.3701 12.4799 23.28 12.6974 23.1197 12.8578C22.9593 13.0181 22.7419 13.1082 22.5151 13.1082H18.0841C18.2966 12.1498 18.2913 11.1559 18.0685 10.1998C17.8458 9.24368 17.4113 8.34974 16.7971 7.58391C16.1829 6.81808 15.4047 6.19988 14.5197 5.77486C13.6348 5.34983 12.6658 5.12885 11.6841 5.12818C11.5841 5.12818 11.4941 5.12818 11.3991 5.13418L11.4001 1.99517Z"
      fill="#FFCA6A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.855113 10.8302C0.700885 10.8302 0.549523 10.7885 0.417062 10.7095C0.284602 10.6305 0.175975 10.5172 0.102691 10.3815C0.0294062 10.2458 -0.00580747 10.0928 0.000780548 9.93869C0.00736856 9.78458 0.0555129 9.63509 0.140113 9.50619L6.12511 0.386175C6.2029 0.267535 6.30898 0.170127 6.4338 0.102712C6.55862 0.035298 6.69825 0 6.84011 0C6.98197 0 7.1216 0.035298 7.24642 0.102712C7.37124 0.170127 7.47732 0.267535 7.55511 0.386175L10.7151 5.20118C9.3077 5.41306 8.00728 6.07664 7.00984 7.09194C6.0124 8.10723 5.37199 9.41918 5.18511 10.8302H0.855113Z"
      fill="#64C7FF"
    />
  </svg>
);
const Memo = memo(SvgIconDataColor);
export default Memo;
