import styled from "styled-components";
import { fontStyle } from "../../../../../../../../styled/utility";

export const Wrapper = styled.div`
  margin-block: var(--layout-spacing-8);
  width: 112px;
  max-width: 109px;

  .selector {
    max-height: var(--layout-size-24);
    background-color: var(--color-accent-300);
    border-radius: var(--layout-border-radius-4);
    display: flex;
    align-items: center;
    padding: var(--layout-spacing-2) 0;
    /* gap: var(--layout-spacing-4); */
    justify-content: space-between;

    .icon__button__element {
      --icon-button-icon-color: var(--color-accent-50);
      &:hover {
        opacity: 0.8;
      }

      &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }

    .month-year-button {
      background-color: transparent;
      outline: none;
      border: none;
      ${fontStyle("x-small-medium")};
      font-weight: 500;
      color: var(--color-accent-50);
      cursor: pointer;
      padding: 0 var(--layout-spacing-2);

      .dashed-line {
        display: inline-block;
        margin: 0 var(--layout-spacing-2);
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }
`;

export const PopoverContainer = styled.div`
  /* max-width: 220px; */
  /* min-width: 220px; */
  border-radius: var(--layout-border-radius-4);
  background-color: var(--layout-background-white);
  padding: var(--layout-spacing-12) 0;
  /* min-height: 120px; */
  margin: 0 var(--layout-spacing-12);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.09);

  .divider {
    border-bottom: 0.031rem solid var(--color-neutral-100);
    margin-top: var(--layout-spacing-12);
  }

  .years {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--layout-spacing-12);

    .icon__button__element {
      --icon-button-icon-color: var(--font-tint-04);

      &:disabled {
        opacity: 0.3;
      }
    }

    .selected-year {
      color: #6a7282;
      ${fontStyle("small-bold")};
      font-weight: 700;
    }
  }

  .months {
    padding-inline: var(--layout-spacing-20);
    padding-block: var(--layout-spacing-8);
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    gap: var(--layout-spacing-12);
    grid-template-columns: repeat(4, 1fr);

    li {
      button {
        width: 100%;
        border: none;
        ${fontStyle("x-small-regular")};
        border-radius: var(--layout-border-radius-4);
        /* color: var(--color-primary-300); */
        color: rgba(64, 74, 95, 0.5);
        font-weight: 700;
        padding: var(--layout-spacing-8);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;

        &.active {
          background-color: var(--color-accent-300);
          color: var(--layout-background-n30);
        }

        &:disabled {
          opacity: 0.3;
          cursor: auto;
          background-color: transparent;
        }

        &:not(.active):not(:disabled):hover {
          background-color: var(--layout-background-n20);
        }
      }
    }
  }

  .month-year-list {
    display: grid;
  }

  .actions {
    margin-top: var(--layout-spacing-8);
    display: flex;
    justify-content: flex-end;
    padding-inline: var(--layout-spacing-20);
  }
`;
