import React from "react";
import { Container } from "./styled";
import Notifications from "./../../../../../../setup/Notification/index";

const NotificationWidget = () => {
  return (
    // <ScrollY $maxHeight={"300px"}>
    <Container className="wd-inner">
      <Notifications />
    </Container>
    // </ScrollY>
  );
};

export default NotificationWidget;
