import styled from "styled-components";
import { fontStyle } from "../../../../../../../../../styled/utility";

export const Wrapper = styled.div.attrs({
  className: "assigner-popover",
})`
  position: relative;
  background-color: var(--layout-background-white);
  border-radius: var(--layout-border-radius-4);
  border: 1px solid #cedade;
  overflow: hidden;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.09);

  .inner {
    display: grid;

    .info-wrp {
      background-color: var(--color-accent-50);
      padding-block: var(--layout-spacing-12);
      display: grid;

      .user {
        padding-inline: var(--layout-spacing-16);
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__start,
        &__end {
          display: flex;
          gap: var(--layout-spacing-8);
          align-items: center;
        }

        &__content {
          flex: 1;
          min-width: 0px;
        }

        &__name,
        &__position {
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &__name {
          ${fontStyle("x-small-regular")};
          color: #404a5f;
          line-height: 1.3;
        }

        &__position {
          ${fontStyle("x-small-regular")};
          color: #aaaeb7;
          font-size: 10px;
          line-height: 1;
        }

        &__end {
          .icon__button__element {
            --icon-button-icon-color: var(--color-primary-75);

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }

      .assigner-status {
        display: grid;
        gap: var(--layout-spacing-4);
        margin-top: var(--layout-spacing-4);

        &__title {
          ${fontStyle("x-small-regular")};
          color: var(--color-info-400);
          padding-inline: var(--layout-size-16);
        }

        &__items {
          display: grid;
          padding-inline: var(--layout-size-16);
          gap: var(--layout-size-8);
          padding-bottom: var(--layout-size-12);
          max-height: 120px;
          overflow-y: auto;
        }

        &__item {
          display: grid;
          padding-left: var(--layout-size-24);
          padding-top: var(--layout-size-12);
          position: relative;

          &::before {
            content: "";
            display: block;
            width: 1px;
            height: 100%;
            position: absolute;
            left: var(--layout-spacing-12);
            top: 0;
            background-color: #779fad;
          }

          .task-title,
          .task-content {
            ${fontStyle("x-small-regular")};
            color: var(--color-accent-300);
          }

          .task-title {
            font-size: 10px;
            line-height: 160%;
          }

          .task-content {
            font-weight: 500;
            line-height: 1.1;
          }
        }
      }
    }

    .calendar-wrp {
      .rdp {
        .assigned-day {
          background-color: var(--color-accent-300);
          color: var(--font-default-white);
          border-radius: 999px;
        }
      }
    }
  }

  .spinner-loader {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
