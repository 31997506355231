import * as React from "react";
import { memo } from "react";
const Svg02 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.183825 7.99988C-0.0664463 7.8706 -0.0622786 7.67835 0.203153 7.56609L15.6378 1.03838C15.8987 0.928138 16.0525 1.06427 15.9835 1.33318L12.5532 14.7114C12.4831 14.9845 12.2366 15.0824 11.9934 14.9239L8.16734 12.4317C7.92823 12.2758 7.59348 12.3324 7.42303 12.5536L5.99521 14.4055C5.82309 14.6286 5.60881 14.5899 5.51716 14.321L4.20767 10.4806C4.1157 10.2109 3.83327 9.88479 3.58801 9.75805L0.183825 7.99988Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg02);
export default Memo;
