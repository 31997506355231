import React from "react";
import { MinimizeButton } from "../../Styled";
import { handlerMinimize } from "../../Store/ChatBaseStore";

const ButtonMinimize = () => {
  return (
    <MinimizeButton onClick={handlerMinimize} data-tooltip-text="Minimize">
      <span></span>
    </MinimizeButton>
  );
};

export default ButtonMinimize;
