import React from "react";
import { toast } from "react-toastify";
import { saveProjectContractRequest } from "../../../../Actions/Project";
import { useProfileMissedFields } from "../../../../hooks/useProfileMissedFields";
import { useAppStore } from "../../../../store/AppStore/AppStore";
import ModalBase from "../../ModalBase";
import Form from "./Form";
import "./style.scss";

const ContractRequestModal = (props) => {
  // Props
  const {
    onClose,
    show,
    projectId,
    member,
    projectName,
    afterSubmited,
    project,
  } = props;

  const jobTitles = useAppStore((state) => state.jobTitles);
  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);
  const { getMissFieldsStatusByUserTenantId } = useProfileMissedFields();
  const [missField, setMissField] = React.useState({
    isMissed: false,
    fields: [],
  });

  const formRef = React.useRef();

  const onSubmitHandler = () => {
    formRef.current.onSubmit();
  };

  console.log("as member ", member);

  const onSubmittedHandler = (values) => {
    const { fee, instructions = "", role, template } = values;

    // Show loading indicator
    showLoader();

    const formValues = {
      projectMemberID: member?.projectMemberId,
      jobTitleId: role,
      contractFee: fee,
      hrInstruction: instructions,
      templateId: template,
      startDate: project?.startDate,
      endDate: project?.endDate,
    };

    saveProjectContractRequest(formValues)
      .then((res) => {
        if (!res.data.isSuccess) {
          hideLoader();
          toast.error(res?.data?.displayMessage);
          return;
        }
        hideLoader();
        // toast.success(res?.data?.displayMessage);
        onClose && onClose();
        afterSubmited();
      })
      .catch((err) => {
        hideLoader();
      });
  };

  const memberUserTenantId = member?.userTenantId;
  const projectRoleId = member?.projectRoleId;

  React.useEffect(() => {
    if (memberUserTenantId) {
      getMissFieldsStatusByUserTenantId(memberUserTenantId)
        .then((res) => {
          if (res) {
            setMissField(res);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [getMissFieldsStatusByUserTenantId, memberUserTenantId]);

  const joinAsTitle = React.useMemo(() => {
    return (
      jobTitles.find((t) => t.jobTitleId === projectRoleId)?.jobTitleName || ""
    );
  }, [jobTitles, projectRoleId]);

  return (
    <ModalBase
      visibility={show}
      onClose={onClose}
      // onBackdropClick={onBackdropClick}
      title={`Contract Request for ${member?.givenName}`}
      subtitle={
        missField.isMissed
          ? `For the Contract, ${member?.givenName} basic profile should be up to date`
          : null
      }
    >
      <div className="pop__onb__hr__container">
        <div className="pop__top">
          <div className="pop__top__content">
            <div className="pop__top__member__wr">
              <div className="pop__top__member__pic">
                <div className="pop__top__member__pic__iw">
                  <img src={member?.profileImage || null} alt="" />
                </div>
                <div className="pop__top__member__pic__ic">
                  <div className="icon icon-lp-profile profile" />
                </div>
              </div>
              <div className="pop__top__member_info">
                <div className="member__name">{member?.givenName}</div>
                {/* <div className="member__position">{member?.jobTitleName}</div> */}
              </div>
            </div>
            <div className="pop__top__info__wr">
              <div className="info__ic">
                <div className="icon icon-lp-masks icon-20 text-orange" />
              </div>
              <div className="info__content">
                <div className="info__content__subtitle">Join as the</div>
                <div className="info__content__title">{joinAsTitle}</div>
              </div>
            </div>
            <div className="pop__top__info__wr">
              <div className="info__ic">
                <div className="icon icon-lp-doc-write icon-20 text-orange" />
              </div>
              <div className="info__content">
                <div className="info__content__subtitle">Project</div>
                <div className="info__content__title">{projectName}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="pop__center" hidden={true}>
          <div className="content__wr">
            <div className="content__wr__title">History</div>
            <div className="history__list__wr">
              <ul className="list__items">
                <li>
                  <div className="item__content">
                    <div className="item__content__date">2022 jan 25</div>
                    <div className="item__content__info">
                      <div className="item__content__info__title">
                        Invite To the Project
                      </div>
                      <div className="item__content__info__desc__text">
                        <span className="assigner">@buddhika</span>
                        invited <span className="txt-highlight">Jabez </span>
                        to the project{" "}
                        <span className="txt-highlight">Negotiators</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="item__content">
                    <div className="item__content__date">2022 jan 25</div>
                    <div className="item__content__info">
                      <div className="item__content__info__title">
                        Invite To the Project
                      </div>
                      <div className="item__content__info__desc__text">
                        <span className="assigner">@buddhika</span>
                        invited <span className="txt-highlight">Jabez </span>
                        to the project{" "}
                        <span className="txt-highlight">Negotiators</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="item__content">
                    <div className="item__content__date">2022 jan 25</div>
                    <div className="item__content__info">
                      <div className="item__content__info__title">
                        Invite To the Project
                      </div>
                      <div className="item__content__info__desc__text">
                        <span className="assigner">@buddhika</span>
                        invited <span className="txt-highlight">Jabez </span>
                        to the project{" "}
                        <span className="txt-highlight">Negotiators</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="pop__bottom">
          <div className="pop__bottom__iw">
            <div>
              <Form
                ref={formRef}
                onSubmittedValues={onSubmittedHandler}
                projectId={projectId}
                member={member}
              />
            </div>

            <div className="buttons__wr">
              <button
                className="btn btn-secondary text-white btn-lg text-white icon icon-lp-cancel btn--with-icon"
                type="button"
                onClick={onClose}
              >
                Cancel
              </button>
              &nbsp;
              <button
                className="btn btn-green-light btn-lg text-white icon icon-lp-done btn--with-icon"
                type="button"
                // disabled={missField.isMissed}
                onClick={onSubmitHandler}
                // onClick={!missField.isMissed ? onSubmitHandler : null}
              >
                Request Generate Contract
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalBase>
  );
};

export default ContractRequestModal;
