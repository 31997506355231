import styled from "styled-components";
import { fontStyle } from "../../../../../../../../../../../styled/utility";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  border-radius: var(--layout-border-radius-8);
  background: var(--color-neutral-10);

  .field__group {
    &.actions {
      margin-top: var(--layout-spacing-16);

      .element {
        display: flex;
        align-items: center;
        gap: var(--layout-spacing-8);
        justify-content: flex-end;

        button {
          width: 100%;

          &.cancel__btn {
            width: 28%;
          }
        }
      }
    }
  }

  .input-row {
    .code-input {
      width: 70px;
      max-width: 100px;
    }
  }
`;
