import * as React from "react";
import { memo } from "react";
const SvgWeather = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.0998 6.0051C14.0623 4.94257 13.2623 4.06759 12.2248 3.90506C12.0498 2.69252 11.0123 1.75501 9.74989 1.75501C8.63739 1.75501 7.68736 2.49252 7.37496 3.49251C7.07496 3.51751 6.78747 3.61752 6.53747 3.76752C6.07496 3.23002 5.39997 2.88001 4.63747 2.88001C3.28747 2.88001 2.18747 3.96752 2.15 5.33006C0.937493 5.50506 0 6.56758 0 7.84264C0 9.24267 1.11251 10.3802 2.5 10.3802H13.7875C15.0125 10.3802 16 9.39265 16 8.18012C16 7.06759 15.175 6.15501 14.1 6.00514L14.0998 6.0051ZM9.74976 2.00502C10.8623 2.00502 11.7872 2.81754 11.9623 3.87999H11.8873C11.2123 3.87999 10.6123 4.18 10.2124 4.64251C9.94985 4.49251 9.64985 4.40501 9.32487 4.40501C9.28737 4.40501 9.24987 4.40501 9.21237 4.41751C8.87487 3.8925 8.29986 3.53 7.63743 3.49249C7.94994 2.62997 8.77493 2.005 9.74997 2.005L9.74976 2.00502ZM0.49976 7.8426C0.49976 6.83008 1.23725 5.96763 2.21229 5.83003L2.6248 5.76753L2.6373 5.34252C2.6748 4.2425 3.54981 3.37994 4.6373 3.37994C5.2123 3.37994 5.77479 3.64246 6.14983 4.09247L6.42483 4.41747L6.78734 4.19247C7.01234 4.05497 7.27483 3.97996 7.53734 3.97996C8.34985 3.97996 9.01227 4.65497 9.02481 5.47999V5.8425L9.32481 5.97999C10.0498 6.305 10.5123 7.04252 10.5123 7.84256C10.5123 8.96759 9.6123 9.88007 8.5123 9.88007H2.49977C1.39977 9.88007 0.49976 8.96759 0.49976 7.8426ZM13.7872 9.88011H9.98723C10.5997 9.4176 10.9997 8.68009 10.9997 7.8426C10.9997 6.80509 10.3872 5.9175 9.5248 5.53002V5.50502C9.5248 5.30502 9.4998 5.10501 9.4373 4.91752C9.62479 4.93002 9.79981 4.99252 9.9623 5.08003L10.3248 5.29254L10.5998 4.98002C10.9248 4.60502 11.3998 4.38001 11.8998 4.38001C12.8248 4.38001 13.5747 5.10503 13.6123 6.01751L13.6248 6.44252L14.0373 6.50502C14.8748 6.61753 15.5123 7.34253 15.5123 8.17999C15.4998 9.11751 14.7373 9.88011 13.7872 9.88011Z"
      fill="currentColor"
    />
    <path
      d="M9.74976 1.255C9.82476 1.255 9.87476 1.205 9.87476 1.13V0.129977C9.87476 0.0549754 9.82476 0.00497437 9.74976 0.00497437C9.67476 0.00497437 9.62476 0.0549754 9.62476 0.129977V1.13C9.62476 1.205 9.67476 1.255 9.74976 1.255Z"
      fill="currentColor"
    />
    <path
      d="M11.9623 2.16755C11.9998 2.16755 12.0248 2.15505 12.0498 2.13005L12.7623 1.41753C12.8123 1.36753 12.8123 1.29253 12.7623 1.24253C12.7123 1.19253 12.6373 1.19253 12.5873 1.24253L11.8748 1.95505C11.8248 2.00505 11.8248 2.08005 11.8748 2.13005C11.8998 2.15505 11.9248 2.16755 11.9623 2.16755Z"
      fill="currentColor"
    />
    <path
      d="M7.44976 2.13009C7.47476 2.15509 7.51226 2.16759 7.53726 2.16759C7.57476 2.16759 7.59976 2.15509 7.62476 2.13009C7.67476 2.08008 7.67476 2.00508 7.62476 1.95508L6.92476 1.25507C6.87476 1.20507 6.79976 1.20507 6.74976 1.25507C6.69976 1.30507 6.69976 1.38007 6.74976 1.43007L7.44976 2.13009Z"
      fill="currentColor"
    />
    <path
      d="M8.48723 1.39247C8.51223 1.44247 8.54973 1.46747 8.59973 1.46747C8.61223 1.46747 8.62473 1.46747 8.64973 1.45497C8.71223 1.42997 8.74973 1.35497 8.72473 1.29246L8.34973 0.367442C8.32473 0.304941 8.24973 0.26744 8.18722 0.29244C8.12471 0.317441 8.08722 0.392442 8.11222 0.45495L8.48723 1.39247Z"
      fill="currentColor"
    />
    <path
      d="M12.5372 3.14251C12.5622 3.19251 12.5997 3.21751 12.6497 3.21751C12.6622 3.21751 12.6747 3.21751 12.6997 3.20501L13.6247 2.83C13.6872 2.805 13.7247 2.73 13.6997 2.66749C13.6747 2.60498 13.5997 2.56749 13.5372 2.59249L12.6122 2.9675C12.5497 3.0175 12.5122 3.08001 12.5372 3.14251Z"
      fill="currentColor"
    />
    <path
      d="M10.9123 1.49247C10.9248 1.50497 10.9498 1.50497 10.9623 1.50497C11.0123 1.50497 11.0623 1.47997 11.0748 1.42997L11.4623 0.504951C11.4873 0.44245 11.4623 0.367455 11.3998 0.342441C11.3373 0.317428 11.2623 0.342441 11.2373 0.404943L10.8498 1.32996C10.8123 1.39246 10.8498 1.45496 10.9123 1.49247Z"
      fill="currentColor"
    />
    <path
      d="M5.89976 2.77997L6.82476 3.16747C6.83726 3.17997 6.86226 3.17997 6.87476 3.17997C6.92476 3.17997 6.97476 3.15497 6.98726 3.10497C7.01226 3.04246 6.98726 2.96747 6.92476 2.94246L5.99976 2.55495C5.93726 2.52995 5.86227 2.55495 5.83725 2.61746C5.79975 2.67996 5.83726 2.75495 5.89976 2.77997Z"
      fill="currentColor"
    />
    <path
      d="M3.44976 11.1552C3.38726 11.1177 3.31227 11.1302 3.27476 11.1927L2.52476 12.3177C2.48726 12.3802 2.49976 12.4552 2.56226 12.4927C2.58726 12.5052 2.61226 12.5177 2.63726 12.5177C2.67476 12.5177 2.71226 12.4927 2.73726 12.4677L3.48726 11.3427C3.51226 11.2677 3.49976 11.1927 3.44976 11.1552Z"
      fill="currentColor"
    />
    <path
      d="M3.97469 12.4552L4.72469 11.3302C4.76219 11.2677 4.74969 11.1927 4.68719 11.1552C4.62469 11.1177 4.5497 11.1302 4.51219 11.1927L3.76219 12.3177C3.72469 12.3802 3.73719 12.4552 3.79969 12.4927C3.82469 12.5052 3.84969 12.5177 3.87469 12.5177C3.91219 12.5052 3.94969 12.4802 3.97469 12.4552Z"
      fill="currentColor"
    />
    <path
      d="M5.22483 12.4552L5.97483 11.3302C6.01233 11.2677 5.99983 11.1927 5.93733 11.1552C5.87483 11.1177 5.79983 11.1302 5.76233 11.1927L5.01233 12.3177C4.97483 12.3802 4.98733 12.4552 5.04983 12.4927C5.07483 12.5052 5.09983 12.5177 5.12483 12.5177C5.16233 12.5052 5.19983 12.4802 5.22483 12.4552Z"
      fill="currentColor"
    />
    <path
      d="M6.47469 12.4552L7.22469 11.3302C7.26219 11.2677 7.24969 11.1927 7.18719 11.1552C7.12469 11.1177 7.0497 11.1302 7.01219 11.1927L6.26219 12.3177C6.22469 12.3802 6.23719 12.4552 6.29969 12.4927C6.32469 12.5052 6.34969 12.5177 6.37469 12.5177C6.41219 12.5052 6.44969 12.4802 6.47469 12.4552Z"
      fill="currentColor"
    />
    <path
      d="M7.72483 12.4552L8.47483 11.3302C8.51233 11.2677 8.49983 11.1927 8.43733 11.1552C8.37483 11.1177 8.29983 11.1302 8.26233 11.1927L7.51233 12.3177C7.47483 12.3802 7.48733 12.4552 7.54983 12.4927C7.57483 12.5052 7.59983 12.5177 7.62483 12.5177C7.66233 12.5052 7.69983 12.4802 7.72483 12.4552Z"
      fill="currentColor"
    />
    <path
      d="M9.52483 11.1801L8.77483 12.3052C8.73733 12.3677 8.74983 12.4427 8.81233 12.4802C8.83733 12.4927 8.86233 12.5052 8.88733 12.5052C8.92483 12.5052 8.96233 12.4802 8.98733 12.4552L9.73733 11.3301C9.77483 11.2676 9.76233 11.1926 9.69983 11.1551C9.63733 11.1176 9.56233 11.1301 9.52483 11.1801Z"
      fill="currentColor"
    />
    <path
      d="M10.7747 11.1801L10.0247 12.3052C9.98719 12.3677 9.99969 12.4427 10.0622 12.4802C10.0872 12.4927 10.1122 12.5052 10.1372 12.5052C10.1747 12.5052 10.2122 12.4802 10.2372 12.4552L10.9872 11.3301C11.0247 11.2676 11.0122 11.1926 10.9497 11.1551C10.8872 11.1176 10.8122 11.1301 10.7747 11.1801Z"
      fill="currentColor"
    />
    <path
      d="M12.0248 11.1801L11.2748 12.3052C11.2373 12.3677 11.2498 12.4427 11.3123 12.4802C11.3373 12.4927 11.3623 12.5052 11.3873 12.5052C11.4248 12.5052 11.4623 12.4802 11.4873 12.4552L12.2373 11.3301C12.2748 11.2676 12.2623 11.1926 12.1998 11.1551C12.1373 11.1176 12.0623 11.1301 12.0248 11.1801Z"
      fill="currentColor"
    />
    <path
      d="M13.2747 11.1801L12.5247 12.3052C12.4872 12.3677 12.4997 12.4427 12.5622 12.4802C12.5872 12.4927 12.6122 12.5052 12.6372 12.5052C12.6747 12.5052 12.7122 12.4802 12.7372 12.4552L13.4872 11.3301C13.5247 11.2676 13.5122 11.1926 13.4497 11.1551C13.3872 11.1176 13.3122 11.1301 13.2747 11.1801Z"
      fill="currentColor"
    />
    <path
      d="M4.27469 12.9303L3.52469 14.0553C3.48719 14.1178 3.49969 14.1928 3.56219 14.2303C3.58719 14.2428 3.61219 14.2553 3.63719 14.2553C3.67469 14.2553 3.71219 14.2303 3.73719 14.2053L4.48719 13.0803C4.52469 13.0178 4.51219 12.9428 4.44969 12.9053C4.38719 12.8678 4.3122 12.8803 4.27469 12.9303Z"
      fill="currentColor"
    />
    <path
      d="M5.52483 12.9303L4.77483 14.0553C4.73733 14.1178 4.74983 14.1928 4.81233 14.2303C4.83733 14.2428 4.86233 14.2553 4.88733 14.2553C4.92483 14.2553 4.96233 14.2303 4.98733 14.2053L5.73733 13.0803C5.77483 13.0178 5.76233 12.9428 5.69983 12.9053C5.63733 12.8678 5.56233 12.8803 5.52483 12.9303Z"
      fill="currentColor"
    />
    <path
      d="M6.77469 12.9303L6.02469 14.0553C5.98719 14.1178 5.99969 14.1928 6.06219 14.2303C6.08719 14.2428 6.11219 14.2553 6.13719 14.2553C6.17469 14.2553 6.21219 14.2303 6.23719 14.2053L6.98719 13.0803C7.02469 13.0178 7.01219 12.9428 6.94969 12.9053C6.88719 12.8678 6.8122 12.8803 6.77469 12.9303Z"
      fill="currentColor"
    />
    <path
      d="M8.02483 12.9303L7.27483 14.0553C7.23733 14.1178 7.24983 14.1928 7.31233 14.2303C7.33733 14.2428 7.36233 14.2553 7.38733 14.2553C7.42483 14.2553 7.46233 14.2303 7.48733 14.2053L8.23733 13.0803C8.27483 13.0178 8.26233 12.9428 8.19983 12.9053C8.13733 12.8678 8.06233 12.8803 8.02483 12.9303Z"
      fill="currentColor"
    />
    <path
      d="M9.27469 12.9303L8.52469 14.0553C8.48719 14.1178 8.49969 14.1928 8.56219 14.2303C8.58719 14.2428 8.61219 14.2553 8.63719 14.2553C8.67469 14.2553 8.71219 14.2303 8.73719 14.2053L9.48719 13.0803C9.52469 13.0178 9.51219 12.9428 9.44969 12.9053C9.38719 12.8678 9.3122 12.8803 9.27469 12.9303Z"
      fill="currentColor"
    />
    <path
      d="M10.5248 12.9303L9.77483 14.0553C9.73733 14.1178 9.74983 14.1928 9.81233 14.2303C9.83733 14.2428 9.86233 14.2553 9.88733 14.2553C9.92483 14.2553 9.96233 14.2303 9.98733 14.2053L10.7373 13.0803C10.7748 13.0178 10.7623 12.9428 10.6998 12.9053C10.6373 12.8678 10.5623 12.8803 10.5248 12.9303Z"
      fill="currentColor"
    />
    <path
      d="M11.7747 12.9303L11.0247 14.0553C10.9872 14.1178 10.9997 14.1928 11.0622 14.2303C11.0872 14.2428 11.1122 14.2553 11.1372 14.2553C11.1747 14.2553 11.2122 14.2303 11.2372 14.2053L11.9872 13.0803C12.0247 13.0178 12.0122 12.9428 11.9497 12.9053C11.8872 12.8678 11.8122 12.8803 11.7747 12.9303Z"
      fill="currentColor"
    />
    <path
      d="M5.27469 14.6799L4.52469 15.805C4.48719 15.8675 4.49969 15.9425 4.56219 15.98C4.58719 15.9925 4.61219 16.005 4.63719 16.005C4.67469 16.005 4.71219 15.98 4.73719 15.955L5.48719 14.8299C5.52469 14.7674 5.51219 14.6925 5.44969 14.6549C5.38719 14.6174 5.3122 14.6299 5.27469 14.6799Z"
      fill="currentColor"
    />
    <path
      d="M4.02483 14.6799L3.27483 15.805C3.23733 15.8675 3.24983 15.9425 3.31233 15.98C3.33733 15.9925 3.36233 16.005 3.38733 16.005C3.42483 16.005 3.46233 15.98 3.48733 15.955L4.23733 14.8299C4.27483 14.7674 4.26233 14.6925 4.19983 14.6549C4.13733 14.6174 4.06233 14.6299 4.02483 14.6799Z"
      fill="currentColor"
    />
    <path
      d="M6.52483 14.6799L5.77483 15.805C5.73733 15.8675 5.74983 15.9425 5.81233 15.98C5.83733 15.9925 5.86233 16.005 5.88733 16.005C5.92483 16.005 5.96233 15.98 5.98733 15.955L6.73733 14.8299C6.77483 14.7674 6.76233 14.6925 6.69983 14.6549C6.63733 14.6174 6.56233 14.6299 6.52483 14.6799Z"
      fill="currentColor"
    />
    <path
      d="M7.77469 14.6799L7.02469 15.805C6.98719 15.8675 6.99969 15.9425 7.06219 15.98C7.08719 15.9925 7.11219 16.005 7.13719 16.005C7.17469 16.005 7.21219 15.98 7.23719 15.955L7.98719 14.8299C8.02469 14.7674 8.01219 14.6925 7.94969 14.6549C7.88719 14.6174 7.8122 14.6299 7.77469 14.6799Z"
      fill="currentColor"
    />
    <path
      d="M9.02483 14.6799L8.27483 15.805C8.23733 15.8675 8.24983 15.9425 8.31233 15.98C8.33733 15.9925 8.36233 16.005 8.38733 16.005C8.42483 16.005 8.46233 15.98 8.48733 15.955L9.23733 14.8299C9.27483 14.7674 9.26233 14.6925 9.19983 14.6549C9.13733 14.6174 9.06233 14.6299 9.02483 14.6799Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgWeather);
export default Memo;
