export const SYSTEM_ROLE_TYPE = {
  ADMIN: 1,
  USER: 2,
  TENANT_ADMIN: 13,
  RM: 15,
  PO: 14,
  DOP: 16,
  PD: 17,
  LA: 18,
  PM: 19,
  HR: 3,
  RnD: 21,
};

export const TEMPLATE_TYPES = {
  NDA: 1,
  CONTRACT: 2,
};

export const STORAGE_KEYS = {
  CURRENT_PROJECT_ID: "current_project",
};
