import * as React from "react";
import { memo } from "react";
const SvgTodo = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.26138 4.20483H16V5.20856H7.26138V4.20483Z"
      fill="currentColor"
    />
    <path
      d="M5.77934e-08 9.58321H5.74874V15H5.77934e-08V9.58321Z"
      fill="currentColor"
    />
    <path
      d="M0 2V7.41679H5.74874V2H0ZM2.40918 6.0986L1.13799 4.90081L1.64043 4.42738L2.40918 5.15174L4.10646 3.55247L4.6089 4.0259L2.40918 6.0986Z"
      fill="currentColor"
    />
    <path
      d="M7.26138 11.7898H16V12.7935H7.26138V11.7898Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgTodo);
export default Memo;
