import React from "react";
import * as Styled from "./styled";
import TabBase from "../../../../../common/components/UI/TabBase";

function SettingTab({ children, ...otherProps }) {
  return <Styled.Tab {...otherProps}>{children}</Styled.Tab>;
}

SettingTab.Panel = TabBase.Panel;

export default SettingTab;
