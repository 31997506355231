import React from "react";
import { isSameDay, isDate } from "date-fns";

import { UIDatePickerFooter } from "../../../../../../../common/components/DatePicker/styled";
import DatePicker from "../../../../../../../common/components/DatePicker";
import FieldGroup from "../../../../../../../common/components/Form/FieldGroup";
import TextAreaField from "../../../../../../../common/components/Form/TextAreaField";
import UIButton from "../../../../../../../common/components/Buttons/UIButton";
import DaySelectOption from "../DaySelectOption";
import * as Styled from "./styled";
import PopoverLayout from "./PopoverLayout";

const Calendar = (props) => {
  const { viewMode = true, onSave, onClose, value, ...otherProps } = props;
  const [showOptionMenu, setShowOptionMenu] = React.useState(false);
  const [target, setTarget] = React.useState(null);
  const [dayPayload, setDayPayload] = React.useState(null);
  const [note, setNote] = React.useState(value?.note || "");
  const [modifiers, setModifiers] = React.useState(value?.modifiers);

  const DateFooter = (
    <UIDatePickerFooter className="date-picker-footer">
      <div
        className="rdp__day day__na"
        data-tooltip-text="Not Available"
        data-tooltip-append-to=".date-picker-footer"
      >
        NA
      </div>

      <div
        className="rdp__day day__con"
        data-tooltip-text="Confirm"
        data-tooltip-append-to=".date-picker-footer"
      >
        Con
      </div>

      <div
        className="rdp__day day__tent"
        data-tooltip-text="Tentative"
        data-tooltip-append-to=".date-picker-footer"
      >
        Tent
      </div>

      <div
        className="rdp__day day__pub"
        data-tooltip-text="Public"
        data-tooltip-append-to=".date-picker-footer"
      >
        <i></i> Pub
      </div>
    </UIDatePickerFooter>
  );

  const handleDayClick = (day, _modifiers, event) => {
    setTarget(event.target);
    setShowOptionMenu(true);

    setDayPayload({
      day,
      modifiers: _modifiers,
      selectedType:
        Object.keys(_modifiers).length > 0 ? Object.keys(_modifiers)[0] : null,
    });
  };

  const removeDay = (type, date, days) => {
    const index = days[type]?.findIndex((selectedDay) =>
      isSameDay(
        date,
        isDate(selectedDay.date) ? selectedDay.date : new Date(selectedDay.date)
      )
    );

    if (index > -1) {
      days[type]?.splice(index, 1);
    }

    return days;
  };

  const onDayOptionSelectHandler = (data) => {
    setShowOptionMenu(false);

    const { day, selectedType, modifiers: _modifiers } = data;
    const modifiersDays = { ...modifiers };

    // If already existing modifiers
    if (_modifiers[selectedType] && !_modifiers?.public) {
      const modifiersDates = removeDay(selectedType, day, modifiersDays);
      setModifiers(modifiersDates);
      return;
    }

    let updatedModifiersDays;

    for (let key in modifiersDays) {
      if (key === "public") {
        continue;
      }
      updatedModifiersDays = removeDay(key, day, modifiersDays);
    }

    if (Object(updatedModifiersDays).hasOwnProperty(selectedType)) {
      updatedModifiersDays[selectedType].push({ date: day });
    } else {
      updatedModifiersDays = {
        ...updatedModifiersDays,
        [selectedType]: [{ date: day }],
      };
    }

    setModifiers(updatedModifiersDays);
  };

  const onSaveHandler = () => {
    if (!viewMode) {
      onSave && onSave({ note, modifiers });
    }
  };

  const editModeProps = !viewMode
    ? {
        mode: "multiple",
        // selected: days,
        // onSelect: setDays,
        onDayClick: handleDayClick,
        ...otherProps,
      }
    : {
        ...otherProps,
      };

  const modifiersToStringArray = React.useMemo(() => {
    let updated = {};
    for (let key in modifiers) {
      updated = {
        ...updated,
        [key]: modifiers[key]?.map((data) => new Date(data.date)),
      };
    }
    return updated;
  }, [modifiers]);

  return (
    <PopoverLayout>
      <DatePicker
        modifiers={modifiersToStringArray}
        modifiersClassNames={{
          notAvailable: "day--na",
          confirm: "day--con",
          public: "day--pub",
          tentative: "day--tent",
        }}
        footer={DateFooter}
        {...editModeProps}
      />

      <PopoverLayout.PopoverDivided />

      <Styled.Footer>
        {viewMode ? (
          <Styled.NoteContent>
            <div className="title">Special Notes</div>
            <div className="text">{note}</div>
          </Styled.NoteContent>
        ) : (
          <>
            <FieldGroup label="Special Notes">
              <TextAreaField
                name="specialNote"
                onChange={(e) => setNote(e.target.value)}
                value={note}
              />
            </FieldGroup>

            <div className="footer__actions">
              <UIButton
                type="button"
                size={UIButton.Sizes.Small}
                variant={UIButton.Variant.Danger}
                onClick={onClose}
                rounded
              >
                Cancel
              </UIButton>
              <UIButton
                type="button"
                size={UIButton.Sizes.Small}
                variant={UIButton.Variant.Primary}
                onClick={onSaveHandler}
                rounded
              >
                Save
              </UIButton>
            </div>
          </>
        )}
      </Styled.Footer>

      {/* Option popover menu */}
      <DaySelectOption
        isShow={showOptionMenu}
        anchorEl={target}
        selectedDayPayload={dayPayload}
        onOptionSelect={onDayOptionSelectHandler}
        closeOutsideClick={() => setShowOptionMenu(false)}
      />
    </PopoverLayout>
  );
};

export default Calendar;
