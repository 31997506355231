import styled from "styled-components";

export const Container = styled.div`
  height: 360px;

  .notifications {
    .notifications__header {
      padding-inline: 0;
      padding-top: 0;
    }

    .notifications__content {
      padding-inline: 0;
    }
  }
`;
