import * as React from "react";
import { memo } from "react";
const SvgIconArrowRight = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.776 5.46101L1.32535 0.215563C1.00802 -0.0718542 0.522678 -0.0718542 0.223996 0.215563C-0.0746651 0.50298 -0.0746651 0.970044 0.223996 1.27545L5.13338 6L0.223996 10.7245C-0.0746651 11.012 -0.0746651 11.497 0.223996 11.7844C0.522657 12.0719 1.00799 12.0719 1.32535 11.7844L6.776 6.52099C7.07467 6.23358 7.07467 5.76651 6.776 5.46111V5.46101Z"
      fill="white"
    />
  </svg>
);
const Memo = memo(SvgIconArrowRight);
export default Memo;
