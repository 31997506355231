import React from "react";
import ReactDOM from "react-dom";
import { motion, AnimatePresence } from "framer-motion";
import "./styles.scss";

const _animationContainer = {
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.07,
      delayChildren: 0.1,
      when: "beforeChildren",
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
};

const ModalBackdrop = ({
  children,
  visibility,
  onClick,
  className,
  variants = null,
  portalElement,
}) => {
  const backdropRef = React.useRef();
  const [animationContainer, setAnimationContainer] =
    React.useState(_animationContainer);

  const [appendElement, setAppendElement] = React.useState("modal-backdrop");

  const onClickHandler = (e) => {
    if (e.target === backdropRef.current) {
      onClick && onClick();
    }
  };

  React.useEffect(() => {
    if (variants) {
      setAnimationContainer(variants);
    }

    if (portalElement) {
      setAppendElement(portalElement);
    }
  }, [variants, portalElement]);

  const wrapper = (
    <>
      <AnimatePresence exitBeforeEnter>
        {visibility ? (
          <motion.div
            ref={backdropRef}
            className={["backdrop__wrapper", className].join(" ")}
            key="modal_backdrop"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={animationContainer}
            onClick={onClickHandler}
          >
            {children}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </>
  );

  return ReactDOM.createPortal(wrapper, document.getElementById(appendElement));
};

export default ModalBackdrop;
