import React from "react";
import "./styles.scss";

const Search = ({ ...inputProps }) => {
  return (
    <div className="setting__search">
      <input
        className="form-control"
        type="text"
        placeholder="Search in settings"
        aria-label="Search in settings"
        {...inputProps}
      />
    </div>
  );
};

export default Search;
