import API_ENDPOINTS from "../../../services/api-endpoints";
import API_Project from "../../../services/api-project";
import API_Template from "../../../services/api-template";
import API_GLOBAL from "../../../services/api";

// Get All Projects by Tenant ID
export const getAllProjectsDataForSelectList = async () => {
  // Get user
  let USER = JSON.parse(localStorage.getItem("user"));
  let tenantId = USER.result.tenantId;

  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Project.get(`${endpoints.PROJECT_GETALL}/${tenantId}`)
      .then((res) => {
        // If not success, return [];
        if (!res?.data?.isSuccess) {
          resolve([]);
        }

        let data = res?.data?.result;

        const projects = data.map((item) => {
          return {
            name: item.projectName,
            value: item.projectId,
          };
        });

        resolve(projects);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Get Template Type List
export const getTemplateTypeList = async () => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Template.get(endpoints.TEMPLATE_GET_TEMPLATE_TYPE)
      .then((res) => {
        if (!res.data.isSuccess) {
          return [];
        }

        const data = res?.data?.result;

        const templateTypes = data.map((item) => {
          return {
            label: item.templateTypeName,
            value: item.tenantTemplateTypeId,
          };
        });

        resolve(templateTypes);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Get Templates List
export const getTemplatesList = async () => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Template.get(endpoints.TEMPLATE_GETALL + "/1/10000")
      .then((res) => {
        //
        if (!res.data.isSuccess) {
          resolve([]);
          return;
        }

        const data = res?.data?.result;

        const dataList = data.map((item) => {
          return {
            ...item,
            label: item.templateName,
            value: item.templateId,
          };
        });

        resolve(dataList);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Assign templates to the projects
export const assignTemplates = (formData) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Project.post(`${endpoints.PROJECT_ASSIGN_TEMPLATE}`, formData)
      .then((res) => {
        resolve(res?.data || null);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Get Assign templates by project id
export const getAssignTemplatesByProjectId = (projectId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Project.get(
      `${endpoints.GET_ASSIGN_TEMPLATE_BY_PROJECT_ID}/${projectId}`
    )
      .then((res) => {
        resolve(res?.data?.result || []);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Update Assign template
export const updateTemplateByTemplateId = (id, formData) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Project.put(
      `${endpoints.PROJECT_ASSIGN_TEMPLATE_UPDATE}/${id}`,
      formData
    )
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Delete Assign template
export const deleteTemplateByTemplateId = (id) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Project.delete(`${endpoints.PROJECT_ASSIGNED_TEMPLATE_DELETE}/${id}`)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
