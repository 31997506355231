import React from "react";

import { Add } from "../../icons/Fill";
import { AddBtnStyled } from "./styled";

const AddButton = ({ onClick, label = "Add New", ...otherProps }) => {
  return (
    <AddBtnStyled
      variant={AddBtnStyled.Variant.Primary}
      size={AddBtnStyled.Sizes.Small}
      startIcon={<Add />}
      outlined
      rounded
      onClick={onClick}
      {...otherProps}
    >
      {label}
    </AddBtnStyled>
  );
};

export default AddButton;
