import React from "react";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import * as Styled from "./styled-slide";
import { ArrowLeft, ArrowRight } from "../../icons/Popup/Popovers";

// Import Swiper styles
import "swiper/css";

const HeaderSlide = (props) => {
  const {
    children,
    className,
    onUpdateSlider,
    slideTo,
    onSwiperCallback,
    modules=[],
    ...slideProps
  } = props;

  const swiperRef = React.useRef();
  const swiperNextRef = React.useRef();
  const swiperPrevRef = React.useRef();

  React.useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.params.navigation.prevEl = swiperPrevRef.current;
      swiperRef.current.params.navigation.nextEl = swiperNextRef.current;
      swiperRef.current.navigation.init();
      swiperRef.current.navigation.update();

      if (slideTo) {
        swiperRef.current.slideTo(slideTo);
      }
    }
  }, [slideTo]);

  const onSlideChange = () => {
    swiperRef.current.update();
  };

  return (
    <Styled.UISlideOuter className={["slide__outer", className].join(" ")}>
      {/* Arrow prev button */}
      <Styled.UIArrowButton className="prev" ref={swiperPrevRef}>
        <ArrowLeft></ArrowLeft>
      </Styled.UIArrowButton>

      <Styled.UISlide className="slide__inner">
        <Swiper
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
            onSwiperCallback && onSwiperCallback(swiper);
          }}
          onSlideChange={onSlideChange}
          modules={[Navigation, Pagination, ...modules]}
          spaceBetween={0}
          {...slideProps}
        >
          {children}
        </Swiper>
      </Styled.UISlide>

      {/* Arrow Next  button */}
      <Styled.UIArrowButton className="next" ref={swiperNextRef}>
        <ArrowRight />
      </Styled.UIArrowButton>
    </Styled.UISlideOuter>
  );
};

HeaderSlide.Item = SwiperSlide;

export default HeaderSlide;
