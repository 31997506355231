import React from "react";

import {
  milestoneInitialState,
  milestoneReducer,
} from "../../reducers/milestone.reducer";
import { MilestoneActionTypes } from "../../actionTypes/milestone.actions";
import ConditionModal from "../../components/Modal/ConditionModal";
import { useQuery, useQueryClient } from "react-query";
import {
  createChangeRequest,
  getMilestoneTracker,
  saveCondition,
  saveDue,
  saveRow,
  saveSubmission,
  updateChangeRequest,
  deleteRow,
} from "../../../../api";
import { useGlobalStore } from "../../../../../../store/GlobalStore";
import CellSubMenuPopover from "../../components/Table/components/Popovers/CellSubMenuPopover";
import DeliverablePopup from "../../components/Modal/DeliverablePopup";
import { GET_API_SUBMISSION_KEY } from "../../components/Modal/DeliverablePopup/index";
import Alert from "../../../../../../utility/alert";

export const GET_MILESTONE_TRACKER_KEY = "@GET_MILESTONE_TRACKER_KEY";

export const MilestoneContext = React.createContext({
  ...milestoneInitialState,
  projectId: 0,
  dispatch: () => {},
  addRowHandler: () => {},
  addSubRowHandler: (row) => {},
  deleteRowHandler: (row) => {},
  eventchangeHandler: (row, event) => {},
  onDeliverableNamechangeHandler: (row, val) => {},
  toggleDueModalHandler: (isShow, row) => {},
  cellSubMenuHandler: (data) => {},
  dueValueSubmitHandler: (data) => {},
  submissionValueSubmitHandler: (data) => {},
  changeRequestCreateHandler: (request) => {},
  changeRequestStateHandler: (request) => {},
  dueDateSubmitHandler: (data) => {},
  toggleDeliverableModalHandler: (isShow, row) => {},
});

export const useMilestone = () => {
  return React.useContext(MilestoneContext);
};

export const MilestoneProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    milestoneReducer,
    milestoneInitialState
  );
  const queryClient = useQueryClient();
  const projectId = useGlobalStore((state) => state.selectedProjectId);

  const addRowHandler = React.useCallback(() => {
    let newRow = null;

    const addRowDispatch = (payload) => {
      dispatch({
        type: MilestoneActionTypes.ADD_ROW,
        payload,
      });
    };

    addRowDispatch({
      isLoading: true,
    });

    saveRow(projectId, 0, null).then((row) => {
      console.log("MILESTONE_ENDPOINTS.row:: ", row);
      if (row?.length === 0) {
        addRowDispatch({
          isLoading: true,
        });
        return;
      }
      newRow = row[0];

      addRowDispatch({
        isLoading: false,
        data: newRow,
      });
    });
  }, [projectId]);

  const addSubRowHandler = React.useCallback(
    (row) => {
      const eventDispatch = (payload) => {
        dispatch({
          type: MilestoneActionTypes.ADD_SUB_ROW,
          payload: {
            ...payload,
            row,
          },
        });
      };

      eventDispatch({
        isLoading: true,
      });

      console.log("row-sub::: ", row?.original);
      let updatedRow = null;
      let parentId = row?.original?.id || 0;

      saveRow(projectId, parentId, null).then((res_row) => {
        console.log("MILESTONE_ENDPOINTS.sub.row:: ", res_row);
        if (res_row?.length === 0) {
          eventDispatch({
            isLoading: false,
          });
          return;
        }
        updatedRow = res_row[0];

        eventDispatch({
          data: updatedRow,
          isLoading: false,
        });
      });
    },
    [projectId]
  );

  const deleteRowHandler = React.useCallback((row) => {
    console.log("deleteRowHandler", row);
    Alert.confirm({
      allowOutsideClick: false,
      title: "Delete Row!",
      text: "Are you sure you want to delete this row ?",
      confirmButtonText: "Yes",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRow(row?.original?.id || 0).then((res) => {
          if (res) {
            queryClient.refetchQueries([GET_MILESTONE_TRACKER_KEY]);
            dispatch({
              type: MilestoneActionTypes.DELETE_ROW,
              payload: {
                row,
              },
            });
          }
        });
      }
    });
  }, []);

  const eventchangeHandler = React.useCallback(
    (row, event, cell) => {
      if (!row) {
        return;
      }
      // console.log("selected cell", row?.original[cell?.column?.id || "0"]);
      //console.log("eventchangeHandler:: ", row);
      let updatedRow = null;
      let parentId = row?.original?.parentId || 0;
      let data = {
        deliverableId: row?.original?.id || 0,
        label: row?.original[cell?.column?.id || "0"]?.label || "",
        eventId: event?.value || 0,
      };

      saveRow(projectId, parentId, data).then((res_row) => {
        //console.log("MILESTONE_ENDPOINTS.event change:: ", res_row);
        if (res_row?.length === 0) {
          return;
        }
        updatedRow = res_row[0];

        dispatch({
          type: MilestoneActionTypes.EVENT_CHANGE,
          payload: {
            data: updatedRow,
          },
        });
      });
    },
    [projectId]
  );

  const onDeliverableNamechangeHandler = React.useCallback(
    (row, val) => {
      // console.log("row: ", row);
      // console.log("val: ", val);

      if (!row) {
        return;
      }
      let updatedRow = null;
      let parentId = row?.original?.parentId || 0;
      let data = {
        deliverableId: row?.original?.id || 0,
        label: val || "",
        eventId: row?.original?.eventId || 0,
      };

      saveRow(projectId, parentId, data).then((res_row) => {
        console.log("MILESTONE_ENDPOINTS.delivarable name change:: ", res_row);
        if (res_row?.length === 0) {
          return;
        }
        updatedRow = res_row[0];

        dispatch({
          type: MilestoneActionTypes.DELIVERABLE_NAME_UPDATE,
          payload: {
            data: updatedRow,
          },
        });
      });
    },
    [projectId]
  );

  const toggleDueModalHandler = React.useCallback((isShow, data) => {
    dispatch({
      type: MilestoneActionTypes.DUE_MODAL_STATE,
      payload: {
        isShow,
        data,
      },
    });
  }, []);

  const cellSubMenuHandler = React.useCallback((data) => {
    dispatch({
      type: MilestoneActionTypes.CELL_SUBMENU_STATE,
      payload: {
        data,
      },
    });
  }, []);

  const dueValueSubmitHandler = React.useCallback(
    (sdata) => {
      let updatedRow = null;

      saveCondition(projectId, sdata).then((res_row) => {
        console.log("saveCondition", res_row);
        //toggleDueModalHandler(false);
        // if (!res_row) {
        //   return;
        // }
        updatedRow = res_row;
        dispatch({
          type: MilestoneActionTypes.CONDITION_UPDATE,
          payload: {
            data: updatedRow,
            id: sdata?.row?.original?.id || 0,
          },
        });
      });
    },
    [projectId]
  );
  const submissionValueSubmitHandler = React.useCallback(
    (submissionData) => {
      let updatedCell = null;

      saveSubmission(projectId, submissionData).then((res_cell) => {
        console.log("saveSubmission", res_cell);

        updatedCell = res_cell;
        dispatch({
          type: MilestoneActionTypes.SUBMISSION_SAVE,
          payload: {
            data: updatedCell,
            id: submissionData?.rowId || 0,
          },
        });
      });
    },
    [projectId]
  );
  const changeRequestStateHandler = React.useCallback((request) => {
    let updatedRequest = null;

    updateChangeRequest(request).then((res_req) => {
      console.log("updateChangeRequest state", res_req);

      updatedRequest = res_req;
      // dispatch({
      //   type: MilestoneActionTypes.CHANGE_REQUEST_STATE_CHANGE,
      //   payload: {
      //     data: updatedCell,
      //     id: request?.rowId || 0,
      //   },
      // });
    });
  }, []);
  const changeRequestCreateHandler = React.useCallback((request) => {
    let updatedRequest = null;

    createChangeRequest(request).then((res_req) => {
      console.log("createChangeRequest", res_req);

      updatedRequest = res_req;
      queryClient.refetchQueries([GET_API_SUBMISSION_KEY]);
      // dispatch({
      //   type: MilestoneActionTypes.CHANGE_REQUEST_STATE_CHANGE,
      //   payload: {
      //     data: updatedCell,
      //     id: request?.rowId || 0,
      //   },
      // });
    });
  }, []);
  const dueDateSubmitHandler = React.useCallback((dueData) => {
    let updatedCell = null;

    saveDue(dueData).then((res_cell) => {
      console.log("saveDue", res_cell);
      console.log("dueData", dueData);

      updatedCell = res_cell;
      dispatch({
        type: MilestoneActionTypes.DUE_DATE_CHANGE,
        payload: {
          data: updatedCell,
          id: dueData?.rowId || 0,
        },
      });
    });
  }, []);

  const toggleDeliverableModalHandler = React.useCallback((isShow, data) => {
    dispatch({
      type: MilestoneActionTypes.DELIVERABLE_MODAL_STATE,
      payload: {
        isShow,
        data,
      },
    });
  }, []);

  // Fetch data
  const initialQuery = useQuery(
    [GET_MILESTONE_TRACKER_KEY, projectId],
    () => getMilestoneTracker(projectId),
    {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,

      onSuccess: (data) => {
        console.log("onSuccess getMilestoneTracker", data);

        const initialDispatch = (payload) => {
          dispatch({
            type: MilestoneActionTypes.INITIAL_STATE,
            payload,
          });
        };

        if (!data) {
          initialDispatch({
            isLoading: false,
          });
          return;
        }

        initialDispatch({
          isLoading: false,
          project: data.project || [],
          headers: data.headers || [],
          sections: data.sections || [],
          eventOptions: data.eventlist || [],
        });
      },
    }
  );

  React.useEffect(() => {
    const initialDispatch = (payload) => {
      dispatch({
        type: MilestoneActionTypes.INITIAL_STATE,
        payload,
      });
    };

    if (initialQuery.isLoading) {
      initialDispatch({
        isLoading: true,
      });
      return;
    }

    if (initialQuery.isError) {
      initialDispatch({
        isLoading: false,
      });
    }
  }, [initialQuery.isError, initialQuery.isLoading]);

  console.log("state ", state);

  const values = {
    ...state,
    projectId: 0,
    dispatch,
    addRowHandler,
    addSubRowHandler,
    deleteRowHandler,
    eventchangeHandler,
    onDeliverableNamechangeHandler,
    toggleDueModalHandler,
    cellSubMenuHandler,
    dueValueSubmitHandler,
    submissionValueSubmitHandler,
    changeRequestCreateHandler,
    changeRequestStateHandler,
    dueDateSubmitHandler,
    toggleDeliverableModalHandler,
  };

  return (
    <MilestoneContext.Provider value={values}>
      {children}

      {/* Modals */}
      <ConditionModal />
      <DeliverablePopup />

      {/* Cell popover */}
      <CellSubMenuPopover />
    </MilestoneContext.Provider>
  );
};
