import API_ENDPOINTS from "../../services/api-endpoints";
import API_Project from "../../services/api-project";
import API_STORAGE from "../../services/api-fileupload";
import API_GLOBAL from "../../services/api";

export const getAllJobTitles = async () => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_GLOBAL.get(endpoints.GETALLJOBTITLES)
      .then((res) => {
        if (!res.data.isSuccess) {
          return [];
        }

        const data = res?.data?.result;

        const dataList = data.map((item) => {
          return {
            ...item,
            label: item.jobTitleDescription,
            value: item.jobTitleId,
          };
        });

        resolve(dataList);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Get Country List
export const getCountryList = async () => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_GLOBAL.get(endpoints.GETALLCOUNTRIES)
      .then((response) => {
        if (!response.data.isSuccess) {
          resolve([]);
        }

        const data = response.data.result;
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Upload Image
export const uploadImage = async (data, config = null) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_STORAGE.post(endpoints.FILE_STREAM_UPLOAD_ENDPOINT, data, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Get User Titles
export const getUserTitles = async () => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_GLOBAL.get(endpoints.GET_USER_TITLES)
      .then((response) => {
        if (!response.data.isSuccess) {
          resolve([]);
        }

        const data = response.data.result.result || [];

        const _data = data.map((val) => ({
          ...val,
          id: val.titleId,
          label: val.titleName,
        }));

        resolve(_data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const updateProjectCheckedIn = (data) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_GLOBAL.post(endpoints.PROJECT_UPDATE_CHECKED_IN, data)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
