import styled from "styled-components";
import Popover from "../../Popover";
import IconButton from "../../Buttons/IconButton";

export const IconSelectButton = styled.button`
  --picker-btn-size: var(--layout-size-24);
  --picker-icon-size: var(--layout-size-16);

  border: none;
  outline: none;
  width: var(--picker-btn-size);
  height: var(--picker-btn-size);
  min-width: var(--picker-btn-size);
  min-height: var(--picker-btn-size);
  padding: 0;
  margin: 0;
  background-color: transparent;
`;

export const IconPopoverPanel = styled(Popover)`
  margin: var(--layout-spacing-16);

  .icons-popover {
    border-radius: var(--layout-border-radius-8);
    background: var(--color-primary-50, #e6ebed);
    padding: var(--layout-spacing-16);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--layout-spacing-24);
    /* width: 100%; */
    /* max-width: 224px; */
    min-width: 224px;
    max-height: 300px;
    overflow-y: auto;
  }
`;

export const IconItem = styled(IconButton)`
  --icon-button-icon-color: var(--font-tint-04);
  border-radius: var(--layout-border-radius-4);

  ${({ $isActive }) => {
    if ($isActive) {
      return `--icon-button-icon-color: var(--color-primary-300)`;
    }

    return `
        &:hover {
            --icon-button-icon-color: var(--font-tint-01);
        }
    `;
  }};
`;
