import React from "react";
import { useRouteLoaderData } from "react-router-dom";

import ActionButton from "../../../../../Buttons/ActionButton";
import TableList from "../../../../../TableList";
import { useSettingRoleStore } from "../../../../../../../store/Setting/Permission";
import { toast } from "react-toastify";
import { ROUTE_OPTIONS } from "../../../../../../../constant/route-options";
import { SETTING_PERMISSION_MODULES } from "../../../../../../../constant/setting_permission_module";
import { Permission } from "../../../../../../../helpers/permission";

const columns = [
  {
    label: "Role",
    name: "role",
    sortable: true,
    width: "25%",
  },
  {
    label: "Description",
    name: "description",
    sortable: true,
    width: "40%",
  },
  {
    label: "Status",
    name: "roleStatus",
    sortable: true,
    width: "20%",
  },
  {
    label: "Action",
    name: "action",
    sortable: false,
    width: "15%",
  },
];

const RolesDetails = () => {
  const { permission } = useRouteLoaderData(
    ROUTE_OPTIONS.setting.children["tenant-permission"].id
  );

  const moduleType = SETTING_PERMISSION_MODULES.TenantPermission;

  const permissions = new Permission(permission);

  const rolePermission = permissions.findByName(moduleType.Role.key);

  const data = useSettingRoleStore((state) => state.data);
  const onSetRoleData = useSettingRoleStore((state) => state.onSetRoleData);

  const onEdit = useSettingRoleStore((state) => state.onEdit);
  const onDelete = useSettingRoleStore((state) => state.onDelete);

  React.useEffect(() => {
    onSetRoleData();
  }, [onSetRoleData]);

  const onDeleteHandler = (id) => {
    onDelete(id, (response) => {
      //alert("" + response);
      // toast.success(response.displayMessage);
      onSetRoleData();
    });
  };

  const tableRenderRows = (rowData, index) => {
    const { role, description, roleStatus, action, id } = rowData;

    return (
      <TableList.Row key={index}>
        <TableList.Column width={role.style.width}>
          {role.value}
        </TableList.Column>

        <TableList.Column width={description.style.width}>
          {description.value}
        </TableList.Column>

        <TableList.Column width={roleStatus.style.width}>
          {Boolean(roleStatus?.value) ? "Activated" : "Deactivated"}
        </TableList.Column>

        <TableList.Column width={action.style.width}>
          {rolePermission.permission.canEdit ? (
            <ActionButton variant="EDIT" onClick={() => onEdit(id.value)} />
          ) : null}

          {rolePermission.permission.canDelete ? (
            <ActionButton
              variant="DELETE"
              onClick={() => onDeleteHandler(id.value)}
            />
          ) : null}
        </TableList.Column>
      </TableList.Row>
    );
  };

  return (
    <div className="setting-details setting__details--role">
      <TableList columns={columns} data={data} renderRows={tableRenderRows} />
    </div>
  );
};

export default RolesDetails;
