import React from "react";
import { StyledImageContainer } from "./styled";

const ImageView = ({ url }) => {
  return (
    <StyledImageContainer>
      <img src={url} alt="" />
    </StyledImageContainer>
  );
};

export default ImageView;
