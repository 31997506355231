import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.470588 2.11765H4.23529C4.49519 2.11765 4.70588 2.32834 4.70588 2.58824V4.47059C4.70588 4.73049 4.49519 4.94118 4.23529 4.94118H0.470588C0.21069 4.94118 0 4.73049 0 4.47059V2.58824C0 2.32834 0.21069 2.11765 0.470588 2.11765ZM11.2941 4.94118H15.0588C15.3187 4.94118 15.5294 5.15187 15.5294 5.41176V7.29412C15.5294 7.55402 15.3187 7.76471 15.0588 7.76471H11.2941C11.0342 7.76471 10.8235 7.55402 10.8235 7.29412V5.41176C10.8235 5.15187 11.0342 4.94118 11.2941 4.94118ZM0.470588 8.70588H4.23529C4.49519 8.70588 4.70588 8.91657 4.70588 9.17647V11.0588C4.70588 11.3187 4.49519 11.5294 4.23529 11.5294H0.470588C0.21069 11.5294 0 11.3187 0 11.0588V9.17647C0 8.91657 0.21069 8.70588 0.470588 8.70588ZM11.2941 11.5294H15.0588C15.3187 11.5294 15.5294 11.7401 15.5294 12V13.8824C15.5294 14.1423 15.3187 14.3529 15.0588 14.3529H11.2941C11.0342 14.3529 10.8235 14.1423 10.8235 13.8824V12C10.8235 11.7401 11.0342 11.5294 11.2941 11.5294ZM8.23529 6.82353V12.4706H10.1176V13.4118H8.23529V15.5294C8.23529 15.7893 8.0246 16 7.76471 16C7.50481 16 7.29412 15.7893 7.29412 15.5294V10.5882H5.41176V9.64706H7.29412V4H5.41176V3.05882H7.29412V0.470588C7.29412 0.21069 7.50481 0 7.76471 0C8.0246 0 8.23529 0.21069 8.23529 0.470588V5.88235H10.1176V6.82353H8.23529Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
