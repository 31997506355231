import { getUserPageRightPermission } from "../../Actions/Permissions";
import { STORAGE_KEYS } from "../../constant/System";
import { WebStorage } from "../../utility/module/WebStorage";

const baseModulePermission = (module) => {
  const hasPermissions = () => {
    if (module?.modulePermission === null) {
      return false;
    }

    const isAllRightFalse = Object.entries(module?.modulePermission).every(
      (m) => m[1] === false
    );

    return !isAllRightFalse ? true : false;
  };

  return {
    name: module?.name || null,
    hasPermission: hasPermissions(),
    permission: {
      canView: module?.modulePermission?.canView || false,
      canAddNew: module?.modulePermission?.canAddNew || false,
      canEdit: module?.modulePermission?.canEdit || false,
      canDelete: module?.modulePermission?.canDelete || false,
      canExecute: module?.modulePermission?.canExecute || false,
    },
  };
};

const basePermissionFormat = (data) => {
  let permissionItems = data?.permission?.permissionItems || [];

  return {
    rightId: data?.rightId || null,
    moduleId: data?.moduleId || null,
    pageId: data?.pageId || null,
    isAuthenticated: data?.isAuthenticated || false,
    permission:
      permissionItems?.map((module) => baseModulePermission(module)) || [],
  };
};

export const permissionLoader = async (routeId, sub = "") => {

  const projectId = WebStorage.get(STORAGE_KEYS.CURRENT_PROJECT_ID);

  const data = await getUserPageRightPermission({
    routeId,
    projectId: projectId || 0,
    sub,
  });

  if (!data?.result?.isAuthenticated) {
    let ErrorCode = 403;

    let options = JSON.stringify({
      code: ErrorCode,
      message: "You don't have permission for this",
    });

    throw new Response(options, {
      status: ErrorCode,
    });
  }

  return basePermissionFormat(data?.result);
};
