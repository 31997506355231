import React, { useState } from "react";
import styles from "./mButton.module.css";

let defaultbackgroundColor = "#245A6C";
let defaultHoverbackgroundColor = "#347084";
let defaultHoverForeColor = "#ffffff";
let defaultWidth = "100%";
let defaultHeight = "35px";
let defaultBorderRadius = "25px";
let defaultMargin = "0px";
const MIButton = ({
  children,
  onClick,
  btnColor = defaultbackgroundColor,
  labelColor,
  disabled,
  type,
  style,
  btnHoverColor = defaultHoverbackgroundColor,
  btnHoverForeColor = defaultHoverForeColor,
  btnWidth = defaultWidth,
  btnBoarderRadius = defaultBorderRadius,
  btnHeight = defaultHeight,
  btnMargin = defaultMargin,
  ...props
}) => {
  const [hover, setHover] = useState(false);
  const toggleHover = () => {
    setHover(!hover);
  };
  const commonStyle = {
    backgroundColor: btnColor,
    color: labelColor || "white",
    height: btnHeight,
    margin: btnMargin,
    width: btnWidth,
    style: style,
  };

  const commonHoverStyle = {
    color: btnHoverForeColor || "white",
    backgroundColor: btnHoverColor,
    width: btnWidth,
  };

  const outlineStyle = {
    border: `1px solid ${btnColor}`,
    color: btnColor,
    backgroundColor: "white",
    height: btnHeight,
    margin: btnMargin,
    width: btnWidth,
    style: style,
  };
  const outlineHoverStyle = {
    color: labelColor || "white",
    backgroundColor: btnColor,
  };

  const roundedStyle = {
    backgroundColor: btnColor,
    color: labelColor || "white",
    borderRadius: btnBoarderRadius,
    height: btnHeight,
    margin: btnMargin,
    width: btnWidth,
    style: style,
  };
  const roundedHoverStyle = {
    color: btnHoverForeColor || "white",
    backgroundColor: btnHoverColor,
    width: btnWidth,
    borderRadius: btnBoarderRadius,
  };
  const disabledStyle = {
    cursor: "default",
    backgroundColor: btnColor,
    color: labelColor || "white",
    opacity: 0.4,
  };
  const blockStyles = {
    width: "95%",
    margin: "0 auto",
  };
  //Button Hover
  let btnStyle;
  switch (type) {
    case "btn-cm":
      if (hover) {
        btnStyle = commonHoverStyle;
      } else {
        btnStyle = {
          backgroundColor: btnColor,
          color: labelColor || "white",
        };
      }
      break;
    case "btn-rounded":
      if (hover) {
        btnStyle = roundedHoverStyle;
      } else {
        btnStyle = roundedStyle;
      }

      break;
    case "block":
      btnStyle = blockStyles;
      break;
    case "btn-outline":
      if (hover) {
        btnStyle = outlineHoverStyle;
      } else {
        btnStyle = outlineStyle;
      }
      break;
    default:
      btnStyle = {
        backgroundColor: btnColor,
        color: labelColor || "white",
      };

      break;
  }
  return (
    <button
      style={
        disabled
          ? { ...commonStyle, ...btnStyle, ...disabledStyle, ...style }
          : { ...commonStyle, ...btnStyle, ...style }
      }
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      {...props}
      type="button"
      onClick={!disabled ? onClick : () => {}}
      className={styles.btn}
    >
      {children || "button"}
    </button>
  );
};

export default MIButton;
