import styled from "styled-components";

export const DocumentEditorContainer = styled.div`
  height: 100%;
  width: 100%;

  #txTemplateDesignerContainer #ribbonbar {
    height: 115px;
    --font-color: #245a6c;
    --font-family: var(--bs-body-font-family);
  }

  #txTemplateDesignerContainer ul.tabs {
    --main-menu-font-size: var(--x-small-regular-font-size);
  }

  #txRibbonTabContentContainer div .ribbon-group-footer {
    display: none;
  }

  #txBtnRibbonHide {
    display: none;
  }

  #txTglBtnSideBars {
    font-size: var(--x-small-regular-font-size);
    font-family: var(--bs-body-font-family);
    font-weight: normal;
  }

  #fieldNavigatorSidebar, #commentsSidebar{
    background-color: white !important;
  }
`;
