const useValidation = () => {
  const textOnly = (value) => {
    if (value === undefined) return;

    return value.replace(/[^a-z ]/gi, "");
  };

  const numberOnly = (value) => {
    if (value === undefined) return;

    return value.replace(/[^0-9]+/g, "");
  };

  return {
    textOnly,
    numberOnly,
  };
};

export default useValidation;
