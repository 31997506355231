import * as React from "react";
import { memo } from "react";
const SvgAccess1 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.4999 16H3.50008C3.10251 15.9995 2.72135 15.8392 2.44016 15.5544C2.15898 15.2696 2.00069 14.8834 2 14.4805V6.64008C2.00052 6.23708 2.15872 5.85074 2.43993 5.56578C2.72114 5.28081 3.10239 5.1205 3.50008 5.11997H12.4999C12.8976 5.1205 13.2789 5.28081 13.5601 5.56578C13.8413 5.85074 13.9995 6.23708 14 6.64008V14.4805C13.9993 14.8834 13.841 15.2696 13.5598 15.5544C13.2786 15.8392 12.8975 15.9995 12.4999 16ZM3.50008 5.76005C3.26981 5.76022 3.04902 5.853 2.8862 6.018C2.72337 6.183 2.63182 6.40673 2.63165 6.64008V14.4805C2.63182 14.7139 2.72337 14.9376 2.8862 15.1026C3.04902 15.2676 3.26981 15.3604 3.50008 15.3606H12.4999C12.7301 15.3602 12.9508 15.2674 13.1136 15.1024C13.2764 14.9375 13.368 14.7138 13.3683 14.4805V6.64008C13.368 6.40679 13.2764 6.18315 13.1136 6.01819C12.9508 5.85323 12.7301 5.7604 12.4999 5.76005H3.50008Z"
      fill="currentColor"
    />
    <path
      d="M11.1579 5.76005H4.84209C4.75841 5.76005 4.67817 5.72637 4.619 5.66641C4.55983 5.60645 4.52659 5.52513 4.52659 5.44034V3.52011C4.52659 2.58652 4.89258 1.69116 5.54403 1.03101C6.19548 0.370867 7.07903 0 8.00032 0C8.92161 0 9.80518 0.370867 10.4566 1.03101C11.1081 1.69116 11.4741 2.58652 11.4741 3.52011C11.4741 3.60498 11.4408 3.68639 11.3816 3.74641C11.3223 3.80643 11.242 3.84014 11.1582 3.84014C11.0745 3.84014 10.9941 3.80643 10.9349 3.74641C10.8757 3.68639 10.8424 3.60498 10.8424 3.52011C10.8424 2.75627 10.543 2.02373 10.01 1.48362C9.47699 0.943511 8.75409 0.640079 8.00032 0.640079C7.24655 0.640079 6.52366 0.943511 5.99067 1.48362C5.45767 2.02373 5.15823 2.75627 5.15823 3.52011V5.11997H11.1586C11.2423 5.11997 11.3227 5.15369 11.3819 5.21371C11.4411 5.27373 11.4744 5.35513 11.4744 5.44001C11.4744 5.52489 11.4411 5.60629 11.3819 5.66631C11.3227 5.72633 11.2417 5.76005 11.1579 5.76005Z"
      fill="currentColor"
    />
    <path
      d="M7.99967 12.8003C7.91599 12.8003 7.83576 12.7666 7.77659 12.7066C7.71742 12.6467 7.68418 12.5654 7.68418 12.4806V11.2004C7.68418 11.1155 7.71746 11.0341 7.77669 10.9741C7.83592 10.9141 7.91624 10.8804 8.00001 10.8804C8.08377 10.8804 8.16409 10.9141 8.22332 10.9741C8.28255 11.0341 8.31583 11.1155 8.31583 11.2004V12.4806C8.31583 12.5226 8.30765 12.5642 8.29175 12.6031C8.27586 12.6419 8.25255 12.6772 8.22318 12.7069C8.19382 12.7366 8.15896 12.7601 8.12061 12.7761C8.08225 12.7922 8.04115 12.8003 7.99967 12.8003Z"
      fill="currentColor"
    />
    <path
      d="M7.99968 11.5201C7.68743 11.5201 7.38218 11.4263 7.12255 11.2505C6.86292 11.0747 6.66056 10.8248 6.54106 10.5325C6.42157 10.2401 6.3903 9.91846 6.45122 9.60812C6.51214 9.29777 6.6625 9.0127 6.8833 8.78895C7.1041 8.56521 7.38542 8.41284 7.69168 8.3511C7.99793 8.28937 8.31537 8.32106 8.60386 8.44215C8.89234 8.56324 9.13892 8.76831 9.3124 9.0314C9.48588 9.2945 9.57847 9.60381 9.57847 9.92023C9.57796 10.3444 9.41145 10.751 9.11549 11.0509C8.81952 11.3509 8.41824 11.5196 7.99968 11.5201ZM7.99968 8.95979C7.81235 8.95979 7.62923 9.01608 7.47347 9.12155C7.31772 9.22701 7.19632 9.37689 7.12463 9.55227C7.05294 9.72765 7.03419 9.92064 7.07073 10.1068C7.10728 10.293 7.19748 10.464 7.32994 10.5982C7.4624 10.7325 7.63117 10.8239 7.8149 10.8609C7.99863 10.898 8.18907 10.879 8.36214 10.8063C8.53521 10.7337 8.68313 10.6107 8.7872 10.4528C8.89128 10.295 8.94682 10.1094 8.94682 9.91957C8.94631 9.66524 8.84633 9.42148 8.6688 9.24171C8.49127 9.06193 8.25066 8.96013 7.99968 8.95979Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgAccess1);
export default Memo;
