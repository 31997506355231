import * as React from "react";
import { memo } from "react";
const SvgLeft = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5115 13.2689C13.1626 13.8934 13.1626 14.9069 12.5115 15.5314C12.186 15.8437 11.7597 16 11.3332 16C10.9067 16 10.4804 15.8437 10.1549 15.5312L3.48832 9.13124C2.83723 8.50671 2.83723 7.49318 3.48832 6.86874L10.1549 0.468785C10.806 -0.156262 11.8606 -0.156262 12.5117 0.468785C13.1628 1.09331 13.1628 2.10685 12.5117 2.73129L7.02347 7.99999L12.5115 13.2689Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgLeft);
export default Memo;
