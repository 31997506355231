import * as React from "react";
import { memo } from "react";
const SvgNight = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1187_2503)">
      <path
        d="M15.5883 10.4998C14.1774 11.0864 12.6242 11.2408 11.1255 10.9436C9.62667 10.6464 8.24992 9.91083 7.16967 8.83027C6.08941 7.74971 5.35415 6.37265 5.0571 4.87366C4.76011 3.37468 4.9147 1.82128 5.50137 0.410313C5.53158 0.337594 5.53158 0.255873 5.50148 0.183097C5.47133 0.110322 5.41351 0.0525425 5.34074 0.0224981C5.26797 -0.00759967 5.18626 -0.00749 5.11355 0.0227727C3.42709 0.725415 2.01913 1.96438 1.10772 3.54801C0.196336 5.13149 -0.167808 6.97145 0.0717351 8.78299C0.311222 10.5944 1.14105 12.2765 2.43272 13.5686C3.72512 14.8597 5.4068 15.6891 7.21767 15.9284C9.02869 16.1677 10.868 15.8036 12.4512 14.8925C14.0345 13.9813 15.2736 12.5739 15.977 10.8878C16.0075 10.8149 16.0077 10.7328 15.9775 10.6598C15.9473 10.5868 15.8892 10.5288 15.8161 10.4988C15.743 10.4687 15.661 10.4692 15.5882 10.4999L15.5883 10.4998ZM13.76 13.1487C12.7098 14.1972 11.3795 14.9206 9.92851 15.2319C8.47753 15.5432 6.96765 15.4293 5.57979 14.9039C4.19195 14.3784 2.98515 13.4637 2.10411 12.2695C1.22303 11.0753 0.705116 9.65215 0.612498 8.17088C0.519855 6.68961 0.856441 5.21312 1.58182 3.9183C2.30719 2.62347 3.39059 1.56556 4.70224 0.871257C4.2443 2.32173 4.19306 3.86993 4.55425 5.34754C4.91539 6.82501 5.67504 8.1751 6.75033 9.25058C7.92767 10.4323 9.43508 11.2299 11.0743 11.5386C12.4242 11.7952 13.8167 11.7129 15.1271 11.2991C14.767 11.9806 14.3065 12.6042 13.761 13.1487L13.76 13.1487Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1187_2503">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgNight);
export default Memo;
