import API_ENDPOINTS from "../../../services/api-endpoints";
import API_Scheduler from "../../../services/api-scheduler";
import API_Project from "../../../services/api-project";

export {
  getAllScheduleItemTypes,
  getActivityItemTypeByBlockId,
  createScheduleitem,
  deleteScheduleItem,
} from "./ScheduleItemType";

export {
  getAllColumnTypes,
  getAllColumnTypesAsOptionSource,
} from "./ColumnType";

export const getAllScheduleLegend = () => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Scheduler.get(`${endpoints.GET_SCHEDULE_LEGEND_DATA}`)
      .then((res) => {
        console.log("GET_SCHEDULE_LEGEND :::", res);

        resolve(res.data.result);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const getAllMilestones = () => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Scheduler.get(`${endpoints.GET_ALL_SCHEDULE_MILESTONES}`)
      .then((res) => {
        console.log("GET_ALL_SCHEDULE_MILESTONES :::", res);

        resolve(res.data.result);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const getProjectRelatedCalenderDates = (projectId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Scheduler.get(
      `${endpoints.GET_ALL_CALENDER_DATES}?projectId=${projectId}`
    )
      .then((res) => {
        console.log("GET_ALL_CALENDER_DATES_BY_PROJECT :::", res);

        resolve(res.data.result);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const assignDefaultWorkingDays = (projectId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    const body = [
      {
        dayTypeId: 0,
        projectId: projectId,
        dayTypeNo: 0,
        isWorkDay: false,
        isWeekStart: false,
      },
      {
        dayTypeId: 0,
        projectId: projectId,
        dayTypeNo: 1,
        isWorkDay: true,
        isWeekStart: true,
      },
      {
        dayTypeId: 0,
        projectId: projectId,
        dayTypeNo: 2,
        isWorkDay: true,
        isWeekStart: false,
      },
      {
        dayTypeId: 0,
        projectId: projectId,
        dayTypeNo: 3,
        isWorkDay: true,
        isWeekStart: false,
      },
      {
        dayTypeId: 0,
        projectId: projectId,
        dayTypeNo: 4,
        isWorkDay: true,
        isWeekStart: false,
      },
      {
        dayTypeId: 0,
        projectId: projectId,
        dayTypeNo: 5,
        isWorkDay: true,
        isWeekStart: false,
      },
      {
        dayTypeId: 0,
        projectId: projectId,
        dayTypeNo: 6,
        isWorkDay: false,
        isWeekStart: false,
      },
    ];

    API_Project.post(endpoints.CRATE_DAY_CONFIGS, [...body])
      .then((response) => {
        console.log("CRATE_DAY_CONFIGS::", response);
        if (response.data.isSuccess === true) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const genarateCalenderDates = (projectId, startDate, endDate) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    const body = {
      projectId: projectId,
      projectStartDate: startDate,
      projectEndDate: endDate,
      firstDay: 1,
    };

    API_Scheduler.post(endpoints.GENERTE_CALENDER_DATES, {
      ...body,
    })
      .then((response) => {
        console.log("GENERTE_CALENDER_DATES::", response);
        if (response.data.isSuccess === true) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const assignColumnTypesToEpisods = (projectId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    const body = {
      projectId: projectId,
      episodeCount: 1,
    };

    API_Project.post(endpoints.CONFIG_EPISODE_FOR_COLUMN_TYPES, {
      ...body,
    })
      .then((response) => {
        console.log("CONFIG_EPISODE_FOR_COLUMN_TYPES::", response);
        if (response.data.isSuccess === true) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const initCalenderBaseOnProject = (projectId, startDate, endDate) => {
  return new Promise((resolve, reject) => {
    getProjectRelatedCalenderDates(projectId).then((dates) => {
      if (dates === null || dates?.length === 0) {
        assignDefaultWorkingDays(projectId).then((days) => {
          if (days) {
            genarateCalenderDates(projectId, startDate, endDate)
              .then((res) => {
                assignColumnTypesToEpisods(projectId);
                resolve(res);
              })
              .catch((err) => {
                reject({ error: err });
              });
          }
        });
      }
    });
  });
};
export const getEpisodeIdsByProjectId = (projectId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Project.get(
      `${endpoints.GET_PROJECT_EPISODE_IDS}?projectId=${projectId}`
    )
      .then((res) => {
        console.log("GET_PROJECT_EPISODE_IDS :::", res);

        resolve(
          res.data.result?.length > 0 ? res.data.result[0]?.episodeId : 0
        );
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const moveCalenderItems = (
  _projectId,
  _columnTypeId,
  _episodeId,
  _startDate,
  _endDate,
  _date,
  _scheduleIds
) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    const body = {
      projectId: _projectId,
      columnTypeId: _columnTypeId,
      episodeId: _episodeId,
      startDate: _startDate,
      endDate: _endDate,
      date: _date,
      scheduleIds: _scheduleIds,
    };

    API_Scheduler.put(endpoints.MOVE_SCHEDULER_ITEMS, {
      ...body,
    })
      .then((response) => {
        console.log("MOVE_SCHEDULER_ITEMS::", response);
        if (response.data.isSuccess === true) {
          resolve(response);
        } else {
          resolve(response);
        }
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const deleteCalenderItems = (
  _scheduleIds,
  _columnTypeId,
  _projectId,
  _episodeId,
  _startDate,
  _endDate
) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    const body = {
      scheduleIds: _scheduleIds,
      columnTypeId: _columnTypeId,
      projectId: _projectId,
      episodeId: _episodeId,
      startDate: _startDate,
      endDate: _endDate,
    };

    API_Scheduler.put(endpoints.DELETE_SCHEDULER_ITEMS, {
      ...body,
    })
      .then((response) => {
        console.log("DELETE_SCHEDULER_ITEMS::", response);
        if (response.data.isSuccess === true) {
          resolve(response);
        } else {
          resolve(response);
        }
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
