import styled from "styled-components";

export const IconBlock = styled.div`
  --icon-size: ${({ size }) => size || "1.142rem"};
  --icon-font-size: ${({ fontSize }) => fontSize || "1.142rem"};
  --icon-font-color: ${({ color }) => color || "inherit"};

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--icon-font-color);
  font-size: var(--icon-font-size);
  min-height: var(--icon-size);
  min-width: var(--icon-size);
  height: var(--icon-size);
  width: var(--icon-size);
`;
