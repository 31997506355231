import React from "react";
import ReactDOM from "react-dom";
import * as Styled from "./styled";
import { IconBlock } from "../../../../../../styled/Common/Icons";
import { IconPaste } from "../../../../../../common/components/icons";
import {
  clearCopyTextValue,
  useTextSelectionStore,
} from "../../../../store/TextSelectionStore";

const PasteButton = (props) => {
  const { name, onPaste } = props;
  const buttonRef = React.useRef();

  const [input, setInput] = React.useState(null);
  const [showDialog, setShowDialog] = React.useState(false);

  let copyText = useTextSelectionStore((state) => state.copyText);
  copyText = String(copyText).trim();

  React.useEffect(() => {
    if (name) {
      setInput(document.querySelector(`[name="${name}"]`) || null);
    }
  }, [name]);

  const onDone = React.useCallback(() => {
    const event = new Event("input", { bubbles: true });
    input.dispatchEvent(event);
    clearCopyTextValue();
    setShowDialog(false);
  }, [input]);

  const handlePaste = React.useCallback(
    (e) => {
      if (!copyText || !input) return;

      const value = input.value;

      if (value.length === 0) {
        onPaste(input.name, copyText);
        onDone();
        return;
      }

      setShowDialog(true);
    },
    [copyText, input, onDone, onPaste]
  );

  const handlePasteOption = React.useCallback(
    (type) => {
      if (!copyText || !input) return;

      const value = input.value;

      switch (type) {
        case "ADD_BEFORE": {
          let val = `${copyText} ${value}`;
          onPaste(input.name, val);
          onDone();
          return;
        }
        case "ADD_AFTER": {
          let val = `${value} ${copyText}`;
          onPaste(input.name, val);
          onDone();
          return;
        }

        default: {
          onPaste(input.name, copyText);
          onDone();
          return;
        }
      }
    },
    [input, copyText, onDone, onPaste]
  );

  if (!input?.parentNode) return null;

  return (
    <>
      {ReactDOM.createPortal(
        <Styled.Button
          ref={buttonRef}
          type="button"
          onClick={handlePaste}
          data-tooltip-text="Paste"
          data-tooltip-placement="top"
          isVisible={!!copyText.toString().length > 0}
        >
          <IconBlock className="icon">
            <IconPaste />
          </IconBlock>
        </Styled.Button>,
        input.parentNode
      )}

      {showDialog && buttonRef?.current ? (
        <Styled.ActionDialog
          // hideOverlay
          anchorEl={buttonRef?.current}
          placement="right-start"
          appendTo={document.body}
          onOutsideClick={() => setShowDialog(false)}
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [0, 8],
              },
            },
          ]}
        >
          <div className="inner">
            <button
              className="option"
              onClick={() => handlePasteOption("REPLACE")}
              type="button"
            >
              Replace
            </button>

            <button
              className="option"
              onClick={() => handlePasteOption("ADD_BEFORE")}
              type="button"
            >
              Add Before
            </button>

            <button
              className="option"
              onClick={() => handlePasteOption("ADD_AFTER")}
              type="button"
            >
              Add After
            </button>
          </div>
        </Styled.ActionDialog>
      ) : null}
    </>
  );
};

export default PasteButton;
