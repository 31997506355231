import React from "react";
import { useRouteLoaderData } from "react-router-dom";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { ROUTE_OPTIONS } from "../../../../constant/route-options";
import { SETTING_PERMISSION_MODULES } from "../../../../constant/setting_permission_module";
import { Permission } from "../../../../helpers/permission";
import { useProjectStore } from "../../../../store/ProjectStore";
import { useTemplateGeneralStore } from "../../../../store/Setting/Templates/TemplateGeneralStore";
import FromGroup from "../../../controls/FromGroup";

import SettingPanel from "../../../ui-components/pages-ui/Setting/SettingPanel";
import SettingAccordion from "../../../ui-components/pages-ui/Setting/SettingPanel/SettingAccordion";
import AssignProjectTemplateForm from "../../../ui-components/pages-ui/Setting/Template/GenaralSettings/AssignProjectTemplateForm";
import ExistingProjectTemplate from "../../../ui-components/pages-ui/Setting/Template/GenaralSettings/ExistingProjectTemplate";

const GeneralTemplateSettings = () => {
  const { permission } = useRouteLoaderData(
    ROUTE_OPTIONS.setting.children["template-general-settings"].id
  );

  const moduleType = SETTING_PERMISSION_MODULES.Templates;

  const permissions = new Permission(permission);

  const projectPermission = permissions.findByName(moduleType.project.key);
  const assignProjectPermission = permissions.findByName(
    moduleType.AssignProject.key
  );
  const existingProjectPermission = permissions.findByName(
    moduleType.ExistingProject.key
  );

  // Breadcrumbs List
  const breadcrumbsList = [{ text: "Templates" }, { text: "General Settings" }];

  const useStore = useTemplateGeneralStore;
  // All project options
  const projectOptions = useProjectStore((state) => state.projects);
  const getProjectsByTenant = useProjectStore(
    (state) => state.getProjectsByTenant
  );

  const selectedProjectId = useStore((state) => state.selectedProjectId);
  const isFormExpanded = useStore((state) => state.isFormExpanded);

  const onProjectSelected = useStore((state) => state.onProjectSelected);
  const onToggleAccordion = useStore((state) => state.onToggleAccordion);
  const getTemplateOptions = useStore(
    (state) => state.onLoadTemplateTypeOptions
  );

  const onLoadJobCategoryOptions = useStore(
    (state) => state.onLoadJobCategoryOptions
  );

  const onLoadTemplatesOptions = useStore(
    (state) => state.onLoadTemplatesOptions
  );

  const onLoadTemplatesByProjectId = useStore(
    (state) => state.onLoadTemplatesByProjectId
  );

  // Load the project
  React.useEffect(() => {
    getProjectsByTenant();
  }, [getProjectsByTenant]);

  React.useEffect(() => {
    getTemplateOptions();
    onLoadJobCategoryOptions();
    onLoadTemplatesOptions();
  }, [getTemplateOptions, onLoadJobCategoryOptions, onLoadTemplatesOptions]);

  return (
    <SettingPanel breadcrumbs={breadcrumbsList}>
      {/* Select Projects */}
      {projectPermission.hasPermission ? (
        <SettingAccordion hideHeder={true}>
          <SettingAccordion.Card>
            <div className="row">
              <div className="col-12">
                <FromGroup className="mb-0" label="Select Project">
                  <SelectSearch
                    className="select-search w-100"
                    options={projectOptions}
                    value={selectedProjectId}
                    onChange={onProjectSelected}
                    search
                    filterOptions={fuzzySearch}
                    placeholder="Search Project..."
                  />
                </FromGroup>
              </div>
            </div>
          </SettingAccordion.Card>
        </SettingAccordion>
      ) : null}

      {/* When show project was selected */}

      {selectedProjectId && projectPermission.hasPermission ? (
        <>
          {/* Assign Project Template */}
          {projectPermission.permission.canAddNew ||
          projectPermission.permission.canEdit ? (
            <SettingAccordion
              title="Assign Project Template"
              isExpanded={isFormExpanded}
              onClick={onToggleAccordion}
              // actions={<CreateTemplateAction />}
            >
              <SettingAccordion.Card>
                <AssignProjectTemplateForm />
              </SettingAccordion.Card>
            </SettingAccordion>
          ) : null}

          {/* Existing Project Template */}
          {projectPermission.permission.canView ? (
            <SettingAccordion
              title="Existing Project Template"
              onClick={(isOpen) => {
                if (isOpen) {
                  onLoadTemplatesByProjectId();
                }
              }}
            >
              <SettingAccordion.Card>
                <ExistingProjectTemplate />
              </SettingAccordion.Card>
            </SettingAccordion>
          ) : null}
        </>
      ) : null}
    </SettingPanel>
  );
};

export default GeneralTemplateSettings;
