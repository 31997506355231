import * as React from "react";
import { memo } from "react";
const Plus = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    {...props}
  >
    <g clip-path="url(#clip0_14374_18833)">
      <path
        d="M6.5 0.5C6.23489 0.5 6.02003 0.714865 6.02003 0.979966V6.02003H0.979966C0.714856 6.02003 0.5 6.2349 0.5 6.5C0.5 6.7651 0.714865 6.97997 0.979966 6.97997H6.02003V12.02C6.02003 12.2851 6.2349 12.5 6.5 12.5C6.7651 12.5 6.97996 12.2851 6.97996 12.02V6.97997H12.02C12.2851 6.97997 12.5 6.7651 12.5 6.5C12.5 6.2349 12.2851 6.02003 12.02 6.02003H6.97996V0.979966C6.97996 0.714856 6.7651 0.5 6.5 0.5Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_14374_18833">
        <rect
          width="12"
          height="12"
          fill="currentColor"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(Plus);
export default Memo;
