import styled from "styled-components";
import Popover from "../../../../../../../common/components/Popover";
import { fontStyle } from "../../../../../../../styled/utility";

export const PopperContainer = styled(Popover)`
  background-color: var(--layout-background-white);
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
  border-radius: var(--layout-border-radius-8);
`;

export const DropdownWrapper = styled.div`
  max-width: 150px;
  min-width: 150px;
  padding: var(--layout-spacing-12);

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: var(--layout-spacing-2);

    li {
      ${fontStyle("x-small-regular")};
      transition: var(--animate-1);
      width: 100%;
      border-radius: var(--layout-border-radius-4);
      padding: var(--layout-spacing-4) var(--layout-spacing-12);
      color: var(--font-default-color);
      cursor: pointer;

      &.is__selected {
        background-color: #56B5BC;
        color: var(--font-default-white);
      }

      &:hover:not(.is__selected) {
        background-color: var(--layout-background-n30);
      }
    }
  }
`;
