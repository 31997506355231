import styled from "styled-components";
import {
  StyledBaseButton,
  StyledIconButton,
} from "../../../../../styled/Common/Buttons";
import { fontStyle } from "../../../../../styled/utility";

export const StyledCardWrapper = styled.div`
  --space-1: var(--layout-spacing-4);
  --space-2: var(--layout-spacing-8);
  --space-3: var(--layout-spacing-12);
  --space-4: var(--layout-spacing-16);
  --space-5: var(--layout-spacing-20);
  --size-6: var(--layout-size-24);

  --fw-600: ${({ theme }) => theme.fontWeight.medium};

  --font-size-title: ${({ theme }) => theme.fonts.title};
  --font-size-subtitle: ${({ theme }) => theme.fonts.body2};
  --font-size-11: ${({ theme }) => theme.fonts.body3};
  --font-size-9: ${({ theme }) => theme.fonts.small};

  --rounded-4: ${({ theme }) => theme.rounded("sm")};
  --rounded-8: ${({ theme }) => theme.rounded("md")};

  --text-color-dark-1: var(--font-color-shade-primary);
  --text-color-light-1: var(--font-tint-04);
  --text-color-light-2: #555e70;

  --card-bg-color: ${({ theme }) => theme.colors.white()};
  --card-border-color: ${({ theme }) => theme.colors.primary(10)};
  --card-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);

  --card-divided-color: ${({ theme }) => theme.colors.color_1(10)};

  --card-title-font-size: var(--font-size-title);
  --card-title-font-color: var(--text-color-dark-1);
  --card-title-font-weight: var(--fw-600);

  --card-subtitle-font-size: var(--font-size-subtitle);
  --card-subtitle-font-color: var(--text-color-light-1);

  --card-action-icon-color: ${({ theme }) =>
    theme.actionColors.action_color_0(20)};

  --card-action-icon-color-hover: ${({ theme }) =>
    theme.actionColors.action_color_0(30)};

  padding: var(--space-4);
  border-radius: var(--rounded-4);
  background-color: var(--card-bg-color);
  border: var(--card-border-color) 1px solid;
  position: relative;
  cursor: pointer;

  &:hover {
    .side__menu {
      opacity: 1;
      z-index: 1;
      transform: translateX(var(--size-6));
    }
  }

  .tag__card__inner {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
  }

  .side__menu__wr {
  }
`;

export const StyledTagType = styled.div`
  display: flex;
  align-items: center;
  gap: var(--layout-spacing-8);

  .type__icon {
    .button__icon {
      --icon-button-icon-color: var(--font-tint-04);
    }
  }

  .type__name {
    min-width: 0;

    span {
      ${fontStyle("small-regular")};
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;
      color: var(--font-tint-03, #a5a9b8);
      text-transform: capitalize;
    }
  }
`;

export const StyledEpisode = styled.div`
  border-bottom: 1px solid var(--card-divided-color);
  /* margin-bottom: var(--space-2); */
  /* padding-right: var(--space-4); */
  position: relative;
  display: flex;

  .episodes {
    display: flex;
    overflow: hidden;
    flex-grow: 1;

    .episode__inner {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      margin-bottom: 2px;
      gap: var(--space-1);
      position: relative;
    }

    .episode__item {
      display: block;
    }
  }

  .actions {
    width: var(--space-4);
    min-width: var(--space-4);
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
`;

export const StyledArrowBtn = styled(StyledBaseButton)`
  height: 10px;
  width: 100%;
  font-size: 7px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.05);

  &.prev {
    transform: rotate(180deg);
  }

  &:disabled {
    opacity: 0.45;
  }
`;

export const StyledEpisodeButton = styled(StyledBaseButton)`
  --episode-bg-color: transparent;
  --episode-text-color: ${({ theme }) => theme.actionColors.action_color_0(40)};

  border-top-left-radius: var(--rounded-4);
  border-top-right-radius: var(--rounded-4);
  font-size: var(--font-size-11);

  color: var(--episode-text-color);

  padding: 2px 8px;
  width: ${({ theme }) => theme.sizes(7)};
  max-width: ${({ theme }) => theme.sizes(7)};
  background-color: var(--episode-bg-color);

  &.is--active {
    --episode-text-color: ${({ theme }) => theme.actionColors.action_color_6()};
    --episode-bg-color: ${({ theme }) => theme.actionColors.action_color_6(20)};
    --episode-text-color: ${({ theme }) => theme.actionColors.action_color_6()};
    font-weight: var(--fw-600);
  }

  &:hover {
    --episode-bg-color: ${({ theme }) => theme.actionColors.action_color_6(10)};
  }
`;

/* 
  Card Header styles
*/

export const StyledCardHeader = styled.div`
  position: relative;
  padding-left: var(--space-4);
  /* padding-bottom: var(--space-2); */
  /* border-bottom: 1px solid;
  border-bottom-color: ${({ showBorder }) =>
    showBorder ? "var(--card-divided-color)" : "transparent"}; */

  .top {
    display: flex;
    align-items: center;
    position: relative;

    .title__wr {
      flex-grow: 1;
      display: flex;
      flex-grow: 1;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding-right: var(--space-3);

      .title {
        text-overflow: ellipsis;
        overflow: hidden;
        /* white-space: nowrap; */
        color: var(--card-title-font-color);
        font-weight: var(--card-title-font-weight);
        /* cursor: pointer; */

        /* &:hover {
          text-decoration: underline;
        } */
      }
    }

    .action__wr {
      display: flex;
      align-items: center;
      gap: var(--space-2);
    }
  }

  .bottom {
    max-height: calc(1.5em * 2);
    margin-top: var(--space-1);
    color: var(--text-color-light-1);
    overflow: hidden;

    .contents {
      margin-top: var(--space-1);
    }

    .subtitle,
    .contents {
      max-height: var(--layout-size-40);
      overflow: hidden;
    }
  }
`;

export const StyledCardStatus = styled.div`
  width: 8px;
  height: 8px;
  border-radius: var(--rounded-8);
  background-color: ${({ statusColor }) => statusColor || "red"};
  position: absolute;
  /* top: var(--space-1); */
  left: ${({ theme }) => theme.spacing(-4)};
`;

export const StyledTodoStatus = styled(StyledCardStatus)`
  width: 4px;
  height: 4px;
  background-color: ${({ theme }) => theme.colors.action_color_4()};
  top: -2px;
  right: -2px;
  left: unset;
`;

export const StyledActionBtn = styled(StyledIconButton)`
  --cta-icon-btn-bg-color: transparent;
  --cta-icon-btn-size: var(--size-4);
  --cta-icon-btn-radius: 2px;
  color: var(--card-action-icon-color);
  position: relative;

  &:hover {
    --cta-icon-btn-bg-color: transparent;
    color: var(--card-action-icon-color-hover);
  }
`;

/* 
  Card Content styles
*/
