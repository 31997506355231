import React from "react";
import * as Styled from "./styled";
import { FormGrid } from "../styled";
import FieldGroup from "../../../../../../../../common/components/Form/FieldGroup";
import InputField from "../../../../../../../../common/components/Form/InputField";
import Chips from "../../../../../../../../common/components/UI/Chips";

const StepFive = () => {
  return (
    <Styled.Container>
      <FormGrid>
        <div className="grid-item full">
          <FieldGroup label="Departments" className="field__group">
            <InputField
              name="Departments"
              placeholder="Departments (ex: Accounts)"
            />
          </FieldGroup>
          <div className="chips__container">
            <div className="chips__row">
              <Chips className="chips__svg" value="Research" />
              <Chips className="chips__svg" value="Products" />
              <Chips className="chips__svg" value="Amounts" />
              <Chips className="chips__svg" value="Research" />
            </div>
            <div className="chips__row">
              <Chips className="chips__svg" value="Amounts" />
              <Chips className="chips__svg" value="Research" />
            </div>
          </div>
        </div>
      </FormGrid>
    </Styled.Container>
  );
};

export default StepFive;
