import styled from "styled-components";
import { fontStyle } from "../../../../styled/utility";

export const HeaderOuter = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  max-width: var(--rdp-max-width);
  width: var(--rdp-max-width);
`;

export const CalendarHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: var(--layout-spacing-20);

  .left {
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .right {
  }

  .arrow-btn {
    background-color: #ebebf0;
    border-radius: 50%;

    &:hover {
      background-color: var(--font-tint-02);
    }
  }

  select {
    border: none;
    ${fontStyle("small-medium")};
    font-weight: 500;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0 4px;
    cursor: pointer;
  }
`;
