import React from "react";
import { Link } from "react-router-dom";

import { styleClass } from "../../../utility";

const BreadcrumbLink = ({ to = undefined, label, className, iconClassName='icon-lp-arrow-right-line' }) => {

  const cls = styleClass(" crumb__item icon", iconClassName,className);

  if (to) {
    return (
      <Link className={cls} to={to}>
        {label}
      </Link>
    );
  }

  return <span className={cls}>{label}</span>;
};

export default BreadcrumbLink;
