import * as React from "react";
import { memo } from "react";
const Svg02 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 0C5.87824 0 3.84336 0.84288 2.3432 2.3432C0.842944 3.84352 0 5.8784 0 8C0 10.1216 0.84288 12.1566 2.3432 13.6568C3.84352 15.1571 5.8784 16 8 16C10.1216 16 12.1566 15.1571 13.6568 13.6568C15.1571 12.1565 16 10.1216 16 8C15.9977 5.87904 15.1541 3.84544 13.6542 2.34576C12.1544 0.846016 10.121 0.0024 8 0ZM8 14.4C6.3944 14.4032 4.84656 13.8017 3.66432 12.7154C2.48213 11.6289 1.75232 10.1371 1.62016 8.53712C1.48804 6.93696 1.96322 5.34576 2.95117 4.08016L11.92 13.0498C10.8009 13.9252 9.4208 14.4005 8 14.4ZM13.0488 11.92L4.08 2.9504C5.30888 1.98784 6.84768 1.50846 8.40576 1.60283C9.96389 1.69721 11.4336 2.3587 12.5373 3.46251C13.641 4.56632 14.3024 6.03611 14.3966 7.59419C14.4908 9.15232 14.0115 10.691 13.0488 11.92Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg02);
export default Memo;
