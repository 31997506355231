import API_Common from "../../../services/api-common";
import API_ENDPOINTS from "../../../services/api-endpoints";
import { setAllChats } from "../Store/ChatBaseStore";

export const getAllChats = (projectId, userId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Common.get(
      `${endpoints.GET_ALL_CAHTS}?projectId=${projectId}&userId=${userId}`
    )
      .then((res) => {
        console.log("GET_ALL_CAHTS :::", res.data.result.allChats);
        setAllChats(res.data.result.allChats);
        resolve(res.data.result.allChats);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const getSearchAllChats = (projectId, userId, query) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Common.get(
      `${endpoints.GET_ALL_CAHTS}?projectId=${projectId}&userId=${userId}&searchText=${query}`
    )
      .then((res) => {
        console.log("GET_ALL_CAHTS SEARCH :::", res);
        setAllChats(res.data.result.allChats);
        resolve(res.data.result.allChats);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const getExitingIndivudualChats = (
  projectId,
  userId,
  groupId,
  loggedUserId
) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Common.get(
      `${endpoints.CHECK_AND_GET_CHAT_DATA}?projectId=${projectId}&groupId=${groupId}&loggedUserId=${loggedUserId}&userId=${userId}`
    )
      .then((res) => {
        // console.log("CHECK_AND_GET_CHAT_DATA :::", res);
        // setAllChats(res.data.result.allChats);
        resolve(res.data.result);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
