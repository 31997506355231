import React from "react";
import "./styles.scss";

const TextInputWrapper = (props) => {
  const { children, iconClass, rightAction } = props;

  const hasRightAction = rightAction ? "text__input__right--action" : null;

  return (
    <div className={["text__input", hasRightAction].join(" ")}>
      <i
        aria-hidden="true"
        className={["input-icon fa-regular", iconClass].join(" ")}
      ></i>
      {children}
      {/* right side */}
      {rightAction ? <div className="right-action">{rightAction}</div> : null}
    </div>
  );
};

export default TextInputWrapper;
