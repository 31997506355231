import React from "react";
import RejectIcon from "../../../../../../../common/components/icons/Line/Close/04";
import AcceptIcon from "../../../../../../../common/components/icons/icon-check-sm";
import IconButton from "../../../../../../../common/components/Buttons/IconButton";
import WarningIcon from "../../../../../../../common/components/icons/Line/Warning/01";

import {
  toastContainer,
  toastHeader,
  toastTitle,
  toastButtons,
  redButton,
  greenButton,
  toastInfo,
  toastAuthor,
  toastMessage,
  toastIcon,
  RejectAcceptIcon,
} from "./style";

const ChangeRequestToast = ({
  onAccept,
  onReject,
  RequestMessage,
  RequestTitle,
  RequestAuthor,
  RequestedTime,
}) => {
  return (
    <div style={toastContainer}>
      <div style={toastHeader}>
        <div style={toastIcon}>
          <WarningIcon width={"23px"} height={"23px"} />
        </div>

        <span style={toastTitle}>{RequestTitle}</span>
        <div style={toastButtons}>
          <div style={redButton}>
            <IconButton
              className="cell-status-icon"
              icon={
                <div style={RejectAcceptIcon}>
                  <RejectIcon width={"10px"} height={"10px"} />
                </div>
              }
              as="div"
              btnSize={20}
              btnIconSize={10}
              onClick={onReject}
            />
          </div>

          <div style={greenButton}>
            <IconButton
              className="cell-status-icon"
              icon={
                <div style={RejectAcceptIcon}>
                  <AcceptIcon />
                </div>
              }
              as="div"
              btnSize={20}
              btnIconSize={20}
              onClick={onAccept}
            />
          </div>
        </div>
      </div>

      <div style={toastInfo}>
        <span style={toastAuthor}>
          {RequestAuthor} | {RequestedTime}
        </span>
      </div>

      <div style={toastMessage}>
        <p>{RequestMessage}</p>
      </div>
    </div>
  );
};

export default ChangeRequestToast;
