import React from "react";
import { Formik } from "formik";

import { getInputs } from "./utility";
import Tabs from "../Tabs";
import InputControl from "./InputControl";
import InputsList from "./InputsList";

import * as Styled from "../styled";
import { TAG_MODAL_MODE } from "../../../../../store/ResearchBoardStore";
import UIButton from "../../../../../../../common/components/Buttons/UIButton";
import useIcon from "../../../../../../../common/components/IconSelector/hooks/useIcon";
import { FieldTypes } from "../../../../../../../constant/FieldTypes";

const getFromData = (inputs, formData) => {
  const __values = [];

  for (let i = 0; i < inputs.length; i++) {
    for (let j = i; j < formData.length; j++) {
      if (String(inputs[i].id()) === formData[j].tagFieldId) {
        const __field = inputs[i];

        __values.push({
          tagFieldId: __field.id(),
          fieldTypeId: __field.fieldType().id(),
          tagSubTypeId: __field.tagSubTypeId(),
          displayTypeId: __field.displayTypeId(),
          visualTypeId: __field.visualTypeId(),
          layoutTypeId: __field.layoutTypeId(),
          displayName: __field.label(),
          tagSubTypeName: __field.tagSubTypeName(),
          icon: __field.icon() || "",
          value: formData[j].value,
        });
      }
    }
  }

  return __values;
};

const getRowData = (values) => {
  const rowFormData = [];

  for (let key in values) {
    const tagFieldId = key.split("__")[1];
    rowFormData.push({
      tagFieldId,
      value: values[key],
    });
  }

  return rowFormData;
};

const TagForm = ({
  data,
  onSubmit,
  mode,
  onCancel,
  sectionVisibilityStatus,
  isLocked,
  currentFocusTab,
  setMainField,
  showMainField,
  setShouldClose,
}) => {
  // If form fields are not available
  // then return
  const fieldList = data?.fields || null;
  const findIcon = useIcon();

  const tabItems = React.useMemo(() => {
    const items = sectionVisibilityStatus;
    // const items = data?.tabItems;

    if (!items)
      return {
        data: [],
        canShow: false,
        firstItem: null,
      };

    // const _data = items.filter((group) => group.fieldCount > 0);

    return {
      data: items,
      canShow: items.some((d) => d.visibility === true),
      firstItem: items.find((d) => d.visibility === true) || null,
    };
  }, [sectionVisibilityStatus]);

  const formInputs = React.useMemo(() => {
    let inputsObj = getInputs(fieldList);
    return inputsObj;
  }, [fieldList]);

  const onSubmitHandler = (values, actions) => {
    let val = {};
    let unsortedValues = [];

    for (const key in values) {
      if (key.includes("customFileUploadManager")) {
        const getUnsortedValues =
          values[key]?.value?.filter((f) => !f.isSorted) || [];

        if (getUnsortedValues.length > 0) {
          unsortedValues.push({
            ...values[key],
            value: getUnsortedValues,
          });
        }
      } else if (key.includes("customGroupField")) {
      } else {
        val[key] = values[key];
      }
    }

    const rowFormData = getRowData(val);
    const formData = getFromData(formInputs.inputs, rowFormData);

    onSubmit && onSubmit(formData, actions, unsortedValues);
  };

  const hideTopBorder = React.useMemo(() => {
    if (mode !== TAG_MODAL_MODE.Edit) return false;

    const requiredFieldCount = formInputs?.inputs?.filter(
      (field) =>
        field.isRequired() && field.fieldType().id() !== FieldTypes.GroupFields
    ).length;

    return requiredFieldCount === 1 && !showMainField ? true : false;
  }, [formInputs?.inputs, mode, showMainField]);

  if (fieldList && Object.keys(fieldList).length === 0) {
    return null;
  }

  if (!formInputs?.initialValues) return null;

  return (
    <Formik
      initialValues={formInputs.initialValues}
      validationSchema={formInputs.validationSchema}
      onSubmit={onSubmitHandler}
      enableReinitialize={true}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Styled.FormContainer>
              {/* Controls */}
              <Styled.FormControls>
                <Styled.BasicControllers
                  className="basic"
                  $mode={mode}
                  $showField={showMainField}
                >
                  <InputsList
                    inputs={formInputs.inputs}
                    showRequiredItems
                    render={(field, i) => (
                      <InputControl
                        setMainField={setMainField}
                        key={field.id()}
                        errors={errors}
                        field={field}
                        handleChange={handleChange}
                        touched={touched}
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    )}
                  />
                </Styled.BasicControllers>

                {/* Render Tabs  */}

                <Styled.ControllerAccordion
                  hidden={!tabItems.canShow}
                  $hideTopBorder={hideTopBorder}
                >
                  <Tabs
                    activeTab={String(
                      currentFocusTab || tabItems.firstItem?.id || ""
                    ).toLowerCase()}
                  >
                    {tabItems.data.map((tab, i) => {
                      const Icon = findIcon(tab?.icon || "fill-users");

                      return (
                        <Tabs.Panel
                          key={i}
                          tabId={String(tab.id).toLowerCase()}
                          title={tab.name}
                          icon={<Icon />}
                          visibility={tab.visibility}
                        >
                          <InputsList
                            inputs={formInputs.inputs}
                            tabId={tab.id}
                            render={(field, i) => (
                              <InputControl
                                tabId={tab.id}
                                key={i}
                                errors={errors}
                                field={field}
                                handleChange={handleChange}
                                touched={touched}
                                values={values}
                                setFieldValue={setFieldValue}
                              />
                            )}
                          />
                        </Tabs.Panel>
                      );
                    })}
                  </Tabs>
                </Styled.ControllerAccordion>
              </Styled.FormControls>

              {/* 
                Action
               */}
              <Styled.FormAction>
                {mode === TAG_MODAL_MODE.Edit ? (
                  tabItems.canShow || !hideTopBorder ? (
                    <div className="line"></div>
                  ) : null
                ) : (
                  <div className="line"></div>
                )}

                <div className="inner">
                  <UIButton
                    type="submit"
                    variant={UIButton.Variant.Primary}
                    size={UIButton.Sizes.Small}
                    className="btn__submit"
                    disabled={isLocked}
                  >
                    {mode === TAG_MODAL_MODE.Edit ? "Update" : "Create"}
                  </UIButton>

                  <UIButton
                    type="button"
                    variant={UIButton.Variant.Primary}
                    shade="200"
                    size={UIButton.Sizes.Small}
                    className="btn__submit__close"
                    disabled={isLocked}
                    onClick={() => {
                      handleSubmit();
                      setShouldClose(true);
                    }}
                  >
                    {mode === TAG_MODAL_MODE.Edit ? "Update" : "Create"} & Close
                  </UIButton>
                </div>
              </Styled.FormAction>
            </Styled.FormContainer>
          </form>
        );
      }}
    </Formik>
  );
};

export default TagForm;
