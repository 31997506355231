import styled from "styled-components";
import { fontStyle } from "../../../../../../styled/utility";

export const FormContainer = styled.div`
  .form__content {
    /* margin-top: 40px; */
    background-color: var(--layout-background-n20);
    margin-bottom: var(--layout-spacing-28);
  }

  .details{
    ${fontStyle("small-bold")};
    padding:0;
  }

  .logo{
    width: 100%;
  }

  .logo___wrapper{
    max-width: 135px;

  }

  .logo__details{
    color: var(--font-color-shade-primary);
  }
`;

export const FieldGroupLabel = styled.label`
  color: var(--font-default-color);
`;
