import React from "react";
import Table from "../Table";
import { useMilestone } from "../../context/MilestoneContext";

const MilestoneTable = () => {
  const { sections } = useMilestone();
  return (
    <div>
      <Table data={sections}>
        <Table.Head />
        <Table.Body />
      </Table>
    </div>
  );
};

export default MilestoneTable;
