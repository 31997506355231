import styled, { css } from "styled-components";
import { ViewTypes } from ".";

const horizontalLayout = css`
  flex-direction: column;

  .title {
    margin-bottom: var(--layout-spacing-2);
  }
`;

const horizontalIconLayout = css`
  flex-direction: column;

  .start {
    margin-left: var(--layout-spacing-minus-2);
  }
  .title {
    margin-bottom: var(--layout-spacing-2);
  }
`;

const verticalLayout = css`
  flex-direction: column;

  .title {
    padding-right: var(--layout-spacing-12);

    .title__text {
      margin-top: 3px;
    }
  }

  .content {
    flex-grow: 1;
    flex-direction: column;
    gap: var(--layout-spacing-2);
  }
`;

const verticalCompoundLayout = css`
  flex-direction: column;

  .title {
    padding-right: var(--layout-spacing-12);

    .title__text {
      margin-top: 3px;
    }
  }

  .content {
    flex-grow: 1;
    flex-direction: column;
    gap: var(--layout-spacing-2);
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  width: 100%;

  ${({ viewType }) => {
    switch (viewType) {
      case ViewTypes.Vertical:
        return verticalLayout;

      case ViewTypes.HorizontalIcon:
        return horizontalIconLayout;

      case ViewTypes.VerticalCompound:
        return verticalCompoundLayout;

      // default horizontal layout 0
      default:
        return horizontalLayout;
    }
  }};

  &:not(:last-child) {
    padding-bottom: var(--layout-spacing-12);
  }

  .title {
    white-space: nowrap;
    
  }

  .content {
    display: flex;
    flex-direction: row;

    .start {
      padding-right: var(--layout-spacing-8);
    }

    .end {
      display: block;
      flex-direction: column;
      flex-grow: 1;
      color: ${({ theme }) => theme.colors.primary_dark()};
      gap: var(--layout-spacing-8);
      word-break: break-all;
      

      .file__wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--layout-spacing-2);
      }
    }

    .link__item {
      /* &:not(:first-child) {
        margin-top: var(--layout-spacing-4);
      } */
      a {
        text-decoration: underline;
        display: inline-block;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
`;
