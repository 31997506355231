import React from "react";
import { addYears } from "date-fns";
import { DayPicker } from "react-day-picker";

// Day picker css
import "react-day-picker/dist/style.css";

import DatePickerHeader from "./CalendarHeader";
import { CalendarWrapper } from "./styled";

const FromDate = new Date(1920, 0, 1);
const ToDate = addYears(new Date(), 50);

const Calendar = (props) => {
  // Props
  const {
    fromDate,
    toDate,
    fixedWeeks = true,
    showOutsideDays = true,
    ...restProps
  } = props;

  const [from, setFromDate] = React.useState(FromDate);
  const [to, setToDate] = React.useState(ToDate);

  React.useEffect(() => {
    if (fromDate) {
      setFromDate(fromDate);
    }

    if (toDate) {
      setToDate(toDate);
    }
  }, [fromDate, toDate]);

  return (
    <CalendarWrapper>
      <DayPicker
        ISOWeek
        showOutsideDays={showOutsideDays}
        weekStartsOn={1}
        fromDate={from}
        toDate={to}
        fixedWeeks={fixedWeeks}
        components={{
          Caption: DatePickerHeader,
        }}
        {...restProps}
      />
    </CalendarWrapper>
  );
};

export default Calendar;
