import styled from "styled-components";
import { StyledBaseButton } from "../../../../../../styled/Common/Buttons";

export const StyledViewSwitcher = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const StyledSwitch = styled(StyledBaseButton)`
  --sw-size: ${({ theme }) => theme.sizes(6)};
  --sw-active: ${({ isActive }) => (isActive ? 1 : 0.5)};
  --sw-bg-color:${({ theme }) => theme.colors.color_1(10)};

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: var(--sw-size);
  height: var(--sw-size);
  opacity: var(--sw-active);
  background-color: var(--sw-bg-color);


  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
