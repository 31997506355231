import { Swiper as SlideContainer, SwiperSlide as Slide } from "swiper/react";

import { useAssigneeContext } from "../AssigneeContext";

import AssigneeItem from "./AssigneeItem";
import * as Styled from "./styled";
import { useScheduleContext } from "../../../context/ScheduleContext";

function AssigneeList() {
  const { selectedItem, handleSelectItem } = useAssigneeContext();
  const { availableMembersData } = useScheduleContext();

  return (
    <Styled.AssigneeList>
      <SlideContainer
        spaceBetween={4}
        slidesPerView="auto"
        className="assignee__swiper"
        touchStartPreventDefault={false}
      >
        {/* {availableMembersData.map((member, i) => (
          <Slide key={i}>
            <AssigneeItem
              data={member}
              isSelected={selectedItem?.key === i}
              onSelect={(e) => {
                handleSelectItem({
                  key: i,
                  payload: { id: i },
                  htmlElement: e.currentTarget,
                });
              }}
            />
          </Slide>
        ))} */}
        {availableMembersData.map((member, i) => {
          return (
            <Slide key={i}>
              <AssigneeItem
                data={member}
                isSelected={selectedItem?.key === i}
                onSelect={(e) => {
                  handleSelectItem({
                    key: i,
                    payload: { ...member },
                    htmlElement: e.currentTarget,
                  });
                }}
              />
            </Slide>
          );
        })}
      </SlideContainer>
    </Styled.AssigneeList>
  );
}

export default AssigneeList;
