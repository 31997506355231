import React from "react";

import { useNavigation } from "react-day-picker";
import * as Styled from "./styled";
import Months from "./Months";
import Years from "./Years";
import IconButton from "../../Buttons/IconButton";
import IconLeft from "../../icons/Line/arrow-left";
import IconRight from "../../icons/Line/arrow-right";

const CalendarHeader = (props) => {
  const { previousMonth, nextMonth, goToMonth } = useNavigation();

  const onChangeHandler = (newMonth) => {
    goToMonth(newMonth);
  };

  return (
    <Styled.CalendarHeaderContainer aria-labelledby={`${props.id}`}>
      <div className="left">
        <IconButton
          className="arrow-btn"
          icon={<IconLeft />}
          btnIconSize={12}
          onClick={() => {
            goToMonth(previousMonth);
          }}
        />
      </div>

      <div className="center">
        <Months displayMonth={props.displayMonth} onChange={onChangeHandler} />
        <Years displayMonth={props.displayMonth} onChange={onChangeHandler} />
      </div>

      <div className="right">
        <IconButton
          className="arrow-btn"
          icon={<IconRight />}
          btnIconSize={12}
          onClick={() => {
            goToMonth(nextMonth);
          }}
        />
      </div>

    </Styled.CalendarHeaderContainer>
  );
};

export default CalendarHeader;
