import React from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AuthCardLayout from "../../components/AuthLayout";
import LoginForm from "./LoginForm";
import FooterContent from "../../components/FooterContent";
import { useAppStore } from "../../../../store/AppStore/AppStore";
import { ROUTE_OPTIONS } from "../../../../constant/route-options";
import { WebStorage } from "../../../../utility/module/WebStorage";
import { PublicRoutePath } from "../../../../routes/paths/public-path";
import * as LoginAPI from "../../api/login";
import { useProjectStore } from "../../../../store/ProjectStore";
import { setCurrentTenant } from "../../../../store/GlobalStore";
import { STORAGE_KEYS } from "../../../../constant/System";

const Login = () => {
  const navigate = useNavigate();

  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);
  const getProjectsByTenant = useProjectStore(
    (state) => state.getProjectsByTenant
  );
  const onLinkClick = () => {
    window.location.href = "/create-account";
  };

  // Role Mutation
  const userRoleMutation = useMutation(
    (id) => {
      return LoginAPI.getUserRolesById(id);
    },
    {
      onSuccess: (roles) => {
        WebStorage.set("user-roles", roles);
      },
    }
  );

  const setUserLocalData = React.useCallback(
    (data) => {
      WebStorage.set("user", data);
      userRoleMutation.mutate(data?.result?.userId);
    },
    [userRoleMutation]
  );

  const firstLogin = React.useCallback(
    (data, email) => {
      setUserLocalData(data);

      const profilePath = `${ROUTE_OPTIONS.setting.path}/${ROUTE_OPTIONS.setting.children["my-profile"].path}`;

      navigate(profilePath, {
        replace: true,
        state: { email },
      });
    },
    [navigate, setUserLocalData]
  );

  const redirectToVerificationPage = React.useCallback(
    (email) => {
      toast.error("Your email has not been verified!", {
        onClose: () => {
          navigate(PublicRoutePath.Verification.path, {
            replace: true,
            state: { email },
          });
        },
      });
    },
    [navigate]
  );

  const onLoginSuccess = React.useCallback(
    (data, variables) => {
      hideLoader();

      if (!data) {
        return;
      }

      if (!data.isSuccess) {
        toast.error(data?.displayMessage);
        return;
      }
      // Set current tenant
      setCurrentTenant(data?.result?.tenantList, data?.result?.tenantId);

      WebStorage.set(
        STORAGE_KEYS.CURRENT_PROJECT_ID,
        data?.result?.currentProject?.projectId || 0
      );

      // toast.success(data?.displayMessage);

      // Check if the user is an Invited user and is a First Login.
      // If it's true then redirect to the change password page;
      const isFirstLogin =
        data?.result && data?.result?.isInvited && data?.result?.isFirstLogin;

      if (isFirstLogin) {
        firstLogin(data, variables.email);
        return;
      }

      // Check if the user is verified or not.
      // If it's true then redirect to the verification page;
      if (data?.result && !data?.result?.isAccountVerified) {
        redirectToVerificationPage(variables.email);
        return;
      }

      // Set user data to Local Storage
      setUserLocalData(data);
      getProjectsByTenant();
      navigate(`/${ROUTE_OPTIONS.dashboard.path}`, { replace: true });

      // TODO:
      // if (redirectUrl && redirectUrl.length > 1) {
      //   Check if the redirect route status is available.
      //   navigate(`${redirectUrl}`, { replace: true });
      // } else {
      //   navigate(`/${ROUTE_OPTIONS.dashboard.path}`, { replace: true });
      // }
    },
    [
      firstLogin,
      getProjectsByTenant,
      hideLoader,
      navigate,
      redirectToVerificationPage,
      setUserLocalData,
    ]
  );

  // Login Mutation
  const loginMutation = useMutation(
    (payload) => {
      return LoginAPI.doLogin(payload);
    },
    {
      onMutate: () => showLoader(),
      onError: () => hideLoader(),
      onSuccess: onLoginSuccess,
    }
  );

  const notifyUserStatus = React.useCallback(() => {
    const userStatus = WebStorage.get("user-status");

    if (userStatus !== null)
      if (userStatus === -1) {
        // toast.error("invalid username or password");
        WebStorage.set("user-status", null);
      }
  }, []);

  // Reset Storage
  React.useEffect(() => {
    WebStorage.set("user", null);
    WebStorage.set("user-roles", null);
    notifyUserStatus();
  }, [notifyUserStatus]);

  const handleResetPasswordClick = () => {
    //navigate(PublicRoutePath.ResetPassword.path);
    try {
      showLoader();
      const resetLink = process.env.REACT_APP_AZURE_RESET_PASSWORD;
      console.log(resetLink);
      const redirectURL = window.location.origin + "/login";
      console.log(redirectURL);
      const newResetLink = resetLink.replace("#", redirectURL);

      hideLoader();
      console.log(newResetLink);
      window.location.replace(newResetLink);
    } catch (error) {
      console.error("Error redirecting to reset password link: ", error);
      hideLoader();
      // redirectToLogin();
    }
  };

  const layoutFoot = (
    <FooterContent
      textContent="Forgot your password?"
      linkText="Reset now"
      onLinkClick={handleResetPasswordClick}
    />
  );

  return (
    <>
      <AuthCardLayout
        title="Log into your account"
        subTitle="to create, edit or manage projects"
        layoutFooter={layoutFoot}
      >
        {/* Login Form */}
        <LoginForm onSubmit={(payload) => loginMutation.mutate(payload)} />

        {/* Social Logins */}
        {/* <SocialLoginContent /> */}

        {/* Footer contents */}
        <FooterContent
          textContent="Don’t have an account?"
          linkText="SignUp"
          onLinkClick={onLinkClick}
        />
      </AuthCardLayout>
    </>
  );
};

export default Login;
