import React from "react";
import * as Styled from "./styled";

const list = [
  {
    isSelected: false,
    type: "notAvailable",
    text: "Not Available",
  },
  {
    isSelected: false,
    type: "confirm",
    text: "Confirm",
  },
  // {
  //   isSelected: false,
  //   type: "public",
  //   text: "Public",
  // },
  {
    isSelected: false,
    type: "tentative",
    text: "Tentative",
  },
];

const DaySelectOption = (props) => {
  const {
    selectedType = null,
    onOptionSelect,
    selectedDayPayload,
    anchorEl,
    isShow,
    closeOutsideClick,
  } = props;

  const [currentSelectedType, setCurrentSelectedType] =
    React.useState(selectedType);

  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    if (isShow) {
      const options = list.map((item) => {
        if (item.type === currentSelectedType) {
          return {
            ...item,
            isSelected: true,
          };
        }

        return {
          ...item,
          isSelected: false,
        };
      });

      setOptions(options);
    }
  }, [currentSelectedType, isShow]);

  React.useEffect(() => {
    if (isShow) {
      if (selectedDayPayload?.selectedType) {
        setCurrentSelectedType(selectedDayPayload?.selectedType);
      }
    }
  }, [selectedDayPayload, isShow]);

  const onClickHandler = (type) => {
    setCurrentSelectedType(type);

    onOptionSelect &&
      onOptionSelect({
        selectedType: type,
        day: selectedDayPayload.day,
        modifiers: selectedDayPayload.modifiers,
      });

    setCurrentSelectedType(null);
  };

  const render = (
    <Styled.DropdownWrapper>
      <ul>
        {options.map((item, i) => {
          if (item.isSelected) {
            return (
              <li
                role="button"
                key={i}
                onClick={() => onClickHandler(item.type)}
                className="is__selected"
              >
                {item.text}
              </li>
            );
          }

          return (
            <li role="button" key={i} onClick={() => onClickHandler(item.type)}>
              {item.text}
            </li>
          );
        })}
      </ul>
    </Styled.DropdownWrapper>
  );

  return (
    <>
      {isShow ? (
        <Styled.PopperContainer
          anchorEl={anchorEl}
          // appendTo="#schedule__calendar"
          // hideOverlay={true}
          showArrow={true}
          onOutsideClick={closeOutsideClick}
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [0, 8],
              },
            },
          ]}
        >
          {render}
        </Styled.PopperContainer>
      ) : null}
    </>
  );
};

export default DaySelectOption;
