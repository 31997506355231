import React from "react";
import UIButton from "../../../../../common/components/Buttons/UIButton";
import FieldGroup from "../../../../../common/components/Form/FieldGroup";
import InputField from "../../../../../common/components/Form/InputField";
import * as LineIcon from "../../../../../common/components/icons/Line";
import * as FillIcon from "../../../../../common/components/icons/Fill";
import { ValidationMessage } from "../../../../../utility/validation-message";

const CreateNewPass = () => {
  return (
    <form>
      <div className="form__content">
        <FieldGroup label="New Password" error={""}>
        <InputField
            name="password"
            startIcon={<LineIcon.Access1 />}
            type="password"
            // value={values.password}
            // onChange={handleChange}
          />
        </FieldGroup>
        <FieldGroup label="Re-type Password" error={""}>
        <InputField
            name="password"
            startIcon={<LineIcon.Access1 />}
            type="password"
            // value={values.password}
            // onChange={handleChange}
          />
        </FieldGroup>
      </div>

      <div className="form__actions">
        <UIButton type="submit" fullWidth > Create New Password</UIButton>
      </div>
    </form>
  )
}

export default CreateNewPass