import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Modal from "../components/controls/modals/modal";
import close_icon from "../components/img/close-circle.png";
import useUserData from "../hooks/useUserData";

import { useProfileMissedFields } from "../hooks/useProfileMissedFields";
import { ROUTE_OPTIONS } from "../constant/route-options";
import useUserRolesData from "../hooks/useUserRolesData";
import { SYSTEM_ROLE_TYPE } from "../constant/System";
import AlertDialog from "../components/ui-components/Dialog/AlertDialog";
import Button, {
  BUTTON_ICONS,
} from "../components/ui-components/Buttons/Button";

const hocOptions = { validateOnPageLoad: true, page: null };

const validateUserRoles = [
  SYSTEM_ROLE_TYPE.USER,
  SYSTEM_ROLE_TYPE.DOP,
  SYSTEM_ROLE_TYPE.PD,
  SYSTEM_ROLE_TYPE.LA,
  SYSTEM_ROLE_TYPE.PM,
];

const withProfileUpdateAlert = (
  WrapperComponent,
  options = { validateOnPageLoad: true, page: null }
) => {
  // merge options
  options = {
    ...hocOptions,
    ...options,
  };

  return (props) => {
    // Local status
    const [showDialog, setShowDialog] = useState(false);
    const [incompleteFileds, setIncompleteFields] = useState([]);
    const { pathname } = useLocation();
    const navigate = useNavigate();

    // Custom hooks
    const { userId } = useUserData();
    const { userRoles } = useUserRolesData();
    const { fields } = useProfileMissedFields();

    const pageType = options?.page || null;

    const shouldUserValidate = React.useMemo(() => {
      return (
        userRoles &&
        userRoles?.length !== 0 &&
        userRoles.some((role) => validateUserRoles.includes(role))
      );
    }, [userRoles]);

    // Functions
    const gotoProfile = React.useCallback(
      (e) => {
        setShowDialog(false);
        navigate(
          `${ROUTE_OPTIONS.setting.path}/${ROUTE_OPTIONS.setting.children["my-profile"].path}`,
          {
            state: {
              from: pathname,
            },
          }
        );
      },
      [navigate, pathname]
    );

    const onValidateProfile = React.useCallback(() => {
      return new Promise((resolve, reject) => {
        if (shouldUserValidate) {
          if (userId !== null && userId !== undefined) {
            if (fields && fields !== null && fields.length > 0) {
              setShowDialog(true);
              setIncompleteFields(fields);
              resolve(false);
            } else {
              resolve(true);
            }
          }
        } else {
          resolve(true);
        }
      });
    }, [userId, fields, shouldUserValidate]);

    const emptyFieldsItem = React.useMemo(() => {
      return (
        <ul className="fields-list">
          {incompleteFileds.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      );
    }, [incompleteFileds]);

    const dialogContents = React.useMemo(() => {
      if (pageType === "NDA") {
        return {
          title: "Updated Profile Info",
          message: (
            <>
              For the NDA your basic profile should up to date.
              {emptyFieldsItem}
            </>
          ),
          actions: (
            <>
              <Button
                size="lg"
                leftIcon={BUTTON_ICONS.cancel}
                variant="gray-400"
                onClick={() => setShowDialog(false)}
              >
                Cancel
              </Button>

              {/* {incompleteFileds.length === 0 ? (
                <Button
                  size="lg"
                  leftIcon={BUTTON_ICONS.done}
                  variant="green-light"
                  onClick={() => navigate(pathname)}
                >
                  Continue to NDA
                </Button>
              ) : null} */}

              <Button
                size="lg"
                leftIcon={BUTTON_ICONS.change}
                variant="green-light"
                onClick={gotoProfile}
              >
                Update my profile
              </Button>
            </>
          ),
        };
      }

      if (pageType === "Contracts") {
        return {
          title: "Updated Profile Info",
          message: (
            <>
              For the Contracts your basic profile should up to date.
              {emptyFieldsItem}
            </>
          ),
          actions: (
            <>
              <Button
                size="lg"
                leftIcon={BUTTON_ICONS.cancel}
                variant="gray-400"
                onClick={() => setShowDialog(false)}
              >
                Cancel
              </Button>
              {/* {incompleteFileds.length === 0 ? (
                <Button
                  size="lg"
                  leftIcon={BUTTON_ICONS.done}
                  variant="green-light"
                  onClick={() => navigate(pathname)}
                >
                  Continue to Contact
                </Button>
              ) : null} */}

              <Button
                size="lg"
                leftIcon={BUTTON_ICONS.change}
                variant="green-light"
                onClick={gotoProfile}
              >
                Update my profile
              </Button>
            </>
          ),
        };
      }

      return null;
    }, [
      gotoProfile,
      pageType,
      emptyFieldsItem,
      // incompleteFileds,
      // navigate,
      // pathname,
    ]);

    // manually validate user profile fields.
    const onValidate = React.useCallback(() => {
      return new Promise((resolve, reject) => {
        onValidateProfile()
          .then((isValid) => {
            resolve(isValid);
          })
          .catch((err) => reject(err));
      });
    }, [onValidateProfile]);

    // validate user profile fields on page load
    useEffect(() => {
      if (options?.validateOnPageLoad) {
        onValidateProfile().then((res) => res);
      }
    }, [onValidateProfile]);

    return (
      <>
        <WrapperComponent onValidate={onValidate} {...props} />

        <AlertDialog
          visibility={showDialog}
          conClose={() => setShowDialog(false)}
          alertIcon={
            <i
              className="icon icon-lp-info icon-35"
              style={{
                color: "#E89F7A",
              }}
            ></i>
          }
          title={dialogContents?.title}
          contents={dialogContents?.message}
          actions={dialogContents?.actions}
        />
      </>
    );
  };
};

export default withProfileUpdateAlert;
