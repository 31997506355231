import styled from "styled-components";

export const TabContainer = styled.div`
  width: 100%;

  .base__tab__nav {
    width: inherit;

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;

      li {
        list-style: none;
        cursor: pointer;

        &.is--disabled {
          cursor: default;
        }
      }
    }
  }

  .base__tab__wrapper {
    display: block;
    width: inherit;

    .base__tab__content {
      display: block;
    }
  }
`;
