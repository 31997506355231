import React, { useState } from "react";
import FieldControl from "../FieldControl";
import TimeUnitSelector from "./TimeUnitSelector";
import { TimeInput, TimeDropdown } from "./TimePickerWrapper";

const TimeDuration = React.forwardRef((props, ref) => {
  const {
    inputOuterClass,
    className,
    size = "sm",
    startIcon = null,
    value,
    disabled,
    ...other
  } = props;

  const placeholder = "HH : MM : SS";
  const [showDropdown, setShowDropdown] = useState(false);

  const [time, setTime] = useState({
    hours: "00",
    minutes: "00",
    seconds: "00"
  });

  const updateTime = (updatedValues) => {
    setTime((prevTime) => ({ ...prevTime, ...updatedValues }));
  };

  const handleChange = (type) => (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 2) {
      let maxValue = type === "hours" ? 23 : 59;
      if (Number(value) <= maxValue) {
        updateTime({ [type]: value.padStart(2, "0") });
      }
    }
  };

  const modifyValue = (type, delta) => {
    const newValue = Math.max(0, Math.min(
      type === "hours" ? 23 : 59,
      Number(time[type]) + delta
    )).toString().padStart(2, "0");
    updateTime({ [type]: newValue });
  };

  const { hours, minutes, seconds } = time;
  const formattedTime = (hours === "00" && minutes === "00" && seconds === "00")
    ? placeholder
    : `${hours} : ${minutes} : ${seconds}`;

  return (
    <FieldControl className={inputOuterClass} size={size} isDisabled={disabled}>
      <TimeInput
        value={formattedTime}
        onClick={() => setShowDropdown((prev) => !prev)}
        readOnly
        disabled={disabled}
        isPlaceholder={formattedTime === placeholder}
        ref={ref}
      />
      {showDropdown && (
        <TimeDropdown>
          <TimeUnitSelector
            label="HH"
            value={hours}
            onIncrement={() => modifyValue("hours", 1)}
            onDecrement={() => modifyValue("hours", -1)}
            onChange={handleChange("hours")}
          />
          <TimeUnitSelector
            label="MM"
            value={minutes}
            onIncrement={() => modifyValue("minutes", 1)}
            onDecrement={() => modifyValue("minutes", -1)}
            onChange={handleChange("minutes")}
          />
          <TimeUnitSelector
            label="SS"
            value={seconds}
            onIncrement={() => modifyValue("seconds", 1)}
            onDecrement={() => modifyValue("seconds", -1)}
            onChange={handleChange("seconds")}
          />
        </TimeDropdown>
      )}
    </FieldControl>
  );
});

export default TimeDuration;
