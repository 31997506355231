import styled from "styled-components";

export const Container = styled.div`
  /* min-width: 0; */
  /* display: flex; */
  user-select: none;
  flex: 1;
  min-width: 0px;
  overflow-x: clip;

  .inner {
    display: block;
    min-height: 100%;
  }

  .column__swiper {
    --slide-width: ${({ $columnWidth }) => `${$columnWidth}`};
    overflow: visible !important;
    width: 100%;
    /* min-height: 100%; */

    .swiper-slide {
      width: var(--slide-width) !important;
      min-width: var(--slide-width) !important;
      max-width: var(--slide-width) !important;
      /* min-height: 100%; */
    }
  }
`;
