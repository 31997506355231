import React from "react";

export const SettingVariant = {
  Project: 0,
  Tenant: 1,
};

const SettingContext = React.createContext({
  projectId: null,
  tenantId: null,
  variant: SettingVariant.Project,
  quickSearchValue: undefined,
  handleQuickSearch: () => {},
});

export const useSettingContext = () => {
  return React.useContext(SettingContext);
};

export const SettingContextProvider = ({
  children,
  projectId,
  variant,
  tenantId,
}) => {
  // Search values
  const [quickSearchValue, setQuickSearchValue] = React.useState(undefined);

  const handleQuickSearch = React.useCallback((val = "") => {
    if (!val?.length) {
      setQuickSearchValue(null);
      return;
    }
    setQuickSearchValue(val);
  }, []);

  const values = {
    projectId,
    tenantId,
    variant,
    quickSearchValue,
    handleQuickSearch,
  };

  return (
    <SettingContext.Provider value={values}>{children}</SettingContext.Provider>
  );
};
