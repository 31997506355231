import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.70293 8.68572C8.67698 9.71053 8.58618 10.7483 8.53429 11.7861C8.52132 12.0585 8.28782 12.279 8.0154 12.279H4.53886C4.26645 12.279 4.03295 12.0585 4.01997 11.7861C3.9162 10.1516 3.81242 8.56897 3.79945 8.556C3.66973 8.43925 3.52703 8.33548 3.39731 8.21873C3.35839 8.17981 3.31948 8.15386 3.28056 8.11495C3.20273 8.05009 3.1249 7.98523 3.06003 7.93334C2.91734 7.81659 2.76167 7.68687 2.61898 7.57012C2.50223 7.46634 2.02226 7.07718 1.62012 6.75287C1.29582 6.49343 1.0234 6.25993 0.99746 6.24696L0.0375194 3.73036C-0.066258 3.45794 0.0504916 3.14661 0.322907 3.04283C0.569378 2.93905 0.828822 3.02986 0.971515 3.25039L2.50223 5.42971L2.55412 5.46863L2.90437 5.72807C3.13787 5.89671 3.37137 6.06535 3.60487 6.23398C3.73459 6.32479 3.85134 6.41559 3.98106 6.5064C4.04592 6.54532 4.12375 6.57126 4.20158 6.58423C4.29239 6.58423 4.42211 6.59721 4.56481 6.59721C4.72047 6.59721 4.88911 6.61018 5.01883 6.61018C5.0318 6.62315 5.0318 6.62315 5.04478 6.63612C5.12261 6.72693 5.30422 6.9734 5.51177 7.23284C5.65447 7.41445 5.79716 7.60903 5.92688 7.77767C5.99174 7.86848 6.0566 7.95928 6.10849 8.02414C6.14741 8.07603 6.21227 8.11495 6.27713 8.11495C6.34199 8.11495 6.40685 8.089 6.44577 8.02414C6.49766 7.94631 6.56252 7.86848 6.62738 7.77767C6.7571 7.60903 6.8998 7.41445 7.04249 7.23284C7.27599 6.93448 7.47057 6.66207 7.53543 6.59721C7.5484 6.59721 7.56138 6.59721 7.57435 6.59721C7.58732 6.59721 7.60029 6.61018 7.62624 6.61018C7.66515 6.62315 7.6911 6.63612 7.73001 6.64909C7.75596 6.66207 7.7819 6.67504 7.79488 6.67504C7.97649 6.7399 8.19701 6.80476 8.39159 6.86962H8.41754C8.62509 6.93448 8.67698 7.07718 8.68996 7.19392C8.70293 7.69984 8.7159 8.19278 8.70293 8.68572Z"
      fill="currentColor"
    />
    <path
      d="M15.9673 4.96271L15.176 7.10312C15.1501 7.11609 14.9166 7.31067 14.6442 7.54417C14.2939 7.82956 13.8918 8.15386 13.788 8.24467C13.6583 8.34845 13.5286 8.45223 13.4118 8.556C13.347 8.60789 13.2821 8.65978 13.2172 8.71167C13.1783 8.73761 13.1524 8.77653 13.1135 8.80247C12.9967 8.89328 12.88 8.99706 12.7632 9.08786C12.7502 9.10083 12.6594 10.4499 12.5686 11.8509C12.5557 12.0844 12.3611 12.266 12.1146 12.266H9.15695C9.10506 12.266 9.05318 12.2531 9.01426 12.2401L9.02723 11.1375C9.02723 11.0726 9.0402 11.0077 9.0402 10.9299C9.09209 10.1905 9.14398 9.43811 9.15695 8.68572C9.16993 8.28359 9.15695 7.90739 9.14398 7.55715C9.20884 7.5312 9.2737 7.51823 9.33856 7.49228C9.35154 7.49228 9.37748 7.47931 9.39045 7.46634C9.4164 7.45337 9.44234 7.45337 9.48126 7.4404C9.49423 7.4404 9.5072 7.42742 9.52017 7.42742C9.53315 7.42742 9.54612 7.42742 9.54612 7.42742C9.59801 7.47931 9.77962 7.71281 9.9742 7.97225C10.0909 8.12792 10.2207 8.29656 10.3244 8.43925C10.3763 8.51709 10.4412 8.59492 10.4801 8.65978C10.519 8.71167 10.5709 8.73761 10.6228 8.73761C10.6747 8.73761 10.7266 8.71167 10.7655 8.65978C10.8044 8.59492 10.8693 8.53006 10.9212 8.43925C11.0249 8.29656 11.1547 8.12792 11.2714 7.97225C11.4401 7.73876 11.5957 7.54417 11.6736 7.45337C11.6865 7.4404 11.6865 7.42742 11.6995 7.42742C11.8162 7.42742 11.9589 7.41445 12.0887 7.41445C12.2054 7.41445 12.3222 7.40148 12.4 7.40148C12.4649 7.40148 12.5297 7.37554 12.5816 7.33662C12.6854 7.25879 12.8021 7.18095 12.9059 7.10312C13.1005 6.96043 13.308 6.81773 13.5026 6.66207L13.801 6.44154L13.8529 6.40262L15.1631 4.52166C15.2798 4.34005 15.5133 4.26221 15.7209 4.35302C15.9544 4.4568 16.0581 4.72921 15.9673 4.96271Z"
      fill="currentColor"
    />
    <path
      d="M6.26416 6.44154C7.04249 6.45451 7.75596 5.75401 7.76893 4.96271C7.7819 4.15844 7.09438 3.47091 6.30308 3.47091C5.4988 3.47091 4.81128 4.13249 4.7983 4.93677C4.77236 5.70213 5.47286 6.42857 6.26416 6.44154Z"
      fill="currentColor"
    />
    <path
      d="M10.5969 6.89556C11.3103 6.90854 11.946 6.2729 11.9589 5.55943C11.9719 4.83299 11.3492 4.21033 10.6228 4.21033C9.89637 4.21033 9.2737 4.80705 9.26073 5.53349C9.24776 6.23398 9.88339 6.88259 10.5969 6.89556Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
