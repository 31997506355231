export const CREW_PERMISSION_MODULES = {
  CrewPlanner: {
    key: "crew-planner",
    actions: {
      CreateNewAction: {
        key: "create_new",
      },
      SaveAction: {
        key: "save_planer",
      },
      ViewShortlist: {
        key: "view_shortlist",
      },
    },
  },
};
