import React from "react";
import ActionButton from "../../../../../Buttons/ActionButton";
import TableList from "../../../../../TableList";
import TableRightActionBody from "../../TableRightAction/TableRightActionBody";
import TableRightActionHeader from "../../TableRightAction/TableRightActionHeader";
import { styleClass } from "../../../../../../../utility/common";
import { useRoleRightStore } from "../../../../../../../store/Setting/Permission";
import { toast } from "react-toastify";
import Alert from "../../../../../../../utility/alert";

import "./styles.scss";

const columns = [
  {
    label: "Right",
    name: "role",
    width: "43%",
  },
  {
    label: <TableRightActionHeader />,
    name: "rights",
    sortable: false,
    width: "22%",
  },
  {
    label: "Right Access Action",
    name: "rightAction",
    width: "25%",
  },
  {
    label: "Action",
    name: "action",
    sortable: false,
    width: "10%",
  },
];

const RoleRightTable = () => {
  const data = useRoleRightStore((state) => state.data);

  const onLoadRoleRightData = useRoleRightStore(
    (state) => state.onLoadRoleRightData
  );

  const onDelete = useRoleRightStore((state) => state.onDelete);

  const onAccessOrDenied = useRoleRightStore((state) => state.onAccessOrDenied);

  const onUpdateTableRowData = useRoleRightStore(
    (state) => state.onUpdateTableRowData
  );

  const onPermissionChanged = useRoleRightStore(
    (state) => state.onPermissionChanged
  );

  const btnAccessStatus = (rights) => {
    if (!rights || !rights?.value) {
      return "";
    }

    const AllActiveStatus = Object.entries(rights.value).every(
      (k) => k[1] === true
    );
    const SomePendingStatus = Object.entries(rights.value).some(
      (k) => k[1] === true
    );

    return AllActiveStatus === true
      ? "active"
      : SomePendingStatus
      ? "pending"
      : "";
  };

  const btnDeniedStatus = (rights) => {
    //
    if (!rights || !rights?.value) {
      return "";
    }

    const AllActiveStatus = Object.entries(rights.value).every(
      (k) => k[1] === false
    );

    return AllActiveStatus === true ? "active" : "";
  };

  const RightsButton = ({ onClick, className, label }) => {
    return (
      <span
        className={styleClass("table__btn-action", className)}
        onClick={onClick}
      >
        {label}
      </span>
    );
  };

  const onUpdateHandler = (id) => {
    onUpdateTableRowData(id)
      .then((res) => {
        console.log(res);
        // toast.success(res.displayMessage);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onDeleteHandler = (id) => {
    Alert.confirm({
      title: "Are you sure?",

      text: "You want to complete this",

      confirmButtonText: "Yes, Complete",
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(id)
          .then((res) => {
            console.log(res);
            // toast.success(res.displayMessage);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const tableRenderRows = (rowData, index) => {
    const { role, rights, rightAction, action, id } = rowData;

    return (
      <TableList.Row key={index}>
        <TableList.Column width={role.style.width}>
          {role.value.name}
        </TableList.Column>

        <TableList.Column width={rights?.style.width}>
          <TableRightActionBody
            permissionState={rights?.value}
            onUpdated={(val) => onPermissionChanged(val, id.value)}
          />
        </TableList.Column>

        <TableList.Column
          className="action-col"
          width={rightAction.style.width}
        >
          <RightsButton
            label="Access All"
            className={btnAccessStatus(rights)}
            onClick={() => onAccessOrDenied(id.value, "Access")}
          />

          <RightsButton
            label="Denied All"
            className={btnDeniedStatus(rights)}
            onClick={() => onAccessOrDenied(id.value, "Denied")}
          />
        </TableList.Column>

        <TableList.Column width={action.style.width}>
          <ActionButton
            variant="UPDATE"
            className="action-update"
            onClick={() => onUpdateHandler(id.value)}
          />
          <ActionButton
            variant="DELETE"
            onClick={() => onDeleteHandler(id.value)}
          />
        </TableList.Column>
      </TableList.Row>
    );
  };

  return (
    <div className="setting-details setting__details--role">
      <TableList columns={columns} data={data} renderRows={tableRenderRows} />
    </div>
  );
};

export default RoleRightTable;
