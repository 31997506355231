import React, { useImperativeHandle } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import FromGroup from "../../../../controls/FromGroup";
import { useProfileMissedFields } from "../../../../../hooks/useProfileMissedFields";
import { useAppStore } from "../../../../../store/AppStore/AppStore";

// Form initial values
const initialValues = {
  fullName: "",
  country: "",
  address: "",
  residentStatus: 1,
  visaStatus: "",
  visaValidity: "",
};

// Validation Schema
const validationSchema = Yup.object().shape({
  fullName: Yup.string().required("This field is required"),
  country: Yup.string().required("This field is required"),
  residentStatus: Yup.string().required("This field is required"),
  address: Yup.string().when("residentStatus", {
    is: "1",
    then: Yup.string().required("This field is required"),
    otherwise: Yup.string().notRequired(),
  }),
  visaStatus: Yup.string().when("residentStatus", {
    is: "0",
    then: Yup.string().required("This field is required"),
    otherwise: Yup.string().notRequired(),
  }),
  visaValidity: Yup.string().when("residentStatus", {
    is: "0",
    then: Yup.string().required("This field is required"),
    otherwise: Yup.string().notRequired(),
  }),
});

const InformationForm = React.forwardRef((props, ref) => {
  //
  const { onFormSubmitted, missFields, userTenantId } = props;
  const countries = useAppStore((state) => state.countries);
  const formRef = React.useRef();
  const [currentProfileDetails, setCurrentProfileDetails] =
    React.useState(null);

  const { profileDetails } = useProfileMissedFields();

  useImperativeHandle(ref, () => ({
    onUpdate: () => {
      formik.handleSubmit();
    },
  }));

  const onSubmit = (values) => {
    const address =
      values.residentStatus.toString() === "1"
        ? values.address
        : currentProfileDetails.address.length === 0
        ? "Not Resident"
        : currentProfileDetails.address;

    const payload = {
      ...currentProfileDetails,
      address,
      fullName: values.fullName,
      countryId: values.country,
    };

    onFormSubmitted &&
      onFormSubmitted(userTenantId, payload, () => {
        // formik.resetForm();
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { errors, values, handleChange, handleBlur, touched, setFieldValue } =
    formik;

  const onResidentBlurHandler = (e) => {
    handleBlur("residentStatus")(e);
    setFieldValue("visaValidity", "");
    setFieldValue("visaStatus", "");
    setFieldValue("address", "");
  };

  const isMissed = missFields.isMissed;

  // Load current profile information
  React.useEffect(() => {
    if (userTenantId && isMissed) {
      profileDetails(userTenantId)
        .then((res) => {
          setFieldValue("fullName", res?.fullName || "");
          setFieldValue("country", res?.countryId || "");
          setFieldValue("address", res?.address || "");
          setCurrentProfileDetails(res);
        })
        .catch((err) => {});
    }
  }, [profileDetails, userTenantId, isMissed, setFieldValue]);

  return (
    <form onSubmit={formik.handleSubmit} ref={formRef}>
      <div className="row">
        <div className="col-12 col-sm-4">
          <FromGroup
            label="Full Name"
            inputHeightAuto
            error={touched.fullName && errors.fullName}
          >
            <input
              type="text"
              className="form-control"
              name="fullName"
              value={values.fullName}
              onChange={handleChange}
              autoFocus
            />
          </FromGroup>
        </div>

        <div className="col-12 col-sm-4">
          <FromGroup
            label="Country"
            inputHeightAuto
            error={touched.country && errors.country}
          >
            <select
              className="form-control"
              name="country"
              value={values.country}
              onChange={handleChange}
            >
              <option value="">Select Country</option>
              {countries.map((country) => {
                return (
                  <option value={country.countryId}>
                    {country.countryName}
                  </option>
                );
              })}
            </select>
          </FromGroup>
        </div>

        <div className="col-12 col-sm-4">
          <FromGroup
            label="Resident Status"
            inputHeightAuto
            error={touched.residentStatus && errors.residentStatus}
          >
            <select
              className="form-control"
              name="residentStatus"
              value={values.residentStatus}
              onChange={handleChange}
              onBlur={onResidentBlurHandler}
            >
              <option value="">Select Resident</option>
              <option value="1">Resident</option>
              <option value="0">Not Resident</option>
            </select>
          </FromGroup>
        </div>

        {/* Only show when status  is 1*/}
        {values.residentStatus.toString() === "0" ? (
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-sm-4">
                <FromGroup
                  label="VISA Status"
                  inputHeightAuto
                  error={touched.visaStatus && errors.visaStatus}
                >
                  <input
                    type="text"
                    className="form-control"
                    name="visaStatus"
                    value={values.visaStatus}
                    onChange={handleChange}
                  />
                </FromGroup>
              </div>

              <div className="col-12 col-sm-4">
                <FromGroup
                  label="VISA Validity"
                  inputHeightAuto
                  error={touched.visaValidity && errors.visaValidity}
                >
                  <input
                    type="date"
                    className="form-control"
                    name="visaValidity"
                    value={values.visaValidity}
                    onChange={handleChange}
                  />
                </FromGroup>
              </div>
            </div>
          </div>
        ) : null}

        {/* Residential Address */}
        {values.residentStatus.toString() === "1" ? (
          <div className="col-12 col-sm-12">
            <FromGroup
              label="Address"
              inputHeightAuto
              error={touched.address && errors.address}
            >
              <input
                type="text"
                className="form-control"
                name="address"
                value={values.address}
                onChange={handleChange}
              />
            </FromGroup>
          </div>
        ) : null}
      </div>
    </form>
  );
});

export default InformationForm;
