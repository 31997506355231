import styled, { css } from "styled-components";
import { StyledCardStatus } from "../../../research-data/TagCard/styled";

export const modalCss = css`
  --modal-max-width: 780px;
  --modal-width: 45%;

  /* responsive styles goes here */

  .modal__content {
    position: relative;
  }
`;

export const StyledModalHeader = styled.div`
  width: 100%;
  padding-block: var(--layout-spacing-16);
  padding-inline: var(--layout-spacing-20);

  ${({ $hasBannerContent }) => {
    if ($hasBannerContent) {
      return `
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
      `;
    }

    return `
      padding-left: var(--layout-spacing-28);
      padding-bottom: 0;
    `;
  }};

  .header__inner {
    display: flex;
    align-items: center;
    position: relative;

    .start {
      position: relative;
      padding-left: var(--layout-spacing-20);
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .end {
      display: flex;
      padding-top: var(--layout-spacing-4);
      /* align-items: center; */
      gap: var(--layout-spacing-12);
      align-self: flex-start;

      .delete__btn {
        color: ${({ theme }) => theme.colors.primary()};
        background-color: ${({ theme }) => theme.colors.white()};

        &:hover {
          opacity: 0.7;
        }
      }

      .edit__btn {
        /* color: ${({ theme }) => theme.colors.color_1(30)}; */
        color: ${({ theme }) => theme.colors.primary()};
        background-color: ${({ theme }) => theme.colors.white()};

        &:hover {
          opacity: 0.7;
        }
      }

      .close__btn {
        background-color: ${({ theme }) => theme.colors.white()};
        border-radius: 999px;
        color: ${({ theme }) => theme.colors.color_1(30)};
      }
    }
  }
`;

export const StyledStatus = styled(StyledCardStatus)`
  left: 0;
  top: 6px;
  --rounded-8: 99px;
  align-self: flex-start;
`;

export const StyledTopContent = styled.div`
  padding-top: 56.25%;
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 19.2%
      ),
      linear-gradient(180deg, rgba(255, 255, 255, 0) 70.06%, #ffffff 100%);
  }

  .inner__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const StyledOtherContent = styled.div`
  padding-top: var(--layout-spacing-32);
  padding-left: var(--layout-spacing-48);

  .inner__content {
    display: block;
    background-color: #fff;

    .popup__column__gutter {
      width: var(--layout-spacing-48);
    }

    &.single__item {
      .popup__column__sizer,
      .popup__column {
        width: calc(100% - var(--layout-spacing-48));

        &::after {
          content: none !important;
        }
      }
    }

    .popup__column__sizer,
    .popup__column {
      width: calc(50% - var(--layout-spacing-48));
    }

    .popup__column {
      position: relative;
      margin-bottom: var(--layout-spacing-28);

      &:nth-child(2n + 1) {
        &::after {
          content: "";
          display: block;
          width: 1px;
          height: 100%;
          background-color: var(--layout-background-n30);
          position: absolute;
          top: 0;
          right: var(--layout-spacing-minus-24);
        }
      }

      .popup__column__title {
        padding-bottom: var(--layout-spacing-12);
        margin-bottom: var(--layout-spacing-12);
        border-bottom: 1px solid var(--layout-background-n30);
        color: var(--font-color-shade-primary);
      }
    }
  }
`;
