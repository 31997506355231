import React from "react";
import { styleClass } from "../../../utility";
import "./styles.scss";

const PageErrorMessage = ({ title, children, className }) => {
    
  return (
    <div className={styleClass("error__page ", className)}>
      <div className="message">
        <div className="title">{title}</div>
        <div className="desc">{children}</div>
      </div>
    </div>
  );
};

export default PageErrorMessage;
