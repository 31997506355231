import React from "react";
import SearchInput from "../../../../common/components/Form/SearchInput";

const SearchBar = ({ ...otherProps }) => {
  return (
    <SearchInput
      canExpandable={false}
      defaultExpanded
      className="chat__search__input"
      {...otherProps}
    />
  );
};

export default SearchBar;
