import React from "react";
import { List, MenuContainer, ListItem, SubList, SubListItem } from "./styled";
import IconButton from "../../common/components/Buttons/IconButton";
import IconArrowRight from "../../common/components/icons/Line/Arrow/right";
import SelectionField from "../../common/components/Form/SelectionField";

const Label = ({ type, label, isSelected }) => {
  if (type) {
    switch (type) {
      case "checkbox":
        return <SelectionField text={label} defaultChecked={isSelected} />;

      default: {
        return label;
      }
    }
  }

  return label;
};

const Menu = ({ children }) => {
  return <MenuContainer>{children}</MenuContainer>;
};

export const MenuList = ({ children }) => {
  return <List>{children}</List>;
};

export const SubMenu = ({ children }) => {
  return <SubList>{children}</SubList>;
};

export const Item = ({
  label,
  children,
  hasChild,
  isSelected,
  type,
  onClick,
  isDisabled,
  ...otherProps
}) => {
  return (
    <ListItem
      {...otherProps}
      $isSelected={isSelected}
      $isDisabled={isDisabled}
      onClick={isDisabled ? null : onClick}
    >
      <div className="item__inner">
        {/* <div className="start"></div> */}
        <div className="center">
          {label}
          {/* <Label label={label} type={type} /> */}
        </div>
        {hasChild && !isDisabled && (
          <div className="end">
            <IconButton
              className="arrow__right"
              icon={<IconArrowRight />}
              btnSize={12}
              btnIconSize={12}
            />
          </div>
        )}
      </div>
      {!isDisabled && children}
    </ListItem>
  );
};

export const SubItem = ({
  label,
  children,
  hasChild,
  isSelected,
  type,
  onClick,
  isDisabled,
  ...otherProps
}) => {
  return (
    <SubListItem
      {...otherProps}
      $isSelected={isSelected}
      $isDisabled={isDisabled}
      onClick={isDisabled ? null : onClick}
    >
      <div className="item__inner">
        {/* <div className="start"></div> */}
        <div className="center">
          {label}
          {/* <Label label={label} type={type} isSelected={isSelected} /> */}
        </div>
        {hasChild && !isDisabled && (
          <div className="end">
            <IconButton
              className="arrow__right"
              icon={<IconArrowRight />}
              btnSize={12}
              btnIconSize={12}
            />
          </div>
        )}
      </div>
      {!isDisabled && children}
    </SubListItem>
  );
};

export default Menu;
