import * as React from "react";
import { memo } from "react";
const SvgArrowRight = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 9 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 6L4.64275e-07 11.1962L9.18537e-07 0.803847L9 6Z"
      fill="#A1D5D9"
    />
  </svg>
);
const Memo = memo(SvgArrowRight);
export default Memo;
