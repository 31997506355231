import * as React from "react";
import { memo } from "react";
const SvgHistory = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.99884 3.91494C8.86623 3.91494 8.73906 3.96762 8.64529 4.06139C8.55152 4.15516 8.49884 4.28233 8.49884 4.41494V8.77471L11.4494 10.7747C11.5043 10.8109 11.5657 10.8359 11.6303 10.8483C11.6948 10.8607 11.7611 10.8602 11.8255 10.847C11.8898 10.8338 11.951 10.808 12.0054 10.7711C12.0598 10.7343 12.1064 10.6871 12.1425 10.6322C12.1787 10.5773 12.2037 10.5159 12.2161 10.4513C12.2285 10.3868 12.228 10.3205 12.2148 10.2561C12.2016 10.1918 12.1758 10.1306 12.1389 10.0762C12.1021 10.0218 12.0549 9.97523 12 9.93908L9.49884 8.24828V4.41494C9.49884 4.34928 9.48591 4.28426 9.46078 4.2236C9.43565 4.16294 9.39882 4.10782 9.35239 4.06139C9.30596 4.01496 9.25084 3.97813 9.19018 3.953C9.12952 3.92788 9.0645 3.91494 8.99884 3.91494Z"
      fill="currentColor"
    />
    <path
      d="M8.99884 5.51936e-10C7.5705 0.00509173 6.16926 0.390323 4.93905 1.11612C3.70885 1.84192 2.69403 2.88213 1.99884 4.12988V1.60115C1.99884 1.46854 1.94616 1.34136 1.85239 1.2476C1.75863 1.15383 1.63145 1.10115 1.49884 1.10115C1.36623 1.10115 1.23906 1.15383 1.14529 1.2476C1.05152 1.34136 0.99884 1.46854 0.99884 1.60115V6H5.39884C5.53145 6 5.65863 5.94732 5.75239 5.85355C5.84616 5.75978 5.89884 5.63261 5.89884 5.5C5.89884 5.36739 5.84616 5.24021 5.75239 5.14645C5.65863 5.05268 5.53145 5 5.39884 5H2.67355C3.30428 3.6692 4.33867 2.57123 5.62953 1.86233C6.92038 1.15342 8.40194 0.869702 9.86337 1.05154C11.3248 1.23338 12.6917 1.87151 13.7694 2.87513C14.8472 3.87874 15.581 5.19672 15.8664 6.64151C16.1518 8.08629 15.9742 9.58428 15.359 10.9223C14.7437 12.2603 13.7221 13.3703 12.4396 14.0941C11.1571 14.8179 9.67891 15.1188 8.21547 14.954C6.75202 14.7891 5.37785 14.1669 4.2885 13.1759C4.24043 13.1286 4.18332 13.0915 4.1206 13.0669C4.05787 13.0422 3.99082 13.0305 3.92344 13.0323C3.85607 13.0341 3.78976 13.0496 3.72848 13.0776C3.6672 13.1057 3.61221 13.1459 3.56681 13.1957C3.5214 13.2455 3.48652 13.3039 3.46423 13.3675C3.44194 13.4312 3.43271 13.4986 3.4371 13.5659C3.44148 13.6331 3.45939 13.6988 3.48975 13.759C3.52011 13.8192 3.56229 13.8726 3.61378 13.9161C4.57495 14.791 5.7348 15.4185 6.99301 15.7444C8.25122 16.0703 9.56988 16.0848 10.8349 15.7865C12.1 15.4882 13.2733 14.8862 14.2533 14.0325C15.2334 13.1788 15.9908 12.0992 16.4598 10.8871C16.9289 9.67497 17.0955 8.3668 16.9454 7.07577C16.7952 5.78475 16.3328 4.54975 15.598 3.47762C14.8633 2.40549 13.8784 1.52852 12.7286 0.922583C11.5787 0.316644 10.2986 -1.52655e-05 8.99884 5.51936e-10Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgHistory);
export default Memo;
