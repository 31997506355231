import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 3.67851C16 3.9003 15.8146 4.0803 15.5862 4.0803H14.2069C13.9785 4.0803 13.7931 3.9003 13.7931 3.67851C13.7931 3.45673 13.9785 3.27673 14.2069 3.27673H15.5862C15.8146 3.27673 16 3.45673 16 3.67851Z"
      fill="currentColor"
    />
    <path
      d="M13.2969 2.27227C13.1827 2.07995 13.2504 1.83442 13.4483 1.72353L14.6428 1.05389C14.8399 0.942998 15.0937 1.00871 15.2079 1.20085C15.3221 1.39299 15.2544 1.63871 15.0566 1.7496L13.8621 2.41923C13.6647 2.53013 13.4111 2.46477 13.2969 2.27227Z"
      fill="currentColor"
    />
    <path
      d="M15.2081 6.15616C15.0939 6.34848 14.8401 6.41402 14.6429 6.30312L13.4485 5.63349C13.2504 5.52259 13.1829 5.27688 13.2971 5.08474C13.4113 4.89224 13.6651 4.82707 13.8623 4.93778L15.0567 5.60742C15.2546 5.71831 15.3223 5.96384 15.2081 6.15616Z"
      fill="currentColor"
    />
    <path
      d="M7.90143 6.09348L8.2652 7.85936C8.32497 8.14954 8.13131 8.43168 7.83246 8.48971C7.53103 8.54775 7.2423 8.35757 7.18326 8.06954L6.77536 6.08938C7.3874 6.09134 7.28846 6.08581 7.90143 6.09348Z"
      fill="currentColor"
    />
    <path
      d="M12.1379 1.08442V6.27402C12.1379 6.34794 12.0519 6.38348 11.9941 6.33544C11.1029 5.59313 9.65113 5.37242 8.55172 5.31027V2.04745C9.65094 1.98548 11.1022 1.76477 11.9934 1.02282C12.0515 0.974423 12.1379 1.01014 12.1379 1.08442Z"
      fill="currentColor"
    />
    <path
      d="M12.1379 7.61419V15.7321C12.1379 15.88 12.0143 16 11.8621 16H0.275862C0.123586 16 0 15.88 0 15.7321V2.33941C0 2.19138 0.123586 2.07156 0.275862 2.07156H4.78363C4.50501 2.37156 4.30032 2.73584 4.20451 3.14298H1.10345V14.9286H11.0345V6.68027C11.5292 6.94544 11.8306 7.27455 12.1379 7.61419Z"
      fill="currentColor"
    />
    <path
      d="M4.96552 3.67886C4.96552 2.79119 5.70648 2.07173 6.62069 2.07173H7.72414V5.28599H6.62069C5.70648 5.28599 4.96552 4.56635 4.96552 3.67886Z"
      fill="currentColor"
    />
    <path
      d="M9.93103 9.7322V10.2143C9.93103 10.3031 9.85692 10.3751 9.76552 10.3751H2.37241C2.28101 10.3751 2.2069 10.3031 2.2069 10.2143V9.7322C2.2069 9.64345 2.28101 9.57149 2.37241 9.57149H9.76552C9.85692 9.57149 9.93103 9.64345 9.93103 9.7322Z"
      fill="currentColor"
    />
    <path
      d="M2.2069 12.3572V11.875C2.2069 11.7863 2.28101 11.7143 2.37241 11.7143H9.76552C9.85692 11.7143 9.93103 11.7863 9.93103 11.875V12.3572C9.93103 12.4459 9.85692 12.5179 9.76552 12.5179H2.37241C2.28101 12.5179 2.2069 12.4459 2.2069 12.3572Z"
      fill="currentColor"
    />
    <path
      d="M2.37241 7.42865H5.90345C5.99485 7.42865 6.06897 7.50062 6.06897 7.58937V8.0715C6.06897 8.16025 5.99485 8.23222 5.90345 8.23222H2.37241C2.28101 8.23222 2.2069 8.16025 2.2069 8.0715V7.58937C2.2069 7.50062 2.28101 7.42865 2.37241 7.42865Z"
      fill="currentColor"
    />
    <path
      d="M2.2069 5.92867V5.44653C2.2069 5.35778 2.28101 5.28582 2.37241 5.28582H4.8C4.8914 5.28582 4.96552 5.35778 4.96552 5.44653V5.92867C4.96552 6.01742 4.8914 6.08938 4.8 6.08938H2.37241C2.28101 6.08938 2.2069 6.01742 2.2069 5.92867Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
