import React from "react";

export const AssigneeContext = React.createContext({
  selectedItem: null,
  handleSelectItem: (data) => {},
});

export const useAssigneeContext = () => {
  return React.useContext(AssigneeContext);
};

export const AssigneeProvider = ({ children }) => {
  const [selectedItem, setSelectedItem] = React.useState(null);

  const handleSelectItem = React.useCallback((data) => {
    setSelectedItem((prev) => {
      if (data?.key === prev?.key) {
        return null;
      }

      return {
        htmlElement: data?.htmlElement,
        payload: data?.payload,
        key: data?.key,
      };
    });
  }, []);

  return (
    <AssigneeContext.Provider value={{ selectedItem, handleSelectItem }}>
      {children}
    </AssigneeContext.Provider>
  );
};
