import React from "react";
import "./styles.scss";

import ActionButton from "../Buttons/ActionButton";
import { styleClass } from "../../../utility/common";

const Chip = (props) => {
  const { children, onEdit, onDelete } = props;

  return (
    <div className="chip">
      {children}
      {onEdit || onDelete ? (
        <div className="chip__actions">
          {onEdit ? <ActionButton variant="EDIT" onClick={onEdit} /> : null}

          {onDelete ? (
            <ActionButton variant="DELETE" onClick={onDelete} />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

Chip.Wrapper = ({ children, className }) => {
  return (
    <div className={styleClass("chip__wrapper", className)}>{children}</div>
  );
};

Chip.Items = ({ children }) => {
  return <div className="chip__items">{children}</div>;
};

Chip.Node = ({ lable = null, text }) => {
  return (
    <div className="chip__items__node">
      {lable ? <div className="label">{lable}</div> : null}

      <div className="text">{text}</div>
    </div>
  );
};

Chip.Actions = ({ children }) => {
  return <div className="chip__actions">{children}</div>;
};

export default Chip;
