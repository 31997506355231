import styled from "styled-components";
import { fontStyle } from "../../../../../styled/utility";

export const Wrapper = styled.div`
  .task-item {
    display: flex;
    align-items: center;
    gap: var(--layout-spacing-8);
    width: 100%;
    max-width: 100%;

    &__start {
    }

    &__count {
      width: var(--layout-size-24);
      height: var(--layout-size-24);
      min-width: var(--layout-size-24);
      min-height: var(--layout-size-24);
      ${fontStyle("label-medium")};
      color: var(--color-white);
      background-color: var(--color-info-200);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 999px;
      line-height: 1.3;
    }

    &__center {
      ${fontStyle("small-regular")};
      color: var(--font-default-color);
      flex: 1;
    }

    &__end {
      display: flex;
      align-self: flex-start;
      gap: var(--layout-spacing-8);
    }
  }
`;
