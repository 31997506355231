import React from "react";
import * as Styled from "./styled";
import SearchInput from "../../../../../../../../../../../common/components/Form/SearchInput";
import SelectField from "./../../../../../../../../../../../common/components/Form/SelectField";
import { getAllColumnTypesAsOptionSource } from "../../../../../../../../../API/common";
import { useAppStore } from "../../../../../../../../../../../store/AppStore/AppStore";
import {
  useSchedulerStore,
  setSelectedColumnType,
} from "../../../../../../../store/SchedulerStore";

function FilterTools() {
  const hideLoader = useAppStore((state) => state.hideLoader);
  const showLoader = useAppStore((state) => state.showLoader);
  const [allColumnTypes, setAllColumnTypes] = React.useState([]);
  //const [selectedCol, setSelectedCol] = React.useState([]);

  const __selectedColumnType = useSchedulerStore(
    (state) => state.selectedColumnType
  );
  const modalState = useSchedulerStore((state) => state.legendModalState);

  React.useEffect(() => {
    if (modalState.visibility) {
      showLoader();
      getAllColumnTypesAsOptionSource().then((result) => {
        setAllColumnTypes(result?.map((columnType) => columnType) || []);
        hideLoader();
      });
    }
  }, [hideLoader, modalState.visibility, showLoader]);

  const onSectionChangeHandler = React.useCallback((selected) => {
    setSelectedColumnType(selected);
  }, []);

  // React.useEffect(() => {
  //   console.log("__selectedColumnType::: ", __selectedColumnType);
  // }, [__selectedColumnType]);
  return (
    <Styled.FilterItemContainer>
      <SearchInput defaultExpanded="true" canExpandable="false" />
      <SelectField
        options={allColumnTypes}
        onChange={onSectionChangeHandler}
        value={__selectedColumnType}
      />
    </Styled.FilterItemContainer>
  );
}

export default FilterTools;
