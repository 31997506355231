import React from "react";
import PropTypes from "prop-types";

import * as Styled from "./styled";
import { IconBlock } from "../../../../styled/Common/Icons";
import { Add } from "../../icons/Fill";
import { Add1 } from "../../icons/Line";
import Popover from "../../Popover";
import Typography from "../../Typography";

const ListItem = ({ option, ...other }) => {
  return (
    <li {...other}>
      <div className="content">
        <IconBlock as="span" className="option__icon">
          {option.icon || <Add1 />}
        </IconBlock>

        <span>{option.label}</span>
      </div>
      {option?.description ? (
        <div className="description">{option?.description}</div>
      ) : null}
    </li>
  );
};

const OptionButton = (props) => {
  const {
    options = [],
    size,
    className,
    showHint,
    renderItem,
    optionListClassName,
    appendTo,
    indicateAnimation = false,
    afterIndicateAnimationStop,
    indicateAnimationDuration = 6900,
    enabledBtnLeftGap = false,
    onClick,
  } = props;
  const btnRef = React.useRef();
  const hintRef = React.useRef();
  const [show, setShow] = React.useState(false);
  const [showAnimation, setShowAnimation] = React.useState(false);
  const [btnLeftGap, setBtnLeftGap] = React.useState();

  const onClickHandler = (option) => {
    setShow(false);
    option.onClick && option.onClick();
  };

  React.useLayoutEffect(() => {
    let timer;
    if (indicateAnimation) {
      setShowAnimation(true);

      timer = setTimeout(() => {
        setShowAnimation(false);
        afterIndicateAnimationStop && afterIndicateAnimationStop();
      }, indicateAnimationDuration);

      return () => {
        timer && clearTimeout(timer);
      };
    }
  }, [
    indicateAnimation,
    afterIndicateAnimationStop,
    indicateAnimationDuration,
  ]);

  React.useLayoutEffect(() => {
    if (!showHint || !enabledBtnLeftGap || !hintRef?.current) {
      return;
    }

    const gap =
      hintRef?.current?.getBoundingClientRect()?.width +
      btnRef?.current?.getBoundingClientRect()?.width;

    setBtnLeftGap(gap || null);
  }, [showHint, enabledBtnLeftGap]);

  const hasOptions = !!options?.filter((o) => o !== null)?.length || 0;

  return (
    <>
      <Styled.ButtonContainer
        btnSize={size}
        isActive={show && hasOptions}
        ref={btnRef}
        onClick={(e) => {
          if (hasOptions) {
            setShow(true);
            return;
          }

          onClick && onClick(e);
        }}
        className={className}
        style={{ marginLeft: btnLeftGap ? `${btnLeftGap}px` : 0 }}
      >
        {showAnimation ? <div className="animate__circle"></div> : null}

        {showHint ? (
          <div className="hint" ref={hintRef}>
            <Typography
              variant={Typography.font_variant.xSmall}
              color={Typography.font_color.tint_04}
              fontStyle={Typography.font_style.italic}
            >
              Need to add more? Click here
            </Typography>
          </div>
        ) : null}

        <IconBlock className="icon">
          <Add />
        </IconBlock>
      </Styled.ButtonContainer>

      {show && hasOptions ? (
        <Popover
          anchorEl={btnRef?.current}
          onOutsideClick={() => setShow(false)}
          placement="right-start"
          modifiers={[
            {
              name: "offset",
              options: {
                offset: ({ reference, popper }) => {
                  return [0, 10];
                  // return [
                  //   (popper.width / 2 + reference.width / 2 + 5) * -1,
                  //   reference.height * -1,
                  // ];
                },
              },
            },
          ]}
          appendTo={appendTo}
        >
          <Styled.OptionList className={optionListClassName}>
            <ul>
              {options.map((option, i) => {
                if (renderItem) {
                  return renderItem(
                    {
                      ...option,
                      onClick: () => onClickHandler(option),
                    },
                    i
                  );
                }

                return (
                  <ListItem
                    option={option}
                    key={i}
                    onClick={() => onClickHandler(option)}
                  />
                );
              })}
            </ul>
          </Styled.OptionList>
        </Popover>
      ) : null}
    </>
  );
};

OptionButton.propTypes = {
  options: PropTypes.array,
  size: PropTypes.oneOf(["md", "lg"]),
  showHint: PropTypes.bool,
  className: PropTypes.any,
  optionListClassName: PropTypes.any,
  renderItem: PropTypes.func,
  appendTo: PropTypes.any,
};

export default OptionButton;
