import styled from "styled-components";
import { fontStyle } from "../../../../../../styled/utility";

export const GridWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  max-height: 100%;

  table {
    table-layout: fixed;
    width: 100%;
    min-width: 100%;

    th,
    td {
      padding: var(--layout-spacing-2);
      background-color: var(--color-neutral-10);
    }

    th {
      .th-cell {
        ${fontStyle("label-medium")};
        font-weight: 500;
        text-align: center;
        font: inherit;
        color: var(--color-accent-500);
        text-align: inherit;
        background-color: var(--color-accent-75);
        border-radius: var(--layout-border-radius-4);
        padding: var(--layout-spacing-8);
        height: 40px;
        max-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    tbody {
      tr {
        --bg-color: var(--color-neutral-40);
        --pd: var(--layout-spacing-4);
        --cell-h: 40px;

        &.row-hover {
          td,
          .deliverable-cell,
          .due-cell {
            background-color: var(--color-secondary-50) !important;
          }
        }

        td {
          &:first-child {
            .td-cell {
              --cell-left-space: var(--layout-spacing-24);
              /* padding-left: var(--layout-spacing-24); */
            }
          }
        }

        &.sub-row {
          --pd: var(--layout-spacing-2);
          --bg-color: var(--color-neutral-20);
          --cell-h: 36px;

          &.has-sub-rows {
            --pd: var(--layout-spacing-2);
            --bg-color: var(--color-neutral-30);
            --cell-h: 36px;
          }

          td:first-child {
            .td-cell {
              margin-left: var(--layout-spacing-8);
              /* padding-left: var(--layout-spacing-24); */
              --cell-left-space: var(--layout-spacing-24);
            }
          }
        }

        &.depth-2 {
          td:first-child {
            .td-cell {
              --cell-left-space: var(--layout-spacing-32);
              /* padding-left: var(--layout-spacing-32); */
            }
          }
        }

        td {
          height: var(--cell-h);
          max-height: var(--cell-h);

          .td-cell {
            display: flex;
            border-radius: var(--layout-border-radius-4);
            /* padding: var(--pd); */
            height: inherit;
            max-height: inherit;
            align-items: center;
          }
        }
      }
    }
  }
`;

export const CellTextBase = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  min-width: 0px;
`;

export const TextLg = styled(CellTextBase)`
  ${fontStyle("large-bold")};
  font-weight: 700;
  color: var(--color-primary-200);
`;

export const TextMd = styled(CellTextBase)`
  ${fontStyle("small-medium")};
  font-weight: 500;
  color: #05113a;
`;

export const TextSm = styled(CellTextBase)`
  ${fontStyle("label-regular")};
  font-weight: 400;
  color: var(--color-primary-300);
`;
