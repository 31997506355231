import React from "react";

import * as Styled from "./styled";
import IconArrowDown from "../../icons/Fill/arrow-down";
import IconMenu from "../../icons/Fill/menu";
import Popover from "../../Popover";
import IconButton from "../../Buttons/IconButton";
import { AnimatePresence, motion } from "framer-motion";

export const DropdownMenuIcon = IconMenu;
export const DropdownArrowIcon = IconArrowDown;

export const Sizes = {
  __12: 12,
  __16: 16,
  __20: 20,
  __24: 24,
};

const DropdownMenu = React.forwardRef((props, ref) => {
  const {
    options = [],
    render,
    closeDropdown,
    className,
    ...restProps
  } = props;

  return (
    <Styled.DropdownMenu
      ref={ref}
      className={["dropdown__menu", className].join(" ")}
      {...restProps}
    >
      <ul>
        {options.length > 0 ? (
          <>
            {options.map(
              (item, i) => render && render(item, { key: i, closeDropdown })
            )}
          </>
        ) : (
          <li className="no-options">No Options</li>
        )}
      </ul>
    </Styled.DropdownMenu>
  );
});

const AnimateDropdownMenu = motion(DropdownMenu, { forwardMotionProps: true });
const AnimatePopover = motion(Popover, { forwardMotionProps: true });

const PopoverDropdown = (props) => {
  const {
    customDropdown: CustomDropDown,
    customDropdownIcon,
    customDropdownMenu: CustomDropdownMenu,
    dropdownButtonClassName,
    options = [],
    render,
    dropdownIconSize = Sizes.__12,
    className,
    dropdownAppendTo,
    hideDropdownMask = false,
    dropdownMaskClassName,
    isMaskTransparent = false,
    dropdownPlacement = "bottom-end",
  } = props;

  const buttonRef = React.useRef();
  const [isShow, setIsShow] = React.useState(false);

  const handleClick = React.useCallback(() => {
    setIsShow((prev) => !prev);
  }, []);

  return (
    <Styled.DropdownWrapper className={className}>
      <Styled.DropdownButtonElement
        ref={buttonRef}
        className={["button__wrapper", dropdownButtonClassName].join(" ")}
        onClick={handleClick}
      >
        {CustomDropDown ? (
          <CustomDropDown />
        ) : (
          <Styled.DropdownIconElement iconSize={dropdownIconSize}>
            {customDropdownIcon ? customDropdownIcon : <IconArrowDown />}
          </Styled.DropdownIconElement>
        )}
      </Styled.DropdownButtonElement>

      {/* List */}
      <AnimatePresence>
        {buttonRef?.current && isShow ? (
          <AnimatePopover
            // initial={{ opacity: 0, scale: 0.5 }}
            // animate={{ opacity: 1, scale: 1 }}
            // transition={{ duration: 0.5 }}
            anchorEl={buttonRef?.current}
            placement={dropdownPlacement}
            hideOverlay={hideDropdownMask}
            onOutsideClick={handleClick}
            appendTo={dropdownAppendTo}
            maskClassName={[
              "dropdown__mask",
              isMaskTransparent ? "is__mask__transparent" : "",
              dropdownMaskClassName,
            ].join(" ")}
          >
            {CustomDropdownMenu ? (
              <CustomDropdownMenu
                options={options}
                closeDropdown={handleClick}
              />
            ) : (
              <AnimateDropdownMenu
                options={options}
                render={render}
                closeDropdown={handleClick}
              />
            )}
          </AnimatePopover>
        ) : null}
      </AnimatePresence>
    </Styled.DropdownWrapper>
  );
};

export function PopoverDropdownMenuItem({
  icon,
  label,
  iconClassName,
  className,
  isDisabled,
  ...rest
}) {
  return (
    <Styled.PopoverDropdownItem
      $disabled={isDisabled}
      className={className}
      {...rest}
    >
      {icon ? (
        <IconButton
          as="div"
          className={["item__icon", iconClassName].join(" ")}
          icon={icon}
        />
      ) : null}

      <div className="item__text">{label}</div>
    </Styled.PopoverDropdownItem>
  );
}

export default PopoverDropdown;
