import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";

import ContentItem, { ViewTypes } from "../ContentItem";
import { FieldTypes } from "../../../../../../constant/FieldTypes";
import ScheduleField from "../../../common/Form/ScheduleField";
import Typography from "../../../../../../common/components/Typography";
import { RESEARCH_TAG_VISUAL_TYPE } from "../../TagCard";

const SubTagContent = (props) => {
  const { data } = props;
  const [contentFileds, setContentFileds] = useState([]);

  let document_extensions = [
    "doc",
    "docx",
    "pdf",
    "ppt",
    "rtf",
    "swf",
    "txt",
    "xls",
  ];
  let image_extensions = ["jpg", "png", "svg"];

  useEffect(() => {
    renderContent();
  }, [data]);

  const renderContent = async () => {
    try {
      const content = data?.tagFileds?.map((filed, i) => {
        if (
          filed?.value === "" ||
          filed.value?.length === 0 ||
          filed.value === null ||
          filed.value === undefined
        ) {
          return null;
        }

        let viewType =
          filed?.layoutTypeId !== undefined || filed?.layoutTypeId !== null
            ? filed?.layoutTypeId
            : 1;

        if (
          filed?.fieldTypeId === FieldTypes.Int ||
          filed?.fieldTypeId === FieldTypes.TextInput ||
          filed?.fieldTypeId === FieldTypes.Decimal ||
          filed?.fieldTypeId === FieldTypes.Currency ||
          filed?.fieldTypeId === FieldTypes.Email ||
          filed?.fieldTypeId === FieldTypes.TextArea ||
          filed?.fieldTypeId === FieldTypes.Address ||
          filed?.fieldTypeId === FieldTypes.Telephone
        ) {
          return (
            <ContentItem
              key={i}
              title={filed.displayName}
              viewType={viewType}
              render={filed?.value}
              icon={filed?.icon}
            />
          );
        } else if (filed?.fieldTypeId === FieldTypes.WebAddress) {
          return (
            <ContentItem
              key={i}
              title={filed.displayName}
              viewType={viewType}
              icon={filed?.icon}
              render={() => (
                <>
                  {filed?.value && Array.isArray(filed?.value) ? (
                    <>
                      {filed?.value.map((d, i) => (
                        <div className="link__item" key={i}>
                          <a href={d.url} target="_blank" rel="noreferrer">
                            {d.name}
                          </a>
                        </div>
                      ))}
                    </>
                  ) : null}
                </>
              )}
            />
          );
        } else if (filed?.fieldTypeId === FieldTypes.TelephoneList) {
          console.warn("field.value", filed.value);

          if(filed?.value && Array.isArray(filed?.value)){
            return (
              <ContentItem
                key={i}
                title={filed.displayName}
                viewType={viewType}
                icon={filed?.icon}
                render={filed.value.map(d => d.telephoneNo)}
              />
            );
          }else if(filed?.value){
            <ContentItem
            key={i}
            title={filed.displayName}
            viewType={viewType}
            render={filed?.value}
            icon={filed?.icon}
          />
          }
        }else if (filed?.fieldTypeId === FieldTypes.Select) {
          return (
            <ContentItem
              key={i}
              title={filed.displayName}
              viewType={viewType}
              icon={filed?.icon}
              render={filed?.value?.label}
            />
          );
        } else if (filed?.fieldTypeId === FieldTypes.File) {
          if (filed?.value) {

            const getFiles = () => {
              return filed?.value.map((file) => {
                let extension = file?.filePath?.split(".").pop() || "";
                extension = String(extension).toLowerCase();

                if (extension && image_extensions.indexOf(extension) !== -1) {
                  return (
                    <Image src={file?.filePath} height={75} width={150}></Image>
                  );
                }

                if (
                  extension &&
                  document_extensions.indexOf(extension) !== -1
                ) {
                  return (
                    <div className="link__item">
                      <a target="_blank" rel="noreferrer" href={file?.filePath}>
                        {file?.fileName}
                      </a>
                    </div>
                  );
                }

                return (
                  <Typography
                    variant={Typography.font_variant.small}
                    fontWeight={Typography.font_weight.regular}
                  ></Typography>
                );
              });
            };

            if (filed?.visualTypeId === RESEARCH_TAG_VISUAL_TYPE.IMAGE) {
              return null;
            }

            return (
              <ContentItem
                key={i}
                title={filed.displayName}
                icon={filed?.icon}
                viewType={viewType}
                render={() => <div className="file__wrapper">{getFiles()}</div>}
              />
            );
          }
        } else if (filed?.fieldTypeId === FieldTypes.Schedule) {

            const processingTimeField = data.tagFileds.find(
              (field) => field.displayName === "Processing Time"
            );
            return (
              <>
                <ContentItem
                  key={i}
                  title={filed.displayName}
                  viewType={viewType}
                  icon={filed?.icon}
                  render={
                    <ScheduleField
                      // isFullButton
                      // buttonText="Schedule"
                      value={filed.value}
                      tooltipText={null}
                    />
                  }
                />
                {processingTimeField && <ContentItem
                    key={`processing-time-${i}`}
                    title={processingTimeField.displayName}
                    viewType={viewType}
                    icon={null}
                    render={<>
                      <Typography
                          variant={Typography.font_variant.small}
                          fontWeight={Typography.font_weight.regular}
                      >
                        {processingTimeField.value}
                      </Typography>
                    </>}
                />}
              </>
            );
          
          
          
        } else {
          return null;
        }

        return null;
      });

      setContentFileds(content);
    } catch (err) {
      console.log(err);
    }
  };

  return contentFileds;
};

export default SubTagContent;
