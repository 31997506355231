import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { useAppStore } from "../../../../../store/AppStore/AppStore";
import { PublicRoutePath } from "../../../../../routes/paths/public-path";
import { createUserRole } from "../../../../../Actions/Setting/Permission";
import { SYSTEM_ROLE_TYPE } from "../../../../../constant/System";
import API_ENDPOINTS from "../../../../../services/api-endpoints";
import { uploadImage } from "../../../../../Actions/Common";
import API from "../../../../../services/api";
import FromGroup from "../../../../../components/controls/FromGroup";
import ProfileImageUploader from "../../../../../components/controls/ProfileImageUploader";
import * as Styled from "./styled";
import Typography from "../../../../../common/components/Typography";
import SelectField from "../../../../../common/components/Form/SelectField";
import InputField from "../../../../../common/components/Form/InputField";
import UIButton from "../../../../../common/components/Buttons/UIButton";
import IconClose from "../../../../../common/components/icons/Line/Cancel/02";
import IconCorrect from "../../../../../common/components/icons/Fill/correct";
import Logo from "../../../../../common/components/UI/Logo/Logo";
import Alert from "../../../../../utility/alert";

const UserAccountDetails = () => {
  //
  const navigate = useNavigate();
  const location = useLocation();

  const [title, setTitle] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [jobTitle, setJobTitle] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nickName, setNickName] = useState("");
  const [country, setCountry] = useState(0);
  const [city, setCity] = useState("");

  let CountryList = [];
  let JobTitleList = [];

  const [countryOptionList, setCountryOptionList] = useState([]);
  const [jobTitleOptionList, setJobTitleOptionList] = useState([]);

  const userTitles = useAppStore((state) => state.userTitles);
  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);

  useEffect(() => {
    getAndBindCountryList();
    getAndBindJobTitleList();
  }, []);

  //
  const redirectToVerificationPage = () => {
    const email = location?.state?.email;

    if (!email) {
      return;
    }

    // send verification code by email.
    navigate(PublicRoutePath.Verification.path, {
      replace: true,
      state: { email },
    });

    // senEmailVerification({ email, name: "" })
    //   .then((res) => {
    //     if (res?.data?.displayMessage) {
    //       navigate(PublicRoutePath.Verification.path, {
    //         replace: true,
    //         state: { email },
    //       });
    //     }
    //   })
    //   .catch((err) => {});
  };

  // Assign default role for user
  const assignUserRole = (userId) => {
    if (!userId) return;

    createUserRole({ userId, roleId: SYSTEM_ROLE_TYPE.USER })
      .then((res) => {})
      .catch((err) => {
        console.error(err);
      });
  };

  const errorAlert = ({ text }) => {
    return Alert.confirm({
      allowOutsideClick: false,
      title: "Error!",
      text: text,
      confirmButtonText: "OK",
      icon: "error",
      showCancelButton: false,
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("yes");
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (title === "") {
      errorAlert({ text: "Please Enter Title !" });
      // toast.error("Please Enter Title !");
      return;
    }

    if (jobTitle === 0) {
      errorAlert({ text: "Please Enter Job Title !" });
      // toast.error("Please Enter Job Title !");
      return;
    }
    if (firstName === "") {
      errorAlert({ text: "Please Enter First Name !" });
      // toast.error("Please Enter First Name !");
      return;
    }
    if (lastName === "") {
      errorAlert({ text: "Please Enter Last Name !" });
      // toast.error("Please Enter Last Name !");
      return;
    }
    if (nickName === "") {
      errorAlert({ text: "Please Enter Nick Name !" });
      // toast.error("Please Enter Nick Name !");
      return;
    }
    if (country === 0) {
      errorAlert({ text: "Please Enter Country !" });
      // toast.error("Please Enter Country !");
      return;
    }
    if (city === "") {
      errorAlert({ text: "Please Enter City !" });
      // toast.error("Please Enter City !");
      return;
    }

    showLoader(); //Loading Indicator on

    try {
      const signupData = JSON.parse(localStorage.getItem("usersignup"));

      signupData.title = title;
      signupData.jobTilte = jobTitle;
      signupData.firstName = firstName;
      signupData.lastName = lastName;
      signupData.nickName = nickName;
      signupData.country = country;
      signupData.city = city;

      console.log(signupData);

      const endpoints = new API_ENDPOINTS();

      let profileImagePath = null;

      if (profileImage && profileImage?.imageData) {
        const imageFile = {
          containerName: process.env.REACT_APP_PROFILE_IMAGE_CONTAINER,
          imageData: [profileImage?.imageData],
          imageName: [profileImage?.file.name],
          imageType: [profileImage?.file.type],
        };

        // Upload profile image and get url
        const profileRes = await uploadImage(imageFile);

        if (!profileRes.isSuccess) {
          errorAlert({ text: profileRes.displayMessage });
          // toast.error(profileRes.displayMessage);
          hideLoader(); //Loading Indicator off
          profileImagePath = null;
          return;
        }

        profileImagePath = profileRes?.result[0]?.filePath;
      }

      API.post(endpoints.SIGNUP, {
        fullName: null,
        givenName: signupData.firstName,
        surname: signupData.lastName,
        displayName: signupData.nickName,
        nickName: signupData.nickName,
        jobTitleId: signupData.jobTilte,
        CountryId: signupData.country,
        Title: "",
        titleId: signupData.title,
        City: signupData.city,
        email: signupData.email,
        signInType: "emailAddress",
        password: signupData.password,
        issuer: "",
        issuerAssignedId: signupData.email,
        profileImage: profileImagePath,
      }).then((response) => {
        console.log("sign-up response: ", response);
        if (response.data.isSuccess === true) {
          toast.info(response.data.displayMessage);
          localStorage.setItem("usersignup", null);
          setTimeout(3000);

          setTitle("");
          setJobTitle("");
          setFirstName("");
          setLastName("");
          setNickName("");
          setCountry("");
          setCity("");

          let newuser = {
            isNew: true,
            email: signupData.email,
            msg: response.data.displayMessage,
          };

          localStorage.setItem("NEWUSER", JSON.stringify(newuser));
          hideLoader(); //Loading Indicator off

          // Assign role
          const userId = response?.data?.result?.id;
          assignUserRole(userId);

          redirectToVerificationPage();
        } else {
          errorAlert({ text: response.data.errorMessages?.[0] });
          // toast.error(response.data.errorMessages?.[0]);
          hideLoader(); //Loading Indicator off
          return;
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const cancel = async (e) => {
    e.preventDefault();

    navigate(PublicRoutePath.CreateAccount.path, { replace: true });
  };

  const getAndBindCountryList = async (e) => {
    try {
      showLoader(); //Loading Indicator
      const endpoints = new API_ENDPOINTS();

      API.get(endpoints.GETALLCOUNTRIES).then((response) => {
        console.log("counties:", response);
        if (response.data.isSuccess === true) {
          CountryList = response.data.result;

          const country_list_dropdown = CountryList.map((item) => {
            // return <option value={item.countryId}>{item.countryName}</option>;
            return {
              value: item.countryId,
              label: item.countryName,
            };
          });

          setCountryOptionList(country_list_dropdown);

          hideLoader(); //Loading Indicator
        } else {
          console.log(response.data.displayMessage);
          errorAlert({ text: response.data.displayMessage });
          // toast.error(response.data.displayMessage);
          hideLoader(); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
      hideLoader(); //Loading Indicator
    }
  };

  const getAndBindJobTitleList = async (e) => {
    try {
      showLoader(); //Loading Indicator
      const endpoints = new API_ENDPOINTS();

      API.get(endpoints.GETALLJOBTITLES).then((response) => {
        console.log("job titles:", response);
        if (response.data.isSuccess === true) {
          JobTitleList = response.data.result;

          const jobTitle_list_dropdown = JobTitleList.map((item) => {
            return {
              value: item.jobTitleId,
              label: item.jobTitleName,
            };
          });

          setJobTitleOptionList(jobTitle_list_dropdown);

          hideLoader(); //Loading Indicator
        } else {
          console.log(response.data.displayMessage);
          errorAlert({ text: response.data.displayMessage });
          // toast.error(response.data.displayMessage);
          hideLoader(); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
      hideLoader(); //Loading Indicator
    }
  };

  const onProfileImageHandler = (files) => {
    if (files[0]) {
      setProfileImage(files[0]);
    }
  };

  return (
    <Styled.SignUpPage>
      <div className="logo__wrapper">
        <Logo />
      </div>
      <div className="sign__up">
        <div className="sign__up__header">
          <Typography
            className="main__title"
            variant={Typography.font_variant.h1}
            color={Typography.font_color.color_shade_primary}
            fontWeight={Typography.font_weight.bold}
          >
            Let's get started
          </Typography>

          <Typography
            className="main__subtitle"
            variant={Typography.font_variant.base}
            color={Typography.font_color.tint_04}
            fontWeight={Typography.font_weight.regular}
          >
            To start with your account and project, we need to get to know you
            first.
          </Typography>
        </div>

        <div className="sign__up__body">
          <div className="sign__up__form--container">
            <div className="sign__up__form--left">
              <form className="sign__up__form--container-form">
                <FromGroup label="Title" className="input--title">
                  <SelectField
                    name="field1"
                    placeholder="Title"
                    onChange={(data) => setTitle(data.value)}
                    value={userTitles.find((t) => t.value === title)}
                    options={userTitles.map((data) => ({
                      value: data.id,
                      label: data.label,
                    }))}
                  />
                </FromGroup>

                <FromGroup label="Job Title" className="input--job-title">
                  <SelectField
                    name="field2"
                    placeholder="Job Title"
                    onChange={(data) => setJobTitle(data.value)}
                    value={jobTitleOptionList.find((d) => d.value === jobTitle)}
                    options={jobTitleOptionList}
                  />
                </FromGroup>

                <FromGroup label="First Name" className="input--fname">
                  <InputField
                    name="field3"
                    type="text"
                    placeholder="Jorge"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </FromGroup>

                <FromGroup label="Last Name" className="input--lname">
                  <InputField
                    type="text"
                    name="field4"
                    placeholder="Moore"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </FromGroup>

                <FromGroup label="Nick Name" className="input--nick-name">
                  <InputField
                    type="text"
                    name="field5"
                    placeholder="Rico"
                    value={nickName}
                    onChange={(e) => setNickName(e.target.value)}
                  />
                </FromGroup>

                <FromGroup label="Country" className="input--country">
                  <SelectField
                    name="field6"
                    placeholder="Select County"
                    onChange={(data) => setCountry(data.value)}
                    value={countryOptionList.find((d) => d.value === country)}
                    options={countryOptionList}
                  />
                </FromGroup>

                <FromGroup label="City" className="input--city">
                  <InputField
                    type="text"
                    name="field7"
                    placeholder="City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </FromGroup>
              </form>
            </div>

            <div className="sign__up__form--right">
              {/* <ImageUpload /> */}
              <ProfileImageUploader onFileUploaded={onProfileImageHandler} />
            </div>
          </div>

          <div className="sign__up__form--action">
            <UIButton
              type="button"
              variant={UIButton.Variant.Danger}
              startIcon={<IconClose />}
              onClick={cancel}
              size={UIButton.Sizes.Medium}
            >
              Cancel
            </UIButton>

            <UIButton
              type="button"
              variant={UIButton.Variant.Primary}
              startIcon={<IconCorrect />}
              onClick={handleSubmit}
              size={UIButton.Sizes.Medium}
            >
              Save
            </UIButton>
          </div>
        </div>
      </div>
    </Styled.SignUpPage>
  );
};

export default UserAccountDetails;
