import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import {
  getAllProjectsByTenant,
  getAllProjectsByTenantId,
  getAllProjectsByTenantIdAndUserId_ProjectSelector,
} from "../../Actions/Project";

let store = (set, get) => ({
  projects: [],
  tenantProjects: [],
  userTenantProjects: [],
  isFetching: false,
  templates: [],
  isShowOnboardingModal: false,
  projectSelectedMember: null,
  isListReload: false,
  projectList: [],

  setProjectSelectedMember: (member) => {
    set({ projectSelectedMember: member });
  },

  openOnboardingModal: () => {
    set({ isShowOnboardingModal: true });
  },

  onReloadList: (value) => {
    set({ isListReload: value });
  },

  closeOnboardingModal: () => {
    set({ isShowOnboardingModal: false });
  },

  getProjectsByTenant: () => {
    set({ isFetching: true });

    getAllProjectsByTenant()
      .then((projects) => {
        set({ projects: projects, isFetching: false, projectList: projects });
      })
      .catch((err) => {
        set({ projects: [], isFetching: false, projectList: [] });
      });
  },

  getProjectsByTenantId: (id) => {
    getAllProjectsByTenantId(id)
      .then((projects) => {
        set({ tenantProjects: projects, projectList: projects });
      })
      .catch((err) => {
        set({ tenantProjects: [], projectList: [] });
      });
  },

  getProjectsByTenantAndUserId: (tenantid, userid) => {
    getAllProjectsByTenantIdAndUserId_ProjectSelector(tenantid, userid)
      .then((projects) => {
        set({ userTenantProjects: projects, projectList: projects });
      })
      .catch((err) => {
        set({ userTenantProjects: [], projectList: [] });
      });
  },
});

store = devtools(store, { name: "ProjectStore" });

store = persist(store, {
  name: "project_store",
  serialize: (state) => btoa(encodeURI(JSON.stringify(state))),
  deserialize: (str) => JSON.parse(decodeURI(atob(str))),
});

export const useProjectStore = create(store);
