import styled from "styled-components";

export const Container2xl = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding-inline: var(--layout-spacing-24);
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 0fr;

  .contents {
    display: grid;
    grid-template-rows: 1fr;
    gap: var(--layout-spacing-20);
    max-height: 100%;
    overflow: hidden;

    &__body {
      height: 100%;
      overflow: hidden;
      display: grid;
      grid-template-rows: 0fr;
    }

    &__action {
      padding-bottom: var(--layout-spacing-36);
    }

    &__filters {
      /* height: 80px; */
      padding-top: var(--layout-spacing-12);
      padding-bottom: var(--layout-spacing-16);
    }

    &__scroll-view {
      overflow: auto;
    }
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  z-index: 1;
`;
