import styled from "styled-components";
import { fontStyle } from "../../../styled/utility";

export const ProjectContainer = styled.div`
  background-color: var(--layout-background-n10);
  padding: var(--layout-spacing-52) 0;

  .wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .project__wall {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: var(--layout-spacing-52) var(--layout-spacing-48);
    border-radius: var(--layout-borderRadius-8, 8px);
    background: var(--color-neutral-20, #f5f5f7);
    width: 100%;
    max-width: 70%;

    &__header {
      display: flex;
      padding-bottom: var(--layout-spacing-24);

      &__inner {
        display: flex;
        justify-content: space-between;
        flex: 1;

        .title {
          ${fontStyle("base-semi-bold")};
          color: var(--font-color-shade-primary, #012732);
          font-weight: 600;
        }

        .actions {
        }
      }
    }

    &__content {
      display: flex;
      /* justify-content: center; */

      &__inner {
        flex: 1;
      }
    }
  }
`;
