import React from "react";
import ReactDOM from "react-dom";
import * as Styled from "./styled";
import { IconBlock } from "../../../../../../styled/Common/Icons";
import { IconExport } from "../../../../../../common/components/icons";
import { findParentByClass } from "../../../../../../utility";
import { setExportText } from "../../../../store/TextSelectionStore";

const ExportButton = (props) => {
  const { name, value } = props;
  const [input, setInput] = React.useState(null);

  React.useEffect(() => {
    if (name) {
      const inputEl = document.querySelector(`[name="${name}"]`) || null;
      setInput(inputEl);
    }
  }, [name]);

  const handleExport = React.useCallback(
    (e) => {
      if (value && input) {
        const selection = window.getSelection();

        if (selection.type === "Range" && selection.focusNode.contains(input)) {
          setExportText(selection.toString().trim());
          selection.removeAllRanges();
          return;
        }

        setExportText(String(value).trim());
      }
    },
    [value, input]
  );

  const appendElement = React.useMemo(() => {
    if (!input) return null;

    const parent = findParentByClass(input, "field__group");

    if (!parent) return null;

    return parent.querySelector(".label__wrapper") || null;
  }, [input]);

  if (!appendElement || !value) return null;

  return ReactDOM.createPortal(
    <Styled.Button
      type="button"
      onClick={handleExport}
      data-tooltip-text="Export"
      data-tooltip-placement="top"
    >
      <IconBlock className="icon">
        <IconExport />
      </IconBlock>
    </Styled.Button>,
    appendElement
  );
};

export default ExportButton;
