import React from "react";
import { format, isWeekend } from "date-fns";
import * as Styled from "./styled";
import { useScheduleContext } from "../../../../context/ScheduleContext";

const DayColumn = () => {
  const dayItemRef = React.useRef();
  // const datesLengthRef = React.useRef(0);

  const { dates } = useScheduleContext();

  // React.useEffect(() => {
  //   if (
  //     !dates.length ||
  //     !dayItemRef?.current?.childNodes?.length ||
  //     !isDateFetched
  //   ) {
  //     return;
  //   }

  //   const observe = new IntersectionObserver(
  //     (entries) => {
  //       const entry = entries[0];

  //       if (!entry.isIntersecting) {
  //         return;
  //       }

  //       const target = entry.target;
  //       const index = Number(target.getAttribute("data-row-index"));
  //       const data = dates[index];

  //       if (data) {
  //         if (
  //           data?.date &&
  //           format(new Date(currentFocusDate), "yyyy-MM") !==
  //             format(new Date(data?.date), "yyyy-MM")
  //         ) {
  //           console.log("date ", data?.date);

  //           data?.date && updateFocusDate(new Date(data?.date));
  //         }
  //       }
  //     },
  //     {
  //       rootMargin: "-90% 0px 0px 0px",
  //       threshold: 1,
  //     }
  //   );

  //   const days = [...dayItemRef?.current?.childNodes];

  //   days.forEach((el) => {
  //     observe.observe(el);
  //   });
  // }, [currentFocusDate, dates, dates.length, isDateFetched, updateFocusDate]);

  return (
    <Styled.DayColumn className="day__column" label="Day">
      <Styled.DayItems ref={dayItemRef}>
        {dates.map((d, i) => (
          <DayItem
            className="day__element"
            key={i}
            date={d?.date}
            data-row-index={i}
            data-row-id={d?.dateId}
          />
        ))}
      </Styled.DayItems>
    </Styled.DayColumn>
  );
};

function DayItem({ date, ...otherProps }) {
  return (
    <Styled.DayItem
      $isCurrent
      $isWeekend={isWeekend(new Date(date))}
      {...otherProps}
    >
      {format(new Date(date), "EEEEEE")}
    </Styled.DayItem>
  );
}

export default DayColumn;
