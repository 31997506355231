import React from "react";
import { Modal } from "./styled";
import Form from "./Form";
import { useMilestone } from "../../../context/MilestoneContext";

const ConditionModal = () => {
  const {
    isVisibleDueModal,
    toggleDueModalHandler,
    selectedDueCellValues,
    dueValueSubmitHandler,
    eventsList,
  } = useMilestone();

  const isEditMode =
    selectedDueCellValues?.value?.value === null ? false : true;
  const eventId = selectedDueCellValues?.row?.original?.eventId || 0;
  const eventName = eventsList?.find((e) => e.value === eventId)?.label || "";
  console.log(eventId, eventName);

  const onSubmit = React.useCallback(
    (value, options) => {
      dueValueSubmitHandler({
        formValues: value,
        ...selectedDueCellValues,
      });
      options.resetForm();
    },
    [dueValueSubmitHandler, selectedDueCellValues]
  );

  if (!isVisibleDueModal) {
    return null;
  }

  console.log("selected Due Cell Values", selectedDueCellValues);

  return (
    <Modal
      visibility={isVisibleDueModal}
      title={eventId !== 0 ? "(" + eventName + ")" : null}
      subTitle={isEditMode ? "Edit Condition" : "Add Condition"}
      onClose={() => toggleDueModalHandler(false)}
      titleComponent={(props) => <Header {...props} />}
      bodyComponent={(props) => <Body onSubmit={onSubmit} {...props} />}
    />
  );
};

function Header({ subTitle, title }) {
  return (
    <>
      <div className="caption">{subTitle}</div>
      <div className="title">{title}</div>
    </>
  );
}

function Body(props) {
  return <Form {...props} />;
}

export default ConditionModal;
