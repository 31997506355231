import * as React from "react";
import { memo } from "react";
const SvgSave = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1178_2479)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.888883C0 0.397968 0.39797 0 0.888889 0H11.929C12.2591 0 12.5756 0.131134 12.809 0.364472L15.6355 3.19112C15.8689 3.42445 16 3.741 16 4.07104V15.1111C16 15.602 15.602 16 15.1111 16H12.8V8.88894C12.8 8.39802 12.402 8.00005 11.9111 8.00005H4.08889C3.59797 8.00005 3.2 8.39802 3.2 8.88894V16H0.888889C0.39797 16 0 15.602 0 15.1111V0.888883ZM5.06667 9.77771C4.82119 9.77771 4.62222 9.97668 4.62222 10.2222C4.62222 10.4676 4.82119 10.6666 5.06667 10.6666H10.9333C11.1788 10.6666 11.3778 10.4676 11.3778 10.2222C11.3778 9.97668 11.1788 9.77771 10.9333 9.77771H5.06667ZM4.62222 11.9999C4.62222 11.7544 4.82119 11.5555 5.06667 11.5555H10.9333C11.1788 11.5555 11.3778 11.7544 11.3778 11.9999C11.3778 12.2454 11.1788 12.4444 10.9333 12.4444H5.06667C4.82119 12.4444 4.62222 12.2454 4.62222 11.9999ZM5.06667 13.3332C4.82119 13.3332 4.62222 13.5322 4.62222 13.7777C4.62222 14.0232 4.82119 14.2221 5.06667 14.2221H10.9333C11.1788 14.2221 11.3778 14.0232 11.3778 13.7777C11.3778 13.5322 11.1788 13.3332 10.9333 13.3332H5.06667Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1178_2479">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgSave);
export default Memo;
