import {
  intervalToDuration,
  isValid,
  addDays,
  getYear,
  differenceInCalendarWeeks,
  format,
} from "date-fns";

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getDatesDuration = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return null;
  }

  const start = new Date(startDate);
  const end = new Date(endDate);

  return {
    ...intervalToDuration({
      start,
      end,
    }),
    startDate: start,
    endDate: end,
  };
};

export const getDates = (start, end) => {
  start = new Date(start);
  end = new Date(end);

  if (!start || !end || !isValid(start) || !isValid(end)) {
    return [];
  }
  const result = [];

  while (start <= end) {
    result.push(start);
    start = addDays(start, 1);
  }

  return result;
};

export const getYears = ({ numberOfYears = 0, start }) => {
  if (!start || !isValid(start)) return [];

  start = getYear(start);

  return Array(numberOfYears + 1)
    .fill(start)
    .map((d, i) => d + i);
};

export const getWeekCount = (start, end, weekStartsOn = 0) => {
  return differenceInCalendarWeeks(new Date(end), new Date(start), {
    // 0 - Sunday
    weekStartsOn,
  });
};

export const dateFormat = (date) => {
  return format(date, "yyyy-MM-dd");
};
