import * as React from "react";
import { memo } from "react";
const SvgAddress = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_958_1672)">
      <path d="M5.58416 0H7.05338V1.50114H5.58416V0Z" fill="currentColor" />
      <path
        d="M5.58416 7.53952H7.05338V16H5.58416V7.53952Z"
        fill="currentColor"
      />
      <path
        d="M16 4.52024L11.8594 2.0188H0V7.02168H11.8594L16 4.52024Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_958_1672">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgAddress);
export default Memo;
