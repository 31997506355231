import React from "react";

import { styleClass } from "../../../utility/common";
import TableListBody from "./TableListBody";
import TableListHeader from "./TableListHeader";
import "./styles.scss";
import useTableData from "./useTableData";

const TableList = (props) => {
  const { className, data, columns, renderRows, ...others } = props;

  const [sortField, setSortField] = React.useState("");
  const [order, setOrder] = React.useState("asc");

  const { handleSort, buildRows, tableColumns } = useTableData({
    data,
    columns,
    renderRows
  });

  const handleSorting = (name) => {
    const sortOrder = name === sortField && order === "asc" ? "desc" : "asc";
    setSortField(name);
    setOrder(sortOrder);

    handleSort(name, sortOrder);
  };

  return (
    <div className={styleClass("table__list", className)} {...others}>
      <TableListHeader
        sortField={sortField}
        sortOrder={order}
        columns={tableColumns}
        onSortHandler={handleSorting}
      />
      <TableListBody data={buildRows} />
    </div>
  );
};

TableList.Row = ({ className, children, ...other }) => {
  return (
    <div className={styleClass("rows", className)} {...other}>
      {children}
    </div>
  );
};

TableList.Column = ({ className, children, width = "auto", ...other }) => {
  return (
    <div
      className={styleClass("column", className)}
      style={{ width, ...other.style }}
      {...other}
    >
      {children}
    </div>
  );
};

export default TableList;
