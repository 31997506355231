import styled from "styled-components";
import BaseFlexModal from "../../../../../../../common/components/UI/Modal/BaseFlexModal";
import { fontStyle } from "../../../../../../../styled/utility";

export const Modal = styled(BaseFlexModal)`
  --modal-max-width: 480px;

  .header__container {
    padding-bottom: var(--layout-spacing-20);

    .header__content {
      width: 100%;
    }
  }

  .btn__close {
    position: absolute;
    right: var(--layout-spacing-28);
    top: var(--layout-spacing-28);
  }
`;

export const TitleContainer = styled.div`
  width: inherit;
  padding-bottom: var(--layout-spacing-4);
  border-bottom: 0.5px solid;
  border-bottom-color: var(--color-neutral-50, #c1c4ce);

  .addText {
    ${fontStyle("small-regular")};
  }

  .activityText {
    color: var(--font-color-shade-accent, #3c7f84);
    ${fontStyle("h6-bold")};
  }
`;

export const ActivitySection = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--layout-spacing-8, 8px);

  .titlesContainer {
    display: flex;
    align-items: center;
    gap: var(--layout-spacing-8, 8px);
    align-self: stretch;
    ${fontStyle("label-medium")};
    font-weight: 500;
  }

  .actionsContainer {
    display: flex;
    flex-direction: column;
    gap: var(--layout-spacing-8, 8px);
  }

  > * {
    &:last-child {
      margin-top: var(--layout-spacing-12);
    }
  }
`;

export const ActivityRow = styled.div`
  display: flex;
  //justify-content: space-between;
  gap: var(--layout-spacing-8);
  align-items: center;
`;
