import styled from "styled-components";
import { fontStyle } from "../../../../../../styled/utility";

export const TimeBlockWrapper = styled.div`
  flex: 1;
  padding-block: var(--layout-spacing-8);

  .time__blocks {
    width: 100%;
    flex: 1;
  }
`;

export const TimeBlock = styled.div`
  height: var(--layout-size-40);
  position: relative;
  user-select: none;
  cursor: auto;
  --color: var(--font-tint-04, #969bac);

  ${({ $isCurrentTime }) => {
    if ($isCurrentTime) {
      return `
       --color: var(--font-color-shade-danger, #A62222);
       `;
    }
  }}

  span {
    ${fontStyle("x-small-regular")};
    line-height: 1;
    color: var(--color);
    position: absolute;
    left: 0;
    top: -5px;
    background-color: var(--font-default-white);
    width: var(--layout-size-36);
    pointer-events: none;
  }

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 1px solid var(--color);
    width: 100%;
    height: 1px;
    pointer-events: none;
    opacity: 1;
  }

  &::after {
    display: none;
  }

  &:last-child {
    &::after {
      display: block;
      top: unset;
      bottom: 0;
      left: var(--layout-size-36);
      width: calc(100% - var(--layout-size-36));
    }
  }
`;

export const EventItems = styled.div`
  position: absolute;
  z-index: 1;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
