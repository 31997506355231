import React from "react";

import FieldControl from "../FieldControl";

const InputField = (props, ref) => {
  const inputRef = React.useRef();

  const {
    inputOuterClass,
    className,
    type = "text",
    size = "sm",
    startIcon = null,
    endIcon = null,
    isEndIconActive,
    value,
    disabled,
    ...other
  } = props;

  return (
    <FieldControl
      inputOuterClass={inputOuterClass}
      size={size}
      startIcon={startIcon}
      endIcon={endIcon}
      hasStartIcon={startIcon !== null}
      hasEndIcon={endIcon !== null}
      isDisabled={disabled}
      isEndIconActive={isEndIconActive}
    >
      <input
        className={className}
        ref={(node) => {
          inputRef.current = node;

          if (typeof ref === "function") {
            ref(node);
          } else if (ref) {
            ref.current = node;
          }
        }}
        type={type}
        disabled={disabled}
        value={value}
        {...other}
      />
    </FieldControl>
  );
};

export default React.forwardRef(InputField);
