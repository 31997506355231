import React from 'react'
// import SchedularLegends from '../../components/SchedularLegends'
import SwitchField from './../../../../common/components/Form/SwitchField';
import ViewController from '../../components/ViewController';
import SkipHoliday from '../../components/SkipHoliday';

const Ui = () => {
  return (
    <>
      <ViewController />
      <SkipHoliday />
      {/* <ActivityBlockModal /> */}
      {/* <SchedularLegends /> */}
    </>
  )
}

export default Ui