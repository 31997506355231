import React from "react";

import * as Styled from "./styled";
import Typography from "../../Typography";

const FieldGroup = (props) => {
  const {
    variant = "vertical",
    hint,
    error,
    label,
    children,
    className,
    colSizes = ["auto", "auto"],
  } = props;

  const fieldGroupClasses = React.useMemo(() => {
    return ["field__group", `field__group--${variant}`, className].join(" ");
  }, [variant, className]);

  return (
    <Styled.FieldGroup
      variant={variant}
      className={fieldGroupClasses}
      colLeft={colSizes[0] || "auto"}
      colRight={colSizes[1] || "auto"}
    >
      {label ? (
        <div className="label__wrapper">
          <Typography
            as="label"
            variant={Typography.font_variant.small}
            fontWeight={Typography.font_weight.medium}
          >
            {label}
          </Typography>
        </div>
      ) : null}

      <div className="control__wrapper">
        <div className="element">{children}</div>
      </div>

      {hint ? <div className="hint__wrapper">{hint}</div> : null}

      {error ? <div className="error__wrapper">{error}</div> : null}
    </Styled.FieldGroup>
  );
};

// FieldGroup.propTypes = {
//   hint: PropTypes.string,
//   error: PropTypes.any,
//   label: PropTypes.string,
//   className: PropTypes.any,

//   /**
//    * @param variant one of  "vertical" or "horizontal"
//    */
//   variant: PropTypes.oneOf("vertical", "horizontal").isRequired,
// };

export default FieldGroup;
