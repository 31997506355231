import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.128 8C10.128 9.16953 9.17526 10.1176 8 10.1176C6.82474 10.1176 5.87198 9.16953 5.87198 8C5.87198 6.83047 6.82474 5.88235 8 5.88235C9.17526 5.88235 10.128 6.83047 10.128 8Z"
      fill="currentColor"
    />
    <path
      d="M2.12802 10.1176C3.30014 10.1176 4.25605 9.16641 4.25605 8C4.25605 6.8336 3.30014 5.88235 2.12802 5.88235C0.955903 5.88235 0 6.8336 0 8C0 9.16641 0.955903 10.1176 2.12802 10.1176ZM2.12802 6.48632C2.96633 6.48632 3.64912 7.16578 3.64912 8C3.64912 8.83423 2.96633 9.51369 2.12802 9.51369C1.28971 9.51369 0.606923 8.83423 0.606923 8C0.606923 7.16578 1.28971 6.48632 2.12802 6.48632Z"
      fill="currentColor"
    />
    <path
      d="M15.9128 8.55112C15.9621 8.3737 16 8.19252 16 8C16 7.80749 15.9659 7.6263 15.9128 7.44888V8.55112Z"
      fill="currentColor"
    />
    <path
      d="M15.4075 9.42309C15.4909 9.34005 15.5668 9.24568 15.6351 9.15131V6.8487C15.5668 6.75055 15.4909 6.65996 15.4075 6.57691V9.42309Z"
      fill="currentColor"
    />
    <path
      d="M14.6185 6.10129C14.5426 6.07487 14.4706 6.04844 14.3909 6.02957V9.97043C14.4706 9.95533 14.5426 9.92514 14.6185 9.89871V6.10129Z"
      fill="currentColor"
    />
    <path
      d="M15.1283 6.34665C15.0562 6.29758 14.9804 6.25983 14.9007 6.21831V9.78169C14.9766 9.74017 15.0562 9.70242 15.1283 9.65335V6.34665Z"
      fill="currentColor"
    />
    <path
      d="M11.9488 8C11.9488 8.25669 12.0019 8.49827 12.0891 8.72476V7.27902C12.0019 7.50173 11.9488 7.74332 11.9488 8Z"
      fill="currentColor"
    />
    <path
      d="M13.3826 9.91759C13.4585 9.94023 13.5306 9.96288 13.6102 9.97798V6.02202C13.5306 6.03712 13.4585 6.05977 13.3826 6.08242V9.91759Z"
      fill="currentColor"
    />
    <path
      d="M12.3698 6.78453V9.21548C12.4381 9.30607 12.514 9.38912 12.5974 9.46839V6.53162C12.514 6.61089 12.4381 6.69393 12.3698 6.78453Z"
      fill="currentColor"
    />
    <path
      d="M14.1147 10.0006V5.99937C14.0692 5.9956 14.0237 5.98427 13.9744 5.98427C13.944 5.98427 13.9175 5.99182 13.8872 5.99182V10.0044C13.9175 10.0044 13.944 10.012 13.9744 10.012C14.0237 10.0157 14.0692 10.0044 14.1147 10.0006Z"
      fill="currentColor"
    />
    <path
      d="M13.1019 6.18811C13.0261 6.22586 12.9464 6.26361 12.8743 6.3089V9.68733C12.9464 9.73262 13.0223 9.77414 13.1019 9.80812V6.18811Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
