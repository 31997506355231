import * as React from "react";
import { memo } from "react";
const SvgPicker = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1226_5989)">
      <path
        d="M15.52 3.08645C16.176 2.36645 16.16 1.24645 15.456 0.542448C14.768 -0.161566 13.648 -0.177553 12.912 0.478437L11.6 1.77442L10.304 0.478437L8.40003 2.38244L9.712 3.69441L2.288 11.1024C1.71201 11.6624 0.48 14.0784 0 15.4384L0.56 15.9984C1.92 15.5184 4.24 14.3504 4.896 13.7104L12.32 6.30241L13.632 7.61438L15.52 5.69438L14.208 4.38241L15.52 3.08645ZM7.984 9.05445H5.904L10.496 4.46245L11.536 5.50245C11.536 5.51844 9.776 7.26245 7.984 9.05445Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1226_5989">
        <rect width={15.9984} height={15.9984} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgPicker);
export default Memo;
