import React from "react";
import Logo from "../../../../assets/logo.svg";
import * as Styled from "./styled";

const LogoIcon = ({isFull=false, to}) => {
  return (
    <Styled.LogoWrapper isFull={isFull} to={to}>
       <img className="logo_icon" src={Logo} alt="logo" />
    </Styled.LogoWrapper>
  );
};

export default LogoIcon;
