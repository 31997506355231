import styled from "styled-components";
import { fontStyle } from "../../../../styled/utility";

export const ViewControllerContainer = styled.div`
    display: inline-flex;
    padding: var(--layout-spacing-16);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--layout-spacing-16, 16px);

    border-radius: var(--layout-border-radius-8, 8px);
    background: var(--layout-background-white, #FFF);
    box-shadow: -3px 8px 15px 0px rgba(45, 89, 102, 0.08);
`

export const ViewControllerSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--layout-spacing-12, 12px);

    .header {
        gap: var(--layout-spacing-2);
        
        .title{
            color: var(--font-default-color);
            ${fontStyle("x-small-semi-bold")};
        }
    }

    .viewList{
        display: flex;
        padding-left: var(--layout-spacing-4);
        flex-direction: column;
        gap: var(--layout-spacing-8);
    }
`
