import * as React from "react";
import { memo } from "react";
const SvgDrag = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.55416 1.93935C6.55416 2.91692 5.79381 3.70934 4.85587 3.70934C3.91794 3.70934 3.15759 2.91689 3.15759 1.93935C3.15759 0.961819 3.91794 0.169366 4.85587 0.169366C5.79381 0.169366 6.55416 0.961819 6.55416 1.93935Z"
      fill="currentColor"
    />
    <path
      d="M6.391 1.93935C6.3861 2.60077 5.98641 3.21798 5.38277 3.44237C4.77099 3.67021 4.08742 3.47297 3.66973 2.95438C3.25534 2.43918 3.21129 1.67575 3.54574 1.10619C3.87366 0.543386 4.52786 0.237328 5.1495 0.368259C5.86569 0.519591 6.3861 1.17933 6.391 1.93935C6.39263 2.15869 6.71894 2.15866 6.71732 1.93932C6.71242 1.12657 6.22136 0.398874 5.49048 0.114927C4.76776 -0.163926 3.91457 0.0775211 3.42676 0.699825C2.9308 1.33234 2.85412 2.24028 3.26524 2.94427C3.6731 3.64309 4.47087 3.99845 5.23764 3.83863C6.09413 3.6584 6.71248 2.84225 6.71903 1.93937C6.71903 1.72003 6.39263 1.72002 6.391 1.93935Z"
      fill="currentColor"
    />
    <path
      d="M6.55416 8.00089C6.55416 8.97846 5.79381 9.77088 4.85587 9.77088C3.91794 9.77088 3.15759 8.97843 3.15759 8.00089C3.15759 7.02336 3.91794 6.23091 4.85587 6.23091C5.79381 6.23091 6.55416 7.02336 6.55416 8.00089Z"
      fill="currentColor"
    />
    <path
      d="M6.391 8.00089C6.3861 8.66231 5.98641 9.27952 5.38277 9.50391C4.77099 9.73175 4.08742 9.53452 3.66973 9.01592C3.25534 8.50072 3.21129 7.73729 3.54574 7.16773C3.87366 6.60493 4.52786 6.29887 5.1495 6.4298C5.86569 6.58113 6.3861 7.24087 6.391 8.00089C6.39263 8.22023 6.71894 8.2202 6.71732 8.00086C6.71242 7.18811 6.22136 6.46041 5.49048 6.17647C4.76776 5.89761 3.91457 6.13906 3.42676 6.76137C2.9308 7.39388 2.85412 8.30182 3.26524 9.00581C3.6731 9.70463 4.47087 10.06 5.23764 9.90017C6.09413 9.71994 6.71248 8.90379 6.71903 8.00091C6.71903 7.78157 6.39263 7.78156 6.391 8.00089Z"
      fill="currentColor"
    />
    <path
      d="M6.55416 14.0629C6.55416 15.0404 5.79381 15.8329 4.85587 15.8329C3.91794 15.8329 3.15759 15.0404 3.15759 14.0629C3.15759 13.0853 3.91794 12.2929 4.85587 12.2929C5.79381 12.2929 6.55416 13.0853 6.55416 14.0629Z"
      fill="currentColor"
    />
    <path
      d="M6.391 14.0629C6.3861 14.7243 5.98641 15.3415 5.38277 15.5659C4.77099 15.7937 4.08742 15.5965 3.66973 15.0779C3.25534 14.5627 3.21129 13.7993 3.54574 13.2297C3.87366 12.6669 4.52786 12.3608 5.1495 12.4918C5.86569 12.6431 6.3861 13.3028 6.391 14.0629C6.39263 14.2822 6.71894 14.2822 6.71732 14.0628C6.71242 13.2501 6.22136 12.5224 5.49048 12.2384C4.76776 11.9596 3.91457 12.201 3.42676 12.8233C2.9308 13.4559 2.85412 14.3638 3.26524 15.0678C3.6731 15.7666 4.47087 16.122 5.23764 15.9621C6.09413 15.7819 6.71248 14.9658 6.71903 14.0629C6.71903 13.8435 6.39263 13.8435 6.391 14.0629Z"
      fill="currentColor"
    />
    <path
      d="M12.8351 1.93935C12.8351 2.91692 12.0748 3.70934 11.1368 3.70934C10.1989 3.70934 9.43856 2.91689 9.43856 1.93935C9.43856 0.961819 10.1989 0.169366 11.1368 0.169366C12.0748 0.169366 12.8351 0.961819 12.8351 1.93935Z"
      fill="currentColor"
    />
    <path
      d="M12.672 1.93935C12.6671 2.60077 12.2674 3.21798 11.6637 3.44237C11.052 3.67021 10.3684 3.47297 9.9507 2.95438C9.53631 2.43918 9.49227 1.67575 9.82671 1.10619C10.1546 0.543386 10.8088 0.237328 11.4305 0.368259C12.1467 0.519591 12.6671 1.17933 12.672 1.93935C12.6736 2.15869 12.9999 2.15866 12.9983 1.93932C12.9934 1.12657 12.5023 0.398874 11.7715 0.114927C11.0487 -0.163926 10.1955 0.077521 9.70773 0.699825C9.21177 1.33234 9.13509 2.24028 9.54621 2.94427C9.95407 3.64309 10.7518 3.99845 11.5186 3.83863C12.3751 3.6584 12.9935 2.84225 13 1.93937C13 1.72003 12.6736 1.72002 12.672 1.93935Z"
      fill="currentColor"
    />
    <path
      d="M12.8351 8.00089C12.8351 8.97846 12.0748 9.77088 11.1368 9.77088C10.1989 9.77088 9.43856 8.97843 9.43856 8.00089C9.43856 7.02336 10.1989 6.23091 11.1368 6.23091C12.0748 6.23091 12.8351 7.02336 12.8351 8.00089Z"
      fill="currentColor"
    />
    <path
      d="M12.672 8.00089C12.6671 8.66231 12.2674 9.27952 11.6637 9.50391C11.052 9.73175 10.3684 9.53452 9.9507 9.01592C9.53631 8.50072 9.49227 7.73729 9.82671 7.16773C10.1546 6.60493 10.8088 6.29887 11.4305 6.4298C12.1467 6.58113 12.6671 7.24087 12.672 8.00089C12.6736 8.22023 12.9999 8.2202 12.9983 8.00086C12.9934 7.18811 12.5023 6.46041 11.7715 6.17647C11.0487 5.89761 10.1955 6.13906 9.70773 6.76137C9.21177 7.39388 9.13509 8.30182 9.54621 9.00581C9.95407 9.70463 10.7518 10.06 11.5186 9.90017C12.3751 9.71994 12.9935 8.90379 13 8.00091C13 7.78157 12.6736 7.78156 12.672 8.00089Z"
      fill="currentColor"
    />
    <path
      d="M12.8351 14.0629C12.8351 15.0404 12.0748 15.8329 11.1368 15.8329C10.1989 15.8329 9.43856 15.0404 9.43856 14.0629C9.43856 13.0853 10.1989 12.2929 11.1368 12.2929C12.0748 12.2929 12.8351 13.0853 12.8351 14.0629Z"
      fill="currentColor"
    />
    <path
      d="M12.672 14.0629C12.6671 14.7243 12.2674 15.3415 11.6637 15.5659C11.052 15.7937 10.3684 15.5965 9.9507 15.0779C9.53631 14.5627 9.49227 13.7993 9.82671 13.2297C10.1546 12.6669 10.8088 12.3608 11.4305 12.4918C12.1467 12.6431 12.6671 13.3028 12.672 14.0629C12.6736 14.2822 12.9999 14.2822 12.9983 14.0628C12.9934 13.2501 12.5023 12.5224 11.7715 12.2384C11.0487 11.9596 10.1955 12.201 9.70773 12.8233C9.21177 13.4559 9.13509 14.3638 9.54621 15.0678C9.95407 15.7666 10.7518 16.122 11.5186 15.9621C12.3751 15.7819 12.9935 14.9658 13 14.0629C13 13.8435 12.6736 13.8435 12.672 14.0629Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgDrag);
export default Memo;
