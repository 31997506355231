import React from "react";
import ReactDOM from "react-dom";
import { format, parse, isValid } from "date-fns";

import { hasClassName } from "../../../../utility";
// import DatePicker from "../../DatePicker";
import DatePicker from "../../Calendar";
import Popover from "../../Popover";
import FieldControl from "../FieldControl";
import * as Styled from "./styled";

const DatePickerField = (props, ref) => {
  const {
    inputOuterClass,
    className,
    size = "sm",
    startIcon = null,
    value = "",
    disabled,
    displayFormat = "yyyy-MM-dd",
    onDateSelect,
    fromDate,
    toDate,
    dateIsRequired,
    defaultMonth,
    ...other
  } = props;

  const inputRef = React.useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isShowDatePicker, setIsShowDatePicker] = React.useState(false);

  const [inputValue, setInputValue] = React.useState("");
  const [selectedDay, setSelectedDay] = React.useState();
  // parse(value, displayFormat, new Date())

  const onDateSelectHandler = React.useCallback(
    (date) => {
      setSelectedDay(date);

      if (date) {
        const formatDate = format(date, displayFormat);
        setInputValue(formatDate);
        onDateSelect(formatDate);
        setIsShowDatePicker(false);
      } else {
        setInputValue("");
      }
    },
    [displayFormat, onDateSelect]
  );

  const renderCalendar = React.useMemo(() => {
    return isShowDatePicker
      ? ReactDOM.createPortal(
          <Popover
            anchorEl={anchorEl}
            placement="bottom-start"
            hideOverlay
            modifiers={[
              {
                name: "offset",
                options: {
                  offset: [0, 8],
                },
              },
            ]}
          >
            <Styled.DatePickerContainer className="calender__popover">
              <DatePicker
                initialFocus={isShowDatePicker}
                mode="single"
                defaultMonth={defaultMonth || selectedDay}
                selected={selectedDay}
                onSelect={onDateSelectHandler}
                hideFooter
                fromDate={fromDate}
                toDate={toDate}
                required={dateIsRequired}
              />
            </Styled.DatePickerContainer>
          </Popover>,
          document.body
        )
      : null;
  }, [
    isShowDatePicker,
    anchorEl,
    selectedDay,
    onDateSelectHandler,
    fromDate,
    toDate,
    dateIsRequired,
    defaultMonth,
  ]);

  const onSetInputValue = React.useCallback(
    (value) => {
      setInputValue(value);
      const date = parse(value, displayFormat, new Date());

      if (isValid(date)) {
        setSelectedDay(date);
      } else {
        setSelectedDay(undefined);
        onDateSelect("");
      }
    },
    [displayFormat, onDateSelect]
  );

  const handleInputChange = (e) => {
    onSetInputValue(e.currentTarget.value);
  };

  // Set initial date
  React.useLayoutEffect(() => {
    const date = new Date(value);
    if (isValid(date)) {
      const formatDate = format(date, displayFormat);
      onSetInputValue(formatDate);
    } else {
      setInputValue("");
    }
  }, [displayFormat, onSetInputValue, value]);

  // Popover hide event
  React.useLayoutEffect(() => {
    const onDocumentClick = (e) => {
      if (e.target === inputRef.current) {
        return;
      }

      if (isShowDatePicker) {
        if (!hasClassName(e.target, "calender__popover")) {
          setIsShowDatePicker(false);
        }
      }
    };

    document.addEventListener("click", onDocumentClick);

    return () => {
      document.removeEventListener("click", onDocumentClick);
    };
  }, [isShowDatePicker]);

  return (
    <FieldControl
      ref={setAnchorEl}
      className={inputOuterClass}
      size={size}
      startIcon={startIcon}
      hasStartIcon={startIcon !== null}
      isDisabled={disabled}
    >
      <input
        ref={(_ref) => {
          inputRef.current = _ref;
          return ref;
        }}
        type="text"
        disabled={disabled}
        value={inputValue}
        onFocus={() => setIsShowDatePicker(true)}
        onChange={handleInputChange}
        autoComplete="off"
        {...other}
      />

      {renderCalendar}
    </FieldControl>
  );
};

export default React.forwardRef(DatePickerField);
