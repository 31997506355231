import * as React from "react";
import { memo } from "react";
const SvgCallsheet = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.6911 0.00012207H1.3329C1.14889 0.00012207 1 0.14934 1 0.333454V14.3289C1 14.7765 1.17327 15.1964 1.48765 15.5115C1.80202 15.8264 2.22139 15.9998 2.66872 15.9998H5.3404C5.34098 15.9998 5.34144 16.0001 5.34202 16.0001H13.0784C13.5202 16.0001 13.9389 15.8273 14.2585 15.5117C14.5732 15.1968 14.7465 14.7759 14.7465 14.3265C14.7465 14.2381 14.7114 14.1533 14.6489 14.0908C14.5865 14.0283 14.5017 13.9932 14.4136 13.9932L13.0241 13.9935V0.333532C13.0241 0.149416 12.8752 0.000199763 12.6912 0.000199763L12.6911 0.00012207ZM3.67517 14.3289C3.67517 14.8826 3.22361 15.3331 2.66867 15.3331C2.39917 15.3331 2.14722 15.2292 1.95833 15.0402C1.76978 14.851 1.66575 14.5984 1.66575 14.3289V0.666786H12.3581V13.9936L4.00802 13.9955C3.82401 13.9955 3.67512 14.1447 3.67512 14.3289L3.67517 14.3289ZM14.0257 14.66C13.9769 14.8017 13.8969 14.931 13.7893 15.0385C13.5965 15.2286 13.3443 15.3335 13.0783 15.3335H12.6928C12.6922 15.3335 12.6917 15.3331 12.6911 15.3331H3.99749C4.14606 15.1374 4.25685 14.9115 4.30757 14.6622L14.0257 14.66Z"
      fill="currentColor"
    />
    <path
      d="M2.80664 2.74978H11.1216C11.3056 2.74978 11.4545 2.60056 11.4545 2.41645C11.4545 2.23233 11.3056 2.08311 11.1216 2.08311H2.80664C2.62263 2.08311 2.47374 2.23233 2.47374 2.41645C2.47374 2.6005 2.62264 2.74978 2.80664 2.74978Z"
      fill="currentColor"
    />
    <path
      d="M2.80664 4.82944H11.1216C11.3056 4.82944 11.4545 4.68022 11.4545 4.49611C11.4545 4.31199 11.3056 4.16277 11.1216 4.16277H2.80664C2.62263 4.16277 2.47374 4.31199 2.47374 4.49611C2.47374 4.68016 2.62264 4.82944 2.80664 4.82944Z"
      fill="currentColor"
    />
    <path
      d="M2.80664 6.90876H11.1216C11.3056 6.90876 11.4545 6.75955 11.4545 6.57543C11.4545 6.39132 11.3056 6.2421 11.1216 6.2421H2.80664C2.62263 6.2421 2.47374 6.39132 2.47374 6.57543C2.47374 6.75955 2.62264 6.90876 2.80664 6.90876Z"
      fill="currentColor"
    />
    <path
      d="M2.80664 8.98842H11.1216C11.3056 8.98842 11.4545 8.8392 11.4545 8.65509C11.4545 8.47098 11.3056 8.32176 11.1216 8.32176H2.80664C2.62263 8.32176 2.47374 8.47098 2.47374 8.65509C2.47374 8.8392 2.62264 8.98842 2.80664 8.98842Z"
      fill="currentColor"
    />
    <path
      d="M8.20609 10.7344C8.20609 10.5503 8.0572 10.4011 7.87319 10.4011H2.80664C2.62263 10.4011 2.47374 10.5503 2.47374 10.7344C2.47374 10.9185 2.62264 11.0677 2.80664 11.0677H7.87319C8.0572 11.0677 8.20609 10.9185 8.20609 10.7344Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgCallsheet);
export default Memo;
