import * as React from "react";
import { memo } from "react";
const SvgIconAdd = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 18.45 18.45"
    {...props}
  >
    <path
      id="noun-add-1316971"
      d="M129.225,120H120v9.225A9.225,9.225,0,1,0,129.225,120Zm1.153,12.685a1.156,1.156,0,0,1-1.153,1.153,1.172,1.172,0,0,1-1.153-1.153v-2.306h-2.306a1.156,1.156,0,0,1-1.153-1.153,1.172,1.172,0,0,1,1.153-1.153h2.306v-2.306a1.156,1.156,0,0,1,1.153-1.153,1.172,1.172,0,0,1,1.153,1.153v2.306h2.306a1.156,1.156,0,0,1,1.153,1.153,1.172,1.172,0,0,1-1.153,1.153h-2.306Z"
      transform="translate(-120 -120)"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgIconAdd);
export default Memo;
