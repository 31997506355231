import React from "react";
import { Container, Container2xl, LoaderWrapper } from "./styled";
import Header from "./components/Header";
import AddButton from "../../components/AddButton";
import Filters from "./components/Filters";
import { MilestoneProvider, useMilestone } from "./context/MilestoneContext";
import MilestoneTable from "./components/MilestoneTable";
import AnimateLoader from "../../../../common/components/AnimateLoader";

const Tracker = () => {
  return (
    <MilestoneProvider>
      <LayoutWrapper />
    </MilestoneProvider>
  );
};

function LayoutWrapper() {
  const { loaders } = useMilestone();

  if (loaders?.initialLoader) {
    return (
      <LoaderWrapper>
        <AnimateLoader />
      </LoaderWrapper>
    );
  }

  return (
    <Container>
      {/* Main header */}
      <Header />

      {/* Main Content  */}
      <Content />
    </Container>
  );
}

function Content() {
  const { addRowHandler, loaders } = useMilestone();

  return (
    <div className="contents">
      <div className="contents__body">
        <div className="contents__filters">
          <Container2xl>
            <Filters />
          </Container2xl>
        </div>

        <div className="contents__scroll-view">
          <Container2xl>
            <MilestoneTable />
          </Container2xl>
        </div>
      </div>

      <div className="contents__action">
        <Container2xl>
          <AddButton
            isLoading={loaders.sectionLoader}
            size="lg"
            label="Add Section"
            onClick={addRowHandler}
          />
        </Container2xl>
      </div>
    </div>
  );
}

export default Tracker;
