import * as React from "react";
import { memo } from "react";
const SvgIconArrowLeft = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.223996 5.46101L5.67465 0.215563C5.99198 -0.0718542 6.47732 -0.0718542 6.776 0.215563C7.07467 0.50298 7.07467 0.970044 6.776 1.27545L1.86662 6L6.776 10.7245C7.07467 11.012 7.07467 11.497 6.776 11.7844C6.47734 12.0719 5.99201 12.0719 5.67465 11.7844L0.223996 6.52099C-0.0746653 6.23358 -0.0746653 5.76651 0.223996 5.46111V5.46101Z"
      fill="white"
    />
  </svg>
);
const Memo = memo(SvgIconArrowLeft);
export default Memo;
