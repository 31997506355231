import React, { useState } from "react";
import UIButton from "../../../../../common/components/Buttons/UIButton";
import FieldGroup from "../../../../../common/components/Form/FieldGroup";
import InputField from "../../../../../common/components/Form/InputField";
import * as LineIcon from "../../../../../common/components/icons/Line";
import { validateVerificationCode } from "../../../../../Actions/User/EmailVerification";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ROUTES_PATH } from "../../../../../routes/paths";
import { useAppStore } from "../../../../../store/AppStore/AppStore";
import * as Yup from "yup";
import { useFormik } from "formik";

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .required("This field is required")
    .min(6, "Minimum 6 characters required"),
});

const initialValues = {
  code: "",
};

const EmailVerificationForm = (props) => {
  const { email, codeRef } = props;

  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);

  const navigate = useNavigate();

  const onSubmitHandler = async (value) => {
    showLoader();

    validateVerificationCode({
      email: email,
      code: value.code,
    })
      .then((res) => {
        hideLoader();
        const data = res?.data;
        if (!data.isSuccess) {
          toast.error(data?.errorMessages && data.errorMessages);
          return;
        }

        const message = data?.result.message;

        if (!data?.result?.isVerified) {
          setFieldError("code", message);
          return;
        }

        toast.success(message, {
          onClose: () => {
            navigate(ROUTES_PATH.PublicRoutePath.Login.path, { replace: true });
          },
        });
      })
      .catch((err) => {
        setFieldError("code", "Code you entered was not valid");
      });
  };
  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: onSubmitHandler,
    validateOnChange: true,
    validateOnBlur: true,
  });

  const { values, errors, handleChange, touched, handleSubmit, setFieldError } =
    formik;

  return (
    <form onSubmit={handleSubmit}>
      <div className="form__content">
        <FieldGroup
          label="Enter verification code"
          error={touched.code && errors.code}
        >
          <InputField
            startIcon={<LineIcon.Access1 />}
            ref={codeRef}
            name={"code"}
            type="password"
            value={values.code}
            onChange={handleChange}
          />
        </FieldGroup>
      </div>

      <div className="form__actions">
        <UIButton type="submit" fullWidth>
          {" "}
          Verify
        </UIButton>
      </div>
    </form>
  );
};

export default EmailVerificationForm;
