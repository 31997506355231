import * as React from "react";
import { memo } from "react";
const SvgFavorite = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2192_10413)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM7.96172 4.58335C7.98773 4.56358 8.01917 4.55226 8.05181 4.55089V4.54558C8.08665 4.54422 8.12098 4.55428 8.14957 4.57424C8.17815 4.59421 8.19943 4.623 8.21014 4.65618L8.96317 6.98726L11.4117 6.98268C11.4455 6.98262 11.4784 6.9933 11.5058 7.01315C11.5332 7.03301 11.5535 7.06103 11.564 7.09319C11.5744 7.12535 11.5744 7.16 11.5639 7.19214C11.5534 7.22429 11.533 7.25228 11.5056 7.27209L9.52151 8.7115L10.2821 11.0403C10.2924 11.0723 10.2924 11.1068 10.2819 11.1388C10.2715 11.1708 10.2513 11.1987 10.2241 11.2185C10.1969 11.2383 10.1641 11.249 10.1305 11.2491C10.0968 11.2492 10.064 11.2387 10.0367 11.2191L8.05787 9.77513L6.07984 11.2191C6.05253 11.239 6.0196 11.2498 5.98578 11.2498C5.95196 11.2499 5.91899 11.2392 5.89163 11.2193C5.86426 11.1994 5.8439 11.1714 5.83348 11.1392C5.82306 11.107 5.82311 11.0724 5.83363 11.0403L6.59575 8.71677L4.61166 7.27741C4.58425 7.2576 4.56384 7.22961 4.55334 7.19746C4.54285 7.16531 4.54282 7.13067 4.55326 7.09851C4.5637 7.06635 4.58407 7.03832 4.61145 7.01847C4.63882 6.99862 4.67178 6.98794 4.7056 6.988L7.15408 6.99253L7.90635 4.6615C7.91639 4.6304 7.93571 4.60313 7.96172 4.58335Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2192_10413">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgFavorite);
export default Memo;
