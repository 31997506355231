import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import * as Loader from "react-loader-spinner";

import API_ENDPOINTS from "../../../services/api-endpoints";
import API from "../../../services/api";

import SingleHeader from "../layout/single_header";
import login from "../../img/login.svg";
import "react-toastify/dist/ReactToastify.css";
import "../../sass/login/login.scss";
import { validatePassword } from "../../../utility";
import { useAppStore } from "../../../store/AppStore/AppStore";
import FromGroup from "../../controls/FromGroup";
import PasswordField from "../../ui-components/Form/PasswordField";

const ChangePassword = () => {
  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);

  // Status

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    // console.log("params:: ", query.get("token"));
    setToken(query.get("token"));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword.length < 5) {
      toast.error("Passwords must have minimum 5 characters !");
      setTimeout(3000);
      return;
    }

    if (!validatePassword(newPassword)) {
      toast.error(
        "Password must contain uppercase,lowercase and special character!"
      );
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("Passwords are not match !");
      setTimeout(3000);
      return;
    }

    // show loader
    showLoader();

    try {
      const endpoints = new API_ENDPOINTS();

      API.post(endpoints.RESETPASSWORD, {
        userId: "",
        verificationCode: token,
        newPassword: newPassword,
      }).then((response) => {
        if (response.data.isSuccess === true) {
          toast.success(
            "Password reset completed successfully. Please login again with new password."
          );

          setToken("");

          //Loading Indicator off
          hideLoader();
        } else {
          toast.error(response.data.displayMessage);

          //Loading Indicator off
          hideLoader();

          return;
        }
      });
    } catch (err) {
      toast.error(err);
      //Loading Indicator off
      hideLoader();
    }
  };

  return (
    <>
      <section class="layout">
        <SingleHeader />
        <div class="body">
          {/* <Email></Email> */}
          <div class="form_wrapper" style={{ paddingBottom: "3%" }}>
            <div class="login-logo">
              <img src={login} alt="" width={70} height={70} />
            </div>
            <div class="form_container">
              {/* <p className={msgCls}>{Msg}</p> */}
              <div class="title_container">
                <h2 class="t1">Change Password</h2>
                <h3 class="t1-sub">
                  create more secure password to protect yourself
                </h3>
              </div>
              <div class="row clearfix">
                <div class="">
                  <form onSubmit={handleSubmit}>
                    <div class="input_field">
                      <FromGroup label="New Password">
                        <PasswordField
                          type="password"
                          name="pwd"
                          required
                          onChange={(e) => setNewPassword(e.target.value)}
                          value={newPassword}
                        />
                      </FromGroup>

                      <FromGroup label="Confirm Password">
                        <PasswordField
                          type="password"
                          name="confirm_pwd"
                          placeholder=""
                          required
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </FromGroup>
                    </div>

                    <input
                      class="button"
                      type="submit"
                      value="Change Password"
                    />
                    {/* <MIButton type="btn-cm">
                  Send Reset Password Request
                </MIButton> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChangePassword;
