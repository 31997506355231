import React from "react";
import { setMonth, startOfMonth } from "date-fns";
import * as Styled from "../styled";
import { useDayPicker } from "react-day-picker";
import { useSwiper } from "swiper/react";

const Item = ({ onChangeHandler, month, className, label }) => {
  const swiper = useSwiper();

  const onClick = () => {
    onChangeHandler(month.getMonth());
    swiper.update();
  };

  return (
    <div className={className} role={"button"} onClick={onClick}>
      {label}
    </div>
  );
};

const Months = (props) => {
  const { displayMonth } = props;
  const { today } = useDayPicker();
  const [currentDate, setCurrentDate] = React.useState(displayMonth || today);

  const monthsList = React.useMemo(() => {
    let _monthsList = [];

    const date = startOfMonth(new Date());

    for (let month = 0; month <= 11; month++) {
      _monthsList.push(setMonth(date, month));
    }

    return _monthsList;
  }, []);

  const onChangeHandler = React.useCallback(
    (val) => {
      const selectedMonth = Number(val);
      const newMonth = setMonth(startOfMonth(displayMonth), selectedMonth);

      props.onChange(newMonth);
      setCurrentDate(newMonth);
    },
    [displayMonth, props]
  );

  return (
    <Styled.MonthSlider
      slideTo={currentDate?.getMonth() || 0}
      observer={"true"}
      slidesPerGroupAuto
      slidesPerView="auto"
    >
      {monthsList.map((m, i) => {
        // Set short month label
        let label = m.toLocaleString("en-US", { month: "short" });

        // Set full month label
        if (m.getMonth() === currentDate.getMonth()) {
          label = m.toLocaleString("en-US", { month: "long" });
        }

        let className =
          currentDate.getMonth() === m.getMonth()
            ? "month__item selected"
            : "month__item";

        return (
          <Styled.MonthSlider.Item
            key={m}
            className="month-slide-item"
            data-value={m.getMonth()}
            data-index={i}
          >
            <Item
              month={m}
              className={className}
              label={label}
              onChangeHandler={onChangeHandler}
            />
          </Styled.MonthSlider.Item>
        );
      })}
    </Styled.MonthSlider>
  );
};

export default Months;
