import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.02982 4C5.0282 4 2.21776 5.32708 0.0955931 7.7383C0.0382739 7.81308 0 7.90654 0 8C0 8.09346 0.0382117 8.18692 0.114699 8.2617C2.23687 10.6729 5.0473 12 8.0298 12C10.955 12 13.7463 10.6729 15.9067 8.243C16.0405 8.09345 16.0214 7.88785 15.9067 7.75704C13.7463 5.32714 10.9358 4.00005 8.0298 4.00005L8.02982 4ZM7.99161 11.1775C6.19446 11.1775 4.74144 9.757 4.74144 7.99998C4.74144 6.24297 6.19444 4.82243 7.99161 4.82243C9.78877 4.82243 11.2418 6.24297 11.2418 7.99998C11.2418 9.757 9.78877 11.1775 7.99161 11.1775ZM0.879482 7.99998C2.12219 6.6729 3.59432 5.71962 5.18117 5.19626C4.43553 5.90657 3.97667 6.89718 3.97667 7.99998C3.97667 9.10278 4.43553 10.0934 5.18117 10.8037C3.59429 10.2803 2.12219 9.32707 0.879482 7.99998ZM10.802 10.8037C11.5477 10.0934 12.0065 9.10278 12.0065 7.99998C12.0065 6.89718 11.5477 5.90653 10.802 5.19626C12.3697 5.71965 13.8419 6.6729 15.1037 7.99998C13.8419 9.34574 12.3698 10.299 10.802 10.8037Z"
      fill="currentColor"
    />
    <path
      d="M9.1387 7.25232C8.81371 7.25232 8.56514 7.0093 8.56514 6.69158C8.56514 6.44856 8.71811 6.24297 8.94751 6.16818C8.66073 6.01863 8.33575 5.94392 7.99158 5.94392C6.82536 5.94392 5.88853 6.85982 5.88853 7.99998C5.88853 9.14015 6.82536 10.0561 7.99158 10.0561C9.1578 10.0561 10.0946 9.14015 10.0946 7.99998C10.0946 7.55137 9.94165 7.14019 9.69315 6.80371C9.65488 7.06541 9.40637 7.25232 9.1387 7.25232Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
