import styled, { css } from "styled-components";
import { fontStyle } from "../../../../styled/utility";

const dragStartCss = css`
  border-color: var(--primary-p300);
`;

const dragOverCss = css`
  border-color: var(--primary-p100);
`;

export const UploaderOuter = styled.div`
  width: 100%;

  .preview__files {
    display: block;
  }
`;

export const Error = styled.div`
  margin-top: 4px;
  color: red;
  font-size: 10px;
`;

export const Uploader = styled.div`
  width: 100%;
  min-height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafdff;
  border-radius: var(--layout-border-radius-16);
  border: 1px dashed #bfc2c9;
  flex-direction: column;
  padding: var(--layout-spacing-16);
  position: relative;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  pointer-events: ${({ isUploading }) => (isUploading ? ` none` : "auto")};

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.91);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--layout-border-radius-16);
    overflow: hidden;

    .text {
      ${fontStyle("small-medium")};
      text-align: center;
    }

    .progress__bar {
      width: 100%;
      height: var(--layout-size-4);
      position: absolute;
      bottom: 0;

      .progress__value {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 70%;
        transition: width 2s ease-out;
        background-color: var(--info-i300);
      }
    }
  }

  ${({ isDisabled }) => {
    if (isDisabled) {
      return `
        cursor: auto;
        border-style: solid;
        border-color: var(--color-neutral-30);
        background-color: var(--color-neutral-30);
      `;
    }
  }};

  &:hover {
    ${({ isDisabled }) => {
      if (isDisabled) {
        return;
      }

      return dragOverCss;
    }};
  }

  ${({ isDragOver, isDragStart }) => {
    if (isDragStart) {
      return dragStartCss;
    }

    if (isDragOver) {
      return dragOverCss;
    }
  }};

  .icon__wrapper {
    background-color: #ecf6f7;
    border-radius: 999px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
  }

  .icon__block {
    ${({ isDisabled }) => {
      if (isDisabled) {
        return `color: var(--font-tint-04)`;
      }

      return `
        color: #7bc5ca;
      `;
    }};
  }

  .text {
    ${({ isDisabled }) => {
      if (isDisabled) {
        return `color: var(--font-tint-04)`;
      }

      return `
        color: #404a5f;
      `;
    }};
  }

  .text__black {
    ${({ isDisabled }) => {
      if (isDisabled) {
        return `color: var(--font-tint-04)`;
      }

      return;
    }};
  }

  .text__file__types {
    margin-top: var(--layout-spacing-8);
    margin-inline: var(--layout-spacing-8);
    text-align: center;
  }

  .file__input {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    z-index: -1;
    visibility: hidden;
  }
`;

export const PreviewWrapper = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  gap: var(--layout-spacing-8);
  margin-top: var(--layout-spacing-16);

  .heading {
    .title {
      color: var(--color-neutral-60);
      ${fontStyle("small-regular")};
    }
  }

  .content {
    display: grid;
    grid-template-rows: 0fr;
    gap: var(--layout-spacing-8);
  }
`;
export const LinkView = styled.div`
  padding: var(--layout-spacing-8) var(--layout-spacing-20);
  border-radius: var(--layout-border-radius-4);
  border: 1px solid var(--color-accent-75);
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-accent-50);

  .start,
  .end {
    display: flex;
    align-items: center;
  }

  .start {
    flex: 1;
    max-width: 100%;
    overflow: hidden;
    padding-right: var(--layout-spacing-16);

    a {
      display: block;
      max-width: 100%;
      ${fontStyle("small-regular")};

      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        overflow: hidden;
        max-width: 100%;
      }
    }
  }

  .icon__button__element {
    --icon-button-icon-color: var(--font-tint-04);
  }
`;
