import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.6413 0.916C11.6228 0.325 10.3933 0 9.17879 0C6.80779 0 4.77329 1.157 3.59979 3.1755C2.81479 4.5265 2.74829 5.263 2.69529 5.8545C2.64279 6.447 2.60729 6.8385 1.75779 7.756C1.13579 8.4275 0.912292 8.9195 1.03029 9.3505C1.16979 9.858 1.71079 10.017 2.14529 10.145C2.19779 10.1605 2.25079 10.176 2.30179 10.1915C2.36729 11.1665 2.63479 13.7955 3.64579 14.3425C3.86079 14.4585 4.10579 14.5175 4.37629 14.5175C4.80479 14.5175 5.23579 14.3685 5.58229 14.2485C5.75729 14.188 5.93779 14.1255 6.06279 14.1055C6.24129 14.9375 6.47579 15.696 6.48579 15.729L6.56979 16H13.6233L13.4308 15.481C13.4258 15.468 12.9563 14.1895 12.9563 13.2C12.9563 12.6755 13.3858 11.728 13.8008 10.811C14.1368 10.0675 14.4853 9.2985 14.6368 8.6795L14.7053 8.404C15.1853 6.4845 16.0778 2.9095 12.6413 0.916ZM13.9588 8.218L13.8893 8.497C13.7543 9.047 13.4203 9.7825 13.0983 10.494C12.6293 11.5305 12.1863 12.51 12.1863 13.2005C12.1863 13.911 12.3853 14.718 12.5388 15.23H7.14029C7.05329 14.9275 6.91279 14.4105 6.80029 13.8795C6.72779 13.5345 6.47529 13.3285 6.12279 13.3285C5.88429 13.3285 5.62779 13.4175 5.32979 13.5205C4.87679 13.6775 4.32879 13.837 4.01129 13.665C3.52029 13.399 3.13329 11.503 3.05629 9.8985L3.04479 9.662L2.82879 9.5655C2.68529 9.5015 2.52129 9.453 2.36279 9.4065C2.19379 9.3565 1.79829 9.24 1.77179 9.146C1.77179 9.144 1.72079 8.928 2.32179 8.2795C3.35029 7.1695 3.40179 6.592 3.46129 5.9235C3.51029 5.381 3.56479 4.766 4.26479 3.563C5.29679 1.7885 7.08779 0.7705 9.17779 0.7705C10.2588 0.7705 11.3518 1.059 12.2548 1.583C15.1903 3.285 14.4493 6.2535 13.9588 8.218Z"
      fill="currentColor"
    />
    <path
      d="M12.5283 4.578C12.5283 4.578 11.8703 4.082 11.1583 3.674C11.1458 3.7395 11.1328 3.806 11.1278 3.881C11.1278 3.9025 11.1338 3.9435 11.1383 3.9925C11.1638 4.2225 11.2028 4.571 11.0668 4.8625C10.8283 5.3745 10.2173 5.597 9.70529 5.358C9.19279 5.119 8.97079 4.5085 9.20929 3.9965C9.34479 3.7055 9.63629 3.5115 9.82929 3.3825C9.87029 3.3555 9.90429 3.3335 9.92579 3.315C9.97779 3.265 10.0198 3.214 10.0608 3.1625C9.29179 2.8795 8.48829 2.694 8.48829 2.694C8.30879 2.6525 8.09779 2.7555 8.01929 2.923L7.29879 4.4685C7.23129 4.4125 7.16779 4.354 7.11029 4.2945C7.00179 4.1835 6.77329 3.71 6.45029 3.559C6.06179 3.378 5.59979 3.546 5.41879 3.9345C5.23729 4.323 5.40529 4.7845 5.79379 4.966C6.11679 5.1165 6.62679 4.9875 6.78129 4.999C6.86429 5.005 6.95029 5.017 7.03629 5.032L6.32729 6.552C6.24929 6.719 6.30679 6.9475 6.45329 7.0585C6.45329 7.0585 7.26879 7.673 8.06579 8.096C8.02379 8.1425 7.98179 8.1865 7.93879 8.2285C7.82729 8.336 7.35479 8.565 7.20329 8.8885C7.02279 9.277 7.19079 9.7385 7.57829 9.92C7.96679 10.101 8.42879 9.933 8.61029 9.5445C8.76079 9.2215 8.63179 8.7115 8.64229 8.557C8.64729 8.497 8.65279 8.4365 8.66179 8.375C9.49879 8.7135 10.4928 8.943 10.4928 8.943C10.6723 8.9845 10.8833 8.881 10.9618 8.714L12.6538 5.085C12.7323 4.917 12.6758 4.689 12.5283 4.578Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
