import React from "react";
import { useRouteLoaderData } from "react-router-dom";
import { toast } from "react-toastify";
import { ROUTE_OPTIONS } from "../../../../../../../constant/route-options";
import { SETTING_PERMISSION_MODULES } from "../../../../../../../constant/setting_permission_module";
import { Permission } from "../../../../../../../helpers/permission";
import { useAppStore } from "../../../../../../../store/AppStore/AppStore";
import { useTemplateGeneralStore as useStore } from "../../../../../../../store/Setting/Templates/TemplateGeneralStore";
import Alert from "../../../../../../../utility/alert";
import ActionButton from "../../../../../Buttons/ActionButton";
import TableList from "../../../../../TableList";
import IconButton from "./../../../../../../../common/components/Buttons/IconButton";
import IconChecked from "./../../../../../../../common/components/icons/Fill/correct";

const columns = [
  {
    label: "Template Type",
    name: "type",
    sortable: true,
    width: "25%",
  },
  {
    label: "Job Category",
    name: "category",
    sortable: true,
    width: "25%",
  },
  {
    label: "Template",
    name: "template",
    sortable: true,
    width: "30%",
  },
  {
    label: "Is Default",
    name: "defaultTemplate",
    sortable: false,
    width: "100px",
  },
  {
    label: "Action",
    name: "action",
    sortable: false,
    width: "15%",
  },
];

const ExistingProjectTemplate = () => {
  const { permission } = useRouteLoaderData(
    ROUTE_OPTIONS.setting.children["template-general-settings"].id
  );

  const moduleType = SETTING_PERMISSION_MODULES.Templates;

  const permissions = new Permission(permission);

  const projectPermission = permissions.findByName(moduleType.project.key);

  const allTemplatesData = useStore((state) => state.allTemplatesData);
  console.log("allTemplatesData::: ", allTemplatesData);

  const onDelete = useStore((state) => state.onDelete);
  const onEdit = useStore((state) => state.onEdit);

  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);

  const onDeleteHandler = (id) => {
    Alert.confirm({
      icon: null,
      title: "Are you sure?",
      text: "You want to delete this",
      confirmButtonText: "Yes, Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        showLoader();
        // If yes, delete
        onDelete(id)
          .then((res) => {
            // If not successful show error message
            if (!res?.isSuccess) {
              toast.error(res?.displayMessage);
              hideLoader();
              return;
            }

            // toast.success(res?.displayMessage || "");
            hideLoader();
          })
          .catch((err) => {
            toast.error("Something went wrong!");
            hideLoader();
          });
      }
    });
  };

  const tableRenderRows = (
    { type, category, template, action, defaultTemplate, id },
    index
  ) => {
    console.log("isDefault", defaultTemplate);
    return (
      <TableList.Row key={index}>
        <TableList.Column width={type.style.width}>
          {type.value}
        </TableList.Column>

        <TableList.Column width={category.style.width}>
          {category.value}
        </TableList.Column>

        <TableList.Column width={template.style.width}>
          {template.value}
        </TableList.Column>

        <TableList.Column width="100px" className="center">
          <IconButton
            as="span"
            btnIconSize={12}
            btnSize={12}
            btnIconColor={
              defaultTemplate?.value
                ? "var(--font-color-shade-primary)"
                : "var(--font-tint-01)"
            }
            icon={<IconChecked />}
          />
        </TableList.Column>

        <TableList.Column width={action.style.width}>
          <ActionButton
            variant="EDIT"
            onClick={
              projectPermission.permission.canEdit
                ? () => onEdit(id.value)
                : null
            }
          />
          <ActionButton
            variant="DELETE"
            onClick={
              projectPermission.permission.canDelete
                ? () => onDeleteHandler(id.value)
                : null
            }
          />
        </TableList.Column>
      </TableList.Row>
    );
  };

  return (
    <>
      <TableList
        columns={columns}
        data={allTemplatesData}
        renderRows={tableRenderRows}
      />
    </>
  );
};

export default ExistingProjectTemplate;
