import React from "react";
import { Popover } from "react-bootstrap";
import { styleClass } from "../../../utility";
import "./styles.scss";

const UIPopover = (props, ref) => {
  const { children, id, className, ...otherProps } = props;

  return (
    <Popover
      {...otherProps}
      ref={ref}
      id={id}
      bsPrefix={styleClass("ui__popover", className)}
    >
      {children}
    </Popover>
  );
};

export default React.forwardRef(UIPopover);
