import React from "react";
import { motion } from "framer-motion";
import { StyledArrowBtn, StyledEpisode, StyledEpisodeButton } from "../styled";
import { IconArrowTrangle } from "../../../../../../common/components/icons";

const Episode = (props) => {
  const { onChange, numberOfEpisodes } = props;
  const containerRef = React.useRef(null);

  const [scrollableWidth, setScrollableWidth] = React.useState(0);
  const [moveX, setMoveX] = React.useState(0);
  const [currentSelectIndex, setCurrentSelectIndex] = React.useState(1);

  React.useEffect(() => {
    setScrollableWidth(
      containerRef?.current.scrollWidth - containerRef?.current.offsetWidth
    );
  }, []);

  const onSlide = (direction) => {
    const container = containerRef?.current;
    const perSlideGroupWidth = container.offsetWidth;

    // Set Scrollable Width
    setScrollableWidth(container.scrollWidth - container.offsetWidth);

    if (
      direction === 1 &&
      perSlideGroupWidth + moveX <= container.scrollWidth
    ) {
      setMoveX((prev) => (prev += container.offsetWidth));
      return;
    }

    if (
      direction === -1 &&
      perSlideGroupWidth - moveX <= container.scrollWidth
    ) {
      setMoveX((prev) => (prev -= container.offsetWidth));
      return;
    }
  };

  const onChangeHandler = React.useCallback(
    (index) => {
      onChange && onChange(index);
      setCurrentSelectIndex(index);
    },
    [onChange]
  );

  const renderItems = React.useMemo(() => {
    return Array(numberOfEpisodes)
      .fill("")
      .map((_, i) => {
        const val = i + 1;

        return (
          <div key={i} className="episode__item">
            <StyledEpisodeButton
              className={i + 1 === currentSelectIndex ? "is--active" : ""}
              onClick={() => onChangeHandler(val)}
            >
              {val < 10 ? `0${val}` : val}
            </StyledEpisodeButton>
          </div>
        );
      });
  }, [currentSelectIndex, numberOfEpisodes, onChangeHandler]);

  return (
    <StyledEpisode>
      <div className="episodes" ref={containerRef}>
        <motion.div
          className="episode__inner"
          initial={false}
          style={{ x: 0 }}
          animate={{ x: -moveX }}
          transition={{ easings: "easeIn", duration: 0.6 }}
        >
          {renderItems}
        </motion.div>
      </div>

      <div className="actions">
        <StyledArrowBtn
          className="btn-arrow next"
          onClick={() => onSlide(1)}
          disabled={moveX < scrollableWidth ? false : true}
        >
          <IconArrowTrangle />
        </StyledArrowBtn>

        <StyledArrowBtn
          className="btn-arrow prev"
          onClick={() => onSlide(-1)}
          disabled={0 < moveX ? false : true}
        >
          <IconArrowTrangle />
        </StyledArrowBtn>
      </div>
    </StyledEpisode>
  );
};

export default Episode;
