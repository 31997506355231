import React from "react";

import Button, { BUTTON_ICONS } from "../../../ui-components/Buttons/Button";
import SettingPanel from "../../../ui-components/pages-ui/Setting/SettingPanel";
import SettingAccordion from "../../../ui-components/pages-ui/Setting/SettingPanel/SettingAccordion";
import Role from "../../../ui-components/pages-ui/Setting/Permission/Role";
import UserRoles from "../../../ui-components/pages-ui/Setting/Permission/UserRoles";
import RoleRight from "../../../ui-components/pages-ui/Setting/Permission/RoleRight";
import UserRight from "../../../ui-components/pages-ui/Setting/Permission/UserRight";

import {
  useSettingRoleStore,
  useSettingUserRoleStore,
  useUserMenuStore,
  useRoleMenuStore,
} from "../../../../store/Setting/Permission";
import UserMenu from "../../../ui-components/pages-ui/Setting/Permission/UserMenu";
import RoleMenu from "../../../ui-components/pages-ui/Setting/Permission/RoleMenu";
import { useRouteLoaderData } from "react-router-dom";
import { ROUTE_OPTIONS } from "../../../../constant/route-options";
import { SETTING_PERMISSION_MODULES } from "../../../../constant/setting_permission_module";
import { Permission as PagePermission } from "../../../../helpers/permission";

const Permission = () => {
  const { permission } = useRouteLoaderData(
    ROUTE_OPTIONS.setting.children["tenant-permission"].id
  );

  const moduleType = SETTING_PERMISSION_MODULES.TenantPermission;

  const permissions = new PagePermission(permission);

  const rolePermission = permissions.findByName(moduleType.Role.key);
  const userRolePermission = permissions.findByName(moduleType.UserRole.key);
  const userMenuPermission = permissions.findByName(moduleType.UserMenu.key);
  const roleMenuPermission = permissions.findByName(moduleType.RoleMenu.key);
  const assignRoleRightsPermission = permissions.findByName(
    moduleType.AssignRoleRights.key
  );
  const assignUserRightsPermission = permissions.findByName(
    moduleType.AssignUserRights.key
  );

  // Breadcrumbs List
  const breadcrumbsList = [{ text: "Tenant" }, { text: "Permission" }];

  const showForm = useSettingRoleStore((state) => state.showForm);
  const onToggleForm = useSettingRoleStore((state) => state.onToggleForm);

  // User Roles status and functions
  const showUserRoleForm = useSettingUserRoleStore((state) => state.showForm);

  const onToggleUserRoleForm = useSettingUserRoleStore(
    (state) => state.onToggleForm
  );

  //Role Store
  const showRoleMenuForm = useRoleMenuStore((state) => state.showForm);
  const onToggleRoleMenuForm = useRoleMenuStore((state) => state.onToggleForm);

  //Menu Store
  const showMenuForm = useUserMenuStore((state) => state.showForm);
  const onToggleUserMenuForm = useUserMenuStore((state) => state.onToggleForm);

  return (
    <>
      <SettingPanel breadcrumbs={breadcrumbsList}>
        {/* 
        ------------------------
          Create Role 
        ------------------------
        */}
        {rolePermission.hasPermission ? (
          <SettingAccordion
            title="Role"
            actions={
              !showForm && rolePermission.permission.canAddNew ? (
                <>
                  <Button
                    size="sm"
                    variant="green-light"
                    leftIcon={BUTTON_ICONS.plus}
                    onClick={onToggleForm}
                  >
                    Add
                  </Button>
                </>
              ) : null
            }
          >
            <SettingAccordion.Card>
              <Role variant="TENANT_WISE" />
            </SettingAccordion.Card>
          </SettingAccordion>
        ) : null}

        {/* 
        ------------------------
          Create User Role 
        ------------------------
        */}
        {userRolePermission.hasPermission ? (
          <SettingAccordion
            title="User Role"
            actions={
              !showUserRoleForm && userRolePermission.permission.canAddNew ? (
                <>
                  <Button
                    size="sm"
                    variant="green-light"
                    leftIcon={BUTTON_ICONS.plus}
                    onClick={onToggleUserRoleForm}
                  >
                    Add
                  </Button>
                </>
              ) : null
            }
          >
            <SettingAccordion.Card>
              <UserRoles variant="TENANT_WISE" />
            </SettingAccordion.Card>
          </SettingAccordion>
        ) : null}

        {/* 
        ------------------------
          Create User Role  Rights
        ------------------------
        */}
        {assignRoleRightsPermission.hasPermission ? (
          <SettingAccordion title="Assign Role Rights">
            <SettingAccordion.Card>
              <RoleRight variant="TENANT_WISE" />
            </SettingAccordion.Card>
          </SettingAccordion>
        ) : null}

        {/* 
        ------------------------
          Create User USer Rights
        ------------------------
        */}
        {assignUserRightsPermission.hasPermission ? (
          <SettingAccordion title="Assign User Rights">
            <SettingAccordion.Card>
              <UserRight variant="TENANT_WISE" />
            </SettingAccordion.Card>
          </SettingAccordion>
        ) : null}

        {/* 
        ------------------------
          Create Role Menu
        ------------------------
        */}
        {roleMenuPermission.hasPermission ? (
          <SettingAccordion
            title="Role Menu"
            actions={
              !showRoleMenuForm && roleMenuPermission.permission.canAddNew ? (
                <>
                  <Button
                    size="sm"
                    variant="green-light"
                    leftIcon={BUTTON_ICONS.plus}
                    onClick={onToggleRoleMenuForm}
                  >
                    Add
                  </Button>
                </>
              ) : null
            }
          >
            <SettingAccordion.Card>
              <RoleMenu variant="TENANT_WISE" />
            </SettingAccordion.Card>
          </SettingAccordion>
        ) : null}

        {/* 
        ------------------------
          Create User Menu
        ------------------------
        */}
        {userMenuPermission.hasPermission ? (
          <SettingAccordion
            title="User Menu"
            actions={
              !showMenuForm && userMenuPermission.permission.canAddNew ? (
                <>
                  <Button
                    size="sm"
                    variant="green-light"
                    leftIcon={BUTTON_ICONS.plus}
                    onClick={onToggleUserMenuForm}
                  >
                    Add
                  </Button>
                </>
              ) : null
            }
          >
            <SettingAccordion.Card>
              <UserMenu variant="TENANT_WISE" />
            </SettingAccordion.Card>
          </SettingAccordion>
        ) : null}
      </SettingPanel>
    </>
  );
};

export default Permission;
