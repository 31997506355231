import * as React from "react";
import { memo } from "react";
const SvgEvening = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 3.53009C9.57694 3.53009 10.8952 4.63816 11.2184 6.11825H4.78172C5.1049 4.63817 6.42306 3.53009 8.00013 3.53009H8ZM0.235307 7.76511C0.105359 7.76511 0 7.65981 0 7.52986V7.05925C0 6.92931 0.105364 6.82399 0.235307 6.82399H15.7647C15.8946 6.82399 16 6.9293 16 7.05925V7.52986C16 7.6598 15.8946 7.76511 15.7647 7.76511H0.235307ZM7.52946 14.3935V9.64744C7.52946 9.51749 7.63478 9.41214 7.76473 9.41214H8.23536C8.36531 9.41214 8.47063 9.5175 8.47063 9.64744V14.3935L9.38328 13.4809C9.47516 13.389 9.62411 13.389 9.71604 13.4809L10.0488 13.8136C10.1407 13.9055 10.1407 14.0545 10.0488 14.1464L8.3328 15.8623C8.149 16.0461 7.85108 16.0461 7.66728 15.8623L5.95127 14.1464C5.8594 14.0545 5.8594 13.9055 5.95127 13.8136L6.28403 13.4809C6.37596 13.389 6.52491 13.389 6.6168 13.4809L7.52946 14.3935ZM7.52946 0.235419C7.52946 0.105476 7.63478 0.00012207 7.76473 0.00012207H8.23536C8.36531 0.00012207 8.47063 0.105482 8.47063 0.235419V1.88242C8.47063 2.01236 8.36532 2.11772 8.23536 2.11772H7.76473C7.63478 2.11772 7.52946 2.01236 7.52946 1.88242V0.235419ZM13.0603 2.4288L11.8267 3.66229C11.7348 3.75416 11.5859 3.75416 11.494 3.66229L11.1612 3.32955C11.0693 3.23768 11.0693 3.08868 11.1612 2.9968L12.3948 1.7633C12.4866 1.67143 12.6356 1.67143 12.7275 1.7633L13.0603 2.09605C13.1522 2.18792 13.1522 2.33687 13.0603 2.4288ZM3.60532 1.7633L4.83887 2.9968C4.9308 3.08867 4.9308 3.23767 4.83887 3.32955L4.50611 3.66229C4.41423 3.75416 4.26528 3.75416 4.17335 3.66229L2.9398 2.4288C2.84792 2.33687 2.84792 2.18793 2.9398 2.09605L3.27256 1.7633C3.36443 1.67143 3.51344 1.67143 3.60532 1.7633Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgEvening);
export default Memo;
