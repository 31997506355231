// Find permission object by name

function PermissionData(obj) {
  this.hasPermission = obj?.hasPermission || false;

  this.name = obj?.name || null;

  this.permission = {
    canView: obj?.permission?.canView || false,
    canAddNew: obj?.permission?.canAddNew || false,
    canEdit: obj?.permission?.canEdit || false,
    canDelete: obj?.permission?.canDelete || false,
    canExecute: obj?.permission?.canExecute || false,
  };

  return this;
}

export function Permission(array = []) {
  const self = this;
  self.data = array || [];

  self.findByName = function (key) {
    const obj = self.data.find((d) => d.name === key) || null;

    return new PermissionData(obj);
  };

  return self;
}
