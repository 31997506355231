import React from "react";
import {
  format,
  getMonth,
  isSameYear,
  isValid,
  isWithinInterval,
  setMonth,
  setYear,
} from "date-fns";

import {
  getAvailableMonths,
  getMonthAndYearOnly,
  getMonthList,
  getYears,
  setCurrentMonth,
} from "./utility";

import Popover from "../../../../../../../../common/components/Popover";
import { PopoverContainer } from "./styled";
import { useScheduleContext } from "../../context/ScheduleContext";
import UIButton from "../../../../../../../../common/components/Buttons/UIButton";
import IconButton from "../../../../../../../../common/components/Buttons/IconButton";
import IconArrowLeft from "../../../../../../../../common/components/icons/Line/Arrow/left";
import IconArrowRight from "../../../../../../../../common/components/icons/Line/Arrow/right";

function MonthAndYearPopover({ anchorEl, onOutsideClick, onChange }) {
  const { dataFetchDate, startDate, endDate } = useScheduleContext();

  const [months, setMonths] = React.useState(getMonthList());
  const [years, setYears] = React.useState([]);
  const [current, setCurrent] = React.useState(new Date());

  React.useEffect(() => {
    if (!anchorEl) return;

    if (!isValid(dataFetchDate)) return;

    setCurrent(new Date(format(dataFetchDate, "yyyy MM")));
  }, [dataFetchDate, anchorEl]);

  React.useEffect(() => {
    if (!isValid(startDate) || !isValid(endDate)) {
      setYears([]);
      return;
    }

    const currentY = format(current, "yyyy");

    const availableYrs = getYears(startDate, endDate);
    let yrs = availableYrs.map((d, i) => {
      return {
        ...d,
        isActive: currentY && d?.value === currentY,
        next: !!availableYrs[i + 1] ? availableYrs[i + 1]?.value : null,
        prev: !!availableYrs[i - 1] ? availableYrs[i - 1]?.value : null,
      };
    });
    setYears(yrs);
  }, [current, endDate, startDate]);

  // Month
  React.useEffect(() => {
    const currentMonth = isValid(current) ? getMonth(current) : 0;

    if (isSameYear(startDate, endDate)) {
      let months = getAvailableMonths(startDate, endDate);
      months = setCurrentMonth(months, currentMonth);
      setMonths(months);
      return;
    }

    if (isSameYear(current, getMonthAndYearOnly(startDate))) {
      const end = setMonth(getMonthAndYearOnly(startDate), 11);
      let months = getAvailableMonths(startDate, end);
      months = setCurrentMonth(months, currentMonth);
      setMonths(months);
      return;
    }

    if (isSameYear(current, getMonthAndYearOnly(endDate))) {
      const start = setMonth(getMonthAndYearOnly(endDate), 0);
      let months = getAvailableMonths(start, endDate);
      months = setCurrentMonth(months, currentMonth);
      setMonths(months);
      return;
    }

    let months = getMonthList();
    months = setCurrentMonth(months, currentMonth);
    setMonths(months);
  }, [current, endDate, startDate]);

  const selectedYear = years.find((d) => d?.isActive);

  const monthUpdateHandler = React.useCallback((month) => {
    setCurrent((prev) => {
      return new Date(format(setMonth(prev, month), "yyyy MM"));
    });
  }, []);

  const isCurrentDateValid = React.useMemo(() => {
    return isWithinInterval(current, {
      start: new Date(format(startDate, "yyyy MM")),
      end: new Date(format(endDate, "yyyy MM")),
    });
  }, [current, endDate, startDate]);

  const handleYearSelection = React.useCallback(
    (val) => {
      const update = (y) => {
        setCurrent((prev) => {
          let updateDate = new Date(format(setYear(prev, y), "yyyy MM"));
          return updateDate;
        });
      };

      if (val === 1 && selectedYear?.next) {
        update(selectedYear?.next);
        return;
      }

      if (val === -1 && selectedYear?.prev) {
        update(selectedYear?.prev);
        return;
      }
    },
    [selectedYear?.next, selectedYear?.prev]
  );

  if (!anchorEl) return null;

  return (
    <Popover
      appendTo={document.body}
      onOutsideClick={onOutsideClick}
      anchorEl={anchorEl}
      placement="right-end"
    >
      <PopoverContainer>
        <div className="month-year-list">
          <div className="years">
            <IconButton
              icon={<IconArrowLeft />}
              onClick={() => handleYearSelection(-1)}
              disabled={!selectedYear?.prev}
              btnIconSize={16}
            />

            <div className="selected-year">{selectedYear?.label}</div>

            <IconButton
              icon={<IconArrowRight />}
              onClick={() => handleYearSelection(1)}
              disabled={!selectedYear?.next}
              btnIconSize={16}
            />

            {/* <ul>
              {years.map((d, i) => {
                return (
                  <li key={i}>
                    <button
                      className={d?.isActive ? "active" : ""}
                      onClick={() => {
                        if (d?.isDisabled) return;
                        yearUpdateHandler(d?.value);
                      }}
                    >
                      {d?.label}
                    </button>
                  </li>
                );
              })}
            </ul> */}
          </div>

          <div className="divider"></div>

          <div className="months">
            <ul>
              {months.map((d, i) => {
                return (
                  <li key={i}>
                    <button
                      className={!d?.isDisabled && d?.isActive ? "active" : ""}
                      disabled={d?.isDisabled}
                      onClick={() => {
                        if (d?.isDisabled) return;

                        monthUpdateHandler(d?.value);
                      }}
                    >
                      {d.label}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        <div className="actions">
          <UIButton
            disabled={!isCurrentDateValid}
            variant={UIButton.Variant.Primary}
            size={UIButton.Sizes.Small}
            onClick={() => {
              if (!isCurrentDateValid) return;
              onChange && onChange(current);
              onOutsideClick();
            }}
          >
            Apply
          </UIButton>
        </div>
      </PopoverContainer>
    </Popover>
  );
}

export default MonthAndYearPopover;
