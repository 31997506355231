import React from "react";
import { toast } from "react-toastify";
import {
  projectOnboardingCompleteInfo,
  projectOnboardingCompletion,
} from "../../../../Actions/Project";
import { ONBOARDING_STATUS } from "../../../../constant/Status";
import { useAppStore } from "../../../../store/AppStore/AppStore";
import { useProjectStore } from "../../../../store/ProjectStore";
import Alert from "../../../../utility/alert";
import Button, { BUTTON_ICONS } from "../../Buttons/Button";

import ModalBase from "../../ModalBase";
import Form from "./Form";
import "./styles.scss";

const OnboardCompletionModal = (props) => {
  const { onClose, member = null, ...otherProps } = props;
  const formRef = React.useRef();

  const [isComplete, setIsComplete] = React.useState(false);

  const [formDefaultValues, setFormDefaultValues] = React.useState({
    companyEmail: "",
    password: "",
    healthDetails: "",
    bankDetails: "",
  });

  // App Store
  const jobTitles = useAppStore((state) => state.jobTitles);
  const hideLoader = useAppStore((state) => state.hideLoader);
  const showLoader = useAppStore((state) => state.showLoader);

  // Project Store
  const modalClose = useProjectStore((state) => state.closeOnboardingModal);

  const selectedMember = useProjectStore(
    (state) => state.projectSelectedMember
  );

  const onReloadList = useProjectStore((state) => state.onReloadList);

  const setSelectedMember = useProjectStore(
    (state) => state.setProjectSelectedMember
  );

  const modalVisibility = useProjectStore(
    (state) => state.isShowOnboardingModal
  );

  const projectMemberId = selectedMember?.projectMemberId || 0;
  const projectRoleId = selectedMember?.projectRoleId || 0;
  const jobTitleId = selectedMember?.jobTitleId || 0;

  const onFormSubmitted = async (values, callback) => {
    try {
      const payload = {
        bankDetails: values.bankDetails,
        companyEmail: values.companyEmail,
        healthDetails: values.healthDetails,
        password: values.password,
        projectMemberId,
        isComplete: false,
      };

      // * Complete functionality
      if (isComplete) {
        Alert.confirm({
          title: "Are you sure?",
          text: "You want to complete this",
          confirmButtonText: "Yes, Complete",
        }).then(async (result) => {
          if (result.isConfirmed) {
            showLoader();

            const _payload = {
              ...payload,
              isComplete: true,
            };

            const res = await projectOnboardingCompletion(_payload);

            if (!res.isSuccess) {
              toast.error(res?.displayMessage);
              hideLoader();
              return;
            }

            // toast.success(res?.displayMessage);

            //
            hideLoader();
            setSelectedMember(null);
            modalClose();
            callback && callback();
            onReloadList(true);
          }
          setIsComplete(false);
        });

        return;
      }

      // * Save functionality.
      showLoader();

      const res = await projectOnboardingCompletion(payload);

      if (!res.isSuccess) {
        toast.error(res?.displayMessage);
        hideLoader();
        return;
      }

      // toast.success(res?.displayMessage);
      callback && callback();
      hideLoader();
      setSelectedMember(null);
      modalClose();
      // Reload list
      onReloadList(true);

      //
    } catch (error) {
      hideLoader();
    }
  };

  const onCompleteHandler = () => {
    setIsComplete(true);
    formRef.current.onFormSubmit && formRef.current.onFormSubmit();
  };

  const onSaveHandler = () => {
    formRef.current.onFormSubmit && formRef.current.onFormSubmit();
  };

  // Project base job title
  const joinAsJobTitle = React.useMemo(() => {
    const obj = jobTitles.find(
      (t) => projectRoleId.toString() === t?.jobTitleId?.toString()
    );

    return obj?.jobTitleName || "";
  }, [jobTitles, projectRoleId]);

  // Profile job title
  const profileJobTitle = React.useMemo(() => {
    const obj = jobTitles.find(
      (t) => jobTitleId.toString() === t?.jobTitleId?.toString()
    );
    return obj?.jobTitleName || "";
  }, [jobTitles, jobTitleId]);

  const loadExistingFormData = React.useCallback(async () => {
    try {
      showLoader();
      // api call here.
      const res = await projectOnboardingCompleteInfo(projectMemberId);

      if (!res.isSuccess) {
        hideLoader();
        return;
      }

      const data = res?.result;

      // Set form default values
      setFormDefaultValues({
        bankDetails: data?.bankDetails || "",
        companyEmail: data?.companyEmail || "",
        healthDetails: data?.healthDetails || "",
        password: data?.password || "",
      });

      // Hide Loader
      hideLoader();
    } catch (error) {}
  }, [projectMemberId, showLoader, hideLoader]);

  React.useEffect(() => {
    if (modalVisibility) {
      loadExistingFormData();
    }
  }, [loadExistingFormData, modalVisibility]);

  //
  // if (!projectMemberId) {
  //   modalClose();
  //   setSelectedMember(null);
  //   return null;
  // }

  return (
    <ModalBase
      {...otherProps}
      title="Onboarding Completion"
      // subtitle="Lorem ipsum dolor, sit amet consectetur adipisicing eli."
      size="md"
      className="onboard__completion__modal"
      onClose={modalClose}
      visibility={modalVisibility}
    >
      <ModalBase.Contents className="contents">
        <div className="profile__content">
          <div className="pop__onb__hr__container">
            <div className="pop__top">
              <div className="pop__top__content">
                <div className="pop__top__member__wr">
                  <div className="pop__top__member__pic">
                    <div className="pop__top__member__pic__iw">
                      <img
                        alt="Profile"
                        src={selectedMember?.profileImage || null}
                      />
                    </div>
                    <div className="pop__top__member__pic__ic">
                      <div className="icon icon-lp-profile profile" />
                    </div>
                  </div>
                  <div className="pop__top__member_info">
                    <div className="member__name">
                      {selectedMember?.givenName}
                    </div>
                    {/* <div className="member__position">{profileJobTitle}</div> */}
                  </div>
                </div>

                <div className="pop__top__info__wr">
                  <div className="info__ic">
                    <div className="icon icon-lp-masks icon-20 text-orange" />
                  </div>
                  <div className="info__content">
                    <div className="info__content__subtitle">Join as the</div>
                    <div className="info__content__title">{joinAsJobTitle}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form__content">
          <Form
            onAfterSubmit={onFormSubmitted}
            ref={formRef}
            formDefaultValues={formDefaultValues}
          />
        </div>
      </ModalBase.Contents>

      <ModalBase.Footer>
        <div className="actions__wr">
          <Button
            leftIcon={BUTTON_ICONS.cancel}
            variant="gray-400"
            size="lg"
            onClick={modalClose}
          >
            Cancel
          </Button>

          <Button
            variant="green-light"
            leftIcon={BUTTON_ICONS.done}
            onClick={onSaveHandler}
          >
            Save
          </Button>

          {selectedMember?.statusId === ONBOARDING_STATUS.ContractExecuted ? (
            <Button
              variant="green-dark"
              leftIcon={BUTTON_ICONS.done}
              onClick={onCompleteHandler}
            >
              Complete
            </Button>
          ) : null}
        </div>
      </ModalBase.Footer>
    </ModalBase>
  );
};

export default OnboardCompletionModal;
