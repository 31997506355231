import styled from "styled-components";
import { fontStyle } from "../../styled/utility";
import { Item } from "./ContextMenuUi";

export const MenuContainer = styled.div`
  border-radius: var(--layout-border-radius-4, 4px);
  background: var(--color-white, #fff);
  /* layout/boxShadow/primary/20 */
  box-shadow: rgba(108, 139, 148, 0.1) 0px 8px 32px 3px;
  min-width: 160px;
  border: 1px solid var(--layout-background-n20);
  padding: var(--layout-spacing-16) var(--layout-spacing-8);
  border-radius: var(--layout-border-radius-4);
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: var(--layout-spacing-8);
`;

export const SubList = styled(List)`
  gap: var(--layout-spacing-2);
  box-shadow: rgba(108, 139, 148, 0.1) 0px 8px 32px 3px;
  position: absolute;
  left: calc(100% - 4px);
  top: 0;
  border-radius: var(--layout-border-radius-4, 4px);
  background: var(--color-white, #fff);
  padding: var(--layout-spacing-12) var(--layout-spacing-8);
  min-width: 80px;
  display: none;
`;

export const ListItem = styled.li`
  --bg-color: ${({ $isSelected }) =>
    $isSelected ? `var(--color-accent-40, #eef8f8)` : `transparent`};
  --fw: ${({ $isSelected }) => ($isSelected ? `500` : `400`)};

  list-style: none;
  cursor: ${({ $isDisabled }) => ($isDisabled ? "auto" : "pointer")};
  ${fontStyle("label-regular")};
  color: ${({ $isDisabled }) =>
    $isDisabled ? "rgba(0,0,0,0.4)" : "var(--font-default-color, #061216)"};
  padding: var(--layout-spacing-4) var(--layout-spacing-12);
  position: relative;
  white-space: nowrap;
  border-radius: var(--layout-border-radius-4);
  background-color: var(--bg-color);
  font-weight: var(--fw);

  &:hover {
    --bg-color: ${({ $isDisabled }) =>
      $isDisabled ? "transparent" : " var(--color-accent-50, #eef8f8)"};
      
    & > ul {
      display: flex;
    }
  }

  .item__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .start,
    .center,
    .end {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .center {
      label {
        .element__text {
          ${fontStyle("label-regular")};
        }
      }
    }

    .end {
      .arrow__right {
        --icon-button-icon-color: var(--font-tint-04);
        margin-right: -4px;
      }
    }
  }
`;

export const SubListItem = styled(ListItem)`
  padding: var(--layout-spacing-4) var(--layout-spacing-8);
  position: relative;
  min-width: 100px;
`;

export const ItemDivide = styled.li`
  height: 1px;
  background-color: var(--font-tint-01);
  width: 100%;
`;
