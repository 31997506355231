import styled from "styled-components";
import Column from "../BaseColumn";
import { weekendCss } from "../styled";
import BaseControlItem from "../BaseControlItem";

export const DayColumn = styled(Column)`
  .column__body {
    background: var(--color-neutral-20, #f5f5f7);
  }
`;

export const DayItems = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`;

export const DayItem = styled(BaseControlItem)`
  &:nth-child(7n) {
    border-bottom-color: var(--column-border-color);
  }

  ${weekendCss};
`;
