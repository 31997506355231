import React from "react";
import * as Styled from "./styled";
import IconButton from "../../../../../../../../../common/components/Buttons/IconButton";

function SectionItem(props) {
  const { icon, text, color } = props;

  return (
    <Styled.ItemContainer $color={color}>
      <div className="text">{text}</div>
      {icon && <IconButton icon={icon} as="div" className="icon" btnSize={16} btnIconSize={16} />}
    </Styled.ItemContainer>
  );
}

export default SectionItem;
