import React from "react";
import ReactDOM from "react-dom";
import * as Styled from "./styled";
import { useChatBaseStore } from "../../Store/ChatBaseStore";
import { AnimatePresence } from "framer-motion";

const BaseDialog = ({
  title,
  children,
  visibility,
  onRootClick,
  appendToSamePlace = false,
}) => {
  const isLayoutLoaded = useChatBaseStore((s) => s.isLayoutLoaded);
  const appendTo = React.useRef();

  React.useEffect(() => {
    if (isLayoutLoaded) {
      // setTimeout(() => {
      appendTo.current = document?.getElementById("chat_dialog_container");
      // }, 500);
    }
  }, [isLayoutLoaded]);

  const layout = React.useMemo(() => {
    return (
      <AnimatePresence exitBeforeEnter>
        {visibility ? (
          <Styled.DialogWindow
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Styled.DialogBackdrop
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={onRootClick}
            />

            <Styled.DialogContainer>
              <Styled.DialogContent
                initial={{ y: "100%", opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: "100%", opacity: 0 }}
                transition={{ type: "spring", duration: 0.8 }}
              >
                <div className="header">
                  <div className="title">{title}</div>
                </div>
                <div className="content">{children}</div>
              </Styled.DialogContent>
            </Styled.DialogContainer>
          </Styled.DialogWindow>
        ) : null}
      </AnimatePresence>
    );
  }, [children, onRootClick, title, visibility]);

  const renderPortal = React.useMemo(() => {
    if (!isLayoutLoaded || !appendTo?.current) {
      return null;
    }

    return ReactDOM.createPortal(layout, appendTo?.current);
  }, [layout, isLayoutLoaded]);

  if (appendToSamePlace) {
    return layout;
  }

  return renderPortal;
};

export default BaseDialog;
