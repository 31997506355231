import styled from "styled-components";
import { layoutBoxShadow } from "../../../../../styled/utility";

export const NavContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--layout-spacing-32);
  width: 100%;
  background-color: var(--layout-background-n10);
  box-shadow: ${layoutBoxShadow("primary-5")};
  height: var(--layout-size-60);
  position: fixed;
  top: 0;
  left: 0;

  .nav__btns {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    gap: var(--layout-spacing-8);
  }
`;

export const LogoWrapper = styled.div`
  height: 19px;

  img {
    max-height: 100%;
    height: 100%;
  }
`;
