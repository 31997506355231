import React from "react";

const useUserData = () => {
  const data = JSON.parse(localStorage.getItem("user")) || undefined;
  const userRole = JSON.parse(localStorage.getItem("user-roles")) || undefined;

  return React.useMemo(() => {
    return {
      user: data,
      userId: data?.result?.userId || null,
      userTenantId: data?.result?.userTenantId || null,
      tenantId: data?.result?.tenantId || null,
      userRoles: userRole || null,
      userInfo: data?.result,
    };
  }, [data, userRole]);
};

export default useUserData;
