import styled from "styled-components";

export const FormContainer = styled.div`
  .form__content {
    /* margin-top: 40px; */
    background-color: var(--layout-background-n20);
    margin-bottom: var(--layout-spacing-28);
  }
`;

export const FieldGroupLabel = styled.label`
  color: var(--font-default-color);
`;

export const LogoContainer = styled.div`
  height: var(--layout-size-60);
  margin-top: var(--layout-spacing-8);
  padding: var(--layout-spacing-4);
  border-radius: var(--layout-border-radius-4);
  background-color: var(--layout-background-n40);
  display: inline-flex;
  border: 1px solid var(--layout-background-n50);

  img {
    height: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;
