import * as React from "react";
import { memo } from "react";
const Svg02 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.2808 16H3.96552C3.4335 16 3 15.5665 3 15.0345V2.77833C3 2.24631 3.4335 1.81281 3.96552 1.81281H6.92118V3.19212C6.92118 3.42857 7.11823 3.60591 7.33497 3.60591H8.91133C9.14778 3.60591 9.32512 3.40887 9.32512 3.19212V1.81281H12.2808C12.8128 1.81281 13.2463 2.24631 13.2463 2.77833V15.0345C13.2463 15.5665 12.8128 16 12.2808 16ZM3.96552 2.2069C3.65025 2.2069 3.39409 2.46306 3.39409 2.77833V15.0345C3.39409 15.3498 3.65025 15.6059 3.96552 15.6059H12.2808C12.5961 15.6059 12.8522 15.3498 12.8522 15.0345V2.77833C12.8522 2.46306 12.5961 2.2069 12.2808 2.2069H9.71921V3.19212C9.71921 3.64532 9.36453 4 8.91133 4H7.33497C6.88177 4 6.52709 3.62562 6.52709 3.19212V2.2069H3.96552Z"
      fill="currentColor"
    />
    <path
      d="M8.12315 9.24138C7.86699 9.24138 7.63054 9.18226 7.41379 9.08374C6.82266 8.80788 6.44828 8.21675 6.44828 7.5665C6.44828 6.64039 7.19704 5.89162 8.12315 5.89162C9.04926 5.89162 9.79803 6.64039 9.79803 7.5665C9.79803 8.21675 9.42364 8.80788 8.83251 9.08374C8.61576 9.18226 8.37931 9.24138 8.12315 9.24138ZM8.12315 6.28571C7.41379 6.28571 6.84236 6.85714 6.84236 7.5665C6.84236 8.05911 7.13793 8.51231 7.57143 8.72906C7.9064 8.8867 8.3202 8.8867 8.65517 8.72906C9.10837 8.51231 9.38424 8.05911 9.38424 7.5665C9.40394 6.85714 8.83251 6.28571 8.12315 6.28571Z"
      fill="currentColor"
    />
    <path
      d="M8.26109 12.2956C8.24139 12.2956 8.22168 12.2956 8.20198 12.2956H8.06405C7.4138 12.2956 6.84237 12.2956 6.40888 12.1379C5.87686 11.9409 5.6207 11.5665 5.6207 10.936C5.6207 9.89162 6.36947 8.98522 7.45321 8.68965L7.53203 8.66995L7.59114 8.70936C7.92612 8.86699 8.33991 8.86699 8.67489 8.70936L8.734 8.66995L8.81282 8.68965C9.89656 8.96552 10.6453 9.89162 10.6453 10.936C10.6453 11.5468 10.3892 11.9409 9.85715 12.1379C9.42365 12.2956 8.89163 12.2956 8.26109 12.2956ZM8.18227 11.9015C8.77341 11.9015 9.32513 11.9015 9.69952 11.7635C10.0739 11.6256 10.2315 11.3892 10.2315 10.936C10.2315 10.0887 9.6404 9.3399 8.77341 9.10345C8.35961 9.28079 7.88671 9.28079 7.49262 9.10345C6.62562 9.3596 6.03449 10.1084 6.03449 10.936C6.03449 11.3892 6.19213 11.6256 6.56651 11.7635C6.9409 11.9015 7.49262 11.9015 8.08375 11.9015H8.18227Z"
      fill="currentColor"
    />
    <path
      d="M10.9409 14.4236H5.32513C5.20691 14.4236 5.12809 14.3448 5.12809 14.2266C5.12809 14.1084 5.20691 14.0296 5.32513 14.0296H10.9409C11.0591 14.0296 11.1379 14.1084 11.1379 14.2266C11.1379 14.3448 11.0394 14.4236 10.9409 14.4236Z"
      fill="currentColor"
    />
    <path
      d="M10.3892 13.4384H5.85714C5.73892 13.4384 5.6601 13.3596 5.6601 13.2414C5.6601 13.1232 5.73892 13.0443 5.85714 13.0443H10.3892C10.5074 13.0443 10.5862 13.1232 10.5862 13.2414C10.5862 13.3596 10.5074 13.4384 10.3892 13.4384Z"
      fill="currentColor"
    />
    <path
      d="M8.91131 4.0197H7.33496C6.88175 4.0197 6.52707 3.64532 6.52707 3.21182V0.807882C6.52707 0.35468 6.88175 0 7.33496 0H8.91131C9.36451 0 9.71919 0.374384 9.71919 0.807882L9.71921 3.19212C9.71921 3.64532 9.34481 4.0197 8.91131 4.0197ZM7.33496 0.394089C7.0985 0.394089 6.92116 0.591133 6.92116 0.807882L6.92118 3.19212C6.92118 3.42857 7.11823 3.60591 7.33497 3.60591H8.91133C9.14778 3.60591 9.32512 3.40887 9.32512 3.19212L9.3251 0.807882C9.3251 0.571429 9.12806 0.394089 8.91131 0.394089H7.33496Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg02);
export default Memo;
