import * as React from "react";
import { memo } from "react";
const SvgCalender = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.9377 2.46968V1.77253C13.9377 1.33912 13.6176 1 13.2088 1C12.7999 1 12.4799 1.3392 12.4799 1.77253V2.46968H10.471V1.77253C10.471 1.33912 10.151 1 9.74215 1C9.33323 1 9.01327 1.3392 9.01327 1.77253V2.46968H6.98661V1.77253C6.98661 1.33912 6.66658 1 6.25773 1C5.84881 1 5.52886 1.3392 5.52886 1.77253V2.46968H3.50219V1.77253C3.50219 1.33912 3.18216 1 2.77332 1C2.3644 1 2.04444 1.3392 2.04444 1.77253V2.46968H0V15H16V2.46968H13.9377ZM1.45767 13.4738V4.01477H2.06213V4.35397C2.06213 4.78738 2.38216 5.1265 2.79101 5.1265C3.19993 5.1265 3.51988 4.7873 3.51988 4.35397V4.01477H5.54655V4.35397C5.54655 4.78738 5.86658 5.1265 6.27542 5.1265C6.68434 5.1265 7.0043 4.7873 7.0043 4.35397V4.01477H9.03096V4.35397C9.03096 4.78738 9.35099 5.1265 9.75984 5.1265C10.1688 5.1265 10.4887 4.7873 10.4887 4.35397V4.01477H12.5154V4.35397C12.5154 4.78738 12.8354 5.1265 13.2443 5.1265C13.6532 5.1265 13.9731 4.7873 13.9731 4.35397V4.01477H14.5776L14.5776 13.4738H1.45767Z"
      fill="currentColor"
    />
    <path
      d="M5.5821 6.44546H7.53766V8.51815H5.5821V6.44546Z"
      fill="currentColor"
    />
    <path
      d="M8.4621 6.44546H10.4177V8.51815H8.4621V6.44546Z"
      fill="currentColor"
    />
    <path
      d="M11.3599 6.44546H13.3154V8.51815H11.3599V6.44546Z"
      fill="currentColor"
    />
    <path
      d="M2.68433 9.70523H4.63988V11.7779H2.68433V9.70523Z"
      fill="currentColor"
    />
    <path
      d="M5.5821 9.70523H7.53766V11.7779H5.5821V9.70523Z"
      fill="currentColor"
    />
    <path
      d="M8.4621 9.70523H10.4177V11.7779H8.4621V9.70523Z"
      fill="currentColor"
    />
    <path
      d="M11.3599 9.70523H13.3154V11.7779H11.3599V9.70523Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgCalender);
export default Memo;
