import React from "react";
import { ThemeProvider } from "styled-components";
import { RouterProvider } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// import ToolTipFunction from "./common/components/ToolTip/ToolTip";
import TooltipContainer from "./common/components/TooltipContainer";

import URLProvider from "./context/URLProvider";
import { NotificationProvider } from "./context/NotificationContext";
import useAppRoutes from "./routes/useAppRoutes";

import LoopLoader from "./components/ui-components/LoopLoader";
import { useAppStore } from "./store/AppStore/AppStore";
import { Theme } from "./styled/theme";

import "./styles/theme/style.css";
import "./styles/common-styles.scss";
import "./styles/utility.scss";
import GlobalStyle from "./styled/global";
import { ToastMessageProvider } from "./context/ToastMessageProvider";

// Create a client
const queryClient = new QueryClient();

function App() {
  const rootRouter = useAppRoutes();

  const loadJobTitles = useAppStore((state) => state.getJobTitles);
  const loadUserTitle = useAppStore((state) => state.getUserTitleList);
  const loadAllCountries = useAppStore((state) => state.loadAllCountries);

  React.useEffect(() => {
    loadJobTitles();
    loadAllCountries();
    loadUserTitle();
  }, [loadJobTitles, loadAllCountries, loadUserTitle]);

  // LoadToolTip
  // ToolTipFunction();

  return (
    <>
      <GlobalStyle />

      <ThemeProvider theme={Theme}>
        <LoopLoader />
        {/* <Tooltip /> */}
        <TooltipContainer />

        <QueryClientProvider client={queryClient}>
          <ToastMessageProvider>
            <NotificationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <URLProvider>
                  <RouterProvider router={rootRouter} />
                </URLProvider>
              </LocalizationProvider>
            </NotificationProvider>
            <ReactQueryDevtools position="top-left" />
          </ToastMessageProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
