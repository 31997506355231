import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/css/project_brief.59ed5c37a660f352e55c74b3d98320b2.css";
import { ROUTE_OPTIONS } from "../../../constant/route-options";
import { useNavigate, useParams, useRouteLoaderData } from "react-router-dom";
import { PROJECT_PERMISSION_MODULES } from "../../../constant/project_permission_module";
import { Permission } from "../../../helpers/permission";
import Container from "../../ui-components/Container";
import BriefToolbar from "./Components/ProjectBrief/BriefToolbar";
import BriefCard from "./Components/ProjectBrief/BriefCard";
import {
  getProjectByID,
  projectContractJoinOrDecline,
  setProjectMemberStatus,
} from "../../../Actions/Project";
import { useAppStore } from "../../../store/AppStore/AppStore";
import withProfileUpdateAlert from "../../../hoc/withProfileUpdateAlert";
import Alert from "../../../utility/alert";
import useUserData from "../../../hooks/useUserData";
import useProjectsByParams from "../../../hooks/useProjectsByParams";
import { ONBOARDING_STATUS } from "../../../constant/Status";

const ActionsShowingStatus = [
  ONBOARDING_STATUS.InvitationAccepted, // 4
  // ONBOARDING_STATUS.MemberContractDeclined, // 20
  // ONBOARDING_STATUS.MemberRequestedContract, // 19
];

const ProjectBrief = (props) => {
  const { onValidate } = props;
  const [projectDetails, setProjectDetails] = React.useState(null);
  const [projectMembers, setProjectMembers] = React.useState([]);

  const { id } = useParams();
  const navigate = useNavigate();
  const { userId } = useUserData();
  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);

  const { status, onReFetch } = useProjectsByParams({
    userId: userId,
    tenantId: projectDetails?.tenantId,
  });

  const { data: projectsListData } = status;

  const { permission } = useRouteLoaderData(
    ROUTE_OPTIONS.projects.children["project-brief"].id
  );

  const moduleType = PROJECT_PERMISSION_MODULES.ProjectBrief;
  const permissions = new Permission(permission);

  const declineProjectAction = permissions.findByName(
    moduleType.DeclineProject.key
  );

  const joinProjectAction = permissions.findByName(moduleType.JoinProject.key);

  const project = React.useMemo(() => {
    return (
      projectsListData?.find((p) => p.projectId.toString() === id.toString()) ||
      null
    );
  }, [projectsListData, id]);

  const member = React.useMemo(() => {
    return (
      projectMembers?.find(
        (m) => m.memberUserId?.toString() === userId?.toString()
      ) || null
    );
  }, [projectMembers, userId]);

  const getProjectByIdCallback = React.useCallback(async () => {
    try {
      if (!id) return;
      showLoader();

      const data = await getProjectByID(id);

      if (!data) {
        hideLoader();
        return;
      }

      console.log("data --- project brief ", data);

      setProjectMembers(data?.projectMembers);
      setProjectDetails(data?.projectDetails);
      hideLoader();
    } catch (error) {
      hideLoader();
      toast.error("Error");
    }
  }, [id, hideLoader, showLoader]);

  const onJoinHandler = () => {
    if (!member?.projectMemberId) {
      return;
    }

    onValidate().then((isValid) => {
      if (isValid) {
        showLoader();
        setProjectMemberStatus({
          projectMemberId: member?.projectMemberId,
          projectMemberStatusId: 19,
        })
          .then((res) => {
            hideLoader();

            if (!res.isSuccess) {
              return;
            }

            Alert.fire({
              allowOutsideClick: false,
              icon: "success",
              title: "Your information successfully saved",
              text: `${projectDetails?.name} will create a contract for you and notify you by email.`,
              confirmButtonText: "Got it!",
            }).then(() => {
              navigate(`/${ROUTE_OPTIONS.dashboard.path}`);
            });
          })
          .catch((err) => {
            hideLoader();
          });
      }
    });
  };

  const onDeclineHandler = () => {
    if (!member?.projectMemberId) {
      return;
    }

    showLoader();

    setProjectMemberStatus({
      projectMemberId: member?.projectMemberId,
      projectMemberStatusId: ONBOARDING_STATUS.InvitationRejected,
    })
      .then((res) => {
        hideLoader();

        if (!res.isSuccess) {
          return;
        }

        Alert.fire({
          allowOutsideClick: false,
          icon: "success",
          title: "Your information successfully saved",
          text: "You declined the Join Process request. You can still join their project by calling them.",
          confirmButtonText: "Got it!",
        }).then(() => {
          onReFetch();
        });
      })
      .catch((err) => {
        hideLoader();
      });
  };

  const onDecideLaterHandler = () => {
    navigate(`/${ROUTE_OPTIONS.dashboard.path}`);
  };

  React.useEffect(() => {
    getProjectByIdCallback();
  }, [getProjectByIdCallback]);

  const canBtnShow = React.useMemo(() => {
    return ActionsShowingStatus.some(
      (a) => a?.toString() === project?.projectMemberStatusId.toString()
    );
  }, [project?.projectMemberStatusId]);

  return (
    <>
      {/* New UI */}

      <Container>
        <div className="project__brief__container">
          {/* heading */}
          <BriefToolbar
            title={projectDetails?.name}
            onDecline={member?.projectMemberId ? onDeclineHandler : null}
            onJoinProject={member?.projectMemberId ? onJoinHandler : null}
            onCancel={onDecideLaterHandler}
            showDeclineBtn={
              declineProjectAction.permission.canView && canBtnShow
            }
            showJoinBtn={joinProjectAction.permission.canView && canBtnShow}
            showDecideLateBtn={canBtnShow}
            statusText={project?.projectMemberStatus}
            statusId={project?.projectMemberStatusId}
          />

          <BriefCard
            joinAs={member?.roleInProject || ""}
            downloadDocName={projectDetails?.projectDeckUrl}
            downloadUrl={projectDetails?.projectDeckUrl}
            projectDetails={projectDetails}
          />

          {/* contents */}
        </div>
      </Container>
    </>
  );
};

export default withProfileUpdateAlert(ProjectBrief, {
  page: "Contracts",
  validateOnPageLoad: false,
});
