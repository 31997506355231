import * as React from "react";
import { memo } from "react";
const SvgSettings = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.92984 11.4821L7.11835 10.8971C7.14785 10.7241 7.16735 10.5476 7.16735 10.3656C7.16735 10.1836 7.14785 10.0071 7.11835 9.83412L7.92984 9.24913C8.07034 9.14763 8.11534 8.94163 8.02834 8.79113L7.39535 7.69564C7.30835 7.54514 7.10735 7.48064 6.94985 7.55214L6.03986 7.96114C5.76736 7.73464 5.45586 7.55514 5.11687 7.43064L5.01637 6.43515C4.99887 6.26265 4.84237 6.12115 4.66887 6.12115H3.40388C3.22988 6.12115 3.07388 6.26265 3.05738 6.43515L2.95588 7.43064C2.61789 7.55514 2.30539 7.73414 2.03389 7.96114L1.1239 7.55214C0.965902 7.48064 0.764404 7.54514 0.677404 7.69564L0.04441 8.79113C-0.0425893 8.94163 0.00241035 9.14763 0.142909 9.24913L0.954402 9.83412C0.924902 10.0071 0.906402 10.1836 0.906402 10.3656C0.906402 10.5476 0.924902 10.7241 0.954402 10.8971L0.142909 11.4821C0.00241035 11.5836 -0.0425892 11.7896 0.04541 11.9401L0.677404 13.0356C0.764404 13.1861 0.965402 13.2506 1.1239 13.1796L2.03389 12.7701C2.30539 12.9966 2.61789 13.1761 2.95688 13.3006L3.05738 14.2961C3.07388 14.4686 3.23138 14.6101 3.40488 14.6101H4.66937C4.84337 14.6101 4.99937 14.4686 5.01687 14.2961L5.11736 13.3006C5.45636 13.1756 5.76786 12.9971 6.04036 12.7701L6.95035 13.1791C7.10735 13.2506 7.30885 13.1861 7.39584 13.0356L8.02884 11.9401C8.11534 11.7901 8.07084 11.5841 7.92984 11.4821ZM4.03637 12.1131C3.07138 12.1131 2.28939 11.3311 2.28939 10.3661C2.28939 9.40113 3.07138 8.61913 4.03637 8.61913C5.00137 8.61913 5.78336 9.40113 5.78336 10.3661C5.78336 11.3311 5.00137 12.1131 4.03637 12.1131Z"
      fill="currentColor"
    />
    <path
      d="M12.4698 6.13715L12.6483 5.30366C12.6728 5.18916 12.6023 5.06616 12.4913 5.03016L11.7678 4.79667C11.7158 4.30167 11.5118 3.82767 11.1733 3.44918L11.4858 2.75818C11.5338 2.65168 11.4908 2.51669 11.3893 2.45769L10.6518 2.03019C10.5513 1.97169 10.4118 2.00069 10.3433 2.09569L9.89882 2.71018C9.40183 2.60369 8.88883 2.66118 8.43284 2.86268L7.87134 2.35119C7.78434 2.27219 7.64284 2.27169 7.55584 2.34919L6.92085 2.91868C6.83385 2.99668 6.81935 3.13768 6.88785 3.23218L7.33435 3.84467C7.07185 4.29817 6.97685 4.80167 7.02685 5.28616L6.36685 5.66216C6.26536 5.72016 6.22136 5.85466 6.26835 5.96216L6.61385 6.74165C6.66085 6.84915 6.78985 6.90665 6.90085 6.87015L7.62134 6.63465C7.78434 6.81615 7.97784 6.97715 8.20034 7.10565C8.42284 7.23514 8.65833 7.32314 8.89783 7.37414L9.05033 8.11664C9.07483 8.23114 9.18883 8.31464 9.30533 8.30264L10.1528 8.21564C10.2688 8.20364 10.3648 8.09814 10.3638 7.98114L10.3628 7.22064C10.8083 7.02365 11.1978 6.69165 11.4613 6.23865L12.2143 6.32165C12.3303 6.33465 12.4458 6.25165 12.4698 6.13715ZM8.72983 6.19265C8.09434 5.82416 7.87734 5.00916 8.24634 4.37267C8.61533 3.73718 9.42983 3.52068 10.0658 3.88967C10.7013 4.25817 10.9183 5.07266 10.5493 5.70916C10.1798 6.34515 9.36533 6.56165 8.72983 6.19265Z"
      fill="currentColor"
    />
    <path
      d="M15.9878 10.6431L15.6898 9.72462C15.6488 9.59912 15.5103 9.51862 15.3803 9.54712L14.5393 9.72962C14.2073 9.27363 13.7373 8.92513 13.1908 8.74713L13.1038 7.89314C13.0903 7.76164 12.9708 7.65364 12.8393 7.65364H11.8733C11.7413 7.65364 11.6223 7.76214 11.6088 7.89314L11.5228 8.74713C10.9748 8.92463 10.5053 9.27363 10.1743 9.72962L9.33233 9.54712C9.20333 9.51862 9.06383 9.59912 9.02283 9.72462L8.72483 10.6431C8.68383 10.7691 8.74933 10.9156 8.87033 10.9686L9.65533 11.3141V11.3151C9.65533 11.9086 9.84882 12.4546 10.1728 12.9011L9.73932 13.6441C9.67282 13.7586 9.70633 13.9156 9.81332 13.9936L10.5948 14.5611C10.7013 14.6391 10.8613 14.6216 10.9493 14.5231L11.5218 13.8831C11.7843 13.9686 12.0648 14.0166 12.3568 14.0166C12.6478 14.0166 12.9283 13.9686 13.1908 13.8831L13.7633 14.5231C13.8523 14.6216 14.0113 14.6391 14.1188 14.5611L14.8993 13.9936C15.0068 13.9156 15.0398 13.7586 14.9733 13.6441L14.5398 12.9011C14.8638 12.4546 15.0573 11.9086 15.0573 11.3151V11.3141L15.8423 10.9686C15.9633 10.9156 16.0288 10.7691 15.9878 10.6431ZM12.3568 12.8226C11.5238 12.8226 10.8488 12.1476 10.8488 11.3151C10.8488 10.4831 11.5238 9.80762 12.3568 9.80762C13.1888 9.80762 13.8638 10.4831 13.8638 11.3151C13.8638 12.1481 13.1888 12.8226 12.3568 12.8226Z"
      fill="currentColor"
    />
    <path
      d="M4.03637 11.3591C4.58479 11.3591 5.02937 10.9145 5.02937 10.3661C5.02937 9.8177 4.58479 9.37313 4.03637 9.37313C3.48796 9.37313 3.04338 9.8177 3.04338 10.3661C3.04338 10.9145 3.48796 11.3591 4.03637 11.3591Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgSettings);
export default Memo;
