import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.56914 5.71812H6.71429V12.573H5.56914V5.71812Z"
      fill="currentColor"
    />
    <path
      d="M8.99771 5.71812H10.1429V12.573H8.99771V5.71812Z"
      fill="currentColor"
    />
    <path
      d="M1 2.28954V3.43469H2.14515V14.8598C2.14515 15.1635 2.2658 15.4548 2.48055 15.6696C2.69531 15.8843 2.98658 16.005 3.2903 16.005H12.4309C12.7346 16.005 13.0258 15.8843 13.2406 15.6696C13.4554 15.4548 13.576 15.1635 13.576 14.8598V3.4324H14.7212V2.28725L1 2.28954ZM3.2903 14.8575V3.4324H12.4309V14.8575H3.2903Z"
      fill="currentColor"
    />
    <path
      d="M5.56914 0.00497437H10.1394V1.15012H5.56914V0.00497437Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
