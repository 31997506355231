export const MilestoneActionTypes = {
  INITIAL_STATE: "@INITIAL_STATE",
  ADD_ROW: "@ADD_ROW",
  ADD_SUB_ROW: "@ADD_SUB_ROW",
  DELETE_ROW: "@DELETE_ROW",
  DUE_MODAL_STATE: "@DUE_MODAL_STATE",
  EVENT_CHANGE: "@EVENT_CHANGE",
  CELL_SUBMENU_STATE: "@CELL_SUBMENU_STATE",
  CONDITION_UPDATE: "@CONDITION_UPDATE",
  LOADERS_STATE: "@LOADERS_STATE",
  DELIVERABLE_MODAL_STATE: "@DELIVERABLE_MODAL_STATE",
  SUBMISSION_SAVE: "@SUBMISSION_SAVE",
  DUE_DATE_CHANGE: "@DUE_DATE_CHANGE",
  CHANGE_REQUEST_STATE_CHANGE: "@CHANGE_REQUEST_STATE_CHANGE",
  DELIVERABLE_NAME_UPDATE: "DELIVERABLE_NAME_UPDATE",
};
