import * as React from "react";
import { memo } from "react";
const SvgShare = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0005 0.00497437V4.00467C1.99985 4.00467 0 8.10466 0 14.0051C0.395007 12.2134 1.41934 10.6226 2.88707 9.52156C4.3548 8.42053 6.16865 7.88223 7.99938 8.00436H10.0005V12.004L16 5.68875L10.0005 0.00497437Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgShare);
export default Memo;
