import React from "react";

import EditIcon from "../../../../../../common/components/icons/Line/Edit/01";
import Typography from "../../../../../../common/components/Typography";
import { IconBlock } from "../../../../../../styled/Common/Icons";
import { StyledActionBtn, StyledCardHeader, StyledCardStatus } from "../styled";

const Header = (props) => {
  const {
    hideRightActions = false,
    title = "",
    subTitle = "",
    discription = "",
    onEditHandler,
    handleTitleClick,
  } = props;

  return (
    <StyledCardHeader>
      <div className="top">
        {/* Status  */}
        <StyledCardStatus />

        {/* Title */}
        <div className="title__wr">
          <Typography
            className="title"
            variant={Typography.font_variant.large}
            fontWeight={Typography.font_weight.medium}
            data-tooltip-text={title}
            onClick={handleTitleClick}
            // data-tooltip-placement="top"
          >
            {title}
          </Typography>
        </div>

        {/* Right actions */}

        <div className="action__wr">
          {!hideRightActions ? (
            <>
              {/* //TODO */}
              {/* 
                Shared btn
              */}
              {/* <StyledActionBtn>
                <IconBlock>
                  <Users />
                </IconBlock>
              </StyledActionBtn> */}

              {/*
                Todo btn
              */}
              {/* <StyledActionBtn>
                <IconBlock>
                  {hasTodo ? <StyledTodoStatus /> : null}

                  <Todo />
                </IconBlock>
              </StyledActionBtn> */}
            </>
          ) : null}

          <StyledActionBtn
            onClick={onEditHandler}
            data-tooltip-text="Edit"
            data-tooltip-placement="top"
          >
            <IconBlock>
              <EditIcon />
            </IconBlock>
          </StyledActionBtn>

          {/* Menu */}
          {/* <StyledActionBtn
            data-tooltip-text="More options"
            data-tooltip-placement="top"
          >
            <IconBlock>
              <Menu />
            </IconBlock>
          </StyledActionBtn> */}
        </div>
      </div>

      {/* More info */}

      {(subTitle || discription) && (
        <div className="bottom">
          {subTitle && (
            <Typography
              className="subtitle"
              variant={Typography.font_variant.small}
              fontWeight={Typography.font_weight.regular}
              color={Typography.font_color.tint_04}
            >
              {subTitle}
            </Typography>
          )}

          {discription && (
            <Typography
              className="contents"
              variant={Typography.font_variant.xSmall}
              fontWeight={Typography.font_weight.regular}
              color={Typography.font_color.tint_04}
            >
              {discription}
            </Typography>
          )}
        </div>
      )}
    </StyledCardHeader>
  );
};

export default Header;
