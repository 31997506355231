import React from "react";
import { useDayPicker } from "react-day-picker";
import { setYear, startOfMonth, startOfYear } from "date-fns";

import * as Styled from "../styled";

const Years = (props) => {
  const { displayMonth } = props;

  const {
    fromDate,
    toDate,
    locale,
    today,
    formatters: { formatYearCaption },
  } = useDayPicker();

  const [currentYear, setCurrentDate] = React.useState(displayMonth || today);

  let years = [];

  const fromYear = fromDate.getFullYear();
  const toYear = toDate.getFullYear();

  for (let year = fromYear; year <= toYear; year++) {
    years.push(setYear(startOfYear(new Date()), year));
  }

  const onChange = React.useCallback(
    (e) => {
      const value = e.target.value;
      const newMonth = setYear(startOfMonth(displayMonth), Number(value));
      props.onChange(newMonth);
      setCurrentDate(newMonth);
    },
    [displayMonth, props]
  );

  React.useEffect(() => {
    setCurrentDate(displayMonth);
  }, [displayMonth]);

  if (!fromDate) return <></>;

  if (!toDate) return <></>;

  return (
    <>
      <select onChange={onChange} value={currentYear.getFullYear()}>
        {years.map((year, i) => {
          return (
            <option key={i} value={year.getFullYear()}>
              {formatYearCaption(year, { locale })}
            </option>
          );
        })}
      </select>
    </>
  );
};

export default Years;
