import styled from "styled-components";

export const ClampLineText = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: ${({ lines }) => (lines ? lines : 2)};
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: inherit;
  font: inherit;
`;
