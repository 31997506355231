import React from "react";
import * as Styled from "./styled";

import { TextEllipsis } from "../../../../../../styled/Common/styled.text";

import IconButton from "../../../../../../common/components/Buttons/IconButton";
import { Add } from "../../../../../../common/components/icons/Fill";

const ListView = (props) => {
  const {
    title,
    children,
    isScrollbarEnabled = true,
    innerContentClassName,
    onAdd,
    enabledHeader,
    ...rest
  } = props;

  return (
    <Styled.ViewContainer isScrollbarEnabled={isScrollbarEnabled} $enabledHeader={enabledHeader} {...rest}>
      {enabledHeader && (
        <div className="list__view__head">
          <div className="title">
            <TextEllipsis>{title}</TextEllipsis>
          </div>

          {/* Add button */}
          <IconButton className="add__button" icon={<Add />} onClick={onAdd} />
        </div>
      )}

      <div className="list__view__content">
        <div className={["inner__content", innerContentClassName].join(" ")}>
          {children}
        </div>
      </div>
    </Styled.ViewContainer>
  );
};

export default ListView;
