import React from "react";
import ErrorText from "../ErrorText";
import "./styles.scss";

const LineInputGroup = (props) => {
  const { error, children, label } = props;

  return (
    <div className="line__input__group custom">
      <div className="inner">
        <div className="line__input__label">{label}</div>
        <div className="line__input__element">{children}</div>
      </div>
      <ErrorText error={error} />
    </div>
  );
};

export default LineInputGroup;
