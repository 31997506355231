export const MILESTONE_ENDPOINTS = {
  GET_MILESTONE_TRACKER: "schedule/deliverable",
  SAVE_ROW: "schedule/deliverable",
  SAVE_SUB_SECTION: "schedule/deliverable",
  UPDATE_CELL: "schedule/deliverable",
  SAVE_CONDITION: "/schedule/deliverable/condition",
  SAVE_SUBMISSION: "/schedule/deliverable/submission",
  GET_SUBMISSION_DATA: "/submission",
  CHANGE_REQUEST: "/schedule/deliverable/submission/change-request",
  SVAE_DUE: "/schedule/deliverable/due",
  GET_DUE: "/schedule/deliverable/due",
  DELETE_ROW: "/schedule/deliverable",
};
