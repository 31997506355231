import React from "react";
import './styles.scss';


const FloatActionBar = ({ children }) => {
  return (
    <div className="float__actions">
      <div className="float__actions__iw">
        <div className="action__item">{children}</div>
      </div>
    </div>
  );
};

export default FloatActionBar;
