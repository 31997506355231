import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 0C9.1841 0 9.33333 0.14924 9.33333 0.333333C9.33333 0.517427 9.1841 0.666667 9 0.666667H2C1.8159 0.666667 1.66667 0.8159 1.66667 1V15C1.66667 15.1841 1.8159 15.3333 2 15.3333H13.3333C13.5174 15.3333 13.6667 15.1841 13.6667 15V3.66667H11.6667C11.1144 3.66667 10.6667 3.21897 10.6667 2.66667V0.252454C10.6667 0.113027 10.7797 0 10.9191 0C11.1843 0 11.4387 0.105357 11.6262 0.292893L14.0404 2.7071C14.228 2.89463 14.3333 3.149 14.3333 3.4142V15.0022C14.3333 15.5545 13.8856 16 13.3333 16H2C1.4477 16 1 15.5523 1 15V1C1 0.447717 1.4432 0 1.9955 0H9Z"
      fill="currentColor"
    />
    <path
      d="M7.59471 5.3944C7.60814 5.31297 7.72521 5.31297 7.73864 5.3944L7.86047 6.13417C8.11454 7.67657 9.32344 8.88547 10.8658 9.13953L11.6056 9.26137C11.687 9.2748 11.687 9.39187 11.6056 9.4053L10.8658 9.52713C9.32344 9.7812 8.11454 10.9901 7.86047 12.5325L7.73864 13.2723C7.72521 13.3537 7.60814 13.3537 7.59471 13.2723L7.47287 12.5325C7.21881 10.9901 6.00991 9.7812 4.46751 9.52713L3.72774 9.4053C3.64631 9.39187 3.64631 9.2748 3.72774 9.26137L4.46751 9.13953C6.00991 8.88547 7.21881 7.67657 7.47287 6.13417L7.59471 5.3944Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
