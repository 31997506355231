import styled from "styled-components";
import { fontStyle } from "../../../../../styled/utility";

export const Link = styled.div`
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;

  .link__text {
    ${fontStyle("small-regular")};
    color: var(--color-neutral-400);
    padding: var(--layout-spacing-4);
  }

  .link__line {
    border-bottom: 1px solid transparent;
  }

  ${({ $active, $isDisabled }) => {
    if ($active) {
      return `
        .link__text {
            color: var(--color-primary-500);
            ${fontStyle("small-bold")};
        }

        .link__line {
            border-bottom-color: var(--color-secondary-300);
        }
        `;
    }

    if ($isDisabled)
      return `
      cursor: auto;
      opacity: 0.3;
    `;

    if (!$active || $isDisabled) {
      return `
        &:hover {
            opacity: 0.5;
        }
        `;
    }
  }};
`;

export const LinkWrapper = styled.div`
  display: flex;
  gap: var(--layout-spacing-16);
  padding-bottom: var(--layout-spacing-16);
`;
