import styled from "styled-components";

export const TextEllipsis = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  display: inline-block;
  color: inherit;
  font: inherit;
  min-width: 0px;
  flex: 1;
`;
