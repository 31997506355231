import React from "react";

import { StyledContentWrapper } from "./styled";
import useTagCardContent from "../../../../hooks/useTagCardContent";
// import { useResearchBoardStore } from "../../../../store/ResearchBoardStore";
import Typography from "../../../../../../common/components/Typography";

const Contents = (props) => {
  const { variant, visual, who, name, visualType, iconComponent } = props;
  //console.log("visual-content-", visual);
  let showContent = true;

  if (visual === undefined) showContent = false;

  const { content: renderContents } = useTagCardContent({
    variant,
    visual,
    visualType,
    iconComponent,
  });

  if (!renderContents && !(name && String(name).trim().length)) {
    return null;
  }

  return (
    <StyledContentWrapper>
      {showContent
        ? renderContents && (
            <div className="start">
              <div className="inner">{renderContents}</div>{" "}
            </div>
          )
        : null}
      {/* <div className="start">
        <div className="inner">{renderContents}</div>
      </div> */}

      {name && String(name).trim().length > 0 && (
        <div className="end">
          <Typography
            className="text__x--small"
            variant={Typography.font_variant.xSmall}
          >
            {who}
          </Typography>

          <Typography
            className="text__large"
            variant={Typography.font_variant.label}
            fontWeight={Typography.font_weight.semi_bold}
          >
            {name}
          </Typography>
        </div>
      )}
    </StyledContentWrapper>
  );
};

export default Contents;
