import styled from "styled-components";
import Popover from "../../../../../../../../../common/components/Popover";
import { fontStyle } from "../../../../../../../../../styled/utility";

export const PopoverMenu = styled(Popover)`
  .menu__wrapper {
    max-width: 180px;
    min-width: 130px;
    width: 100%;
    background-color: var(--color-neutral-10);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: var(--layout-border-radius-12);
    padding: var(--layout-spacing-8) 0;

    .menu-divider {
      width: 100%;
      height: 1px;
      background-color: var(--color-neutral-80);
      /* margin: var(--layout-spacing-2) 0; */
    }

    .menu-item {
      button {
        outline: none;
        border: none;
        background-color: transparent;
        width: 100%;
        text-align: left;
        padding: var(--layout-spacing-8) var(--layout-spacing-16);
        display: flex;
        gap: var(--layout-spacing-8);
        align-items: center;

        &:hover {
          opacity: 0.5;
        }

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;

          .icon__button__element {
            --icon-button-icon-color: var(--font-tint-04);
          }
        }

        .text {
          color: var(--color-primary-300);
          ${fontStyle("label-medium")};
          font-weight: 600;
        }
      }
    }
  }
`;

export const CalendarPopoverWrapper = styled(Popover)`
  .mask {
    background-color: red;
  }
  .calendar-wrapper {
    background-color: var(--font-default-white);
    position: var(--layout-spacing-16);
    border-radius: var(--layout-border-radius-4);
    margin: var(--layout-spacing-4);
    overflow: hidden;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.09);
    border: 1px solid #cedade;
  }
`;
