import styled from "styled-components";

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 280px;
  text-align: center;

  .loader {
    width: ${({ size }) => size};

    img {
      max-width: 100%;
      width: 100%;
    }
  }

  .txt {
    margin-top: var(--layout-spacing-minus-12);
  }
`;
