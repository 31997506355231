import React from "react";

export const URLContext = React.createContext({
  redirectUrl: "",
  addRedirectUrl: (url) => {},
  removeRedirectUrl: () => {},
});

export const useUrlContext = () => {
  return React.useContext(URLContext);
};

const URLProvider = ({ children }) => {
  const [url, setUrl] = React.useState("");

  const addRedirectUrlHandler = (url) => {
    setUrl(url);
  };

  const removeRedirectUrlHandler = (url) => {
    setUrl("");
  };

  const values = {
    redirectUrl: url,
    addRedirectUrl: addRedirectUrlHandler,
    removeRedirectUrl: removeRedirectUrlHandler,
  };

  return <URLContext.Provider value={values}>{children}</URLContext.Provider>;
};

export default URLProvider;
