import styled from "styled-components";

export const FilterItemContainer = styled.div`
     display: flex;
    flex-direction: column;
    gap: var(--layout-spacing-4); 
    margin-top: var(--layout-spacing-24);

    .search__wrapper  {
        --search-height: var(--layout-size-32);
        --search-input-rounded: var(--layout-border-radius-4);
    }
`
