import React from "react";
import Loader from "../../../assets/loader_primary.gif";
import * as Styled from "./styled";
import Typography from "../Typography";

const AnimateLoader = ({ size = "72px", text }) => {
  return (
    <Styled.LoaderContainer size={size}>
      <div className="loader">
        <img src={Loader} alt="Loader" />
      </div>

      {text ? (
        <div className="txt">
          <Typography
            variant={Typography.font_variant.small}
            color={Typography.font_color.color_shade_accent}
          >
            {text}
          </Typography>
        </div>
      ) : null}
    </Styled.LoaderContainer>
  );
};

export default AnimateLoader;
