import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.476 5.35251C15.508 5.30077 15.5457 5.24717 15.5908 5.19357C16.0369 4.64839 15.9992 3.93312 15.9992 3.93312L15.7094 1.06291C15.7056 1.02778 15.6774 1.00191 15.6416 1.00191C15.604 1.00191 15.5719 1.03331 15.5738 1.07031L15.6378 3.79082C15.6378 3.82962 15.6039 3.86289 15.5625 3.85922C15.5286 3.85555 15.5023 3.82782 15.5004 3.79455L15.3442 1.06665C15.3423 1.03152 15.3122 1.00384 15.2764 1.00384C15.2388 1.00384 15.2086 1.03344 15.2086 1.07038L15.1748 3.79458C15.1748 3.83152 15.1446 3.86112 15.107 3.86112H15.0938L15.077 3.86106C15.0394 3.86106 15.0092 3.83146 15.0092 3.79452L14.9753 1.07032C14.9753 1.03338 14.9452 1.00378 14.9076 1.00378C14.8718 1.00378 14.8417 1.03152 14.8398 1.06659L14.6817 3.79264C14.6798 3.82591 14.6535 3.85545 14.6196 3.85731C14.5782 3.86098 14.5443 3.82958 14.5443 3.78891L14.6083 1.0684C14.6083 1.03146 14.5781 1 14.5405 1C14.5047 1 14.4766 1.02587 14.4728 1.061L14.1829 3.93121C14.1829 3.93121 14.1453 4.64647 14.5913 5.19166C14.6365 5.24527 14.6741 5.29887 14.7061 5.3506C14.7871 5.48182 14.8266 5.63522 14.8209 5.78863L14.5142 14.4695C14.5048 14.7596 14.7401 14.9999 15.0356 14.9999H15.1466C15.4421 14.9999 15.6792 14.7596 15.6679 14.4695L15.3593 5.79048C15.3536 5.63707 15.3932 5.48366 15.476 5.35245L15.476 5.35251Z"
      fill="currentColor"
    />
    <path
      d="M1.96115 5.55766C2.46556 5.1973 2.81938 4.35449 2.81938 3.37312C2.81938 2.0628 2.18888 1.00192 1.40969 1.00192C0.632391 1.00192 0 2.06276 0 3.37312C0 4.35449 0.353822 5.19542 0.858229 5.55766C1.037 5.6852 1.13681 5.89216 1.12927 6.10844L0.833715 14.4696C0.82428 14.7597 1.05954 15 1.35503 15H1.46604C1.76154 15 1.9987 14.7597 1.98736 14.4696L1.68997 6.10844C1.68256 5.89216 1.78231 5.68521 1.96115 5.55766Z"
      fill="currentColor"
    />
    <path
      d="M8.28506 3.22342C5.5974 3.22342 3.41979 5.36175 3.41979 8.00094C3.41979 10.6401 5.5974 12.7785 8.28506 12.7785C10.9727 12.7785 13.1503 10.6401 13.1503 8.00094C13.1522 5.36175 10.9727 3.22342 8.28506 3.22342ZM10.617 8.28556H8.28506C8.20976 8.28556 8.13636 8.25596 8.08367 8.20422L8.07994 8.20055C8.02725 8.14881 7.9971 8.07674 7.9971 8.00279V5.71106C7.9971 5.55585 8.12509 5.43198 8.28132 5.43198C8.43938 5.43198 8.56553 5.55765 8.56553 5.71106V7.72372H10.617C10.7751 7.72372 10.9013 7.8494 10.9013 8.00281C10.9031 8.15989 10.7751 8.28556 10.617 8.28556L10.617 8.28556Z"
      fill="currentColor"
    />
    <path
      d="M12.5499 3.81484C11.9966 3.27146 11.351 2.84455 10.6339 2.54513C9.89053 2.2365 9.1 2.07942 8.28694 2.07942C7.47387 2.07942 6.68338 2.2365 5.93994 2.54513C5.22287 2.84268 4.57731 3.26959 4.02395 3.81484C3.47059 4.35821 3.03584 4.99213 2.73092 5.69627C2.41662 6.42627 2.25666 7.20254 2.25666 8.00094C2.25666 8.79933 2.41662 9.57558 2.73092 10.3056C3.03394 11.0097 3.46869 11.6437 4.02395 12.187C4.57914 12.7304 5.22287 13.1573 5.93994 13.4567C6.68335 13.7654 7.47388 13.9225 8.28694 13.9225C9.10001 13.9225 9.8905 13.7654 10.6339 13.4567C11.351 13.1592 11.9966 12.7323 12.5499 12.187C13.1033 11.6437 13.538 11.0097 13.843 10.3056C14.1573 9.57561 14.3172 8.79933 14.3172 8.00094C14.3172 7.20254 14.1573 6.4263 13.843 5.69627C13.538 4.99027 13.1033 4.35821 12.5499 3.81484ZM8.28506 13.3625C5.27556 13.3625 2.82504 10.958 2.82504 8.00094C2.82504 5.04387 5.27367 2.6394 8.28506 2.6394C11.2964 2.6394 13.7451 5.04572 13.7451 8.00094C13.7451 10.9562 11.2964 13.3625 8.28506 13.3625Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
