import React from "react";
import { debounce } from "lodash";
import { useQuery } from "react-query";
import { tagSearch } from "../api/common";

function useTagsSearch({ queryValue = undefined }) {
  const [searchValue, setSearchValue] = React.useState(undefined);

  const isQueryEnabled = React.useMemo(() => {
    if (searchValue && searchValue?.length > 0) return true;
    return false;
  }, [searchValue]);

  // API Query
  const { data, isLoading, isError } = useQuery(
    ["tag-search", searchValue],
    () => tagSearch({ searchValue: searchValue }),
    {
      enabled: !!isQueryEnabled,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
    }
  );

  const searchTextDebounce = React.useMemo(
    () => debounce(setSearchValue, 1000),
    []
  );

  React.useEffect(() => {
    const prev = searchTextDebounce;

    return () => {
      prev.cancel();
    };
  }, [searchTextDebounce]);

  React.useEffect(() => {
    searchTextDebounce(queryValue);
  }, [searchTextDebounce, queryValue]);

  return { data, isLoading, isError };
}

export default useTagsSearch;
