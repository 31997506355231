import React from "react";
import { styleClass } from "../../../../../../../utility";
import icon_sun from "../../../../../../assets/svg/sun_1.svg";
import icon_moon from "../../../../../../assets/svg/moon.svg";

import "../styles.scss";

const LocationItem = (props) => {
  const { isActive, area, time } = props;

  const activeClass = React.useMemo(() => {
    if (isActive) {
      return "location__item--active";
    }
    return "";
  }, [isActive]);

  const dayTimeIcon = React.useMemo(() => {
    const hours = new Date(`1999-1-1 ${time}`)?.getHours() || null;

    if (hours >= 6 && hours < 18) {
      return <img className="icon__sun" src={icon_sun} alt="" />;
    }

    return <img className="icon__moon" src={icon_moon} alt="" />;
  }, [time]);

  return (
    <div className={styleClass("location__item", activeClass)}>
      <div className="location__item__wr">
        <div className="location__item__wr__left">
          <span className="location__item__icon">{dayTimeIcon}</span>
        </div>
        <div className="location__item__wr__right">
          <div className="location__item__area">{area}</div>
          <div className="location__item__time">{time}</div>
        </div>
      </div>
    </div>
  );
};

export default LocationItem;
