import React from "react";
import GenerateTemplate from "../../components/TXTextController/components/GenerateTemplate";
import { useLocation } from "react-router-dom";

const EditTemplate = ({ mergeData }) => {
  const location = useLocation();
  const { templateId, templateTypeId, templateName } = location.state;

  const testingMergeData = mergeData || [
    {
      Id: 1,
      Name: "Robbert Knox",
      Age: 26,
      Company: {
        name: "Futura Tech Labs",
        country: "Sri Lanka",
        branch: {
          name: "Futura 2.0",
          location: "Singapore",
        },
      },
      Country: "Sri Lanka",
    },
  ];

  return (
    <>
      <GenerateTemplate
        templateId={templateId}
        templateTypeId={templateTypeId}
        templateName={templateName}
        isEditMode={true}
        mergeData={testingMergeData}
      />
    </>
  );
};

export default EditTemplate;
