import styled from "styled-components";
import { fontStyle } from "../../../../../../../styled/utility";

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: var(--layout-spacing-20);
  grid-column-gap: var(--layout-spacing-16);

  .grid-item-3 .grid-item-4 {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .field__group {
    ${fontStyle("small-medium")};
  }

  .grid-item {
    &.full {
      ${"" /* grid-column: 1/-1; */}
      grid-column: 1/-1;
    }

    &.half {
    }
  }
`;
