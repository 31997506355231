import React from "react";
import { LoaderWrapper, Modal } from "./styled";
import Form from "./Form";
import { useMilestone } from "../../../context/MilestoneContext";
import { deliverableReducer, initialState } from "./deliverable-popup.reducer";
import { ActionTypes } from "./action.types";
import { useQuery } from "react-query";
import { getSubmissionData } from "../../../../../api";
import AnimateLoader from "../../../../../../../common/components/AnimateLoader";

export const GET_API_SUBMISSION_KEY = "GET_API_SUBMISSION_KEY";

function DeliverablePopup() {
  const {
    isVisibleDeliverableModal,
    deliverableModalState,
    toggleDeliverableModalHandler,
  } = useMilestone();

  const [modalState, eventDispatch] = React.useReducer(
    deliverableReducer,
    initialState
  );

  const submissionId = modalState.submissionId;

  // Set modal initial state according to the selected cell values
  React.useEffect(() => {
    if (!isVisibleDeliverableModal) return;

    const initDispatch = (payload) => {
      eventDispatch({
        type: ActionTypes.INITIAL_STATE,
        payload,
      });
    };

    initDispatch({
      modalState: deliverableModalState,
    });

    return () => {
      eventDispatch({
        type: ActionTypes.STATE_RESET,
      });
    };
  }, [deliverableModalState, isVisibleDeliverableModal]);

  // Whether Submission API calling or not.
  const isEnabled = !!submissionId;

  useQuery({
    queryKey: [GET_API_SUBMISSION_KEY, submissionId],
    queryFn: submissionQueryFn,
    enabled: isEnabled,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    onSuccess: submissionOnSuccess,
  });

  // Submission API function
  function submissionQueryFn() {
    eventDispatch({
      type: ActionTypes.SET_API_INITIAL_STATE,
      payload: { isLoading: true },
    });

    return getSubmissionData(submissionId);
  }

  // Submission API success function
  function submissionOnSuccess(data) {
    const dispatch = (payload) => {
      eventDispatch({
        type: ActionTypes.SET_API_INITIAL_STATE,
        payload,
      });
    };

    if (!data) {
      dispatch({
        isLoading: false,
        response: null,
      });

      return;
    }

    dispatch({
      isLoading: false,
      response: data,
    });
  }

  if (!isVisibleDeliverableModal) return null;

  return (
    <Modal
      visibility={isVisibleDeliverableModal}
      onClose={() => toggleDeliverableModalHandler(false)}
      bodyComponent={(props) =>
        !modalState.isLoading ? (
          <Form {...props} state={modalState} dispatch={eventDispatch} />
        ) : (
          <LoaderWrapper>
            <AnimateLoader />
          </LoaderWrapper>
        )
      }
    />
  );
}

export default DeliverablePopup;
