const config = {
  id: "milestone_tracker",
  menuIcon: "menu-scheduler-tool-01",
  label: "Milestone Tracker",
  path: "/milestone",
  children: {
    tracker: {
      id: "tracker",
      path: "",
      label: "Milestone Tracker",
    },
  },
};

export default config;
