import * as React from "react";
import { memo } from "react";
const SvgStatusDone = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3835 5.37709C15.8858 4.80663 16.1905 4.05794 16.1905 3.2381C16.1905 1.44974 14.7407 0 12.9524 0C11.9539 0 11.0609 0.451965 10.4669 1.16248C9.71683 0.932996 8.92046 0.809521 8.09524 0.809521C3.62436 0.809521 0 4.43388 0 8.90476C0 13.3756 3.62436 17 8.09524 17C12.5661 17 16.1905 13.3756 16.1905 8.90476C16.1905 7.64019 15.9005 6.44336 15.3835 5.37709ZM10.0145 1.8745C9.82186 2.28893 9.71428 2.75095 9.71428 3.2381C9.71428 5.02645 11.164 6.47619 12.9524 6.47619C13.6171 6.47619 14.235 6.27592 14.7491 5.93243C15.1551 6.84011 15.381 7.84606 15.381 8.90476C15.381 12.9285 12.119 16.1905 8.09524 16.1905C4.07145 16.1905 0.809524 12.9285 0.809524 8.90476C0.809524 4.88097 4.07145 1.61904 8.09524 1.61904C8.75952 1.61904 9.40304 1.70795 10.0145 1.8745ZM14.667 1.87707C14.8093 2.0193 14.8094 2.24995 14.6671 2.39225L12.9599 4.10033C12.8916 4.16867 12.7989 4.20708 12.7023 4.20709C12.6056 4.2071 12.513 4.16872 12.4446 4.10039L11.5906 3.24635C11.4483 3.10409 11.4483 2.87344 11.5906 2.73117C11.7328 2.58891 11.9635 2.58891 12.1057 2.73117L12.7022 3.32757L14.1518 1.87719C14.2941 1.7349 14.5247 1.73484 14.667 1.87707ZM3.23808 8.33809L12.1428 6.47619L8.53561 12.1234L6.28837 10.5044L6.84776 11.1156L4.90491 12.2335V9.64304L3.23808 8.33809ZM6.42682 10.1417L11.3455 7.01453L5.4748 9.87215V11.1771L5.9411 10.8768L6.42682 10.1417Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgStatusDone);
export default Memo;
