import styled from "styled-components";

export const Circle = styled.div`
  width: var(--layout-size-24);
  height: var(--layout-size-24);
  min-width: var(--layout-size-24);
  min-height: var(--layout-size-24);
  background-color: ${({$color}) => $color};
  border-radius: 50%;
`;
