import styled from "styled-components";


export const OuterContainer = styled.section`
  min-height: inherit;
  background-color: var(--layout-background-n10);
  height: calc(100vh - var(--nav-height));
`;

export const Container = styled.div`
  height: inherit;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
`;