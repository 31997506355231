import * as React from "react";
import { memo } from "react";
const SvgMenu = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 5 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 13.5647C1 14.3329 1.68568 15 2.5 15C3.31432 15 4 14.3531 4 13.5647C4 12.7965 3.31432 12.1294 2.5 12.1294C1.68568 12.1496 1 12.7965 1 13.5647Z"
      fill="currentColor"
    />
    <path
      d="M1 7.5C1 8.26823 1.68568 8.9353 2.5 8.9353C3.31432 8.9353 4 8.28843 4 7.5C4 6.73177 3.31432 6.0647 2.5 6.0647C1.68568 6.08491 1 6.73179 1 7.5Z"
      fill="currentColor"
    />
    <path
      d="M1 1.4353C1 2.20353 1.68568 2.87059 2.5 2.87059C3.31432 2.87059 4 2.22373 4 1.4353C4 0.667067 3.31432 0 2.5 0C1.68568 0.0202022 1 0.667084 1 1.4353Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgMenu);
export default Memo;
