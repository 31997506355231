import * as React from "react";
import { memo } from "react";
const SvgSort = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.88873 5H0L4.44472 11.2222L8.88873 5ZM7.10986 11.2222H16L11.5553 5L7.10986 11.2222Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgSort);
export default Memo;
