import * as React from "react";
import { memo } from "react";
const Svg02 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.6354 2.44223H12.5403V1.33066C12.5403 0.589595 11.9339 0.00012207 11.2094 0.00012207H2.33094C1.58965 0.00012207 1 0.606423 1 1.33066V12.2107C1 12.9517 1.60648 13.5412 2.33094 13.5412H3.44284V14.6528C3.44284 15.3938 4.04932 16.0001 4.79061 16.0001H13.6522C14.3935 16.0001 15 15.3938 15 14.6528V3.78961C14.9832 3.04854 14.3767 2.44224 13.6354 2.44224L13.6354 2.44223ZM3.44287 3.78959V12.8506H2.33097C1.97719 12.8506 1.69075 12.5644 1.69075 12.2106V1.33062C1.69075 0.976949 1.97712 0.690592 2.33097 0.690592H11.1926C11.5464 0.690592 11.8328 0.976879 11.8328 1.33062V2.44218H4.79068C4.04939 2.44218 3.44291 3.04848 3.44291 3.78955L3.44287 3.78959ZM14.2924 14.6527C14.2924 15.0064 14.0061 15.2928 13.6522 15.2928H4.79061C4.43684 15.2928 4.15039 15.0065 4.15039 14.6527V3.78959C4.15039 3.43592 4.43677 3.14956 4.79061 3.14956H13.6522C14.006 3.14956 14.2924 3.43585 14.2924 3.78959V14.6527Z"
      fill="currentColor"
    />
    <path
      d="M6.42482 5.03591C6.23951 5.03591 6.07104 5.1875 6.07104 5.38958C6.07104 5.57483 6.22268 5.74325 6.42482 5.74325H12.0012C12.1865 5.74325 12.355 5.59165 12.355 5.38958C12.3551 5.1875 12.2034 5.03591 12.0013 5.03591H6.42482Z"
      fill="currentColor"
    />
    <path
      d="M6.42482 7.59591C6.23951 7.59591 6.07104 7.7475 6.07104 7.94957C6.07104 8.15165 6.22268 8.30325 6.42482 8.30325H12.0012C12.1865 8.30325 12.355 8.15165 12.355 7.94957C12.3551 7.7475 12.2034 7.59591 12.0013 7.59591H6.42482Z"
      fill="currentColor"
    />
    <path
      d="M6.42482 10.1559C6.23951 10.1559 6.07104 10.3075 6.07104 10.5096C6.07104 10.6948 6.22268 10.8632 6.42482 10.8632H12.0012C12.1865 10.8632 12.355 10.7116 12.355 10.5096C12.3551 10.3074 12.2034 10.1559 12.0013 10.1559H6.42482Z"
      fill="currentColor"
    />
    <path
      d="M6.42482 12.7159C6.23951 12.7159 6.07104 12.8675 6.07104 13.0696C6.07104 13.2548 6.22268 13.4232 6.42482 13.4232H12.0012C12.1865 13.4232 12.355 13.2716 12.355 13.0696C12.3551 12.8675 12.2034 12.7159 12.0013 12.7159H6.42482Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg02);
export default Memo;
