import styled from "styled-components";

export const Container = styled.div`
  --layout-height: calc(100vh - var(--nav-height));
  --layout-bg-color: var(--layout-background-n10);

  display: flex;
  flex-direction: column;
  min-height: var(--layout-height);
  max-height: var(--layout-height);
  height: var(--layout-height);
  background-color: var(--layout-bg-color);
  position: relative;
  overflow: hidden;

  .layout__header {
    background-color: var(--layout-background-white);
    /* padding-inline: var(--layout-spacing-24); */
    /* position: sticky;
    top: 0;
    left: 0; */
  }

  .layout__body {
    min-height: 0;
    flex: 1;
    display: flex;
    overflow: hidden;
    /* flex-grow: 1; */
    position: relative;

    .mask {
      background-color: transparent;
    }
  }
`;
