export const getFileStreamType = (fileName) => {
  let streamType = window.TXTextControl.streamType.PlainText;
  switch (fileName.split(".").pop().toLowerCase()) {
    case "doc":
      streamType = window.TXTextControl.streamType.MSWord;
      break;
    case "docx":
      streamType = window.TXTextControl.streamType.WordprocessingML;
      break;
    case "rtf":
      streamType = window.TXTextControl.streamType.RichTextFormat;
      break;
    case "htm":
      streamType = window.TXTextControl.streamType.HTMLFormat;
      break;
    case "tx":
      streamType = window.TXTextControl.streamType.InternalUnicodeFormat;
      break;
    case "pdf":
      streamType = window.TXTextControl.streamType.AdobePDF;
      break;
    default:
      streamType = window.TXTextControl.streamType.WordprocessingML;
      break;
  }
  return streamType;
};

export const getMimeType = (fileType) => {
  switch (fileType) {
    case "pdf":
      return "application/pdf";
    case "doc":
      return "application/msword";
    case "docx":
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    default:
      return "";
  }
};

export const base64ToUint8Array = (base64) => {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

export const base64ToArrayBuffer = (base64) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes.buffer;
};
