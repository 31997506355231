import styled from "styled-components";

export const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--layout-spacing-8, 8px);
`;

export const FieldItem = styled.div`
  flex: 3 1 auto;
`;
