import React from "react";
import { OverlayTrigger } from "react-bootstrap";
import { IconCheckSm } from "../../../../../common/components/icons";
import useSystemStatus from "../../../../../hooks/useSystemStatus";
import { StyledStatusIconWrapper, StyledTooltip } from "./styled";

const OnboardingStatusIcon = (props) => {
  const { status = 1 } = props;

  //
  const { getOnboardingStatus } = useSystemStatus();

  const data = React.useMemo(() => {
    return getOnboardingStatus(status);
  }, [status, getOnboardingStatus]);

  const { color, icon: Icon, label } = data;

  if (!label) {
    return null;
  }

  return (
    <StyledStatusIconWrapper statusColor={color}>
      <div className="top__circle">
        <IconCheckSm />
      </div>

      <OverlayTrigger
        // defaultShow={true}
        key={"status_tooltip"}
        placement={"top-start"}
        overlay={<StyledTooltip id={`status-tooltip`}>{label}</StyledTooltip>}
      >
        <div className="icon__wr">
          <Icon />
        </div>
      </OverlayTrigger>
    </StyledStatusIconWrapper>
  );
};

export default OnboardingStatusIcon;
