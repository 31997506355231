import React from "react";
import { styleClass } from "../../../utility/common";
import ActionButton from "../Buttons/ActionButton";
import "./styles.scss";

const ChipCard = (props) => {
  const { children, title, onEdit, onDelete } = props;

  return (
    <div className="chip__card">
      <div className="chip__card--header">
        <div className="title">{title}</div>
        <div className="actions">
          {onEdit ? <ActionButton variant="EDIT" onClick={onEdit} /> : null}

          {onDelete ? (
            <ActionButton variant="DELETE" onClick={onDelete} />
          ) : null}
        </div>
      </div>
      <div className="chip__card--body">{children}</div>
    </div>
  );
};

ChipCard.Wrapper = ({ children, className }) => {
  return (
    <div className={styleClass("chip__card__wrapper", className)}>
      {children}
    </div>
  );
};

export default ChipCard;
