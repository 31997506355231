import React from "react";
import { getAssignTemplatesByProjectId } from "../Actions/Setting/Templates";
import { useGlobalStore } from "../store/GlobalStore";

export const TEMPLATE_DOC_TYPES = {
  NDA: 1,
  Contract: 2,
  Supplies: 3,
  Deliveries: 4,
};

const useDefaultTemplateDoc = ({ templateType }) => {
  const [defaultTemplate, setDefaultTemplate] = React.useState(undefined);
  const [templateId, setTemplateId] = React.useState(undefined);
  const [isLoading, setIsLoading] = React.useState(undefined);

  const projectId = useGlobalStore((state) => state.selectedProjectId);

  React.useEffect(() => {
    if (!projectId || !templateType) return;

    setIsLoading(true);

    getAssignTemplatesByProjectId(projectId)
      .then((data) => {
        setIsLoading(false);

        const value = data
          .filter((d) => d?.templateTypeId === templateType)
          .find((d) => !!d?.defaultTemplate);

        setTemplateId(value?.templateId || null);
        setDefaultTemplate(value || null);

        setTimeout(() => {
          setIsLoading(undefined);
        }, 100);
      })
      .catch(() => {
        setIsLoading(undefined);
        setTemplateId(undefined);
        setDefaultTemplate(null);
      });
  }, [projectId, templateType]);

  return { defaultTemplate, isLoading, templateId };
};

export default useDefaultTemplateDoc;
