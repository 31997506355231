import React from "react";
import { Wrapper } from "./styled";
import ViewDue from "./components/ViewDue";

const Filters = () => {
  return (
    <Wrapper>
      <ViewDue />
    </Wrapper>
  );
};

export default Filters;
