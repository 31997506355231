import DocumentViewer from "@txtextcontrol/tx-react-ds-document-viewer";
import React from "react";
import { DocumentViewerContainer } from "./styled";
import useUserData from "../../../../../../hooks/useUserData";
import { useAppStore } from "../../../../../../store/AppStore/AppStore";

const TextControllerViewer = ({
  documentName,
  documentUrl,
  documentData,
  submitSignature,
  member,
}) => {
  const USER = useUserData();
  const showLoader = useAppStore((state) => state.showLoader);
  // const hideLoader = useAppStore((state) => state.hideLoader);
  const [wrapperHeight, setWrapperHeight] = React.useState(null);
  const [docData, setDocData] = React.useState({
    base64Data: null,
    documentName: null,
  });
  const [viewerAttributes, setViewerAttributes] = React.useState({
    isLoaded: false,
  });

  const signatureSettings = React.useMemo(
    () => ({
      showSignatureBar: true,
      ownerName: member.givenName,
      signerName: member.fullName || "",
      signerInitials: member.nickName || "",
      redirectUrlAfterSignature: null,
      signatureBoxes: [{ name: "txSign", signingRequired: true, style: 0 }],
    }),
    [member]
  );

  const getDocumentData = React.useCallback(async () => {
    // const base64TemplateData = await getBase64TemplateDocById(1);
    // const documentDetails = await getTemplateDetailsById(1);
    setDocData((prevState) => ({
      ...prevState,
      base64Data: documentData,
      documentName: documentName,
    }));
  }, [documentData, documentName]);

  React.useEffect(() => {
    const containerBody = document.getElementById("containerBody");
    if (containerBody) {
      setWrapperHeight(containerBody.clientHeight + "px");
    }
    getDocumentData();
  }, [getDocumentData]);

  React.useEffect(() => {
    if (viewerAttributes.isLoaded) {
      window.TXDocumentViewer.loadDocument(
        docData.base64Data,
        docData.documentName,
        signatureSettings
      );
    }
  }, [docData, signatureSettings, viewerAttributes]);

  const handleDocumentViwerLoad = () => {
    window.addEventListener("documentViewerLoaded", () => {
      setViewerAttributes((prevState) => ({
        ...prevState,
        isLoaded: true,
      }));

      window.TXDocumentViewer.addEventListener("signaturesCompleted", () => {
        console.log("Signature Completed...!");
        window.TXDocumentViewer.signatures.setBeforeSubmitCallback(
          (callback) => {
            showLoader("Submitting...");
          }
        );
        window.TXDocumentViewer.signatures.setSubmitCallback((callback) => {
          submitSignature(callback);
        });
      });
      console.log("TXDocumentViewer: ", window.TXDocumentViewer);
    });
  };

  return (
    <DocumentViewerContainer>
      <DocumentViewer
        width="100%"
        height={wrapperHeight}
        serviceURL={process.env.REACT_APP_TXTEXTCONTROLLER_SERVICE_URL}
        clientId={process.env.REACT_APP_TXTEXTCONTROLLER_DOCVIEWER_CLIENT_ID}
        clientSecret={
          process.env.REACT_APP_TXTEXTCONTROLLER_DOCVIEWER_CLIENT_SECRET
        }
        onLoad={handleDocumentViwerLoad}
        toolbarDocked={false}
        userNames={[USER.userInfo.email]}
      ></DocumentViewer>
    </DocumentViewerContainer>
  );
};

export default TextControllerViewer;
