import * as React from "react";
import { memo } from "react";
const SvgAdd1 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_958_1693)">
      <path
        d="M8 0C7.64652 0 7.36005 0.286487 7.36005 0.639955V7.36005H0.639955C0.286474 7.36005 0 7.64653 0 8C0 8.35347 0.286487 8.63995 0.639955 8.63995H7.36005V15.36C7.36005 15.7135 7.64653 16 8 16C8.35347 16 8.63995 15.7135 8.63995 15.36V8.63995H15.36C15.7135 8.63995 16 8.35347 16 8C16 7.64653 15.7135 7.36005 15.36 7.36005H8.63995V0.639955C8.63995 0.286474 8.35347 0 8 0Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_958_1693">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgAdd1);
export default Memo;
