import * as React from "react";
import { memo } from "react";
const SvgProfile01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.8251 0H2.19178C0.977872 0 0 0.994731 0 2.19178V13.8082C0 15.0221 0.994731 16 2.19178 16H13.8082C15.0221 16 16 15.0053 16 13.8082V2.19178C16.0169 0.994731 15.0221 0 13.8251 0ZM14.4826 13.8251C14.4826 14.196 14.1791 14.4995 13.8082 14.4995H12.392V14.4152C12.392 11.9874 10.4194 10.0148 7.99157 10.0148C5.56375 10.0148 3.59115 11.9874 3.59115 14.4152V14.4826H2.17492C1.804 14.4826 1.50053 14.1791 1.50053 13.8082V2.19178C1.50053 1.82086 1.804 1.51739 2.17492 1.51739H13.7914C14.1623 1.51739 14.4658 1.82086 14.4658 2.19178V13.8251H14.4826Z"
      fill="currentColor"
    />
    <path
      d="M8.00843 9.12118C9.48895 9.12118 10.6891 7.92098 10.6891 6.44046C10.6891 4.95994 9.48895 3.75975 8.00843 3.75975C6.52791 3.75975 5.32771 4.95994 5.32771 6.44046C5.32771 7.92098 6.52791 9.12118 8.00843 9.12118Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgProfile01);
export default Memo;
