import styled from "styled-components";
import Popover from "../../../../../../common/components/Popover";
import { layoutBoxShadow } from "../../../../../../styled/utility";
import { fontStyle } from "../../../../../../styled/utility";

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  border-radius: var(--layout-border-radius-4);
  background-color: var(--layout-background-accent);
  padding: var(--layout-spacing-4) var(--layout-spacing-8);
  transition: var(--animate-1);
  position: absolute;
  bottom: var(--layout-spacing-4);
  right: var(--layout-spacing-4);
  width: var(--layout-size-60);

  ${({ isVisible }) => {
    if (!isVisible) {
      return `
        visibility: hidden;
        z-index:-1;
      `;
    } else {
      return `
        visibility: visible;
        z-index:0;
       `;
    }
  }};

  &:hover {
    background-color: var(--layout-background-n30);
  }

  .icon {
    --icon-size: var(--layout-size-16);
    --icon-font-size: var(--layout-size-16);
  }
`;

export const ActionDialog = styled(Popover)`
  /* box-shadow: ${layoutBoxShadow("primary-5")}; */
  box-shadow: 0 8px 18px #2d59660d;
  padding: var(--layout-spacing-12);
  background-color: var(--layout-background-white);
  border-radius: var(--layout-border-radius-4);
  min-width: 100px;

  .inner {
    display: flex;
    flex-direction: column;
    gap: var(--layout-spacing-4);

    .option {
      outline: none;
      border: none;
      color: var(--font-tint-04);
      ${fontStyle("x-small-regular")};
      padding: var(--layout-spacing-4);
      border-radius: var(--layout-border-radius-4);
      white-space: nowrap;
      background-color: transparent;
      text-align: left;

      &:hover {
        color: var(--font-color-shade-primary);
        ${fontStyle("x-small-medium")};
        background-color: var(--layout-background-accent);
      }
    }
  }
`;
