import styled from "styled-components";

export const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: inherit;
  background-color: var(--layout-background-n10);
  position: relative;
  height: calc(100vh - var(--nav-height));

  .content__wr,
  .toolbar__wr {
    display: block;
  }

  .content__wr {
    margin-top: var(--layout-spacing-12);
    padding-bottom: var(--layout-spacing-16);
    padding-inline: var(--layout-spacing-16);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    min-height: 0;
  }

  .inner__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    /* overflow: hide; */
    min-height: 0;
  }

  .web__container {
    min-height: 100%;
  }
`;

export const BackgroundLayout = styled.section`
  min-height: inherit;
  background-color: var(--layout-background-n30);
  position: relative;
`;
