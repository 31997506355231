export class TagModel {
  constructor() {
    this.__typeId = null;
    this.__typeName = null;
    this.__typePluralName = null;
    this.__icon = null;
    this.__order = null;
  }

  id(val) {
    if (val === undefined) return this.__typeId;

    this.__typeId = val;
  }

  name(val) {
    if (val === undefined) return this.__typeName;

    this.__typeName = val;
  }

  pluralName(val) {
    if (val === undefined) return this.__typePluralName;

    this.__typePluralName = val;
  }

  icon(val) {
    if (val === undefined) return this.__icon;

    this.__icon = val;
  }

  order(val) {
    if (val === undefined) return this.__order;

    this.__order = val;
  }
}
