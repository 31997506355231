import styled, { css } from "styled-components";
import { fontStyle } from "../../../../styled/utility";

const elementStylesSmall = css`
  --element-size: var(--layout-size-16);
  --radio-inner-circle-size: var(--layout-size-8);
  --tick-icon-size: var(--layout-size-8);
`;

const elementStylesMd = css`
  --element-size: var(--layout-size-20);
  --radio-inner-circle-size: var(--layout-size-12);
  --tick-icon-size: var(--layout-size-12);
`;

const elementStylesLg = css`
  --element-size: var(--layout-size-24);
  --radio-inner-circle-size: var(--layout-size-16);
  --tick-icon-size: var(--layout-size-12);
`;

export const Wrapper = styled.label`
  --element-bg-color: transparent;
  --border-color: var(--font-tint-03);

  display: inline-flex;
  align-items: center;
  gap: var(--layout-spacing-8);
  cursor: ${({ isDisabled }) => (isDisabled ? "auto" : "pointer")};
  min-width: 0px;
  user-select: none;

  input[type="radio"],
  input[type="checkbox"] {
    width: 0;
    height: 0;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: -100vw;
    bottom: 0;

    &:checked {
      & + .element-checkbox {
        --element-bg-color: #3abec2;
        --border-color: #3abec2;

        .icon {
          opacity: 1;
        }
      }

      & + .element-radio {
        --element-bg-color: #3abec2;
        --border-color: #3abec2;

        &::before {
          opacity: 1;
        }
      }
    }

    &:disabled {
      & + .element,
      & + .element + .element__text {
        opacity: 0.5;
      }
    }
  }

  .element {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--layout-border-radius-4);
    border: 1px solid var(--border-color);
    width: var(--element-size);
    height: var(--element-size);
    min-width: var(--element-size);
    min-height: var(--element-size);
    background-color: var(--element-bg-color);

    .icon {
      --icon-size: var(--tick-icon-size);
      --icon-font-size: var(--tick-icon-size);
      opacity: 0;
      color: var(--layout-background-white);
    }

    &-radio {
      border-radius: 50%;

      &::before {
        content: "";
        display: block;
        position: absolute;
        border-radius: 50%;
        background-color: var(--layout-background-white);
        width: var(--radio-inner-circle-size);
        height: var(--radio-inner-circle-size);
        opacity: 0;
      }
    }

    ${({ size }) => {
      switch (size) {
        case "md":
          return elementStylesMd;

        case "lg":
          return elementStylesLg;

        default:
          return elementStylesSmall;
      }
    }}
  }

  .element__text {
    ${({ size }) => {
      switch (size) {
        case "md":
          return `${fontStyle("large-regular")}`;

        case "lg":
          return `${fontStyle("small-regular")}`;

        default:
          return `${fontStyle("x-small-regular")}`;
      }
    }};

    display: inline-flex;
    line-height: 1;
    color: var(--font-default-black);
  }

  .element__right {
    display: flex;
    align-items: center;
  }
`;
