import React from "react";
import { buildColumn } from "../column.helper";
import { useMilestone } from "../../../context/MilestoneContext";

const useColumns = ({ columnHelper }) => {
  const { sectionHeader = [] } = useMilestone();

  const columns = React.useMemo(() => {
    return sectionHeader.map((col) => {
      return columnHelper.accessor(String(col.key), buildColumn(col));
    });
  }, [columnHelper, sectionHeader]);

  return { columns };
};

export default useColumns;
