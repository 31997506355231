import React from "react";
import { entityLockRequest, entityLockRelease } from "../api/entity_lock";
import useUserData from "./useUserData";

const useEntityLock = ({ lockTypeId }) => {
  const { userId } = useUserData();

  const onLockRequest = React.useCallback(
    (entityId) => {
      return new Promise((resolve) => {
        entityLockRequest(lockTypeId, entityId, userId)
          .then((res) => {
            if (!res?.isSuccess) {
              resolve(null);
            }

            resolve(res?.result);
          })
          .catch((err) => {
            resolve(null);
          });
      });
    },
    [lockTypeId, userId]
  );

  const onLockRelease = React.useCallback(
    (entityId) => {
      return new Promise((resolve) => {
        entityLockRelease(lockTypeId, entityId, userId)
          .then((res) => {
            if (!res?.isSuccess) {
              resolve(null);
            }

            resolve(res?.result);
          })
          .catch((err) => {
            resolve(null);
          });
      });
    },
    [lockTypeId, userId]
  );

  return { onLockRequest, onLockRelease };
};

export default useEntityLock;
