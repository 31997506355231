import BreadcrumbLink from "../../../components/ui-components/Breadcrumb/BreadcrumbLink";
import { ChildrenRoutes } from "./childRoutes";
import Dashboard from "..";
import config from "./config";
import { permissionLoader } from "../../../routes/loaders";
// import { permissionLoader } from "../../../routes/loaders";

export const DashboardRoutes = {
  id: config.id,
  path: config.path,
  element: <Dashboard />,
  handle: {
    crumb: () => <BreadcrumbLink label={config.label} to={config.path} />,
  },
  children: ChildrenRoutes,
  loader: async () => {
    // return true;
    // return await permissionLoader(ROUTE_OPTIONS.dashboard.id);
    return await permissionLoader(config.id, config.id);
  },
};


export const DashboardPaths = config;