import GenerateTemplate from "../../components/TXTextController/components/GenerateTemplate";

const CreateTemplate = ({ mergeData }) => {
  const testingMergeData = mergeData || [
    {
      Id: 1,
      Name: "Robbert Knox",
      Age: 26,
      Company: {
        name: "Futura Tech Labs",
        country: "Sri Lanka",
        branch: {
          name: "Futura 2.0",
          location: "Singapore",
        },
      },
      Country: "Sri Lanka",
    },
  ];
  return (
    <>
      <GenerateTemplate isEditMode={false} mergeData={testingMergeData} />
    </>
  );
};

export default CreateTemplate;
