import styled from "styled-components";

export const Container = styled.div`
  .chips__container {
    margin-top: var(--layout-spacing-16);
    display: flex;
    flex-wrap: wrap;
    gap: var(--layout-spacing-8);
  }

  .chips__row {
    display: flex;
    gap: var(--layout-spacing-8);
    flex-wrap: nowrap;
  }
`;
