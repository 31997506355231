import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.1688 11.2859C9.95447 11.3037 9.75792 11.1969 9.56145 11.0902C7.75736 10.0401 6.22121 8.54512 5.14947 6.76536C4.953 6.46283 4.77438 6.10688 4.84584 5.75092C4.97085 5.07458 5.82827 4.80762 6.07835 4.16694C6.31059 3.61523 6.00689 2.99231 5.64964 2.51176C5.06016 1.71087 4.30997 1.01676 3.45258 0.500631C3.04172 0.251509 2.59516 0.0379081 2.11291 0.00232775C-0.0484257 -0.104446 -0.173462 3.49066 0.112335 4.89667C0.415964 6.35604 1.14838 7.70869 2.00574 8.93672C4.02417 11.8555 6.79283 14.3294 10.1509 15.504C11.3298 15.9134 12.5981 16.1625 13.8127 15.8778C14.42 15.7354 14.9916 15.4684 15.4203 15.0057C15.849 14.5607 16.0812 13.92 15.974 13.3149C15.8669 12.692 15.4382 12.1936 15.0094 11.7487C14.3843 11.108 13.6876 10.5384 12.9374 10.0401C12.7945 9.95116 12.6516 9.86217 12.4909 9.84439C12.3122 9.8266 12.1515 9.91559 11.9907 10.0046C11.5441 10.2538 11.1333 10.5563 10.776 10.8945C10.6153 11.0547 10.4366 11.2148 10.2223 11.2504C10.2045 11.2683 10.1866 11.2859 10.1688 11.2859Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
