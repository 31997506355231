import React from 'react'
import * as Styled from "./styled"

function InfoBlock(props) {
  const {title, content} = props;

  return (
    <Styled.BlockContainer>
        <div className="title">{title}</div>
        <div className="content">{content}</div>
    </Styled.BlockContainer>
  )
}

export default InfoBlock