import React from "react";

import MyProfile from "../../components/pages/settings/myProfile";
import ProjectSetup from "../../components/pages/settings/project/setups";
import SystemClient from "../../components/pages/settings/system-settings/client";
import Setup from "../../components/pages/settings/system-settings/common/setup";
import SystemCompany from "../../components/pages/settings/system-settings/company";
import SystemGenerals from "../../components/pages/settings/SystemGenerals";
import SystemRights from "../../components/pages/settings/SystemRights";

import TanentClient from "../../components/pages/settings/tanent/client";
import TanentCompany from "../../components/pages/settings/tanent/company";
import TanentProject from "../../components/pages/settings/tanent/project";
import Permission from "../../components/pages/settings/tanent/Permission";

import GeneralTemplateSettings from "../../components/pages/settings/templates/genaral-settings";

import BreadcrumbLink from "../../components/ui-components/Breadcrumb/BreadcrumbLink";
import { ROUTE_OPTIONS } from "../../constant/route-options";
import { permissionLoader } from "../loaders";

const SettingChild = ROUTE_OPTIONS.setting.children;

const key = "tenant-settings";

export const settingRoutes = [
  {
    id: SettingChild["system-rights"].id,
    path: SettingChild["system-rights"].path,
    element: <SystemRights />,
    handle: {
      crumb: () => (
        <BreadcrumbLink label={SettingChild["system-rights"].label} />
      ),
    },

    loader: async () => {
      return await permissionLoader(key, SettingChild["system-rights"].id);
    },
  },

  {
    id: SettingChild["system-generals"].id,
    path: SettingChild["system-generals"].path,
    element: <SystemGenerals />,
    handle: {
      crumb: () => (
        <BreadcrumbLink label={SettingChild["system-generals"].label} />
      ),
    },
    loader: async () => {
      return await permissionLoader(key, SettingChild["system-generals"].id);
    },
  },

  {
    id: SettingChild["data-setup"].id,
    path: SettingChild["data-setup"].path,
    element: <Setup />,
    loader: async () => {
      return await permissionLoader(key, SettingChild["data-setup"].id);
    },
    handle: {
      crumb: () => <BreadcrumbLink label={SettingChild["data-setup"].label} />,
    },
  },

  {
    id: SettingChild["system-client"].id,
    path: SettingChild["system-client"].path,
    element: <SystemClient />,
    handle: {
      crumb: () => (
        <BreadcrumbLink label={SettingChild["system-client"].label} />
      ),
    },
    loader: async () => {
      return await permissionLoader(key, SettingChild["system-client"].id);
    },
  },

  {
    id: SettingChild["project-setup"].id,
    path: SettingChild["project-setup"].path,
    element: <ProjectSetup />,
    handle: {
      crumb: () => (
        <BreadcrumbLink label={SettingChild["project-setup"].label} />
      ),
    },
    loader: async () => {
      return await permissionLoader(key, SettingChild["project-setup"].id);
    },
  },

  {
    id: SettingChild["system-company"].id,
    path: SettingChild["system-company"].path,
    element: <SystemCompany />,
    handle: {
      crumb: () => (
        <BreadcrumbLink label={SettingChild["system-company"].label} />
      ),
    },
    loader: async () => {
      return await permissionLoader(key, SettingChild["system-company"].id);
    },
  },

  {
    id: SettingChild["my-profile"].id,
    path: SettingChild["my-profile"].path,
    element: <MyProfile />,
    handle: {
      crumb: () => <BreadcrumbLink label={SettingChild["my-profile"].label} />,
    },
    loader: async () => {
      return await permissionLoader(key, SettingChild["my-profile"].id);
    },
  },

  {
    id: SettingChild["tenant-permission"].id,
    path: SettingChild["tenant-permission"].path,
    element: <Permission />,
    handle: {
      crumb: () => (
        <BreadcrumbLink label={SettingChild["tenant-permission"].label} />
      ),
    },
    loader: async () => {
      return await permissionLoader(key, SettingChild["tenant-permission"].id);
    },
  },

  {
    id: SettingChild["tenant-client"].id,
    path: SettingChild["tenant-client"].path,
    element: <TanentClient />,
    handle: {
      crumb: () => (
        <BreadcrumbLink label={SettingChild["tenant-client"].label} />
      ),
    },
    loader: async () => {
      return await permissionLoader(key, SettingChild["tenant-client"].id);
    },
  },

  {
    id: SettingChild["tenant-company"].id,
    path: SettingChild["tenant-company"].path,
    element: <TanentCompany />,
    handle: {
      crumb: () => (
        <BreadcrumbLink label={SettingChild["tenant-company"].label} />
      ),
    },
    loader: async () => {
      return await permissionLoader(key, SettingChild["tenant-company"].id);
    },
  },

  {
    id: SettingChild["tenant-project"].id,
    path: SettingChild["tenant-project"].path,
    element: <TanentProject />,
    handle: {
      crumb: () => (
        <BreadcrumbLink label={SettingChild["tenant-project"].label} />
      ),
    },
    loader: async () => {
      return await permissionLoader(key, SettingChild["tenant-project"].id);
    },
  },

  {
    id: SettingChild["template-general-settings"].id,
    path: SettingChild["template-general-settings"].path,
    element: <GeneralTemplateSettings />,
    handle: {
      crumb: () => (
        <BreadcrumbLink
          label={SettingChild["template-general-settings"].label}
        />
      ),
    },
    loader: async () => {
      return await permissionLoader(
        key,
        SettingChild["template-general-settings"].id
      );
    },
  },
];
