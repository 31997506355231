import API_Scheduler from "../../../../services/api-scheduler";
import { ScheduleEndpoints } from "../endpoints";

const calendarDatesBody = {
  projectId: 0,
  episodeId: 0,
  startDate: "",
  endDate: "",
  txDate: "",
  weekStartDay: 0,
  date: "",
  direction: 1,
};

export const getCalendarDates = (body = calendarDatesBody) => {
  return new Promise((resolve, reject) => {
    API_Scheduler.put(`${ScheduleEndpoints.GET_CALENDAR_DATES}`, body)
      .then((res) => {
        console.log("GET_CALENDAR_DATES :::", res);

        resolve(res.data.result);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const getMemberAvailability = (projectId, projectMemberId, date) => {
  return new Promise((resolve, reject) => {
    API_Scheduler.post(`${ScheduleEndpoints.GET_MEMBER_AVAILABILITY}`, {
      projectId: projectId,
      date: date,
      projectMemberId: projectMemberId,
    })
      .then((res) => {
        console.log("GET_MEMBER_AVAILABILITY :::", res);

        resolve(res.data.result);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
