import API_ENDPOINTS from "../../../../../../services/api-endpoints";
import API_Template from "../../../../../../services/api-template";
import TextControllerEditor from "../TextControllerEditor";
import React from "react";
import { getBase64TemplateDocById } from "../../../../../../Actions/Templates";
import {
  fileUploadToServers,
  getTemplateDetailsById,
  mergeDocumentData,
  newDocument,
  readDocument,
  refreshDocumentEditorLayout,
} from "../../services/TXTextController";
import { ControlWrapper } from "./styled/styled";
import { useAppStore } from "../../../../../../store/AppStore/AppStore";
import { useNavigate } from "react-router-dom";

const FileExtention = ".docx";
const returnFormat = "DOCX";

const GenerateTemplate = ({
  templateId,
  templateTypeId,
  templateName,
  isEditMode,
  mergeData,
}) => {
  const [templateState, setTemplateState] = React.useState({
    templateId: 0,
    allTemplateTypes: [],
    show: false,
    templateTypeId: 0,
    templateName: "",
    isEditMode: isEditMode,
    isSaved: false,
    isSaveAndClosed: false,
  });
  const [editorState, setEditorState] = React.useState({
    isEditorLoaded: false,
  });
  const templateStateRef = React.useRef(templateState);
  const editorStateRef = React.useRef(editorState);
  const [saveDoc, setSaveDoc] = React.useState(false);
  const [updateDoc, setUpdateDoc] = React.useState(false);
  const isOpenSideMenu = useAppStore((state) => state.isOpenSideMenu);
  const navigate = useNavigate();

  const getTemplateTypeList = React.useCallback(async () => {
    try {
      const endpoints = new API_ENDPOINTS();
      const response = await API_Template.get(
        endpoints.TEMPLATE_GET_TEMPLATE_TYPE
      );

      if (response.data.isSuccess === true) {
        console.log("Template type response: ", response);
        const templateTypeList = response.data.result.map((item) => ({
          templateTypeId: item.globalTemplateTypeId,
          templateTypeName: item.templateTypeName,
        }));

        setTemplateState((prevState) => ({
          ...prevState,
          allTemplateTypes: templateTypeList,
        }));
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleNewDocument = () => {
    setTemplateState((prevState) => ({
      ...prevState,
      templateId: 0,
      templateName: "",
      templateTypeId: 0,
      isEditMode: false,
    }));
    newDocument();
  };

  const handleReadDocument = async (id) => {
    console.log("Load Read Document");
    templateId = id;
    const templateDetails = await getTemplateDetailsById(id);
    console.log("Template details: ", templateDetails);
    setTemplateState((prevState) => ({
      ...prevState,
      templateId: id,
      templateName: templateDetails.templateName,
      templateTypeId: templateDetails.templateTypeId,
      isEditMode: true,
    }));
    try {
      const base64TemplateData = await getBase64TemplateDocById(id);
      readDocument(base64TemplateData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveTemplate = React.useCallback((values) => {
    setTemplateState((prevState) => ({
      ...prevState,
      templateName: values.templateName,
      templateTypeId: values.templateTypeId,
    }));
    setSaveDoc(true);
  }, []);

  const handleUpdateTemplate = React.useCallback(({ close = false }) => {
    setTemplateState((prevState) => ({
      ...prevState,
      isEditMode: true,
      isSaveAndClosed: close,
    }));
    setUpdateDoc(true);
  }, []);

  const handleMergeTemplate = React.useCallback(async () => {
    console.log("Merge Template");
    if (!mergeData || mergeData.length < 1) {
      console.log("Please provide merge data");
      return;
    }
    try {
      const base64TemplateData = await getBase64TemplateDocById(templateId);
      console.log("Merge temp Id: ", templateId);
      mergeDocumentData(base64TemplateData, mergeData, returnFormat);
      setTemplateState((prevState) => ({
        ...prevState,
        isEditMode: false,
      }));
    } catch (error) {
      console.log(error);
    }
  }, [templateId, mergeData]);

  React.useEffect(() => {
    getTemplateTypeList();
    if (isEditMode) {
      setTemplateState((prevState) => ({
        ...prevState,
        templateId: templateId,
        templateTypeId: templateTypeId,
        templateName: templateName,
        isEditMode: isEditMode,
      }));
    }
  }, [
    getTemplateTypeList,
    isEditMode,
    templateId,
    templateName,
    templateTypeId,
  ]);

  React.useEffect(() => {
    if (saveDoc) {
      console.log("On Save Document: ", templateState);
      fileUploadToServers(FileExtention, templateState, setTemplateState);
      setSaveDoc(false);
    }
    if (updateDoc) {
      console.log("On Update Document: ", templateState);
      fileUploadToServers(FileExtention, templateState, setTemplateState).then(
        () => {
          if (templateState.isSaveAndClosed) {
            setTimeout(() => {
              navigate("/templates");
            }, 10);
          }
        }
      );
      setUpdateDoc(false);
    }
  }, [saveDoc, updateDoc, templateState, navigate]);

  React.useEffect(() => {
    console.log("TemplateTypeList: ", templateState.allTemplateTypes);
    templateStateRef.current = templateState;
  }, [templateState]);

  React.useEffect(() => {
    if (editorStateRef.current.isEditorLoaded) {
      setTimeout(() => {
        refreshDocumentEditorLayout();
      }, 300);
    }
  }, [isOpenSideMenu]);

  React.useEffect(() => {
    if (editorState.isEditorLoaded && isEditMode && templateId) {
      handleReadDocument(templateId);
    }
  }, [editorState.isEditorLoaded, isEditMode, templateId]);

  return (
    <ControlWrapper>
      <TextControllerEditor
        templateStateRef={templateStateRef}
        editorStateRef={editorStateRef}
        setEditorState={setEditorState}
        mergeData={mergeData}
        handleNewDoc={handleNewDocument}
        handleRead={handleReadDocument}
        handleSave={handleSaveTemplate}
        handleUpdate={handleUpdateTemplate}
      />
    </ControlWrapper>
  );
};

export default GenerateTemplate;
