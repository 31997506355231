import React from "react";
import FileListItem from "./FileListItem";

export const UnsortedFiles = ({ files }) => {
  return (
    <>
      {files.map((data, i) => {
        return (
          <FileListItem
            key={i}
            fileName={data?.fileName}
            localId={data?.localId}
            isSorted={data?.isSorted}
            mode="edit"
          />
        );
      })}
    </>
  );
};
