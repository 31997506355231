import styled from "styled-components";

const __fontsBuilder = (StyleComponent) => {
  return {
    Regular: styled(StyleComponent).attrs(() => ({
      weight: "400",
    }))``,
    Medium: styled(StyleComponent).attrs(() => ({
      weight: "600",
    }))``,
    SemiBold: styled(StyleComponent).attrs(() => ({
      weight: "600",
    }))``,
    Bold: styled(StyleComponent).attrs(() => ({
      weight: "700",
    }))``,
    Italic: styled(StyleComponent).attrs(() => ({
      weight: "400",
      fontStyle: "italic",
    }))``,
    ItalicMedium: styled(StyleComponent).attrs(() => ({
      weight: "500",
      fontStyle: "italic",
    }))``,
  };
};

export const TextBase = styled.div`
  font-size: ${({ size }) => size};
  font-weight: ${({ weight }) => weight};
  color: ${({ color }) => color};
  text-transform: ${({ transform }) => transform};
  text-align: ${({ align }) => align};
  font-style: ${({ fontStyle }) => fontStyle};
  line-height: ${({ lineHeight }) => lineHeight};
`;

// Text H5 20px
export const TextH5Base = styled(TextBase).attrs(({ theme }) => ({
  size: theme.fonts.textH5,
  lineHeight: 1.2,
}))``;

export const TextH5 = __fontsBuilder(TextH5Base);

// Text H6 18px
export const TextH6Base = styled(TextBase).attrs(({ theme }) => ({
  size: theme.fonts.textH6,
  lineHeight: 1.2,
}))``;

export const TextH6 = __fontsBuilder(TextH6Base);

// Text Large 16px
export const TextLargeBase = styled(TextBase).attrs(({ theme }) => ({
  size: theme.fonts.textLarge,
  lineHeight: 1.4,
}))``;

export const TextLarge = __fontsBuilder(TextLargeBase);

// Text Base == Normal 14px
export const TextNormalBase = styled(TextBase).attrs(({ theme }) => ({
  size: theme.fonts.textSmall,
  lineHeight: 1.4,
}))``;

export const TextNormal = __fontsBuilder(TextNormalBase);

// Text XSmall 12px
export const TextSmallBase = styled(TextBase).attrs(({ theme }) => ({
  size: theme.fonts.textSmall,
  lineHeight: 1.4,
}))``;

export const TextSmall = __fontsBuilder(TextSmallBase);

// Text XSmall 10px
export const TextXSmallBase = styled(TextBase).attrs(({ theme }) => ({
  size: theme.fonts.textXSmall,
  lineHeight: 1.2,
}))``;

export const TextXSmall = __fontsBuilder(TextXSmallBase);


// Text Label 11px
export const TextLabelBase = styled(TextBase).attrs(({ theme }) => ({
  size: theme.fonts.textLabel,
  lineHeight: 1.2,
}))``;

export const TextLabels = __fontsBuilder(TextLabelBase);

// Text Input 11px
export const TextInputBase = styled(TextBase).attrs(({ theme }) => ({
  size: theme.fonts.textInput,
  lineHeight: 1.2,
}))``;

export const TextInput = __fontsBuilder(TextInputBase);


// Text Input 11px
export const TextCaptionBase = styled(TextBase).attrs(({ theme }) => ({
  size: theme.fonts.textCaption,
  lineHeight: 1.2,
}))``;

export const TextCaption = __fontsBuilder(TextCaptionBase);



/**
 * OLD fonts
 *
 */

export const H1 = styled(TextBase)`
  font-size: ${({ theme }) => theme.fonts.h1};
`;

export const TitleLg = styled.h1`
  font-size: ${({ theme }) => theme.fonts.h1};
`;

export const TitleMd = styled.h2`
  font-size: ${({ theme }) => theme.fonts.h2};
`;

// 16
export const TitleSm = styled.div`
  font-size: ${({ theme }) => theme.fonts.h3};
`;

// 14
export const Text = styled.div`
  font-size: ${({ theme }) => theme.fonts.body1};
`;

// 12
export const TextSm = styled.div`
  font-size: ${({ theme }) => theme.fonts.body2};
`;

// 11
export const TextXs = styled.div`
  font-size: ${({ theme }) => theme.fonts.body3};
`;

export const TextLabel = styled.div`
  font-size: ${({ theme }) => theme.fonts.label};
`;

export const TextTiny = styled.div`
  font-size: ${({ theme }) => theme.fonts.small};
`;
