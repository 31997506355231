import React from "react";
import * as Styled from "./styled";
import Avatar from "../../../../../../components/ui-components/Avatar";
import { TextEllipsis } from "../../../../../../styled/Common/styled.text";
import IconMenu from "../../../../../../common/components/icons/Fill/menu";
import IconButton from "../../../../../../common/components/Buttons/IconButton";

const Message = (props) => {
  const { layoutType = "ltr" } = props;
  console.log("props?.data:----", props?.data);
  return (
    <Styled.MessageWrapper layoutType={layoutType} className="message__item">
      <div className="message__avatar">
        <Avatar src={props?.data?.profileImage} size="size-28" />
      </div>

      <div className="message__content__area">
        <div className="message__content__area--head">
          <div className="avatar__name">
            <TextEllipsis>{props?.data?.fullName}</TextEllipsis>
          </div>
          <div className="message__time">
            {new Date(props?.data?.timeStamp).toLocaleTimeString("en-US")}
          </div>
        </div>

        <div className="message__content__area--body">
          <div className="message">{props?.data?.content}</div>

          <div className="action">
            <IconButton className="menu-action" icon={<IconMenu />} />
          </div>
        </div>
      </div>
    </Styled.MessageWrapper>
  );
};

export default Message;
