import React from "react";
import * as Styled from "./styled";

const Card = ({ children }) => {
  return <Styled.Card className="setting-card">{children}</Styled.Card>;
};

const Header = ({ children }) => {
  return <Styled.Header>{children}</Styled.Header>;
};

const Content = ({ children }) => {
  return <Styled.Content>{children}</Styled.Content>;
};

Card.Header = Header;
Card.Content = Content;

export default Card;
