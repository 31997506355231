import API_ENDPOINTS from "../../services/api-endpoints";
import API from "../../services/api-notification";

export const readNotification = (id) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API.put(`${endpoints.READ_NOTIFICATION_BY_ID}/${id}`)
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
