import React from "react";
import { AnimatePresence } from "framer-motion";

import { css } from "../../../../utility";
import "./tab-base-styles.scss";
import Header from "./TabHeader";
import TabLink from "./TabLink";
import TabPanel from "./TabPanel";
import TabPanelWrapper from "./TabPanelWrapper";

const BaseTab = (props) => {
  const { onSelect, activeTab, className, onTabChange } = props;

  const [navLinks, panels] = React.Children.toArray(props.children);

  const [selectedTab, setSelectedTab] = React.useState("");
  const [minHeight, setMinHeight] = React.useState([]);
  const [elementHeight, setElementHeight] = React.useState(0);

  const onSelectTab = React.useCallback(
    (tabId) => {
      setSelectedTab(tabId);
      // onSelect && onSelect(tabId);
      onTabChange && onTabChange(tabId);
    },
    [onTabChange]
  );

  const setMinHeightHandler = (h) => {
    setMinHeight((prev) => [...prev, h]);
  };

  const renderTab = React.useMemo(() => {
    if (!navLinks) {
      return null;
    }

    const renderItem = (c) => {
      const _children = React.Children.toArray(c);

      return _children.map((item, index) => {
        if (React.isValidElement(item)) {
          let propsData = {
            ...item.props,
            isActive: selectedTab === item.props.linkTo,
            isDisabled: item.props.isDisabled ? true : false,
            onClick: onSelectTab,
          };

          return React.cloneElement(item, propsData);
        }
        return null;
      });
    };

    return React.cloneElement(navLinks, {
      ...navLinks.props,
      children: renderItem(navLinks.props.children),
    });
  }, [navLinks, onSelectTab, selectedTab]);

  const renderPanel = React.useMemo(() => {
    if (!panels) {
      return null;
    }

    const renderSelectedPanel = (c) => {
      const _children = React.Children.toArray(c);

      return _children.map((child) => {
        if (child.props.panelId === selectedTab) {
          let propsData = {
            ...child.props,
            isActive: true,
          };

          return React.cloneElement(child, propsData);
        }

        return null;
      });
    };

    return React.cloneElement(panels, {
      ...panels.props,
      minHeight: elementHeight,
      children: renderSelectedPanel(panels.props.children),
    });

    // return panels;
  }, [panels, selectedTab, elementHeight]);

  React.useEffect(() => {
    setSelectedTab(activeTab);
    onSelect && onSelect(activeTab);
  }, [activeTab, onSelect]);

  React.useEffect(() => {
    if (minHeight) {
      const eleHeight = minHeight.reduce((a, b) => (a > b ? a : b), 0);
      setElementHeight(eleHeight);
    }
  }, [minHeight]);

  return (
    <div className={css("tab__base", className)}>
      {/* header */}
      {renderTab}

      {/* Show selected panel */}
      <AnimatePresence exitBeforeEnter>{renderPanel}</AnimatePresence>
    </div>
  );
};

BaseTab.Header = Header;

BaseTab.NavLink = TabLink;

BaseTab.PanelWrapper = TabPanelWrapper;

BaseTab.Panel = TabPanel;

export default BaseTab;
