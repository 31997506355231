import React, { useEffect, useState } from "react";

import AuthCardLayout from "../../components/AuthLayout";
import EmailVerificationForm from "./EmailVerify";
import FooterContent from "../../components/FooterContent";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES_PATH } from "../../../../routes/paths";
import { toast } from "react-toastify";
import { senEmailVerification } from "../../../../Actions/User/EmailVerification";

const EmailVerificationUI = () => {
  const [email, setEmail] = React.useState("");
  const codeRef = React.useRef();

  const navigate = useNavigate();
  const location = useLocation();

  // const [fields, setFields] = useState({
  //   code: { val: "", error: "" },
  // });
  // const [show, setShow] = useState(false);

  const [resendTime, setResendTime] = useState(120);

  React.useEffect(() => {
    if (location?.state?.email) {
      setEmail(location?.state?.email);

      codeRef.current.focus();
    } else {
      navigate(ROUTES_PATH.PublicRoutePath.Login.path, { replace: true });
    }
  }, [location, navigate]);

  const onCountdown = React.useCallback(() => {
    let count = 120;

    const countdown = setInterval(() => {
      count--;
      setResendTime(count);
      if (count === 0) {
        clearInterval(countdown);
      }
    }, 1000);

    return countdown;
  }, []);

  const onResendVerificationCode = React.useCallback(() => {
    onCountdown();

    try {
      if (email && email.length > 0) {
        senEmailVerification({ email, name: "" })
          .then((res) => {
            if (res?.data?.displayMessage) {
              toast.success(res?.data?.displayMessage);

              codeRef.current.focus();
            }
          })
          .catch((err) => {
            toast.error("Something went wrong");
          });
      }
    } catch (error) {}
  }, [email, onCountdown]);

  useEffect(() => {
    const countdown = onCountdown();

    return () => clearInterval(countdown);
  }, [onCountdown]);

  return (
    <AuthCardLayout
      title="Email Verification"
      subTitle={`A Verification Code has been sent to your email address  ${email} , Please check.`}
    >
      <EmailVerificationForm email={email} codeRef={codeRef} />

      <FooterContent
        textContent="Didn't get a verification code?"
        linkText="resend"
        onLinkClick={onResendVerificationCode}
        time={resendTime}
        isDisabled={resendTime > 0 ? true : false}
      />
    </AuthCardLayout>
  );
};

export default EmailVerificationUI;
