import React, { useRef, useState, useEffect } from "react";
import * as Loader from "react-loader-spinner";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { useRouteLoaderData } from "react-router-dom";
import { toast } from "react-toastify";
import { confirm } from "react-confirm-box";
import { MultiSelect } from "react-multi-select-component";
import "react-toastify/dist/ReactToastify.css";

import API from "../../../../services/api";
import API_Tenant from "../../../../services/api-tenant";
import API_ENDPOINTS from "../../../../services/api-endpoints";
import SettingPanel from "../../../ui-components/pages-ui/Setting/SettingPanel";
import SettingAccordion from "../../../ui-components/pages-ui/Setting/SettingPanel/SettingAccordion";
import Button, { BUTTON_ICONS } from "../../../ui-components/Buttons/Button";
import FromGroup from "../../../controls/FromGroup";
import Chip from "../../../ui-components/Chip";
import ChipCard from "../../../ui-components/ChipCard";
import { ROUTE_OPTIONS } from "../../../../constant/route-options";
import { SETTING_PERMISSION_MODULES } from "../../../../constant/setting_permission_module";
import { Permission } from "../../../../helpers/permission";

import "../../../sass/settings/card__common.scss";
import "../../../sass/settings/tag__card.scss";
import "../../../../components/controls/select-search/styles.css";

const TanentClient = () => {
  const { permission } = useRouteLoaderData(
    ROUTE_OPTIONS.setting.children["tenant-client"].id
  );

  const moduleType = SETTING_PERMISSION_MODULES.TenantClient;

  const permissions = new Permission(permission);

  const basicPermission = permissions.findByName(moduleType.basic.key);
  const regionPermission = permissions.findByName(moduleType.region.key);
  const regionCountriesPermission = permissions.findByName(
    moduleType.region_countries.key
  );

  const [clientId, setClientId] = useState(0);
  const [clientName, setClientName] = useState("");
  const [address01, setAddress01] = useState("");
  const [address02, setAddress02] = useState("");
  const [address03, setAddress03] = useState("");
  const [telephone, settTelephone] = useState("");
  let ClientList = [];
  const [Client, setClient] = useState([]);

  const [clients, settClients] = useState([]);

  const [clientDetailDiv, setShowClientDetailDiv] = useState(true);

  const [show, setShow] = useState(false); //Loading Indicator

  let ClientRegionList = [];
  let AllClientRegionList = [];

  const [clientRegions, setClientRegions] = useState([]);
  const [clientRegions_dd, setClientRegions_dd] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [clientRegionId, setClientRegionId] = useState(0);
  const [clientRegionName, setClientRegionName] = useState("");

  const [clientRegionDiv, setShowClientRegionDiv] = useState(false);
  const [clientRegionViewDiv, setShowClientRegionViewDiv] = useState(false);

  let ClientRegionCountryList = [];
  let AllClientRegionCountryList = [];

  let CountryList = [];
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [countryOptionList, setCountryOptionList] = useState([]);
  const [clientRegionCountries, setClientRegionCountries] = useState([]);

  const [clientRegionCountryDiv, setShowClientRegionCountryDiv] =
    useState(false);
  const [clientRegionCountryViewDiv, setShowClientRegionCountryViewDiv] =
    useState(false);

  const [selectedRegionID, setSelectedRegionID] = useState(0);
  //let RegionList = [];
  const [regions_dd, setRegions_dd] = useState([]);
  const [showAddClient, setshowAddClient] = useState(true);
  const [searchClientName, setSearchClientName] = useState("");

  var inputtext = "";
  useEffect(() => {
    fetchRegions()
    getAndBindClientList();

    // Handle Client Search and Enable Client Create Options
    const elements = Array.from(
      document.getElementsByClassName("select-search__input")
    );
    elements.map((element) => {
      console.log(element);
      element.addEventListener("keydown", (event) => {
        inputtext = element.getAttribute("value").toString();
        if (event.key.length === 1) {
          inputtext = inputtext + event.key;
        } else {
          if (event.key === "Backspace") {
            inputtext = inputtext.slice(0, -1);
          }
        }
        setSearchClientName(inputtext);
        console.log(inputtext);
        getExistClientCountByNameAndEnableClientCreationOptions(inputtext);
      });
    });
  }, []);

//Get all Regions
  const fetchRegions = async () => {
    try {
      //console.log("122 - Fetch Region API Call")
      setShow(true); // Loading Indicator
      const endpoints = new API_ENDPOINTS();
      const response = API.get(endpoints.GETALLREGIONS).then((response) => {
        if (response.data.isSuccess === true) {
          AllClientRegionList = response.data.result;
          // console.log("122 - AllClientRegionList", AllClientRegionList);
          // console.log("122 - response.data.result: ",response.data.result);

          //Calling both function here to run on mount and only to run after api data is fetched
          getAndBindClientRegionList();
          getAndBindRegionList();

        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
        }
      });

      // const response = await API.get(endpoints.GETALLREGIONS);
      // if (response.data.isSuccess === true) {
      //   setAllRegions(response.data.result); // Storing regions data
      // } else {
      //   console.log(response.data.displayMessage);
      //   toast.error(response.data.displayMessage);
      // }
   } catch (err) {
      console.log(err);
    } finally {
      setShow(false); // Stop loading indicator
    }
  };

  //***************CLIENT***************** */
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
    } catch (err) {
      console.log(err);
    }
  };
  const addNewClient = async (e) => {
    e.preventDefault();
    try {
      console.log(searchClientName);
      setShow(true); //Loading Indicator off
      setShowClientDetailDiv(true);
      const endpoints = new API_ENDPOINTS();
      const response = API_Tenant.post(endpoints.SETUPTENANTCLIENT, {
        clientId: 0,
        globalClientId: 0,
        clientName: searchClientName,
        address1: "",
        address2: "",
        address3: "",
        telephone: "",
        email: "",
        clientCountries: [],
        clientRegions: [],
      }).then((response) => {
        console.log("response", response);
        if (response.data.isSuccess === true) {
          // toast.success(response.data.displayMessage);
          getAndBindClientList();
          getClientById(response.data.result.clientId, false);

          setShow(false); //Loading Indicator off
          setshowAddClient(true);
          inputtext = null;
          setSearchClientName("");
        } else {
          toast.error(response.data.displayMessage);
          console.log(response.data.displayMessage);

          setShow(false); //Loading Indicator off
          return;
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
  const getExistClientCountByNameAndEnableClientCreationOptions = async (
    searchText
  ) => {
    try {
      if (searchText.length === 0) {
        setshowAddClient(true);
        return;
      }
      const endpoints = new API_ENDPOINTS();
      const response = API_Tenant.get(
        endpoints.GETTENANTCLIENTBYNAME + "/" + searchText
      ).then((response) => {
        //console.log(response);
        if (response.data.isSuccess === true) {
          if (response.data.result.length === 0) {
            console.log("No client found Enables client creation options");
            setshowAddClient(false);
          } else {
            setshowAddClient(true);
          }
        } else {
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };
  const getAndBindClientList = async (e) => {
    try {
      setShow(true); //Loading Indicator
      const endpoints = new API_ENDPOINTS();
      const response = API_Tenant.get(
        endpoints.GETALLTENANTCLIENTS + "/1/100"
      ).then((response) => {
        console.log(response);
        if (response.data.isSuccess === true) {
          ClientList = response.data.result.result;

          const client_list = ClientList.map((item) => {
            return { name: item.clientName, value: item.clientId };
            // return (
            //   <div className="col-md-4" hidden={setShowClientDetailViewDiv}>
            //     <div className="card-container">
            //       <img
            //         className="close-classic"
            //         src={close_icon}
            //         height="30"
            //         width="30"
            //         onClick={() => deleteClient(item)}
            //       />
            //       <img
            //         className="close-edit"
            //         src={edit_icon}
            //         height="30"
            //         width="30"
            //         onClick={() => editClient(item)}
            //       />
            //       <span className="pro" hidden>
            //         PRO
            //       </span>
            //       <img className="" src={deparment_icon} alt="user" />
            //       <h3>{item.clientName}</h3>
            //       <h6>{item.address1 + ", " + item.address2}</h6>
            //       <p>
            //         {item.address3}
            //         <br /> {item.telephone}
            //       </p>
            //     </div>
            //   </div>
            // );
          });

          settClients(client_list);

          setShow(false); //Loading Indicator
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };
  const getClientById = async (id, hide_div) => {
    try {
      //setShow(true); //Loading Indicator
      setClientId(0);
      setClientName("");
      setAddress01("");
      setAddress02("");
      setAddress03("");
      settTelephone("");
      if (hide_div) {
        setShowClientDetailDiv(true);
      }
      setshowAddClient(true);
      const endpoints = new API_ENDPOINTS();
      const response = API_Tenant.get(
        endpoints.GETTENANTCLIENTBYID + "/" + id
      ).then((response) => {
        console.log(response);
        if (response.data.isSuccess === true) {
          setClient(response.data.result);
          setClientId(response.data.result.clientId);
          setClientName(response.data.result.clientName);
          setAddress01(response.data.result.address1);
          setAddress02(response.data.result.address2);
          setAddress03(response.data.result.address3);
          settTelephone(response.data.result.telephone);

          ClientRegionList = response.data.result.clientRegions;
          if (ClientRegionList.length === 0) {
            setShowClientRegionDiv(false);
          } else {
            setShowClientRegionDiv(true);
          }
          getAndBindClientRegionListByID(ClientRegionList);

          ClientRegionCountryList = response.data.result.clientCountries;
          if (ClientRegionCountryList.length === 0) {
            setShowClientRegionCountryDiv(false);
          } else {
            setShowClientRegionCountryDiv(true);
          }
          getAndBindClientRegionCountryListByID(ClientRegionCountryList);

          addClient();
          // setClientDetailUIViewOptions();
          setShow(false); //Loading Indicator
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };
  const saveClient = async (e) => {
    let isEditMode = false;

    if (clientName?.length === 0) {
      toast.error("Please enter a client name");
      return;
    }
    if (address01?.length === 0) {
      toast.error("Please enter a address");
      return;
    }
    if (address02?.length === 0) {
      toast.error("Please enter a address");
      return;
    }
    if (telephone?.length === 0) {
      toast.error("Please enter a telephone number");
      return;
    }
    try {
      const endpoints = new API_ENDPOINTS();
      let End_point = "";
      //Create
      if (clientId == 0) {
        // console.log("create mode");
        End_point = endpoints.SETUPTENANTCLIENT;
        isEditMode = false;
        const response = API_Tenant.post(End_point, {
          clientId: clientId,
          clientName: clientName,
          address1: address01,
          address2: address02,
          address3: address03,
          telephone: telephone,
        }).then((response) => {
          // console.log(response);
          if (response.data.isSuccess === true) {
            if (!isEditMode) {
              // toast.success("Client created successfully");
            } else {
              // toast.success("Client updated successfully");
            }

            // setClientId(0);
            // setClientName("");
            // setAddress01("");
            // setAddress02("");
            // setAddress03("");
            // settTelephone("");

            // getAndBindClientList();

            setShow(false); //Loading Indicator off
          } else {
            console.log(response.data.displayMessage);
            toast.error(response.data.displayMessage);
            setShow(false); //Loading Indicator off
            return;
          }
        });
        //Update
      } else {
        console.log("update mode");
        End_point = endpoints.UPDATETENANTCLIENT;
        isEditMode = true;
        const response = API_Tenant.put(End_point + "/" + clientId, {
          clientId: clientId,
          clientName: clientName,
          address1: address01,
          address2: address02,
          address3: address03,
          telephone: telephone,
        }).then((response) => {
          console.log(response);
          if (response.data.isSuccess === true) {
            if (!isEditMode) {
              // toast.success("Client created successfully");
            } else {
              // toast.success("Client updated successfully");
            }
            // setClientId(0);
            // setClientName("");
            // setAddress01("");
            // setAddress02("");
            // setAddress03("");
            // settTelephone("");

            // getAndBindClientList();

            setShow(false); //Loading Indicator off
          } else {
            console.log(response.data.displayMessage);
            toast.error(response.data.displayMessage);
            setShow(false); //Loading Indicator off
            return;
          }
        });
      }
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };
  const editClient = async (e) => {
    try {
      setClientId(e.clientId);
      setClientName(e.clientName);
      setAddress01(e.address1);
      setAddress02(e.address2);
      setAddress03(e.address3);
      settTelephone(e.telephone);
      addClient();
    } catch (err) {
      console.log(err);
    }
  };
  const deleteClient = async (e) => {
    try {
      // console.log(e);

      const confirmation = await confirm(
        "Are you sure you want to delete this item ?"
      );

      if (!confirmation) {
        return;
      }

      setShow(true); //Loading Indicator on
      const endpoints = new API_ENDPOINTS();
      const response = API.delete(
        endpoints.DELETEGLOBALCLIENT + "/" + e.clientId,
        {}
      ).then((response) => {
        // console.log(response);
        if (response.data.isSuccess === true) {
          // toast.success("Client deleted successfully");
          getAndBindClientList();
          setClientId(0);
          setClientName("");
          setAddress01("");
          setAddress02("");
          setAddress03("");
          settTelephone("");
          setShow(false); //Loading Indicator off
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator off

          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };
  const setClientDetailUIViewOptions = async (e) => {
    try {
      if (clientId == 0) {
        setShowClientDetailDiv(false);
      } else {
        setShowClientDetailDiv(true);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const addClient = async (e) => {
    try {
      setShowClientDetailDiv(false);
      //setShowClientDetailViewDiv(false);
    } catch (err) {
      console.log(err);
    }
  };
  const cancelClient = async (e) => {
    try {
      setClientId(0);
      setClientName("");
      setAddress01("");
      setAddress02("");
      setAddress03("");
      settTelephone("");
      setShowClientDetailDiv(true);
    } catch (err) {
      console.log(err);
    }
  };
  //*****/ Client Region ******//
  const getAndBindClientRegionListByID = async (data) => {
    try {
      if (data !== null) {
        const clientRegion_list = ClientRegionList.map((item, i) => {
          return (
            <Chip key={i}>
              <Chip.Items>
                <Chip.Node text={item.regionName} />
              </Chip.Items>
            </Chip>
          );
        });
        let selectedRegionIds = [];
        ClientRegionList.map((item) => {
          selectedRegionIds.push({
            label: item.regionName,
            value: item.regionId,
          });
        });

        setClientRegions(clientRegion_list);
        setSelectedRegions(selectedRegionIds);
      } else {
        return;
      }
    } catch (err) {
      console.log(err);
    }
  };
   const getAndBindClientRegionList = async (e) => {
  
    // ## Commenting Below lines after centralizing the GetAllRegions api call used by both  
    // ## getAndBindRegionList and getAndBindClientRegionList and share the responce data in above logic

    // try {
    //   //setShow(true); //Loading Indicator
    //   const endpoints = new API_ENDPOINTS();
    //   const response = API.get(endpoints.GETALLREGIONS).then((response) => {
    //     console.log("rl", response);
    //     if (response.data.isSuccess === true) {
    //       AllClientRegionList = response.data.result;
         // console.log("556: AllClientRegionList: ",AllClientRegionList);
          const regions_list_dropdown = AllClientRegionList.map((item) => {
            return { label: item.regionName, value: item.regionId };
          });
         // console.log("556: regions_list_dropdown: ",regions_list_dropdown);
          setClientRegions_dd(regions_list_dropdown);

    //       setShow(false); //Loading Indicator
    //     } else {
    //       console.log(response.data.displayMessage);
    //       toast.error(response.data.displayMessage);
    //       setShow(false); //Loading Indicator
    //       return;
    //     }
    //   });
    // } catch (err) {
    //   console.log(err);
    //   setShow(false); //Loading Indicator
    // }
  };
  const saveClientRegion = async (e) => {
    let isEditMode = false;
    let _selectedRegionIds = [];
    // if (clientRegionName.length === 0) {
    //   toast.error("Please enter a Region Name");
    //   return;
    // }

    try {
      selectedRegions.map((item) => {
        _selectedRegionIds.push(item.value);
      });
      const endpoints = new API_ENDPOINTS();
      let End_point = "";
      //Create
      if (clientId == 0) {
        // console.log("create mode");
        // End_point = endpoints.SETUPCLIENTREGION;
        // isEditMode = false;
        // const response = API.post(End_point, {
        //   clientRegionId: clientRegionId,
        //   clientRegionName: clientRegionName,
        // }).then((response) => {
        //   // console.log(response);
        //   if (response.data.isSuccess === true) {
        //     if (!isEditMode) {
        //       toast.success("Client Region created successfully");
        //     } else {
        //       toast.success("Client Region updated successfully");
        //     }
        //     getAndBindClientRegionList();
        //     setClientRegionId(0);
        //     setClientRegionName("");
        //     setShow(false); //Loading Indicator off
        //   } else {
        //     console.log(response.data.displayMessage);
        //     toast.error(response.data.displayMessage);
        //     setShow(false); //Loading Indicator off
        //     return;
        //   }
        // });
        //Update
      } else {
        console.log("update mode");
        End_point = endpoints.UPDATE_TENANT_CLIENTREGION;
        isEditMode = true;
        const response = API_Tenant.post(End_point, {
          clientId: clientId,
          regionId: _selectedRegionIds,
        }).then((response) => {
          console.log(response);
          if (response.data.isSuccess === true) {
            if (!isEditMode) {
              // toast.success("Client Region created successfully");
            } else {
              // toast.success("Client Region updated successfully");
            }
            getClientById(clientId, false);
            // setClientRegionId(0);
            // setClientRegionName("");

            setShow(false); //Loading Indicator off
          } else {
            console.log(response.data.displayMessage);
            toast.error(response.data.displayMessage);
            setShow(false); //Loading Indicator off
            return;
          }
        });
      }
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };

  const addClientRegion = async (e) => {
    try {
      setShowClientRegionDiv(false);
      setShowClientRegionViewDiv(false);
    } catch (err) {
      console.log(err);
    }
  };
  const cancelClientRegion = async (e) => {
    try {
      setClientRegionId(0);
      setClientRegionName("");

      setShowClientRegionDiv(true);
    } catch (err) {
      console.log(err);
    }
  };
  //*****/ Client Region Country ******//
  // const onClientRegionChange = async (clientRegionId) => {
  //   try {
  //     let selectedCountryIds = [];
  //     const endpoints = new API_ENDPOINTS();
  //     const response = API.get(
  //       endpoints.GETCLIENTREGIONCOUNTRYBYID + "/" + clientRegionId
  //     ).then((response) => {
  //       console.log(response);
  //       if (response.data.isSuccess === true) {
  //         setClientRegionId_ClientRegionCountry(regionId);
  //         response.data.result[0].regionCountries.map((item) => {
  //           selectedCountryIds.push({
  //             label: item.countryName,
  //             value: item.countryId,
  //           });
  //         });
  //         setSelectedCountries(selectedCountryIds);
  //         console.log(selectedCountries);
  //       } else {
  //         console.log(response.data.displayMessage);
  //         toast.error(response.data.displayMessage);
  //         return;
  //       }
  //     });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  const getAndBindClientRegionCountryListByID = async (data) => {
    try {
      if (data !== null) {
        const regionCountry_list = ClientRegionCountryList?.map((item) => {
          return (
            <ChipCard
              title={item.regionName}
              onEdit={
                regionCountriesPermission.permission.canEdit
                  ? () => editRegionCountry(item)
                  : null
              }
              // onDelete={() => deleteRegionCountry(item)}
            >
              <ul className="disc--list">
                {item.regionCountries?.map((rcitem, i) => {
                  return <li key={i}>{rcitem.countryName}</li>;
                })}
              </ul>
            </ChipCard>
          );
        });
        console.log(regionCountry_list);
        setClientRegionCountries(regionCountry_list);
        //setRegionCountryUIViewOptions();
        setShow(false); //Loading Indicator
      } else {
        setShow(false); //Loading Indicator
        return;
      }
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };
  const getAndBindClientRegionCountryList = async (e) => {
    try {
      setShow(true); //Loading Indicator
      const endpoints = new API_ENDPOINTS();
      const response = API.get(endpoints.GETALLREGIONCOUNTRIES).then(
        (response) => {
          console.log(response);
          if (response.data.isSuccess === true) {
            AllClientRegionCountryList = response.data.result;

            //setRegionCountryUIViewOptions();
            setShow(false); //Loading Indicator
          } else {
            console.log(response.data.displayMessage);
            toast.error(response.data.displayMessage);
            setShow(false); //Loading Indicator
            return;
          }
        }
      );
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };
  const onRegionChange = async (regionId) => {
    try {
      console.log(regionId);
      let selectedCountryIds = [];

      setShow(true); //Loading Indicator
      const endpoints = new API_ENDPOINTS();
      const response = API.get(
        endpoints.GETREGIONCOUNTRYBYID + "/" + regionId
      ).then((response) => {
        if (response.data.isSuccess === true) {
          //Load Countries filter by Region
          CountryList = response.data.result[0].regionCountries;
          const country_list_dropdown = CountryList.map((item) => {
            return { label: item.countryName, value: item.countryId };
          });

          setCountryOptionList(country_list_dropdown);

          // Set Selected Countries

          if (Client !== null) {
            setSelectedRegionID(regionId);
            Client.clientCountries.forEach((element) => {
              console.log(element.regionId);
              if (element.regionId == regionId) {
                element.regionCountries.map((rc) => {
                  console.log(rc);
                  selectedCountryIds.push({
                    label: rc.countryName,
                    value: rc.countryId,
                  });
                });
              }
            });
            setSelectedCountries(selectedCountryIds);
            //console.log(selectedCountries);
          } else {
            return;
          }

          setShow(false); //Loading Indicator
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
  const getAndBindRegionList = async (e) => {
    // console.log("831: AllClientRegionList: ",AllClientRegionList);
    // const regions_list_dropdown = allRegions.map((item) => {
    //   return <option value={item.regionId}>{item.regionName}</option>;
    // });
    // console.log("831: regions_list_dropdown: ",regions_list_dropdown);
    // setRegions_dd(regions_list_dropdown);

    // ## Commenting Below lines after centralizing the GetAllRegions api call used by both  
    // ## getAndBindRegionList and getAndBindClientRegionList and share the responce data in above logic

    // try {
    //   setShow(true); //Loading Indicator
    //   const endpoints = new API_ENDPOINTS();
    //   const response = API.get(endpoints.GETALLREGIONS).then((response) => {
    //     console.log(response);
    //     if (response.data.isSuccess === true) {
    //       RegionList = response.data.result;
          //console.log("831: AllClientRegionList: ",AllClientRegionList);
          const regions_list_dropdown = AllClientRegionList.map((item) => {
            return <option value={item.regionId}>{item.regionName}</option>;
          });
          //console.log("831: regions_list_dropdown: ",regions_list_dropdown);
          setRegions_dd(regions_list_dropdown);

    //       setShow(false); //Loading Indicator
    //     } else {
    //       console.log(response.data.displayMessage);
    //       toast.error(response.data.displayMessage);
    //       setShow(false); //Loading Indicator
    //       return;
    //     }
    //   });
    // } catch (err) {
    //   console.log(err);
    //   setShow(false); //Loading Indicator
    // }
  };
  const getAndBindCountryList = async (e) => {
    try {
      setShow(true); //Loading Indicator
      const endpoints = new API_ENDPOINTS();
      const response = API.get(endpoints.GETALLCOUNTRIES).then((response) => {
        // console.log(response);
        if (response.data.isSuccess === true) {
          CountryList = response.data.result;

          const country_list_dropdown = CountryList.map((item) => {
            return { label: item.countryName, value: item.countryId };
          });

          setCountryOptionList(country_list_dropdown);

          setShow(false); //Loading Indicator
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };
  const saveRegionCountry = async (e) => {
    let isEditMode = false;
    let selectedCountryIds = [];
    if (selectedRegionID === 0) {
      toast.error("Please select a region");
      return;
    }
    if (selectedCountries.length === 0) {
      toast.error("Please select a Country");
      return;
    }

    try {
      selectedCountries.map((item) => {
        selectedCountryIds.push(item.value);
      });
      console.log("si", selectedCountryIds);
      const endpoints = new API_ENDPOINTS();
      let End_point = "";
      console.log("regionId", selectedRegionID);

      //Create
      if (clientId === 0) {
        // console.log("create mode");
        // End_point = endpoints.SETUPREGIONCOUNTRIES;
        // isEditMode = false;
        // const response = API.post(End_point, {
        //   regionId: regionId,
        //   countryId: selectedCountryIds,
        // }).then((response) => {
        //   console.log(response);
        //   if (response.data.isSuccess === true) {
        //     if (!isEditMode) {
        //       toast.success("Region Countries created successfully");
        //     } else {
        //       toast.success("Region Countries updated successfully");
        //     }
        //     setRegionId(0);
        //     setSelectedCountries([]);
        //     getAndBindRegionCountryList();
        //     setShow(false); //Loading Indicator off
        //   } else {
        //     console.log(response.data.displayMessage);
        //     toast.error(response.data.displayMessage);
        //     setShow(false); //Loading Indicator off
        //     return;
        //   }
        // });
        //Update
      } else {
        console.log("update mode");
        End_point = endpoints.UPDATE_TENANT_CLIENTREGIONCOUNTRIES;
        isEditMode = true;
        const response = API_Tenant.post(End_point, {
          clientId: clientId,
          regionId: selectedRegionID,
          countryId: selectedCountryIds,
        }).then((response) => {
          console.log(response);
          if (response.data.isSuccess === true) {
            if (!isEditMode) {
              // toast.success("Region Countries created successfully");
            } else {
              // toast.success("Region Countries updated successfully");
            }
            setSelectedRegionID(0);
            setSelectedCountries([]);

            getClientById(clientId, false);

            setShow(false); //Loading Indicator off
          } else {
            console.log(response.data.displayMessage);
            toast.error(response.data.displayMessage);
            setShow(false); //Loading Indicator off
            return;
          }
        });
      }
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };
  const editRegionCountry = async (e) => {
    let selectedCountryIds = [];
    try {
      console.log(e);
      setSelectedRegionID(e.regionId);
      onRegionChange(e.regionId);
      e.regionCountries?.map((item) => {
        selectedCountryIds.push({
          label: item.countryName,
          value: item.countryId,
        });
      });
      setSelectedCountries(selectedCountryIds);
      console.log(selectedCountries);

      addRegionCountry();
    } catch (err) {
      console.log(err);
    }
  };
  const addRegionCountry = async (e) => {
    try {
      setShowClientRegionCountryDiv(false);
      setShowClientRegionCountryDiv(false);
    } catch (err) {
      console.log(err);
    }
  };
  const cancelRegionCountry = async (e) => {
    try {
      setSelectedRegionID(0);
      setSelectedCountries([]);
      setShowClientRegionCountryDiv(true);
    } catch (err) {
      console.log(err);
    }
  };
  //*****/ UI ******

  // Breadcrumbs List
  const breadcrumbsList = [{ text: "Tanent" }, { text: "Client" }];

  return (
    <>
      <div style={{ justifyContent: "center", display: "flex" }}>
        <Loader.ThreeDots
          visible={show}
          width={100}
          height={30}
          color="#347084"
        ></Loader.ThreeDots>
      </div>

      <SettingPanel
        breadcrumbs={breadcrumbsList}
        // actions={
        //   <>
        //     <Button variant="secondary">
        //       Cancel
        //     </Button>
        //     <Button>
        //       Save
        //     </Button>
        //   </>
        // }
      >
        {basicPermission.hasPermission ? (
          <SettingAccordion title="Basic Details">
            <SettingAccordion.Card>
              {/* <FromGroup inputHeightAuto label=""></FromGroup> */}

              <form className="row setting-form">
                <div className="col-md-12">
                  <FromGroup
                    inputHeightAuto
                    label="Select Client or Create New Client"
                  >
                    <SelectSearch
                      options={clients}
                      value={clientId}
                      onChange={getClientById}
                      search
                      filterOptions={fuzzySearch}
                      placeholder="Search client"
                    />
                  </FromGroup>
                </div>

                {!clientDetailDiv &&
                showAddClient &&
                basicPermission.permission.canAddNew ? (
                  <>
                    <div className="col-md-4">
                      <FromGroup inputHeightAuto label="Address">
                        <input
                          className="form-control"
                          type="text"
                          id="address_1"
                          name="address_1"
                          placeholder="Address 1"
                          aria-label="Address 1"
                          onChange={(e) => setAddress01(e.target.value)}
                          value={address01}
                        />
                      </FromGroup>
                    </div>

                    <div className="col-md-4">
                      <FromGroup inputHeightAuto label="&nbsp;">
                        <input
                          className="form-control"
                          type="text"
                          id="address_2"
                          name="address_2"
                          placeholder="Address 2"
                          aria-label="Address 2"
                          onChange={(e) => setAddress02(e.target.value)}
                          value={address02}
                        />
                      </FromGroup>
                    </div>

                    <div className="col-md-4">
                      <FromGroup inputHeightAuto label="&nbsp;">
                        <input
                          className="form-control"
                          type="text"
                          id="address_3"
                          name="address_3"
                          placeholder="Address 3"
                          aria-label="Address 3"
                          onChange={(e) => setAddress03(e.target.value)}
                          value={address03}
                        />
                      </FromGroup>
                    </div>

                    <div className="col-md-12">
                      <FromGroup inputHeightAuto label="Telephone">
                        <input
                          className="form-control"
                          type="text"
                          id="telephone"
                          name="telephone"
                          placeholder="Telephone"
                          aria-label="Telephone"
                          onChange={(e) => settTelephone(e.target.value)}
                          value={telephone}
                        />
                      </FromGroup>
                    </div>
                  </>
                ) : null}

                <div className="col-12">
                  <FromGroup type="button-inline-left">
                    {!showAddClient && basicPermission.permission.canAddNew ? (
                      <Button onClick={addNewClient}>Add Client</Button>
                    ) : null}

                    {!clientDetailDiv &&
                    showAddClient &&
                    basicPermission.permission.canAddNew ? (
                      <>
                        <Button variant="secondary" onClick={cancelClient}>
                          Cancel
                        </Button>

                        <Button onClick={saveClient}>Save</Button>
                      </>
                    ) : null}
                  </FromGroup>
                </div>

                {/* {clients} */}
              </form>
            </SettingAccordion.Card>
          </SettingAccordion>
        ) : null}

        {/* Client Region */}
        {!clientDetailDiv && regionPermission.hasPermission ? (
          <SettingAccordion
            title="Client Region"
            actions={
              regionPermission.permission.canAddNew ? (
                <Button
                  size="sm"
                  variant="green-light"
                  leftIcon={BUTTON_ICONS.plus}
                  onClick={addClientRegion}
                >
                  Add
                </Button>
              ) : null
            }
          >
            <SettingAccordion.Card>
              {!clientRegionDiv && regionPermission.permission.canAddNew ? (
                <form className="row setting-form">
                  <div className="col-md-12">
                    <FromGroup inputHeightAuto label="Region Name">
                      <MultiSelect
                        options={clientRegions_dd}
                        value={selectedRegions}
                        onChange={setSelectedRegions}
                        labelledBy="Select"
                      />
                    </FromGroup>
                  </div>

                  <div className="col-md-12">
                    <FromGroup type="button-inline-left">
                      {clientRegions.length > 0 ? (
                        <Button
                          variant="secondary"
                          onClick={cancelClientRegion}
                        >
                          Cancel
                        </Button>
                      ) : null}

                      <Button onClick={saveClientRegion}>Save</Button>
                    </FromGroup>
                  </div>
                </form>
              ) : null}

              {clientRegions.length > 0 &&
              regionPermission.permission.canView ? (
                <div className="setting-details">
                  <Chip.Wrapper>{clientRegions}</Chip.Wrapper>
                </div>
              ) : null}
            </SettingAccordion.Card>
          </SettingAccordion>
        ) : null}

        {/* Client Region Countries */}
        {!clientDetailDiv && regionCountriesPermission.hasPermission ? (
          <SettingAccordion
            title="Client Region Countries"
            actions={
              clientRegionCountryDiv &&
              regionCountriesPermission.permission.canAddNew ? (
                <Button
                  size="sm"
                  variant="green-light"
                  leftIcon={BUTTON_ICONS.plus}
                  onClick={addRegionCountry}
                >
                  Add
                </Button>
              ) : null
            }
          >
            <SettingAccordion.Card>
              {!clientRegionCountryDiv &&
              regionCountriesPermission.permission.canAddNew ? (
                <form className="row setting-form">
                  <div className="col-md-6">
                    {/* <FromGroup inputHeightAuto label=""></FromGroup> */}

                    <FromGroup inputHeightAuto label="Region">
                      <select
                        className="form-control"
                        aria-label="Default select example"
                        onChange={(e) => onRegionChange(e.target.value)}
                        value={selectedRegionID}
                      >
                        <option selected value={0}>
                          --- Select Region ---
                        </option>
                        {regions_dd}
                      </select>
                    </FromGroup>
                  </div>

                  <div className="col-md-6" hidden={clientRegionCountryDiv}>
                    <FromGroup inputHeightAuto label="Country">
                      <MultiSelect
                        options={countryOptionList}
                        value={selectedCountries}
                        onChange={setSelectedCountries}
                        labelledBy="Select"
                        className="sm-rmsc"
                      />
                    </FromGroup>
                  </div>

                  <div className="col-12">
                    <FromGroup type="button-inline-left">
                      {clientRegionCountries.length > 0 ? (
                        <Button
                          variant="secondary"
                          onClick={cancelRegionCountry}
                        >
                          Cancel
                        </Button>
                      ) : null}

                      <Button onClick={saveRegionCountry}>Save</Button>
                    </FromGroup>
                  </div>
                </form>
              ) : null}

              {clientRegionCountries.length > 0 &&
              regionCountriesPermission.permission.canView ? (
                <div className="row setting-details">
                  <div className="col-12">
                    <ChipCard.Wrapper className="client-region-countries">
                      {clientRegionCountries}
                    </ChipCard.Wrapper>
                  </div>
                </div>
              ) : null}
            </SettingAccordion.Card>
          </SettingAccordion>
        ) : null}
      </SettingPanel>
    </>
  );
};

export default TanentClient;
