import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import * as Styled from "./styled";
import InputField from "../../../../../../../../common/components/Form/InputField";
import DatePickerField from "../../../../../../../../common/components/Form/DatePickerField";
import FieldGroup from "../../../../../../../../common/components/Form/FieldGroup";
import FileDropZone from "../../../../../../../../common/components/Form/FileDropZone";
import { FormGrid } from "../styled";
import {
  useProjectWizardStore,
  onUpdateDoSubmitState,
  getCurrentStep,
} from "../../../../store";
import SelectField from "../../../../../../../../common/components/Form/SelectField";
import {
  getAllGenresAsOptionSource,
  getAllLanguagesAsOptionSource,
  getProjectById,
} from "../../../../../API/common";
import { useAppStore } from "../../../../../../../../store/AppStore/AppStore";
import { addDays, isAfter } from "date-fns";

const validationSchema = Yup.object().shape({
  workingTitle: Yup.string()
    .required("This field is required")
    .max(75, "The value may not be longer than 75 characters."),
  broadcastTitle: Yup.string().max(
    75,
    "The value may not be longer than 75 characters."
  ),
  duration: Yup.number()
    .required("This field is required")
    .min(1, "Minimum duration is 1 minute"),
  startDate: Yup.string().required("This field is required"),
  endDate: Yup.string().required("This field is required"),
  genre: Yup.array().min(1, "Select at least one genre").required("required"),
  txDate: Yup.string().required("This field is required"),
  language: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .required("This field is required")
    .nullable(),
});

const initialValues = {
  projectId: 0,
  tenantId: 0,
  clientId: 1,
  workingTitle: "",
  broadcastTitle: "",
  episodes: 1,
  duration: 0,
  txDate: "",
  startDate: "",
  endDate: "",
  genre: [],
  language: null,
  logo: "",
};

const StepOne = ({ onSubmitted }) => {
  const hideLoader = useAppStore((state) => state.hideLoader);
  const showLoader = useAppStore((state) => state.showLoader);
  const [allLanguages, setAllLanguages] = React.useState([]);
  const [allGenres, setAllGenres] = React.useState([]);

  const [logoFile, setLogoFiles] = React.useState(null);

  const formShouldSubmit = useProjectWizardStore((state) => state.doSubmit);
  const currentStep = getCurrentStep();
  let USER = JSON.parse(localStorage.getItem("user"));
  const onSubmitHandler = React.useCallback(
    (values, options) => {
      onSubmitted(values, { ...options, stepKey: "step_01" });
    },
    [onSubmitted]
  );

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: onSubmitHandler,
    validateOnChange: true,
    validateOnBlur: true,
  });

  const { values, errors, handleChange, touched, handleSubmit, setFieldValue } =
    formik;

  React.useEffect(() => {
    if (formShouldSubmit) {
      handleSubmit();
      onUpdateDoSubmitState();
    }
  }, [formShouldSubmit, handleSubmit]);

  React.useEffect(() => {
    showLoader();
    getAllGenresAsOptionSource().then((result) => {
      setAllGenres(result?.map((genre) => genre) || []);
    });

    getAllLanguagesAsOptionSource().then((result) => {
      console.log("getAllLanguagesAsOptionSource:::", result);
      setAllLanguages(result?.map((lang) => lang) || []);
      hideLoader();
    });
    // getProjectById(1);
  }, [
    USER.result.tenantId,
    currentStep,
    hideLoader,
    setFieldValue,
    showLoader,
  ]);
  React.useEffect(() => {
    // Set TenantId
    let tenantId = USER.result.tenantId;
    setFieldValue("tenantId", tenantId);

    if (currentStep?.data !== null) {
      console.log("currentStep data:: ", currentStep?.data);

      setFieldValue("projectId", currentStep?.data?.projectId || 0);
      setFieldValue("tenantId", currentStep?.data?.tenantId || 0);
      setFieldValue("clientId", currentStep?.data?.clientId || 0);
      setFieldValue("workingTitle", currentStep?.data?.workingTitle || "");
      setFieldValue("broadcastTitle", currentStep?.data?.broadcastTitle || "");
      setFieldValue("episodes", currentStep?.data?.episodes || 1);
      setFieldValue("duration", currentStep?.data?.duration || 0);
      setFieldValue("txDate", currentStep?.data?.txDate || "");
      setFieldValue("startDate", currentStep?.data?.startDate || "");
      setFieldValue("endDate", currentStep?.data?.endDate || "");
      setFieldValue("genre", currentStep?.data?.genre || []);
      setFieldValue(
        "language",
        !currentStep?.data?.language?.value ? null : currentStep?.data?.language
      );
      setFieldValue("logo", currentStep?.data?.logo || null);
      setLogoFiles(currentStep?.data?.logo);
    } else {
      setFieldValue("projectId", 0);
      setFieldValue("tenantId", tenantId);
      setFieldValue("clientId", 0);
      setFieldValue("workingTitle", "");
      setFieldValue("broadcastTitle", "");
      setFieldValue("episodes", 1);
      setFieldValue("duration", 0);
      setFieldValue("txDate", "");
      setFieldValue("startDate", "");
      setFieldValue("endDate", "");
      setFieldValue("genre", []);
      setFieldValue("language", null);
      setFieldValue("logo", null);
      setLogoFiles(null);
    }
  }, [USER.result.tenantId, currentStep?.data, setFieldValue]);

  const onGenresChange = (gen) => {
    setFieldValue("genre", gen);
  };

  const onLanguageChange = (lang) => {
    setFieldValue("language", lang);
  };

  const onStartDateChange = (val) => {
    setFieldValue("startDate", val);
  };
  const onEndDateChange = (val) => {
    setFieldValue("endDate", val);
  };
  const onTXDateChange = (val) => {
    setFieldValue("txDate", val);
  };

  const onFileUploadHandler = (files) => {
    if (files.length === 0) {
      return;
    }
    setLogoFiles(files[0]?.filePath);
    setFieldValue("logo", files[0]?.filePath);
  };
  const onWorkingTitleChange = () => {
    if (values.broadcastTitle?.length === 0) {
      setFieldValue("broadcastTitle", values.workingTitle);
    }
  };

  return (
    <Styled.FormContainer>
      <FormGrid>
        <div className="grid-item">
          <FieldGroup
            label="Working Title"
            className="field__group"
            error={touched.workingTitle && errors.workingTitle}
          >
            <InputField
              name="workingTitle"
              placeholder="Working Title"
              value={values.workingTitle}
              onChange={handleChange}
              onBlur={onWorkingTitleChange}
            />
          </FieldGroup>
        </div>
        <div className="grid-item ">
          <FieldGroup
            className="field__group"
            label="Broadcast Title"
            error={touched.broadcastTitle && errors.broadcastTitle}
          >
            <InputField
              name="broadcastTitle"
              placeholder="Broadcast Title"
              value={values.broadcastTitle}
              onChange={handleChange}
            />
          </FieldGroup>
        </div>
        <div className="grid-item ">
          <FieldGroup label="#No Episode" className="field__group">
            <InputField
              name="episodes"
              placeholder="#No Episode"
              type="number"
              value={values.episodes}
              onChange={handleChange}
              readOnly={true}
            />
          </FieldGroup>
        </div>
        <div className="grid-item ">
          <FieldGroup
            label="Duration(minute)"
            className="field__group"
            error={touched.duration && errors.duration}
          >
            <InputField
              name="duration"
              placeholder="Duration"
              type="number"
              value={values.duration}
              onChange={handleChange}
            />
          </FieldGroup>
        </div>

        <div className="grid-item ">
          <FieldGroup
            label="Start Date"
            className="field__group"
            error={touched.startDate && errors.startDate}
          >
            <DatePickerField
              name="startDate"
              dateIsRequired
              placeholder="Start Date"
              value={values.startDate}
              onDateSelect={(val) => {
                onStartDateChange(val);

                if (
                  values.endDate &&
                  isAfter(new Date(val), new Date(values.endDate))
                ) {
                  setFieldValue("endDate", "");
                }

                if (
                  values.txDate &&
                  isAfter(new Date(val), new Date(values.txDate))
                ) {
                  setFieldValue("txDate", "");
                }
              }}
            />
          </FieldGroup>
        </div>

        <div className="grid-item ">
          <FieldGroup
            label="End Date"
            className="field__group"
            error={touched.endDate && errors.endDate}
          >
            <DatePickerField
              dateIsRequired
              name="endDate"
              placeholder="End Date"
              value={values.endDate}
              onDateSelect={onEndDateChange}
              fromDate={addDays(new Date(values?.startDate), 1)}
              disabled={!values.startDate}
              defaultMonth={new Date(values?.startDate)}
            />
          </FieldGroup>
        </div>

        <div className="grid-item ">
          <FieldGroup
            label="TX Date"
            className="field__group"
            error={touched.txDate && errors.txDate}
          >
            <DatePickerField
              name="txDate"
              dateIsRequired
              placeholder="TX Date"
              value={values.txDate}
              fromDate={addDays(new Date(values?.startDate), 1)}
              disabled={!values.startDate}
              onDateSelect={(val) => {
                onTXDateChange(val);
              }}
              defaultMonth={new Date(values?.startDate)}
            />
          </FieldGroup>
        </div>

        <div className="grid-item ">
          <FieldGroup
            label="Genre"
            className="field__group"
            error={touched.genre && errors.genre}
          >
            <SelectField
              name="genre"
              isMulti={true}
              value={values.genre}
              options={allGenres}
              onChange={(val) => {
                onGenresChange(val);
              }}
            />
          </FieldGroup>
        </div>
        <div className="grid-item ">
          <FieldGroup
            label="Language"
            className="field__group"
            error={touched.language && errors.language}
          >
            <SelectField
              name="language"
              value={values.language}
              options={allLanguages}
              onChange={(val) => {
                onLanguageChange(val);
              }}
            />

            {/* <CountryField
              value={"AGO"}
              onChange={(v) => {
                console.log(v);
              }}
            /> */}
          </FieldGroup>
        </div>
        <div className="grid-item full">
          <FieldGroup label="Project Logo" className="project__logo">
            <FileDropZone
              name="logo"
              onFileUpload={onFileUploadHandler}
              allowedTypesText="PNG, JPG or GIF (max 5MB)"
              autoServerUpload={true}
            />
            {logoFile !== null && (
              <Styled.LogoContainer>
                <img src={logoFile} alt="logo" />
              </Styled.LogoContainer>
            )}
          </FieldGroup>
        </div>
        {/* <div className="grid-item ">
          <FieldGroup label="Tenant Project Code" className="field__group">
            <InputField
              name="tenant_project_code"
              placeholder="Tenant Project Code"
            />
          </FieldGroup>
        </div>
        <div className="grid-item ">
          <FieldGroup label="Broadcaster Ref Code" className="field__group">
            <InputField
              name="broadcaster_ref_code"
              placeholder="Broadcaster Ref Code"
            />
          </FieldGroup>
        </div> */}
      </FormGrid>
    </Styled.FormContainer>
  );
};

export default StepOne;
