import { functionalUpdate, makeStateUpdater } from "@tanstack/react-table";

export const RowHoverFeature = {
  getInitialState: (state) => {
    return {
      rowSelectionHover: null,
      ...state,
    };
  },

  getDefaultOptions: (table) => {
    return {
      enableRowHover: true,
      onRowHoverChange: makeStateUpdater("rowSelectionHover", table),
    };
  },

  createRow: (row, table) => {
    row.setRowHover = (updater) => {
      const safeUpdater = (old) => {
        let newState = functionalUpdate(updater, old);
        return newState;
      };
      return table.options.onRowHoverChange?.(safeUpdater);
    };

    row.onRowHover = () => {
      row.setRowHover(() => {
        return row.id;
      });
    };

    row.onRowLeave = () => {
      row.setRowHover(() => {
        return null;
      });
    };

    row.getIsRowHovered = () => {
      const { rowSelectionHover } = table.getState();

      if (!rowSelectionHover) return false;

      return rowSelectionHover === row.id;
    };
  },
};
