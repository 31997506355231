import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.23537 8.29721H1.15775C0.519312 8.29721 0 8.83829 0 9.5035V14.7937C0 15.4589 0.519312 16 1.15775 16H6.23537C6.87381 16 7.39312 15.4589 7.39312 14.7937V9.5035C7.39312 8.83829 6.87381 8.29721 6.23537 8.29721ZM6.99016 14.7939C6.99016 15.2277 6.65156 15.5805 6.23525 15.5805L1.15793 15.5804C0.741622 15.5804 0.403016 15.2276 0.403016 14.7938V9.50359C0.403016 9.06983 0.741622 8.71703 1.15793 8.71703L6.23555 8.71715C6.65185 8.71715 6.99046 9.06995 6.99046 9.50371L6.99016 14.7939Z"
      fill="currentColor"
    />
    <path
      d="M4.54764 11.7979C4.90157 11.634 5.09551 11.4287 5.09551 10.9609C5.09551 10.3861 4.72328 9.93517 3.78145 9.93517H2.33412V13.8104H3.96215C4.93033 13.8104 5.30326 13.315 5.30326 12.7347C5.30326 12.2377 5.00148 11.9002 4.54764 11.798V11.7979ZM2.9621 10.5395H3.76548C4.16508 10.5395 4.4411 10.6476 4.4411 11.0607C4.4411 11.3822 4.21425 11.543 3.7602 11.543H2.96213L2.96224 10.5395H2.9621ZM3.94651 13.2062H2.9621V12.1471H3.86639C4.39493 12.1471 4.65928 12.319 4.65928 12.6628C4.6594 13.1059 4.37488 13.2062 3.94651 13.2062Z"
      fill="currentColor"
    />
    <path
      d="M10.5266 7.70286C11.165 7.70286 11.6843 7.16177 11.6843 6.49669V1.20617C11.6843 0.541086 11.165 0 10.5266 0H5.44896C4.81053 0 4.29121 0.541086 4.29121 1.20617V6.49669C4.29121 7.16177 4.81053 7.70286 5.44896 7.70286H10.5266ZM4.69408 6.49669V1.20617C4.69408 0.772656 5.03269 0.419852 5.44899 0.419852H10.5266C10.9429 0.419852 11.2815 0.772656 11.2815 1.20617V6.49669C11.2815 6.9302 10.9429 7.283 10.5266 7.283H5.44899C5.0328 7.283 4.6942 6.93032 4.6942 6.49669H4.69408Z"
      fill="currentColor"
    />
    <path
      d="M7.05714 4.63164H8.91938L9.29184 5.51306H9.96228L8.32355 1.63781H7.65311L6.01439 5.51306H6.68482L7.05714 4.63164ZM7.98816 2.43082L8.6641 4.02746H7.31253L7.98816 2.43082Z"
      fill="currentColor"
    />
    <path
      d="M14.8422 8.29721H9.76463C9.12619 8.29721 8.60688 8.83829 8.60688 9.5035V14.7937C8.60688 15.4589 9.12619 16 9.76463 16H14.8422C15.4807 16 16 15.4589 16 14.7937V9.5035C16 8.83829 15.4807 8.29721 14.8422 8.29721ZM15.5972 14.7939C15.5972 15.2277 15.2586 15.5805 14.8422 15.5805L9.76463 15.5804C9.34833 15.5804 9.00972 15.2276 9.00972 14.7938L9.00984 9.50359C9.00984 9.06983 9.34844 8.71703 9.76475 8.71703H14.8424C15.2587 8.71703 15.5973 9.06983 15.5973 9.50359L15.5972 14.7939Z"
      fill="currentColor"
    />
    <path
      d="M12.6071 10.484C13.0571 10.484 13.334 10.6291 13.666 10.9609L14.0649 10.5062C13.6783 10.0628 13.1967 9.84092 12.6202 9.84092C11.4497 9.84092 10.6224 10.7123 10.6224 11.8588C10.6224 13.0386 11.4687 13.8546 12.5777 13.8546C13.1401 13.8546 13.618 13.6274 14.0116 13.1727L13.6074 12.7404C13.2231 13.1149 12.939 13.2169 12.559 13.2169C11.8888 13.2169 11.2767 12.6552 11.2767 11.8339C11.2767 11.0249 11.8706 10.484 12.6071 10.484Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
