import React from "react";

import { DateDropdown } from "./styled";
import { TextLg, TextMd, TextSm } from "../../styled";
import { BaseSelectField } from "../../../../../../../../common/components/Form/SelectField";
import { useMilestone } from "../../../../context/MilestoneContext";
import IconButton from "../../../../../../../../common/components/Buttons/IconButton";
import AddIcon from "../../../../../../../../common/components/icons/Line/Add/02";
import TwoColumnCell from "./TwoColumnCell";
import EditableText from "../../../EditableText";
import LoadingAnimationGif from "../../../../../../../../assets/rolling-animation-green.gif";

const DeliverablesCell = (props) => {
  const { table, cell, column, row, getValue } = props;
  const { addSubRowHandler, eventchangeHandler, eventsList, loaders } =
    useMilestone();

  const depth = row.depth;
  const isRowHover = row.getIsRowHovered();

  const value = getValue()?.label || "";

  const isRowCreating = loaders.sectionLoaders[row.id] || false;

  return (
    <TwoColumnCell className="deliverable-cell">
      <TwoColumnCell.Left>
        <TwoColumnCell.TextContent>
          <TextRender value={value} {...props} />
        </TwoColumnCell.TextContent>

        <TwoColumnCell.IconContent>
          {depth === 0 || (isRowHover && depth === 1) ? (
            <>
              {!isRowCreating ? (
                <IconButton
                  className="add-btn icon-hover"
                  icon={<AddIcon />}
                  btnIconSize={12}
                  btnSize={20}
                  onClick={(e) => {
                    e.preventDefault();
                    addSubRowHandler(row);
                  }}
                />
              ) : (
                <div className="rolling-loader">
                  <img src={LoadingAnimationGif} alt="" />
                </div>
              )}
            </>
          ) : null}
        </TwoColumnCell.IconContent>
      </TwoColumnCell.Left>

      <TwoColumnCell.Right>
        {depth === 0 || depth === 1 ? (
          <DateDropdown>
            <BaseSelectField
              // autoFocus={cell.getIsCellFocus()}
              value={
                eventsList?.filter((s) => s.value === row?.original?.eventId) ||
                null
              }
              placeholder={"Select"}
              options={eventsList}
              onChange={(e) => {
                eventchangeHandler(row, e, cell);
              }}
            />
          </DateDropdown>
        ) : null}
      </TwoColumnCell.Right>
    </TwoColumnCell>
  );
};

function TextRender({ row, cell, value }) {
  const depth = row.depth;
  const hasSubRow = !!row.subRows.length;
  const { onDeliverableNamechangeHandler } = useMilestone();
  const [delivarableName, setDelivarableName] = React.useState(value);

  const textField = React.useMemo(() => {
    return (
      <EditableText
        placeholder="Deliverable Name"
        value={delivarableName}
        onBlur={() => {
          onDeliverableNamechangeHandler(row, delivarableName);
        }}
        onChange={(val) => {
          setDelivarableName(val);
        }}
      />
    );
  }, [delivarableName, onDeliverableNamechangeHandler, row]);

  if (depth === 0) {
    return <TextLg>{textField}</TextLg>;
  }

  if (depth === 1 && hasSubRow) {
    return <TextMd>{textField}</TextMd>;
  }

  return <TextSm>{textField}</TextSm>;
}

export default DeliverablesCell;
