import { Tooltip } from "react-bootstrap";
import styled from "styled-components";

export const StyledStatusIconWrapper = styled.div`
  width: ${({ theme }) => theme.sizes(6)};
  height: ${({ theme }) => theme.sizes(6)};
  border-radius: ${({ theme }) => theme.rounded("full")};
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ statusColor }) => statusColor || "#000"};

  .top__circle {
    width: ${({ theme }) => theme.sizes(2)};
    height: ${({ theme }) => theme.sizes(2)};
    border-radius: ${({ theme }) => theme.rounded("full")};
    background-color: ${({ statusColor }) => statusColor || "#000"};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -2px;
    right: 0px;
  }

  .icon__wr {
    color: ${({ statusColor }) => statusColor || "#000"};
    font-size: ${({ theme }) => theme.sizes(3)};
  }
`;

export const StyledTooltip = styled(Tooltip)`
  z-index: 999;
  background-color: #fff;
  min-width: 90px;
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(3)}`};
  box-shadow: 0px 3px 6px #00000017;
  color: ${({ theme }) => theme.textColors.primary()};
  font-size: ${({ theme }) => theme.fonts.small};
  border-radius: ${({ theme }) => theme.rounded("sm")};
  font-weight:${({ theme }) => theme.fontWeight.medium};
  
`;
