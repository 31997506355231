import React from "react";
import ActionButton from "../../../../../Buttons/ActionButton";
import TableList from "../../../../../TableList";

import { styleClass } from "../../../../../../../utility/common";
import { useUserRightStore } from "../../../../../../../store/Setting/Permission/UserRightStore";

import "./styles.scss";
import TableRightActionBody from "../../TableRightAction/TableRightActionBody";
import TableRightActionHeader from "../../TableRightAction/TableRightActionHeader";
import Alert from "../../../../../../../utility/alert";
import { useRouteLoaderData } from "react-router-dom";
import { ROUTE_OPTIONS } from "../../../../../../../constant/route-options";
import { SETTING_PERMISSION_MODULES } from "../../../../../../../constant/setting_permission_module";
import { Permission } from "../../../../../../../helpers/permission";

const columns = [
  {
    label: "Role",
    name: "role",
    width: "43%",
  },
  {
    label: <TableRightActionHeader />,
    name: "rights",
    sortable: false,
    width: "22%",
  },
  {
    label: "Right Access Action",
    name: "rightAction",
    width: "25%",
  },
  {
    label: "Action",
    name: "action",
    sortable: false,
    width: "10%",
  },
];

const UserRightTable = () => {
  const { permission } = useRouteLoaderData(
    ROUTE_OPTIONS.setting.children["tenant-permission"].id
  );
  const moduleType = SETTING_PERMISSION_MODULES.TenantPermission;
  const permissions = new Permission(permission);
  const userRightPermission = permissions.findByName(
    moduleType.AssignUserRights.key
  );

  const data = useUserRightStore((state) => state.data);

  const onLoadRoleRightData = useUserRightStore(
    (state) => state.onLoadRoleRightData
  );

  const onDelete = useUserRightStore((state) => state.onDelete);

  const onAccessOrDenied = useUserRightStore((state) => state.onAccessOrDenied);

  const onUpdateTableRowData = useUserRightStore(
    (state) => state.onUpdateTableRowData
  );

  const onPermissionChanged = useUserRightStore(
    (state) => state.onPermissionChanged
  );

  const btnAccessStatus = (rights) => {
    if (!rights || !rights?.value) {
      return "";
    }

    const AllActiveStatus = Object.entries(rights.value).every(
      (k) => k[1] === true
    );
    const SomePendingStatus = Object.entries(rights.value).some(
      (k) => k[1] === true
    );

    return AllActiveStatus === true
      ? "active"
      : SomePendingStatus
      ? "pending"
      : "";
  };

  const btnDeniedStatus = (rights) => {
    //
    if (!rights || !rights?.value) {
      return "";
    }

    const AllActiveStatus = Object.entries(rights.value).every(
      (k) => k[1] === false
    );

    return AllActiveStatus === true ? "active" : "";
  };

  const RightsButton = ({ onClick, className, label }) => {
    return (
      <span
        className={styleClass("table__btn-action", className)}
        onClick={onClick}
      >
        {label}
      </span>
    );
  };

  const onUpdateHandler = (id) => {
    onUpdateTableRowData(id)
      .then((res) => {
        console.log(res);
        // toast.success(res.displayMessage);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onDeleteHandler = (id) => {
    Alert.confirm({
      title: "Are you sure?",

      text: "You want to complete this",

      confirmButtonText: "Yes, Complete",
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(id)
          .then((res) => {
            console.log(res);
            // toast.success(res.displayMessage);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };
  const tableRenderRows = (rowData, index) => {
    const { role, rights, rightAction, action, id } = rowData;

    return (
      <TableList.Row key={index}>
        <TableList.Column width={role.style.width}>
          {role.value.name}
        </TableList.Column>

        <TableList.Column width={rights?.style.width}>
          <TableRightActionBody
            permissionState={rights?.value}
            onUpdated={(val) => {
              onPermissionChanged(val, id.value);
            }}
          />
        </TableList.Column>

        <TableList.Column
          className="action-col"
          width={rightAction.style.width}
        >
          <RightsButton
            label="Access All"
            className={btnAccessStatus(rights)}
            onClick={() => onAccessOrDenied(id.value, "Access")}
          />

          <RightsButton
            label="Denied All"
            className={btnDeniedStatus(rights)}
            onClick={() => onAccessOrDenied(id.value, "Denied")}
          />
        </TableList.Column>

        <TableList.Column width={action.style.width}>
          {/* Edit */}
          {userRightPermission.permission.canEdit ? (
            <ActionButton
              variant="UPDATE"
              className="action-update"
              onClick={() => onUpdateHandler(id.value)}
            />
          ) : null}

          {/* Delete */}
          {userRightPermission.permission.canDelete ? (
            <ActionButton
              variant="DELETE"
              onClick={() => onDeleteHandler(id.value)}
            />
          ) : null}
        </TableList.Column>
      </TableList.Row>
    );
  };

  return (
    <div className="setting-details setting__details--role">
      <TableList columns={columns} data={data} renderRows={tableRenderRows} />
    </div>
  );
};

export default UserRightTable;
