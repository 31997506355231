import styled from "styled-components";

export const StyledVideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
  }
`;
