import styled from "styled-components";
import { fontStyle } from "../../../../../../../../../styled/utility";

export const SelectorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--layout-spacing-2, 2px);

  .number {
    ${fontStyle("x-small-regular")};
    width: var(--layout-size-16);
    min-width: var(--layout-size-16);
    text-align: center;
  }

  .iconButton {
    /* --icon-button-size: none; */

    &:disabled {
      opacity: 0.3;
    }

    .button__icon {
      color: var(--font-tint-04);
    }
  }
`;
