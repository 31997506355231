import styled from "styled-components";

export const Container = styled.div`
  padding:  0;
  --calendar-height: 322px;

  .cln__event__inner {
    display: flex;
    flex: 1;
    /* align-items: stretch; */
    width: 100%;
    max-width: 100%;
    min-width: 0;
    height: var(--calendar-height);

    .start,
    .end {
      /* display: flex; */
    }

    .end {
      overflow: hidden;
      flex: 1;
      display: flex;
    }

    .divider {
      width: 1px;
      min-height: 100%;
      max-height: 100%;
      background: var(--color-accent-75, #bae1e4);
      margin: 0 var(--layout-spacing-16);
    }
  }

  .cln__event__note {
    /* display: flex; */
    margin-top: var(--layout-spacing-20);
  }
`;
