//
export const ProjectRoutePath = {
  id: "projects",
  path: "/projects",
  label: "Projects",

  NewProject: {
    path: "",
    id: "new-project",
    label: "New Project",
  },

  Onboarding: {
    path: "onboarding",
    id: "onboarding",
    label: "Onboarding",
    child: {
      // Onboarding sub links
      AllMembers: {
        path: "all-members",
        id: "all-members",
        label: "All Members",
      },

      ShortlistedList: {
        path: "shortlisted-list",
        id: "shortlisted-list",
        label: "Shortlisted List",
      },

      OnboardingList: {
        path: "onboarding-list",
        id: "onboarding-list",
        label: "Onboarding List",
      },

      HRList: {
        path: "hr-list",
        id: "hr-list",
        label: "HR List",
      },

      Favorites: {
        path: "favorites",
        id: "favorites",
        label: "Favorites",
      },
    },
  },

  ProjectBrief: {
    path: "brief/:id",
    id: "project-brief",
    label: "Project Brief",
  },
  // projectDashBoard: {
  //   path: "dashboard/:id",
  //   id: "project-dashboard",
  //   label: "Project dashboard",
  // },
};
