import {
  action_color_1,
  action_color_2,
  action_color_3,
  action_color_4,
  black,
  color1,
  color2,
  primary,
  primary_dark,
  primary_light,
  secondary,
  white,
  bg,
} from "./colors";

export const Media = {
  max: {
    xs: `@media screen and (max-width: 0)`,
    sm: `@media screen and (max-width: 576px)`,
    md: `@media screen and (max-width: 768px)`,
    lg: `@media screen and (max-width: 992px)`,
    xl: `@media screen and (max-width: 1200px)`,
    xxl: `@media screen and (max-width: 1400px)`,
  },
};

export const ContainerMaxWidth = {
  sm: "540px",
  md: "720px",
  lg: "960px",
  xl: "1140px",
  xxl: "1320px",
};

export const roundedTypes = {
  xs: "0.125rem", // 2px
  sm: "0.25rem", // 4px
  md: "0.5rem", // 8px
  lg: "0.75rem", // 12px
  xl: "1rem", //16px
  "2xl": "1.25rem", //20px
  "3xl": "1.5rem", //24px
  "4xl": "1.75rem", //28px
  full: "999px",
};

export const Theme = {
  colors: {
    black: black,
    white: white,

    bg: bg,

    // Green
    primary: primary,

    // Green light
    primary_light: primary_light,

    // Green 2
    primary_dark: primary_dark,

    // Orange
    secondary: secondary,

    // Gary Dark
    color_1: color1,

    // Beige
    color_2: color2,

    // Action/Blue/60
    action_color_1: action_color_1,

    // Action/Yellow/Base
    action_color_2: action_color_2,

    // Action/Light Yellow/Base
    action_color_3: action_color_3,

    // Action/Red/Base
    action_color_4: action_color_4,
  },

  textColors: {
    black: black,
    white: white,

    // Gray dark(Black)
    primary: color1,

    // Green light
    primary_light: primary_light,

    //Orange
    secondary: secondary,

    // Action/Red/Base
    color_1: action_color_4,
  },

  actionColors: {
    black: black,
    white: white,

    // Gray dark(Black)
    action_color_0: color1,

    // Action/Blue/60
    action_color_1: action_color_1,

    // Action/Yellow/Base
    action_color_2: action_color_2,

    // Action/Light Yellow/Base
    action_color_3: action_color_3,

    // Action/Red/Base
    action_color_4: action_color_4,

    // Beige
    action_color_5: color2,

    // Green light
    action_color_6: primary_light,
  },

  fontFamily: {
    primary: '"Roboto", "Helvetica Neue", sans-serif',
  },

  fonts: {
    textH5: "1.25rem", // 20px
    textH6: "1.125rem", // 18px
    textLarge: "1rem", // 16px
    textNormal: "0.875rem", // 14px
    textSmall: "0.75rem", // 12px
    textXSmall: "0.625rem", // 10px
    textLabel: "0.703rem", // 11px
    textInput: "0.703rem", // 11px
    textCaption: "0.703rem", // 11px

    //  old
    h1: "1.269rem", // 20ppx
    h2: "1.125rem", // 18px
    h3: "1rem", // 16px
    title: "1rem", // 16px
    base: "0.888rem", // 14px
    body1: "0.888rem", // 14px
    body2: "0.788rem", // 12px
    body3: "0.703rem", // 11.24px
    label: "0.703rem", // 11.24px
    input: "0.703rem", // 11.24px
    small: "0.714rem", // 9px
  },

  fontWeight: {
    light: 300,
    regular: 400,
    medium: 600,
    semibold: 600,
    bold: 700,
  },

  spacing: (size = 0) => {
    if (!size) return `var(--spacing-0)`;
    // var(--spacing-2)
    if (typeof size === "string") {
      return `var(--spacing-${size})`;
    }

    let _size = size.toString().replace(".", "-");

    return 0 < size
      ? `var(--spacing-${_size})`
      : `var(--spacing${_size}-minus)`;
  },

  sizes: (size = 0) => {
    if (!size) return `var(--size-0)`;

    if (typeof size === "string") {
      return `var(--size-${size})`;
    }

    let key = size.toString().replace(".", "-");

    return `var(--size-${key})`;
  },

  rounded: (size = "xs") => {
    return `var(--rounded-${size})`;
  },

  letterSpacing: {
    0: "0px",
    1: "-0.03rem",
  },

  animate: {
    primary: "all ease-in-out 0.3s",
  },

  borders: (variant = "px1") => {
    const types = {
      px1: "1px solid",
      0.5: "0.031rem solid", // 0.5px,
      1: "0.063rem solid", // 1px,
    };

    return types[variant] ? types[variant] : null;
  },

  shadow: {},
};
