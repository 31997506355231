import styled from "styled-components";
import { fontStyle } from "../../../../../../../styled/utility";

export const Container = styled.div`
  padding-left: var(--layout-spacing-32);
  padding-right: var(--layout-spacing-32);
  padding-top: var(--layout-spacing-4);
  margin-bottom: var(--layout-spacing-28);

  .headers {
    display: flex;
    width: 100%;
    align-items: center;

    .start,
    .end {
      display: flex;
    }

    .start {
      flex: 1;
      min-width: 0;
      flex-direction: column;
    }

    .edit__button {
      --icon-button-icon-color: var(--font-tint-04);

      &:hover {
        --icon-button-icon-color: var(--font-tint-02);
      }
    }
  }
  .main__header {
    color: var(--font-color-shade-accent);
    ${fontStyle("large-semi-bold")}
  }

  .sub__header {
    color: var(--font-tint-04);
    ${fontStyle("x-small")}
  }

  .form__card {
    width: 100%;
    background-color: var(--layout-background-n20);
    border-radius: var(--layout-border-radius-8);
    margin-top: var(--layout-spacing-4);

    .form__wrapper {
      padding: var(--layout-spacing-16);
    }

    .form__button__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: var(--layout-spacing-16);

      .left__button {
        display: flex;
        gap: 10px;
      }

      .right__button {
        display: flex;
        gap: 10px;
      }
    }
  }
`;
