import React from "react";
import * as Styled from "./styled";
import InfoBlock from "./components/InfoBlock";
import { format } from "date-fns";
import AddColumnPopover from "./components/Popovers/AddColumnPopover";
import HeaderButton from "./components/HeaderButton";

const Header = ({ projectData }) => {
  const { duration, startDate } = projectData;

  const [addColumnEle, setAddColumnEle] = React.useState(null);

  return (
    <Styled.HeaderContainer>
      <Styled.LeftBlockWrapper>
        <InfoBlock
          title="Duration & Episodes"
          content={`${duration}Min - Feature`}
        />

        <InfoBlock
          title="Start Date"
          content={format(new Date(startDate), "dd-LLL-yyyy")}
        />

        {/* <InfoBlock title="Client" content="Netflix" /> */}
      </Styled.LeftBlockWrapper>

      <Styled.RightBlockWrapper>
        <div className="textFrame">
          <div>There are some conflicts between holydays</div>
          <div className="textLink">click here </div>
          <div>to highlight</div>
        </div>

        <div className="actions">
          <HeaderButton
            label="Add Column"
            onClick={(e) => setAddColumnEle(e.currentTarget)}
          />
        </div>
      </Styled.RightBlockWrapper>

      {/* popovers */}
      <AddColumnPopover
        anchorEl={addColumnEle}
        onOutsideClick={() => {
          setAddColumnEle(null);
        }}
      />
    </Styled.HeaderContainer>
  );
};

export default Header;
