export const SETTING_PERMISSION_MODULES = {
  DataSetup: {
    language: {
      key: "language",
    },
    currency: {
      key: "currency",
    },
    country: {
      key: "country",
    },
    region: {
      key: "region",
    },
    region_countries: {
      key: "region_countries",
    },
  },

  Client: {
    basic: {
      key: "basic",
    },
    region: {
      key: "region",
    },
    region_countries: {
      key: "region_countries",
    },
  },

  Company: {
    basic: {
      key: "basic",
    },
    department: {
      key: "department",
    },
  },

  ProjectSetup: {
    genre: {
      key: "genre",
    },
  },

  MyProfile: {
    basic: {
      key: "basic",
    },
  },

  TenantClient: {
    basic: {
      key: "basic",
    },
    region: {
      key: "region",
    },
    region_countries: {
      key: "region_countries",
    },
  },

  TenantCompany: {
    basic: {
      key: "basic",
    },
    department: {
      key: "department",
    },
  },

  TenantProject: {
    project: {
      key: "project",
    },
  },

  TenantPermission: {
    Role: {
      key: "role",
    },
    UserRole: {
      key: "user_role",
    },
    AssignRoleRights: {
      key: "assign_role_rights",
    },
    AssignUserRights: {
      key: "assign_user_rights",
    },
    RoleMenu: {
      key: "role_menu",
    },
    UserMenu: {
      key: "user_menu",
    },
  },

  Templates: {
    project: {
      key: "project",
    },
    AssignProject: {
      key: "assign_project",
    },
    ExistingProject: {
      key: "existing_project",
    },
  },
};
