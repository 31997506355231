import React from "react";
// import { useSettingContext } from "../../../../../../../../shared/context/SettingContext";
import { Modal } from "./styled";
import Form from "./Form";
import { useActivityBlock } from "../../../context/ActivityBlockContext";
import { saveBlockWithActivities } from "../../../../../../../../api/schedule";
import { useQueryClient } from "react-query";
import { BlockListKey } from "../../Tables/BlockTable/index";
import { useAppStore } from "../../../../../../../../../../store/AppStore/AppStore";

const BlockModal = ({ ...otherProps }) => {
  // const { projectId, variant } = useSettingContext();
  const { blockModalData, BlockModal: BModal } = useActivityBlock();
  const queryClient = useQueryClient();
  const isEditMode = blockModalData.payload?.type === "EDIT";
  const isOpen = blockModalData.visibility;
  const hideLoader = useAppStore((state) => state.hideLoader);
  const showLoader = useAppStore((state) => state.showLoader);

  const renderModalTitle = React.useMemo(() => {
    if (!isEditMode) {
      return "Add Block";
    }

    return "Edit Block";
  }, [isEditMode]);

  const handleSubmit = (val, actions) => {
    showLoader();
    saveBlockWithActivities(val).then(() => {
      queryClient.refetchQueries([BlockListKey]);
      BModal.close();
      hideLoader();
    });
  };

  return (
    <Modal
      //   isLoading={false}
      title={renderModalTitle}
      subTitle="Manage your Block specific details here"
      visibility={isOpen}
      body={<BodyComponent handleSubmit={handleSubmit} />}
      onClose={BModal.close}
      {...otherProps}
    />
  );
};

function BodyComponent({ handleSubmit, formData }) {
  return <Form onSubmit={handleSubmit} />;
}

export default BlockModal;
