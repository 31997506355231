import React from "react";
import { motion } from "framer-motion";

import { css } from "../../../../utility";
import "./tab-base-styles.scss";

const variants = {
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
};

const TabPanel = (props) => {
  TabPanel.displayName = "TAB_PANEL";
  const ref = React.useRef();

  const { children, className, isActive } = props;

  const isActiveClass = isActive === true ? "is--active" : "";

  return (
    <>
      {isActive ? (
        <motion.div
          ref={ref}
          key="tab_panel"
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={variants}
          className={css("tab__base__panel", isActiveClass, className)}
        >
          {children}
        </motion.div>
      ) : null}
    </>
  );
};

export default TabPanel;
