import styled from "styled-components";
import { fontStyle } from "../../../../../../../styled/utility";

export const TabsWarper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: var(--layout-spacing-20);

  .tab__nav {
    /* min-width: 0; */
    padding-right: var(--layout-spacing-24);
    border-right: 1px solid var(--layout-background-primary);

    &--list {
      display: flex;
      margin: 0;
      padding: 0;
      gap: var(--layout-spacing-32);
      flex-direction: column;
    }

    &--item {
      padding: 0;
      max-width: var(--layout-size-80);
      width: auto;
      height: var(--layout-size-40);
      display: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: var(--color-primary-75);
      cursor: pointer;
      /* font-size: ;
      line-height: 14px; */
      transition: var(--animate-primary);
      user-select: none;
      text-align: center;
      ${fontStyle("x-small-medium")};
      /* word-break: break-all; */

      .icon {
        --icon-size: var(--layout-size-24);
        --icon-font-size: var(--layout-size-24);
        color: var(--color-primary-75);
        margin-bottom: var(--layout-spacing-2);
      }

      &.visible {
        display: inline-flex;

        &.active {
          /* background-color: var(--color-accent-75); */
          color: var(--color-primary-400);

          .icon {
            color: var(--color-secondary-300);
          }
        }
      }
    }
  }

  .tab__panels {
    padding-left: var(--layout-spacing-28);
    flex-grow: 1;
  }
`;

export const TabPanel = styled.div`
  display: none;

  &.visible.active {
    display: block;
  }
`;
