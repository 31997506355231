import React from "react";

// import Iframe from "react-iframe";
import { FreeMode, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
import { StyledSlideContainer } from "./styled";

const Slider = (props) => {
  const { data = [] } = props;

  const [thumbsSwiper, setThumbsSwiper] = React.useState(null);

  const [sliderOptions] = React.useState({
    thumbContainerWidth: "100%",
    thumbSize: "54px",
    slidesPerView: 7,
    thumbsCenteredSlides: true,
  });

  const renderImages = React.useMemo(() => {
    return data.map((_data, i) => {
      let item = null;

      switch (_data?.type) {
        case "image": {
          item = <img alt="" src={_data.url} />;
          break;
        }
        case "pdf": {
          item = (
            <>
              {/* <Iframe
                url="https://www.africau.edu/images/default/sample.pdf"
                width="100%"
                height="100%"
                className="slide__iframe"
                display="block"
                position="relative"
              /> */}
            </>
          );
          break;
        }
        default:
          return item;
      }

      return item ? <SwiperSlide key={i}>{item}</SwiperSlide> : null;
    });
  }, [data]);

  const renderThumbnails = React.useMemo(() => {
    
    if (data.length <= 1) {
      return null;
    }

    return data.map((img, i) => (
      <SwiperSlide key={i}>
        <img alt="" src={img?.thumbnail} />
      </SwiperSlide>
    ));
  }, [data]);

  return (
    <StyledSlideContainer
      thumbContainerWidth={sliderOptions.thumbContainerWidth}
      thumbSize={sliderOptions.thumbSize}
    >
      <Swiper
        spaceBetween={0}
        navigation={false}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Thumbs]}
        className="slide"
      >
        {renderImages}
      </Swiper>

      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={4}
        slidesPerView={sliderOptions.slidesPerView}
        freeMode={true}
        modules={[FreeMode, Thumbs]}
        className="thumbs__wrapper"
        centeredSlides={sliderOptions.thumbsCenteredSlides}
        centeredSlidesBounds={sliderOptions.thumbsCenteredSlides}
        centerInsufficientSlides={sliderOptions.thumbsCenteredSlides}
        // loop={true}
      >
        {renderThumbnails}
      </Swiper>
    </StyledSlideContainer>
  );
};

export default Slider;
