import * as React from "react";
import { memo } from "react";
const SvgIconSearch = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.30303 6.58304L9.85126 9.13177C10.0495 9.33005 10.0502 9.65089 9.84962 9.85147C9.65047 10.0507 9.32469 10.0478 9.13007 9.85311L6.58183 7.30438C4.98127 8.55027 2.66621 8.43749 1.19495 6.96604C-0.398317 5.37247 -0.398317 2.78876 1.19495 1.19518C2.78822 -0.398394 5.37143 -0.398394 6.96469 1.19518C8.43586 2.66663 8.54861 4.98223 7.30296 6.5831L7.30303 6.58304ZM6.24353 6.24467C7.4385 5.04946 7.4385 3.11175 6.24353 1.91654C5.04855 0.721339 3.11122 0.721339 1.91624 1.91654C0.72127 3.11175 0.72127 5.04946 1.91624 6.24467C3.11122 7.43987 5.04855 7.43987 6.24353 6.24467Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgIconSearch);
export default Memo;
