import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.2493 10.4672L10.3166 10.3663C10.552 9.99637 10.922 9.74411 11.3592 9.67685C10.922 8.66783 10.0811 7.86062 9.05529 7.47383C8.34898 8.16333 7.39042 8.60057 6.34777 8.60057C5.30513 8.60057 4.36338 8.18014 3.65707 7.47383C2.0931 8.07924 1 9.57595 1 11.3417V12.788C1 13.0234 1.2018 13.2252 1.43724 13.2252H8.0631V12.0648C8.0631 11.1904 8.78622 10.4672 9.6607 10.4672H10.2493Z"
      fill="currentColor"
    />
    <path
      d="M3.20301 4.34589C3.20301 6.21257 4.61563 7.7429 6.34777 7.7429C8.07991 7.7429 9.49253 6.21257 9.49253 4.34589C9.49253 4.16091 9.49253 3.99274 9.47571 3.80775C9.98022 2.32786 9.72797 -0.362839 8.76941 0.0407669C7.92856 0.410739 3.35437 -0.766444 3.62344 1.1675C2.54715 1.20113 3.10211 3.32006 3.21983 3.79093C3.21983 3.97592 3.20301 4.16091 3.20301 4.34589ZM4.16158 4.44679C4.27929 4.44679 4.3802 4.36271 4.36338 4.24499C4.29611 3.74048 4.21203 2.4624 4.64927 2.58012C5.20422 2.73147 5.80963 2.88282 6.34777 2.88282C6.88591 2.88282 7.49132 2.71465 8.04628 2.58012C8.48352 2.4624 8.39943 3.74048 8.33217 4.24499C8.31535 4.36271 8.41625 4.46361 8.53397 4.44679L8.71895 4.42998C8.68532 5.8426 7.62586 6.96933 6.34777 6.96933C5.06969 6.96933 4.02704 5.8426 3.97659 4.42998L4.16158 4.44679Z"
      fill="currentColor"
    />
    <path
      d="M15.1598 11.3249H14.3358C14.1844 11.3249 14.0499 11.2576 13.9826 11.1231L13.7977 10.8204C13.6799 10.6186 13.4613 10.5177 13.2259 10.5177H11.6115C11.376 10.5177 11.1742 10.6354 11.0397 10.8372L10.8547 11.1399C10.7706 11.2576 10.6361 11.3417 10.5015 11.3417H9.67752C9.27391 11.3417 8.93757 11.6781 8.93757 12.0817V15.2601C8.93757 15.6637 9.27391 16 9.67752 16H15.1598C15.5634 16 15.8998 15.6637 15.8998 15.2601V12.0648C15.8998 11.6612 15.5634 11.3249 15.1598 11.3249ZM12.4019 15.2096C11.4769 15.2096 10.7202 14.4528 10.7202 13.5279C10.7202 12.603 11.4769 11.8462 12.4019 11.8462C13.3268 11.8462 14.0835 12.603 14.0835 13.5279C14.1004 14.4528 13.3436 15.2096 12.4019 15.2096Z"
      fill="currentColor"
    />
    <path
      d="M12.4019 12.5694C11.8805 12.5694 11.4433 12.9898 11.4433 13.5279C11.4433 14.0661 11.8637 14.4865 12.4019 14.4865C12.9232 14.4865 13.3604 14.0661 13.3604 13.5279C13.3604 12.9898 12.94 12.5694 12.4019 12.5694Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
