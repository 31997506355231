
import { axiosInstance } from "./axios-instance";

const instance = new axiosInstance(process.env.REACT_APP_API_TEMPLATE_URL);

export default instance;


// import axios from "axios";
// import SESSION from "./session.js";

// const instance = axios.create({
//   baseURL: process.env.REACT_APP_API_TEMPLATE_URL,
// });

// const session = new SESSION();
// console.log("session", session);
// let auth = "";
// if (session.USER !== null) {
//   auth = session.USER.result.authenticationInfo.access_token;
// }
// Alter defaults after instance has been created
//instance.defaults.headers.common['Authorization'] = 'Bearer <token>';
// instance.defaults.headers.common["Authorization"] = "Bearer " + auth;
// instance.defaults.headers.post["Content-Type"] = "application/json";
// instance.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
//instance.defaults.headers.post['Access-Control-Allow-Methods'] = session.USER.result.authenticationInfo.access_token;
// instance.defaults.headers.post['Access-Control-Allow-Headers'] = 'X-Token';

// instance.defaults.timeout = 2500;

// Override timeout for this request as it's known to take a long time
// instance.get('/longRequest', {
//   timeout: 5000
// });
// export default instance;
