export const css = (...arg) => {
  return arg.filter((cls) => cls !== undefined).join(" ");
};

export const styleClass = (...arg) => {
  return arg.filter((cls) => cls !== undefined).join(" ");
};

export const validatePassword = (val) => {
  return /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(val);
};

export const addEvent = (event, callback) => {
  callback && callback();
  window.addEventListener(event, callback);

  return () => {
    window.removeEventListener(event, callback);
  };
};

export function createUID() {
  return Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substr(0, 10);
}

export const hasClassName = (ele, className) => {
  do {
    if (ele && ele?.classList && ele?.classList?.contains(className)) {
      return true;
    }

    ele = ele.parentNode;
  } while (ele);

  return false;
};

export const findParentByClass = (ele, className) => {
  do {
    if (ele && ele?.classList && ele?.classList?.contains(className)) {
      return ele;
    }

    ele = ele.parentNode;
  } while (ele);

  return null;
};

export const isEqualElement = (ele, matchElement) => {
  const validate = () => {
    do {
      if (ele && ele.isEqualNode(matchElement)) {
        return ele;
      }

      ele = ele.parentNode;
    } while (ele);

    return null;
  };

  return !!validate();
};

export const findElementByAttr = (ele, attr) => {
  do {
    if (ele && ele?.classList && ele?.dataset[attr]) {
      return ele;
    }

    ele = ele.parentNode;
  } while (ele);

  return null;
};

export const onRefParser = (node, fRef, localRef) => {
  localRef.current = node;

  if (typeof fRef === "function") {
    fRef(node);
  } else if (fRef) {
    fRef.current = node;
  }
};

export const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getDateWithoutOffset = (isoStr1) => {
  if (!isoStr1 || !Date.parse(isoStr1)) return new Date();

  const date = new Date(isoStr1);
  const timestampWithOffset = date.getTime();
  const offset = date.getTimezoneOffset() * 60 * 1000;
  const timestampWithoutOffset = timestampWithOffset - offset;

  return new Date(timestampWithoutOffset);
};

export function getContrastYIQ(hexcolor, light = "#ffffff", dark = "#000000") {
  if (!/^#[0-9A-F]{6}$/i.test(hexcolor)) {
    return "black";
  }

  const r = parseInt(hexcolor.substring(1, 3), 16);
  const g = parseInt(hexcolor.substring(3, 5), 16);
  const b = parseInt(hexcolor.substring(5, 7), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? dark : light;
}
