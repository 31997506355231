import React from "react";

const useFileUploader = ({
  onFileUpload,
  allowTypes = ["image/png", "image/jpeg"],
  maxFileSize = 2,
  name = "file_upload",
}) => {
  const [isDrop, setIsDrop] = React.useState(false);
  const [isDragStart, setIsDragStart] = React.useState(false);
  const [isDragOver, setIsDragOver] = React.useState(false);
  const [error, setError] = React.useState("");
  const [uploadedFiles, setUploadedFiles] = React.useState([]);

  const maxAllowedSize = Math.abs(maxFileSize) * 1024 * 1024;

  const onChangeHandler = React.useCallback(
    async (e) => {
      e.preventDefault();
      setError("");
      setUploadedFiles([]);

      const fileList = Array.from(e.target.files);

      const matchFilesWithAllowType = fileList.filter(
        (file) => allowTypes.indexOf(file.type) > -1
      );

      // Check if the file type is allowed
      if (matchFilesWithAllowType.length !== fileList.length) {
        let types = allowTypes.map((type) => type.split("/")[1]).join(", ");
        setError(`Invalid file type. Allowed file types are: ${types}`);
        e.target.value = "";
        return;
      }

      // Check if the file size is exceed
      const matchFilesSizeWithMaxAllowSize = fileList.filter(
        (file) => file.size <= maxAllowedSize
      );

      if (matchFilesSizeWithMaxAllowSize.length !== fileList.length) {
        setError(
          `The file is too large. Allowed maximum size is: ${maxFileSize}MB`
        );
        e.target.value = "";
        return;
      }

      let files = Array.from(e.target.files).map((file) => {
        // Define a new file reader
        let reader = new FileReader();
        let base64Text = ";base64,";

        // Create a new promise
        return new Promise((resolve) => {
          // Resolve the promise after reading file
          reader.onload = () => {
            resolve({
              file: file,
              imageUrl: reader.result,
              imageData: reader.result.substring(
                reader.result.indexOf(base64Text) + base64Text.length
              ),
            });
          };

          // Read the file as a image
          reader.readAsDataURL(file);
        });
      });

      let res = await Promise.all(files);

      setUploadedFiles(res);
    },
    [allowTypes, maxAllowedSize, maxFileSize]
  );

  const onDropHandler = (e) => {
    setIsDrop(true);
    setIsDragOver(false);
  };

  const onDragOverHandler = (e) => {
    setIsDragOver(true);
  };

  const onDragStartHandler = (e) => {
    setIsDragStart(true);
  };

  const onDragLeaveHandler = (e) => {
    setIsDragOver(false);
  };

  const uploadClassName = React.useMemo(() => {
    if (isDragStart) {
      return "is--drag_start";
    }

    if (isDragOver) {
      return "is--darg_over";
    }

    if (isDrop) {
      return "is--drop";
    }

    return "";
  }, [isDrop, isDragOver, isDragStart]);

  React.useEffect(() => {
    if (uploadedFiles.length) {
      onFileUpload && onFileUpload(uploadedFiles);
      setUploadedFiles([]);
    }
  }, [onFileUpload, uploadedFiles]);

  React.useEffect(() => {
    if (name) {
      const inputEl = document.getElementsByName(name)[0];

      if (inputEl) {
        inputEl.accept = allowTypes.join(",");
      }
    }
  }, [name, allowTypes]);

  return {
    error,
    uploadedFiles,
    isDragStart,
    isDrop,
    isDragOver,
    onChangeHandler,
    onDropHandler,
    onDragOverHandler,
    onDragStartHandler,
    onDragLeaveHandler,
    getUploadClasses: uploadClassName,
  };
};

export default useFileUploader;
