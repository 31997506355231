import * as Yup from "yup";

const generateValidations = (field) => {
  //   let schema = Yup[field.typeValue ? field.typeValue : "string"]();

  const type =
    String(field.fieldType().validationType()).toLowerCase() || "string";

  let schema = Yup[type]();

  if (field.isRequired()) {
    if (type === "object") {
      // Select field
      if (field.fieldType().type() === "List") {
        schema = schema.nullable();
      }
    }

    schema = schema.required("This field is required");
  }

  if (!field.isRequired()) {
    schema = schema.nullable();
  }

  if (field.isCustomValidation() && field.validateRegEx()) {
    const regex = new RegExp(field.validateRegEx());

    schema = schema.matches(regex, field.errorMessage());
  }

  return schema;
};

export const getInputs = (fields) => {
  if (!fields) return;

  let initialValues = {};

  let validationsFields = {};

  let inputs = [];

  Object.keys(fields).forEach((key) => {
    const field = fields[key];

    if (!field.name()) return;

    const name = field.name();

    initialValues[name] = field.value();

    const schema = generateValidations(field);

    validationsFields[name] = schema;
    inputs.push(field);
  });

  return {
    validationSchema: Yup.object({ ...validationsFields }),
    initialValues,
    inputs: inputs,
  };
};
