import {
  getAllTemplates,
  getBase64TemplateDocById,
  // getFormFieldValues,
} from "../../../../../Actions/Templates";
import API_ENDPOINTS from "../../../../../services/api-endpoints";
import API_FileUpload from "../../../../../services/api-fileupload";
import API_Template from "../../../../../services/api-template";
import Alert from "../../../../../utility/alert";
import { VIEW_CONTRACT_SOURCES } from "../../../../../constant/Status";
import {
  base64ToArrayBuffer,
  base64ToUint8Array,
  getFileStreamType,
  getMimeType,
} from "./Utils";
import { documentMergeAPI } from "./DocumentProcessingAPIs";

export const refreshDocumentEditorLayout = () => {
  window.TXTextControl.refreshLayout();
};

export const newDocument = () => {
  console.log("New Document");
  window.TXTextControl.resetContents();
};

export const readDocument = (docx, password = null) => {
  if (docx) {
    if (password) {
      window.TXTextControl.loadDocument(
        window.TXTextControl.streamType.WordprocessingML,
        docx,
        (callBack) => {},
        { masterPassword: password }
      );
    } else {
      window.TXTextControl.loadDocument(
        window.TXTextControl.streamType.WordprocessingML,
        docx
      );
    }
  }
};

export const readDocumentByTemplateId = async (templateId) => {
  try {
    const base64TemplateData = await getBase64TemplateDocById(templateId);
    readDocument(base64TemplateData);
  } catch (error) {
    console.log(error);
  }
};

export const fileUploadToServers = async (
  extension,
  templateState,
  setTemplateState
) => {
  const {
    templateId,
    allTemplateTypes,
    show,
    templateTypeId,
    templateName,
    isEditMode,
  } = templateState;
  console.log(isEditMode ? "Update" : "Save", " Document");

  let fileName = templateName + extension;
  var bDocument;
  const endpoints = new API_ENDPOINTS();

  window.TXTextControl.saveDocument(getFileStreamType(fileName), (e) => {
    bDocument = e.data;
    // Send the document data to the server
    const response = API_FileUpload.post(
      endpoints.DOCUMENT_UPLOAD_DOCUMENT_ENDPOINT,
      {
        fileName: fileName,
        containerName: process.env.REACT_APP_TEMPLATES_CONTAINER,
        documentData: e.data,
      }
    ).then((response) => {
      console.log("Template upload response: ", response);
      if (response.data.isSuccess === true) {
        let templateUrl = response.data.blob.uri;
        if (isEditMode) {
          // Update Template
          // templateId !== 0
          const response = API_Template.put(
            endpoints.TEMPLATE_UPDATE + "/" + templateId,
            {
              templateId: templateId,
              globalTemplateId: 0,
              templateTypeId: templateTypeId,
              templateName: templateName,
              templateDescription: "",
              templateFilePath: templateUrl,
            }
          ).then(async (response) => {
            if (response.data.isSuccess === true) {
              console.log("Updated response: ", response);
              // toast.success(response.data.displayMessage);
              await readDocumentByTemplateId(templateId);
            } else {
              console.log(response.data.result.message);
              Alert.confirm({
                allowOutsideClick: false,
                title: "Error!",
                text: response.data.result.message,
                confirmButtonText: "OK",
                icon: "error",
                showCancelButton: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  console.log("yes");
                }
              });
            }
          });
        } else {
          // Create Template
          // templateId === 0
          const response = API_Template.post(endpoints.TEMPLATE_CREATE, {
            templateId: templateId,
            globalTemplateId: 0,
            templateTypeId: templateTypeId,
            templateName: templateName,
            templateDescription: "",
            templateFilePath: templateUrl,
          }).then((response) => {
            console.log("Created response: ", response);
            if (response.data.isSuccess === true) {
              setTemplateState((prevState) => ({
                ...prevState,
                templateId: response.data.result.templateId,
                isSaved: true,
              }));
              setTimeout(() => {
                document
                  .getElementById("btnSaveAndCloseButton")
                  .classList.remove("txui-state-disabled");
              }, 1000);
              // toast.success(response.data.displayMessage);
            } else {
              console.log(response.data.result.message);
              setTemplateState((prevState) => ({
                ...prevState,
                isSaved: false,
              }));
              Alert.confirm({
                allowOutsideClick: false,
                title: "Error!",
                text: response.data.result.message,
                confirmButtonText: "OK",
                icon: "error",
                showCancelButton: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  console.log("yes");
                }
              });
              return;
            }
          });
        }
      }
    });
  });
};

const convertBlobUrlToBase64 = (blobUrl) => {
  // Fetch the Blob using the Blob URL
  fetch(blobUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const fileReader = new FileReader();
      fileReader.onload = function (event) {
        const base64Data = event.target.result.split(",")[1];
        // Now you have the base64 representation of the Blob
        readDocument(base64Data);
      };

      // Read the Blob as data URL
      fileReader.readAsDataURL(blob);
    })
    .catch((error) => console.error("Error fetching Blob:", error));
};

const loadDocFromMergedData = (base64Data, fileName, fileType) => {
  let bytes;
  switch (fileType) {
    case "pdf":
      bytes = base64ToUint8Array(base64Data);
      break;
    case "doc":
    case "docx":
      bytes = base64ToArrayBuffer(base64Data);
      break;
    default:
      console.error("Unsupported file type");
      return;
  }

  const blob = new Blob([bytes], { type: getMimeType(fileType) });
  const link = document.createElement("a");

  link.href = window.URL.createObjectURL(blob);
  link.download = `document.${fileType}`;

  // Append the link to the body for Firefox compatibility
  document.body.appendChild(link);

  // Trigger the download
  // link.click();
  convertBlobUrlToBase64(link);

  // Remove the link from the body
  document.body.removeChild(link);
};

// template     : Base64 string. Supported formats are RTF, DOC, DOCX and TX.
// returnFormat : "PDF", "PDFA", "RTF", "DOC", "DOCX", "HTML" and "TX". Default value is "PDF";
export const mergeDocumentData = async (template, mergeData, returnFormat) => {
  if (template) {
    try {
      const data = documentMergeAPI(returnFormat, mergeData, template);
      loadDocFromMergedData(data, "", returnFormat.toLowerCase());
    } catch (error) {
      console.error("Error:", error);
    }
  }
};

const addMergeField = ({ name, text, numericFormat }) => {
  let mergeField = new window.TXTextControl.MergeField();

  mergeField.name = name;
  mergeField.text = text;
  mergeField.numericFormat = "";

  window.TXTextControl.addMergeField(mergeField);
};

export const CONTEXT_MENU_ITEM_TEXT = {
  FORM_FIELDS: "Form Fields",
  NEW_DOCUMENT: "New Document",
  FILE: "File",
  COMMENT: "Comment...",
};

let contextMenuItem = (values) => {
  const {
    isChecked = false,
    isEnabled = true,
    text,
    imageUrl = "",
    items,
    dropDownIsScrollable = true,
    clickHandler,
  } = values;
  return {
    isChecked,
    isEnabled,
    text: text,
    imageUrl,
    items: items,
    dropDownIsScrollable,
    clickHandler: clickHandler,
  };
};

const generateFormFieldItems = (formFields) => {
  let formFieldItems = [];
  formFields.forEach((item) => {
    const { text, items } = item;
    let fields = [];
    // field.fieldName - Same as the backend DB entry name
    // field.text - Can be modified according to the visualization requirements
    items.forEach((field) => {
      fields.push(
        contextMenuItem({
          text: field.text,
          items: [],
          clickHandler: () =>
            addMergeField({
              name: field.fieldName,
              text: "<<" + field.text + ">>",
            }),
        })
      );
    });
    formFieldItems.push(contextMenuItem({ text, items: fields }));
  });

  return {
    isChecked: false,
    isEnabled: true,
    text: CONTEXT_MENU_ITEM_TEXT.FORM_FIELDS,
    imageUrl: "",
    items: formFieldItems,
    dropDownIsScrollable: true,
    clickHandler: () => {},
  };
};

// For Template builder editor context menu
export const getContextMenuItems = (formFields) => {
  let contextMenuItems = [];

  // For Form Field Items. If need another item just push it to "contextMenuItems"
  const formFieldsItem = generateFormFieldItems(formFields);
  contextMenuItems.push(formFieldsItem);

  return contextMenuItems;
};

// For Document editor context menu
export const getContextMenuFormFieldValues = (formFields) => {
  let FormFieldContext = [];
  formFields.forEach((item) => {
    const { text, items } = item;
    let fields = [];
    items.forEach((field) => {
      fields.push(
        contextMenuItem({
          text: field.fieldName,
          items: [],
          clickHandler: () => {
            const selection = window.TXTextControl.selection;
            selection.setText(field.value);
          },
        })
      );
    });
    FormFieldContext.push(contextMenuItem({ text, items: fields }));
  });

  return {
    isChecked: false,
    isEnabled: true,
    text: CONTEXT_MENU_ITEM_TEXT.FORM_FIELDS,
    imageUrl: "",
    items: FormFieldContext,
    dropDownIsScrollable: true,
    clickHandler: () => {},
  };
};

// For Document editor context menu
export const getContextMenuCommentItem = (handleAddComments) => {
  // For Comments Item;
  const newCommentsItem = contextMenuItem({
    isEnabled: false,
    text: CONTEXT_MENU_ITEM_TEXT.COMMENT,
    imageUrl:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAEhSURBVDhPrZJNS8NAFEXzC/0Prl0JLsS1SHGnKG1TP2jExkhAK24UTXXR0uhKhNRWCAZCVBqCtI3TMJKrGe1IYBYd6IMDMw/umQ+eMqloOMaKdsc4v/fw156+3j8+Mbd6wahcdqYXkOQLI0LR8fpcsHn2gH40YAxHMeI4DyHk/4Dlqs2DIoqaCd04ylHTdVQPtF/JonorDE5w3wbC52QStlg3WljYusLSfpuH5jdu+IcGUSwUZDdhi6duF81WE7KfOHtBPKao2y+MRy+SF7CNRM1O4Louri1LWsDnIE1THJsmtktFhGHImpl0Z2/3Z2gMIYd6DVajkT+0bdvoPffg+z5KFRWUUrlbOY6Dk/opymoZSZJIP0kJXgOsFQqSYUX5BviQYpsZCuxjAAAAAElFTkSuQmCC",
    items: [],
    clickHandler: handleAddComments,
  });

  return newCommentsItem;
};

export const addUniqueItem = (items, newItem, source = null) => {
  const indexToRemove = items.findIndex(
    (existing) => existing.text === newItem.text
  );
  if (indexToRemove !== -1) {
    items.splice(indexToRemove, 1);
  }
  if (
    !source ||
    source === VIEW_CONTRACT_SOURCES.hr ||
    newItem.text !== CONTEXT_MENU_ITEM_TEXT.FORM_FIELDS
  ) {
    items.push(newItem);
  }
};

export const getTemplateDetailsById = async (id) => {
  try {
    const docs = await getAllTemplates();
    const templateId = typeof id === "string" ? parseInt(id, 10) : id;
    const template = docs.find((doc) => doc.templateId === templateId);

    if (template) {
      return template;
    } else {
      console.log("Template not found");
      return null;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const readDocumentFromLocal = (event) => {
  var file = event.target.files[0];
  var fileReader = new FileReader();
  fileReader.onload = (e) => {
    var streamType = getFileStreamType(file.name);
    window.TXTextControl.loadDocument(
      streamType,
      e.target.result.split(",")[1]
    );
  };
  fileReader.readAsDataURL(file);
};
