import React from "react";
import * as Styled from "./styled";
import { useScheduleContext } from "../../../../context/ScheduleContext";

const WeekColumn = () => {
  const { dates } = useScheduleContext();

  const weeks = React.useMemo(() => {
    return [...new Set(dates.map((d) => d?.weekNumber))].sort((a, b) =>
      String(a).localeCompare(String(b))
    );
  }, [dates]);

  return (
    <Styled.WeekColumn className="week__column" label="Wk">
      <Styled.WeekItems>
        {weeks.map((d, i) => {
          return <WeekItem key={i} weekNumber={d} />;
        })}
      </Styled.WeekItems>
    </Styled.WeekColumn>
  );
};

function WeekItem({ weekNumber }) {
  return <Styled.WeekItem $isCurrent>{weekNumber}</Styled.WeekItem>;
}

export default WeekColumn;
