import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import FromGroup from "../../../../../../controls/FromGroup";
import Button from "../../../../../Buttons/Button";
import UISelect from "../../../../../../controls/UISelect";
import { useSettingUserRoleStore } from "../../../../../../../store/Setting/Permission";
import { toast } from "react-toastify";

// Form initial Values
const formValues = {
  userId: null,
  roleIds: [],
};

// Validation Schema
const validationSchema = Yup.object().shape({
  userId: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .required("This field is required")
    .nullable(),
  roleIds: Yup.array()
    .min(1, "Pick at least one item")
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
    ),
});

const UserRolesForm = () => {
  // Status
  const userOptions = useSettingUserRoleStore((state) => state.userOptions);
  const roleOptions = useSettingUserRoleStore((state) => state.roleOptions);
  const formData = useSettingUserRoleStore((state) => state.formData);
  const isEditMode = useSettingUserRoleStore((state) => state.isEditMode);

  // Functions
  const onFormSubmit = useSettingUserRoleStore((state) => state.onFormSubmit);
  const onToggleForm = useSettingUserRoleStore((state) => state.onToggleForm);
  const clearForm = useSettingUserRoleStore((state) => state.clearForm);

  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    onSubmit,
  });

  const { errors, values, resetForm, handleSubmit, touched, setFieldValue } =
    formik;

  function onSubmit(values, actions) {
    let payload = {
      userId: values.userId.value,
      roleIds: values.roleIds.map((v) => v.value),
    };

    onFormSubmit(payload)
      .then((res) => {
        console.log("done", res);

        if (res.isSuccess === true) {
          console.log("done", res);
          if (isEditMode === false) {
            // toast.success(res.displayMessage);
          } else {
            // toast.success("Role is updated successfully");
          }
        } else {
          toast.error(res.displayMessage);
        }

        clearForm(() => {
          resetForm();
          onToggleForm();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getRolesIds = React.useCallback(
    (idsArr) => {
      let result = [];

      [...roleOptions].forEach((o) => {
        idsArr.forEach((id) => {
          if (o.value === id) {
            result.push(o);
          }
        });
      });

      return result;
    },
    [roleOptions]
  );

  React.useEffect(() => {
    if (isEditMode) {
      setFieldValue(
        "userId",
        userOptions.find((o) => o.value === formData.userId)
      );

      setFieldValue("roleIds", getRolesIds(formData.roleIds));
    }
  }, [
    setFieldValue,
    isEditMode,
    userOptions,
    formData,
    roleOptions,
    getRolesIds,
  ]);

  const onFormClearHandler = () => {
    clearForm(() => {
      resetForm();
      onToggleForm();
    });
  };

  return (
    <div className="setting-form setting__user__roles--form">
      <form onSubmit={handleSubmit} className="row ">
        <div className="col-md-4">
          <FromGroup
            label="User Name"
            inputHeightAuto
            error={touched.userId && errors.userId}
          >
            <UISelect
              name="userId"
              value={values.userId}
              options={userOptions}
              onChange={(val) => setFieldValue("userId", val)}
              placeholder="Select User"
            />
          </FromGroup>
        </div>

        <div className="col-md-12">
          <FromGroup
            label="Roles"
            inputHeightAuto
            error={touched.roleIds && errors.roleIds}
          >
            <UISelect
              isMulti={true}
              name="roleIds"
              options={roleOptions}
              value={values.roleIds}
              onChange={(val) => setFieldValue("roleIds", val)}
              placeholder="Select Role/s"
            />
          </FromGroup>
        </div>

        <div className="col-12">
          <FromGroup type="button-inline-left">
            <Button variant="secondary" onClick={onFormClearHandler}>
              Cancel
            </Button>
            <Button type="submit">{isEditMode ? "Edit" : "Add"}</Button>
          </FromGroup>
        </div>
      </form>
    </div>
  );
};

export default UserRolesForm;
