import styled from "styled-components";
import { fontStyle } from "../../../../styled/utility";

export const StyledAddButton = styled.button`
  outline: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-neutral-30);
  background-color: var(--color-accent-50);
  height: var(--layout-size-28);
  max-height: var(--layout-size-28);
  gap: var(--layout-spacing-16);

  ${({ $size }) => {
    if ($size === "lg") {
      return `
        height: var(--layout-size-60);
        max-height: var(--layout-size-60);
        border-radius: var(--layout-border-radius-8);
        border: 1px dashed var(--font-default-black);
      `;
    }
  }};

  ${({ $full }) => {
    if ($full) {
      return `
        width: 100%;
      `;
    }
  }};

  &:hover {
    background-color: var(--color-neutral-30);
  }

  .icon__button__element {
    &:hover {
      opacity: 1;
    }
  }

  .button__icon {
    --icon-button-icon-color: var(--color-accent-400);
  }

  .label {
    ${fontStyle("small-regular")};
    color: var(--font-default-black);
    display: block;
  }
`;
