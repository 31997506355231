import styled from "styled-components";

export const Form = styled.div`
  display: flex;
  align-items: center;

  .form__input {
    flex: 1;
    position: relative;
    height: 64px;
    max-height: 64px;

    textarea {
      padding: var(--layout-spacing-8);
      border: none;
      height: 100%;
      width: 100%;
      border-radius: var(--layout-border-radius-4, 4px);
      border: 1px solid var(--color-neutral-40, #dfe0e5);
      background: var(--color-white, #fff);
      resize: none;
    }

    .send__button {
      --icon-button-size: var(--layout-size-32);
      --icon-button-icon-size: var(--layout-size-12);
      --icon-button-icon-color: var(--font-default-white);
      min-width: var(--layout-size-36);
      position: absolute;
      right: var(--layout-spacing-8);
      bottom: var(--layout-spacing-8);
      border-radius: var(--button-border-radius-sm, 4px);
      background: var(--button-primary-active);

      &:disabled {
        background: var(--button-disabled, #c1c4ce);
      }
    }
  }
`;
