import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError,
} from "react-router-dom";
import Button from "../../components/ui-components/Buttons/Button";
import PageErrorMessage from "../../components/ui-components/PageErrorMessage";

const PageErrorBoundary = () => {
  let error = useRouteError();
  const navigate = useNavigate();

  if (isRouteErrorResponse(error)) {
    const options = JSON.parse(error?.data);

    // 403 Forbidden
    if (error.status === 403) {
      return (
        <PageErrorMessage title={`403 Forbidden`}>
          <p>{options.message}</p>

          <Button
            className="mt-3"
            size="sm"
            variant="primary"
            onClick={() => navigate(-1)}
          >
            Go Back
          </Button>
        </PageErrorMessage>
      );
    }
  }

  // Any other error message
  return (
    <PageErrorMessage title={`Something went wrong!`}>
      <Button
        className="mt-3"
        size="sm"
        variant="primary"
        onClick={() => navigate(-1)}
      >
        Go Back
      </Button>
    </PageErrorMessage>
  );
};

export default PageErrorBoundary;
