import produce from "immer";
import { MilestoneActionTypes } from "../actionTypes/milestone.actions";

export const milestoneInitialState = {
  loaders: {
    initialLoader: false,
    sectionLoader: false,
    sectionLoaders: {},
  },
  headerInfo: {},
  filters: {},
  lastUpdate: "",
  sections: [],
  sectionHeader: [],
  selectedDueRow: null,
  selectedDueCellValues: null,
  isVisibleDueModal: false,
  cellSubMenuState: null,
  eventsList: [],
  isVisibleDeliverableModal: false,
  deliverableModalState: null,
};

export const milestoneReducer = (state, action) => {
  switch (action.type) {
    case MilestoneActionTypes.INITIAL_STATE: {
      return initialState(state, action.payload);
    }

    case MilestoneActionTypes.ADD_ROW: {
      return addRow(state, action.payload);
    }

    case MilestoneActionTypes.ADD_SUB_ROW: {
      return addSubRow(state, action.payload);
    }

    case MilestoneActionTypes.DELETE_ROW: {
      return deleteRow(state, action.payload);
    }

    case MilestoneActionTypes.DUE_MODAL_STATE: {
      return toggleDueModal(state, action.payload);
    }
    case MilestoneActionTypes.EVENT_CHANGE: {
      return changeEvent(state, action.payload);
    }

    case MilestoneActionTypes.CELL_SUBMENU_STATE: {
      return cellSubMenuPopover(state, action.payload);
    }
    case MilestoneActionTypes.CONDITION_UPDATE: {
      return condtionUpdate(state, action.payload);
    }
    case MilestoneActionTypes.SUBMISSION_SAVE: {
      return submissionUpdate(state, action.payload);
    }
    case MilestoneActionTypes.DUE_DATE_CHANGE: {
      return dueDateChangeUpdate(state, action.payload);
    }
    case MilestoneActionTypes.DELIVERABLE_NAME_UPDATE: {
      return deliverableNameUpdate(state, action.payload);
    }

    case MilestoneActionTypes.DELIVERABLE_MODAL_STATE: {
      return toggleDeliverableModal(state, action.payload);
    }

    default: {
      return state;
    }
  }
};

function initialState(state, payload) {
  return produce(state, (draft) => {
    const { isLoading, ...rest } = payload;

    // Set loader state
    draft.loaders.initialLoader = isLoading;

    if (!Object.keys(rest).length) {
      return;
    }

    const { project, headers, sections, eventOptions } = rest;

    console.log("project", project);
    console.log("headers", headers);
    console.log("sections", sections);
    console.log("eventOptions", eventOptions);

    // set events
    draft.eventsList = eventOptions || [];
    // set project header
    draft.headerInfo = project || null;
    // set table header
    draft.sectionHeader = headers || [];
    // set table body data
    draft.sections = sections || [];
  });
}

function addRow(state, payload) {
  return produce(state, (draft) => {
    const { data, isLoading } = payload;

    // Set loading state
    draft.loaders.sectionLoader = isLoading;

    // Add new row
    if (data) {
      draft.sections.push(data);
    }
  });
}

function addSubRow(state, payload) {
  try {
    return produce(state, (draft) => {
      const { isLoading, data, row } = payload;

      // Set loading state in terms of row id
      draft.loaders.sectionLoaders[row.id] = isLoading;

      if (!data) {
        return;
      }

      const id = data?.id;

      const sectionData = draft.sections;
      const index = sectionData.findIndex((d) => d.id === id);
      sectionData[index] = data;

      console.log("index", index);
    });
  } catch (error) {
    console.log(error);
  }
}

function deleteRow(state, payload) {
  try {
    return produce(state, (draft) => {
      const { row } = payload;
      const id = row?.original?.id;
      if (!row?.original?.id) {
        return;
      }

      // const data = findObjectsNotEqualToId(draft.sections, id);
      // draft.sections = data;

      // console.log("delete sectionData", JSON.parse(JSON.stringify(data)));
    });
  } catch (error) {
    console.log(error);
  }
}

function toggleDueModal(state, payload) {
  try {
    return produce(state, (draft) => {
      const { data, isShow } = payload;

      console.log(data);

      if (isShow) {
        draft.selectedDueCellValues = { ...data };
        draft.isVisibleDueModal = true;
        return;
      }

      draft.selectedDueCellValues = null;
      draft.isVisibleDueModal = false;
    });
  } catch (error) {
    console.log(error);
  }
}

function changeEvent(state, payload) {
  try {
    return produce(state, (draft) => {
      const { data } = payload;

      // console.log("parentRow", JSON.parse(JSON.stringify(parentRow)));
      const id = data?.id;

      const sectionData = draft.sections;
      const index = sectionData.findIndex((d) => d.id === id);
      console.log("index", index);
      sectionData[index] = data;

      // ;
    });
  } catch (error) {
    console.log(error);
  }
}

function cellSubMenuPopover(state, payload) {
  try {
    return produce(state, (draft) => {
      const { data } = payload;

      if (!data?.element) {
        draft.cellSubMenuState = null;
        return;
      }

      draft.cellSubMenuState = data;
    });
  } catch (error) {
    console.log(error);
  }
}
function condtionUpdate(state, payload) {
  try {
    return produce(state, (draft) => {
      const { data, id } = payload;
      // console.log("payload:", payload);

      // console.log("draft.sections", JSON.parse(JSON.stringify(draft.sections)));
      const row_id = id;

      const sectionData = findById(draft.sections, row_id);
      // console.log("sectionData", JSON.parse(JSON.stringify(sectionData)));

      sectionData[data.columnId] = data;
      draft.selectedDueCellValues = null;
      draft.isVisibleDueModal = false;
    });
  } catch (error) {
    console.log(error);
  }
}
function submissionUpdate(state, payload) {
  try {
    return produce(state, (draft) => {
      const { data, id } = payload;
      // console.log("payload:", payload);
      if (!data) {
        return;
      }

      // console.log("draft.sections", JSON.parse(JSON.stringify(draft.sections)));
      const row_id = id;

      const sectionData = findById(draft.sections, row_id);
      // console.log("sectionData", JSON.parse(JSON.stringify(sectionData)));

      sectionData[data.columnId] = data;
      draft.deliverableModalState = null;
      draft.isVisibleDeliverableModal = false;
    });
  } catch (error) {
    console.log(error);
  }
}
function dueDateChangeUpdate(state, payload) {
  try {
    return produce(state, (draft) => {
      const { data, id } = payload;
      // console.log("payload:", payload);
      if (!data) {
        return;
      }

      // console.log("draft.sections", JSON.parse(JSON.stringify(draft.sections)));
      const row_id = id;

      const sectionData = findById(draft.sections, row_id);
      // console.log("sectionData", JSON.parse(JSON.stringify(sectionData)));

      sectionData[data.columnId] = data;
      draft.cellSubMenuState = null;
    });
  } catch (error) {
    console.log(error);
  }
}

function deliverableNameUpdate(state, payload) {
  try {
    return produce(state, (draft) => {
      const { data } = payload;

      // console.log("parentRow", JSON.parse(JSON.stringify(parentRow)));
      const id = data?.id;

      const sectionData = draft.sections;
      const index = sectionData.findIndex((d) => d.id === id);
      console.log("index", index);
      sectionData[index] = data;

      // ;
    });
  } catch (error) {
    console.log(error);
  }
}
// function changeRequestStateUpdate(state, payload) {
//   try {
//     return produce(state, (draft) => {
//       const { data, id } = payload;
//       // console.log("payload:", payload);
//       if (!data) {
//         return;
//       }

//       // console.log("draft.sections", JSON.parse(JSON.stringify(draft.sections)));
//       const row_id = id;

//       const sectionData = findById(draft.sections, row_id);
//       // console.log("sectionData", JSON.parse(JSON.stringify(sectionData)));

//       sectionData[data.columnId] = data;

//     });
//   } catch (error) {
//     console.log(error);
//   }
// }

function toggleDeliverableModal(state, payload) {
  try {
    return produce(state, (draft) => {
      const { data, isShow } = payload;

      if (!isShow) {
        draft.deliverableModalState = null;
        draft.isVisibleDeliverableModal = false;
        draft.cellSubMenuState = null;
        return;
      }

      draft.isVisibleDeliverableModal = true;
      draft.deliverableModalState = data;
      draft.cellSubMenuState = null;
    });
  } catch (error) {
    console.log(error);
  }
}

// helper

function findById(arr = [], id) {
  let result;

  for (const data of arr) {
    if (id === data.id) {
      result = data;
      break;
    }
    if (!result) {
      result = findById(data.subRows, id);
    }
  }

  return result;
}
function findObjectsNotEqualToId(arr, givenId) {
  let result = [];

  arr.forEach((item) => {
    // If the current object's id is not equal to the given id, add it to the result
    if (item.id !== givenId) {
      result.push(item);
    }

    // If there are subRows, recursively check them as well
    if (item.subRows && item.subRows.length > 0) {
      result = result.concat(findObjectsNotEqualToId(item.subRows, givenId));
    }
  });

  return result;
}
