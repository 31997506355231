import React from "react";
import "../../../components/sass/project/dashboard.css";
import Header from "../layout/header";
import LeftSide from "../layout/leftside";
const ProjectDashboard = () => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <section id="mid">
        <div className="symbol" onclick="show()">
          <span className="cross c1" />
          <span className="cross c2" />
        </div>

        <div className="midMid">
          <div className="midMid1">
            <div className="topHead">
              <h1>Dashboard</h1>
              <p>Welcome The S !</p>
            </div>
            <ul>
              <li>
                <h3>21</h3>
                <p>In Progress</p>
              </li>
              <li>
                <h3>42</h3>
                <p>Completed</p>
              </li>
              <li>
                <h3>12</h3>
                <p>To-Do</p>
              </li>
            </ul>
          </div>
          <div className="midMid2">
            <ul id="option">
              <li className="inactive">ALL</li>
              <hr />
              <li className="activeMid">PROGRESS</li>
              <hr />
              <li className="inactive">TO-DO</li>
              <hr />
              <li className="inactive">COMPLETED</li>
            </ul>
          </div>
          <div className="midMid3">
            <div className="progress1 progress">
              <p>In Progress</p>
              <h3>ABCDE Project</h3>
              <p>Completed: 35/70</p>
              <a href="#">View</a>
              <br />
              <progress value={35} max={70} />
              <h4>DUE: OCT 27, 2022</h4>
            </div>
            <div className="progress2 progress">
              <p>In Progress</p>
              <h3>XYZAb Project</h3>
              <p>Completed: 21/80</p>
              <a href="#">View</a>
              <br />
              <progress value={21} max={80} />
              <h4>DUE: SEPT 10, 2022</h4>
            </div>
            <div className="progress3 progress">
              <p>In Progress</p>
              <h3>QWERTY Project</h3>
              <p>Completed: 56/60</p>
              <a href="#">View</a>
              <br />
              <progress value={56} max={60} />
              <h4>DUE: AUG 5, 2022</h4>
            </div>
            <div className="progress4 progress">
              <p>In Progress</p>
              <h3>MNOP Project</h3>
              <p>Completed: 12/100</p>
              <a href="#">View</a>
              <br />
              <progress value={12} max={100} />
              <h4>DUE: NOV 30, 2022</h4>
            </div>
          </div>
        </div>
        <div className="midRight">
          <h2>Your Activity</h2>

          <a href="#">See More</a>
        </div>
      </section>
    </div>
  );
};

export default ProjectDashboard;
