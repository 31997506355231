import * as React from "react";
import { memo } from "react";
const Svg02 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.40898 7.99916L15.7044 1.71305C15.9579 1.45953 16.0569 1.09007 15.9641 0.743809C15.8713 0.397463 15.6009 0.12701 15.2546 0.034247C14.9084 -0.0585856 14.5389 0.0404151 14.2855 0.293927L8 6.59004L1.71454 0.293927C1.46104 0.0404033 1.09163 -0.0585956 0.745401 0.034247C0.399092 0.127002 0.128667 0.397463 0.035914 0.743809C-0.0569089 1.09007 0.0420814 1.45955 0.295566 1.71305L6.59102 7.99916L0.295566 14.2853C0.106407 14.4729 0 14.7284 0 14.9948C0 15.2613 0.106407 15.5168 0.295566 15.7044C0.483165 15.8936 0.738606 16 1.00505 16C1.2715 16 1.52694 15.8936 1.71454 15.7044L8 9.40829L14.2855 15.7044C14.4731 15.8936 14.7285 16 14.9949 16C15.2614 16 15.5168 15.8936 15.7044 15.7044C15.8936 15.5168 16 15.2613 16 14.9948C16 14.7284 15.8936 14.4729 15.7044 14.2853L9.40898 7.99916Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg02);
export default Memo;
