import API_ENDPOINTS from "../../../services/api-endpoints";
import API from "../../../services/api-tenant";

//Save Role
export const saveRole = (dataObj) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API.post(`${endpoints.PERMISSION_CREATE_ROLE}`, dataObj)
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};

//Save UserRole
export const saveUserRole = (dataObj) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API.post(`${endpoints.PERMISSION_CREATE_USERROLE}`, dataObj)
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};

//Save UserRole
export const saveRoleRight = (dataObj) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API.post(`${endpoints.PERMISSION_CREATE_ROLERIGHT}`, dataObj)
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
//Delete User Role
export const deleteUserRole = (id) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API.delete(`${endpoints.PERMISSION_DELETE_USERROLE + "/" + id}`)
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
//Update Role Right
export const updateRoleRight = (dataObj) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API.put(
      `${endpoints.PERMISSION_UPDATE_ROLERIGHT + "/" + dataObj.id}`,
      dataObj
    )
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
//Delete Role Right
export const deleteRoleRight = (id) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API.delete(`${endpoints.PERMISSION_DELETE_ROLERIGHT + "/" + id}`)
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};

//Save User Right
export const saveUserRight = (dataObj) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API.post(`${endpoints.PERMISSION_CREATE_USERRIGHT}`, dataObj)
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
//Update User Right
export const updateUserRight = (dataObj) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API.put(
      `${endpoints.PERMISSION_UPDATE_USERRIGHT + "/" + dataObj.id}`,
      dataObj
    )
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
//Delete User Right
export const deleteUserRight = (id) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API.delete(`${endpoints.PERMISSION_DELETE_USERRIGHT + "/" + id}`)
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
//Save Role Menu
export const saveRoleMenu = (dataObj) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API.post(`${endpoints.PERMISSION_CREATE_ROLEMENU}`, dataObj)
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
//Delete Role Menu
export const deleteRoleMenu = (id) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API.delete(`${endpoints.PERMISSION_DELETE_ROLEMENU + "/" + id}`)
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};

// Default Object
const createUserRoleObj = {
  id: 0,
  userId: "",
  roleId: 0,
  projectId: 0,
};

export const createUserRole = (data = createUserRoleObj) => {
  const endpoints = new API_ENDPOINTS();

  const postData = {
    ...createUserRoleObj,
    ...data,
  };

  return new Promise((resolve, reject) => {
    API.post(`${endpoints.PERMISSION_CREATE_USERROLE}`, postData)

      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })

      .catch((err) => {
        reject(err);
      });
  });
};
//Save User Menu
export const saveUserMenu = (dataObj) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API.post(`${endpoints.PERMISSION_CREATE_USERMENU}`, dataObj)
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
//Delete User Menu
export const deleteUserMenu = (id) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API.delete(`${endpoints.PERMISSION_DELETE_USERMENU + "/" + id}`)
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
