import styled from "styled-components";

export const Table = styled.table`
  min-width: 100%;

  /* Table head styles */
  thead {
    tr {
      th {
        /* height: 0; */

        > div {
          display: flex;
          align-items: center;
          letter-spacing: normal;
          height: var(--tr-height, "100%");
        }
      }
    }
  }

  /* Table body styles */
  tbody {
    tr {
      td {
        /* height: 0; */

        > div {
          display: flex;
          align-items: center;
          height: var(--tr-height, "100%");
        }
      }
    }
  }

  tr {
    td,
    th {
      &[align="center"] {
        > div {
          justify-content: center;
        }
      }

      &[align="right"] {
        > div {
          justify-content: flex-end;
        }
      }
    }
  }
`;
