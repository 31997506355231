import React from "react";
import * as Styled from "./styled";
import IconButton from "../../../../../common/components/Buttons/IconButton";
import TabBase from "../../../../../common/components/UI/TabBase";

function IconMenuTab({ children, ...otherProps }) {
  return <Styled.Tab {...otherProps}>{children}</Styled.Tab>;
}

IconMenuTab.Label = ({ ...otherProps }) => {
  return (
    <IconButton
      className="menu__icon"
      btnIconSize={28}
      btnSize={28}
      as="div"
      {...otherProps}
    />
  );
};

IconMenuTab.Panel = TabBase.Panel;

export default IconMenuTab;
