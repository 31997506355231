import styled from "styled-components";

export const WelcomeContainer = styled.div`
  min-height: 180px;
  ${({ $bgImg }) => {
    if ($bgImg) {
      return `
        background-image: url(${$bgImg});
        background-position: center;
        background-size: cover;
      `;
    }

    return `
      background-color: var(--color-neutral-30);
    `;
  }}

  .dashboard_welcome {
    /* margin-bottom: var(--layout-spacing-16, 16px); */
    padding: var(--layout-spacing-40) 4rem;
  }

  .dashboard_welcome .top {
    color: var(--color-neutral-10);
    /* Large/SemiBold */
    font-family: var(--large-medium-font-family);
    font-size: var(--large-medium-font-size);
    font-weight: var(--large-medium-font-weight);
    font-style: normal;
    line-height: var(--large-medium-line-height);
  }
  .dashboard_welcome .top .user_name {
    color: var(--color-accent-300, #3c7f84);
  }
  .dashboard_welcome .bottom {
    margin-top: 6px;
    color: var(--color-neutral-10);
    /* Label/Medium */
    font-family: var(--x-small-medium-font-family);
    font-size: var(--x-small-medium-font-size);
    font-weight: var(--x-small-medium-font-weight);
    font-style: normal;
    line-height: var(--x-small-medium-line-height);
  }

  .dashboard_welcome .bottom .noti_count,
  .dashboard_welcome .bottom .milestone_count {
    width: var(--layout-size-20);
    height: var(--layout-size-20);
    border-radius: var(--border-radius-32);
    padding: var(--layout-spacing-4, 4px);
    display: inline-block;
    line-height: 1.1;
    vertical-align: middle;
  }

  .dashboard_welcome .bottom .noti_count {
    color: var(--color-primary-600, #fff);
    background-color: var(--color-danger-200, #f05353);
  }

  .dashboard_welcome .bottom .milestone_count {
    color: var(--color-primary-600, #fff);
    background-color: var(--color-info-300, #1eaaf9);
  }
`;
