import React from "react";

import Slider from "./Slider";
import { StyledWrapper } from "./styled";
// import { RESEARCH_TAG_VISUAL_TYPE } from "../../../../research-data/TagCard";

const SlideViewer = ({ data }) => {
  // Map items

  // console.log("visualTypeImages modal ", data?.visualTypeImages);

  const slideItems = React.useMemo(() => {
    // const result = [];

    // switch (data.visualType) {
    //   case RESEARCH_TAG_VISUAL_TYPE.IMAGE: {
    //     const images = data.visual?.map((d) => {
    //       return {
    //         type: "image",
    //         url: d.filePath,
    //         thumbnail: d.filePath,
    //       };
    //     });

    //     result.push(...images);
    //     break;
    //   }

    //   default:
    //     return result;
    // }

    // return result;
    const d = data?.visualTypeImages
      ?.reduce(function (prev, next) {
        return prev.concat(next);
      })
      ?.map((d) => {
        return {
          type: "image",
          url: d,
          thumbnail: d,
        };
      });

    return d || [];
  }, [data]);

  return (
    <StyledWrapper>
      <Slider data={slideItems} />
    </StyledWrapper>
  );
};

export default SlideViewer;
