import React from "react";
import Popover from "../../../../../../../../../common/components/Popover";
import { Wrapper } from "./styled";
import { useAssigneeContext } from "../AssigneeContext";
import Calendar from "../../../../../../../../../common/components/Calendar";
import Avatar from "../../../../../../../../../common/components/UI/Avatar";
import IconButton from "../../../../../../../../../common/components/Buttons/IconButton";
import IconPhone from "../../../../../../../../../common/components/icons/Fill/phone";
import IconEmail from "../../../../../../../../../common/components/icons/Fill/email";
import AnimateLoader from "../../../../../../../../../common/components/AnimateLoader";
import { getMemberAvailability } from "../../../../../../../API/Calendar";
import { dateFormat } from "../../../helps";

const DetailCalendar = () => {
  const { selectedItem } = useAssigneeContext();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [currentAvailability, setCurrentAvailability] = React.useState([]);

  const selectedElement = selectedItem?.htmlElement || null;
  console.log("selectedItem", selectedItem);
  React.useEffect(() => {
    if (!selectedElement) {
      setAnchorEl(null);
      return;
    }

    if (selectedItem?.payload?.availability) {
      const _currentavalability = selectedItem?.payload?.availability.map(
        (av) => {
          return {
            from: new Date(av?.startDate),
            to: new Date(av?.endDate),
            startDateId: av?.startDateId,
            endDateId: av?.endDateId,
          };
        }
      );

      setCurrentAvailability(_currentavalability);
    }
    const avatarElement = selectedElement.querySelector(".assigner-avatar");
    setAnchorEl(avatarElement);
  }, [selectedElement, selectedItem?.payload?.availability]);

  const handleMonthChange = React.useCallback(
    (month) => {
      console.log(month);
      setIsLoading(true);

      try {
        getMemberAvailability(
          selectedItem?.payload?.projectId || 0,
          selectedItem?.payload?.memberId || 0,
          dateFormat(new Date(month))
        ).then((availabilityData) => {
          console.log("getMemberAvailability::: ", availabilityData);

          if (availabilityData?.availability) {
            const _currentavalability = availabilityData?.availability.map(
              (av) => {
                return {
                  from: new Date(av?.startDate),
                  to: new Date(av?.endDate),
                  startDateId: av?.startDateId,
                  endDateId: av?.endDateId,
                };
              }
            );

            setCurrentAvailability(_currentavalability);
          }

          setIsLoading(false);
        });
      } catch (err) {
        setIsLoading(false);
        console.log("getMemberAvailability error::: ", err);
      }
    },
    [selectedItem?.payload?.memberId, selectedItem?.payload?.projectId]
  );

  return anchorEl ? (
    <Popover
      anchorEl={anchorEl}
      hideOverlay
      appendTo={document.body}
      placement="right-end"
      modifiers={[
        {
          name: "offset",
          options: {
            offset: [24, 12],
          },
        },
      ]}
    >
      <Wrapper className="assigner-popover">
        <div className="inner">
          <div className="info-wrp">
            <div className="user">
              <div className="user__start">
                <div className="user__avatar">
                  <Avatar
                    size="size-26"
                    src={
                      selectedItem?.payload?.profileImageUrl ||
                      "https://saruwataarchive.blob.core.windows.net/loop-profile-dev/09114d2c-6584-480b-919c-76ea94959452-2023-07-25-04-52-25-img_avatar.png"
                    }
                  />
                </div>
                <div className="user__content">
                  <div className="user__name">
                    {selectedItem?.payload?.memberName}
                  </div>
                  <div className="user__position">
                    {selectedItem?.payload?.roleName}
                  </div>
                </div>
              </div>

              <div className="user__end">
                <IconButton icon={<IconEmail />} />
                <IconButton icon={<IconPhone />} />
              </div>
            </div>

            {/* <div className="assigner-status">
              <div className="assigner-status__title">Status</div>
              <div className="assigner-status__items">
                {Array(6)
                  .fill("")
                  .map((_, i) => (
                    <div className="assigner-status__item" key={i}>
                      <div className="task-title">Working On</div>
                      <div className="task-content">
                        Ep :02 | Shooting Day 2
                      </div>
                    </div>
                  ))}
              </div>
            </div> */}
          </div>

          <div className="calendar-wrp">
            <Calendar
              fixedWeeks={false}
              showOutsideDays={false}
              onMonthChange={handleMonthChange}
              modifiers={{
                assigned: currentAvailability,
              }}
              modifiersClassNames={{
                assigned: "assigned-day",
              }}
              onSelect={(e) => {
                console.log("onSelect", e);
              }}
            />
          </div>
        </div>

        {/* Spinner  */}
        {isLoading ? (
          <div className="spinner-loader">
            <AnimateLoader text="Loading..." />
          </div>
        ) : null}
      </Wrapper>
    </Popover>
  ) : null;
};

export default DetailCalendar;
