import React from "react";
import { LeftLink, Wrapper } from "./styled";

function ProjectItemLink({ src, name, caption, rightActions, leftActions }) {
  return (
    <Wrapper>
      <div className="project-item">
        <div className="project-item__start">
          {src ? (
            <div className="project-item__image">
              <img src={src} alt="Project logo" />
            </div>
          ) : null}
          <div className="project-item__info-group">
            <div className="web-text-base font-500 project-item__name">
              {name}
            </div>
            <div className="web-text-label project-item__caption">
              {caption}
            </div>
            <div className="project-item__left-actions">{leftActions}</div>
          </div>
        </div>
        <div className="project-item__end">{rightActions}</div>
      </div>
    </Wrapper>
  );
}

export const ProjectLink = LeftLink;

export default ProjectItemLink;
