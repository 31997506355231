import API_ENDPOINTS from "../../services/api-endpoints";
import API_Template from "../../services/api-template";

export const getTemplateDocById = async (templateId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Template.get(
      `${endpoints.TEMPLATE_GET_TEMPLATE_DOC_BY_ID}/${templateId}`
    )
      .then((res) => {
        const { isSuccess, result } = res?.data;

        if (!isSuccess) {
          return null;
        }

        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getBase64TemplateDocById = async (templateId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Template.get(
      `${endpoints.TEMPLATE_GET_BASE64_TEMPLATE_DOC_BY_ID}/${templateId}`
    )
      .then((res) => {
        const { isSuccess, result } = res?.data;

        if (!isSuccess) {
          return null;
        }

        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * Get All Templates
 * @returns Promise
 */
export const getAllTemplates = async () => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Template.get(`${endpoints.TEMPLATE_GETALL}/1/1000`)
      .then((res) => {
        const { isSuccess = false, result = [] } = res?.data;

        if (!isSuccess) {
          return [];
        }

        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * Create document params
 */
const createDocumentParams = {
  userDocumentId: 0, // use zero for create new
  userId: "",
  documentTypeId: 0,
  projectId: 0,
  templateId: 0,
  senderNote: "",
  documentURL: "",
  remarks: "",
};

/**
 * Create Document
 * * POST
 * @param formData
 * @returns Promise
 */
export const createDocument = (formData = createDocumentParams) => {
  const payload = {
    ...createDocumentParams,
    ...formData,
  };

  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Template.post(`${endpoints.CREATE_DOCUMENT}`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};

/**
 * API for Generate User Doc
 * * POST
 * @param templateId
 * @param userTenantId
 * @returns Promise
 */
export const generateUserDoc = (templateId, userTenantId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Template.post(
      `${endpoints.GENERATE_USER_DOC}/${templateId}/${userTenantId}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};

/**
 * API for DocumentInfo
 * * GET
 * @param {userDocumentId or contractId} docId
 * @returns Promise
 */
export const getDocumentInfoById = (docId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Template.get(`${endpoints.DOCUMENT_GET_BY_ID}/${docId}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};

export const getFormFieldValues = (userTenantId) => {
  const endpoints = new API_ENDPOINTS();
  return new Promise((resolve, reject) => {
    API_Template.get(
      `${endpoints.TEMPLATE_GET_FORM_FIELD_VALUES_BY_USER_TENANT_ID}/${userTenantId}`
    )
      .then((response) => {
        const { isSuccess, result } = response?.data;
        if (!isSuccess) {
          resolve([]);
        }
        resolve(result);
      }) 
      .catch((err) => {
        reject({ error: err });
      });
  });
};

export const getFormFields = () => {
  const endpoints = new API_ENDPOINTS();
  return new Promise((resolve, reject) => {
    API_Template.get(endpoints.TEMPLATE_GET_FORM_FIELDS)
      .then((response) => {
        const { isSuccess, result } = response?.data;
        if (!isSuccess) {
          resolve([]);
        }
        resolve(result);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
