import styled from "styled-components";

export const PageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - var(--nav-height));
  max-height: calc(100vh - var(--nav-height));
  background-color: var(--layout-background-n10);
  /* overflow-y: auto; */
`;

export const ScrollView = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
`;
