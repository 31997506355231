"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.species = void 0;
/**
 * Local data for diagram layouts
 */
exports.species = [
    { 'Name': 'Director','NikName': 'JAN', 'fillColor': '#3DD94A' },
    { 'Name': 'Camera Crew','NikName': 'ALEX', 'Category': 'Director' },
    { 'Name': 'Camera Assistance','NikName': 'WINK', 'Category': 'Director' },
    { 'Name': 'Lighting','NikName': 'BKX', 'Category': 'Director' },
    { 'Name': 'Actors','NikName': 'CHAN', 'Category': 'Director' },
    { 'Name': 'Janaka','NikName': 'KOKO', 'Category': 'Camera Crew' },
    { 'Name': 'Buddhiks','NikName': 'MOKO', 'Category': 'Camera Crew' },
    { 'Name': 'Kamal','NikName': 'Lili', 'Category': 'Camera Crew' },
    { 'Name': 'Shirantha','NikName': 'LostMe', 'Category': 'Camera Crew' },
    { 'Name': 'Mark Stew','NikName': 'SpareME', 'Category': 'Camera Crew' },
    { 'Name': 'Main Actors','NikName': 'Sooon', 'Category': 'Actors' },
    { 'Name': 'Sub Actors','NikName': 'MoonD', 'Category': 'Actors' },
    { 'Name': 'Milan Perera','NikName': 'Losed', 'Category': 'Main Actors' },
    { 'Name': 'Flix Orix','NikName': 'KKLL', 'Category': 'Main Actors' },
    { 'Name': 'Jason Peries','NikName': 'BBJS', 'Category': 'Main Actors' },
    { 'Name': 'Salvin Stek','NikName': 'AKSs', 'Category': 'Main Actors' },
    { 'Name': 'Keren Stew','NikName': 'BODDY', 'Category': 'Sub Actors' },
    { 'Name': 'Lintus Plex','NikName': 'JINKO', 'Category': 'Sub Actors' },
    { 'Name': 'Premitha','NikName': 'LOONTA', 'Category': 'Sub Actors' },
    { 'Name': 'Kanchana','NikName': 'MYHJ', 'Category': 'Sub Actors' },
    { 'Name': 'Kits Saw','NikName': 'CONCO', 'Category': 'Sub Actors' }
];
