import styled, { css } from "styled-components";
import { fontStyle } from "../../../../../../../../../styled/utility";

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: var(--column-width);
  max-width: var(--column-width);
  /* margin-bottom: var(--layout-spacing-16); */

  ${({ $isFixed }) => {
    if ($isFixed) {
      return `
        position:sticky;
        top: var(--column-top-height);
        height: calc(var(--scheduler-panel-h) - var(--column-top-height) - (var(--scheduler-panel-padding-y) * 2));
      `;
    }
  }}

  .column__head {
    color: var(--column--head-text-color);
    ${fontStyle("x-small-semi-bold")};
    font-weight: 700;
    text-align: center;
    height: var(--column-main-title-height);
    min-height: var(--column-main-title-height);
    position: sticky;
    top: var(--column-top-height);
    background-color: var(--layout-bg-color);
    z-index: 1;
    border-bottom: 1px solid var(--column-border-color);
  }

  .column__body {
    display: flex;
    flex: 1;
    background-color: var(--layout-background-white);
    border: 1px solid var(--column-border-color);
    flex-direction: column;
    padding: var(--layout-spacing-4) 0;
    border-top: none;
  }

  &:not(:first-child) {
    margin-left: -1px;
  }
`;

export const weekendCss = css`
  ${({ $isWeekend }) => {
    if ($isWeekend) {
      return `
        background-color: var(--weekend-bg-color);

        .text {
          color:var(--font-tint-02);
        }
      `;
    }
  }};
`;

export const StyledBaseControlItem = styled.div`
  cursor: pointer;
  position: relative;
  min-height: var(--column-cell-height);
  padding: var(--layout-spacing-4);
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  .text {
    ${fontStyle("x-small-regular")};
    border-radius: var(--layout-border-radius-4, 4px);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
