import styled from "styled-components";

export const ModalStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 9999, // fr the crop popup to appear on top level layer
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px",
      padding: "20px",
      zIndex: 10000, //fr the crop popup content to appear on top level layer 
      width: "80%", 
      maxWidth: "500px", 
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", 
      overflow: "hidden", 
    },
  };

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`;

export const ImageWrapper = styled.div`
  max-height: 500px;
  overflow: hidden;
  margin-bottom: 50px;
`;

export const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  width: 100%;
  position: absolute;
  bottom: 10px;
  left: 0;
`;

