import { css } from "../../../../utility";
import "./tab-base-styles.scss";

const TabPanelWrapper = (props) => {
  TabPanelWrapper.displayName = "TAB_PANEL__WRAPPER";

  const { children, className, minHeight } = props;

  return (
    <div
      className={css("tab__base__wrapper", className)}
      // style={{ minHeight: `${minHeight}px` }}
    >
      {children}
    </div>
  );
};

export default TabPanelWrapper;
