export const SettingRoutePath = {
  id: "setting",
  path: "/setting",
  label: "Setting",

  SystemRights: {
    path: "system-rights",
    id: "system-rights",
    label: "System Rights",
  },

  SystemGenerals: {
    path: "system-generals",
    id: "system-generals",
    label: "System Generals",
  },

  SystemDataSetup: {
    path: "data-setup",
    id: "data-setup",
    label: "Data Setup",
  },

  SystemClient: {
    path: "system-client",
    id: "system-client",
    label: "System Client",
  },

  ProjectSetup: {
    path: "project-setup",
    id: "project-setup",
    label: "Project Setup",
  },

  SystemCompany: {
    path: "system-company",
    id: "system-company",
    label: "System Company",
  },

  MyProfile: {
    path: "my-profile",
    id: "my-profile",
    label: "My Profile",
  },

  TenantPermission: {
    path: "tenant-permission",
    id: "tenant-permission",
    label: "Tenant Permission",
  },

  TenantClient: {
    path: "tenant-client",
    id: "tenant-client",
    label: "Tenant Client",
  },

  TenantCompany: {
    path: "tenant-company",
    id: "tenant-company",
    label: "Tenant Company",
  },

  TenantProject: {
    path: "tenant-project",
    id: "tenant-project",
    label: "Tenant Project",
  },

  TemplateGeneralSettings: {
    path: "template-general-settings",
    id: "template-general-settings",
    label: "Template General Settings",
  },
};
