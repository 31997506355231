import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.813559 10.0339H5.39661L8.46102 6.94237L5.61356 7.40335C5.39657 7.43045 5.17968 7.29485 5.15259 7.07797C5.09839 6.83388 5.26108 6.64409 5.47797 6.61699L9.54576 5.93903H9.70837V5.96613H9.78967V5.99323C9.81677 5.99323 9.84387 6.02033 9.87107 6.04743H9.89816L9.92527 6.10163H9.95237V6.12873H9.97947V6.18293L10.0066 6.21003V6.31844H10.0337V6.37264H10.0066V6.39974L9.41 10.4947C9.3558 10.7116 9.16591 10.8472 8.94903 10.82C8.70494 10.7929 8.56935 10.5759 8.59644 10.3591L9.03032 7.5116L5.96591 10.6031V15.1862H15.1863V0.813299H0.81337V10.0336L0.813559 10.0339ZM9.8983 6.04746C9.8983 6.04746 9.92541 6.07456 9.92541 6.10166L9.8983 6.04746ZM0.40678 16C0.189794 16 0 15.8102 0 15.5932V0.40678C0 0.189794 0.189794 0 0.40678 0H15.5932C15.8102 0 16 0.189794 16 0.40678V15.5932C16 15.8102 15.8102 16 15.5932 16H0.40678ZM5.15254 10.8475H0.813559V15.1864H5.15254V10.8475Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
