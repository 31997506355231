/**
 * Form Field Model
 *
 * */

export class FieldType {
  __id;
  __type;
  __validateRegEx;
  __allowMultiple;
  __validationType;

  constructor() {
    this.__id = null;
    this.__type = null;
    this.__validateRegEx = null;
    this.__allowMultiple = null;
    this.__validationType = null;
  }

  id(val) {
    if (val === undefined) return this.__id;

    this.__id = val;
  }

  type(val) {
    if (val === undefined) return this.__type;
    this.__type = val;
  }

  validateRegEx(val) {
    if (val === undefined) return this.__validateRegEx;
    this.__validateRegEx = val;
  }

  allowMultiple(val) {
    if (val === undefined) return this.__allowMultiple;
    this.__allowMultiple = val;
  }

  validationType(val) {
    if (val === undefined) return this.__validationType;
    this.__validationType = val;
  }
}

export class FormField {
  __id;
  __tagSubTypeId;
  __tagSubTypeName;
  __name;
  __value;
  __validateRegEx;
  __label;
  __isActive;
  __isCustomValidation;
  __isRequired;
  __hint;
  __errorMessage;
  __fieldLength;
  __fieldType;
  __fieldOptions;
  __displayTypeId;
  __visualTypeId;
  __layoutTypeId;
  __isDisabled;
  __tagSubTypeIcon;
  __icon;
  __order;

  constructor() {
    this.__id = null;
    this.__tagSubTypeId = null;
    this.__name = null;
    this.__icon = null;
    this.__value = "";
    this.__validateRegEx = null;
    this.__label = null;
    this.__isCustomValidation = false;
    this.__isRequired = false;
    this.__isActive = true;
    this.__hint = null;
    this.__errorMessage = null;
    this.__fieldLength = 255;
    this.__fieldType = null;
    this.__fieldOptions = [];
    this.__displayTypeId = null;
    this.__visualTypeId = null;
    this.__layoutTypeId = null;
    this.__tagSubTypeName = null;
    this.__tagSubTypeIcon = null;
    this.__order = -1;
    this.__isDisabled = false;
    this.__isMainField = false;
    this.__isCombined = false;
    this.__combinedRoot = 0;
    this.__combinedOrder = 0;
  }

  isMainField(val) {
    if (val === undefined) return this.__isMainField;
    this.__isMainField = val;
  }

  icon(val) {
    if (val === undefined) return this.__icon;
    this.__icon = val;
  }

  order(val) {
    if (val === undefined) return this.__order;
    this.__order = val;
  }

  tagSubTypeIcon(val) {
    if (val === undefined) return this.__tagSubTypeIcon;
    this.__tagSubTypeIcon = val;
  }

  tagSubTypeName(val) {
    if (val === undefined) return this.__tagSubTypeName;
    this.__tagSubTypeName = val;
  }

  visualTypeId(val) {
    if (val === undefined) return this.__visualTypeId;
    this.__visualTypeId = val;
  }

  layoutTypeId(val) {
    if (val === undefined) return this.__layoutTypeId;
    this.__layoutTypeId = val;
  }

  displayTypeId(val) {
    if (val === undefined) return this.__displayTypeId;
    this.__displayTypeId = val;
  }

  fieldType(val) {
    if (val === undefined) return this.__fieldType;

    this.__fieldType = val;
  }

  fieldOptions(val) {
    if (val === undefined) return this.__fieldOptions;

    this.__fieldOptions = val;
  }

  tagSubTypeId(val) {
    if (val === undefined) return this.__tagSubTypeId;

    this.__tagSubTypeId = val;
  }

  id(val) {
    if (val === undefined) return this.__id;

    this.__id = val;
  }

  label(val) {
    if (val === undefined) return this.__label;
    this.__label = val;
  }

  name(val) {
    if (val === undefined) return this.__name;
    this.__name = val;
  }

  value(val) {
    if (val === undefined) return this.__value;
    this.__value = val;
  }

  disabled(val) {
    if (val === undefined) return this.__isDisabled;
    this.__isDisabled = val;
  }

  validateRegEx(val) {
    if (val === undefined) return this.__validateRegEx;
    this.__validateRegEx = val;
  }

  isRequired(val) {
    if (val === undefined) return this.__isRequired;
    this.__isRequired = val;
  }

  isCustomValidation(val) {
    if (val === undefined) return this.__isCustomValidation;
    this.__isCustomValidation = val;
  }

  isActive(val) {
    if (val === undefined) return this.__isActive;
    this.__isActive = val;
  }

  hint(val) {
    if (val === undefined) return this.__hint;
    this.__hint = val;
  }

  errorMessage(val) {
    if (val === undefined) return this.__errorMessage;
    this.__errorMessage = val;
  }

  length(val) {
    if (val === undefined) return this.__fieldLength;
    this.__fieldLength = val;
  }

  isCombined(val) {
    if (val === undefined) return this.__isCombined;
    this.__isCombined = val;
  }

  combinedRoot(val) {
    if (val === undefined) return this.__combinedRoot;
    this.__combinedRoot = val;
  }

  combinedOrder(val) {
    if (val === undefined) return this.__combinedOrder;
    this.__combinedOrder = val;
  }
}

export const $createFormField = () => {
  return new FormField();
};

export const $isFormField = (field) => {
  return field instanceof FormField;
};
