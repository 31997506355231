import React from "react";
import { StyledBaseControlItem } from "./styled";

const BaseControlItem = ({ children, ...otherProps }, ref) => {
  return (
    <StyledBaseControlItem ref={ref} {...otherProps}>
      <div className="text">{children}</div>
    </StyledBaseControlItem>
  );
};

export default React.forwardRef(BaseControlItem);
