import React from "react";

const useScrollPosition = ({ scrollContainerRef = null }) => {
  const [position, setPosition] = React.useState(0);

  React.useEffect(() => {
    const element = scrollContainerRef?.current;

    if (!element) {
      return;
    }

    setPosition(element.scrollTop);

    const onScroll = () => {
      setPosition(element.scrollTop);
    };

    element.addEventListener("scroll", onScroll);

    return () => {
      element.removeEventListener("scroll", onScroll);
    };
  }, [scrollContainerRef]);

  return {
    scrollTop: position,
  };
};

export default useScrollPosition;
