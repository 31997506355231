import styled from "styled-components";

export const Container = styled.div`
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-item-1,
  .grid-item-2 {
    grid-column: 1 / 3;
  }

  .grid-item-3 {
    grid-column: 1;
  }
`;

export default Container;
