import styled from "styled-components";

export const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: var(--layout-spacing-20);

  .social__text {
    padding-top: var(--layout-spacing-8);
  }

  .social__content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--layout-spacing-8);
    padding-top: var(--layout-spacing-16);

    .social__link {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: var(--layout-size-24);
      height: var(--layout-size-24);
      min-width: var(--layout-size-24);
      min-height: var(--layout-size-24);
      transition: var(--layout-size-24);

      &:hover {
        opacity: var(--layout-opacity-60);
      }

      .icon {
        --icon-size: var(--layout-size-24);
        --icon-font-size: var(--layout-size-24);
      }
    }
  }
`;
