import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import * as Styled from "./styled";
import ModuleSelection from "../../ModuleSelection";
import moduleIcon from "../../../../assets/moduleIcon.svg";
import { useAppStore } from "../../../../../../../../store/AppStore/AppStore";
import {
  getAllProjectFeatures,
  getAllProjectRelatedFeatures,
} from "../../../../../API/common";
import {
  getCurrentStep,
  getSpecificStep,
  onUpdateDoSubmitState,
  useProjectWizardStore,
} from "../../../../store";
const validationSchema = Yup.object().shape({});

const initialValues = {
  projectId: 0,
  _selectedFeatures: [],
};
const StepSix = ({ onSubmitted }) => {
  const currentStep = getCurrentStep();
  const projectBasicData = getSpecificStep("step_01");

  const hideLoader = useAppStore((state) => state.hideLoader);
  const showLoader = useAppStore((state) => state.showLoader);
  let USER = JSON.parse(localStorage.getItem("user"));
  const [allFeatures, setAllFeatures] = React.useState([]);
  const [selectedFeatures, setSelectedFeatrues] = React.useState([]);

  const formShouldSubmit = useProjectWizardStore((state) => state.doSubmit);
  const onSubmitHandler = React.useCallback(
    (values, options) => {
      console.log("formik values ::", values);
      onSubmitted(values, { ...options, stepKey: "step_06" });
    },
    [onSubmitted]
  );

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: onSubmitHandler,
    validateOnChange: true,
    validateOnBlur: true,
  });

  const { handleSubmit, setFieldValue } = formik;

  React.useEffect(() => {
    if (formShouldSubmit) {
      handleSubmit();
      onUpdateDoSubmitState();
    }
  }, [formShouldSubmit, handleSubmit]);

  React.useEffect(() => {
    showLoader();
    let tenantId = USER.result.tenantId;
    setFieldValue("projectId", projectBasicData?.data?.projectId);
    getAllProjectFeatures(tenantId).then((result) => {
      setAllFeatures(result?.map((feature) => feature) || []);
    });
    getAllProjectRelatedFeatures(projectBasicData?.data?.projectId || 0).then(
      (result) => {
        setSelectedFeatrues(result?.map((feature) => feature) || []);

        setFieldValue(
          "_selectedFeatures",
          result?.map((feature) => feature) || []
        );
        hideLoader();
      }
    );
  }, [
    USER.result.tenantId,
    hideLoader,
    projectBasicData?.data?.projectId,
    setFieldValue,
    showLoader,
  ]);

  React.useEffect(() => {
    if (currentStep?.data !== null) {
      //setSelectedFeatrues([currentStep?.data]);
      //setFieldValue("_selectedFeatures", currentStep?.data);
      console.log("currentStep?.data", currentStep?.data);
    } else {
      setSelectedFeatrues([]);
      setFieldValue("_selectedFeatures", []);
    }
  }, [currentStep?.data, setFieldValue]);

  const onFeatureSelect = (feature) => {
    if (
      !!selectedFeatures.find(
        (f) => f.projectFeatureId === feature.projectFeatureId
      )
    ) {
      setSelectedFeatrues((selectedFeatures) =>
        selectedFeatures.filter(
          (f) => f.projectFeatureId !== feature.projectFeatureId
        )
      );
    } else {
      setSelectedFeatrues((selectedFeatures) => [...selectedFeatures, feature]);
    }
  };
  React.useEffect(() => {
    setFieldValue("_selectedFeatures", selectedFeatures);
  }, [selectedFeatures, setFieldValue]);
  return (
    <Styled.Container>
      {allFeatures?.map((feature, i) => {
        return (
          <ModuleSelection
            key={i}
            icon={moduleIcon}
            title={feature?.projectFeatureName}
            onClick={() => onFeatureSelect(feature)}
            isSelected={
              !!selectedFeatures?.find(
                (selectedFeature) =>
                  selectedFeature?.projectFeatureId ===
                  feature?.projectFeatureId
              )
            }
          />
        );
      })}
    </Styled.Container>
  );
};

export default StepSix;
