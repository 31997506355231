import produce from "immer";
import create from "zustand";
import { devtools, persist } from "zustand/middleware";

export const DocumentStoreName = "research_documents_store";

const initialState = {
  documentTabs: [],
  isShowMainDocumentModal: false,
  isShowDocumentCreateModal: false,
  documentPayload: null,
  activeTab: null,
  tabVisibilityCount: 4,
};

let store = (set, get) => initialState;

store = devtools(store, { name: "Documents Store" });

store = persist(store, {
  name: DocumentStoreName,
  version: 2,
  migrate: (persistedState, version) => {
    if (version === 1) {
      // if the stored value is in version 0, we rename the field to the new name
      persistedState.newField = persistedState.oldField;
      delete persistedState.oldField;
    }

    return persistedState;
  },
});

export const useDocumentStore = create(store);

export const MainDocumentModal = {
  open: () => {
    useDocumentStore.setState(
      produce((draft) => {
        draft.isShowMainDocumentModal = true;
      })
    );
  },

  close: () => {
    useDocumentStore.setState(
      produce((draft) => {
        draft.isShowMainDocumentModal = false;
      })
    );
  },
};

export const CreateDocumentModal = {
  open: (payload = null) => {
    useDocumentStore.setState(
      produce((draft) => {
        draft.isShowDocumentCreateModal = true;
        draft.documentPayload = payload;
      })
    );
  },
  close: () => {
    useDocumentStore.setState(
      produce((draft) => {
        draft.isShowDocumentCreateModal = false;
        draft.documentPayload = null;
      })
    );
  },
};

const setMinimumTabToVisible = () => {
  const visibilityCount = useDocumentStore.getState().tabVisibilityCount;

  useDocumentStore.setState(
    produce((draft) => {
      const updatedTabs = draft.documentTabs
        .sort((a, b) => a.order - b.order)
        .map((tab, i) => {
          tab.tabVisibility = i < visibilityCount ? true : false;
          return tab;
        });

      draft.documentTabs = updatedTabs;
    })
  );
};

export const addNewTab = (payload, defaultActive= true) => {
  const isExist = useDocumentStore
    .getState()
    .documentTabs.find((tab) => tab?.id === payload?.id);

  if (isExist) {
    onTabChange(payload);
    return;
  }

  useDocumentStore.setState(
    produce((draft) => {
      let existingTab =
        draft?.documentTabs?.find((tab) => tab.id === payload.id) || null;

      draft.documentTabs.forEach((tab) => {
        tab.isActive = false;

        if (tab?.canClose) {
          tab.order = tab.order + 1;
        }
      });

      if (existingTab) {
        draft.documentTabs = draft.documentTabs.filter(
          (tab) => tab.id !== existingTab.id
        );
      }

      draft.documentTabs.push(payload);

      const sortData = draft.documentTabs.sort((a, b) => a.order - b.order);
      draft.documentTabs = sortData;

      // set Active tab
      if(defaultActive) {
        draft.activeTab = draft.documentTabs.find((t) => t.isActive === true);
      }
    })
  );

  setMinimumTabToVisible();
};

export const onTabChange = (payload) => {
  useDocumentStore.setState(
    produce((draft) => {
      draft.documentTabs.forEach((tab) => {
        tab.isActive = false;

        if (tab?.canClose && !payload?.tabVisibility) {
          tab.order = tab.order + 1;
        }
      });

      const item = draft.documentTabs?.find((tab) => tab?.id === payload?.id);

      if (item) {
        item.isActive = true;

        if (item?.canClose && !payload?.tabVisibility) {
          item.order = 1;
        }

        // set Active tab
        draft.activeTab = item;
      }
    })
  );

  if (!payload?.tabVisibility) {
    setMinimumTabToVisible();
  }
};

export const removeTab = (id) => {
  useDocumentStore.setState(
    produce((draft) => {
      let deletingTab = draft.documentTabs.find((tab) => tab.id === id);
      let index = draft.documentTabs.findIndex((t) => t.id === id);

      const items = draft.documentTabs.filter((tab) => tab.id !== id);

      if (deletingTab?.isActive) {
        index = index !== 0 ? index - 1 : index;

        let currentActive = items[index];

        if (currentActive) {
          currentActive.isActive = true;
          // set Active tab
          draft.activeTab = currentActive;
          // onTabChange(currentActive)
        }
      }

      draft.documentTabs = items;
    })
  );

  setMinimumTabToVisible();
};

export const renameTab = (id, value) => {
  useDocumentStore.setState(
    produce((draft) => {
      let tab = draft.documentTabs.find((tab) => tab.id === id);

      tab.documentName = value;
    })
  );

  setMinimumTabToVisible();
};

export const clearDocumentStore = () => {
  useDocumentStore.setState(initialState);
};
