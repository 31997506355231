import styled from "styled-components";
import BaseFlexModal from "../../../../../../common/components/UI/Modal/BaseFlexModal";
// import BaseFlexModal from "../../../../../../../common/components/UI/Modal/BaseFlexModal";

export const Modal = styled(BaseFlexModal)`
  /* --modal-width: 100%;
  --modal-max-width: 620px; */

  .modal__content {
    min-height: 0px;
  }

  .header__container {
    padding-bottom: var(--layout-spacing-4);

    .header__inner {
      ${({ hasLine }) => {
        if (hasLine) {
          return `
              padding-bottom: var(--layout-spacing-12);
              border-bottom: 1px solid var(--layout-background-primary, #E6EBED);
            `;
        }
      }}
    }
  }
`;
