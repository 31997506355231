import styled, { css } from "styled-components";
import BaseModal from "../../../UI/Modal/BaseModal";

export const modalCss = css``;

export const Modal = styled(BaseModal)`
  /* max-height: calc(100vh - 20%); */
  --modal-width: 100%;
  --modal-max-width: ${({ $mWidth = 0 }) =>
    $mWidth ? `${$mWidth}px` : "620px"};
`;

export const ModalHeader = styled.div`
  padding-top: var(--layout-spacing-32);
  padding-inline: var(--layout-spacing-28);

  .header__inner {
    display: flex;
    align-items: center;
    margin-bottom: var(--layout-spacing-8);
    justify-content: space-between;

    .btn__close {
      color: var(--font-tint-04);
    }
  }
`;
