import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_11275_15890)">
      <path
        d="M15.9649 10.8404C15.9808 10.795 15.9903 10.7476 15.9933 10.6996C15.9941 10.688 16 10.6783 16 10.6667V0.533333C16 0.391889 15.9438 0.256228 15.8438 0.156217C15.7438 0.0561607 15.6081 0 15.4667 0H0.533333C0.391889 0 0.256228 0.0561664 0.156217 0.156217C0.0561607 0.256216 0 0.391879 0 0.533333V15.4667C0 15.6081 0.0561664 15.7438 0.156217 15.8438C0.256216 15.9438 0.391879 16 0.533333 16H10.6667C10.8082 15.9994 10.9437 15.9432 11.0441 15.8434L15.8434 11.0441L15.8452 11.0418L15.8452 11.0419C15.8877 10.9978 15.9221 10.9467 15.9471 10.8908C15.9539 10.8743 15.9599 10.8574 15.965 10.8403L15.9649 10.8404ZM10.1334 10.6667V14.9333H1.06668V1.06666H14.9334V10.1333H10.6667C10.5252 10.1333 10.3896 10.1895 10.2896 10.2895C10.1895 10.3895 10.1334 10.5252 10.1334 10.6667ZM11.2 14.1791V11.2H14.1791L11.2 14.1791Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_11275_15890">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
