import API_ENDPOINTS from "../../../services/api-endpoints";
import GlobalAPI from "../../../services/api";
import API_Tenant from "../../../services/api-tenant";

export const doLogin = (payload) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    GlobalAPI.post(endpoints.LOGIN, {
      userName: payload.email,
      password: payload.password,
    })
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUserRolesById = (userId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve) => {
    API_Tenant.get(endpoints.PERMISSION_USERROLE_GET_BY_USERID + "/" + userId)
      .then((res) => {
        if (!res.data.isSuccess) {
          resolve([]);
          return;
        }

        const roleIds = res.data.result.map((element) => {
          return element.roleId;
        });

        resolve(roleIds);
      })
      .catch((err) => {
        resolve([]);
      });
  });
};
