import API from "../../../services/api";
import API_ENDPOINTS from "../../../services/api-endpoints";

// Send email verification.
export const senEmailVerification = (params) => {
  const { email, name } = params;

  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    try {
      API.post(`${endpoints.SEND_EMAIL_VERIFICATION}`, {
        email,
        toName: name,
      }).then((response) => {
        resolve(response);
      });
    } catch (error) {
      reject(error);
    }
  });
};

// Check verification code.
export const validateVerificationCode = (params) => {
  const { email, code } = params;

  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    try {
      API.post(`${endpoints.REQUEST_EMAIL_VERIFICATION_CODE}`, {
        email,
        verificationCode: code,
      }).then((response) => {
        resolve(response);
      });
    } catch (error) {
      reject(error);
    }
  });
};
