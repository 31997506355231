import React from "react";
import { PlainItemContainer } from "./styled";

const PlainItem = ({ title, children, ...other }) => {
  return (
    <PlainItemContainer {...other}>
      <div className="pain-item">
        {title ? <div className="pain-item__title">{title}</div> : null}
        <div className="pain-item__content">{children}</div>
      </div>
    </PlainItemContainer>
  );
};

export default PlainItem;
