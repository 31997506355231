const config = {
  id: "onboarding_1",
  menuIcon: "menu-budget-01",
  label: "Onboarding",
  path: "onboarding-ui",
  children: {
    allMembers: {
      id: "all_members",
      path: "all",
      label: "All Members",
    },
    shortlist: {
      id: "shortlist",
      path: "shortlist",
      label: "Shortlist",
    },
    hr_list: {
      id: "hr_list_1",
      path: "hrlist",
      label: "HR List",
    },
  },
};

export default config;
