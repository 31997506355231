import React from "react";
import { getProfileDetails, getProfileMissedFields } from "../Actions/User";
import useUserData from "./useUserData";

export const useProfileMissedFields = () => {
  const [fields, setFields] = React.useState([]);
  const [isMounted, setMounted] = React.useState(false);
  const { userTenantId } = useUserData();

  // Mounted
  React.useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);

  // Functions
  const getEmptyFields = React.useCallback(() => {
    if (userTenantId !== null && isMounted) {
      getProfileMissedFields({ userTenantId })
        .then((emptyFields) => {
          setFields(emptyFields);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userTenantId, isMounted]);

  const getMissFieldsStatusByUserTenantId = React.useCallback(
    (id) => {
      return new Promise((resolve, reject) => {
        if (id !== null && isMounted) {
          getProfileMissedFields({ userTenantId: id })
            .then((emptyFields) => {
              resolve({
                isMissed: emptyFields.length > 0 ? true : false,
                fields: emptyFields,
              });
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    [isMounted]
  );

  const profileDetails = React.useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        try {
          if (isMounted && id !== null) {
            const res = await getProfileDetails({ userTenantId: id });
            resolve(res);
          }
        } catch (error) {
          reject(null);
        }
      });
    },
    [isMounted]
  );

  React.useEffect(() => {
    getEmptyFields();
  }, [getEmptyFields]);

  return { fields, getMissFieldsStatusByUserTenantId, profileDetails };
};
