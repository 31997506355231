import React from "react";
import { IconDataColor } from "../../../../../../common/components/icons";
import { IconBlock } from "../../../../../../styled/Common/Icons";
import { StyledButton } from "./styled";

const ResearchDataButton = ({ ...btnProps }) => {
  return (
    <StyledButton {...btnProps} data-tooltip-text="Data Browse">
      <IconBlock className="icon">
        <IconDataColor />
      </IconBlock>
      <div className="text">Data Browse</div>
    </StyledButton>
  );
};

export default ResearchDataButton;
