export const getMarginAndBoarderTopSize = (el) => {
  const style = getComputedStyle(el);

  return (
    Number.parseInt(style.marginTop) + Number.parseInt(style.borderTopWidth)
  );
};

export function isValidChild(parent, child) {
  if (parent.contains(child)) return true;
  return false;
}

export const getLayerPosition = (e) => {
  return {
    startX: e.clientX,
    startY: e.clientY,
    x: e.clientX,
    y: e.clientY,
  };
};

export function getLayerStyles(containerRef, scrollTop, state) {
  if (!containerRef || !state?.cellsContainer) {
    return null;
  }

  const containerRefReact = containerRef?.getBoundingClientRect();

  let cellsContainerRect = state?.cellsContainer?.getBoundingClientRect();

  let startX = state?.layerPosition?.startX - containerRefReact.x;
  let startY = state?.layerPosition?.startY - containerRefReact.y;
  let x = state?.layerPosition?.x - containerRefReact.x;
  let y = state?.layerPosition?.y - containerRefReact.y;

  let coordinateWidth = Math.abs(startX - x);
  let coordinateHeight = Math.abs(startY - y);

  let width = Math.min(state.maxDrawnWidth, coordinateWidth);
  let height = coordinateHeight;
  let top = startY - scrollTop + getMarginAndBoarderTopSize(containerRef);
  let left = startX;

  if (startX > x) {
    let maxLeftPosition = Math.abs(containerRefReact.x - cellsContainerRect.x);

    width = Math.min(state.width - state.maxDrawnWidth, coordinateWidth);
    left = Math.max(x, maxLeftPosition);
  }

  if (startY >= y) {
    top = Math.abs(y);
  }

  return {
    top,
    left,
    width,
    height,
  };
}

export const updateSelectedItems = (containerRef, state) => {
  if (!containerRef || !state?.cellsContainer) return;

  let nodes = [...state?.cellsContainer.querySelectorAll("[data-id")] || [];

  let tolerance = 0;
  const layerRect = document
    .querySelector(".selection__layer")
    ?.getBoundingClientRect();

  if (!layerRect) return;

  nodes.forEach((node) => {
    const nodeRect = node?.getBoundingClientRect();

    const aAboveB = nodeRect?.top + tolerance > layerRect?.bottom;
    const aBelowB = nodeRect?.bottom - tolerance < layerRect?.top;

    const isSelected = !(aAboveB || aBelowB);

    if (isSelected) node.dataset.selected = true;
    else node.dataset.selected = false;
  });
};
