import React from "react";
import * as Styled from "./styled";

import TimeBlocks from "./TimeBlocks";
import { format } from "date-fns";

const EventPanel = ({ currentSelectDate }) => {
  let currentHours = new Date().getHours();

  const eventBodyRef = React.useRef();

  return (
    <Styled.Wrapper>
      <div className="event__inner">
        <div className="event__head">
          <div className="date">{format(currentSelectDate, "dd MMM yyyy")}</div>
          <div className="day">{format(currentSelectDate, "EEEE")}</div>
        </div>

        <div className="event__body" ref={eventBodyRef}>
          <TimeBlocks
            eventBodyRef={eventBodyRef?.current}
            currentHours={currentHours}
          />
        </div>
      </div>
    </Styled.Wrapper>
  );
};

export default EventPanel;
