const config = {
  id: "budget",
  menuIcon: "menu-budget-01",
  label: "Budget",
  path: "/budget",
  children: {
    planner: {
      id: "budget-planer",
      path: "",
      label: "Budget Planner",
    },
  },
};

export default config;
