import styled from "styled-components";
import { fontStyle } from "../../../../../../../../../../../styled/utility";

export const BlockActivitiesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--layout-spacing-4);

    .titleBlock{
        text-align: center;
        border-radius: var(--border-radius-4);
        padding: var(--layout-spacing-8) var(--layout-spacing-32, 32px);
        background: #38B7FF;
        color: var(--white);
        ${fontStyle("x-small-medium")};
    }
`
