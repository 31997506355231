import API_ENDPOINTS from "../../services/api-endpoints";
import API from "../../services/api-tenant";

export const getUserMenuPermission = (userId, projectId = 0) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API.get(`${endpoints.PERMISSION_GET_USER_MENU}/${projectId}`)
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
