import TemplateSearch from "../../components/pages/template/template-search";
import CreateTemplate from "../../components/pages/template/create-template";
import BreadcrumbLink from "../../components/ui-components/Breadcrumb/BreadcrumbLink";
import { ROUTE_OPTIONS } from "../../constant/route-options";
import { permissionLoader } from "../loaders";

const child = ROUTE_OPTIONS.templates.children;

const key = "template";

export const templateRoutes = [
  {
    id: child["all-templates"].id,
    path: child["all-templates"].path,
    element: <TemplateSearch />,
    handle: {
      //crumb: () => <BreadcrumbLink label={child["all-templates"].label} />,
    },
    loader: async () => {
      return await permissionLoader(key, child["all-templates"].id);
    },
  },

  // Create a new template
  {
    id: child["create-templates"].id,
    path: child["create-templates"].path,
    element: <CreateTemplate />,
    handle: {
      crumb: () => <BreadcrumbLink label={child["create-templates"].label} />,
    },
    loader: async () => {
      return await permissionLoader(key, child["create-templates"].id);
    },
  },
];
