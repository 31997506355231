import styled from "styled-components";
import { fontStyle } from "../../../../../../../../../../../../styled/utility";

export const Item = styled.div`
  color: ${({ $textColor }) => $textColor};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  cursor: pointer;
  display: flex;
  padding: var(--layout-spacing-8, 8px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--layout-spacing-8, 8px);
  transition: var(--animate-1);
  ${fontStyle("x-small-medium")};

  &:hover {
    opacity: 0.5;
  }

`;
