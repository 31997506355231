import styled from "styled-components";
import TabBase from "../../../../../common/components/UI/TabBase";

export const Tab = styled(TabBase)`
  .base__tab__nav {
    display: flex;
    justify-content: center;

    ul {
      display: flex;
      align-items: center;
      gap: var(--layout-spacing-60);

      li {
        --item-bg-color: var(--color-accent-50, #eef8f8);
        --item-text-color: var(--color-accent-100);

        border-radius: var(--layout-borderRadius-12, 12px);
        border: 0px solid var(--layout-background-N50, #c1c4ce);
        list-style: none;
        display: flex;
        padding: var(--layout-spacing-8);
        color: var(--item-text-color);
        background-color: var(--item-bg-color);
        cursor: pointer;

        &:hover {
          --item-text-color: var(--color-accent-200);
        }

        &.is--active {
          --item-bg-color: var(--color-accent-75, #bae1e4);
          --item-text-color: var(--color-accent-500) !important;
        }

        .menu__icon {
          transition: none;
          --icon-button-icon-color: inherit;

          .button__icon {
            transition: none;
          }
        }
      }
    }
  }

  .base__tab__wrapper {
    .base__tab__content {
      margin-top: var(--layout-spacing-16);
    }
  }
`;
