import React from "react";
import IconButton from "./../../../../../common/components/Buttons/IconButton";
import IconAdd from "./../../../../../common/components/icons/Fill/add";

const BaseDashboardBlock = (props) => {
  const {
    onAddNew,
    title,
    blockSpan = 1,
    blockClassName,
    blockContentClassName,
    children,
  } = props;

  const blockClz = [
    "db_block",
    blockSpan > 1 ? `span-${blockSpan}` : "",
    blockClassName,
  ].join(" ");

  return (
    <div className={blockClz.trim()}>
      {/* Title */}
      {!!title && (
        <div className="block_title">
          {title}
          {!!onAddNew && (
            <IconButton
              btnIconSize={20}
              btnSize={24}
              icon={<IconAdd />}
              onClick={onAddNew}
            />
          )}
        </div>
      )}

      <div className={["block_content", blockContentClassName].join(" ")}>
        {children}
      </div>
    </div>
  );
};

export default BaseDashboardBlock;
