import React from "react";
import * as Styled from "./styled";
// import EventDialog from "../../EventDialog";
// import { findParentByClass } from "../../../../../../utility";

function TimeBlocks(props) {
  const { currentHours } = props;
  const containerRef = React.useRef();

  // const [anchorEl, setAnchorEl] = React.useState(null);

  // Temporarily disable
  
  // React.useEffect(() => {
  //   console.log("eventBodyRef ", containerRef);

  //   if (!containerRef || !currentHours) return;

  //   const element = containerRef?.current?.querySelector(
  //     `[data-index='${currentHours}']`
  //   );

  //   element.scrollIntoView({ block: "nearest", inline: "nearest" });
  // }, [currentHours]);

  return (
    <Styled.TimeBlockWrapper className="time__container" ref={containerRef}>
      <div className="time__blocks">
        {new Array(24).fill("").map((_, i) => {
          let time = `${String(i).padStart(2, "0")}:00`;

          return (
            <Styled.TimeBlock
              className="time__block"
              key={i}
              $isCurrentTime={currentHours === i}
              data-index={i}
              // onClick={(e) => setAnchorEl(e.target)}
            >
              <span>{time}</span>
            </Styled.TimeBlock>
          );
        })}
      </div>

      <Styled.EventItems className="event__items"></Styled.EventItems>
      {/* 
      <EventDialog
      // anchorEl={anchorEl}
      // onOutsideClick={() => setAnchorEl(null)}
      // appendTo={document.querySelector('.time__container')}
      /> */}
    </Styled.TimeBlockWrapper>
  );
}

export default TimeBlocks;
