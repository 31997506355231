import * as React from "react";
import { memo } from "react";
const SvgMenu = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5714 3.2H3.42856C3.02029 3.2 2.64296 2.89508 2.4388 2.4C2.23469 1.90492 2.23469 1.29508 2.4388 0.8C2.64296 0.304917 3.02029 0 3.42856 0H12.5714C12.9797 0 13.357 0.304917 13.5612 0.8C13.7653 1.29508 13.7653 1.90492 13.5612 2.4C13.357 2.89508 12.9797 3.2 12.5714 3.2ZM16 8C16 7.57566 15.8796 7.16866 15.6652 6.86867C15.451 6.5686 15.1602 6.4 14.8571 6.4H1.14285C0.734572 6.4 0.357246 6.70492 0.153086 7.2C-0.0510286 7.69508 -0.0510286 8.30492 0.153086 8.8C0.357246 9.29508 0.734572 9.6 1.14285 9.6H14.8571C15.1602 9.6 15.451 9.43142 15.6652 9.13133C15.8796 8.83134 16 8.42432 16 8ZM13.7143 14.4C13.7143 13.9757 13.5939 13.5687 13.3795 13.2687C13.1652 12.9686 12.8745 12.8 12.5714 12.8H3.42856C3.02029 12.8 2.64296 13.1049 2.4388 13.6C2.23469 14.0951 2.23469 14.7049 2.4388 15.2C2.64296 15.6951 3.02029 16 3.42856 16H12.5714C12.8745 16 13.1652 15.8314 13.3795 15.5313C13.5939 15.2313 13.7143 14.8243 13.7143 14.4Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgMenu);
export default Memo;
