import React from "react";
import {
  IconArrowLeft,
  IconArrowRight,
} from "../../../../../common/components/icons";
import { IconBlock } from "../../../../../styled/Common/Icons";
import { StyledArrow, StyledArrowsWrapper } from "./styled";

const ArrowButtons = () => {
  return (
    <StyledArrowsWrapper>
      <StyledArrow className="research_slide_btn_prev">
        <IconBlock className="icon">
          <IconArrowLeft />
        </IconBlock>
      </StyledArrow>

      <StyledArrow className="research_slide_btn_next">
        <IconBlock className="icon">
          <IconArrowRight />
        </IconBlock>
      </StyledArrow>
    </StyledArrowsWrapper>
  );
};

export default ArrowButtons;
