import styled from "styled-components";

export const StyledToolbar = styled.div`
  --tb-top-space: var(--layout-spacing-8);
  --tb-px: 0 var(--layout-spacing-16);

  width: 100%;

  .top {
    background-color: var(--layout-background-white);
    display: flex;
    align-items: center;
    padding: var(--tb-px);
    padding-top: var(--tb-top-space);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
    justify-content: space-between;

    .start {
      display: flex;
      align-items: center;
    }

    .end {
      display: flex;
      flex-grow: 0;
      align-items: center;
      justify-content: flex-end;
      gap: var(--layout-spacing-24);
    }
  }

  .bottom {
    display: block;
    padding: var(--tb-px);
    padding-block: var(--layout-spacing-12);

    .inner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .start {
        display: flex;
        align-items: center;
        gap: var(--layout-spacing-8);
      }

      .end {
        /* flex-grow: 1; */

        display: flex;
        align-items: center;
        flex: 1 0 0;
        flex-grow: 0px;

        .line {
          margin-inline-start: var(--layout-spacing-12);
          margin-inline-end: var(--layout-spacing-16);
          height: var(--layout-border-width-01);
          width: 100%;
          background-color: ${({ theme }) => theme.colors.color_2()};
        }

        .actions {
          display: flex;
          align-items: center;
          gap: var(--layout-spacing-8);
          min-height: 38px;
        }
      }
    }

    .search__input__block {
      width: 400px;
      max-width: 400px;
    }

    .add__new__btn {
      margin-left: var(--layout-spacing-8);
    }
  }
`;
