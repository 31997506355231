import React from "react";
import { StyledVideoContainer } from "./styled";

const VideoView = ({ placeholderUrl, videoUrl }) => {
  return (
    <StyledVideoContainer>
      <img src={placeholderUrl} alt="" />
    </StyledVideoContainer>
  );
};

export default VideoView;
