import React from "react";
import * as Styled from "./styled";
import FieldGroup from "../../../../../../../../common/components/Form/FieldGroup";
import { FormGrid } from "../styled";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  onUpdateDoSubmitState,
  useProjectWizardStore,
} from "../../../../store";
import SelectField from "../../../../../../../../common/components/Form/SelectField";
import { getAllLanguagesAsOptionSource } from "../../../../../API/Language";
import { getProjectSpecs } from "../../../../../API/Project";

const validationSchema = Yup.object().shape({
  format: Yup.object()
    .shape({
      value: Yup.number().required(),
      label: Yup.string().required(),
    })
    .nullable()
    .required("This field is required"),
  delivery: Yup.object()
    .shape({
      value: Yup.number().required(""),
      label: Yup.string().required(""),
    })
    .nullable()
    .required("This field is required"),
  languge: Yup.object()
    .shape({
      value: Yup.string().required(""),
      label: Yup.string().required(""),
    })
    .nullable()
    .required("This field is required"),
});

const initialValues = {
  format: null,
  delivery: null,
  languge: null,
};

const deliveryFormates = [
  { value: 1, label: "MP4" },
  { value: 2, label: "MOV" },
  { value: 3, label: "Apple Pro Res" },
  { value: 4, label: "H 264" },
  { value: 5, label: "Blue-Ray" },
];
const filmingFormates = [
  { value: 1, label: "1080p" },
  { value: 2, label: "4K" },
  { value: 3, label: "8K" },
  { value: 4, label: "imax" },
  { value: 5, label: "16mm" },
];

const StepThree = (props) => {
  const formShouldSubmit = useProjectWizardStore((state) => state.doSubmit);
  const [allLanguages, setAllLanguages] = React.useState([]);

  const activeProjectId = useProjectWizardStore(
    (state) => state.activeProjectId
  );
  console.log("activeProjectId", activeProjectId);

  React.useEffect(() => {
    getAllLanguagesAsOptionSource().then((result) => {
      console.log("getAllLanguagesAsOptionSource:::", result);
      setAllLanguages(result?.map((lang) => lang) || []);
    });
  }, []);

  const { onSubmitted } = props;

  const onSubmitHandler = React.useCallback(
    (values, options) => {
      onSubmitted(values, { ...options, stepKey: "step_03" });
    },
    [onSubmitted]
  );

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: onSubmitHandler,
    validateOnChange: true,
    validateOnBlur: true,
  });

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
    formik;

  React.useEffect(() => {
    if (formShouldSubmit) {
      handleSubmit();
      onUpdateDoSubmitState();
    }
  }, [formShouldSubmit, handleSubmit]);
  const onLanguageChange = (lang) => {
    setFieldValue("languge", lang);
  };

  React.useEffect(() => {
    getProjectSpecs(activeProjectId || 0).then((project) => {
      console.log("getProjectSpecs", project);

      if (project) {
        if (project?.filmingFormatId) {
          setFieldValue(
            "format",
            ...filmingFormates.filter(
              (f) => f.value === project?.filmingFormatId
            )
          );
        }
        if (project?.deliveryFormatId) {
          setFieldValue(
            "delivery",
            ...deliveryFormates.filter(
              (d) => d.value === project?.deliveryFormatId
            )
          );
        }
        if (project?.languageId) {
          setFieldValue(
            "languge",
            ...allLanguages.filter((l) => l.value === project?.languageId)
          );
        }
      }
    });
  }, [activeProjectId, allLanguages, setFieldValue]);
  return (
    <Styled.Container>
      <FormGrid>
        <div className="grid-item full">
          <FieldGroup
            label="Filming Format"
            className="field__group"
            error={touched.format && errors.format}
          >
            <SelectField
              name="format"
              placeholder="Filming Format"
              value={values.format}
              onChange={(val) => setFieldValue("format", val)}
              options={filmingFormates}
            />
          </FieldGroup>
        </div>
        <div className="grid-item full">
          <FieldGroup
            label="Delivery Format"
            className="field__group"
            error={touched.delivery && errors.delivery}
          >
            <SelectField
              name="delivery"
              placeholder="Delivery Format"
              value={values.delivery}
              onChange={(val) => setFieldValue("delivery", val)}
              options={deliveryFormates}
            />
          </FieldGroup>
        </div>
        <div className="grid-item half">
          <FieldGroup
            label="Language"
            className="field__group"
            error={touched.languge && errors.languge}
          >
            {/* <CountryField
              value={values.languge}
              onChange={(val) => {
                setFieldValue("languge", val);
              }}
            /> */}
            <SelectField
              name="language"
              value={values.languge}
              options={allLanguages}
              onChange={(val) => {
                onLanguageChange(val);
              }}
            />
          </FieldGroup>
        </div>
      </FormGrid>
    </Styled.Container>
  );
};

export default StepThree;
