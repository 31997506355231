import React from "react";
import { useMatches } from "react-router";
import "./styles.scss";

const Breadcrumb = () => {
  const matches = useMatches();

  let crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => match.handle.crumb(match.data));

  return (
    <div className="top__nav__breadcrumb">
      <div className="top__nav__breadcrumb-container">
        <ul>
          {crumbs.map((crumb, index) => {
            return <li key={index}>{crumb}</li>;
          })}
          {/* <li className="icon icon-lp-home">
            <a href="#">Home</a>
          </li>
          <li className="icon icon-lp-arrow-right-line">
            <a href="#">Settings</a>
          </li>
          <li className="icon icon-lp-arrow-right-line">
            <a href="#">&nbsp;&nbsp;Client Setup</a>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default Breadcrumb;
