import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.2201 7.55752C9.83702 6.89417 8.98832 6.66681 8.32497 7.0503C8.16883 7.14024 8.03781 7.25712 7.93235 7.38997C7.56301 6.69786 7.26808 6.01853 7.15486 5.45425C7.15486 5.45425 8.29256 5.03058 9.62336 4.35673C9.57862 4.10974 9.51471 3.86823 9.45855 3.68105C9.16591 3.68197 8.88058 3.53496 8.72398 3.26378C8.49161 2.86065 8.62948 2.34522 9.0326 2.1133C9.43573 1.88047 9.95024 2.01789 10.1831 2.42101C10.3401 2.69311 10.3255 3.01314 10.1776 3.26652C10.3182 3.41535 10.5049 3.6007 10.7076 3.76962C11.5454 3.28295 12.3612 2.71821 12.9378 2.1165C12.9378 2.1165 13.4208 2.75063 14.0029 3.6281C13.9299 3.65594 13.8578 3.6879 13.787 3.72808C13.1236 4.11157 12.8963 4.95936 13.2798 5.62271C13.6628 6.28606 14.5111 6.51342 15.1744 6.13084C15.2493 6.08701 15.3182 6.03725 15.3821 5.98292C15.767 6.77136 16.0309 7.52282 15.9971 8.05971C15.9971 8.05971 14.9297 8.47242 13.7112 9.07186C13.7541 9.27958 13.8066 9.4768 13.8546 9.63522C14.1472 9.63431 14.4321 9.78132 14.5882 10.0534C14.821 10.4556 14.6832 10.9711 14.2801 11.2039C13.8774 11.4367 13.362 11.2984 13.1296 10.8953C12.9725 10.6241 12.9876 10.3031 13.1355 10.0498C13.0122 9.91965 12.8538 9.76077 12.6803 9.61011C11.7737 10.1146 10.9222 10.6871 10.4931 11.2372C10.4931 11.2372 9.95207 10.5542 9.30379 9.6092C9.44394 9.57998 9.58136 9.52885 9.71239 9.45398C10.3757 9.06957 10.6031 8.22178 10.2201 7.55752Z"
      fill="currentColor"
    />
    <path
      d="M0.694394 12.0855C0.966034 11.9284 1.28652 11.9426 1.53945 12.0909C1.66134 11.9759 1.80743 11.8307 1.9485 11.67C1.9485 11.67 0.88614 10.0721 0 9.21976C0 9.21976 0.848248 8.69018 1.9485 8.05148C1.97909 8.1647 2.02338 8.27518 2.08455 8.38156C2.46759 9.04491 3.31584 9.27226 3.97919 8.88877C4.64254 8.50528 4.86989 7.65749 4.48686 6.99414C4.43618 6.90603 4.37637 6.82568 4.31018 6.75354C5.16527 6.31755 5.94367 5.97241 6.41253 5.88247C6.41253 5.88247 6.8467 7.10143 7.53014 8.46191C7.72508 8.42082 7.90998 8.37106 8.06018 8.3254C8.05881 8.03276 8.20582 7.74743 8.47746 7.59083C8.88058 7.35891 9.39555 7.49633 9.62839 7.89945C9.86077 8.30258 9.72243 8.81801 9.31977 9.04993C9.04813 9.20698 8.72718 9.19283 8.47472 9.04445C8.35373 9.15859 8.20947 9.30285 8.06885 9.46081C8.54913 10.2849 9.10383 11.066 9.69367 11.5659C9.69367 11.5659 9.02667 12.0571 8.07388 12.678C8.0474 12.6086 8.01681 12.5397 7.978 12.4731C7.59497 11.8097 6.74672 11.5823 6.08337 11.9649C5.42002 12.3484 5.19266 13.1962 5.57616 13.8605C5.6209 13.9381 5.67294 14.0093 5.72955 14.0746C5.06575 14.4266 4.39829 14.7361 3.79794 14.9352C3.79794 14.9352 3.24005 13.8723 2.53835 12.6621C2.32332 12.7059 2.11788 12.7602 1.95353 12.81C1.9549 13.1026 1.80789 13.388 1.53625 13.5445C1.13313 13.7765 0.618153 13.639 0.385318 13.2359C0.153397 12.8328 0.291271 12.3174 0.694394 12.0855Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
