import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import login from "../../img/login.svg";
import "../../sass/login/login.scss";
import SingleHeader from "../layout/single_header";
import MIButton from "../../controls/buttons/mButton";
import MIInputField from "../../controls/textFileds/mTextFiled";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Loader from "react-loader-spinner";
import API_ENDPOINTS from "../../../services/api-endpoints";
import API from "../../../services/api";
// class ResetPassword extends Component {
//   state = {};
//   render() {
//     return (
//       <div>
//         <section className="layout">
//           <SingleHeader />
//           <div className="body">
//             <div className="form_wrapper" style={{ paddingBottom: "3%" }}>
//               <div className="login-logo">
//                 <img src={login} alt="" width={70} height={70} />
//               </div>
//               <div className="form_container">
//                 <div className="title_container">
//                   <h2 className="t1">Reset your password</h2>
//                   <h3 className="t1-sub">
//                     create more secure password to protect yourself
//                   </h3>
//                 </div>
//                 <div className="row clearfix">
//                   <div className="">
//                     <form method="Get" action="/">
//                       <div className="input_field">
//                         {" "}
//                         <span className="lbl">Email</span>
//                         <p>&nbsp;</p>
//                       </div>
//                       <div className="input_field">
//                         {" "}
//                         <span>
//                           <i
//                             aria-hidden="true"
//                             className="fa-regular fa-envelope"
//                           ></i>
//                         </span>
//                         <input
//                           type="email"
//                           name="email"
//                           placeholder=""
//                           required
//                         />
//                         {/* <MIInputField></MIInputField> */}
//                       </div>

//                       <input
//                         className="button"
//                         type="submit"
//                         value="Send Reset Password Request"
//                       />
//                       {/* <MIButton type="btn-cm">
//                         Send Reset Password Request
//                       </MIButton> */}
//                     </form>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//       </div>
//     );
//   }
// }
const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [userName, setUserName] = useState("");
  const [token, setToken] = useState("");
  const [resetURL, setResetURL] = useState("");
  const [Msg, setMsg] = useState("");
  const [msgCls, setMsgCls] = useState("");

  let url = "";

  const [show, setShow] = useState(false); //Loading Indicator

  const Html = ({ title, children }) => (
    <html>
      <head>
        <title>{title}</title>
      </head>
      <body>{children}</body>
    </html>
  );

  const Button = ({
    href,
    backgroundColor,
    color,
    height,
    fontSize,
    borderRadius,
    children,
  }) => (
    <table
      style={{
        backgroundColor,
        color,
        fontSize,
        fontWeight: 700,
        width: 200,
        height,
        borderRadius,
      }}
    >
      <tr>
        <td>
          <a
            href={href}
            style={{
              color,
              display: "block",
              lineHeight: "30px",
              textDecoration: "none",
              textAlign: "center",
            }}
          >
            {children}
          </a>
        </td>
      </tr>
    </table>
  );

  Button.propTypes = {
    backgroundColor: "",
    color: "",
    height: 0,
    fontSize: 0,
    borderRadius: 0,
  };

  Button.defaultProps = {
    backgroundColor: "steelblue",
    color: "#fff",
    height: 30,
    fontSize: 16,
    borderRadius: 3,
  };

  const Email = () => (
    <Html title="Loop Rest Password - Forget password request">
      <h1>Please use below link to reset password!</h1>
      <a href={url} value={url} onChange={(e) => setResetURL(e.target.value)}>
        Rest Password Now
      </a>
    </Html>
  );
  var ReactDOMServer = require("react-dom/server");
  const emailHtmlMarkup = ReactDOMServer.renderToStaticMarkup(<Email />);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShow(true); //Loading Indicator on
    setMsg("");
    setMsgCls("");
    try {
      const endpoints = new API_ENDPOINTS();
      const response = API.post(endpoints.FORGETPASSWORDTOKENREQUEST, {
        userName: userName,
        redirectUrl: window.location.origin + "/change-pwd",
      }).then((response) => {
        console.log("FORGETPASSWORDTOKENREQUEST", response);
        setShow(false); //Loading Indicator off

        if (response.data.isSuccess === true) {
          //console.log(response);

          setUserName("");
          setMsgCls("succmsg");
          setMsg(
            "please check and verify your email account, for reset password!"
          );

          setResetURL("");
          setToken("");

          setShow(false); //Loading Indicator off
        } else {
          console.log(response.data.result.message);
          setMsgCls("errmsg");
          setMsg(response.data.result.message);

          toast.error(response.data.result.message);
          setShow(false); //Loading Indicator off

          return;
        }
      });
    } catch (err) {
      console.log(err);
      toast.error(err);
      setShow(false); //Loading Indicator off
    }
  };

  return (
    <div>
      <section className="layout">
        <SingleHeader />
        <div className="body">
          {/* <Email></Email> */}
          <div className="form_wrapper" style={{ paddingBottom: "3%" }}>
            <div className="login-logo">
              <img src={login} alt="" width={70} height={70} />
            </div>
            <div className="form_container">
              <p className={msgCls}>{Msg}</p>
              <div className="title_container">
                <h2 className="t1">Reset your password</h2>
                <h3 className="t1-sub">
                  create more secure password to protect yourself
                </h3>
              </div>
              <div className="row clearfix">
                <div className="">
                  <form onSubmit={handleSubmit}>
                    <div className="input_field">
                      {" "}
                      <span className="lbl">Email</span>
                      {/* <p>&nbsp;</p> */}
                    </div>
                    <div className="input_field">
                      {" "}
                      <span>
                        <i
                          aria-hidden="true"
                          className="fa-regular fa-envelope"
                        ></i>
                      </span>
                      <input
                        type="email"
                        name="email"
                        placeholder=""
                        required
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                      {/* <MIInputField></MIInputField> */}
                    </div>

                    <input
                      className="button"
                      type="submit"
                      value="Send Reset Password Request"
                    />
                    {/* <MIButton type="btn-cm">
                    Send Reset Password Request
                  </MIButton> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <Loader.ThreeDots
              visible={show}
              width={100}
              height={30}
              color="#347084"
            ></Loader.ThreeDots>
          </div>
        </div>
      </section>
    </div>
  );
};
export default ResetPassword;
