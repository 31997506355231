import styled from "styled-components";
import Column from "../BaseColumn";
import BaseControlItem from "../BaseControlItem";

export const TXColumn = styled(Column)`
  /* .column__body {
    background: var(--color-neutral-20, #f5f5f7);
  } */
`;

export const TXItems = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`;

export const TXItem = styled(BaseControlItem)`
  min-height: calc(var(--column-cell-height) * 7) !important;

  &:not(:last-child) {
    border-bottom: 1px solid var(--column-border-color);
  }

  .text {
    color: var(--font-tint-02);
  }
`;
