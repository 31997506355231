import React from "react";
import * as Styled from "./styled";
import FieldGroup from "../../../Form/FieldGroup";
import TextAreaField from "../../../Form/TextAreaField";

const Footer = ({ viewMode, note, handleNoteChange }) => {
  return (
    <Styled.Footer>
      {viewMode ? (
        <Styled.NoteContent>
          <div className="title">Notes</div>
          <div className="text">{note}</div>
        </Styled.NoteContent>
      ) : (
        <>
          <FieldGroup label="Notes">
            <TextAreaField
              name="specialNote"
              onChange={(e) => handleNoteChange(e.target.value)}
              value={note}
            />
          </FieldGroup>

          {/* <div className="footer__actions">
            <UIButton
              type="button"
              size={UIButton.Sizes.Small}
              variant={UIButton.Variant.Primary}
              onClick={onSaveHandler}
              rounded
            >
              Save
            </UIButton>
          </div> */}
        </>
      )}
    </Styled.Footer>
  );
};

export default Footer;
