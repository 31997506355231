import API_ENDPOINTS from "../../../../../services/api-endpoints";
import API_Project from "../../../../../services/api-project";

export const __addUserToProject = ({ member, projectId }) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Project.post(endpoints.PROJECT_ADD_USER_TO_PROJECT, {
      memberUserId: member?.id,
      memberName: member.givenName,
      projectId: projectId,
      projectMemberId: 0,
      ProjectRoleId: member?.jobTitleId,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });
};

export const __sendInvitation = ({
  projectMemberId,
  userDocumentId,
  projectId,
  senderNote,
}) => {
  var base_url = window.location.origin;
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Project.post(endpoints.PROJECT_SEND_PROJECT_ADDED_USER_INVITAION, {
      projectId: projectId,
      projectMemberId: projectMemberId,
      invitationUrl: base_url + "/confirm-project-member/{invitationId}",
      senderNote: senderNote,
      //templateId: 0,
      userDocumentId: userDocumentId,
      //fromDate: new Date(),
      isProjectDocumentRequired: false,
    }).then((response) => {
      if (!response.data.isSuccess) {
        reject(response);
        return;
      }

      resolve(response);
    });
  });
};
