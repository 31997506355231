import * as React from "react";
import { memo } from "react";
const SvgArrowDown = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8 12.6L0 3H16L8 12.6Z" fill="currentColor" />
  </svg>
);
const Memo = memo(SvgArrowDown);
export default Memo;
