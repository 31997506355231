import React from "react";
import * as Styled from "./styled";
import Typography from "../../../Typography";
import IconClose from "../../../icons/Line/Close/01";
import { IconBlock } from "../../../../../styled/Common/Icons";
import AnimateLoader from "../../../AnimateLoader";


const BaseFlexModal = (props) => {
  const {
    visibility,
    onRootClose,
    title,
    titleComponent,
    subTitle,
    body,
    bodyComponent,
    modalCss = undefined,
    onClose,
    isLoading,
    ...otherProps
  } = props;

  const renderTitle = React.useMemo(() => {
    if (titleComponent) {
      return titleComponent({ title, subTitle });
    }

    return <TitleComponent title={title} subTitle={subTitle} />;
  }, [titleComponent, title, subTitle]);

  const renderBody = React.useMemo(() => {
    if (bodyComponent) {
      return bodyComponent({ body, onClose, onRootClose, visibility });
    }

    return <WallComponent>{body}</WallComponent>;
  }, [body, bodyComponent, onClose, onRootClose, visibility]);

  return (
    <Styled.Modal
      visibility={visibility}
      onRootClose={onRootClose}
      outerClassName="flex__modal__outer"
      cssBackdropStyle={modalCss ? modalCss : Styled.modalCss}
      {...otherProps}
    >
      {isLoading ? (
        <Styled.LoaderWrapper>
          <AnimateLoader />
        </Styled.LoaderWrapper>
      ) : null}

      {/* 
        Modal Header
    */}
      <Styled.ModalHeader className="header__container">
        {/* Title and close btn */}
        <div className="header__inner">
          <div className="header__content">{renderTitle}</div>

          <div className="btn__close" role="button" onClick={onClose}>
            <IconBlock>
              <IconClose />
            </IconBlock>
          </div>
        </div>
      </Styled.ModalHeader>

      {/* 
         Body Container 
         */}
      <Styled.ModalBody className="body__container">
        {renderBody}
      </Styled.ModalBody>
    </Styled.Modal>
  );
};

const TitleComponent = ({ title, subTitle }) => {
  return (
    <div className="title__element">
      <Typography
        color={Typography.font_color.color_shade_primary}
        fontWeight={Typography.font_weight.bold}
        variant={Typography.font_variant.h5}
      >
        {title}
      </Typography>

      {subTitle && (
        <Typography
          color={Typography.font_color.tint_04}
          fontWeight={Typography.font_weight.regular}
          variant={Typography.font_variant.xSmall}
        >
          {subTitle}
        </Typography>
      )}
    </div>
  );
};

export const WallComponent = ({ children }) => {
  return <div className="wall__element">{children}</div>;
};

export default BaseFlexModal;
