const config = {
  id: "template_1",
  menuIcon: "fa-solid fa-gears",
  label: "Templates",
  path: "/template-v2",
  TempBreadCrumPath: "/templates",
  children: {
    test: {
      id: "test",
      path: "test",
      label: "Test",
    },
    all_templates: {
      id: "all_templates",
      path: "",
      label: "All Templates",
    },
    create_template: {
      id: "create_template",
      path: "create",
      label: "Create Template",
    },
    edit_template: {
      id: "edit_template",
      path: "edit",
      label: "Edit Template",
    },
  },
};

export default config;
