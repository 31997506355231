import React, { useState } from "react";
import { format, formatDistanceToNow } from "date-fns";

import UIButton from "../../../../../../../common/components/Buttons/UIButton";
import InputField from "../../../../../../../common/components/Form/InputField";
import TextAreaField from "../../../../../../../common/components/Form/TextAreaField";
import FieldGroup from "../../../../../../../common/components/Form/FieldGroup";
import FileUploadManager from "../../../../../../../common/components/Form/FileUploadManager";
import FormGroup from "../../../../../../../components/controls/FromGroup";
import InsertUrlList from "../../../../../../../common/components/Form/InsertUrlList/index";
import DeliverablesIcon from "../../../../../../../common/components/icons/Line/Deliverables/02";
import TimeLine from "./Timeline";
import {
  popupContainer,
  popupHeader,
  popupFooter,
  buttonLeft,
  buttonRight,
  labelStyle,
  dueDateBadge,
  dueDateContainer,
  attachmentSection,
  separatorLine1,
  separatorLine2,
  verticalSeparator,
  formSection,
  h3style,
  h4style,
  dueDateText,
  dueDateBold,
  iconStyle,
  scrollStyle,
} from "./style";
import ChangeRequestToast from "./Toast";
import { useMilestone } from "../../../context/MilestoneContext";
import {
  getSubmissionStatusName,
  SubmissionActions,
  SubmissionStatusTypes,
} from "../../../constant";
import { ActionTypes } from "./action.types";

const Form = ({ state, dispatch }) => {
  const {
    toggleDeliverableModalHandler,
    submissionValueSubmitHandler,
    changeRequestCreateHandler,
    changeRequestStateHandler,
    deliverableModalState,
  } = useMilestone();

  console.log("Form", state);

  const {
    submissionId,
    cellId,
    selectedCellState,
    currentState,
    formValues,
    isAuthor,
    changeRequests,
    changeLogs,
    isLoading,
    fileUploadSections,
    isResubmit,
  } = state;

  const canEdit = isAuthor;

  const rowId = selectedCellState?.row?.original?.id || 0;
  const stateId = currentState || SubmissionStatusTypes.Prepared;
  let USER = JSON.parse(localStorage.getItem("user"));
  const userID = USER?.result?.userId || "";
  console.log("currentState", currentState);
  console.log("deliverableModalState", deliverableModalState);

  const [enabledChangeRequest, setEnabledCanChangeRequest] = useState(false);

  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [urlList, setUrlList] = useState([]);
  const [uploadFiles, setUploadedFiles] = React.useState([]);

  // Set field values
  React.useEffect(() => {
    setSubject(formValues?.subject || "");
    setDescription(formValues?.note || "");
    setUrlList(formValues?.links || []);
    setUploadedFiles(formValues?.attachments || []);
  }, [formValues]);

  // use for change request input
  const [changeRequestDescription, setChangeRequestDescription] = useState("");

  // Update state
  const removeRequestFromList = React.useCallback(
    (item) => {
      dispatch({
        type: ActionTypes.CHANGE_REQUEST_ITEM_UPDATE,
        payload: {
          changeRequestId: item?.changeRequestId,
        },
      });
    },
    [dispatch]
  );

  const handleAcceptChangeRequest = (item) => {
    const request = {
      ...item,
      rowId,
      changeToStateId: SubmissionStatusTypes.Approved,
    };
    console.log("Change Request Accepted.", request);
    changeRequestStateHandler(request);
    removeRequestFromList(item);
  };

  const handleRejectChangeRequest = (item) => {
    const request = {
      ...item,
      rowId,
      changeToStateId: SubmissionStatusTypes.Rejected,
    };
    console.log("Change Request Rejected..", request);
    changeRequestStateHandler(request);
    removeRequestFromList(item);
  };

  const submitHandle = (action) => {
    const values = {
      submissionId,
      rowId,
      cellId,
      stateId,
      subject,
      description,
      changeRequestDescription,
      uploadFiles,
      urlList,
    };

    if (enabledChangeRequest) {
      const request = {
        ...values,
        userId: userID,
        stateId: SubmissionStatusTypes.ChangeRequested,
      };
      const submissionRequest = {
        ...values,
        stateId: SubmissionStatusTypes.ChangeRequested,
      };
      console.log("Submit changed request", request);
      changeRequestCreateHandler(request);
      submissionValueSubmitHandler(submissionRequest);

      return;
    }

    console.log("Submission", values);
    switch (action) {
      case SubmissionActions.Submit: {
        const request = {
          ...values,
          stateId:
            values?.submissionId === 0
              ? SubmissionStatusTypes.Submitted
              : SubmissionStatusTypes.Updated,
        };
        submissionValueSubmitHandler(request);
        break;
      }
      case SubmissionActions.Resubmit: {
        const request = {
          ...values,
          stateId: SubmissionStatusTypes.Resubmitted,
        };
        submissionValueSubmitHandler(request);
        break;
      }
      case SubmissionActions.Approved: {
        const request = {
          ...values,
          stateId: SubmissionStatusTypes.Approved,
        };
        submissionValueSubmitHandler(request);
        break;
      }
      default:
        return;
    }
    //submissionValueSubmitHandler(values);
  };

  const changeRequestHandler = React.useCallback(() => {
    setEnabledCanChangeRequest(true);
  }, []);

  return (
    <div style={popupContainer}>
      <div style={{ display: "flex" }}>
        <div style={iconStyle}>
          <DeliverablesIcon width={"40px"} height={"45px"} />
        </div>

        <div style={popupHeader}>
          <h4 style={h4style}>Deliverables Submission</h4>
          <h3 style={h3style}>Staff Contracts & Log</h3>
        </div>
      </div>

      <hr style={separatorLine1} />

      <div style={{ display: "flex" }}>
        {/* left col (Due Date stuff..) */}
        <div style={scrollStyle}>
          <div style={dueDateContainer}>
            {deliverableModalState?.data?.value?.due !== null ? (
              <div style={dueDateBadge}>
                <div style={dueDateText}>
                  Due: {deliverableModalState?.data?.label}
                </div>
                <div style={dueDateBold}>
                  {format(
                    new Date(deliverableModalState?.data?.value?.due?.dueDate),
                    "dd MMM yyyy"
                  )}
                </div>
              </div>
            ) : null}

            {changeLogs.map((log, i) => {
              const changeDate = format(
                new Date(log?.sysUpdatedOn),
                "yyy-MM-dd"
              );
              const changeTime = format(new Date(log?.sysUpdatedOn), "HH:mm");
              return (
                <TimeLine
                  key={i}
                  Status={getSubmissionStatusName(log?.to)}
                  AuthorName={log?.displayName}
                  TimelineDate={changeDate}
                  TimelineTime={changeTime}
                />
              );
            })}
          </div>
        </div>

        <div style={verticalSeparator}></div>

        {/* right col (Form field stuffs..) */}
        <div style={formSection}>
          {isAuthor &&
            changeRequests?.map((request, i) => {
              const requestedTime = formatDistanceToNow(
                new Date(request?.sysUpdatedOn)
              );
              return (
                <ChangeRequestToast
                  key={i}
                  RequestTitle={"Change Request " + request?.changeRequestId}
                  RequestAuthor={request?.displayName}
                  RequestedTime={requestedTime}
                  RequestMessage={request?.text}
                  onAccept={() => {
                    handleAcceptChangeRequest(request);
                  }}
                  onReject={() => {
                    handleRejectChangeRequest(request);
                  }}
                />
              );
            })}

          <div>
            <FieldGroup>
              <label style={labelStyle}>Subject</label>
              <InputField
                disabled={!canEdit}
                placeholder="Enter Subject"
                value={subject}
                onChange={
                  canEdit ? (e) => setSubject(e.target.value) : undefined
                }
                style={{ justifyContent: "flex-start" }}
              />
            </FieldGroup>
          </div>

          <div>
            <FieldGroup>
              <label style={labelStyle}>Description</label>
              <TextAreaField
                disabled={!canEdit}
                placeholder="Enter description here"
                value={description}
                onChange={
                  canEdit ? (e) => setDescription(e.target.value) : undefined
                }
              />
            </FieldGroup>
          </div>

          <div>
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <div style={{ width: "100%", marginRight: "10px" }}>
                <FieldGroup>
                  <label style={labelStyle}>Link</label>
                  <InsertUrlList
                    isPreviewMode={!canEdit}
                    value={urlList}
                    onChange={(values) => {
                      setUrlList(values);
                    }}
                  />
                </FieldGroup>
              </div>
            </div>
          </div>

          <div style={attachmentSection}>
            <div>
              <FormGroup>
                <label style={labelStyle}>Attachments</label>
                <FileUploadManager
                  //   generalPlaceholderText="Video/Audio/Images"
                  isPreviewMode={!canEdit}
                  defaultValue={uploadFiles}
                  uploadSections={fileUploadSections}
                  onChange={(payload) => {
                    setUploadedFiles(payload?.value);
                    // console.log(payload?.value);
                  }}
                />
              </FormGroup>
            </div>
          </div>

          {!isAuthor && enabledChangeRequest ? (
            <div>
              <FieldGroup>
                <label style={labelStyle}>Change Request</label>
                <TextAreaField
                  placeholder="Enter reason here"
                  value={changeRequestDescription}
                  onChange={(e) => setChangeRequestDescription(e.target.value)}
                />
              </FieldGroup>
            </div>
          ) : null}
        </div>
      </div>

      <hr style={separatorLine2} />

      <div style={popupFooter}>
        {!isLoading && currentState !== SubmissionStatusTypes.Approved ? (
          <>
            <div style={buttonLeft}>
              <UIButton
                size={"sm"}
                variant={UIButton.Variant.Danger}
                onClick={() => toggleDeliverableModalHandler(false)}
              >
                Cancel
              </UIButton>
            </div>
            <div style={buttonRight}>
              {!isAuthor && !enabledChangeRequest ? (
                <UIButton
                  variant={UIButton.Variant.Secondary}
                  size={"sm"}
                  onClick={changeRequestHandler}
                >
                  Change request
                </UIButton>
              ) : null}

              {isAuthor && !isResubmit ? (
                <UIButton
                  variant={UIButton.Variant.Success}
                  size={"sm"}
                  onClick={() => submitHandle(SubmissionActions.Submit)}
                >
                  Submit
                </UIButton>
              ) : null}
              {!isAuthor &&
              !enabledChangeRequest &&
              currentState !== SubmissionStatusTypes.ChangeRequested ? (
                <UIButton
                  variant={UIButton.Variant.Success}
                  size={"sm"}
                  onClick={() => submitHandle(SubmissionActions.Approved)}
                >
                  Approve
                </UIButton>
              ) : null}
              {isAuthor && isResubmit ? (
                <UIButton
                  variant={UIButton.Variant.Success}
                  size={"sm"}
                  onClick={() => submitHandle(SubmissionActions.Resubmit)}
                >
                  Resubmit
                </UIButton>
              ) : null}
              {!isAuthor && enabledChangeRequest ? (
                <UIButton
                  variant={UIButton.Variant.Secondary}
                  size={"sm"}
                  onClick={() => submitHandle(SubmissionActions.ChangeRequest)}
                >
                  Submit Request
                </UIButton>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Form;
