import * as React from "react";
import { memo } from "react";
const Svg06 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.34812 5.18493C9.77633 5.18493 10.9346 4.02431 10.9346 2.59262C10.9346 1.16083 9.77652 0 8.34812 0C6.91992 0 5.76169 1.16062 5.76169 2.5923C5.76169 4.0241 6.91973 5.18493 8.34812 5.18493ZM9.64265 2.18799C9.86575 2.18799 10.0459 2.36898 10.0459 2.59235C10.0459 2.81616 9.86568 2.99672 9.64265 2.99672C9.41981 2.99672 9.23943 2.81573 9.23943 2.59235C9.23936 2.36917 9.41974 2.18799 9.64265 2.18799ZM8.34812 0.890619C8.57077 0.890619 8.75152 1.07161 8.75152 1.29498C8.75152 1.51836 8.57077 1.69935 8.34812 1.69935C8.12528 1.69935 7.9449 1.51836 7.9449 1.29498C7.9449 1.07135 8.12528 0.890619 8.34812 0.890619ZM8.34812 3.48564C8.57077 3.48564 8.75152 3.66663 8.75152 3.88983C8.75152 4.11321 8.57077 4.29445 8.34812 4.29445C8.12528 4.29445 7.9449 4.11327 7.9449 3.88983C7.9449 3.66665 8.12528 3.48564 8.34812 3.48564ZM7.05405 2.18802C7.27652 2.18802 7.45727 2.36901 7.45727 2.59238C7.45727 2.81619 7.27671 2.99675 7.05405 2.99675C6.83095 2.99675 6.65039 2.81576 6.65039 2.59238C6.6502 2.3692 6.83095 2.18802 7.05405 2.18802Z"
      fill="currentColor"
    />
    <path
      d="M4.08124 5.18509C5.00939 5.18509 5.76185 4.4309 5.76185 3.50046C5.76204 2.57027 5.00947 1.81583 4.08124 1.81583C3.15328 1.81583 2.40064 2.57002 2.40064 3.50046C2.40083 4.43089 3.15326 5.18509 4.08124 5.18509ZM4.9226 3.23758C5.06744 3.23758 5.18479 3.35539 5.18479 3.5004C5.1846 3.64577 5.06707 3.76321 4.9226 3.76321C4.77776 3.76321 4.66041 3.64584 4.66041 3.5004C4.66041 3.35533 4.77776 3.23758 4.9226 3.23758ZM4.08124 2.39458C4.22627 2.39458 4.34343 2.5122 4.34343 2.6572C4.34343 2.80239 4.22609 2.92001 4.08124 2.92001C3.93659 2.92001 3.81906 2.80239 3.81906 2.6572C3.81906 2.51233 3.93659 2.39458 4.08124 2.39458ZM4.08124 4.08081C4.22627 4.08081 4.34343 4.19862 4.34343 4.34362C4.34343 4.48862 4.22609 4.60644 4.08124 4.60644C3.93659 4.60644 3.81906 4.48862 3.81906 4.34362C3.81906 4.19881 3.93659 4.08081 4.08124 4.08081ZM3.24064 3.23763C3.3851 3.23763 3.50263 3.35544 3.50263 3.50044C3.50263 3.64582 3.38492 3.76326 3.24064 3.76326C3.09561 3.76326 2.97826 3.64588 2.97826 3.50044C2.97807 3.35538 3.09542 3.23763 3.24064 3.23763Z"
      fill="currentColor"
    />
    <path
      d="M11.4532 6.54316H11.8628V8.18171H11.4532V6.54316Z"
      fill="currentColor"
    />
    <path
      d="M11.9799 6.19708V8.52747L14 9.30521V5.41947L11.9799 6.19708Z"
      fill="currentColor"
    />
    <path d="M2 9.47626H11.3357V5.24365H2V9.47626Z" fill="currentColor" />
    <path
      d="M8.49705 9.59418H7.95716L7.03874 10.5148L6.93761 10.4134L7.75483 9.59425H7.21494L6.66763 10.1426L6.12032 9.59425H5.58062L6.39785 10.4134L6.29646 10.5148L5.37804 9.59418H4.83816L6.04208 10.801L3.01463 16H4.05613L6.66778 11.4834L9.27943 16H10.3635L7.2929 10.801L8.49705 9.59418ZM6.66768 11.0864C6.53762 11.0864 6.43188 10.9804 6.43188 10.8497C6.43188 10.7191 6.53744 10.6129 6.66768 10.6129C6.79799 10.6129 6.90373 10.7189 6.90373 10.8497C6.90373 10.9802 6.79818 11.0864 6.66768 11.0864Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg06);
export default Memo;
