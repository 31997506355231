import React from "react";

// import { permissionLoader } from "../../../routes/loaders";
import BreadcrumbLink from "../../../../components/ui-components/Breadcrumb/BreadcrumbLink";
import config from "../config";
import { ScheduleChildRoutes } from "./schedule.children.routes";
// child routes
// import { scheduleItemChildRoutes } from "./activity-block.children.routes";
// import { CommonChildRoutes } from "./common.children.routes";

// import General from "../../screens/Project/General";
// import Common from "../../screens/Project/Common";
// import ScheduleItem from "../../screens/Project/ScheduleItem";
const Permission = React.lazy(() =>
  import("../../screens/Project/pages/Permission")
);
const Schedule = React.lazy(() =>
  import("../../screens/Project/pages/Schedule")
);

// const Common = React.lazy(() => import("../../screens/Project/pages/Common"));
const Project = React.lazy(() => import("../../screens/Project/pages/Project"));

const Templates = React.lazy(() =>
  import("../../screens/Project/pages/Templates")
);

const children = config.children.project.children;

export const ProjectRoutes = [
  /**
   * Permission page route
   */
  {
    id: children.permission.id,
    path: children.permission.path,
    element: (
      <React.Suspense fallback={<>Initialize...</>}>
        <Permission />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <BreadcrumbLink label={children.permission.label} />,
      config: () => children.common,
    },
    loader: async () => {
      return true;
      //   return await permissionLoader(children.common.id);
    },
  },

  /**
   * Project page route
   */
  {
    id: children.project.id,
    path: children.project.path,
    element: (
      <React.Suspense fallback={<>Initialize...</>}>
        <Project />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <BreadcrumbLink label={children.project.label} />,
      config: () => children.common,
    },
    loader: async () => {
      return true;
      //   return await permissionLoader(children.project.id);
    },
  },

  /**
   * Template page route
   */
  {
    id: children.template.id,
    path: children.template.path,
    element: (
      <React.Suspense fallback={<>Initialize...</>}>
        <Templates />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <BreadcrumbLink label={children.template.label} />,
      config: () => children.common,
    },
    loader: async () => {
      return true;
      //   return await permissionLoader(children.template.id);
    },
  },

  /**
   * Schedule page route
   */
  {
    id: children.schedule.id,
    path: children.schedule.path,
    element: (
      <React.Suspense fallback={<>Initialize...</>}>
        <Schedule />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <BreadcrumbLink label={children.schedule.label} />,
      // config: () => children.schedule,
    },
    loader: async () => {
      return true;
      //   return await permissionLoader(children.template.id);
    },
    children: ScheduleChildRoutes,
  },

  /**
   * Common page route
   */
  // {
  //   id: children.common.id,
  //   path: children.common.path,
  //   element: (
  //     <React.Suspense fallback={<>Initialize...</>}>
  //       <Common />
  //     </React.Suspense>
  //   ),
  //   handle: {
  //     crumb: () => <BreadcrumbLink label={children.common.label} />,
  //     config: () => children.common,
  //   },
  //   loader: async () => {
  //     return true;
  //     //   return await permissionLoader(children.common.id);
  //   },
  //   children: CommonChildRoutes,
  // },

  // {
  //   id: children.general.id,
  //   path: children.general.path,
  //   element: <General />,
  //   handle: {
  //     crumb: () => <BreadcrumbLink label={children.general.label} />,
  //   },
  //   loader: async () => {
  //     return true;
  //     //   return await permissionLoader(children.general.id);
  //   },
  // },

  // {
  //   id: children.scheduleItem.id,
  //   path: children.scheduleItem.path,
  //   element: <ScheduleItem />,
  //   handle: {
  //     crumb: () => <BreadcrumbLink label={children.scheduleItem.label} />,
  //   },
  //   loader: async () => {
  //     return true;
  //     //   return await permissionLoader(children.common.id);
  //   },
  //   children: scheduleItemChildRoutes,
  // },
];
