import React from "react";
import PageErrorMessage from "../../components/ui-components/PageErrorMessage";
import "./styles.scss";

const PageNotFound = () => {
  return (
    <PageErrorMessage title={"Page Not Found!"}>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
    </PageErrorMessage>
  );
};

export default PageNotFound;
