import { css } from "../../../../utility";
import "./tab-base-styles.scss";


const Header = (props) => {
 Header.displayName = "TAB_HEADER";

  const { children, className } = props;

  return (
    <div className={css("tab__base__header", className)}>
      <ul>{children}</ul>
    </div>
  );
};

export default Header;
