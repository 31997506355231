import React from "react";
import produce from "immer";
import "react-toastify/dist/ReactToastify.css";
import { MessageView, ChatsView } from "./Screens";
import {
  useChatBaseStore,
  setGenaralChatGroups,
  setMemberChatGroups,
  setCurrentChatData,
  setUpdateChatData,
  handlerMinimize,
} from "./Store/ChatBaseStore";
import API_Common from "../../services/api-common";
import API_ENDPOINTS from "../../services/api-endpoints";
import { useGlobalStore } from "../../store/GlobalStore";
import Connector from "../../services/signalRConnection";
import { ChatToggleButton } from "./Styled";
import IconChat from "../../common/components/icons/Line/chat";

const ChatBoxManager = () => {
  const isShowMsgView = useChatBaseStore((state) => state.showMessageView);
  const isShowChatView = useChatBaseStore((state) => state.showChatView);
  const isMinimized = useChatBaseStore((state) => state.isMinimized);

  const { events } = Connector();
  const chatData = useChatBaseStore((state) => state.chatData);
  const genaralGroups = useChatBaseStore((state) => state.genaralGroups);
  const memberGroups = useChatBaseStore((state) => state.memberGroups);

  const projectId = useGlobalStore((state) => state.selectedProjectId);
  const lastMsgId = useChatBaseStore((state) => state.lastMessageId);
  let USER = JSON.parse(localStorage.getItem("user"));
  let Dategroups = [];
  let groupArray = [];

  const filterAndCategorizedChatWithUpdatedData = React.useCallback(
    (dataObj) => {
      let userId = USER.result.userId;
      console.log("chatData", chatData);

      if (dataObj?.chatMessage) {
        var sortedData = [...dataObj?.chatMessage].sort((a, b) =>
          a.timeStamp < b.timeStamp ? -1 : 1
        );

        Dategroups = sortedData.reduce((Dategroups, obj) => {
          const date = obj?.timeStamp?.split("T")[0];
          if (!Dategroups[date]) {
            Dategroups[date] = [];
          }
          Dategroups[date].push(obj);

          return Dategroups;
        }, {});

        groupArray = Object.keys(Dategroups).map((date) => {
          return {
            date,
            messages: Dategroups[date],
          };
        });

        console.log("groupArrays: ", groupArray);

        setCurrentChatData(groupArray);
      }
    },
    [chatData]
  );

  const setMsg = React.useCallback((m, g) => {
    // toast.info("New Message Received from " + g, {
    //   position: toast.POSITION.BOTTOM_LEFT,
    // });
    console.log("g ::: ", g);
    console.log("lastMsg ::: ", lastMsgId);
    let msg = JSON.parse(m);
    console.log("m ::: ", msg);

    var groupChat =
      genaralGroups?.find((g) => g.groupId === msg.groupId) || null;
    var individualChat =
      memberGroups?.find((g) => g.groupId === msg.groupId) || null;
    /////////////////////////////////////////////////////////////////////
    if (chatData?.groupId === msg?.groupId) {
      console.log("__Chat data__ ", chatData?.groupId);
      const chatData_object = structuredClone(chatData);
      if (chatData_object?.chatMessage) {
        msg.messageId = msg.messageId + Math.random();
        chatData_object?.chatMessage.push(msg);
        console.log("chatData_object:: ", chatData_object);
        filterAndCategorizedChatWithUpdatedData(chatData_object);
        setUpdateChatData(chatData_object);
      }
    }

    if (groupChat !== null) {
      const groupChatData_objects = structuredClone(genaralGroups);
      msg.messageId = msg.messageId + Math.random();
      groupChatData_objects
        ?.find((g) => g.groupId === msg.groupId)
        ?.chatMessage.push(msg);

      setGenaralChatGroups(groupChatData_objects);
    }

    if (individualChat !== null) {
      const memberChatData_objects = structuredClone(memberGroups);
      msg.messageId = msg.messageId + Math.random();
      memberChatData_objects
        ?.find((g) => g.groupId === msg.groupId)
        ?.chatMessage.push(msg);

      setMemberChatGroups(memberChatData_objects);
    }
  });

  React.useEffect(() => {
    events((_, message) => setMsg(message, _));
  });
  const getChatGroups = React.useCallback(() => {
    let userId = USER.result.userId;
    const endpoints = new API_ENDPOINTS();
    API_Common.get(
      endpoints.GET_ALL_CHAT_GROUPS_BY_PROJECT +
        "?projectId=" +
        projectId +
        "&userId=" +
        userId
    ).then((response) => {
      if (response.data.isSuccess === true) {
        console.log("MF :: get Chat groupe :chat-groups:  ", response.data);

        if (chatData != null || chatData != undefined) {
          const updatedChatdata = response.data.result.filter((element) => {
            return element.groupId == chatData?.groupId;
          });
          console.log("updated chat obj", updatedChatdata[0]);
          if (updatedChatdata !== null || updatedChatdata !== undefined) {
            setUpdateChatData(updatedChatdata[0]);
            filterAndCategorizedChat(updatedChatdata[0]);
          }
        }

        const memberChatdata = response.data.result.filter((element) => {
          return element.groupType === 1;
        });
        const groupChatdata = response.data.result.filter((element) => {
          return element.groupType === 2;
        });
        console.log("chat-groups:  ", groupChatdata);
        console.log("member chat-groups:  ", memberChatdata);
        setGenaralChatGroups(groupChatdata);
        setMemberChatGroups(memberChatdata);
      }
    });
  }, [projectId]);

  const filterAndCategorizedChat = React.useCallback(
    (dataObj) => {
      let userId = USER.result.userId;
      console.log("MF:: chatData dataObj", dataObj);

      if (dataObj?.chatMessage) {
        var sortedData = [...dataObj?.chatMessage].sort((a, b) =>
          a.timeStamp < b.timeStamp ? -1 : 1
        );

        Dategroups = sortedData.reduce((Dategroups, obj) => {
          const date = obj?.timeStamp?.split("T")[0];
          if (!Dategroups[date]) {
            Dategroups[date] = [];
          }
          Dategroups[date].push(obj);

          return Dategroups;
        }, {});

        groupArray = Object.keys(Dategroups).map((date) => {
          return {
            date,
            messages: Dategroups[date],
          };
        });

        console.log("groupArrays: ", groupArray);

        setCurrentChatData(groupArray);

        console.log("sort: ", sortedData);
        console.log("Dategroups: ", Dategroups);
      }
    },
    [chatData]
  );

  const [screenStatus, setScreenStatus] = React.useState({
    isChatViewHide: false,
    isMessageViewHide: true,
    isOneOfAnimationDoneFire: false,
  });

  // React.useEffect(() => {
  //   if (isShowMsgView && !screenStatus?.isOneOfAnimationDoneFire) {
  //     setScreenStatus(
  //       produce((draft) => {
  //         draft.isChatViewHide = true;
  //         draft.isMessageViewHide = false;
  //         draft.isOneOfAnimationDoneFire = true;
  //       })
  //     );
  //   }
  // }, [isShowMsgView, screenStatus?.isOneOfAnimationDoneFire]);

  React.useEffect(() => {
    const chatContainer = document.getElementById("chat_box_container");

    if (isMinimized) {
      chatContainer.classList.add("is--minimized");
      return;
    }

    chatContainer.classList.remove("is--minimized");
  }, [isMinimized]);

  return (
    <>
      {/* Chat View */}
      <ChatsView
        isCollapsed={false}
        visibility={isShowChatView} // !isShowMsgView && screenStatus.isMessageViewHide
        // onAnimationDone={() => {
        //   setScreenStatus(
        //     produce((draft) => {
        //       draft.isChatViewHide = true;
        //       draft.isMessageViewHide = false;
        //       draft.isOneOfAnimationDoneFire = true;
        //     })
        //   );
        // }}
      />

      {/* Message View */}
      <MessageView
        visibility={isShowMsgView}
        // onAnimationDone={() => {
        //   setScreenStatus(
        //     produce((draft) => {
        //       draft.isChatViewHide = false;
        //       draft.isMessageViewHide = true;
        //       draft.isOneOfAnimationDoneFire = true;
        //     })
        //   );
        // }}
      />

      <ChatToggleButton
        $visibility={!isMinimized}
        data-tooltip-text={!isMinimized ? "Hide Chat" : "Open Chat"}
        icon={<IconChat />}
        btnIconSize={24}
        btnSize={52}
        onClick={handlerMinimize}
      />
    </>
  );
};

export default ChatBoxManager;
