import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import * as Styled from "./styled";
import BaseDialog from "../../../Components/BaseDialog";
import FieldGroup from "../../../../../common/components/Form/FieldGroup";
import InputField from "../../../../../common/components/Form/InputField";
import UIButton from "../../../../../common/components/Buttons/UIButton";
import IconAdd from "../../../../../common/components/icons/Line/add-1";
import {
  GroupDialog,
  useChatBaseStore,
  setGenaralChatGroups,
  setMemberChatGroups,
} from "../../../Store/ChatBaseStore";
import API_Common from "../../../../../services/api-common";
import API_ENDPOINTS from "../../../../../services/api-endpoints";
import { useGlobalStore } from "../../../../../store/GlobalStore";
import { useAppStore } from "../../../../../store/AppStore/AppStore";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAllChats, getExitingIndivudualChats } from "../../../API";

const initialValues = { name: "" };

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Group Name is required"),
});

const AddNewGroupDialog = () => {
  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);

  const projectId = useGlobalStore((state) => state.selectedProjectId);
  let USER = JSON.parse(localStorage.getItem("user"));
  const visibility = useChatBaseStore((s) => s.isOpenCreateGroupDialog);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onAddGroup,
  });

  const { errors, values, handleChange, handleSubmit, touched, resetForm } =
    formik;

  const onClose = React.useCallback(() => {
    GroupDialog().close();
    resetForm();
  }, [resetForm]);

  function onAddGroup(values, { resetForm }) {
    console.log(values);

    try {
      const endpoints = new API_ENDPOINTS();
      showLoader(); //Loading Indicator on

      API_Common.post(endpoints.ADD_NEW_GROUP, {
        groupId: 0,
        groupName: values.name,
        groupType: 2,
        visibility: true,
        projectId: projectId,
        lastActivityOn: new Date(),
        isArchived: false,
      }).then((response) => {
        console.log("genaral group create response::", response);
        if (response.data.isSuccess === true) {
          addCurrentUserAsAdminOfGroup(response?.data?.result);
          // toast.success(response.data.displayMessage);

          resetForm();
          onClose();
        } else {
          toast.error(response.data.displayMessage);
        }

        hideLoader(); //Loading Indicator off
      });
    } catch (err) {
      console.log(err);
      toast.error(err);
      hideLoader(); //Loading Indicator off
    }
  }
  const addCurrentUserAsAdminOfGroup = React.useCallback((groupId) => {
    try {
      const endpoints = new API_ENDPOINTS();
      showLoader(); //Loading Indicator on
      let userId = USER.result.userId;
      API_Common.post(endpoints.ADD_NEW_GROUP_MEMBERS_FOR_CHAT, {
        chatGroupMemberId: 0,
        groupId: groupId,
        userId: userId,
        memberType: 1, //Owner
        notReadCount: 0,
        isMemberActive: true,
        joinedDate: new Date(),
        joinedType: 3, //By Self
      }).then((response) => {
        console.log("member added to group as Owner response::", response);
        if (response.data.isSuccess === true) {
          //getChatGroups();
          getAllChats(projectId, userId);
        } else {
          toast.error(response.data.displayMessage);
        }
      });
    } catch (err) {
      console.log(err);
      toast.error(err);
    }
  }, []);
  const getChatGroups = React.useCallback(() => {
    let userId = USER.result.userId;
    const endpoints = new API_ENDPOINTS();
    API_Common.get(
      endpoints.GET_ALL_CHAT_GROUPS_BY_PROJECT +
        "?projectId=" +
        projectId +
        "&userId=" +
        userId
    ).then((response) => {
      if (response.data.isSuccess === true) {
        //console.log("chat-groups:  ", response.data);
        const memberChatdata = response.data.result.filter((element) => {
          return element.groupType == 1;
        });
        const groupChatdata = response.data.result.filter((element) => {
          return element.groupType == 2;
        });
        console.log("chat-groups:  ", groupChatdata);
        console.log("member chat-groups:  ", memberChatdata);
        setGenaralChatGroups(groupChatdata);
        setMemberChatGroups(memberChatdata);
      }
    });
  }, [projectId]);

  return (
    <BaseDialog
      title="Add New Group"
      visibility={visibility}
      onRootClick={onClose}
      appendToSamePlace
    >
      <Styled.FormWrapper>
        <Styled.Form onSubmit={handleSubmit}>
          <div className="form__controls">
            <FieldGroup error={touched.name && errors.name}>
              <InputField
                name="name"
                placeholder="Group Name"
                value={values.name}
                onChange={handleChange}
                autoComplete="off"
              />
            </FieldGroup>
          </div>

          <div className="form__actions">
            <UIButton
              type="submit"
              fullWidth
              size={UIButton.Sizes.Small}
              variant={UIButton.Variant.Primary}
              startIcon={<IconAdd />}
            >
              Add Group
            </UIButton>
          </div>
        </Styled.Form>
      </Styled.FormWrapper>
    </BaseDialog>
  );
};

export default AddNewGroupDialog;
