import styled from "styled-components";
import { fontStyle } from "../../../../../../../../../styled/utility";

export const LegendsContainer = styled.div`
  --shadow: ${({ $showHeaderShadow }) =>
    $showHeaderShadow ? `0 0 14px 1px rgb(0 0 0 / 15%)` : `none`};

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  position: relative;
  overflow-y: auto;
  min-height: 0;

  .modal__content__head {
    position: sticky;
    top: 0;
    left: 0;
    padding: var(--layout-spacing-16) var(--layout-spacing-20)
      var(--layout-spacing-24);
    background-color: var(--layout-background-white);
    z-index: 1;
    box-shadow: var(--shadow);

    .title {
      ${fontStyle("base-bold")};
    }
  }

  .modal__content__body {
    padding: 0 var(--layout-spacing-20) var(--layout-spacing-16);
    flex-grow: 1;
    gap: var(--layout-size-24);
    display: flex;
    flex-direction: column;
  }
`;
