import styled from "styled-components";
import { StyledBaseButton } from "../../../../../../styled/Common/Buttons";
import { fontStyle } from "../../../../../../styled/utility";

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;

  .button__wrapper {
    padding: var(--layout-spacing-20) var(--layout-spacing-24);
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
  }

  .contents {
    display: flex;
    flex: 1;
    min-height: 0px;
  }
`;

export const AddMemberButton = styled(StyledBaseButton)`
  ${fontStyle("small-medium")};
  display: flex;
  padding: var(--layout-spacing-12, 12px);
  justify-content: center;
  align-items: center;
  gap: var(--layout-spacing-8, 8px);
  align-self: stretch;
  border-radius: var(--layout-border-radius-8, 8px);
  background: var(--color-white, #fff);
  width: 100%;
  transition: var(--animate-1);
  gap: var(--layout-spacing-8);

  .icon {
    --icon-size: var(--layout-size-16);
    --icon-font-size: var(--layout-size-12);
    --icon-font-color: var(--font-tint-04);
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const EmptyBlockWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  .icon {
    --icon-size: var(--layout-size-60);
    --icon-font-size: var(--layout-size-60);
    --icon-font-color: var(--color-neutral-40);
  }
`;
