import styled from "styled-components";
import { fontStyle } from "../../../../../../../styled/utility";

export const Container = styled.div`
  background-color: var(--layout-background-n10);
  border-radius: var(--layout-border-radius-12);

  .steps__content {
    display: flex;
    padding: var(--layout-spacing-12) var(--layout-spacing-20);

    > div {
      ${fontStyle("large-semi-bold")}
      font-weight: var(--font-weight-bold);
    }

    .step__header {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: var(--layout-spacing-32);
    }

    .step__num__holder {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--layout-spacing-32);
    }
  }
`;

export const StepNum = styled.div`
  border-radius: 50%;
  height: var(--layout-size-40);
  width: var(--layout-size-40);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--font-default-white);
  color: var(--layout-dashed-primary-01-color);

  ${({ $isActive, $isCompleted }) => {
    if ($isActive) {
      return `
            background-color: var(--font-color-shade-primary);
            padding:var(--layout-size-6) var(--layout-spacing-16) (--layout-size-6) var(--layout-spacing-16);
            color: var(--font-default-white);
            box-shadow: 0 0 0 4px var(--font-default-white), 0 0 0 5px var(--font-color-shade-primary);
        `;
    }

    if ($isCompleted) {
      return `
            background-color: var(--color-accent-400);
            color: var(--font-default-white);
        `;
    }
  }}
`;
