import styled from "styled-components";
import { StyledBaseButton } from "../../../styled/Common/Buttons";

export const StyledMessageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: ${({ theme }) => `${theme.spacing(12)}`};
`;

export const StyledMessageButton = styled(StyledBaseButton)`
  --avt-msg-icon-size: ${({ theme }) => theme.sizes(4)};

  width: auto;
  border-radius: 0 ${({ theme }) => theme.rounded("xl")}
    ${({ theme }) => theme.rounded("xl")} 0;
  padding: 0 ${({ theme }) => theme.sizes(1)};
  border: 2px solid ${({ theme }) => theme.colors.white()};
  background-color: ${({ color, theme }) => color || theme.colors.primary()};
  position: absolute;
  right: ${({ theme }) => theme.sizes(3)};
  min-width: ${({ theme }) => theme.sizes(12)};
  padding-left: ${({ theme }) => theme.sizes(3)};
  color: ${({ theme }) => theme.colors.white()};
  transition: ${({ theme }) => theme.animate.primary};
  font-size: var(--avt-msg-icon-size);

  &:hover {
    transform: translateX(${({ theme }) => theme.spacing(1)});
  }
`;
