import React from "react";
import { motion } from "framer-motion";
import ModalBackdrop from "../ModalBackdrop";
import "./styles.scss";

const variants = {
  visible: {
    opacity: 1,
    // scale: 1,
  },
  hidden: {
    opacity: 0,
    // scale: 0,
  },
};

const ModalBase = (props) => {
  const {
    visibility,
    children,
    title,
    subtitle,
    onClose,
    onBackdropClick,
    size,
    className,
  } = props;

  const modalSizeClass = React.useMemo(() => {
    if (size) {
      return `base__modal--${size}`;
    }

    return "";
  }, [size]);

  return (
    <ModalBackdrop visibility={visibility} onClick={onBackdropClick}>
      <motion.div
        variants={variants}
        className={["base__modal", className, modalSizeClass].join(" ")}
      >
        <div className="base__modal__content">
          <div className="base__modal__header">
            <div className="start">
              <div className="title">{title}</div>
              {subtitle ? <div className="subtitle">{subtitle}</div> : null}
            </div>
            <div className="end">
              <button
                className="modal__close"
                type="button"
                title="Close"
                onClick={onClose}
              >
                <span className="icon icon-lp-close"></span>
              </button>
            </div>
          </div>
          <div className="base__modal__body">{children}</div>
        </div>
      </motion.div>
    </ModalBackdrop>
  );
};

ModalBase.Contents = (props) => {
  const { children, className } = props;

  return (
    <div className={["base__modal__contents", className].join(" ")}>
      {children}
    </div>
  );
};

ModalBase.Footer = (props) => {
  const { children, className } = props;

  return (
    <div className={["base__modal__footer", className].join(" ")}>
      {children}
    </div>
  );
};

export default ModalBase;
