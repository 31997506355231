import React from "react";
import { Close } from "../../icons/Popup/Popovers";
import * as Styled from "./styled";

const Chips = ({ value }) => {
  return (
      <Styled.ChipContainer>
        {value}
        <button className="chips__btn">
        <Close className="chips__close" />
        </button>
      </Styled.ChipContainer>
  );
};

export default Chips;
