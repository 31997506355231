import styled from "styled-components";
import { StyledBoxButton } from "../../../../styled/Common/Buttons";
import { fontStyle } from "../../../../styled/utility";
import { IconBlock } from "../../../../styled/Common/Icons";

export const DropdownWrapper = styled.div`
  display: block;

  .mask {
    background-color: transparent;
  }
`;

export const DropdownButtonElement = styled(StyledBoxButton)`
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: var(--animate-1);
  color: var(--font-tint-04);

  &:hover {
    color: var(--font-tint-01);
  }
`;

export const DropdownIconElement = styled(IconBlock)`
  color: inherit;

  ${({ iconSize }) => {
    return `
      --icon-size: var(--layout-size-${iconSize});
      --icon-font-size: var(--layout-size-${iconSize});
    `;
  }};
`;

export const DropdownMenu = styled.div`
  display: block;
  background-color: var(--layout-background-white);
  /* min-width: 160px; */
  min-width: 140px;
  max-width: 210px;
  /* margin: var(--layout-spacing-4); */
  border-radius: var(--layout-border-radius-8, 8px);
  box-shadow: -3px 8px 15px 0px rgba(45, 89, 102, 0.08);
  padding: var(--layout-spacing-16) var(--layout-spacing-8);
  border: 1px solid rgba(45, 89, 102, 0.08);

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: var(--layout-spacing-8);

    li {
      ${fontStyle("small-regular")};
      cursor: pointer;
      padding: var(--layout-spacing-4) var(--layout-spacing-12);
      min-width: 0px;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      border-radius: var(--layout-border-radius-8);
      transition: var(--animate-1);
      color: var(--font-default-black);

      &:hover {
        /* opacity: 0.7; */
        background-color: var(--layout-background-n20);
      }

      &.no-options {
        text-align: center;
        color: var(--font-tint-02);
        cursor: default;
      }
    }
  }
`;

export const PopoverDropdownItem = styled.li`
  display: flex;
  align-items: center;
  gap: var(--layout-spacing-8);

  ${({ $disabled }) => {
    if ($disabled) {
      return `
        opacity: 0.5;
        cursor: default !important;
      `;
    }
  }};

  .item__icon {
    --icon-button-size: var(--layout-size-16);
    --icon-button-icon-size: var(--layout-size-16);
    --icon-button-icon-color: var(--font-tint-04);
  }

  .item__text {
    white-space: inherit;
    text-overflow: inherit;
  }
`;
