import BreadcrumbLink from "../../../components/ui-components/Breadcrumb/BreadcrumbLink";

import MemberSearch from "../../../components/pages/onBoarding/member-search";
import ShortListSearch from "../../../components/pages/onBoarding/shortList-search";
import OnboardingList from "../../../components/pages/onBoarding/onBoarding-list";
import HRList from "../../../components/pages/onBoarding/hr-list";
import { ROUTE_OPTIONS } from "../../../constant/route-options";
import { permissionLoader } from "../../loaders";
import PermissionWrapper from "../../../components/pages/project/permission-wrapper";
import SignContract from "../../../components/pages/onBoarding/sign-contract";

const child = ROUTE_OPTIONS.projects.children["onboarding"].children;

const parentKey = ROUTE_OPTIONS.projects.children["onboarding"].id;

const permissionCache = {};
export const onboardingRoutes = [
  // All Members
  {
    id: child["all-members"].id,
    path: child["all-members"].path,
    element: (
      <PermissionWrapper
        cacheKey={`${child["all-members"].id}-all-members`}
        permissionCache={permissionCache}
      >
        <MemberSearch />
      </PermissionWrapper>
    ),
    handle: {
      crumb: () => <BreadcrumbLink label={child["all-members"].label} />,
    },
    loader: async () => {
      const routeId = child["all-members"].id;
      console.log("all-members id - ", routeId);
      const cacheKey = `${routeId}-all-members`;

      if (permissionCache[cacheKey]) {
        console.log(
          "all-members- Returning cached permission data: ",
          permissionCache[cacheKey]
        );
        return permissionCache[cacheKey];
      }

      console.log("Calling Permission api for: ", routeId);

      const permission = await permissionLoader(parentKey, routeId);
      permissionCache[cacheKey] = permission;
      return permission;
      //return await permissionLoader(parentKey, routeId);
    },
  },

  // Shortlisted List
  {
    id: child["shortlisted-list"].id,
    path: child["shortlisted-list"].path,
    element: (
      <PermissionWrapper
        cacheKey={`${child["shortlisted-list"].id}-shortlist`}
        permissionCache={permissionCache}
      >
        <ShortListSearch />
      </PermissionWrapper>
    ),
    handle: {
      crumb: () => <BreadcrumbLink label={child["shortlisted-list"].label} />,
    },
    loader: async () => {
      const routeId = child["shortlisted-list"].id;
      console.log("shortList id - ", routeId);
      const cacheKey = `${routeId}-shortlist`;

      if (permissionCache[cacheKey]) {
        console.log(
          "shortlist- Returning cached permission data: ",
          permissionCache[cacheKey]
        );
        return permissionCache[cacheKey];
      }

      console.log("Calling Permission api for: ", routeId);

      const permission = await permissionLoader(parentKey, routeId);
      permissionCache[cacheKey] = permission;
      return permission;
      //return await permissionLoader(parentKey, routeId);
    },
  },

  // Onboarding List
  {
    id: child["onboarding-list"].id,
    path: child["onboarding-list"].path,
    element: (
      <PermissionWrapper
        cacheKey={`${child["onboarding-list"].id}-onboarding-list`}
        permissionCache={permissionCache}
      >
        <OnboardingList />
      </PermissionWrapper>
    ),
    handle: {
      crumb: () => <BreadcrumbLink label={child["onboarding-list"].label} />,
    },
    loader: async () => {
      const routeId = child["onboarding-list"].id;
      console.log("onboarding-list id - ", routeId);
      const cacheKey = `${routeId}-onboarding-list`;

      if (permissionCache[cacheKey]) {
        console.log(
          "onboarding-list- Returning cached permission data: ",
          permissionCache[cacheKey]
        );
        return permissionCache[cacheKey];
      }

      console.log("Calling Permission api for: ", routeId);

      const permission = await permissionLoader(parentKey, routeId);
      permissionCache[cacheKey] = permission;
      return permission;

      //return await permissionLoader(parentKey, child["onboarding-list"].id);
    },
  },

  // HR List
  {
    id: child["hr-list"].id,
    path: child["hr-list"].path,
    element: (
      <PermissionWrapper
        cacheKey={`${child["hr-list"].id}-hr-list`}
        permissionCache={permissionCache}
      >
        <HRList />
      </PermissionWrapper>
    ),
    handle: {
      crumb: () => <BreadcrumbLink label={child["hr-list"].label} />,
    },
    loader: async () => {
      const routeId = child["hr-list"].id;
      console.log("hr-list id - ", routeId);
      const cacheKey = `${routeId}-hr-list`;

      if (permissionCache[cacheKey]) {
        console.log(
          "hr-list- Returning cached permission data: ",
          permissionCache[cacheKey]
        );
        return permissionCache[cacheKey];
      }

      console.log("Calling Permission api for: ", routeId);

      const permission = await permissionLoader(parentKey, routeId);
      permissionCache[cacheKey] = permission;
      return permission;
      //return await permissionLoader(parentKey, child["hr-list"].id);
    },
  },

  // Favorites
  {
    id: child.favorites.id,
    path: child.favorites.path,
    element: <h1>Favorites</h1>,
    handle: {
      crumb: () => <BreadcrumbLink label={child.favorites.label} />,
    },
    loader: async () => {
      return await permissionLoader(parentKey, child.favorites.id);
    },
  },

  // Sign Contract
  {
    id: child["sign-contract"].id,
    path: child["sign-contract"].path,
    element: <SignContract />,
    handle: {
      crumb: () => <BreadcrumbLink label={child["sign-contract"].label} />,
    },
    loader: async () => {
      // return await permissionLoader(parentKey, child.id);
      return true;
    },
  },
];
