import React from "react";
import { IconBlock } from "../../../../styled/Common/Icons";
import * as Styled from "./styled";
import { StyledBoxButton } from "../../../../styled/Common/Buttons";

const IconButton = ({
  className,
  as = StyledBoxButton,
  icon,
  btnSize,
  btnIconSize,
  btnIconColor,
  disabled,
  hover,
  ...rest
}) => {
  return (
    <Styled.Button
      as={as}
      className={[
        "icon__button__element",
        className,
        disabled ? "is--disabled" : "",
      ].join(" ")}
      btnSize={btnSize}
      btnIconSize={btnIconSize}
      btnIconColor={btnIconColor}
      disabled={disabled}
      hover={hover}
      {...rest}
    >
      <IconBlock className="button__icon">{icon}</IconBlock>
    </Styled.Button>
  );
};

export default IconButton;
