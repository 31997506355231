import React from "react";
import * as Styled from "./styled";
import { useScheduleContext } from "../../../../context/ScheduleContext";

const TXDateColumn = () => {
  const { dates } = useScheduleContext();

  const txNumbers = React.useMemo(() => {
    return [...new Set(dates.map((d) => d?.txWeekNumber))]
      .sort((a, b) => String(a).localeCompare(String(b)))
      .reverse();
  }, [dates]);

  return (
    <Styled.TXColumn className="tx__date__column" label="TX">
      <Styled.TXItems>
        {txNumbers.map((d, i) => {
          return <TXItem key={i} txNumber={d} />;
        })}
      </Styled.TXItems>
    </Styled.TXColumn>
  );
};

function TXItem({ txNumber }) {
  return <Styled.TXItem $isCurrent>{txNumber}</Styled.TXItem>;
}

export default TXDateColumn;
