import * as React from "react";
import { memo } from "react";
const Svg04 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.164013 0.7449L0.744114 0.1648C0.962798 -0.0538846 1.35878 -0.0124964 1.53607 0.1648L15.836 14.4647C16.0547 14.6834 16.0547 15.038 15.836 15.2567L15.2559 15.8368C15.0372 16.0555 14.6412 16.0141 14.4639 15.8368L0.164013 1.53686C-0.054671 1.31818 -0.054671 0.963585 0.164013 0.7449Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.744061 15.836L0.16396 15.2559C-0.0547244 15.0372 -0.0133362 14.6412 0.16396 14.4639L14.4639 0.164013C14.6826 -0.054671 15.0371 -0.054671 15.2558 0.164013L15.8359 0.744114C16.0546 0.962798 16.0132 1.35878 15.8359 1.53607L1.53602 15.836C1.31734 16.0547 0.962745 16.0547 0.744061 15.836Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg04);
export default Memo;
