import React from "react";
import SettingCard from "../../../../../components/common/SettingCard";
import SettingTab from "../../../../../components/common/SettingTab";
import OptionButton from "../../../../../../../common/components/Buttons/OptionButton";
import SearchInput from "../../../../../../../common/components/Form/SearchInput";
import { useSettingContext } from "../../../../../shared/context/SettingContext";
import useSettingPermission from "../../../../../hooks/useSettingPermission";
import config from "../../../../../routes/config";
import StagesTable from "./components/Tables/StagesTable";
import ProductionStageModal from "./components/Modals/ProductionStageModal";
import MilestoneModal from "./components/Modals/MilestoneModal";
import {
  ActivityBlockProvider,
  useActivityBlock,
} from "./context/ActivityBlockContext";
import MilestoneTable from "./components/Tables/MilestoneTable";
import BlockTable from "./components/Tables/BlockTable";
import BlockModal from "./components/Modals/BlockModal";

const ActivityBlock = () => {
  return (
    <ActivityBlockProvider>
      <SettingCard
        heading="Schedule Settings"
        subheading="Manage your Schedule Setting details here"
        headerEndContent={<HeaderEndComponent />}
      >
        <SettingTab>
          <SettingTab.Panel label="Stages">
            <StagesTable />
          </SettingTab.Panel>

          <SettingTab.Panel label="Blocks">
            {/* <UserRoleTable /> */}
            <BlockTable />
          </SettingTab.Panel>

          <SettingTab.Panel label="Milestones">
            <MilestoneTable />
          </SettingTab.Panel>
        </SettingTab>

        {/* Modals */}
        <ProductionStageModal />

        <MilestoneModal />

        <BlockModal />
      </SettingCard>
    </ActivityBlockProvider>
  );
};

export default ActivityBlock;

function HeaderEndComponent() {
  const { handleQuickSearch } = useSettingContext();
  const { StageModal, MilestoneModal, BlockModal } = useActivityBlock();

  const { scheduleAddBtnPermission } = useSettingPermission({
    pageKey: config.children.project.id,
  });

  return (
    <SettingCard.FlexContent>
      {scheduleAddBtnPermission ? (
        <OptionButton
          enabledBtnLeftGap
          showHint
          options={[
            {
              label: "Add Stage",
              onClick: () => {
                StageModal.open({ type: "NEW" });
              },
            },
            {
              label: "Add Block",
              onClick: () => {
                BlockModal.open({ type: "NEW" });
              },
            },
            {
              label: "Add Milestone",
              onClick: () => {
                MilestoneModal.open({ type: "NEW" });
              },
            },
          ]}
        />
      ) : null}

      <SearchInput
        canExpandable={false}
        defaultExpanded
        onChange={(e) => {
          handleQuickSearch(e?.target?.value);
        }}
      />
    </SettingCard.FlexContent>
  );
}
