import BreadcrumbLink from "../../components/ui-components/Breadcrumb/BreadcrumbLink";
import { ROUTE_OPTIONS } from "../../constant/route-options";
// import BeatSheet from "../../pages/research-board/screens/BeatSheet";
// import ResearchData from "../../pages/research-board/screens/ResearchData";
// import ResearchOrganizer from "../../pages/research-board/screens/ResearchOrganizer";

// import { permissionLoader } from "../loaders";
import React from "react";

const ResearchDocuments = React.lazy(() =>
  import("../../pages/research-board/screens/ResearchDocuments")
);

const child = ROUTE_OPTIONS.research_board.children;

export const researchBoardRoutes = [
  // {
  //   // index: true,
  //   id: child["research-organizer"].id,
  //   path: child["research-organizer"].path,
  //   element: <ResearchOrganizer />,
  //   handle: {
  //     crumb: () => <BreadcrumbLink label={child["research-organizer"].label} />,
  //   },
  //   loader: async () => {
  //     return await permissionLoader(child["research-organizer"].id);
  //   },
  // },

  // {
  //   id: child["research-data"].id,
  //   path: child["research-data"].path,
  //   element: <ResearchData />,
  //   handle: {
  //     crumb: () => <BreadcrumbLink label={child["research-data"].label} />,
  //   },
  //   loader: async () => {
  //     return await permissionLoader(child["research-data"].id);
  //   },
  // },

  // {
  //   id: child["beat-sheet"].id,
  //   path: child["beat-sheet"].path,
  //   element: <BeatSheet />,
  //   handle: {
  //     crumb: () => <BreadcrumbLink label={child["beat-sheet"].label} />,
  //   },
  //   loader: async () => {
  //     return await permissionLoader(child["beat-sheet"].id);
  //   },
  // },

  // Research Document
  {
    id: child["documents"].id,
    path: child["documents"].path,
    element: (
      <React.Suspense fallback={<></>}>
        <ResearchDocuments />
      </React.Suspense>
    ),
    handle: {
      //crumb: () => <BreadcrumbLink label={child["documents"].label} />,
    },
    loader: async () => {
      return true;
      // return await permissionLoader(child["documents"].id);
    },
  },
  // {
  //   index: true,
  //   redirect: child["research-data"].path,
  // },
];
