import React from "react";

import AuthCardLayout from "../../components/AuthLayout";
import CreateNewPass from "./CreateNewPass";

const CreateNewPasswordUI = () => {
  return (
    <AuthCardLayout
      title="Create New Password"
      subTitle="Create more secure and memarable password"
    >
      <CreateNewPass/>
    </AuthCardLayout>
  )
}

export default CreateNewPasswordUI