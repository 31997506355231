import * as React from "react";
import { memo } from "react";
const SvgArrowRight = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.24643 15.7588C3.57425 16.0804 4.1062 16.0804 4.434 15.7588L11.7539 8.58216C12.082 8.26077 12.082 7.73922 11.7539 7.41785L4.434 0.241247C4.26996 0.0804179 4.05497 -6.94577e-07 3.83989 -7.1338e-07C3.6249 -7.32174e-07 3.40981 0.0804131 3.24606 0.241247C2.91798 0.562644 2.91798 1.08419 3.24606 1.40556L9.97224 8.00003L3.24629 14.5945C2.91821 14.9159 2.91821 15.4374 3.24629 15.7588L3.24643 15.7588Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgArrowRight);
export default Memo;
