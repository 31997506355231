import React from "react";

import TanStackTable, {
  columnHelper,
  Elements,
} from "../../../../../../../../components/common/TanStackTable";
// import { useSettingContext } from "../../../../../../../../shared/context/SettingContext";
// import ViewSubStagePopover from "../../../../components/ui/ViewSubStagePopover";
import { FlexCenter } from "../../../../../../../../styled";
import { useActivityBlock } from "../../../context/ActivityBlockContext";
// import { FilterArea } from "../styled";
// import SelectField from "../../../../../../../../../../common/components/Form/SelectField";
import { useQuery, useQueryClient } from "react-query";
import { useAppStore } from "../../../../../../../../../../store/AppStore/AppStore";
import {
  deleteMileStone,
  getAllMileStoneDataSet,
} from "../../../../../../../../api/schedule";
import Alert from "../../../../../../../../../../utility/alert";

export const MileStoneListKey = "milestone_list";

function MilestoneTable() {
  // const { projectId, tenantId } = useSettingContext();
  const { MilestoneModal } = useActivityBlock();
  const hideLoader = useAppStore((state) => state.hideLoader);
  const showLoader = useAppStore((state) => state.showLoader);
  const queryClient = useQueryClient();

  const columns = [
    columnHelper.accessor("order", {
      header: "Order",
      size: 50,
      cell: ({ row, getValue }) => {
        return getValue();
      },
    }),
    columnHelper.accessor("name", {
      header: "Name",
      size: 200,
      cell: ({ row, getValue }) => {
        return getValue();
      },
    }),

    columnHelper.accessor("shortName", {
      header: () => <FlexCenter>Short Name</FlexCenter>,
      size: 100,
      enableSorting: false,
      cell: ({ row, getValue }) => {
        return <FlexCenter>{getValue()}</FlexCenter>;
      },
    }),

    columnHelper.accessor("id", {
      header: "Action",
      size: 30,
      enableSorting: false,
      cell: ({ row, getValue }) => {
        return (
          <Elements.Actions gap={8}>
            <Elements.ActionButton
              type="edit"
              title="Edit"
              onClick={() => {
                MilestoneModal.open({ ...row?.original, type: "EDIT" } || null);
              }}
            />
            <Elements.ActionButton
              type="delete"
              title="Delete"
              onClick={() => {
                deleteMilestone(row?.original);
              }}
            />
            {/* <Elements.ActionButton type="duplicate" /> */}
          </Elements.Actions>
        );
      },
    }),
  ];
  const { data: milestons } = useQuery({
    queryKey: [MileStoneListKey],
    queryFn: () => getAllMileStoneDataSet(),
  });
  const deleteMilestone = (milestone) => {
    Alert.confirm({
      allowOutsideClick: false,
      title: "Delete Milestone",
      text: "Are you sure you want to delete this Milestone?",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log("yes");
        showLoader();
        deleteMileStone(milestone?.id || 0).then((res) => {
          if (res?.isSuccess) {
            queryClient.refetchQueries([MileStoneListKey]);
            hideLoader();
          } else {
            Alert.confirm({
              allowOutsideClick: false,
              iconHtml: null,
              type: "error",
              title: "Failed to delete!",
              text: res?.displayMessage,
              confirmButtonText: "Got it!",
              showCancelButton: false,
            }).then(() => {});
            hideLoader();
          }
        });
      }
    });
  };

  return (
    <>
      {/* <FilterArea>
        <SelectField options={[{ label: "Pre-Production", value: 1 }]} />
      </FilterArea> */}

      <TanStackTable
        enabledRowsSpace
        isLoading={false}
        columns={columns}
        data={milestons}
      />
      {/* Dialogs */}
    </>
  );
}

export default MilestoneTable;
