import BreadcrumbLink from "../../../../components/ui-components/Breadcrumb/BreadcrumbLink";
import config from "../config";
import Tracker from "../../screens/tracker";

const children = config.children;
// const key = "schedule";

export const ChildrenRoutes = [
  {
    id: children.tracker.id,
    path: children.tracker.path,
    element: <Tracker />,
    handle: {
      crumb: () => <BreadcrumbLink label={children.tracker.label} />,
    },
    loader: async () => {
      return true;
      // return await permissionLoader(key, children.schedule.id);
    },
  },
];
