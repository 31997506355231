import * as signalR from "@microsoft/signalr";
const URL =
  process.env.REACT_APP_CHAT_HUB ??
  "https://chathub-dev.azurewebsites.net/chathub"; //or whatever your backend port is

class Connector {
  connection;
  events;
  static instance;

  constructor() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(URL)
      .withAutomaticReconnect()
      .build();
    // this.connection.start().catch((err) => document.write(err));
    this.events = (onMessageReceived) => {
      this.connection.on("ReceiveMessage", (username, message) => {
        // console.log(
        //   "msg received",
        //   "username :" + username + " msg: " + message
        // );
        onMessageReceived(username, message);
      });
    };
  }

  newMessage = (groupName, messages) => {
    this.connection
      .send("SendMessage", groupName, messages)
      .then((x) => console.log("sent"));
  };

  static getInstance() {
    if (!Connector.instance) Connector.instance = new Connector();
    return Connector.instance;
  }
}
export default Connector.getInstance;
