import styled from "styled-components";
import { fontStyle } from "../../../../../../../../styled/utility";

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 417px;
    
    .title{
        ${fontStyle("label-medium")};
        justify-content: center;
        font-weight: 500;

    }

    .daysContainer{
        display: flex;
        align-items: flex-start;
        gap: var(--layout-spacing-4, 4px);

        .day{
            display: flex;
            width: 44px;
            max-width: 44px;
            min-width: 44px;
            padding: var(--layout-spacing-4);
            border-radius: var(--layout-border-radius-4, 4px);
            justify-content: center;
            align-items: center;
            background-color: transparent;
            border: none;
            ${fontStyle("x-small-medium")};

            &:hover{
                transition: var(--animate-1);
                background: var(--color-primary-50);
            }
        }

        .active{
            background: var(--color-primary-200);
            color: var(--color-white);

            &:hover{
                background: var(--color-primary-200);
                color: var(--color-white);
            }
        }
    }
`
