import * as React from "react";
import { memo } from "react";
const SvgIconPaste = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.66667 0H6.33334C6.51746 0 6.66667 0.207135 6.66667 0.375V1.125C6.66667 1.33213 6.51746 1.5 6.33334 1.5H3.66667C3.48255 1.5 3.33334 1.29286 3.33334 1.125V0.375C3.33334 0.167865 3.48255 0 3.66667 0Z"
      fill="#56B5BC"
    />
    <path d="M10 8.25H11.8047L10 6.21975V8.25Z" fill="#56B5BC" />
    <path
      d="M10 5.25V1.125C10.0001 1.02551 9.96495 0.93012 9.90245 0.85974C9.8399 0.789427 9.7551 0.749935 9.66666 0.749994H7.33333V1.12499C7.33302 1.42324 7.2276 1.70923 7.04011 1.92011C6.85265 2.13104 6.59844 2.24965 6.33333 2.24999H3.66667C3.40156 2.24964 3.14735 2.13105 2.9599 1.92011C2.7724 1.70923 2.66698 1.42324 2.66667 1.12499V0.749994H0.333338C0.244901 0.749935 0.160112 0.789427 0.0975518 0.85974C0.0350519 0.930111 -5.19421e-05 1.0255 5.78664e-08 1.125V13.125C-5.20221e-05 13.2245 0.0350519 13.3199 0.0975518 13.3903C0.160104 13.4606 0.244898 13.5001 0.333338 13.5H4.66667V6.375C4.66698 6.07676 4.7724 5.79077 4.95989 5.57988C5.14735 5.36895 5.40156 5.25035 5.66667 5.25L10 5.25Z"
      fill="#56B5BC"
    />
    <path
      d="M9.66667 9C9.57823 9.00006 9.49344 8.96056 9.43088 8.89025C9.36838 8.81988 9.33328 8.72449 9.33333 8.62499V5.99999H5.66667C5.57823 5.99993 5.49344 6.03943 5.43088 6.10974C5.36838 6.18011 5.33328 6.2755 5.33333 6.375V14.625C5.33328 14.7245 5.36838 14.8199 5.43088 14.8903C5.49343 14.9606 5.57823 15.0001 5.66667 15H11.6667C11.7551 15.0001 11.8399 14.9606 11.9024 14.8903C11.9649 14.8199 12.0001 14.7245 12 14.625V9H9.66667Z"
      fill="#56B5BC"
    />
  </svg>
);
const Memo = memo(SvgIconPaste);
export default Memo;
