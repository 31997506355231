import React from "react";
import "./styles.scss";

const FromGroup = (props) => {
  const {
    label = null,
    children,
    className,
    type,
    error = null,
    inputHeightAuto = false,
  } = props;

  return (
    <div
      className={[
        "form__group",
        type ? `form__group--button ${type}` : "form__group--input",
        inputHeightAuto ? 'input-h-auto' :  "",
        error ? "form__group--error" : null,
        className,
      ].join(" ")}
    >
      {label ? <label className="form__group__label">{label}</label> : null}

      <div className="form__group__element">{children}</div>

      {error ? (
        <div className="form__group__error">
          <span className="text__error">{error}</span>
        </div>
      ) : null}
    </div>
  );
};

export default FromGroup;
