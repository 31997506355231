import { TextControllerConfigs } from "../constant";

export const documentMergeAPI = async ({
  returnFormat,
  mergeData,
  template,
}) => {
  const url =
    process.env.REACT_APP_TXTEXTCONTROLLER_SERVICE_URL +
    TextControllerConfigs.mergePath +
    returnFormat;
  const authToken =
    "Bearer " +
    btoa(
      process.env.REACT_APP_TXTEXTCONTROLLER_DS_SERVER_USERNAME +
        ":" +
        process.env.REACT_APP_TXTEXTCONTROLLER_DS_SERVER_PASSWORD
    );
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken,
    },
    body: JSON.stringify({
      mergeData,
      template,
    }),
  });

  return await response.json();
};
