import styled from "styled-components";
import { fontStyle } from "../../../../../../../../../styled/utility";

export const Wrapper = styled.div`
  border-radius: var(--layout-border-radius-4);
  background: var(--color-white, #fff);
  margin: var(--layout-spacing-8) 0;

  /* layout/boxShadow/primary/10 */
  box-shadow: -3px 8px 15px 0px rgba(45, 89, 102, 0.08);
  padding: var(--layout-spacing-28);
  min-width: 11.25rem;
  max-width: 13.125rem;

  .p-inner {
    &__header {
      padding-bottom: var(--layout-spacing-16);
      border-bottom: 1px solid var(--font-tint-01);
    }

    &__body {
      padding-top: var(--layout-spacing-16);
    }

    .btn__add-column {
      outline: none;
      border: none;
      width: 100%;
      display: flex;
      align-items: center;
      gap: var(--layout-spacing-8);
      ${fontStyle("label-medium")};
      font-weight: 500;
      color: var(--font-default-color);
      background-color: transparent;
      white-space: nowrap;
      padding: 0;

      &:hover {
        opacity: 0.5;
      }

      .icon__button__element {
        --icon-button-icon-color: var(--font-tint-04);
      }
    }

    .column-list {
      display: grid;
      gap: var(--layout-spacing-16);

      &__item {
        display: flex;
        align-items: center;

        .element__text {
          color: var(--font-default-color);
          ${fontStyle("small-regular")};
        }
      }
    }
  }
`;
