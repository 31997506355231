import * as React from "react";
import { memo } from "react";
const SvgClose = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.80536 8.84338L5.91241 5.00001L9.80536 1.14459C10.0244 0.903624 10.0244 0.530141 9.79321 0.313253C9.67155 0.192771 9.51338 0.13253 9.3431 0.13253C9.18493 0.13253 9.03897 0.204811 8.92947 0.313253L5.04868 4.15663L1.04623 0.180723C0.924577 0.060241 0.766405 0 0.596129 0C0.437958 0 0.291992 0.0722811 0.182492 0.180723C0.0729929 0.301205 1.0107e-05 0.457851 0.0121655 0.614448C0.0121655 0.771094 0.0851503 0.915653 0.194648 1.0241L4.19709 5L0.182482 8.9759C0.0608274 9.09639 0 9.25303 0 9.42167C0 9.57831 0.0729848 9.72287 0.182482 9.83132C0.267624 9.9036 0.401481 10 0.608274 10C0.827273 10 0.961084 9.9036 1.04622 9.83132L5.06083 5.85541L8.94162 9.69878C9.02677 9.78311 9.16062 9.86747 9.37957 9.86747C9.59857 9.86747 9.73238 9.77107 9.81752 9.69878C9.93917 9.5783 10 9.42166 10 9.25302C9.98784 9.09637 9.92702 8.95183 9.80536 8.84338Z"
      fill="#BFC2C9"
    />
  </svg>
);
const Memo = memo(SvgClose);
export default Memo;
