import styled, { css } from "styled-components";
import { fontStyle } from "../../../../styled/utility";
import { StyledBoxButton } from "../../../../styled/Common/Buttons";

export const UploadItemModalCss = css`
  --modal-max-width: 576px;
  --modal-width: 576px;
`;

export const FileUploadManagerWrapper = styled.div`
  .unsorted__list__wrapper {
    margin-top: var(--layout-spacing-12);
  }
`;

export const UploaderWrapper = styled.div`
  position: relative;
`;

export const Dialog = styled.div`
  width: 480px;
  background-color: var(--layout-background-white);
  border-radius: var(--layout-border-radius-12);
  box-shadow: 0 1px 15px 6px rgb(0 0 0 / 14%);
  min-height: 120px;
`;

export const ConfirmModalContent = styled.div`
  .header {
    padding: var(--layout-spacing-16);
    border-bottom: 1px solid var(--color-neutral-30);
    padding-bottom: var(--layout-spacing-16);

    .title {
    }
  }

  .body {
    padding: var(--layout-spacing-16) var(--layout-spacing-16);
    background-color: var(--layout-background-white);

    .list__wrapper {
      ul {
        li {
        }
      }
    }
  }

  .footer {
    padding: var(--layout-spacing-8) var(--layout-spacing-16);
    padding-bottom: var(--layout-spacing-16);
    display: flex;
    gap: var(--layout-spacing-4);
    align-items: center;
    justify-content: flex-end;
  }
`;

export const FileItemList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  gap: var(--layout-spacing-4);
  display: flex;
  flex-direction: column;
`;

export const FileItem = styled.li`
  display: grid;
  align-items: center;
  gap: var(--layout-spacing-4);
  grid-template-columns: 2fr auto;
  padding: var(--layout-spacing-4) var(--layout-spacing-8);
  background-color: var(--color-accent-50);
  border: 1px solid var(--color-accent-75);
  border-radius: var(--layout-border-radius-4);
  position: relative;

  ${({ hasError }) => {
    if (hasError) {
      return `
        margin-bottom: var(--layout-spacing-20);
        background-color: var(--color-danger-50);
        border-color: var(--color-danger-75);
      `;
    }
  }}

  .start {
    display: flex;
    align-items: center;
    gap: var(--layout-spacing-16);
    display: grid;
    grid-template-columns: 2fr 120px;

    .file__name {
      display: grid;
      align-items: center;
      gap: var(--layout-spacing-16);
      grid-template-columns: auto 1fr;

      .file__name__text {
        ${fontStyle("small-regular")};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .icon {
        --icon-size: var(--layout-size-16);
        --icon-font-size: var(--layout-size-16);
        --icon-font-color: var(--font-tint-03);
      }

      .input__wrapper {
        width: 100%;
      }
    }

    .section__selector {
      /* max-width: 30%;
      width: 30%; */
    }
  }

  .end {
    display: flex;
    align-items: center;
    gap: var(--layout-spacing-4);
    padding-left: var(--layout-spacing-8);
  }

  .error {
    ${fontStyle("x-small-regular")};
    display: inline-block;
    color: var(--color-danger-300);
    position: absolute;
    left: 0;
    bottom: var(--layout-spacing-minus-20);

  }
`;

export const ActionButton = styled(StyledBoxButton)`
  .icon {
    --icon-size: var(--layout-size-16);
    --icon-font-size: var(--layout-size-16);
    --icon-font-color: var(--font-tint-04);
  }

  &:disabled {
    opacity: 0.4;
  }
`;
