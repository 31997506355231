import * as React from "react";
import { memo } from "react";
const SvgArrowLeft = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.752 0.241237C12.4242 -0.0804122 11.8922 -0.0804122 11.5644 0.241237L4.24447 7.41784C3.91639 7.73923 3.91639 8.26078 4.24447 8.58215L11.5644 15.7588C11.7285 15.9196 11.9434 16 12.1585 16C12.3735 16 12.5886 15.9196 12.7524 15.7588C13.0804 15.4374 13.0804 14.9158 12.7524 14.5944L6.02617 7.99997L12.7521 1.4055C13.0802 1.08411 13.0802 0.562566 12.7521 0.241191L12.752 0.241237Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgArrowLeft);
export default Memo;
