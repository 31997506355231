import styled from "styled-components";
import { fontStyle } from "../../../../../../styled/utility";

export const ProjectList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 400px), 1fr));
  gap: var(--layout-spacing-16);

  .links {
    display: flex;
    gap: var(--layout-spacing-4);
    align-items: center;
  }
`;

export const IconWrapperElement = styled.div`
  position: relative;

  .count {
    width: var(--layout-size-20);
    height: var(--layout-size-20);
    border-radius: 999px;
    background-color: var(--color-danger-300);
    color: var(--color-white);
    display: flex;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    line-height: 1.1;
    right: var(--layout-spacing-minus-8);
    top: var(--layout-spacing-minus-8);
    z-index: 1;
    ${fontStyle("x-small-medium")};
    font-weight: 500;
  }
`;
