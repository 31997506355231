import styled, { css } from "styled-components";
import { SubmissionStatusTypes } from "../../../../constant";
import { fontStyle } from "../../../../../../../../styled/utility";
import { SubmissionPendingStatus } from "./StatusCell";

export const TwoColumnCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--layout-spacing-8);

  padding-right: var(--layout-spacing-8);
  max-width: 100%;
  /* flex: 1; */
  width: 100%;
  background-color: var(--bg-color);
  height: inherit;
  border-radius: inherit;

  .left {
    display: flex;
    align-items: center;
    gap: var(--layout-spacing-4);

    .text-content {
    }

    .icon-content {
    }
  }

  .right {
    color: var(--color-neutral-900);
  }

  .icon-hover {
    &:hover {
      opacity: 0.7;
    }
  }

  &.deliverable-cell {
    padding-left: var(--cell-left-space);
    .add-btn {
      --icon-button-icon-color: var(--color-neutral-100);
    }

    .rolling-loader {
      width: var(--layout-size-20);
      height: var(--layout-size-20);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: var(--layout-spacing-4);

      img {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  &.due-cell {
    padding-left: var(--layout-spacing-12);

    .left {
      cursor: pointer;
    }

    .delete-btn {
      --icon-button-icon-color: var(--color-secondary-500);
    }
  }
`;

const approvedCell = css`
  background-color: var(--color-success-300);
  --cell-status-icon-color: var(--color-neutral-10);
`;

const pendingCell = css`
  background-color: var(--color-warning-200);
  --cell-status-icon-color: var(--color-primary-200);
`;

const resubmitCell = css`
  background-color: #ff974d;
  --text-color: var(--color-neutral-10);
  text-transform: uppercase;
`;

export const StatusCell = styled.div.attrs({ className: "cell-status" })`
  width: 100%;
  padding: var(--layout-spacing-8);
  border-radius: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: inherit;
  background-color: var(--bg-color);

  .cell-status-icon {
    --icon-button-icon-color: var(--cell-status-icon-color);
  }

  .text {
    color: var(--text-color);
  }

  ${({ $value }) => {
    if ($value === SubmissionStatusTypes.Approved) {
      return approvedCell;
    }

    // Need to changed this after the work flow was finalized
    if (SubmissionPendingStatus.includes($value)) {
      return pendingCell;
    }

    if ($value === SubmissionStatusTypes.Resubmitted) {
      return resubmitCell;
    }
  }};
`;

const defaultDateCell = css`
  --text-color: var(--color-primary-300);
  background-color: var(--bg-color);
`;

const todayDateCell = css`
  --text-color: var(--color-danger-200);
  background-color: var(--bg-color);
`;

const overDueDate = css`
  background-color: var(--color-danger-200);
  --text-color: var(--color-neutral-10);
`;

export const DateStatusCell = styled(StatusCell)`
  font-weight: 600;

  ${({ $value }) => {
    if ($value === "default") {
      return defaultDateCell;
    }

    if ($value === "today") {
      return todayDateCell;
    }

    // Over due date, +2 days
    if ($value === "over") {
      return overDueDate;
    }
  }}
`;

export const MilestoneBlock = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  height: inherit;
  border-radius: inherit;

  .left {
    flex: 1;
    height: inherit;
    border-radius: inherit;
  }
  .right {
    height: inherit;
    display: flex;
    align-items: center;
  }
`;

export const DateDropdown = styled.div`
  .sr__control {
    padding: 0;
    background-color: transparent;
  }

  .sr__placeholder {
    color: var(--color-primary-100);
    margin-right: 4px;
    ${fontStyle("label-regular")};
  }

  .sr__input-container {
    margin: 0;
    padding: 0;
  }

  .sr__single-value {
    margin-right: 4px;
    ${fontStyle("label-regular")};
  }
  .sr__indicators {
    font-size: 8px;

    .dropdown-arrow {
      color: var(--color-neutral-700) !important;
      margin-left: 2px;
    }
  }
`;
