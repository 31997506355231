// Main menu icons

import Templates01 from "../../icons/Module/templates/01";
import Projects01 from "../../icons/Module/projects/01";
import Settings01 from "../../icons/Module/settings/01";
import Team01 from "../../icons/Module/team-hierarchy/01";
import Research01 from "../../icons/Module/research-tool/01";
import Scheduler01 from "../../icons/Module/scheduler-tool/01";
import Budget01 from "../../icons/Module/Budget/01";
import Document01 from "../../icons/Module/document-mgr-01";
import Onboarding01 from "../../icons/Module/onboarding-01";
import Dashboard01 from "../../icons/Module/dashboard-01";
import Profile01 from "../../icons/Module/profile-01";
import ProjectSettings01 from "../../icons/Module/project-settings-01";

const moduleIcons = [
  {
    type: "module",
    name: "module-template-01",
    icon: Templates01,
  },
  {
    type: "module",
    name: "module-budget-01",
    icon: Budget01,
  },
  {
    type: "module",
    name: "module-projects-01",
    icon: Projects01,
  },
  {
    type: "module",
    name: "module-settings-01",
    icon: Settings01,
  },
  {
    type: "module",
    name: "module-team-01",
    icon: Team01,
  },
  {
    type: "module",
    name: "module-research-01",
    icon: Research01,
  },
  {
    type: "module",
    name: "module-scheduler-01",
    icon: Scheduler01,
  },
  {
    type: "module",
    name: "module-document-mgr-01",
    icon: Document01,
  },
  {
    type: "module",
    name: "module-onboarding-01",
    icon: Onboarding01,
  },
  {
    type: "module",
    name: "module-dashboard-01",
    icon: Dashboard01,
  },
  {
    type: "module",
    name: "module-profile-01",
    icon: Profile01,
  },
  {
    type: "module",
    name: "module-project-settings-01",
    icon: ProjectSettings01,
  },
];

/*
    Icon names 2024/4/3
    
    module-template-01
    module-budget-01
    module-projects-01
    module-settings-01
    module-team-01
    module-research-01
    module-scheduler-01
    module-document-mgr-01
    module-onboarding-01
    module-dashboard-01
    module-profile-01
    module-project-settings-01
*/

export default moduleIcons;
