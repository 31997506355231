import * as React from "react";
import { memo } from "react";
const SvgAllTemplates = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5184 5.93425L9.56237 4.33425L8.51937 0.448248C8.47187 0.289263 8.36339 0.155535 8.21761 0.0762767C8.07184 -0.00298114 7.90062 -0.0213265 7.74137 0.0252482L0.479366 1.97125C0.317997 2.01013 0.178677 2.11151 0.09204 2.25309C0.00540298 2.39467 -0.0214572 2.56487 0.0173657 2.72625L2.59737 12.3682C2.62065 12.447 2.65922 12.5204 2.71088 12.5842C2.76254 12.648 2.82626 12.7011 2.89842 12.7403C2.97058 12.7794 3.04975 12.804 3.13142 12.8126C3.21308 12.8212 3.29564 12.8136 3.37437 12.7902L6.26537 12.0162L5.87137 13.4862C5.85215 13.5661 5.84887 13.6489 5.86169 13.7301C5.87452 13.8112 5.9032 13.889 5.94611 13.959C5.98901 14.029 6.0453 14.0899 6.11175 14.1382C6.17819 14.1864 6.2535 14.2211 6.33337 14.2402L12.8224 15.9792C12.9012 16.0026 12.9839 16.0101 13.0657 16.0015C13.1475 15.9929 13.2268 15.9682 13.299 15.9289C13.3713 15.8897 13.4351 15.8365 13.4868 15.7726C13.5385 15.7086 13.5771 15.6351 13.6004 15.5562L15.9764 6.68825C16.0149 6.52701 15.9879 6.35707 15.9013 6.21572C15.8147 6.07437 15.6755 5.97314 15.5144 5.93425H15.5184ZM1.30437 2.90725L7.56937 1.23025L9.88737 9.88525L3.62237 11.5622L1.30437 2.90725ZM12.5414 14.7742L7.08937 13.2842L7.51837 11.6842L10.6394 10.8462C10.7192 10.8271 10.7945 10.7924 10.861 10.7442C10.9274 10.6959 10.9837 10.635 11.0266 10.565C11.0695 10.495 11.0982 10.4172 11.111 10.3361C11.1239 10.2549 11.1206 10.1721 11.1014 10.0922L9.89737 5.58725L14.6564 6.89225L12.5444 14.7742H12.5414Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgAllTemplates);
export default Memo;
