import styled from "styled-components";
import { fontStyle } from "../../../../../../../styled/utility";

export const NoteContent = styled.div`
  max-width: 245px;

  .title {
    ${fontStyle("x-small-regular")};
    color: #949aa6;
  }

  .text {
    ${fontStyle("x-small-regular")};
    margin-top: var(--layout-spacing-8);
    color: #404a5f;
    max-height: 150px;
    overflow-y: auto;
  }
`;

export const Footer = styled.div`
  .footer__actions {
    margin-top: var(--layout-spacing-16);
    display: flex;
    justify-content: flex-end;
    gap: var(--layout-spacing-8);
  }
`;
