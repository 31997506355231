const config = {
  id: "schedule",
  menuIcon: "menu-scheduler-tool-01",
  label: "Scheduler",
  path: "/scheduler",
  children: {
    schedule: {
      id: "scheduler",
      path: "",
      label: "Schedule",
    },
    ui: {
      id: "ui",
      path: "ui",
      label: "UI Element",
    },
    setup: {
      id: "setup",
      path: "setup",
      label: "Setup",
    },
  },
};

export default config;
