import React from "react";
import { ColumnContainer } from "./styled";

const Column = ({ children, label, className, isFixed, ...restProps }) => {
  return (
    <ColumnContainer
      className={["column", className].join(" ")}
      $isFixed={isFixed}
      {...restProps}
    >
      <div className="column__head">{label}</div>
      <div className="column__body">{children}</div>
    </ColumnContainer>
  );
};

export default Column;
