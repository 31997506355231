import {
  addYears,
  eachMonthOfInterval,
  format,
  getMonth,
  intervalToDuration,
  isSameYear,
  isValid,
} from "date-fns";

export function getMonthAndYearOnly(date) {
  return new Date(format(date, "yyyy MM"));
}

export function getYears(start, end) {
  if (!isValid(start) || !isValid(end)) {
    return [];
  }

  if (isSameYear(start, end)) {
    return [
      {
        label: format(start, "yyyy"),
        value: format(start, "yyyy"),
        isActive: false,
      },
    ];
  }

  const { years } = intervalToDuration({
    start,
    end,
  });

  return Array(years + 1)
    .fill("")
    .map((_, i) => {
      return {
        label: format(addYears(start, i), "yyyy"),
        value: format(addYears(start, i), "yyyy"),
        isActive: false,
      };
    });
}

export function getMonthList() {
  return Array(12)
    .fill("")
    .map((_, i) => {
      return {
        label: format(new Date(`${i + 1}`), "MMM"),
        value: i,
        isDisabled: false,
        isActive: false,
      };
    });
}

export function getAvailableMonths(start, end) {
  let availableMonths = eachMonthOfInterval({
    start,
    end,
  });

  availableMonths = availableMonths.map((d) => getMonth(d));

  return getMonthList().map((d) => {
    return {
      ...d,
      isDisabled: !availableMonths.includes(d?.value),
    };
  });
}

export function setCurrentMonth(months = [], currentMonth) {
  return months?.map((m) => {
    const d = {
      ...m,
      isActive: m?.value === +currentMonth,
    };
    return d;
  });
}
