import { useEffect } from 'react';

const PermissionWrapper = ({ children, cacheKey, permissionCache }) => {
  useEffect(() => {
    return () => {
      // Cleanup function to clear cache when the component unmounts
      if (permissionCache[cacheKey]) {
        console.log('Clearing cached permission data for:', cacheKey);
        delete permissionCache[cacheKey];
      }
    };
  }, [cacheKey]);

  return children;
};

export default PermissionWrapper;
