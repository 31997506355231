import React from "react";
import * as Styled from "./styled";
import FilterTools from "./components/FilterTools";
import BlockActivities from "./components/BlockActivities";
import OtherActivites from "./components/OtherActivities";
import useScrollPosition from "../../../../../../../../../hooks/useScrollPosition";
import { ActivityModal } from "../../../../../store/SchedulerStore";

function ModalContent() {
  const containerRef = React.useRef();

  const { scrollTop } = useScrollPosition({ scrollContainerRef: containerRef });

  const handleActivity = React.useCallback((type, data) => {
    console.log("click");
    console.log("type::: ", type);
    console.log("data::: ", data);
    ActivityModal.open({ type: type, data: data });
  }, []);

  return (
    <Styled.LegendsContainer
      ref={containerRef}
      $showHeaderShadow={scrollTop > 0 ? true : false}
    >
      <div className="modal__content__head">
        <div className="title">
          <span>Scheduler Legends</span>
        </div>

        <FilterTools />
      </div>

      <div className="modal__content__body">
        <BlockActivities onClick={handleActivity} />

        <OtherActivites onClick={handleActivity} />
      </div>
    </Styled.LegendsContainer>
  );
}

export default ModalContent;
