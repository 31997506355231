import BreadcrumbLink from "../../../../components/ui-components/Breadcrumb/BreadcrumbLink";
import config from "../config";
import AppDashboard from "../../screens/AppDashboard";
import ProjectDashboard from "../../screens/ProjectDashboard";

// import { permissionLoader } from "../../../../routes/loaders";

const children = config.children;

export const ChildrenRoutes = [
  {
    id: children.app_dashboard.id,
    path: children.app_dashboard.path,
    element: <AppDashboard />,
    handle: {
      //crumb: () => <BreadcrumbLink label={children.app_dashboard.label} />,
    },
    loader: async () => {
      return true;
      // return await permissionLoader(children.app_dashboard.id);
    },
  },
  {
    id: children.project_dashboard.id,
    path: children.project_dashboard.path,
    element: <ProjectDashboard />,
    handle: {
      crumb: () => <BreadcrumbLink label={children.project_dashboard.label} />,
    },
    loader: async () => {
      return true;
      //   return await permissionLoader(children.project_dashboard.id);
    },
  },

 
];
