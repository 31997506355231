import React from "react";
import * as Styled from "./styled";

function ColorPicker({ color = "#bae1e4" }) {
  return (
    <Styled.Circle className="color__picker" $color={color} />
  );
}

export default ColorPicker;
