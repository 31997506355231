import React from "react";
import { useRoleMenuStore } from "../../../../../../store/Setting/Permission";

import Form from "./Form";
import Table from "./Table";
import "./styles.scss";
import { useRouteLoaderData } from "react-router-dom";
import { ROUTE_OPTIONS } from "../../../../../../constant/route-options";
import { SETTING_PERMISSION_MODULES } from "../../../../../../constant/setting_permission_module";
import { Permission } from "../../../../../../helpers/permission";

export const ROLE_VARIANT = "TENANT_WISE" | "PROJECT_WISE";

const RoleMenu = (props) => {
  const { variant = undefined } = props;

  const routeId = ROUTE_OPTIONS.setting.children["tenant-permission"].id;
  const { permission } = useRouteLoaderData(routeId);
  const moduleType = SETTING_PERMISSION_MODULES.TenantPermission;
  const permissions = new Permission(permission);
  const roleMenuPermission = permissions.findByName(moduleType.RoleMenu.key);
  
  if (!variant) {
    throw new Error("variant props not defined for role");
  }

  const showForm = useRoleMenuStore((state) => state.showForm);
  const onLoadDropdown = useRoleMenuStore((state) => state.onLoadDropdown);

  // Load dropdown data
  React.useEffect(() => {
    onLoadDropdown();
  }, [onLoadDropdown]);

  return (
    <div className="role__wrapper">
      {showForm &&
      (roleMenuPermission.permission.canAddNew ||
        roleMenuPermission.permission.canEdit) ? (
        <Form />
      ) : null}

      {/*
      -------------------
       Table
      -------------------
      */}
      {roleMenuPermission.permission.canView ? <Table /> : null}
    </div>
  );
};

export default RoleMenu;
