import * as React from "react";
import { memo } from "react";
const SvgDocBadge = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.04645 5.8788V2.83354L9.59873 3.34957C9.50425 2.869 9.50425 2.37462 9.59873 1.89405L9.20147 1.54847H1.94466C1.689 1.55487 1.44623 1.66209 1.2693 1.84675C1.09237 2.03142 0.99562 2.27854 1.00015 2.53425V15.0142C0.99562 15.2699 1.09237 15.5171 1.2693 15.7017C1.44623 15.8864 1.689 15.9936 1.94466 16H12.4427C12.6983 15.9936 12.9411 15.8864 13.118 15.7017C13.295 15.5171 13.3917 15.2699 13.3872 15.0142V6.45153C12.6742 6.45095 11.9755 6.25216 11.369 5.87736L9.04645 5.8788ZM12.3547 14.9676H2.03258V2.58054H8.22574V6.70955H12.3547V14.9676Z"
      fill="currentColor"
    />
    <path
      d="M15.9677 2.58054C15.9677 3.09092 15.8164 3.58985 15.5328 4.01421C15.2493 4.43858 14.8462 4.76934 14.3747 4.96465C13.9032 5.15997 13.3843 5.21107 12.8837 5.1115C12.3832 5.01193 11.9234 4.76615 11.5625 4.40526C11.2016 4.04436 10.9558 3.58456 10.8562 3.08398C10.7567 2.5834 10.8078 2.06454 11.0031 1.59301C11.1984 1.12148 11.5291 0.718453 11.9535 0.4349C12.3779 0.151346 12.8768 0 13.3872 0C14.0716 0 14.728 0.271878 15.2119 0.755823C15.6958 1.23977 15.9677 1.89614 15.9677 2.58054Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgDocBadge);
export default Memo;
