import React from "react";

import TanStackTable, {
  columnHelper,
  Elements,
} from "../../../../../../../../components/common/TanStackTable";
// import { useSettingContext } from "../../../../../../../../shared/context/SettingContext";
// import { ViewButtonWrapper } from "../../../../styled";
// import ViewSubStagePopover from "../../../../components/ui/ViewSubStagePopover";
// import { FlexCenter } from "../../../../../../../../styled";
import { useActivityBlock } from "../../../context/ActivityBlockContext";
import { FilterArea } from "../styled";
import SelectField from "../../../../../../../../../../common/components/Form/SelectField";
import {
  deleteBlock,
  getAllBlocks,
  getAllStages,
} from "../../../../../../../../api/schedule";
import Alert from "../../../../../../../../../../utility/alert";
import { useAppStore } from "../../../../../../../../../../store/AppStore/AppStore";
import { useQuery, useQueryClient } from "react-query";

export const BlockListKey = "block_list";

function BlockTable() {
  // const { projectId, tenantId } = useSettingContext();
  const { BlockModal } = useActivityBlock();
  const [stagesList, setStageList] = React.useState([]);
  const hideLoader = useAppStore((state) => state.hideLoader);
  const showLoader = useAppStore((state) => state.showLoader);
  const queryClient = useQueryClient();

  const columns = [
    columnHelper.accessor("name", {
      header: "Block Name",
      size: 200,
      cell: ({ row, getValue }) => {
        return getValue();
      },
    }),

    // columnHelper.accessor("activities", {
    //   header: () => {
    //     return <FlexCenter>Activities</FlexCenter>;
    //   },
    //   size: 50,
    //   enableSorting: false,
    //   cell: ({ row, getValue }) => {
    //     return (
    //       <FlexCenter>
    //         <ViewSubStagePopover data={getValue()} />
    //       </FlexCenter>
    //     );
    //   },
    // }),

    columnHelper.accessor("id", {
      header: "Action",
      size: 30,
      enableSorting: false,
      cell: ({ row, getValue }) => {
        return (
          <Elements.Actions gap={8}>
            <Elements.ActionButton
              type="edit"
              title="Edit"
              onClick={() => {
                BlockModal.open({ ...row?.original, type: "EDIT" } || null);
              }}
            />
            {/* <Elements.ActionButton /> */}
            <Elements.ActionButton
              type="delete"
              title="Delete"
              onClick={() => {
                deleteBlockType(row?.original);
              }}
            />
          </Elements.Actions>
        );
      },
    }),
  ];

  React.useEffect(() => {
    getAllStages().then((response) => {
      // console.log("getAllStages::: ", response?.data?.result);
      const mappedData = response?.data?.result.map((stage) => {
        return {
          label: stage?.columnName,
          value: stage?.columnTypeId,
        };
      });
      setStageList(mappedData);
    });
  }, []);

  // React.useEffect(() => {
  //   getAllBlocks().then((response) => {
  //     // console.log("getAllBlocks::: ", response?.data?.result);
  //     const mappedData = response?.data?.result.map((block) => {
  //       return {
  //         name: block?.blockName,
  //         activities: "",
  //         id: block?.blockTypeId || 0,
  //         data: block,
  //       };
  //     });
  //     setBlockList(mappedData);
  //   });
  // }, []);

  const { data: blocksList } = useQuery({
    queryKey: [BlockListKey],
    queryFn: () => getAllBlocks(),
  });

  const deleteBlockType = (block) => {
    Alert.confirm({
      allowOutsideClick: false,
      title: "Delete Block",
      text: "Are you sure you want to delete this Block?",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log("yes");
        showLoader();
        deleteBlock(block?.id || 0).then((res) => {
          if (res?.isSuccess) {
            queryClient.refetchQueries([BlockListKey]);
            hideLoader();
          } else {
            Alert.confirm({
              allowOutsideClick: false,
              iconHtml: null,
              type: "error",
              title: "Failed to delete!",
              text: res?.displayMessage,
              confirmButtonText: "Got it!",
              showCancelButton: false,
            }).then(() => {});
            hideLoader();
          }
        });
      }
    });
  };

  return (
    <>
      {/* <FilterArea>
        <SelectField options={stagesList} />
      </FilterArea> */}

      <TanStackTable
        enabledRowsSpace
        isLoading={false}
        columns={columns}
        data={blocksList}
      />
      {/* Dialogs */}
    </>
  );
}

export default BlockTable;
