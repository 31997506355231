import React from "react";
import * as Styled from "./styled";

const TabBase = (props) => {
  const {
    children,
    className = "",
    defaultActiveTab = "tabKey0",
    ...otherProps
  } = props;
  const [currentActive, setCurrentActive] = React.useState(defaultActiveTab);

  const tabs = React.useMemo(() => {
    return React.Children.map(children, (child, i) => {
      if (!React.isValidElement(child)) {
        return;
      }
      const defaultTabKey = child.props?.tabKey || `tabKey${i}`;

      return {
        ...child,
        props: {
          ...child.props,
          isDisabled: child.props?.isDisabled || false,
          tabKey: defaultTabKey,
          isActive: currentActive === defaultTabKey || false,
          onTabChange: () => {
            if (child.props?.isDisabled) return;
            setCurrentActive(defaultTabKey);
          },
        },
      };
    });
  }, [children, currentActive]);

  const renderTab = React.useMemo(() => {
    return tabs?.find((tab) => tab?.props?.isActive);
  }, [tabs]);

  return (
    <Styled.TabContainer className={`base__tab ${className}`} {...otherProps}>
      <div className="base__tab__nav">
        <ul>
          {tabs.map((tab, i) => {
            const props = tab?.props;
            return (
              <li
                key={i}
                onClick={props.onTabChange}
                className={[
                  "base__tab__link",
                  props?.isActive ? "is--active" : "",
                  props?.isDisabled ? "is--disabled" : "",
                ].join(" ")}
              >
                {props?.label}
              </li>
            );
          })}
        </ul>
      </div>

      {/*  */}
      <div className="base__tab__wrapper">
        <div
          className="base__tab__content"
          data-tab-key={renderTab?.props?.tabKey}
        >
          {renderTab?.props?.children}
        </div>
      </div>
    </Styled.TabContainer>
  );
};

TabBase.Panel = ({ children }) => {
  return children;
};

export default TabBase;
