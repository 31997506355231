import produce from "immer";
import create from "zustand";
import { devtools } from "zustand/middleware";

let store = (set, get) => ({
  legendModalState: {
    visibility: false,
    payload: null,
  },
  activityModalState: {
    visibility: false,
    payload: null,
  },
  legendScheduleItemTypes: [],
  selectedColumnType: null,
  selectedScheduleItem: null,
  selectedCellDate: "" + new Date(),
});

store = devtools(store, { name: "Scheduler Store" });

// store = persist(store, {
//   name: "global_store",
//   serialize: (state) => btoa(JSON.stringify(state)),
//   deserialize: (str) => JSON.parse(atob(str)),
// });

export const useSchedulerStore = create(store);

export const setState = useSchedulerStore.setState;

export const LegendModal = {
  open: (payload) => {
    setState(
      produce((draft) => {
        draft.legendModalState.visibility = true;
        draft.legendModalState.payload = payload;
      })
    );
  },
  close: () => {
    setState(
      produce((draft) => {
        draft.legendModalState.visibility = false;
        draft.legendModalState.payload = null;
      })
    );
  },
};

export const ActivityModal = {
  open: (payload) => {
    setState(
      produce((draft) => {
        draft.activityModalState.visibility = true;
        draft.activityModalState.payload = payload;
      })
    );
  },
  close: () => {
    setState(
      produce((draft) => {
        draft.activityModalState.visibility = false;
        draft.activityModalState.payload = null;
      })
    );
  },
};
export const setLegendScheduleItemTypes = (val) => {
  useSchedulerStore.setState(
    produce((draft) => {
      draft.legendScheduleItemTypes = val;
    })
  );
};
export const setSelectedColumnType = (val) => {
  useSchedulerStore.setState(
    produce((draft) => {
      draft.selectedColumnType = val;
    })
  );
};
export const setSelectedScheduleItem = (val) => {
  useSchedulerStore.setState(
    produce((draft) => {
      draft.selectedScheduleItem = val;
    })
  );
};
export const setSelectedCellDate = (val) => {
  useSchedulerStore.setState(
    produce((draft) => {
      draft.selectedCellDate = val;
    })
  );
};
