import * as React from "react";
import { memo } from "react";
const SvgAnnouncement = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.4965 3.00763C10.4529 3.01341 6.99197 4.90374 6.99197 4.90374H2.35717C1.06886 4.90374 0 6.02374 0 7.36863C0 8.71361 1.06896 9.83351 2.35727 9.83351H2.93667L4.19762 13.7442C4.24517 13.8951 4.39188 14.0054 4.54412 14.005H7.10019C7.33204 14.007 7.52272 13.7298 7.44669 13.5013L6.26526 9.83354H7.00366L10.372 11.6822C10.5914 11.8037 10.9038 11.6096 10.9059 11.3504V3.3867C10.9075 3.17133 10.7018 2.98055 10.497 3.00753L10.4965 3.00763ZM13.9783 3.9497C13.9087 3.95529 12.5129 4.97481 12.5129 4.97481C12.3419 5.08225 12.2885 5.34987 12.4042 5.51957C12.5199 5.68926 12.7799 5.72436 12.9332 5.59103L14.2055 4.64299C14.3372 4.55074 14.3982 4.36204 14.3468 4.20547C14.2955 4.04891 14.1365 3.93863 13.9783 3.94979L13.9783 3.9497ZM10.1784 4.00891V10.7223L7.45199 9.22322V5.50803L10.1784 4.00891ZM2.35693 5.6621H6.72491V9.07513H2.35693C1.4764 9.07513 0.726714 8.29166 0.726714 7.36861C0.726714 6.44556 1.47631 5.6621 2.35693 5.6621ZM12.689 6.98934C12.4985 6.99836 12.333 7.18756 12.3419 7.38636C12.3508 7.58515 12.5325 7.7574 12.723 7.74779H15.6313C15.8234 7.75063 16 7.56898 16 7.36861C16 7.16825 15.8233 6.98658 15.6313 6.98944C15.6313 6.98944 12.7004 6.98885 12.6891 6.98944L12.689 6.98934ZM12.6946 9.06915C12.544 9.07827 12.405 9.19581 12.3652 9.34767C12.3254 9.49952 12.3877 9.67449 12.513 9.76233L13.7853 10.7104C13.9387 10.8437 14.1986 10.8086 14.3143 10.6389C14.43 10.4692 14.3766 10.2016 14.2057 10.0941L12.9334 9.14611C12.8658 9.09239 12.7797 9.06464 12.6948 9.06905L12.6946 9.06915ZM3.70893 9.8335H5.49243L6.59437 13.2465H4.81088L3.70893 9.8335Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgAnnouncement);
export default Memo;
