import API_GLOBAL from "../../services/api";
import API_ENDPOINTS from "../../services/api-endpoints";

// Get user missing fields
export const getProfileMissedFields = (params) => {
  let incompleteProperties = [];
  const { userTenantId } = params;

  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    if (!userTenantId) {
      resolve(null);
    }

    try {
      API_GLOBAL.get(
        `${endpoints.GET_PROFILE_BASIC_DETAILS}/${userTenantId}`
      ).then((response) => {
        if (response.data.isSuccess === true) {
          if (
            response.data.result.title?.length === 0 ||
            response.data.result.title === null
          ) {
            incompleteProperties.push("Title");
          }
          if (
            response.data.result.jobTitleId === 0 ||
            response.data.result.jobTitleId === null
          ) {
            incompleteProperties.push("Job Title");
          }
          if (
            response.data.result.city?.length === 0 ||
            response.data.result.city === null
          ) {
            incompleteProperties.push("City");
          }
          if (
            response.data.result.countryId === 0 ||
            response.data.result.countryId === null
          ) {
            incompleteProperties.push("Country");
          }
          if (
            response.data.result.idNumber?.length === 0 ||
            response.data.result.idNumber === null
          ) {
            incompleteProperties.push("ID Number");
          }
          if (
            response.data.result.fullName?.length === 0 ||
            response.data.result.fullName === null
          ) {
            incompleteProperties.push("Full Name");
          }
          if (
            response.data.result.address?.length === 0 ||
            response.data.result.address === null
          ) {
            incompleteProperties.push("Address");
          }

          resolve(incompleteProperties);
        } else {
          resolve(null);
        }
      });
    } catch (error) {
      reject(null);
    }
  });
};

// Create an account object
const CreateAnAccountObj = {
  givenName: "",
  surname: "",
  displayName: "",
  jobTitleId: 0,
  countryId: 0,
  title: "",
  city: "",
  email: "",
  signInType: "emailAddress",
  password: "",
  issuer: "",
  issuerAssignedId: "",
  isInvited: false,
  invitedBy: "",
  projectId: 0,
};

// Create an account
export const createAnAccount = (formData = CreateAnAccountObj) => {
  const endpoints = new API_ENDPOINTS();

  let _formData = {
    ...CreateAnAccountObj,
    ...formData,
  };

  return new Promise((resolve, reject) => {
    API_GLOBAL.post(endpoints.SIGNUP, _formData)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getProfileDetails = (params) => {
  const { userTenantId } = params;

  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    if (!userTenantId) {
      resolve(null);
    }

    try {
      API_GLOBAL.get(
        `${endpoints.GET_PROFILE_BASIC_DETAILS}/${userTenantId}`
      ).then((response) => {
        if (response.data.isSuccess === true) {
          resolve(response?.data.result);
        } else {
          resolve(null);
        }
      });
    } catch (error) {
      reject(null);
    }
  });
};

export const updateProfileDetails = (params) => {
  const { userTenantId, formData } = params;

  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    //
    if (!userTenantId) {
      reject({ error: "Invalid user" });
    }

    API_GLOBAL.post(
      `${endpoints.USER_PROFILE_UPDATE}/${userTenantId}`,
      formData
    ).then((response) => {
      if (!response.data.isSuccess) {
        reject({ error: response.data.displayMessage });
        return;
      }

      resolve(response.data);
    });

    try {
    } catch (error) {
      reject({ error: error });
    }
  });
};
