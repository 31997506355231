import * as React from "react";
import { memo } from "react";
const SvgIconExport = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.49081 6.42842V0.642763H11.3455V8.35717H5.6726V9.00004H12V0H2.8363V6.42848L3.49081 6.42842Z"
      fill="#697089"
    />
    <path
      d="M0 6.42842V12H5.6726V7.23003L7.17592 5.75356L8.41083 6.96641L8.72717 3.32982L5.01809 3.63197L6.253 4.84483L4.63847 6.42842H0ZM5.01809 11.3572H0.654509V7.07153H5.01809V11.3572Z"
      fill="#697089"
    />
    <path d="M4.14532 10.5H1.52705V7.92849H4.14532V10.5Z" fill="#697089" />
  </svg>
);
const Memo = memo(SvgIconExport);
export default Memo;
