import React from "react";
import { SYSTEM_ROLE_TYPE } from "../../../../../../constant/System";
import { useGlobalStore } from "../../../../../../store/GlobalStore";
import { useTempUserStore } from "../../../../../../store/TempUserStore";

import Avatar from "../../../../../ui-components/Avatar";
import Button, {
  BUTTON_ICONS,
} from "../../../../../ui-components/Buttons/Button";
import "./styles.scss";
import AvatarList from "../../../../../../common/components/UI/AvatarList";

const MAX_MEMBERS_COUNT = 7;

const MemberList = () => {
  const onToggleModal = useTempUserStore((state) => state.onToggleModal);

  const globalSelectedProject = useGlobalStore(
    (state) => state.selectedProject
  );

  const roleIds = JSON.parse(localStorage.getItem("user-roles"));

  const members = React.useMemo(() => {
    let list = globalSelectedProject?.projectMembers || [];

    return {
      list: list.slice(0, MAX_MEMBERS_COUNT).map((m) => ({
        userId: m.userId,
        src: m.profileImage,
        memberUserId: m.memberUserId,
        name: m.givenName + " " + m.surName,
      })),
      count: list.length || 0,
    };
  }, [globalSelectedProject?.projectMembers]);

  const hasPermissionToInvite = React.useMemo(() => {
    let accessPersons = [
      SYSTEM_ROLE_TYPE.ADMIN,
      SYSTEM_ROLE_TYPE.PO,
      SYSTEM_ROLE_TYPE.RM,
      SYSTEM_ROLE_TYPE.HR,
      SYSTEM_ROLE_TYPE.TENANT_ADMIN,
    ];

    return roleIds && accessPersons.some((r) => roleIds.includes(r));
  }, [roleIds]);

  return (
    <div className="member">
      {members.count > 0 ? (
        <>
          <div className="divider"></div>
          <div className="dot"></div>

          <AvatarList data={members.list} className="member__list" />

          {/* <div className="member__list"> */}
          {/* {members.list.reverse().map((member,i) => (
              <div className="member__item" key={i}>
                <Avatar size="size-26" src={member.src} />
              </div>
            ))}

            {members.count > MAX_MEMBERS_COUNT ? (
              <button className="btn__more__member">+{members.count}</button>
            ) : null} */}

          {/* </div> */}
        </>
      ) : null}

      {/* {members.count > 0 && hasPermissionToInvite ? (
        <div className="divider small"></div>
      ) : null}

      {hasPermissionToInvite ? (
        <Button
          variant="orange"
          size="sm"
          leftIcon={BUTTON_ICONS.plus}
          onClick={onToggleModal}
        >
          Invite
        </Button>
      ) : null} */}
    </div>
  );
};

export default MemberList;
