import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.12382 14.5546C6.07941 14.5991 6.05713 14.6214 6.01272 14.6436C5.99052 14.6436 5.99052 14.6658 5.96832 14.6658C5.94611 14.688 5.90163 14.688 5.87943 14.7103H5.85722L0.702502 15.9778C0.658094 15.9778 0.613612 16 0.569204 16C0.413703 16 0.280325 15.9333 0.169232 15.8444C0.035935 15.711 -0.0307549 15.5108 0.0137305 15.3106L1.28016 10.1515V10.1293C1.28016 10.1071 1.30236 10.0625 1.32456 10.0403C1.32456 10.0181 1.34677 10.0181 1.34677 9.99587C1.36897 9.97364 1.39118 9.92912 1.41345 9.9069C1.41345 9.9069 1.41345 9.88468 1.43566 9.88468L10.3675 0.967367C11.6563 -0.322456 13.7448 -0.322456 15.0335 0.967367C16.3222 2.25719 16.3222 4.34749 15.0335 5.63728L6.12382 14.5546ZM4.63521 13.8653L2.14672 11.3747L1.32467 14.6881L4.63521 13.8653ZM5.72386 13.376L12.8783 6.21548L9.78987 3.12444L2.63547 10.285L5.72386 13.376ZM14.2558 1.74571C13.8336 1.32317 13.2782 1.1008 12.7005 1.1008C12.1228 1.1008 11.5896 1.32317 11.1452 1.74571L10.5453 2.34609L13.656 5.43713L14.2558 4.83675C15.1002 3.99168 15.1002 2.59074 14.2558 1.74571Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
