//
export const CrewRoutePath = {
  id: "crew",
  path: "/crew",
  label: "Crew",

  CrewPlanner: {
    path: "",
    id: "crew/planner",
    label: "Crew Planner",
  },
};
