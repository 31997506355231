import create from "zustand";
import { devtools } from "zustand/middleware";

export const TAG_MODAL_MODE = {
  Create: 1,
  Edit: 2,
  Text_Selected: 3,
};

let store = (set, get) => ({
  showPreviewModal: false,
  previewModalData: null,
  showResearchPanel: false,
  showTagModal: false,
  isTagsRevalidate: false,
  tagModalPayload: {
    mode: -1,
    tagId: null,
    tagTypeId: null,
    selectedText: "",
    previewPayload:null,
  },
  tagSearchResult: [],

  onShowPreviewModal: (payload) => {
    set({ showPreviewModal: true, previewModalData: payload });
  },

  onHidePreviewModal: () => {
    set({ showPreviewModal: false, previewModalData: null });
  },

  onShowTagModal: (payload) => {
    set({
      showTagModal: true,
      isTagsRevalidate: false,
      tagModalPayload: payload,
      previewModalData: payload.previewPayload,
    });
  },

  reloadTagModal: (payload) => {
    set({
      showTagModal: true,
      isTagsRevalidate: false,
      tagModalPayload: payload,
    });
  },

  onHideTagModal: () => {
    set({
      showTagModal: false,
      tagModalPayload: {
        mode: -1,
        tagId: null,
        selectedText: "",
        tagTypeId: null,
        tagData: null,
      },
    });
  },

  onTagRevalidate: (isValidate = true) => {
    set({
      isTagsRevalidate: isValidate,
    });
  },

  onToggleResearchPanel: () => {
    set((prev) => ({ showResearchPanel: !prev.showResearchPanel }));
  },

  onHideResearchPanel: () => {
    set((prev) => ({ showResearchPanel: false }));
  },

  onTagSearchResultChange: (payload) => {
    set({ tagSearchResult: payload });
  },
});

store = devtools(store, { name: "ResearchBoardStore" });

export const useResearchBoardStore = create(store);
