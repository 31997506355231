import React from "react";
import { RESEARCH_TAG_VISUAL_TYPE } from "../components/research-data/TagCard";
import ImageView from "../components/research-data/ImageView";
import LocationContent from "../components/research-data/TagCard/Content/LocationContent";
import VideoView from "../components/research-data/VideoView";

const useTagCardContent = ({
  variant,
  galleryOptions,
  visual,
  visualType,
  iconComponent: ColumnIcon,
}) => {
  const content = React.useMemo(() => {
    //
    // const defaultColumnIcon = (
    //   <div className="placeholder__icon">
    //     {ColumnIcon ? <ColumnIcon /> : null}
    //   </div>
    // );
    
    const defaultColumnIcon = null;

    switch (visualType) {
      case RESEARCH_TAG_VISUAL_TYPE.NA: {
        return defaultColumnIcon;
      }

      case RESEARCH_TAG_VISUAL_TYPE.IMAGE: {
        if (visual?.length !== 0 && visual?.length !== undefined) {
          const url =
            typeof visual[0] === "object" ? visual[0]?.filePath : visual[0];

          return <ImageView url={url || ""} />;
          // return <ImageView url={url || ""} />;
        } else {
          return defaultColumnIcon;
        }
      }
      case RESEARCH_TAG_VISUAL_TYPE.VIDEO: {
        if (visual?.length !== 0 && visual?.length !== undefined) {
          return <VideoView placeholderUrl={""} videoUrl={visual[0]} />;
        } else {
          return defaultColumnIcon;
        }
      }
      case RESEARCH_TAG_VISUAL_TYPE.LOCATION: {
        if (visual?.length !== 0 && visual?.length !== undefined) {
          return <LocationContent galleryOptions={galleryOptions} />;
        } else {
          return defaultColumnIcon;
        }
      }
      case RESEARCH_TAG_VISUAL_TYPE.SLIDER: {
        if (visual?.length !== 0 && visual?.length !== undefined) {
          return <LocationContent galleryOptions={galleryOptions} />;
        } else {
          return defaultColumnIcon;
        }
      }
      case RESEARCH_TAG_VISUAL_TYPE.EMPTY: {
        return null;
      }

      default:
        return null;
    }
  }, [galleryOptions, visual, visualType]);

  return { content };
};

export default useTagCardContent;
