import * as React from "react";
import { memo } from "react";
const SvgFlag = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      id="Vector"
      d="M16 33L8.04949 25.1758L0 32.9709V4.96133C0 2.22523 1.70188 0 3.79381 0H12.2062C14.2981 0 16 2.22523 16 4.96133V33Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgFlag);
export default Memo;
