/*
-------------------------
    Onboarding Status
-------------------------
*/
export const ONBOARDING_STATUS = {
  ShortListed: 1,
  ProjectAdded: 2,
  InvitationReceived: 3,
  InvitationAccepted: 4,
  InvitationRejected: 5,
  ContractRequested: 6,
  ContractChangeRequested: 7,
  ContractGenerated: 8,
  ContractSent: 9,
  ContractSigned: 10,
  ContractAccepted: 11,
  ContractRejected: 12,
  ContractExecuted: 13,
  ContractRejectedIsAck: 14, // MemberRequestedContract
  Onboarded: 15,
  ContractAcceptingByHR: 16, // show only HR ,
  ContractAcceptedByHR: 17, // Show Onboarding and HR
  ContractApproved: 18, // Onboarding
  MemberRequestedContract: 19,
  MemberContractDeclined: 20,
};

export const VIEW_CONTRACT_SOURCES = {
  hr: "hr",
  onboarding: "onboarding",
  outside: "outside",
  // hr: "d947cb8c-f593-4c38-97e0-36237f5411ae",
  // onboarding: "3790d9e7-44f8-4a1d-9d7e-9efcbf8ae72e",
  // outside: "45a3512d-716c-48d2-99c8-e2d1e0525766",
};

export const SOURCE_TYPES = {
  HR: "HR",
  ONBOARDING: "ONBOARDING",
  OUTSIDE: "OUTSIDE",
};
