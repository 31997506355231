import React from "react";
import { Wrapper } from "./styled";
import { IconBlock } from "../../../../styled/Common/Icons";
import IconTick from "../../icons/Fill/correct";

const SelectionField = (props) => {
  const {
    size = "sm",
    type = "checkbox",
    text,
    name,
    isDisabled,
    wrapperClassName,
    ...inputProps
  } = props;

  const elementClass = React.useMemo(() => {
    return type === "checkbox" ? "element-checkbox" : "element-radio";
  }, [type]);

  const render = React.useMemo(() => {
    if (typeof text === "string") {
      return <span className="element__text">{text}</span>;
    }

    if (typeof text !== "string" && React.isValidElement(text)) {
      return <div className="element__right">{text}</div>;
    }

    return null;
  }, [text]);

  return (
    <Wrapper size={size} isDisabled={isDisabled} className={wrapperClassName}>
      {/* 
        Native input
      */}
      <input type={type} name={name} disabled={isDisabled} {...inputProps} />

      {/*
        Radio or Checkbox
      */}
      <span className={["element", elementClass].join(" ")}>
        {type === "checkbox" ? (
          <IconBlock className="icon">
            <IconTick />
          </IconBlock>
        ) : null}
      </span>

      {/*
        Label
      */}
      {text ? render : null}
    </Wrapper>
  );
};

SelectionField.Sizes = {
  sm: "sm",
  md: "md",
  lg: "lg",
};

export default SelectionField;
