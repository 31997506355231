import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.655 8.8425C11.4054 8.42608 11.0632 8.07278 10.655 7.81C10.2576 7.55367 9.82575 7.35502 9.3725 7.22C9.1225 7.1425 8.82 7.0625 8.475 6.97C8.1975 6.8925 7.895 6.82 7.4275 6.72C6.94301 6.6177 6.46736 6.47726 6.005 6.3C5.77917 6.2078 5.57922 6.06192 5.4225 5.875C5.29431 5.708 5.23122 5.50008 5.245 5.29C5.245 5.2575 5.245 5.2275 5.245 5.195C5.25484 5.0178 5.31657 4.8474 5.4225 4.705C5.61029 4.47683 5.85657 4.304 6.135 4.205C6.5935 4.05023 7.07647 3.98075 7.56 4C7.93472 4.00004 8.30784 4.04879 8.67 4.145C8.90996 4.21303 9.13596 4.32307 9.3375 4.47C9.46547 4.56199 9.5749 4.67735 9.66 4.81L10.2025 5.65L11.885 4.5675L11.3425 3.7275C11.1229 3.38433 10.8405 3.08575 10.51 2.8475C10.1203 2.56397 9.68361 2.35154 9.22 2.22C9.03975 2.16904 8.85701 2.12732 8.6725 2.095V0H6.6725V2.0525C6.27199 2.10309 5.87754 2.19354 5.495 2.3225C4.87048 2.53738 4.31642 2.91856 3.8925 3.425L3.865 3.4575C3.48006 3.9465 3.26112 4.54553 3.24 5.1675C3.1983 5.85269 3.40315 6.5302 3.8175 7.0775L3.85 7.12C4.23305 7.58817 4.73091 7.94907 5.295 8.1675C5.85432 8.37905 6.42943 8.54623 7.015 8.6675C7.4425 8.7625 7.715 8.8275 7.96 8.8975H7.9875C8.305 8.9775 8.5725 9.05 8.805 9.12C9.07604 9.19994 9.33443 9.31777 9.5725 9.47C9.71464 9.56438 9.83416 9.68902 9.9225 9.835C10.0129 10.0145 10.0559 10.2142 10.0475 10.415C10.0475 10.43 10.0475 10.445 10.0475 10.46C10.0561 10.7027 9.98686 10.9418 9.85 11.1425C9.65127 11.4195 9.37449 11.631 9.055 11.75C8.56973 11.9358 8.05171 12.0209 7.5325 12H7.47C6.90689 12.0171 6.3479 11.8988 5.84 11.655C5.58883 11.5193 5.37369 11.3256 5.2125 11.09L4.6425 10.2675L3 11.4075L3.57 12.23C3.91792 12.7341 4.38174 13.1473 4.9225 13.435L4.95 13.4475C5.49266 13.7104 6.07439 13.8835 6.6725 13.96V16H8.6725V13.915C9.05236 13.8485 9.42434 13.743 9.7825 13.6C10.4569 13.3429 11.0422 12.8957 11.4675 12.3125C11.8065 11.809 12.0043 11.2239 12.0404 10.618C12.0764 10.0121 11.9494 9.40762 11.6725 8.8675L11.655 8.8425Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
