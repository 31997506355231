import React, { useRef, useState, useEffect } from "react";
import ImageUpload from "../../../controls/imageUpload/imageUpload";
import "../../../sass/settings/card__common.scss";
import "../../../sass/settings/tag__card.scss";
import deparment_icon from "../../../img/department.png";
import close_icon from "../../../img/close-circle.png";
import edit_icon from "../../../img/edit.png";
import API from "../../../../services/api";
import API_ENDPOINTS from "../../../../services/api-endpoints";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Loader from "react-loader-spinner";
import { confirm } from "react-confirm-box";
import SettingPanel from "../../../ui-components/pages-ui/Setting/SettingPanel";
import SettingAccordion from "../../../ui-components/pages-ui/Setting/SettingPanel/SettingAccordion";
import FromGroup from "../../../controls/FromGroup";
import Button, { BUTTON_ICONS } from "../../../ui-components/Buttons/Button";
import Card from "../../../ui-components/Card";
import { ROUTE_OPTIONS } from "../../../../constant/route-options";
import { useRouteLoaderData } from "react-router-dom";
import { SETTING_PERMISSION_MODULES } from "../../../../constant/setting_permission_module";
import { Permission } from "../../../../helpers/permission";

const SystemCompany = () => {
  const { permission } = useRouteLoaderData(
    ROUTE_OPTIONS.setting.children["system-company"].id
  );

  const moduleType = SETTING_PERMISSION_MODULES.Company;

  const permissions = new Permission(permission);

  const basicPermission = permissions.findByName(moduleType.basic.key);
  const departmentPermission = permissions.findByName(
    moduleType.department.key
  );

  const [companyName, setCompanyName] = useState("");
  const [location, setLocation] = useState("");
  const [telephone, settTelephone] = useState("");
  const [address01, setAddress01] = useState("");
  const [address02, setAddress02] = useState("");
  const [address03, setAddress03] = useState("");

  const [departmentName, setDepartmentName] = useState("");
  const [departmentHead, setDepartmentHead] = useState("");
  const [departmentEmail, setDepartmentEmail] = useState("");
  const [departmentPhone, setDepartmentPhone] = useState("");
  let DepartmentList = [];

  const [show, setShow] = useState(false); //Loading Indicator

  const [jobTitleId, setJobTitleID] = useState(0);
  const [jobTitle, setJobTitle] = useState("");
  const [jobTitleDesc, setJobTitleDesc] = useState("");

  const [jobTitleDev, setShowJobTitleDiv] = useState(false);
  const [jobTitleViewDiv, setShowJobTitleViewDiv] = useState(false);
  let JoBTitle_List = [];
  const [jobTitles, setJobTitles] = useState([]);

  const [platformId, setPlatformId] = useState(0);
  const [plateformName, setPlatformName] = useState("");

  const [platformDiv, setShowPlatformDiv] = useState(false);
  const [plateformViewDiv, setShowPlatformViewDiv] = useState(false);
  let Platform_List = [];
  const [plateforms, setPlatforms] = useState([]);

  useEffect(() => {
    getAndBindJobTitleList();
    getAndBindPlateformList();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
    } catch (err) {
      console.log(err);
    }
  };
  //*****/ JOB TITLE ****************// Move to Data Setup
  const getAndBindJobTitleList = async (e) => {
    try {
      setShow(true); //Loading Indicator
      const endpoints = new API_ENDPOINTS();
      const response = API.get(endpoints.GETALLJOBTITLES).then((response) => {
        //console.log(response);
        if (response.data.isSuccess === true) {
          JoBTitle_List = response.data.result;

          const jobTitle_list = JoBTitle_List.map((item) => {
            return (
              <div className="tag-dialog">
                <span className="content-span">{item.jobTitleName}</span>
                <img
                  className="close-classic"
                  src={close_icon}
                  height="30"
                  width="30"
                  onClick={() => deleteJobTitle(item)}
                />
                <img
                  className="close-edit"
                  src={edit_icon}
                  height="30"
                  width="30"
                  onClick={() => editJobTitle(item)}
                />
              </div>
            );
          });
          setJobTitles(jobTitle_list);
          setJobTilteUIViewOptions();
          setShow(false); //Loading Indicator
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };
  const saveJobTitle = async (e) => {
    //console.log(index);
    let isEditMode = false;
    if (jobTitle.length === 0) {
      toast.error("Please enter a Job Title");
      return;
    }
    if (jobTitleDesc.length === 0) {
      toast.error("Please enter a Job Title Description");
      return;
    }
    setShow(true); //Loading Indicator on
    try {
      const endpoints = new API_ENDPOINTS();
      let End_point = "";
      if (jobTitleId == 0) {
        End_point = endpoints.SETUPJOBTITLE;
        isEditMode = false;
      } else {
        End_point = endpoints.UPDATEJOBTITLE;
        isEditMode = true;
      }

      const response = API.post(End_point, {
        jobTitleId: jobTitleId,
        jobTitleName: jobTitle,
        jobTitleDescription: jobTitleDesc,
      }).then((response) => {
        //console.log(response);
        if (response.data.isSuccess === true) {
          if (!isEditMode) {
            // toast.success("Job Title created successfully");
          } else {
            // toast.success("Job Title updated successfully");
          }
          setJobTitleID(0);
          setJobTitle("");
          setJobTitleDesc("");
          getAndBindJobTitleList();

          setShow(false); //Loading Indicator off
        } else {
          // toast.success(response.data.displayMessage);
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator off
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };
  const editJobTitle = async (e) => {
    try {
      setJobTitleID(e.jobTitleId);
      setJobTitle(e.jobTitleName);
      setJobTitleDesc(e.jobTitleDescription);
      addJobTitle();
    } catch (err) {
      console.log(err);
    }
  };
  const deleteJobTitle = async (e) => {
    try {
      const confirmation = await confirm(
        "Are you sure you want to delete this item ?"
      );

      if (!confirmation) {
        return;
      }

      setShow(true); //Loading Indicator on
      const endpoints = new API_ENDPOINTS();
      const response = API.delete(
        endpoints.DELETEJOBTITLE + "/" + e.jobTitleId,
        {}
      ).then((response) => {
        //console.log(response);
        if (response.data.isSuccess === true) {
          // toast.success("Job Title deleted successfully");
          setJobTitleID(0);
          setJobTitle("");
          setJobTitleDesc("");
          getAndBindJobTitleList();
          setShow(false); //Loading Indicator off
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator off

          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };
  const setJobTilteUIViewOptions = async (e) => {
    try {
      if (JoBTitle_List.length == 0) {
        setShowJobTitleDiv(false);
        setShowJobTitleViewDiv(true);
      } else {
        setShowJobTitleDiv(true);
        setShowJobTitleViewDiv(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const addJobTitle = async (e) => {
    try {
      setShowJobTitleDiv(false);
      setShowJobTitleViewDiv(false);
    } catch (err) {
      console.log(err);
    }
  };
  const cancelJobTitle = async (e) => {
    try {
      setJobTitleID(0);
      setJobTitle("");
      setJobTitleDesc("");
      setShowJobTitleDiv(true);
    } catch (err) {
      console.log(err);
    }
  };
  //*****/ PLATFORM ****************//
  const getAndBindPlateformList = async (e) => {
    try {
      setShow(true); //Loading Indicator
      const endpoints = new API_ENDPOINTS();
      const response = API.get(endpoints.GETALLPLATFORMS).then((response) => {
        //console.log(response);
        if (response.data.isSuccess === true) {
          Platform_List = response.data.result;

          const platform_list = Platform_List.map((item) => {
            return (
              <div className="tag-dialog">
                <span className="content-span">{item.plateformName}</span>
                <img
                  className="close-classic"
                  src={close_icon}
                  height="30"
                  width="30"
                  onClick={() => deletePlatform(item)}
                />
                <img
                  className="close-edit"
                  src={edit_icon}
                  height="30"
                  width="30"
                  onClick={() => editPlatform(item)}
                />
              </div>
            );
          });
          setPlatforms(platform_list);
          setPlateformUIViewOptions();
          setShow(false); //Loading Indicator
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };
  const savePlateform = async (e) => {
    //console.log(index);
    let isEditMode = false;
    if (plateformName.length === 0) {
      toast.error("Please enter a Platform Name");
      return;
    }

    setShow(true); //Loading Indicator on
    try {
      const endpoints = new API_ENDPOINTS();
      let End_point = "";
      if (platformId == 0) {
        End_point = endpoints.SETUPPLATFORM;
        isEditMode = false;
      } else {
        End_point = endpoints.UPDATEPLATFORM;
        isEditMode = true;
      }

      const response = API.post(End_point, {
        platformId: platformId,
        plateformName: plateformName,
      }).then((response) => {
        //console.log(response);
        if (response.data.isSuccess === true) {
          if (!isEditMode) {
            // toast.success("Platform created successfully");
          } else {
            // toast.success("Platform updated successfully");
          }
          setPlatformId(0);
          setPlatformName("");
          getAndBindPlateformList();

          setShow(false); //Loading Indicator off
        } else {
          // toast.success(response.data.displayMessage);
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator off
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };
  const editPlatform = async (e) => {
    try {
      setPlatformId(e.platformId);
      setPlatformName(e.plateformName);
      addPlatform();
    } catch (err) {
      console.log(err);
    }
  };
  const deletePlatform = async (e) => {
    try {
      const confirmation = await confirm(
        "Are you sure you want to delete this item ?"
      );

      if (!confirmation) {
        return;
      }

      setShow(true); //Loading Indicator on
      const endpoints = new API_ENDPOINTS();
      const response = API.delete(
        endpoints.DELETEPLATFORM + "/" + e.platformId,
        {}
      ).then((response) => {
        //console.log(response);
        if (response.data.isSuccess === true) {
          // toast.success("Platform deleted successfully");
          setPlatformId(0);
          setPlatformName("");

          getAndBindPlateformList();
          setShow(false); //Loading Indicator off
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator off

          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };
  const setPlateformUIViewOptions = async (e) => {
    try {
      if (JoBTitle_List.length == 0) {
        setShowPlatformDiv(false);
        setShowPlatformViewDiv(true);
      } else {
        setShowPlatformDiv(true);
        setShowPlatformViewDiv(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const addPlatform = async (e) => {
    try {
      setShowPlatformDiv(false);
      setShowPlatformViewDiv(false);
    } catch (err) {
      console.log(err);
    }
  };
  const cancelPlatform = async (e) => {
    try {
      setPlatformId(0);
      setPlatformName("");
      setShowPlatformDiv(true);
    } catch (err) {
      console.log(err);
    }
  };

  // Breadcrumbs List
  const breadcrumbsList = [{ text: "Common" }, { text: "Company" }];

  return (
    <>
      <SettingPanel breadcrumbs={breadcrumbsList}>
        {basicPermission.hasPermission ? (
          <SettingAccordion title="Basic Details">
            <SettingAccordion.Card>
              <form className="row">
                <div className="col-md-12">
                  <FromGroup label="Company Name">
                    <input
                      className="form-control"
                      type="text"
                      id="company_name"
                      name="company_name"
                      placeholder="Your Company Name"
                      aria-label="Company Name"
                    />
                  </FromGroup>
                </div>

                <div className="col-md-6">
                  <FromGroup label="Location">
                    <input
                      className="form-control"
                      type="text"
                      id="location"
                      name="location"
                      placeholder="Where is this business located"
                      aria-label="Where is this business located"
                    />
                  </FromGroup>
                </div>

                <div className="col-md-6">
                  <FromGroup label="Telephone">
                    <input
                      className="form-control"
                      type="phone"
                      id="telephone"
                      name="telephone"
                      placeholder="Telephone Number"
                      aria-label="Telephone Number"
                    />
                  </FromGroup>
                </div>

                <div className="col-md-4">
                  <FromGroup label="Address">
                    <input
                      className="form-control"
                      type="text"
                      id="address_1"
                      name="address_1"
                      placeholder="Address 1"
                      aria-label="Address 1"
                    />
                  </FromGroup>
                </div>

                <div className="col-md-4">
                  <FromGroup label="&nbsp;">
                    <input
                      className="form-control"
                      type="text"
                      id="address_2"
                      name="address_2"
                      placeholder="Address 2"
                      aria-label="Address 1"
                    />
                  </FromGroup>
                </div>

                <div className="col-md-4">
                  <FromGroup label="&nbsp;">
                    <input
                      className="form-control"
                      type="text"
                      id="address_3"
                      name="address_3"
                      placeholder="Address 3"
                      aria-label="Address 3"
                    />
                  </FromGroup>
                </div>

                <div className="col-md-4">
                  <FromGroup label="Date time format">
                    <input
                      className="form-control"
                      type="date"
                      id="date_time"
                      name="date_time"
                      placeholder="Date Time Format"
                      aria-label="Date Time Format"
                    />
                  </FromGroup>
                </div>

                <div className="col-md-8">
                  <FromGroup label="Language">
                    <input
                      className="form-control"
                      type="text"
                      id="language"
                      name="language"
                      placeholder="Language"
                      aria-label="Language"
                    />
                  </FromGroup>
                </div>

                <div className="col-12">
                  <FromGroup type="button-inline-left">
                    <Button variant="secondary">Cancel</Button>
                    <Button>Save</Button>
                  </FromGroup>
                </div>
              </form>
            </SettingAccordion.Card>
          </SettingAccordion>
        ) : null}

        {/* Departments */}
        {departmentPermission.hasPermission ? (
          <SettingAccordion
            title="Departments"
            actions={
              departmentPermission.permission.canAddNew ? (
                <Button
                  size="sm"
                  variant="green-light"
                  leftIcon={BUTTON_ICONS.plus}
                >
                  Add
                </Button>
              ) : null
            }
          >
            <SettingAccordion.Card>
              {/*  */}
              <form className="row setting-form">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 col-md-8">
                      <div className="row">
                        <div className="col-md-12">
                          <FromGroup label="Department Name">
                            <input
                              className="form-control"
                              type="text"
                              id="department_name"
                              name="department_name"
                              placeholder="Your Department Name"
                              aria-label="Department Name"
                            />
                          </FromGroup>
                        </div>

                        <div className="col-md-4">
                          <FromGroup label=" Head Of Department">
                            <input
                              className="form-control"
                              type="text"
                              id="head_of_department"
                              name="head_of_department"
                              placeholder="Head Of Department"
                              aria-label="Head Of Department"
                            />
                          </FromGroup>
                        </div>

                        <div className="col-md-4">
                          <FromGroup label="Email">
                            <input
                              className="form-control"
                              type="text"
                              id="emailAddress"
                              name="emailAddress"
                              placeholder="Email Address"
                              aria-label="Email Address"
                            />
                          </FromGroup>
                        </div>

                        <div className="col-md-4">
                          <FromGroup label="Phone">
                            <input
                              className="form-control"
                              type="text"
                              id="phoneNumber"
                              name="phoneNumber"
                              placeholder="Phone Number"
                              aria-label="Phone Number"
                            />
                          </FromGroup>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <FromGroup label="&nbsp;">
                        <ImageUpload layoutAuto={true} />
                      </FromGroup>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <FromGroup type="button-inline-left">
                    <Button variant="secondary">Cancel</Button>
                    <Button>Save</Button>
                  </FromGroup>
                </div>
              </form>

              <div className="setting-details">
                <div className="row">
                  <Card.Wrapper>
                    <Card
                      imgUrl={deparment_icon}
                      title="Account & Finance"
                      subtitle="Frank McMiller"
                      onEdit={() => {}}
                      onDelete={() => {}}
                    >
                      <p>
                        franck@peddalingpictures.com <br /> +94 77 638 1538
                      </p>
                    </Card>
                    <Card
                      imgUrl={deparment_icon}
                      title="Account & Finance"
                      subtitle="Frank McMiller"
                      onEdit={() => {}}
                      onDelete={() => {}}
                    >
                      <p>
                        franck@peddalingpictures.com <br /> +94 77 638 1538
                      </p>
                    </Card>
                    <Card
                      imgUrl={deparment_icon}
                      title="Account & Finance"
                      subtitle="Frank McMiller"
                      onEdit={() => {}}
                      onDelete={() => {}}
                    >
                      <p>
                        franck@peddalingpictures.com <br /> +94 77 638 1538
                      </p>
                    </Card>
                  </Card.Wrapper>
                </div>
              </div>
            </SettingAccordion.Card>
          </SettingAccordion>
        ) : null}
      </SettingPanel>
    </>
  );
};

export default SystemCompany;
