import React from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getNDADetails2, submitInvitation } from "../../../Actions/NDA";
import { initialState, ndaReducer, ActionTypes } from "../../../reducers/nad";
import { useProfileMissedFields } from "../../../hooks/useProfileMissedFields";
import { PublicRoutePath } from "../../../routes/paths/public-path";
import { getDocumentSFDTByDocId } from "../../../Actions/Document";
import { ROUTE_OPTIONS } from "../../../constant/route-options";
import { useAppStore } from "../../../store/AppStore/AppStore";

import Container from "../../ui-components/Container";
import DocumentViewNDA from "../../ui-components/DocumentViewNDA";
import FloatActionBar from "../../ui-components/Container/FloatActionBar";
import withProfileUpdateAlert from "../../../hoc/withProfileUpdateAlert";
import useGeneratePath from "../../../hooks/useGeneratePath";
import AlertDialog from "../../ui-components/Dialog/AlertDialog";
import Button, { BUTTON_ICONS } from "../../ui-components/Buttons/Button";
import Alert from "../../../utility/alert";
import useUserData from "../../../hooks/useUserData";

import "../../assets/css/onboarding_nda.e0590c766de10bda39a989cf382a3cae.css";
import TextControllerViewer from "../../../pages/Template/components/TXTextController/components/TextControllerViewer";
import { getProjectMembersListByProjectId } from "../../../Actions/Project";

const ProjectMemberConfirmation = () => {
  // Refs
  const textareaRef = React.useRef(null);

  //Hooks
  const params = useParams();
  const navigate = useNavigate();
  const { genBriefPath } = useGeneratePath();

  const { fields } = useProfileMissedFields();
  const { userId } = useUserData();

  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);

  // States
  const [state, dispatch] = React.useReducer(ndaReducer, initialState);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [remarkVal, setRemarkVal] = React.useState("");
  const [isInitaionSubmited, setIsInitaionSubmited] = React.useState(false);
  const [isHideAction, setHideAction] = React.useState(false);
  const [templateData, setTemplateData] = React.useState(null);
  const [actionStatus, setActionStatus] = React.useState(-1);

  const documentId = state?.document?.documentId || null;

  const { data, isLoading, isFetched } = useQuery(
    ["nda", params.id],
    async () => getNDADetails2(params.id),
    {
      enabled: !!params.id,
      refetchOnWindowFocus: true,
      refetchIntervalInBackground: false,
    }
  );

  // Confirmation modal show handler
  const onShowConfirmationHandler = (status) => {
    setShowConfirmation(true);
    setActionStatus(status);
  };

  // Modal submit handler
  const onSubmitHandler = (status) => {
    if (isInitaionSubmited) {
      return;
    }

    setIsInitaionSubmited(true);

    // Check if the status is 1, then check if the user profile is updated or not.
    if (status === 1 && fields.length > 0) {
      setShowConfirmation(false);
      toast.error("For the NDA your basic profile should up to date.", {
        // autoClose: 7000,
        onClose: () => {
          setIsInitaionSubmited(false);
        },
      });

      return;
    }

    // API Call for submit.
    submitInvitation({
      id: params.id,
      status: Number.parseInt(status),
      remark: remarkVal,
    })
      .then((data) => {
        setIsInitaionSubmited(false);
        setShowConfirmation(false);

        if (!data.isSuccess) {
          toast.error(data?.result?.message);
          setHideAction(true);
          return;
        }

        setHideAction(true);

        toast.success(data?.displayMessage, {
          onClose: () => {
            if (status === 1 && state?.projectDetails) {
              const pid = state.projectDetails.projectId;
              // Generate Path
              let briefPath = genBriefPath(pid);

              // set project details to section
              localStorage.setItem(
                "selected_project",
                JSON.stringify(state.projectDetails)
              );

              navigate(briefPath);
            }
          },
        });
      })
      .catch((err) => {
        let msg;

        if (err.systemeError) {
          msg = err.systemeError.errors.projectInvitationAcceptOrRejectDto[0];
        } else {
          msg = err;
        }

        toast.error(msg);
        setIsInitaionSubmited(false);
        setShowConfirmation(false);
      });
  };

  const onCancelHandler = () => {
    navigate(`/${ROUTE_OPTIONS.dashboard.path}`);
  };

  const handleAccessibilityForFloatingButtons = async (projectId) => {
    try {
      const members = await getProjectMembersListByProjectId(projectId);
      const member = members.find((member) => member.userId === userId);
      if (member?.userCurrentStatusIdInProject > 3) {
        setHideAction(true);
      }
    } catch (error) {
      setHideAction(false);
    }
  };

  React.useEffect(() => {
    if (documentId) {
      getDocumentSFDTByDocId(documentId)
        .then((res) => {
          if (!res?.isSuccess) {
            return;
          }

          setTemplateData(res?.result || null);
        })
        .catch((err) => {});
    }
  }, [documentId]);

  React.useEffect(() => {
    if (showConfirmation) {
      textareaRef?.current.focus();
      setRemarkVal("");
    }
  }, [showConfirmation]);

  React.useEffect(() => {
    showLoader();

    if (!isLoading) {
      hideLoader();
    }

    if (!isLoading && isFetched && data) {
      console.log("NDA data --> ", data);

      // Check if the data is successfully
      if (!data?.isSuccess) {
        Alert.fire({
          allowOutsideClick: false,
          icon: "error",
          title: "Error",
          text: data?.result?.message,
          confirmButtonText: "Go Back",
        }).then(() => {
          navigate(`/${ROUTE_OPTIONS.dashboard.path}`);
        });
        return;
      }

      // Check match userId with current log user
      if (data?.result?.projectInviteeDetails?.userId !== userId) {
        localStorage.setItem("user-status", -1);
        navigate(PublicRoutePath.Login.path);
        return;
      }

      handleAccessibilityForFloatingButtons(
        data?.result?.projectDetails?.projectId
      ).then((res) => {
        // Dispatch data
        dispatch({
          type: ActionTypes.NDA_FETCH_DETAILS,
          payload: data?.result,
        });
      });
    }
  }, [isLoading, showLoader, hideLoader, data, isFetched, navigate, userId]);

  if (isLoading || !data.isSuccess || !documentId) {
    return <></>;
  }

  return (
    <>
      <Container withGutterY={false} pageContentClassName="full--page">
        <div className="nda doc-wrapper ">
          <div className="nda__top">
            <div className="container__v2">
              <div className="container__block">
                <div className="nda__top__inner">
                  <div className="start">
                    {state.logoUrl ? (
                      <div className="logo__wr">
                        <img src={state.logoUrl} alt="" />
                      </div>
                    ) : (
                      <div className="text__wr">
                        <div className="text">{state.projectName}</div>
                      </div>
                    )}

                    {state.title ? (
                      <div className="text__wr">
                        <div className="text">{state.title}</div>
                      </div>
                    ) : null}
                  </div>
                  <div className="end">
                    <div className="left">
                      {state.joinAs ? (
                        <>
                          <div className="subtitle">Join as the </div>
                          <div className="title">{state.joinAs}</div>
                        </>
                      ) : null}

                      {state.description ? (
                        <div className="desc">{state.description}</div>
                      ) : null}
                    </div>
                    <div className="right">
                      {state.contactPerson.name ? (
                        <div className="contact__person__block">
                          <div className="contact__person__block__heading">
                            Contact Person
                          </div>
                          <div className="contact__person__block__info">
                            {state.contactPerson.avatarUrl ? (
                              <div className="avatar avatar--size-36 contact__person_avatar">
                                <div className="avatar__pic">
                                  <img
                                    className="avatar__pic__img"
                                    src={state.contactPerson.avatarUrl}
                                    alt=""
                                  />
                                </div>
                              </div>
                            ) : null}

                            <div className="info__iw">
                              <div className="position">
                                {state.contactPerson.position}
                              </div>
                              <div className="name">
                                {state.contactPerson.name}
                                <a href={`mailto:${state.contactPerson.email}`}>
                                  <span className="icon icon-lp-email text-gray-500 icon__email"></span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nda__conntent">
            {/* <div className="container__v2">
                          <div className="container__block">
                            <button onClick={toggleModal}>Toggle Modal</button>
                          </div>
                        </div> */}

            <DocumentViewNDA
              title={state.document.title || null}
              url={state.document.url || null}
              templateData={templateData}
            />
            {/* <TextControllerViewer
              templateName={state.document.title || null}
              templateUrl={state.document.url || null}
              documentData={templateData}
            /> */}
          </div>
        </div>

        <FloatActionBar>
          {!isHideAction && templateData ? (
            <>
              <button
                className="btn icon icon-lp-close icon--v-middle box-btn box-btn-gray-200"
                type="button"
                onClick={onCancelHandler}
              />
              <button
                className="btn btn-gray-400 text-white icon icon-lp-cancel btn--with-icon"
                type="button"
                onClick={() => onShowConfirmationHandler(0)}
              >
                Decline
              </button>
              <button
                className="btn btn-green-light text-white icon icon-lp-done btn--with-icon"
                type="button"
                onClick={() => onShowConfirmationHandler(1)}
              >
                Accept NDA
              </button>
            </>
          ) : (
            <button
              className="btn icon icon-lp-close icon--v-middle box-btn box-btn-gray-200"
              type="button"
              onClick={onCancelHandler}
            />
          )}
        </FloatActionBar>
      </Container>

      {/* Confirmation dialog */}
      <AlertDialog
        alertIcon={
          <>
            <i className="icon icon-35 icon-lp-add-user-1 text-orange"></i>
          </>
        }
        visibility={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        contentTextAlign="left"
        title="Confirmation"
        contents={
          <>
            <div className="col-12">
              <label className="form-label">Remark</label>
              <textarea
                className="form-control textarea-control"
                ref={textareaRef}
                value={remarkVal}
                onChange={(e) => setRemarkVal(e.target.value)}
              ></textarea>
            </div>
          </>
        }
        actions={
          <>
            <button
              className="btn icon icon-lp-close icon--v-middle box-btn box-btn-gray-200"
              type="button"
              onClick={() => setShowConfirmation(false)}
            ></button>

            {actionStatus === 0 ? (
              <Button
                leftIcon={BUTTON_ICONS.cancel}
                variant="secondary"
                onClick={() => onSubmitHandler(2)}
              >
                Decline
              </Button>
            ) : null}

            {actionStatus === 1 ? (
              <Button
                leftIcon={BUTTON_ICONS.done}
                onClick={() => onSubmitHandler(1)}
              >
                Accept NDA
              </Button>
            ) : null}
          </>
        }
      />
    </>
  );
};

// export default withAuth(withProfileUpdateAlert(ProjectMemberConfirmation));
export default withProfileUpdateAlert(ProjectMemberConfirmation, {
  page: "NDA",
});
