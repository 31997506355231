import API_Template from "../../services/api-template";
import API_FileUpload from "../../services/api-fileupload";
import API_ENDPOINTS from "../../services/api-endpoints";

/**
 * API for Get document of sfdt format template by document id
 * * GET
 * @param {documentId}
 * @returns Promise
 */

export const getDocumentSFDTByDocId = (documentId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Template.get(`${endpoints.DOCUMENT_DOC_GET_BY_ID}/${documentId}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};

export const getDocumentBase64ByDocId = (documentId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Template.get(`${endpoints.DOCUMENT_GET_BASE64_DOC_BY_ID}/${documentId}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};

const documentSaveBody = {
  fileName: "",
  containerName: process.env.REACT_APP_DOCUMENTS_CONTAINER,
  documentData: "",
};

export const saveDocumentToDocx = (data) => {
  const endpoints = new API_ENDPOINTS();
  const payload = {
    ...documentSaveBody,
    ...data,
  };

  return new Promise((resolve, reject) => {
    API_FileUpload.post(endpoints.DOCUMENT_UPLOAD_DOCUMENT_ENDPOINT, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
