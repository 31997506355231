import * as React from "react";
import { memo } from "react";
const SvgSplit = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.95501 6.00253V3.38767H10.1327L8.32327 0L6.5138 3.38767H7.71164V6.00253H3V7.25585H13.6666V6.00253H8.95501Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.00001 9.99791H7.71166V12.6126H6.51381L8.32328 16L10.1327 12.6126H8.95502V9.99791H13.6667V8.74436H3.00003L3.00001 9.99791Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgSplit);
export default Memo;
