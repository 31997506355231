import styled from "styled-components";
import { fontStyle } from "../../../../../../styled/utility";

export const Container = styled.div`
  .header {
    display: flex;
    border-bottom: 1px solid var(--layout-background-n40);
    padding-bottom: var(--layout-spacing-16);
  }

  .card__header {
    ${fontStyle("small-medium")};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .add__btn__container {
    display: flex;
    align-items: center;
  }

  .add__btn {
    border: 1px dashed var(--color-accent-300);
    ${fontStyle("x-small-medium")};
    color: var(--font-color-shade-accent);
    background-color: var(--color-accent-50);
    padding: var(--layout-spacing-8) var(--layout-spacing-8);
    border-radius: var(--layout-border-radius-4);
    flex-grow: 1;
    opacity: 0.6;
  transition: opacity 0.3s ease;
  }

  .add__btn:hover {
  opacity: 1;
}

  .card__number {
    .card__input {
      ${fontStyle("large-semi-bold")};
      text-align: center;
      padding: var(--layout-spacing-4);
      width: var(--layout-size-36);
      }
    }

.card__number{
    margin-left: var(--layout-spacing-12);
    width:var(--layout-size-36);
    height:var(--layout-size-36);
  }

  .table__container {
    margin-top: var(--layout-spacing-16);

    .table__head {
      padding-bottom: var(--layout-spacing-8);
      padding-right: var(--layout-spacing-16);
    }

    .details{
      padding:0;
      padding-bottom: var(--layout-spacing-8);
      padding-right: var(--layout-spacing-16);
    }

    .field__header {
      padding-bottom: var(--layout-spacing-8);
    }

    .ep__title {
      width: 30%;
    }

    .field__input {
      padding-right: var(--layout-spacing-16);
    }

    .btn__td{
      ${'' /* padding-bottom: var(--layout-spacing-16); */}
    }

    .svg__input {
      margin-left: var(--layout-spacing-24);
    }

    .bin__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      opacity: 0.6;
      background-color: transparent;
      transition: opacity 0.3s ease;
      color: var(--font-tint-04);
      font-size: var(--layout-size-16);
    }



    .bin__btn:hover {
      opacity: 1;
    }

    .btn__content {

    }

    .btn__content {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .add__logo {
    margin-right: var(--layout-spacing-8);
  }
`;
