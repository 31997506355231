import React from "react";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import useColumns from "./hooks/useColumns";
import DefaultCell from "./components/Cells/DefaultCell";
import { GridWrapper } from "./styled";
import { getCommonPinningStyles } from "./helper";
import { RowHoverFeature } from "./features/row-hover.feature";
import { useMilestone } from "../../context/MilestoneContext";

export const columnHelper = createColumnHelper();

const TableContext = React.createContext({
  data: [],
  columns: [],
  table: null,
});

export const useTable = () => React.useContext(TableContext);

function Table(props) {
  const { data = [], children, ...otherProps } = props;
  const tableRef = React.useRef();
  const [rowSelectionHover, setRowHover] = React.useState(false);
  const { toggleDueModalHandler } = useMilestone();

  // const [columnPinning] = React.useState({
  //   left: ["deliverables", "series"],
  //   right: ["due"],
  // });

  const { columns } = useColumns({ columnHelper });

  const onDueCellHandler = React.useCallback(
    (data) => {
      console.log(data);
      toggleDueModalHandler(true, data);
    },
    [toggleDueModalHandler]
  );

  const table = useReactTable({
    _features: [RowHoverFeature],

    defaultColumn: {
      cell: (props) => <DefaultCell {...props} />,
    },
    data,
    columns,
    state: {
      // columnPinning,
      expanded: true,
      rowSelectionHover,
    },
    meta: {
      onDueCellHandler: onDueCellHandler,
    },
    getRowId: (row) => row.uuid,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),

    onRowHoverChange: setRowHover,
  });

  return (
    <TableContext.Provider value={{ table, data, columns }}>
      <GridWrapper>
        <table
          ref={tableRef}
          style={{
            width: table.getTotalSize(),
          }}
          {...otherProps}
        >
          {children}
        </table>
      </GridWrapper>
    </TableContext.Provider>
  );
}

function TableBody() {
  const { table } = useTable();

  const rows = table.getRowModel().rows;

  return (
    <tbody>
      {rows.length > 0 ? (
        rows.map((row, i) => <TableRow key={row.id} row={row} />)
      ) : (
        <SpanRow label="No record/s" />
      )}
    </tbody>
  );
}

function TableHead() {
  const { table } = useTable();

  return (
    <thead>
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            const width = header.column.columnDef?.meta?.size;
            const textAlign = header.column.columnDef?.meta?.align || "left";

            return (
              <th
                {...{
                  key: header.id,
                  style: {
                    width,
                    textAlign,
                    ...getCommonPinningStyles(header.column),
                  },
                }}
              >
                <div className="th-cell">
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </div>
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
}

function TableRow({ row }) {
  const subRowClz = row.depth ? `sub-row` : undefined;

  const hasSubRows =
    row.depth && row.subRows.length ? `has-sub-rows` : undefined;

  const depthClz = row.depth ? `depth-${row.depth}` : undefined;

  const isRowHover = row.getIsRowHovered();

  const rowHoverClz = isRowHover ? "row-hover" : null;

  return (
    <>
      <tr
        className={[subRowClz, depthClz, hasSubRows, rowHoverClz]
          .join(" ")
          .trim()}
        onMouseOver={() => row?.onRowHover()}
        onMouseLeave={() => row?.onRowLeave()}
      >
        {row.getVisibleCells().map((cell) => {
          const textAlign = cell.column.columnDef?.meta?.align || undefined;
          const width = cell.column.columnDef?.meta?.size;

          let tdClassName = [""];
          tdClassName = tdClassName.join(" ").trim();

          return (
            <td
              className={tdClassName}
              key={cell.id}
              style={{
                width,
                textAlign,
                ...getCommonPinningStyles(cell.column),
              }}
            >
              <div className="td-cell">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </div>
            </td>
          );
        })}
      </tr>
    </>
  );
}

function SpanRow({ label }) {
  const { table } = useTable();

  const rows = table.getRowModel().rows;

  return (
    <tr>
      <td colSpan={rows.length}>{label}</td>
    </tr>
  );
}

Table.Head = TableHead;
Table.Body = TableBody;

export default Table;
