import styled from "styled-components";

export const HRHeader = styled.div`
  display: grid;
  grid-template-columns: 12% auto 15% 175px;
  color: var(--font-color-shade-primary, #012732);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  padding: 0 var(--layout-spacing-20);

  .inner {
    display: grid;
    grid-template-columns: 30% 25% auto;
    gap: var(--spacing-2);
  }
`;
