import * as React from "react";
import { memo } from "react";
const SvgGlass = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2304_11539)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6848 10.5329L15.762 14.6108C16.0793 14.9281 16.0803 15.4414 15.7594 15.7623C15.4407 16.0811 14.9195 16.0765 14.6081 15.765L10.5309 11.687C7.97003 13.6804 4.26593 13.5 1.91192 11.1457C-0.637307 8.59595 -0.637307 4.46202 1.91192 1.91229C4.46115 -0.637431 8.59428 -0.637431 11.1435 1.91229C13.4974 4.26661 13.6778 7.97157 11.6847 10.533L11.6848 10.5329ZM9.98965 9.99147C11.9016 8.07914 11.9016 4.9788 9.98965 3.06647C8.07769 1.15414 4.97795 1.15414 3.06599 3.06647C1.15403 4.9788 1.15403 8.07914 3.06599 9.99147C4.97795 11.9038 8.07769 11.9038 9.98965 9.99147Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2304_11539">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgGlass);
export default Memo;
