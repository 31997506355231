import * as React from "react";
import { memo } from "react";
const SvgIconCurvedRightArrowDown = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      id="Vector"
      d="M5.14416 11.8752H9.07482L7.96617 13.007C7.7497 13.2279 7.6652 13.55 7.74441 13.8518C7.82361 14.1536 8.05455 14.3893 8.35015 14.4702C8.64576 14.551 8.96118 14.4648 9.17764 14.2438L11.7487 11.619C11.9096 11.455 12 11.2325 12 11.0005C12 10.7685 11.9096 10.546 11.7487 10.3821L9.17764 7.75724C8.96118 7.53631 8.64576 7.44997 8.35015 7.53083C8.05455 7.6117 7.82361 7.84746 7.74441 8.14926C7.6652 8.45112 7.7497 8.77314 7.96617 8.99406L9.07482 10.1259H5.14416C4.2349 10.1254 3.36309 9.75638 2.72032 9.10002C2.07755 8.44366 1.71599 7.5536 1.71546 6.62546V1.3757C1.71546 1.06283 1.55195 0.773759 1.28655 0.617351C1.02121 0.460883 0.694259 0.460883 0.428856 0.617351C0.163521 0.77375 0 1.06283 0 1.3757V6.62546C0 7.54718 0.237756 8.45273 0.689237 9.25104C1.14079 10.0492 1.79024 10.712 2.57229 11.1726C3.35433 11.6333 4.24135 11.8756 5.14423 11.8753L5.14416 11.8752Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgIconCurvedRightArrowDown);
export default Memo;
