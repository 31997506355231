import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.57592 10.5375H8.34989L8.67852 9.6121H6.57592V10.5375ZM12.9589 6.39608L12.3393 5.63254L11.628 5.88433L12.2477 6.64786L12.9589 6.39608ZM13.1306 5.35295L13.7503 6.11649L14.3585 5.90231L14.0495 5.02759L13.1306 5.35295ZM8.44962 7.99183L7.82996 7.22829L6.11159 7.83487L6.4206 8.70959L8.44962 7.99183ZM8.6213 6.94707L9.24096 7.71224L9.95381 7.46045L9.33415 6.69528L8.6213 6.94707ZM10.1255 6.4157L10.7451 7.18087L11.4564 6.92908L10.8367 6.16391L10.1255 6.4157ZM9.19027 10.5375H9.944L10.2726 9.6121H9.5189L9.19027 10.5375ZM10.7844 10.5375H11.5381L11.8667 9.6121H11.113L10.7844 10.5375ZM12.3785 10.5375H13.1339L13.4625 9.6121H12.7088L12.3785 10.5375ZM13.9742 10.5375H14.9945V9.6121H14.3029L13.9742 10.5375ZM9.46332 1.35541V2.07153H10.1794L9.46332 1.35541ZM8.66871 2.46883V0.794604H0.79297V13.3791H5.78296V9.28183L5.2336 7.72696C5.16002 7.51931 5.26793 7.29369 5.47394 7.22175L10.7402 5.35786V2.8645H9.06601C8.84692 2.8645 8.66871 2.68792 8.66871 2.46883ZM9.2802 0.0637646L11.4302 2.20233C11.5218 2.29225 11.5332 2.32822 11.5332 2.46883V5.07828L14.159 4.1496C14.365 4.07603 14.5906 4.18394 14.6642 4.38995L15.2365 6.01185C15.31 6.21786 15.2021 6.44349 14.9945 6.51707L11.5332 7.74167V8.81913H15.3918C15.6092 8.81913 15.7875 8.99571 15.7875 9.2148V15.6027C15.7875 15.8218 15.6092 16 15.3918 16H6.18026C5.96117 16 5.78296 15.8218 5.78296 15.6027V14.1737H0.397302C0.178214 14.1737 0 13.9955 0 13.7764V0.397302C0 0.178214 0.178214 0 0.397302 0H9.06601C9.1494 0 9.22297 0.00653995 9.2802 0.0637646ZM10.7402 8.81913V8.02126L8.48886 8.81913H10.7402ZM14.9945 11.3305H6.57592V15.2054H14.9945V11.3305Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
