import React, { Component } from "react";
import "../../sass/layout/single_header.scss";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../../common/components/UI/Logo/Logo";
import { ROUTES_PATH } from "../../../routes/paths";

const SingleHeader = () => {
  return (
    <div className="header-l">
      <header className="header-two-bars">
        <div className="header-first-bar-l">
          <div className="header-limiter">
            <Logo />

            <nav>
              {/* <a href="#">FEATURES</a>
              <a href="#">BLOG</a>
              <a href="#">PRICING</a> */}
              <NavLink
                to={ROUTES_PATH.PublicRoutePath.CreateAccount.path}
                className="signup-button"
              >
                SIGNUP
              </NavLink>
              <NavLink to={ROUTES_PATH.PublicRoutePath.Login.path}>
                LOGIN
              </NavLink>
            </nav>
          </div>
        </div>
      </header>
    </div>
  );
};

export default SingleHeader;
