import styled from "styled-components";

export const UISlideOuter = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  max-width: var(--rdp-max-width);
  width: var(--rdp-max-width);
`;

export const UIArrowButton = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  min-width: ${({ theme }) => theme.sizes(5)};
  width: ${({ theme }) => theme.sizes(5)};
  height: ${({ theme }) => theme.sizes(5)};
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    opacity: 0.4;
  }
`;

export const UISlide = styled.div`
  display: block;
  overflow: hidden;
  flex-grow: 1;

  .slide__inner {
    display: flex;
    align-items: center;
  }

  .slide__item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
