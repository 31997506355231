import styled from "styled-components";
import IconButton from "../../../../../../common/components/Buttons/IconButton";

export const ViewButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`;

export const ViewButton = styled(IconButton)`
  --icon-button-icon-color: var(--color-accent-50);
  background-color: var(--color-accent-200);
  border-radius: var(--layout-border-radius-4);

  &:hover {
    opacity: 0.5;
  }

  .button-icon {
  }
`;
