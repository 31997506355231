
import IconAddress01 from "../../icons/TagField/Address/01";
import IconEmail01 from "../../icons/TagField/Email/01";
import IconPhone01 from "../../icons/TagField/Phone/01";
import IconSpecialNotes01 from "../../icons/TagField/SprcialNotes/01";
import IconWeb01 from "../../icons/TagField/Web/01";


const tagFieldIcons = [
  { type: "tag-field", name: "tag-field-address-01", icon:IconAddress01  },
  { type: "tag-field", name: "tag-field-email-01", icon:IconEmail01  },
  { type: "tag-field", name: "tag-field-phone-01", icon:IconPhone01  },
  { type: "tag-field", name: "tag-field-special-notes-01", icon:IconSpecialNotes01  },
  { type: "tag-field", name: "tag-field-web-01", icon:IconWeb01  },

];

export default tagFieldIcons;
