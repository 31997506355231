import styled from "styled-components";
import { fontStyle } from "../../../../styled/utility";

export const StyledSwitchOuterWrapper = styled.div`
  --switch-text-color: ${({ theme }) => theme.colors.color_1(50)};

  &.is-checked {
    --switch-text-color: ${({ theme }) => theme.colors.color_1()};
  }

  .inner {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(2)};
  }

  .text {
    flex: none;
    order: 0;
    flex-grow: 0;
    color: var(--switch-text-color);
    ${fontStyle("label-regular")};
  }
`;

export const StyledSwitchWrapper = styled.label`
  --switch-h: 18px;
  --switch-w: 39px;
  --switch-border-color: ${({ theme }) => theme.colors.primary_light(20)};
  --switch-bg-color: ${({ theme }) => theme.colors.white()};
  --switch-circle-color: ${({ theme }) => theme.colors.primary_light(30)};
  --switch-circle-color-active: ${({ theme }) => theme.colors.primary_light()};
  --switch-circle-w: 22px;
  --switch-circle-h: 18px;

  display: flex;
  align-items: center;
  height: var(--switch-h);
  width: var(--switch-w);
  box-shadow: 0 0 0px 1px var(--switch-border-color);
  background-color: var(--switch-bg-color);
  position: relative;
  border-radius: ${({ theme }) => theme.rounded("full")};
  overflow: hidden;
  cursor: pointer;

  .circle {
    position: absolute;
    width: var(--switch-circle-w);
    height: var(--switch-circle-h);
    background-color: var(--switch-circle-color);
    border-radius: inherit;
    top: 0;
    left: 0;
    transition: ${({ theme }) => theme.animate.primary};
  }

  input[type="checkbox"] {
    position: absolute;
    z-index: -1;
    visibility: hidden;
    width: 0;
    height: 0;

    &:checked + .circle {
      background-color: var(--switch-circle-color-active);
      transform: translateX(17px);
    }
  }
`;
