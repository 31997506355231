import React from "react";
import { useSettingUserRoleStore } from "../../../../../../../store/Setting/Permission";
import ActionButton from "../../../../../Buttons/ActionButton";
import TableList from "../../../../../TableList";
import "./styles.scss";
import { toast } from "react-toastify";
import Alert from "../../../../../../../utility/alert";
import { useRouteLoaderData } from "react-router-dom";
import { ROUTE_OPTIONS } from "../../../../../../../constant/route-options";
import { SETTING_PERMISSION_MODULES } from "../../../../../../../constant/setting_permission_module";
import { Permission } from "../../../../../../../helpers/permission";

const columns = [
  {
    label: "User",
    name: "user",
    sortable: false,
    width: "15%",
  },
  {
    label: "Roles",
    name: "roles",
    sortable: false,
    width: "70%",
  },
  {
    label: "Action",
    name: "action",
    sortable: false,
    width: "15%",
  },
];

const UserRolesTable = () => {
  const { permission } = useRouteLoaderData(
    ROUTE_OPTIONS.setting.children["tenant-permission"].id
  );
  const moduleType = SETTING_PERMISSION_MODULES.TenantPermission;
  const permissions = new Permission(permission);
  const userRolePermission = permissions.findByName(moduleType.UserRole.key);

  const data = useSettingUserRoleStore((state) => state.data);
  const onSetUserRoleData = useSettingUserRoleStore(
    (state) => state.onSetUserRoleData
  );

  const onEdit = useSettingUserRoleStore((state) => state.onEdit);
  const onDelete = useSettingUserRoleStore((state) => state.onDelete);

  React.useEffect(() => {
    onSetUserRoleData();
  }, [onSetUserRoleData]);

  const onDeleteHandler = (id) => {
    Alert.confirm({
      title: "Are you sure?",

      text: "You want to complete this",

      confirmButtonText: "Yes, Complete",
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(id)
          .then((res) => {
            console.log(res);
            // toast.success(res.displayMessage);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };
  const tableRenderRows = (rowData, index) => {
    const { user, roles = [], action, id } = rowData;

    return (
      <TableList.Row key={index}>
        <TableList.Column width={user.style.width}>
          {user.value.name}
        </TableList.Column>

        <TableList.Column width={roles.style.width}>
          <div className="roles">
            {roles.value.map((role, i) => (
              <span className="roles__item" key={i}>
                {role.label}
              </span>
            ))}
          </div>
        </TableList.Column>

        <TableList.Column width={action.style.width}>
          {/* Edit */}
          {userRolePermission.permission.canEdit ? (
            <ActionButton variant="EDIT" onClick={() => onEdit(id.value)} />
          ) : null}

          {/* Delete */}
          {userRolePermission.permission.canDelete ? (
            <ActionButton
              variant="DELETE"
              onClick={() => onDeleteHandler(id.value)}
            />
          ) : null}
        </TableList.Column>
      </TableList.Row>
    );
  };

  return (
    <div className="setting-details setting__details--role">
      <TableList columns={columns} data={data} renderRows={tableRenderRows} />
    </div>
  );
};

export default UserRolesTable;
