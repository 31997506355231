import * as React from "react";
import { memo } from "react";
const SvgMorning = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 3.52957C9.57694 3.52957 10.8952 4.6377 11.2184 6.11785H4.78172C5.1049 4.63771 6.42306 3.52957 8.00013 3.52957H8ZM0.235307 7.76479C0.105359 7.76479 0 7.65948 0 7.52952V7.05889C0 6.92894 0.105364 6.82362 0.235307 6.82362H15.7647C15.8946 6.82362 16 6.92894 16 7.05889V7.52952C16 7.65947 15.8946 7.76479 15.7647 7.76479H0.235307ZM7.52946 11.0185L6.61681 11.9312C6.52493 12.0231 6.37598 12.0231 6.28405 11.9312L5.95129 11.5984C5.85941 11.5066 5.85941 11.3575 5.95129 11.2657L7.66729 9.54966C7.85109 9.36591 8.14901 9.36591 8.33281 9.54966L10.0488 11.2657C10.1407 11.3575 10.1407 11.5065 10.0488 11.5984L9.71606 11.9312C9.62413 12.0231 9.47518 12.0231 9.3833 11.9312L8.47064 11.0185V15.7648C8.47064 15.8948 8.36533 16.0001 8.23537 16.0001H7.76475C7.6348 16.0001 7.52948 15.8948 7.52948 15.7648L7.52946 11.0185ZM7.52946 0.235429C7.52946 0.105481 7.63478 0.00012207 7.76473 0.00012207H8.23536C8.36531 0.00012207 8.47063 0.105486 8.47063 0.235429V1.8825C8.47063 2.01245 8.36532 2.11781 8.23536 2.11781H7.76473C7.63478 2.11781 7.52946 2.01244 7.52946 1.8825V0.235429ZM13.0603 2.4289L11.8267 3.66245C11.7348 3.75433 11.5858 3.75433 11.494 3.66245L11.1612 3.32969C11.0693 3.23782 11.0693 3.08881 11.1612 2.99693L12.3948 1.76338C12.4866 1.67151 12.6356 1.67151 12.7275 1.76338L13.0603 2.09614C13.1522 2.18802 13.1522 2.33697 13.0603 2.4289ZM3.60532 1.76338L4.83887 2.99693C4.9308 3.08881 4.9308 3.23781 4.83887 3.32969L4.50611 3.66245C4.41423 3.75433 4.26528 3.75433 4.17335 3.66245L2.9398 2.4289C2.84792 2.33698 2.84792 2.18802 2.9398 2.09614L3.27256 1.76338C3.36443 1.67151 3.51344 1.67151 3.60532 1.76338Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgMorning);
export default Memo;
