import React from "react";

const useTableData = ({
  data = [],
  columns = [],
  renderRows
}) => {
  const [tableData, setTableData] = React.useState([]);
  const [tableColumns, setTableColumns] = React.useState([]);
 
  // Setup header columns
  React.useEffect(() => {
    if (columns && columns.length > 0) {
      // Check if column has already sortByOrder
      const hasSortByOrder = [...columns].some((column) => column.sortByOrder);

      const orderedColumn = [...columns]
        .map((col, i) => {
          const sortByOrderProp =
            !hasSortByOrder && i === 0 ? { sortByOrder: "asc" } : null;

          if (!col.order) {
            return {
              ...col,
              ...sortByOrderProp,
              order: i + 1,
            };
          }

          return { ...col, ...sortByOrderProp };
        })
        .sort((a, b) => {
          return a.order - b.order;
        });

      setTableColumns(orderedColumn);
    }
  }, [columns]);

  React.useEffect(() => {
    if (tableColumns.length > 0 ) {
      const headerKeys = [...tableColumns].map((col) => col.name);

      const bodyData = [...data];

      const updatedBodyData = [];

      for (let i = 0; i < bodyData.length; i++) {
        const bodyKeys = Object.keys(bodyData[i]);
        let result = {};

        bodyKeys.forEach((bKey) => {
          if (headerKeys.includes(bKey)) {
            // Get header column by key;
            const hColumn = [...tableColumns].find((o) => o.name === bKey);

            result = {
              ...result,
              [bKey]: {
                value: bodyData[i][bKey],
                order: hColumn.order,
                style: {
                  width: hColumn.width,
                },
              },
            };
            return;
          }

          result = {
            ...result,
            [bKey]: {
              value: bodyData[i][bKey],
            },
          };
        });

        updatedBodyData.push(result);
      }

      
    setTableData(updatedBodyData, tableColumns);
    // setTableData(getDefaultSorting(updatedBodyData, tableColumns));
    }
  }, [data, tableColumns]);

 
  const buildRows = React.useMemo(() => {
    if (!tableData.length) {
      return null;
    }

    return tableData.map((row, index) => {
      return renderRows && renderRows(row, index);
    });
  }, [tableData, renderRows]);

  const handleSort = (name, sortOrder) => {
    const sortedData = [...tableData].sort((a, b) => {
      if (a[name].value === null) return 1;
      if (b[name].value === null) return -1;
      if (a[name].value === null && b[name].value === null) return 0;

      return (
        a[name].value
          .toString()
          .localeCompare(b[name].value.toString(), "en", { numeric: true }) *
        (sortOrder === "asc" ? 1 : -1)
      );
    });

    setTableData(sortedData);
  };

  return { handleSort, buildRows, tableColumns };
};

function getDefaultSorting(defaultTableData, columns) {
  if (!columns.length) {
    return defaultTableData;
  }

  const sorted = [...defaultTableData].sort((a, b) => {
    const filterColumn = columns.find((column) => column.sortByOrder);

    let { name = null, sortByOrder = "asc" } = { ...filterColumn };

    if (a[name]?.value === null) return 1;
    if (b[name]?.value === null) return -1;
    if (a[name]?.value === null && b[name]?.value === null) return 0;

    const ascending = a[name].value
      .toString()
      .localeCompare(b[name].value.toString(), "en", {
        numeric: true,
      });

    return sortByOrder === "asc" ? ascending : -ascending;
  });
  return sorted;
}

export default useTableData;
