import * as React from "react";
import { memo } from "react";
const SvgIconMessage = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16.431 14.5"
    {...props}
  >
    <g id="noun-chat-1680224" transform="translate(-16 -972.36)">
      <path
        id="Path_1284"
        data-name="Path 1284"
        d="M16.446,986.858a.483.483,0,0,1-.446-.483V975.259a2.9,2.9,0,0,1,2.9-2.9H29.532a2.9,2.9,0,0,1,2.9,2.9v5.316a2.9,2.9,0,0,1-2.9,2.9H20.538l-3.738,3.27a.483.483,0,0,1-.355.113Zm.521-1.548,3.066-2.681a.484.484,0,0,1,.317-.121h9.182a1.918,1.918,0,0,0,1.933-1.933v-5.316a1.918,1.918,0,0,0-1.933-1.933H18.9a1.918,1.918,0,0,0-1.933,1.933v10.05Zm3.141-6.184a1.208,1.208,0,1,1,1.208-1.208A1.208,1.208,0,0,1,20.108,979.126Zm4.108,0a1.208,1.208,0,1,1,1.208-1.208A1.208,1.208,0,0,1,24.216,979.126Zm4.108,0a1.208,1.208,0,1,1,1.208-1.208A1.208,1.208,0,0,1,28.323,979.126Z"
        transform="translate(0)"
        fill="currentColor"
      />
    </g>
  </svg>
);
const Memo = memo(SvgIconMessage);
export default Memo;
