import styled from "styled-components";
import BaseLayout from "../../Components/BaseLayout";

export const MessageLayout = styled(BaseLayout)``;

export const MessageHeader = styled.div`
  display: block;
  margin-left: var(--layout-spacing-minus-8);
  margin-right: var(--layout-spacing-minus-8);

  .message__header {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;

    .start,
    .center,
    .end {
      display: flex;
      align-items: center;
    }

    .start {
      flex: 1;
      min-width: 0px;
      align-items: center;

      .back__button {
        --icon-button-icon-size: var(--layout-size-12);
        --icon-button-icon-color: var(--font-tint-04);
        margin-right: var(--layout-spacing-4);

        &:hover {
          --icon-button-icon-color: var(--font-default-black);
        }
      }

      .message__title {
        min-width: 0px;
        flex: 1;
        display: flex;
        align-items: stretch;
        font-weight: 500;
        color: var(--font-default-black);
        margin-right: var(--layout-spacing-8);
        /* cursor: pointer; */
        transition: var(--animate-1);

        &:hover {
          /* opacity: 0.7; */
        }
      }
    }

    .center {
      justify-content: flex-end;

      .chat__members {
        --more-btn-size: var(--layout-size-28);
        padding-left: var(--layout-spacing-12);

        .btn__more__avatar {
          margin-left: var(--layout-spacing-8);
        }
      }
    }

    .end {
      justify-content: flex-end;

      .more__button {
        --icon-button-icon-size: var(--layout-size-12);
      }

      .group__dropdown__btn {
        .dropdown__icon {
          color: var(--font-tint-04);
          --icon-size: var(--layout-size-16);
          --icon-font-size: var(--layout-size-16);
        }
      }
    }
  }
`;

export const MessageContent = styled.div`
  display: flex;
  flex: 1;
  min-height: 0px;
  background: var(--color-neutral-20, #f5f5f7);
`;
