import React from "react";
import { styleClass } from "../../../../../../../utility/common";
import "./styles.scss";

const MenuItem = (props) => {
  const { children, title, iconClass } = props;
  return (
    <div className="setting__menu__item">
      <div className="menu__header">
        <i
          className={styleClass(
            "menu__header--icon icon",
            iconClass
          )}
        />
        <div className="menu__header--title">{title}</div>
      </div>
      <div className="menu__contents">
        <ul>{children}</ul>
      </div>
    </div>
  );
};

export default MenuItem;
