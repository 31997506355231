import styled from "styled-components";
import { StyledBoxButton } from "../../../../styled/Common/Buttons";

export const Button = styled(StyledBoxButton)`
  --icon-button-size: ${({ btnSize }) =>
    btnSize ? `var(--layout-size-${btnSize})` : `var(--layout-size-24)`};

  --icon-button-icon-size: ${({ btnIconSize }) =>
    btnIconSize
      ? `var(--layout-size-${btnIconSize})`
      : `var(--layout-size-16)`};

  --icon-button-icon-color: ${({ btnIconColor }) =>
    btnIconColor ? `${btnIconColor}` : `var(--font-default-black)`};

  width: var(--icon-button-size);
  height: var(--icon-button-size);
  min-width: var(--icon-button-size);
  min-height: var(--icon-button-size);
  transition: var(--animate-1);
  transition: var(--animate-1);

  .button__icon {
    --icon-size: var(--icon-button-icon-size);
    --icon-font-size: var(--icon-button-icon-size);
    color: var(--icon-button-icon-color);
    transition: var(--animate-1);
  }

  ${({ hover }) => {
    if (hover) {
      if (typeof hover === "string") {
        return `
          &:hover {
            --icon-button-icon-color: ${hover} !important;
          }
        `;
      }

      return `
        &:hover {
          opacity:0.6;
        }
      `;
    }
  }};
`;
