import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.1409 13.0001C10.1409 13.2632 9.92325 13.4764 9.6547 13.4764H6.7374C6.46885 13.4764 6.25119 13.2632 6.25119 13.0001C6.25119 12.7371 6.46885 12.5239 6.7374 12.5239H9.6547C9.92325 12.5239 10.1409 12.7371 10.1409 13.0001ZM12.3775 9.28515H6.7374C6.46885 9.28515 6.25119 9.49837 6.25119 9.76143C6.25119 10.0245 6.46885 10.2377 6.7374 10.2377H12.3775C12.6461 10.2377 12.8637 10.0245 12.8637 9.76143C12.8637 9.49837 12.6461 9.28515 12.3775 9.28515ZM12.572 6.42747H6.7374C6.46885 6.42747 6.25119 6.64069 6.25119 6.90375C6.25119 7.16682 6.46885 7.38003 6.7374 7.38003H12.572C12.8406 7.38003 13.0582 7.16682 13.0582 6.90375C13.0582 6.64069 12.8406 6.42747 12.572 6.42747ZM15 4.80812V14.9053C15 15.6915 14.347 16.3311 13.5444 16.3311H5.57045C4.76782 16.3311 4.11485 15.6915 4.11485 14.9053V13.2859H2.33919C1.60075 13.2859 1 12.6974 1 11.9741V1.64293C1 0.919592 1.60074 0.331116 2.33919 0.331116H10.552C11.2905 0.331116 11.8912 0.919576 11.8912 1.64293V3.38231H13.5444C14.347 3.38231 15 4.02189 15 4.80812ZM4.11491 12.3333V4.80812C4.11491 4.02189 4.76789 3.38226 5.57051 3.38226H10.9189V1.64289C10.9189 1.44475 10.7544 1.28363 10.5521 1.28363H2.3393C2.13703 1.28363 1.97255 1.44474 1.97255 1.64289V11.974C1.97255 12.1722 2.13702 12.3333 2.3393 12.3333L4.11491 12.3333ZM14.0337 4.80812C14.0337 4.54375 13.8144 4.32885 13.5445 4.32885H5.57051C5.30063 4.32885 5.08125 4.54374 5.08125 4.80812V14.9053C5.08125 15.1696 5.30062 15.3845 5.57051 15.3845H13.5445C13.8143 15.3845 14.0337 15.1696 14.0337 14.9053V4.80812Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
