import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00975 2.25163H7.57751C7.7214 2.25163 7.83881 2.13422 7.83881 1.99034C7.83881 1.84645 7.7214 1.72904 7.57751 1.72904H6.00975C5.86587 1.72904 5.74846 1.84628 5.74846 1.99034C5.74846 2.1344 5.86587 2.25163 6.00975 2.25163Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.36139 0.16128H5.22587C4.9374 0.16128 4.70328 0.395225 4.70328 0.683868V15.3163C4.70328 15.6048 4.9374 15.8389 5.22587 15.8389H8.36139C8.65004 15.8389 8.88381 15.6048 8.88381 15.3163V0.683868C8.88381 0.395225 8.65004 0.16128 8.36139 0.16128ZM8.36139 15.3163H5.22587V0.683868H8.36139V15.3163Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00975 3.2968H7.57751C7.7214 3.2968 7.83881 3.1794 7.83881 3.03551C7.83881 2.89162 7.7214 2.77422 7.57751 2.77422H6.00975C5.86587 2.77422 5.74846 2.89162 5.74846 3.03551C5.74846 3.1794 5.86587 3.2968 6.00975 3.2968Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00975 4.34198H7.57751C7.7214 4.34198 7.83881 4.22457 7.83881 4.08068C7.83881 3.9368 7.7214 3.81939 7.57751 3.81939H6.00975C5.86587 3.81939 5.74846 3.9368 5.74846 4.08068C5.74846 4.22457 5.86587 4.34198 6.00975 4.34198Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.79363 14.2711C7.37074 14.2711 7.83881 13.8031 7.83881 13.226C7.83881 12.6487 7.37074 12.1808 6.79363 12.1808C6.21652 12.1808 5.74846 12.6487 5.74846 13.226C5.74846 13.8031 6.21652 14.2711 6.79363 14.2711ZM6.79363 12.7034C7.08227 12.7034 7.31605 12.9373 7.31605 13.226C7.31605 13.5144 7.08227 13.7485 6.79363 13.7485C6.50516 13.7485 6.27105 13.5144 6.27105 13.226C6.27105 12.9373 6.50516 12.7034 6.79363 12.7034Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.30647 2.25163H2.87423C3.01811 2.25163 3.13552 2.13422 3.13552 1.99034C3.13552 1.84645 3.01811 1.72904 2.87423 1.72904H1.30647C1.16258 1.72904 1.04517 1.84628 1.04517 1.99034C1.04517 2.1344 1.16258 2.25163 1.30647 2.25163Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.65811 0.16128H0.522587C0.234119 0.16128 0 0.395225 0 0.683868V15.3163C0 15.6048 0.234119 15.8389 0.522587 15.8389H3.65811C3.94675 15.8389 4.1807 15.6048 4.1807 15.3163V0.683868C4.1807 0.395225 3.94675 0.16128 3.65811 0.16128ZM3.65811 15.3163H0.522587V0.683868H3.65811V15.3163Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.30647 3.2968H2.87423C3.01811 3.2968 3.13552 3.1794 3.13552 3.03551C3.13552 2.89162 3.01811 2.77422 2.87423 2.77422H1.30647C1.16258 2.77422 1.04517 2.89162 1.04517 3.03551C1.04517 3.1794 1.16258 3.2968 1.30647 3.2968Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.30647 4.34198H2.87423C3.01811 4.34198 3.13552 4.22457 3.13552 4.08068C3.13552 3.9368 3.01811 3.81939 2.87423 3.81939H1.30647C1.16258 3.81939 1.04517 3.9368 1.04517 4.08068C1.04517 4.22457 1.16258 4.34198 1.30647 4.34198Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.09035 14.2711C2.66746 14.2711 3.13552 13.8031 3.13552 13.226C3.13552 12.6487 2.66746 12.1808 2.09035 12.1808C1.51306 12.1808 1.04517 12.6487 1.04517 13.226C1.04517 13.8031 1.51306 14.2711 2.09035 14.2711ZM2.09035 12.7034C2.37899 12.7034 2.61294 12.9373 2.61294 13.226C2.61294 13.5144 2.37899 13.7485 2.09035 13.7485C1.80188 13.7485 1.56776 13.5144 1.56776 13.226C1.56776 12.9373 1.80188 12.7034 2.09035 12.7034Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7719 2.47495L12.3158 2.20268C12.4578 2.1776 12.5527 2.04207 12.5278 1.89993C12.5027 1.75796 12.3672 1.66302 12.2252 1.68811L10.6813 1.96037C10.5394 1.98546 10.4443 2.12098 10.4692 2.26313C10.4942 2.4051 10.6298 2.50003 10.7719 2.47495Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.871 14.842L13.3302 0.431981C13.2802 0.147867 13.0089 -0.042006 12.7246 0.00798816L9.63686 0.552524C9.35257 0.602692 9.16287 0.873741 9.21286 1.15785L11.7537 15.568C11.804 15.8521 12.0749 16.042 12.3594 15.992L15.4473 15.4473C15.7316 15.3973 15.9211 15.1261 15.871 14.842ZM12.2686 15.4773L9.72779 1.06727L12.8156 0.522736L15.3567 14.9329L12.2686 15.4773Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9534 3.50427L12.4973 3.232C12.6393 3.20674 12.7342 3.07139 12.7093 2.92925C12.6844 2.78711 12.5487 2.69234 12.4067 2.71725L10.8628 2.98952C10.7209 3.01461 10.6258 3.15013 10.6508 3.29227C10.6758 3.43442 10.8113 3.52935 10.9534 3.50427Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5883 3.74675L11.044 4.01902C10.902 4.0441 10.8073 4.17963 10.8322 4.3216C10.8573 4.46374 10.9928 4.5585 11.1348 4.53359L12.6787 4.26132C12.8206 4.23607 12.9156 4.10072 12.8907 3.95857C12.8657 3.81643 12.7302 3.72167 12.5883 3.74675Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4203 13.328C12.5205 13.8966 13.0626 14.2758 13.6312 14.1758C14.1996 14.0755 14.5791 13.5334 14.4788 12.965C14.3786 12.3964 13.8364 12.017 13.2681 12.1172C12.6996 12.2175 12.3202 12.7595 12.4203 13.328ZM13.3587 12.632C13.6428 12.5818 13.9141 12.7715 13.9642 13.0559C14.0144 13.3401 13.8243 13.6113 13.5402 13.6613C13.2559 13.7113 12.9849 13.5216 12.9347 13.2373C12.8849 12.9532 13.0746 12.6819 13.3587 12.632Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
