import React from "react";
import AnimateLoader from "../../../AnimateLoader";

import {
  StyledModalBackdrop,
  StyledModalContent,
  StyledModalOuter,
  LoaderWrapper,
} from "./styled";

const BaseModal = ({
  children,
  visibility,
  onRootClose,
  outerClassName,
  contentClassName,
  cssBackdropStyle,
  isLoading,
  ...otherProps
}) => {
  return (
    <StyledModalBackdrop
      cssStyle={cssBackdropStyle}
      visibility={visibility}
      onClick={onRootClose}
      {...otherProps}
    >
      <StyledModalOuter className={["outer", outerClassName].join(" ")}>
        <StyledModalContent
          className={["modal__content", contentClassName].join(" ")}
        >
          {isLoading ? (
            <LoaderWrapper>
              <AnimateLoader />
            </LoaderWrapper>
          ) : null}

          {children}
        </StyledModalContent>
      </StyledModalOuter>
    </StyledModalBackdrop>
  );
};

export default BaseModal;
