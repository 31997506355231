import React from "react";
import UIButton from "../../../common/components/Buttons/UIButton";
import DocIcon from "../../../common/components/icons/Fill/document";
import { OnboardingContent, OnboardingItemElement } from "./styled";
import { NOTIFICATION_TYPE } from "..";

const OnboradingItem = ({
  projectLogo,
  projectName,
  tenantName,
  userCurrentStatusInProject,
  roleInProject,
  fromUser,
  notificationHeader,
  notificationBody,
  notificationType,
  handleOnClick,
}) => {
  return (
    <OnboardingItemElement
      src={projectLogo}
      title={projectName}
      titleSubItem={[tenantName]}
      statusText={userCurrentStatusInProject}
      content={
        <OnboardingContent>
          <div className="left-content">
            <div>
              <div className="sm__text ">Join as the</div>
              <div className="base__text ">{roleInProject}</div>
            </div>
            {notificationType === NOTIFICATION_TYPE.INVITATION &&
              notificationBody &&
              notificationBody !== "" && (
                <div className="sm__text text__gray">
                  {(() => {
                    const splitBody = notificationBody.includes("{fromUser}")
                      ? notificationBody.split("{fromUser}")
                      : notificationBody.split(fromUser);
                    return (
                      <>
                        {splitBody[0]}
                        <span className="text__secondary">{fromUser}</span>
                        {splitBody[1]}
                      </>
                    );
                  })()}
                </div>
              )}

            {notificationType === NOTIFICATION_TYPE.CONTRACT && (
              <div className="sm__text text__gray">{notificationBody}</div>
            )}
          </div>
          <div className="right-content">
            <UIButton
              startIcon={<DocIcon />}
              variant={UIButton.Variant.Success}
              size={UIButton.Sizes.Small}
              onClick={handleOnClick}
            >
              View
            </UIButton>
          </div>
        </OnboardingContent>
      }
    />
  );
};

export default OnboradingItem;
