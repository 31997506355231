import styled from "styled-components";
import { fontStyle } from "../../../../../styled/utility";
import { ElementStatusType } from ".";
import IconButton from "../../../../../common/components/Buttons/IconButton";
import { BaseTable } from "../../../../../common/components/UI/BaseTable";

export const TableWrapper = styled.div`
  margin-left: var(--layout-spacing-minus-2);
  margin-right: var(--layout-spacing-minus-2);
  max-width: calc(100% + var(--layout-spacing-4));
`;

export const Table = styled(BaseTable)`
  /* Table head styles */
  thead {
    tr {
      th {
        padding-inline: var(--layout-spacing-2);
        padding-block: var(--layout-spacing-2);

        > div {
          ${fontStyle("label-semi-bold")};
          color: var(--color-accent-500, #346e73);
          padding: var(--layout-spacing-8, 8px) var(--layout-spacing-16);
          border-radius: var(--layout-borderRadius-4, 4px);
          background: var(--color-accent-75, #bae1e4);
        }

        &.can__sortable {
          cursor: pointer;
        }

        .sort__icons {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: var(--layout-size-12);
          height: var(--layout-size-12);
          flex-direction: column;
          margin-left: var(--layout-spacing-8);

          .arrow-up,
          .arrow-down {
            --icon-button-icon-color: var(--font-tint-04);
          }

          .arrow-up {
            margin-bottom: -1px;
          }

          .arrow-down {
            margin-top: -1px;
          }
        }

        &.asc {
          .sort__icons .arrow-up {
            --icon-button-icon-color: var(--color-primary-300);
          }
        }

        &.desc {
          .sort__icons .arrow-down {
            --icon-button-icon-color: var(--color-primary-300);
          }
        }
      }
    }
  }

  /* Table body styles */
  tbody {
    tr {
      td {
        ${fontStyle("x-small-medium")};
        font-weight: 600;
        color: var(--color-primary-300, #023747);
        padding-inline: var(--layout-spacing-2);
        padding-block: var(--layout-spacing-2);

        > div {
          padding: var(--layout-spacing-8) var(--layout-spacing-16);
          border-radius: var(--layout-border-radius-4);
          background: var(--color-neutral-20, #f5f5f7);
        }
      }
    }
  }

  ${({ $isRowPacked }) => {
    if ($isRowPacked) {
      return `
        thead tr th {
          padding-block: var(--layout-spacing-4);
        }

        tbody tr {
          td {
            padding-block: 0;
             
            > div {
              border-radius: 0;
            }
          }

          &:first-child {
            td {
              > div {
                border-top-left-radius: var(--layout-border-radius-4);
                border-top-right-radius: var(--layout-border-radius-4);
              }
            }
          }

          &:last-child {
            td {
              > div {
                border-bottom-left-radius: var(--layout-border-radius-4);
                border-bottom-right-radius: var(--layout-border-radius-4);
              }
            }
          }

        }
      `;
    }
  }};

  .table__actions {
    display: flex;
    justify-content: inherit;
  }

  .table__action__btn {
    --icon-button-icon-color: var(--font-tint-04);

    &:hover {
      --icon-button-icon-color: var(--color-primary-100);
    }
  }

  .table__row__expander {
    --icon-button-icon-color: var(--color-accent-300);
    margin-right: var(--layout-spacing-8);

    .button__icon {
      transform: rotate(-90deg);
    }

    &.expanded {
      --icon-button-icon-color: var(--color-secondary-300);

      .button__icon {
        transform: rotate(0deg);
      }
    }
  }

  .table__empty__row {
    .empty__block {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex: 1;
      padding-block: var(--layout-spacing-48);
      margin: var(--layout-spacing-32);
      background-color: var(--layout-background-white);
      border-radius: var(--layout-border-radius-8);
      border: 1px solid var(--color-accent-300, #56b5bc);

      &__caption {
        ${fontStyle("base-medium")};
        color: var(--font-color-shade-primary);
        font-weight: 500;
      }

      &__message {
        ${fontStyle("x-small-regular")};
        color: var(--font-tint-04);
        font-weight: 400;
      }
    }
  }
`;

export const Status = styled.div`
  --status-color: #ccc;

  display: flex;
  align-items: center;
  gap: var(--layout-spacing-8);

  ${({ $type }) => {
    if ($type === ElementStatusType.Active) {
      return `--status-color:var(--color-success-300, #79C131);`;
    }
    if ($type === ElementStatusType.Deactivate) {
      return `--status-color:var(--color-danger-300);`;
    }

    // Default color
    return `
      --status-color: #ccc;
    `;
  }}

  span {
    display: block;

    &:nth-child(1) {
      width: var(--layout-size-12);
      height: var(--layout-size-12);
      min-width: var(--layout-size-12);
      min-height: var(--layout-size-12);
      border-radius: 999px;
      display: block;

      background-color: var(--status-color);
    }

    &:nth-child(2) {
      color: var(--color-primary-300, #023747);
    }
  }
`;

export const ToggleButton = styled(IconButton)`
  --icon-button-icon-color: var(--color-neutral-40);
  transition: none;

  .button__icon {
    transition: none !important;
  }

  ${({ $active, $type, $noAction }) => {
    if ($noAction) {
      return `
        cursor: auto !important;
      `;
    }

    if ($type === "correct" && $active) {
      return `
          --icon-button-icon-color:var(--color-primary-300);
      `;
    }

    if ($type === "close" && $active) {
      return `
        --icon-button-icon-color:var(--color-danger-300);
      `;
    }
  }};
`;

export const GapBlock = styled.div`
  ${({ $depth = 0 }) => `--depth: ${$depth}`};

  margin-left: calc(var(--depth) * 24px);
`;

export const ChipItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--layout-spacing-4);
  border-radius: var(--layout-borderRadius-4, 4px);
  background: var(--color-primary-300, #023747);
  gap: var(--layout-spacing-4);

  &:hover {
    background: var(--color-primary-200);
  }

  .val {
    ${fontStyle("x-small-semi-bold")};
    font-weight: 600;
    color: var(--color-neutral-10, #fafafb);
    padding: var(--layout-spacing-2) 0;
  }

  .close__btn {
    --icon-button-icon-color: var(--color-white);
    margin-right: var(--layout-spacing-minus-2);
  }
`;

export const ChipItems = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--layout-spacing-8);
`;

export const Actions = styled.div`
  ${({ $gap = 4 }) => {
    if ($gap === "auto") {
      return `
        --gap:var(--layout-spacing-4);
        justify-content: space-between !important;
      `;
    }

    return `--gap:var(--layout-spacing-${$gap})`;
  }};

  display: flex;
  align-items: center;
  gap: var(--gap);
`;

export const TextButton = styled.div`
  ${({ $type }) => {
    switch ($type) {
      case "secondary": {
        return `
          --text-color: var(--color-secondary-300, #EF8B6D);
        `;
      }

      default: {
        return `
          --text-color: var(--color-accent-300, #56B5BC);
        `;
      }
    }
  }};

  ${fontStyle("x-small-semi-bold")};
  font-weight: 600;
  color: var(--text-color);
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const Link = styled.a`
  display: block;
  max-width: 100%;

  &:hover {
    text-decoration: underline;
  }

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    max-width: 100%;
  }
`;
