import styled from "styled-components";
import { fontStyle } from "../../../../../../../../../styled/utility";
import { getContrastYIQ } from "../../../../../../../../../utility";

export const Container = styled.div`
  --invertTextColor: ${({ $activityBgColor }) =>
    getContrastYIQ($activityBgColor)};

  --invertIconColor: ${({ $activityBgColor }) => {
    return getContrastYIQ(
      $activityBgColor,
      `var(--font-tint-01)`,
      `var(--font-tint-04)`
    );
  }};

  position: absolute;
  width: 100%;
  min-height: var(--column-cell-height);
  pointer-events: auto;
  padding: 0 var(--layout-spacing-8);

  &[data-selected="true"] {
    .data__cell__inner {
      --text-color: rgba(0, 0, 0, 0.5);

      .center {
        background-color: rgba(14, 101, 235, 0.2);
        color: var(--text-color) !important;

        .right .icon__video {
          --icon-button-icon-color: var(--text-color) !important;
        }
        /* border: 1px solid rgba(14, 101, 235, 0.3); */
      }
    }
  }

  &.is__cut {
    .data__cell__inner {
      .center {
        opacity: 0.3;
      }
    }
  }

  ${({ $dataIndex }) => {
    if ($dataIndex) {
      return `
        top: calc((${$dataIndex} * var(--column-cell-height)) + var(--layout-spacing-4));
      `;
    }
  }}

  .data__cell__inner {
    display: flex;
    gap: var(--layout-spacing-4);
    min-height: calc(var(--column-cell-height) - 2px);
    margin-top: 1px;

    .start {
      display: flex;
      align-items: center;
      height: calc(var(--column-cell-height) - 2px);

      .icon__warning {
        --icon-button-icon-color: var(--color-danger-200);
      }
    }

    .center {
      flex: 1;
      display: flex;
      align-items: center;
      border-radius: var(--layout-border-radius-4);
      /* border: 1px solid var(--color-neutral-30, #ebecef); */
      background: ${({ $activityBgColor }) =>
        $activityBgColor ? `${$activityBgColor}` : "#fdf3f0"};
      gap: var(--layout-spacing-8);
      padding-inline: var(--layout-spacing-8);
      min-width: 0;

      .left {
        min-width: 0;
        display: flex;
        padding: var(--layout-spacing-2) 0;
        flex-grow: 1;
        align-items: center;
        color: var(--invertTextColor);

        span {
          ${fontStyle("x-small-semi-bold")};
          display: inline-block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 100%;
          min-width: 0;
          font-weight: 500;
        }
      }
      .right {
        display: flex;
        align-items: center;
        gap: var(--layout-spacing-8);

        .icon__video {
          --icon-button-icon-color: ${({ $videoIconColor }) => {
            return $videoIconColor
              ? `#${$videoIconColor}`
              : `var(--invertIconColor)`;
          }};

          /* --icon-button-icon-color: ${({ $videoIconColor }) => {
            return $videoIconColor
              ? `#${$videoIconColor}`
              : `var(--font-tint-04)`;
          }}; */
        }
      }
    }

    .end {
      display: flex;
      align-items: center;

      .flags {
        display: flex;
        align-items: center;
      }
    }
  }
`;

export const FlagContainer = styled.div`
  width: var(--layout-size-16);
  height: var(--layout-size-28);
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ $flagIconColor }) =>
    $flagIconColor ? $flagIconColor : `var(--font-tint-04)`};
  position: relative;

  &:not(:first-child) {
    margin-left: var(--layout-spacing-minus-4);
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    ${fontStyle("x-small-semi-bold")};
    color: var(--font-default-white);
    text-transform: uppercase;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  svg {
    width: inherit;
    height: inherit;
  }
`;

export const MoreButton = styled.button`
  --btn-border-color: var(--color-info-75, #a3dcfd);
  --btn-text-color: var(--color-info-500, #126898);

  ${fontStyle("x-small-regular")};
  width: var(--layout-size-24);
  height: var(--layout-size-24);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--btn-text-color);
  position: relative;
  border-radius: 50%;
  border: 2px solid var(--btn-border-color);
  background-color: var(--layout-background-white);
`;
