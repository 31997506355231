import * as React from "react";
import { memo } from "react";
const SvgFloorEnd = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 16V0H3.06446V14.9335H6.96772V9.95566H13V11.0221H8.03239V15.9999L2 16ZM6.96777 6.0445V0H8.03223V4.97783H13V6.04445L6.96777 6.0445Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgFloorEnd);
export default Memo;
