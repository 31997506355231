import create from "zustand";
import { devtools } from "zustand/middleware";
import API_Globle from "../../../services/api";
import API from "../../../services/api-tenant";
import API_ENDPOINTS from "../../../services/api-endpoints";
import {
  deleteUserRight,
  saveUserRight,
  updateUserRight,
} from "../../../Actions/Setting/Permission";

const dummyData = [
  {
    role: { name: "Supun", id: 1 },
    rights: {
      create: false,
      edit: false,
      delete: false,
      view: false,
      execute: false,
    },
    rightAction: "121",
    action: "121",
    id: "121",
  },
];

const rightOptions = [
  { label: "Create", value: 1 },
  { label: "View", value: 2 },
];

const userOptions = [
  { label: "HR", value: 1 },
  { label: "Admin", value: 2 },
  { label: "Supper Admin", value: 3 },
];

const moduleOptions = [
  { label: "Setting", value: 1 },
  { label: "Project", value: 2 },
  { label: "Crew", value: 3 },
];

let SettingUserRightStore = (set, get) => ({
  isEditMode: false,
  userOptions: [],
  rightOptions: [],
  moduleOptions: [],
  data: [],
  variant: null,
  showForm: true,
  formData: {
    userId: null,
    roleIds: [],
    id: null,
  },

  onToggleForm: () => set((state) => ({ showForm: !state.showForm })),

  setVariant: (val) => {
    set({
      variant: val,
      isEditMode: false,
      showForm: false,
      formData: {
        userId: null,
        roleIds: [],
        id: null,
      },
    });
  },

  onEdit: (id) => {
    // Get selected data by id
    const selectedForm = get().data.find((obj) => obj.id === id);

    if (selectedForm) {
      const { user = null, roles = [] } = selectedForm;

      // update state
      set({
        isEditMode: true,
        showForm: true,
        formData: {
          userId: user.id,
          roleIds: roles.map((r) => r.id),
          id,
        },
      });
    }
  },

  onDelete: async (id) => {
    console.log("delete id ", id);
    const row = get().data.find((d) => d.id === id);
    console.log("row", row);
    return new Promise((resolve, reject) => {
      return deleteUserRight(id)
        .then((res) => {
          resolve(res);
          get().onLoadUserRightData(row.action);
        })
        .catch(() => {
          reject();
        });
    });
  },

  onFormSubmit: async (values) => {
    return new Promise((resolve, reject) => {
      let isEdit = get().isEditMode;
      let id = get().formData.id;
      let variant = get().variant;

      console.log("id ", id);
      console.log("isEdit ", isEdit);
      console.log("variant ", variant);
      console.log("values ", values);

      let dataObject = {
        id: 0,
        userId: values.userId,
        rightId: values.rightId,
        permission: get().getPermissionInt(values.rights),
        isDeny: values.isDeny,
        projectId: 0,
      };
      return saveUserRight(dataObject)
        .then((res) => {
          resolve(res);
          get().onLoadUserRightData(values.userId);
        })
        .catch(() => {
          reject();
        });
    });
  },

  clearForm: (callback) => {
    set({
      isEditMode: false,
      formData: {
        userId: null,
        roleIds: [],
        id: null,
      },
    });

    callback && callback();
  },

  onLoadUserRightData: async (id) => {
    // setTimeout(() => {
    //   set({ data: dummyData });
    // }, [2000]);

    let Rights = [];
    const endpoints = new API_ENDPOINTS();

    API.get(endpoints.PERMISSION_USERRIGHT_GET_BY_USER + "/" + id).then(
      (response) => {
        console.log("response: ", response);
        if (response.data.isSuccess === true) {
          API.get(endpoints.PERMISSION_RIGHT_GET_ALL).then((iresponse) => {
            console.log("Right responce: ", iresponse);
            Rights = iresponse.data.result;

            let userRightList = [];
            let i = 0;
            response.data.result.forEach((element) => {
              var user_right = element;
              console.log("user_right", element);
              var right = Rights.find((f) => f.rightId === element.rightId);
              console.log("right", right);
              var permission = get().getPermissionFromInt(
                user_right.permission
              );
              console.log("permission", permission);
              var availablePermission = get().getAvailablePermissionFromInt(
                right.availablePermissions
              );
              console.log("availablePermission", availablePermission);
              var granted_permissions = get().getGrantedPermission(
                availablePermission,
                permission
              );
              console.log("granted_permissions", granted_permissions);

              let obj = {
                role: { name: right.description, id: element.rightId },
                rights: granted_permissions,
                rightAction: element.isDeny,
                action: user_right.userId,
                id: element.id,
              };
              userRightList[i] = obj;
              i++;
            });

            set({ data: userRightList });
          });
        }
      }
    );
  },
  getPermissionFromInt: (intVal) => {
    console.log("intVal", intVal);
    let permission = {
      view: false,
      create: false,
      edit: false,
      delete: false,
      execute: false,
    };

    if (intVal === "1") {
      permission = {
        view: true,
        create: false,
        edit: false,
        delete: false,
        execute: false,
      };
    } else if (intVal === "2") {
      permission = {
        view: false,
        create: true,
        edit: false,
        delete: false,
        execute: false,
      };
    } else if (intVal === "4") {
      permission = {
        view: false,
        create: false,
        edit: true,
        delete: false,
        execute: false,
      };
    } else if (intVal === "8") {
      permission = {
        view: false,
        create: false,
        edit: false,
        delete: true,
        execute: false,
      };
    } else if (intVal === "16") {
      permission = {
        view: false,
        create: false,
        edit: false,
        delete: false,
        execute: true,
      };
    } else if (intVal === "") {
      permission = {
        view: false,
        create: false,
        edit: false,
        delete: false,
        execute: false,
      };
    } else if (intVal === null) {
      permission = {
        view: false,
        create: false,
        edit: false,
        delete: false,
        execute: false,
      };
    } else {
      let binary = Number(intVal).toString(2);
      var charArray = binary.split("");

      let index = 0;
      charArray.forEach((digit) => {
        if (index == 0) {
          permission.view = Boolean(Number(digit));
        }
        if (index == 1) {
          permission.create = Boolean(Number(digit));
        }
        if (index == 2) {
          permission.edit = Boolean(Number(digit));
        }
        if (index == 3) {
          permission.delete = Boolean(Number(digit));
        }
        if (index == 4) {
          permission.execute = Boolean(Number(digit));
        }
        index++;
      });
    }

    return permission;
  },
  getAvailablePermissionFromInt: (intVal) => {
    console.log("intVal", intVal);
    let permission = {};

    if (intVal === "1") {
      permission = { view: true };
    } else if (intVal === "2") {
      permission = { create: true };
    } else if (intVal === "4") {
      permission = { edit: true };
    } else if (intVal === "8") {
      permission = { delete: true };
    } else if (intVal === "16") {
      permission = { execute: true };
    } else if (intVal === "") {
      permission = {};
    } else if (intVal === null) {
      permission = {};
    } else {
      let binary = Number(intVal).toString(2);
      var charArray = binary.split("");

      let index = 0;
      charArray.forEach((digit) => {
        if (index == 0) {
          permission.view = Boolean(Number(digit));
        }
        if (index == 1) {
          permission.create = Boolean(Number(digit));
        }
        if (index == 2) {
          permission.edit = Boolean(Number(digit));
        }
        if (index == 3) {
          permission.delete = Boolean(Number(digit));
        }
        if (index == 4) {
          permission.execute = Boolean(Number(digit));
        }
        index++;
      });
    }

    return permission;
  },
  getGrantedPermission: (availablePermission, permission) => {
    var granted_permissions = {};

    if (availablePermission.hasOwnProperty("view")) {
      granted_permissions.view = permission.view;
    }
    if (availablePermission.hasOwnProperty("create")) {
      granted_permissions.create = permission.create;
    }
    if (availablePermission.hasOwnProperty("edit")) {
      granted_permissions.edit = permission.edit;
    }
    if (availablePermission.hasOwnProperty("delete")) {
      granted_permissions.delete = permission.delete;
    }
    if (availablePermission.hasOwnProperty("execute")) {
      granted_permissions.execute = permission.execute;
    }

    return granted_permissions;
  },
  getPermissionInt: (rights) => {
    let permissions = 0;
    let permissions_string = "";

    if (rights.view == true) {
      permissions = permissions + 1;
    }
    if (rights.create == true) {
      permissions = permissions + 2;
    }
    if (rights.edit == true) {
      permissions = permissions + 4;
    }
    if (rights.delete == true) {
      permissions = permissions + 8;
    }
    if (rights.execute == true) {
      permissions = permissions + 16;
    }

    if (permissions > 0) {
      permissions_string = permissions.toString();
    }

    return permissions_string;
  },
  onLoadUserOptions: async () => {
    //set({ userOptions });
    let USER = JSON.parse(localStorage.getItem("user"));
    let tenantId = USER.result.tenantId;
    const endpoints = new API_ENDPOINTS();

    API_Globle.get(endpoints.PERMISSION_GET_USER_LIST + "/" + tenantId).then(
      (response) => {
        console.log("response: ", response);
        if (response.data.isSuccess === false) {
          let userList = [];
          let i = 0;
          response.data.result.forEach((element) => {
            let obj = {
              label: element.givenName,
              value: element.id,
            };
            userList[i] = obj;
            i++;
          });

          set({ userOptions: userList });
        }
      }
    );
  },

  onLoadModuleOptions: async () => {
    //set({ moduleOptions });
    const endpoints = new API_ENDPOINTS();

    API.get(endpoints.PERMISSION_MODULE_GETALL).then((response) => {
      console.log("response: ", response);
      if (response.data.isSuccess === true) {
        let moduleList = [];
        let i = 0;
        response.data.result.forEach((element) => {
          let obj = {
            label: element.moduleDescription,
            value: element.moduleId,
          };
          moduleList[i] = obj;
          i++;
        });

        set({ moduleOptions: moduleList });
      }
    });
  },

  onLoadRightOptions: async (module = undefined) => {
    set({ rightOptions: [] });

    // setTimeout(() => {
    //   set({ rightOptions });
    // }, 2000);

    const endpoints = new API_ENDPOINTS();

    API.get(
      endpoints.PERMISSION_RIGHT_GET_BY_MODULE + "?ModuleId=" + module.value
    ).then((response) => {
      console.log("response: ", response);
      if (response.data.isSuccess === true) {
        let rightList = [];
        let i = 0;
        response.data.result.forEach((element) => {
          let obj = {
            label: element.description,
            value: element.rightId,
          };
          rightList[i] = obj;
          i++;
        });

        console.log("rightOptions ", rightList);
        set({ rightOptions: rightList });
      }
    });
  },

  onPermissionChanged: (values, rowId) => {
    const updateData = [...get().data].map((row) => {
      if (row.id === rowId) {
        return {
          ...row,
          rights: values,
        };
      }

      return row;
    });

    set({ data: updateData });
  },

  onAccessOrDenied: (rowId, actionType) => {
    const genPermissionObj = (obj, state) => {
      let result = {};

      for (let key in obj) {
        result = {
          ...result,
          [key]: state,
        };
      }
      return result;
    };

    const updateData = get().data.map((row) => {
      if (row.id === rowId) {
        return {
          ...row,
          rights:
            actionType === "Access"
              ? genPermissionObj(row.rights, true)
              : genPermissionObj(row.rights, false),
        };
      }

      return row;
    });

    set({ data: updateData });
  },

  onUpdateTableRowData: async (id) => {
    return new Promise((resolve, reject) => {
      const row = get().data.find((d) => d.id === id);

      console.log("row id ", row);

      let dataObject = {
        id: row.id,
        userId: row.action,
        rightId: row.role.id,
        permission: get().getPermissionInt(row.rights),
        isDeny: row.rightAction,
        projectId: 0,
      };
      return updateUserRight(dataObject)
        .then((res) => {
          resolve(res);
          get().onLoadUserRightData(row.action);
        })
        .catch(() => {
          reject();
        });
    });
  },
});

SettingUserRightStore = devtools(SettingUserRightStore);

export const useUserRightStore = create(SettingUserRightStore);
