import React from "react";
import { AdvItemContainer } from "./styled";

const AdvanceItem = (props) => {
  const {
    title,
    titleSubItem = [],
    statusText,
    src,
    content,
    ...other
  } = props;
  return (
    <AdvItemContainer {...other}>
      <div className="adv-item">
        {src ? (
          <div className="adv-item__left">
            <div className="adv-item__image">
              <img src={src} alt="" />
            </div>
          </div>
        ) : null}

        <div className="adv-item__right adv-item__right">
          <div className="adv-item__head">
            <div className="adv-item__title">
              <span>{title}</span>
              {titleSubItem.map((text, i) => (
                <span className={text ? "subtitle" : null} key={i}>
                  {text}
                </span>
              ))}
            </div>

            {statusText ? (
              <div className="adv-item__status">
                <span>{statusText}</span>
              </div>
            ) : null}
          </div>

          <div className="adv-item__content">{content}</div>
        </div>
      </div>
    </AdvItemContainer>
  );
};

export default AdvanceItem;
