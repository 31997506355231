import React from "react";
import * as Styled from "./styled";
import YearColumn from "./Columns/YearColumn";
import MonthColumn from "./Columns/MonthColumn";
import DayColumn from "./Columns/DayColumn";
import WeekColumn from "./Columns/WeekColumn";
import DateColumn from "./Columns/DateColumn";
import TXDateColumn from "./Columns/TXDateColumn";

const Calendar = () => {
  return (
    <Styled.Container>
      {/* Year column */}
      {/* <YearColumn />

      <MonthColumn /> */}

      <WeekColumn />

      <DayColumn />

      <DateColumn />

      <TXDateColumn />
    </Styled.Container>
  );
};

export default Calendar;
