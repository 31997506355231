import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.0002 3.01077C10.7156 3.01077 12.9176 5.24477 12.9176 7.99973C12.9176 10.7552 10.7154 12.9895 8.0002 12.9895C5.28431 12.9895 3.08243 10.7552 3.08243 7.99973C3.08243 5.24477 5.28431 3.01077 8.0002 3.01077ZM9.36088 0C13.1897 0.665884 16 4.04836 16 7.99973C16 11.9511 13.1905 15.3342 9.36088 16V14.8668C12.5784 14.2134 14.9025 11.3386 14.9025 7.99973C14.9025 4.66145 12.5777 1.7865 9.36088 1.13323V0ZM6.63912 1.13323C3.42216 1.78659 1.09734 4.66145 1.09734 7.99973C1.09734 11.3387 3.42163 14.2135 6.63912 14.8668V16C2.80962 15.3341 0 11.9511 0 7.99973C0 4.04836 2.81016 0.665844 6.63912 0V1.13323ZM6.67162 5.18755H7.43054V10.8119H6.67162V5.18755ZM8.56951 5.18755H9.32842V10.8119H8.56951V5.18755Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
