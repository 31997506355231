import React from "react";
import { IconBlock } from "../../../styled/Common/Icons";
import * as FieldStyled from "./FieldStyled";

const FieldControl = (props, ref) => {
  const {
    inputOuterClass,
    size = "sm",
    startIcon = null,
    endIcon = null,
    isEndIconActive,
    isDisabled,
    children,
  } = props;

  const startIconComponent = React.useMemo(() => {
    if (!startIcon) return null;

    return (
      <IconBlock as="span" className="field__start__icon">
        {startIcon}
      </IconBlock>
    );
  }, [startIcon]);

  const endIconComponent = React.useMemo(() => {
    if (!endIcon) return null;

    return (
      <IconBlock as="span" className="field__end__icon">
        {endIcon}
      </IconBlock>
    );
  }, [endIcon]);

  return (
    <FieldStyled.Container
      ref={ref}
      className={inputOuterClass}
      size={size}
      hasStartIcon={startIcon !== null}
      hasEndIcon={startIcon !== null}
      isDisabled={isDisabled}
      isEndIconActive={isEndIconActive}
    >
      {startIconComponent}
      {children}
      {endIconComponent}
    </FieldStyled.Container>
  );
};

export default React.forwardRef(FieldControl);
