import styled from "styled-components";
import { fontStyle } from "../../../../../../styled/utility";

export const ViewContainer = styled.div`
  flex-direction: column;
  /* flex: 1; */
  /* margin: var(--layout-spacing-12) 0; */

  .list__view__head {
    display: flex;
    align-items: center;
    gap: var(--layout-spacing-8);
    padding: 0 var(--layout-spacing-20);

    .title {
      ${fontStyle("label-semi-bold")};
      color: var(--font-color-shade-primary);
      font-weight: 600;
      min-width: 0px;
      display: flex;
      align-items: stretch;
    }

    .add__button {
      --icon-button-size: var(--layout-size-24);
      --icon-button-icon-size: var(--layout-size-20);
      --icon-button-icon-color: var(--color-accent-400);

      &:hover {
        --icon-button-icon-color: var(--color-accent-300);
      }
    }
  }

  .list__view__content {
    display: flex;
    flex: 1;
    /* margin-top: var(--layout-spacing-24); */

    .inner__content {
      flex: 1;
      /* padding: 0 var(--layout-spacing-20); */

      ${({ isScrollbarEnabled }) => {
        if (isScrollbarEnabled) {
          return `
            overflow-y: auto;
        `;
        }
      }};
    }
  }
`;
