import styled from "styled-components";
import { fontStyle } from "../../../../styled/utility";

export const TooltipWrapper = styled.div`
  max-width: 180px;
  padding: var(--layout-spacing-4) var(--layout-spacing-8);
  background-color: var(--font-default-color);
  border-radius: var(--layout-border-radius-8);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--font-default-white);
  pointer-events: none;
  ${fontStyle("small-medium")};

  z-index: var(--zindex-tooltip);

  #tooltip__arrow,
  #tooltip__arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
  }

  #tooltip__arrow {
    visibility: hidden;
  }

  #tooltip__arrow::before {
    visibility: visible;
    content: "";
    transform: rotate(45deg);
  }

  &[data-popper-placement^="top"] > #tooltip__arrow {
    bottom: -4px;
  }

  &[data-popper-placement^="bottom"] > #tooltip__arrow {
    top: -4px;
  }

  &[data-popper-placement^="left"] > #tooltip__arrow {
    right: -4px;
  }

  &[data-popper-placement^="right"] > #tooltip__arrow {
    left: -4px;
  }
`;
