import React from "react";
import { AvatarListWrapper } from "./styled";
import Avatar from "../../../../components/ui-components/Avatar";

const AvatarList = (props) => {
  const { data = [], maxCount = 5, avatarSize = "size-26", className } = props;

  return (
    <AvatarListWrapper className={className}>
      {data
        ?.reverse()
        ?.slice(0, data?.length > maxCount ? maxCount : data.length)
        ?.map((data, i) => (
          <div
            className="avatar__item"
            data-tooltip-text={data.name || null}
            data-tooltip-placement="bottom"
            key={i}
          >
            <Avatar size={avatarSize} src={data?.src} />
          </div>
        ))}

      {maxCount < data?.length ? (
        <button className="btn__more__avatar">+{data?.length}</button>
      ) : null}
    </AvatarListWrapper>
  );
};

export default AvatarList;
