import React from "react";
import "./styles.scss";

const Logo = (props) => {
  return (
    <div
      className="sys__logo"
      data-tooltip-text="Home"
      data-tooltip-placement="bottom"
    >
      <div className="sys__logo__inner">
        <img src={props.url} alt="Logo" />
      </div>
    </div>
  );
};

export default Logo;
