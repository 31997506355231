import React from "react";
import { StyledSwitch, StyledViewSwitcher } from "./styled";

const ViewSwitcher = ({ defaultViewType = "card", onSwitch }) => {
  const [viewType, setViewType] = React.useState(defaultViewType);

  const onToggle = (type) => {
    setViewType(type);
    onSwitch && onSwitch(type);
  };

  return (
    <StyledViewSwitcher>
      <StyledSwitch
        onClick={() => onToggle("card")}
        isActive={viewType === "card"}
      >
        <div className="icon icon-lp-grid-small" />
      </StyledSwitch>

      <StyledSwitch
        onClick={() => onToggle("list")}
        isActive={viewType === "list"}
      >
        <div className="icon icon-lp-th-list" />
      </StyledSwitch>
    </StyledViewSwitcher>
  );
};

export default ViewSwitcher;
