import React from "react";
import AppLogo from "../../../../../common/components/UI/Logo/Logo";
import * as Styled from "./styled";
import UIButton from "../../../../../common/components/Buttons/UIButton";
import * as LineIcon from "../../../../../common/components/icons/Line";
import * as FillIcon from "../../../../../common/components/icons/Fill";
import Logo from "../../../../../common/components/UI/Logo/Logo";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PublicRoutePath } from "../../../../../routes/paths/public-path";

const Navbar = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate(PublicRoutePath.Login.path);
  };

  const handleSignupClick = () => {
    navigate(PublicRoutePath.CreateAccount.path);
  };

  return (
    <Styled.NavContainer bgColor="10">
      <Styled.LogoWrapper>
        <Logo />
      </Styled.LogoWrapper>
      <div className="nav__btns">
        {window.location.pathname !== PublicRoutePath.Login.path && (
          <UIButton
            variant="primary"
            rounded
            size="sm"
            onClick={handleLoginClick}
          >
            Login
          </UIButton>
        )}
        {window.location.pathname !== PublicRoutePath.CreateAccount.path && (
          <UIButton
            variant="secondary"
            rounded
            size="sm"
            onClick={handleSignupClick}
          >
            Signup
          </UIButton>
        )}
      </div>
    </Styled.NavContainer>
  );
};

export default Navbar;
