import React from "react";
import * as Styled from "./styled";
import { AnimatePresence } from "framer-motion";
import { useScheduleContext } from "../../context/ScheduleContext";
import IconButton from "../../../../../../../../common/components/Buttons/IconButton";
import IconRight from "../../../../../../../../common/components/icons/Line/Arrow/right";
import IconLeft from "../../../../../../../../common/components/icons/Line/Arrow/left";
import IconAdd from "../../../../../../../../common/components/icons/Line/add-1";
import DetailCalendar from "./DetailCalendar";
import { AssigneeProvider } from "./AssigneeContext";
import AssigneeList from "./AssigneeList";

const container = {
  hidden: {
    x: "100%",
    opacity: 0,
    transition: {
      type: "tween",
      delayChildren: 0.3,
    },
  },
  show: {
    x: "0",
    opacity: 1,
    transition: {
      type: "tween",
      delayChildren: 0.3,
    },
  },
};

const AssigneePanel = () => {
  const { dates, isOpenAssigneePanel, toggleAssigneePanel } =
    useScheduleContext();

  const panelHeight = `calc(var(--column-cell-height) * ${dates?.length || 0})`;

  return (
    <AnimatePresence exitBeforeEnter>
      {isOpenAssigneePanel ? (
        <AssigneeProvider>
          <Styled.Container
            className="assignee__area"
            variants={container}
            initial="hidden"
            animate="show"
            exit="hidden"
            key="assigneePanel"
          >
            <div className="inner">
              {/* Top */}
              <div className="top__content">
                <div className="action">
                  <IconButton
                    className="btn-collapse"
                    btnIconSize={16}
                    icon={<IconRight />}
                    onClick={toggleAssigneePanel}
                  />
                  <IconButton
                    className="btn-add-new"
                    btnIconSize={12}
                    icon={<IconAdd />}
                  />
                </div>
              </div>

              {/* Main  */}
              <div className="main__content">
                <div
                  className="assigner__container"
                  style={{ height: panelHeight }}
                >
                  <AssigneeList />
                </div>
              </div>

              {/* Assignee detail with Calendar popover */}
              <DetailCalendar />
            </div>
          </Styled.Container>
        </AssigneeProvider>
      ) : (
        <Styled.AssigneeExpandButton
          title="Expand the assignee panel"
          icon={<IconLeft />}
          onClick={toggleAssigneePanel}
        />
      )}
    </AnimatePresence>
  );
};

export default AssigneePanel;
