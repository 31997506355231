import * as React from "react";
import { memo } from "react";
const SvgContributor = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.96177 13.4413H0V11.6437C0 11.2712 0.173812 10.8988 0.486704 10.6559C2.24221 9.3117 4.13677 8.42104 4.51915 8.2429C4.55389 8.22672 4.58869 8.17811 4.58869 8.14575V7.20645C4.32797 6.80159 4.18889 6.33194 4.11942 5.92708C3.96298 5.9109 3.75443 5.71653 3.54581 5.00403C3.26773 4.08098 3.52845 3.90279 3.78917 3.90279H3.84133C3.65015 3.3846 3.63279 2.88252 3.7718 2.41291C3.91087 1.86229 4.22369 1.42507 4.58869 1.06875C4.81467 0.842019 5.07539 0.647714 5.35348 0.485771C5.57946 0.340008 5.82274 0.226675 6.08346 0.129522C6.292 0.0647342 6.51799 0.0161835 6.76134 0.0161835C6.86562 0.0161835 6.95252 0 7.05679 0C7.64777 0 8.09967 0.145763 8.44729 0.340068C9.0209 0.631539 9.24682 1.0364 9.24682 1.0364C9.24682 1.0364 10.7416 1.13355 10.0811 4.04854C10.2897 4.11333 10.4288 4.37243 10.1854 5.13354C9.95944 5.87846 9.75089 6.07283 9.59445 6.07283C9.55971 6.07283 9.50754 6.05665 9.4728 6.04047C9.40326 6.36435 9.29899 6.7206 9.12517 7.06074V8.14573C9.12517 8.19433 9.15991 8.2267 9.19471 8.24289C9.38589 8.32386 9.9595 8.59913 10.7069 9.00399L10.0116 9.65177L7.68254 11.838C7.5261 11.9838 7.43919 12.1781 7.43919 12.3724C7.43919 12.583 7.5261 12.7773 7.68254 12.9069L8.23871 13.4413H7.76945L5.96177 13.4413ZM8.25609 12.3725L9.38587 13.4413L10.8111 12.1134V16H12.4276V12.1134L13.8529 13.4413L15 12.3887L12.7752 10.3158L11.9409 9.52228L11.6454 9.247L10.6199 10.2025L8.25609 12.3725Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgContributor);
export default Memo;
