import React from "react";
import { IconsList } from "../icons-list";

const useIcon = () => {
  return React.useCallback((name) => {
    if (!name) return IconsList[0].icon;

    const IconComponent = IconsList.find((ic) => ic.name === name);

    return IconComponent?.icon ? IconComponent?.icon : IconsList[0].icon;
  }, []);
};

export default useIcon;
