import React from "react";
import TabPanel from "./TabPanel";

import * as Styled from "./styled";
import { IconBlock } from "../../../../../../../styled/Common/Icons";

const Tabs = ({ children, activeTab, onChange }) => {
  const [selectedTab, setSelectedTab] = React.useState(activeTab);

  React.useEffect(() => {
    if (activeTab) {
      setSelectedTab(activeTab);
    }
  }, [activeTab]);

  const onTabChange = React.useCallback(
    (tabId) => {
      setSelectedTab(tabId);
      onChange && onChange(tabId);
    },
    [onChange]
  );

  const mapChild = React.useMemo(() => {
    const childList = React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return {
          ...child,
          props: {
            ...child.props,
            isActive: selectedTab === child.props.tabId,
            onClick: onTabChange.bind(this, child.props.tabId),
            visibility: child.props.visibility,
          },
        };
      }

      return null;
    });

    return React.Children.toArray(childList);
  }, [children, onTabChange, selectedTab]);

  const renderNavItems = React.useMemo(() => {
    return (
      <ul className="tab__nav--list">
        {mapChild.map((tab, i) => {
          const { title, onClick, tabId, isActive, icon, visibility } =
            tab.props;

          return (
            <li
              key={i}
              onClick={onClick}
              data-tab-id={tabId}
              title={title}
              className={[
                "tab__nav--item",
                visibility ? "visible" : "",
                isActive ? "active" : "",
              ].join(" ")}
            >
              <IconBlock className="icon">{icon}</IconBlock>
              {title}
            </li>
          );
        })}
      </ul>
    );
  }, [mapChild]);

  const renderPanels = React.useMemo(() => {
    return mapChild.map((tabPanel) => {
      return {
        ...tabPanel,
        props: {
          ...tabPanel.props,
          className: [
            "tab__panel",
            tabPanel.props.visibility ? "visible" : "",
            tabPanel.props.isActive ? "active" : "",
          ].join(" "),
        },
      };
    });
  }, [mapChild]);

  return (
    <Styled.TabsWarper>
      <div className="tab__nav">{renderNavItems}</div>

      {/* 
        Tab panel
      */}
      <div className="tab__panels">{renderPanels}</div>
    </Styled.TabsWarper>
  );
};

Tabs.Panel = TabPanel;

export default Tabs;
