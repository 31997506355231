import React from "react";
import useUserData from "../../../../../../hooks/useUserData";
import { WelcomeContainer } from "./styled";
import { PageContainer } from "../../../../../../styled/Common/Container";

const img = `https://t3.ftcdn.net/jpg/03/16/91/28/360_F_316912806_RCeHVmUx5LuBMi7MKYTY5arkE4I0DcpU.jpg`;

const WelcomeWidget = () => {
  const { userInfo } = useUserData();

  const dayStatus = React.useMemo(() => {
    const hours = new Date().getHours();

    var message =
      hours < 12
        ? "Good Morning"
        : hours < 18
        ? "Good Afternoon"
        : "Good Evening";

    return message;
  }, []);

  return (
    <WelcomeContainer $bgImg={img}>
      <PageContainer>
        <div className="dashboard_welcome bg__image">
          <div className="top">
            {dayStatus}{" "}
            <span className="user_name">{userInfo?.displayName}</span>
          </div>
          <div className="bottom">
            You have <span className="noti_count">05 </span> notifications,{" "}
            <span className="milestone_count">04</span> milestones due today
          </div>
        </div>
      </PageContainer>
    </WelcomeContainer>
  );
};

export default WelcomeWidget;
