import React from "react";

import ModalBase from "../../ModalBase";
// import HistoryList from "./HistoryList";
import BaseTab from "../../Tab/BaseTab";
import InformationForm from "./InformationForm";
import GenerateContractForm from "./GenerateContractForm";
import { toast } from "react-toastify";

import "./styles.scss";
import Warning from "../../../assets/img/warning.png";
import { useAppStore } from "../../../../store/AppStore/AppStore";
import { useProfileMissedFields } from "../../../../hooks/useProfileMissedFields";
import { updateProfileDetails } from "../../../../Actions/User";
import {
  contractGenerate,
  getProjectContractRequestInfo,
  setContractDocumentByIds,
} from "../../../../Actions/Project";
import { createDocument, generateUserDoc } from "../../../../Actions/Templates";
import { VIEW_CONTRACT_SOURCES } from "../../../../constant/Status";
import useGeneratePath from "../../../../hooks/useGeneratePath";
import Alert from "../../../../utility/alert";
import Button, { BUTTON_ICONS } from "../../Buttons/Button";
import { TEMPLATE_TYPES } from "../../../../constant/System";
import { useNavigate } from "react-router-dom";

const projectDurationState = {
  start: "",
  end: "",
};

const GenerateContractModal = (props) => {
  // * props
  const {
    visibility,
    onClose,
    onBackdropClick,
    member,
    onGenerateContractSuccess,
    isRegenerate = false,
  } = props;

  const { genViewContractPath } = useGeneratePath();

  console.log("GenerateContractModal member ", member);
  const jobTitles = useAppStore((state) => state.jobTitles);

  const userTenantId = member?.userTenantId;
  const contractRequestId = member?.contractRequestId;

  const informationFormRef = React.useRef();
  const generateContractFormRef = React.useRef();

  const getProjectAssignTemplates = useAppStore(
    (state) => state.getProjectAssignTemplates
  );

  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);

  const { getMissFieldsStatusByUserTenantId } = useProfileMissedFields();
  const navigate = useNavigate();

  // Status
  const [project] = React.useState(
    JSON.parse(localStorage.getItem("selected_project"))
  );

  const [missField, setMissField] = React.useState({
    isMissed: false,
    fields: [],
  });

  const [contractInfo, setContractInfo] = React.useState(null);

  const [projectDuration, setProjectDuration] =
    React.useState(projectDurationState);

  const [historyDataList] = React.useState([
    {
      date: "2022 jan 25",
      title: "Invite To the Project",
      projectName: "Negotiators",
      invitedFrom: "buddhika",
      invitedTo: "Jabez",
    },
    {
      date: "2022 jan 25",
      title: "Invite To the Project",
      projectName: "Negotiators",
      invitedFrom: "buddhika",
      invitedTo: "Jabez",
    },
  ]);

  const [activeTab, setActiveTab] = React.useState("information");

  const checkProfileDetails = React.useCallback(() => {
    if (userTenantId && visibility) {
      getMissFieldsStatusByUserTenantId(userTenantId)
        .then((res) => {
          const missedField = res.fields || [];
          const requiredField = ["Full Name", "Country", "Address"];
          const result = [];

          requiredField.forEach((f) => {
            missedField.forEach((mf) => {
              if (f === mf) {
                result.push(f);
              }
            });
          });

          const updatedMissingField = {
            isMissed: result.length > 0 ? true : false,
            fields: result,
          };

          setMissField(updatedMissingField);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [getMissFieldsStatusByUserTenantId, userTenantId, visibility]);

  console.log("contractInfo ------------ ", contractInfo);
  console.log("missField ------------ ", missField);
  // On update action
  const onClickUpdate = () => {
    const fn = informationFormRef.current?.onUpdate;
    fn && fn();
  };

  const onSaveGenerateContractHandler = () => {
    const fn = generateContractFormRef.current?.onSaveGenerateContract;

    if (isRegenerate) {
      Alert.confirm({
        allowOutsideClick: false,
        title: "Are you sure you?",
        text: "You need to regenerate the contract?, it will be lost  your previous changes",
        confirmButtonText: "Yes, do it!",
      }).then((result) => {
        if (result.isConfirmed) {
          fn && fn();
        }
      });

      return;
    }

    console.log("call");
    fn && fn();
  };

  // When call form is submitted
  const onUpdateFormHandler = (userTenantId, val, callback) => {
    // * Loading
    showLoader();

    updateProfileDetails({ userTenantId, formData: val })
      .then((res) => {
        if (!res.isSuccess) {
          toast.error(res?.displayMessage);
          hideLoader();
          return;
        }

        // toast.success(res?.displayMessage);
        // Re check profile
        checkProfileDetails();

        callback();
        hideLoader();
      })
      .catch((err) => {
        console.log(err);
        hideLoader();
      });
  };

  const onGenerateContractSubmitted = (val, callback) => {
    const templateId = val.template;
    const jobTitleId = val.role;
    const contractFee = val.contractFee;
    const startDate = val.sDate;
    const endDate = val.eDate;
    const reportOfficer = val.reportOfficer;
    const _userTenantId = member?.userTenantId;
    const _userId = member?.userId;

    console.log("val ", val);

    showLoader("Generating...");

    const contractFailMessage = "Contract generation fails";
    /**
     * ! Check whether the this values are valid
     * @returns Contract generation fails with error message
     */
    if (
      !Number(templateId) > 0 ||
      !Number(jobTitleId) > 0 ||
      !_userTenantId > 0 ||
      !_userId > 0 ||
      !projectId > 0 ||
      !contractRequestId > 0
    ) {
      toast.error(contractFailMessage);
      hideLoader();
      return;
    }

    /**
     * * Generate User Document and get document url
     */
    generateUserDoc(templateId, _userTenantId)
      .then((res) => {
        // ! Check response is valid
        if (!res?.isSuccess) {
          toast.error(contractFailMessage);
          hideLoader();
          return;
        }

        // * Get document url
        const { docURL } = res?.result;

        // ! Check Document URL is available
        if (docURL.length === 0) {
          toast.error("Document URL is not valid");
          hideLoader();
          return;
        }

        /**
         * * Create Document
         */
        createDocument({
          userDocumentId: 0,
          userId: _userId,
          documentTypeId: TEMPLATE_TYPES.CONTRACT,
          projectId,
          templateId,
          senderNote: "",
          documentURL: docURL,
          remarks: "Create Document",
        })
          .then((res) => {
            // ! Check Document creation was successful
            if (!res.isSuccess) {
              toast.error("Document creation fails");
              hideLoader();
              return;
            }

            // Get user document id from the response
            const userDocumentId = res?.result?.userDocumentId;

            /**
             * * Set Contract Document Id
             */
            setContractDocumentByIds(contractRequestId, userDocumentId)
              .then((res) => {
                // ! Check created document userDocumentId is equal with response userDocumentId
                if (
                  userDocumentId.toString() !==
                  res?.result?.userDocumentId.toString()
                ) {
                  toast.error("Contract document Id is not valid");
                  hideLoader();
                  return;
                }

                /**
                 * * Contract Generate
                 */
                const baseUrl = window.location.origin;
                // http://localhost:3000/view-contract/:userId/:contractId/:contractRequestId/:source

                const viewPath = genViewContractPath({
                  userId: _userId,
                  contractId: userDocumentId,
                  contractRequestId,
                  source: VIEW_CONTRACT_SOURCES.outside,
                });

                contractGenerate({
                  senderNote: "",
                  contractUrl: `${baseUrl}${viewPath}`,
                  reportingPersonInfo: reportOfficer,
                  contractRequestId,
                  startDate,
                  endDate,
                  templateId,
                  contractFee,
                  jobTitleId,
                })
                  .then((res) => {
                    // ! Check Contract Generate is successful
                    if (!res.isSuccess) {
                      toast.error(res?.displayMessage);
                      hideLoader();
                      return;
                    }
                    // * Show success message
                    Alert.confirm({
                      allowOutsideClick: false,
                      title: "Contract Generated!",
                      text: "Do you want to open the contract?",
                      confirmButtonText: "Yes, Open it!",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        const updatedViewContractPath = genViewContractPath({
                          userId: _userId,
                          contractId: userDocumentId,
                          contractRequestId,
                          source: VIEW_CONTRACT_SOURCES.hr,
                        });
                        navigate(updatedViewContractPath);
                      }
                      hideLoader();
                    });
                    // toast.success(res?.displayMessage);

                    onGenerateContractSuccess && onGenerateContractSuccess(res);
                    callback();
                    onClose && onClose();
                  })
                  .catch((err) => {
                    console.log(err);
                    toast.error(err);
                    hideLoader();
                  });
              })
              .catch((err) => {
                hideLoader();
                toast.error(err);
              });
          })
          .catch((err) => {
            toast.error("Error:- Document creation fails");
            hideLoader();
          });
      })
      .catch((err) => {
        toast.error(contractFailMessage);
        hideLoader();
      });
  };

  // On tab change
  const onTabChangeHandler = (tabId) => {
    setActiveTab(tabId);
  };

  const getProjectContractInfo = React.useCallback(() => {
    console.log("called getProjectContractInfo");
    if (contractRequestId) {
      getProjectContractRequestInfo(contractRequestId)
        .then((res) => {
          // if (!res.success) {
          //   return;
          // }
          setContractInfo(res?.result || null);

          console.log("contractInfo ", res);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [contractRequestId]);

  React.useEffect(() => {
    if (visibility) {
      setActiveTab("information");
    }
  }, [visibility]);

  const { startDate = "", endDate = "", projectId = 0 } = project;

  React.useEffect(() => {
    if (visibility) {
      setProjectDuration({ start: startDate, end: endDate });
      // load templates
      getProjectAssignTemplates(projectId);

      // Project Contract Info
      getProjectContractInfo();
    }
  }, [
    startDate,
    endDate,
    getProjectAssignTemplates,
    projectId,
    visibility,
    getProjectContractInfo,
  ]);

  React.useEffect(() => {
    checkProfileDetails();
  }, [checkProfileDetails]);

  const joinAsJobTitle = React.useMemo(() => {
    if (contractInfo?.jobTitleId && jobTitles) {
      const obj = jobTitles.find(
        (t) =>
          contractInfo?.jobTitleId?.toString() === t?.jobTitleId?.toString()
      );

      return obj?.jobTitleName || "";
    }

    return "";
  }, [contractInfo?.jobTitleId, jobTitles]);

  const modalTitle = React.useMemo(() => {
    if (isRegenerate) {
      return `Re-Generate Contract for ${member?.displayName}`;
    }
    return `Generate Contract for ${member?.displayName}`;
  }, [member?.displayName, isRegenerate]);

  return (
    <ModalBase
      visibility={visibility}
      onClose={onClose}
      onBackdropClick={onBackdropClick}
      title={modalTitle}
      subtitle={
        missField.isMissed
          ? `For the Contract, ${member?.displayName} basic profile should be up to date`
          : null
      }
    >
      <div className="pop__onb__hr__container">
        {/* warning message */}
        {/* <WarningMessage /> */}

        <div className="pop__top">
          <div
            className={`pop__top__content ${
              missField.isMissed ? "has-waring" : ""
            }`}
          >
            {missField.isMissed ? (
              <div className="warning">
                <img src={Warning} alt="" />
              </div>
            ) : null}

            <div className="pop__top__member__wr">
              <div className="pop__top__member__pic">
                <div className="pop__top__member__pic__iw">
                  <img alt="Profile" src={member?.profileImage || null} />
                </div>
                <div className="pop__top__member__pic__ic">
                  <div className="icon icon-lp-profile profile" />
                </div>
              </div>
              <div className="pop__top__member_info">
                <div className="member__name">{member?.givenName}</div>
                {/* <div className="member__position">{member?.jobTitleName}</div> */}
              </div>
            </div>

            <div className="pop__top__info__wr">
              <div className="info__ic">
                <div className="icon icon-lp-masks icon-20 text-orange" />
              </div>
              <div className="info__content">
                <div className="info__content__subtitle">Join as the</div>
                <div className="info__content__title">{joinAsJobTitle}</div>
              </div>
            </div>
            <div className="pop__top__info__wr">
              <div className="info__ic">
                <div className="icon icon-lp-doc-write icon-20 text-orange" />
              </div>
              <div className="info__content">
                <div className="info__content__subtitle">Project</div>
                <div className="info__content__title">
                  {project?.projectName || ""}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pop__center with-tabs">
          <BaseTab
            className="modal__tab"
            activeTab={activeTab}
            onTabChange={onTabChangeHandler}
          >
            <BaseTab.Header>
              {/* <BaseTab.NavLink linkTo="history">History</BaseTab.NavLink> */}
              {missField.isMissed ? (
                <BaseTab.NavLink linkTo="information">
                  Missing Information
                </BaseTab.NavLink>
              ) : null}
            </BaseTab.Header>

            <BaseTab.PanelWrapper>
              {/* <BaseTab.Panel panelId="history">
                <HistoryList data={historyDataList} />
              </BaseTab.Panel> */}
              {missField.isMissed ? (
                <BaseTab.Panel panelId="information" className="form__tab">
                  <div className="row ">
                    <div className="col-12 col-sm-12">
                      <InformationForm
                        missFields={missField}
                        userTenantId={userTenantId}
                        ref={informationFormRef}
                        onFormSubmitted={onUpdateFormHandler}
                      />
                    </div>
                  </div>
                </BaseTab.Panel>
              ) : null}
            </BaseTab.PanelWrapper>
          </BaseTab>
        </div>

        {contractInfo?.hrInstruction ? (
          <div className="instruction">
            <div className="instruction__inner">
              <div className="title">HR Instructions</div>
              <div className="details">
                <p>{contractInfo.hrInstruction}</p>
              </div>
            </div>
          </div>
        ) : null}

        <div className="pop__bottom">
          <div className="pop__bottom__iw">
            <GenerateContractForm
              ref={generateContractFormRef}
              projectDuration={projectDuration}
              onFormSubmitted={onGenerateContractSubmitted}
              contractInfo={contractInfo}
            />

            <div className="buttons__wr">
              <Button
                size="lg"
                variant="gray-400"
                leftIcon={BUTTON_ICONS.cancel}
                onClick={onClose}
              >
                Cancel
              </Button>

              {!missField.isMissed ? (
                <Button
                  // className="btn btn-green-light btn-lg text-white icon icon-lp-done btn--with-icon"
                  // type="button"
                  size="lg"
                  variant="green-light"
                  leftIcon={BUTTON_ICONS.done}
                  disabled={missField.isMissed}
                  onClick={
                    !missField.isMissed ? onSaveGenerateContractHandler : null
                  }
                >
                  {!isRegenerate ? "Generate Contract" : "Re-Generate Contract"}
                </Button>
              ) : null}

              {/* Only show when tab is information */}
              {activeTab === "information" && missField.isMissed ? (
                <Button
                  variant="green-dark"
                  leftIcon={BUTTON_ICONS.done}
                  onClick={onClickUpdate}
                >
                  Update Information
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </ModalBase>
  );
};

export default GenerateContractModal;
