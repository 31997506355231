import styled from "styled-components";

export const Container = styled.div`
  /* Default */
  --field-gradient-default: linear-gradient(180deg, #fafafb 5%, #c1c4ce 100%);

  /* Error */
  --field-gradient-error: linear-gradient(
    180deg,
    rgba(230, 230, 230, 0.05) 0%,
    #ff5e5e 100%
  );

  /* Valid */
  --field-gradient-valid: linear-gradient(
    180deg,
    rgba(230, 230, 230, 0) 100%,
    var(--color-neutral-50) 100%
  );

  --field-border-gradient: ${({ isError, isValid }) =>
    isError
      ? `var(--field-gradient-error)`
      : isValid
      ? `var(--field-gradient-valid)`
      : `var(--field-gradient-default)`};

  &:focus-within {
    /* focus Default */
    --field-gradient-default: linear-gradient(180deg, #c1c4ce 5%, #56b5bc 100%);

    .field__start__icon {
      --icon-font-color: var(--font-tint-04);
    }

    select,
    input[type="text"],
    input[type="password"],
    input[type="number"],
    input[type="tel"],
    input[type="time"],
    input[type="email"],
    textarea {
      &::placeholder {
        color: var(--font-tint-04);
      }
    }
  }

  background-image: var(--field-border-gradient);
  border-radius: var(--layout-border-radius-4);
  padding: 1px;
  transition: var(--animate-1);
  display: flex;
  align-items: center;
  position: relative;

  ${({ isDisabled }) => {
    if (isDisabled) {
      return `
        color:red;
        background-image: none; 
        background-color:var(--color-neutral-30);
      `;
    }
  }};

  .field__start__icon {
    --icon-size: var(--layout-size-12);
    --icon-font-size: var(--layout-size-12);
    --icon-font-color: var(--color-neutral-50);
    transition: var(--animate-1);
    position: absolute;
    left: var(--layout-size-12);
  }

  .field__end__icon {
    --icon-size: var(--layout-size-12);
    --icon-font-size: var(--layout-size-16);
    --icon-font-color: ${({ isEndIconActive }) =>
      isEndIconActive ? "var(--color-neutral-200)" : "var(--color-neutral-50)"};
    transition: var(--animate-1);
    position: absolute;
    right: var(--layout-size-12);
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  ${({ size }) => {
    switch (size) {
      case "sm": {
        return `
          min-height: var(--layout-size-32);
          `;
      }
      case "md": {
        return `
          min-height: var(--layout-size-40);
        `;
      }

      default: {
        return `
          min-height: var(--layout-size-48);
        `;
      }
    }
  }};

  select,
  input[type="text"],
  input[type="password"],
  input[type="number"],
  input[type="tel"],
  input[type="time"],
  input[type="email"],
  textarea {
    margin: 0;
    color: var(--font-default-color);
    outline: none;
    width: 100%;
    min-height: inherit;
    border-radius: 3px;
    border: 0;
    padding: var(--layout-spacing-4) var(--layout-spacing-12);
    padding-left: ${({ hasStartIcon }) => {
      return hasStartIcon
        ? `var(--layout-spacing-36)`
        : `var(--layout-spacing-12)`;
    }};
    padding-right: ${({ hasEndIcon }) => {
      return hasEndIcon
        ? `var(--layout-spacing-36)`
        : `var(--layout-spacing-12)`;
    }};

    &::placeholder {
      color: var(--color-neutral-50);
    }

    &:disabled {
      color: var(--font-tint-04);
      background-color: var(--color-neutral-30);

      &::placeholder {
        color: var(--font-tint-04);
      }
    }

    ${({ size }) => {
      switch (size) {
        case "sm": {
          return `
            font-size: var(--small-regular-font-size);
           
          `;
        }
        case "md": {
          return `
            font-size: var(--base-regular-font-size);
           
          `;
        }

        default: {
          return `
            font-size: var(--large-regular-font-size);
           
          `;
        }
      }
    }};
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-position: right;
    background-repeat: no-repeat;
    background-image: url("/assets/icons/svg/select-arrow.svg");
    background-position: calc(100% - 6px);
    padding-right: 20px;
    background-size: 6px;

    &::-ms-expand {
      display: none;
    }
  }

  textarea {
    ${({ size }) => {
      switch (size) {
        case "sm": {
          return `
            font-size: var(--small-regular-font-size);
            // --textarea-height: 50px;
           
          `;
        }
        case "md": {
          return `
            font-size: var(--base-regular-font-size);
            // --textarea-height: 85px;
          `;
        }

        default: {
          return `
            font-size: var(--large-regular-font-size);
            // --textarea-height: 120px;
          `;
        }
      }
    }};

    max-width: 100%;

    /* height: var(--textarea-height);
    max-height: var(--textarea-height);
    min-height: var(--textarea-height); */
    /* resize: none; */
  }
`;
