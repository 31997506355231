import API_ENDPOINTS from "../../../../../services/api-endpoints";
import API_Project from "../../../../../services/api-project";
import API_Global from "../../../../../services/api";
import { ONBOARDING_STATUS } from "../../../../../constant/Status";

export const createProject = (data) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    const body = {
      tenantId: data.tenantId,
      projectName: data.workingTitle,
      broadcastTitle: data.broadcastTitle,
      projectPrivacyId: 1,
      projectTypeId: 1,
      startDate: data.startDate,
      endDate: data.endDate,
      duration: data.duration + "",
      clientId: data.clientId,
      projectLogo: data.logo,
      languageId: data.language.value,
      txDate: data.txDate,
      currentUserProjectId: data.currentUserProjectId,
    };

    API_Project.post(endpoints.PROJECT_CREATE, {
      ...body,
    })
      .then((response) => {
        console.log("Create Project::", response);
        if (response.data.isSuccess === true) {
          addUserToProject(response?.data?.result?.projectId || 0);
          resolve(response.data.result);
        } else {
          resolve(response.data.result);
        }
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};

export const saveProject = (data) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    const body = {
      projectId: data.projectId,
      tenantId: data.tenantId,
      projectName: data.workingTitle,
      broadcastTitle: data.broadcastTitle,
      projectPrivacyId: 1,
      projectTypeId: 1,
      startDate: data.startDate,
      endDate: data.endDate,
      duration: data.duration + "",
      clientId: data.clientId,
      projectLogo: data.logo,
      languageId: data.language.value,
      txDate: data.txDate,
      currentUserProjectId: data.currentUserProjectId,
    };
    console.log("body :::", body);
    API_Project.put(endpoints.PROJECT_UPDATE + "/" + data?.projectId || 0, {
      ...body,
    })
      .then((response) => {
        console.log("Update Project::", response);
        resolve(response.data.result);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const addGenresToProject = (data, projectId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    const body = {
      projectId: projectId,
      projectGenresId: data?.genre?.map((gens) => gens.value) || [],
    };

    API_Project.post(endpoints.PROJECT_GENRES_ADD, {
      ...body,
    })
      .then((response) => {
        console.log("add genres::", response);
        if (response.data.isSuccess === true) {
          resolve(response.data.result);
        } else {
          resolve(null);
        }
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const getAllProjectFeatures = (tenantId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Global.get(`${endpoints.PROJECT_FEATURES_GETALL_BY_TENANT}/${tenantId}`)
      .then((res) => {
        console.log("project features :::", res);

        resolve(res.data.result);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const getAllProjectRelatedFeatures = (projectId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Project.get(
      `${endpoints.PROJECT_GET_FEATURES_BY_PROJECTID}/${projectId}`
    )
      .then((res) => {
        console.log("project related features :::", res);

        resolve(res.data.result);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const addProjectFeatures = (data) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    const body = {
      projectId: data?.projectId || 0,
      projectFeatureList:
        data?._selectedFeatures?.map((feature) => ({
          projectFeatureId: feature.projectFeatureId,
          projectFeatureName: feature.projectFeatureName,
        })) || [],
    };

    API_Project.post(endpoints.PROJECT_FEATURES_ADD, {
      ...body,
    })
      .then((response) => {
        console.log("add Project Features::", response);
        if (response.data.isSuccess === true) {
          resolve(response.data.result);
        } else {
          resolve(null);
        }
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const getProjectById = (projectId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Project.get(`${endpoints.PROJECT_GETBYID}/${projectId}`)
      .then((res) => {
        console.log("PROJECT_GETBYID :::", res.data.result.projectDetails);

        resolve(res.data.result.projectDetails);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const getGenresByProjectId = (projectId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Project.get(`${endpoints.PROJECT_GENRES_GETBY_PROJECTID}/${projectId}`)
      .then((res) => {
        console.log("getGenresByProjectId :::", res);
        resolve(res.data.result);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const addUserToProject = (projectId) => {
  const endpoints = new API_ENDPOINTS();
  let USER = JSON.parse(localStorage.getItem("user"));

  return new Promise((resolve, reject) => {
    const body = {
      memberUserId: USER?.result?.userId,
      memberName: USER?.result?.givenName + " " + USER?.result?.surName,
      projectId: projectId,
      projectMemberId: 0,
      ProjectRoleId: USER?.result?.jobTitleId,
    };

    API_Project.post(endpoints.PROJECT_ADD_USER_TO_PROJECT, {
      ...body,
    })
      .then(async (response) => {
        if (response.data.isSuccess === true) {
          console.log("After add user to project::", response.data.result);
          const projectMember = {
            projectMemberId: response.data.result?.projectMemberId,
            projectMemberStatusId: ONBOARDING_STATUS.Onboarded,
          };

          await setProjectMemberStatus(projectMember);
          resolve(response.data.result);
        } else {
          resolve(null);
        }
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
const projectMemberStatusBody = {
  projectMemberId: 0,
  projectMemberStatusId: 0,
};

export const setProjectMemberStatus = (data) => {
  const payload = {
    ...projectMemberStatusBody,
    ...data,
  };

  return new Promise((resolve, reject) => {
    const endpoints = new API_ENDPOINTS();

    API_Project.post(`${endpoints.SET_PROJECT_MEMBER_STATUS}`, payload)
      .then((res) => {
        if (res?.data.isSuccess) {
          console.log("Member onborded successfully");
        }
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getProjectEpisodes = (projectId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Project.get(`${endpoints.PROJECT_GET_EPISODES}?projectId=${projectId}`)
      .then((res) => {
        console.log("getProjectEpisodes :::", res);
        resolve(res.data.result);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const deleteProjectEpisode = (episodeId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Project.delete(`${endpoints.PROJECT_DELETE_EPISODE}/${episodeId}`)
      .then((res) => {
        console.log("deleteProjectEpisode :::", res);
        resolve(res.data);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const saveProjectEpisodes = (data) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    console.log("body :::", data);
    API_Project.post(endpoints.PROJECT_SAVE_EPISODES, [...data])
      .then((response) => {
        console.log("save Project Episodes ::", response);
        resolve(response.data);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const saveProjectSpecs = (
  projectId,
  filmingFormateId,
  deliveryForamtId,
  languageId
) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Project.put(
      endpoints.PROJECT_SPECS_UPDATE +
        "/" +
        projectId +
        "/" +
        deliveryForamtId +
        "/" +
        filmingFormateId +
        "/" +
        languageId
    )
      .then((response) => {
        console.log("Update Project specs::", response);
        resolve(response.data);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const getProjectSpecs = (projectId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Project.get(`${endpoints.PROJECT_SEPECS_GET_BY_PROJECT}/${projectId}`)
      .then((res) => {
        console.log("PROJECT_SEPECS_GET_BY_PROJECT :::", res);

        resolve(res?.data?.result);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
