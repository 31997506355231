import React from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUrlContext } from "../context/URLProvider";
import useUserData from "../hooks/useUserData";
import { PublicRoutePath } from "../routes/paths/public-path";

const withAuth = (WrapperComponent) => {
  return (props) => {
    // Hooks
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { userId } = useUserData();
    const { addRedirectUrl } = useUrlContext();

    useEffect(() => {
      if (userId === undefined) {
        return;
      }

      if (userId === null) {
        // If the user is not logged in, redirect to the login page with the location of the current page.
        addRedirectUrl(pathname);

        navigate(PublicRoutePath.Login.path, {
          state: {
            from: pathname,
          }
        });
      }
    }, [pathname, userId, navigate, addRedirectUrl]);

    return <WrapperComponent {...props} />;
  };
};

export default withAuth;
