import styled from "styled-components";
import { fontStyle } from "../../../../../styled/utility";
import TabBase from "../../../../../common/components/UI/TabBase";

export const Tab = styled(TabBase)`
  .base__tab__nav {
    border-bottom: 1px solid var(--color-secondary-300, #ef8b6d);

    ul {
      display: flex;
      align-items: center;
      gap: var(--layout-spacing-12);

      li {
        --item-bg-color: transparent;
        --item-border-color: transparent;
        --item-text-color: #949aa6;

        list-style: none;
        display: flex;
        padding: var(--layout-spacing-4) var(--layout-spacing-8);
        color: var(--item-text-color);
        background-color: var(--item-bg-color);
        border-radius: var(--layout-border-radius-8)
          var(--layout-border-radius-8) 0 0;
        position: relative;
        cursor: pointer;
        ${fontStyle("label-medium")};
        font-weight: 500;
        /* transition: var(--animate-1); */

        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 3px;
          background-color: var(--item-border-color);
          bottom: 0;
          left: 0;
        }

        &:hover {
          --item-text-color: var(--color-primary-300);
        }

        &.is--active {
          --item-bg-color: var(--color-accent-300, #56b5bc);
          --item-border-color: var(--color-secondary-300, #ef8b6d);
          --item-text-color: var(--font-default-white) !important;
        }
      }
    }
  }

  .base__tab__wrapper {
    .base__tab__content {
      margin-top: var(--layout-spacing-16);
    }
  }
`;
