export const getCommonPinningStyles = (column) => {
  const isPinned = column.getIsPinned();

  //   const isLastLeftPinnedColumn =
  //     isPinned === "left" && column.getIsLastColumn("left");

  //   const isFirstRightPinnedColumn =
  //     isPinned === "right" && column.getIsFirstColumn("right");

  return {
    // boxShadow: isLastLeftPinnedColumn
    //   ? "-4px 0 4px -4px gray inset"
    //   : isFirstRightPinnedColumn
    //   ? "4px 0 4px -4px gray inset"
    //   : undefined,
    // opacity: isPinned ? 0.95 : 1,

    // left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
    // right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
    // position: isPinned ? "sticky" : "relative",
    // zIndex: isPinned ? 1 : 0,
    width: column.columnDef?.meta?.size || column.getSize(),
  };
};
