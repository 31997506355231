import React from "react";
import Typography from "../../../../common/components/Typography";
import { IconBlock } from "../../../../styled/Common/Icons";
import * as ColorIcon from "../../../../common/components/icons/Color";
import * as Styled from "./styled";
import Navbar from "../Common/Navbar";

const AuthCardLayout = ({ children, title, subTitle, layoutFooter }) => {
  return (
    <React.Fragment>
      <Navbar />
      <Styled.LayoutContainer bgColor="n30">
        <Styled.Container>
          <Styled.Card>
            {/* Header */}
            <div className="card__header">
              {/* Logo */}
              <div className="logo__wrapper">
                <div className="logo__inner">
                  <IconBlock className="logo__icon">
                    <ColorIcon.Login />
                  </IconBlock>
                </div>
              </div>

              {/* Title */}
              <div className="title__wrapper">
                <Typography
                  variant={Typography.font_variant.h1}
                  fontWeight={Typography.font_weight.bold}
                  color={Typography.font_color.color_shade_primary}
                >
                  {title}
                </Typography>

                {subTitle ? (
                  <Typography color={Typography.font_color.tint_04}>
                    {subTitle}
                  </Typography>
                ) : null}
              </div>
            </div>

            {/* Body */}
            <div className="card__body">{children}</div>
          </Styled.Card>
          {layoutFooter}
        </Styled.Container>
      </Styled.LayoutContainer>
    </React.Fragment>
  );
};

export default AuthCardLayout;
