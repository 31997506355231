import React from "react";
import * as Styled from "./styled";

const TabPanel = (props) => {
  const { children, className } = props;

  return <Styled.TabPanel className={className}>{children}</Styled.TabPanel>;
};

export default TabPanel;
