import styled, { css } from "styled-components";
import { Media } from "../../../../../styled/theme";

export const cssContainerCard = css`
  grid-template-columns: repeat(6, 1fr);
  gap: ${({ theme }) => `${theme.spacing(12)} ${theme.spacing(5)}`};

  ${Media.max.xxl} {
    grid-template-columns: repeat(4, 1fr);
  }

  ${Media.max.md} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const cssContainerList = css`
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.spacing(5)};
`;

export const StyledContainer = styled.div`
  display: grid;
  padding-left: ${({ theme }) => theme.spacing(5)};
  padding-right: ${({ theme }) => theme.spacing(5)};
  ${({ viewType, layoutType }) => {
    if (layoutType === "group") {
      return null;
    }

    return viewType === "list" ? cssContainerList : cssContainerCard;
  }}
`;

const listCss = css`
  grid-template-columns: 12% auto 15% 175px;
  grid-template-areas: "avt cont opt act";
  padding: var(--layout-spacing-4) var(--layout-spacing-20);
  /* padding-left: 0; */
  align-items: center;
  border-radius: var(--layout-border-radius-8);

  .card__contents {
    display: grid;
    grid-template-columns: 30% 25% auto;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(2)};
  }

  .profile__name {
    justify-content: space-between;
    .name__1 {
      width: 35%;
    }

    .divide {
      margin: 0 ${({ theme }) => theme.spacing(5)};
    }

    .name__2 {
      width: 60%;
    }
  }

  .card__options {
    padding: 0;
    align-self: center;
  }

  .card__actions {
    margin: 0;
    padding-left: ${({ theme }) => theme.sizes(2)};
    display: flex;
    justify-content: flex-end;

    .line {
      display: none;
    }

    button {
      width: 100%;
    }
  }
`;

const cardCss = css`
  grid-template-columns: auto auto;
  grid-template-rows: repeat(4, auto);
  grid-template-areas:
    "avt opt"
    "cont cont"
    "act act";
  padding: ${({ theme }) => theme.spacing(4)};
  padding-top: 0;

  .card__avatar {
    margin-top: ${({ theme }) => theme.spacing(-13)};
  }

  .profile__name {
    margin-bottom: ${({ theme }) => theme.spacing(3)};
  }

  .card__actions {
    /* border-radius: ${({ theme }) => theme.rounded("4xl")};
    border-top-left-radius: 0;
    border-top-right-radius: 0; */
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: flex-end;
    padding: 0 ${({ theme }) => theme.spacing(4)};
    min-height: 28px;

    .line {
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      background-color: ${({ theme }) => theme.colors.color_1(10)};
      z-index: -1;
    }
  }
`;

const cardParent = css`
  border-radius: ${({ theme }) => theme.rounded("4xl")};

  &::before {
    content: "";
    display: block;
    width: calc(100%);
    height: 100%;
    position: absolute;
    /* background-color: ${(props) => props.statusColor}; */
    z-index: 1;
    border-radius: inherit;
    /* top: ${({ theme }) => theme.spacing(-1)}; */
    transition: inherit;
    box-shadow: 0px -4px 0 0.2px ${(props) => props.statusColor};
  }
`;

const listParent = css`
  border-radius: ${({ theme }) => theme.rounded("full")};
`;

export const StyledCard = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  background: transparent;
  /* margin-top: ${({ theme }) => theme.spacing(1)}; */
  box-shadow: 0px 7px 50px hsl(0deg 0% 0% / 7%);
  transition: ${({ theme }) => theme.animate.primary};
  ${({ viewType }) => (viewType !== "list" ? cardParent : listParent)}

  &:hover {
    box-shadow: 0px 7px 50px hsl(0deg 0% 0% / 15%);

    &::before {
    }
  }

  .card__inner {
    border-radius: inherit;
    z-index: 2;
    display: grid;
    width: 100%;
    height: inherit;
    background-color: ${({ theme }) => theme.colors.white()};
    padding: 0 ${({ theme }) => theme.spacing(4)};
    ${({ viewType }) => (viewType === "list" ? listCss : cardCss)}
  }

  .card__avatar {
    grid-area: avt;
    display: flex;
    align-items: center;
  }

  .card__options {
    grid-area: opt;
    align-self: flex-end;
    padding: ${({ theme }) => theme.spacing(3)} 0;

    &__inner {
      display: flex;
      align-items: center;
      align-self: flex-end;
      justify-content: flex-end;
      gap: ${({ theme }) => theme.spacing(1)};
    }
  }

  .card__contents {
    grid-area: cont;
  }

  .card__actions {
    grid-area: act;
    margin: 0 ${({ theme }) => theme.spacing(-4)};
  }

  .profile__name {
    display: flex;
    align-items: center;

    .name__1 {
      font-size: ${({ theme }) => theme.fonts.h3};
      color: ${({ theme }) => theme.textColors.primary()};
      font-weight: ${({ theme }) => theme.fontWeight.medium};
    }

    .name__2 {
      font-size: ${({ theme }) => theme.fonts.body3};
      color: ${({ theme }) => theme.textColors.primary()};
    }

    .divide {
      background-color: ${({ theme }) => theme.colors.primary()};
      width: 1px;
      height: ${({ theme }) => theme.spacing(5)};
      margin: 0 ${({ theme }) => theme.spacing(2)};
      opacity: 0.5;
    }
  }

  .job__title {
    font-size: ${({ theme }) => theme.fonts.body2};
    color: ${({ theme }) => theme.textColors.primary(50)};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  .skills {
    font-size: ${({ theme }) => theme.fonts.body3};
    color: ${({ theme }) => theme.textColors.primary(30)};
  }
`;
