import React from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";
import { AnimatePresence, motion } from "framer-motion";
import * as Styled from "./styled";

const PopoverOverLayer = Styled.PopoverOverLayer;

const MotionPopoverOverLayer = motion(PopoverOverLayer, {
  forwardMotionProps: true,
});

const opacityAnimation = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

const Popover = (
  {
    anchorEl,
    showArrow = false,
    children,
    modifiers = [],
    className,
    onOutsideClick,
    hideOverlay = false,

    placement = "auto",
    /*
      "auto" | "auto-start" | "auto-end"
      "top-start" | "top-end" | "bottom-start" |
      "bottom-end" | "right-start" | "right-end" |
      "left-start" | "left-end"
    */

    appendTo,
    maskClassName,
    overlayStyle,
    isOverlayTransparent,
  },
  ref
) => {
  const [popperElement, setPopperElement] = React.useState(null);
  const [arrowElement, setArrowElement] = React.useState(null);

  const { styles, attributes } = usePopper(anchorEl, popperElement, {
    placement,
    modifiers: [
      {
        name: "arrow",
        options: {
          element: arrowElement,
        },
      },
      ...modifiers,
    ],
  });

  const Component = (
    <>
      <Styled.PopoverContent
        ref={setPopperElement}
        style={styles.popper}
        className={className}
        {...attributes.popper}
      >
        {children}

        {/* Arrow */}
        {showArrow ? (
          <div
            ref={setArrowElement}
            style={styles.arrow}
            className="popper__arrow"
          />
        ) : null}
      </Styled.PopoverContent>

      {!hideOverlay ? (
        <AnimatePresence>
          <MotionPopoverOverLayer
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={opacityAnimation}
            onClick={onOutsideClick}
            className={["mask", maskClassName].join(" ")}
            style={overlayStyle}
            $isTransparent={isOverlayTransparent}
          />
        </AnimatePresence>
      ) : null}
    </>
  );

  if (appendTo) {
    let ele = appendTo;

    if (typeof appendTo === "string") {
      ele = document.querySelector(appendTo);
    }

    return ele && ReactDOM.createPortal(Component, ele);
  }

  return Component;
};

export default React.forwardRef(Popover);
