import * as React from "react";
import { memo } from "react";
const SvgLocation = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_958_1697)">
      <path
        d="M9.35416 3.97899C9.35416 3.68828 9.27331 3.40411 9.12184 3.16239C8.97037 2.92068 8.75508 2.73229 8.50319 2.62104C8.2513 2.50979 7.97414 2.48068 7.70674 2.5374C7.43934 2.59411 7.19371 2.7341 7.00093 2.93966C6.80814 3.14522 6.67685 3.40712 6.62366 3.69224C6.57047 3.97736 6.59777 4.27289 6.70211 4.54147C6.80644 4.81005 6.98313 5.0396 7.20982 5.20111C7.43651 5.36262 7.70303 5.44882 7.97567 5.44882C8.15677 5.44913 8.33616 5.41133 8.50354 5.33758C8.67092 5.26382 8.823 5.15557 8.95106 5.01902C9.07913 4.88247 9.18065 4.72031 9.24982 4.54184C9.319 4.36337 9.35445 4.1721 9.35416 3.97899ZM7.97567 4.92409C7.80036 4.92409 7.62899 4.86866 7.48322 4.76481C7.33746 4.66096 7.22385 4.51336 7.15676 4.34066C7.08968 4.16797 7.07212 3.97794 7.10633 3.79461C7.14053 3.61127 7.22495 3.44287 7.34891 3.3107C7.47287 3.17852 7.6308 3.08851 7.80274 3.05204C7.97468 3.01558 8.1529 3.03429 8.31487 3.10582C8.47683 3.17736 8.61526 3.29849 8.71266 3.45392C8.81005 3.60934 8.86204 3.79206 8.86204 3.97899C8.86256 4.10326 8.83998 4.2264 8.79562 4.34132C8.75126 4.45623 8.68599 4.56064 8.60358 4.64851C8.52117 4.73638 8.42325 4.80598 8.31548 4.85328C8.2077 4.90058 8.09221 4.92465 7.97567 4.92409Z"
        fill="currentColor"
      />
      <path
        d="M8.09867 12.64L8.29551 12.4015C8.44315 12.1916 12.1603 7.46723 12.1603 4.31767C12.1603 3.17255 11.7337 2.07434 10.9743 1.26462C10.2149 0.454896 9.18492 0 8.11097 0C7.03701 0 6.00704 0.454896 5.24764 1.26462C4.48824 2.07434 4.06162 3.17255 4.06162 4.31767C4.06162 7.46723 7.77879 12.2178 7.92642 12.4015L8.09867 12.64ZM8.09867 0.513994C9.04499 0.514942 9.95228 0.916279 10.6213 1.62988C11.2904 2.34349 11.6665 3.31103 11.6671 4.32006C11.6671 6.89241 8.86089 10.8028 8.0981 11.8266C7.33476 10.8028 4.55374 6.89241 4.55374 4.32006C4.55298 3.31492 4.92544 2.35033 5.5897 1.63714C6.25396 0.923941 7.15601 0.52014 8.09867 0.513994Z"
        fill="currentColor"
      />
      <path
        d="M11.2494 10.2256V10.7503H13.2676L15.2618 15.4729H0.738736L2.73238 10.7486H4.80151V10.2256H2.4371L0 16H16L13.5629 10.2256H11.2494Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_958_1697">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgLocation);
export default Memo;
