import React, {useEffect} from 'react';

const PayPalButton = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://www.paypal.com/sdk/js?client-id=BAACLiABT-zCsQnXls7cBIlm7rMctlv3DyAypsMfksnN-ZqUHnQnRcIgdcrsTjwAZj4T7nJOW-UCdWsy7o&components=hosted-buttons&disable-funding=venmo&currency=SGD";
        script.async = true;
        script.onload = () => {
            window.paypal.HostedButtons({
                hostedButtonId: "6DNG7CKXTYMJE",
            }).render("#paypal-container-6DNG7CKXTYMJE");
        };
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return <div id="paypal-container-6DNG7CKXTYMJE"></div>;
};

export default PayPalButton;
