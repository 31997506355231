import * as React from "react";
import { memo } from "react";
const SvgIconFavorite = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 21.12 21.12"
    {...props}
  >
    <g
      id="Group_1667"
      data-name="Group 1667"
      transform="translate(-315.5 -361)"
    >
      <circle
        id="Ellipse_113"
        data-name="Ellipse 113"
        cx={10.56}
        cy={10.56}
        r={10.56}
        transform="translate(315.5 361)"
        fill="currentColor"
      />
      <g id="noun-star-1208084" transform="translate(321.423 367.139)">
        <path
          id="Path_1281"
          data-name="Path 1281"
          d="M10.628,960.408a.211.211,0,0,0-.192.146l-.993,3.077-3.232-.006a.211.211,0,0,0-.124.382l2.619,1.9L7.7,968.974a.211.211,0,0,0,.325.236l2.611-1.906,2.612,1.906a.211.211,0,0,0,.324-.236L12.568,965.9l2.619-1.9a.211.211,0,0,0-.124-.382l-3.232.006-.994-3.077a.211.211,0,0,0-.209-.146Z"
          transform="translate(-6.001 -960.408)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
const Memo = memo(SvgIconFavorite);
export default Memo;
