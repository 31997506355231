import React from "react";
import "./styles.scss";

const TableListBody = (props) => {
  const { data } = props;

  return (
    <div className="table__list__body">
      {data ? data : <div className="table__empty">No record found</div>}
    </div>
  );
};

export default TableListBody;
