import React from "react";
import { Link, useRouteLoaderData } from "react-router-dom";
import { PROJECT_PERMISSION_MODULES } from "../../../../constant/project_permission_module";
import { ROUTE_OPTIONS } from "../../../../constant/route-options";
import { Permission } from "../../../../helpers/permission";

export const TabNames = {
  member: "member",
  shortlisted: "shortlisted",
  onboarding: "onboarding",
  HRList: "HRList",
  Favorites: "Favorites",
};

const TabMenuItems = (props) => {
  const { permission } = useRouteLoaderData(
    ROUTE_OPTIONS.projects.children.onboarding.id
  );

  const moduleType = PROJECT_PERMISSION_MODULES.Onboarding;

  const permissions = new Permission(permission);

  const memberPermission = permissions.findByName(moduleType.AllMembers.key);
  const shortListPermission = permissions.findByName(moduleType.ShortList.key);
  const hrListPermission = permissions.findByName(moduleType.HRList.key);
  const favoritesPermission = permissions.findByName(moduleType.Favorites.key);
  const onboardingPermission = permissions.findByName(
    moduleType.OnboardingList.key
  );

  const {
    memberCount,
    shortListCount,
    onboardingListCount,
    hrListCount,
    activeTab,
  } = props;

  const childPtah = ROUTE_OPTIONS.projects.children.onboarding.children;

  const isActive = (name) => {
    if (activeTab === name) {
      return "st active";
    }

    return "st";
  };

  const genPath = (path) => {
    const p1 = ROUTE_OPTIONS.projects.path;
    const p2 = ROUTE_OPTIONS.projects.children.onboarding.path;
    return `${p1}/${p2}/${path}`;
  };

  return (
    <ul className="onb__page__tabs__left__iW">
      {memberPermission.hasPermission ? (
        <li className={isActive("member")}>
          <Link to={genPath(childPtah["all-members"].path)}>
            All Members<span>{memberCount}</span>
          </Link>
        </li>
      ) : null}

      {shortListPermission.hasPermission ? (
        <li className={isActive("shortlisted")}>
          <Link to={genPath(childPtah["shortlisted-list"].path)}>
            Shortlisted
            <span>{shortListCount}</span>
          </Link>
        </li>
      ) : null}

      {onboardingPermission.hasPermission ? (
        <li className={isActive("onboarding")}>
          <Link to={genPath(childPtah["onboarding-list"].path)}>
            OnBoarding
            <span>{onboardingListCount}</span>
          </Link>
        </li>
      ) : null}

      {hrListPermission.hasPermission ? (
        <li className={isActive("HRList")}>
          <Link to={genPath(childPtah["hr-list"].path)}>
            HR List
            <span>{hrListCount}</span>
          </Link>
        </li>
      ) : null}
      
      {/* {favoritesPermission.hasPermission ? (
        <li className={isActive("Favorites")}>
          <Link to={genPath(childPtah["favorites"].path)}>
            Favorites<span>(0)</span>
          </Link>
        </li>
      ) : null} */}
    </ul>
  );
};

export default TabMenuItems;
