import * as React from "react";
import { memo } from "react";
const SvgDocumentMgr01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.79882 0.210938H10.2018V1.87413C10.2031 2.2024 10.3342 2.51635 10.5669 2.7477C10.7996 2.97906 11.1142 3.10873 11.4425 3.10737H13.1057V7.31624H8.80614C8.61778 7.31624 8.42872 7.07533 8.31543 6.95112L7.58314 6.13351C7.2494 5.76497 6.92591 5.45718 6.39698 5.45718H2.79751V1.21214C2.79888 0.659325 3.24601 0.211619 3.79882 0.210938ZM1.93636 16.2109H13.9602C14.4768 16.2103 14.8959 15.7912 14.8966 15.2746V8.71929C14.8959 8.20266 14.4769 7.7836 13.9602 7.78292H8.80614C8.44306 7.78292 8.21239 7.53313 7.96737 7.25878L7.23507 6.44117C6.99075 6.17022 6.76006 5.91702 6.3963 5.91702H1.93636C1.41973 5.91702 1.00068 6.3347 1 6.85134V15.2745C1.00068 15.7911 1.41972 16.2103 1.93636 16.2109ZM8.69432 6.00587H11.4113C11.5321 5.99563 11.6249 5.89462 11.6249 5.77315C11.6249 5.65167 11.5321 5.54998 11.4113 5.53975H8.69432C8.57352 5.54998 8.4807 5.65167 8.4807 5.77315C8.4807 5.89462 8.57351 5.99563 8.69432 6.00587ZM4.48545 2.65484H8.63701C8.75781 2.64461 8.85064 2.54292 8.85064 2.42144C8.85064 2.29996 8.75782 2.19896 8.63701 2.18872H4.48545C4.36465 2.19896 4.27183 2.29997 4.27183 2.42144C4.27183 2.54292 4.36464 2.64461 4.48545 2.65484ZM4.48545 4.33032H11.4113C11.5321 4.32008 11.6249 4.21839 11.6249 4.09692C11.6249 3.97544 11.5321 3.87444 11.4113 3.8642H4.48545C4.36465 3.87444 4.27183 3.97544 4.27183 4.09692C4.27183 4.21839 4.36464 4.32008 4.48545 4.33032ZM10.6688 0.44502V1.87413C10.6694 2.0782 10.7513 2.27407 10.8967 2.41807C11.0421 2.56139 11.2386 2.64123 11.4427 2.63919H12.8718L10.6688 0.44502Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgDocumentMgr01);
export default Memo;
