import React from "react";
import { Button, TimeSelect } from "./TimePickerWrapper";

const TimeUnitSelector = ({ label, value, onIncrement, onDecrement, onChange }) => {
  return (
    <TimeSelect>
      <label>{label}</label>
      <Button type="button" onClick={onIncrement}>▲</Button>
      <input type="text" value={value} onChange={onChange} maxLength="2" />
      <Button type="button" onClick={onDecrement}>▼</Button>
    </TimeSelect>
  );
};

export default TimeUnitSelector;
