import * as React from "react";
import { memo } from "react";
const SvgListAlt = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2192_10323)">
      <path
        d="M0 0V4H4V0H0ZM6.25 0V4H16V0H6.25ZM0 6V10H4V6H0ZM6.25 6V10H16V6H6.25ZM0 12V16H4V12H0ZM6.25 12V16H16V12H6.25Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2192_10323">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgListAlt);
export default Memo;
