import React from "react";
import { Outlet } from "react-router-dom";
import { PageWrapper } from "./styled";

const Dashboard = () => {
  return (
    <PageWrapper>
      <Outlet />
    </PageWrapper>
  );
};

export default Dashboard;
