import styled from "styled-components";
import Popover from "../../../../../../common/components/Popover";
import { fontStyle } from "../../../../../../styled/utility";

export const CalendarPopover = styled(Popover)`
  z-index: 999999;
`;

export const ScheduleButton = styled.button`
  --cta-icon-btn-bg-color: transparent;
  --cta-icon-btn-size: var(--layout-size-24);
  --cta-icon-btn-radius: var(--layout-border-radius-4);

  background-color: var(--cta-icon-btn-bg-color);
  border-radius: var(--cta-icon-btn-radius);
  margin: 0;
  border: none;
  display: flex;
  align-items: center;

  ${({ isFullButton }) => {
    if (isFullButton) {
      return `
        padding: var(--layout-spacing-4) var(--layout-spacing-8);
        width:100%;
      `;
    } else {
      return `
        padding: 0;
        height:var(--cta-icon-btn-size);
      `;
    }
  }};

  .icon {
    --icon-size: var(--layout-size-16);
    --icon-font-size: var(--layout-size-16);
    --icon-font-color: var(--color-accent-200);
    width: var(--cta-icon-btn-size);
    height: var(--cta-icon-btn-size);
  }

  .text {
    padding-left: var(--layout-spacing-8);
    ${fontStyle("small-regular")};
  }

  &:hover {
    --cta-icon-btn-bg-color: ${({ theme }) =>
      theme.actionColors.action_color_5(30)};
  }
`;
