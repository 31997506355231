import API_ENDPOINTS from "../../../../services/api-endpoints";
import API_Scheduler from "../../../../services/api-scheduler";

export const getAllScheduleItemTypes = () => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Scheduler.get(`${endpoints.GET_ALL_SCHEDULE_ITEM_TYPES}`)
      .then((res) => {
        console.log("GET_ALL_SCHEDULE_ITEM_TYPES :::", res);

        resolve(res.data.result);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const getActivityItemTypeByBlockId = (id) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Scheduler.get(`${endpoints.GET_ACTIVITY_ITEM_TYPE_BY_BLOCK}/${id}`)
      .then((res) => {
        console.log("GET_ACTIVITY_ITEM_TYPE_BY_BLOCK :::", res);

        resolve(res.data.result);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const createScheduleitem = (
  colTypeId,
  projectId,
  episodeId,
  childTypeId,
  date,
  itemCounts,
  startDate,
  endDate
) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    const body = {
      columnTypeId: colTypeId,
      episodeId: episodeId,
      projectId: projectId,
      childType: childTypeId,
      date: date,
      dateItems: itemCounts,
      startDate: startDate,
      endDate: endDate,
    };

    API_Scheduler.post(endpoints.CRAETE_SCHEDULE_ITEM, {
      ...body,
    })
      .then((response) => {
        console.log("CRAETE_SCHEDULE_ITEM::", response);
        if (response.data.isSuccess === true) {
          resolve(response.data.result);
        } else {
          resolve([]);
        }
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const deleteScheduleItem = (
  id,
  colTypeId,
  projectId,
  epId,
  start,
  end
) => {
  const endpoints = new API_ENDPOINTS();
  return new Promise((resolve, reject) => {
    API_Scheduler.put(
      endpoints.DELETE_SCHEDULE_ITEM +
        "?scheduleItemId=" +
        id +
        "&columnTypeId=" +
        colTypeId +
        "&projectId=" +
        projectId +
        "&episodeId=" +
        epId +
        "&startDate=" +
        start +
        "&endDate=" +
        end || 0
    )
      .then((response) => {
        console.log("deleted Schedule item::", response);
        if (response.data.isSuccess === true) {
          resolve(response.data.result);
        } else {
          resolve(null);
        }
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
