import styled from "styled-components";
import { fontStyle } from "../../../../../../../../../../../styled/utility";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  border-radius: var(--layout-border-radius-8);
  background: var(--color-neutral-10);

  .field__group {
    &.actions {
      margin-top: var(--layout-spacing-16);

      .element {
        display: flex;
        align-items: center;
        gap: var(--layout-spacing-8);
        justify-content: flex-end;

        button {
          width: 100%;

          &.cancel__btn {
            width: 28%;
          }
        }
      }
    }
  }

  .item__list {
    min-height: 224px;
    max-height: 224px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 0;
    /* padding-bottom: var(--layout-spacing-52); */
  }

  .input-row {
    margin-bottom: var(--layout-spacing-8);

    .item__inner {
      display: flex;
      align-items: center;
    }

    &.is-dragging {
      box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 5px 2px;
    }

    .code-input {
      width: 44px;
      max-width: 44px;
    }

    .name {
      width: 160px;
      max-width: 160px;
    }

    .icon_selector {
      &:hover {
        opacity: 0.6;
      }
    }
  }

  .controller {
    gap: var(--layout-spacing-16) !important;

    .action {
      display: flex;
      align-items: center;
      gap: var(--layout-spacing-8);
    }
  }
`;
