import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import FromGroup from "../../../../../../controls/FromGroup";
import UISelect from "../../../../../../controls/UISelect";
import Button from "../../../../../Buttons/Button";

import Checkbox from "../../../../../../controls/Checkbox";
import { useUserRightStore } from "../../../../../../../store/Setting/Permission/UserRightStore";
import API_ENDPOINTS from "../../../../../../../services/api-endpoints";
import API from "../../../../../../../services/api-tenant";
import { useRouteLoaderData } from "react-router-dom";
import { ROUTE_OPTIONS } from "../../../../../../../constant/route-options";
import { SETTING_PERMISSION_MODULES } from "../../../../../../../constant/setting_permission_module";
import { Permission } from "../../../../../../../helpers/permission";

// Form initial Values
const formValues = {
  userId: null,
  module: null,
  rightId: null,
  isDeny: false,
  rights: {
    create: true,
    edit: false,
    view: false,
    delete: false,
    execute: false,
  },
};

// Validation Schema
const validationSchema = Yup.object().shape({
  userId: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .required("This field is required")
    .nullable(),
  rightId: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .required("This field is required")
    .nullable(),
});

const UserRightForm = () => {
  const { permission } = useRouteLoaderData(
    ROUTE_OPTIONS.setting.children["tenant-permission"].id
  );
  const moduleType = SETTING_PERMISSION_MODULES.TenantPermission;
  const permissions = new Permission(permission);
  const userRightPermission = permissions.findByName(
    moduleType.AssignUserRights.key
  );

  const [rightsTypes, setRightsTypes] = React.useState([
    { name: "create", label: "Create" },
    { name: "view", label: "View" },
    { name: "edit", label: "Edit" },
    { name: "delete", label: "Delete" },
    { name: "execute", label: "Execute" },
  ]);
  const userOptions = useUserRightStore((state) => state.userOptions);
  const rightOptions = useUserRightStore((state) => state.rightOptions);
  const moduleOptions = useUserRightStore((state) => state.moduleOptions);
  const isEditMode = useUserRightStore((state) => state.isEditMode);

  const onLoadUserRightList = useUserRightStore(
    (state) => state.onLoadUserRightData
  );
  const onLoadRightOptions = useUserRightStore(
    (state) => state.onLoadRightOptions
  );
  const getAvailablePermissionFromInt = useUserRightStore(
    (state) => state.getAvailablePermissionFromInt
  );
  const clearForm = useUserRightStore((state) => state.clearForm);

  const onToggleForm = useUserRightStore((state) => state.onToggleForm);

  const onFormSubmit = useUserRightStore((state) => state.onFormSubmit);

  const onFormClearHandler = () => {
    clearForm(() => {
      resetForm();
      onToggleForm();
    });
  };

  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    onSubmit,
  });

  const {
    errors,
    values,
    resetForm,
    handleSubmit,
    touched,
    setFieldValue,
    handleChange,
  } = formik;

  function onSubmit(values, actions) {
    let payload = {
      userId: values.userId.value,
      rightId: values.rightId.value,
      isDeny: values.isDeny,
      rights: values.rights,
    };

    onFormSubmit(payload)
      .then((res) => {
        console.log("done", res);
        clearForm(() => {
          //resetForm();
          onToggleForm();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const onModuleChange = (val) => {
    setFieldValue("module", val);
    onLoadRightOptions(val);
  };
  const onUserChange = (val) => {
    console.log(val);
    setFieldValue("userId", val);

    onLoadUserRightList(val.value);
  };
  const onRightChange = (val) => {
    console.log("Selected Right", val);
    setFieldValue("rightId", val);
    let right_Options = [];
    const endpoints = new API_ENDPOINTS();
    API.get(endpoints.PERMISSION_RIGHT_GET_BY_ID + "/" + val.value).then(
      (response) => {
        if (response.data.isSuccess === true) {
          var right = response.data.result;
          console.log("right", right);
          var availble_permissions = getAvailablePermissionFromInt(
            right.availablePermissions
          );

          if (availble_permissions?.view) {
            right_Options.push({ name: "view", label: "View" });
          }
          if (availble_permissions?.create) {
            right_Options.push({ name: "create", label: "Create" });
          }
          if (availble_permissions?.edit) {
            right_Options.push({ name: "edit", label: "Edit" });
          }
          if (availble_permissions?.delete) {
            right_Options.push({ name: "delete", label: "Delete" });
          }
          if (availble_permissions?.execute) {
            right_Options.push({ name: "execute", label: "Execute" });
          }

          setRightsTypes(right_Options);
        }
      }
    );
  };
  const onCheckboxChange = (e) => {
    const name = e.target.name;
    let rights = values.rights;

    setFieldValue("rights", {
      ...rights,
      [name]: e.target.checked,
    });
  };

  return (
    <div className="setting-form setting__role__right--form">
      <form onSubmit={handleSubmit} className="row ">
        <div className="col-md-12">
          <div className="row">
            <div className="col-4">
              <FromGroup
                label="User"
                inputHeightAuto
                error={touched.userId && errors.userId}
              >
                <UISelect
                  name="userId"
                  value={values.userId}
                  options={userOptions}
                  onChange={onUserChange}
                  placeholder="Select User"
                />
              </FromGroup>
            </div>
          </div>
        </div>

        {userRightPermission.permission.canAddNew ? (
          <>
            <div className="col-md-6">
              <FromGroup label="Module" inputHeightAuto>
                <UISelect
                  name="module"
                  options={moduleOptions}
                  value={values.module}
                  onChange={onModuleChange}
                  placeholder="Select Module"
                />
              </FromGroup>
            </div>

            <div className="col-md-6">
              <FromGroup
                label="Right"
                inputHeightAuto
                error={touched.rightId && errors.rightId}
              >
                <UISelect
                  name="rightId"
                  options={rightOptions}
                  value={values.rightId}
                  onChange={(val) => setFieldValue("rightId", val)}
                  placeholder="Select Right"
                  isDisabled={rightOptions.length > 0 ? false : true}
                />
              </FromGroup>
            </div>

            <div className="col-md-6">
              <FromGroup inputHeightAuto label="Access">
                <div className="d-flex gap-4 flex-wrap">
                  {rightsTypes.map((checkBox, i) => {
                    return (
                      <Checkbox
                        key={i}
                        id={checkBox.name}
                        label={checkBox.label}
                        name={checkBox.name}
                        checked={values.rights?.[checkBox.name]}
                        onChange={(e) => onCheckboxChange(e)}
                      />
                    );
                  })}
                </div>
              </FromGroup>
            </div>

            <div className="col-md-6">
              <FromGroup inputHeightAuto label="Status">
                <Checkbox
                  id="isDeny"
                  label="Is Deny"
                  name="isDeny"
                  // defaultChecked={values.roleStatus}
                  checked={values.isDeny}
                  onChange={handleChange}
                />
              </FromGroup>
            </div>

            <div className="col-12">
              <FromGroup type="button-inline-left">
                <Button variant="secondary" onClick={onFormClearHandler}>
                  Cancel
                </Button>
                <Button type="submit">{isEditMode ? "Edit" : "Add"}</Button>
              </FromGroup>
            </div>
          </>
        ) : null}
      </form>
    </div>
  );
};

export default UserRightForm;
