import { useRouteLoaderData } from "react-router-dom";
import { Permission } from "../helpers/permission";

const usePagePermission = ({ key }) => {
  const { permission } = useRouteLoaderData(key);

  const permissions = new Permission(permission);

  return permissions;
};

export default usePagePermission;
