import React from "react";
import { ClampLineText } from "./styled";

function TextClampLines({ text, lines = 2, as, ...rest }) {
  return (
    <ClampLineText lines={lines} as={as} {...rest}>
      {text}
    </ClampLineText>
  );
}

export default TextClampLines;
