import * as React from "react";
import { memo } from "react";
const Svg02 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.99754 0C5.42872 0 3.33333 2.40633 3.33333 5.3494C3.33459 5.76907 3.63123 6.10893 3.99754 6.11037C4.36577 6.11204 4.6654 5.77128 4.66667 5.3494C4.66667 3.23171 6.14912 1.5276 7.99754 1.52759C9.84595 1.52761 11.3284 3.23172 11.3284 5.3494C11.3297 5.77128 11.6293 6.11204 11.9975 6.11037C12.3638 6.10893 12.6605 5.76907 12.6617 5.3494C12.6617 2.40635 10.5664 2.02026e-05 7.99754 0Z"
      fill="currentColor"
    />
    <path
      d="M3.99755 4.58279C3.62932 4.58424 3.33188 4.92752 3.33333 5.3494L3.33334 8.40459C3.3346 8.82427 3.63124 9.16413 3.99755 9.16557C4.36577 9.16723 4.66541 8.82647 4.66667 8.40459L4.66667 5.3494C4.66813 4.92532 4.3677 4.58111 3.99755 4.58279Z"
      fill="currentColor"
    />
    <path
      d="M8.75502 10.9049C8.58385 10.8779 8.41022 10.9282 8.27076 11.0451L7.99739 11.2747L7.72402 11.0451C7.25701 10.6568 6.6092 11.0856 6.66959 11.7431L6.70595 12.1278L6.39613 12.2859C5.86801 12.5546 5.86801 13.4133 6.39613 13.6819L6.70595 13.84L6.66959 14.2248C6.6092 14.8823 7.25701 15.3111 7.72402 14.9228L7.99739 14.6931L8.27076 14.9228C8.73812 15.3157 9.39027 14.8851 9.3278 14.2248L9.28875 13.84L9.59857 13.6819C10.1267 13.4133 10.1267 12.5546 9.59857 12.2859L9.28875 12.1278L9.3278 11.7431C9.36633 11.3321 9.11246 10.9607 8.75502 10.9049Z"
      fill="currentColor"
    />
    <path
      d="M3.99754 7.63797C2.90134 7.63797 2 8.67627 2 9.93218V16.0369C2 17.2928 2.90135 18.3311 3.99754 18.3311H11.9975C13.0937 18.3311 13.9951 17.2928 13.9951 16.0369V9.93218C13.9951 8.67627 13.0937 7.63797 11.9975 7.63797H3.99754ZM3.99755 9.16557L11.9975 9.16556C12.3783 9.16556 12.6617 9.49594 12.6617 9.93218V16.0369C12.6617 16.4732 12.3783 16.8035 11.9975 16.8035H3.99754C3.61677 16.8035 3.33333 16.4732 3.33333 16.0369V9.93218C3.33333 9.49594 3.61678 9.16557 3.99755 9.16557Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg02);
export default Memo;
