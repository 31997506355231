import styled from "styled-components";
import { TextSm, TitleSm } from "../../../../styled/Common/Typography.styled";

export const DialogContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(4)};
`;

export const Dialog = styled.div`
  min-width: 460px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.rounded('xl')};
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 17px 40px rgb(0 0 0 / 5%);

  .icon__container {
    width: 72px;
    height: 72px;
    margin-top: -28px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.rounded('full')};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;

    & + .dialog__content .dialog__body {
      padding-top: 0;
    }
  }

  .dialog__content {
    width: 100%;
    border-radius: ${({ theme }) => theme.rounded('xl')};
    overflow: hidden;

    .dialog__body {
      width: 100%;
      padding: 24px;
      /* padding-bottom: 28px; */
      display: flex;
      flex-direction: column;
      align-items: center;

      .fields-list {
        margin-top: 8px;
      }

      .content__wrapper,
      .title__wrapper {
        width: 100%;
      }

      .title__wrapper {
        text-align: center;
        margin-bottom: ${({ theme }) => theme.spacing(1)};
      }
      .content__wrapper {
        color: #394252;
      }
    }

    .dialog__actions {
      width: 100%;
      background-color: #fbfbfb;
      padding: 16px 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: ${({ theme }) => theme.spacing(2)};
    }
  }
`;

export const Title = styled(TitleSm)`
  color: #245a6c;
  font-weight: 600;
  line-height: 1.25;
`;

export const Description = styled(TextSm)`
  color: #394252;
  line-height: 1.25;
  text-align: ${(props) => props.textAlign || "center"};
`;
