import React from "react";
import { IconItem, IconPopoverPanel, IconSelectButton } from "./styled";
import IconButton from "../../Buttons/IconButton";
import IconImage from "../../icons/Line/image";
import { IconsList } from "../../IconSelector/icons-list";
import useIcon from "../../IconSelector/hooks/useIcon";

const allIcons = IconsList;

const IconPickerContext = React.createContext({
  iconList: [],
  selectedIcon: null,
  setSelectedIcon: (icon) => {},
  iconBtnElement: null,
  setIconBtnElement: (icon) => {},
  handleChange: (selectedIcon) => {},
});

const useIconPicker = () => React.useContext(IconPickerContext);

const IconPicker = ({ value, onChange }) => {
  const [selectedIcon, setSelectedIcon] = React.useState(null);
  const [iconBtnElement, setIconBtnElement] = React.useState(null);
  const [iconList, setIconList] = React.useState([]);

  React.useEffect(() => {
    // let icons = allIcons.filter((i) => i?.type === "scheduler");
    setIconList(allIcons);
  }, []);

  React.useEffect(() => {
    setSelectedIcon(value);
  }, [value]);

  const handleChange = React.useCallback(
    (iconName) => {
      setSelectedIcon(iconName);
      onChange && onChange(iconName);
    },
    [onChange]
  );

  const values = {
    iconList,
    selectedIcon,
    iconBtnElement,
    handleChange,
    setIconBtnElement,
    setSelectedIcon,
  };

  return (
    <IconPickerContext.Provider value={values}>
      <IconButtonElement />
      <IconPopover />
    </IconPickerContext.Provider>
  );
};

function IconButtonElement() {
  const { setIconBtnElement, selectedIcon } = useIconPicker();

  const getIconByName = useIcon();

  const IconComponent = selectedIcon ? getIconByName(selectedIcon) : IconImage;

  return (
    <IconSelectButton
      type="button"
      onClick={(e) => setIconBtnElement(e.currentTarget)}
    >
      <IconButton as="div" icon={<IconComponent />} />
    </IconSelectButton>
  );
}

function IconPopover() {
  const {
    iconBtnElement,
    setIconBtnElement,
    iconList,
    selectedIcon,
    handleChange,
  } = useIconPicker();

  const getIconByName = useIcon();

  if (!iconBtnElement) return null;

  return (
    <IconPopoverPanel
      anchorEl={iconBtnElement}
      appendTo={document.body}
      onOutsideClick={() => setIconBtnElement(null)}
    >
      <div className="icons-popover">
        {iconList.map((icon, i) => {
          const IconComponent = getIconByName(icon?.name);

          return (
            <IconItem
              $isActive={icon?.name === selectedIcon}
              type="button"
              key={i}
              icon={<IconComponent />}
              btnIconSize={20}
              btnSize={28}
              onClick={() => {
                handleChange(icon?.name);
              }}
            />
          );
        })}
      </div>
    </IconPopoverPanel>
  );
}

export default IconPicker;
