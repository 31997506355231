import produce from "immer";
import create, { useStore } from "zustand";
import { devtools, persist } from "zustand/middleware";

const initialState = {
  showMessageView: false,
  showChatView: true,
  isMinimized: true,
  chatData: null,
  genaralGroups: [],
  memberGroups: [],
  currentChat: [],
  lastMessageId: 0,
  isOpenCreateGroupDialog: false,
  isOpenNewMemberDialog: false,
  isOpenNewIndividualMemberDialog: false,
  isLayoutLoaded: false,
  allChats: [],
};

let store = (set, get) => {
  return initialState;
};

store = devtools(store, { name: "Chat Base Store" });

store = persist(store, {
  name: "chat_base_store",
  version: 1,
  migrate: (persistedState, version) => {
    if (version === 0) {
      // if the stored value is in version 0, we rename the field to the new name
      persistedState.newField = persistedState.oldField;
      delete persistedState.oldField;
    }

    return persistedState;
  },
  serialize: (state) => btoa(JSON.stringify(state)),
  deserialize: (str) => JSON.parse(atob(str)),
});

export const useChatBaseStore = create(store);

export const handlerMessageVisibility = (val = false, data = null) => {
  useChatBaseStore.setState(
    produce((draft) => {
      draft.showMessageView = val;
      draft.chatData = data;
    })
  );
};
export const handlerMinimize = (val = false) => {
  useChatBaseStore.setState(
    produce((draft) => {
      draft.isMinimized = !draft.isMinimized;
    })
  );
};

export const onLayoutLoaded = (val = false) => {
  useChatBaseStore.setState(
    produce((draft) => {
      draft.isLayoutLoaded = val;
    })
  );
};

export const GroupDialog = () => {
  //
  const stateChange = (val) => {
    useChatBaseStore.setState(
      produce((draft) => {
        draft.isOpenCreateGroupDialog = val;
      })
    );
  };

  return {
    open: () => {
      stateChange(true);
    },

    close: () => {
      stateChange(false);
    },
  };
};

export const NewMemberDialog = () => {
  //
  const stateChange = (val) => {
    useChatBaseStore.setState(
      produce((draft) => {
        draft.isOpenNewMemberDialog = val;
      })
    );
  };

  return {
    open: () => {
      stateChange(true);
    },

    close: () => {
      stateChange(false);
    },
  };
};

export const NewIndividualMemberDialog = () => {
  //
  const stateChange = (val) => {
    useChatBaseStore.setState(
      produce((draft) => {
        draft.isOpenNewIndividualMemberDialog = val;
      })
    );
  };

  return {
    open: () => {
      stateChange(true);
    },

    close: () => {
      stateChange(false);
    },
  };
};

export const setGenaralChatGroups = (val) => {
  //
  useChatBaseStore.setState(
    produce((draft) => {
      if (Array.isArray(val)) {
        draft.genaralGroups = val;
      } else {
        draft.genaralGroups.push(val);
      }
    })
  );
};

export const setMemberChatGroups = (val) => {
  //
  useChatBaseStore.setState(
    produce((draft) => {
      if (Array.isArray(val)) {
        draft.memberGroups = val;
      } else {
        draft.memberGroups.push(val);
      }
    })
  );
};

export const setCurrentChatData = (val) => {
  //
  useChatBaseStore.setState(
    produce((draft) => {
      if (Array.isArray(val)) {
        draft.currentChat = val;
      } else {
        draft.currentChat.push(val);
      }
    })
  );
};

export const setUpdateChatData = (val) => {
  //
  useChatBaseStore.setState(
    produce((draft) => {
      draft.chatData = val;
    })
  );
};

export const setLastMessage = (val) => {
  //
  useChatBaseStore.setState(
    produce((draft) => {
      draft.lastMessageId = val;
    })
  );
};

export const setAllChats = (val) => {
  //
  useChatBaseStore.setState(
    produce((draft) => {
      draft.allChats = val;
    })
  );
};

export const clearChat = () => {
  useChatBaseStore.setState(initialState);
};
