import * as React from "react";
import { memo } from "react";
const SvgCost = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_961_3189)">
      <path
        d="M15.6232 1.31779C15.5895 0.811647 15.1847 0.406723 14.6786 0.373008L9.16309 0.00181176C8.87637 -0.0150483 8.58959 0.0861682 8.38721 0.288595L0.290979 8.38681C-0.0969931 8.77488 -0.0969931 9.4328 0.290979 9.82087L6.17762 15.7089C6.56559 16.097 7.22335 16.097 7.61132 15.7089L15.7076 7.61073C15.9099 7.40831 16.028 7.12145 15.9943 6.81781L15.6232 1.31779ZM8.13418 11.255V11.9805C8.13418 12.0648 8.0667 12.1155 7.99922 12.1155H7.29083C7.20649 12.1155 7.15587 12.048 7.15587 11.9805V11.2719C6.43055 11.2213 5.8402 10.6645 5.77279 9.93905C5.77279 9.8547 5.84027 9.7872 5.90775 9.7872H6.63306C6.70054 9.7872 6.75111 9.82092 6.76802 9.88841C6.81865 10.1078 7.02103 10.2765 7.25718 10.2765H7.88124C8.25229 10.2765 8.58963 10.0066 8.64026 9.63535C8.65706 9.23042 8.31972 8.85928 7.89804 8.85928H7.42573C6.53174 8.85928 5.73901 8.20129 5.65468 7.32399C5.57034 6.37922 6.24503 5.58625 7.12212 5.46815V4.74266C7.12212 4.6583 7.1896 4.60767 7.25708 4.60767L7.9824 4.60772C8.06673 4.60772 8.11736 4.67522 8.11736 4.74271V5.45128C8.84267 5.50192 9.43302 6.05863 9.50043 6.78412C9.50043 6.86848 9.43295 6.93598 9.36547 6.93598L8.65708 6.93593C8.5896 6.93593 8.53903 6.88529 8.52212 6.83471C8.4715 6.61535 8.26911 6.44664 8.03296 6.44664H7.4089C7.03785 6.44664 6.70051 6.71657 6.64988 7.08778C6.59926 7.50956 6.9366 7.8807 7.35827 7.8807H7.89799C8.89317 7.8807 9.70278 8.74112 9.60158 9.75341C9.5173 10.5295 8.89323 11.1369 8.13428 11.255L8.13418 11.255ZM13.2787 4.27026C12.8401 4.7089 12.1486 4.7089 11.71 4.27026C11.2715 3.83162 11.2715 3.13991 11.71 2.70123C12.1485 2.26259 12.8401 2.26259 13.2787 2.70123C13.7172 3.13987 13.7172 3.84851 13.2787 4.27026Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_961_3189">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgCost);
export default Memo;
