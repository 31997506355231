import styled from "styled-components";
import { fontStyle } from "../../../../../../styled/utility";

export const DividerWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  gap: var(--layout-spacing-16);
  z-index: 1;
  
  &::before,
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    border-top: 1px dashed #97adb4;
    z-index: 1;
  }

  .time {
    ${fontStyle("x-small-medium")};
    display: flex;
    padding: var(--layout-spacing-4) var(--layout-spacing-16);
    justify-content: center;
    align-items: center;
    border-radius: var(--layout-border-radius-4);
    background: var(--color-white, #fff);
    font-weight: 500;
    z-index: 2;
    white-space: nowrap;
  }
`;
