import React from "react";
import * as Styled from "./styled";

export function BaseTable(props) {
  const { children, className = "", ...otherProps } = props;

  return (
    <Styled.Table
      className={["base__table", className].join("")}
      {...otherProps}
    >
      {children}
    </Styled.Table>
  );
}

export const Head = ({ children, ...otherProps }) => {
  return <thead {...otherProps}>{children}</thead>;
};

export const Body = ({ children, ...otherProps }) => {
  return <tbody {...otherProps}>{children}</tbody>;
};

export const TR = ({ children, ...otherProps }) => {
  const ref = React.useRef();

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }

    const trHeight = ref.current.getBoundingClientRect().height;

    ref.current.style.setProperty(
      "--tr-height",
      `${Number(trHeight).toFixed(0)}px`
    );
  }, []);

  return (
    <tr ref={ref} {...otherProps}>
      {children}
    </tr>
  );
};

export const TH = ({ children, ...otherProps }) => {
  return (
    <th {...otherProps}>
      <div>{children}</div>
    </th>
  );
};

export const TD = ({ children, ...otherProps }) => {
  return (
    <td {...otherProps}>
      <div>{children}</div>
    </td>
  );
};
