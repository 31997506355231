import React from "react";
import * as Styled from "./styled";
import DataCell from "../DataCell";
import { useScheduleContext } from "../../../context/ScheduleContext";
import { LegendModal } from "../../../../../store/SchedulerStore";
import { format, isWeekend } from "date-fns";
import { CONTEXT_MENU_TYPE } from "../index,";

// import { format } from "date-fns";

const Column = ({ handleContextMenu, data, cellItems }) => {
  const { dates } = useScheduleContext();
  const cellContainerRef = React.useRef(null);

  const { columnName, columnTypeId } = React.useMemo(() => {
    const d = JSON.parse(data);

    return {
      columnTypeId: d?.columnTypeId,
      columnName: d?.columnName,
      order: d?.order,
      status: d?.status,
    };
  }, [data]);

  return (
    <Styled.Container>
      <div className="inner">
        <div className="top__content">
          {/*<div className="column__title">*/}
          {/*  <span>The BIG Drama</span>*/}
          {/*  <span>EP10</span>*/}
          {/*</div>*/}
        </div>

        <div className="main__content">
          <div className="main__content__inner">
            <div className="title">
              <div className="text">{columnName}</div>
            </div>

            <div className="cell__container" ref={cellContainerRef}>
              <div className="cells">
                {dates.map((d, i) => {
                  return (
                    <Styled.RowCell
                      className="cell"
                      data-selectable={true}
                      key={i}
                      onClick={() => {
                        LegendModal.open({
                          ...d,
                          columnTypeId,
                        });
                      }}
                      onContextMenu={(event) => {
                        event.preventDefault();
                        const anchorEle = event.target;
                        handleContextMenu({
                          anchorEle,
                          event,
                          payload: {
                            columnTypeId,
                            ...d,
                            contextMenuType: CONTEXT_MENU_TYPE.EmptyRow,
                          },
                        });
                      }}
                      data-date={format(new Date(d?.date), "yyyyMMdd")}
                      $isWeekend={isWeekend(new Date(d?.date))}
                    ></Styled.RowCell>
                  );
                })}
              </div>

              <div className="presentation">
                {JSON.parse(cellItems)?.map((cellItem, i) => {
                  const allData = [
                    ...cellItem?.activities,
                    ...cellItem?.milstones,
                  ];

                  if (!allData?.length) {
                    return null;
                  }

                  return (
                    <DataCell
                      key={i}
                      data-selectable={true}
                      data-selected={false}
                      data-id={cellItem?.dateId}
                      data={cellItem}
                      onClick={() => {
                        LegendModal.open({
                          ...cellItem,
                          columnTypeId,
                        });
                      }}
                      onContextMenu={(event) => {
                        event.preventDefault();
                        const anchorEle = event.target;
                        handleContextMenu({
                          anchorEle,
                          event,
                          payload: {
                            columnTypeId,
                            cellItem,
                            contextMenuType: CONTEXT_MENU_TYPE.CellDataRow,
                          },
                        });
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Styled.Container>
  );
};

export default React.memo(Column);
