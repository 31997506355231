import styled from "styled-components";

export const MainConatiner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--layout-spacing-20);
  padding-top: var(--layout-spacing-20);

  .weekDaySelectorContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--layout-spacing-8);
  }
`;
