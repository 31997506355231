import React from "react";
import Typography from "../../../../common/components/Typography";
import * as Styled from "./styled";

const FooterContent = ({
  textContent,
  onLinkClick,
  linkText,
  time = 0,
  isDisabled = false,
}) => {
  return (
    <Styled.FooterContainer>
      <Typography as="div" color={Typography.font_color.tint_04}>
        {textContent}
      </Typography>

      <button
        onClick={onLinkClick}
        className="text__link"
        disabled={isDisabled}
      >
        {linkText}
      </button>

      {time > 0 ? (
        <div className="timer-text">
          <div>It may take some time to receive the verification email.</div>
          <div>
            Wait {String(time).padStart(2, "0")} seconds before resending
          </div>
        </div>
      ) : null}
    </Styled.FooterContainer>
  );
};

export default FooterContent;
