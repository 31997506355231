import * as React from "react";
import { memo } from "react";
const SvgAdd = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_958_1686)">
      <path
        d="M8 0H0V8C0 9.58225 0.469194 11.129 1.34824 12.4446C2.2273 13.7601 3.47672 14.7855 4.93853 15.391C6.40034 15.9965 8.00887 16.155 9.56071 15.8463C11.1126 15.5376 12.538 14.7757 13.6568 13.6568C14.7757 12.538 15.5376 11.1126 15.8463 9.56071C16.155 8.00887 15.9965 6.40034 15.391 4.93853C14.7855 3.47672 13.7601 2.2273 12.4446 1.34824C11.129 0.469194 9.58225 0 8 0ZM8.99989 11.0005C8.99921 11.2655 8.89363 11.5194 8.70626 11.7068C8.5189 11.8942 8.26498 11.9997 8 12.0004C7.73614 11.9962 7.48426 11.8895 7.29766 11.7029C7.11105 11.5163 7.00435 11.2644 7.00011 11.0005V9.00075H5.00032C4.73535 9.00007 4.48142 8.8945 4.29405 8.70714C4.10669 8.51977 4.00112 8.26584 4.00043 8.00086C4.00467 7.737 4.11138 7.48514 4.29798 7.29853C4.48459 7.11193 4.73646 7.00522 5.00032 7.00097H7.00011V5.00119C7.0008 4.73621 7.10636 4.48229 7.29373 4.29492C7.4811 4.10755 7.73503 4.00198 8 4.0013C8.26387 4.00554 8.51573 4.11225 8.70233 4.29886C8.88894 4.48546 8.99565 4.73733 8.99989 5.00119V7.00097H10.9997C11.2647 7.00166 11.5186 7.10722 11.7059 7.29459C11.8933 7.48196 11.9989 7.73589 11.9996 8.00086C11.9953 8.26473 11.8886 8.51659 11.702 8.70319C11.5154 8.8898 11.2635 8.99651 10.9997 9.00075H8.99989V11.0005Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_958_1686">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgAdd);
export default Memo;
