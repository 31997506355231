import * as React from "react";
import { memo } from "react";
const SvgFloor = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 13.7827V2.78275H3.55547V3.8472H1.0665V12.7178H3.55547V13.7824L0 13.7827ZM12.4445 13.7827V12.718H14.9335V3.8471H4.97794V2.78265H16V13.7826L12.4445 13.7827ZM7.46675 13.7827V12.718H11.0222V13.7827H7.46675ZM9.24441 6.33102V4.20202H10.311V6.33117L9.24441 6.33102ZM9.24441 12.3634V7.7503H10.311V12.3634H9.24441Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgFloor);
export default Memo;
