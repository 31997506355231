const InputsList = ({
  inputs,
  tabId = null,
  showRequiredItems = false,
  render,
}) => {
  let filteredInputs = inputs || [];

  if (tabId) {
    filteredInputs = inputs
      .filter((field) => String(field.tagSubTypeId()) === String(tabId))
      .sort((a, b) => a.order - b.order);
  }

  return filteredInputs
    .filter(
      (field) => field.isRequired() === showRequiredItems && !field.isCombined()
    )
    .sort((a, b) => a.order() - b.order())
    .map((input, i) => {
      return (render && render(input, i)) || null;
    });
};

export default InputsList;
