import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

export const StyledNav = styled.nav`
  ul {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    gap: var(--layout-size-8);

    li {
      text-decoration: none;
    }
  }
`;

const linkCss = css`
  --nav-item-radius: var(--layout-border-radius-8);
  --nav-item-px: var(--layout-spacing-16);
  --nav-item-py: var(--layout-spacing-16);
  --nav-item-bg-color: transparent;
  --nav-item-icon-size: var(--layout-size-16);
  --nav-item-font-size: var(--x-small-medium-font-size);
  --nav-item-animate: var(--animate-1);
  --nav-item-icon-color: var(--font-tint-03);
  --nav-item-font-color: var(--font-tint-03);
  --nav-item-border-color: transparent;

  display: flex;
  align-items: center;
  border-top-left-radius: var(--nav-item-radius);
  border-top-right-radius: var(--nav-item-radius);
  transition: var(--nav-item-animate);
  background-color: var(--nav-item-bg-color);
  padding: var(--nav-item-py) var(--nav-item-px);
  border-width: 1px 1px 0 1px;
  border-color: var(--nav-item-border-color);
  border-style: solid;

  &:hover {
    --nav-item-bg-color: var(--layout-background-n20);
    --nav-item-font-color: var(--font-tint-04);
    --nav-item-border-color: #d4d6db;

    .nav__item__icon {
      --nav-item-icon-color: var(--font-tint-04);
    }
  }

  &.active {
    --nav-item-font-color: var(--font-color-shade-accent);
    --nav-item-bg-color: var(--color-accent-50);
    --nav-item-border-color: var(--color-accent-50);

    .nav__item__icon {
      --nav-item-icon-color: var(--font-color-shade-accent);
    }
  }

  .nav__item__icon {
    transition: var(--nav-item-animate);
    width: var(--nav-item-icon-size);
    height: var(--nav-item-icon-size);
    color: var(--nav-item-icon-color);
    margin-right: var(--layout-size-8);
  }

  .nav__item__text {
    transition: var(--nav-item-animate);
    font-size: var(--nav-item-font-size);
    color: var(--nav-item-font-color);
    font-weight: var(--x-small-medium-font-weight);
    line-height: var(--x-small-medium-line-height);
  }
`;

export const StyledNavLink = styled(NavLink)`
  ${linkCss}
`;

export const StyledNavLinkDisabled = styled.div`
  ${linkCss}

  opacity: 0.7;
  cursor: default;

  &:hover {
    background-color: transparent;
    border-color: transparent;
  }
`;
