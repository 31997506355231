import API_ENDPOINTS from "../../../../../services/api-endpoints";
import API_Global from "../../../../../services/api";
export const getAllLanguages = () => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Global.get(`${endpoints.GETALLLANGUAGES}`)
      .then((res) => {
        console.log("Languages :::", res);

        resolve(res.data.result);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const getAllLanguagesAsOptionSource = () => {
  return new Promise((resolve, reject) => {
    getAllLanguages()
      .then((res) => {
        var mapedObjectList =
          res?.map((obj) => {
            console.log("lang-obj", obj);
            return {
              label: obj?.languageName || "",
              value: obj?.languageId || 0,
            };
          }) || [];
        console.log("Language options :::", mapedObjectList);

        resolve(mapedObjectList);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
