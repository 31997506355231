import DeliverablesCell from "./components/Cells/DeliverablesCell";
import DueCell from "./components/Cells/DueCell";
import EpisodeCell from "./components/Cells/EpisodeCell";
import SeriesCell from "./components/Cells/SeriesCell";

export function buildColumn(options) {
  const defaultOptions = {
    header: options?.label || "",
    key: options?.key,
  };

  if (options.type === "deliverables") {
    return {
      ...defaultOptions,
      size: 300,
      cell: (props) => <DeliverablesCell {...props} />,
      meta: {},
    };
  }

  if (options.type === "due") {
    return {
      ...defaultOptions,
      cell: (props) => <DueCell {...props} />,
      meta: {
        size: "280px",
      },
    };
  }
  if (options.type === "series") {
    return {
      ...defaultOptions,
      cell: (props) => <SeriesCell {...props} />,
      meta: {
        size: "142px",
      },
    };
  }

  if (options.type === "episode") {
    return {
      ...defaultOptions,
      cell: (props) => <EpisodeCell {...props} />,
      meta: {
        size: "142px",
      },
    };
  }
}
