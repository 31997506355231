import { createBrowserRouter } from "react-router-dom";

import allRoutes from "./main-routes";

const useAppRoutes = () => {
  const root = createBrowserRouter(allRoutes);

  return root;
};

export default useAppRoutes;
