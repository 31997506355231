import produce from "immer";
import create from "zustand";
import { devtools } from "zustand/middleware";

let store = () => ({
  isVisible: false,
  doSubmit: false,
  currentActiveStepKey: "step_01",
  activeProjectId: 0,
  actionStatus: {
    hasSkipAndFinish: true,
    hasNext: true,
    hasPrev: false,
    hasSkip: false,
    hasFinish: false,
  },
  steps: [
    {
      key: "step_01",
      modalTitle: "Project Setup Wizard",
      title: "Basic Information",
      subTitle: "Manage your Project specific details here",
      isActive: true,
      isCompleted: false,
      isEnabled: true,
      options: {},
      data: null,
    },
    {
      key: "step_02",
      modalTitle: "Setup Wizard",
      title: "Episode Setup",
      subTitle: "Manage your Project specific details here",
      isActive: false,
      isCompleted: false,
      isEnabled: true,
      options: {
        modalWidth: 755,
        showProjectName: true,
      },
      data: null,
    },
    {
      key: "step_03",
      modalTitle: "Setup Wizard",
      title: "Technical Specs",
      subTitle: "Manage your Project specific details here",
      isActive: false,
      isCompleted: false,
      isEnabled: true,
      options: {
        showProjectName: true,
      },
      data: null,
    },
    {
      key: "step_04",
      modalTitle: "Setup Wizard",
      title: "Project Supporting Documents ",
      subTitle: "Manage your Project specific details here",
      isActive: false,
      isCompleted: false,
      isEnabled: false,
      options: {
        showProjectName: true,
      },
      data: null,
    },
    {
      key: "step_05",
      modalTitle: "Setup Wizard",
      title: "Departments ",
      subTitle: "Manage your Project specific details here",
      isActive: false,
      isCompleted: false,
      isEnabled: false,
      options: {
        showProjectName: true,
      },
      data: null,
    },
    {
      key: "step_06",
      modalTitle: "Setup Wizard",
      title: "Module Selection",
      subTitle: "Manage your Project specific details here",
      isActive: false,
      isCompleted: false,
      isEnabled: true,
      options: {
        showProjectName: true,
      },
      data: null,
    },
  ],
});

store = devtools(store, { name: "project_wizard" });

export const useProjectWizardStore = create(store);
const state = useProjectWizardStore;
export const projectWizardSubscribe = state.subscribe;

// outside functions

export const ModalVisibility = {
  open: () => {
    console.log("cc");
    state.setState(
      produce((draft) => {
        draft.isVisible = true;
        draft.steps[0].isActive = true;
      })
    );
  },

  close: () => {
    state.setState(
      produce((draft) => {
        draft.isVisible = false;
        draft.doSubmit = false;
        draft.actionStatus.hasSkipAndFinish = true;
        draft.actionStatus.hasNext = true;
        draft.actionStatus.hasPrev = false;
        draft.actionStatus.hasSkip = false;
        draft.actionStatus.hasFinish = false;
        draft.currentActiveStepKey = "step_01";
        const steps = draft.steps;
        steps.forEach((step) => {
          draft.steps.find((s) => s.key === step.key).data = null;
        });
      })
    );
  },
};

export const addSteps = (steps) => {
  state.setState(
    produce((draft) => {
      draft.steps = steps;
    })
  );
};

export const onComplete = (key, callback) => {
  state.setState(
    produce((draft) => {
      const step = draft.steps
        .filter((d) => d.isEnabled)
        .find((step) => step.key === key);

      if (step) {
        step.isCompleted = true;
        callback && callback(state);
      }
    })
  );
};

export const updateActionsState = () => {
  state.setState(
    produce((draft) => {
      const steps = draft.steps.filter((d) => d.isEnabled);

      const currentIndex = steps.findIndex(
        (step) => step.key === draft.currentActiveStepKey
      );

      draft.actionStatus.hasFinish = false;
      draft.actionStatus.hasSkipAndFinish = true;
      draft.actionStatus.hasSkip = false;
      draft.actionStatus.hasNext = true;
      draft.actionStatus.hasPrev = false;

      if (currentIndex !== 0) {
        draft.actionStatus.hasPrev = true;
        draft.actionStatus.hasSkipAndFinish = false;
        draft.actionStatus.hasSkip = true;
      }

      if (currentIndex === steps.length - 1) {
        draft.actionStatus.hasFinish = true;
        draft.actionStatus.hasNext = false;
        draft.actionStatus.hasSkip = false;
      }
    })
  );
};

const navigate = (to = 1) => {
  state.setState(
    produce((draft) => {
      draft.doSubmit = false;
      const currentStepKey = state.getState()?.currentActiveStepKey;

      const steps = draft.steps.filter((d) => d.isEnabled);

      if (to === 1) {
        steps.find((step) => step.key === currentStepKey).isCompleted = true;
      }

      steps.forEach((step, i) => {
        if (step.key === currentStepKey) {
          step.isActive = false;
          const newStep = steps[i + to];

          if (newStep?.key) {
            newStep.isActive = true;
            draft.currentActiveStepKey = newStep?.key;
          }
        }
      });
    })
  );

  updateActionsState();
};

export const onNext = () => {
  navigate(1);
};

export const onBeforeNext = () => {
  state.setState(
    produce((draft) => {
      draft.doSubmit = true;
    })
  );
};

export const onUpdateDoSubmitState = (_state = false) => {
  state.setState(
    produce((draft) => {
      draft.doSubmit = _state;
    })
  );
};

export const onPrevious = () => {
  navigate(-1);
};

export const getCurrentStep = () => {
  const currentStepKey = state.getState()?.currentActiveStepKey;

  return (
    state.getState()?.steps?.find((step) => step?.key === currentStepKey) ||
    null
  );
};

export const onSkip = () => {
  state.setState(
    produce((draft) => {
      const steps = draft.steps.filter((d) => d.isEnabled);
      const lastStep = steps.findLast((step) => step);

      steps.forEach((step) => {
        step.isCompleted = true;
        step.isActive = false;
      });

      lastStep.isActive = true;
      lastStep.isCompleted = false;
      draft.currentActiveStepKey = lastStep?.key;
    })
  );

  updateActionsState();
};
export const updateStepData = (__key, __data) => {
  state.setState(
    produce((draft) => {
      draft.steps.find((s) => s.key === __key).data = __data;
    })
  );
};
export const updateStepDataProjectId = (__key, __projectId) => {
  state.setState(
    produce((draft) => {
      draft.steps.find((s) => s.key === __key).data.projectId = __projectId;
    })
  );
};
export const setCurrentStep = (__key) => {
  state.setState(
    produce((draft) => {
      draft.currentActiveStepKey = __key;
    })
  );
};
export const getSpecificStep = (__key) => {
  return state.getState()?.steps?.find((step) => step?.key === __key) || null;
};
export const setActiveProjectId = (id) => {
  state.setState(
    produce((draft) => {
      draft.activeProjectId = id;
    })
  );
};
