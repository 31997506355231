import React, { useRef, useState, useEffect } from "react";
import ImageUpload from "../../../controls/imageUpload/imageUpload";
import "../../../sass/settings/card__common.scss";
import deparment_icon from "../../../img/department.png";
import close_icon from "../../../img/close-circle.png";
import edit_icon from "../../../img/edit.png";
import API from "../../../../services/api-tenant";
import API_GLOBLE from "../../../../services/api";
import API_ENDPOINTS from "../../../../services/api-endpoints";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Loader from "react-loader-spinner";
import SESSION from "../../../../services/session";
import { confirm } from "react-confirm-box";
import SettingAccordion from "../../../ui-components/pages-ui/Setting/SettingPanel/SettingAccordion";
import SettingPanel from "../../../ui-components/pages-ui/Setting/SettingPanel";
import FromGroup from "../../../controls/FromGroup";
import Button, { BUTTON_ICONS } from "../../../ui-components/Buttons/Button";
import Card from "../../../ui-components/Card";
import { ROUTE_OPTIONS } from "../../../../constant/route-options";
import { useRouteLoaderData } from "react-router-dom";
import { SETTING_PERMISSION_MODULES } from "../../../../constant/setting_permission_module";
import { Permission } from "../../../../helpers/permission";

const TanentCompany = () => {
  const { permission } = useRouteLoaderData(
    ROUTE_OPTIONS.setting.children["tenant-company"].id
  );

  const moduleType = SETTING_PERMISSION_MODULES.TenantCompany;

  const permissions = new Permission(permission);

  const basicPermission = permissions.findByName(moduleType.basic.key);
  const departmentPermission = permissions.findByName(
    moduleType.department.key
  );

  const [companyId, setCompanyId] = useState(0);
  const [TenantCompanyId, setTenantCompanyId] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [companyLocation, setCompanyLocation] = useState("");
  const [companyTelephone, setcompanyTelephone] = useState("");
  const [address01, setAddress01] = useState("");
  const [address02, setAddress02] = useState("");
  const [address03, setAddress03] = useState("");
  const [companyDateTimeFormat, setCompanyDateTimeFormat] = useState("");
  const [companyLanguage, setCompanyLanguage] = useState(0);

  const [departmentId, setDepartmentId] = useState(0);
  const [departmentName, setDepartmentName] = useState("");
  const [headOfDepartment, setHeadOfDepartment] = useState("");
  const [dep_email, setDep_email] = useState("");
  const [dep_telephone, setDep_Telephone] = useState("");
  const [departments, setDepartments] = useState("");

  const [btnDepartmentSave, setBtnDepartmentSave] = useState("");
  let USER = JSON.parse(localStorage.getItem("user"));

  let DepartmentList = [];
  let LanguageList = [];
  const [languages, setLanguages] = useState("");
  const [show, setShow] = useState(false); //Loading Indicator
  const [CurrentCompanyName, setCurrentCompanyName] = useState("");

  const [departmentDiv, setShowDepartmentDiv] = useState(false);
  const [departmentViewDiv, setShowDepartmentViewDiv] = useState(false);

  useEffect(() => {
    getAndBindCompanyBasicDetails();
    getAndBindLanguageList();
    getAndBindDepartmentList();
    setBtnDepartmentSave("Add");
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
    } catch (err) {
      console.log(err);
    }
  };
  //*****/ Basic Company Details ******//
  const getAndBindCompanyBasicDetails = async (e) => {
    try {
      setShow(true); //Loading Indicator
      console.log(USER.result.tenantCompanyId);
      let tenantCompanyId = USER.result.tenantCompanyId;
      const endpoints = new API_ENDPOINTS();
      const response = API.get(
        endpoints.GETCOMPANYBYID + "/" + tenantCompanyId
      ).then((response) => {
        console.log(response);
        if (response.data.isSuccess === true) {
          setCompanyId(response.data.result[0].companyId);
          setTenantCompanyId(response.data.result[0].tenantCompanyId);
          setCompanyName(response.data.result[0].companyName);
          setCurrentCompanyName(response.data.result[0].companyName);
          setCompanyLocation(response.data.result[0].location);
          setcompanyTelephone(response.data.result[0].telephone);
          setAddress01(response.data.result[0].addressLine1);
          setAddress02(response.data.result[0].addressLine2);
          setAddress03(response.data.result[0].city);
          setCompanyDateTimeFormat(response.data.result[0].dateFormat);
          setCompanyLanguage(response.data.result[0].defaultLanguage);
          setShow(false); //Loading Indicator
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };
  const saveCompanyBasicDetails = async (e) => {
    let isEditMode = false;

    if (companyName.length === 0) {
      toast.error("Please enter a Company Name");
      return;
    }
    if (companyLocation.length === 0) {
      toast.error("Please enter a Location.");
      return;
    }
    if (companyTelephone.length === 0) {
      toast.error("Please enter a Telepone Number.");
      return;
    }
    if (address01.length === 0) {
      toast.error("Please enter your Address.");
      return;
    }
    if (companyDateTimeFormat.length === 0) {
      toast.error("Please enter Date Time Format.");
      return;
    }
    if (companyLanguage.length === 0) {
      toast.error("Please enter Language.");
      return;
    }

    try {
      const endpoints = new API_ENDPOINTS();
      let End_point = "";
      let tenantCompanyId = USER.result.tenantCompanyId;
      //Create
      if (companyId === 0) {
        //#region Create Company
        // End_point = endpoints.SETUPDEPARTMENT;
        // isEditMode = false;
        // const response = API.post(End_point, {
        //   departmentId: departmentId,
        //   companyId: 1,
        //   departmentName: departmentName,
        //   departmentHead: headOfDepartment,
        // }).then((response) => {
        //   console.log(response);
        //   if (response.data.isSuccess === true) {
        //     if (!isEditMode) {
        //       toast.success("Department created successfully");
        //     } else {
        //       toast.success("Department updated successfully");
        //     }
        //     getAndBindDepartmentList();
        //     setDepartmentId(0);
        //     setDepartmentName("");
        //     setHeadOfDepartment("");
        //     setDep_email("");
        //     setDep_Telephone("");
        //     setBtnDepartmentSave("Add +");
        //     setShow(false); //Loading Indicator off
        //   } else {
        //     console.log(response.data.displayMessage);
        //     toast.error(response.data.displayMessage);
        //     setShow(false); //Loading Indicator off
        //     return;
        //   }
        // });
        //#endregion
        //Update
      } else {
        console.log("update mode");

        isEditMode = true;

        if (CurrentCompanyName !== companyName) {
          console.log("name changed");
          const response = API_GLOBLE.put(endpoints.UPDATEGLOBLECOMPANY, {
            tenantCompanyId: tenantCompanyId,
            tenantId: 1,
            companyName: companyName,
          }).then((response) => {
            console.log(response);
            if (response.data.isSuccess === true) {
              // toast.success(response.data.displayMessage);
              setShow(false); //Loading Indicator off
            } else {
              console.log(response.data.displayMessage);
              toast.error(response.data.displayMessage);
              setShow(false); //Loading Indicator off
              return;
            }
          });
        }

        const response = API.put(
          endpoints.UPDATETANENTCOMPANY +
            "/" +
            tenantCompanyId +
            "/" +
            companyId,
          {
            companyId: companyId,
            tenantCompanyId: tenantCompanyId,
            companyName: companyName,
            location: companyLocation,
            telephone: companyTelephone,
            addressLine1: address01,
            addressLine2: address02,
            city: address03,
            zipCode: "",
            country: "",
            dateFormat: companyDateTimeFormat,
            defaultLanguage: companyLanguage,
            logoUrl: "",
          }
        ).then((response) => {
          console.log(response);
          if (response.data.isSuccess === true) {
            // toast.success("Company Details updated successfully");
            getAndBindCompanyBasicDetails();

            setShow(false); //Loading Indicator off
          } else {
            console.log(response.data.displayMessage);
            toast.error(response.data.displayMessage);
            setShow(false); //Loading Indicator off
            return;
          }
        });
      }
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };
  const getAndBindLanguageList = async (e) => {
    try {
      setShow(true); //Loading Indicator
      const endpoints = new API_ENDPOINTS();
      const response = API_GLOBLE.get(endpoints.GETALLLANGUAGES).then(
        (response) => {
          if (response.data.isSuccess === true) {
            LanguageList = response.data.result;
            console.log(LanguageList);
            const lang_list = LanguageList.map((item) => {
              return (
                <option value={item.languageId}>{item.languageName}</option>
              );
            });
            setLanguages(lang_list);
            setShow(false); //Loading Indicator
          } else {
            console.log(response.data.displayMessage);
            toast.error(response.data.displayMessage);
            setShow(false); //Loading Indicator
            return;
          }
        }
      );
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  //*****/ DEPARTMENTS ******//
  const getAndBindDepartmentList = async (e) => {
    try {
      setShow(true); //Loading Indicator
      const endpoints = new API_ENDPOINTS();
      const response = API.get(endpoints.GETALLDEPARTMENT).then((response) => {
        console.log(response);
        if (response.data.isSuccess === true) {
          DepartmentList = response.data.result;

          const dep_list = DepartmentList.map((item, i) => {
            return (
              <Card
                key={i}
                imgUrl={deparment_icon}
                title={item.departmentName}
                subtitle={item.departmentHead}
                onEdit={
                  departmentPermission.permission.canEdit
                    ? () => editDepartment(item)
                    : null
                }
                onDelete={
                  departmentPermission.permission.canDelete
                    ? () => deleteDepartment(item)
                    : null
                }
              >
                <p>
                  franck@peddalingpictures.com <br /> +94 77 638 1538
                </p>
              </Card>
            );
          });
          setDepartments(dep_list);
          setDepartmentUIViewOptions();
          setShow(false); //Loading Indicator
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };
  const saveDepartment = async (e) => {
    let isEditMode = false;
    if (departmentName.length === 0) {
      toast.error("Please enter a valid Department Name");
      return;
    }
    if (headOfDepartment.length === 0) {
      toast.error("Please enter a valid Head of Department Name.");
      return;
    }
    if (dep_email.length === 0) {
      toast.error("Please enter a Email.");
      return;
    }
    if (dep_telephone.length === 0) {
      toast.error("Please enter a Phone number.");
      return;
    }
    try {
      const endpoints = new API_ENDPOINTS();
      let End_point = "";
      //Create
      if (departmentId === 0) {
        End_point = endpoints.SETUPDEPARTMENT;
        isEditMode = false;
        const response = API.post(End_point, {
          departmentId: departmentId,
          companyId: 1,
          departmentName: departmentName,
          departmentHead: headOfDepartment,
        }).then((response) => {
          //console.log(response);
          if (response.data.isSuccess === true) {
            if (!isEditMode) {
              // toast.success("Department created successfully");
            } else {
              // toast.success("Department updated successfully");
            }

            setDepartmentId(0);
            setDepartmentName("");
            setHeadOfDepartment("");
            setDep_email("");
            setDep_Telephone("");
            getAndBindDepartmentList();
            setShow(false); //Loading Indicator off
          } else {
            console.log(response.data.displayMessage);
            toast.error(response.data.displayMessage);
            setShow(false); //Loading Indicator off
            return;
          }
        });
        //Update
      } else {
        //console.log("update mode");
        End_point = endpoints.UPDATEDEPARTMENT;
        isEditMode = true;
        const response = API.put(End_point + "/" + departmentId, {
          departmentId: departmentId,
          companyId: 1,
          departmentName: departmentName,
          departmentHead: headOfDepartment,
        }).then((response) => {
          // console.log(response);
          if (response.data.isSuccess === true) {
            if (!isEditMode) {
              // toast.success("Department created successfully");
            } else {
              // toast.success("Department updated successfully");
            }

            setDepartmentId(0);
            setDepartmentName("");
            setHeadOfDepartment("");
            setDep_email("");
            setDep_Telephone("");
            getAndBindDepartmentList();
            setShow(false); //Loading Indicator off
          } else {
            console.log(response.data.displayMessage);
            toast.error(response.data.displayMessage);
            setShow(false); //Loading Indicator off
            return;
          }
        });
      }
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };
  const editDepartment = async (e) => {
    try {
      setDepartmentId(e.departmentId);
      setDepartmentName(e.departmentName);
      setHeadOfDepartment(e.departmentHead);
      setDep_email("franck@peddalingpictures.com");
      setDep_Telephone("+94 77 638 1538");
      addDepartment();
    } catch (err) {
      console.log(err);
    }
  };
  const deleteDepartment = async (e) => {
    try {
      // console.log(e);
      const confirmation = await confirm(
        "Are you sure you want to delete this item ?"
      );

      if (!confirmation) {
        return;
      }
      setShow(true); //Loading Indicator on
      const endpoints = new API_ENDPOINTS();
      const response = API.delete(
        endpoints.DELETEDEPARTMENT + "/" + e.departmentId,
        {}
      ).then((response) => {
        // console.log(response);
        if (response.data.isSuccess === true) {
          // toast.success("Department deleted successfully");
          getAndBindDepartmentList();
          setDepartmentId(0);
          setDepartmentName("");
          setHeadOfDepartment("");
          setDep_email("");
          setDep_Telephone("");
          setBtnDepartmentSave("Add");
          setShow(false); //Loading Indicator off
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator off

          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };
  const setDepartmentUIViewOptions = async (e) => {
    try {
      if (DepartmentList.length == 0) {
        setShowDepartmentDiv(false);
        setShowDepartmentViewDiv(true);
      } else {
        setShowDepartmentDiv(true);
        setShowDepartmentViewDiv(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const addDepartment = async (e) => {
    try {
      setShowDepartmentDiv(false);
      setShowDepartmentViewDiv(false);
    } catch (err) {
      console.log(err);
    }
  };
  const cancelDepartment = async (e) => {
    try {
      setDepartmentId(0);
      setDepartmentName("");
      setHeadOfDepartment("");
      setDep_email("");
      setDep_Telephone("");

      setShowDepartmentDiv(true);
    } catch (err) {
      console.log(err);
    }
  };

  // Breadcrumbs List
  const breadcrumbsList = [{ text: "Tenant" }, { text: "Company" }];

  //*****/ UI ******//
  return (
    <>
      <div style={{ justifyContent: "center", display: "flex" }}>
        <Loader.ThreeDots
          visible={show}
          width={100}
          height={30}
          color="#347084"
        ></Loader.ThreeDots>
      </div>

      <SettingPanel breadcrumbs={breadcrumbsList}>
        {/* Basic Details */}
        {basicPermission.permission.canAddNew ? (
          <SettingAccordion title="Basic Details">
            <SettingAccordion.Card>
              <form className="row setting-form">
                <div className="col-md-12">
                  <FromGroup label=" Company Name" inputHeightAuto>
                    <input
                      className="form-control"
                      type="text"
                      id="company_name"
                      name="company_name"
                      placeholder="Your Company Name"
                      aria-label="Company Name"
                      onChange={(e) => setCompanyName(e.target.value)}
                      value={companyName}
                    />
                  </FromGroup>
                </div>
                <div className="col-md-6">
                  <FromGroup label="Location" inputHeightAuto>
                    <input
                      className="form-control"
                      type="text"
                      id="location"
                      name="location"
                      placeholder="Where is this business located"
                      aria-label="Where is this business located"
                      onChange={(e) => setCompanyLocation(e.target.value)}
                      value={companyLocation}
                    />
                  </FromGroup>
                </div>
                <div className="col-md-6">
                  <FromGroup label="Telephone" inputHeightAuto>
                    <input
                      className="form-control"
                      type="phone"
                      id="telephone"
                      name="telephone"
                      placeholder="Telephone Number"
                      aria-label="Telephone Number"
                      onChange={(e) => setcompanyTelephone(e.target.value)}
                      value={companyTelephone}
                    />
                  </FromGroup>
                </div>
                <div className="col-md-4">
                  <FromGroup label="Address" inputHeightAuto>
                    <input
                      className="form-control"
                      type="text"
                      id="address_1"
                      name="address_1"
                      placeholder="Address 1"
                      aria-label="Address 1"
                      onChange={(e) => setAddress01(e.target.value)}
                      value={address01}
                    />
                  </FromGroup>
                </div>
                <div className="col-md-4">
                  <FromGroup label="&nbsp;" inputHeightAuto>
                    <input
                      className="form-control"
                      type="text"
                      id="address_2"
                      name="address_2"
                      placeholder="Address 2"
                      aria-label="Address 1"
                      onChange={(e) => setAddress02(e.target.value)}
                      value={address02}
                    />
                  </FromGroup>
                </div>
                <div className="col-md-4">
                  <FromGroup label="&nbsp;" inputHeightAuto>
                    <input
                      className="form-control"
                      type="text"
                      id="address_3"
                      name="address_3"
                      placeholder="Address 3"
                      aria-label="Address 3"
                      onChange={(e) => setAddress03(e.target.value)}
                      value={address03}
                    />
                  </FromGroup>
                </div>
                <div className="col-md-4">
                  <FromGroup label="Date time format" inputHeightAuto>
                    <input
                      className="form-control"
                      type="text"
                      id="date_time"
                      name="date_time"
                      placeholder="Date Time Format"
                      aria-label="Date Time Format"
                      onChange={(e) => setCompanyDateTimeFormat(e.target.value)}
                      value={companyDateTimeFormat}
                    />
                  </FromGroup>
                </div>
                <div className="col-md-8">
                  <FromGroup label="Language" inputHeightAuto>
                    <select
                      className="form-control"
                      aria-label="Default select example"
                      onChange={(e) => setCompanyLanguage(e.target.value)}
                      value={companyLanguage}
                    >
                      <option selected value={0}>
                        --- Select Language ---
                      </option>
                      {languages}
                    </select>
                  </FromGroup>
                </div>

                <div className="col-12">
                  <FromGroup type="button-inline-left">
                    <Button variant="secondary">Cancel</Button>
                    <Button onClick={saveCompanyBasicDetails}>Save</Button>
                  </FromGroup>
                </div>
              </form>
            </SettingAccordion.Card>
          </SettingAccordion>
        ) : null}

        {departmentPermission.hasPermission ? (
          <SettingAccordion
            title="Departments"
            actions={
              departmentDiv && departmentPermission.permission.canAddNew ? (
                <Button
                  size="sm"
                  variant="green-light"
                  leftIcon={BUTTON_ICONS.plus}
                  onClick={addDepartment}
                >
                  {btnDepartmentSave}
                </Button>
              ) : null
            }
          >
            <SettingAccordion.Card>
              {!departmentDiv &&
              (departmentPermission.permission.canAddNew ||
                departmentPermission.permission.canEdit) ? (
                <form className="row setting-form">
                  {/* <FromGroup label="" inputHeightAuto></FromGroup> */}

                  <div className="col-md-12">
                    <FromGroup label="Department Name" inputHeightAuto>
                      <input
                        className="form-control"
                        type="text"
                        id="department_name"
                        name="department_name"
                        placeholder="Your Department Name"
                        aria-label="Department Name"
                        onChange={(e) => setDepartmentName(e.target.value)}
                        value={departmentName}
                      />
                    </FromGroup>
                  </div>

                  <div className="col-md-4">
                    <FromGroup label="Head Of Department" inputHeightAuto>
                      <input
                        className="form-control"
                        type="text"
                        id="head_of_department"
                        name="head_of_department"
                        placeholder="Head Of Department"
                        aria-label="Head Of Department"
                        onChange={(e) => setHeadOfDepartment(e.target.value)}
                        value={headOfDepartment}
                      />
                    </FromGroup>
                  </div>

                  <div className="col-md-4">
                    <FromGroup label="Email" inputHeightAuto>
                      <input
                        className="form-control"
                        type="text"
                        id="emailAddress"
                        name="emailAddress"
                        placeholder="Email Address"
                        aria-label="Email Address"
                        onChange={(e) => setDep_email(e.target.value)}
                        value={dep_email}
                      />
                    </FromGroup>
                  </div>

                  <div className="col-md-4">
                    <FromGroup label="Phone" inputHeightAuto>
                      <input
                        className="form-control"
                        type="text"
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder="Phone Number"
                        aria-label="Phone Number"
                        onChange={(e) => setDep_Telephone(e.target.value)}
                        value={dep_telephone}
                      />
                    </FromGroup>
                  </div>

                  <div className="col-12">
                    <FromGroup type="button-inline-left">
                      {departments.length > 0 ? (
                        <Button variant="secondary" onClick={cancelDepartment}>
                          Cancel
                        </Button>
                      ) : null}

                      <Button onClick={saveDepartment}>Save</Button>
                    </FromGroup>
                  </div>
                </form>
              ) : null}

              {departments.length > 0 &&
              departmentPermission.permission.canView ? (
                <div className="setting-details">
                  <div className="row">
                    <div className="col-12">
                      <Card.Wrapper>{departments}</Card.Wrapper>
                    </div>
                  </div>
                </div>
              ) : null}
            </SettingAccordion.Card>
          </SettingAccordion>
        ) : null}
      </SettingPanel>
    </>
  );
};

export default TanentCompany;
