import { getMarginAndBoarderTopSize } from "../helpers";

export const ActionTypes = {
  Init: "init",
  MouseDown: "mouse_down",
  MouseMove: "mouse_move",
  MouseUp: "mouse_up",
  Reset: "reset",
};

export const initialSelectionState = {
  isMousePressed: false,
  startX: 0,
  startY: 0,
  x: 0,
  y: 0,
  width: 0,
  maxDrawnWidth: 0,
  height: 0,
  cellsContainer: null,
  selectedItems: new Set(),
  layerPosition: {
    startX: 0,
    startY: 0,
    x: 0,
    y: 0,
  },
  getLayerStyles: () => {},
};

export function selectionReducer(state, action) {
  const type = action.type;
  const payload = action?.payload;

  const status = {
    // Reset to initial state
    [ActionTypes.Reset]: initialSelectionState,

    // Fire when mouse first down
    [ActionTypes.Init]: {
      ...state,
      isMousePressed: payload?.isMousePressed,
    },

    // Mouse down event state
    [ActionTypes.MouseDown]: {
      ...state,
      startX: payload?.startX,
      startY: payload?.startY,
      width: payload?.width,
      maxDrawnWidth: payload?.maxDrawnWidth,
      layerPosition: payload?.layerPosition,
      selectedItems: state?.selectedItems?.add(payload?.item),
      cellsContainer: payload?.cellsContainer,
    },

    // Mouse move event state
    [ActionTypes.MouseMove]: {
      ...state,
      x: payload?.x,
      y: payload?.y,
      layerPosition: {
        ...state.layerPosition,
        x: payload?.layerPosition?.x,
        y: payload?.layerPosition?.y,
      },
      selectedItems: state.selectedItems.add(payload?.item),
      // getLayerStyles: (containerRef, scrollTop) => {
      //   return __getLayerStyles(containerRef, scrollTop, state);
      // },
    },

    // Mouse up event state
    [ActionTypes.MouseUp]: {
      ...state,
      cellsContainer: null,
      isMousePressed: false,
      layerPosition: {
        startX: 0,
        startY: 0,
        x: 0,
        y: 0,
      },
    },
  };

  if (status[type]) {
    return status[type];
  }

  return state;
}
