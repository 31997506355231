import usePagePermission from "../../../hooks/usePagePermission";

const useSettingPermission = ({ pageKey }) => {
  const { findByName } = usePagePermission({ key: pageKey });

  const roleCreatePermission = findByName("create-new-role");

  return {
    roleCreatePermission: roleCreatePermission?.permission?.canAddNew,
    scheduleAddBtnPermission: true,
  };
};

export default useSettingPermission;
