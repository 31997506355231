import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.9919 7.73826L7.98388 10.7463L4.97582 7.73826L5.95258 6.7615C5.99241 6.89426 6.0588 7.01944 6.16311 7.12565C6.49692 7.45946 7.03746 7.45946 7.36937 7.12565C7.70128 6.79184 7.70318 6.2513 7.36937 5.91939C7.26505 5.81508 7.13798 5.7468 7.00522 5.70887L7.98198 4.7321L8.97202 5.72214C9.00806 5.5761 9.08013 5.43575 9.19393 5.32006C9.52774 4.98625 10.0683 4.98625 10.4002 5.32006C10.7321 5.65386 10.734 6.19441 10.4002 6.52632C10.2864 6.64011 10.146 6.71408 9.9981 6.74822L10.9881 7.73826H10.9919ZM13.9678 14.5149C13.9678 15.3362 13.302 16 12.4827 16H3.48506C2.66382 16 2 15.3343 2 14.5149V2.6496C2 1.82835 2.66572 1.16453 3.48506 1.16453H4.60597C4.46942 1.4073 4.39165 1.68611 4.39165 1.98198V2.72926H3.56472V14.4334H9.65861V11.689H12.403V2.72926H11.5761V1.98198C11.5761 1.68421 11.4983 1.4073 11.3618 1.16453H12.4827C13.3039 1.16453 13.9678 1.83025 13.9678 2.6496V14.5149ZM12.1185 12.2598H10.2276V14.1508L12.1185 12.2598ZM9.89948 0.921764H9.32859V0H6.63727V0.921764H6.06638C5.45567 0.921764 4.96064 1.41679 4.96064 2.0275V3.14272H11.0052V2.0275C11.0052 1.41679 10.5102 0.921764 9.89948 0.921764Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
