import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToastMessageContext = React.createContext({
  toast,
  fireToast: (callback) => {},
});

export const useToastMessage = () => {
  return React.useContext(ToastMessageContext);
};

export const ToastMessageProvider = ({ children }) => {
  
  const fireToast = (callback) => {
    callback && callback(toast);
  };

  return (
    <ToastMessageContext.Provider value={{ toast, fireToast }}>
      {children}
      <ToastContainer position="top-center" style={{ zIndex: 99999999999 }} />
    </ToastMessageContext.Provider>
  );
};
