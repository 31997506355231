import Swal from "sweetalert2";
import Warning from "./../assets/icons/svg/Line/Warning/01.svg";

// Styles override
const Alert = Swal.mixin({
  customClass: {
    container: "sys__alert",
    confirmButton: "btn-alert",
    cancelButton: "btn-alert btn-alert-cancel ",
    // showClass: {
    //   popup: "swal2-show1",
    //   backdrop: "swal2-backdrop-show1",
    //   icon: "swal2-icon-show1",
    // },
    // hideClass: {
    //   popup: "swal2-hide",
    //   backdrop: "swal2-backdrop-hide",
    //   icon: "swal2-icon-hide",
    // },
  },
  buttonsStyling: false,
  reverseButtons: true,
});

// Confirm Dialog modal
Alert.confirm = (obj) => {
  const { showCancelButton = true } = obj;
  return Swal.mixin({
    customClass: {
      container: "sys__alert",
      confirmButton: "btn-alert",
      cancelButton: showCancelButton ? "btn-alert btn-alert-cancel" : "",
    },
    iconHtml: `<img class="warning__icon" src="${Warning}" />`,
    reverseButtons: true,
  }).fire(obj);
};



export default Alert;
