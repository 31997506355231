import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.3421 5.36633C14.3052 5.38372 14.2677 5.39216 14.2295 5.39216C14.1281 5.39216 14.0319 5.33329 13.9861 5.23363C13.9234 5.09613 13.9814 4.93215 14.1158 4.86846L15.29 4.30754C14.6014 4.00424 13.6953 4.23966 13.1574 4.4667C12.5838 4.70756 11.2061 5.44985 11.2061 5.44985L8.24367 4L6.1222 4.54172L9.09701 7.0288L6.3615 8.5369L5.32692 8.09666L3.60429 8.67441L6.04356 10.4401C6.04356 10.4401 13.7041 6.78199 15.1633 6.08579C16.107 5.63472 16.1498 5.08044 15.7944 4.6726L14.3421 5.36633Z"
      fill="currentColor"
    />
    <path
      d="M2.36535 11.9999C1.49144 11.9999 0.78004 11.7158 0.378863 11.1512C-0.384216 10.0774 0.236939 8.2636 0.263332 8.18737C0.296751 8.09246 0.398231 8.0426 0.492683 8.07626C0.585365 8.11048 0.633447 8.21502 0.600577 8.31049C0.594712 8.32732 0.0176125 10.0192 0.668026 10.9351C1.3402 11.8817 3.18238 11.8582 5.04874 10.8799C5.13672 10.8342 5.24461 10.8691 5.28922 10.9598C5.33382 11.0493 5.29979 11.1598 5.2112 11.206C4.20123 11.7357 3.20766 12 2.36543 12L2.36535 11.9999Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
