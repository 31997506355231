import React from "react";
import { styleClass } from "../../../../../utility/common";

import "./styles.scss";

const SettingPanel = (props) => {
  const { children, breadcrumbs = [], actions, className } = props;

  const getBreadcrumbs = React.useMemo(() => {
    return (
      breadcrumbs &&
      breadcrumbs.map((breadcrumb, index) => {
        let lastClass =
          index === breadcrumbs.length - 1 ? "breadcrumb__text--last" : "";

        return (
          <span
            key={index}
            className={styleClass("breadcrumb__text", lastClass)}
          >
            {breadcrumb?.text}

            {/* Show arrow */}
            {index === breadcrumbs.length - 1 ? null : (
              <i className="icon-arrow icon icon-lp-arrow-right-line"></i>
            )}
          </span>
        );
      })
    );
  }, [breadcrumbs]);

  return (
    <div className={styleClass("setting__panel", className)}>
      {/* Header */}
      <div className="setting__panel__header">
        <div className="breadcrumbs">{getBreadcrumbs}</div>
        <div className="actions">{actions}</div>
      </div>

      {/* Contents */}
      <div className="setting__panel__contents">{children}</div>
    </div>
  );
};

export default SettingPanel;
