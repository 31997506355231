import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import { getProjectByID } from "../../Actions/Project";
import { clearDocumentStore } from "../../pages/research-board/screens/ResearchDocuments/store/DocumentStore";
import { WebStorage } from "../../utility/module/WebStorage";
import { STORAGE_KEYS } from "../../constant/System";

let store = (set, get) => ({
  selectedProjectId: 0,
  selectedProject: null,
  currentTenant: null,
});

store = devtools(store, { name: "Global Store" });

store = persist(store, {
  name: "global_store",
  serialize: (state) => btoa(encodeURI(JSON.stringify(state))),
  deserialize: (str) => JSON.parse(decodeURI(atob(str))),
});

export const useGlobalStore = create(store);

export const setGlobalProject = (id) => {
  return new Promise((resolve, reject) => {
    getProjectByID(id)
      .then((project) => {
        useGlobalStore.setState({
          selectedProject: project,
          selectedProjectId: id,
        });
        WebStorage.set(STORAGE_KEYS.CURRENT_PROJECT_ID, id || 0);

        resolve(project);
        clearDocumentStore();
      })
      .catch((err) => {
        useGlobalStore.setState({
          selectedProject: null,
          selectedProjectId: id,
        });
        WebStorage.set(STORAGE_KEYS.CURRENT_PROJECT_ID, 0);
        reject(false);
      });
  });
};

export const removeGlobalProject = () => {
  useGlobalStore.setState({
    selectedProject: null,
    selectedProjectId: 0,
    // currentTenant: null,
  });
  WebStorage.set(STORAGE_KEYS.CURRENT_PROJECT_ID, 0);
};

export const setCurrentTenant = (tenantList = [], tenantId) => {
  const current = tenantList.find((d) => d?.tenantId === tenantId);

  useGlobalStore.setState({
    currentTenant: current,
  });
};
