import React from "react";
import { DropDownMenu, List } from "./styled";
import UIButton from "../../../../../common/components/Buttons/UIButton";
import IconMenu from "../../../../../common/components/icons/Fill/menu";
import IconButton from "../../../../../common/components/Buttons/IconButton";
import { PopoverDropdownMenuItem } from "../../../../../common/components/UI/PopoverDropdown";

const ProjectListView = (props) => {
  const {
    data,
    handleBrief,
    handleOnboarding,
    handleDelete,
    handleFeature,
    handleEdit,
  } = props;

  console.log(data);
  return (
    <List>
      {data?.length > 0 ? (
        data?.map((row, i) => {
          return (
            <div className="list__item" key={i}>
              <div className="list__item__col mw-0">
                <div className="project-name">
                  <span>{row?.name}</span>
                </div>
              </div>
              <div className="list__item__col list__item__col--right gap-8">
                <div>
                  <UIButton
                    className="tb__button"
                    size={UIButton.Sizes.Small}
                    onClick={() => handleOnboarding && handleOnboarding(row)}
                  >
                    Onboarding
                  </UIButton>
                  <DropDownMenu
                    customDropdownIcon={
                      <IconButton
                        as="span"
                        icon={<IconMenu />}
                        btnIconSize={16}
                        btnSize={24}
                        hover="var(--font-tint-04)"
                      />
                    }
                    options={[
                      {
                        label: "Project Brief",
                        onClick: () => handleBrief && handleBrief(row),
                        disabled: !handleBrief,
                      },
                      {
                        label: "Features ",
                        onClick: () => handleFeature && handleFeature(row),
                        disabled: !handleFeature,
                      },
                      {
                        label: "Edit",
                        onClick: () => {
                          handleEdit && handleEdit(row);
                        },
                        disabled: !handleEdit,
                      },
                      {
                        label: "Delete",
                        onClick: () => {
                          handleDelete && handleDelete(row);
                        },
                        disabled: !handleDelete,
                      },
                    ]}
                    render={(option, { closeDropdown }) => {
                      return (
                        <PopoverDropdownMenuItem
                          label={option?.label}
                          onClick={() => {
                            closeDropdown();
                            option?.onClick && option?.onClick();
                          }}
                          isDisabled={option?.disabled}
                        />
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="list__item">
          <div className="list__item__col">
            <div>No projects available.</div>
          </div>
        </div>
      )}
    </List>
  );
};

export default ProjectListView;
