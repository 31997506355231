import React from "react";

const useContainerDimensions = (elementRef) => {
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });

  React.useEffect(() => {
    const getDimensions = () => ({
      width: elementRef?.current?.offsetWidth || 0,
      height: elementRef?.current?.offsetHeight || 0,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (elementRef.current) {
      setTimeout(() => {
        setDimensions(getDimensions());
      }, 10);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [elementRef]);

  return dimensions;
};

export default useContainerDimensions;
