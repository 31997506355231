import React from "react";
import ReactDOM from "react-dom";
import { AnimatePresence } from "framer-motion";

import { LayoutWrapper } from "./styled";
import { onLayoutLoaded } from "../../Store/ChatBaseStore";
import AddNewGroupDialog from "../../Shared/Dialogs/AddNewGroupDialog";
import AddNewMemberDialog from "../../Shared/Dialogs/AddNewMemberDialog";
import AddNewIndividualMemberDialog from "../../Shared/Dialogs/AddNewIndividualMemberDialog";

const variant = {
  visible: {
    x: 0,
    opacity: 1,
    transition: { type: "spring", duration: 0.5 },
  },
  hidden: {
    x: "28px",
    opacity: 0,
    transition: { type: "spring", duration: 0.5 },
  },
};

const BaseLayout = (props) => {
  const {
    body,
    animationKey,
    header,
    visibility = false,
    onAnimationDone,
    isAnimationsEnabled = true,
    ...otherProps
  } = props;

  const component = (
    <LayoutWrapper
      key={animationKey}
      initial="hidden"
      animate="visible"
      exit="hidden"
      // custom={isMinimized ? "calc(100% - var(--layout-size-52))" : 0}
      variants={isAnimationsEnabled ? variant : null}
      {...otherProps}
    >
      <div className="layout__header">{header}</div>

      <div className="layout__content">
        {body}

        <div id="chat_dialog_container">
          {/* Add new group */}
          <AddNewGroupDialog />

          {/* Add New Member Dialog */}
          <AddNewMemberDialog />

          {/* Add New Member Dialog Main Chat*/}
          <AddNewIndividualMemberDialog />
        </div>
      </div>
    </LayoutWrapper>
  );

  React.useEffect(() => {
    onLayoutLoaded(true);

    return () => {
      onLayoutLoaded(false);
    };
  }, []);

  return ReactDOM.createPortal(
    <AnimatePresence
      onExitComplete={(p) =>
        onAnimationDone && onAnimationDone(animationKey, p)
      }
    >
      {visibility && component}
    </AnimatePresence>,
    document.getElementById("chat_box_container")
  );
};

export default BaseLayout;
