import React from "react";
import { useQuery } from "react-query";
import { getTagTypes } from "../api/common";

const useTagTypes = (key, { enabled = false, cacheTime = 5 * 60 * 1000 }) => {
  const tagTypesQuery = useQuery([key], getTagTypes, {
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    retry: false,
    enabled: enabled,
    cacheTime: cacheTime,
  });

  const sortData = React.useMemo(() => {
    if (!tagTypesQuery?.data) return [];

    return tagTypesQuery?.data?.sort((a, b) => {
      return a.name().localeCompare(b.name());
    });
  }, [tagTypesQuery?.data]);

  return {
    query: tagTypesQuery,
    tagTypeItems: sortData,
  };
};

export default useTagTypes;
