import styled from "styled-components";

export const StyledSlideContainer = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;

  &::after {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 55.85%,
      #ffffff 100%
    );

    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    display: block;
    z-index: 1;
    pointer-events: none;
  }

  .swiper {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-slide {
    background-size: cover;
    background-position: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .thumbs__outer {
  }

  .thumbs__wrapper {
    height: ${({ thumbSize }) => thumbSize || "24px"};
    position: absolute;
    bottom: ${({ theme }) => theme.spacing(2)};
    width: ${({ thumbContainerWidth }) => thumbContainerWidth || "100%"};
    padding-inline: ${({ theme }) => theme.spacing(1.5)};
    z-index: 2;

    .swiper-slide {
      position: relative;
      border-radius: ${({ theme }) => theme.sizes(1)};
      overflow: hidden;
      border: 1px solid #fff;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
      }
    }
  }
`;
