import React from "react";
import { useRouteLoaderData } from "react-router-dom";
import { ROUTE_OPTIONS } from "../../../../../../constant/route-options";
import { SETTING_PERMISSION_MODULES } from "../../../../../../constant/setting_permission_module";
import { Permission } from "../../../../../../helpers/permission";
import { useRoleRightStore } from "../../../../../../store/Setting/Permission";

import RoleRightForm from "./RoleRightForm";
import RoleRightTable from "./RoleRightTable";
import "./styles.scss";

const RoleRight = (props) => {
  const { variant = undefined } = props;

  const { permission } = useRouteLoaderData(
    ROUTE_OPTIONS.setting.children["tenant-permission"].id
  );
  const moduleType = SETTING_PERMISSION_MODULES.TenantPermission;
  const permissions = new Permission(permission);
  const roleRightPermission = permissions.findByName(
    moduleType.AssignRoleRights.key
  );

  if (!variant) {
    throw new Error("variant props not defined for role");
  }

  const showForm = useRoleRightStore((state) => state.showForm);

  const setVariant = useRoleRightStore((state) => state.setVariant);

  const onLoadRolesOptions = useRoleRightStore(
    (state) => state.onLoadRolesOptions
  );

  const onLoadModuleOptions = useRoleRightStore(
    (state) => state.onLoadModuleOptions
  );

  React.useEffect(() => {
    setVariant(variant);
  }, [setVariant, variant]);

  React.useEffect(() => {
    onLoadRolesOptions();
    onLoadModuleOptions();
  }, [onLoadRolesOptions, onLoadModuleOptions]);

  return (
    <div className="role__wrapper">
      {/* <RoleRightForm /> */}
      <RoleRightForm />

      {roleRightPermission.permission.canView ? <RoleRightTable /> : null}
    </div>
  );
};

export default RoleRight;
