import React, { useState, useEffect, lazy } from "react";
import "../../../sass/project/setup.scss";
import API from "../../../../services/api";
import API_ENDPOINTS from "../../../../services/api-endpoints";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Loader from "react-loader-spinner";
import { confirm } from "react-confirm-box";
import SettingPanel from "../../../ui-components/pages-ui/Setting/SettingPanel";
import SettingAccordion from "../../../ui-components/pages-ui/Setting/SettingPanel/SettingAccordion";
import Button, { BUTTON_ICONS } from "../../../ui-components/Buttons/Button";
import FromGroup from "../../../controls/FromGroup";
import Chip from "../../../ui-components/Chip";
import { ROUTE_OPTIONS } from "../../../../constant/route-options";
import { SETTING_PERMISSION_MODULES } from "../../../../constant/setting_permission_module";
import { Permission } from "../../../../helpers/permission";
import { useRouteLoaderData } from "react-router-dom";

const ProjectSetup = () => {
  const { permission } = useRouteLoaderData(
    ROUTE_OPTIONS.setting.children["project-setup"].id
  );

  const moduleType = SETTING_PERMISSION_MODULES.ProjectSetup;

  const permissions = new Permission(permission);

  const genrePermission = permissions.findByName(moduleType.genre.key);

  const [show, setShow] = useState(false); //Loading Indicator
  const [genresId, setGenresId] = useState(0);
  const [genresName, setGenresName] = useState("");
  const [allGenres, setAllGenres] = useState([]);
  let GenresList = [];

  const [hideGenresDataInputDiv, setHideGenresDataInputDiv] = useState(false);
  const [hideGenresViewtDiv, setHideGenresViewtDiv] = useState(false);
  useEffect(() => {
    getAndBindGenresList();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
    } catch (err) {
      console.log(err);
    }
  };
  //*****/ GENRES ******//
  const getAndBindGenresList = async (e) => {
    try {
      setShow(true); //Loading Indicator
      const endpoints = new API_ENDPOINTS();
      const response = API.get(endpoints.GENRES_GETALL).then((response) => {
        console.log(response);
        if (response.data.isSuccess === true) {
          GenresList = response.data.result;
          console.log("genlist", GenresList);
          const genere_list = GenresList.map((item, i) => {
            console.log("item", item);
            return (
              <Chip
                key={i}
                onDelete={
                  genrePermission.permission.canDelete
                    ? () => deleteGenere(item)
                    : null
                }
                onEdit={
                  genrePermission.permission.canEdit
                    ? () => editGenere(item)
                    : null
                }
              >
                <Chip.Items>
                  <Chip.Node text={item.genereName} />
                </Chip.Items>
              </Chip>
            );
          });
          setAllGenres(genere_list);
          setGenresUIViewOptions();
          //   setHideGenresDataInputDiv(false);
          setHideGenresViewtDiv(false);
          setShow(false); //Loading Indicator
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };
  const setGenresUIViewOptions = async (e) => {
    try {
      if (GenresList.length === 0) {
        setHideGenresDataInputDiv(false);
        setHideGenresViewtDiv(true);
      } else {
        setHideGenresDataInputDiv(true);
        setHideGenresViewtDiv(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const saveGenres = async (e) => {
    //console.log(index);
    let isEditMode = false;
    if (genresName.length === 0) {
      toast.error("Please enter a Genre Name");
      return;
    }

    setShow(true); //Loading Indicator on
    try {
      const endpoints = new API_ENDPOINTS();
      let End_point = "";
      //Create
      if (genresId === 0) {
        End_point = endpoints.GENRES_CREATE;
        isEditMode = false;
        const response = API.post(End_point, {
          genereId: genresId,
          genereName: genresName,
        }).then((response) => {
          console.log(response);
          if (response.data.isSuccess === true) {
            if (!isEditMode) {
              // toast.success("Genre created successfully");
            } else {
              // toast.success("Genre updated successfully");
            }
            setGenresId(0);
            setGenresName("");
            getAndBindGenresList();

            setShow(false); //Loading Indicator off
          } else {
            // toast.success(response.data.displayMessage);
            console.log(response.data.displayMessage);
            toast.error(response.data.displayMessage);
            setShow(false); //Loading Indicator off
            return;
          }
        });
      } else {
        // Update
        End_point = endpoints.GENRES_UPDATE;
        isEditMode = true;
        const response = API.put(End_point + "/" + genresId, {
          genereId: genresId,
          genereName: genresName,
        }).then((response) => {
          console.log(response);
          if (response.data.isSuccess === true) {
            if (!isEditMode) {
              // toast.success("Genre created successfully");
            } else {
              // toast.success("Genre updated successfully");
            }
            setGenresId(0);
            setGenresName("");
            getAndBindGenresList();

            setShow(false); //Loading Indicator off
          } else {
            // toast.success(response.data.displayMessage);
            console.log(response.data.displayMessage);
            toast.error(response.data.displayMessage);
            setShow(false); //Loading Indicator off
            return;
          }
        });
      }
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };
  const editGenere = async (e) => {
    try {
      setGenresId(e.genereId);
      setGenresName(e.genereName);
      addGenere();
    } catch (err) {
      console.log(err);
    }
  };
  const addGenere = async (e) => {
    try {
      setHideGenresDataInputDiv(false);
      setHideGenresViewtDiv(false);
    } catch (err) {
      console.log(err);
    }
  };
  const cancelGenere = async (e) => {
    try {
      setGenresId(0);
      setGenresName("");
      setHideGenresDataInputDiv(true);
    } catch (err) {
      console.log(err);
    }
  };
  const deleteGenere = async (e) => {
    try {
      const confirmation = await confirm(
        "Are you sure you want to delete this item ?"
      );

      if (!confirmation) {
        return;
      }

      setShow(true); //Loading Indicator on
      const endpoints = new API_ENDPOINTS();
      const response = API.delete(
        endpoints.GENRES_DELETE + "/" + e.genereId,
        {}
      ).then((response) => {
        //console.log(response);
        if (response.data.isSuccess === true) {
          // toast.success("Genre deleted successfully");
          getAndBindGenresList();
          setGenresId(0);
          setGenresName("");

          setShow(false); //Loading Indicator off
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator off

          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };

  // Breadcrumbs List
  const breadcrumbsList = [{ text: "Common" }, { text: "Project Setup" }];

  //***** UI *****////////////////////////////////////////////////////////
  return (
    <>
      <div style={{ justifyContent: "center", display: "flex" }}>
        <Loader.ThreeDots
          visible={show}
          width={100}
          height={30}
          color="#347084"
        ></Loader.ThreeDots>
      </div>

      {genrePermission.hasPermission ? (
        <SettingPanel breadcrumbs={breadcrumbsList}>
          <SettingAccordion
            title="Genres"
            actions={
              hideGenresDataInputDiv && genrePermission.permission.canAddNew ? (
                <Button
                  size="sm"
                  variant="green-light"
                  leftIcon={BUTTON_ICONS.plus}
                  onClick={addGenere}
                >
                  Add
                </Button>
              ) : null
            }
          >
            <SettingAccordion.Card>
              {!hideGenresDataInputDiv &&
              (genrePermission.permission.canAddNew ||
                genrePermission.permission.canEdit) ? (
                <div className="setting-form">
                  <div className="form__container">
                    <form className="form__inline">
                      <FromGroup
                        className="grid-col-1/4"
                        label="Genre Name"
                        inputHeightAuto
                      >
                        <input
                          className="form-control"
                          type="text"
                          id="genres_name"
                          name="genres_name"
                          placeholder
                          aria-label="Genre Name"
                          onChange={(e) => setGenresName(e.target.value)}
                          value={genresName}
                        />
                      </FromGroup>

                      <FromGroup label="&nbsp;" type="button-inline-left">
                        <Button variant="secondary" onClick={cancelGenere}>
                          Cancel
                        </Button>
                        <Button onClick={saveGenres}>Save</Button>
                      </FromGroup>
                    </form>
                  </div>
                </div>
              ) : null}

              {allGenres.length > 0 && genrePermission.permission.canView ? (
                <div className="setting-details">
                  <Chip.Wrapper>{allGenres}</Chip.Wrapper>
                </div>
              ) : null}
            </SettingAccordion.Card>
          </SettingAccordion>
        </SettingPanel>
      ) : null}
    </>
  );
};

export default ProjectSetup;
