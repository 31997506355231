import "../../../../components/assets/css/template_builder.977bc20a47c0d58c09caf98ed89409cd.css";
import React from "react";
import API_ENDPOINTS from "../../../../services/api-endpoints";
import API_Template from "../../../../services/api-template";
import { getAllTemplates } from "../../../../Actions/Templates";
import TemplateItem from "../../components/TemplateItem";
import SmallTemplateItem from "../../components/SmallTemplateItem";
import Container from "../../../../components/ui-components/Container";
import { Link } from "react-router-dom";
import { ROUTE_OPTIONS } from "../../../../constant/route-options";

let Templates = [];

const AllTemplates = () => {
  const [templateState, setTemplateState] = React.useState({
    allTemplateTypes: [],
  });

  const getTemplateTypeList = React.useCallback(async () => {
    try {
      const endpoints = new API_ENDPOINTS();
      const response = await API_Template.get(
        endpoints.TEMPLATE_GET_TEMPLATE_TYPE
      );

      if (response.data.isSuccess === true) {
        const templateTypeList = response.data.result.map(
          (item) => item.templateTypeName
        );

        setTemplateState((prevState) => ({
          ...prevState,
          allTemplateTypes: templateTypeList,
        }));
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  React.useEffect(() => {
    const fetchTemplates = async () => {
      try {
        await getAllTemplates().then((docs) => {
          const templates = docs.map((doc) => doc);
          Templates.push(...templates);
          console.log("All Temps: ", Templates);
        });
      } catch (error) {
        console.log(error);
      }
    };

    getTemplateTypeList();
    fetchTemplates();
  }, []);

  return (
    <>
      <Container>
        <div className="template__builder__page__container">
          <div className="template__builder__page__iW">
            <div className="template__builder__page__wrapper">
              <div className="template__builder__page__title__w">
                <i className="page__title__icon icon icon-lp-insert-template" />
                <div className="builder__page__title__container">
                  <div className="builder__page__title__iW">
                    <div className="__page__main__title">Template</div>
                    <div className="__page__sub__title">
                      Create, edit &amp; share your contracts and agreements
                      with your partner, client or anyone
                    </div>
                  </div>
                </div>
              </div>
              <div className="template__builder__options__container">
                <div className="template__builder__options__left">
                  <select
                    className="form-select"
                    aria-label="Select Template"
                    value={0} //{templateTypeId}
                    onChange={(e) => {console.log("MMMMMMMM: ", e.target.value);}}
                  >
                    <option value={0}>All Templates</option>
                    {templateState.allTemplateTypes.map(
                      (templateType, index) => (
                        <option key={index + 1} value={index + 1}>
                          {templateType}
                        </option>
                      )
                    )}
                    {/* {templateTypesDD} */}
                  </select>
                </div>
                <div className="template__builder__options__middle">
                  <div className="col-auto">
                    {" "}
                    <label
                      className="visually-hidden"
                      htmlFor="autoSizingInputGroup"
                    >
                      Search
                    </label>
                    <div className="input-group">
                      <div className="input-before-text icon icon-lp-search" />
                      <input
                        className="form-control line-input"
                        type="text"
                        placeholder="Search"
                        id="autoSizingInputGroup"
                        onKeyDown="" //{searchInputKeyDown}
                      />
                    </div>
                  </div>
                </div>
                <div className="template__builder__options__right">
                  <div className="__option__right__iW">
                    <div className="__option__right__sort"> </div>
                    <div className="__option__right__filter">
                      <div className="dropdown" hidden={true}>
                        <button
                          className="btn btn--primary dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          id="dropdownMenu2"
                        >
                          Recent
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenu2"
                        >
                          <li>
                            <button className="dropdown-item" type="button">
                              Action
                            </button>
                            <button className="dropdown-item" type="button">
                              Action 2
                            </button>
                            <button className="dropdown-item" type="button">
                              Action 3
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tp__builder__recent__container"
                // hidden={recentTemplateDivView}
              >
                <div className="tp__builder__recent__title__W">
                  Recent Templates
                </div>
                <div className="tp__builder__recent__template__container">
                  <div className="tp__builder__recent__template__iW">
                    {/* {createTemplatePermission.hasPermission ? (
                      <Link
                        className="tp__builder__create__new__item"
                        to={
                          ROUTE_OPTIONS.templates.children["create-templates"]
                            .path
                        }
                      >
                        <div className="tp__builder__create__new__item__iW">
                          <div className="__create__new__item__icon icon icon-lp-document-add" />
                          <div className="__create__new__item__small-text">
                            Create New{" "}
                          </div>
                          <div className="__create__new__item__big-text">
                            Template
                          </div>
                        </div>
                      </Link>
                    ) : null} */}

                    <div className="tp__builder__recent__item__container">
                      <div className="tp__builder__recent__item__container__iW">
                        {/* {allRecentTemplates} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tp__builder__fq__used__container"
                // hidden={frequentTemplateDivView}
              >
                <div className="tp__builder__fq__used__title__W">
                  Frequently Used Templates
                </div>
                <div className="tp__builder__fq__used__filter">
                  Sort by
                  <span
                    className="event-pointer icon icon-lp-sort"
                    title="Sort By"
                    // onClick={SortFrequentemplates}
                  >
                    {/* {allFrequentTemplatesSortingOrder} */}
                  </span>
                </div>
                <div className="tp__builder__fq__used__template__container">
                  <div className="tp__builder__fq__used__template__iW">
                    <div className="tp__builder__fq__used__item__container">
                      <div className="tp__builder__fq__used__item__container__iW">
                        {/* {allFrequentTemplates} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tp__builder__all__tp__container">
                <div className="tp__builder__all__tp__title__W">
                  {/* {templateTitle} */}
                </div>
                <div className="tp__builder__all__tp__filter">
                  Sort by
                  <a
                    className="icon icon-lp-sort"
                    href="#"
                    title="Sort By"
                    // onClick={SortAllTemplates}
                  >
                    {/* {allTemplatesSortingOrder} */}
                  </a>
                </div>
                {/* {allTemplates} */}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default AllTemplates;
