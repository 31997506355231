import Schedule_API from "../../../services/api-scheduler";
import { SCHEDULE_ENDPOINTS } from "./schedule.endpoints";
import { getEpisodeIdsByProjectId } from "../../Scheduler/API/common";

export const getAllBlocks = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Schedule_API.get(
        `${SCHEDULE_ENDPOINTS.GET_All_BLOCK}`
      );

      if (!response?.data?.isSuccess) {
        resolve([]);
        return;
      }

      //   const data = response;
      const mappedData = response?.data?.result.map((block) => {
        return {
          name: block?.blockName,
          activities: "",
          id: block?.blockTypeId || 0,
          data: block,
        };
      });

      resolve(mappedData);

      //
    } catch (error) {
      reject(error);
    }
  });
};

export const saveBlockWithActivities = async (data) => {
  const requestBody = {
    blockTypeId: data?.blockTypeId || 0,
    blockName: data?.name,
    columnTypeId: data?.stage,
    status: 1,
    bgColour: null,
    blockActivities: data?.activity.map((activity, index) => ({
      blockActivityTypeId: activity?.blockActivityTypeId || 0,
      activityItemTypeId: activity?.activityItemTypeId || 0,
      itemTypeId: activity?.itemTypeId || 0,
      itemName: activity?.name,
      shortName: activity?.code,
      icon: activity?.icon,
      iconColour: null,
      textColour: null,
      bgColour: activity?.color,
      childTypeId: 1,
      defaultDayCount: 1,
      order: index + 1,
    })),
  };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await Schedule_API.post(
        `${SCHEDULE_ENDPOINTS.SAVE_BLOCK}`,
        requestBody
      );
      const result = response || null;
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const getAllStagesDataSet = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Schedule_API.get(
        `${SCHEDULE_ENDPOINTS.GET_STATGES}`
      );

      if (!response?.data?.isSuccess) {
        resolve([]);
        return;
      }

      const mappedData = response?.data?.result.map((stage) => {
        console.log("stage", stage);
        return {
          name: stage.columnName,
          subStages: "",
          id: stage.columnTypeId,
          data: stage,
        };
      });

      resolve(mappedData);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const getAllStages = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Schedule_API.get(
        `${SCHEDULE_ENDPOINTS.GET_STATGES}`
      );

      if (!response?.data?.isSuccess) {
        resolve([]);
        return;
      }

      const data = response;

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const deleteBlock = (blockTypeId) => {
  return new Promise((resolve, reject) => {
    Schedule_API.delete(`${SCHEDULE_ENDPOINTS.DELETE_BLOCK}/${blockTypeId}`)
      .then((res) => {
        console.log("deleteBlock :::", res);
        resolve(res.data);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const saveStage = async (columnTypeId, projectId, data, values) => {
  console.log("saveStage ::: ", values);
  // data params
  let params = {
    columnName: values?.name,
    order: data?.order || 0,
    status: data?.status || 1,
  };

  if (columnTypeId === 0) {
    return new Promise(async (resolve, reject) => {
      try {
        let res = await Schedule_API.post(
          `${SCHEDULE_ENDPOINTS.SAVE_STAGE}`,
          params
        );

        const data = res.data || null;
        if (data?.result?.columnTypeId) {
          getEpisodeIdsByProjectId(projectId).then((episodeId) => {
            Schedule_API.post(`${SCHEDULE_ENDPOINTS.CREATE_COLUMN}`, {
              columnTypeId: data?.result?.columnTypeId, projectId: projectId, episodeId: episodeId
            }).then((createColumnResp) => {
              resolve(episodeId, createColumnResp);
            })
          })
          resolve({data});
        } else {
          reject({
            systemError: {
              title: "Invalid column type",
            },
          });
        }
        
      } catch (err) {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      }
    });
  } else {
    return new Promise(async (resolve, reject) => {
      try {
        let res = await Schedule_API.put(
          `${SCHEDULE_ENDPOINTS.SAVE_STAGE}/${columnTypeId}`,
          params
        );

        const data = res || null;
        resolve(data);
      } catch (err) {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      }
    });
  }
};
export const deleteStages = (columnTypeId) => {
  return new Promise((resolve, reject) => {
    Schedule_API.delete(`${SCHEDULE_ENDPOINTS.SAVE_STAGE}/${columnTypeId}`)
      .then((res) => {
        console.log("deleteStages :::", res);
        resolve(res.data);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const getAllMileStoneDataSet = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Schedule_API.get(
        `${SCHEDULE_ENDPOINTS.GET_All_MILESTONES}`
      );

      if (!response?.data?.isSuccess) {
        resolve([]);
        return;
      }

      const mappedData = response?.data?.result.map((milestone) => {
        console.log("milestone", milestone);
        return {
          order: milestone?.order || 0,
          name: milestone?.itemType?.itemName,
          shortName: milestone?.itemType?.shortName,
          id: milestone?.milestoneTypeId,
          data: milestone,
        };
      });

      resolve(mappedData);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const saveActivity = (itemTypeId, values, data) => {
  let params = {
    itemType: {
      itemTypeId: itemTypeId,
      itemName: values?.name,
      shortName: values?.code,
      textColour: "",
      bgColour: values?.colorCode,
      childTypeId: 2, //Milestone
      columnTypeId: 1,
      status: 1,
      groupId: 0,
      icon: "",
    },
    activityItemType: {
      activityItemTypeId: 0,
      itemTypeId: itemTypeId,
      isIndividual: true,
      icon: "",
      iconColour: "",
      groupId: 0,
      isIndex: true,
    },
    milestoneItemType: {
      milestoneTypeId: data ? data?.milestoneTypeId : 0,
      itemTypeId: itemTypeId,
      order: data ? data?.order : 0,
    },
  };
  return new Promise((resolve, reject) => {
    Schedule_API.post(`${SCHEDULE_ENDPOINTS.SAVE_ACTIVITY}`, params)
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
export const deleteMileStone = (milestoneTypeId) => {
  return new Promise((resolve, reject) => {
    Schedule_API.delete(
      `${SCHEDULE_ENDPOINTS.DELETE_MILESTONE}/${milestoneTypeId}`
    )
      .then((res) => {
        console.log("deleteMileStone :::", res);
        resolve(res.data);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
