import React from "react";
import { useUserMenuStore } from "../../../../../../store/Setting/Permission";

import Form from "./Form";
import Table from "./Table";
import "./styles.scss";
import { ROUTE_OPTIONS } from "../../../../../../constant/route-options";
import { useRouteLoaderData } from "react-router-dom";
import { SETTING_PERMISSION_MODULES } from "../../../../../../constant/setting_permission_module";
import { Permission } from "../../../../../../helpers/permission";

export const ROLE_VARIANT = "TENANT_WISE" | "PROJECT_WISE";

const UserMenu = (props) => {
  const { variant = undefined } = props;

  const routeId = ROUTE_OPTIONS.setting.children["tenant-permission"].id;
  const { permission } = useRouteLoaderData(routeId);
  const moduleType = SETTING_PERMISSION_MODULES.TenantPermission;
  const permissions = new Permission(permission);
  const userMenuPermission = permissions.findByName(moduleType.UserMenu.key);

  if (!variant) {
    throw new Error("variant props not defined for role");
  }

  const showForm = useUserMenuStore((state) => state.showForm);
  const onLoadDropdown = useUserMenuStore((state) => state.onLoadDropdown);

  // Load dropdown data
  React.useEffect(() => {
    onLoadDropdown();
  }, [onLoadDropdown]);

  return (
    <div className="role__wrapper">
      {showForm &&
      (userMenuPermission.permission.canEdit ||
        userMenuPermission.permission.canAddNew) ? (
        <Form />
      ) : null}

      {userMenuPermission.permission.canView ? <Table /> : null}
    </div>
  );
};

export default UserMenu;
