import React from "react";
import { useMatches } from "react-router-dom";
import "./styles.scss";

const PageSelector = () => {
  const matches = useMatches();

  let crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => match.handle.crumb(match.data));

  let config = matches
    .filter((match) => Boolean(match.handle?.config))
    .map((match) => match.handle.config(match.data));

  const currentPage = React.useMemo(() => {
    if (crumbs.length > 0) {
      const __config = config[config.length - 1];

      if (__config) {
        return __config.toolbarLabel || __config.label;
      }

      const props = crumbs[crumbs.length - 1]?.props;
      return props?.label;
    }

    return null;
  }, [crumbs, config]);

  if (!currentPage) return null;

  return (
    <div className="page__selector">
      <div className="text">{currentPage}</div>
    </div>
  );
};

export default PageSelector;
