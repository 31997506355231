import BreadcrumbLink from "../../../components/ui-components/Breadcrumb/BreadcrumbLink";
// import { permissionLoader } from "../../../routes/loaders";
import config from "./config";

import Template from "..";
import Test from "../screens/Test";
import EditTemplate from "../screens/EditTemplate";
import CreateTemplate from "../screens/CreateTemplate";
import AllTemplates from "../screens/AllTemplates";

const children = config.children;

export const TemplateRoutes = {
  id: config.id,
  path: config.path,
  element: <Template />,
  handle: {
    crumb: () => <BreadcrumbLink label={config.label} to={config.TempBreadCrumPath}/>,
  },
  loader: async () => {
    return true;
    //   return await permissionLoader(children.general.id);
  },
  children: [
    {
      id: children.test.id,
      path: children.test.path,
      element: <Test />,
      handle: {
        crumb: () => <BreadcrumbLink label={children.test.label} />,
      },
      loader: async () => {
        return true;
        //   return await permissionLoader(children.general.id);
      },
    },
    {
      id: children.all_templates.id,
      path: children.all_templates.path,
      element: <AllTemplates />,
      handle: {
        crumb: () => <BreadcrumbLink label={children.all_templates.label} />,
      },
      loader: async () => {
        return true;
        //   return await permissionLoader(children.general.id);
      },
    },
    {
      id: children.create_template.id,
      path: children.create_template.path,
      element: <CreateTemplate />,
      handle: {
        crumb: () => <BreadcrumbLink label={children.create_template.label} />,
      },
      loader: async () => {
        return true;
        //   return await permissionLoader(children.general.id);
      },
    },
    {
      id: children.edit_template.id,
      path: children.edit_template.path,
      element: <EditTemplate />,
      handle: {
        crumb: () => <BreadcrumbLink label={children.edit_template.label} />,
      },
      loader: async () => {
        return true;
        //   return await permissionLoader(children.general.id);
      },
    },
  ],
};

export const TemplatePaths = config;
