import InputField from "../../../../../../common/components/Form/InputField";
import SelectField from "../../../../../../common/components/Form/SelectField";
import { FieldTypes } from "../../../../../../constant/FieldTypes";
import { FieldContainer, FieldItem } from "./styled";

const GroupFields = (props) => {
  const {
    tabGroupId,
    fields,
    handleChange,
    setFieldValue,
    values,
    setMainField,
  } = props;

  const renderField = (field) => {
    const fieldType = String(field.fieldType().id());
    const fieldName = field.name();
    const isDisabled = field.disabled();
    const isMainField = field.isMainField();

    switch (fieldType) {
      case String(FieldTypes.TextInput):
        return (
          <InputField
            ref={(ref) => {
              if (isMainField) {
                setMainField &&
                  setMainField({ ref, val: values[fieldName], fieldName });
              }
            }}
            type="text"
            name={fieldName}
            value={values[fieldName] || ""}
            onChange={handleChange}
            disabled={isDisabled}
            className={isMainField ? "is-main-field" : ""}
          />
        );

      case String(FieldTypes.Select):
        const options = field.fieldOptions();
        const isMulti = false;

        const getValue = (val) => {
          if (options) {
            return isMulti
              ? options.filter(
                  (option) => val?.value?.indexOf(option.value) >= 0
                )
              : options.find((option) => option?.value === val?.value);
          }

          return isMulti ? [] : "";
        };

        const onChange = (option) => {
          if (isMulti) {
            setFieldValue(
              fieldName,
              option ? option.map((item) => item.value) : []
            );
            return;
          }

          setFieldValue(fieldName, option);
        };
        return (
          <SelectField
            options={options}
            value={getValue(values[fieldName])}
            onChange={onChange}
            isDisabled={isDisabled}
          />
        );

      default:
        return null;
    }
  };
  return (
    <FieldContainer key={tabGroupId}>
      {fields.map((field) => (
        <FieldItem key={field.id()}>{renderField(field)}</FieldItem>
      ))}
    </FieldContainer>
  );
};

export default GroupFields;
