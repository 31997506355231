import styled from "styled-components";
import { fontStyle } from "../../../../styled/utility";

export const AvatarListWrapper = styled.div`
  --item--gap: var(--layout-spacing-minus-12);
  --more-btn-size: 26px;

  display: flex;
  align-items: center;

  .avatar__item {
    display: flex;
    margin-left: var(--item--gap);
    transition: var(--animate-1);
    z-index: 1;

    &:hover {
      scale: 1.1;
      z-index: 2;
    }
  }

  .btn__more__avatar {
    ${fontStyle("x-small")};
    background-color: var(--color-neutral-40, #dfe0e5);
    color: var(--font-default-color, #061216);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 999px;
    border: 2px solid #fff;
    width: var(--more-btn-size);
    height: var(--more-btn-size);
    max-width: var(--more-btn-size);
    max-height: var(--more-btn-size);
    margin-left: var(--item--gap);
    z-index: 2;
  }
`;
