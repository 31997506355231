import styled from "styled-components";
import { motion } from "framer-motion";

export const LayoutWrapper = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: unset;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: var(--box-height);
  min-height: var(--box-height);
  max-width: var(--box-width);
  min-width: var(--box-width);
  background-color: var(--layout-background-white);
  border-top-left-radius: var(--layout-border-radius-16);
  border-top-right-radius: var(--layout-border-radius-16);
  border: 1px solid var(--color-neutral-30, #ebecef);
  /* layout/boxShadow/primary/10 */
  box-shadow: -3px 8px 15px 0px rgba(45, 89, 102, 0.08);
  pointer-events: auto;
  z-index: 1;

  .layout__header {
    padding: var(--layout-spacing-12) var(--layout-spacing-20);
    z-index: 2;
    min-height: var(--layout-size-52);
    height: var(--layout-size-52);
    /* layout/boxShadow/primary/10 */
    box-shadow: -3px 8px 15px 0px rgba(45, 89, 102, 0.08);
  }

  .layout__content {
    flex: 1;
    min-height: 0px;
    display: flex;
    position: relative;

    #backdrop__layer {
      z-index: 3;
    }

    ::-webkit-scrollbar-track {
    }

    ::-webkit-scrollbar {
      width: 8px !important;
    }

    ::-webkit-scrollbar-thumb {
    }
  }
`;
