import styled from "styled-components";
import { fontStyle } from "../../../../../../../../../styled/utility";

export const AssigneeList = styled.div.attrs({ className: "assignee-list" })`
  display: flex;
  height: inherit;
  /* gap: var(--layout-spacing-4); */
  min-height: 100%;

  .assignee__swiper {
    overflow: visible !important;
    overflow-x: clip !important;
    width: 100%;

    .swiper-slide {
      max-width: var(--layout-size-24) !important;
      min-width: var(--layout-size-24) !important;
      /* min-height: 100%; */
    }
  }
`;

export const AssignerElement = styled.div.attrs({ className: "assigner" })`
  --assigner-header-h: 26px;
  width: 100%;
  position: relative;
  height: 100%;
  cursor: pointer;
  /* margin-top: var(--column-main-title-height); */

  .assigner {
    &__header {
      display: flex;
      justify-content: center;
      top: calc(
        var(--column-top-height) + var(--column-main-title-height) -
          var(--assigner-header-h)
      );
      /* var(--column-top-height); */
      left: 0;
      position: sticky;
      margin-top: calc(var(--assigner-header-h) * -1);
      z-index: 11;
      height: var(--layout-size-24);
      background-color: var(--layout-bg-color);

      .assigner-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
      }
    }

    &__body {
      height: 100%;
      margin-top: var(--layout-spacing-4);
      display: flex;
      justify-content: center;
      position: relative;

      .description {
        ${fontStyle("x-small-regular")};
        color: var(--font-tint-01);
        position: absolute;
        top: var(--layout-spacing-16);
        writing-mode: vertical-lr;
        transform: scale(-1, -1);
        pointer-events: none;
        z-index: 1;
        letter-spacing: 0.5px;
        user-select: none;
      }

      .presentation {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;

        ${({ $isSelected }) => {
          if ($isSelected) {
            return `background-color:var(--color-accent-50)`;
          }
        }};

        &:hover {
          background-color: var(--layout-background-n10);
        }

        .block {
          ${({ $isSelected }) => {
            if ($isSelected) {
              return `background-color:#56B5BC`;
            }
          }};
        }
      }
    }
  }
`;

export const AssignerBlockItem = styled.div.attrs({ className: "block" })`
  position: absolute;
  width: 10px;
  border-radius: 999px;
  background-color: var(--layout-background-n40);
  top: ${({ $pos = 0 }) =>
    `calc( ${$pos} *  var(--column-cell-height) + var(--layout-spacing-4) )`};
  height: ${({ $daycount = 1 }) =>
    `calc( ${$daycount} *  var(--column-cell-height) )`};
  pointer-events: none;
`;
