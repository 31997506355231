import styled from "styled-components";
import { fontStyle } from "../../../../../../styled/utility";

export const Wrapper = styled.div`
  padding: var(--layout-spacing-12) var(--layout-spacing-60);
  background-color: var(--font-default-white);
  display: flex;
  justify-content: space-between;

  .cols {
    display: flex;
    gap: var(--layout-spacing-60);
  }
`;

export const HeaderInfo = styled.div`
  display: grid;
  gap: var(--layout-spacing-2);

  .label {
    ${fontStyle("x-small-regular")};
    font-weight: 500;
    color: var(--font-tint-04);
  }

  .content {
    ${fontStyle("base-medium")};
    color: var(--color-neutral-500);
    font-weight: 500;
  }
`;
