import * as React from "react";
import { memo } from "react";
const SvgDocumentList = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 0.271999C1 0.121783 1.12263 7.95956e-06 1.2739 7.95956e-06H11.0503C11.123 7.95956e-06 11.1926 0.0286641 11.244 0.0796724L13.9198 2.73685C13.9711 2.78786 14 2.85704 14 2.92918V15.728C14 15.8782 13.8774 16 13.7261 16H1.2739C1.12263 16 1 15.8782 1 15.728V0.271999ZM1.54779 0.543991V15.456H13.4522V3.04184L10.9369 0.543991H1.54779Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9455 0.0207121C11.0479 -0.021387 11.1657 0.00188338 11.244 0.0796724L13.9198 2.73685C13.9981 2.81464 14.0215 2.93163 13.9791 3.03326C13.9368 3.1349 13.8369 3.20117 13.7261 3.20117H11.0503C10.8991 3.20117 10.7764 3.07939 10.7764 2.92918V0.271999C10.7764 0.161989 10.8432 0.0628112 10.9455 0.0207121ZM11.3242 0.928645V2.65718H13.0649L11.3242 0.928645Z"
      fill="currentColor"
    />
    <path
      d="M5.73364 9.55856L6.76806 8.28892V8.26585L5.17566 7.83879L5.48956 6.86923L7.03539 7.44638L6.94243 5.80733H8.00021L7.90707 7.44638L9.46463 6.86923L9.79 7.83879L8.18588 8.26585V8.28886L9.22048 9.55851L8.38345 10.1587L7.48855 8.79672H7.46537L6.57046 10.1587L5.73364 9.55856Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgDocumentList);
export default Memo;
