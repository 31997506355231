import React from "react";

import PermissionButton from "./PermissionButton";
import "./styles.scss";

const TableRightActionBody = (props) => {
  const { permissionState, onUpdated } = props;

  const [actions, updateAction] = React.useState({});

  React.useEffect(() => {
    updateAction(permissionState);
  }, [permissionState]);

  console.log(actions);

  return (
    <div className="table__action table__action--body">
      <PermissionButton type="create" actions={actions} onUpdated={onUpdated} />
      <PermissionButton type="view" actions={actions} onUpdated={onUpdated} />
      <PermissionButton type="edit" actions={actions} onUpdated={onUpdated} />
      <PermissionButton type="delete" actions={actions} onUpdated={onUpdated} />
      <PermissionButton type="execute" actions={actions} onUpdated={onUpdated} />
    </div>
  );
};

export default TableRightActionBody;
