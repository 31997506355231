import React from "react";

import IconPlus from "../../../../../../common/components/icons/Line/add-1";
import IconChat from "../../../../../../common/components/icons/Line/chat";
import { IconBlock } from "../../../../../../styled/Common/Icons";
import * as Styled from "./styled";
import { NewMemberDialog } from "../../../../Store/ChatBaseStore";

const AddNewMembers = () => {
  return (
    <Styled.Wrapper className="scrollbar-w-4">
      <div className="button__wrapper">
        <Styled.AddMemberButton
          onClick={() => {
            NewMemberDialog().open();
          }}
        >
          <IconBlock className="icon">
            <IconPlus />
          </IconBlock>
          Add Member
        </Styled.AddMemberButton>
      </div>

      <div className="contents">
        <EmptyBlock />
      </div>
    </Styled.Wrapper>
  );
};

function EmptyBlock() {
  return (
    <Styled.EmptyBlockWrapper>
      <IconBlock className="icon">
        <IconChat />
      </IconBlock>
    </Styled.EmptyBlockWrapper>
  );
}

export default AddNewMembers;
