import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.483631 14.4283C0.483631 12.9042 0.933045 11.61 1.8195 10.5817C2.63676 9.63361 3.8304 8.9323 5.27134 8.55362L5.74433 8.42932L5.35839 8.12891C4.30931 7.31238 3.7076 6.08287 3.7076 4.75566C3.7076 2.40002 5.62394 0.483591 7.97947 0.483591C10.3348 0.483591 12.2511 2.40002 12.2511 4.75566C12.2511 6.08255 11.6491 7.31206 10.5996 8.12891L10.2137 8.42932L10.6867 8.55362C11.552 8.78104 12.3254 9.12176 12.9853 9.56638L13.2555 9.16529C12.6639 8.76674 11.9895 8.44694 11.2454 8.21181C12.1971 7.31504 12.7347 6.07892 12.7347 4.7557C12.7347 2.13342 10.6015 0 7.97943 0C5.35726 0 3.22392 2.13342 3.22392 4.7557C3.22392 6.07904 3.76124 7.31508 4.71238 8.21161C1.71032 9.15687 0 11.401 0 14.4283V15.9598H10.0756V15.4761H0.483631V14.4283Z"
      fill="currentColor"
    />
    <path
      d="M15.948 12.3984C15.9196 12.3109 15.8439 12.2471 15.7527 12.2338L13.957 11.9729L13.1539 10.3457C13.1132 10.2632 13.0291 10.2109 12.937 10.2109C12.845 10.2109 12.7609 10.2631 12.7202 10.3457L11.9171 11.9729L10.1213 12.2338C10.0302 12.2471 9.95453 12.3109 9.92612 12.3984C9.89767 12.4859 9.92141 12.582 9.9873 12.6463L11.2867 13.9129L10.98 15.7013C10.9644 15.792 11.0018 15.8837 11.0762 15.9378C11.1184 15.9684 11.1682 15.984 11.2184 15.984C11.2569 15.984 11.2955 15.9768 11.3309 15.9582L12.937 15.1158L14.5431 15.9642C14.5785 15.9828 14.6171 16 14.6556 16C14.656 16 14.6563 16 14.6565 16C14.79 16 14.8983 15.8837 14.8983 15.7502C14.8983 15.7292 14.8957 15.7008 14.8906 15.6814L14.5874 13.9129L15.8868 12.6462C15.9527 12.5821 15.9764 12.4859 15.948 12.3984ZM14.1587 13.6554C14.1017 13.7109 14.0757 13.7909 14.0891 13.8694L14.3345 15.3002L13.0496 14.6247C12.9792 14.5876 12.895 14.5876 12.8246 14.6247L11.5396 15.3002L11.785 13.8694C11.7984 13.791 11.7724 13.7109 11.7154 13.6554L10.6758 12.642L12.1125 12.4332C12.1912 12.4218 12.2594 12.3723 12.2946 12.301L12.937 10.9991L13.5795 12.301C13.6147 12.3723 13.6828 12.4218 13.7616 12.4332L15.1983 12.642L14.1587 13.6554Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
