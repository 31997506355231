import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import * as Styled from "./styled";
import FieldGroup from "../../../../../../../../../../../common/components/Form/FieldGroup";
import Button from "../../../../../../../../../../../common/components/Buttons/UIButton";
import { useActivityBlock } from "../../../../context/ActivityBlockContext";

import SelectField from "../../../../../../../../../../../common/components/Form/SelectField";
import InputField from "../../../../../../../../../../../common/components/Form/InputField";
import ActivityList from "./ActivityList";
// import { join } from "lodash";
import { getAllStages } from "../../../../../../../../../api/schedule";

const validationSchema = Yup.object().shape({
  stage: Yup.string().required("Stage is required"),
  // colorCode: Yup.string().required("Color Code is required"),
  name: Yup.string().required("Name is required"),
  activity: Yup.array()
    .of(
      Yup.object().shape({
        color: Yup.string(),
        code: Yup.string()
          .required("Code is required")
          .max(5, "Code max length is 5 character"),
        name: Yup.string().required("Activity name is required"),
        icon: Yup.string(),
      })
    )
    .min(1, "Minimum 1 activity is required"),
});

function Form({ onSubmit, formData }) {
  const [stages, setStages] = React.useState([]);
  const { blockModalData, BlockModal } = useActivityBlock();

  const isEditMode = blockModalData.payload?.type === "EDIT";

  const initialValues = {
    blockTypeId: blockModalData?.payload?.data?.blockTypeId || 0,
    stage: blockModalData?.payload?.data?.columnTypeId || "",
    name: blockModalData?.payload?.data?.blockName || "",
    activity:
      blockModalData?.payload?.data?.blockActivityTypes.flatMap(
        (item, index, array) => {
          return Array(item?.defaultDayCount || 1)
            .fill(null)
            .map((_, idx) => ({
              blockActivityTypeId: item?.blockActivityTypeId,
              activityItemTypeId: item?.activityItemTypeId,
              itemTypeId: item?.activityItemType?.itemType?.itemTypeId,
              code: item?.activityItemType?.itemType?.shortName,
              name: item?.activityItemType?.itemType?.itemName,
              color: item?.activityItemType?.itemType?.bgColour,
              icon: item?.activityItemType?.icon,
            }));
        }
      ) || [],
  };

  React.useEffect(() => {
    getAllStages().then((response) => {
      const stages = response?.data?.result.map((stage) => ({
        label: stage.columnName,
        value: stage.columnTypeId,
      }));
      setStages(stages);
    });
  }, []);

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values, actions) => {
      const val = {
        ...values,
      };
      console.log(val);
      onSubmit(val, actions);
    },
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    setFieldTouched,
  } = formik;

  const activityError = React.useMemo(() => {
    if (!touched.activity) return;

    if (typeof errors.activity === "string") {
      return errors.activity;
    }

    return errors?.activity
      ?.filter((d) => d !== undefined)
      ?.map((d) => Object.values(d)[0])[0];
  }, [errors.activity, touched.activity]);

  return (
    <Styled.Form onSubmit={handleSubmit}>
      <FieldGroup
        label="Production Stage"
        error={touched.stage && errors.stage}
      >
        <SelectField
          placeholder="Select"
          options={stages}
          value={stages.find((d) => d?.value === values.stage)}
          onChange={(v) => {
            setFieldValue("stage", v?.value);
          }}
        />
      </FieldGroup>
      <FieldGroup label="Block Name" error={touched.name && errors.name}>
        <InputField
          name="name"
          type="text"
          placeholder="Block Name"
          value={values.name}
          onChange={handleChange}
        />
      </FieldGroup>

      <FieldGroup label="Add Activities" error={activityError}>
        <ActivityList
          value={values.activity}
          onChange={(val) => {
            setFieldValue("activity", val);
          }}
        />
      </FieldGroup>

      <FieldGroup className="actions">
        <Button
          type="button"
          variant={Button.Variant.Danger}
          size={Button.Sizes.Small}
          className="cancel__btn"
          onClick={() => {
            BlockModal.close();
          }}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          variant={Button.Variant.Primary}
          size={Button.Sizes.Small}
        >
          {isEditMode ? "Save" : "Add"}
        </Button>
      </FieldGroup>
    </Styled.Form>
  );
}

export default Form;
