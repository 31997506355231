import * as React from "react";
import { memo } from "react";
const SvgAttachment = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_961_3179)">
      <path
        d="M14.7607 4.49942L6.20836 13.5883C5.67769 14.127 4.97219 14.4275 4.23846 14.4275C3.50473 14.4275 2.79917 14.127 2.26856 13.5883C1.74654 13.0331 1.45337 12.2803 1.45337 11.4955C1.45337 10.7106 1.74655 9.95781 2.26856 9.40262L9.16874 2.06952C9.47612 1.75689 9.88518 1.58238 10.3106 1.58238C10.7361 1.58238 11.1451 1.75689 11.4525 2.06952C11.7554 2.39146 11.9255 2.82811 11.9255 3.28332C11.9255 3.7386 11.7554 4.17519 11.4525 4.49719L4.55575 11.8292C4.4435 11.9485 4.27988 11.9952 4.12648 11.9517C3.97309 11.9081 3.85323 11.7807 3.81203 11.6178C3.77089 11.4548 3.81466 11.2808 3.92686 11.1615L10.8215 3.83137C11.0049 3.63649 11.0765 3.35243 11.0094 3.0862C10.9423 2.81997 10.7466 2.61198 10.4961 2.5407C10.2456 2.46934 9.97833 2.54548 9.79489 2.74036L2.89755 10.0698C2.41838 10.579 2.23114 11.3212 2.40645 12.0167C2.58183 12.7124 3.09303 13.2557 3.74752 13.4422C4.40207 13.6287 5.10045 13.4299 5.57964 12.9207L12.4764 5.5906C12.8643 5.1878 13.1447 4.6829 13.2892 4.1274C13.4336 3.57183 13.4368 2.98552 13.2985 2.42818C13.1602 1.87087 12.8853 1.3625 12.5019 0.954917C12.1185 0.547331 11.6402 0.255072 11.1159 0.107965C10.5915 -0.0391423 10.0398 -0.0358657 9.51697 0.117467C8.99413 0.270799 8.51899 0.568709 8.13985 0.980826L1.23967 8.31393C0.445397 9.15885 -0.00052313 10.3044 4.60572e-07 11.4988C0.000526385 12.6931 0.447446 13.8383 1.24248 14.6825C2.03752 15.5267 3.11559 16.0006 4.23937 16C5.36316 15.9994 6.44069 15.5244 7.23505 14.6795L15.7874 5.59058C15.9708 5.3957 16.0423 5.11164 15.9753 4.84541C15.9081 4.57918 15.7125 4.37126 15.462 4.29991C15.2115 4.22861 14.9442 4.30469 14.7608 4.49957L14.7607 4.49942Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_961_3179">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgAttachment);
export default Memo;
