import React from "react";
import "./styles.scss";

const Checkbox = (props) => {
  const { id, label, ...inputProps } = props;

  return (
    <div className="form-check">
      <input {...inputProps} className="form-check-input" type="checkbox" id={id}  />
      {label ? (
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
      ) : null}
    </div>
  );
};

export default Checkbox;
