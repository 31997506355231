const size = {
  xs: "375px",
  sm: "425px",
  md: "768px",
  lg: "1024px",
  xl: "1440px",
  xxl: "2560px",
};

const size2 = {
  xs: "375px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1440px",
};

export const responsive = {
  xs: `(min-width: ${size.xs})`,
  sm: `(min-width: ${size.sm})`,
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg})`,
  xl: `(min-width: ${size.xl})`,
  xxl: `(min-width: ${size.xxl})`
};

export const responsive2 = {
  xs: `(min-width: ${size2.xs})`,
  sm: `(min-width: ${size2.sm})`,
  md: `(min-width: ${size2.md})`,
  lg: `(min-width: ${size2.lg})`,
  xl: `(min-width: ${size2.xl})`,
  xxl: `(min-width: ${size2.xxl})`
};

export const layoutBoxShadow = (type) => {
  return `var(--layout-box-shadow-${type}-x)
      var(--layout-box-shadow-${type}-y)
      var(--layout-box-shadow-${type}-blur)
      var(--layout-box-shadow-${type}-spread)
      var(--layout-box-shadow-${type}-color)`;
};

export const fontStyle = (type) => {
  return `
    font-size: var(--${type}-font-size);
    font-weight: var(--${type}-font-weight);
    font-family: var(--${type}-font-family);
    line-height: var(--${type}-line-height);
  `;
};
