import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.9675 14.9599C3.01979 15.2516 3.173 15.5156 3.40031 15.7058C3.62762 15.8959 3.91455 16.0001 4.2109 16C4.28757 16.0003 4.36412 15.9937 4.43964 15.9804L11.9274 14.6217C12.2093 14.5711 12.4657 14.4264 12.6546 14.2112C12.8435 13.996 12.9538 13.723 12.9675 13.4369H13.6381C13.9744 13.4364 14.2968 13.3026 14.5347 13.0647C14.7725 12.8269 14.9064 12.5045 14.9069 12.1681V2.66667C14.9075 2.56369 14.8674 2.46463 14.7954 2.39101L12.5139 0.113392C12.4406 0.0406803 12.3415 -8.22725e-05 12.2383 1.24675e-07H6.02908C5.69291 -2.7439e-07 5.37047 0.13341 5.13258 0.370936C4.89469 0.608463 4.76078 0.93069 4.76026 1.26686V2.26588L2.04081 2.75855C1.71014 2.81896 1.41695 3.00811 1.22561 3.28449C1.03427 3.56087 0.960427 3.90187 1.02028 4.23265L2.9675 14.9599ZM13.9939 2.69795H12.207V0.911046L13.9939 2.69795ZM5.54227 1.26686C5.54279 1.13809 5.59431 1.01477 5.68555 0.923903C5.77678 0.833032 5.90031 0.782012 6.02908 0.782013H11.425V3.08895C11.425 3.19265 11.4662 3.29211 11.5395 3.36544C11.6128 3.43876 11.7123 3.47996 11.816 3.47996H14.1249V12.1681C14.1244 12.2971 14.0729 12.4206 13.9817 12.5118C13.8905 12.603 13.767 12.6544 13.6381 12.6549H6.02908C5.90013 12.6544 5.77661 12.603 5.68543 12.5118C5.59424 12.4206 5.54279 12.2971 5.54227 12.1681V1.26686ZM2.18157 3.52883L4.76026 3.05963V12.1681C4.76078 12.5045 4.89462 12.8269 5.13246 13.0647C5.3703 13.3026 5.69273 13.4364 6.02908 13.4369H12.1816C12.1726 13.5134 12.1451 13.5866 12.1014 13.65C12.0271 13.754 11.9146 13.8243 11.7886 13.8455L4.30083 15.2043C4.23806 15.2161 4.17358 15.2152 4.11114 15.2018C4.04871 15.1883 3.98958 15.1626 3.93719 15.1261C3.83382 15.0512 3.7637 14.939 3.74169 14.8133L1.78861 4.08993C1.76698 3.9635 1.79612 3.83363 1.8697 3.72857C1.94328 3.6235 2.05536 3.55172 2.18157 3.52883Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
