import styled from "styled-components";
import ArrowImg from "./img/arrow.png";

export const ButtonContainer = styled.button`
  --btn-bg-color: #ecf6f7;

  width: var(--btn--width);
  height: var(--btn--width);
  min-width: var(--btn--width);
  min-height: var(--btn--width);
  border-radius: 999px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background-color: var(--btn-bg-color);
  transition: var(--animate-1);
  color: #8ecdd2;
  position: relative;

  .animate__circle {
    position: absolute;
    width: var(--btn--width);
    height: var(--btn--width);
    border-radius: 50%;
    transition: all 0.3s;
    animation: circle 2s infinite;
    z-index: -1;
    background-color: var(--color-accent-400);
  }

  @keyframes circle {
    50% {
      transform: scale(1.8);
      opacity: 0;
    }
    100% {
      transform: scale(1.8);
      opacity: 0;
    }
  }

  .hint {
    position: absolute;
    white-space: nowrap;
    right: var(--hint-right);
    pointer-events: none;

    &::before {
      position: absolute;
      top: var(--layout-spacing-minus-32);
      left: calc(100% - 32px);
      width: 64px;
      height: 24px;
      background-size: contain;
      content: "";
      display: block;
      background-image: url(${ArrowImg});
      background-repeat: no-repeat;
    }
  }

  ${({ btnSize }) => {
    switch (btnSize) {
      case "md": {
        return `
        --btn--width: var(--layout-size-40);
        --hint-right: var(--layout-size-52);
       

        .icon {
            --icon-size: var(--layout-size-16);
            --icon-font-size: var(--layout-size-16);
        }
        `;
      }

      case "lg": {
        return `
          --btn--width: var(--layout-size-44);
          --hint-right: var(--layout-size-56);
        

          .icon {
            --icon-size: var(--layout-size-20);
            --icon-font-size: var(--layout-size-20);
          }
        `;
      }

      default: {
        return `
        --btn--width: var(--layout-size-32);
        --hint-right: var(--layout-size-44);
       

        .icon {
            --icon-size: var(--layout-size-16);
            --icon-font-size: var(--layout-size-16);
        }
        `;
      }
    }
  }};

  &:hover {
    --btn-bg-color: #56b5bc;
    color: var(--color-white);
  }

  &:active {
    --btn-bg-color: #56b5bc;
    color: var(--color-white);
  }

  ${({ isActive }) => {
    if (isActive) {
      return `
        --btn-bg-color: #56b5bc;
        color: var(--color-white);
      `;
    }
  }};
`;

export const OptionList = styled.div`
  background-color: var(--layout-background-white);
  border-radius: var(--layout-border-radius-12);
  padding: var(--layout-spacing-16) 0;
  min-width: 160px;
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.05);

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      cursor: pointer;
      color: var(--font-default-color);
      font-size: var(--small-regular-font-size);
      width: 100%;
      padding: var(--layout-spacing-8) var(--layout-spacing-16);
      border-radius: var(--layout-spacing-4);
      transition: var(--animate-1);

      .content {
        display: flex;
        align-items: center;
      }

      .option__icon {
        --icon-size: var(--small-regular-font-size);
        --icon-font-size: var(--small-regular-font-size);
        --icon-font-color: #949aa6;
        margin-right: var(--layout-spacing-8);
        display: inline-flex;
      }

      &:hover {
        background-color: var(--layout-background-accent);
      }
    }
  }
`;
