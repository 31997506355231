import create from "zustand";
import { devtools } from "zustand/middleware";
import API from "../../../services/api-tenant";
import API_Globle from "../../../services/api";
import API_ENDPOINTS from "../../../services/api-endpoints";
import { confirm } from "react-confirm-box";
import {
  deleteUserRole,
  saveUserRole,
} from "../../../Actions/Setting/Permission";

const dummyData = [
  {
    user: { name: "Saman", id: 1 },
    roles: [
      { label: "HR", id: 1 },
      { label: "Admin", id: 2 },
    ],
    action: "121",
    id: "121",
  },
  {
    user: { name: "Kasun", id: 2 },
    roles: [
      { label: "HR", id: 1 },
      { label: "Admin", id: 2 },
      { label: "Supper Admin", id: 3 },
    ],
    action: "122",
    id: "122",
  },
  {
    user: { name: "Supun", id: 3 },
    roles: [
      { label: "Admin", id: 2 },
      { label: "Supper Admin", id: 3 },
    ],
    action: "123",
    id: "123",
  },
];

const userOptions = [
  { label: "Saman", value: 1 },
  { label: "Kasun", value: 2 },
  { label: "Supun", value: 3 },
];

const roleOptions = [
  { label: "HR", value: 1 },
  { label: "Admin", value: 2 },
  { label: "Supper Admin", value: 3 },
];

let SettingUserRoleStore = (set, get) => ({
  isEditMode: false,
  userOptions: [],
  roleOptions: [],
  users: [],
  roles: [],
  data: [],
  variant: null,
  showForm: false,
  formData: {
    userId: null,
    roleIds: [],
    id: null,
  },

  onToggleForm: () => set((state) => ({ showForm: !state.showForm })),

  setVariant: (val) => {
    set({
      variant: val,
      isEditMode: false,
      showForm: false,
      formData: {
        userId: null,
        roleIds: [],
        id: null,
      },
    });
  },

  onEdit: (id) => {
    console.log("id", id);
    // Get selected data by id
    const selectedForm = get().data.find((obj) => obj.id === id);

    if (selectedForm) {
      const { user = null, roles = [] } = selectedForm;

      // update state
      set({
        isEditMode: true,
        showForm: true,
        formData: {
          userId: user.id,
          roleIds: roles.map((r) => r.id),
          id,
        },
      });
    }
  },

  onDelete: async (id) => {
    console.log("delete id ", id);

    const row = get().data.find((d) => d.id === id);
    console.log("row", row);
    return new Promise((resolve, reject) => {
      return deleteUserRole(id)
        .then((res) => {
          resolve(res);
          get().onSetUserRoleData();
        })
        .catch(() => {
          reject();
        });
    });
  },

  onFormSubmit: async (values) => {
    return new Promise((resolve, reject) => {
      let isEdit = get().isEditMode;
      let id = get().formData.id;
      let variant = get().variant;

      console.log("id ", id);
      console.log("isEdit ", isEdit);
      console.log("variant ", variant);
      console.log("values ", values);

      values.roleIds.forEach((roleId) => {
        let dataObject = {
          id: isEdit ? id : 0,
          userId: values.userId,
          roleId: roleId,
          projectId: 0,
        };

        return saveUserRole(dataObject)
          .then((res) => {
            resolve(res);
            get().onSetUserRoleData();
          })
          .catch(() => {
            reject();
          });
      });

      //resolve("done");
    });
  },

  clearForm: (callback) => {
    set({
      isEditMode: false,
      formData: {
        userId: null,
        roleIds: [],
        id: null,
      },
    });

    callback && callback();
  },

  onSetUserRoleData: async () => {
    // setTimeout(() => {
    //   set({ data: dummyData });
    // }, [2000]);
    //let onSetUserData = get().getUserList;
    //let onSetRoleData = get().getRoleList;

    const endpoints = new API_ENDPOINTS();
    const users = get().userOptions;
    const roles = get().roleOptions;
    let USER = JSON.parse(localStorage.getItem("user"));
    let tenantId = USER.result.tenantId;

    API.get(endpoints.PERMISSION_USERROLE_GET_ALL).then((response) => {
      console.log("response: ", response);
      if (response.data.isSuccess === true) {
        let Users = [];
        let Roles = [];
        let mappingList = [];
        let API_Result = response.data.result;

        API_Result.forEach((element) => {
          var obj = {
            id: element.id,
            userId: element.userId,
            roleList: API_Result.filter((r) => r.userId === element.userId),
          };

          var item =
            mappingList.find((f) => f.userId === element.userId) || null;
          console.log("[]", item);
          if (item === null) {
            mappingList.push(obj);
          }
        });

        API_Result = mappingList;

        let UserRoleList = [];
        let i = 0;

        API_Globle.get(
          endpoints.PERMISSION_GET_USER_LIST + "/" + tenantId
        ).then((response) => {
          console.log("user responce: ", response);
          Users = response.data.result;
          console.log("Users:", Users);

          API.get(endpoints.PERMISSION_ROLE_GET_ALL).then((response) => {
            console.log("role responce: ", response);
            Roles = response.data.result;

            API_Result.forEach((element) => {
              console.log("element.userId:", element.userId);
              var user = Users.find((u) => u.id === element.userId);
              var roles = [];
              console.log("user: ", user);
              element.roleList.forEach((relement) => {
                var roleDetails = Roles.find(
                  (f) => f.roleId === relement.roleId
                );
                var role = {
                  label: roleDetails.roleName,
                  id: roleDetails.roleId,
                };

                roles.push(role);
              });

              let dataObject = {
                user: { name: user?.givenName, id: user?.id },
                roles: roles,
                action: element.id,
                id: element.id,
              };
              var obj = UserRoleList.filter((o) => o.user?.id === user?.id);
              UserRoleList[i] = dataObject;
              i++;
            });
            console.log("UserRoleList:", UserRoleList);

            set({ data: UserRoleList });
          });
        });
      }
    });
  },
  getUserList: async (e) => {
    let USER = JSON.parse(localStorage.getItem("user"));
    let tenantId = USER.result.tenantId;
    const endpoints = new API_ENDPOINTS();

    API_Globle.get(endpoints.PERMISSION_GET_USER_LIST + "/" + tenantId).then(
      (response) => {
        console.log("response: ", response);
        if (response.data.isSuccess === false) {
          set({ users: response.data.result || [] });
        }
      }
    );
  },
  getRoleList: async (e) => {
    const endpoints = new API_ENDPOINTS();

    API.get(endpoints.PERMISSION_ROLE_GET_ALL).then((response) => {
      console.log("response: ", response);
      if (response.data.isSuccess === true) {
        //roles = response.data.result;
      }
    });
  },

  onLoadUserOptions: async () => {
    let USER = JSON.parse(localStorage.getItem("user"));
    let tenantId = USER.result.tenantId;
    const endpoints = new API_ENDPOINTS();

    API_Globle.get(endpoints.PERMISSION_GET_USER_LIST + "/" + tenantId).then(
      (response) => {
        console.log("response: ", response);
        if (response.data.isSuccess === false) {
          let userList = [];
          let i = 0;
          response.data.result.forEach((element) => {
            let obj = {
              label: element.givenName,
              value: element.id,
            };
            userList[i] = obj;
            i++;
          });

          set({ userOptions: userList });
        }
      }
    );
  },

  onLoadRoleOptions: async () => {
    const endpoints = new API_ENDPOINTS();

    API.get(endpoints.PERMISSION_ROLE_GET_ALL).then((response) => {
      console.log("response: ", response);
      if (response.data.isSuccess === true) {
        let roleList = [];
        let i = 0;
        response.data.result.forEach((element) => {
          let obj = {
            label: element.roleName,
            value: element.roleId,
          };
          roleList[i] = obj;
          i++;
        });

        set({ roleOptions: roleList });
      }
    });
  },
});

SettingUserRoleStore = devtools(SettingUserRoleStore);

export const useSettingUserRoleStore = create(SettingUserRoleStore);
