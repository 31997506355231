import React from "react";
import { useNotification } from "../../context/NotificationContext";
import { Container, Divider } from "./styled";
import SwitchField from "../../common/components/Form/SwitchField";
import PlainItem from "./NotificationItems/PlainItem";
import OnboradingItem from "./NotificationItems/OnboradingItem";
import { useNavigate } from "react-router-dom";

export const NOTIFICATION_TYPE = {
  INVITATION: 1,
  CONTRACT: 3,
};

const Notifications = () => {
  const { notificationList, onReadNotification, onToggleNotification } =
    useNotification();
  const navigate = useNavigate();

  const [visibleNotifications, setVisibleNotifications] =
    React.useState(notificationList);
  const [isReadOnly, setIsReadOnly] = React.useState(false);

  const handleOnView = React.useCallback(
    (notificationId, url, isRead) => {
      const origin = window.location.origin;
      const path = url.replace(origin, "");
      onToggleNotification();
      navigate(path);
      if (!isRead) {
        onReadNotification(notificationId);
      }
    },
    [navigate, onReadNotification, onToggleNotification]
  );

  const notificationItems = React.useMemo(() => {
    if (visibleNotifications.length < 1) {
      return (
        <PlainItem key={0} title="No Notification yet!">
          We'll notify you once we have something for you
        </PlainItem>
      );
    }

    return visibleNotifications.map((notificationGroup) => {
      const { date, notifications } = notificationGroup;
      return (
        <React.Fragment key={date}>
          <Divider>{date}</Divider>
          {notifications.map((notification) => {
            const {
              notificationId,
              notificationType,
              projectInfo,
              tenantName,
              fromUser,
              notificationHeader,
              notificationBody,
              url,
              isRead,
            } = notification;

            if (
              notificationType === NOTIFICATION_TYPE.INVITATION ||
              notificationType === NOTIFICATION_TYPE.CONTRACT
            ) {
              return (
                <React.Fragment key={notificationId}>
                  <OnboradingItem
                    projectLogo={projectInfo?.projectLogo}
                    projectName={projectInfo?.projectName}
                    tenantName={tenantName}
                    userCurrentStatusInProject={
                      projectInfo?.userCurrentStatusInProject
                    }
                    roleInProject={projectInfo?.roleInProject}
                    fromUser={fromUser}
                    notificationHeader={notificationHeader}
                    notificationBody={notificationBody}
                    notificationType={notificationType}
                    handleOnClick={() =>
                      handleOnView(notificationId, url, isRead)
                    }
                  />
                </React.Fragment>
              );
            } else {
              return (
                // For System notifications. Still no notificationType for this.
                <PlainItem key={notificationId} title={notificationHeader}>
                  {notificationBody}
                </PlainItem>
              );
            }
          })}
        </React.Fragment>
      );
    });
  }, [handleOnView, visibleNotifications]);

  const handleReadOnlySwitch = (e) => {
    setIsReadOnly(e.target.checked);
  };

  React.useEffect(() => {
    if (isReadOnly) {
      setVisibleNotifications(
        notificationList
          .map((dateObj) => ({
            ...dateObj,
            notifications: dateObj.notifications.filter(
              (notification) => !notification.isRead
            ),
          }))
          .filter((dateObj) => dateObj.notifications.length > 0)
      );
    } else {
      setVisibleNotifications(notificationList);
    }
  }, [isReadOnly, notificationList]);

  console.log("noto");
  return (
    <Container>
      <div className="notifications" key="notifications">
        <div className="notifications__header">
          <div className="title">Notifications</div>
          <div>
            <SwitchField onChange={handleReadOnlySwitch} label="Unread only" />
          </div>
        </div>
        <div className="notifications__content">
          <div className="items">{notificationItems}</div>
        </div>
      </div>
    </Container>
  );
};

export default Notifications;
