import React from "react";
import "./styles.scss";

import Dropdown from "react-bootstrap/Dropdown";
import { useProjectStore } from "../../../../../../store/ProjectStore";
import { useGlobalStore } from "../../../../../../store/GlobalStore";
import useUserData from "../../../../../../hooks/useUserData";
import { SYSTEM_ROLE_TYPE } from "../../../../../../constant/System";
import { updateProjectCheckedIn } from "../../../../../../Actions/Common";

const ProjectSelector = ({ onSelect }) => {
  let USER = JSON.parse(localStorage.getItem("user"));
  const { userRoles } = useUserData();

  const projects = useProjectStore((state) =>
    userRoles?.find((u) => u === SYSTEM_ROLE_TYPE.ADMIN)
      ? state.tenantProjects
      : state.userTenantProjects
  );

  const globalSelectedProjectId = useGlobalStore(
    (state) => state.selectedProjectId
  );

  const globalSelectedProject = useGlobalStore(
    (state) => state.selectedProject
  );

  const onProjectSelect = React.useCallback(
    (val) => {
      // Callback function
      onSelect && onSelect(val);
      const data = {
        TenantId: USER?.result?.tenantId,
        UserId: USER?.result?.userId,
        ProjectId: val,
      };
      updateProjectCheckedIn(data);
    },
    [USER?.result?.tenantId, USER?.result?.userId, onSelect]
  );

  React.useEffect(() => {
    if (!Number.parseInt(globalSelectedProjectId)) {
      const projectId = USER?.result?.currentProject?.projectId ?? 0;
      if (projectId) {
        onSelect && onSelect(projectId);
      }
    }
  }, [
    projects,
    globalSelectedProjectId,
    onSelect,
    USER?.result?.currentProject?.projectId,
  ]);

  if (!projects.length) {
    return null;
  }

  return (
    <>
      <div className="dashed-divider"></div>

      <div className="project__selector">
        <Dropdown
          bsPrefix="project__dropdown"
          onSelect={onProjectSelect}
          data-tooltip-text="Current project"
          data-tooltip-placement="right"
        >
          <Dropdown.Toggle
            variant="default"
            childBsPrefix="project__dropdown__button"
          >
            <span title={globalSelectedProject?.projectDetails?.name || ""}>
              {globalSelectedProject == null
                ? "Select Project"
                : globalSelectedProject?.projectDetails?.name}
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu className="scrollbar dropdown__menu">
            {projects.map((item, i) => (
              <Dropdown.Item
                as={"button"}
                key={i}
                eventKey={item.projectId}
                title={item.projectName.length > 10 ? item.projectName : ""}
              >
                {item.projectName}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

export default ProjectSelector;
