import * as React from "react";
import { memo } from "react";
const SvgCorrect = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.9998 3.33077C16.0055 2.9655 15.8842 2.65532 15.6358 2.40018C15.3816 2.13928 15.0783 2.00595 14.7259 2.0002C14.3619 1.99435 14.0529 2.11905 13.7987 2.3741L5.10714 10.8183L2.26476 7.6441C2.02217 7.37745 1.7217 7.2354 1.36354 7.21805C1.00539 7.19485 0.69345 7.30498 0.427651 7.54843C0.161943 7.78613 0.0203974 8.0847 0.00301376 8.44422C-0.0200986 8.80365 0.0897302 9.11967 0.332316 9.39208L4.07575 13.5751C4.23172 13.749 4.42531 13.8708 4.65634 13.9404C4.88164 14.0099 5.1098 14.0187 5.34095 13.9664C5.57198 13.9084 5.77419 13.7983 5.94752 13.6359L15.6096 4.24371C15.8638 3.98865 15.9938 3.68423 15.9996 3.33065L15.9998 3.33077Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgCorrect);
export default Memo;
