import React from "react";
// import { useSettingContext } from "../../../../../../../../shared/context/SettingContext";
import { Modal } from "./styled";
import Form from "./Form";
import { useActivityBlock } from "../../../context/ActivityBlockContext";
import { saveStage } from "../../../../../../../../api/schedule";
import { useAppStore } from "../../../../../../../../../../store/AppStore/AppStore";
import { useQueryClient } from "react-query";
import { StagesListKey } from "../../Tables/StagesTable/index";
import { useSettingContext } from "../../../../../../../../shared/context/SettingContext";

const ProductionStageModal = ({ ...otherProps }) => {
  const { projectId } = useSettingContext();
  const { stageModalData, StageModal } = useActivityBlock();
  const hideLoader = useAppStore((state) => state.hideLoader);
  const showLoader = useAppStore((state) => state.showLoader);
  const queryClient = useQueryClient();

  const isEditMode = stageModalData.payload?.type === "EDIT";
  const columnTypeId = stageModalData.payload?.data?.columnTypeId || 0;

  const isOpen = stageModalData.visibility;

  const renderModalTitle = React.useMemo(() => {
    if (!isEditMode) {
      return "Add Stage";
    }

    return "Edit Stage";
  }, [isEditMode]);
  const handleSubmit = (val, actions) => {
    showLoader();
    saveStage(
      isEditMode ? columnTypeId : 0,
      projectId,
      stageModalData.payload?.data,
      val
    ).then(() => {
      queryClient.refetchQueries([StagesListKey]);
      StageModal.close();
      hideLoader();
    });
  };

  return (
    <Modal
      //   isLoading={false}
      title={renderModalTitle}
      subTitle="Manage your stage specific details here"
      visibility={isOpen}
      body={<BodyComponent handleSubmit={handleSubmit} />}
      onClose={StageModal.close}
      {...otherProps}
    />
  );
};

function BodyComponent({ handleSubmit, formData }) {
  return <Form onSubmit={handleSubmit} />;
}

export default ProductionStageModal;
