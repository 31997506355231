/*
How to use : 
  Add bellow attribute in the HTML tag or Your Custom component.
  data-tooltip-text="This is a Tooltip"

  Example : 
    <p data-tooltip-text="Tooltip for Element">Form Elements</p>

*/

import React, { useEffect } from "react";

import Tooltip from "./Tooltip";

const DATA_TOOLTIP_ATTR = "data-tooltip-text";

/**
 ** Placements options :
 * auto, auto-start, auto-end,
 * left, left-start, left-end,
 * right, right-start, right-end
 * top, top-start, top-end
 * bottom, bottom-start, bottom-end
 */
const DATA_TOOLTIP_PLACEMENT_ATTR = "data-tooltip-placement";

const DATA_TOOLTIP_OFFSET_X = "data-tooltip-offset-x";
const DATA_TOOLTIP_OFFSET_Y = "data-tooltip-offset-y";
const DATA_TOOLTIP_APPEND_TO = "data-tooltip-append-to";

const findTooltipElement = (element) => {
  do {
    if (
      element &&
      element?.hasAttribute &&
      element?.hasAttribute(DATA_TOOLTIP_ATTR)
    ) {
      return element;
    }
    element = element.parentNode;
  } while (element);

  return undefined;
};

function TooltipContainer() {
  const [tooltipText, setTooltipText] = React.useState("");
  const [tooltipPlacement, setTooltipPlacement] = React.useState("bottom");
  const [tooltipOffset, setTooltipOffset] = React.useState({ x: 0, y: 8 });
  const [tooltipAppendTo, setTooltipAppendTo] = React.useState(document.body);

  const [isShowTooltip, setIsShowTooltip] = React.useState(false);
  const [referenceElement, setReferenceElement] = React.useState(null);

  useEffect(() => {
    function handleMouseOver(event) {
      const element = findTooltipElement(event.target);

      if (element) {
        const text = element.getAttribute(DATA_TOOLTIP_ATTR);
        const placement = element?.getAttribute(DATA_TOOLTIP_PLACEMENT_ATTR);
        const offsetX = element?.getAttribute(DATA_TOOLTIP_OFFSET_X) || 0;
        const offsetY = element?.getAttribute(DATA_TOOLTIP_OFFSET_Y) || 8;
        let appendTo =
          element?.getAttribute(DATA_TOOLTIP_APPEND_TO) || document.body;

        if (text.length === 0) {
          return;
        }

        if (typeof appendTo === "string") {
          appendTo = document.querySelector(appendTo);
        }

        setTooltipAppendTo(appendTo);
        setTooltipPlacement(placement || "bottom");
        setTooltipOffset({ x: offsetX, y: offsetY });
        setTooltipText(text);
        setReferenceElement(element);
        setIsShowTooltip(true);
      }
    }

    function handleMouseOut() {
      setIsShowTooltip(false);
      setTooltipPlacement("bottom");
      setTooltipOffset({ x: 0, y: 8 });
    }

    window.addEventListener("mouseover", handleMouseOver);
    window.addEventListener("mouseout", handleMouseOut);

    return () => {
      window.removeEventListener("mouseover", handleMouseOver);
      window.removeEventListener("mouseout", handleMouseOut);
    };
  }, [tooltipText]);

  return (
    <Tooltip
      isShow={isShowTooltip && referenceElement}
      text={tooltipText}
      referenceElement={referenceElement}
      placement={tooltipPlacement}
      offset={tooltipOffset}
      tooltipAppendTo={tooltipAppendTo}
    />
  );
}

export default TooltipContainer;
