import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Local
import API from "../../../services/api";
import API_Project from "../../../services/api-project";
import API_ENDPOINTS from "../../../services/api-endpoints";
import Container from "../../ui-components/Container";
import TabMenuItems, { TabNames } from "./components/TabMenuItems";
import GenerateContractModal from "../../ui-components/Modals/GenerateContractModal";

import "../../assets/css/contract_generation.18ab0e0756269739ce6619d3fae327a2.css";
import "../../../components/sass/layout/common.css";
import HRListItem from "./components/HRListItem";
import {
  getProjectHRListByName,
  getProjectHRListByProjectId,
} from "../../../Actions/Project";
import {
  ONBOARDING_STATUS,
  VIEW_CONTRACT_SOURCES,
} from "../../../constant/Status";
import useGeneratePath from "../../../hooks/useGeneratePath";
import { useAppStore } from "../../../store/AppStore/AppStore";
import { useProjectStore } from "../../../store/ProjectStore";
import { ViewContractRights } from "./view-contract";
import OnboardingItems from "./components/OnboardingList";
import { OnboardingActionType } from "../../../constant/ActionTypes";
import { HRHeader } from "./components/HRListItem/styled";

const HRList = () => {
  let USER = JSON.parse(localStorage.getItem("user"));
  let _PROJECT_ = JSON.parse(localStorage.getItem("selected_project"));
  var inputtext = "";
  let MemberList = [];
  let ShortList = [];
  let OnBoardingList = [];
  let HRList = [];

  const [allMembers, setAllMembers] = useState([]);
  const [isRegenerate, setIsRegenerate] = useState(false);
  const [show, setShow] = useState(false); //Loading Indicator
  const [allShortLIst, setAllShortList] = useState([]);
  const [allOnBoadingListSearch, setAllOnBoadingListSearch] = useState([]);
  const [allHRListSearch, setAllHRListSearch] = useState([]);
  const [showGenerateContractModal, setShowGenerateContractModal] =
    useState(false);

  const [member, setMember] = useState(null);

  const setProjectSelectedMember = useProjectStore(
    (state) => state.setProjectSelectedMember
  );

  const openOnboardingModal = useProjectStore(
    (state) => state.openOnboardingModal
  );

  const isListReload = useProjectStore((state) => state.isListReload);
  const onReloadList = useProjectStore((state) => state.onReloadList);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { genViewContractPath } = useGeneratePath();

  useEffect(() => {
    console.log("selected_project", _PROJECT_);
    searchAllMembers();
    getShortList(_PROJECT_.projectId);
    getOnBoardingList(_PROJECT_.projectId);
    getHRList(_PROJECT_.projectId);
  }, []);

  useEffect(() => {
    if (isListReload) {
      getOnBoardingList(_PROJECT_.projectId);
      getHRList(_PROJECT_.projectId);
      onReloadList(false);
    }
  }, [isListReload, onReloadList, _PROJECT_.projectId]);

  /**
   * * Go To View Contract page
   * @param member
   */
  const goToViewContract = (member, right = ViewContractRights.viewOnly) => {
    const { userId, contractId, contractRequestId } = member;

    const path = genViewContractPath({
      userId,
      contractId,
      contractRequestId,
      source: VIEW_CONTRACT_SOURCES.hr,
    });

    navigate(path);
    // navigate(path, {
    //   state: {
    //     rightType: right,
    //   },
    // });
  };
  //
  const onActionClickHandler = (action) => {
    console.log("member in hr list ", action);

    const actionType = action?.type;
    const member = action?.payload.member;

    // select member
    setMember(member);
    setProjectSelectedMember(member);

    if (actionType === OnboardingActionType.GENERATE_CONTRACT) {
      if (member?.isRegenerate) {
        setIsRegenerate(true);
      }

      setShowGenerateContractModal(true);
      return;
    }

    if (actionType === OnboardingActionType.VIEW_CONTRACT) {
      goToViewContract(member, ViewContractRights.viewAndEdit);
      return;
    }

    if (actionType === OnboardingActionType.ONBOARD) {
      openOnboardingModal();
      return;
    }
  };

  const searchInputKeyDown = async (e) => {
    inputtext = e.target.value;
    if (e.key.length === 1) {
      inputtext = inputtext + e.key;
    } else {
      if (e.key === "Backspace") {
        inputtext = inputtext.slice(0, -1);
      }
    }

    console.log(inputtext);
    searchMembersByName(inputtext);
    try {
    } catch (err) {
      console.log(err);
    }
  };

  const searchAllMembers = async () => {
    try {
      setShow(true);
      let tenantId = USER.result.tenantId;
      const endpoints = new API_ENDPOINTS();
      const response = API.get(endpoints.GETALLUSER_BYTenant + "/" + 0).then(
        (response) => {
          console.log(response);
          if (response.data.isSuccess === true) {
            MemberList = response.data.result;
            console.log("Allmembers", MemberList);
            const member_list = MemberList.map((item) => {
              return <div></div>;
            });
            setAllMembers(member_list);
            setShow(false);
          } else {
            setShow(false);
            return;
          }
        }
      );
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const getShortList = async (projectId) => {
    try {
      setShow(true);
      let tenantId = USER.result.tenantId;
      const endpoints = new API_ENDPOINTS();
      const response = API_Project.get(
        endpoints.PROJECT_GET_PROJECT_MEMBER_SHORTLIST_BY_PROJECT +
          "/" +
          projectId
      ).then((response) => {
        console.log(response);
        if (response.data.isSuccess === true) {
          //toast.success(response.data.displayMessage);
          ShortList = response.data.result;
          console.log("Short List", ShortList);
          const short_list = ShortList.map((item) => {
            return (
              <div className="shortlisted__member">
                <div className="shortlisted__member__iW">
                  <div className="shortlisted__member__pic">
                    {" "}
                    <img src={item.profileImage} />
                  </div>
                </div>
                <div className="shortlisted__member__iW">
                  <div className="shortlisted__member__name">
                    {item.surName}
                  </div>
                </div>
                <div className="shortlisted__member__iW">
                  <div className="shortlisted__member__designation">
                    {item.jobTitleName}
                  </div>
                </div>
              </div>
            );
          });
          setAllShortList(short_list);
          setShow(false);
        } else {
          setShow(false);
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const getOnBoardingList = async (projectId) => {
    try {
      setShow(true);
      let tenantId = USER.result.tenantId;
      const endpoints = new API_ENDPOINTS();
      const response = API_Project.get(
        endpoints.PROJECT_GET_PROJECT_MEMBER_ONBOARDINGLIST_BY_PROJECT +
          "/" +
          projectId
      ).then((response) => {
        console.log(response);
        if (response.data.isSuccess === true) {
          //toast.success(response.data.displayMessage);
          OnBoardingList = response.data.result;
          console.log("Onboarding List", OnBoardingList);
          ////////////////////////////////////////////
          let categorizedMembers = [];
          OnBoardingList.forEach((member) => {
            let members = OnBoardingList.filter(
              (m) => m.jobTitleId === member.jobTitleId
            );

            categorizedMembers.push({
              jobTitleId: member.jobTitleId,
              jobTitleName: member.jobTitleName,
              members: members,
            });
          });

          categorizedMembers = categorizedMembers.filter(
            (ele, ind) =>
              ind ===
              categorizedMembers.findIndex(
                (elem) => elem.jobTitleId === ele.jobTitleId
              )
          );
          console.log("cat-Allmembers", categorizedMembers);
          //////////////////////////////////////////////////////////////////////
          const onboarding_list = OnBoardingList.map((item) => {
            return (
              <div className="shortlisted__member">
                <div className="shortlisted__member__iW">
                  <div className="shortlisted__member__pic">
                    {" "}
                    <img src={item.profileImage} />
                  </div>
                </div>
                <div className="shortlisted__member__iW">
                  <div className="shortlisted__member__name">
                    {item.surName}
                  </div>
                </div>
                <div className="shortlisted__member__iW">
                  <div className="shortlisted__member__designation">
                    {item.jobTitleName}
                  </div>
                </div>
              </div>
            );
          });
          /////////////////////////////////////////////Search////////////////////////////////////////////////
          const onboarding_list_search = categorizedMembers.map((item) => {
            let skillList = item.skillList;

            let skillListString = "";
            console.log("skills", skillList);
            skillList?.forEach((skill) => {
              skillListString = skillListString + skill.skillName + ", ";
            });
            return (
              <div>
                <div className="onb__page__container__iW shrink_to_fit">
                  <div className="onb__page__section__title">
                    {item.jobTitleName}
                    <span>({item.members.length})</span>
                  </div>
                </div>
                <div className="onb__page__container__iW shrink_to_fit no_top_margin">
                  {item.members.map((member) => {
                    let skillList = member.skillList;
                    let skillListString = "";
                    let statusColor = "#000000";
                    if (member.statusId === 2) {
                      statusColor = "rgb(45 81 92)";
                    }
                    if (member.statusId === 3) {
                      statusColor = "#45a15d";
                    }
                    // console.log("skills", skillList);
                    skillList?.forEach((skill) => {
                      skillListString =
                        skillListString + skill.skillName + ", ";
                    });
                    return (
                      <div className="onb__member">
                        <span
                          style={{
                            width: "25%",
                            position: "absolute",
                            top: "-11px",
                            backgroundColor: statusColor,
                            borderRadius: 5,
                            left: "60%",
                            border: "solid gray 1px",
                            color: "gainsboro",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;{member.status}
                        </span>

                        <div className="onb__member__iW">
                          <div className="onb__member__pic_n_menu">
                            <div className="onb__member__pic_n_menu__iW">
                              <div className="onb__member__pic">
                                <a className="icon icon-lp-star " href="#">
                                  {" "}
                                </a>
                                <div className="onb__member__pic__iW">
                                  {" "}
                                  <img src={member.profileImage} />
                                </div>
                              </div>
                              <div className="onb__member__menu">
                                <a className="icon icon-lp-dot-menu" href="#" />
                              </div>
                            </div>
                          </div>
                          <div className="onb__member__name">
                            <div className="onb__member__name__iW">
                              <div className="onb__member__nickname">
                                {member.surName}
                              </div>
                              <div className="onb__member__fullname">
                                {member.surName} {member.givenName}
                              </div>
                            </div>
                          </div>
                          <div className="onb__member__primary__skill">
                            {member.jobTitleName}
                          </div>
                          <div className="onb__member__other__skills">
                            {skillListString}{" "}
                          </div>
                        </div>
                        <div className="onb__member__iW">
                          <div className="onb__member__other__info">
                            <div className="onb__member__other__info__iW">
                              {" "}
                              <a
                                className="icon icon-lp-profile profile"
                                href="#"
                              />
                              <div className="city_location">
                                <div className="city_location_iW">
                                  {" "}
                                  <a
                                    className="icon icon-lp-city city"
                                    href="#"
                                  >
                                    Węgliniec
                                  </a>
                                  <a
                                    className="icon icon-lp-location location"
                                    href="#"
                                  >
                                    Poland
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="onb__member__iW">
                          <div className="onb__member__actions">
                            <div className="onb__member__actions__iW">
                              {" "}
                              <a
                                className="onb__member__chat icon icon-lp-chat"
                                href="#"
                              >
                                {" "}
                              </a>
                              <a
                                className="onb__member__remove icon icon-lp-delete-2"
                                href="#"
                                // onClick={() => removeUserFromShortlist(member)}
                              >
                                {" "}
                              </a>
                              <a
                                className="onb__member__addToShortlist"
                                href="#"
                                // onClick={() => ShowAddToProjectDialog(member)}
                              >
                                Action
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          });
          //setAllShortList(short_list);
          setAllOnBoadingListSearch(onboarding_list_search);
          setShow(false);
        } else {
          setShow(false);
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const getOnBoardingListByStatus = async (projectId, statusId) => {
    try {
      setShow(true);
      let tenantId = USER.result.tenantId;
      const endpoints = new API_ENDPOINTS();
      const response = API_Project.get(
        endpoints.PROJECT_GET_PROJECT_MEMBER_ONBOARDINGLIST_BY_PROJECT +
          "/" +
          projectId
      ).then((response) => {
        console.log(response);
        if (response.data.isSuccess === true) {
          //toast.success(response.data.displayMessage);
          OnBoardingList = response.data.result;
          console.log("Onboarding List", OnBoardingList);
          ////////////////////////////////////////////
          let categorizedMembers = [];
          let StatusBaseList = OnBoardingList.filter(
            (m) => m.statusId === statusId
          );
          StatusBaseList.forEach((member) => {
            let members = OnBoardingList.filter(
              (m) =>
                m.jobTitleId === member.jobTitleId && m.statusId === statusId
            );

            categorizedMembers.push({
              jobTitleId: member.jobTitleId,
              jobTitleName: member.jobTitleName,
              members: members,
            });
          });

          categorizedMembers = categorizedMembers.filter(
            (ele, ind) =>
              ind ===
              categorizedMembers.findIndex(
                (elem) => elem.jobTitleId === ele.jobTitleId
              )
          );
          console.log("cat-Allmembers", categorizedMembers);
          //////////////////////////////////////////////////////////////////////
          const onboarding_list = OnBoardingList.map((item) => {
            return (
              <div className="shortlisted__member">
                <div className="shortlisted__member__iW">
                  <div className="shortlisted__member__pic">
                    {" "}
                    <img src={item.profileImage} />
                  </div>
                </div>
                <div className="shortlisted__member__iW">
                  <div className="shortlisted__member__name">
                    {item.surName}
                  </div>
                </div>
                <div className="shortlisted__member__iW">
                  <div className="shortlisted__member__designation">
                    {item.jobTitleName}
                  </div>
                </div>
              </div>
            );
          });
          /////////////////////////////////////////////Search////////////////////////////////////////////////
          const onboarding_list_search = categorizedMembers.map((item) => {
            let skillList = item.skillList;

            let skillListString = "";
            console.log("skills", skillList);
            skillList?.forEach((skill) => {
              skillListString = skillListString + skill.skillName + ", ";
            });
            return (
              <div>
                <div className="onb__page__container__iW shrink_to_fit">
                  <div className="onb__page__section__title">
                    {item.jobTitleName}
                    <span>({item.members.length})</span>
                  </div>
                </div>
                <div className="onb__page__container__iW shrink_to_fit no_top_margin">
                  {item.members.map((member) => {
                    let skillList = member.skillList;
                    let skillListString = "";
                    let statusColor = "#000000";
                    if (member.statusId === 2) {
                      statusColor = "rgb(45 81 92)";
                    }
                    if (member.statusId === 3) {
                      statusColor = "#45a15d";
                    }
                    // console.log("skills", skillList);
                    skillList?.forEach((skill) => {
                      skillListString =
                        skillListString + skill.skillName + ", ";
                    });
                    return (
                      <div className="onb__member">
                        <span
                          style={{
                            width: "25%",
                            position: "absolute",
                            top: "-11px",
                            backgroundColor: statusColor,
                            borderRadius: 5,
                            left: "60%",
                            border: "solid gray 1px",
                            color: "gainsboro",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;{member.status}
                        </span>

                        <div className="onb__member__iW">
                          <div className="onb__member__pic_n_menu">
                            <div className="onb__member__pic_n_menu__iW">
                              <div className="onb__member__pic">
                                <a className="icon icon-lp-star " href="#">
                                  {" "}
                                </a>
                                <div className="onb__member__pic__iW">
                                  {" "}
                                  <img src={member.profileImage} />
                                </div>
                              </div>
                              <div className="onb__member__menu">
                                <a className="icon icon-lp-dot-menu" href="#" />
                              </div>
                            </div>
                          </div>
                          <div className="onb__member__name">
                            <div className="onb__member__name__iW">
                              <div className="onb__member__nickname">
                                {member.surName}
                              </div>
                              <div className="onb__member__fullname">
                                {member.surName} {member.givenName}
                              </div>
                            </div>
                          </div>
                          <div className="onb__member__primary__skill">
                            {member.jobTitleName}
                          </div>
                          <div className="onb__member__other__skills">
                            {skillListString}{" "}
                          </div>
                        </div>
                        <div className="onb__member__iW">
                          <div className="onb__member__other__info">
                            <div className="onb__member__other__info__iW">
                              {" "}
                              <a
                                className="icon icon-lp-profile profile"
                                href="#"
                              />
                              <div className="city_location">
                                <div className="city_location_iW">
                                  {" "}
                                  <a
                                    className="icon icon-lp-city city"
                                    href="#"
                                  >
                                    Węgliniec
                                  </a>
                                  <a
                                    className="icon icon-lp-location location"
                                    href="#"
                                  >
                                    Poland
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="onb__member__iW">
                          <div className="onb__member__actions">
                            <div className="onb__member__actions__iW">
                              {" "}
                              <a
                                className="onb__member__chat icon icon-lp-chat"
                                href="#"
                              >
                                {" "}
                              </a>
                              <a
                                className="onb__member__remove icon icon-lp-delete-2"
                                href="#"
                                // onClick={() => removeUserFromShortlist(member)}
                              >
                                {" "}
                              </a>
                              <a
                                className="onb__member__addToShortlist"
                                href="#"
                                // onClick={() => ShowAddToProjectDialog(member)}
                              >
                                Action
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          });
          //setAllShortList(short_list);
          setAllOnBoadingListSearch(onboarding_list_search);
          setShow(false);
        } else {
          setShow(false);
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const onStatusChange = async (statusId) => {
    try {
      console.log("statusId", statusId);
      if (statusId !== 0) {
        getOnBoardingListByStatus(_PROJECT_.projectId, statusId);
      } else {
        getOnBoardingList(_PROJECT_.projectId);
      }
      let element = document.getElementById("status_filter_options");
      element.style.display = "none";
    } catch (err) {
      console.log(err);
    }
  };

  const onFilter = async () => {
    try {
      let element = document.getElementById("status_filter_options");
      element.style.display = "flex";
    } catch (err) {
      console.log(err);
    }
  };

  const setHRListData = (response) => {
    const data = response?.data?.result || [];
    const listArray = data;
    listArray.forEach((element) => {
      element.jobTitleId = element.projectRoleId;
      element.jobTitleName = element.projectRoleName;
    });
    // const listArray = data.filter(
    //   (item) =>
    //     item.statusId === ONBOARDING_STATUS.ContractRequested ||
    //     item.statusId === ONBOARDING_STATUS.ContractChangeRequested ||
    //     item.statusId === ONBOARDING_STATUS.ContractSigned ||
    //     item.statusId === ONBOARDING_STATUS.ContractExecuted
    // );

    console.log("HR filtered data", listArray);

    setAllHRListSearch(listArray);
  };

  const getHRList = async (projectId) => {
    try {
      const response = await getProjectHRListByProjectId(projectId);

      if (!response.data.isSuccess) {
        return;
      }

      setHRListData(response);
    } catch (err) {
      console.error(err);
      setShow(false); //Loading Indicator
    }
  };

  const searchMembersByName = async (searchText) => {
    try {
      const response = await getProjectHRListByName(
        _PROJECT_.projectId,
        searchText
      );

      if (!response.data.isSuccess) {
        return;
      }

      setHRListData(response);
    } catch (err) {
      console.log(err);
    }
  };

  const onGenerateContractSuccess = (res) => {
    console.log("onGenerateContractSuccess ", res);
    // * Reload HR List
    setIsRegenerate(false);
    getHRList(_PROJECT_.projectId);
  };

  return (
    <>
      <Container withGutterY={false} pageContentClassName="onb_fullpage">
        <div className="onb__page__container top__info">
          {/* <div className="onb__page__container__iW">
            <div className="onb__page__title">
              HR List - {_PROJECT_.projectName}
            </div>
          </div> */}

          <div className="onb__page__container__iW extended">
            <div className="onb__page__actionbar">
              <div className="onb__page__actionbar__iW">
                <div className="onb__page__actionbar__left">
                  <div className="onb__page__actionbar__left__iW">
                    <div className="onb__page__actionbar__search">
                      <div className="input-group line-inputs icon icon-lp-search">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Search"
                          aria-label="find member"
                          onKeyDown={searchInputKeyDown}
                        />
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          All Members
                        </button>
                        <div className="dropdown-menu dropdown-menu-end custom_dropdown_style">
                          <div className="dropdown_iW">
                            <ul>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Director of Photography
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Director of Photography
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Director of Photography
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="onb__page__actionbar__search-filter">
                      <div className="dropdown icon_only_btn_dropdown">
                        {" "}
                        <button
                          className="btn dropdown-toggle icon icon-lp-filter-1 icon-20"
                          id="filter_search"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        />
                        <div
                          className="dropdown-menu custom_dropdown_style"
                          aria-labelledby="filter_search"
                        >
                          <div className="dropdown_iW">
                            <div className="dropdown__title">
                              Job Categories
                            </div>
                            <ul>
                              <li>
                                {" "}
                                <a className="dropdown-item" href="#">
                                  Director of Photography
                                </a>
                              </li>
                              <li>
                                {" "}
                                <a className="dropdown-item" href="#">
                                  Director of Photography
                                </a>
                              </li>
                              <li>
                                {" "}
                                <a className="dropdown-item" href="#">
                                  Director of Photography
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="onb__page__actionbar__right">
                  <div className="onb__page__actionbar__right__iW">
                    <div className="onb__page__actionbar__sort">
                      <div className="dropdown icon_only_btn_dropdown">
                        {" "}
                        <button
                          className="btn dropdown-toggle icon icon-lp-sorting icon-20"
                          id="filter_search"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={() => onFilter()}
                        />
                        <div
                          className="dropdown-menu custom_dropdown_style"
                          aria-labelledby="filter_search"
                          id="status_filter_options"
                        >
                          <div className="dropdown_iW">
                            <div className="dropdown__title">Filter by</div>
                            <ul>
                              <li>
                                {" "}
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() => onStatusChange(0)}
                                >
                                  All
                                </a>
                              </li>
                              <li>
                                {" "}
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() => onStatusChange(2)}
                                >
                                  Add to Project
                                </a>
                              </li>
                              <li>
                                {" "}
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() => onStatusChange(3)}
                                >
                                  Invited
                                </a>
                              </li>
                              <li>
                                {" "}
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() => onStatusChange(4)}
                                >
                                  NDA
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="onb__page__actionbar__viewSwitch">
                      <ul>
                        <li className="active">
                          <a className="icon icon-lp-grid-small" href="#" />
                        </li>
                        <li>
                          <a className="icon icon-lp-th-list" href="#" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="onb__page__global__save me-2">
              <button
                className="btn btn-orange text-white btn-lg icon icon-lp-add-user lp-org-btn"
                type="button"
              ></button>
            </div>
            <div className="onb__page__global__save">
              <button
                className="btn btn-green-light text-white btn-lg icon icon-lp-add-user"
                type="button"
              >
                Add Shortlisted to Project
              </button>
            </div> */}
          </div>

          <div className="onb__page__container__iW">
            <div className="onb__page__tabs">
              <div className="onb__page__tabs__iW">
                <div className="onb__page__tabs__left">
                  <TabMenuItems
                    memberCount={allMembers.length}
                    shortListCount={allShortLIst.length}
                    onboardingListCount={allOnBoadingListSearch.length}
                    hrListCount={allHRListSearch.length}
                    activeTab={TabNames.HRList}
                  />
                </div>

                <div className="onb__page__tabs__right">
                  <div className="onb__page__tabs__right__iW">
                    <div className="onb__page__tabs__right__additional">
                      <ul className="onb__page__tabs__right__additional__iW">
                        <li>
                          <a href="#">
                            Request Sent<span>(2)</span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            Offer Rejected<span>(32)</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="onb__page__pagination">
                      <nav aria-label="Page navigation example">
                        <ul className="pagination">
                          <li className="page-item disabled">
                            <a
                              className="page-link"
                              href="#"
                              tabIndex={-1}
                              aria-disabled="true"
                            >
                              Previous
                            </a>
                          </li>
                          <li className="page-item active">
                            <a className="page-link" href="#">
                              1
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              2
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              3
                            </a>
                          </li>
                          <li className="page-item disabled">
                            <a
                              className="page-link"
                              href="#"
                              tabIndex={-1}
                              aria-disabled="true"
                            >
                              Next
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="onb__page__container top_bottom_gap">
          <OnboardingItems viewType="list">
            {/* {allMembers} */}
            <HRHeader>
              <div>Profile</div>
              <div>
                <div className="inner">
                  <div>Name</div>
                  <div>Designation</div>
                  <div></div>
                </div>
              </div>
              <div></div>
              <div>Action</div>
            </HRHeader>

            {allHRListSearch?.map((item, i) => {
              return (
                <HRListItem
                  viewType="list"
                  key={i}
                  data={item}
                  projectName={_PROJECT_.projectName}
                  onActionClick={onActionClickHandler}
                />
              );
            })}
          </OnboardingItems>
        </div>
      </Container>

      <GenerateContractModal
        member={member}
        isRegenerate={isRegenerate}
        visibility={showGenerateContractModal}
        onClose={() => setShowGenerateContractModal(false)}
        onGenerateContractSuccess={onGenerateContractSuccess}
        // onBackdropClick={toggleModal}
      />

      {/* Genarate Contract  */}
      <React.Fragment>
        {/* <Modal
          show={showContractGenModal}
          onClose={() => setShowsContractGenModal(false)}
          id="__project_create_popup"
        >
          <div classname="content">
            <div className="base__content">
              <div className="base__content__iW">
                <div className="base__content__contain__area">
                  <div className="page__content">
                    <div className="page__content__iW">
                      <div className="page__content__wrapper">
                        <div className="external__wr">
                          <div className="pop__header">
                            <div className="pop__header__iw">
                              <div className="pop__title">
                                Generate Contract for Jabez Moorfield
                              </div>
                              <div className="pop__subtitle">
                                For the Contract, Jabez basic profile should be
                                up to date
                                <span className="icon icon__bell icon-12 icon-lp-notification text-orange" />
                              </div>
                            </div>
                            <button
                              className="pop__close"
                              type="button"
                              title="Close"
                              onClick={() => setShowsContractGenModal(false)}
                            >
                              <span className="icon icon-lp-close" />
                            </button>
                          </div>
                          <div className="pop__onb__hr__container">
                            <div className="pop__top">
                              <div className="pop__top__content">
                                <div className="pop__top__member__wr">
                                  <div className="pop__top__member__pic">
                                    <div className="pop__top__member__pic__iw">
                                      <img src="https://robohash.org/velnesciuntenim.jpg?size=100x100&set=set1" />
                                    </div>
                                    <div className="pop__top__member__pic__ic">
                                      <div className="icon icon-lp-profile profile" />
                                    </div>
                                  </div>
                                  <div className="pop__top__member_info">
                                    <div className="member__name">
                                      Jabez Moorfield{" "}
                                    </div>
                                    <div className="member__position">
                                      Props Manager
                                    </div>
                                  </div>
                                </div>
                                <div className="pop__top__info__wr">
                                  <div className="info__ic">
                                    <div className="icon icon-lp-masks icon-20 text-orange" />
                                  </div>
                                  <div className="info__content">
                                    <div className="info__content__subtitle">
                                      Join as the
                                    </div>
                                    <div className="info__content__title">
                                      Director of Photography
                                    </div>
                                  </div>
                                </div>
                                <div className="pop__top__info__wr">
                                  <div className="info__ic">
                                    <div className="icon icon-lp-doc-write icon-20 text-orange" />
                                  </div>
                                  <div className="info__content">
                                    <div className="info__content__subtitle">
                                      Project
                                    </div>
                                    <div className="info__content__title">
                                      Negotiators
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="pop__center">
                              <div className="content__wr">
                                <div className="content__wr__title">
                                  History
                                </div>
                                <div className="history__list__wr">
                                  <ul className="list__items">
                                    <li>
                                      <div className="item__content">
                                        <div className="item__content__date">
                                          2022 jan 25
                                        </div>
                                        <div className="item__content__info">
                                          <div className="item__content__info__title">
                                            Invite To the Project
                                          </div>
                                          <div className="item__content__info__desc__text">
                                            <span className="assigner">
                                              @buddhika
                                            </span>
                                            invited{" "}
                                            <span className="txt-highlight">
                                              Jabez{" "}
                                            </span>
                                            to the project{" "}
                                            <span className="txt-highlight">
                                              Negotiators
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="item__content">
                                        <div className="item__content__date">
                                          2022 jan 25
                                        </div>
                                        <div className="item__content__info">
                                          <div className="item__content__info__title">
                                            Invite To the Project
                                          </div>
                                          <div className="item__content__info__desc__text">
                                            <span className="assigner">
                                              @buddhika
                                            </span>
                                            invited{" "}
                                            <span className="txt-highlight">
                                              Jabez{" "}
                                            </span>
                                            to the project{" "}
                                            <span className="txt-highlight">
                                              Negotiators
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="item__content">
                                        <div className="item__content__date">
                                          2022 jan 25
                                        </div>
                                        <div className="item__content__info">
                                          <div className="item__content__info__title">
                                            Invite To the Project
                                          </div>
                                          <div className="item__content__info__desc__text">
                                            <span className="assigner">
                                              @buddhika
                                            </span>
                                            invited{" "}
                                            <span className="txt-highlight">
                                              Jabez{" "}
                                            </span>
                                            to the project{" "}
                                            <span className="txt-highlight">
                                              Negotiators
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="pop__bottom">
                              <div className="pop__bottom__iw">
                                <div className="inputs__wr">
                                  <div className="line__input__group">
                                    <div className="line__input__label">
                                      Role
                                    </div>
                                    <div className="line__input__element">
                                      <select
                                        className="select"
                                        aria-label="Select Role"
                                      >
                                        <option disabled selected value>
                                          Select Role
                                        </option>
                                        <option value={1}>Project 1</option>
                                        <option value={2}>Project 2</option>
                                        <option value={3}>Project 3</option>
                                        <option value={4}>Project 4</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="line__input__group">
                                    <div className="line__input__label">
                                      Fee
                                    </div>
                                    <div className="line__input__element">
                                      <input
                                        type="text"
                                        placeholder="Hiring Fee"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="buttons__wr">
                                  <button
                                    className="btn btn-gray-400 text-white btn-lg text-white icon icon-lp-cancel btn__icon"
                                    type="button"
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className="btn btn-green-light btn-lg text-white icon icon-lp-done btn__icon"
                                    type="button"
                                    onClick={() => genarateContractRequest(9)}
                                  >
                                    Generate Contract
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal> */}
      </React.Fragment>
    </>
  );
};

export default HRList;
