import React from "react";
import NewProject from "../../../components/pages/project/new-project";
import ProjectBrief from "../../../components/pages/project/project-brief";
import BreadcrumbLink from "../../../components/ui-components/Breadcrumb/BreadcrumbLink";

import { onboardingRoutes } from "./onboarding-route";
import OnboardingPage from "../../../components/pages/onBoarding";
import { ROUTE_OPTIONS } from "../../../constant/route-options";
import { permissionLoader } from "../../loaders";
import ViewContract from "../../../components/pages/onBoarding/view-contract";
import PermissionWrapper from "../../../components/pages/project/permission-wrapper";
import ka from "date-fns/locale/ka";
// const ProjectDashboard = React.lazy(() =>
//   import("../../../components/pages/project/ProjectDashboard")
// );
const permissionCache = {};
export const projectRoutes = [
  // Project Dashboard
  // {
  //   id: ROUTE_OPTIONS.projects.children["project-dashboard"].id,
  //   path: ROUTE_OPTIONS.projects.children["project-dashboard"].path,
  //   element: (
  //     <React.Suspense fallback={<></>}>
  //       <ProjectDashboard />
  //     </React.Suspense>
  //   ),
  //   handle: {
  //     crumb: (path, id) => (
  //       <BreadcrumbLink
  //         label={ROUTE_OPTIONS.projects.children["project-dashboard"].label}
  //       />
  //     ),
  //   },

  //   loader: async () => {
  //     return true;
  //     // return await permissionLoader(
  //     //   ROUTE_OPTIONS.projects.children["project-dashboard"].id
  //     // );
  //   },
  // },

  // NewProject
  {
    id: ROUTE_OPTIONS.projects.children["new-project"].id,
    path: ROUTE_OPTIONS.projects.children["new-project"].path,
    element: <NewProject />,
    handle: {
      // crumb: () => (
      //   <BreadcrumbLink
      //     label={ROUTE_OPTIONS.projects.children["new-project"].label}
      //   />
      // ),
    },
    loader: async () => {
      // return true;
      return await permissionLoader(
        ROUTE_OPTIONS.projects.id,
        ROUTE_OPTIONS.projects.children["new-project"].id
      );
    },
  },

  // Onboarding
  {
    id: ROUTE_OPTIONS.projects.children["onboarding"].id,
    path: ROUTE_OPTIONS.projects.children["onboarding"].path,
    children: onboardingRoutes,
    element: (
      <PermissionWrapper 
        cacheKey={`${ROUTE_OPTIONS.projects.children["onboarding"].id}-project`}
        permissionCache={permissionCache}
      >
        <OnboardingPage />
      </PermissionWrapper>
    ),
    handle: {
      crumb: () => (
        <BreadcrumbLink
          label={ROUTE_OPTIONS.projects.children["onboarding"].label}
          // to={`${ProjectRoutePath.path}/${ProjectRoutePath.Onboarding.path}`}
        />
      ),
    },
    loader: async () => {
      const routeId = ROUTE_OPTIONS.projects.children["onboarding"].id;
      const cacheKey = `${routeId}-onboarding`;
               
      if (permissionCache[cacheKey]) {
          console.log('onboarding- Returning cached permission data: ',permissionCache[cacheKey]);
          return permissionCache[cacheKey];
       }

      console.log('Calling Permission api for: ', routeId);

      const permission = await permissionLoader(routeId, routeId);
      permissionCache[cacheKey] = permission;
      return permission;
    },
  },

  // ProjectBrief
  {
    id: ROUTE_OPTIONS.projects.children["project-brief"].id,
    path: ROUTE_OPTIONS.projects.children["project-brief"].path,
    element: <ProjectBrief />,
    handle: {
      crumb: (path, id) => (
        <BreadcrumbLink
          label={ROUTE_OPTIONS.projects.children["project-brief"].label}
        />
      ),
    },

    loader: async () => {
      return await permissionLoader(
        ROUTE_OPTIONS.projects.id,
        ROUTE_OPTIONS.projects.children["project-brief"].id
      );

      // return await permissionLoader(
      //   ROUTE_OPTIONS.projects.children["project-brief"].id
      // );
    },
  },

  // ViewContract
  {
    id: ROUTE_OPTIONS.projects.children["view-contract"].id,
    path: ROUTE_OPTIONS.projects.children["view-contract"].path,
    element: <ViewContract />,
    handle: {
      crumb: () => <BreadcrumbLink label="View Contract" />,
    },
  },
];
