import API_Common from "../../services/api-common";
import { Endpoints } from "../endpoint";

export const entityLockRequest = (lockTypeId, entityId, userId) => {
  return new Promise((resolve, reject) => {
    const endpoint = `${Endpoints.Common_EntityLocks_Request}?lockTypeId=${lockTypeId}&entityId=${entityId}&userId=${userId}`;

    API_Common.get(endpoint)
      .then(async (res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const entityLockRelease = (lockTypeId, entityId, userId) => {
  return new Promise((resolve, reject) => {
    const endpoint = `${Endpoints.Common_EntityLocks_Release}?lockTypeId=${lockTypeId}&entityId=${entityId}&userId=${userId}`;

    API_Common.get(endpoint)
      .then(async (res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
