import React, { useState, useEffect } from "react";
import { useRouteLoaderData } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import * as Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";
import { confirm } from "react-confirm-box";

import API from "../../../../../services/api";
import API_ENDPOINTS from "../../../../../services/api-endpoints";
import useValidation from "../../../../../hooks/useValidation";
import Chip from "../../../../ui-components/Chip";
import SettingPanel from "../../../../ui-components/pages-ui/Setting/SettingPanel";
import SettingAccordion from "../../../../ui-components/pages-ui/Setting/SettingPanel/SettingAccordion";
import Button, { BUTTON_ICONS } from "../../../../ui-components/Buttons/Button";
import FromGroup from "../../../../controls/FromGroup";
import ChipCard from "../../../../ui-components/ChipCard";
import "../../../../../helpers/permission";

import "../../../../sass/settings/tag__card_2-col.scss";
import "../../../../sass/settings/card__wt.scss";
import { SETTING_PERMISSION_MODULES } from "../../../../../constant/setting_permission_module";
import { Permission } from "../../../../../helpers/permission";

const Setup = () => {
  // Breadcrumbs List
  const breadcrumbsList = [{ text: "Common" }, { text: "Setup" }];

  const { permission } = useRouteLoaderData("data-setup");

  const pagePermission = new Permission(permission);

  const DataSetup = SETTING_PERMISSION_MODULES.DataSetup;

  const language = pagePermission.findByName(DataSetup.language.key);
  const currency = pagePermission.findByName(DataSetup.currency.key);
  const country = pagePermission.findByName(DataSetup.country.key);
  const region = pagePermission.findByName(DataSetup.region.key);
  const regionCountry = pagePermission.findByName(
    DataSetup.region_countries.key
  );

  const { textOnly } = useValidation();

  let LanguageList = [];

  const [languageId, setLanguageId] = useState(0);
  const [languageName, setLanguageName] = useState("");
  const [languageFont, setLanguageFont] = useState("");
  const [languages, setLanguages] = useState([]);
  const [index, setindex] = useState(20);

  let CurrencyList = [];
  const [currencyId, setCurrencyId] = useState(0);
  const [currencyCode, setCurrencyCode] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [currencyFont, setCurrencyFont] = useState("");
  const [currencyName, setCurrencyName] = useState("");
  const [currencies, setCurrencies] = useState([]);

  let CountryList = [];

  const [countryId, setCountryId] = useState(0);
  const [countryCode, setCountryCode] = useState("");
  const [countryName, setCountryName] = useState("");
  const [signUpEnable, setSignUpEnable] = useState(false);
  const [countries, setCountries] = useState([]);
  const [countries_dd, setCountries_dd] = useState([]);

  const [btnLanguageSave, setBtnLanguageSave] = useState("");
  const [btnCurrencySave, setBtnCurrencySave] = useState("");
  const [btnCountrySave, setBtnCountrySave] = useState("");

  const [show, setShow] = useState(false); //Loading Indicator

  const [langDiv, setShowLangDiv] = useState(true);
  const [langViewDiv, setShowLangViewDiv] = useState(true);

  const [currencyDiv, setShowCurrencyDiv] = useState(true);
  const [currencyViewDiv, setShowCurrencyViewDiv] = useState(true);

  const [countryDiv, setShowCountryDiv] = useState(true);
  const [countryViewDiv, setShowCountryViewDiv] = useState(true);

  let RegionList = [];
  const [regions, setRegions] = useState([]);
  const [regions_dd, setRegions_dd] = useState([]);

  const [regionId, setRegionId] = useState(0);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [countryOptionList, setCountryOptionList] = useState([]);

  let RegionCountryList = [];
  const [regionCountryDiv, setShowRegionCountryDiv] = useState(false);
  const [regionCountryViewDiv, setShowRegionCountryViewDiv] = useState(false);
  const [regionCountries, setRegionCountries] = useState([]);

  useEffect(() => {
    getAndBindLanguageList();
    getAndBindCurrencyList();
    getAndBindCountryList();
    getAndBindRegionList();
    getAndBindRegionCountryList();
    setBtnLanguageSave("Add");
    setBtnCurrencySave("Add");
    setBtnCountrySave("Add");
  }, []);

  const handleSubmit = async (e) => {
    //e.preventDefault();
    console.log(e);
    try {
    } catch (err) {
      console.log(err);
    }
  };

  //*****/ LANGUAGE ******//
  const getAndBindLanguageList = async (e) => {
    try {
      setShow(true); //Loading Indicator
      const endpoints = new API_ENDPOINTS();
      const response = API.get(endpoints.GETALLLANGUAGES).then((response) => {
        //console.log(response);
        if (response.data.isSuccess === true) {
          LanguageList = response.data.result;

          const lang_list = LanguageList.map((item, i) => {
            return (
              <Chip
                key={i}
                onEdit={
                  language?.permission.canEdit ? () => editLanguage(item) : null
                }
                onDelete={
                  language?.permission.canDelete
                    ? () => deleteLanguage(item)
                    : null
                }
              >
                <Chip.Items>
                  <Chip.Node text={item.languageName} />
                  <Chip.Node text={item.defaultFont} />
                </Chip.Items>
              </Chip>
            );
          });
          setLanguages(lang_list);
          setLanguageUIViewOptions();
          setShow(false); //Loading Indicator
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const saveLanguage = async (e) => {
    //console.log(index);
    let isEditMode = false;
    if (languageName.length === 0) {
      toast.error("Please enter a Language Name");
      return;
    }
    if (languageFont.length === 0) {
      toast.error("Please enter a language Font Name");
      return;
    }
    setShow(true); //Loading Indicator on
    try {
      const endpoints = new API_ENDPOINTS();
      let End_point = "";
      if (languageId == 0) {
        End_point = endpoints.SETUPLANGUAGE;
        isEditMode = false;
      } else {
        End_point = endpoints.UPDATELANGUAGE;
        isEditMode = true;
      }

      const response = API.post(End_point, {
        languageId: languageId,
        languageName: languageName,
        languageIsoCode: "",
        defaultFont: languageFont,
        defaultFontSize: "",
      }).then((response) => {
        //console.log(response);
        if (response.data.isSuccess === true) {
          if (!isEditMode) {
            // toast.success("Language created successfully");
          } else {
            // toast.success("Language updated successfully");
          }
          setLanguageName("");
          setLanguageFont("");
          getAndBindLanguageList();

          setShow(false); //Loading Indicator off
        } else {
          // toast.success(response.data.displayMessage);
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator off
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };

  const editLanguage = async (e) => {
    try {
      setLanguageId(e.languageId);
      setLanguageName(e.languageName);
      setLanguageFont(e.defaultFont);
      addLanguage();
    } catch (err) {
      console.log(err);
    }
  };

  const deleteLanguage = async (e) => {
    try {
      const confirmation = await confirm(
        "Are you sure you want to delete this item ?"
      );

      if (!confirmation) {
        return;
      }

      setShow(true); //Loading Indicator on
      const endpoints = new API_ENDPOINTS();
      const response = API.delete(
        endpoints.DELETELANGUAGE + "/" + e.languageId,
        {}
      ).then((response) => {
        //console.log(response);
        if (response.data.isSuccess === true) {
          // toast.success("Language deleted successfully");
          getAndBindLanguageList();
          setLanguageName("");
          setLanguageFont("");
          getAndBindLanguageList();
          setBtnLanguageSave("Add");
          setShow(false); //Loading Indicator off
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator off

          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };

  const setLanguageUIViewOptions = async (e) => {
    try {
      if (LanguageList.length == 0) {
        setShowLangDiv(false);
        setShowLangViewDiv(true);
      } else {
        setShowLangDiv(true);
        setShowLangViewDiv(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addLanguage = async (e) => {
    try {
      setShowLangDiv(false);
      setShowLangViewDiv(false);
    } catch (err) {
      console.log(err);
    }
  };
  const cancelLanguage = async (e) => {
    try {
      setLanguageId(0);
      setLanguageName("");
      setLanguageFont("");
      setShowLangDiv(true);
    } catch (err) {
      console.log(err);
    }
  };

  //*****/ CURRENCY ******//
  const getAndBindCurrencyList = async (e) => {
    try {
      setShow(true); //Loading Indicator
      const endpoints = new API_ENDPOINTS();
      const response = API.get(endpoints.GETALLCURRENCIES).then((response) => {
        console.log("CurrencyList", response);
        if (response.data.isSuccess === true) {
          CurrencyList = response.data.result;
          console.log("CurrencyList", CurrencyList);
          const currency_list = CurrencyList.map((item, i) => {
            return (
              <Chip
                key={i}
                onEdit={
                  currency.permission.canEdit ? () => editCurrency(item) : null
                }
                onDelete={
                  currency.permission.canDelete
                    ? () => deleteCurrency(item)
                    : null
                }
              >
                <Chip.Items>
                  <Chip.Node text={item.currencyName} />
                  <Chip.Node text={item.currencySymbol} />
                </Chip.Items>
              </Chip>
            );
          });
          setCurrencies(currency_list);
          setCurrencyUIViewOptions();
          setShow(false); //Loading Indicator
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          //setShow(false); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const saveCurrency = async (e) => {
    let isEditMode = false;
    if (currencyCode.length === 0) {
      toast.error("Please enter a valid Currency Code.");
      return;
    }
    if (currencySymbol.length === 0) {
      toast.error("Please enter a valid Currency Symbol.");
      return;
    }
    if (currencyFont.length === 0) {
      toast.error("Please enter a valid Currency Font.");
      return;
    }
    if (currencyName.length === 0) {
      toast.error("Please enter a valid Currency Name.");
      return;
    }
    try {
      const endpoints = new API_ENDPOINTS();
      let End_point = "";
      //Create
      if (currencyId == 0) {
        End_point = endpoints.SETUPCURRENCY;
        isEditMode = false;
        const response = API.post(End_point, {
          currencyId: currencyId,
          currencyCode: currencyCode,
          currencySymbol: currencySymbol,
          symbolFont: currencyFont,
          currencyName: currencyName,
          isBaseCurrency: false,
        }).then((response) => {
          //console.log(response);
          if (response.data.isSuccess === true) {
            if (!isEditMode) {
              // toast.success("Currency created successfully");
            } else {
              // toast.success("Currency updated successfully");
            }
            getAndBindCurrencyList();
            setCurrencyId(0);
            setCurrencyCode("");
            setCurrencyFont("");
            setCurrencySymbol("");
            setCurrencyName("");
            getAndBindLanguageList();

            setShow(false); //Loading Indicator off
          } else {
            console.log(response.data.displayMessage);
            toast.error(response.data.displayMessage);
            setShow(false); //Loading Indicator off
            return;
          }
        });
        //Update
      } else {
        console.log("update mode");
        End_point = endpoints.UPDATECURRENCY;
        isEditMode = true;
        const response = API.put(End_point + "/" + currencyId, {
          currencyId: currencyId,
          currencyCode: currencyCode,
          currencySymbol: currencySymbol,
          symbolFont: currencyFont,
          currencyName: currencyName,
          isBaseCurrency: false,
        }).then((response) => {
          console.log(response);
          if (response.data.isSuccess === true) {
            if (!isEditMode) {
              // toast.success("Currency created successfully");
            } else {
              // toast.success("Currency updated successfully");
            }
            getAndBindCurrencyList();
            setCurrencyId(0);
            setCurrencyCode("");
            setCurrencyFont("");
            setCurrencySymbol("");
            setCurrencyName("");
            getAndBindLanguageList();
            setBtnCurrencySave("Add");
            setShow(false); //Loading Indicator off
          } else {
            console.log(response.data.displayMessage);
            toast.error(response.data.displayMessage);
            setShow(false); //Loading Indicator off
            return;
          }
        });
      }
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };

  const editCurrency = async (e) => {
    try {
      console.log("edit currency: ", e);
      setCurrencyId(e.currencyId);
      setCurrencyCode(e.currencyCode);
      setCurrencyName(e.currencyName);
      setCurrencySymbol(e.currencySymbol);
      setCurrencyFont(e.symbolFont);
      addCurrency();
    } catch (err) {
      console.log(err);
    }
  };

  const deleteCurrency = async (e) => {
    try {
      //console.log(e);
      const confirmation = await confirm(
        "Are you sure you want to delete this item ?"
      );

      if (!confirmation) {
        return;
      }

      setShow(true); //Loading Indicator on
      const endpoints = new API_ENDPOINTS();
      const response = API.delete(
        endpoints.DELETECURRENCY + "/" + e.currencyId,
        {}
      ).then((response) => {
        //console.log(response);
        if (response.data.isSuccess === true) {
          // toast.success("Currency deleted successfully");
          getAndBindCurrencyList();
          setCurrencyId(0);
          setCurrencyCode("");
          setCurrencyFont("");
          setCurrencySymbol("");
          setCurrencyName("");
          getAndBindLanguageList();
          setBtnCurrencySave("Add");
          setShow(false); //Loading Indicator off
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator off

          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };

  const setCurrencyUIViewOptions = async (e) => {
    try {
      if (CurrencyList.length == 0) {
        setShowCurrencyDiv(false);
        setShowCurrencyViewDiv(true);
      } else {
        setShowCurrencyDiv(true);
        setShowCurrencyViewDiv(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addCurrency = async (e) => {
    try {
      setShowCurrencyDiv(false);
      setShowCurrencyViewDiv(false);
    } catch (err) {
      console.log(err);
    }
  };

  const cancelCurrency = async (e) => {
    try {
      setCurrencyId(0);
      setCurrencyCode("");
      setCurrencyFont("");
      setCurrencySymbol("");
      setCurrencyName("");
      setShowCurrencyDiv(true);
    } catch (err) {
      console.log(err);
    }
  };

  //*****/ COUNTRY ******//
  const getAndBindCountryList = async (e) => {
    try {
      setShow(true); //Loading Indicator
      const endpoints = new API_ENDPOINTS();
      const response = API.get(endpoints.GETALLCOUNTRIES).then((response) => {
        // console.log(response);
        if (response.data.isSuccess === true) {
          CountryList = response.data.result;

          const country_list = CountryList.map((item, i) => {
            return (
              <Chip
                key={i}
                onEdit={
                  country.permission.canEdit ? () => editCountry(item) : null
                }
                onDelete={
                  country.permission.canDelete
                    ? () => deleteCountry(item)
                    : null
                }
              >
                <Chip.Items>
                  <Chip.Node text={item.countryName} />
                </Chip.Items>
              </Chip>
            );
          });

          const country_list_dropdown = CountryList.map((item) => {
            return { label: item.countryName, value: item.countryId };
          });

          setCountries(country_list);
          setCountryOptionList(country_list_dropdown);
          setCountryUIViewOptions();
          setShow(false); //Loading Indicator
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const saveCountry = async (e) => {
    let isEditMode = false;
    if (countryCode.length === 0) {
      toast.error("Please enter a country code");
      return;
    }
    if (countryName.length === 0) {
      toast.error("Please enter a country name");
      return;
    }
    try {
      const endpoints = new API_ENDPOINTS();
      let End_point = "";
      //Create
      if (countryId == 0) {
        // console.log("create mode");
        End_point = endpoints.SETUPCOUNTRY;
        isEditMode = false;
        const response = API.post(End_point, {
          countryId: countryId,
          countryName: countryName,
          countryCode: countryCode,
          isSignUpEnabled: false,
        }).then((response) => {
          // console.log(response);
          if (response.data.isSuccess === true) {
            if (!isEditMode) {
              // toast.success("Country created successfully");
            } else {
              // toast.success("Country updated successfully");
            }
            getAndBindCountryList();
            setCountryId(0);
            setCountryCode("");
            setCountryName("");
            setSignUpEnable(false);

            setShow(false); //Loading Indicator off
          } else {
            console.log(response.data.displayMessage);
            toast.error(response.data.displayMessage);
            setShow(false); //Loading Indicator off
            return;
          }
        });
        //Update
      } else {
        console.log("update mode");
        End_point = endpoints.UPDATECOUNTRY;
        isEditMode = true;
        const response = API.put(End_point + "/" + countryId, {
          countryId: countryId,
          countryName: countryName,
          countryCode: countryCode,
          isSignUpEnabled: signUpEnable,
        }).then((response) => {
          console.log(response);
          if (response.data.isSuccess === true) {
            if (!isEditMode) {
              // toast.success("Country created successfully");
            } else {
              // toast.success("Country updated successfully");
            }
            getAndBindCountryList();
            setCountryId(0);
            setCountryCode("");
            setCountryName("");
            setSignUpEnable(false);

            setShow(false); //Loading Indicator off
          } else {
            console.log(response.data.displayMessage);
            toast.error(response.data.displayMessage);
            setShow(false); //Loading Indicator off
            return;
          }
        });
      }
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };

  const editCountry = async (e) => {
    try {
      setCountryId(e.countryId);
      setCountryCode(e.countryCode);
      setCountryName(e.countryName);
      addCountry();
    } catch (err) {
      console.log(err);
    }
  };

  const deleteCountry = async (e) => {
    try {
      //console.log(e);
      const confirmation = await confirm(
        "Are you sure you want to delete this item ?"
      );

      if (!confirmation) {
        return;
      }
      setShow(true); //Loading Indicator on
      const endpoints = new API_ENDPOINTS();
      const response = API.delete(
        endpoints.DELETECOUNTRY + "/" + e.countryId,
        {}
      ).then((response) => {
        //console.log(response);
        if (response.data.isSuccess === true) {
          // toast.success("Country deleted successfully");
          getAndBindCountryList();
          setCountryId(0);
          setCountryCode("");
          setCountryName("");
          setSignUpEnable(false);
          setShow(false); //Loading Indicator off
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator off

          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };

  const setCountryUIViewOptions = async (e) => {
    try {
      if (CountryList.length == 0) {
        setShowCountryDiv(false);
        setShowCountryViewDiv(true);
      } else {
        setShowCountryDiv(true);
        setShowCountryViewDiv(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addCountry = async (e) => {
    try {
      setShowCountryDiv(false);
      setShowCountryViewDiv(false);
    } catch (err) {
      console.log(err);
    }
  };

  const cancelCountry = async (e) => {
    try {
      setCountryId(0);
      setCountryCode("");
      setCountryName("");
      setSignUpEnable(false);

      setShowCountryDiv(true);
    } catch (err) {
      console.log(err);
    }
  };

  //*****/ Region ******//
  const getAndBindRegionList = async (e) => {
    try {
      setShow(true); //Loading Indicator
      const endpoints = new API_ENDPOINTS();
      const response = API.get(endpoints.GETALLREGIONS).then((response) => {
        console.log(response);
        if (response.data.isSuccess === true) {
          RegionList = response.data.result;

          const region_list = RegionList.map((item, i) => {
            return (
              <Chip
                key={i}
                // onEdit={region.permission.canEdit ? () => editLanguage(item) : null}
                // onDelete={region.permission.canEdit ? () => deleteLanguage(item) : null}
              >
                <Chip.Items>
                  <Chip.Node text={item.regionName} />
                </Chip.Items>
              </Chip>
            );
          });
          const regions_list_dropdown = RegionList.map((item) => {
            return <option value={item.regionId}>{item.regionName}</option>;
          });

          setRegions_dd(regions_list_dropdown);
          setRegions(region_list);
          setShow(false); //Loading Indicator
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  //*****/ Region Country ******//\
  const onRegionChange = async (regionId) => {
    try {
      let selectedCountryIds = [];
      const endpoints = new API_ENDPOINTS();
      const response = API.get(
        endpoints.GETREGIONCOUNTRYBYID + "/" + regionId
      ).then((response) => {
        console.log(response);
        if (response.data.isSuccess === true) {
          setRegionId(regionId);
          response.data.result[0].regionCountries.map((item) => {
            selectedCountryIds.push({
              label: item.countryName,
              value: item.countryId,
            });
          });
          setSelectedCountries(selectedCountryIds);
          console.log(selectedCountries);
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          return;
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getAndBindRegionCountryList = async (e) => {
    try {
      setShow(true); //Loading Indicator
      const endpoints = new API_ENDPOINTS();
      const response = API.get(endpoints.GETALLREGIONCOUNTRIES).then(
        (response) => {
          console.log("region-countries", response);
          if (response.data.isSuccess === true) {
            RegionCountryList = response.data.result;

            // hidden={regionCountryViewDiv}
            const regionCountry_list = RegionCountryList.map((item, i) => {
              return (
                <ChipCard
                  key={i}
                  title={item.regionName}
                  onEdit={
                    regionCountry.permission.canEdit
                      ? () => editRegionCountry(item)
                      : null
                  }
                  // onDelete={() => deleteRegionCountry(item)}
                >
                  <ul className="disc--list">
                    {item.regionCountries?.map((rcitem, i) => {
                      return <li key={i}>{rcitem.countryName}</li>;
                    })}
                  </ul>
                </ChipCard>
              );
            });
            setRegionCountries(regionCountry_list);
            setRegionCountryUIViewOptions();
            setShow(false); //Loading Indicator
          } else {
            console.log(response.data.displayMessage);
            toast.error(response.data.displayMessage);
            setShow(false); //Loading Indicator
            return;
          }
        }
      );
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const saveRegionCountry = async (e) => {
    let isEditMode = false;
    let selectedCountryIds = [];
    if (regionId === 0) {
      toast.error("Please select a region");
      return;
    }
    if (selectedCountries.length === 0) {
      toast.error("Please select a Country");
      return;
    }

    try {
      selectedCountries.map((item) => {
        selectedCountryIds.push(item.value);
      });
      console.log("si", selectedCountryIds);
      const endpoints = new API_ENDPOINTS();
      let End_point = "";
      console.log("regionId", regionId);
      //Create
      if (regionId === 0) {
        // console.log("create mode");
        End_point = endpoints.SETUPREGIONCOUNTRIES;
        isEditMode = false;
        const response = API.post(End_point, {
          regionId: regionId,
          countryId: selectedCountryIds,
        }).then((response) => {
          console.log(response);
          if (response.data.isSuccess === true) {
            if (!isEditMode) {
              // toast.success("Region Countries created successfully");
            } else {
              // toast.success("Region Countries updated successfully");
            }

            setRegionId(0);
            setSelectedCountries([]);

            getAndBindRegionCountryList();

            setShow(false); //Loading Indicator off
          } else {
            console.log(response.data.displayMessage);
            toast.error(response.data.displayMessage);
            setShow(false); //Loading Indicator off
            return;
          }
        });
        //Update
      } else {
        console.log("update mode");
        End_point = endpoints.UPDATEREGIONCOUNTRIES;
        isEditMode = true;
        const response = API.post(End_point, {
          regionId: regionId,
          countryId: selectedCountryIds,
        }).then((response) => {
          console.log(response);
          if (response.data.isSuccess === true) {
            if (!isEditMode) {
              // toast.success("Region Countries created successfully");
            } else {
              // toast.success("Region Countries updated successfully");
            }
            setRegionId(0);
            setSelectedCountries([]);

            getAndBindRegionCountryList();

            setShow(false); //Loading Indicator off
          } else {
            console.log(response.data.displayMessage);
            toast.error(response.data.displayMessage);
            setShow(false); //Loading Indicator off
            return;
          }
        });
      }
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };

  const editRegionCountry = async (e) => {
    let selectedCountryIds = [];
    try {
      console.log(e);
      setRegionId(e.regionId);
      onRegionChange(e.regionId);
      e.regionCountries.map((item) => {
        selectedCountryIds.push({
          label: item.countryName,
          value: item.countryId,
        });
      });
      setSelectedCountries(selectedCountryIds);
      console.log(selectedCountries);

      addRegionCountry();
    } catch (err) {
      console.log(err);
    }
  };

  const deleteRegionCountry = async (e) => {
    try {
      // console.log(e);
      const confirmation = await confirm(
        "Are you sure you want to delete this item ?"
      );

      if (!confirmation) {
        return;
      }
      setShow(true); //Loading Indicator on
      const endpoints = new API_ENDPOINTS();
      const response = API.delete(
        endpoints.DELETEREGIONCOUNTRIES + "/" + e.regionId,
        {}
      ).then((response) => {
        console.log(response);
        if (response.data.isSuccess === true) {
          // toast.success("Region Country deleted successfully");
          setRegionId(0);
          setSelectedCountries([]);
          getAndBindRegionCountryList();
          setShow(false); //Loading Indicator off
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator off

          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };

  const setRegionCountryUIViewOptions = async (e) => {
    try {
      if (RegionList.length == 0) {
        setShowRegionCountryDiv(false);
        setShowRegionCountryViewDiv(true);
      } else {
        setShowRegionCountryDiv(true);
        setShowRegionCountryViewDiv(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addRegionCountry = async (e) => {
    try {
      setShowRegionCountryDiv(false);
      setShowRegionCountryViewDiv(false);
    } catch (err) {
      console.log(err);
    }
  };

  const cancelRegionCountry = async (e) => {
    try {
      setRegionId(0);
      setSelectedCountries([]);
      setShowRegionCountryDiv(true);
    } catch (err) {
      console.log(err);
    }
  };

  const textHandler = (event) => {
    const result = event.target.value.replace(/[^a-z]/gi, "");
  };

  //*****/ UI ******//
  return (
    <>
      <div style={{ justifyContent: "center", display: "flex" }}>
        <Loader.ThreeDots
          visible={show}
          width={100}
          height={30}
          color="#347084"
        ></Loader.ThreeDots>
      </div>

      <SettingPanel breadcrumbs={breadcrumbsList}>
        {/* Language */}
        {language.hasPermission ? (
          <SettingAccordion
            title="Language"
            actions={
              language.permission.canAddNew && langDiv ? (
                <Button
                  size="sm"
                  variant="green-light"
                  leftIcon={BUTTON_ICONS.plus}
                  onClick={addLanguage}
                >
                  {btnLanguageSave}
                </Button>
              ) : null
            }
          >
            <SettingAccordion.Card>
              {/* hidden={langDiv} */}

              {!langDiv &&
              (language.permission.canAddNew || language.permission.canEdit) ? (
                <form className="row setting-form">
                  <input
                    type="hidden"
                    name="languageId"
                    onChange={(e) => setLanguageId(e.target.value)}
                    value={languageId}
                  />

                  <div className="col-md-6">
                    <FromGroup label=" Language Name">
                      <input
                        className="form-control"
                        type="text"
                        id="language_name"
                        name="language_name"
                        placeholder="Language Name"
                        aria-label="Language Name"
                        onChange={(e) =>
                          setLanguageName(e.target.value)
                        }
                        value={languageName}
                      />
                    </FromGroup>
                  </div>

                  <div className="col-md-6">
                    {" "}
                    <label className="form-label" for="font">
                      Font
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="font"
                      name="font"
                      placeholder="Font Name"
                      aria-label="Font Name"
                      onChange={(e) => setLanguageFont(e.target.value)}
                      value={languageFont}
                    />
                  </div>

                  <div className="col-12">
                    <FromGroup type="button-inline-left">
                      {languages.length > 0 ? (
                        <Button variant="secondary" onClick={cancelLanguage}>
                          Cancel
                        </Button>
                      ) : null}

                      <Button onClick={saveLanguage}>Save</Button>
                    </FromGroup>
                  </div>
                </form>
              ) : null}

              {!langViewDiv && language.permission.canView ? (
                <div className="setting-details">
                  <div className="col-md-12">
                    <Chip.Wrapper>{languages}</Chip.Wrapper>
                  </div>
                </div>
              ) : null}
            </SettingAccordion.Card>
          </SettingAccordion>
        ) : null}

        {/* Currency */}
        {currency.hasPermission ? (
          <SettingAccordion
            title="Currency"
            actions={
              currency.permission.canAddNew && currencyDiv ? (
                <Button
                  size="sm"
                  variant="green-light"
                  leftIcon={BUTTON_ICONS.plus}
                  onClick={addCurrency}
                >
                  {btnCurrencySave}
                </Button>
              ) : null
            }
          >
            <SettingAccordion.Card>
              {(currency.permission.canAddNew || currency.permission.canEdit) &&
              !currencyDiv ? (
                <form className="row setting-form">
                  <div className="col-md-4">
                    <FromGroup label="Currency Code">
                      <input
                        className="form-control"
                        type="text"
                        id="currency_code"
                        name="currency_code"
                        placeholder="Currency Code"
                        aria-label="Currency Code"
                        onChange={(e) =>
                          setCurrencyCode(textOnly(e.target.value))
                        }
                        value={currencyCode}
                      />
                    </FromGroup>
                  </div>

                  <div className="col-md-4">
                    <FromGroup label="Currency Symbol">
                      <input
                        className="form-control"
                        type="text"
                        id="currency_symbol"
                        name="currency_symbol"
                        placeholder="Currency Symbol"
                        aria-label="Currency Symbol"
                        onChange={(e) => setCurrencySymbol(e.target.value)}
                        value={currencySymbol}
                      />
                    </FromGroup>
                  </div>

                  <div className="col-md-4">
                    <FromGroup label="Font">
                      <input
                        className="form-control"
                        type="text"
                        id="currency_font"
                        name="currency_font"
                        placeholder="Font Name"
                        aria-label="Font Name"
                        onChange={(e) => setCurrencyFont(e.target.value)}
                        value={currencyFont}
                      />
                    </FromGroup>
                  </div>

                  <div className="col-md-6">
                    <FromGroup label="Currency Name">
                      <input
                        className="form-control"
                        type="text"
                        id="currency_name"
                        name="currency_name"
                        placeholder="Currency Name"
                        aria-label="Currency Name"
                        onChange={(e) =>
                          setCurrencyName(textOnly(e.target.value))
                        }
                        value={currencyName}
                      />
                    </FromGroup>
                  </div>

                  <div className="col-12">
                    <FromGroup type="button-inline-left">
                      {currencies.length > 0 ? (
                        <Button variant="secondary" onClick={cancelCurrency}>
                          Cancel
                        </Button>
                      ) : null}

                      <Button onClick={saveCurrency}>Save</Button>
                    </FromGroup>
                  </div>
                </form>
              ) : null}

              {currency.permission.canView && currencies.length > 0 ? (
                <div className="setting-details">
                  <Chip.Wrapper>{currencies}</Chip.Wrapper>
                  {/* currencyViewDiv */}
                </div>
              ) : null}
            </SettingAccordion.Card>
          </SettingAccordion>
        ) : null}

        {/* Country */}
        {country.hasPermission ? (
          <SettingAccordion
            title="Country"
            actions={
              country.permission.canAddNew && countryDiv ? (
                <Button
                  size="sm"
                  variant="green-light"
                  leftIcon={BUTTON_ICONS.plus}
                  onClick={addCountry}
                >
                  {btnCountrySave}
                </Button>
              ) : null
            }
          >
            <SettingAccordion.Card>
              {!countryDiv &&
              (country.permission.canAddNew || country.permission.canEdit) ? (
                <form className="row setting-form">
                  <div className="col-md-6">
                    <FromGroup label="Country Code">
                      <input
                        className="form-control"
                        type="text"
                        id="country_code"
                        name="country_code"
                        placeholder="Country Code"
                        aria-label="Country Code"
                        onChange={(e) =>
                          setCountryCode(textOnly(e.target.value))
                        }
                        value={countryCode}
                      />
                    </FromGroup>
                  </div>

                  <div className="col-md-6">
                    <FromGroup label="Country Name">
                      <input
                        className="form-control"
                        type="text"
                        id="country_name"
                        name="country_name"
                        placeholder="Country Name"
                        aria-label="Country Name"
                        onChange={(e) =>
                          setCountryName(textOnly(e.target.value))
                        }
                        value={countryName}
                      />
                    </FromGroup>
                  </div>

                  <div className="col-12">
                    <FromGroup type="button-inline-left">
                      {countries.length > 0 ? (
                        <Button
                          type="button"
                          variant="secondary"
                          onClick={cancelCountry}
                        >
                          Cancel
                        </Button>
                      ) : null}

                      <Button onClick={saveCountry}>Save</Button>
                    </FromGroup>
                  </div>
                </form>
              ) : null}

              {countries.length > 0 && country.permission.canView ? (
                <div className="setting-details">
                  {/* countryViewDiv */}
                  <Chip.Wrapper>{countries}</Chip.Wrapper>
                </div>
              ) : null}
            </SettingAccordion.Card>
          </SettingAccordion>
        ) : null}

        {/* Region */}
        {region.hasPermission ? (
          <SettingAccordion
            title="Region"
            // actions={
            //   <Button
            //     size="sm"
            //     variant="green-light"
            //     leftIcon={BUTTON_ICONS.plus}
            //   >
            //     Add
            //   </Button>
            // }
          >
            <SettingAccordion.Card>
              {region.permission.canEdit || region.permission.canAddNew ? (
                <form className="row setting-form">
                  <div className="col-md-12" hidden="true">
                    <FromGroup label="Region Name">
                      <input
                        className="form-control"
                        type="text"
                        id="client_region_name"
                        name="client_region_name"
                        placeholder="Region Name"
                        aria-label="Region Name"
                      />
                    </FromGroup>
                  </div>
                </form>
              ) : null}

              {region.permission.canView ? (
                <div className="form-details">
                  <Chip.Wrapper>{regions}</Chip.Wrapper>
                </div>
              ) : null}
            </SettingAccordion.Card>
          </SettingAccordion>
        ) : null}

        {/* Region Countries */}
        {regionCountry.hasPermission ? (
          <SettingAccordion
            title="Region Countries"
            actions={
              regionCountry.permission.canAddNew ? (
                <Button
                  size="sm"
                  variant="green-light"
                  leftIcon={BUTTON_ICONS.plus}
                  onClick={addRegionCountry}
                >
                  Add
                </Button>
              ) : null
            }
          >
            <SettingAccordion.Card>
              {!regionCountryDiv &&
              (regionCountry.permission.canAddNew ||
                regionCountry.permission.canEdit) ? (
                <div className="setting-form">
                  <form className="row">
                    <div className="col-md-6">
                      <FromGroup label="Region">
                        <select
                          className="form-control"
                          aria-label="Default select example"
                          onChange={(e) => onRegionChange(e.target.value)}
                          value={regionId}
                        >
                          <option selected value={0}>
                            --- Select Region ---
                          </option>
                          {regions_dd}
                        </select>
                      </FromGroup>
                    </div>

                    <div className="col-md-6">
                      <FromGroup label="Country">
                        <MultiSelect
                          options={countryOptionList}
                          value={selectedCountries}
                          onChange={setSelectedCountries}
                          labelledBy="Select"
                          className="sm-rmsc"
                        />
                      </FromGroup>
                    </div>

                    <div className="col-12">
                      <FromGroup type="button-inline-left">
                        <Button
                          variant="secondary"
                          onClick={cancelRegionCountry}
                        >
                          Cancel
                        </Button>
                        <Button onClick={saveRegionCountry}>Save</Button>
                      </FromGroup>
                    </div>
                  </form>
                </div>
              ) : null}

              {regionCountries.length > 0 &&
              regionCountry.permission.canView ? (
                <div className="setting-details">
                  <ChipCard.Wrapper className="client-region-countries">
                    {regionCountries}
                  </ChipCard.Wrapper>
                </div>
              ) : null}
            </SettingAccordion.Card>
          </SettingAccordion>
        ) : null}
      </SettingPanel>
    </>
  );
};

export default Setup;
