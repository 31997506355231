import React from "react";
import ActionButton from "../Buttons/ActionButton";
import "./styles.scss";

const Card = (props) => {
  const { title, subtitle, children, onDelete, onEdit, imgUrl } = props;
  return (
    <div className="ui__card">
      {onEdit || onDelete ? (
        <div className="ui__card__action">
          {onEdit ? <ActionButton variant="EDIT" onClick={onEdit} /> : null}
          {onDelete ? (
            <ActionButton variant="DELETE" onClick={onDelete} />
          ) : null}
        </div>
      ) : null}
      <div className="ui__card__header">
        <div className="ui__card__img">
          <div className="img__wrapper">
            <img src={imgUrl} alt="" />
          </div>
        </div>
        <div className="title">{title}</div>
        <div className="sub-title">{subtitle}</div>
      </div>
      <div className="ui__card__body">{children}</div>
    </div>
  );
};

Card.Wrapper = (props) => (
  <div className="ui__card_wrapper">{props.children}</div>
);

export default Card;
