import styled from "styled-components";

export const StyledSearchWrapper = styled.div`
  --search-height: var(--layout-size-28);
  --search-inner-border-color: transparent;
  --search-inner-px: 0;
  --search-inner-py: ${({ theme, isExpanded }) =>
    isExpanded ? `${theme.sizes(3)} ${theme.sizes(2)}` : `0`};
  --search-width: ${({ isExpanded }) => (isExpanded ? `100%` : `auto`)};
  --search-input-rounded: var(--layout-border-radius-24);

  width: var(--search-width);
  position: relative;
  height: 100%;
  display: flex;
  background-image: var(--gradient-normal-180);
  padding: 1px;
  border-radius: var(--search-input-rounded);
  transition: var(--animate-1);

  &:focus-within .inner {
    --search-inner-border-color: ${({ theme }) => theme.colors.color_1(40)};
  }

  .inner {
    overflow: hidden;
    height: inherit;
    width: inherit;
    display: flex;
    align-items: center;
    border-radius: var(--search-input-rounded);
    padding-inline: var(--search-inner-py);
    padding-block: var(--search-inner-px);
    background-color: var(--color-white);
    transition: var(--animate-1);
    /* var(--color-neutral-50) */
    /* box-shadow: 0 0 0 1px var(--search-inner-border-color); */
  }

  .search__input {
    --search-input-font-size: var(--small-regular-font-size);
    --search-input-placeholder-color: var(--color-neutral-50);

    outline: none;
    margin: 0;
    padding: 0;
    border: none;
    width: 100%;
    font-size: var(--search-input-font-size);
    height: inherit;

    &::placeholder {
      color: var(--search-input-placeholder-color);
      /* font-style: italic; */
    }
  }
`;

export const StyledSearchBtn = styled.button`
  --search-btn-size: var(--search-height);
  --search-icon-size: ${({ theme }) => theme.sizes(4)};

  height: var(--search-btn-size);
  width: var(--search-btn-size);
  min-height: var(--search-btn-size);
  min-width: var(--search-btn-size);
  outline: none;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;

  .search__icon {
    color: ${({ theme }) => theme.colors.color_1(30)};
    font-size: 12px;
  }
`;
