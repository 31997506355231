export const OnboardingActionType = Object.freeze({
  ADD_TO_SHORTLIST: "ADD_TO_SHORTLIST",
  ADD_TO_PROJECT: "ADD_TO_PROJECT",
  RE_INVITE: "RE_INVITE",
  VIEW_CONTRACT: "VIEW_CONTRACT",
  CONTRACT_REQUESTED: "CONTRACT_REQUESTED",
  SIGN_CONTRACT: "SIGN_CONTRACT",
  GENERATE_CONTRACT: "GENERATE_CONTRACT",
  ONBOARD: "ONBOARD",
});
