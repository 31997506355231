import * as React from "react";
import { memo } from "react";
const SvgArrowDown = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.241236 4.24643C-0.0804126 4.57425 -0.0804126 5.1062 0.241236 5.434L7.41784 12.7539C7.73923 13.082 8.26078 13.082 8.58215 12.7539L15.7588 5.434C15.9196 5.26996 16 5.05497 16 4.83989C16 4.6249 15.9196 4.40981 15.7588 4.24605C15.4374 3.91798 14.9158 3.91798 14.5944 4.24605L7.99997 10.9722L1.4055 4.24629C1.08411 3.91821 0.562565 3.91821 0.241191 4.24629L0.241236 4.24643Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgArrowDown);
export default Memo;
