import styled from "styled-components";
import { fontStyle } from "../../../../../../../styled/utility";

export const Wrapper = styled.div`
  padding: var(--layout-spacing-16);
  border-radius: var(--layout-border-radius-20);
  border: 1px solid var(--color-accent-75);
  background: var(--color-accent-50);
  box-shadow: 0px 8px 10px 0px rgba(122, 122, 122, 0.1);

  .project-item {
    display: flex;
    flex: 1;

    &__start {
      display: flex;
      align-items: center;
      gap: var(--layout-spacing-12);
      flex: 1;
    }

    &__image {
      width: 72px;
      height: 72px;
      overflow: hidden;
      border-radius: var(--layout-border-radius-16);

      img {
        width: 100%;
        max-width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &__info__info-group {
      display: flex;
      flex-direction: column;
    }

    &__name {
      color: var(--color-primary-500);
    }

    &__caption {
      color: var(--color-neutral-80);
    }

    &__left-actions {
      display: flex;
      align-items: center;
      gap: var(--layout-spacing-8);
    }

    &__end {
      display: flex;
      align-items: center;
      gap: var(--layout-spacing-32);
    }
  }
`;

export const LeftLink = styled.div`
  ${fontStyle("label-regular")};
  color: var(--font-tint-04);
  cursor: pointer;
  padding: var(--layout-spacing-2);
  text-decoration: underline;

  &:hover {
    color: var(--color-primary-200);
  }
`;
