import SCHEDULE_API from "../../../services/api-scheduler";
import { MILESTONE_ENDPOINTS } from "./milestone.endpoints";

export const getMilestoneTracker = async (projectId) => {
  const data = {
    project: null,
    headers: [],
    sections: [],
    eventlist: [],
  };
  return new Promise(async (resolve, reject) => {
    try {
      const response = await SCHEDULE_API.get(
        `${MILESTONE_ENDPOINTS.GET_MILESTONE_TRACKER}/${projectId}`
      );

      if (!response?.data?.isSuccess) {
        resolve([]);
        return;
      }

      const milestoneTracker = response?.data?.result;

      data.project = milestoneTracker?.projectDetails || null;

      data.headers = milestoneTracker?.tableHeaders?.map((header) => {
        return {
          label: header?.label,
          key: header?.columnId,
          type: header?.type,
        };
      });

      data.sections = mapData(milestoneTracker?.sections || []);
      data.eventlist = milestoneTracker?.eventOptions || [];
      data.eventlist = milestoneTracker?.eventOptions?.map((option) => {
        return {
          label: option?.label,
          value: option?.eventId,
        };
      });

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const saveRow = async (_projectId, _parentId, data) => {
  let params = {
    deliverableId: data?.deliverableId || 0,
    projectId: _projectId,
    label: data?.label || "",
    eventId: data?.eventId || 0,
    parentId: _parentId,
  };

  return new Promise(async (resolve, reject) => {
    try {
      let res = await SCHEDULE_API.post(
        `${MILESTONE_ENDPOINTS.SAVE_ROW}`,
        params
      );

      if (!res?.data?.isSuccess) {
        resolve(null);
        return;
      }
      console.log("MILESTONE_ENDPOINTS.SAVE_ROW:: ", res);

      const data = mapData([res?.data?.result]);
      resolve(data);
    } catch (err) {
      if (err.response && err.response.data) {
        const error = err.response.data;
        reject({
          systemError: {
            errors: error.errors,
            title: error.title,
          },
        });
      } else {
        reject(err);
      }
    }
  });
};
export const saveCondition = async (_projectId, data) => {
  let params = {
    conditionId: data?.value?.value?.conditionId || 0,
    value: data?.formValues?.value || 0,
    unit: data?.formValues?.unit || 0,
    operator: data?.formValues?.operator || 0,
    deliverableEventCellId: data.formValues?.deliverableEventCellId || 0,
    cellId: data?.value?.cellId || 0,
  };

  return new Promise(async (resolve, reject) => {
    try {
      let res = await SCHEDULE_API.post(
        `${MILESTONE_ENDPOINTS.SAVE_CONDITION}`,
        params
      );

      if (!res?.data?.isSuccess) {
        resolve(null);
        return;
      }

      const data = res?.data?.result;
      resolve(data);
    } catch (err) {
      if (err.response && err.response.data) {
        const error = err.response.data;
        reject({
          systemError: {
            errors: error.errors,
            title: error.title,
          },
        });
      } else {
        reject(err);
      }
    }
  });
};
export const saveSubmission = async (_projectId, data) => {
  let params = {
    submissionId: data?.submissionId || 0,
    subject: data?.subject || "",
    note: data?.description || "",
    changeRequest: "",
    state: data?.stateId || 0,
    cellId: data?.cellId || 0,
    links: data?.urlList?.map((link) => {
      return {
        resourceId: typeof link?.id === "string" ? 0 : link?.id,
        resourceType: 1,
        url: link?.url,
        label: link?.name,
        extension: "",
        md5sum: "",
      };
    }),
    attachments: data?.uploadFiles?.map((file) => {
      return {
        resourceId: typeof file?.localId === "string" ? 0 : file?.localId,
        resourceType: 1,
        url: file?.filePath,
        label: file?.fileName,
        extension: file?.extension,
        md5sum: "",
      };
    }),
  };

  return new Promise(async (resolve, reject) => {
    try {
      let res = await SCHEDULE_API.post(
        `${MILESTONE_ENDPOINTS.SAVE_SUBMISSION}`,
        params
      );
      console.log("res", res);
      if (!res?.data?.isSuccess) {
        resolve(null);
        return;
      }

      const data = res?.data?.result;
      resolve(data);
    } catch (err) {
      if (err.response && err.response.data) {
        const error = err.response.data;
        reject({
          systemError: {
            errors: error.errors,
            title: error.title,
          },
        });
      } else {
        reject(err);
      }
    }
  });
};
export const getSubmissionData = async (submissionId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await SCHEDULE_API.get(
        `${MILESTONE_ENDPOINTS.GET_SUBMISSION_DATA}/${submissionId}`
      );

      if (!response?.data?.isSuccess) {
        resolve([]);
        return;
      }

      const data = response?.data?.result;

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const createChangeRequest = async (data) => {
  let params = {
    changeRequestId: 0,
    text: data?.changeRequestDescription,
    userId: data?.userId,
    state: data?.stateId,
    submissionId: data?.submissionId,
  };
  console.log("params", params);
  return new Promise(async (resolve, reject) => {
    try {
      let res = await SCHEDULE_API.post(
        `${MILESTONE_ENDPOINTS.CHANGE_REQUEST}`,
        params
      );
      console.log("res cr", res);

      if (!res?.data?.isSuccess) {
        resolve(null);
        return;
      }

      const data = res?.data?.result;
      resolve(data);
    } catch (err) {
      if (err.response && err.response.data) {
        const error = err.response.data;
        reject({
          systemError: {
            errors: error.errors,
            title: error.title,
          },
        });
      } else {
        reject(err);
      }
    }
  });
};
export const updateChangeRequest = async (data) => {
  let params = {
    changeRequestId: data?.changeRequestId || 0,
    text: data?.text || "",
    userId: data?.userId || "",
    state: data?.changeToStateId || data?.state,
    submissionId: data?.submissionId || 0,
  };

  return new Promise(async (resolve, reject) => {
    try {
      let res = await SCHEDULE_API.put(
        `${MILESTONE_ENDPOINTS.CHANGE_REQUEST}`,
        params
      );

      if (!res?.data?.isSuccess) {
        resolve(null);
        return;
      }

      const data = res?.data?.result;
      resolve(data);
    } catch (err) {
      if (err.response && err.response.data) {
        const error = err.response.data;
        reject({
          systemError: {
            errors: error.errors,
            title: error.title,
          },
        });
      } else {
        reject(err);
      }
    }
  });
};
export const saveDue = async (data) => {
  let params = {
    dueId: data?.dueId || 0,
    dueDate: data?.dueDate,
    cellId: data?.cellId || 0,
  };

  return new Promise(async (resolve, reject) => {
    try {
      let res = await SCHEDULE_API.post(
        `${MILESTONE_ENDPOINTS.SVAE_DUE}`,
        params
      );

      if (!res?.data?.isSuccess) {
        resolve(null);
        return;
      }

      const data = res?.data?.result;
      resolve(data);
    } catch (err) {
      if (err.response && err.response.data) {
        const error = err.response.data;
        reject({
          systemError: {
            errors: error.errors,
            title: error.title,
          },
        });
      } else {
        reject(err);
      }
    }
  });
};
export const getDue = async (dueId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await SCHEDULE_API.get(
        `${MILESTONE_ENDPOINTS.GET_DUE}/${dueId}`
      );

      if (!response?.data?.isSuccess) {
        resolve([]);
        return;
      }

      const data = response?.data?.result;

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const deleteRow = async (deliverableId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await SCHEDULE_API.delete(
        `${MILESTONE_ENDPOINTS.DELETE_ROW}/${deliverableId}`
      );

      if (!res?.data?.isSuccess) {
        resolve(null);
        return;
      }
      console.log("DELETE_ROW:: ", res);

      const data = res?.data?.result;
      resolve(data);
    } catch (err) {
      if (err.response && err.response.data) {
        const error = err.response.data;
        reject({
          systemError: {
            errors: error.errors,
            title: error.title,
          },
        });
      } else {
        reject(err);
      }
    }
  });
};
function mapData(arr = []) {
  function data(d) {
    return {
      ...d,
      subSections: [],
      subRows: d?.subSections.map((dd) => data(dd)),
    };
  }
  return arr.map((d, i) => {
    return data(d);
  });
}
