import React from "react";
import Card from "../Card";
import Typography from "../../../../../common/components/Typography";
import { FlexContent } from "./styled";

function SettingCard(props) {
  const { headerEndContent, children, heading, subheading } = props;

  return (
    <Card>
      <Card.Header>
        <div className="start">
          <Typography
            className="title"
            variant={Typography.font_variant.base}
            fontWeight={Typography.font_weight.semi_bold}
          >
            {heading}
          </Typography>

          <Typography
            variant={Typography.font_variant.xSmall}
            color={Typography.font_color.tint_04}
            fontStyle={Typography.font_style.italic}
          >
            {subheading}
          </Typography>
        </div>

        <div className="end">{headerEndContent}</div>
      </Card.Header>

      <Card.Content>{children}</Card.Content>
    </Card>
  );
}

SettingCard.FlexContent = FlexContent;

export default SettingCard;
