import React from "react";
import ReactDOM from "react-dom";
import { NavLink, generatePath, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import AngleLogo from "../../../../assets/logo-degree.svg";
import API from "../../../../services/api";
import API_ENDPOINTS from "../../../../services/api-endpoints";
import ProjectDashboard from "../../project/dashboard";
import CreateNewProject from "../../project/create-new-project";

import { usePermissionStore } from "../../../../store/AppStore/PermissionStore";
import { ROUTE_OPTIONS } from "../../../../constant/route-options";
import { useAppStore } from "../../../../store/AppStore/AppStore";
import { ROUTES_PATH } from "../../../../routes/paths";
import {
  removeGlobalProject,
  setCurrentTenant,
  useGlobalStore,
} from "../../../../store/GlobalStore";
import UserSettingOption from "./Components/UserSettingOption";
import "./sideMenu.scss";
import IconButton from "./../../../../common/components/Buttons/IconButton";
import { clearChat } from "../../../../setup/ChatBoxManager/Store/ChatBaseStore";
import { useIcon } from "../../../../common/components/IconSelector";

const menuIconSize = 20;
const menuBtnSize = 36;

const SettingLink = ({ routes }) => {
  const linkData = routes?.find((route) => route.key === "setting");
  const getIcon = useIcon();

  if (!linkData) {
    return null;
  }
  const { menuIcon, label, id } = ROUTE_OPTIONS.setting;
  const { path } = linkData;
  const MenuIcon = getIcon(menuIcon);

  return (
    <>
      <li
        className="m-item"
        data-tooltip-text={label}
        data-tooltip-placement="right"
        id={id}
      >
        <NavLink to={`${path}`}>
          <IconButton
            as="div"
            btnIconSize={menuIconSize}
            btnSize={menuBtnSize}
            icon={<MenuIcon />}
          />
          {/* <i className={`${menuIcon}`}></i> */}
        </NavLink>
      </li>
    </>
  );
};

const MainLinks = ({ routes }) => {
  const currentProjectId = useGlobalStore((state) => state.selectedProjectId);

  let mainLinks = routes?.filter((route) => route.key !== "setting");
  const getIcon = useIcon();

  console.log("mainLinks", mainLinks);
  mainLinks = [
    ...mainLinks,

    // ! Add temporary
    {
      menuId: 22,
      key: "milestone",
      path: "/milestone",
      name: "Milestone",
      parentId: null,
      status: 1,
      children: [],
    },
  ];

  if (!mainLinks) return null;

  return (
    <>
      {mainLinks?.map((route, i) => {
        const { key = null } = route;

        const menuItems = {
          ...ROUTE_OPTIONS,
        };

        if (!Object(menuItems).hasOwnProperty(key)) {
          return null;
        }

        const { menuIcon, label, id } = ROUTE_OPTIONS[key];
        const MenuIcon = getIcon(menuIcon);

        const _path = generatePath(route.path, { id: currentProjectId });

        return (
          <li
            key={i}
            className="m-item"
            data-tooltip-text={label}
            data-tooltip-placement="right"
            id={id}
          >
            <NavLink to={`${_path}`}>
              <IconButton
                as="div"
                btnIconSize={menuIconSize}
                btnSize={menuBtnSize}
                icon={<MenuIcon />}
              />
              {/* <i className={menuIcon}></i> */}
            </NavLink>
          </li>
        );
      })}
    </>
  );
};

const SideMenu = ({ className }) => {
  const [tenantList, seTenantList] = React.useState([]);
  const [tenantId, setTenatId] = React.useState(0);

  const navigate = useNavigate();

  let routes = usePermissionStore((state) => state.menu);

  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);

  const tenantSettingsRoute = routes?.find(
    (route) => route.key === "tenant-settings"
  );

  const getAndBindTenants = React.useCallback(async (e) => {
    try {
      let USER = JSON.parse(localStorage.getItem("user"));

      seTenantList(USER?.result?.tenantList || []);
      setTenatId(USER.result.tenantId);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const switchTenant = async (tenantId, navigateTo = null) => {
    try {
      if (Number(tenantId) === 0) {
        return;
      }
      showLoader(); //Loading Indicator
      let TenantId = tenantId;

      let USER = JSON.parse(localStorage.getItem("user"));

      const endpoints = new API_ENDPOINTS();

      API.post(endpoints.SWITCH_TENANT, {
        userId: USER.result.userId,
        tenantId: TenantId,
      }).then((response) => {
        console.log("Tenant list:", response);
        if (response.data.isSuccess === true) {
          localStorage.setItem("user", null);
          console.log("swiched: ", TenantId);
          let NewTenantID = Number(TenantId);
          USER.result.tenantId = NewTenantID;
          USER.result.tenantList = response?.data?.result;

          localStorage.setItem("user", JSON.stringify(USER));

          if (navigateTo) {
            navigate(navigateTo);
          }

          // Remove current selected project
          removeGlobalProject();
          setCurrentTenant(response?.data?.result, NewTenantID);

          window.location.reload();
          hideLoader();
        } else {
          hideLoader();
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);

          return;
        }
      });
    } catch (err) {
      hideLoader();
      console.log(err);
      toast.error(err);
    }
  };

  const onLogoutHandler = () => {
    removeGlobalProject();
    clearChat();
    navigate(ROUTES_PATH.PublicRoutePath.Login.path);
  };

  React.useEffect(() => {
    getAndBindTenants();
  }, [getAndBindTenants]);

  return (
    <aside className={["corner-radius", className]}>
      <div className="slide-menu">
        <nav className="nav-menu">
          <div className="start">
            <ul className="menu-items">
              {/* <li
                // className="disabled"
                data-target="#menu-setup"
                data-tip="Home"
                id="m-menu-home"
              >
                <div className="nav-item" onClick={() => navigate(-1)}>
                  <i className="fa-solid fa-caret-left"></i>
                </div>
              </li> */}

              {/* Load Setting Link */}
              <SettingLink routes={routes} />

              <MainLinks routes={routes} />
            </ul>
          </div>

          <div className="center">
            <div className="angle__logo">
              <img src={AngleLogo} alt="" />
            </div>
          </div>

          <div className="end">
            <UserSettingOption
              selectedTenantId={tenantId}
              tenantList={tenantList}
              onTenantSelect={switchTenant}
              onLogout={onLogoutHandler}
              tenantSettingsRoute={tenantSettingsRoute}
            />
          </div>
        </nav>

        <div className="menu-panel" id="menu-project">
          <div className="h-m-menu-inner">
            <ul className="container-fluid">
              <ol className="row">
                <div className="h-m-menu-mainTitle">Project</div>
              </ol>
              <ol className="row">
                <div className="h-m-menu-subTitle">Manage Your Projects</div>
              </ol>
              <ol className="row">
                <div className="h-m-menu-lineBreak"></div>
              </ol>
              <li className="row">
                <ul className="h-m-menu-menuItems">
                  <li
                    onClick={() =>
                      ReactDOM.render(
                        <CreateNewProject />,
                        document.getElementById("containerBody")
                      )
                    }
                  >
                    <a href="#">
                      <i className="fa-solid fa-plus"></i>
                      <div className="mainTT">
                        New
                        <br />
                        <span>New project</span>
                      </div>
                    </a>
                  </li>
                  <li
                    onClick={() =>
                      ReactDOM.render(
                        <ProjectDashboard />,
                        document.getElementById("containerBody")
                      )
                    }
                  >
                    <a href="#">
                      <i className="fa-solid fa-bars"></i>
                      <div className="mainTT">
                        Dashboard
                        <br />
                        <span>Project Dashboard</span>
                      </div>
                    </a>
                  </li>
                  {/* <li>
                        <a href="#">
                          <i className="fa-solid fa-arrows-up-down"></i>
                          <div className="mainTT">
                            Admin Request Status
                            <br />
                            <span>View employee request status</span>
                          </div>
                        </a>
                      </li> */}
                  {/* <li>
                        <a className="sub_menu" href="#">
                          <i className="fa-solid fa-plus"></i>
                          <div className="mainTT">
                            Create Project
                            <br />
                            <span>Create new project</span>
                          </div>
                        </a>
                        <ul className="submenu" id="sub-create-project">
                          <ol className="row">
                            <div className="title">
                              Create Project
                              <br />
                              <span>Project create options</span>
                            </div>
                            <div className="h-m-menu-lineBreak"></div>
                          </ol>
                          <ul>
                            <li>
                              <a href="#">New</a>
                            </li>
                            <li>
                              <a href="#">Import</a>
                            </li>
                          </ul>
                        </ul>
                      </li>
                      <li>
                        <a className="sub_menu" href="#">
                          <i className="fa-solid fa-file-pen"></i>
                          <div className="mainTT">
                            Manage Project
                            <br />
                            <span>Manage your projects</span>
                          </div>
                        </a>
                        <ul className="submenu" id="sub-mgr-project">
                          <ol className="row">
                            <div className="title">
                              Manage Projects
                              <br />
                              <span>Manage your all projects</span>
                            </div>
                            <div className="h-m-menu-lineBreak"></div>
                          </ol>
                          <ul>
                            <li>
                              <a href="">Menu Options</a>
                            </li>
                            <li>
                              <a href="">Menu Options</a>
                            </li>
                            <li>
                              <a href="">Menu Options</a>
                            </li>
                            <li>
                              <a href="">Menu Options</a>
                            </li>
                            <li>
                              <a href="">Menu Options</a>
                            </li>
                            <li>
                              <a href="">Menu Options</a>
                            </li>
                            <li>
                              <a href="">Menu Options</a>
                            </li>
                            <li>
                              <a href="">Menu Options</a>
                            </li>
                            <li>
                              <a href="">Menu Options</a>
                            </li>
                            <li>
                              <a href="">Menu Options</a>
                            </li>
                          </ul>
                        </ul>
                      </li> */}
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div className="menu-panel" id="1menu-calender">
          <div className="h-m-menu-inner">
            <ul className="container-fluid">
              <ol className="row">
                <div className="h-m-menu-mainTitle">Calender</div>
              </ol>
              <ol className="row">
                <div className="h-m-menu-subTitle">Manage Calender</div>
              </ol>
              <ol className="row">
                <div className="h-m-menu-lineBreak"></div>
              </ol>
              <li className="row">
                <ul className="h-m-menu-menuItems">
                  <li className="profile">
                    <a className="sub_menu" href="#">
                      <i className="fa-solid fa-outdent"></i>
                      <div className="mainTT">
                        Leave Setup
                        <br />
                        <span>Leave setup configuration</span>
                      </div>
                    </a>
                    <ul></ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default SideMenu;
