import * as React from "react";
import { memo } from "react";
const Svg02 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.9886 14.7081H14.1236V14.1186C14.1236 13.9838 14.0238 13.866 13.874 13.866H13.1088V9.5544H13.874C14.0071 9.5544 14.1235 9.45336 14.1235 9.30177V8.51023C14.1235 8.37547 14.0237 8.2576 13.874 8.2576H12.3102C12.2104 5.83234 10.3473 3.87866 7.98502 3.6934V2.27867C8.76686 2.12708 9.24931 2.56495 10.0645 2.36288C10.131 2.34605 10.1809 2.27867 10.1809 2.21129V0.813367C10.1809 0.712326 10.0977 0.644947 9.99791 0.661775C9.21607 0.830195 8.75025 0.425972 7.98501 0.560734V0.156567C7.98501 0.0723566 7.91846 0.00497437 7.83528 0.00497437H7.40277C7.3196 0.00497437 7.25304 0.0723538 7.25304 0.156567V3.71024C4.8908 3.89549 3.01099 5.84918 2.92782 8.27443H1.36408C1.23098 8.27443 1.11455 8.37547 1.11455 8.52706V9.33551C1.11455 9.47027 1.21435 9.58814 1.36408 9.58814H2.12935V13.9165H1.36414C1.23103 13.9165 1.1146 14.0176 1.1146 14.1692V14.7587H0.249532C0.116426 14.7587 0 14.8597 0 15.0113V15.7523C0 15.8871 0.0998017 16.005 0.249532 16.005H14.9886C15.1217 16.005 15.2381 15.9039 15.2381 15.7523V14.9439C15.2215 14.826 15.1216 14.7081 14.9886 14.7081ZM8.31776 13.3775V10.0765C8.31776 9.79021 8.53405 9.57123 8.81682 9.57123C9.0996 9.57123 9.31589 9.79021 9.31589 10.0765V13.3944C9.31589 13.6807 9.0996 13.8996 8.81682 13.8996C8.53405 13.8828 8.31776 13.6638 8.31776 13.3775ZM6.92038 10.0765V13.3944C6.92038 13.6807 6.70409 13.8996 6.42132 13.8996C6.13854 13.8996 5.92225 13.6807 5.92225 13.3944V10.0765C5.92225 9.79021 6.13854 9.57123 6.42132 9.57123C6.70409 9.55435 6.92038 9.79015 6.92038 10.0765ZM11.2123 13.8828C10.9296 13.8828 10.7133 13.6638 10.7133 13.3775V10.0765C10.7133 9.79021 10.9296 9.57123 11.2123 9.57123C11.4951 9.57123 11.7114 9.79021 11.7114 10.0765V13.3944C11.7114 13.6638 11.4952 13.8828 11.2123 13.8828ZM4.02581 9.55439C4.30858 9.55439 4.52487 9.77337 4.52487 10.0597V13.3775C4.52487 13.6638 4.30858 13.8828 4.02581 13.8828C3.74303 13.8828 3.52674 13.6638 3.52674 13.3775V10.0765C3.52674 9.79015 3.74298 9.55439 4.02581 9.55439Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg02);
export default Memo;
