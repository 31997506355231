import * as React from "react";
import { memo } from "react";
const SvgTable = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1035_5112)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0001 0V16H0V0H16.0001ZM0.88841 7.55528H7.55528V0.88836H0.88841V7.55528ZM8.44368 7.55528H15.1105V0.88836H8.44478L8.44368 7.55528ZM0.88841 15.1106H7.55528V8.44364H0.88841V15.1106ZM8.44368 15.1106H15.1105V8.44364H8.44478L8.44368 15.1106Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1035_5112">
        <rect width={16.0001} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgTable);
export default Memo;
