import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import FromGroup from "../../../../../../controls/FromGroup";
import Button from "../../../../../Buttons/Button";
import { useRoleMenuStore } from "../../../../../../../store/Setting/Permission";
import UISelect from "../../../../../../controls/UISelect";
import { toast } from "react-toastify";

const formValues = {
  roleId: null,
  menuIds: [],
};

// Validation Schema
const validationSchema = Yup.object().shape({
  roleId: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .required("This field is required")
    .nullable(),
  menuIds: Yup.array()
    .min(1, "Pick at least one item")
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
    ),
});

const Form = (props) => {
  // const { variant } = props;

  const formData = useRoleMenuStore((state) => state.formData);
  const isEditMode = useRoleMenuStore((state) => state.isEditMode);
  const variant = useRoleMenuStore((state) => state.variant);
  const roleOptions = useRoleMenuStore((state) => state.roleOptions);
  const menuOptions = useRoleMenuStore((state) => state.menuOptions);

  const clearForm = useRoleMenuStore((state) => state.clearForm);
  const onFormSubmit = useRoleMenuStore((state) => state.onFormSubmit);
  const onToggleForm = useRoleMenuStore((state) => state.onToggleForm);

  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    onSubmit,
  });

  const { errors, values, resetForm, handleSubmit, touched, setFieldValue } =
    formik;

  function onSubmit(values, actions) {
    const payload = {
      roleId: values.roleId.value,
      menuIds: values.menuIds.map((d) => d.value),
    };

    onFormSubmit(payload)
      .then((res) => {
        console.log("done", res);
        if (res.isSuccess === true) {
          console.log("done", res);
          if (isEditMode === false) {
            // toast.success(res.displayMessage);
          } else {
            // toast.success("Role Menu(s) is updated successfully");
          }
        } else {
          toast.error(res.displayMessage);
        }
      })
      .catch(() => {
        console.log("error");
      });
  }

  const onFormClearHandler = React.useCallback(() => {
    clearForm(() => {
      resetForm();
      onToggleForm();
    });
  }, [resetForm, clearForm, onToggleForm]);

  React.useEffect(() => {
    if (!isEditMode) {
      resetForm();
    }
  }, [isEditMode, resetForm, variant]);

  // Set values to form
  React.useEffect(() => {
    if (isEditMode) {
      setFieldValue("roleId", formData?.roleId || null);
      setFieldValue("menuIds", formData?.menuIds || []);
    }
  }, [isEditMode, formData, setFieldValue]);

  return (
    <div className="setting-form setting__menu--form">
      <form onSubmit={handleSubmit} className="row ">
        <div className="col-md-4">
          <FromGroup
            label="Role"
            inputHeightAuto
            error={touched.roleId && errors.roleId}
          >
            <UISelect
              name="roleId"
              value={values.roleId}
              options={roleOptions}
              onChange={(val) => setFieldValue("roleId", val)}
              placeholder="Select Role"
            />
          </FromGroup>
        </div>

        <div className="col-md-12">
          <FromGroup
            label="Menus"
            inputHeightAuto
            error={touched.menuIds && errors.menuIds}
          >
            <UISelect
              name="menuIds"
              isMulti
              value={values.menuIds}
              options={menuOptions}
              onChange={(val) => setFieldValue("menuIds", val)}
              placeholder="Select Menu"
            />
          </FromGroup>
        </div>

        <div className="col-12">
          <FromGroup type="button-inline-left">
            <Button variant="secondary" onClick={onFormClearHandler}>
              Cancel
            </Button>
            <Button type="submit">{isEditMode ? "Edit" : "Add"}</Button>
          </FromGroup>
        </div>
      </form>
    </div>
  );
};

export default Form;
