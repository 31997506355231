import styled from "styled-components";

export const PopoverContainer = styled.div`
  position: relative;
  background-color: var(--layout-background-white);
  border-radius: var(--layout-border-radius-16);
  box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.07);
  padding-block: var(--layout-spacing-20);
  padding-inline: var(--layout-spacing-24);
  /* width: 225px;
  max-width: 225px; */

  .header {
    display: flex;
    align-items: center;

    .start {
    }

    .end {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .content {
    display: block;
  }
`;

export const PopoverDivided = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--layout-background-n20);
  margin-block: var(--layout-spacing-8);
`;
