import styled, { css } from "styled-components";
import { cssContainerCard, cssContainerList } from "./styled";

export const ItemGroupContainer = styled.div`
  .group__header {
    &__title {
      font-size: ${({ theme }) => theme.fonts.h2};
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      color: ${({ theme }) => theme.colors.primary()};

      span {
        margin-left: ${({ theme }) => theme.spacing(2)};
        color: ${({ theme }) => theme.colors.secondary()};
        font-weight: ${({ theme }) => theme.fontWeight.regular};
      }
    }
  }

  .group__content {

    --group-space-bottom: ${({ theme }) => theme.spacing(8)};
    --group-space-top: ${({ viewType, theme }) => {
      return theme.spacing(viewType === "list" ? 4 : 12);
    }};

    display: grid;
    padding: var(--group-space-top) 0 var(--group-space-bottom);

    ${({ viewType }) => {
      return viewType === "list" ? cssContainerList : cssContainerCard;
    }}
  }
`;
