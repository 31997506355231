import React from "react";
import { styleClass } from "../../../utility";
import "./styles.scss";

const Container = (props) => {
  const { children, withGutterY = true, pageContentClassName } = props;

  const gutterCls = withGutterY ? "with-gutter-y" : null;

  return (
    <div className="base__content">
      <div className="base__content__iW">
        {/* <div className="base__content__menu__area">hdhsd</div> */}
        <div className="base__content__contain__area">
          <div className="page__content">
            <div className={styleClass("page__content__iW", gutterCls)}>
              <div
                className={styleClass(
                  "page__content__wrapper",
                  pageContentClassName
                )}
              >
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Container;
