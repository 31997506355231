import React from "react";
import useSystemStatus from "../../../../../hooks/useSystemStatus";

import Button, { BUTTON_ICONS } from "../../../../ui-components/Buttons/Button";
import "./styles.scss";

const BriefToolbar = (props) => {
  const {
    title,
    onDecline,
    onJoinProject,
    onCancel,
    showDeclineBtn,
    showJoinBtn,
    showDecideLateBtn,
    statusText,
    statusId,
  } = props;

  const { getOnboardingStatus } = useSystemStatus();

  const status = React.useMemo(() => {
    return getOnboardingStatus(statusId);
  }, [statusId, getOnboardingStatus]);

  return (
    <div className="brief__toolbar">
      <div className="start">
        <h4 className="title">{title}</h4>
      </div>

      <div className="center">
        {statusId && status ? (
          <div className="app__status app__status__pending">
            <span className="icon"></span>
            <span className="text">{status?.label}</span>
          </div>
        ) : null}
      </div>

      <div className="end">
        {showDecideLateBtn ? (
          <Button variant="gray-400" onClick={onCancel}>
            Decide Later
          </Button>
        ) : null}

        {showDeclineBtn ? (
          <Button
            leftIcon={BUTTON_ICONS.cancel}
            variant="gray-400"
            onClick={onDecline}
            disabled={!onDecline}
          >
            Decline
          </Button>
        ) : null}

        {showJoinBtn ? (
          <Button
            leftIcon={BUTTON_ICONS.done}
            onClick={onJoinProject}
            disabled={!onJoinProject}
          >
            Join Project
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default BriefToolbar;
