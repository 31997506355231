import styled from "styled-components";
import { fontStyle } from "../../../../../../styled/utility";

export const HeaderContainer = styled.div`
  display: flex;
  padding: var(--layout-spacing-12) var(--layout-spacing-24);
  justify-content: space-between;
  align-items: center;
`;

export const LeftBlockWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--layout-spacing-40);
`;

export const RightBlockWrapper = styled.div`
  display: flex;
  align-items: center;

  .textFrame {
    display: flex;
    align-items: flex-start;
    gap: var(--layout-spacing-4, 4px);

    ${fontStyle("x-small-regular")};
    color: var(--font-tint-04, #969bac);

    .textLink {
      color: var(--color-danger-300);
      text-decoration-line: underline;
      cursor: pointer;
    }
  }

  .actions {
    display: flex;
    gap: var(--layout-spacing-20);
    align-items: center;
    padding-left: var(--layout-spacing-40);
    position: relative;

    &::before {
      content: "";
      display: block;
      height: 80%;
      position: absolute;
      /* top: 0; */
      left: var(--layout-spacing-20);
      width: 1px;
      background-color: var(--color-neutral-40);
    }
  }
`;
