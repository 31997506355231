import styled from "styled-components";
import { fontStyle } from "../../../../../../../../../styled/utility";

export const Container = styled.div`
  padding: var(--layout-spacing-12);
  border-radius: var(--layout-border-radius-8);
  max-width: 14.625rem;
  min-width: 14.625rem;
  margin: 0 var(--layout-spacing-12);

  background-color: var(--layout-background-n20);

  .sub-stage {
    display: grid;
    gap: var(--layout-spacing-8);

    &__item {
      background-color: var(--color-accent-75);
      padding: var(--layout-spacing-8);
      border-radius: var(--layout-border-radius-8);
      display: flex;
      align-items: center;
      gap: var(--layout-spacing-4);
      justify-content: space-between;
      min-width: 100%;

      &--text {
        ${fontStyle("x-small-regular")};
        color: var(--font-default-black);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        min-width: 0;
      }

      &--start {
        flex: 1;
        min-width: 0px;
      }

      &--end {
      }
    }
  }
`;
