const config = {
  id: "dashboard",
  menuIcon: "menu-scheduler-tool-01",
  label: "Dashboard",
  path: "/dashboard",
  children: {
    app_dashboard: {
      id: "app_dashboard",
      path: "",
      label: "Dashboard",
    },
    project_dashboard: {
      id: "project_dashboard",
      path: "project/:id",
      label: "Dashboard",
    },
  },
};

export default config;
