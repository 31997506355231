export const black = () => "#000";
export const white = () => "#fff";

// Background colors
export const bg = (variant = 1) => {
  const colors = {
    1: "var(--bg-1)",
    2: "var(--bg-2)",
  };

  return colors[variant];
};

// Green
export const primary = (variant = "default") => {
  const colors = {
    default: "var(--primary-default)",
    10: "var(--primary-10)",
    20: "var(--primary-20)",
    30: "var(--primary-30)",
    40: "var(--primary-40)",
    50: "var(--primary-50)",
    60: "var(--primary-60)",
    70: "var(--primary-70)",
    80: "var(--primary-80)",
  };

  return colors[variant];
};

// Light Green 
export const primary_light = (variant = "default") => {
  const colors = {
    default: "var(--primary-light-default)",
    10: "var(--primary-light-10)",
    20: "var(--primary-light-20)",
    30: "var(--primary-light-30)",
    40: "var(--primary-light-40)",
    50: "var(--primary-light-50)",
    60: "var(--primary-light-60)",
    70: "var(--primary-light-70)",
    80: "var(--primary-light-80)",
  };

  return colors[variant];
};

// Green 2
export const primary_dark = (variant = "default") => {
  const colors = {
    default: "var(--primary-dark-default)",
    10: "var(--primary-dark-10)",
    20: "var(--primary-dark-20)",
    30: "var(--primary-dark-30)",
    40: "var(--primary-dark-40)",
    50: "var(--primary-dark-50)",
    60: "var(--primary-dark-60)",
    70: "var(--primary-dark-70)",
    80: "var(--primary-dark-80)",
  };

  return colors[variant];
};

// Orange
export const secondary = (variant = "default") => {
  const colors = {
    default: "var(--secondary-default)",
    10: "var(--secondary-10)",
    20: "var(--secondary-20)",
    30: "var(--secondary-30)",
    40: "var(--secondary-40)",
    50: "var(--secondary-50)",
    60: "var(--secondary-60)",
    70: "var(--secondary-70)",
    80: "var(--secondary-80)",
  };

  return colors[variant];
};

// Gray dark(Black)
export const color1 = (variant = "default") => {
  const colors = {
    default: "var(--color-1-default)",
    10: "var(--color-1-10)",
    20: "var(--color-1-20)",
    30: "var(--color-1-30)",
    40: "var(--color-1-40)",
    50: "var(--color-1-50)",
    60: "var(--color-1-60)",
    70: "var(--color-1-70)",
    80: "var(--color-1-80)",
  };

  return colors[variant];
};

// Beige
export const color2 = (variant = "default") => {
  const colors = {
    default: "var(--color-2-default)",
    10: "var(--color-2-10)",
    20: "var(--color-2-20)",
    30: "var(--color-2-30)",
    40: "var(--color-2-40)",
    50: "var(--color-2-50)",
    60: "var(--color-2-60)",
    70: "var(--color-2-70)",
    80: "var(--color-2-80)",
    90: "var(--color-2-90)",
  };

  return colors[variant];
};

// Action/Blue/60
export const action_color_1 = (variant = "default") => {
  const colors = {
    default: "var(--action-color-1-default)",
    10: "var(--action-color-1-10)",
    20: "var(--action-color-1-20)",
    30: "var(--action-color-1-30)",
    40: "var(--action-color-1-40)",
    50: "var(--action-color-1-50)",
    60: "var(--action-color-1-60)",
    70: "var(--action-color-1-70)",
    80: "var(--action-color-1-80)",
    90: "var(--action-color-1-90)",
  };

  return colors[variant];
};

// Action/Yellow/Base
export const action_color_2 = (variant = "default") => {
  const colors = {
    default: "var(--action-color-2-default)",
    10: "var(--action-color-2-10)",
    20: "var(--action-color-2-20)",
    30: "var(--action-color-2-30)",
    40: "var(--action-color-2-40)",
    50: "var(--action-color-2-50)",
    60: "var(--action-color-2-60)",
    70: "var(--action-color-2-70)",
    80: "var(--action-color-2-80)",
    90: "var(--action-color-2-90)",
  };

  return colors[variant];
};

// Action/Light Yellow/Base
export const action_color_3 = (variant = "default") => {
  const colors = {
    default: "var(--action-color-3-default)",
    10: "var(--action-color-3-10)",
    20: "var(--action-color-3-20)",
    30: "var(--action-color-3-30)",
    40: "var(--action-color-3-40)",
    50: "var(--action-color-3-50)",
    60: "var(--action-color-3-60)",
    70: "var(--action-color-3-70)",
    80: "var(--action-color-3-80)",
    90: "var(--action-color-3-90)",
  };

  return colors[variant];
};

// Action/Red/Base
export const action_color_4 = (variant = "default") => {
  const colors = {
    default: "var(--action-color-4-default)",
    10: "var(--action-color-4-10)",
    20: "var(--action-color-4-20)",
    30: "var(--action-color-4-30)",
    40: "var(--action-color-4-40)",
    50: "var(--action-color-4-50)",
    60: "var(--action-color-4-60)",
    70: "var(--action-color-4-70)",
    80: "var(--action-color-4-80)",
    90: "var(--action-color-4-90)",
  };

  return colors[variant];
};
