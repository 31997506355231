export const SCHEDULE_ENDPOINTS = {
  SAVE_BLOCK: "/schedule/blocktype/withActivities",
  GET_All_BLOCK: "/schedule/blocktype/allblocktypes",
  DELETE_BLOCK: "/schedule/blocktype",
  GET_STATGES: "schedule/columntype/allcolumntypes",
  SAVE_STAGE: "schedule/columntype",
  GET_All_MILESTONES: "schedule/milestoneitemtype/allmilestoneitemtypes",
  SAVE_ACTIVITY: "/schedule/activityitem",
  DELETE_MILESTONE: "schedule/milestoneitemtype",
  CREATE_COLUMN: "schedule/column"
};
