import React from "react";
import * as Styled from "./styled";
import BlockItem from "./BlockItem";
import { useSchedulerStore } from "../../../../../../../store/SchedulerStore";
import { ScheduleItemType } from "../../../../../../../../../Constants";

function BlockActivities(props) {
  const { onClick } = props;

  const __selectedColumnType = useSchedulerStore(
    (state) => state.selectedColumnType
  );
  const legendScheduleItemTypes = useSchedulerStore(
    (state) => state.legendScheduleItemTypes
  );
  const [activityItemTypes, setActivityItemTypes] = React.useState([]);
  const [blockTypes, setBlockTypes] = React.useState([]);

  React.useEffect(() => {
    const activities =
      legendScheduleItemTypes?.find(
        (item) => item.columnTypeId === __selectedColumnType?.value
      )?.activityItemTypes || [];
    setActivityItemTypes(activities);
    const blockes =
      legendScheduleItemTypes?.find(
        (item) => item.columnTypeId === __selectedColumnType?.value
      )?.blockTypes || [];
    setBlockTypes(blockes);
  }, [__selectedColumnType?.value, legendScheduleItemTypes]);

  return (
    <Styled.BlockActivitiesContainer>
      <div className="titleBlock">{__selectedColumnType?.label}</div>

      {activityItemTypes?.map((d, i) => {
        return (
          <BlockItem
            key={i}
            itemName={d?.itemType?.itemName}
            onClick={() => onClick(ScheduleItemType.Activity, d)}
          />
        );
      })}
      {blockTypes?.map((d, i) => {
        return (
          <BlockItem
            key={i}
            itemName={d?.blockName}
            onClick={() => onClick(ScheduleItemType.Block, d)}
          />
        );
      })}

      {/* <BlockItem itemName="FINE CUT# | PRODUCER (WK##)" onClick={onClick} />
      <BlockItem itemName="PICTURE LOCK# (WK##)" onClick={onClick} /> */}
    </Styled.BlockActivitiesContainer>
  );
}

export default BlockActivities;
