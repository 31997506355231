import * as React from "react";
import { memo } from "react";
const SvgDoc01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0814 0H2.04577C1.76288 0.00767347 1.49443 0.126596 1.29867 0.330959C1.10291 0.535323 0.99564 0.808641 1.00014 1.0916V14.9084C0.99564 15.1914 1.10291 15.4647 1.29867 15.669C1.49443 15.8734 1.76288 15.9923 2.04577 16H13.6686C13.9516 15.9924 14.2201 15.8735 14.416 15.6692C14.6118 15.4648 14.7191 15.1914 14.7146 14.9084V4.52578L10.0814 0ZM9.91011 1.42297L13.3387 4.796H9.91011V1.42297ZM2.14289 14.8572V1.14275H9.00014V5.71413H13.5715V14.8572H2.14289Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgDoc01);
export default Memo;
