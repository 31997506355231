import styled from "styled-components";
import { fontStyle } from "../../../../../../styled/utility";

export const ChatItemWrapper = styled.div`
  display: flex;
  flex: 1;
  gap: var(--layout-spacing-12);
  padding: 0 var(--layout-spacing-20);
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  .chat__item__start {
    display: flex;

    .chat__item__avatar {
      border-radius: 50%;
      /* layout/boxShadow/primary/10 */
      box-shadow: -3px 8px 15px 0px rgba(45, 89, 102, 0.08);

      .ph__letter {
        width: calc(100%);
        height: calc(100%);
      }
    }
  }

  .chat__item__end {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 0;

    &--top {
      display: flex;
      gap: var(--layout-spacing-12);
      justify-content: space-between;
      flex: 1;

      .chat__name {
        ${fontStyle("label-medium")};
        display: flex;
        align-items: stretch;
        flex: 1;
        font-weight: 500;
        color: var(--font-default-black);
        min-width: 0;
      }

      .message__info {
        position: relative;
        display: flex;
        justify-content: center;
        min-width: var(--layout-size-52);
        width: var(--layout-size-52);

        &__time {
          ${fontStyle("x-small-regular")};
          color: var(--font-tint-03, #a5a9b8);
          display: flex;
          align-items: center;
        }

        &__unread_count {
          ${fontStyle("x-small-medium")};
          font-weight: 500;
          position: absolute;
          bottom: calc(
            var(--layout-spacing-minus-16) + var(--layout-spacing-minus-2)
          );
          color: var(--font-default-white);
          width: var(--layout-size-20);
          height: var(--layout-size-20);
          min-width: var(--layout-size-20);
          min-height: var(--layout-size-20);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background: var(--color-danger-200);
        }
      }
    }

    &--bottom {
      display: flex;
      justify-content: space-between;
      flex: 1;

      .last__message {
        ${fontStyle("x-small-regular")};
        color: ${({ isTyping }) =>
          isTyping
            ? `var(--color-accent-300, #56B5BC);`
            : `var(--font-tint-04, #969bac)`};
        max-width: 50%;
        display: flex;
        align-items: stretch;
      }
    }
  }
`;
