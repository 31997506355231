import React, { useState, useEffect } from "react";
import { useLocation, useRouteLoaderData } from "react-router-dom";

import { ROUTE_OPTIONS } from "../../../../../constant/route-options";
import { StyledToolbar } from "./styled";
import AddButton from "../../../../../common/components/Buttons/AddButton";
import SearchInput from "../../../../../common/components/Form/SearchInput";
import LeftSideActions from "./LeftSideActions";
import ArrowButtons from "../ArrowButtons";
import ResearchDataButton from "./ResearchDataButton";
import ToolbarNav from "./ToolbarNav";
import SwitchField from "../../../../../common/components/Form/SwitchField";
import {
  TAG_MODAL_MODE,
  useResearchBoardStore,
} from "../../../store/ResearchBoardStore";
import { Permission } from "../../../../../helpers/permission";
import useTagsSearch from "../../../hooks/useTagsSearch";

const Toolbar = () => {
  const location = useLocation();
  const routeChildren = ROUTE_OPTIONS.research_board.children;

  const currentPath = location.pathname;

  const { permission } = useRouteLoaderData(ROUTE_OPTIONS.research_board.id);


  const onShowTagModal = useResearchBoardStore((state) => state.onShowTagModal);
  const [searchInput, setSearchInput] = useState("");
  const { data } = useTagsSearch({ queryValue: searchInput });
  const onTagSearchResultChange = useResearchBoardStore(
    (state) => state.onTagSearchResultChange
  );

  // const moduleType = PROJECT_PERMISSION_MODULES.Onboarding;

  // const memberPermission = permissions.findByName(moduleType.AllMembers.key);
  // const shortListPermission = permissions.findByName(moduleType.ShortList.key);
  // const hrListPermission = permissions.findByName(moduleType.HRList.key);
  // const favoritesPermission = permissions.findByName(moduleType.Favorites.key);
  // const onboardingPermission = permissions.findByName(
  //   moduleType.OnboardingList.key
  // );

  const onToggleResearchPanel = useResearchBoardStore(
    (state) => state.onToggleResearchPanel
  );

  const onOpenSidePanelHandler = () => {
    onToggleResearchPanel();
  };

  useEffect(() => {
    onTagSearchResultChange(data);
  }, [data, onTagSearchResultChange]);
  
  return (
    <>
      <StyledToolbar>
        <div className="top">
          <div className="start">
            {/* Left side actions Episode, Add and shared */}
            <LeftSideActions />

            {/* Nav */}
            <ToolbarNav permission={permission} />
          </div>

          <div className="end">
            {/* Filter options */}
            {currentPath.includes(routeChildren["research-data"].path) ? (
              <>
                <SwitchField label="View Todo" />
                <SwitchField label="Enable Episodes" />
              </>
            ) : null}
          </div>
        </div>

        <div className="bottom">
          <div className="inner">
            <div className="start">
              <div className="search__input__block">
                <SearchInput
                  defaultExpanded={true}
                  canExpandable={false}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </div>

              {/* Add new item */}
              {currentPath.includes(routeChildren["research-data"].path) ? (
                <AddButton
                  tooltipText="Create new tag"
                  className="add__new__btn"
                  onClick={() =>
                    onShowTagModal({ mode: TAG_MODAL_MODE.Create })
                  }
                />
              ) : null}
            </div>
            <div className="end">
              <div className="line"></div>

              <div className="actions">
                {/* Arrow Buttons */}
                {currentPath.includes(routeChildren["research-data"].path) ? (
                  <ArrowButtons />
                ) : null}

                {/* Research Data Button */}
                {currentPath.includes(
                  routeChildren["research-organizer"].path
                ) ? (
                  <ResearchDataButton onClick={onOpenSidePanelHandler} />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </StyledToolbar>
    </>
  );
};

export default Toolbar;
