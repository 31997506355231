import React, { useRef, useState, useEffect, lazy } from "react";
import API from "../../../services/api";
import API_ENDPOINTS from "../../../services/api-endpoints";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SettingAccordion from "../../ui-components/pages-ui/Setting/SettingPanel/SettingAccordion";
import SettingPanel from "../../ui-components/pages-ui/Setting/SettingPanel";
import FromGroup from "../../controls/FromGroup";
import Button from "../../ui-components/Buttons/Button";
import { Permission } from "../../../helpers/permission";
import { SETTING_PERMISSION_MODULES } from "../../../constant/setting_permission_module";
import { ROUTE_OPTIONS } from "../../../constant/route-options";
import { useLocation, useNavigate, useRouteLoaderData } from "react-router-dom";
import { uploadImage } from "../../../Actions/Common";
import ProfileImageUploader from "../../controls/ProfileImageUploader";
import useValidation from "../../../hooks/useValidation";
import { useAppStore } from "../../../store/AppStore/AppStore";
import IconButton from "../../ui-components/Buttons/IconButton";
import { Plus } from "../../../common/components/icons/Line/Add";


const MyProfile = () => {
  const { textOnly, numberOnly } = useValidation();

  const { permission } = useRouteLoaderData(
    ROUTE_OPTIONS.setting.children["my-profile"].id
  );

  const moduleType = SETTING_PERMISSION_MODULES.MyProfile;
  const permissions = new Permission(permission);
  const myProfilePermission = permissions.findByName(moduleType.basic.key);

  const userTitles = useAppStore((state) => state.userTitles);

  const [show, setShow] = useState(false); //Loading Indicator
  const [title, setTitle] = useState("");
  const [jobTitle, setJobTitle] = useState(0);
  const [name, setName] = useState("");
  const [nickName, setNickName] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState(0);

  const [id, setId] = useState("");
  const [fullName, setFullName] = useState("");
  const [profileType, setProfileType] = useState(0);
  const [employmentType, setEmploymentType] = useState(0);
  const [address, setAddress] = useState("");
  const [primaryEmail, setPrimaryEmail] = useState("");

  const [address01, setAddress01] = useState("");
  const [address02, setAddress02] = useState("");
  const [address03, setAddress03] = useState("");

  const [profileImage, setProfileImage] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState(0);
  const [nationality, setNationality] = useState(0);
  const [agent, setAgent] = useState("");

  const [shortBio, setShortBio] = useState("");
  const [webLink, setWebLink] = useState("");
  const [endorsment, setEndorsment] = useState("");
  const [recentLetters, setRecentLetters] = useState("");
  const [cv, setCv] = useState("");

  const [agentName, setAgentName] = useState("");
  const [agentAddress01, setAgentAddress01] = useState("");
  const [agentAddress02, setAgentAddress02] = useState("");
  const [agentAddress03, setAgentAddress03] = useState("");
  const [agentEmail, setAgentEmail] = useState("");
  const [agentPhoneNumber, setAgentPhoneNumber] = useState("");

  let USER = JSON.parse(localStorage.getItem("user"));
  console.log("------- USER =------------- ", USER);
  let CountryList = [];
  const [countryOptionList, setCountryOptionList] = useState([]);
  let JobTitleList = [];
  const [jobTitleOptionList, setJobTitleOptionList] = useState([]);

  const { state: locationState } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getAndBindJobTitleList();
    getAndBindCountryList();
    getProfileBasicDetails();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
    } catch (err) {
      console.log(err);
    }
  };
  const getProfileBasicDetails = async (e) => {
    try {
      setShow(true); //Loading Indicator
      let userTenantId = USER.result.userTenantId;
      const endpoints = new API_ENDPOINTS();
      const response = API.get(
        endpoints.GET_PROFILE_BASIC_DETAILS + "/" + userTenantId
      ).then((response) => {
        console.log("Basic:", response);

        if (response.data.isSuccess === true) {
          setTitle(response.data.result.titleId);
          setJobTitle(response.data.result.jobTitleId);
          setName(response.data.result.givenName);
          setNickName(response.data.result.nickName);
          setCity(response.data.result.city);
          setCountry(response.data.result.countryId);
          setId(response.data.result.idNumber);
          setFullName(response.data.result.fullName);
          setAddress(response.data.result.address);
          setPrimaryEmail(response.data.result.email);
          setProfileImageUrl(response.data.result.profileImage || null);

          setShow(false); //Loading Indicator
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };
  const getAndBindCountryList = async (e) => {
    try {
      setShow(true); //Loading Indicator
      const endpoints = new API_ENDPOINTS();
      const response = API.get(endpoints.GETALLCOUNTRIES).then((response) => {
        console.log("counties:", response);
        if (response.data.isSuccess === true) {
          CountryList = response.data.result;

          const country_list_dropdown = CountryList.map((item) => {
            return <option value={item.countryId}>{item.countryName}</option>;
          });

          setCountryOptionList(country_list_dropdown);

          setShow(false); //Loading Indicator
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };
  const getAndBindJobTitleList = async (e) => {
    try {
      setShow(true); //Loading Indicator
      const endpoints = new API_ENDPOINTS();
      const response = API.get(endpoints.GETALLJOBTITLES).then((response) => {
        console.log("job titles:", response);
        if (response.data.isSuccess === true) {
          JobTitleList = response.data.result;

          const jobTitle_list_dropdown = JobTitleList.map((item) => {
            return <option value={item.jobTitleId}>{item.jobTitleName}</option>;
          });

          setJobTitleOptionList(jobTitle_list_dropdown);

          setShow(false); //Loading Indicator
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };
  const updateProfile = async (e) => {
    if (title === "") {
      toast.error("Please Enter Title !");
      return;
    }
    if (jobTitle === 0) {
      toast.error("Please Enter Job Title !");
      return;
    }
    if (name === "") {
      toast.error("Please Enter Name !");
      return;
    }
    if (nickName === "") {
      toast.error("Please Enter Nick Name !");
      return;
    }
    if (country === 0) {
      toast.error("Please Enter Country !");
      return;
    }
    if (city === "") {
      toast.error("Please Enter City !");
      return;
    }
    try {
      let userTenantId = USER.result.userTenantId;
      let userId = USER.result.userId;
      const endpoints = new API_ENDPOINTS();

      let profileImagePath = profileImageUrl;

      if (profileImage && profileImage?.imageData) {
        const imageFile = {
          containerName: process.env.REACT_APP_PROFILE_IMAGE_CONTAINER,
          imageData: [profileImage?.imageData],
          imageName: [profileImage?.file.name],
          imageType: [profileImage?.file.type],
        };

        // Upload profile image and get url
        const profileRes = await uploadImage(imageFile);

        if (!profileRes.isSuccess) {
          toast.error(profileRes.displayMessage);
          return;
        }

        profileImagePath = profileRes?.result[0]?.filePath;
        console.log("inside ");
      }

      console.log("down", profileImagePath);
      API.post(endpoints.USER_PROFILE_UPDATE + "/" + userId, {
        userTenantId: userTenantId,
        fullName: fullName,
        address: address,
        idNumber: id,
        givenName: name,
        surname: "",
        displayName: nickName,
        nickName: nickName,
        jobTitleId: jobTitle,
        countryId: country,
        title: "",
        titleId: title,
        city: city,
        profileType: profileType,
        employmentType: employmentType,
        companyName: "",
        email: primaryEmail,
        phoneNo: phoneNumber,
        dateOfBirth: dateOfBirth,
        gender: gender,
        nationality: nationality,
        agent: agent,
        profileImage: profileImagePath,
      }).then((response) => {
        console.log("update: ", response);

        if (response.data.isSuccess === true) {
          toast.success(response.data.displayMessage, {
            onClose: () => {
              if (locationState?.from && locationState?.from !== null) {
                navigate(locationState?.from, { replace: true });
              }
            },
          });

          getProfileBasicDetails();

          setShow(false); //Loading Indicator off
        } else {
          console.log(response.data.errorMessages?.[0]);
          toast.error(response.data.errorMessages?.[0]);
          setShow(false); //Loading Indicator off
          return;
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onProfileImageHandler = (files) => {
    if (files[0]) {
      setProfileImage(files[0]);
    }
  };
  const onCancelHandler = () => {
    navigate(`/${ROUTE_OPTIONS.dashboard.path}`);
  };

  // Breadcrumbs List
  const breadcrumbsList = [{ text: "Common" }, { text: " My Profile" }];

  return (
    <>
      {myProfilePermission.hasPermission ? (
        <SettingPanel
          breadcrumbs={breadcrumbsList}
          actions={
            myProfilePermission.permission.canAddNew ? (
              <>
                <Button variant="secondary" onClick={onCancelHandler}>
                  Cancel
                </Button>
                <Button onClick={updateProfile}>Save</Button>
              </>
            ) : null
          }
        >
          {/* Basic Details */}
          <SettingAccordion title="Basic Details">
            <SettingAccordion.Card>
              <div className="setting-form">
                <div className="form-inner d-flex">
                  <div className="form-left">
                    <form className="row">
                      <div className="col-md-4">
                        <FromGroup label="Title">
                          <select
                            name="title"
                            id="title"
                            className="form-select"
                            aria-label="Select Title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          >
                            <option value="">Select Title</option>
                            {userTitles.map((value) => (
                              <option key={value.id} value={value.id}>
                                {value.label}
                              </option>
                            ))}
                          </select>
                        </FromGroup>
                      </div>

                      <div className="col-md-8">
                        <FromGroup label=" Job Title">
                          <select
                            className="form-select"
                            aria-label="Select JobTitle"
                            onChange={(e) => setJobTitle(e.target.value)}
                            value={jobTitle}
                          >
                            <option selected value={0}>
                              - Select Job Title -
                            </option>
                            {jobTitleOptionList}
                          </select>
                        </FromGroup>
                      </div>

                      <div className="col-md-8">
                        <FromGroup label="Name">
                          <input
                            className="form-control"
                            type="text"
                            id="name"
                            name="name"
                            placeholder=""
                            aria-label=""
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                          />
                        </FromGroup>
                      </div>

                      <div className="col-md-4">
                        <FromGroup label="Nick Name">
                          <input
                            className="form-control"
                            type="text"
                            id="nickName"
                            name="nickName"
                            placeholder=""
                            aria-label=""
                            onChange={(e) => setNickName(e.target.value)}
                            value={nickName}
                          />
                        </FromGroup>
                      </div>

                      <div className="col-md-4">
                        <FromGroup label="City">
                          <input
                            className="form-control"
                            type="text"
                            id="city"
                            name="city"
                            placeholder=""
                            aria-label=""
                            onChange={(e) => setCity(e.target.value)}
                            value={city}
                          />
                        </FromGroup>
                      </div>

                      <div className="col-md-4">
                        <FromGroup label="Country">
                          <select
                            className="form-select"
                            aria-label="Select County"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                          >
                            <option selected value={0}>
                              - Select Country -
                            </option>
                            {countryOptionList}
                          </select>
                        </FromGroup>
                      </div>
                    </form>

                    <form className="row">
                      <div className="col-md-4">
                        <FromGroup label="ID">
                          <input
                            className="form-control"
                            type="text"
                            id="id"
                            name="id"
                            placeholder=""
                            aria-label=""
                            onChange={(e) => setId(e.target.value)}
                            value={id}
                          />
                        </FromGroup>
                      </div>

                      <div className="col-md-8">
                        <FromGroup label="Full Name">
                          <input
                            className="form-control"
                            type="text"
                            id="fullName"
                            name="fullName"
                            placeholder=""
                            aria-label=""
                            onChange={(e) => setFullName(e.target.value)}
                            value={fullName}
                          />
                        </FromGroup>
                      </div>
                      <div className="col-md-12">
                        <FromGroup label="Address">
                          <input
                            className="form-control"
                            type="text"
                            id="address1"
                            name="address1"
                            placeholder=""
                            aria-label=""
                            onChange={(e) => setAddress(e.target.value)}
                            value={address}
                          />
                        </FromGroup>
                      </div>
                    </form>
                  </div>

                  <div className="form-right" style={{ paddingLeft: "30px" }}>
                    <ProfileImageUploader
                      imageUrl={profileImageUrl}
                      className="profile__uploader"
                      onFileUploaded={onProfileImageHandler}
                      name="profile__uploader"
                    />
                  </div>
                </div>
              </div>
            </SettingAccordion.Card>
          </SettingAccordion>

          {/* Skills */}
          <SettingAccordion title="Skills">
            <SettingAccordion.Card>
              <div className="row setting-form">
                <div className="form-inner d-flex">
                  <div className="form-left">
                    <form className="row">
                      <div className="col-md-8">
                        <FromGroup label="Skill">
                          <input
                            className="form-control"
                            type="text"
                            id="skill"
                            name="skill"
                            placeholder="Enter your skill"
                            aria-label="skill"
                            onChange={(e) => {}}
                            value={null}
                          />
                        </FromGroup>
                      </div>

                      <div className="col-md-4">
                        <FromGroup label="Rate">
                          <div>
                            <input
                              className="form-control"
                              type="number"
                              id="rate"
                              name="rate"
                              placeholder="Enter rate here"
                              aria-label="rate"
                              onChange={(e) => {}}
                              value={null}
                              min={0}
                              maxLength={20}
                            />
                            <span
                              style={{
                                position: "absolute",
                                top: "50%",
                                right: "10px",
                                transform: "translateY(-50%)",
                                pointerEvents: "none",
                                color: "gray",
                              }}
                            >
                              USD
                            </span>
                          </div>
                        </FromGroup>
                      </div>
                      <IconButton
                        text={"Add New Skill"}
                        type="button"
                        icon={<Plus />}
                        handleOnClick={() => {
                          console.log("Skill added...");
                        }}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </SettingAccordion.Card>
          </SettingAccordion>
        </SettingPanel>
      ) : null}
    </>
  );
};

export default MyProfile;
