import * as React from "react";
import { memo } from "react";
const SvgFileFolder = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.63121 4.95284C4.63121 5.19783 4.43142 5.3964 4.18496 5.3964C3.93848 5.3964 3.73864 5.19782 3.73864 4.95284C3.73864 4.70785 3.93848 4.50922 4.18496 4.50922C4.43144 4.50922 4.63121 4.70785 4.63121 4.95284Z"
      fill="currentColor"
    />
    <path
      d="M4.18487 5.51995C3.83633 5.51995 3.55365 6.25498 3.55365 7.16166H4.81614C4.81609 6.25498 4.53358 5.51995 4.18487 5.51995Z"
      fill="currentColor"
    />
    <path
      d="M3.50411 13.1657H7.85561V13.5165H3.50411V13.1657Z"
      fill="currentColor"
    />
    <path
      d="M3.50411 14.2181H7.85561V14.5689H3.50411V14.2181Z"
      fill="currentColor"
    />
    <path
      d="M3.50411 12.1136H7.85561V12.4644H3.50411V12.1136Z"
      fill="currentColor"
    />
    <path
      d="M3.50411 11.0614H7.85561V11.4122H3.50411V11.0614Z"
      fill="currentColor"
    />
    <path
      d="M3.50411 10.0091H7.85561V10.3599H3.50411V10.0091Z"
      fill="currentColor"
    />
    <path
      d="M3.50411 8.95687H7.85561V9.30767H3.50411V8.95687Z"
      fill="currentColor"
    />
    <path
      d="M3.50411 7.90475H7.85561V8.25555H3.50411V7.90475Z"
      fill="currentColor"
    />
    <path
      d="M5.31562 6.85235H7.85546V7.20315H5.31562V6.85235Z"
      fill="currentColor"
    />
    <path
      d="M10.7615 12.901L10.5121 12.653L9.20795 13.9496L8.82663 13.5704L8.57701 13.8182L8.95851 14.1974L8.95834 14.1976L9.20778 14.4455H9.20812L9.45751 14.1976L9.45734 14.1974L10.7615 12.901Z"
      fill="currentColor"
    />
    <path
      d="M10.7615 10.29L10.5121 10.0419L9.20795 11.3384L8.82663 10.9592L8.57701 11.2073L8.95851 11.5865L8.95834 11.5866L9.20778 11.8346L9.20795 11.8344L9.20812 11.8346L9.45751 11.5866L9.45734 11.5865L10.7615 10.29Z"
      fill="currentColor"
    />
    <path
      d="M10.7615 7.67872L10.5121 7.43084L9.20795 8.72731L8.82663 8.34811L8.57701 8.59623L8.95851 8.97519L8.95834 8.97536L9.20778 9.22347L9.20795 9.2233L9.20812 9.22347L9.45751 8.97536L9.45734 8.97519L10.7615 7.67872Z"
      fill="currentColor"
    />
    <path
      d="M5.31562 5.79807H7.85546V6.14887H5.31562V5.79807Z"
      fill="currentColor"
    />
    <path
      d="M13.9999 3.42879C13.9999 1.53522 12.4558 0 10.551 0C8.86433 0 7.46268 1.20439 7.16347 2.7948H2V16H11.8682L11.8682 6.59876C11.866 6.59944 11.8637 6.59927 11.8614 6.6C13.116 6.08696 14 4.86109 14 3.42887L13.9999 3.42879ZM11.5012 15.6492H2.36694V3.1456H7.11647C7.10877 3.23927 7.1021 3.3333 7.1021 3.42883C7.1021 5.32269 8.64621 6.85762 10.551 6.85762C10.881 6.85762 11.199 6.80871 11.5012 6.72269L11.5012 15.6492ZM10.5511 6.39376C8.90392 6.39376 7.56883 5.06629 7.56883 3.42893C7.56883 1.79158 8.90402 0.464107 10.5511 0.464107C12.1981 0.464107 13.5333 1.79158 13.5333 3.42893C13.5333 5.06644 12.1981 6.39376 10.5511 6.39376Z"
      fill="currentColor"
    />
    <path
      d="M10.7894 1.09215H10.3571V3.55776H10.3582L11.6504 4.41554L11.8904 4.05828L10.7894 3.32722L10.7894 1.09215Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgFileFolder);
export default Memo;
