import create from "zustand";
import { devtools } from "zustand/middleware";

import {
  getAllJobTitles,
  getCountryList,
  getUserTitles,
} from "../../Actions/Common";
import { getProjectByID } from "../../Actions/Project";
import { getAssignTemplatesByProjectId } from "../../Actions/Setting/Templates";
import { getAllTemplates } from "../../Actions/Templates";

let store = (set, get) => ({
  isLoading: false,
  loadingContent: "",
  jobTitles: [],
  userTitles: [],
  countries: [],
  allTemplates: [],
  projectAssignTemplates: [],
  globalSelectedProject: null,
  globalSelectedProjectId: null,
  isOpenSideMenu: true,

  // Functions
  showLoader: (text = "Loading...") => {
    set({ isLoading: true, loadingContent: text });
  },

  hideLoader: () => {
    set({ isLoading: false, loadingContent: "" });
  },

  toggleSideMenu: () => {
    set((state) => ({ isOpenSideMenu: !state.isOpenSideMenu }));
  },

  // get all jobs titles
  getJobTitles: () => {
    getAllJobTitles()
      .then((data) => {
        set({ jobTitles: data });
      })
      .catch(() => {
        set({ jobTitles: [] });
      });
  },

  getUserTitleList: () => {
    getUserTitles()
      .then((data) => {
        set({ userTitles: data });
      })
      .catch(() => {
        set({ userTitles: [] });
      });
  },

  // get all templates
  loadAllTemplates: () => {
    getAllTemplates()
      .then((data) => {
        set({ allTemplates: data });
      })
      .catch(() => {
        set({ allTemplates: [] });
      });
  },

  // get assign all templates for project
  getProjectAssignTemplates: async (projectId) => {
    // Reload all templates
    const result = [];

    try {
      const allTemplates = (await getAllTemplates()) || [];

      if (!allTemplates.length) {
        set({ projectAssignTemplates: result });
        return;
      }

      const pTemplates = (await getAssignTemplatesByProjectId(projectId)) || [];

      pTemplates.forEach((template) => {
        allTemplates.forEach((allTemplateItem) => {
          if (template.templateId === allTemplateItem.templateId) {
            result.push(allTemplateItem);
          }
        });
      });

      set({ projectAssignTemplates: result, allTemplates });
    } catch (error) {
      set({ projectAssignTemplates: result });
    }
  },

  // Get all countries
  loadAllCountries: () => {
    getCountryList().then((list) => {
      set({ countries: list || [] });
    });
  },

  setGlobalProject: (id) => {
    return new Promise((resolve, reject) => {
      getProjectByID(id)
        .then((project) => {
          set({
            globalSelectedProject: project || null,
            globalSelectedProjectId: id,
          });

          resolve(project);
        })
        .catch((err) => {
          set({
            globalSelectedProject: null,
            globalSelectedProjectId: id,
          });
          reject(false);
        });
    });
  },
});

store = devtools(store, { name: "AppStore" });

export const useAppStore = create(store);
