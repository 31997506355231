import * as React from "react";
import { memo } from "react";
const SvgGrid = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2192_10340)">
      <path d="M0 0H4V4H0V0Z" fill="currentColor" />
      <path d="M0 6H4V10H0V6Z" fill="currentColor" />
      <path d="M0 12H4V16H0V12Z" fill="currentColor" />
      <path d="M6 0H10V4H6V0Z" fill="currentColor" />
      <path d="M6 6H10V10H6V6Z" fill="currentColor" />
      <path d="M6 12H10V16H6V12Z" fill="currentColor" />
      <path d="M12 0H16V4H12V0Z" fill="currentColor" />
      <path d="M12 6H16V10H12V6Z" fill="currentColor" />
      <path d="M12 12H16V16H12V12Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_2192_10340">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgGrid);
export default Memo;
