import styled from "styled-components";
import { PageContainer } from "../../../styled/Common/Container";
import { fontStyle, responsive } from "../../../styled/utility";

export const OuterContainer = styled.section`
  min-height: inherit;
  background-color: var(--layout-background-n10);
  height: calc(100vh - var(--nav-height));
`;

export const Container = styled.div`
  height: inherit;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
`;

export const SettingContainer = styled(PageContainer)`
  min-height: 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  .setting__header {
    padding-top: var(--layout-spacing-24);
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: var(--layout-background-n10);

    &.is-scrolled {
      box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.05);
    }

    &__top {
      margin-bottom: var(--layout-spacing-16);
    }
    &__title {
      color: var(--font-color-shade-primary);
      margin-bottom: var(--layout-spacing-4);
    }

    &__description {
      color: var(--font-tint-04);
    }

    &__nav {
      border-bottom: 1px solid var(--layout-background-n40);
    }

    .nav__wrapper {
      display: block;

      .main__nav {
        margin-bottom: -1px;

        .link {
          border-top-left-radius: var(--layout-border-radius-8);
          border-top-right-radius: var(--layout-border-radius-8);

          &:hover {
            .link__inner {
              color: var(--color-primary-300);
            }
          }

          .link__inner::after {
            border-bottom-width: 3px;
          }

          &.active {
            .link__inner {
              --tab-border-active-color: var(--color-secondary-300);

              background-color: var(--color-accent-300);
              color: var(--color-white);
              border-top-right-radius: var(--layout-border-radius-8);
              border-top-left-radius: var(--layout-border-radius-8);
            }
          }
        }
      }

      .second__nav {
        nav {
          background-color: var(--layout-background-white);
          border-bottom: 0;
        }
      }
    }
  }

  .setting__contents {
    /* padding: var(--layout-spacing-16) 0; */
    flex: 1;
  }
`;

export const TabButton = styled.button`
  --tab-border-active-color: transparent;

  transition: var(--animate-1);
  padding: var(--layout-spacing-4) var(--layout-spacing-16)
    var(--layout-spacing-8) var(--layout-spacing-16);
  color: var(--font-tint-04);
  border: 0;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    bottom: 0;
    background-color: var(--tab-border-active-color);
  }

  ${({ isActive }) => {
    if (isActive) {
      return `
                color: var(--color-white);
                border-radius: var(--layout-border-radius-8, 8px) var(--layout-border-radius-8, 8px) 0px 0px;
                background: var(--color-accent-300);
        
                ::after{
                    --tab-border-active-color: var(--color-secondary-300);
                }

                &:hover{
                    background: var(--color-accent-100);
                }
            `;
    }

    if (isActive === false) {
      return `
                &:hover{
                    color: var(--color-accent-300);
                }
            `;
    }
  }}
`;

export const CardContainer = styled.div`
  display: flex;
  gap: var(--layout-spacing-16);
  flex-direction: column;

  width: 100%;

  @media ${responsive.lg} {
    max-width: 70%;
  }

  .setting-card {
    max-width: 100% !important;
  }
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`;
