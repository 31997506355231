import styled from "styled-components";
import BgImage from "./../assets/img/bg-dot.png";

export const SignUpPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: var(--color-neutral-30);
  flex-direction: column;

  .logo__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--layout-spacing-56);
  }

  .sign__up {
    display: block;
    max-width: 800px;
    width: 800px;
    background-color: var(--layout-background-white);
    border-radius: var(--layout-border-radius-16);
    padding: var(--layout-spacing-40);
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: var(--layout-spacing-4);
      right: var(--layout-spacing-4);
      background-image: url(${BgImage});
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      width: 83%;
      z-index: 1;
      background-position: 0 var(--layout-spacing-4);
    }

    &__header {
      display: block;
      text-align: center;
      position: relative;
      z-index: 2;
      .main__title {
        margin-bottom: var(--layout-spacing-4);
      }

      .main__subtitle {
      }
    }

    &__body {
      display: block;
      margin-top: var(--layout-spacing-32);
      position: relative;
      z-index: 2;
    }
  }

  .sign__up__form--container {
    display: flex;
    justify-content: center;

    .sign__up__form--left {
    }

    .sign__up__form--right {
      background-color: var(--color-secondary-50);
      padding: var(--layout-spacing-32) var(--layout-spacing-40);
      box-shadow: 0px 9px 13px #0000000a;
      border-radius: var(--layout-border-radius-12);
      margin: var(--layout-spacing-24) 0;
      margin-left: var(--layout-spacing-minus-24);
      display: flex;
      align-items: stretch;
      // width: 240px;

      .profile__image__uploader {
        width: 190px;
      }

      .dragDrop {
        margin: 0;
        width: 212px;
        background-color: #fff;
      }
    }

    .sign__up__form--action {
      display: flex;
      justify-content: flex-end;
      margin-top: var(--layout-spacing-32);
      gap: var(--layout-spacing-8);
    }

    .form__group--input {
      margin-bottom: 0;

      select,
      input {
        height: auto !important;
      }
    }

    &-form {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-template-rows: repeat(5, auto);
      gap: var(--layout-spacing-12) var(--layout-spacing-16);
      border: 1px solid var(--color-primary-50);
      background-color: var(--layout-background-accent);
      padding: var(--layout-spacing-24) 67px var(--layout-spacing-24)
        var(--layout-spacing-40);
      border-radius: var(--layout-border-radius-8);
    }

    .input--title {
      grid-column: span 2;
      grid-row: 1;
    }

    .input--job-title {
      grid-column: span 6;
      grid-row: 1;
    }

    .input--fname {
      grid-column: 1 / 5;
      grid-row: 2;
    }
    .input--lname {
      grid-column: 5 / 9;
      grid-row: 2;
    }

    .input--nick-name {
      grid-column: 1 / 5;
      grid-row: 3;
    }

    .input--country {
      grid-column: 1 / 6;
      grid-row: 4;
    }

    .input--city {
      grid-column: 6 / 9;
      grid-row: 4;
    }
  }

  .sign__up__form--action {
    padding-right: 245px;
    display: flex;
    justify-content: flex-end;
    gap: var(--layout-spacing-8);
    margin-top: var(--layout-spacing-28);
  }
`;
