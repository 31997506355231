import BreadcrumbLink from "../../../../components/ui-components/Breadcrumb/BreadcrumbLink";
import config from "../config";
import Schedule from "./../../screens/Schedule";
import Ui from "../../screens/UI";
import Setup from "../../screens/Setup";
import { permissionLoader } from "../../../../routes/loaders";

const children = config.children;
const key = "schedule";

export const ChildrenRoutes = [
  {
    id: children.schedule.id,
    path: children.schedule.path,
    element: <Schedule />,
    handle: {
      //crumb: () => <BreadcrumbLink label={children.schedule.label} />,
    },
    loader: async () => {
      // return true;
      return await permissionLoader(key, children.schedule.id);
    },
  },
  {
    id: children.ui.id,
    path: children.ui.path,
    element: <Ui />,
    handle: {
      crumb: () => <BreadcrumbLink label={children.ui.label} />,
    },
    loader: async () => {
      return true;
      //   return await permissionLoader(children.general.id);
    },
  },

  /**
   * Setup screen
   */
  {
    id: children.setup.id,
    path: children.setup.path,
    element: <Setup />,
    handle: {
      crumb: () => <BreadcrumbLink label={children.setup.label} />,
    },
    loader: async () => {
      return true;
      //   return await permissionLoader(children.general.id);
    },
  },
];
