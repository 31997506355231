import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./styled";

const VARIANT = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  large: "large",
  base: "base",
  small: "small",
  xSmall: "x-small",
  label: "label",
};

const FONT_STYLE = {
  normal: "normal",
  italic: "italic",
};

const FONT_COLOR = {
  default_black: "default-black",
  default_white: "default-white",
  default_color: "default-color",
  tint_01: "tint-01",
  tint_02: "tint-02",
  tint_03: "tint-03",
  tint_04: "tint-04",
  color_shade_primary: "color-shade-primary",
  color_shade_secondary: "color-shade-secondary",
  color_shade_accent: "color-shade-accent",
  color_shade_success: "color-shade-success",
  color_shade_danger: "color-shade-danger",
  color_shade_warning: "color-shade-warning",
  color_shade_info: "color-shade-info",
};

const FONT_WEIGHT = {
  bold: "bold",
  semi_bold: "semi-bold",
  medium: "medium",
  regular: "regular",
};

function Typography(props) {
  const {
    children,
    variant = VARIANT.base,
    color = FONT_COLOR.default_color,
    fontWeight = FONT_WEIGHT.regular,
    fontStyle = FONT_STYLE.normal,
    ...otherProps
  } = props;

  return (
    <Styled.TextBase
      variant={variant}
      weight={fontWeight}
      textColor={color}
      fontStyles={fontStyle}
      {...otherProps}
    >
      {children}
    </Styled.TextBase>
  );
}

Typography.font_color = FONT_COLOR;
Typography.font_weight = FONT_WEIGHT;
Typography.font_variant = VARIANT;
Typography.font_style = FONT_STYLE;

Typography.propTypes = {
  variant: PropTypes.any,
  fontWeight: PropTypes.any,
  color: PropTypes.any,
  fontStyle: PropTypes.any,
  children: PropTypes.any,
};

export default Typography;
