import styled from "styled-components";
import { fontStyle } from "../../../../../../../../styled/utility";

export const Container = styled.div`
  .header {
    ${fontStyle("small-medium")};
  }

  .link__header {
    ${fontStyle("small-medium")};
  }
`;
