import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.125 6.975C12.925 4.875 12.425 2.975 11.65 1.45C11.125 0.375 9.35 0 8 0C6.65 0 4.875 0.375 4.325 1.45C3.55 2.975 3.05 4.875 2.85 6.975C6.275 8.1 9.725 8.1 13.125 6.975Z"
      fill="currentColor"
    />
    <path
      d="M2.925 9.175C4.6 9.85 6.3 10.175 8 10.175C9.7 10.175 11.4 9.85 13.075 9.175C13.175 9.125 13.225 9.05 13.225 8.95C13.225 8.45 13.2 7.975 13.175 7.5C9.725 8.6 6.275 8.6 2.825 7.5C2.8 7.975 2.775 8.45 2.775 8.95C2.775 9.05 2.825 9.125 2.925 9.175Z"
      fill="currentColor"
    />
    <path
      d="M13.725 8.25C13.725 8.475 13.725 8.725 13.725 8.95C13.725 9.25 13.55 9.525 13.25 9.65C9.7 11.075 6.125 11 2.75 9.65C2.475 9.525 2.275 9.275 2.275 8.95C2.275 8.725 2.275 8.475 2.275 8.25C0.775 8.575 0 9.05 0 9.6C0 12.1 16 12.1 16 9.6C16 9.05 15.225 8.575 13.725 8.25Z"
      fill="currentColor"
    />
    <path
      d="M13.575 13.7C13.5 13.7 13.425 13.75 13.375 13.8C13.35 13.85 13.325 13.9 13.325 13.95C13.325 14.025 13.3 14.1 13.25 14.175C13.075 14.4 12.7 14.6 12.2 14.6C10.85 14.6 11.15 13.775 10.875 13.4C10.6 13.05 10.05 12.8 9.425 12.8C8.875 12.8 8.4 12.975 8.1 13.275C8.075 13.3 8.05 13.325 8.025 13.35C8 13.325 8 13.325 7.975 13.3C7.675 13 7.2 12.8 6.625 12.8C5.975 12.8 5.425 13.05 5.15 13.425C5.025 13.575 4.975 13.775 4.975 13.95C4.975 14.025 4.95 14.1 4.9 14.175C4.725 14.4 4.35 14.6 3.85 14.6C3.35 14.6 2.95 14.4 2.8 14.175C2.75 14.1 2.725 14.025 2.725 13.95C2.725 13.875 2.7 13.825 2.65 13.775C2.6 13.725 2.55 13.7 2.475 13.7C2.325 13.7 2.225 13.8 2.225 13.95C2.225 14.225 2.3 14.5 2.475 14.75C2.625 15 2.85 15.225 3.125 15.4C3.675 15.775 4.425 16 5.275 16C6.35 16 7.3 15.625 7.825 15.075C7.925 14.975 8 14.875 8.05 14.775C8.1 14.85 8.175 14.925 8.225 15C8.75 15.6 9.7 16 10.825 16C12.05 16 13.05 15.55 13.55 14.85C13.75 14.575 13.85 14.275 13.85 13.925C13.825 13.8 13.7 13.7 13.575 13.7Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
