import ModalBackdrop from "../../ModalBackdrop";
import { motion } from "framer-motion";
import { Description, Dialog, DialogContainer, Title } from "./styled";

const variants = {
  visible: {
    opacity: 1,
    // scale: 1,
  },
  hidden: {
    opacity: 0,
    // scale: 0,
  },
};

const MotionComponent = motion(DialogContainer, { forwardMotionProps: true });

const AlertDialog = (props) => {
  const {
    visibility,
    onClose,
    title,
    onBackdropClick,
    actions,
    contents,
    alertIcon,
    contentTextAlign = "center",
  } = props;

  return (
    <ModalBackdrop visibility={visibility} onClick={onBackdropClick}>
      <MotionComponent variants={variants}>
        <Dialog>
          {alertIcon ? (
            <div className="icon__container">{alertIcon}</div>
          ) : null}

          <div className="dialog__content">
            <div className="dialog__body">
              <div className="title__wrapper">
                <Title>{title}</Title>
              </div>
              <div className="content__wrapper">
                <Description textAlign={contentTextAlign}>
                  {contents}
                </Description>
              </div>
            </div>
            <div className="dialog__actions">{actions}</div>
          </div>
        </Dialog>
      </MotionComponent>
    </ModalBackdrop>
  );
};

export default AlertDialog;
