import React from "react";
import { Outlet } from "react-router-dom";
import OnboardCompletionModal from "../../ui-components/Modals/OnboardCompletionModal";

const Projects = () => {
  return (
    <>
      <Outlet />

      <OnboardCompletionModal />
    </>
  );
};

export default Projects;
