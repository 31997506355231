import React from "react";
import * as Styled from "./styled";
import Avatar from "../../../../../../components/ui-components/Avatar";
import { TextEllipsis } from "../../../../../../styled/Common/styled.text";
import { format } from "date-fns";

const ChatItem = (props) => {
  const [isTyping] = React.useState(false);

  let USER = JSON.parse(localStorage.getItem("user"));

  let CURRENT_USER = props?.data?.chatGroupMembers?.find(
    (m) => m.userId === USER?.result?.userId
  );

  const lastActivityOn = props?.data?.lastActivityOn;
  // console.log("lst act:: ", lastActivityOn);
  //console.log("props?.data:: ", props?.data?.profileImage);
  return (
    <Styled.ChatItemWrapper isTyping={isTyping} onClick={props?.onClick}>
      <div className="chat__item__start">
        <Avatar
          size="size-36"
          scr={props?.data?.profileImage}
          placeholderLetter={props?.data?.groupName}
          className="chat__item__avatar"
        />
      </div>

      <div className="chat__item__end">
        <div className="chat__item__end--top">
          <div className="chat__name">
            <TextEllipsis>{props?.data?.groupName}</TextEllipsis>
          </div>
          <div className="message__info">
            <div className="message__info__time">
              {!!lastActivityOn && format(new Date(lastActivityOn), "hh:mm a")}
            </div>
            {/* {CURRENT_USER !== undefined && CURRENT_USER?.notReadCount !== 0 ? (
              <div className="message__info__unread_count">
                {CURRENT_USER?.notReadCount}
              </div>
            ) : null} */}
            {/* <div className="message__info__unread_count">
              {CURRENT_USER?.notReadCount}
            </div> */}
          </div>
        </div>

        <div className="chat__item__end--bottom">
          <div className="last__message">
            <TextEllipsis>
              {/* {isTyping ? "Is typing..." : "Can we have a meeting today"} */}
            </TextEllipsis>
          </div>
        </div>
      </div>
    </Styled.ChatItemWrapper>
  );
};

export default ChatItem;
