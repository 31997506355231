import React from "react";
import { IconBlock } from "../../../../styled/Common/Icons";

import { IconSearch } from "../../icons";
import { StyledSearchBtn, StyledSearchWrapper } from "./styled";

const SearchInput = (props, ref) => {
  const {
    defaultExpanded = false,
    className,
    canExpandable = true,
    ...inputProps
  } = props;

  const inputRef = React.useRef();

  const [isExpanded, setExpanded] = React.useState(defaultExpanded);

  React.useEffect(() => {
    if (isExpanded) {
      // inputRef?.current?.focus();
    }
  }, [isExpanded]);

  const onSearchHandler = React.useCallback(() => {
    if (canExpandable) {
      setExpanded((prev) => !prev);
    }

    inputRef.current.focus();
  }, [canExpandable]);

  return (
    <StyledSearchWrapper
      className={["search__wrapper", className].join(" ")}
      isExpanded={isExpanded}
    >
      <div className="inner">
        {isExpanded ? (
          <input
            ref={(node) => {
              inputRef.current = node;

              if (typeof ref === "function") {
                ref(node);
              } else if (ref) {
                ref.current = node;
              }
            }}
            className="search__input"
            type="text"
            placeholder="Search"
            {...inputProps}
          />
        ) : null}

        <StyledSearchBtn className="search__btn" onClick={onSearchHandler}>
          <IconBlock className="search__icon">
            <IconSearch />
          </IconBlock>
        </StyledSearchBtn>
      </div>
    </StyledSearchWrapper>
  );
};

export default React.forwardRef(SearchInput);
