// getAllProjectsByTenantIdAndUserId

import React from "react";
import { getAllProjectsByTenantIdAndUserId } from "../Actions/Project";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  data: null,
};

const useProjectsByParams = ({ tenantId = null, userId = null }) => {
  const [status, setState] = React.useState(initialState);

  const fetchData = React.useCallback(async () => {
    if (!userId || !tenantId) {
      setState(initialState);
      return;
    }

    setState((prev) => ({ ...prev, ...initialState, isLoading: true }));

    getAllProjectsByTenantIdAndUserId(tenantId, userId)
      .then((res) => {
        if (!res.isSuccess) {
          setState(initialState);
          return;
        }

        setState({ ...initialState, isSuccess: true, data: res?.result || [] });
      })
      .catch((err) => {
        setState({ ...initialState, isError: true });
      });
  }, [tenantId, userId]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    status,
    onReFetch: fetchData,
  };
};

export default useProjectsByParams;
