import styled from "styled-components";
import Column from "../BaseColumn";
import BaseControlItem from "../BaseControlItem";

export const YearColumn = styled(Column)``;

export const YearItems = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const YearItem = styled(BaseControlItem)`
  flex: 1;

  .text {
    color: var(--font-tint-02, #b2b5c2);
    position: absolute;
    transform: rotate(-90deg);
    width: auto !important;
    height: auto !important;
    padding: var(--layout-spacing-2) var(--layout-spacing-4);
    border-radius: var(--layout-border-radius-4);

    ${({ $isCurrent }) => {
      if ($isCurrent) {
        return `
          background-color: var(--color-accent-300,#56B5BC);
          color: var(--font-default-white,#FFF);
        `;
      }
    }};
  }
`;
