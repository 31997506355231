import create from "zustand";
import { devtools } from "zustand/middleware";
import { saveRole } from "../../../Actions/Setting/Permission";
import API from "../../../services/api-tenant";
import API_ENDPOINTS from "../../../services/api-endpoints";
import { confirm } from "react-confirm-box";
import { toast } from "react-toastify";

const dummyData = [
  {
    role: "HR",
    description: "Lorem ipsum dolor sit",
    roleStatus: 0,
    action: "9241",
    id: "9241",
  },
  {
    role: "Admin",
    description: "Lorem ipsum dolor sit amet.",
    roleStatus: 1,
    action: "9240",
    id: "9240",
  },
];

let SettingRoleStore = (set, get) => ({
  isEditMode: false,
  data: [],
  variant: null,
  showForm: false,
  formData: {
    role: "",
    description: "",
    roleStatus: 0,
    id: null,
  },

  onToggleForm: () => set((state) => ({ showForm: !state.showForm })),

  setVariant: (val) => {
    set({
      variant: val,
      isEditMode: false,
      showForm: false,
      formData: {
        role: "",
        description: "",
        roleStatus: 0,
        id: null,
      },
    });
  },

  onEdit: (id) => {
    // Get selected data by id
    const selectedForm = get().data.find((obj) => obj.id === id);

    if (selectedForm) {
      const { role = "", description = "", roleStatus = 0 } = selectedForm;

      // update state
      set({
        isEditMode: true,
        showForm: true,
        formData: {
          role,
          description,
          roleStatus,
          id,
        },
      });

      console.log("id, action ");
    }
  },

  onDelete: async (id,callback) => {
    console.log("delete id ", id);
    let onSetRoleData = get().onSetRoleData;

    const confirmation = await confirm(
      "Are you sure you want to delete this item ?"
    );

    if (!confirmation) {
      return;
    }


    const endpoints = new API_ENDPOINTS();

    API.delete(endpoints.PERMISSION_DELETE_ROLE+ "/" + id,
    {}).then((response) => {
      console.log("response: ", response);
      if (response.data.isSuccess === true){
        callback(response);
     
      }
      
    });
  },

  onFormSubmit: async (values) => {
    return new Promise((resolve, reject) => {
      let isEdit = get().isEditMode;
      let id = get().formData.id;
      let variant = get().variant;
      let onSetRoleData = get().onSetRoleData;

      console.log("id ", id);
      console.log("isEdit ", isEdit);
      console.log("variant ", variant);
      console.log("values ", values);

      let dataObject={
        "roleId": isEdit ? id : 0 ,
        "roleName": values.role,
        "roleDescription": values.description,
        "status": values.roleStatus ? 1  : 0,
        "superRoleId": 0,
        "projectId": 0
      }

      return saveRole(dataObject)
        .then((res) => {
          resolve(res);
          onSetRoleData();
        })
        .catch(() => {
          reject();
        });
    });
  },

  clearForm: (callback) => {
    set((state) => ({
      ...state,
      isEditMode: false,
      formData: {
        role: "",
        description: "",
        roleStatus: 0,
        id: null,
      },
    }));

    callback && callback();
  },

  onSetRoleData: async () => {
    const endpoints = new API_ENDPOINTS();

    API.get(endpoints.PERMISSION_ROLE_GET_ALL).then((response) => {
      console.log("response: ", response);
      if (response.data.isSuccess === true){

        let roleList = [];
        let i = 0;
        response.data.result.forEach(element => {
          let obj ={
            role: element.roleName,
            description: element.roleDescription,
            roleStatus: element.status,
            action: element.roleId,
            id: element.roleId,
          }
          roleList[i]=obj;
          i++;
        });

        set({ data: roleList });
      //set({ data: response.data.result });

      }
      
    });
    

    
  },
});

SettingRoleStore = devtools(SettingRoleStore);

export const useSettingRoleStore = create(SettingRoleStore);
