import styled from "styled-components";

export const TimePickerWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const TimeInput = styled.input`
  width: 290px;
  padding: 5px;
  text-align: center;
  font-size: 14px; 
  border: #ccc; 
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
  /* Conditional color: gray for placeholder, black for updated value */
  color: ${({ isPlaceholder }) => (isPlaceholder ? "#888" : "#000")}; 
  
  &:disabled {
    background-color: #f0f0f0;
    cursor: not-allowed;
    color: #888; 
  }

  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

export const TimeDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border-radius: 4px;
  padding: 10px;
  z-index: 10;
  width: auto; 
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  gap: 5px; 
  flex-wrap: nowrap; 
`;

export const TimeSelect = styled.div`
 width: auto; 
  text-align: center;
  margin-bottom: 0; 

  label {
    font-size: 12px;
    display: block;
    margin-bottom: 7px;
    color: #888;
  }

  input {
    width: 45px; 
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 3px;
    text-align: center;
    -moz-appearance: textfield; 
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;


export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

export const Button = styled.button`
  background: transparent; 
  border: none; 
  cursor: pointer;
  padding: 0; 
  margin: 0 5px;
  font-weight: bold;
  color: #888; 
  font-size: 0.9em; 
  line-height: 1; 
  
  &:hover {
    color: #333; 
  }
`;


export const ButtonInput = styled.input`
  width: 40px; 
  text-align: center;
  border: 1px solid #ccc; 
  border-radius: 4px; 
`;