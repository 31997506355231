import styled, { css } from "styled-components";
import BaseModal from "../BaseModal";

export const modalCss = css``;

export const Modal = styled(BaseModal)`
  /* max-height: calc(100vh - 20%); */

  --modal-width: 100%;
  --modal-max-width: 620px;

  .modal__content {
    min-height: 0px;
  }
`;

export const ModalHeader = styled.div`
  padding: var(--layout-spacing-28);
  padding-bottom: var(--layout-spacing-12);

  .header__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--layout-spacing-16);

    .header__content {
      .title__element {
        display: flex;
        flex-direction: column;
        gap: var(--layout-spacing-4);
      }
    }

    .btn__close {
      color: var(--font-tint-04);
      align-self: flex-start;
    }
  }
`;

export const ModalBody = styled.div`
  padding-inline: var(--layout-spacing-28);
  padding-bottom: var(--layout-spacing-28);

  .wall__element {
    padding: var(--layout-spacing-16);
    border-radius: var(--layout-border-radius-8);
    background: var(--color-neutral-10);
  }
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  pointer-events: none;
`;
