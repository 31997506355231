import React from "react";
import { differenceInDays, format, isAfter, isToday, isValid } from "date-fns";

import * as CellStyled from "./styled";
import { CellTypes, SubmissionStatusTypes } from "../../../../constant";
import IconButton from "../../../../../../../../common/components/Buttons/IconButton";
import IconTick from "../../../../../../../../common/components/icons/Fill/correct";
import IconWaiting from "../../../../../../../../common/components/icons/Line/waiting";
import { useMilestone } from "../../../../context/MilestoneContext";

export const SubmissionPendingStatus = [
  SubmissionStatusTypes.Prepared,
  SubmissionStatusTypes.ChangeRequested,
  SubmissionStatusTypes.Updated,
  SubmissionStatusTypes.None,
  SubmissionStatusTypes.Rejected,
  SubmissionStatusTypes.Submitted,
];

const StatusCell = (props) => {
  // console.log(props);

  const { getValue } = props;

  const data = getValue();

  const value = data?.value;
  // const value = React.useMemo(() => {
  //   return {
  //     submission: {
  //       submissionId: 6,
  //       subject: "Submission 000",
  //       note: "",
  //       changeRequest: "",
  //       state: 1,
  //       cellId: 367,
  //       isEditMode: true,
  //       changeLogs: [],
  //       changeRequests: [],
  //       links: [],
  //       attachments: [],
  //     },
  //     due: {
  //       dueId: 0,
  //       dueDate: new Date(),
  //       cellId: 367,
  //     },
  //   };
  // }, []);

  const passingProps = React.useMemo(() => {
    return {
      data,
      value,
      cell: props?.cell,
      row: props?.row,
      column: props?.column,
    };
  }, [data, props?.cell, props?.column, props?.row, value]);

  const render = React.useMemo(() => {
    if (!value.submission && !value.due) {
      return <DefaultCell {...passingProps} />;
    }

    if (Object(value).hasOwnProperty("submission") && !!value?.submission) {
      return <SubmissionCell {...passingProps} />;
    }

    if (Object(value).hasOwnProperty("due") && !!value?.due) {
      return <DueCell {...passingProps} />;
    }

    return null;

    // switch (data?.type) {
    //   case CellTypes.Due: {
    //     return <DueCell {...passingProps} />;
    //   }

    //   case CellTypes.Submission: {
    //     return <SubmissionCell {...passingProps} />;
    //   }

    //   default: {
    //     return <DefaultCell {...passingProps} />;
    //   }
    // }
  }, [value, passingProps]);

  return <MilestoneWrapper>{render}</MilestoneWrapper>;
};

function DefaultCell(props) {
  // const { toggleDeliverableModalHandler } = useMilestone();

  const { cellSubMenuHandler } = useMilestone();

  return (
    <CellStyled.StatusCell
      onClick={(e) => {
        cellSubMenuHandler({
          element: e.currentTarget,
          ...props,
          value: null,
        });

        // toggleDeliverableModalHandler(true, { ...props });
      }}
      $height="inherit"
      {...props}
    />
  );
}

function DueCell(props) {
  const { data, value } = props;

  const { cellSubMenuHandler } = useMilestone();

  return (
    <CellStyled.DateStatusCell
      $value={"default"}
      onClick={(e) => {
        e.preventDefault();
        cellSubMenuHandler({
          ...props,
          element: e.currentTarget,
          value: value?.due,
        });
      }}
    >
      <div className="text">{data.label}</div>
    </CellStyled.DateStatusCell>
  );
}

function SubmissionCell(props) {
  const { value } = props;

  const state = value?.submission?.state;

  const passProps = {
    ...props,
    value: value?.submission,
  };

  if (state === SubmissionStatusTypes.Approved) {
    return <SubmissionInner icon={<IconTick />} state={state} {...passProps} />;
  }

  // Need to changed this after the work flow was finalized
  if (SubmissionPendingStatus.includes(state)) {
    return (
      <SubmissionInner icon={<IconWaiting />} state={state} {...passProps} />
    );
  }

  if (state === SubmissionStatusTypes.Resubmitted) {
    return <SubmissionInner text="Resubmit" state={state} {...passProps} />;
  }

  // return null;
  return <DefaultCell {...props} />;
}

function SubmissionInner({ state, icon, text, ...rest }) {
  const { cellSubMenuHandler } = useMilestone();

  return (
    <CellStyled.StatusCell
      $value={state}
      onClick={(e) => {
        e.preventDefault();
        cellSubMenuHandler({
          element: e.currentTarget,
          ...rest,
        });
        // toggleDeliverableModalHandler(true, { ...rest });
      }}
    >
      {icon ? <CellIcon icon={icon} /> : null}
      {text ? <div className="text">{text}</div> : null}
    </CellStyled.StatusCell>
  );
}

function MilestoneWrapper({ children, milestones = [] }) {
  return (
    <CellStyled.MilestoneBlock>
      <div className="left">{children}</div>
      {milestones.length ? <div className="right"></div> : null}
    </CellStyled.MilestoneBlock>
  );
}

function CellIcon({ icon }) {
  return (
    <IconButton
      className="cell-status-icon"
      as="div"
      icon={icon}
      btnSize={16}
      btnIconSize={16}
    />
  );
}

export default StatusCell;
