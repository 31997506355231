import React, { Component, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import login from "../../img/login.svg";
import "../../sass/login/login.scss";
import SingleHeader from "../layout/single_header";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Loader from "react-loader-spinner";
import API_ENDPOINTS from "../../../services/api-endpoints";
import API from "../../../services/api";

const CreateNewPassword = () => {
  const [show, setShow] = useState(false); //Loading Indicator
  const [searchParams, setSearchParams] = useSearchParams();
  const [newPassword, setNewPassword] = useState("");
  const [renewPassword, setreNewPassword] = useState("");

  let token = "";
  let userId = "";
  if (searchParams.get("lptk") !== null) {
    token = searchParams.get("lptk");
  }

  console.log(token);
  useEffect(() => {
    getResetTokenInfor();
  }, []);

  const getResetTokenInfor = async (e) => {
    setShow(true); //Loading Indicator on
    try {
      const endpoints = new API_ENDPOINTS();
      const response = API.post(endpoints.FORGETPASSWORDTOKENDETAILREQUEST, {
        resetToken: token,
      }).then((response) => {
        console.log(response);
        if ((response.data.isSuccess = true)) {
          setShow(false); //Loading Indicator on
          return;
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== renewPassword) {
      toast.error("Passwords are not match!");
      return;
    }
    if (token === null || token === "") {
      toast.error("Password reset link not valid!");
      return;
    }

    setShow(true); //Loading Indicator on
    try {
      const endpoints = new API_ENDPOINTS();
      const response = API.post(endpoints.RESETPASSWORD, {
        userId: userId,
        verificationCode: token,
        newPassword: newPassword,
      }).then((response) => {
        console.log(response);
        if ((response.data.isSuccess = true)) {
          // toast.info("You're succesfully login!");
          //localStorage.setItem("user", JSON.stringify(response.data));

          setNewPassword("");
          setreNewPassword("");
          setShow(false); //Loading Indicator off
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator off
          return;
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <section className="layout">
        <SingleHeader />
        <div className="body">
          <div className="form_wrapper" style={{ paddingBottom: "3%" }}>
            <div className="login-logo">
              <img src={login} alt="" width={70} height={70} />
            </div>
            <div className="form_container">
              <div className="title_container">
                <h2 className="t1">Create New Password</h2>
                <h3 className="t1-sub">
                  create more secure and memarable password
                </h3>
              </div>
              <div className="row clearfix">
                <div className="">
                  <form onSubmit={handleSubmit}>
                    <div className="input_field">
                      <span className="lbl-cp">New Password</span>
                      <p>&nbsp;</p>
                    </div>
                    <div className="input_field">
                      <span>
                        <i className="fa-regular fa-keyboard"></i>
                      </span>
                      <input
                        type="password"
                        name="newpassword"
                        placeholder=""
                        required
                        onChange={(e) => setNewPassword(e.target.value)}
                        value={newPassword}
                      />
                    </div>
                    <div className="input_field">
                      <span className="lbl-cp">Re-Type Password</span>
                      <p>&nbsp;</p>
                    </div>
                    <div className="input_field">
                      <span>
                        <i className="fa-regular fa-keyboard"></i>
                      </span>
                      <input
                        type="password"
                        name="repassword"
                        placeholder=""
                        required
                        onChange={(e) => setreNewPassword(e.target.value)}
                        value={renewPassword}
                      />
                    </div>

                    <input
                      className="button"
                      type="submit"
                      value="Create New Password"
                    />
                  </form>
                </div>
              </div>
            </div>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <Loader.ThreeDots
                visible={show}
                width={100}
                height={30}
                color="#347084"
              ></Loader.ThreeDots>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreateNewPassword;
