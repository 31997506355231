import styled from "styled-components";

export const MessageWrapper = styled.div`
  max-height: 100%;
  min-height: 100%;
  flex-direction: column;
  display: flex;
  flex: 1;

  .message__content {
    flex: 1;
    overflow-y: auto;
    padding: var(--layout-spacing-20) 0;

    &__inner {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: var(--layout-spacing-20);
      min-height: 100%;

      .message__item + .time__divider {
        margin-top: var(--layout-spacing-20);
      }
    }
  }

  .message__form {
    padding: var(--layout-spacing-16) var(--layout-spacing-24);
    background: var(--color-neutral-20, #f5f5f7);
  }
`;
