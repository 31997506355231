import { generatePath, useMatch } from "react-router";
import { ROUTES_PATH } from "../../../../../routes/paths";

import React from "react";

const useIsDashboardPathMatch = () => {
  const mainPath = ROUTES_PATH.DashboardPaths;
  let dashboardPath = mainPath.path;

  const genPath = React.useMemo(() => {
    return generatePath(dashboardPath, null);
  }, [dashboardPath]);

  const path = useMatch(genPath);

  return !!path || false;
};

export default useIsDashboardPathMatch;
