export const ActionTypes = {
  NDA_FETCH_DETAILS: "NDA_FETCH_DETAILS",
};

export const initialState = {
  loading: false,
  isEmpty: false,
  isError: null,
  userId: "",
  logoUrl: "",
  title: "",
  projectName: "",
  joinAs: "",
  description: "",
  contactPerson: {
    name: "",
    email: "",
    position: "",
    avatarUrl: "",
  },
  document: {
    title: "",
    url: "",
  },
  projectDetails: null,
};

export const ndaReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.NDA_FETCH_DETAILS: {
      const data = action.payload;

      const projectInviteeDetails = data?.projectInviteeDetails || null;
      const projectDetails = data?.projectDetails || null;
      const projectNDA = data?.ndaInfo || null;

      if (!projectDetails && !projectNDA) {
        return state;
      }

      const {
        projectLogo,
        tenantName,
        projectName,
        projectContactPersonName,
        projectContactPersonEmail,
        projectContactPersonJobTitle,
        projectContactPersonImage,
        projectDescription,
      } = projectDetails;

      const { documentId = 9, templateId = 0, documentUrl = null } = projectNDA;
      const { userId, projectRoleName } = projectInviteeDetails;

      return {
        ...state,
        userId: userId,
        logoUrl: projectLogo,
        title: tenantName || null,
        projectName: projectName || null,
        joinAs: projectRoleName,
        description: projectDescription || null,
        contactPerson: {
          name: projectContactPersonName,
          email: projectContactPersonEmail,
          position: projectContactPersonJobTitle,
          avatarUrl: projectContactPersonImage,
        },
        document: {
          documentId,
          templateId,
          documentUrl,
        },
        projectDetails,
      };
    }

    default: {
      throw new Error();
    }
  }
};
