export const popupContainer = {
  width: "100%",
};

export const popupHeader = {
  marginBottom: "10px",
};

export const h3style = {
  fontSize: "20px",
  color: "#3C7F84",
  fontWeight: "700",
};
export const h4style = {
  fontSize: "14px",
  color: "#061216",
  fontWeight: "405",
};

export const dueDateContainer = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center", // Center content vertically
  alignItems: "center", // Center content horizontally
  // width: "82%",
  marginLeft: "20px",
  marginRight: "auto", // gap btwn the due date and form fields
};
export const scrollStyle = {
  overflowY: "auto", // Enables vertical scrolling
  maxHeight: "60vh", // Ensures the container doesn't grow too large
};

export const dueDateBadge = {
  backgroundColor: "#FF5A5F",
  color: "#fff",
  padding: "6px",
  borderRadius: "0.5px",
  marginTop: "6px",
  marginBottom: "18px",
  marginLeft: "-10px",
  width: "85%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center", // Centers the text inside
  alignItems: "center", // Centers the text inside
};

export const dueDateText = {
  fontSize: "11px",
  fontWeight: "normal",
  textAlign: "center",
};

export const dueDateBold = {
  fontSize: "14px",
  fontWeight: "bold",
  textAlign: "center",
};

export const separatorLine2 = {
  height: "1px",
  backgroundColor: "#e0e0e0",
  margin: "10px 0",
};
export const separatorLine1 = {
  height: "1px",
  backgroundColor: "#e0e0e0",
  marginTop: "-5px",
};

export const verticalSeparator = {
  width: "1px",
  backgroundColor: "#e0e0e0",
  marginRight: "4px",
  marginLeft: "2px",
};

export const formSection = {
  width: "80%",
  display: "flex",
  marginLeft: "15px",
  marginRight: "-30px",
  flexDirection: "column",
  paddingRight: "20px",
  overflowY: "auto", // Enables vertical scrolling
  maxHeight: "60vh", // Ensures the container doesn't grow too large
};

export const popupBody = {
  marginBottom: "20px",
};

export const inputField = {
  width: "100%",
  padding: "8px",
  fontSize: "14px",
  fontWeight: "400",
  border: "1px solid #ccc",
  borderRadius: "4px",
  color: "#888",
  marginBottom: "10px",
};

export const labelStyle = {
  display: "block",
  fontSize: "14px",
  marginBottom: "5px",
  marginTop: "10px",
  fontWeight: "500",
  color: "#333",
};

export const attachmentSection = {
  marginBottom: "20px",
};

export const popupFooter = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "20px",
};

export const buttonLeft = {
  display: "flex",
  justifyContent: "flex-start",
};

export const buttonRight = {
  display: "flex",
  gap: "10px",
};

export const urlButton = {
  marginLeft: "5px",
};

export const toastContainer = {
  width: "100%",
  height: "100%",
  borderRadius: "8px",
  border: "1px solid #FFC12E",
  borderStyle: "dashed",
  backgroundColor: "#FFE6A9",
  padding: "8px 8px 16px 16px",
  display: "flex",
  flexDirection: "column",
  marginBottom: "10px", // space below toast
};

export const toastHeader = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

export const toastTitle = {
  fontSize: "13px",
  fontWeight: "700",
  marginLeft: "-170px",
};
export const toastIcon = {
  color: "#FF5A5F",
  marginTop: "7px",
};
export const RejectAcceptIcon = {
  color: "#f5f8f3",
  marginTop: "1px",
};

export const toastButtons = {
  display: "flex",
  gap: "5px", // gap between red and green button
  marginRight: "5px",
};

export const redButton = {
  backgroundColor: "#FF5A5F",
  border: "none",
  borderRadius: "4px",
};

export const greenButton = {
  backgroundColor: "#95cc54",
  border: "none",
  borderRadius: "4px",
};

export const toastInfo = {
  marginTop: "-11px",
  marginLeft: "40px",
};

export const toastAuthor = {
  fontWeight: "400",
  fontSize: "10px",
  color: "#969BAC",
};

export const toastMessage = {
  fontWeight: "400",
  fontSize: "11px",
  color: "#023747",
  marginTop: "4px",
};

export const iconStyle = {
  marginRight: "15px",
  color: "#969BAC",
};

export const timelineContainer = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  marginTop: "5px",
  borderLeft: "2px dashed #95cc54", // Vertical Line
  paddingLeft: "20px", // Ensures space between line and text
  position: "relative", // This helps in absolute positioning of icons
};

export const timelineStep = {
  display: "flex",
  alignItems: "center",
  position: "relative",
  marginLeft: "20px",
};

export const statusIcon = {
  fontSize: "30px",
  color: "#95cc54",
  position: "relative",
  top: "-36.5px",
  left: "-55px", // Moves the icon to intersect with the dashed line
  zIndex: 1, // Ensures the icon stays on top of the line
  width: "30px",
};

export const statusText = {
  display: "flex",
  flexDirection: "column",
  marginLeft: "-45px",
  marginRight: "25px",
  marginBottom: "5px",
  color: "#97ADB4",
};
export const TimelineStatusStyle = {
  fontSize: "16px",
  fontWeight: "bold",
};

export const dateText = {
  color: "#888",
  fontSize: "12px",
  "white-space": "nowrap",
};
