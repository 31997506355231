import API_ENDPOINTS from "../../services/api-endpoints";
import API from "../../services/api-tenant";

export const getUserPageRightPermission = ({
  routeId,
  projectId = 0,
  sub = "",
}) => {
  const endpoints = new API_ENDPOINTS();
  // Get user
  // let USER = JSON.parse(localStorage.getItem("user"));
  // let userId = USER?.result?.userId;
  // let userId = "4a0aae4d-bd38-4e2b-99be-abbf8c50bc97";

  return new Promise((resolve, reject) => {
    API.get(
      `${endpoints.GET_USER_PAGE_RIGHT_PERMISSION}/${projectId}/${routeId}/${sub || 'nan'}`
    )
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
