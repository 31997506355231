import React from 'react'
import * as Styled from "./styled"
import SelectionField from '../../../../common/components/Form/SelectionField'
import SwitchField from '../../../../common/components/Form/SwitchField'

function ViewController() {
  return (
    <Styled.ViewControllerContainer>
        <Styled.ViewControllerSection>
            <div className="header">
                <div className="title">View Columns</div>
            </div>
            <div className="viewList">
                <SelectionField text="Year" />
                <SelectionField text="Month" />
                <SelectionField text="Day" />
                <SelectionField text="Week No" />
            </div>
        </Styled.ViewControllerSection>

        <Styled.ViewControllerSection>
            <div className="header">
                <div className="title">View Holydays</div>
                <SwitchField />
            </div>
            <div className="viewList">
                <SelectionField text="Korea" />
                <SelectionField text="UK" />
                <SelectionField text="USA" />
                <SelectionField text="Argentina" />
                <SelectionField text="Russia" />
            </div>
        </Styled.ViewControllerSection>
    </Styled.ViewControllerContainer>
  )
}

export default ViewController