import React from "react";
import "./styles.scss";

const PermissionButton = (props) => {
  const { type, actions, onUpdated } = props;

  const hasProperty = Object(actions).hasOwnProperty(type);

  // True or False
  const permissionType = actions?.[type];

  const iconState = (isChecked) => {
    if (isChecked) {
      return <span className="icon icon-lp-done checked"></span>;
    }

    return (
      <span className="icon unchecked">
        <svg
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="VisibilityOffIcon"
          fill="none"
        >
          <path fill="#949AA6" d="M4 11h7v2H4zM13 11h7v2h-7z" />
        </svg>
      </span>
    );
  };

  const onUpdateHandler = () => {
    let result = {
      ...actions,
      [type]: !actions[type],
    };

    onUpdated && onUpdated(result);
  };

  return (
    <button
      className={`bt action action--create`}
      onClick={hasProperty ? () => onUpdateHandler() : null}
      disabled={!hasProperty}
    >
      {hasProperty ? iconState(permissionType) : null}
    </button>
  );
};

export default PermissionButton;
