import styled from "styled-components";
import { fontStyle } from "../../../../../../../../styled/utility";
import { motion } from "framer-motion";
import IconButton from "../../../../../../../../common/components/Buttons/IconButton";

export const Container = styled(motion.div)`
  width: 600px;
  min-width: 600px;
  max-width: 600px;

  .inner {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .top__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(var(--column-top-height) + var(--column-main-title-height));
    max-height: calc(
      var(--column-top-height) + var(--column-main-title-height)
    );
    /* background-color: var(--layout-bg-color); */
    position: sticky;
    top: 0;
    z-index: 9;
    border-bottom: 1px solid #e9eaed;
    justify-content: flex-end;

    &::before {
      content: "";
      display: block;
      height: calc(100% - var(--layout-spacing-24));
      top: 0;
      left: 0;
      position: absolute;
      background-color: var(--layout-bg-color);
      width: 100%;
    }

    .action {
      display: flex;
      align-items: center;
      justify-content: space-between;
      /* padding-bottom: var(--layout-spacing-4); */

      .icon__button__element {
        background-color: var(--layout-bg-color);
      }

      .btn-collapse {
        .button__icon {
          --icon-button-icon-color: var(--font-tint-04);

          &:hover {
            --icon-button-icon-color: var(--font-tint-02);
          }
        }
      }

      .btn-add-new {
        background-color: var(--layout-background-white);

        .button__icon {
          --icon-button-icon-color: var(--font-tint-04);

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }

  .assigner__container {
    min-height: var(--min-scroll-height);
    background-color: var(--layout-background-white);
    border: 1px solid #e9eaed;
    border-top-width: 0;
    padding: 0 var(--layout-spacing-28);
  }

  .main__content {
    display: flex;
    flex-direction: column;
    /* min-height: 0; */
    /* flex: 1; */
    /* flex-grow: 1; */
    /* width: 100%; */

    .dates {
    }
  }
`;

export const AssigneeExpandButton = styled(IconButton)`
  position: absolute;
  right: var(--layout-spacing-20);
  top: var(--layout-spacing-20);
  z-index: 9;

  .button__icon {
    --icon-button-icon-color: var(--color-accent-300);
    --icon-size: var(--layout-size-32);
    background-color: var(--font-default-white);
    border-radius: var(--layout-border-radius-4);
  }
`;
