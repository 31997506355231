import React from "react";
import { generatePath, useNavigate } from "react-router";
import { useQuery } from "react-query";

import BaseDashboardBlock from "../../BaseDashboardBlock";
import IconButton from "../../../../../../common/components/Buttons/IconButton";
import useUserData from "../../../../../../hooks/useUserData";
import IconExpand from "../../../../../../common/components/icons/Line/Expand/01";
import IconTask from "../../../../../../common/components/icons/Line/Tasks/01";
import IconMilestone from "../../../../../../common/components/icons/Fill/Milestone/01";
import { setGlobalProject } from "../../../../../../store/GlobalStore";
import { ROUTES_PATH } from "../../../../../../routes/paths";
import { ROUTE_OPTIONS } from "../../../../../../constant/route-options";
import ProjectWidgetLoader from "./ProjectWidgetLoader";
import { SYSTEM_ROLE_TYPE } from "../../../../../../constant/System";
import { getProjectsByTenantIdAndUserId } from "../../../../api";
import { ONBOARDING_STATUS } from "../../../../../../constant/Status";
import { useAppStore } from "../../../../../../store/AppStore/AppStore";
import Alert from "../../../../../../utility/alert";
import WidgetLink, { WidgetLinks } from "../../WidgetLink";
import ProjectItemLink, { ProjectLink } from "./ProjectItemLink";
import { IconWrapperElement, ProjectList } from "./styled";

const BTN_ACCESS_BREIF = [
  ONBOARDING_STATUS.ShortListed,
  ONBOARDING_STATUS.ProjectAdded,
  ONBOARDING_STATUS.InvitationReceived,
  ONBOARDING_STATUS.InvitationRejected,
];
const BTN_ACCESS_DASHBOARD = [
  ONBOARDING_STATUS.ShortListed,
  ONBOARDING_STATUS.ProjectAdded,
  ONBOARDING_STATUS.InvitationReceived,
  ONBOARDING_STATUS.InvitationAccepted,
  ONBOARDING_STATUS.InvitationRejected,
];

const ProjectItemWidget = () => {
  const { tenantId, userId, userRoles } = useUserData();
  const navigate = useNavigate();
  const contentRef = React.useRef();
  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);

  // Get all projects
  const { isLoading, data = [] } = useQuery(
    ["d_projects", tenantId, userId],
    () => getProjectsByTenantIdAndUserId({ tenantId, userId }),
    {
      enabled: !!(userId && tenantId),
    }
  );

  const handleProjectBrief = async (p) => {
    try {
      showLoader();

      localStorage.setItem("selected_project", JSON.stringify(p));

      const path = generatePath(
        ROUTES_PATH.ProjectRoutePath.ProjectBrief.path,
        {
          id: p?.projectId || "-1",
        }
      );

      navigate("/projects/" + path);
      hideLoader();
    } catch (err) {
      console.log(err);
      hideLoader();
    }
  };

  const handleProjectDashBoard = async (p) => {
    try {
      showLoader();

      localStorage.setItem("selected_project", JSON.stringify(p));

      setGlobalProject(p?.projectId).then(() => {
        const dbMainPath = ROUTES_PATH.DashboardPaths;
        const dashboardPath = dbMainPath.path;
        const projectDashboardPath = dbMainPath.children.project_dashboard.path;

        const path = generatePath(`${dashboardPath}/${projectDashboardPath}`, {
          id: p?.projectId || "-1",
        });

        navigate(path);
        hideLoader();
      });
    } catch (err) {
      console.log(err);
      hideLoader();
    }
  };

  const handleResearchModule = (d) => {
    showLoader();
    try {
      setGlobalProject(d?.projectId).then(() => {
        navigate(ROUTE_OPTIONS.research_board.path + "/documents");
        hideLoader();
      });
    } catch (err) {
      console.log(err);
      hideLoader();
    }
  };

  const handleAlertMessage = () => {
    Alert.confirm({
      allowOutsideClick: false,
      iconHtml: null,
      type: "warning",
      title: "Access Denied !",
      text: `Need to accept the Project NDA to access to this project.`,
      confirmButtonText: "Got it!",
      showCancelButton: false,
    }).then(() => {});
  };

  return (
    <BaseDashboardBlock
      title="Projects"
      blockSpan={2}
      blockClassName="my_calendar"
    >
      <WidgetLinks>
        <WidgetLink label="Recently Work on" isDisabled />
        <WidgetLink label="All Projects" isActive />
      </WidgetLinks>

      <ProjectList ref={contentRef}>
        {isLoading ? (
          <ProjectWidgetLoader parentRef={contentRef?.current} />
        ) : data && data?.length > 0 ? (
          data?.map((d, i) => {
            let hideBtn = BTN_ACCESS_BREIF.includes(d.projectMemberStatusId);
            let hideBtnDashboard = BTN_ACCESS_DASHBOARD.includes(
              d.projectMemberStatusId
            );

            let hasAccessRnDBtn = userRoles?.includes(SYSTEM_ROLE_TYPE.RnD);

            return (
              <ProjectItemLink
                key={i}
                src={
                  d?.projectLogo
                    ? d?.projectLogo
                    : "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg"
                }
                name={d?.projectName}
                caption={"Slogan of the project"}
                leftActions={
                  <div className="links">
                    {!hideBtn && (
                      <ProjectLink
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleProjectBrief(d);
                        }}
                      >
                        Project Brief
                      </ProjectLink>
                    )}

                    {hasAccessRnDBtn ? (
                      <ProjectLink
                        className="item__link"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleResearchModule(d);
                        }}
                      >
                        Research Tool
                      </ProjectLink>
                    ) : null}
                  </div>
                }
                rightActions={
                  <>
                    <IconWrapper count="10">
                      <IconButton
                        data-tooltip-text="Pending Task"
                        icon={<IconTask />}
                        btnIconSize={28}
                        btnSize={28}
                        onClick={() => {}}
                      />
                    </IconWrapper>

                    <IconWrapper count="31">
                      <IconButton
                        // data-tooltip-text="Go to project dashboard"
                        icon={<IconMilestone />}
                        btnIconSize={28}
                        btnSize={28}
                        onClick={() => {}}
                      />
                    </IconWrapper>

                    <IconButton
                      data-tooltip-text="Go to project dashboard"
                      icon={<IconExpand />}
                      btnIconSize={28}
                      btnSize={28}
                      onClick={() => {
                        !hideBtnDashboard
                          ? handleProjectDashBoard(d)
                          : handleAlertMessage();
                      }}
                    />
                  </>
                }
              />
            );

            // return (
            //   <ProjectItem
            //     key={i}
            //     data={d}
            //     handleGoToDashboard={() =>
            //       !hideBtnDashboard
            //         ? handleProjectDashBoard(d)
            //         : handleAlertMessage()
            //     }
            //     handleBrief={!hideBtn ? () => handleProjectBrief(d) : null}
            //     handleResearchTool={
            //       hasAccessRnDBtn ? () => handleResearchModule(d) : null
            //     }
            //   />
            // );
          })
        ) : (
          "No projects available."
        )}
      </ProjectList>
    </BaseDashboardBlock>
  );
};
function IconWrapper({ count, children }) {
  return (
    <IconWrapperElement>
      <div className="count">{count}</div>
      {children}
    </IconWrapperElement>
  );
}

export default ProjectItemWidget;
