import React from "react";
import { Wrapper } from "./styled";

const EditableText = ({
  value = "",
  onChange,
  placeholder = "Placeholder",
  onBlur,
}) => {
  const [textVal, setTextVal] = React.useState("");
  const [isFocus, setIsFocus] = React.useState(false);

  React.useEffect(() => {
    setTextVal(value);
  }, [value]);

  const hasText = String(textVal).length === 0;

  return (
    <Wrapper className="editable-text">
      {isFocus || hasText ? (
        <input
          placeholder={placeholder}
          // autoFocus
          type="text"
          className="text-input"
          value={textVal}
          onBlur={() => {
            setIsFocus(false);
            onBlur && onBlur();
          }}
          onChange={(e) => {
            let val = e.target.value;
            setIsFocus(true);
            setTextVal(val);
            onChange && onChange(val);
          }}
        />
      ) : (
        <div
          className={["text", !hasText ? "no-value" : ""].join(" ").trim()}
          onClick={() => setIsFocus(true)}
        >
          {textVal || placeholder}
        </div>
      )}
    </Wrapper>
  );
};

export default EditableText;
