import * as React from "react";
import { memo } from "react";
const SvgFloorContinue = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 16V0H3.06446V16H2ZM6.96775 6.0445V0H8.03221V4.97783H13V6.04445L6.96775 6.0445ZM6.96775 16V9.95566H13V11.0221H8.03225V15.9999L6.96775 16Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgFloorContinue);
export default Memo;
