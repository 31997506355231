import React from "react";
import { Link, LinkWrapper } from "./styled";

const WidgetLink = ({ label, isDisabled, isActive, ...rest }) => {
  return (
    <Link $active={isActive} {...rest} $isDisabled={isDisabled}>
      <div className="link__text">{label}</div>
      <div className="link__line"></div>
    </Link>
  );
};

export const WidgetLinks = LinkWrapper;

export default WidgetLink;
