import React from "react";

import useContainerDimensions from "../../../../../../../../hooks/useContainerDimensions";
import Typography from "../../../../../../../../common/components/Typography";
import IconEdit from "../../../../../../../../common/components/icons/Line/Edit/01";
import IconDelete from "../../../../../../../../common/components/icons/Line/Delete/01";
import IconDuplicate from "../../../../../../../../common/components/icons/Line/Duplicate/01";
import PopoverDropdown, {
  DropdownMenuIcon,
  PopoverDropdownMenuItem,
  Sizes,
} from "../../../../../../../../common/components/UI/PopoverDropdown";

import * as Styled from "./styled";
import TextClampLines from "./../../../../../../../../common/components/UI/TextClampLines";
import IconButton from "../../../../../../../../common/components/Buttons/IconButton";

const CardContent = ({ name, documentIcon }) => {
  return (
    <Styled.CardInner>
      {documentIcon ? (
        <IconButton as="div" className="doc_icon" icon={documentIcon} />
      ) : null}

      <Typography
        variant={Typography.font_variant.label}
        fontWeight={Typography.font_weight.medium}
        className="card__content__title"
        data-tooltip-text={name}
      >
        <TextClampLines text={name} />
      </Typography>
    </Styled.CardInner>
  );
};

const BaseDocCard = (props) => {
  const {
    name,
    onEdit,
    onDelete,
    onDuplicate,
    onClick,
    statusLabel,
    cardContent = null,
    cardHeaderLeft,
    statusClassName,
    className,
    documentIcon,
    hideHeaderAction = false,
  } = props;

  const cardRef = React.useRef();
  const { width } = useContainerDimensions(cardRef);

  const dropdownOptions = React.useMemo(() => {
    return [
      { label: "Edit", icon: IconEdit, onClick: onEdit },
      { label: "Duplicate", icon: IconDuplicate, onClick: onDuplicate },
      { label: "Delete", icon: IconDelete, onClick: onDelete },
    ];
  }, [onDelete, onDuplicate, onEdit]);

  return (
    <Styled.Wrapper
      className={["doc__card", className].join(" ")}
      isClickable={onClick ? true : false}
      ref={cardRef}
      cardW={width}
    >
      <div className="card__header">
        <div className="header__left__wrapper">{cardHeaderLeft}</div>

        {!!statusLabel && (
          <Styled.CardStatus className={statusClassName}>
            <div className="label">{statusLabel}</div>
          </Styled.CardStatus>
        )}
        {!hideHeaderAction && (
          <PopoverDropdown
            className="option__menu"
            customDropdownIcon={<DropdownMenuIcon />}
            dropdownIconSize={Sizes.__16}
            options={dropdownOptions}
            dropdownAppendTo={document.body}
            isMaskTransparent
            render={(option, { closeDropdown, key }) => {
              const IconElement = option.icon;
              return option?.onClick ? (
                <PopoverDropdownMenuItem
                  key={key}
                  icon={option.icon ? <IconElement /> : null}
                  label={option?.label}
                  onClick={(p) => {
                    closeDropdown();
                    option.onClick(p);
                  }}
                />
              ) : null;
            }}
          />
        )}
      </div>

      <div className="card__contents" onClick={onClick}>
        {cardContent ? (
          cardContent
        ) : (
          <CardContent documentIcon={documentIcon} name={name} />
        )}
      </div>
    </Styled.Wrapper>
  );
};

export default BaseDocCard;
