import React from "react";
import "./styles.scss";
import infinity from "../../../../assets/img/infinity.7b3a7082.png";

const SettingContainer = (props) => {
  const { children, sidebar } = props;

  return (
    <div className="settings__container">

      <div className="settings__container__header">
        <div className="start">
          <div className="icon">
            <img src={infinity} alt="" />
          </div>
          <div className="title">System Settings</div>
          <div className="divided"></div>
        </div>
      </div>

      <div className="settings__container__body">
        <div className="sidebar__wrapper">{sidebar}</div>
        <div className="content">{children}</div>
      </div>
    </div>
  );
};

export default SettingContainer;
