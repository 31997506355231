import * as React from "react";
import { memo } from "react";
const SvgAi = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.98874 8.63459L7.78562 10.0399H8.18917L7.98874 8.63459Z"
      fill="currentColor"
    />
    <path
      d="M14.7291 6.66666H14.4583V3.45066C14.4569 3.42705 14.4523 3.40377 14.4447 3.38133V3.35733C14.4322 3.32878 14.4149 3.30261 14.3933 3.28L11.1433 0.0799999C11.1203 0.0587221 11.0937 0.0416113 11.0647 0.0293333H11.0403C11.0134 0.0147783 10.984 0.00483271 10.9537 0H2.8125C2.74068 0 2.67178 0.0281116 2.621 0.0781112C2.57022 0.128111 2.54167 0.195945 2.54167 0.266669V6.66666H2.27084C2.19901 6.66666 2.13012 6.69478 2.07933 6.74478C2.02855 6.79478 2 6.86261 2 6.93333V12.2667C2 12.3374 2.02855 12.4052 2.07933 12.4552C2.13011 12.5052 2.19901 12.5333 2.27084 12.5333H2.54167V15.7333C2.54167 15.8041 2.57022 15.8719 2.621 15.9219C2.67178 15.9719 2.74068 16 2.8125 16H14.1875C14.2593 16 14.3282 15.9719 14.379 15.9219C14.4298 15.8719 14.4583 15.8041 14.4583 15.7333V12.5333H14.7292C14.801 12.5333 14.8699 12.5052 14.9207 12.4552C14.9714 12.4052 15 12.3374 15 12.2667V6.93333C15 6.86261 14.9714 6.79478 14.9207 6.74478C14.8699 6.69478 14.8009 6.66666 14.7291 6.66666ZM11.2083 0.909368L13.5347 3.2H11.2083V0.909368ZM13.9166 15.4672H3.08327V12.5339H13.9166V15.4672ZM6.99944 11.3792L7.61422 7.81923H8.36444L8.9711 11.3792H8.39694L8.26965 10.5605H7.68736L7.55466 11.3792H6.99944ZM9.43694 11.3792V7.82193H9.9786V11.3792H9.43694ZM13.9164 6.66723H3.08312V0.533902H10.6664V3.46723C10.6664 3.53796 10.695 3.60579 10.7458 3.65579C10.7966 3.70579 10.8655 3.7339 10.9373 3.7339H13.9164L13.9164 6.66723Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgAi);
export default Memo;
