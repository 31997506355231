import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.7719 6.13061H6.03563C5.85341 6.13061 5.67065 6.20367 5.52488 6.34945L4.17467 7.69966C4.02854 7.84561 4.02854 8.06481 4.17467 8.21058L5.52488 9.56079C5.67065 9.70674 5.85341 9.77963 6.03563 9.77963H13.7719C13.9907 9.77963 14.1369 9.63368 14.1369 9.41466V6.49558C14.1369 6.27674 13.9907 6.13061 13.7719 6.13061Z"
      fill="currentColor"
    />
    <path
      d="M1.72977 15.5724V16H2.45971V15.5724C2.3454 15.613 2.22274 15.635 2.09474 15.635C1.96674 15.6348 1.8439 15.6128 1.72977 15.5724Z"
      fill="currentColor"
    />
    <path
      d="M1.72977 9.5391V12.5835C1.84408 12.543 1.96674 12.5209 2.09492 12.5209C2.22292 12.5209 2.34558 12.543 2.45989 12.5835V9.5391C2.34558 9.57945 2.22292 9.60185 2.09492 9.60185C1.96674 9.60185 1.8439 9.57945 1.72977 9.5391Z"
      fill="currentColor"
    />
    <path
      d="M2.45971 0.427551V0H1.72977V0.427551C1.84408 0.387018 1.96674 0.364974 2.09492 0.364974C2.22274 0.365151 2.34558 0.387196 2.45971 0.427551Z"
      fill="currentColor"
    />
    <path
      d="M2.09474 3.47889C1.96657 3.47889 1.8439 3.45685 1.72959 3.41614V6.46073C1.8439 6.42037 1.96657 6.39797 2.09474 6.39797C2.22274 6.39797 2.34541 6.42037 2.45972 6.46073V3.41632C2.34559 3.45685 2.22274 3.47889 2.09474 3.47889Z"
      fill="currentColor"
    />
    <path
      d="M2.45972 2.95411C2.88478 2.80371 3.18931 2.39856 3.18931 1.92194C3.18931 1.44515 2.8846 1.04 2.45972 0.889597C2.34541 0.849242 2.22274 0.82702 2.09474 0.82702C1.96657 0.82702 1.8439 0.849242 1.72959 0.889597C1.30453 1.04 1 1.44515 1 1.92194C1 2.39856 1.30471 2.80371 1.72959 2.95411C1.8439 2.99464 1.96657 3.01686 2.09474 3.01686C2.22274 3.01686 2.34558 2.99464 2.45972 2.95411Z"
      fill="currentColor"
    />
    <path
      d="M1.72977 6.92295C1.3047 7.07335 1.00017 7.4785 1.00017 7.95529C1.00017 8.43191 1.30488 8.83706 1.72977 8.98746C1.84408 9.02781 1.96674 9.05021 2.09492 9.05021C2.22292 9.05021 2.34558 9.02781 2.45989 8.98746C2.88495 8.83706 3.18948 8.43191 3.18948 7.95529C3.18948 7.4785 2.88477 7.07335 2.45989 6.92295C2.34558 6.88259 2.22292 6.86037 2.09492 6.86037C1.96674 6.86055 1.8439 6.88277 1.72977 6.92295Z"
      fill="currentColor"
    />
    <path
      d="M1.72977 13.0457C1.3047 13.1961 1.00017 13.6014 1.00017 14.0781C1.00017 14.5547 1.30488 14.96 1.72977 15.1104C1.84408 15.1508 1.96674 15.1728 2.09492 15.1728C2.22292 15.1728 2.34558 15.1508 2.45989 15.1104C2.88495 14.96 3.18948 14.5547 3.18948 14.0781C3.18948 13.6014 2.88477 13.1961 2.45989 13.0457C2.34558 13.0054 2.22292 12.9831 2.09492 12.9831C1.96674 12.9831 1.8439 13.0054 1.72977 13.0457Z"
      fill="currentColor"
    />
    <path
      d="M10.8526 12.2534H6.03563C5.85341 12.2534 5.67065 12.3264 5.52488 12.4722L4.17467 13.8226C4.02854 13.9684 4.02854 14.1874 4.17467 14.3334L5.52488 15.6837C5.67065 15.8295 5.85341 15.9026 6.03563 15.9026H10.8526C11.0715 15.9026 11.2176 15.7566 11.2176 15.5376V12.6184C11.2176 12.3993 11.0717 12.2534 10.8526 12.2534Z"
      fill="currentColor"
    />
    <path
      d="M5.52488 3.52761C5.67065 3.67356 5.85341 3.74663 6.03563 3.74663H11.2174C11.4363 3.74663 11.5822 3.6005 11.5822 3.38148V0.462401C11.5822 0.243559 11.4365 0.0974273 11.2174 0.0974273H6.03563C5.85341 0.0974273 5.67065 0.170493 5.52488 0.316269L4.17467 1.66665C4.02854 1.81261 4.02854 2.03163 4.17467 2.1774L5.52488 3.52761Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
