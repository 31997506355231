import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import { createUserRole } from "../../Actions/Setting/Permission";
import { createAnAccount } from "../../Actions/User";
import { SYSTEM_ROLE_TYPE } from "../../constant/System";

let tempUserStore = (set) => ({
  isModalOpen: false,
  isSubmitting: false,
  onToggleModal: () =>
    set((state) => {
      return { isModalOpen: !state.isModalOpen };
    }),

  createAccount: (formData) => {
    set({ isSubmitting: true });

    return new Promise((resolve, reject) => {
      createAnAccount({
        email: formData.email,
        jobTitleId: formData.jobTitleId,
        displayName: formData.name,
        givenName: formData.name,
        surname: formData.name,
        invitedBy: formData.invitedBy,
        isInvited: true,
      })
        .then((res) => {
          console.log("response temp:", res);
          resolve(res);

          if (!res.isSuccess) {
            return;
          }

          const { id } = res?.result;

          // Assign default user role
          createUserRole({
            userId: id,
            roleId: SYSTEM_ROLE_TYPE.USER,
          })
            .then((res) => {})
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
});

tempUserStore = devtools(tempUserStore);

tempUserStore = persist(tempUserStore, {
  name: "temp_user_store",
  serialize: (state) => btoa(JSON.stringify(state)),
  deserialize: (str) => JSON.parse(atob(str)),
});

export const useTempUserStore = create(tempUserStore);
