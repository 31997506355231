import React from "react";

import TanStackTable, {
  columnHelper,
  Elements,
} from "../../../../../../../../components/common/TanStackTable";
import { useSettingContext } from "../../../../../../../../shared/context/SettingContext";
import { ViewButtonWrapper } from "../../../../styled";
import ViewSubStagePopover from "../../../../components/ui/ViewSubStagePopover";
import { FlexCenter } from "../../../../../../../../styled";
import { useActivityBlock } from "../../../context/ActivityBlockContext";
import { useQuery, useQueryClient } from "react-query";
import { useAppStore } from "../../../../../../../../../../store/AppStore/AppStore";
import {
  deleteStages,
  getAllStagesDataSet,
} from "../../../../../../../../api/schedule";
import Alert from "../../../../../../../../../../utility/alert";

export const StagesListKey = "stage_list";

function StagesTable() {
  // const { projectId, tenantId } = useSettingContext();
  const { StageModal } = useActivityBlock();
  const hideLoader = useAppStore((state) => state.hideLoader);
  const showLoader = useAppStore((state) => state.showLoader);
  const queryClient = useQueryClient();

  const columns = [
    columnHelper.accessor("name", {
      header: "Production Stages",
      size: 300,
      cell: ({ row, getValue }) => {
        return getValue();
      },
    }),

    // columnHelper.accessor("subStages", {
    //   header: () => {
    //     return <FlexCenter>Sub Stages</FlexCenter>;
    //   },
    //   size: 50,
    //   enableSorting: false,
    //   cell: ({ row, getValue }) => {
    //     return (
    //       <FlexCenter>
    //         <ViewSubStagePopover data={getValue()} />
    //       </FlexCenter>
    //     );
    //   },
    // }),

    columnHelper.accessor("id", {
      header: "Action",
      size: 30,
      enableSorting: false,
      cell: ({ row, getValue }) => {
        return (
          <Elements.Actions gap={8}>
            <Elements.ActionButton
              type="edit"
              title="Edit"
              onClick={() => {
                StageModal.open({ ...row?.original, type: "EDIT" } || null);
              }}
            />
            <Elements.ActionButton
              type="delete"
              title="Delete"
              onClick={() => {
                deleteStage(row?.original);
              }}
            />
          </Elements.Actions>
        );
      },
    }),
  ];
  const { data: stageList } = useQuery({
    queryKey: [StagesListKey],
    queryFn: () => getAllStagesDataSet(),
  });
  const deleteStage = (stage) => {
    Alert.confirm({
      allowOutsideClick: false,
      title: "Delete Stage",
      text: "Are you sure you want to delete this Stage?",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log("yes");
        showLoader();
        deleteStages(stage?.id || 0).then((res) => {
          if (res?.isSuccess) {
            queryClient.refetchQueries([StagesListKey]);
            hideLoader();
          } else {
            Alert.confirm({
              allowOutsideClick: false,
              iconHtml: null,
              type: "error",
              title: "Failed to delete!",
              text: res?.displayMessage,
              confirmButtonText: "Got it!",
              showCancelButton: false,
            }).then(() => {});
            hideLoader();
          }
        });
      }
    });
  };

  return (
    <>
      <TanStackTable
        enabledRowsSpace
        isLoading={false}
        columns={columns}
        data={stageList}
      />
      {/* Dialogs */}
    </>
  );
}

export default StagesTable;
