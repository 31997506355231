import React from "react";

import "./styles.scss";
import { styleClass } from "../../../../utility/common";
import CloseIcon from "../../../img/close-circle.png";
import EditIcon from "../../../img/edit.png";
import ReloadIcon from "../../../img/reload.svg";

const ActionButton = (props) => {
  const {
    variant = "EDIT" | "DELETE" | "UPDATE",
    onClick,
    className,
    type = "button",
  } = props;

  const actionButtonVariant = React.useMemo(() => {
    if (variant === "EDIT") {
      return {
        icon: EditIcon,
        title: "Edit",
        cls: "action__button--edit",
      };
    }

    if (variant === "DELETE") {
      return {
        icon: CloseIcon,
        title: "Delete",
        cls: "action__button--delete",
      };
    }

    if (variant === "UPDATE") {
      return {
        icon: ReloadIcon,
        title: "Update",
        cls: "action__button--update",
      };
    }

    return null;
  }, [variant]);

  if (!actionButtonVariant) {
    return null;
  }

  const { icon, title, cls } = actionButtonVariant;

  return (
    <button
      title={title}
      type={type}
      className={styleClass("action__button", cls, className)}
      onClick={onClick}
    >
      <img src={icon} alt={title} />
    </button>
  );
};

export default ActionButton;
