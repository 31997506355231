import React from "react";
import * as Styled from "./styled";
import FieldGroup from "../../../../../../../../common/components/Form/FieldGroup";
import InputField from "../../../../../../../../common/components/Form/InputField";
import DatePickerField from "../../../../../../../../common/components/Form/DatePickerField";
import BinBtn from "../../../../../../../../common/components/icons/Line/Delete/01";
import { Add1 } from "../../../../../../../../common/components/icons/Line";
import {
  onUpdateDoSubmitState,
  useProjectWizardStore,
} from "../../../../store";

import {
  deleteProjectEpisode,
  getProjectEpisodes,
} from "../../../../../API/Project";
import { useAppStore } from "../../../../../../../../store/AppStore/AppStore";
import Alert from "../../../../../../../../utility/alert";

const episode = {
  projectId: 0,
  episodeId: 0,
  episodeNo: 0,
  title: "",
  duration: 0,
  epCode: "",
  txDate: "",
  status: 1,
};

const StepTwo = ({ onSubmitted }) => {
  const hideLoader = useAppStore((state) => state.hideLoader);
  const showLoader = useAppStore((state) => state.showLoader);

  const formShouldSubmit = useProjectWizardStore((state) => state.doSubmit);
  const [episodeList, setEpisodeList] = React.useState([episode]);
  const activeProjectId = useProjectWizardStore(
    (state) => state.activeProjectId
  );
  episode.projectId = activeProjectId;

  React.useEffect(() => {
    if (formShouldSubmit) {
      onSubmitted({ episodeList }, { stepKey: "step_02" });
      onUpdateDoSubmitState();
    }
  }, [episodeList, formShouldSubmit, onSubmitted]);

  React.useEffect(() => {
    showLoader();
    getProjectEpisodes(activeProjectId || 0).then((episodes) => {
      if (episodes?.length > 0) {
        setEpisodeList(episodes);
        hideLoader();
      } else {
        hideLoader();
      }
    });
  }, [activeProjectId, hideLoader, showLoader]);

  const addNewEpisode = () => {
    let ep = {
      projectId: activeProjectId,
      episodeId: 0,
      episodeNo: 0,
      title: "",
      duration: 0,
      epCode: "",
      txDate: "",
      status: 1,
    };
    ep.episodeNo = (episodeList[episodeList.length - 1]?.episodeNo || 0) + 1;

    setEpisodeList([...episodeList, ep]);
  };
  const deleteEpisode = (episode) => {
    if (episode?.episodeId !== 0) {
      Alert.confirm({
        allowOutsideClick: false,
        title: "Delete Episode",
        text: "Are you sure you want to delete this episode?",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          console.log("yes");
          showLoader();
          deleteProjectEpisode(episode?.episodeId || 0).then((res) => {
            if (res?.isSuccess) {
              let episodes = episodeList.filter((e) => e !== episode);
              setEpisodeList(episodes);
              hideLoader();
            } else {
              Alert.confirm({
                allowOutsideClick: false,
                iconHtml: null,
                type: "error",
                title: "Failed to delete!",
                text: res?.displayMessage,
                confirmButtonText: "Got it!",
                showCancelButton: false,
              }).then(() => {});
              hideLoader();
            }
          });
        }
      });
    } else {
      let episodes = episodeList.filter((e) => e !== episode);
      setEpisodeList(episodes);
    }
  };
  const updateFieldChanged = (index, filed) => (e) => {
    let newArr = [...episodeList];

    if (filed === "episodeNo") {
      newArr[index].episodeNo = Number(e.target.value);
    }
    if (filed === "title") {
      newArr[index].title = e.target.value;
    }
    if (filed === "duration") {
      newArr[index].duration = Number(e.target.value);
    }
    if (filed === "epCode") {
      newArr[index].epCode = e.target.value;
    }

    setEpisodeList(newArr);
    console.log("episodeList", episodeList);
  };
  const onTXDateChange = (index, val) => {
    let newArr = [...episodeList];
    newArr[index].txDate = val;
    setEpisodeList(newArr);
  };
  return (
    <Styled.Container>
      <div className="header">
        <div className="card__header">Number of Episodes</div>
        <div className="card__number">
          <input
            type="lable"
            className="card__input"
            placeholder="0"
            readOnly="true"
            value={episodeList?.length || 0}
          />
        </div>
      </div>
      <div className="table__container">
        <div>
          <table>
            <colgroup>
              <col width="116px" />
              <col width="28%" />
              <col width="92px" />
              <col width="auto" />
              <col width="auto" />
              <col width="28px" />
            </colgroup>
            <thead>
              <tr>
                <th className="table__head">Ep. Number</th>
                <th className="table__head">Episode Title</th>
                <th className="table__head">Duration</th>
                <th className="table__head">TX Date</th>
                <th className="table__head">EP Code</th>
                <th className="table__head"></th>
              </tr>
            </thead>

            <tbody>
              {episodeList.map(function (data, index) {
                return (
                  <tr className="field__row">
                    <td className="field__input">
                      <FieldGroup>
                        <InputField
                          name="Ep.Number"
                          placeholder="Ep.Number"
                          value={data?.episodeNo}
                          onChange={updateFieldChanged(index, "episodeNo")}
                        />
                      </FieldGroup>
                    </td>
                    <td className="field__input">
                      <FieldGroup>
                        <InputField
                          name="Episode Title"
                          placeholder="Episode Title"
                          value={data?.title}
                          onChange={updateFieldChanged(index, "title")}
                        />
                      </FieldGroup>
                    </td>
                    <td className="field__input ">
                      <FieldGroup>
                        <InputField
                          name="Duration"
                          placeholder="min"
                          value={data?.duration}
                          onChange={updateFieldChanged(index, "duration")}
                        />
                      </FieldGroup>
                    </td>
                    <td className="field__input">
                      <FieldGroup>
                        <DatePickerField
                          name="tx_date"
                          placeholder="TX Date"
                          value={data?.txDate}
                          onDateSelect={(val) => onTXDateChange(index, val)}
                        />
                      </FieldGroup>
                    </td>
                    <td className="field__input">
                      <FieldGroup>
                        <InputField
                          name="EP Code"
                          placeholder="EP Code"
                          value={data?.epCode}
                          onChange={updateFieldChanged(index, "epCode")}
                        />
                      </FieldGroup>
                    </td>
                    <td className="btn__td">
                      <button
                        className="bin__btn"
                        onClick={() => deleteEpisode(data)}
                      >
                        <span className="btn__content">
                          <BinBtn className="bin__btn__svg" />
                        </span>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="add__btn__container">
            <button className="add__btn" onClick={addNewEpisode}>
              <Add1 className="add__logo" />
              Add New Episode
            </button>
          </div>
        </div>
      </div>
    </Styled.Container>
  );
};

export default StepTwo;
