import React from "react";
// import { useSettingContext } from "../../../../../../../../shared/context/SettingContext";
import { Modal } from "./styled";
import Form from "./Form";
import { useActivityBlock } from "../../../context/ActivityBlockContext";
import { useAppStore } from "../../../../../../../../../../store/AppStore/AppStore";
import { useQueryClient } from "react-query";
import { MileStoneListKey } from "../../Tables/MilestoneTable/index";
import { saveActivity } from "../../../../../../../../api/schedule";

const MilestoneModal = ({ ...otherProps }) => {
  // const { projectId, variant } = useSettingContext();
  const { milestoneModalData, MilestoneModal: MModal } = useActivityBlock();
  const hideLoader = useAppStore((state) => state.hideLoader);
  const showLoader = useAppStore((state) => state.showLoader);
  const queryClient = useQueryClient();

  const isEditMode = milestoneModalData.payload?.type === "EDIT";

  const isOpen = milestoneModalData.visibility;

  const renderModalTitle = React.useMemo(() => {
    if (!isEditMode) {
      return "Add Milestone";
    }

    return "Edit Milestone";
  }, [isEditMode]);

  const handleSubmit = (val, actions) => {
    console.log("val", val);
    console.log("isEditMode", isEditMode);
    console.log(
      "milestoneModalData.payload?.data",
      milestoneModalData.payload?.data
    );

    showLoader();
    saveActivity(
      isEditMode ? milestoneModalData?.payload?.data?.itemType?.itemTypeId : 0,
      val,
      milestoneModalData?.payload?.data || null
    ).then(() => {
      queryClient.refetchQueries([MileStoneListKey]);
      MModal.close();
      hideLoader();
    });
  };

  return (
    <Modal
      //   isLoading={false}
      title={renderModalTitle}
      subTitle="Manage your Milestone specific details here"
      visibility={isOpen}
      body={<BodyComponent handleSubmit={handleSubmit} />}
      onClose={MModal.close}
      {...otherProps}
    />
  );
};

function BodyComponent({ handleSubmit, formData }) {
  return <Form onSubmit={handleSubmit} />;
}

export default MilestoneModal;
