import React from "react";
import BaseDashboardBlock from "../../BaseDashboardBlock";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const now = new Date();
const currentYear = now.getFullYear();
const currentMonth = now.getMonth();
const currentDate = now.getDate();

const TimeLineWidget = () => {
  return (
    <BaseDashboardBlock blockSpan={2} blockClassName="timeline">
      <div className="timeline_canvas">
        <div className="year_wrapper">{currentYear}</div>
        <div className="month_wrapper">
          {months?.map((month, i) => {
            // current

            let percentage = 0;

            if (i === currentMonth) {
              let dates = new Date(currentYear, currentMonth + 1, 0);
              dates = dates.getDate();
              percentage = (currentDate / dates) * 100;
            }

            if (currentMonth > i) {
              percentage = 100;
            }

            return (
              <div
                className={[
                  "month",
                  i === currentMonth ? "currentMonth" : "",
                ].join(" ")}
                key={i}
              >
                <div className="month_progress">
                  {percentage > 0 && (
                    <div
                      className={[
                        "current_progress",
                        Math.floor(percentage) === 100 && currentMonth !== i && "full",
                      ].join(" ")}
                      style={{ width: `${Math.floor(percentage)}%` }}
                    />
                  )}
                </div>
                <div className="month_title">{month}</div>
              </div>
            );
          })}
        </div>
      </div>
    </BaseDashboardBlock>
  );
};

export default TimeLineWidget;
