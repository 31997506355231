import React from "react";
import Popover from "../../common/components/Popover";
import Menu, { MenuList, Item, SubMenu, SubItem } from "./ContextMenuUi";
import { ItemDivide } from "./styled";

export const useContextMenu = ({ options = [] }) => {
  const [items, setItems] = React.useState(options || []);
  const [element, setElement] = React.useState(null);

  const show = React.useCallback(({ anchorEle = null, options = [] }) => {
    if (options.length > 0) {
      setItems((prev) => [...prev, options]);
    }

    setElement(anchorEle);
  }, []);

  const handleOutsideClick = React.useCallback(() => {
    setElement(null);
    setItems(options);
  }, [options]);

  React.useEffect(() => {
    setItems(options);
  }, [options]);

  const handleClick = React.useCallback(
    (callback, payload) => {
      callback && callback(payload);
      handleOutsideClick();
    },
    [handleOutsideClick]
  );

  const contextMenu = React.useMemo(() => {
    const payloads = (item) => {
      return {
        isSelected:
          item?.isSelected !== undefined ? !item?.isSelected : item?.isSelected,
      };
    };

    return (
      element &&
      items.length > 0 && (
        <Popover
          anchorEl={element}
          onOutsideClick={handleOutsideClick}
          placement="right-start"
        >
          <Menu>
            <MenuList>
              {items.map((item, i) => {
                if (item?.type === "divider") {
                  return <ItemDivide />;
                }

                return (
                  <Item
                    key={i}
                    type={item?.type}
                    label={item?.label}
                    hasChild={item?.child?.length > 0}
                    isSelected={item?.isSelected}
                    isDisabled={item?.isDisabled}
                    onClick={() => {
                      handleClick(item?.onClick, payloads(item));
                    }}
                  >
                    {item?.child && (
                      <SubMenu>
                        {item?.child.map((subItem, ii) => {
                          return (
                            <SubItem
                              key={ii}
                              {...subItem}
                              onClick={() => {
                                handleClick(
                                  subItem?.onClick,
                                  payloads(subItem)
                                );
                              }}
                            />
                          );
                        })}
                      </SubMenu>
                    )}
                  </Item>
                );
              })}
            </MenuList>
          </Menu>
        </Popover>
      )
    );
  }, [element, handleOutsideClick, items, handleClick]);

  return {
    show,
    contextMenu,
  };
};
