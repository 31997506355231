import styled from "styled-components";

export const StyledBaseButton = styled.button`
  border: none;
  box-shadow: none;
  outline: none;
  transition: ${({ theme }) => theme.animate.primary};
  white-space: nowrap;
`;

export const StyledBoxButton = styled(StyledBaseButton)`
  width: var(--layout-size-24);
  height: var(--layout-size-24);
  padding: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  .icon {
    margin: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 1em;
    height: 1em;
  }

  &.is--disabled,
  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
`;

export const StyledIconButton = styled(StyledBoxButton)`
  --cta-icon-btn-bg-color: transparent;
  --cta-icon-btn-size: ${({ theme }) => theme.sizes(7)};
  --cta-icon-btn-radius: 4px;

  width: var(--cta-icon-btn-size);
  height: var(--cta-icon-btn-size);
  background-color: var(--cta-icon-btn-bg-color);
  border-radius: var(--cta-icon-btn-radius);

  &:hover {
    --cta-icon-btn-bg-color: ${({ theme }) =>
      theme.actionColors.action_color_5(30)};
  }
`;
