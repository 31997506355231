import styled from "styled-components";

export const ControlWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;
  padding-top: var(--layout-spacing-8);

  .txScrollbarContainer {
    scrollbar-width: thin;
    scrollbar-color: var(--layout-background-n50) rgba(0, 0, 0, 0);
  }

  #txDocumentEditorContainer,
  #txTemplateDesignerContainer {
    background-color: var(--layout-background-n10) !important;
  }
`;
