import React from "react";
import * as Styled from "./styled";
import ModalContent from "./components/ModalContent";
import {
  LegendModal,
  setLegendScheduleItemTypes,
  setSelectedColumnType,
  useSchedulerStore,
} from "../../../store/SchedulerStore";
import { useAppStore } from "../../../../../../../store/AppStore/AppStore";
import { getAllScheduleLegend } from "../../../../../API/common";

const _animationContainer = {
  visible: {
    x: 0,

    transition: {
      ease: "easeInOut",
      duration: 0.5,
      staggerChildren: 0.07,
      delayChildren: 0.1,
      when: "beforeChildren",
    },
  },
  hidden: {
    x: "30%",

    transition: {
      ease: "easeInOut",
      duration: 0.5,
      when: "afterChildren",
    },
  },
};

const SchedulerLegendsModal = () => {
  const hideLoader = useAppStore((state) => state.hideLoader);
  const showLoader = useAppStore((state) => state.showLoader);
  const legendModalState = useSchedulerStore((state) => state.legendModalState);

  React.useEffect(() => {
    if (legendModalState.visibility) {
      showLoader();
      getAllScheduleLegend().then((response) => {
        setLegendScheduleItemTypes(response);
        if (legendModalState.payload?.columnTypeId) {
          const selectedColmnType = response?.find(
            (columnType) =>
              columnType.columnTypeId === legendModalState.payload?.columnTypeId
          );
          setSelectedColumnType({
            label: selectedColmnType?.columnName || "",
            value: selectedColmnType?.columnTypeId || 0,
          });
        }

        hideLoader();
      });
    }
  }, [
    hideLoader,
    legendModalState.payload?.columnTypeId,
    legendModalState.visibility,
    showLoader,
  ]);

  return (
    <Styled.Modal
      visibility={legendModalState.visibility}
      portalElement="main__container"
      variants={_animationContainer}
      onRootClose={LegendModal.close}
      // isLoading={true}
    >
      <ModalContent />
    </Styled.Modal>
  );
};

export default SchedulerLegendsModal;
