import styled from "styled-components";

export const Wrapper = styled.div`
  font-size: inherit;
  color: inherit;

  .text,
  .text-input {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;

    ::placeholder {
      color: var(--font-tint-01) !important;
    }
  }

  .text {
    white-space: nowrap;
    min-width: 0px;
    display: inline-flex;
    text-overflow: ellipsis;
    overflow: hidden;

    &.no-value {
      opacity: 0.5;
    }
  }

  .text-input {
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid transparent;
    width: 100%;

    &:focus {
      border-color: var(--color-accent-200);
    }
  }
`;
