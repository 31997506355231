import React from "react";

import BreadcrumbLink from "../../../../components/ui-components/Breadcrumb/BreadcrumbLink";
import config from "../config";
import { Outlet } from "react-router-dom";

const children = config.children.tenant.children;

const Permission = React.lazy(() =>
  import("../../screens/Tenant/pages/Permission")
);

const ClientForm = React.lazy(() =>
  import("../../screens/Tenant/pages/Client/Form")
);
const ClientView = React.lazy(() =>
  import("../../screens/Tenant/pages/Client/View")
);
const Company = React.lazy(() => import("../../screens/Tenant/pages/Company"));

export const TenantRoutes = [
  {
    id: children.permission.id,
    path: children.permission.path,
    element: (
      <React.Suspense fallback={<></>}>
        <Permission />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <BreadcrumbLink label={children.permission.label} />,
      config: () => children.permission,
    },
    loader: async () => {
      return true;
      //   return await permissionLoader(children.client.id);
    },
  },

  // Client
  {
    id: children.client.id,
    path: children.client.path,
    element: <Outlet />,
    handle: {
      crumb: () => <BreadcrumbLink label={children.client.label} />,
      config: () => children.client,
    },
    loader: async () => {
      return true;
      //   return await permissionLoader(children.client.id);
    },
    children: [
      {
        id: children.client.children.view.id,
        path: children.client.children.view.path,
        element: (
          <React.Suspense fallback={<></>}>
            <ClientView />
          </React.Suspense>
        ),
      },
      {
        id: children.client.children.form.id,
        path: children.client.children.form.path,
        element: (
          <React.Suspense fallback={<></>}>
            <ClientForm />
          </React.Suspense>
        ),
        // handle: {
        //   crumb: () => <BreadcrumbLink label={children.client.label} />,
        //   config: () => children.client,
        // },
        loader: async () => {
          return true;
          //   return await permissionLoader(children.client.id);
        },
      },
    ],
  },

  // Company
  {
    id: children.company.id,
    path: children.company.path,
    element: (
      <React.Suspense fallback={<></>}>
        <Company />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <BreadcrumbLink label={children.company.label} />,
      config: () => children.company,
    },
    loader: async () => {
      return true;
      //   return await permissionLoader(children.company.id);
    },
  },
];
