import React from "react";

// import { permissionLoader } from "../../../routes/loaders";
import BreadcrumbLink from "../../../../components/ui-components/Breadcrumb/BreadcrumbLink";
import config from "../config";

const Basic = React.lazy(() => import("../../screens/Profile/pages/Basic"));

const MyProfile = React.lazy(() =>
  import("../../screens/Profile/pages/MyProfile")
);

const RateCards = React.lazy(() =>
  import("../../screens/Profile/pages/RateCards")
);

const BusinessRegistration = React.lazy(() =>
  import("../../screens/Profile/pages/BusinessRegistration")
);
const Subscription = React.lazy(() =>
  import("../../screens/Profile/pages/Subscription")
);

const Invoicing = React.lazy(() =>
  import("../../screens/Profile/pages/Invoicing")
);

const SystemPreferences = React.lazy(() =>
  import("../../screens/Profile/pages/SystemPreferences")
);

const Identification = React.lazy(() =>
  import("../../screens/Profile/pages/Identification")
);

const Visa = React.lazy(() => import("../../screens/Profile/pages/Visa"));

const HealthDeclarations = React.lazy(() =>
  import("../../screens/Profile/pages/HealthDeclarations")
);

const children = config.children.profile.children;

export const ProfileRoutes = [
  /**
   * Basic page route
   */
  {
    id: children.basic.id,
    path: children.basic.path,
    element: (
      <React.Suspense fallback={<></>}>
        <Basic />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <BreadcrumbLink label={children.basic.label} />,
      config: () => config.children.profile,
    },
    loader: async () => {
      return true;
      //   return await permissionLoader(children.common.id);
    },
  },

  /**
   *My Profile
   */
  {
    id: children.myProfile.id,
    path: children.myProfile.path,
    element: (
      <React.Suspense fallback={<></>}>
        <MyProfile />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <BreadcrumbLink label={children.myProfile.label} />,
      config: () => config.children.profile,
    },
    loader: async () => {
      return true;
      //   return await permissionLoader(children.common.id);
    },
  },

  /**
   * Identification
   */
  {
    id: children.identification.id,
    path: children.identification.path,
    element: (
      <React.Suspense fallback={<></>}>
        <Identification />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <BreadcrumbLink label={children.identification.label} />,
      config: () => config.children.profile,
    },
    loader: async () => {
      return true;
      //   return await permissionLoader(children.common.id);
    },
  },

  /**
   *Visa Information
   */
  {
    id: children.visaInformation.id,
    path: children.visaInformation.path,
    element: (
      <React.Suspense fallback={<></>}>
        <Visa />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <BreadcrumbLink label={children.visaInformation.label} />,
      config: () => config.children.profile,
    },
    loader: async () => {
      return true;
      //   return await permissionLoader(children.common.id);
    },
  },

  /**
   * Business Registration
   */
  {
    id: children.businessRegistration.id,
    path: children.businessRegistration.path,
    element: (
      <React.Suspense fallback={<></>}>
        <BusinessRegistration />
      </React.Suspense>
    ),
    handle: {
      crumb: () => (
        <BreadcrumbLink label={children.businessRegistration.label} />
      ),
      config: () => config.children.profile,
    },
    loader: async () => {
      return true;
      //   return await permissionLoader(children.common.id);
    },
  },

  /**
   * Subscription
   */
  {
    id: children.subscription.id,
    path: children.subscription.path,
    element: (
      <React.Suspense fallback={<></>}>
        <Subscription />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <BreadcrumbLink label={children.subscription.label} />,
      config: () => config.children.profile,
    },
    loader: async () => {
      return true;
      //   return await permissionLoader(children.common.id);
    },
  },

  /**
   * Invoicing
   */
  {
    id: children.invoicing.id,
    path: children.invoicing.path,
    element: (
      <React.Suspense fallback={<></>}>
        <Invoicing />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <BreadcrumbLink label={children.invoicing.label} />,
      config: () => config.children.profile,
    },
    loader: async () => {
      return true;
      //   return await permissionLoader(children.common.id);
    },
  },
  /**
   * System Preferences
   */
  {
    id: children.systemPreferences.id,
    path: children.systemPreferences.path,
    element: (
      <React.Suspense fallback={<></>}>
        <SystemPreferences />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <BreadcrumbLink label={children.systemPreferences.label} />,
      config: () => config.children.profile,
    },
    loader: async () => {
      return true;
      //   return await permissionLoader(children.common.id);
    },
  },

  /**
   * Health Declarations
   */
  {
    id: children.healthDeclarations.id,
    path: children.healthDeclarations.path,
    element: (
      <React.Suspense fallback={<></>}>
        <HealthDeclarations />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <BreadcrumbLink label={children.healthDeclarations.label} />,
      config: () => config.children.profile,
    },
    loader: async () => {
      return true;
      //   return await permissionLoader(children.common.id);
    },
  },

  /**
   * Rate card page route
   */
  {
    id: children.rateCard.id,
    path: children.rateCard.path,
    element: (
      <React.Suspense fallback={<></>}>
        <RateCards />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <BreadcrumbLink label={children.rateCard.label} />,
      config: () => config.children.profile,
    },
    loader: async () => {
      return true;
      //   return await permissionLoader(children.common.id);
    },
  },
];
