import React from 'react'
import * as Styled from "./styled"

function SkipHoliday() {
  return (
    <Styled.SkipHolidayContainer>
        <div className="text">Skip Holiday</div>
        <div className="text">Set Default Week Start Date</div>
    </Styled.SkipHolidayContainer>
  )
}

export default SkipHoliday