import React from "react";

import './styles.scss';

const SettingSidebar = (props) => {
  const { children } = props;
  return <div className="setting__sidebar">{children}</div>;
};

export default SettingSidebar;
