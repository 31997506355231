import styled from "styled-components";
import { fontStyle } from "../../styled/utility";

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex: 1;

  .notifications {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: var(--layout-spacing-20);
      padding-bottom: var(--layout-spacing-16);
      gap: var(--layout-spacing-4);

      .title {
        ${fontStyle("h6-semi-bold")};
        font-weight: 500;
        color: var(--font-color-shade-accent);
      }
    }

    &__content {
      flex: 1;
      width: 100%;

      overflow-y: auto;
      padding: var(--layout-spacing-20);
      padding-top: 0;

      .items {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: var(--layout-spacing-16);
      }
    }
  }
`;

export const Divider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--font-tint-04);
  ${fontStyle("label-bold")};
  font-weight: 700;
  pointer-events: none;
`;
