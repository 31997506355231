import styled from "styled-components";
import { StyledIconButton } from "../../../../../../styled/Common/Buttons";

export const StyledContentWrapper = styled.div`
  display: block;
  outline: none;
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
  /* cursor: pointer; */
  text-align: left;
  border-top: 1px solid #e9eaed;
  padding-top: var(--layout-spacing-8);

  .start {
    padding-top: 56.25%;
    position: relative;
    overflow: hidden;
    border-radius: var(--rounded-4);
    margin-bottom: var(--layout-spacing-8);

    .inner {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: var(--color-secondary-50);

      .placeholder__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: var(--layout-size-36);
        color: var(--font-tint-04);
      }
    }
  }

  .end {
    .text__x--small {
      color: var(--text-color-light-1);
    }
    .text__large {
      color: var(--text-color-light-2);
    }
  }
`;

export const SwitchBtn = styled(StyledIconButton)`
  display: block;
  position: absolute;
  right: ${({ theme }) => theme.spacing(2)};
  bottom: ${({ theme }) => theme.spacing(2)};
  width: ${({ theme }) => theme.sizes(6)};
  height: ${({ theme }) => theme.sizes(6)};
  z-index: 3;
  background-color: rgb(86 181 188 / 46%);
  backdrop-filter: blur(9px);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgb(86 181 188);
  }
`;
