import * as React from "react";
import { memo } from "react";
const SvgArrowLeft = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 9 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.15493e-08 6L9 0.803846L9 11.1962L7.15493e-08 6Z"
      fill="#A1D5D9"
    />
  </svg>
);
const Memo = memo(SvgArrowLeft);
export default Memo;
