import * as React from "react";
import { memo } from "react";
const SvgEdit = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_958_1590)">
      <path
        d="M15.3999 2.7919L13.2052 0.596374C12.4054 -0.203778 11.0955 -0.198735 10.2857 0.611351L2.1118 8.78832C2.05175 8.8484 2.01188 8.9184 1.99187 8.99834L1.98682 8.99329L0.0220884 15.3548C-0.0329167 15.5298 0.0170436 15.7249 0.147071 15.8549C0.242108 15.95 0.371974 16 0.502002 16C0.551962 16 0.601922 15.995 0.647003 15.98L7.00614 14.0145L7.0011 14.0095C7.08116 13.9844 7.15114 13.9494 7.21103 13.8895L15.3849 5.7125C15.7748 5.32243 15.9949 4.80228 15.9999 4.25219C16.0048 3.7021 15.7898 3.18196 15.3999 2.7919ZM3.10659 14.1746L1.8268 12.8944L2.81675 9.68852C3.57656 9.86858 4.14161 10.5386 4.16161 11.3489C4.16665 11.619 4.38163 11.8341 4.65162 11.8391C5.46156 11.8592 6.13142 12.4242 6.3114 13.1845L3.10659 14.1746Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_958_1590">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgEdit);
export default Memo;
