import styled from "styled-components";
import BaseDocCard from "../../BaseDocCard";

export const DocCard = styled(BaseDocCard)`
  /* height: 100%; */
  border-bottom-right-radius: var(--layout-border-radius-32) !important;
  transition: var(--animate-1);

  ${({ isOpened }) => {
    if (isOpened) {
      return `
      box-shadow: -1px 0 0px 0px var(--color-warning-300),
        -3px 8px 15px 0px rgba(45, 89, 102, 0.08);`;
    }
  }};

  .card__header {
    height: var(--layout-size-24);
  }

  .card__content__duration {
    visibility: hidden;
    opacity: 0;
    transition: var(--animate-1);
  }

  &:hover {
    ${({ isOpened }) => {
      if (!isOpened) {
        return `
          box-shadow: -3px 8px 15px 0px rgba(45, 89, 102, 0.25);
        `;
      }
    }};

    .card__content__duration {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const DocContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 0;
  max-width: 100%;
  gap: var(--layout-spacing-4);

  .end {
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  .text__content {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .text {
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      /* white-space: nowrap;
      min-width: 0;
      display: inline-block;
      max-width: 100%; */
    }
  }

  .card__content__doc__name {
    .text {
      -webkit-line-clamp: 1;
    }
  }
`;
