import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { styleClass } from "../../../../../../utility/common";

import "./styles.scss";

const animationContainer = {
  visible: {
    opacity: 1,
    height: "auto",
    transition: {
      staggerChildren: 0.07,
      delayChildren: 10,
      when: "beforeChildren",
    },
  },
  hidden: {
    height: 0,
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
};

const SettingAccordion = (props) => {
  const {
    children,
    title,
    onClick,
    actions,
    isExpanded = true,
    hideHeder,
  } = props;

  const [isShow, setIsShow] = React.useState(isExpanded);

  const onToggleHandler = () => {
    setIsShow((prev) => {
      onClick && onClick(!prev);
      return !prev;
    });
  };

  React.useEffect(() => {
    setIsShow(isExpanded);
  }, [isExpanded]);

  return (
    <div className="accordion">

      {!hideHeder ? (
        <div className="accordion__header">
          <div className="left">
            <div
              className={styleClass(
                "btn___expand icon icon-lp-arrow-down-line",
                isShow ? "btn___expand--is-expanded" : ""
              )}
              onClick={onToggleHandler}
            ></div>
            {title && <div className="title">{title}</div>}
          </div>
          <div className="action">{actions}</div>
        </div>
      ) : null}

      <AnimatePresence exitBeforeEnter>
        {isShow ? (
          <motion.div
            key="accordion__body"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={animationContainer}
            className="accordion__body"
          >
            {children}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
};

SettingAccordion.Card = (props) => {
  const { children, className } = props;

  return (
    <div className={styleClass("accordion__body--card", className)}>
      {children}
    </div>
  );
};

export default SettingAccordion;
