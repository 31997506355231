import styled from "styled-components";
import { fontStyle } from "../../../../../../../../styled/utility";

export const TableContainer = styled.div`
  width: 100%;

  .title {
    ${fontStyle("small-medium")};
    font-weight: 500;
    margin-bottom: var(--layout-spacing-8);
  }

  .inner {
    background-color: var(--layout-background-n20);
    border-radius: var(--layout-border-radius-8);
    padding: var(--layout-spacing-16) var(--layout-spacing-8);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const Table = styled.table`
  width: inherit;
  border-collapse: separate;

  .selectField {
    width: 40%;
  }

  .primary {
    text-align: center;
    width: 10%;
  }

  .delete {
    text-align: right;
    width: var(--layout-size-32);

    .icon__button__element {
      --icon-button-icon-color: var(--font-tint-04);
    }
  }

  th,
  td {
    padding-left: var(--layout-spacing-8);
    padding-right: var(--layout-spacing-8);
  }

  th {
    padding-bottom: var(--layout-spacing-4);
    ${fontStyle("small-medium")};
    font-weight: 500;
  }

  td {
    padding-bottom: var(--layout-spacing-16);
  }

  tr:last-child {
    td {
      padding-bottom: 0px;
    }
  }
`;
