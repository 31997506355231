import React from "react";
import { useRouteLoaderData } from "react-router-dom";
import { ROUTE_OPTIONS } from "../../../../../../constant/route-options";
import { SETTING_PERMISSION_MODULES } from "../../../../../../constant/setting_permission_module";
import { Permission } from "../../../../../../helpers/permission";
import { useSettingRoleStore } from "../../../../../../store/Setting/Permission/RoleStore";

import RoleForm from "./RoleForm";
import RolesDetails from "./RolesDetails";
import "./styles.scss";

export const ROLE_VARIANT = "TENANT_WISE" | "PROJECT_WISE";

const Role = (props) => {
  const { variant = undefined } = props;

  const { permission } = useRouteLoaderData(
    ROUTE_OPTIONS.setting.children["tenant-permission"].id
  );

  const moduleType = SETTING_PERMISSION_MODULES.TenantPermission;

  const permissions = new Permission(permission);

  const rolePermission = permissions.findByName(moduleType.Role.key);

  if (!variant) {
    throw new Error("variant props not defined for role");
  }

  const showForm = useSettingRoleStore((state) => state.showForm);
  const setVariant = useSettingRoleStore((state) => state.setVariant);

  React.useEffect(() => {
    setVariant(variant);
  }, [setVariant, variant]);

  return (
    <div className="role__wrapper">
      {showForm &&
      (rolePermission.permission.canAddNew ||
        rolePermission.permission.canEdit) ? (
        <RoleForm />
      ) : null}

      {rolePermission.permission.canView ? <RolesDetails /> : null}
    </div>
  );
};

export default Role;
