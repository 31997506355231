import React from "react";
import { useUserRightStore } from "../../../../../../store/Setting/Permission";

import UserRightForm from "./UserRightForm";
import UserRightTable from "./UserRightTable";

import "./styles.scss";
import { useRouteLoaderData } from "react-router-dom";
import { ROUTE_OPTIONS } from "../../../../../../constant/route-options";
import { SETTING_PERMISSION_MODULES } from "../../../../../../constant/setting_permission_module";
import { Permission } from "../../../../../../helpers/permission";

const UserRight = (props) => {
  const { variant = undefined } = props;

  const { permission } = useRouteLoaderData(
    ROUTE_OPTIONS.setting.children["tenant-permission"].id
  );
  const moduleType = SETTING_PERMISSION_MODULES.TenantPermission;
  const permissions = new Permission(permission);
  const userRightPermission = permissions.findByName(
    moduleType.AssignUserRights.key
  );

  if (!variant) {
    throw new Error("variant props not defined for role");
  }

  const showForm = useUserRightStore((state) => state.showForm);

  const setVariant = useUserRightStore((state) => state.setVariant);

  const onLoadUserOptions = useUserRightStore(
    (state) => state.onLoadUserOptions
  );

  const onLoadModuleOptions = useUserRightStore(
    (state) => state.onLoadModuleOptions
  );

  React.useEffect(() => {
    setVariant(variant);
  }, [setVariant, variant]);

  React.useEffect(() => {
    onLoadUserOptions();
    onLoadModuleOptions();
  }, [onLoadUserOptions, onLoadModuleOptions]);

  return (
    <div className="role__wrapper">
      {/* <RoleRightForm /> */}
      <UserRightForm />

      {userRightPermission.permission.canView ? <UserRightTable /> : null}
    </div>
  );
};

export default UserRight;
