import React from "react";
import {
  Outlet,
  useNavigate,
  useRouteLoaderData,
  useLocation,
} from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

// Modals
import PreviewModal from "./components/common/Modals/PreviewModal";
import TagItemModal from "./components/common/Modals/TagItemModal";

import Toolbar from "./components/common/Toolbar";
import { StyledLayout } from "./styled";
import { ROUTE_OPTIONS } from "../../constant/route-options";

const ResearchBoard = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { permission } = useRouteLoaderData(ROUTE_OPTIONS.research_board.id);

  const [firstLink] = permission.filter((p) => p?.hasPermission);

  React.useEffect(() => {
    if (location?.pathname === ROUTE_OPTIONS.research_board.path) {
      const routes = ROUTE_OPTIONS.research_board;
      navigate(routes?.children["documents"]?.path);
    }
  }, [navigate, location?.pathname]);

  // Old one
  // React.useEffect(() => {
  //   if (location?.pathname === ROUTE_OPTIONS.research_board.path && firstLink) {
  //     const routes = ROUTE_OPTIONS.research_board;
  //     navigate(routes?.children[firstLink?.name]?.path);
  //   }
  // }, [firstLink, navigate, location?.pathname]);

  return (
    <StyledLayout>
      {/* Toolbar */}
      {/* <div className="toolbar__wr">
        <Toolbar />
      </div> */}

      {/* Content */}
      {/* old -> <div className="content__wr"> */}
      <div className="inner__content">
        <DndProvider backend={HTML5Backend}>
          <div className="web__container">
            <Outlet />
          </div>
          <div id="research_backdrop"></div>
        </DndProvider>
      </div>

      {/* Preview Modal */}
      <PreviewModal />

      <TagItemModal />
    </StyledLayout>
  );
};

export default ResearchBoard;
