import React from "react";

// import { permissionLoader } from "../../../routes/loaders";
import BreadcrumbLink from "../../../components/ui-components/Breadcrumb/BreadcrumbLink";
import config from "./config";
import { ChildrenRoutes } from "./children.routes";

const OnboardingRoot = React.lazy(() => import(".."));

export const OnboardingPaths = config;

export const OnboardingRoutes = {
  id: config.id,
  path: config.path,
  element: (
    <React.Suspense fallback={<div>Loading...</div>}>
      <OnboardingRoot />
    </React.Suspense>
  ),
  handle: {
    crumb: () => <BreadcrumbLink label={config.label} to={config.path} />,
  },
  loader: async () => {
    return true;
    // return await permissionLoader(config.id);
  },
  children: ChildrenRoutes,
};
