import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.3227 7.13333L12.7733 6.50667C12.76 6.504 12.7467 6.49867 12.736 6.496C12.7173 6.48533 12.6987 6.47467 12.6773 6.46667C12.6453 6.448 12.6187 6.424 12.5893 6.392L10.5867 4.38667C10.3387 4.14133 10 4 9.65333 4H4.61333C4.60533 4 4.59733 4 4.58933 4.00267C4.576 4.00267 4.56267 4.00267 4.54933 4.00533C4.136 4.02133 3.75467 4.23467 3.52 4.58133L2.376 6.26933C2.30933 6.37067 2.19733 6.42933 2.08 6.42933H0.890667C0.4 6.42933 0 6.82933 0 7.32V9.36C0 9.76 0.256 10.1013 0.637333 10.2133L1.59467 10.496C1.67733 10.5227 1.76 10.536 1.85333 10.536H1.97333C2.16533 11.272 2.83467 11.8027 3.616 11.8027C4.39733 11.8027 5.06667 11.272 5.25867 10.536H10.7627C10.9547 11.2747 11.624 11.808 12.408 11.808C13.192 11.808 13.8667 11.2747 14.0587 10.536H15.1093C15.6 10.536 16 10.136 16 9.64533V7.99733C16 7.58933 15.7227 7.232 15.3227 7.13333ZM4.77333 4.53333H7.18133V6.45067H4.18133C4.13867 6.45067 4.11467 6.424 4.104 6.41067C4.09333 6.39467 4.08 6.36533 4.09333 6.32533L4.77333 4.53333ZM7.584 7.48H7.96C8.10667 7.48 8.22667 7.6 8.22667 7.74667C8.22667 7.89333 8.10667 8.01333 7.96 8.01333H7.584C7.43733 8.01333 7.31733 7.89333 7.31733 7.74667C7.31733 7.6 7.43733 7.48 7.584 7.48ZM10.208 4.76533L11.8907 6.45067H7.71467V4.53333H9.65333C9.85867 4.53333 10.0613 4.61867 10.208 4.76533ZM3.616 11.2693C3.024 11.2693 2.52533 10.8267 2.45333 10.232C2.448 10.1867 2.44533 10.144 2.44533 10.0987C2.44533 9.45333 2.97067 8.928 3.616 8.928C4.26133 8.928 4.784 9.45333 4.784 10.0987C4.784 10.144 4.78133 10.1893 4.776 10.232V10.24C4.70133 10.8293 4.20533 11.2693 3.616 11.2693ZM12.408 11.2747C11.816 11.2747 11.3147 10.8293 11.2427 10.232C11.2373 10.1867 11.2347 10.144 11.2347 10.0987C11.2347 9.45067 11.76 8.92267 12.408 8.92267C13.056 8.92267 13.584 9.45067 13.584 10.0987C13.584 10.144 13.5813 10.1893 13.576 10.232V10.24C13.5013 10.832 13.0027 11.2747 12.408 11.2747Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
