import * as React from "react";
import { memo } from "react";
const SvgIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.3418 2.8535V5.66444C10.1343 5.74037 9.93758 5.83805 9.75409 5.9546L9.45218 5.65622V3.47517H8.20833C7.44695 3.47517 6.82551 2.86124 6.82551 2.10851V0.879432H0.889582V2.23666H5.50802V2.89581H0.889582V3.57237H6.70979V4.23201H0.889582V4.90833H6.70979V5.56772H0.889582V6.24379H5.50802V6.90343H0.889582V7.57975H5.50802V8.23914H0.889582V8.9157H6.64838V9.57509H0.889582V10.2512H6.40396V10.9108H0.889582V13.5208H9.45218V11.362C9.62442 11.7061 9.77244 12.0736 9.84999 12.4919V13.4463C9.84999 13.7965 9.99581 14.1159 10.2299 14.3485L10.285 14.4H0V0H7.45453L10.3418 2.8535ZM11.3953 5.80227C12.8931 5.80227 14.1085 7.00378 14.1085 8.48384C14.1085 10.0082 12.8975 10.8557 12.6129 12.4632V13.4463C12.6129 13.9694 12.1769 14.4 11.6482 14.4H11.1423C10.6126 14.4 10.1778 13.9701 10.1778 13.4463V12.4649C9.89281 10.852 8.68174 10.0258 8.68174 8.48384C8.68174 7.00474 9.89843 5.80227 11.3953 5.80227ZM11.3953 8.90821C11.635 8.90821 11.8293 9.10019 11.8293 9.3374C11.8293 9.49385 11.7444 9.63119 11.6177 9.70591V11.1047H11.1726V9.70591C11.0461 9.63119 10.9612 9.49385 10.9612 9.3374C10.9612 9.10019 11.1555 8.90821 11.3953 8.90821ZM14.8873 5.51549L13.7839 6.60601L13.3119 6.13958L14.4156 5.04905L14.8873 5.51549ZM9.00641 6.60626L7.90324 5.51573L8.37494 5.0493L9.47835 6.13982L9.00641 6.60626ZM16 8.8301H14.4396V8.17047H16V8.8301ZM8.35072 8.8301H6.79028V8.17047H8.35072V8.8301ZM11.0613 5.49155V3.9491H11.7287V5.49155H11.0613ZM11.7236 12.8723H11.0672V13.4463C11.0672 13.487 11.1012 13.5206 11.1423 13.5206H11.6482C11.6908 13.5206 11.7236 13.4884 11.7236 13.4463V12.8723ZM11.3953 6.68146C10.3875 6.68146 9.57133 7.48762 9.57133 8.48384C9.57133 9.57485 10.6865 10.6869 10.9872 11.9929H11.8031C12.1013 10.6944 13.219 9.55841 13.219 8.48384C13.219 7.4869 12.4037 6.68146 11.3953 6.68146Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgIcon);
export default Memo;
