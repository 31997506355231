import styled from "styled-components";

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  padding: 0;
  background-color: transparent;
  margin-left: var(--layout-spacing-8);

  &:hover {
  }

  .icon {
    --icon-size: var(--layout-size-12);
    --icon-font-size: var(--layout-size-12);
  }
`;
