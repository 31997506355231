import * as React from "react";
import { memo } from "react";
const Svg02 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.00678 12.9075C5.92265 13.9916 4.1809 13.9916 3.09676 12.9075C2.01262 11.8234 2.01264 10.0816 3.09676 8.99748L5.17618 6.93584C5.86934 6.24268 7.00678 6.24268 7.69992 6.93584C8.14424 7.38016 8.87291 7.38016 9.31725 6.93584C9.76158 6.49152 9.76157 5.76285 9.31725 5.31851C7.73547 3.73673 5.14064 3.73673 3.55886 5.31851L1.47959 7.38016C-0.493195 9.35294 -0.493195 12.552 1.47959 14.5248C2.45709 15.5023 3.75451 16 5.05192 16C6.34937 16 7.62898 15.5023 8.62425 14.5248C9.06857 14.0805 9.06857 13.3518 8.62425 12.9075C8.17993 12.4632 7.45112 12.4632 7.00678 12.9075ZM14.5247 1.47959C12.5519 -0.493195 9.35279 -0.493195 7.38001 1.47959C6.93569 1.92391 6.93569 2.65258 7.38001 3.09691C7.82433 3.54125 8.553 3.54123 8.99734 3.09691C10.0815 2.01278 11.8232 2.01278 12.9074 3.09691C13.9915 4.18104 13.9915 5.92279 12.9074 7.00693L10.8279 9.06857C10.1348 9.77949 8.99734 9.77949 8.3042 9.06857C7.85988 8.62426 7.13121 8.62426 6.68687 9.06857C6.24254 9.5129 6.24255 10.2416 6.68687 10.6859C7.48665 11.4857 8.51747 11.8766 9.56606 11.8766C10.6147 11.8766 11.6455 11.4857 12.4453 10.6859L14.5069 8.62425C16.4976 6.65147 16.4976 3.45237 14.5247 1.47959Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg02);
export default Memo;
