import React from "react";
import ReactDOM from "react-dom";
import { Dropdown } from "react-bootstrap";

import {
  StyledDropdownButton,
  StyledDropdownContainer,
  StyledDropMenu,
} from "./styled";

const DropdownMenu = (props) => {
  const { options = [], ...otherProps } = props;

  const renderItems = React.useMemo(() => {
    return options.map((option, i) => {
      const { eventKey, as = "button", onClick, disabled, label } = option;

      return (
        <Dropdown.Item
          key={i}
          eventKey={eventKey}
          as={as}
          onClick={onClick}
          disabled={disabled}
        >
          {label}
        </Dropdown.Item>
      );
    });
  }, [options]);

  if (!options.length) {
    return null;
  }

  return (
    <StyledDropdownContainer>
      <Dropdown align={"end"}>
        <Dropdown.Toggle
          as="div"
          childBsPrefix="cm__dropdown"
          bsPrefix="dropdown-toggles"
          id="hr__options"
        >
          <StyledDropdownButton {...otherProps}>
            <span className="icon icon-lp-dot-menu"></span>
          </StyledDropdownButton>
        </Dropdown.Toggle>

        {/* render list */}
        {options.length > 0
          ? ReactDOM.createPortal(
              <StyledDropMenu popperConfig={{ strategy: "fixed" }}>
                {renderItems}
              </StyledDropMenu>,
              document.body
            )
          : null}
      </Dropdown>
    </StyledDropdownContainer>
  );
};

export default DropdownMenu;
