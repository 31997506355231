import React, {useState} from 'react';
import './style.css';
import UIButton from '../../Buttons/UIButton';
import LogoIcon from '../Logo/LogoIcon';
import PayPalButton from "./PayPalButton";
import {useLocation} from 'react-router-dom';

const PaymentProgress = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const currency = queryParams.get('cc');
    const amount = queryParams.get('amt');
    const transactionId = queryParams.get('tx');
    const status = queryParams.get('st');

    return (<div className="order-summary-container">
        <div className="success-message">

            <div className="logo-icon"><LogoIcon/></div>
            <h2> {status === "COMPLETED" ? "Payment Successful" : "Proceed with the Payment"}</h2>
            <p>{status === "COMPLETED" ? "Thank you for your purchase. Your order is being processed." : "Data-driven entertainment product planner is a few clicks away!"}</p>
        </div>

        <div className="summary-box">
            {status === "COMPLETED" ? <span>
                        <h3>Order Summary</h3>
                        <hr/>
                        <div className="summary-item">
                            <span>Ref</span>
                            <span>{transactionId}</span>
                        </div>
                        <div className="summary-item">
                            <span>Status</span>
                            <span>{status}</span>
                        </div>
                        <div className="summary-item">
                            <span>Amount</span>
                            <span>{amount} {currency}</span>
                        </div>
                        <hr/>
                        <div className="summary-item total">
                            <span>Total Paid</span>
                            <span>{amount} {currency}</span>
                        </div>
                    </span> : <PayPalButton/>}
            <UIButton variant="success" size={"sm"} onClick={() => window.location.href = window.location.origin}>
                Return to Homepage
            </UIButton>
        </div>
    </div>);
};

export default PaymentProgress;
