import React from "react";
import { DividerWrapper } from "./styled";
import { format, intervalToDuration } from "date-fns";

const Divider = ({ date = new Date() }) => {
  const renderDate = React.useMemo(() => {
    const duration = intervalToDuration({
      start: new Date(),
      end: date,
    });

    let formatString = "";

    if (duration?.years > 0) {
      formatString = "iiii, MMMM dd yyyy";
    } else if (duration?.months > 0) {
      formatString = "iiii, MMMM dd";
    } else if (duration?.days > 6) {
      formatString = "iiii, dd";
    } else if (duration?.days > 0) {
      formatString = "iiii";
    }

    return duration?.days === 0 ? "Today" : format(date, formatString);
  }, [date]);

  return (
    <DividerWrapper className="time__divider">
      <div className="time">{renderDate}</div>
    </DividerWrapper>
  );
};

export default Divider;
