import styled from "styled-components";
import Popover from "../../Popover";

export const ColorButton = styled.button`
  --picker-btn-size: var(--layout-size-32);
  --picker-btn-color: ${({ $color = "#000" }) => $color};

  border: none;
  outline: none;
  border-radius: 999px;
  width: var(--picker-btn-size);
  height: var(--picker-btn-size);
  min-width: var(--picker-btn-size);
  min-height: var(--picker-btn-size);
  background-color: var(--picker-btn-color);
  padding: 0;
  margin: 0;
`;

export const ColorPopoverPanel = styled(Popover)`
  margin: var(--layout-spacing-16);

  .color-popover {
    border-radius: var(--layout-border-radius-8);
    background: #fff;
    /* background: var(--color-primary-50, #e6ebed); */
    padding: var(--layout-spacing-16);
    display: flex;
    flex-wrap: wrap;
    gap: var(--layout-spacing-12);
    width: 100%;
    max-width: 200px;
  }
`;

export const ColorBtnItem = styled.button`
  --btn-item-size: var(--layout-size-32);
  --btn-item-color: ${({ $color = "#000" }) => $color};

  border: none;
  outline: none;
  border-radius: 999px;
  width: var(--btn-item-size);
  height: var(--btn-item-size);
  min-width: var(--btn-item-size);
  min-height: var(--btn-item-size);
  background-color: var(--btn-item-color);
  padding: 0;
  margin: 0;
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.9);

  &:hover {
    opacity: 0.8;
  }

  ${({ $isActive }) => {
    if ($isActive) {
      // return `border: 2px solid #000;`;
      return `box-shadow: 0px 0px 0px 2px #fff, 0px 0px 0px 4px #000`;
    }
  }};
`;
