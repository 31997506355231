import React from "react";

import StepOne from "./components/BodyContainer/Forms/StepOne";
import StepTwo from "./components/BodyContainer/Forms/StepTwo";
import StepThree from "./components/BodyContainer/Forms/StepThree";
import StepFour from "./components/BodyContainer/Forms/StepFour";
import StepFive from "./components/BodyContainer/Forms/StepFive";
import StepSix from "./components/BodyContainer/Forms/StepSix";
import WizardModal from "../../../../common/components/UI/Modal/WizardModal";
import UIButton from "../../../../common/components/Buttons/UIButton";

import {
  ModalVisibility,
  getCurrentStep,
  onBeforeNext,
  onNext,
  onPrevious,
  onSkip,
  setActiveProjectId,
  updateStepData,
  updateStepDataProjectId,
  useProjectWizardStore,
} from "./store";
import {
  createProject,
  saveProject,
  addGenresToProject,
  addProjectFeatures,
} from "../API/common";
import { useProjectStore } from "../../../../store/ProjectStore";
import { initCalenderBaseOnProject } from "../../../../pages/Scheduler/API/common";
import useUserData from "../../../../hooks/useUserData";
import { toast } from "react-toastify";
import { useGlobalStore } from "../../../../store/GlobalStore";
import { SYSTEM_ROLE_TYPE } from "../../../../constant/System";
import { useAppStore } from "../../../../store/AppStore/AppStore";
import Alert from "../../../../utility/alert";
import { saveProjectEpisodes, saveProjectSpecs } from "../API/Project";

const ProjectWizardModal = () => {
  const { tenantId, userId, userRoles } = useUserData();
  const isVisible = useProjectWizardStore((state) => state.isVisible);
  const steps = useProjectWizardStore((state) => state.steps);
  const currentprojectId =
    useGlobalStore((state) => state.selectedProjectId) || 0;
  const activeStep = useProjectWizardStore(
    (state) => state.currentActiveStepKey
  );
  const currentStep = getCurrentStep();
  const getProjectsByTenant = useProjectStore(
    (state) => state.getProjectsByTenant
  );
  const getProjectsByTenantId = useProjectStore(
    (state) => state.getProjectsByTenantId
  );

  const getProjectsByTenantAndUserId = useProjectStore(
    (state) => state.getProjectsByTenantAndUserId
  );
  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);

  const activeProjectId = useProjectWizardStore(
    (state) => state.activeProjectId
  );

  const errorAlert = ({ text }) => {
    return Alert.confirm({
      allowOutsideClick: false,
      title: "Error!",
      text: text,
      confirmButtonText: "OK",
      icon: "error",
      showCancelButton: false,
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("yes");
      }
    });
  };

  const updateProjectList = React.useCallback(() => {
    if (userRoles?.find((u) => u === SYSTEM_ROLE_TYPE.ADMIN)) {
      getProjectsByTenant();
      getProjectsByTenantId(tenantId);
      return;
    }
    getProjectsByTenantAndUserId(tenantId, userId);
  }, [
    getProjectsByTenant,
    getProjectsByTenantAndUserId,
    getProjectsByTenantId,
    tenantId,
    userId,
    userRoles,
  ]);

  const handleSubmit = React.useCallback(
    (value, options) => {
      value = { ...value, currentUserProjectId: +currentprojectId || 0 };
      console.log("Done", value, options?.stepKey);

      if (options?.stepKey) {
        switch (options?.stepKey) {
          case "step_01": {
            showLoader();
            updateStepData(options?.stepKey, value);
            if (value?.projectId === 0) {
              //Create Project
              createProject(value).then((result) => {
                if (result && result.message) {
                  errorAlert({ text: result.message });
                } else {
                  initCalenderBaseOnProject(
                    result?.projectId,
                    value?.startDate || null,
                    result?.endDate || null
                  );
                  setActiveProjectId(result?.projectId || 0);
                  updateStepDataProjectId(
                    options?.stepKey,
                    result?.projectId || 0
                  );
                  addGenresToProject(value, result?.projectId || 0);
                  updateProjectList();
                  onNext();
                }
                hideLoader();
              });
            }
            if (value?.projectId !== 0) {
              //Update Project
              saveProject(value).then((result) => {
                if (result && result.message) {
                  errorAlert({ text: result.message });
                } else {
                  initCalenderBaseOnProject(
                    value?.projectId || 0,
                    value?.startDate || null,
                    result?.endDate || null
                  );
                  setActiveProjectId(result?.projectId || 0);
                  updateStepDataProjectId(
                    options?.stepKey,
                    result?.projectId || 0
                  );
                  addGenresToProject(value, result?.projectId || 0);
                  updateProjectList();
                  onNext();
                }
                hideLoader();
              });
            }
            break;
          }

          case "step_02": {
            if (
              value?.episodeList.length === 1 &&
              value?.episodeList[0]?.episodeNo === 0 &&
              value?.episodeList[0]?.title === "" &&
              value?.episodeList[0]?.epCode === "" &&
              value?.episodeList[0]?.duration === 0 &&
              value?.episodeList[0]?.txDate === ""
            ) {
              onNext();
              return;
            }
            if (value?.episodeList.length === 0) {
              onNext();
              return;
            }
            if (value?.episodeList.filter((e) => e.txDate === "").length > 0) {
              toast.error(
                "Txt Date undefined!. Make sure you have filled the Tx Date before save the episode."
              );
              return;
            }
            try {
              showLoader();
              saveProjectEpisodes(value?.episodeList).then((result) => {
                if (result?.isSuccess) {
                  console.log("Episode Saved", result?.displayMessage);
                  hideLoader();
                  onNext();
                } else {
                  toast.error(result?.displayMessage);
                  console.log("Episode Not Save", result?.displayMessage);
                  hideLoader();
                }
              });
            } catch {
              hideLoader();
            }

            break;
          }

          case "step_03": {
            console.log("spec", value);

            showLoader();
            saveProjectSpecs(
              activeProjectId,
              value?.format.value,
              value?.delivery.value,
              value?.languge.value
            ).then((result) => {
              if (result?.isSuccess) {
                console.log("Specs Saved", result);
                hideLoader();
                onNext();
              } else {
                toast.error(result?.displayMessage);
                console.log("Specs Not Save", result);
                hideLoader();
              }
            });
            break;
          }

          case "step_04": {
            console.log("Done", value, options?.stepKey);
            onNext();
            break;
          }

          case "step_06": {
            updateStepData(options?.stepKey, value);
            addProjectFeatures(value).then((result) => {
              updateProjectList();
              ModalVisibility.close();
            });
            break;
          }

          default:
            return;
        }
      }
    },
    [
      activeProjectId,
      currentprojectId,
      hideLoader,
      showLoader,
      updateProjectList,
    ]
  );

  const renderSteps = React.useMemo(() => {
    if (!activeStep) return null;

    switch (activeStep) {
      case "step_01": {
        return <StepOne onSubmitted={handleSubmit} />;
      }
      case "step_02": {
        return <StepTwo onSubmitted={handleSubmit} />;
      }
      case "step_03": {
        return <StepThree onSubmitted={handleSubmit} />;
      }
      case "step_04": {
        return <StepFour onSubmitted={handleSubmit} />;
      }
      case "step_05": {
        return <StepFive />;
      }
      case "step_06": {
        return <StepSix onSubmitted={handleSubmit} />;
      }

      default:
        return null;
    }
  }, [activeStep, handleSubmit]);

  return (
    <WizardModal
      steps={steps.filter((step) => step.isEnabled)}
      modalWidth={
        currentStep?.options.modalWidth
          ? currentStep?.options.modalWidth
          : undefined
      }
      visibility={isVisible}
      title={currentStep?.modalTitle}
      bodyTitle={currentStep?.title}
      bodySubTitle={currentStep?.subTitle}
      onClose={ModalVisibility.close}
      bodyFooterSlots={{
        leftComponent: FooterLeftButtons,
        rightComponent: () => <FooterRightButtons />,
      }}
    >
      {renderSteps}
    </WizardModal>
  );
};

function FooterLeftButtons() {
  const actionStatus = useProjectWizardStore((state) => state.actionStatus);

  return (
    <>
      {actionStatus?.hasSkipAndFinish ? (
        <UIButton
          variant={UIButton.Variant.Success}
          size={UIButton.Sizes.Small}
        >
          Skip & Finish Setup
        </UIButton>
      ) : null}

      {actionStatus?.hasPrev ? (
        <UIButton
          variant={UIButton.Variant.Secondary}
          size={UIButton.Sizes.Small}
          // startIcon={<SvgLeft />}
          onClick={onPrevious}
        >
          Previous
        </UIButton>
      ) : null}
    </>
  );
}

function FooterRightButtons() {
  const actionStatus = useProjectWizardStore((state) => state.actionStatus);

  return (
    <>
      {actionStatus?.hasSkip ? (
        <UIButton
          variant={UIButton.Variant.Secondary}
          size={UIButton.Sizes.Small}
          onClick={onSkip}
        >
          Skip
        </UIButton>
      ) : null}

      {actionStatus?.hasNext ? (
        <UIButton
          variant={UIButton.Variant.Primary}
          size={UIButton.Sizes.Small}
          onClick={onBeforeNext}
          type="button"
        >
          Next
        </UIButton>
      ) : null}

      {actionStatus?.hasFinish ? (
        <UIButton
          variant={UIButton.Variant.Primary}
          size={UIButton.Sizes.Small}
          onClick={onBeforeNext}
          type="button"
        >
          Finish Setup
        </UIButton>
      ) : null}
    </>
  );
}

export default ProjectWizardModal;
