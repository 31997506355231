import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import { StyledBoxButton } from "../../../styled/Common/Buttons";

export const StyledDropdownContainer = styled.div`
  display: inline-flex;
`;

export const StyledDropMenu = styled(Dropdown.Menu)`
  border-radius: ${({theme}) => theme.rounded('sm')};
  border-color: #e9ecef;
  box-shadow: 0px 2px 11px 4px rgb(0 0 0 / 12%);
`;

export const StyledDropdownButton = styled(StyledBoxButton)`
  .icon {
    color: ${({ theme }) => theme.colors.color_1(40)};
  }

  &:hover {
    .icon {
      color: ${({ theme }) => theme.colors.color_1(80)};
    }
  }
`;
