import React from "react";
import * as Styled from "./styled";

function BlockItem(props) {
  const { itemName, ...otherProps } = props;

  return (
    <Styled.Item {...otherProps}>
      <div>{itemName}</div>
    </Styled.Item>
  );
}

export default BlockItem;
