import React from "react";

import ActionButton from "../../../../../Buttons/ActionButton";
import TableList from "../../../../../TableList";
import { useUserMenuStore } from "../../../../../../../store/Setting/Permission";
import "./styles.scss";
import { toast } from "react-toastify";
import Alert from "../../../../../../../utility/alert";
import { ROUTE_OPTIONS } from "../../../../../../../constant/route-options";
import { useRouteLoaderData } from "react-router-dom";
import { SETTING_PERMISSION_MODULES } from "../../../../../../../constant/setting_permission_module";
import { Permission } from "../../../../../../../helpers/permission";

const columns = [
  {
    label: "User",
    name: "user",
    sortable: true,
    width: "25%",
  },
  {
    label: "Menus",
    name: "menus",
    // sortable: true,
    width: "60%",
  },
  {
    label: "Action",
    name: "action",
    sortable: false,
    width: "15%",
  },
];

const Table = () => {
  const routeId = ROUTE_OPTIONS.setting.children["tenant-permission"].id;
  const { permission } = useRouteLoaderData(routeId);
  const moduleType = SETTING_PERMISSION_MODULES.TenantPermission;
  const permissions = new Permission(permission);
  const userMenuPermission = permissions.findByName(moduleType.UserMenu.key);

  const data = useUserMenuStore((state) => state.data);
  const onLoadTable = useUserMenuStore((state) => state.onLoadTable);

  const onEdit = useUserMenuStore((state) => state.onEdit);
  const onDelete = useUserMenuStore((state) => state.onDelete);

  React.useEffect(() => {
    onLoadTable();
  }, [onLoadTable]);

  const onDeleteHandler = (id) => {
    Alert.confirm({
      title: "Are you sure?",

      text: "You want to complete this",

      confirmButtonText: "Yes, Complete",
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(id)
          .then((res) => {
            console.log(res);
            // toast.success(res.displayMessage);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };
  const tableRenderRows = (rowData, index) => {
    const { user, menus, action, id } = rowData;

    return (
      <TableList.Row key={index}>
        <TableList.Column width={user.style.width}>
          {user.value.label}
        </TableList.Column>

        <TableList.Column width={menus.style.width}>
          <div className="menus">
            {menus?.value?.map((m, i) => (
              <span key={i} className="menus__item">
                {m.label}
              </span>
            ))}
          </div>
        </TableList.Column>

        <TableList.Column width={action.style.width}>
          {/* Edit */}
          {userMenuPermission.permission.canEdit ? (
            <ActionButton variant="EDIT" onClick={() => onEdit(id.value)} />
          ) : null}

          {/* Delete */}
          {userMenuPermission.permission.canDelete ? (
            <ActionButton
              variant="DELETE"
              onClick={() => onDeleteHandler(id.value)}
            />
          ) : null}
        </TableList.Column>
      </TableList.Row>
    );
  };

  return (
    <div className="setting-details setting__details--role">
      <TableList columns={columns} data={data} renderRows={tableRenderRows} />
    </div>
  );
};

export default Table;
