import API_ENDPOINTS from "../../../../services/api-endpoints";
import API_Scheduler from "../../../../services/api-scheduler";

export const getAllColumnTypes = () => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Scheduler.get(`${endpoints.GET_ALL_COLUMN_TYPES}`)
      .then((res) => {
        console.log("GET_ALL_COLUMN_TYPES :::", res);

        resolve(res.data.result);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const getAllColumnTypesAsOptionSource = () => {
  return new Promise((resolve, reject) => {
    getAllColumnTypes()
      .then((res) => {
        var mapedObjectList =
          res?.map((obj) => ({
            label: obj?.columnName || "",
            value: obj?.columnTypeId || 0,
          })) || [];
        console.log("Column Type options :::", mapedObjectList);

        resolve(mapedObjectList);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
