import React from "react";
import Typography from "../../../../common/components/Typography";
import { useGlobalStore } from "../../../../store/GlobalStore";

import ListView from "./Components/ListView";
import SearchBar from "../../Components/SearchBar";
import * as Styled from "./styled";
import {
  GroupDialog,
  NewIndividualMemberDialog,
  handlerMessageVisibility,
  useChatBaseStore,
  handlerMinimize,
} from "../../Store/ChatBaseStore";
import BaseLayout from "../../Components/BaseLayout";
import ChatItem from "./Components/ChatItem";
import IconButton from "../../../../common/components/Buttons/IconButton";
import { Add } from "../../../../common/components/icons/Fill";
import { debounce } from "lodash";
import {
  getAllChats,
  getExitingIndivudualChats,
  getSearchAllChats,
} from "../../API";
import { useAppStore } from "../../../../store/AppStore/AppStore";

import IconChat from "../../../../common/components/icons/Line/chat";
import { MinimizeButton } from "../../Styled";
import ButtonMinimize from "../../Components/ButtonMinimize";

const ChatsView = (props, ref) => {
  const { visibility, onAnimationDone } = props;

  const projectId = useGlobalStore((state) => state.selectedProjectId);

  let USER = JSON.parse(localStorage.getItem("user"));
  const genaralGroups = useChatBaseStore((state) => state.genaralGroups);
  const memberGroups = useChatBaseStore((state) => state.memberGroups);

  const allChatsData = useChatBaseStore((state) => state.allChats);
  const msgView = useChatBaseStore((state) => state.showMessageView);

  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);

  const createGroup = React.useCallback(() => {
    GroupDialog().open();
  }, []);

  const createMember = React.useCallback(() => {
    NewIndividualMemberDialog().open();
  }, []);

  // const getChatGroups = React.useCallback(() => {
  //   let userId = USER?.result?.userId;
  //   const endpoints = new API_ENDPOINTS();
  //   API_Common.get(
  //     endpoints.GET_ALL_CHAT_GROUPS_BY_PROJECT_AND_USER +
  //       "?projectId=" +
  //       projectId +
  //       "&userId=" +
  //       userId
  //   ).then((response) => {
  //     if (response.data.isSuccess === true) {
  //       //console.log("chat-groups:  ", response.data);
  //       const memberChatdata = response.data.result.filter((element) => {
  //         return element.groupType === 1;
  //       });
  //       const groupChatdata = response.data.result.filter((element) => {
  //         return element.groupType === 2;
  //       });
  //       console.log("chat-groups:  ", groupChatdata);
  //       console.log("member chat-groups:  ", memberChatdata);
  //       setGenaralChatGroups(groupChatdata);
  //       setMemberChatGroups(memberChatdata);
  //     }
  //   });
  // }, [projectId]);

  React.useEffect(() => {
    if (!msgView) {
      //showLoader();
      getAllChats(projectId, USER?.result?.userId).then((result) => {
        //hideLoader();
      });
    }

    // setInterval(function () {
    //   getChatGroups();
    // }, 10000);
  }, [USER?.result?.userId, projectId, msgView, showLoader, hideLoader]);

  // const getChatMembers = React.useCallback(() => {
  //   const endpoints = new API_ENDPOINTS();
  //   API_Common.get(endpoints.GET_ALL_CHAT_MEMBERS).then((response) => {
  //     if (response.data.isSuccess === true) {
  //       console.log("chat-members:  ", response.data);
  //       setChatMembers(response.data.result);
  //     }
  //   });
  // }, [projectId]);

  // React.useEffect(() => {
  //   getChatMembers();
  // }, [getChatMembers]);

  return (
    <>
      <BaseLayout
        ref={ref}
        animationKey="chat_view"
        visibility={visibility}
        isAnimationsEnabled={false}
        header={
          <ChatHeader createGroup={createGroup} onMinimized={handlerMinimize} />
        }
        body={
          <ChatContent
            projectId={projectId}
            userId={USER?.result?.userId}
            allChatsData={allChatsData}
            createGroup={createGroup}
            createMember={createMember}
            chatgroups={genaralGroups}
            memberchatgroups={memberGroups}
          />
        }
        onAnimationDone={onAnimationDone}
      />
    </>
  );
};

function ChatHeader({ onMinimized, createGroup }) {
  return (
    <Styled.ChatHeader>
      <Typography
        // onClick={() => onMinimized((prev) => !prev)}
        variant={Typography.font_variant.small}
        color={Typography.font_color.default_black}
        fontWeight={Typography.font_weight.medium}
        className="head__text"
      >
        <IconButton
          as="div"
          icon={<IconChat />}
          btnIconSize={16}
          btnSize={16}
        />
        <span>Chat</span>
      </Typography>

      <div className="actions">
        <IconButton
          data-tooltip-text="Create new group"
          className="add__button"
          icon={<Add />}
          onClick={createGroup}
        />

        <ButtonMinimize />
      </div>
    </Styled.ChatHeader>
  );
}

function ChatContent({
  // createGroup,
  // createMember,
  projectId,
  userId,
  allChatsData,
  chatgroups,
  memberchatgroups,
}) {
  const [searchValue, setSearchValue] = React.useState("");

  const handleSearch = React.useCallback(
    (value) => {
      console.log("search val::: ", value);
      getSearchAllChats(projectId, userId, value);
    },
    [projectId, userId]
  );

  const searchDebounce = React.useMemo(
    () => debounce(handleSearch, 500),
    [handleSearch]
  );
  const getPerticulerChatData = React.useCallback(
    (data) => {
      console.log("getPerticulerChatData::: ", data);
      if (data?.groupType !== 0) {
        handlerMessageVisibility(true, data);
        return;
      }
      getExitingIndivudualChats(
        projectId,
        data?.userId,
        data?.groupId,
        userId
      ).then((rs) => {
        console.log("getPerticulerChatData responce::", rs);
        handlerMessageVisibility(true, rs);
        return;
      });
    },
    [projectId, userId]
  );
  return (
    <Styled.ChatContent>
      <div className="search__input_wrapper">
        <SearchBar
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
            searchDebounce(e.target.value);
          }}
        />
      </div>

      <ListView>
        <Styled.ChatLists>
          {allChatsData?.map((d, i) => {
            // console.log("allChatsData d: ", d);
            // console.log("allChatsData i: ", i);

            return (
              <ChatItem
                key={i}
                data={{
                  ...d,
                  groupName: d?.groupType !== 0 ? d?.groupName : d?.memberName,
                }}
                onClick={() => getPerticulerChatData(d)}
              />
            );
          })}

          {/* <ChatItem
            data={{
              groupName: "Supun",
            }}
            // onClick={() => handlerMessageVisibility(true, d)}
          />
          <ChatItem
            data={{
              groupName: "Supun",
            }}
            // onClick={() => handlerMessageVisibility(true, d)}
          /> */}
        </Styled.ChatLists>
      </ListView>

      {/* <ListView title="Groups" onAdd={createGroup}>
        <Styled.GroupList> */}
      {/* {chatgroups.map((d, i) => {
            //console.log.log("d: ", d);
            //console.log.log("i: ", i);

            return (
              <GroupItem
                title={d?.groupName}
                onClick={() => handlerMessageVisibility(true, d)}
              />
            );
          })} */}

      {/* <GroupItem
            title="General meeting"
            onClick={() => handlerMessageVisibility(true)}
          /> */}
      {/* <GroupItem
            avatarSrc="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fmeta-l.cdn.bubble.io%2Ff1512936020165x278911292087286720%2FA.png?w=&h=&auto=compress&dpr=1&fit=max"
            title="Production"
          /> */}
      {/* </Styled.GroupList>
      </ListView> */}

      {/* <ListView title="All Members" onAdd={createMember}>
        <Styled.MemberList>
          {memberchatgroups.map((d, i) => {
            console.log("d memberItem: ", d);
            //console.log.log("i: ", i);

            return (
              <MemberItem
                data={d}
                onClick={() => handlerMessageVisibility(true, d)}
              />
            );
          })}
          
        </Styled.MemberList>
      </ListView> */}
    </Styled.ChatContent>
  );
}

export default React.forwardRef(ChatsView);
