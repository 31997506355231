import styled from "styled-components";
import { fontStyle } from "../../../../../../../../styled/utility";

export const Button = styled.button`
  ${fontStyle("label-medium")};
  font-weight: 500;
  display: flex;
  align-items: center;
  color: var(--font-default-color);
  cursor: pointer;
  gap: var(--layout-spacing-4, 4px);
  outline: none;
  background-color: transparent;
  border: none;
  padding: var(--layout-size-4);

  .icon__button__element {
    --icon-button-icon-color: var(--font-tint-04);
  }
`;
