import styled from "styled-components";

export const FormWrapper = styled.div`
  flex: 1;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  .form__controls {
  }

  .form__actions {
    padding-top: var(--layout-spacing-8);
  }
`;
