import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.677333 7.13333L3.22667 6.50667C3.24 6.504 3.25333 6.49867 3.264 6.496C3.28267 6.48533 3.30133 6.47467 3.32267 6.46667C3.35467 6.448 3.38133 6.424 3.41067 6.392L5.41333 4.38667C5.66133 4.14133 6 4 6.34667 4H11.3867C11.3947 4 11.4027 4 11.4107 4.00267C11.424 4.00267 11.4373 4.00267 11.4507 4.00533C11.864 4.02133 12.2453 4.23467 12.48 4.58133L13.624 6.26933C13.6907 6.37067 13.8027 6.42933 13.92 6.42933H15.1093C15.6 6.42933 16 6.82933 16 7.32V9.36C16 9.76 15.744 10.1013 15.3627 10.2133L14.4053 10.496C14.3227 10.5227 14.24 10.536 14.1467 10.536H14.0267C13.8347 11.272 13.1653 11.8027 12.384 11.8027C11.6027 11.8027 10.9333 11.272 10.7413 10.536H5.23733C5.04533 11.2747 4.376 11.808 3.592 11.808C2.808 11.808 2.13333 11.2747 1.94133 10.536H0.890666C0.4 10.536 0 10.136 0 9.64533V7.99733C0 7.58933 0.277333 7.232 0.677333 7.13333ZM11.2267 4.53333H8.81867V6.45067H11.8187C11.8613 6.45067 11.8853 6.424 11.896 6.41067C11.9067 6.39467 11.92 6.36533 11.9067 6.32533L11.2267 4.53333ZM8.416 7.48H8.04C7.89333 7.48 7.77333 7.6 7.77333 7.74667C7.77333 7.89333 7.89333 8.01333 8.04 8.01333H8.416C8.56267 8.01333 8.68267 7.89333 8.68267 7.74667C8.68267 7.6 8.56267 7.48 8.416 7.48ZM5.792 4.76533L4.10933 6.45067H8.28533V4.53333H6.34667C6.14133 4.53333 5.93867 4.61867 5.792 4.76533ZM12.384 11.2693C12.976 11.2693 13.4747 10.8267 13.5467 10.232C13.552 10.1867 13.5547 10.144 13.5547 10.0987C13.5547 9.45333 13.0293 8.928 12.384 8.928C11.7387 8.928 11.216 9.45333 11.216 10.0987C11.216 10.144 11.2187 10.1893 11.224 10.232V10.24C11.2987 10.8293 11.7947 11.2693 12.384 11.2693ZM3.592 11.2747C4.184 11.2747 4.68533 10.8293 4.75733 10.232C4.76267 10.1867 4.76533 10.144 4.76533 10.0987C4.76533 9.45067 4.24 8.92267 3.592 8.92267C2.944 8.92267 2.416 9.45067 2.416 10.0987C2.416 10.144 2.41867 10.1893 2.424 10.232V10.24C2.49867 10.832 2.99733 11.2747 3.592 11.2747Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
