import * as React from "react";
import { memo } from "react";
const SvgLogin = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.3435 3.28081V13.6561C10.3431 13.7801 10.2934 13.899 10.2055 13.9865C10.1176 14.0741 9.99862 14.1233 9.87455 14.1233H5.18703C5.06277 14.1232 4.94363 14.0738 4.85577 13.9859C4.76791 13.898 4.71851 13.7789 4.71842 13.6546V3.28081C4.71851 3.15655 4.76791 3.03741 4.85577 2.94955C4.94363 2.86168 5.06277 2.81228 5.18703 2.81218H9.87455C9.99887 2.81218 10.1181 2.86154 10.206 2.94942C10.294 3.03729 10.3434 3.15649 10.3435 3.28081Z"
      fill="#35569B"
    />
    <path
      d="M10.3435 7.99963V13.6561C10.3434 13.7803 10.294 13.8995 10.2062 13.9873C10.1183 14.0752 9.99916 14.1246 9.8749 14.1247H5.18703C5.06277 14.1246 4.94363 14.0752 4.85577 13.9873C4.76791 13.8995 4.71851 13.7803 4.71842 13.656V7.99963H10.3435Z"
      fill="#1A3459"
    />
    <path
      d="M15.7398 2.86146L10.0835 0.048982C10.012 0.013464 9.93269 -0.00321896 9.85295 0.00051284C9.77322 0.00424464 9.69575 0.0282677 9.62789 0.0703073C9.56004 0.112347 9.50403 0.171011 9.46519 0.240746C9.42634 0.31048 9.40594 0.388977 9.40591 0.468802V15.5314C9.40597 15.6056 9.42362 15.6787 9.45742 15.7447C9.49121 15.8107 9.54018 15.8678 9.60032 15.9112C9.66045 15.9546 9.73002 15.9832 9.80332 15.9945C9.87663 16.0058 9.95158 15.9996 10.022 15.9763L15.6782 14.1018C15.7717 14.0708 15.8531 14.0111 15.9107 13.9313C15.9684 13.8514 15.9994 13.7554 15.9994 13.6569V3.28092C15.9994 3.19369 15.9751 3.10817 15.9292 3.034C15.8832 2.95983 15.8175 2.89995 15.7394 2.8611L15.7398 2.86146Z"
      fill="#B8B8FF"
    />
    <path
      d="M16 7.99963V13.656C16 13.7546 15.969 13.8506 15.9114 13.9304C15.8537 14.0103 15.7724 14.07 15.6789 14.101L10.0226 15.9755C9.95222 15.9988 9.87728 16.005 9.80398 15.9937C9.73067 15.9824 9.6611 15.9538 9.60097 15.9104C9.54084 15.867 9.49186 15.8099 9.45807 15.7439C9.42427 15.6778 9.40662 15.6047 9.40656 15.5306V7.99963H16Z"
      fill="#9381FF"
    />
    <path
      d="M12.2188 7.062V8.93651C12.2188 9.06084 12.1694 9.18009 12.0815 9.268C11.9936 9.35592 11.8744 9.40531 11.75 9.40531C11.6257 9.40531 11.5064 9.35592 11.4185 9.268C11.3306 9.18009 11.2812 9.06084 11.2812 8.93651V7.062C11.2812 6.93766 11.3306 6.81842 11.4185 6.7305C11.5064 6.64258 11.6257 6.59319 11.75 6.59319C11.8744 6.59319 11.9936 6.64258 12.0815 6.7305C12.1694 6.81842 12.2188 6.93766 12.2188 7.062Z"
      fill="#35569B"
    />
    <path
      d="M12.2188 7.99963V8.93724C12.2188 9.06157 12.1694 9.18081 12.0815 9.26873C11.9936 9.35665 11.8744 9.40604 11.75 9.40604C11.6257 9.40604 11.5064 9.35665 11.4185 9.26873C11.3306 9.18081 11.2812 9.06157 11.2812 8.93724V7.99963H12.2188Z"
      fill="#1A3459"
    />
    <path
      d="M7.34358 7.62486L3.59469 4.81239C3.52503 4.7602 3.44223 4.72843 3.35555 4.72063C3.26887 4.71283 3.18173 4.72931 3.10387 4.76822C3.02602 4.80713 2.96053 4.86694 2.91474 4.94095C2.86894 5.01496 2.84464 5.10025 2.84456 5.18729V6.59247H0.468611C0.344356 6.59256 0.225218 6.64196 0.137356 6.72982C0.0494951 6.81769 9.36711e-05 6.93683 0 7.06109V8.93561C9.36711e-05 9.05986 0.0494951 9.179 0.137356 9.26687C0.225218 9.35473 0.344356 9.40414 0.468611 9.40423H2.84385V10.8105C2.84406 10.8975 2.86844 10.9827 2.91427 11.0566C2.96009 11.1305 3.02556 11.1903 3.10336 11.2292C3.18116 11.268 3.26824 11.2845 3.35488 11.2768C3.44151 11.2691 3.52429 11.2374 3.59398 11.1854L7.34287 8.3729C7.40114 8.32925 7.44844 8.27263 7.48101 8.20753C7.51359 8.14242 7.53055 8.07062 7.53055 7.99782C7.53055 7.92502 7.51359 7.85322 7.48101 7.78811C7.44844 7.72301 7.40114 7.66638 7.34287 7.62274L7.34358 7.62486Z"
      fill="#FC7A1E"
    />
    <path
      d="M7.53103 7.99963C7.53125 8.07242 7.51441 8.14424 7.48185 8.20934C7.4493 8.27445 7.40194 8.33103 7.34358 8.37453L3.59469 11.187C3.525 11.239 3.44222 11.2707 3.35559 11.2784C3.26895 11.2862 3.18187 11.2697 3.10407 11.2308C3.02627 11.1919 2.9608 11.1322 2.91498 11.0582C2.86915 10.9843 2.84477 10.8991 2.84456 10.8121V9.40763H0.468613C0.406938 9.40758 0.345879 9.39538 0.28893 9.3717C0.231981 9.34802 0.180261 9.31334 0.136733 9.26964C0.0932042 9.22595 0.0587217 9.1741 0.0352594 9.11706C0.0117972 9.06002 -0.000183931 8.99891 2.13434e-06 8.93724V7.99963H7.53103Z"
      fill="#FF9850"
    />
  </svg>
);
const Memo = memo(SvgLogin);
export default Memo;
