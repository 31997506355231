import styled from "styled-components";
import { fontStyle } from "../../../../../../../../../../../styled/utility";

export const OtherActivitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--layout-spacing-4);

  .titleBlock {
    text-align: center;
    border-radius: var(--border-radius-4);
    padding: var(--layout-spacing-8) var(--layout-spacing-32, 32px);
    background: var(--color-neutral-30, #ebecef);
    color: #404a5f;
    ${fontStyle("x-small-medium")};
  }
`;
