import * as React from "react";
import { memo } from "react";
const SvgReference = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1078_2367)">
      <path
        d="M8.42371 11.1388C8.28252 11.1415 8.14365 11.1018 8.02431 11.0248C7.87174 10.926 7.74716 10.7882 7.66298 10.625C7.57881 10.4618 7.53797 10.2789 7.54454 10.0946C7.54463 9.7787 7.63531 9.46974 7.80537 9.20583C7.97543 8.94192 8.21744 8.7346 8.50154 8.60946L14.9607 4.80476C14.9895 4.78892 15.0135 4.76542 15.0303 4.73675C15.0471 4.70808 15.0559 4.67531 15.0559 4.64192C15.0559 4.60853 15.0471 4.57576 15.0303 4.54709C15.0135 4.51842 14.9895 4.49492 14.9607 4.47908L7.99114 0.366275C7.96875 0.354909 7.94904 0.338719 7.93337 0.318821C7.9177 0.298924 7.90644 0.275793 7.90038 0.251024C7.89431 0.226255 7.89358 0.200438 7.89823 0.175352C7.90289 0.150267 7.91282 0.126512 7.92734 0.105724C7.95366 0.0636252 7.99478 0.0334164 8.04224 0.0213023C8.0897 0.0091883 8.13991 0.0160888 8.18254 0.0405865L15.1368 4.1358C15.2246 4.18626 15.2976 4.25981 15.3482 4.34881C15.3988 4.43781 15.4252 4.53902 15.4245 4.64192C15.4229 4.74408 15.3955 4.84409 15.3451 4.93236C15.2948 5.02064 15.223 5.09423 15.1368 5.14609L8.67954 8.95013C8.12002 9.27582 7.91394 9.58653 7.91394 10.0927C7.90737 10.2075 7.9305 10.3222 7.981 10.4251C8.0315 10.528 8.1076 10.6155 8.20168 10.6789C8.28162 10.7213 8.37138 10.7407 8.46131 10.735C8.55124 10.7293 8.63794 10.6986 8.71208 10.6463L15.2497 6.79538L14.7221 6.43647C14.6818 6.40831 14.6541 6.36497 14.6452 6.31598C14.6362 6.267 14.6466 6.21638 14.6742 6.17526C14.7018 6.13415 14.7443 6.1059 14.7922 6.09674C14.8402 6.08758 14.8898 6.09825 14.9301 6.12641L15.9847 6.82794L8.90285 10.9889C8.75967 11.0829 8.59388 11.1348 8.42371 11.1388Z"
        fill="currentColor"
      />
      <path
        d="M8.03899 11.0065C8.0051 11.0031 7.97239 10.992 7.94329 10.974L0.559669 6.63318C0.492312 6.59547 0.428274 6.55188 0.368268 6.50291V7.33537L0.0958406 6.14335C0.06508 6.08739 0.0435265 6.02665 0.0320402 5.96357C0.00933962 5.8621 -0.00137013 5.75823 0.000140025 5.65416C0.000238355 5.33826 0.0909105 5.02929 0.260971 4.76538C0.431032 4.50147 0.673042 4.29416 0.957146 4.16902L7.99178 0.0236506C8.03465 0.000233592 8.08451 -0.00606812 8.13168 0.00597104C8.17885 0.0180102 8.21995 0.0475296 8.24698 0.0887884C8.26991 0.132556 8.27609 0.183469 8.26429 0.231626C8.2525 0.279783 8.22359 0.32174 8.18318 0.34934L1.14855 4.49536C0.923549 4.58544 0.730255 4.74247 0.593738 4.94608C0.45722 5.14969 0.38378 5.39048 0.382942 5.63723V5.68608C0.389206 5.73582 0.399883 5.78487 0.414843 5.83264C0.441816 5.92621 0.4868 6.01335 0.547162 6.08896C0.607524 6.16457 0.682052 6.22713 0.766383 6.27297L8.15064 10.6138C8.17303 10.6251 8.19274 10.6413 8.20841 10.6612C8.22408 10.6811 8.23534 10.7042 8.2414 10.729C8.24747 10.7538 8.2482 10.7796 8.24354 10.8047C8.23889 10.8298 8.22896 10.8535 8.21444 10.8743C8.20186 10.912 8.17832 10.9448 8.14696 10.9684C8.11561 10.9921 8.07794 11.0053 8.03899 11.0065Z"
        fill="currentColor"
      />
      <path
        d="M11.6048 13.5853C11.5718 13.586 11.5393 13.5772 11.511 13.5599C11.4827 13.5426 11.4597 13.5176 11.4447 13.4876C11.4297 13.467 11.4194 13.4432 11.4145 13.418C11.4096 13.3929 11.4102 13.3669 11.4163 13.342C11.4224 13.3171 11.4338 13.2938 11.4498 13.274C11.4657 13.2541 11.4857 13.2381 11.5085 13.2271L15.2644 11.0078L14.7342 10.6502C14.6939 10.6221 14.6663 10.5787 14.6573 10.5297C14.6483 10.4808 14.6588 10.4301 14.6864 10.389C14.7139 10.3479 14.7564 10.3197 14.8044 10.3105C14.8523 10.3013 14.9019 10.312 14.9422 10.3402L16 11.0411L11.7005 13.5704C11.6702 13.5832 11.6374 13.5883 11.6048 13.5853Z"
        fill="currentColor"
      />
      <path
        d="M8.03899 15.217C8.0051 15.2136 7.97239 15.2025 7.94329 15.1845L0.559669 10.8437C0.492311 10.806 0.428272 10.7624 0.368268 10.7134V11.5452L0.0958406 10.3532C0.06508 10.2972 0.0435265 10.2365 0.0320402 10.1734C0.00939027 10.0722 -0.00131916 9.96852 0.000140025 9.86467C0.000238355 9.54877 0.0909105 9.2398 0.260971 8.97589C0.431032 8.71198 0.673042 8.50467 0.957146 8.37953L1.27615 8.16718C1.29636 8.1519 1.31964 8.14139 1.3443 8.13638C1.36897 8.13138 1.39441 8.13202 1.41881 8.13824C1.44321 8.14447 1.46595 8.15613 1.48541 8.1724C1.50487 8.18866 1.52055 8.20913 1.53135 8.23232C1.54631 8.25295 1.55661 8.27671 1.56151 8.3019C1.56641 8.32708 1.56579 8.35306 1.55969 8.37797C1.55359 8.40288 1.54217 8.4261 1.52623 8.44596C1.5103 8.46583 1.49026 8.48185 1.46755 8.49287L1.14855 8.70521C0.574344 9.04784 0.365078 9.35659 0.365078 9.86402V9.91287C0.371342 9.96261 0.382019 10.0117 0.396978 10.0594C0.423952 10.153 0.468936 10.2401 0.529298 10.3158C0.58966 10.3914 0.664187 10.4539 0.748519 10.4998L8.13278 14.8568C8.15517 14.8682 8.17488 14.8844 8.19055 14.9043C8.20622 14.9242 8.21747 14.9473 8.22354 14.9721C8.2296 14.9969 8.23033 15.0227 8.22568 15.0478C8.22102 15.0728 8.21109 15.0966 8.19658 15.1174C8.18198 15.1473 8.1595 15.1725 8.13167 15.1901C8.10383 15.2077 8.07173 15.217 8.03899 15.217Z"
        fill="currentColor"
      />
      <path
        d="M10.1017 16V14.5018L8.90285 15.2034C8.7711 15.2916 8.61818 15.3413 8.4607 15.3471C8.30322 15.3528 8.14722 15.3144 8.00964 15.2359C7.85706 15.1372 7.73248 14.9993 7.64831 14.8361C7.56413 14.6729 7.52329 14.4901 7.52986 14.3058C7.52996 13.9899 7.62063 13.6809 7.79069 13.417C7.96075 13.1531 8.20276 12.9458 8.48687 12.8206L14.9441 9.01853C14.9729 9.00269 14.9969 8.97918 15.0137 8.95051C15.0305 8.92184 15.0393 8.88908 15.0393 8.85568C15.0393 8.82229 15.0305 8.78953 15.0137 8.76086C14.9969 8.73219 14.9729 8.70868 14.9441 8.69284C14.9047 8.66555 14.877 8.62389 14.8668 8.57642C14.8565 8.52895 14.8644 8.47927 14.8889 8.43758C14.9134 8.3959 14.9526 8.36537 14.9985 8.35227C15.0444 8.33918 15.0934 8.3445 15.1355 8.36715C15.2233 8.41761 15.2964 8.49116 15.347 8.58016C15.3976 8.66916 15.4239 8.77037 15.4232 8.87327C15.4219 8.97576 15.3947 9.07617 15.3443 9.16482C15.2939 9.25347 15.222 9.32737 15.1355 9.37939L8.67699 13.1606C8.45189 13.2508 8.25853 13.4079 8.122 13.6116C7.98548 13.8154 7.9121 14.0563 7.91139 14.3032C7.90482 14.4181 7.92795 14.5327 7.97845 14.6356C8.02895 14.7385 8.10505 14.826 8.19913 14.8894C8.27907 14.9318 8.36883 14.9512 8.45876 14.9455C8.54869 14.9398 8.63539 14.9091 8.70953 14.8568L10.4838 13.829V15.3141L11.3949 14.7591V13.3912C11.3955 13.2592 11.3618 13.1293 11.2972 13.0149C11.2327 12.9005 11.1396 12.8056 11.0274 12.7399C10.988 12.7126 10.9603 12.6709 10.9501 12.6234C10.9398 12.576 10.9477 12.5263 10.9722 12.4846C10.9967 12.4429 11.0359 12.4124 11.0818 12.3993C11.1276 12.3862 11.1767 12.3915 11.2188 12.4142C11.3906 12.514 11.533 12.6589 11.6314 12.834C11.7298 13.009 11.7805 13.2077 11.7783 13.4095V14.9923L10.1017 16Z"
        fill="currentColor"
      />
      <path
        d="M6.90526 7.84084C6.87254 7.84339 6.8397 7.8378 6.80956 7.82455L0.97501 4.51164C0.948767 4.50252 0.924779 4.48769 0.904703 4.46817C0.884627 4.44866 0.868944 4.42492 0.858737 4.3986C0.848531 4.37229 0.844045 4.34403 0.84559 4.31577C0.847135 4.28751 0.854674 4.25993 0.867685 4.23494C0.880697 4.20995 0.89887 4.18814 0.920949 4.17102C0.943027 4.15391 0.968483 4.14189 0.995557 4.13581C1.02263 4.12973 1.05068 4.12972 1.07775 4.13579C1.10483 4.14186 1.13029 4.15387 1.15237 4.17097L7.00223 7.48388C7.02449 7.49536 7.04426 7.51127 7.06039 7.53069C7.07651 7.55011 7.08865 7.57264 7.09608 7.59694C7.10352 7.62123 7.1061 7.64681 7.10368 7.67214C7.10126 7.69748 7.09388 7.72206 7.08198 7.74443C7.06206 7.77359 7.03567 7.79753 7.00495 7.81429C6.97424 7.83104 6.94007 7.84014 6.90526 7.84084Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1078_2367">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgReference);
export default Memo;
