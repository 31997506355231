import * as React from "react";
import { memo } from "react";
const SvgUndo = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.9991 11.5481C16.0028 11.6061 15.9952 11.6643 15.977 11.719C15.9588 11.7738 15.9302 11.8239 15.8931 11.8663C15.8561 11.9087 15.8112 11.9425 15.7615 11.9655C15.7117 11.9885 15.6581 12.0002 15.6039 12C15.5039 11.9989 15.4078 11.9578 15.3347 11.8845C15.2616 11.8113 15.2166 11.7114 15.2087 11.6044C15.1259 9.97492 14.5219 8.42594 13.4987 7.21873C12.4755 6.01152 11.0956 5.21994 9.59173 4.97751C8.0879 4.73508 6.55218 5.05663 5.24357 5.88792C3.93497 6.71922 2.93357 8.0094 2.40828 9.54084L6.9649 8.32551C7.02041 8.31137 7.07806 8.30983 7.13416 8.32099C7.19026 8.33216 7.24356 8.35578 7.29065 8.39034C7.33774 8.42491 7.37759 8.46965 7.40762 8.5217C7.43766 8.57376 7.45723 8.63197 7.46507 8.69261C7.46832 8.79535 7.43873 8.89618 7.38122 8.97839C7.32371 9.06059 7.24174 9.11922 7.1489 9.14454L1.9865 10.5295C1.8434 10.5675 1.69213 10.5446 1.56445 10.4655C1.43676 10.3865 1.34259 10.2574 1.30168 10.1054L0.0110763 4.53701C-0.00210404 4.47744 -0.00353849 4.41557 0.00686485 4.35537C0.0172682 4.29517 0.0392791 4.23797 0.0714875 4.18744C0.103696 4.1369 0.145391 4.09414 0.193898 4.06191C0.242404 4.02968 0.296652 4.00868 0.353163 4.00026C0.448896 3.99677 0.542861 4.02853 0.619462 4.09024C0.696064 4.15196 0.750696 4.23993 0.774293 4.33955L1.82777 8.83375C2.49075 7.19525 3.64936 5.84461 5.11624 5.00024C6.58312 4.15587 8.27252 3.86714 9.91126 4.18072C11.55 4.4943 13.0423 5.39187 14.1468 6.7283C15.2513 8.06472 15.9034 9.76186 15.9978 11.5453L15.9991 11.5481Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgUndo);
export default Memo;
