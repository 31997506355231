import styled from "styled-components";
import { fontStyle } from "../../../../styled/utility";

export const ChipContainer = styled.div`
  background-color: var(--color-accent-50);
  border: 1px solid var(--color-accent-75);
  display: inline-flex;
  align-items: center;
  padding: var(--layout-spacing-8) var(--layout-spacing-16);
  border-radius: var(--layout-border-radius-4);
  margin-right: 8px;

  .chips__close {
    margin-left: var(--layout-spacing-12);
  }

  .chips__btn {
    border: none;
    padding: 0;
    opacity: 0.6;
    transition: opacity 0.3s ease;
    ${fontStyle("small-medium")};
  }

  .chips__btn:hover {
    opacity: 1;
  }
`;
