import React from "react";
import { useNavigate } from "react-router-dom";

import AuthCardLayout from "../../components/AuthLayout";
import SocialLoginContent from "../../components/SocialLoginContent";
import CreateAccountForm from "./CreateAccountForm";
import { ROUTES_PATH } from "../../../../routes/paths";
import { validateUserEmail } from "../../api/create-account";
import { useAppStore } from "../../../../store/AppStore/AppStore";
import { useToastMessage } from "../../../../context/ToastMessageProvider";
import Alert from "../../../../utility/alert";

const signUpData = {
  userName: "",
  email: "",
  password: "",
  title: "",
  jobTitle: 0,
  name: "",
  nickName: "",
  country: 0,
  city: "",
};

localStorage.setItem("usersignup", null);

const CreateAccountUI = () => {
  const navigate = useNavigate();
  const { fireToast } = useToastMessage();
  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);

  const errorAlert = ({ text }) => {
    return Alert.confirm({
      allowOutsideClick: false,
      title: "Error!",
      text: text,
      confirmButtonText: "OK",
      icon: "error",
      showCancelButton: false,
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("yes");
      }
    });
  };

  const handleSubmitForm = (values) => {
    showLoader();

    validateUserEmail(values?.email)
      .then((res) => {
        // Check if occurred any error.
        if (!res.isSuccess) {
          errorAlert({ text: res.result.message });
          // fireToast((toast) => toast.error(res.result.message));
          hideLoader();
          // setFieldError("email", res.result.message);
          return;
        }

        signUpData.email = values?.email;
        signUpData.password = values?.password;
        localStorage.setItem("usersignup", JSON.stringify(signUpData));

        navigate(ROUTES_PATH.PublicRoutePath.CreateAccountDetails.path, {
          replace: true,
          state: {
            email: values?.email,
          },
        });

        hideLoader();
      })
      .catch((err) => {
        errorAlert({ text: err });
        // fireToast((toast) => toast.error(err));
        hideLoader();
      });
  };

  return (
    <AuthCardLayout
      title="Create an Account"
      subTitle="to create, edit or manage projects"
    >
      {/* Login Form */}
      <CreateAccountForm handleSubmitForm={handleSubmitForm} />
      {/* Social Logins */}
      {/* <SocialLoginContent /> */}
    </AuthCardLayout>
  );
};

export default CreateAccountUI;
