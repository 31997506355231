import React from "react";

import Typography from "../../../../../../../../../common/components/Typography";
import * as Styled from "./styled";
import IconButton from "../../../../../../../../../common/components/Buttons/IconButton";

const CardContent = ({ name, templateName, duration, documentIcon }) => {
  return (
    <Styled.DocContent className="card__content">
      <div className="start">
        {documentIcon ? (
          <IconButton as="div" className="doc_icon" icon={documentIcon} />
        ) : null}
      </div>

      <div className="end">
        <Typography
          variant={Typography.font_variant.label}
          fontWeight={Typography.font_weight.medium}
          className="card__content__title text__content"
          data-tooltip-text={name}
        >
          <div className="text">{name}</div>
        </Typography>

        <Typography
          variant={Typography.font_variant.label}
          fontWeight={Typography.font_weight.medium}
          color={Typography.font_color.tint_04}
          className="card__content__doc__name text__content"
          data-tooltip-text={templateName}
        >
          <div className="text">{templateName}</div>
        </Typography>

        {/* <Typography
        variant={Typography.font_variant.xSmall}
        fontWeight={Typography.font_weight.regular}
        className="card__content__duration text__content"
        color={Typography.font_color.tint_04}
        // data-tooltip-text={templateName}
      >
        <div className="text">{duration}</div>
      </Typography> */}
      </div>
    </Styled.DocContent>
  );
};

const DocumentCard = (props) => {
  const {
    name,
    templateName,
    duration,
    isOpened,
    documentIcon,
    className,
    ...otherProps
  } = props;

  return (
    <Styled.DocCard
      isOpened={isOpened}
      className={["document__card", className].join(" ")}
      cardContent={
        <CardContent
          name={name}
          templateName={templateName}
          duration={duration}
          documentIcon={documentIcon}
        />
      }
      {...otherProps}
    />
  );
};

export default DocumentCard;
