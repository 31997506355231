export const PROJECT_PERMISSION_MODULES = {
  NewProjectActions: {
    Edit: {
      key: "edit",
    },

    Delete: {
      key: "delete",
    },

    Features: {
      key: "features",
    },

    Onboarding: {
      key: "onboarding",
    },
    
    ProjectBrief: {
      key: "project_brief",
    },
    ProjectCreateNew: {
      key: "project_create_new"
    },
  },

  Onboarding: {
    AllMembers: {
      key: "all-members",
      actions: {
        AddShortList: {
          key: "add_to_short_list",
        },
        AddTemporaryUser: {
          key: "add_tempory_user",
        },
      },
    },

    OnboardingList: {
      key: "onboarding-list",
    },

    ShortList: {
      key: "short-list",
      actions: {
        AddToProject: {
          key: "add_to_project",
        },
        RemoveFromShortList: {
          key: "remove_from_short_list",
        },
      },
    },

    HRList: {
      key: "hr-list",
    },

    Favorites: {
      key: "favorites",
    },

  },

  ProjectBrief: {
    JoinProject: {
      key: "join_project",
    },
    DeclineProject: {
      key: "decline_project",
    },
  },
};
