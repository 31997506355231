import React from "react";
import BreadcrumbLink from "../../../components/ui-components/Breadcrumb/BreadcrumbLink";
import { ChildrenRoutes } from "./childRoutes";
import config from "./config";
const Budget = React.lazy(() => import(".."));
// import { permissionLoader } from "../../../routes/loaders";

export const BudgetRoutes = {
  id: config.id,
  path: config.path,
  element: (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Budget />
    </React.Suspense>
  ),
  handle: {
    crumb: () => <BreadcrumbLink label={config.label} to={config.path} />,
  },
  children: ChildrenRoutes,
  loader: async () => {
    return true;
    // return await permissionLoader(config.id);
  },
};

export const BudgetPaths = config;
