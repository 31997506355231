import React from "react";
import * as Styled from "./styled";
import { FormGrid } from "../styled";
import InsertUrlList from "../../../../../../../../common/components/Form/InsertUrlList";
import FieldGroup from "../../../../../../../../common/components/Form/FieldGroup";
import {
  onUpdateDoSubmitState,
  useProjectWizardStore,
} from "../../../../store";
import FileUploadManager from "../../../../../../../../common/components/Form/FileUploadManager";

const sectionList = [{ value: "1", label: "Files" }];

const StepFour = ({ onSubmitted }) => {
  const formShouldSubmit = useProjectWizardStore((state) => state.doSubmit);

  const [urls, setUrls] = React.useState([]);
  const [files, setFiles] = React.useState([]);

  React.useEffect(() => {
    if (formShouldSubmit) {
      onSubmitted({ urls, files }, { stepKey: "step_04" });
      onUpdateDoSubmitState();
    }
  }, [formShouldSubmit, onSubmitted, urls, files]);

  return (
    <Styled.Container>
      <FormGrid>
        <div className="grid-item full">
          <FieldGroup label="Attachments" className="field__group">
            <FileUploadManager
              defaultValue={files}
              generalPlaceholderText="Video/Audio/Images"
              uploadSections={sectionList}
              onChange={(payload) => {
                setFiles(payload?.value);
              }}
            />
          </FieldGroup>
        </div>
        <div className="grid-item full">
          <FieldGroup label="Link" className="field__group">
            <div>
              <InsertUrlList
                value={urls}
                onChange={(val) => {
                  setUrls(val);
                }}
              />
            </div>
          </FieldGroup>
        </div>
        <div className="grid-item half"></div>
      </FormGrid>
    </Styled.Container>
  );
};

export default StepFour;
