import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.1599 0H4.85509L2 3.07131V14.6714H7.57994V14.1504H2.521V3.43796L5.23282 3.4308L5.22566 0.521003H12.6389V14.1504H10.8085L10.7004 12.8633C11.1963 12.5487 11.5265 11.9962 11.5265 11.3657C11.5265 10.3862 10.7294 9.59167 9.75253 9.59167C8.77565 9.59167 7.9785 10.3888 7.9785 11.3657C7.9785 11.9952 8.31047 12.5485 8.80724 12.8636L8.5438 16L9.75253 15.453L10.9639 16L10.8523 14.6714H13.1599V0ZM2.85677 2.91573L4.70598 0.925429L4.71181 2.9098L2.85677 2.91573ZM9.75253 10.1127C10.4428 10.1127 11.0055 10.6753 11.0055 11.3657C11.0055 12.056 10.4428 12.6187 9.75253 12.6187C9.06219 12.6187 8.4995 12.056 8.4995 11.3657C8.4995 10.6753 9.06219 10.1127 9.75253 10.1127ZM9.75253 14.8825L9.13773 15.1612L9.31342 13.0823C9.45419 13.1184 9.60078 13.1397 9.75253 13.1397C9.90531 13.1397 10.0528 13.1183 10.1942 13.082L10.3699 15.1586L9.75253 14.8825Z"
      fill="currentColor"
    />
    <path
      d="M11.2604 4.39832L3.89368 4.45618L3.89777 4.97716L11.2645 4.9193L11.2604 4.39832Z"
      fill="currentColor"
    />
    <path
      d="M11.2638 6.15666L3.89707 6.21444L3.90116 6.73543L11.2679 6.67765L11.2638 6.15666Z"
      fill="currentColor"
    />
    <path
      d="M11.2646 7.915L3.89786 7.97278L3.90194 8.49377L11.2687 8.43599L11.2646 7.915Z"
      fill="currentColor"
    />
    <path
      d="M7.58515 9.70107H3.90166V10.2221H7.58515V9.70107Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
