import create from "zustand";
import { devtools, persist } from "zustand/middleware";

let store = (set, get) => ({
  copyText: null,
  exportText: null,
  enablePasteAndExportOptions: false,
});

store = devtools(store, { name: "Text Selection Store" });

store = persist(store, {
  name: "research_text_selection_store",
});

export const useTextSelectionStore = create(store);

export const setCopyTextValue = (value) => {
  useTextSelectionStore.setState({
    copyText: value,
  });
};

export const clearCopyTextValue = () => {
  useTextSelectionStore.setState({
    copyText: null,
  });
};

export const setExportText = (val) => {
  useTextSelectionStore.setState({
    exportText: val,
  });
};

export const enablePasteAndExport = (val) => {
  useTextSelectionStore.setState({
    enablePasteAndExportOptions: val,
  });
};
