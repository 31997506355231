import styled from "styled-components";
import { PageContainer } from "../../../styled/Common/Container";

export const Outer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Container = styled(PageContainer)`
  min-height: inherit;

  &.app-dashboard {
    margin-top: var(--layout-spacing-minus-56);
  }

  .app_db {
    display: flex;
    flex-direction: column;
  }

  .event-pointer {
    cursor: pointer;
  }

  .event-disabled {
    opacity: 0.5;
    cursor: auto;
  }

  .dashboard_wrapper {
    display: flex;
    flex-direction: column;
    padding: 2rem 4rem;
  }

  .dashboard_widgets {
    display: grid;
    gap: var(--layout-spacing-24, 24px);
    grid-auto-columns: 1fr 1fr;
  }
  .dashboard_widgets > * {
    padding: var(--layout-spacing-24, 24px);
    background-color: var(--layout-background-white, #ffffff);
    border-radius: var(--layout-border-radius-8, 8px);
  }

  .db_block.documents {
    border-radius: var(--layout-border-radius-8, 0.5rem);
    background: var(--color-neutral-20, #f5f5f7);
  }
  .db_block .block_title {
    font-family: var(--base-bold-font-family);
    font-size: var(--base-bold-font-size);
    font-weight: var(--base-bold-font-weight);
    font-style: normal;
    line-height: var(--base-bold-line-height);
    color: var(--color-accent-500);
    display: flex;
    align-items: center;
    gap: var(--layout-spacing-12, 12px);
    margin-bottom: var(--layout-spacing-16, 1rem);
  }

  .db_block .block_title .icon__button__element {
    --icon-button-icon-color: var(--color-accent-400);
  }

  .db_block .block_title a {
    text-decoration: none;
    text-align: center;
    width: var(--layout-size-24, 1.5rem);
    height: var(--layout-size-24, 1.5rem);
  }
  .db_block .block_content {
    display: flex;
    flex-direction: column;
  }

  .span-2 {
    grid-column: span 2;
  }
  @media (max-width: 992px) {
    .span-2 {
      grid-column: span 1;
    }
  }

  .notes {
    display: grid !important;
    gap: var(--layout-spacing-16, 1rem);
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 150px), 1fr));
  }
  .notes .note {
    display: inline-flex;
    padding: var(--layout-spacing-16, 16px);
    flex-direction: column;
    min-height: 100px;
    border-radius: var(--layout-border-radius-8, 0.5rem);
    background: var(--color-warning-75, #ffe6a9);
    font-family: var(--x-small-regular-font-family);
    font-size: var(--x-small-regular-font-size);
    font-weight: var(--x-small-regular-font-weight);
    font-style: normal;
    line-height: var(--x-small-regular-line-height);
    box-shadow: 11px 20px 20px 0px rgba(0, 0, 0, 0.11);
  }

  .block_content .tab {
    display: flex;
    flex-direction: row;
    gap: var(--layout-spacing-16, 16px);
    margin-bottom: var(--layout-spacing-12, 0.75rem);
  }
  .block_content .tab .tab_item {
    font-family: var(--x-small-regular-font-family);
    font-size: var(--x-small-regular-font-size);
    font-weight: var(--x-small-regular-font-weight);
    font-style: normal;
    line-height: var(--x-small-regular-line-height);
    padding: 0 0 var(--layout-spacing-16, 1rem) 0;

    &.disabled {
      opacity: 0.5;
    }
  }
  .block_content .tab .tab_item.current {
    font-family: var(--x-small-medium-font-family);
    font-size: var(--x-small-medium-font-size);
    font-weight: var(--x-small-medium-font-weight);
    font-style: normal;
    line-height: var(--x-small-medium-line-height);
    border-bottom: var(--layout-border-width-1, 0.125rem) solid
      var(--color-secondary-300, #ef8b6d);
  }
  .block_content .tab__content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 300px), 1fr));
    gap: var(--layout-spacing-16, 1rem);
  }
  .block_content .tab__content > .item {
    display: flex;
    padding: var(--layout-spacing-12) var(--layout-spacing-16);
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    border-radius: var(--layout-border-radius-8, 0.5rem);
    border: 1px solid var(--color-neutral-30, #ebecef);
    background: var(--color-neutral-10, #fafafb);
    transition: all 300ms ease-in-out;
  }
  .block_content .tab__content > .item:hover {
    border-radius: var(--layout-border-radius-8, 0.5rem);
    border: 1px solid var(--color-white, #fff);
    background: var(--color-info-50, #e9f7fe);
    /* layout/boxShadow/primary/10 */
    box-shadow: -3px 8px 15px 0px rgba(45, 89, 102, 0.08);
  }
  .block_content .tab__content > .item > .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--layout-spacing-2, 0.125rem);
    flex: 1 0 0;
  }
  .block_content .tab__content > .item > .content .project_title {
    color: var(--color-primary-300, #023747);
    font-family: var(--base-bold-font-family);
    font-size: var(--base-bold-font-size);
    font-weight: var(--base-bold-font-weight);
    font-style: normal;
    line-height: var(--base-bold-line-height);
  }
  .block_content .tab__content > .item > .content .milageWrap {
    display: flex;
    align-items: flex-start;
    gap: var(--layout-spacing-12, 0.75rem);
    align-self: stretch;
  }
  .block_content .tab__content > .item > .content .links {
    display: flex;
    align-items: flex-start;
    gap: var(--layout-spacing-12, 0.75rem);
    align-self: stretch;

    .item__link {
      color: var(--color-neutral-400, #4e5673);
      font-family: var(--x-small-medium-font-family);
      font-size: var(--x-small-medium-font-size);
      font-weight: var(--x-small-medium-font-weight);
      font-style: normal;
      line-height: var(--x-small-medium-line-height);
      cursor: pointer;

      &:hover {
        color: (--color-primary-400);
        text-decoration: underline;
      }
    }
  }
  .block_content .tab__content > .item > .content .milageWrap .current_milage {
    color: var(--color-neutral-400, #4e5673);
    font-family: var(--x-small-medium-font-family);
    font-size: var(--x-small-medium-font-size);
    font-weight: var(--x-small-medium-font-weight);
    font-style: normal;
    line-height: var(--x-small-medium-line-height);
  }
  .block_content .tab__content > .item > .content .milageWrap .status {
    color: var(--font-default-color, #061216);
    font-family: var(--x-small-regular-font-family);
    font-size: var(--x-small-regular-font-size);
    font-weight: var(--x-small-regular-font-weight);
    font-style: normal;
    line-height: var(--x-small-regular-line-height);
    padding: 0;
    text-align: left;
    border-radius: 0;
  }
  .block_content .tab__content > .item > .action {
    width: var(--layout-size-16, 1rem);
    height: var(--layout-size-16, 1rem);

    .button__icon {
      --icon-button-icon-color: var(--color-primary-200);
    }
  }

  .documents .tab__content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 142px), 1fr));
    gap: var(--layout-spacing-16, 1rem);
  }
  .documents .tab__content > .document {
    display: flex;
    padding: var(--layout-spacing-20, 1.25rem) var(--layout-spacing-16, 1rem);
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 150px;
    gap: var(--layout-spacing-16, 1rem);
    border-radius: var(--layout-border-radius-4, 0.25rem)
      var(--layout-border-radius-4, 0.25rem)
      var(--layout-border-radius-32, 2rem)
      var(--layout-border-radius-4, 0.25rem);
    background: var(--color-white, #fff);
    /* layout/boxShadow/primary/10 */
    box-shadow: -3px 8px 15px 0px rgba(45, 89, 102, 0.08);
  }
  .documents .tab__content > .document > .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .documents .tab__content > .document > .content .document_title {
    color: var(--font-default-color, #061216);
    text-align: center;
    /* Base/Medium */
    font-family: var(--base-medium-font-family);
    font-size: var(--base-medium-font-size);
    font-weight: var(--base-medium-font-weight);
    font-style: normal;
    line-height: var(--base-medium-line-height);
  }
  .documents .tab__content > .document > .content .edited_time {
    color: var(--font-tint-04, #969bac);
    /* XSmall/Regular */
    font-family: var(--x-small-regular-font-family);
    font-size: var(--x-small-regular-font-size);
    font-weight: var(--x-small-regular-font-weight);
    font-style: normal;
    line-height: var(--x-small-regular-line-height);
  }
  .documents .tab__content > .document > .action {
    display: flex;
    gap: var(--layout-spacing-8, 0.5rem);
  }
  .documents .tab__content > .document > .action a {
    width: var(--layout-size-16, 1rem);
    height: var(--layout-size-16, 1rem);
  }

  .todo_wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--layout-spacing-12, 0.75rem);
  }

  .todo_item {
    display: flex;
    flex-direction: column;
  }
  .todo_item > .date {
    color: var(--font-tint-04, #969bac);
    font-family: var(--x-small-medium-font-family);
    font-size: var(--x-small-medium-font-size);
    font-weight: var(--x-small-medium-font-weight);
    font-style: normal;
    line-height: var(--x-small-medium-line-height);
  }
  .todo_item > .item {
    font-family: var(--label-regular-font-family);
    font-size: var(--label-regular-font-size);
    font-weight: var(--label-regular-font-weight);
    font-style: normal;
    line-height: var(--label-regular-line-height);
    color: var(--font-default-color, #061216);
  }

  .timeline_canvas {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--layout-spacing-20, 1.25rem);
  }
  .timeline_canvas .month_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: var(--layout-spacing-8, 0.5rem);
  }
  .timeline_canvas .month_wrapper .month {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    gap: var(--layout-spacing-8, 0.5rem);
    position: relative;
  }
  .timeline_canvas .month_wrapper .month.current .month_title {
    color: var(--color-accent-300, #2d5966);
  }
  .timeline_canvas .month_wrapper .month .month_progress {
    height: 0.5rem;
    width: 100%;
    border-radius: var(--layout-border-radius-8, 0.5rem);
    background: #c7d3eb;
  }
  .timeline_canvas .month_wrapper .month .month_progress .current_progress {
    height: 100%;
    width: 0%;
    background: #2d5966;
    position: relative;
    border-radius: var(--layout-border-radius-8, 0.5rem);
  }
  .timeline_canvas
    .month_wrapper
    .month
    .month_progress
    .current_progress::after {
    content: "";
    position: absolute;
    width: var(--layout-size-16);
    height: var(--layout-size-16);
    border-radius: var(--border-radius-32);
    background-color: var(--color-accent-300);
    border: var(--layout-border-width-4, 2px) solid var(--color-white, #fff);
    z-index: 1;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
  .timeline_canvas .month_wrapper .month .month_progress .full::after {
    display: hidden !important;
    opacity: 0;
  }
  .timeline_canvas .month_wrapper .month .month_title {
    color: var(--color-primary-200, #2d5966);
    position: absolute;
    bottom: -1.5rem;
    /* XSmall/SemiBold */
    font-family: var(--x-small-medium-font-family);
    font-size: var(--x-small-medium-font-size);
    font-weight: var(--x-small-medium-font-weight);
    font-style: normal;
    line-height: var(--x-small-medium-line-height);
  }
  .timeline_canvas .year_wrapper {
    color: #347084;
    /* H6/Medium */
    font-family: var(--h6-medium-font-family);
    font-size: var(--h6-medium-font-size);
    font-weight: var(--h6-medium-font-weight);
    font-style: normal;
    line-height: var(--h6-medium-line-height);
  }
`;

export const ScrollY = styled.div`
  max-height: ${({ $maxHeight }) => $maxHeight || "100%"};
  overflow-y: auto;
  min-height: 0px;
  padding-right: var(--layout-spacing-2);
  padding-bottom: var(--layout-spacing-16);
`;
