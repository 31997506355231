import React from "react";

import UIButton from "../../../../../common/components/Buttons/UIButton";
import FieldGroup from "../../../../../common/components/Form/FieldGroup";
import InputField from "../../../../../common/components/Form/InputField";
import * as LineIcon from "../../../../../common/components/icons/Line";
import * as FillIcon from "../../../../../common/components/icons/Fill";

const Form = () => {

  return (
    <form>
      <div className="form__content">
        <FieldGroup label="Email" error={""}>
          <InputField type="email" 
            startIcon={<FillIcon.Email />}/>
        </FieldGroup>
      </div>

      <div className="form__actions">
        <UIButton variant="primary" fullWidth>Send Reset Password Request</UIButton>
      </div>
    </form>
  );
};

export default Form;
