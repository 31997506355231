import API_ENDPOINTS from "../../../services/api-endpoints";
import GlobalAPI from "../../../services/api";

export const validateUserEmail = (email) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    GlobalAPI.get(endpoints.CHECK_IS_EMAIL_EXIST + "/" + email)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
