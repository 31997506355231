// globalStyles.js
import { createGlobalStyle } from "styled-components";
// import { ContainerMaxWidth } from "./theme";

const GlobalStyle = createGlobalStyle`

    .web__container {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        margin: 0 auto;
    }

    .scrollbar-w-4 {
        ::-webkit-scrollbar {
            width: 4px !important;
        }
    }

    .mask.is__mask__transparent,
    .mask.editor__dropdown__mask{
        background: transparent;
    }

    .pac-container{
        z-index:9999999
    }

    #chat_box_container {
        --box-height: 490px;
        --box-width: 330px;

        position: fixed;
        bottom: 0;
        right: var(--layout-spacing-24);
        right: 24px;
        z-index: 49;
        display: flex;
        gap: var(--layout-spacing-24);
        // overflow: hidden;
        pointer-events: none;
        max-height: var(--box-height);
        min-height: var(--box-height);
        min-width: var(--box-width);
        max-width: var(--box-width);
        transition: transform cubic-bezier(1, 0.31, 0.18, 0.95) 0.2s;

        &.is--minimized {
            transform: translateY(var(--box-height));
            /* transform: translateY(
            calc((var(--box-height) - var(--layout-size-52)) )
            ); */
        }
    }

    .flex {
        display: flex;
    }

    .flex-col {
        flex-direction: column;
    }

    .flex-1 {
        flex: 1;
    }

    .flex-none {
        flex: none;
    }

    .d-grid {
        display: grid;

        &__col-2{
            grid-template-columns: repeat(2,1fr);
        }

        &__col-3{
            grid-template-columns: repeat(3,1fr);
        }
    }

    .gap-2 {
        gap: var(--layout-spacing-2);
    }
    .gap-4 {
        gap: var(--layout-spacing-2);
    }
    .gap-8 {
        gap: var(--layout-spacing-8);
    }
    .gap-12 {
        gap: var(--layout-spacing-12);
    }
    .gap-16 {
        gap: var(--layout-spacing-16);
    }
    .gap-20 {
        gap: var(--layout-spacing-20);
    }
    .gap-24 {
        gap: var(--layout-spacing-24);
    }

    .w-10 {
        width: 10%;
    }
    .w-20 {
        width: 20%;
    }
    .w-30 {
        width: 30%;
    }
    .w-40 {
        width: 40%;
    }
    .w-50 {
        width: 50%;
    }
    .w-60 {
        width: 60%;
    }
    .w-70 {
        width: 70%;
    }
    .w-80 {
        width: 80%;
    }

    .w-90 {
        width: 90% !important;
    }

    .w-full {
        width: 100% !important;
    }

    .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
    }

    .visibility-hidden {
        visibility: hidden;
    }
`;

export default GlobalStyle;
