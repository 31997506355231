import React from "react";
import Avatar from "../../../../ui-components/Avatar";

const InfoBLock = ({ title, desc, iconClass, downloadUrl }) => {
  if (!desc) return null;

  return (
    <div className="info__block">
      <div className="info__block__start">
        <span className={`icon ${iconClass}`}></span>
      </div>
      <div className="info__block__end">
        <h6>{title}</h6>
        {downloadUrl ? <a href={downloadUrl}>{desc}</a> : <p>{desc}</p>}
      </div>
    </div>
  );
};

const InfoItem = ({ iconClass, label, text }) => {
  return (
    <div className="item">
      <div className="item__start">
        <span className={`icon ${iconClass}`}></span>
      </div>
      <div className="item__end">
        <div className="label">{label}</div>
        <div className="text">{text}</div>
      </div>
    </div>
  );
};
const BriefCard = (props) => {
  const { joinAs, downloadDocName, downloadUrl, projectDetails } = props;

  const contactPerson = projectDetails?.projectDetails || null;

  // const productionInfo = {
  //   name: "Peddling Pictures",
  //   episodes: projectEpisodes,
  //   duration: duration,
  //   trailerUrl: "https://www.youtube.com/embed/tgbNymZ7vqY",
  //   description:
  //     "Torp Heights 15342 Hills Points Michigan Netherlands Antilles",
  // };

  return (
    <div className="brief__card">
      {/* Synopsis */}
      {projectDetails?.synopsis?.description ? (
        <div className="brief__card__top">
          <div className="title">Synopsis</div>
          <div className="content">{projectDetails?.synopsis?.description}</div>
        </div>
      ) : null}

      {/* Center block info */}
      <div className="brief__card__info">
        <div className="card__info">
          <div className="card__info__start">
            <div className="card__info__start__iw">
              {contactPerson?.name ? (
                <div className="left">
                  <div className="title">Contact Person</div>
                  <div className="contact__prs">
                    <Avatar src={contactPerson?.image} size="size-36" />

                    <div className="contact__prs__info">
                      <div className="job__title">
                        {contactPerson?.jobTitle}
                      </div>
                      <div className="name">
                        <span>{contactPerson?.name}</span>
                        <a
                          className="icon icon-lp-email"
                          href={`mailto:${contactPerson?.email}`}
                        >
                          &nbsp;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="right">
                <div className="title">Production Company</div>
                <div className="project__info">
                  <div className="name">{projectDetails?.name}</div>
                  <div className="desc">{projectDetails?.description}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="card__info__center">
            <InfoItem
              iconClass="icon-lp-view-document"
              label="Episodes"
              text={projectDetails?.noOfEpisodes}
            />

            <InfoItem
              iconClass="icon-lp-history-line"
              label="Duration"
              text={projectDetails?.duration}
            />
          </div>

          {/* trailer video */}
          {projectDetails?.trailerUrl ? (
            <div className="card__info__end">
              <div class="container">
                <iframe
                  title="Trailer"
                  class="responsive-iframe"
                  src={projectDetails?.trailerUrl}
                ></iframe>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {/* bottom */}
      <div className="brief__card__bottom">
        <InfoBLock
          iconClass="icon-lp-documents"
          title="Join as the"
          desc={joinAs}
        />

        <InfoBLock
          iconClass="icon-lp-document-layout-left"
          title="Est. Production Start Date "
          desc={projectDetails?.estimateProductionDate}
        />

        <InfoBLock
          iconClass="icon-lp-document-line"
          title="Download Project Deck"
          desc={downloadDocName}
          downloadUrl={downloadUrl}
        />
      </div>
    </div>
  );
};

export default BriefCard;
