import React from "react";
import { styleClass } from "../../../../utility/common";
import './styles.scss';

export const BUTTON_VARIANT =
  "primary" | "secondary" | "gray-400" | "green-light" | "orange";

export const BUTTON_ICONS = {
  cancel: "icon-lp-cancel",
  addUser: "icon-lp-add-user",
  done: "icon-lp-done",
  plus: "icon-lp-plus",
  plusLight: "icon-lp-Path-1333",
  home: "icon-lp-home",
  change: "icon-lp-change",
};

const Button = (props) => {
  const {
    type = "button",
    variant = BUTTON_VARIANT,
    size = "default",
    leftIcon = BUTTON_ICONS,
    rightIcon = BUTTON_ICONS,
    iconSize,
    children,
    textColorClass,
    className,
    ...otherProps
  } = props;

  const btnVariantClass = React.useMemo(() => {
    switch (variant) {
      case "secondary":
        return "btn-secondary text-white";

      case "gray-400":
        return "btn-gray-400 text-white";

      case "green-light":
        return "btn-green-light text-white";

      case "green-dark":
        return "btn-green-dark text-white";

      case "orange":
        return "btn-orange text-white";

      default:
        return "btn-primary text-white";
    }
  }, [variant]);

  return (
    <button
      type={type}
      className={styleClass(
        "web__button btn",
        `btn-${size}`,
        btnVariantClass,
        leftIcon && leftIcon.length > 0
          ? `icon btn--with-icon ${leftIcon}`
          : "",
        rightIcon && rightIcon.length > 0
          ? `icon btn--with-icon icon-right ${rightIcon}`
          : "",
        textColorClass,
        iconSize,
        className
      )}
      {...otherProps}
    >
      <>{children ? (<span className="btn-text">{children}</span>) : null}</>
    </button>
  );
};

export default Button;
