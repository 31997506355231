import React from "react";
import DatePickerField from "../../../common/components/Form/DatePickerField";
import FieldGroup from "../../../common/components/Form/FieldGroup";
// import InputField from "../../../common/components/Form/InputField";
// import SelectField from "../../../common/components/Form/SelectField";
// import TextAreaField from "../../../common/components/Form/TextAreaField";
// import TimePickerField from "../../../common/components/Form/TimePickerField";
// import ScheduleField from "../../../pages/research-board/components/common/Form/ScheduleField";

//import { useProjectStore } from "../../../store/ProjectStore";
// import { TextBase } from "../../../styled/Common/Typography.styled";
// import AccordionBase from "../../ui-components/AccordionBase";
// import Button, { BUTTON_ICONS } from "../../ui-components/Buttons/Button";
// import GenerateContractModal from "../../ui-components/Modals/GenerateContractModal";

// import OnboardCompletionModal from "../../ui-components/Modals/OnboardCompletionModal";
// import TabBase from "../../ui-components/Tab/BaseTab";
// import { Icon } from "../../ui-components/WebIcon";

// import OnboardingItems from "../onBoarding/components/OnboardingList";
// import DragDrop from "./DragDrop";
// import ProjectWizardModal from "./ProjectWizardModal";
// import ScheduleWizard from "./ScheduleWizard";
import FileUploadManager from "../../../common/components/Form/FileUploadManager";
import InsertUrlList from "../../../common/components/Form/InsertUrlList";
import Calendar from "../../../common/components/Calendar";
import ScheduleWizard from "./../../../shared/components/Modals/ScheduleWizard/index";
import EventCalendar from "../../../common/components/UI/EventCalendar";
import SkeletonElement, {
  Element,
} from "../../../common/components/UI/SkeletonElement";
import Alert from "../../../utility/alert";
//import ActivityBlockModal from "../../../pages/Scheduler/components/Modals/ActivityBlockModal";

const UIPage = () => {
  //const [modalShow, toggleModal] = React.useReducer((prev) => !prev, false);

  const [urls, setUrls] = React.useState([]);
  const [selectDay, setSelectDay] = React.useState(new Date());
  //const [expanded, setExpanded] = React.useState(false);
  const [datePickerValue, setDatePickerValue] = React.useState();


  // Alert.confirm({
  //   allowOutsideClick: false,
  //   title: "Are you sure you?",
  //   text: "You need to regenerate the contract?, it will be lost  your previous changes",
  //   confirmButtonText: "Yes",
  // }).then((result) => {
  //   if (result.isConfirmed) {
  //     console.log("yes");
  //   }
  // });

  // const openOnboardingModal = useProjectStore(
  //   (state) => state.openOnboardingModal
  // );

  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

  

  const s =
    "It sounds like you're going through a really \n\ntough time right now. ";

  const sectionList = [
    { value: "1", label: "Legal" },
    { value: "2", label: "Complince" },
    { value: "3", label: "Legal 2" },
    { value: "4", label: "Legal 2" },
  ];

  const files = [
    {
      containerName: null,
      filePath:
        "https://saruwataarchive.blob.core.windows.net/loop-documents-dev/aab69c0b-83bb-4257-b804-d18ac3f762ac-2023-06-07-18-45-44-e52623cb8578d625.png",
      fileName:
        "aab69c0b-83bb-4257-b804-d18ac3f762ac-2023-06-07-18-45-44-e52623cb8578d625.png",
      files: null,
      localId: "yqusevgtu",
      originalName:
        "aab69c0b-83bb-4257-b804-d18ac3f762ac-2023-06-07-18-45-44-e52623cb8578d625.png",
      extension: "png",
      sectionValue: "1",
      mode: "view",
      isSorted: true,
    },
    {
      containerName: null,
      filePath:
        "https://saruwataarchive.blob.core.windows.net/loop-documents-dev/fa7d1a9b-8873-4aa0-947c-7253e4a8fc5d-2023-06-07-18-48-23-PEXXXT20230531181338709000%20%281%29.pdf",
      fileName:
        "fa7d1a9b-8873-4aa0-947c-7253e4a8fc5d-2023-06-07-18-48-23-PEXXXT20230531181338709000 (1).pdf",
      files: null,
      localId: "pxze",
      originalName:
        "fa7d1a9b-8873-4aa0-947c-7253e4a8fc5d-2023-06-07-18-48-23-PEXXXT20230531181338709000 (1).pdf",
      extension: "pdf",
      sectionValue: null,
      mode: "view",
      isSorted: false,
    },
  ];

  return (
    <div className="body" id="containerBody" style={{ marginLeft: 50 }}>
      <div dangerouslySetInnerHTML={{ __html: s }} />

      {/* <ProjectWizardModal /> */}

      {/* <ScheduleWizard /> */}

      {/* <ActivityBlockModal /> */}

      <div className="container" style={{ marginTop: 50, marginBottom: 50 }}>
        <div className="row">
          <div className="col-offset-6 col-6">
            {/* <EventCalendar /> */}

            <SkeletonElement width={200} height={200}>
              <Element width={150} height={4} />
            </SkeletonElement>
          </div>
        </div>

        <div className="row">
          <div className="col-offset-6 col-6">{/* <EventCalendar /> */}</div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-6">
            <FileUploadManager
              defaultValue={files}
              uploadSections={sectionList}
              onChange={(payload) => console.log(payload)}
            />

            <InsertUrlList value={urls} onChange={setUrls} />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-6">
            <Calendar
              mode="single"
              selected={selectDay}
              onSelect={setSelectDay}
            />
          </div>

          <div className="col-4">
            <FieldGroup
              label="Date Picker"
              error="Enter valid text"
              hint="Max character 230"
            >
              <DatePickerField
                value={datePickerValue}
                onDateSelect={(date) => {
                  console.log("UI ", date);
                  setDatePickerValue(date);
                }}
              />
            </FieldGroup>
          </div>
        </div>
      </div>
    </div>
  );

  // return (
  //   <>
  //     <div className="body" id="containerBody" style={{ marginLeft: 50 }}>
  //       {/* ProjectWizardModal */}
  //       {/* <ProjectWizardModal /> */}
  //       <ScheduleWizard />

  //       <br />
  //       <br />
  //       <br />

  //       <div className="container">
  //         <div className="row">
  //           <div className="col-12">
  //             <DragDrop />
  //           </div>
  //         </div>

  //         <div className="row" style={{ marginBlock: "50px" }}>
  //           <div className="col-12">
  //             <h4 data-tooltip-text="Tooltip for Element">Form Elements</h4>
  //           </div>

  //           <div className="col-4">
  //             <FieldGroup
  //               label="Textarea"
  //               error="Enter valid text"
  //               hint="Max character 230"
  //             >
  //               <ScheduleField hideBtnText={false} viewMode />
  //             </FieldGroup>
  //           </div>
  //           <div className="col-4">
  //             <FieldGroup
  //               label="Textarea"
  //               error="Enter valid text"
  //               hint="Max character 230"
  //             >
  //               <TextAreaField />
  //             </FieldGroup>
  //           </div>

  //           <div className="col-4">
  //             <FieldGroup
  //               label="Text Field"
  //               error="Enter valid text"
  //               hint="Max character 230"
  //             >
  //               <InputField />
  //             </FieldGroup>
  //           </div>

  //           <div className="col-4">
  //             <FieldGroup
  //               label="Select Field"
  //               error="Enter valid text"
  //               hint="Max character 230"
  //             >
  //               <SelectField options={[{ label: "Test", value: "1" }]} />
  //             </FieldGroup>
  //           </div>

  //           <div className="col-4">
  //             <FieldGroup
  //               label="Date Picker"
  //               error="Enter valid text"
  //               hint="Max character 230"
  //             >
  //               <DatePickerField
  //                 value={datePickerValue}
  //                 onDateSelect={(date) => {
  //                   console.log("UI ", date);
  //                   setDatePickerValue(date);
  //                 }}
  //               />
  //             </FieldGroup>
  //           </div>

  //           <div className="col-4">
  //             <FieldGroup
  //               label="Time Picker"
  //               error="Enter valid time"
  //               hint="Max character 230"
  //             >
  //               <TimePickerField />
  //             </FieldGroup>
  //           </div>
  //         </div>

  //         <br />
  //         <br />

  //         <div className="row">
  //           <div className="col-12">
  //             <OnboardingItems viewType="card">
  //               <OnboardingItems.Item />
  //               <OnboardingItems.Item />
  //               <OnboardingItems.Item />
  //               <OnboardingItems.Item />
  //               <OnboardingItems.Item />
  //               <OnboardingItems.Item />
  //               <OnboardingItems.Item />
  //               <OnboardingItems.Item />
  //               <OnboardingItems.Item />
  //             </OnboardingItems>
  //           </div>
  //         </div>
  //       </div>

  //       <br />

  //       <div className="container pt-5">
  //         <div className="row">
  //           <div className="col-12">
  //             <TextBase size="12px">hello</TextBase>
  //           </div>

  //           <div className="col-12 ">
  //             <Button>Primary</Button>{" "}
  //             <Button variant="secondary">Secondary</Button>{" "}
  //             <Button variant="green-light">Green Light</Button>{" "}
  //             <Button variant="gray-400">Gary 400</Button>
  //           </div>

  //           <div className="col-12 mt-3">
  //             <Button size="sm">Button sm</Button>{" "}
  //             <Button variant="secondary" size="lg">
  //               Button lg
  //             </Button>
  //           </div>

  //           <div className="col-12 mt-3">
  //             <Button textColorClass="text-red">
  //               Button with different text color
  //             </Button>
  //           </div>

  //           <div className="col-12 mt-3 mb-5">
  //             <Button leftIcon={BUTTON_ICONS.cancel}>Button with icon</Button>
  //           </div>

  //           <div className="col-12 mb-5">
  //             <h1>Base Tab</h1>

  //             <TabBase activeTab="tab1">
  //               <TabBase.Header>
  //                 <TabBase.NavLink linkTo="tab1">Tab 1</TabBase.NavLink>
  //                 <TabBase.NavLink linkTo="tab2">Tab 2</TabBase.NavLink>
  //               </TabBase.Header>

  //               <TabBase.PanelWrapper>
  //                 <TabBase.Panel panelId="tab1">content 1</TabBase.Panel>
  //                 <TabBase.Panel panelId="tab2">
  //                   <p>asasas</p>
  //                   <p>
  //                     Lorem ipsum, dolor sit amet consectetur adipisicing elit.
  //                     Sequi, eaque.
  //                   </p>
  //                 </TabBase.Panel>
  //               </TabBase.PanelWrapper>
  //             </TabBase>
  //           </div>

  //           <div className="col-12">
  //             <button onClick={toggleModal}>Toggle Modal</button>

  //             <GenerateContractModal
  //               visibility={modalShow}
  //               onClose={toggleModal}
  //               // onBackdropClick={toggleModal}
  //             />
  //           </div>

  //           <div className="col-12">
  //             <h1>Accordion Base</h1>

  //             <AccordionBase>
  //               <AccordionBase.Panel
  //                 expanded={expanded === "p1"}
  //                 onChange={handleChange("p1")}
  //               >
  //                 <AccordionBase.PanelHeader>header</AccordionBase.PanelHeader>

  //                 <AccordionBase.PanelContent>
  //                   content
  //                 </AccordionBase.PanelContent>
  //               </AccordionBase.Panel>

  //               <AccordionBase.Panel
  //                 expanded={expanded === "p2"}
  //                 onChange={handleChange("p2")}
  //               >
  //                 <AccordionBase.PanelHeader>header</AccordionBase.PanelHeader>

  //                 <AccordionBase.PanelContent>
  //                   content
  //                 </AccordionBase.PanelContent>
  //               </AccordionBase.Panel>
  //             </AccordionBase>
  //           </div>

  //           <div className="col-12">
  //             <button onClick={openOnboardingModal}>
  //               Open Completion Modal
  //             </button>

  //             {/* Modal */}
  //             <OnboardCompletionModal />
  //           </div>

  //           <br />
  //           <br />
  //           <br />
  //           <Icon name="icon-add" />
  //           {/* <Folder/> */}
  //         </div>
  //       </div>

  //       <br />
  //     </div>
  //   </>
  // );
};

export default UIPage;
