import React from "react";
import Message from "../../Components/Message";
import Divider from "../../Components/Divider";
import MessageForm from "../../Components/MesageForm";
import { MessageWrapper } from "./styled";

import {
  useChatBaseStore,
  setCurrentChatData,
  setUpdateChatData,
} from "../../../../Store/ChatBaseStore";
import API_Common from "../../../../../../services/api-common";
import API_ENDPOINTS from "../../../../../../services/api-endpoints";
import { useGlobalStore } from "../../../../../../store/GlobalStore";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ChatMessage = () => {
  const chatData = useChatBaseStore((state) => state.chatData);
  const [userId, setUserId] = React.useState("");
  const [messages, setMessages] = React.useState([]);
  const messageRef = React.useRef();
  let Dategroups = [];
  let groupArray = [];

  const projectId = useGlobalStore((state) => state.selectedProjectId);

  const scrollToDown = React.useCallback(() => {
    if (messageRef?.current) {
      const lastChildElement = messageRef.current?.lastElementChild;

      lastChildElement?.scrollIntoView();
    }
  }, []);

  let USER = JSON.parse(localStorage.getItem("user"));

  React.useEffect(() => {
    setUserId(USER.result.userId);
  }, [USER.result.userId]);

  const filterAndCategorizedChat = React.useCallback(() => {
    let userId = USER.result.userId;
    console.log("chatData", chatData);

    if (chatData?.chatMessage) {
      var sortedData = [...chatData?.chatMessage].sort((a, b) =>
        a.timeStamp < b.timeStamp ? -1 : 1
      );

      Dategroups = sortedData.reduce((Dategroups, obj) => {
        const date = obj?.timeStamp?.split("T")[0];
        if (!Dategroups[date]) {
          Dategroups[date] = [];
        }
        Dategroups[date].push(obj);
        setMessages(Dategroups);

        return Dategroups;
      }, {});

      groupArray = Object.keys(Dategroups).map((date) => {
        return {
          date,
          messages: Dategroups[date],
        };
      });

      console.log("groupArrays: ", groupArray);

      setMessages(groupArray);
      setCurrentChatData(groupArray);
      setTimeout(() => {
        scrollToDown();
      }, 100);

      console.log("sort: ", sortedData);
      console.log("Dategroups: ", Dategroups);
      console.log("messages: ", messages);
    }
  }, [chatData, scrollToDown]);

  React.useEffect(() => {
    filterAndCategorizedChat();
  }, [filterAndCategorizedChat]);

  const filterAndCategorizedChatWithUpdatedData = React.useCallback(
    (dataObj) => {
      let userId = USER.result.userId;
      console.log("chatData", chatData);

      if (dataObj?.chatMessage) {
        var sortedData = [...dataObj?.chatMessage].sort((a, b) =>
          a.timeStamp < b.timeStamp ? -1 : 1
        );

        Dategroups = sortedData.reduce((Dategroups, obj) => {
          const date = obj?.timeStamp?.split("T")[0];
          if (!Dategroups[date]) {
            Dategroups[date] = [];
          }
          Dategroups[date].push(obj);
          setMessages(Dategroups);

          return Dategroups;
        }, {});

        groupArray = Object.keys(Dategroups).map((date) => {
          return {
            date,
            messages: Dategroups[date],
          };
        });

        console.log("groupArrays: ", groupArray);

        setMessages(groupArray);
        setCurrentChatData(groupArray);
        setTimeout(() => {
          scrollToDown();
        }, 100);
        console.log("sort: ", sortedData);
        console.log("Dategroups: ", Dategroups);
        console.log("messages: ", messages);
      }
    },
    [chatData, scrollToDown]
  );

  const getChatGroups = React.useCallback(() => {
    let userId = USER.result.userId;
    const endpoints = new API_ENDPOINTS();
    API_Common.get(
      endpoints.GET_ALL_CHAT_GROUPS_BY_PROJECT +
        "?projectId=" +
        projectId +
        "&userId=" +
        userId
    ).then((response) => {
      if (response.data.isSuccess === true) {
        console.log("MF :: get Chat groupe :chat-groups:  ", response.data);

        const updatedChatdata = response.data.result.filter((element) => {
          return element.groupId == chatData?.groupId;
        });

        console.log("updated chat obj", updatedChatdata[0]);
        if (updatedChatdata !== null) {
          setUpdateChatData(updatedChatdata[0]);
          filterAndCategorizedChatWithUpdatedData(updatedChatdata[0]);
        }

        // const memberChatdata = response.data.result.filter((element) => {
        //   return element.groupType == 1;
        // });
        // const groupChatdata = response.data.result.filter((element) => {
        //   return element.groupType == 2;
        // });
        // console.log("chat-groups:  ", groupChatdata);
        // console.log("member chat-groups:  ", memberChatdata);
        // setGenaralChatGroups(groupChatdata);
        // setMemberChatGroups(memberChatdata);
      }
    });
  }, [projectId]);

  React.useEffect(() => {
    // setInterval(function () {
    scrollToDown();
    // }, 100);
  }, [useChatBaseStore((state) => state.currentChat)]);

  return (
    <MessageWrapper>
      {/* Chat list */}
      <div className="message__content">
        <div className="message__content__inner" ref={messageRef}>
          {useChatBaseStore((state) => state.currentChat)?.map((d, i) => {
            scrollToDown();
            return (
              <>
                {/* <Message /> */}
                <Divider date={new Date(d?.date)} />
                {d?.messages?.map((m, x) => {
                  if (m.userId !== userId) {
                    return <Message data={m} />;
                  } else {
                    return <Message layoutType="rtl" data={m} />;
                  }
                })}
              </>
            );
          })}
        </div>
      </div>

      {/* Form */}
      <div className="message__form">
        <MessageForm scrollToDown={scrollToDown} />
      </div>
    </MessageWrapper>
  );
};

export default ChatMessage;
