import React from "react";
import "./styles.scss";

import doc from "../../../data/toolbar_customization.json";

import {
  DocumentEditorContainerComponent,
  DocumentEditorComponent,
  Print,
} from "@syncfusion/ej2-react-documenteditor";

DocumentEditorComponent.Inject(Print);

const DocumentViewNDA = (props) => {
  const { title, url, templateData } = props;

  const containerRef = React.useRef();

  const onLoadDefault = () => {
    containerRef.current.pageOutline = "#fff";
    containerRef.current.restrictEditing = true;
    containerRef.current.isReadOnly = true;
    containerRef.current.showPropertiesPane = false;
    containerRef.current.enableContextMenu = false;

    containerRef.current.enablePrint = true;

    let sfdt = `{
      "sections": [
          {
              "blocks": [
                  {
                      "inlines": [
                          {
                              "characterFormat": {
                                  "bold": true,
                                  "italic": true
                              },
                              "text": "Hello World"
                          }
                      ]
                  }
              ],
              "headersFooters": {
              }
          }
      ]
    }`;

    // containerRef.current.documentEditor.open(doc);
    // printWindow
    // console.log();

    //  containerRef.current.documentEditor.editor
  };

  const onCreated = () => {
    console.log("done");
    // var windowWidth = window.innerWidth;
    // var windowHeight = window.innerHeight;
    // this.containerRef.current;
    // containerRef.current.height='600px';
    // containerRef.current.documentEditor.height='100%';
    containerRef.current.resize();
  };

  React.useEffect(() => {
    onLoadDefault();
  }, []);

  React.useEffect(() => {
    if (templateData) {
      containerRef.current.documentEditor.open(templateData);
    }
  }, [templateData]);

  return (
    <div className="document">
      <DocumentEditorContainerComponent
        serviceUrl="https://saruwataarchive.blob.core.windows.net/loop-templates/MailMerge.docx"
        id="containerRef"
        ref={(scope) => {
          containerRef.current = scope;
        }}
        style={{ display: "block" }}
        height={"687px"}
        // minHeight={"100%"}
        locale="en-US"
        width={"100%"}
        enableToolbar={false}
        enablePrint={true}
        isReadOnly={false}
        enableEditor={false}
        enableOptionsPane={false}
        restrictEditing={false}
        showPropertiesPane={false}
        enableContextMenu={false}
        created={onCreated}
      />
      {/* <div className="document__iw">
        <div className="document__action_bar">
          <button
            className="dropdown-toggle icon icon-lp-dot-menu btn__dot__action btn__icon btn__icon--arrow-hidden"
            type="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          ></button>
          <div className="dropdown-menu dropdown-menu-end custom_dropdown_style">
            <div className="dropdown_iW">
              <ul>
                <li>
                  <a className="dropdown-item" href="#">
                    Lorem, ipsum.
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="document__side__menu">
          <ul>
            <li>
              <button
                className="btn__menu icon icon-lp-doc-print btn__icon"
                type="button"
                onClick={() => containerRef.current.print()}
              />
            </li>
            <li>
              <button
                className="btn__menu icon icon-lp-doc-save btn__icon"
                type="button"
              />
            </li>
          </ul>
        </div>

        <div className="document__contents">
          <div className="document__contents__iw">
            <div className="document__contents__title">{title}</div>
            <div className="document__contents__view">
              
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default DocumentViewNDA;
