import styled from "styled-components";
import { fontStyle } from "../../../../styled/utility";

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--layout-spacing-8);
  margin-top: var(--layout-size-24);
  padding: var(--layout-spacing-8);
  flex-wrap: wrap;

  .text__link {
    cursor: pointer;
    text-decoration: underline;
    transition: var(--animate-1);
    color: var(--font-color-shade-accent);
    ${fontStyle("base-bold")};
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;

    &:hover {
      color: var(--font-color-shade-primary);
    }

    &:disabled {
      cursor: auto;
      color: var(--font-tint-02);
    }
  }

  .timer-text {
    color: var(--font-tint-04);
    text-align: center;
  }
`;
