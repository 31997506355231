import IconView from "../../../../common/components/icons/Line/View/01";

const EndIcon = ({ handleOnClick }) => {
  return (
    <div onClick={handleOnClick}>
      <IconView />
    </div>
  );
};

export default EndIcon;
