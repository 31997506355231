import styled from "styled-components";
import Column from "../BaseColumn";
import { weekendCss } from "../styled";
import BaseControlItem from "../BaseControlItem";

export const DateColumn = styled(Column)`
  /* .column__body {
    background: var(--color-neutral-20, #f5f5f7);
  } */
`;

export const DateItems = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: var(--min-scroll-height);
`;

export const DateItem = styled(BaseControlItem)`
  &:nth-child(7n) {
    border-bottom-color: var(--column-border-color);
  }

  .text {
    color: var(--color-neutral-300, #5a627d);

    ${({ $isToday, $isHoliday }) => {
      if ($isToday) {
        return `
          color: var(--font-default-white, #FFF);
          background: var(--color-accent-300, #56B5BC);
          box-shadow: 0px 8px 18px 10px rgba(45, 89, 102, 0.05);
        `;
      }

      if ($isHoliday) {
        return `
        color: var(--font-default-color, #061216);
        border-top: 2px solid var(--color-danger-200, #F05353);
        border-bottom: 2px solid var(--color-danger-200, #F05353);
        `;
      }
    }}
  }

  ${weekendCss};
`;
