import styled from "styled-components";
import { fontStyle } from "../../../../../../../../../styled/utility";

export const Container = styled.div`
  --column-width: 100%;

  width: var(--column-width);
  min-width: var(--column-width);
  max-width: var(--column-width);
  min-height: 100%;
  /* display: flex;
  flex-direction: column; */

  .inner {
    /* display: flex;
    flex-direction: column;
    min-height: 100%;
    flex: 1 1 0px; */

    .top__content {
      height: var(--column-top-height);
      max-height: var(--column-top-height);
      overflow: hidden;
      background-color: var(--layout-bg-color);
      position: sticky;
      top: 0;
      z-index: 1;

      .column__title {
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        align-items: center;
        gap: var(--layout-spacing-8);
        padding-inline: var(--layout-spacing-8);

        span {
          ${fontStyle("small-bold")};
          font-weight: 700;
          color: var(--color-primary-300, #023747);
        }
      }
    }

    .main__content {
      display: flex;
      flex-direction: column;
      flex: 1;

      &__inner {
        display: flex;
        flex-direction: column;

        .title {
          height: var(--column-main-title-height);
          padding-inline: var(--layout-spacing-8);
          position: sticky;
          top: var(--column-top-height);
          z-index: 1;
          background-color: var(--layout-bg-color);
          border-bottom: 1px solid var(--column-border-color);

          .text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            ${fontStyle("small-bold")};
            font-weight: 700;
            color: var(--font-tint-04, #969bac);
          }
        }

        .cell__container {
          background-color: var(--layout-background-white);
          width: 100%;
          min-height: 100%;
          border: 1px solid var(--column-border-color);
          border-top-width: 0px;
          padding: var(--layout-spacing-4) 0;
          position: relative;

          .cells {
            pointer-events: none;
            min-height: var(--min-scroll-height);
          }

          .cell {
          }

          .presentation {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
          }
        }
      }
    }
  }
`;

export const RowCell = styled.div`
  height: var(--column-cell-height);
  width: 100%;
  pointer-events: auto;
  border-radius: var(--layout-border-radius-4);
  padding: 0 var(--layout-spacing-8);
  position: relative;
  background-color: ${({ $isWeekend }) =>
    $isWeekend ? `var(--weekend-bg-color)` : `transparent`};

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: calc(100% - 2px);
    left: 0;
    top: 1px;
    position: absolute;
  }

  &:hover {
    &::before {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
`;

export const SelectionLayer = styled.div`
  position: absolute;
  background-color: rgb(14, 101, 235);
  opacity: 0.1;
  width: 100%;
  min-height: 32px;
`;
