import React from "react";
import BreadcrumbLink from "../../../../components/ui-components/Breadcrumb/BreadcrumbLink";
import config from "../config";
import { permissionLoader } from "../../../../routes/loaders";

// Screens
const Planner = React.lazy(() => import("../../screens/Planner"));

// import { permissionLoader } from "../../../../routes/loaders";

const children = config.children;
const key = config.id;

export const ChildrenRoutes = [
  {
    id: children.planner.id,
    path: children.planner.path,
    element: (
      <React.Suspense fallback={<>Loading...</>}>
        <Planner />
      </React.Suspense>
    ),
    handle: {
      //crumb: () => <BreadcrumbLink label={children.planner.label} />,
    },
    loader: async () => {
      // return true;
      return await permissionLoader(key, children.planner.id);
    },
  },
];
