import * as React from "react";
import { memo } from "react";
const SvgIconCheckSm = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_1_7"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={24}
      height={25}
    >
      <rect y={0.5} width={24} height={24} fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1_7)">
      <path
        d="M10 16.9L6 12.9L7.4 11.5L10 14.1L16.6 7.5L18 8.9L10 16.9Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
const Memo = memo(SvgIconCheckSm);
export default Memo;
