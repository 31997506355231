import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.67239 1.072C5.40993 1.39513 5.41176 2.31426 6.10993 3.63669C6.12535 3.66591 6.14087 3.69362 6.15634 3.72052C6.26665 3.36 6.32379 2.94685 6.324 2.48371C6.324 2.45131 6.32363 2.41784 6.32321 2.38367C6.31806 2.05275 6.57478 1.77874 6.89567 1.77321H6.90489C7.22057 1.77321 7.48167 2.03849 7.48677 2.36464C7.48735 2.40424 7.48781 2.44401 7.48781 2.48382C7.48813 3.21516 7.37271 3.86728 7.14511 4.42862C7.51161 4.50977 7.86011 4.58747 8.14626 5.3499C8.71777 6.87249 8.78638 0.531043 6.67239 1.072Z"
      fill="currentColor"
    />
    <path
      d="M3.57397 2.4397C4.27794 2.60904 4.71575 3.33595 4.55179 4.06325C4.38788 4.79055 3.68429 5.24288 2.98031 5.07348C2.27634 4.90409 1.83853 4.17723 2.00249 3.44992C2.1664 2.72257 2.86999 2.2703 3.57397 2.4397Z"
      fill="currentColor"
    />
    <path
      d="M7.37053 2.36669C7.36641 2.1012 7.15474 1.88934 6.89782 1.89353C6.64079 1.89784 6.43574 2.11647 6.43984 2.38213C6.44031 2.41684 6.44068 2.45084 6.44068 2.48388C6.44031 3.04652 6.36114 3.49298 6.23843 3.85232C6.19093 3.99137 6.13718 4.11777 6.07859 4.23222C5.76354 4.8442 5.30547 5.15431 4.90708 5.32671C4.7091 5.41146 4.52901 5.458 4.40254 5.48254C4.33916 5.49475 4.28942 5.50153 4.25775 5.50508C4.24182 5.50697 4.23046 5.50783 4.22416 5.50831C4.22187 5.50853 4.22025 5.50869 4.21937 5.50874C4.21932 5.50879 4.21942 5.50874 4.21937 5.50874L4.21864 5.50879C4.20942 5.50928 4.20046 5.51062 4.19145 5.51165H2.24237C2.22185 5.51165 2.20175 5.51358 2.18196 5.51633C2.12123 5.51294 2.05878 5.52192 1.99805 5.54485C1.93018 5.56928 1.45143 5.77289 0.986735 6.44525C0.520799 7.11657 0.0802224 8.23769 0.020582 10.0519C0.0116757 10.3174 0.212717 10.54 0.469652 10.5492C0.475068 10.5494 0.480537 10.5495 0.485902 10.5495C0.735637 10.5495 0.942265 10.3451 0.951012 10.0851C1.00773 8.41306 1.40299 7.4981 1.74372 7.00508C1.76023 6.9813 1.77659 6.95918 1.79284 6.93723V15.2568C1.79284 15.6674 2.11492 16 2.51216 16C2.90946 16 3.23154 15.6674 3.23154 15.2568L3.23144 10.5519H3.50117V15.2568C3.50117 15.6674 3.82325 16 4.2205 16C4.61779 16 4.93988 15.6674 4.93988 15.2568V6.33511C5.20056 6.25305 5.51223 6.12035 5.83045 5.90522C6.21868 5.6414 6.61254 5.2466 6.90114 4.68239C6.94692 4.59323 6.98963 4.49959 7.02974 4.4022C7.24318 3.88412 7.37192 3.24953 7.37156 2.48385C7.37151 2.44451 7.37115 2.40548 7.37053 2.36669Z"
      fill="currentColor"
    />
    <path
      d="M8.93721 14.2333L9.18945 14.64C9.2221 14.6927 9.29158 14.7069 9.34461 14.6718C9.39763 14.6367 9.41414 14.5655 9.38148 14.513L8.95653 13.8276L9.01992 12.4923C9.12232 12.4893 9.20461 12.4031 9.20461 12.2965C9.20461 12.1881 9.11956 12.1002 9.01461 12.1002H6.08793C5.98298 12.1002 5.89793 12.1881 5.89793 12.2965C5.89793 12.4031 5.98022 12.4894 6.08257 12.4923L6.24476 15.9092H8.85771L8.93721 14.2333Z"
      fill="currentColor"
    />
    <path
      d="M15.9575 0.0440163C15.9301 0.0157122 15.8936 5.38098e-05 15.8547 5.38098e-05H5.38236C5.3822 5.38098e-05 5.38205 0 5.38184 0C5.38168 0 5.38147 5.38098e-05 5.38127 5.38098e-05H0.145365C0.107083 5.38098e-05 0.0696351 0.0160359 0.0425523 0.0440163C0.0154694 0.071943 0 0.110686 0 0.15029V3.15579C0 3.23882 0.0651044 3.30613 0.145361 3.30613C0.225726 3.30613 0.290882 3.23882 0.290882 3.15579L0.29083 0.300579H5.23633V0.991879C5.23633 1.07496 5.30143 1.14222 5.38185 1.14222C5.46206 1.14222 5.52726 1.07496 5.52726 0.991879V0.300579H10.4728V7.21331L5.84727 7.21336C5.7669 7.21336 5.7018 7.28063 5.7018 7.3636C5.7018 7.44662 5.7669 7.51389 5.84727 7.51389H10.6176C10.6177 7.51389 10.6179 7.51394 10.6181 7.51394C10.6183 7.51394 10.6185 7.51389 10.6187 7.51389H15.8545C15.8934 7.51389 15.9299 7.49828 15.9573 7.46992C15.9848 7.44151 15.9999 7.40374 15.9999 7.36359L16 0.150286C16 0.110143 15.9851 0.0724817 15.9575 0.0440163ZM15.7091 7.21311L10.7636 7.21316V0.300567H15.7091V7.21311Z"
      fill="currentColor"
    />
    <path
      d="M12.3638 3.87715C12.3787 3.87715 12.3936 3.87129 12.4049 3.85956L13.4231 2.80763C13.4458 2.78412 13.4458 2.74618 13.4231 2.72272C13.4004 2.6992 13.3635 2.6992 13.3407 2.72272L12.3226 3.77464C12.2999 3.7981 12.2999 3.83609 12.3226 3.85956C12.3339 3.87123 12.3489 3.87715 12.3638 3.87715Z"
      fill="currentColor"
    />
    <path
      d="M13.9375 3.33881L12.9194 4.39068C12.8966 4.4142 12.8966 4.45224 12.9194 4.4757C12.9307 4.48748 12.9456 4.49335 12.9605 4.49335C12.9754 4.49335 12.9904 4.48748 13.0017 4.4757L14.0198 3.42383C14.0426 3.40032 14.0426 3.36233 14.0198 3.33876C13.9971 3.3153 13.9603 3.3153 13.9375 3.33881Z"
      fill="currentColor"
    />
    <path
      d="M11.9856 4.92903C12.0005 4.92903 12.0154 4.92322 12.0268 4.91149L14.4412 2.41692C14.464 2.39341 14.464 2.35531 14.4412 2.3319C14.4185 2.30839 14.3817 2.30839 14.3589 2.3319L11.9444 4.82633C11.9217 4.8499 11.9217 4.88795 11.9444 4.91146C11.9557 4.92314 11.9706 4.92903 11.9856 4.92903Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
