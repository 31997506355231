import CrewPlanner from "../../components/pages/crew/crew-planner";
import BreadcrumbLink from "../../components/ui-components/Breadcrumb/BreadcrumbLink";
import { ROUTE_OPTIONS } from "../../constant/route-options";
import { permissionLoader } from "../loaders";

const child = ROUTE_OPTIONS.crew.children;

const key = "team";

export const crewRoutes = [
  {
    id: child["crew-planner"].id,
    path: child["crew-planner"].path,
    element: <CrewPlanner />,
    handle: {
     // crumb: () => <BreadcrumbLink label={child["crew-planner"].label} />,
    },
    loader: async () => {
      return await permissionLoader(key, child["crew-planner"].id);
    },
  },
];
