import styled from "styled-components";
import { PageContainer } from "../../../../styled/Common/Container";
import { layoutBoxShadow } from "../../../../styled/utility";

export const LayoutContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - var(--layout-size-60));
  background-color: var(--layout-background-n30);
  padding-top: var(--layout-size-60);
`;

export const Container = styled(PageContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: inherit;
  flex-direction:column;
`;

export const Card = styled.div`
  background-color: var(--layout-background-n10);
  padding: var(--layout-spacing-60);
  width: 490px;
  min-height: 300px;
  box-shadow: ${layoutBoxShadow("primary-5")};
  position: relative;
  border-radius: var(--layout-border-radius-12);

  .card__header {
    display: flex;
    justify-content: center;

    .logo__wrapper {
      position: absolute;
      top: calc((var(--layout-size-60) + var(--layout-size-48)) / -2);

      .logo__inner {
        background-color: var(--layout-background-white);
        /* width: var(--layout-size-60);
        height: var(--layout-size-60); */
        padding: var(--layout-spacing-24);
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: ${layoutBoxShadow("primary-5")};
        border-radius: var(--layout-border-radius-12);
      }

      .logo__icon {
        --icon-size: var(--layout-size-60);
        --icon-font-size: var(--layout-size-60);
      }
    }

    .title__wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  .card__body {
    padding-top: var(--layout-spacing-24);

    form {
      .form__content {
        display: flex;
        flex-direction: column;
      }

      .form__actions {
        margin-top: var(--layout-spacing-20);
      }
    }
  }
`;
