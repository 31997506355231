import React from "react";
import {
  timelineContainer,
  timelineStep,
  statusIcon,
  statusText,
  dateText,
  TimelineStatusStyle,
} from "./style";

const Timeline = ({ Status, AuthorName, TimelineDate, TimelineTime }) => {
  return (
    <div style={timelineContainer}>
      <div style={timelineStep}>
        <div style={statusIcon}>
          <i className="fas fa-check-circle"></i>
        </div>
        <div style={statusText}>
          <div style={TimelineStatusStyle}>{Status}</div>
          <div>{AuthorName}</div>
          <div style={dateText}>
            {TimelineDate} | {TimelineTime}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
