import React from "react";

export const ActivityBlockContext = React.createContext({
  stageModalData: { visibility: false, payload: null },
  StageModal: {
    open: (payload) => {},
    close: () => {},
  },

  milestoneModalData: { visibility: false, payload: null },
  MilestoneModal: {
    open: (payload) => {},
    close: () => {},
  },

  blockModalData: { visibility: false, payload: null },
  BlockModal: {
    open: (payload) => {},
    close: () => {},
  },
});

export const useActivityBlock = () => {
  return React.useContext(ActivityBlockContext);
};

export const ActivityBlockProvider = ({ children }) => {
  const [stageModalData, setStageModalData] = React.useState({
    visibility: false,
    payload: null,
  });

  const [milestoneModalData, setMilestoneModalData] = React.useState({
    visibility: false,
    payload: null,
  });

  const [blockModalData, setBlockModalData] = React.useState({
    visibility: false,
    payload: null,
  });

  const StageModal = {
    open: (payload) => {
      setStageModalData({ visibility: true, payload });
    },
    close: () => {
      setStageModalData({ visibility: false, payload: null });
    },
  };

  const MilestoneModal = {
    open: (payload) => {
      setMilestoneModalData({ visibility: true, payload });
    },
    close: () => {
      setMilestoneModalData({ visibility: false, payload: null });
    },
  };

  const BlockModal = {
    open: (payload) => {
      setBlockModalData({ visibility: true, payload });
    },
    close: () => {
      setBlockModalData({ visibility: false, payload: null });
    },
  };

  return (
    <ActivityBlockContext.Provider
      value={{
        stageModalData,
        StageModal,
        milestoneModalData,
        MilestoneModal,
        blockModalData,
        BlockModal,
      }}
    >
      {children}
    </ActivityBlockContext.Provider>
  );
};
