import React from "react";
import * as Styled from "./styled";
import IconButton from "./../../../../../../../../common/components/Buttons/IconButton";
import IconView from "./../../../../../../../../common/components/icons/Line/View/01";
import Calendar from "../Calendar";
import { useScheduleContext } from "../../context/ScheduleContext";
import DateSelector from "../DateSelector";

const LeftPanel = () => {
  const { handleLoadData } = useScheduleContext();

  return (
    <Styled.Container className="left__area">
      <div className="inner">
        {/* Top */}
        <div className="top__content">
          <div className="top__content__action">
            {/* <IconButton
              data-tooltip-text="View Control"
              className="btn__view"
              icon={<IconView />}
              btnSize={32}
            /> */}

            <DateSelector />
          </div>

          {/* <div className="top__content__designations"></div> */}
        </div>

        {/* Main  */}
        <div className="main__content">
          <Calendar />
        </div>
      </div>
    </Styled.Container>
  );
};

export default LeftPanel;
