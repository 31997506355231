import React from "react";
import { FlipImage } from "../../../../../../../common/components/icons/Line";
import { IconBlock } from "../../../../../../../styled/Common/Icons";
import ImageGallery from "../../../ImageGallery";
import MapView from "../../../MapView";
import { SwitchBtn } from "../styled";
import { StyledWrapper } from "./styled";

const images = [
  {
    type: "image",
    thumbnail: "https://swiperjs.com/demos/images/nature-1.jpg",
    url: "https://swiperjs.com/demos/images/nature-1.jpg",
  },
  {
    type: "iframe",
    thumbnail: "https://swiperjs.com/demos/images/nature-2.jpg",
    url: "https://swiperjs.com/demos/images/nature-2.jpg",
  },
  {
    type: "video",
    thumbnail: "https://swiperjs.com/demos/images/nature-3.jpg",
    url: "https://swiperjs.com/demos/images/nature-3.jpg",
  },
  {
    type: "image",
    thumbnail: "https://swiperjs.com/demos/images/nature-4.jpg",
    url: "https://swiperjs.com/demos/images/nature-4.jpg",
  },
];

const LocationContent = ({ galleryOptions }) => {
  const [showMap, setShowMap] = React.useState(false);

  return (
    <StyledWrapper>
      {/* Flip Image Button */}
      <SwitchBtn onClick={() => setShowMap((prev) => !prev)}>
        <IconBlock>
          <FlipImage />
        </IconBlock>
      </SwitchBtn>

      {showMap ? (
        // Map View
        <MapView />
      ) : (
        // Image Gallery
        <ImageGallery
          slidesPerView={3}
          thumbContainerWidth="82%"
          data={images}
          galleryOptions={galleryOptions}
        />
      )}
    </StyledWrapper>
  );
};

export default LocationContent;
