import * as React from "react";
import { memo } from "react";
const SvgRedo = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.000895359 11.548C-0.00275285 11.6061 0.00477333 11.6643 0.023003 11.719C0.0412327 11.7738 0.0697757 11.8239 0.106854 11.8663C0.143932 11.9087 0.188751 11.9425 0.238516 11.9655C0.288282 11.9885 0.341927 12.0002 0.39611 12C0.496127 11.9989 0.592156 11.9578 0.665298 11.8845C0.73844 11.8113 0.783398 11.7114 0.791324 11.6044C0.874127 9.97474 1.47808 8.42563 2.50131 7.21831C3.52454 6.011 4.90444 5.21935 6.40827 4.9769C7.9121 4.73444 9.44782 5.05602 10.7564 5.88739C12.065 6.71876 13.0664 8.00905 13.5917 9.54063L9.0351 8.32519C8.97954 8.31105 8.92184 8.30953 8.86571 8.32074C8.80957 8.33195 8.75624 8.35563 8.70914 8.39027C8.66203 8.42491 8.6222 8.46974 8.5922 8.52188C8.5622 8.57401 8.54269 8.63231 8.53493 8.69302C8.53168 8.79576 8.56126 8.89661 8.61878 8.97882C8.67629 9.06103 8.75826 9.11966 8.8511 9.14498L14.0135 10.5301C14.1566 10.5681 14.3079 10.5452 14.4355 10.4661C14.5632 10.387 14.6574 10.2579 14.6983 10.1059L15.9889 4.53705C16.0021 4.47748 16.0035 4.4156 15.9931 4.3554C15.9827 4.2952 15.9607 4.23799 15.9285 4.18745C15.8963 4.13691 15.8546 4.09415 15.8061 4.06192C15.7576 4.02968 15.7033 4.00868 15.6468 4.00026C15.5511 3.99677 15.4571 4.02853 15.3805 4.09025C15.3039 4.15197 15.2493 4.23995 15.2257 4.33958L14.1722 8.83417C13.5092 7.19552 12.3506 5.84477 10.8838 5.00033C9.41688 4.15588 7.72748 3.86712 6.08874 4.18073C4.45001 4.49434 2.95773 5.392 1.85323 6.72853C0.748725 8.06507 0.0965607 9.76236 0.00219167 11.5459L0.000895359 11.548Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgRedo);
export default Memo;
