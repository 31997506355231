import styled from "styled-components";

export const Container = styled.div`
  .inner {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .top__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: var(--layout-bg-color);
    position: sticky;
    top: 0;
    z-index: 1;

    &__action {
      /* height: var(--column-top-height); */
      display: flex;

      .btn__view {
        --icon-button-icon-color: var(--font-tint-04);
        border-radius: var(--layout-border-radius-4, 4px);
        border: 1px solid var(--color-primary-50, #e6ebed);
        background: var(--color-white, #fff);

        &:hover {
          opacity: 0.7;
        }
      }
    }

    &__designations {
    }
  }

  .main__content {
    display: flex;
    flex-direction: column;
    /* min-height: 0; */
    /* flex: 1; */
    /* flex-grow: 1; */
    /* width: 100%; */
  }
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--layout-background-n30);
  border-radius: var(--layout-border-radius-8);
  padding: var(--layout-spacing-8);
  margin-block: var(--layout-spacing-16);
`;
