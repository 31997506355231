import React from "react";
import { styleClass } from "../../../utility";
import "./styles.scss";
import useFileUploader from "../../../hooks/useFileUploader";

const ProfileImageUploader = ({
  imageUrl = null,
  onFileUploaded,
  className,
  name = "profile",
}) => {
  //

  const [imgData, setImgData] = React.useState(imageUrl);

  const onFileUploadHandler = (fileArray = []) => {
    if (fileArray.length > 0) {
      setImgData(fileArray[0]?.imageUrl);
    }

    onFileUploaded && onFileUploaded(fileArray);
  };

  const uploader = useFileUploader({
    onFileUpload: onFileUploadHandler,
    name: name,
    maxFileSize: 2, // MB,
    allowTypes: ["image/png", "image/jpeg"],
  });

  React.useEffect(() => {
    if (imageUrl) {
      setImgData(imageUrl);
    }
  }, [imageUrl]);

  const {
    error,
    getUploadClasses,
    onDragStartHandler,
    onDropHandler,
    onDragOverHandler,
    onDragLeaveHandler,
    onChangeHandler,
  } = uploader;

  return (
    <div
      className={styleClass(
        "file__uploader profile__image__uploader",
        getUploadClasses
      )}
    >
      <div className={styleClass("file__uploader__inner", className)}>
        {imgData ? (
          <>
            <div className="image--preview">
              <img src={imgData} alt="" />
            </div>

            <div className="hover-text">
              (Drag & Drop to upload profile image)
            </div>
          </>
        ) : (
          <div className="default-text">
            (Drag & Drop to upload profile image)
          </div>
        )}

        <input
          onDragStart={onDragStartHandler}
          onDrop={onDropHandler}
          onDragOver={onDragOverHandler}
          onDragLeave={onDragLeaveHandler}
          onChange={onChangeHandler}
          type="file"
          id={`file__input__${name}`}
          name={name}
        />
      </div>

      {error ? (
        <div className="error">
          <div className="message">{error}</div>
        </div>
      ) : null}
    </div>
  );
};

export default ProfileImageUploader;
