import produce from "immer";
import { ActionTypes } from "./action.types";
import { SubmissionStatusTypes } from "../../../constant";

export const initialState = {
  selectedCellState: null,
  isLoading: true,
  isAuthor: true,
  isResubmit: false,
  cellId: 0,
  currentState: 0,
  submissionId: 0,
  formValues: {
    subject: "",
    note: "",
    links: [],
    attachments: [],
  },
  changeRequests: [],
  changeLogs: [],
  fileUploadSections: [{ value: "1", label: "Files" }],
};

export const deliverableReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.INITIAL_STATE: {
      return setInitialState(state, action);
    }

    case ActionTypes.SET_API_INITIAL_STATE: {
      return setApiInitialState(state, action);
    }

    case ActionTypes.CHANGE_REQUEST_ITEM_UPDATE: {
      return changeRequestItemUpdate(state, action);
    }

    case ActionTypes.STATE_RESET: {
      return reset();
    }

    default: {
      return state;
    }
  }
};

function setInitialState(state, action) {
  return produce(state, (draft) => {
    const { modalState } = action.payload;

    const submissionId = modalState?.value?.submissionId;

    if (!submissionId) {
      draft.isLoading = false;
    }

    draft.selectedCellState = modalState;
    draft.cellId = modalState?.data?.cellId;
    draft.submissionId = submissionId || 0;
    draft.currentState = modalState?.value?.state || 0;
  });
}

function setApiInitialState(state, action) {
  return produce(state, (draft) => {
    const { isLoading, response } = action.payload;

    draft.isLoading = isLoading;

    if (!response) {
      return;
    }

    draft.submissionId = response?.submissionId;

    draft.isAuthor = response?.isEditMode;

    // Temporary solution // need to change this to filter by backend // Dushan asked to this this way
    const changeRequests = response?.changeRequests?.filter(
      (d) =>
        ![
          SubmissionStatusTypes.Approved,
          SubmissionStatusTypes.Rejected,
        ].includes(d.state)
    );

    draft.changeRequests = changeRequests || [];
    draft.changeLogs = response?.changeLogs || [];
    draft.currentState = response?.state || 0;

    draft.formValues = {
      subject: response?.subject,
      note: response?.note,

      // Map the link data according to the component requirement
      links: response?.links?.map((d) => ({
        id: d?.resourceId,
        url: d?.url || "",
        name: d?.label || "",
      })),

      // Map the attachment data according to the component requirement
      attachments: response?.attachments?.map((d) => ({
        filePath: d?.url || "",
        fileName: d?.label || "",
        localId: d?.resourceId,
        extension: d?.extension || "",
        sectionValue: "1",
        isSorted: true,
      })),
    };
  });
}

function changeRequestItemUpdate(state, action) {
  return produce(state, (draft) => {
    const { changeRequestId } = action.payload;

    draft.changeRequests = [...draft?.changeRequests]?.filter(
      (d) => d?.changeRequestId !== changeRequestId
    );
    if (draft.changeRequests?.length === 0) {
      draft.isResubmit = true;
    }
  });
}

function reset() {
  return initialState;
}
