import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import * as Styled from "./styled";
import BaseDialog from "../../../Components/BaseDialog";
import FieldGroup from "../../../../../common/components/Form/FieldGroup";
// import InputField from "../../../../../common/components/Form/InputField";
import UIButton from "../../../../../common/components/Buttons/UIButton";
import IconAdd from "../../../../../common/components/icons/Line/add-1";
import {
  NewIndividualMemberDialog,
  useChatBaseStore,
  setGenaralChatGroups,
  setMemberChatGroups,
} from "../../../Store/ChatBaseStore";
import SelectField from "./../../../../../common/components/Form/SelectField";
import API_Common from "../../../../../services/api-common";
import API_ENDPOINTS from "../../../../../services/api-endpoints";
import { useGlobalStore } from "../../../../../store/GlobalStore";
import { useAppStore } from "../../../../../store/AppStore/AppStore";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// const initialValues = { name: "" };

// const validationSchema = Yup.object().shape({
//   name: Yup.string().required("Member is required"),
// });

const AddNewIndividualMemberDialog = () => {
  const [members, setMembers] = React.useState([]);
  const visibility = useChatBaseStore((s) => s.isOpenNewIndividualMemberDialog);
  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);

  const chatData = useChatBaseStore((state) => state.chatData);
  const projectId = useGlobalStore((state) => state.selectedProjectId);
  let USER = JSON.parse(localStorage.getItem("user"));
  /**
   * TODO:
   *  Assign dynamic value according to the member selection.
   *  That means a single member or multiple members then.
   *  keep a global reference
   */
  const isMulti = false;

  const initialValues = React.useMemo(() => {
    if (isMulti) {
      return {
        name: [],
      };
    }

    return { name: "" };
  }, [isMulti]);

  const validationSchema = React.useMemo(() => {
    if (isMulti) {
      return Yup.object().shape({
        name: Yup.array().required("Member is required"),
      });
    }

    return Yup.object().shape({
      name: Yup.object()
        .shape({
          value: Yup.string().required(),
          label: Yup.string().required(),
        })
        .nullable()
        .required("Member is required"),
    });
  }, [isMulti]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onAddGroup,
  });

  const {
    errors,
    // values,
    // handleChange,
    handleSubmit,
    touched,
    resetForm,
    setFieldValue,
  } = formik;

  const onClose = React.useCallback(() => {
    NewIndividualMemberDialog().close();
    resetForm();
  }, [resetForm]);

  //---------------------------------------------------------------------------------------

  function onAddGroup(values, { resetForm }) {
    console.log(values);

    try {
      const endpoints = new API_ENDPOINTS();
      showLoader(); //Loading Indicator on

      API_Common.post(endpoints.ADD_NEW_GROUP, {
        groupId: 0,
        groupName:
          values?.name?.label + " and " + USER.result.displayName + " chat",
        groupType: 1,
        visibility: true,
        projectId: projectId,
        lastActivityOn: new Date(),
        isArchived: false,
      }).then((response) => {
        console.log("individual chat group create response::", response);
        if (response.data.isSuccess === true) {
          console.log("SUCCESSS:", response);
          addCurrentUserAsAdminOfGroup(response?.data?.result);
          addChatMember(values?.name?.value, response?.data?.result);
          // toast.success(response.data.displayMessage);
          resetForm();
          onClose();
        } else {
          toast.error(response.data.displayMessage);
        }

        hideLoader(); //Loading Indicator off
      });
    } catch (err) {
      console.log(err);
      toast.error(err);
      hideLoader(); //Loading Indicator off
    }
  }

  const addCurrentUserAsAdminOfGroup = React.useCallback((groupId) => {
    try {
      const endpoints = new API_ENDPOINTS();
      showLoader(); //Loading Indicator on
      let userId = USER.result.userId;
      API_Common.post(endpoints.ADD_NEW_GROUP_MEMBERS_FOR_CHAT, {
        chatGroupMemberId: 0,
        groupId: groupId,
        userId: userId,
        memberType: 1, //Owner
        notReadCount: 0,
        isMemberActive: true,
        joinedDate: new Date(),
        joinedType: 3, //By Self
      }).then((response) => {
        console.log("member added to group as Owner response::", response);
        console.log("Local user data values::", USER);
        if (response.data.isSuccess === true) {
          getChatGroups();
        } else {
          toast.error(response.data.displayMessage);
        }
      });
    } catch (err) {
      console.log(err);
      toast.error(err);
    }
  }, []);

  function addChatMember(userId, groupId) {
    //console.log("vallll: ", values);

    try {
      console.log(userId);
    } catch (err) {
      console.log(err);
    }

    try {
      const endpoints = new API_ENDPOINTS();
      showLoader(); //Loading Indicator on

      API_Common.post(endpoints.ADD_NEW_GROUP_MEMBERS_FOR_CHAT, {
        chatGroupMemberId: 0,
        groupId: groupId,
        userId: userId,
        memberType: 3, //Member
        notReadCount: 0,
        isMemberActive: true,
        joinedDate: new Date(),
        joinedType: 2, //By Admin
      }).then((response) => {
        console.log("member added to group response::", response);
        if (response.data.isSuccess === true) {
          getChatGroups();
          // toast.success(response.data.displayMessage);
          resetForm();
          onClose();
        } else {
          toast.error(response.data.displayMessage);
        }

        hideLoader(); //Loading Indicator off
      });
    } catch (err) {
      console.log(err);
      toast.error(err);
      hideLoader(); //Loading Indicator off
    }
  }

  //-------------------------------------------------------------------------------------------------------------

  const getChatGroups = React.useCallback(() => {
    let userId = USER.result.userId;
    const endpoints = new API_ENDPOINTS();
    API_Common.get(
      endpoints.GET_ALL_CHAT_GROUPS_BY_PROJECT +
        "?projectId=" +
        projectId +
        "&userId=" +
        userId
    ).then((response) => {
      if (response.data.isSuccess === true) {
        //console.log("chat-groups:  ", response.data);
        const memberChatdata = response.data.result.filter((element) => {
          return element.groupType == 1;
        });
        const groupChatdata = response.data.result.filter((element) => {
          return element.groupType == 2;
        });
        console.log("chat-groups:  ", groupChatdata);
        console.log("member chat-groups:  ", memberChatdata);
        setGenaralChatGroups(groupChatdata);
        setMemberChatGroups(memberChatdata);
      }
    });
  }, [projectId]);

  React.useEffect(() => {
    const endpoints = new API_ENDPOINTS();
    fetch(
      process.env.REACT_APP_API_PROJECT_URL +
        endpoints.GET_ALL_PROJECT_MEMBERS_FOR_CHAT +
        "/" +
        projectId
    )
      .then((res) => res.json())
      .then((data) => {
        let filteredUsers = data?.result?.filter(
          (ele, ind) =>
            ind ===
            data?.result?.findIndex(
              (elem) => elem.userId === ele.userId && elem.userId === ele.userId
            )
        );

        const d = filteredUsers?.map((v) => ({
          label: v.memberName,
          value: v.memberUserId,
        }));
        setMembers(d);
      })
      .catch(() => {
        setMembers([]);
      });
  }, []);

  return (
    <BaseDialog
      title="Add New Member"
      visibility={visibility}
      onRootClick={onClose}
      appendToSamePlace
    >
      <Styled.FormWrapper>
        <Styled.Form onSubmit={handleSubmit}>
          <div className="form__controls">
            <FieldGroup error={touched.name && errors.name} id="members">
              <SelectField
                isMulti={isMulti}
                options={members}
                menuPortalTarget={document.getElementById("members")}
                onChange={(value) => setFieldValue("name", value)}
              />
              {/* <InputField
                name="name"
                placeholder="Member Name"
                value={values.name}
                onChange={handleChange}
                autoComplete="off"
              /> */}
            </FieldGroup>
          </div>

          <div className="form__actions">
            <UIButton
              type="submit"
              fullWidth
              size={UIButton.Sizes.Small}
              variant={UIButton.Variant.Primary}
              startIcon={<IconAdd />}
            >
              Add Member
            </UIButton>
          </div>
        </Styled.Form>
      </Styled.FormWrapper>
    </BaseDialog>
  );
};

export default AddNewIndividualMemberDialog;
