import create from "zustand";
import { devtools } from "zustand/middleware";
import { getUserMenuPermission } from "../../Actions/Menu";

const routeFormattedObj = (route) => {
  if (route === undefined) {
    return null;
  }

  return {
    menuId: route?.menuId || null,
    key: route?.key || null,
    path: route?.path || null,
    name: route?.name || null,
    parentId: route?.parentId || null,
    status: route?.status || null,
    children: route?.children || [],
  };
};

let PermissionStore = (set, get) => ({
  menu: [],
  loading: true,
  menuLoadingError: false,

  onLoadAppMenu: (userId, projectId = 0) => {
    set({
      loading: true,
      menuLoadingError: false,
    });

    return new Promise((resolve, reject) => {
      getUserMenuPermission(userId, projectId)
        .then((res) => {
          //
          if (!res.isSuccess) {
            reject({ message: res.displayMessage });

            set({
              loading: false,
              menuLoadingError: true,
              menu: [],
            });
          }

          const formattedData = res?.result?.map((route) => {
            return routeFormattedObj({
              ...route?.menu,
              children: [...route?.childMenuList],
            });
          });

          resolve(formattedData);

          set({
            loading: false,
            menu: formattedData,
            menuLoadingError: false,
          });
        })
        .catch((err) => {
          set({
            loading: false,
            menuLoadingError: true,
            menu: [],
          });

          reject(err);
        });
    });
  },

  findMenuByKey: (routes = [], key) => {
    const route = routes.find((r) => r?.key === key);
    return routeFormattedObj(route);
  },
});

PermissionStore = devtools(PermissionStore);

export const usePermissionStore = create(PermissionStore);
