import React from "react";
import config from "./config";
import BreadcrumbLink from "../../../components/ui-components/Breadcrumb/BreadcrumbLink";

const children = config.children;

const AllMembersScreen = React.lazy(() =>
  import("../screens/AllMembersScreen")
);
const ShortlistScreen = React.lazy(() => import("../screens/ShortlistScreen"));
const HRListScreen = React.lazy(() => import("../screens/HRListScreen"));

export const ChildrenRoutes = [
  /**
   * All Member
   */
  {
    id: children.allMembers.id,
    path: children.allMembers.path,
    element: (
      <React.Suspense fallback={<>Loading...</>}>
        <AllMembersScreen />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <BreadcrumbLink label={children.allMembers.label} />,
    },
    loader: async () => {
      return true;
      // return await permissionLoader(children.planner.id);
    },
  },

  /**
   * Shortlist
   */
  {
    id: children.shortlist.id,
    path: children.shortlist.path,
    element: (
      <React.Suspense fallback={<>Loading...</>}>
        <ShortlistScreen />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <BreadcrumbLink label={children.shortlist.label} />,
    },
    loader: async () => {
      return true;
      // return await permissionLoader(children.planner.id);
    },
  },

  /**
   * HR List
   */
  {
    id: children.hr_list.id,
    path: children.hr_list.path,
    element: (
      <React.Suspense fallback={<>Loading...</>}>
        <HRListScreen />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <BreadcrumbLink label={children.hr_list.label} />,
    },
    loader: async () => {
      return true;
      // return await permissionLoader(children.planner.id);
    },
  },
];
