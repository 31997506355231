import styled, { css } from "styled-components";
import { StyledBaseButton } from "../../../styled/Common/Buttons";

const btnSizeSm = css`
  --btn-space-y: ${({ theme }) => theme.spacing(1.5)};
  --btn-space-x: ${({ theme }) => theme.spacing(3)};
  --btn-icon-size: ${({ theme, iconSize }) => theme.sizes(iconSize || 4)};
`;

const btnSizeDefault = css`
  --btn-space-y: ${({ theme }) => theme.spacing(2)};
  --btn-space-x: ${({ theme }) => theme.spacing(3)};
  --btn-icon-size: ${({ theme, iconSize }) => theme.sizes(iconSize || 4)};
`;

const btnSizeLg = css`
  --btn-space-y: ${({ theme }) => theme.spacing(2.5)};
  --btn-space-x: ${({ theme }) => theme.spacing(3)};
  --btn-icon-size: ${({ theme, iconSize }) => theme.sizes(iconSize || 4)};
`;

const btnSizes = css`
  ${({ size, theme }) => {
    switch (size) {
      case "sm":
        return btnSizeSm;

      case "lg":
        return btnSizeLg;

      default:
        return btnSizeDefault;
    }
  }}
`;

export const StyledButton = styled(StyledBaseButton)`
  /* Button sizes */
  ${btnSizes}
  --btn-bg-color: ${({ bgColor }) => bgColor};
  --btn-text-color: ${({ textColor }) => textColor};
  --btn-font-size: ${({ theme }) => theme.fonts.small};
  --btn-font-weight: ${({ theme }) => theme.fontWeight.bold};
  --btn-radius: ${({ rounded = "full", theme }) => theme.rounded(rounded)};

  border-radius: var(--btn-radius);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight);
  background-color: var(--btn-bg-color);
  color: var(--btn-text-color);
  padding: var(--btn-space-y) var(--btn-space-x);
  line-height: 1.4;

  .btn__ic {
    font-size: var(--btn-icon-size);
    color: var(--btn-text-color);

    &--left {
      margin-right: ${({ theme }) => theme.sizes(2)};
    }
  }

  &:hover {
    --btn-bg-color: ${({ bgColorHover }) => bgColorHover};
    --btn-text-color: ${({ textColorHover }) => textColorHover};
  }

  &:disabled {
    --btn-bg-color: ${({ bgColorDisabled }) => bgColorDisabled};
    --btn-text-color: ${({ textColorDisabled }) => textColorDisabled};
  }
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
