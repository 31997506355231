export const EditorDocumentType = {
  NA: 0,
  ResearchDump: 1,
  BeatSheet: 2,
  ShotList: 3,
  InterviewNotes: 4,
};

export const DragItemTypes = {
  TagCard: "tagCard",
};

export const LOCK_STATUS = {
  Free: 1,
  created: 2,
  Extended: 3,
  Locked: 4,
  Error: 5,
};
