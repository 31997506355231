import styled from "styled-components";
import { fontStyle } from "../../../styled/utility";

export const CalendarWrapper = styled.div`
  padding: var(--layout-spacing-16);
  background-color: var(--layout-background-white);
  display: inline-flex;

  .rdp {
    --rdp-cell-size: var(--layout-spacing-36);
    --rdp-max-width: calc(var(--rdp-cell-size) * 7);
    /*--rdp-accent-color: #0000ff;
    --rdp-background-color: #e7edff;
    --rdp-accent-color-dark: #3003e1;
    --rdp-background-color-dark: #180270;
    --rdp-outline: 2px solid var(--rdp-accent-color);
    --rdp-outline-selected: 3px solid var(--rdp-accent-color); */
    margin: 0;

    .rdp-weeknumber,
    .rdp-day {
      border-radius: 4px;
    }

    .rdp-day {
      ${fontStyle("base-regular")};
    }

    .rdp-weeknumber,
    .rdp-day {
    }

    .rdp-weeknumber {
    }

    .rdp-head_cell {
      ${fontStyle("label-medium")};
      font-weight: 500;
      color: var(--color-secondary-300, #ef8b6d);
      height: auto;
    }

    .rdp-day_today {
      border-radius: var(--layout-border-radius-32, 32px);
      background: var(--color-secondary-75, #f8cfc3);
      color: var(--color-secondary-400, #a7614c);
    }

    .rdp-day_selected {
      background: var(--color-accent-300, #56b5bc);
      color: var(--font-default-white, #fff);
    }
  }
`;
