import React from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getNotificationByUser,
  readNotification,
} from "../Actions/Notification";
import useUserData from "../hooks/useUserData";

export const NOTIFY_ME_KEY = "notify_time";

const notifySelectValues = [
  { key: 10, label: "10min" },
  { key: 15, label: "15min" },
  { key: 20, label: "20min" },
];

const inisialState = {
  isVisible: false,
  count: -1,
  notificationList: [],
  notifyTimes: [],
  selectedNotifyTime: 10,
  onToggleNotification: () => {},
  onNotificationSubmit: (val) => {},
  onReadNotification: (id) => {},
};

export const NotificationContext = React.createContext(inisialState);

export const useNotification = () => {
  return React.useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
  // Local states
  const [isVisible, setIsVisible] = React.useState(false);
  const [notificationList, setNotificationList] = React.useState([]);
  const [notifyTimes] = React.useState(notifySelectValues);
  const [selectedNotifyTime, setSelectedNotifyTime] = React.useState();
  const [count, setCount] = React.useState(-1);

  // Hooks
  const { user } = useUserData();
  const queryClient = useQueryClient();

  // Variables
  const userId = user?.result?.userId;

  // Functions
  const onToggleNotification = () => {
    setIsVisible((prev) => !prev);
  };

  const onNotificationSubmit = (val) => {
    localStorage.setItem(NOTIFY_ME_KEY, val);
    setSelectedNotifyTime(val);
  };

  // Get all notifications
  const { data } = useQuery(
    ["notify", userId, selectedNotifyTime],
    () => {
      return getNotificationByUser(userId).then((res) => res?.result);
    },
    {
      enabled: !!userId,
      /*
        The query will continuously refetch at this frequency in milliseconds
        60000 = 1min
        By default selectedNotifyTime = 10 
        then 60000 * 10 = 10min
     */
      refetchInterval: 60000 * selectedNotifyTime,
      refetchOnWindowFocus: true,
      refetchIntervalInBackground: true,
    }
  );

  // Call to API with use mutaion
  const notificationMutation = useMutation(
    async (id) => {
      return readNotification(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("notify");
      },
    }
  );

  React.useEffect(() => {
    if (data) {
      setNotificationList(data);
      setCount(
        data.reduce((total, item) => total + item.notifications.length, 0)
      );
    }
  }, [data]);

  React.useEffect(() => {
    const time = localStorage.getItem(NOTIFY_ME_KEY);

    if (!time) {
      const defuat = notifySelectValues[0];
      localStorage.setItem(NOTIFY_ME_KEY, defuat.key);
      setSelectedNotifyTime(defuat.key);
    } else {
      setSelectedNotifyTime(time);
    }
  }, []);

  const onReadNotification = (id) => {
    notificationMutation.mutate(id);
  };

  const values = {
    isVisible,
    count,
    notificationList,
    notifyTimes,
    selectedNotifyTime,
    onToggleNotification,
    onNotificationSubmit,
    onReadNotification,
  };

  return (
    <NotificationContext.Provider value={values}>
      {children}
    </NotificationContext.Provider>
  );
};
