import styled, { css } from "styled-components";

const mediaQCss = css`
  @media screen and (min-width: 576px) {
    width: 540px;
  }

  @media screen and (min-width: 768px) {
    width: 720px;
  }

  @media screen and (min-width: 992px) {
    width: 960px;
  }

  @media screen and (min-width: 1200px) {
    width: 1140px;
  }

  @media screen and (min-width: 1400px) {
    width: 1320px;
  }
`;

export const PageContainer = styled.div`
  max-width: 100%;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: var(--layout-spacing-24);
  padding-right: var(--layout-spacing-24);

  ${({ $hasMediaQuery = true }) => ($hasMediaQuery ? mediaQCss : ``)};
`;

export const LayoutContainer = styled.div`
  min-height: inherit;
  width: 100%;

  ${({ bgColor = "accent" }) => `
    background-color: var(--layout-background-${bgColor});
    `};
`;
