import styled from "styled-components";

export const StyledWrapper = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
`;


