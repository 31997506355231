import React from "react";
import { Outlet } from "react-router";
import * as Styled from "./styled";
// import { PageContainer } from "../../styled/Common/Container";

const Template = () => {
  return (
    <Styled.OuterContainer>
      <Styled.Container>
        {/* <PageContainer> */}
          <Outlet />
        {/* </PageContainer> */}
      </Styled.Container>
    </Styled.OuterContainer>
  );
};

export default Template;
