import * as React from "react";
import { memo } from "react";
const SvgDay = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1187_2500)">
      <path
        d="M7.99958 2.93365C6.99747 2.93365 6.01786 3.23081 5.18464 3.78756C4.35141 4.3443 3.70199 5.13562 3.3185 6.06145C2.93501 6.98728 2.83467 8.00604 3.03017 8.9889C3.22568 9.97175 3.70824 10.8746 4.41684 11.5832C5.12544 12.2918 6.02825 12.7743 7.01111 12.9698C7.99396 13.1653 9.01272 13.065 9.93855 12.6815C10.8644 12.298 11.6557 11.6486 12.2124 10.8154C12.7692 9.98214 13.0663 9.00253 13.0663 8.00042C13.0648 6.6571 12.5305 5.36925 11.5806 4.41939C10.6307 3.46952 9.3429 2.9352 7.99958 2.93365ZM7.99958 12.0006C7.20842 12.0006 6.43502 11.766 5.77718 11.3265C5.11935 10.8869 4.60664 10.2622 4.30387 9.53123C4.0011 8.80029 3.92189 7.99598 4.07623 7.22002C4.23058 6.44405 4.61157 5.73128 5.17101 5.17184C5.73045 4.6124 6.44321 4.23142 7.21918 4.07707C7.99514 3.92272 8.79945 4.00194 9.5304 4.30471C10.2613 4.60747 10.8861 5.12019 11.3256 5.77802C11.7652 6.43585 11.9998 7.20925 11.9998 8.00042C11.9998 9.06134 11.5783 10.0788 10.8282 10.829C10.078 11.5792 9.0605 12.0006 7.99958 12.0006Z"
        fill="currentColor"
      />
      <path d="M7.46672 0H8.53328V1.60025H7.46672V0Z" fill="currentColor" />
      <path
        d="M7.46672 14.3998H8.53328V16H7.46672V14.3998Z"
        fill="currentColor"
      />
      <path
        d="M14.3998 7.46672H16V8.53328H14.3998V7.46672Z"
        fill="currentColor"
      />
      <path d="M0 7.46672H1.60025V8.53328H0V7.46672Z" fill="currentColor" />
      <path
        d="M12.4231 13.1776L13.1759 12.4247L14.2425 13.4913L13.4896 14.2442L12.4231 13.1776Z"
        fill="currentColor"
      />
      <path
        d="M1.75668 2.51121L2.50954 1.75835L3.57777 2.82323L2.82491 3.5761L1.75668 2.51121Z"
        fill="currentColor"
      />
      <path
        d="M1.75584 13.4896L2.81905 12.4231L3.57191 13.1759L2.51038 14.2442L1.75584 13.4896Z"
        fill="currentColor"
      />
      <path
        d="M12.4231 2.81905L13.4896 1.7525L14.2425 2.50536L13.1776 3.57693L12.4231 2.81905Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1187_2500">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgDay);
export default Memo;
