import styled from "styled-components";
import { fontStyle } from "../../../../styled/utility";
import { motion } from "framer-motion";

export const DialogWindow = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  left: 0;
  max-height: 100%;
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 8;
`;

export const DialogBackdrop = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  /* background: rgba(0, 0, 0, 0.8); */
  backdrop-filter: blur(5px);
`;

export const DialogContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--layout-spacing-20);
  width: 100%;
  max-width: 100%;
`;

export const DialogContent = styled(motion.div)`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: var(--layout-spacing-20);
  border-radius: var(--layout-border-radius-8, 8px);
  background: var(--color-white, #fff);
  z-index: 9;

  /* layout/boxShadow/primary/10 */
  box-shadow: -3px 8px 15px 0px rgba(45, 89, 102, 0.08);

  .header {
    padding-bottom: var(--layout-spacing-8);

    .title {
      ${fontStyle("label-medium")};
      color: var(--font-default-black);
      font-weight: 500;
      display: flex;
      align-items: center;
      min-width: 0;
    }
  }

  .content {
    display: flex;
  }
`;
