import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./styled";
import { IconBlock } from "../../../../styled/Common/Icons";

const variants = {
  Primary: "primary",
  Secondary: "secondary",
  Success: "success",
  Danger: "danger",
  Info: "info",
};

const sizes = {
  Small: "sm",
  Medium: "md",
  Large: "lg",
};

const UIButton = (props) => {
  const {
    variant = variants.Primary,
    size = sizes.Medium,
    filled = true,
    outlined,
    fullWidth = false,
    children,
    startIcon,
    endIcon,
    rounded = false,
    tooltipText,
    shade,
    hoverShade,
    ...restProps
  } = props;

  const startIconComponent = React.useMemo(() => {
    if (!startIcon) return null;

    return (
      <IconBlock as="span" className="button__ic button__ic__start">
        {startIcon}
      </IconBlock>
    );
  }, [startIcon]);

  const endIconComponent = React.useMemo(() => {
    if (!endIcon) return null;

    return (
      <IconBlock as="span" className="button__ic button__ic__end">
        {endIcon}
      </IconBlock>
    );
  }, [endIcon]);

  return (
    <Styled.ButtonElement
      variant={variant}
      btnSize={size}
      isFilled={filled}
      isOutlined={outlined}
      fullWidth={fullWidth}
      rounded={rounded}
      data-tooltip-text={tooltipText}
      $shade={shade}
      $hoverShade={hoverShade}
      {...restProps}
    >
      {startIconComponent}
      {children}
      {endIconComponent}
    </Styled.ButtonElement>
  );
};

UIButton.Variant = variants;
UIButton.Sizes = sizes;

UIButton.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  filled: PropTypes.bool,
  outlined: PropTypes.bool,
  rounded: PropTypes.bool,
  children: PropTypes.any,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
};

export default UIButton;
