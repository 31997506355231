import React from "react";
import produce from "immer";
import {
  addDays,
  differenceInDays,
  isWithinInterval,
  setDate,
  setDay,
  subDays,
} from "date-fns";
import { useQuery } from "react-query";

// Local imports
import {
  getCalendarDates,
  getMemberAvailability,
} from "../../../../../API/Calendar";
import { MONTHS, getDatesDuration, getYears, dateFormat } from "../helps";
import { ScrollDirection } from "../../../../../constant";
// import { MAX_ROWS_PER_PAGE } from "../../../../../utility";

export const DatesLoadStatus = {
  None: 0,
  DatesInitialized: 1,
  DatesReLoaded: 2,
};

const InitialState = {
  isOpenAssigneePanel: false,
  startDate: "",
  endDate: "",
  currentFocusDate: new Date(),
  dataFetchDate: new Date(),
  datesDuration: null,
  months: MONTHS,
  columnWidth: "202px",
  years: [],
  dates: [],
  columns: [],
  cellData: [],
  availableMembersData: [],
  weeksCount: 0,
  columnTopHeight: 0,
  totalDaysCount: 0,
  isDateFetched: false,
  isDateLoading: undefined,
  isDateFetching: undefined,
  scrollContainer: undefined,
  direction: ScrollDirection.Down,
  episodeId: null,
  updateColumnTopHeight: () => {},
  updateFocusDate: () => {},
  handleLoadData: () => {},
  setScrollContainer: () => {},
  handleUpdateCellData: () => {},
  handleDateChange: (date) => {},
  toggleAssigneePanel: () => {},
};

// Context
export const ScheduleContext = React.createContext(InitialState);

// Hook
export const useScheduleContext = () => React.useContext(ScheduleContext);

// Provider
const ScheduleContextProvider = ({
  children,
  startDate,
  endDate,
  episode = 1,
  txDate,
  projectId,
}) => {
  const [isOpenAssigneePanel, setIsOpenAssigneePanel] = React.useState(false);
  const [columnTopHeight, setColumnTopHeight] = React.useState(0);
  const [years, setYears] = React.useState([]);
  const [dates, setDates] = React.useState([]);
  const [cellData, setCellData] = React.useState([]);
  const [availableMembersData, setAvailableMembersData] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [currentFocusDate, setCurrentFocusDate] = React.useState(new Date());
  const [topOrBottomDate, setTopOrBottomDate] = React.useState(null);
  const [direction, setDirection] = React.useState(null);
  const [scrollContainer, setScrollContainer] = React.useState();
  const [isFirstLoad, setIsFirstLoad] = React.useState(false);
  // const [weekStartDay] = React.useState(0);
  // const [hasUpData, setHasUpData] = React.useState(true);
  // const [hasDownData, setHasDownData] = React.useState(true);

  const intersectionCallbackRef = React.useRef();

  // Note:- Deprecated: weeksCount is not use in the future.
  const [weeksCount] = React.useState(0);

  const handleReset = React.useCallback(() => {
    if (projectId || startDate || endDate || episode || txDate) {
      setDates([]);
      setTopOrBottomDate(null);
      setDirection(null);
      setCurrentFocusDate(new Date());
      setCellData([]);
      setYears([]);
      setIsFirstLoad(false);
    }
  }, [endDate, episode, projectId, startDate, txDate]);

  const updateColumnTopHeight = React.useCallback(() => {
    const el = document.querySelector(".left__area .top__content");

    if (el) {
      setColumnTopHeight(el.getBoundingClientRect().height);
    }
  }, []);

  const datesDuration = React.useMemo(() => {
    return getDatesDuration(startDate, endDate);
  }, [endDate, startDate]);

  // Validation for date query API call
  const isCalendarDatesAPIEnabled = React.useMemo(() => {
    if (
      startDate &&
      endDate &&
      new Date(startDate) < new Date(endDate) &&
      projectId &&
      txDate &&
      topOrBottomDate !== null &&
      direction !== null

      // ? Temporary disabled - 2024-8-9
      // subDays(new Date(startDate), 7) <= new Date(topOrBottomDate) &&
      // addDays(new Date(endDate), 7) >= new Date(topOrBottomDate)
      // -------
      // ((direction === 1 && hasDownData) || (direction === 0 && hasUpData))
    )
      return true;

    return false;
  }, [startDate, endDate, projectId, txDate, topOrBottomDate, direction]);

  const updateColumnData = React.useCallback((columns) => {
    if (!columns) return;

    // Update column data
    const updatedColumns = columns.map((column) => {
      return {
        columnTypeId: column?.columnTypeId,
        columnName: column?.columnName,
        order: column?.order,
        status: column?.status,
      };
    });

    setColumns(updatedColumns);
  }, []);

  const updateDates = React.useCallback((data, __dates, direction) => {
    setDates((prev) => {
      let d = [];

      if (direction === ScrollDirection.Up) {
        d = [...__dates, ...prev];
        // if (d.length >= MAX_ROWS_PER_PAGE + 7) {
        //   d = d.slice(0, d.length - 14);
        // }
      }

      if (direction === ScrollDirection.Down) {
        d = [...prev, ...__dates];
        // if (d.length >= MAX_ROWS_PER_PAGE + 7) {
        //   d = d.slice(14, d.length);
        // }
      }

      let withoutDuplicatesDates = [...new Set(d.map(JSON.stringify))];

      withoutDuplicatesDates = withoutDuplicatesDates.map(JSON.parse);
      const fn = intersectionCallbackRef.current;
      fn && fn(data);

      // Clear
      setDirection(null);
      // setTopOrBottomDate(null);
      intersectionCallbackRef.current = null;

      return withoutDuplicatesDates;
    });
  }, []);

  // Update Cell data
  const updateCellData = React.useCallback((newColumns, direction) => {
    if (!newColumns.length) {
      return;
    }

    setCellData(
      produce((draft) => {
        let isNew = false;

        if (!draft.length) {
          isNew = true;
          return newColumns?.map((c) => ({
            columnTypeId: c?.columnTypeId,
            cellItems: c?.cellData,
          }));
        }

        if (!isNew) {
          draft.forEach((data) => {
            const hasColumn = newColumns?.find(
              (c) => c.columnTypeId === data?.columnTypeId
            );

            if (!hasColumn) {
              return;
            }
            //
            let result = [];

            if (direction === ScrollDirection.Up) {
              result = [...hasColumn?.cellData, ...data?.cellItems];
            }

            if (direction === ScrollDirection.Down) {
              result = [...data?.cellItems, ...hasColumn?.cellData];
            }

            const final = [...new Set(result.map(JSON.stringify))];

            data.cellItems = final.map(JSON.parse);
          });
        }
      })
    );
  }, []);
  // update Available Members Data
  const updateAvailableMembersData = React.useCallback((members) => {
    console.log("updateAvailableMembersData::: ", members);
    if (!members) return;

    //Update member data
    const updatedMembers = members.map((member) => {
      return {
        ...member,
      };
    });

    setAvailableMembersData(updatedMembers);
  }, []);

  const datesQuery = useQuery(
    [
      "get_calendar_dates",
      startDate,
      txDate,
      endDate,
      episode,
      projectId,
      direction,
      topOrBottomDate,
    ],
    () =>
      getCalendarDates({
        projectId,
        episodeId: episode,
        startDate: dateFormat(startDate),
        endDate: dateFormat(endDate),
        txDate: dateFormat(txDate),
        date: dateFormat(new Date(topOrBottomDate)),
        direction,
        // weekStartDay,
      }),
    {
      enabled: isCalendarDatesAPIEnabled,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      // cacheTime: Infinity,
      onSuccess: (data) => {
        console.log("onSuccess sch-calender", data);

        // Clear the previous dates and columns data
        setDates([]);
        setCellData([]);
        setAvailableMembersData([]);

        if (data && data?.dates.length) {
          const __dates = data?.dates;
          const columns = data?.columns;
          updateDates(data, __dates, direction);
          updateColumnData(columns);
          updateCellData(columns, direction);
          updateAvailableMembersData(data?.assignedScheduleItems || []);
          // setHasUpData(data?.isUp);
          // setHasDownData(data?.isDown);
        }
      },
    }
  );

  const totalDaysCount = React.useMemo(() => {
    if (!datesDuration?.startDate) return 0;

    return differenceInDays(datesDuration?.endDate, datesDuration?.startDate);
  }, [datesDuration?.endDate, datesDuration?.startDate]);

  const updateFocusDate = React.useCallback((date) => {
    setCurrentFocusDate(date);
  }, []);

  const handleDateChange = React.useCallback((date) => {
    setDirection(ScrollDirection.Down);
    setTopOrBottomDate(date);
  }, []);

  const toggleAssigneePanel = React.useCallback(() => {
    setIsOpenAssigneePanel((prev) => !prev);
  }, []);

  const updateYears = React.useCallback(() => {
    if (!datesDuration) return;

    const { startDate, years } = datesDuration;

    const allYears = getYears({
      start: startDate,
      numberOfYears: years,
    });

    setYears(allYears);
  }, [datesDuration]);

  const handleLoadData = React.useCallback(
    (params, callback) => {
      if (!Date.parse(new Date(params?.data?.date)) || datesQuery.isLoading) {
        return;
      }

      setDirection(params?.direction);
      setTopOrBottomDate(new Date(params?.data?.date));

      // Assign callback fn
      intersectionCallbackRef.current = (data) => {
        if (!callback) return;

        callback({
          ...params,
          datesPacketSize: data?.dates?.length,
        });
      };
    },
    [datesQuery]
  );

  const handleUpdateCellData = React.useCallback((cellDataArray) => {
    setCellData(
      produce((draft) => {
        cellDataArray.forEach((column) => {
          const hasColumn = draft.find(
            (d) => d.columnTypeId === column?.columnTypeId
          );

          if (!hasColumn) {
            return;
          }

          hasColumn.cellItems = column?.cellData || [];
        });
      })
    );
  }, []);

  // Reset all status
  React.useEffect(() => {
    handleReset();
  }, [handleReset]);

  React.useEffect(() => {
    updateColumnTopHeight();

    window.addEventListener("resize", updateColumnTopHeight);

    return () => {
      window.removeEventListener("resize", updateColumnTopHeight);
    };
  }, [updateColumnTopHeight]);

  // Initialize the columns and dates
  React.useEffect(() => {
    if (!startDate || !endDate) return;

    const currentDate = new Date();
    const sDate = new Date(startDate);
    const eDate = new Date(endDate);

    // console.log("sDate, eDate -------- ", sDate, eDate);

    // let date =
    //   eDate < currentDate || currentDate < sDate ? startDate : currentDate;

    const isValidRange = isWithinInterval(currentDate, {
      start: sDate,
      end: eDate,
    });

    /**
     *
     * cD = 2023-12-06
     *
     * ed+7 < cd - not possible set  sd
     * cd < sd-7  - not possible set sd
     *
     */

    // 2023-12-24 <= 2023-12-06

    let date = isValidRange ? currentDate : sDate;
    // let date = isValidRange ? setDate(currentDate, 1) : sDate;
    setDirection(ScrollDirection.Down);
    setTopOrBottomDate(setDate(date, 1));
    setIsFirstLoad(true);

    updateYears();
  }, [endDate, startDate, updateYears]);

  const values = {
    isOpenAssigneePanel,
    dataFetchDate: topOrBottomDate,
    startDate,
    endDate,
    months: MONTHS,
    columnWidth: "202px",
    currentFocusDate,
    years,
    dates,
    columns,
    cellData,
    availableMembersData,
    datesDuration,
    weeksCount,
    columnTopHeight,
    totalDaysCount,
    updateColumnTopHeight,
    updateFocusDate,
    handleLoadData,
    scrollContainer,
    direction,
    setScrollContainer,
    datesQuery,
    handleUpdateCellData,
    isFirstLoad,
    episodeId: episode,
    setIsFirstLoad,
    handleDateChange,
    toggleAssigneePanel,
  };

  return (
    <ScheduleContext.Provider value={values}>
      {children}
    </ScheduleContext.Provider>
  );
};

export default ScheduleContextProvider;
