import BreadcrumbLink from "../../../../components/ui-components/Breadcrumb/BreadcrumbLink";
import ActivityBlock from "../../screens/Project/pages/Schedule/ActivityBlock";
import config from "../config";

// const children = config.children.scheduleItem.children;
const route = config.children.project.children.schedule.children;

export const ScheduleChildRoutes = [
  {
    id: route.activityBlock.id,
    path: route.activityBlock.path,
    element: (
      <>
        <ActivityBlock />
      </>
    ),
    handle: {
      crumb: () => <BreadcrumbLink label={route.activityBlock.label} />,
    },
    loader: async () => {
      return true;
      //   return await permissionLoader(route.client.id);
    },
  },
];
