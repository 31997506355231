import React from "react";
import ReactDOM from "react-dom";
import * as LineIcon from "../../../../../../common/components/icons/Line";
import { IconBlock } from "../../../../../../styled/Common/Icons";
import Calendar from "./Calendar";

import * as Styled from "./styled";

const ScheduleField = (props) => {
  const {
    buttonText,
    isFullButton = false,
    /**
     * Value object should be like this
     * {
          notAvailable: [{ date: new Date(2023, 2, 1) }],
          confirm: [],
          tentative: [],
          public: [{ date: new Date(2023, 2, 20) }],
        }
     */
    value,
    onSubmit,
    viewMode = true,
    tooltipText = "Schedule",
  } = props;

  const [isShow, setIsShow] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onShowHandler = () => {
    setIsShow(true);
  };

  const onSaveHandler = (data) => {
    setIsShow(false);
    onSubmit && onSubmit(data);
  };

  return (
    <>
      <Styled.ScheduleButton
        ref={setAnchorEl}
        isFullButton={isFullButton}
        data-tooltip-text={tooltipText}
        data-tooltip-placement="right"
        onClick={onShowHandler}
        type="button"
      >
        <IconBlock className="icon">
          <LineIcon.Calender />
        </IconBlock>
        {isFullButton && buttonText ? (
          <div className="text">{buttonText}</div>
        ) : null}
      </Styled.ScheduleButton>

      {isShow
        ? ReactDOM.createPortal(
            <Styled.CalendarPopover
              anchorEl={anchorEl}
              placement="right-start"
              onOutsideClick={viewMode ? () => setIsShow(false) : null}
            >
              <Calendar
                value={value}
                onClose={() => setIsShow(false)}
                onSave={onSaveHandler}
                viewMode={viewMode}
              />
            </Styled.CalendarPopover>,
            document.body
          )
        : null}
    </>
  );
};

export default ScheduleField;
