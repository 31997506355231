import React from "react";

import * as Styled from "./styled";
import { useScheduleContext } from "../../../context/ScheduleContext";
import IconButton from "../../../../../../../../../common/components/Buttons/IconButton";
import IconFlag from "../../../../../../../../../common/components/icons/Scheduler/CellIcons/flag";
//import IconWarning from "../../../../../../../../../common/components/icons/Fill/Warning/01";
import useIcon from "./../../../../../../../../../common/components/IconSelector/hooks/useIcon";

const DataCell = ({ data, onClick, ...otherProps }) => {
  const { dates } = useScheduleContext();
  const findIcon = useIcon();

  const findDataIndex = React.useMemo(() => {
    if (!dates.length || !data.dateId) return -1;

    return dates.findIndex((d) => {
      return d?.dateId === data.dateId;
    });
  }, [data.dateId, dates]);

  const activityIconName = React.useMemo(() => {
    return data?.activities[0]?.itemType?.activityItemType?.icon;
  }, [data?.activities]);

  const ActivityIcon = React.useMemo(() => {
    return findIcon(activityIconName);
  }, [activityIconName, findIcon]);

  if (findDataIndex <= -1) return null;

  return (
    <Styled.Container
      className="data__cell"
      $videoIconColor={
        data?.activities?.length > 0
          ? data?.activities[0]?.itemType?.activityItemType?.iconColour
          : null
      }
      $activityBgColor={
        data?.activities?.length > 0
          ? data?.activities[0]?.itemType?.bgColour
          : null
      }
      $dataIndex={findDataIndex}
      onClick={(e) => {
        if (e.shiftKey) return;

        onClick && onClick(e);
      }}
      {...otherProps}
    >
      <div className="data__cell__inner">
        {/* <div className="start">
          <IconButton
            className="icon__warning"
            icon={<IconWarning />}
            btnIconSize={16}
            btnSize={16}
            as="div"
          />
        </div> */}

        <div className="center">
          <div className="left">
            <span>
              {data?.activities?.length > 0 &&
                data?.activities[0]?.itemType?.itemName}{" "}
              {/* | Day No */}
            </span>
          </div>

          <div className="right">
            {/* <Styled.MoreButton>+2</Styled.MoreButton> */}

            {activityIconName && (
              <IconButton
                as="div"
                className="icon__video"
                icon={<ActivityIcon />}
                btnIconSize={20}
                btnSize={20}
              />
            )}
          </div>
        </div>

        {!!data?.milstones.length && (
          <div className="end">
            <div className="flags">
              {data?.milstones?.map((d, i) => {
                console.log("c data:: ", d);
                return (
                  <FlagElement
                    color={"#" + d?.itemType?.bgColour || null}
                    text={d?.itemType?.shortName || null}
                    key={i}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </Styled.Container>
  );
};

function FlagElement({ color, text }) {
  return (
    <Styled.FlagContainer $flagIconColor={color}>
      <IconFlag />
      <span>{text}</span>
    </Styled.FlagContainer>
  );
}

export default React.memo(DataCell);
