import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.0847 10.0345C13.0269 10.0345 12.1694 10.892 12.1694 11.9498C12.1694 13.0076 13.0269 13.8651 14.0847 13.8651C15.1425 13.8651 16 13.0076 16 11.9498C16 10.892 15.1425 10.0345 14.0847 10.0345ZM14.0847 12.9034C13.5581 12.9034 13.1311 12.4765 13.1311 11.9498C13.1311 11.423 13.558 10.9962 14.0847 10.9962C14.6113 10.9962 15.0383 11.423 15.0383 11.9498C15.0383 12.4765 14.6115 12.9034 14.0847 12.9034Z"
      fill="currentColor"
    />
    <path
      d="M3.07171 10.0345C2.01391 10.0345 1.15642 10.892 1.15642 11.9498C1.15642 13.0076 2.01391 13.8651 3.07171 13.8651C4.1295 13.8651 4.98699 13.0076 4.98699 11.9498C4.98699 10.892 4.1295 10.0345 3.07171 10.0345ZM3.07171 12.9034C2.54513 12.9034 2.11809 12.4765 2.11809 11.9498C2.11809 11.423 2.54495 10.9962 3.07171 10.9962C3.59828 10.9962 4.02533 11.423 4.02533 11.9498C4.02533 12.4765 3.59828 12.9034 3.07171 12.9034Z"
      fill="currentColor"
    />
    <path
      d="M7.38614 10.0345C6.32835 10.0345 5.47086 10.892 5.47086 11.9498C5.47086 13.0076 6.32835 13.8651 7.38614 13.8651C8.44393 13.8651 9.30142 13.0076 9.30142 11.9498C9.30142 10.892 8.44393 10.0345 7.38614 10.0345ZM7.38614 12.9034C6.85957 12.9034 6.43252 12.4765 6.43252 11.9498C6.43252 11.423 6.85939 10.9962 7.38614 10.9962C7.91271 10.9962 8.33976 11.423 8.33976 11.9498C8.33976 12.4765 7.91271 12.9034 7.38614 12.9034Z"
      fill="currentColor"
    />
    <path
      d="M14.0774 9.54223C14.6236 9.54223 15.1291 9.73467 15.5419 10.0618C15.6952 10.1833 15.9099 10.0933 15.9491 9.88887L15.9496 8.41171C15.9496 8.30808 15.9255 8.20605 15.879 8.1135L14.8459 6.05312C14.7893 5.95038 14.6885 5.88784 14.58 5.88784H11.7747C11.6205 5.88784 11.4896 6.01274 11.4665 6.1816L11.4688 10.8446C11.4679 10.9283 11.5289 10.9963 11.6043 10.9963H11.7597C11.8087 10.9963 11.8528 10.9663 11.8766 10.919C12.3076 10.0654 13.1316 9.54223 14.0774 9.54223ZM14.5865 6.49196L15.4888 8.25412H12.6588L12.6547 6.49196H14.5865Z"
      fill="currentColor"
    />
    <path
      d="M7.38614 9.56528C8.36388 9.56528 9.20297 10.1542 9.57105 10.9962H10.42C10.7017 10.9962 10.9301 10.7678 10.9301 10.486V3.51013C10.9301 3.22835 10.7017 3 10.42 3H0.510134C0.228354 3 0 3.22835 0 3.51013V10.4862C0 10.768 0.228354 10.9963 0.510134 10.9963H0.886794C1.25488 10.1544 2.09396 9.56546 3.07171 9.56546C4.02676 9.56546 4.84815 10.1281 5.22892 10.939C5.60969 10.1281 6.43109 9.56528 7.38614 9.56528ZM8.17788 4.7182C8.17788 4.507 8.34905 4.33582 8.56025 4.33582C8.77145 4.33582 8.94263 4.507 8.94263 4.7182V8.35257C8.94263 8.56377 8.77145 8.73495 8.56025 8.73495C8.34905 8.73495 8.17788 8.56377 8.17788 8.35257V4.7182ZM6.1184 4.7182C6.1184 4.507 6.28958 4.33582 6.50078 4.33582C6.71198 4.33582 6.88315 4.507 6.88315 4.7182V8.35257C6.88315 8.56377 6.71198 8.73495 6.50078 8.73495C6.28958 8.73495 6.1184 8.56377 6.1184 8.35257V4.7182ZM2.76384 8.35257C2.76384 8.56377 2.59266 8.73495 2.38146 8.73495C2.17026 8.73495 1.99908 8.56377 1.99908 8.35257V4.7182C1.99908 4.507 2.17026 4.33582 2.38146 4.33582C2.59266 4.33582 2.76384 4.507 2.76384 4.7182V8.35257ZM4.82332 8.35257C4.82332 8.56377 4.65214 8.73495 4.44094 8.73495C4.22974 8.73495 4.05856 8.56377 4.05856 8.35257V4.7182C4.05856 4.507 4.22974 4.33582 4.44094 4.33582C4.65214 4.33582 4.82332 4.507 4.82332 4.7182V8.35257Z"
      fill="currentColor"
    />
    <path
      d="M11.5462 11.2349H9.61215C9.52031 11.2349 9.4458 11.3094 9.4458 11.4012V12.1713C9.4458 12.2634 9.52031 12.3377 9.61215 12.3377H11.5462C11.638 12.3377 11.7125 12.2632 11.7125 12.1713V11.4012C11.7127 11.3094 11.6382 11.2349 11.5462 11.2349Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
