import styled from "styled-components";
import BaseFlexModal from "../../../../../../../common/components/UI/Modal/BaseFlexModal";
import { fontStyle } from "../../../../../../../styled/utility";

export const Modal = styled(BaseFlexModal)`
  --modal-max-width: 664px;

  .header__container {
    padding-bottom: 0;

    .header__inner {
      border-bottom: 1px solid var(--color-neutral-40);
      padding-bottom: var(--layout-spacing-8);
    }
  }

  .header__content {
    display: grid;

    .caption {
      ${fontStyle("small-regular")};
      color: var(--font-default-black);
    }

    .title {
      ${fontStyle("h6-bold")};
      font-weight: 700;
      color: var(--font-color-shade-accent);
    }
  }

  .modal-form {
    padding-top: var(--layout-spacing-16);
    display: grid;

    .start {
      border-bottom: 1px solid var(--color-neutral-40);
      padding-bottom: var(--layout-spacing-16);
      display: grid;
      grid-template-columns: 100px 100px 180px 1fr;
      gap: var(--layout-spacing-12);

      .field__group {
        margin-bottom: 0;
      }
    }

    .end {
      padding-top: var(--layout-spacing-16);
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        min-width: 120px;
      }

      .right {
        display: flex;
        align-items: center;
        gap: var(--layout-spacing-16);
      }
    }

    .event-value {
      width: 100%;
      height: 100%;
      border-radius: var(--layout-border-radius-4);
      background-color: var(--color-neutral-30);
    }
  }
`;
