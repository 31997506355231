import React from "react";
import SkeletonElement, {
  Element,
} from "../../../../../../common/components/UI/SkeletonElement";

const ProjectWidgetLoader = ({ parentRef }) => {
  if (!parentRef) return null;

  const svgWidth = parentRef?.getBoundingClientRect()?.width;
  const itemGap = 24;
  const itemWidth = svgWidth / 3;

  return (
    <SkeletonElement width={svgWidth} height={48}>
      {new Array(3).fill().map((_, i) => {
        return (
          <React.Fragment key={i}>
            <Element
              y={0}
              x={itemWidth * i}
              rx="4"
              ry="4"
              width={itemWidth - itemGap}
              height={48}
            />
          </React.Fragment>
        );
      })}
    </SkeletonElement>
  );
};

export default ProjectWidgetLoader;
