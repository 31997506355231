import styled from "styled-components";
import BaseModal from "../../../../../../../common/components/UI/Modal/BaseModal";

export const Modal = styled(BaseModal)`
  --modal-max-width: 220px;
  position: absolute;
  background-color: transparent;
  height: calc(100% - 1px);
  .outer {
    margin: 0 0 0 auto;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    /* margin-right: auto; */
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .modal__content {
    height: 100%;
    border-radius: var(--layout-border-radius-4);
  }
`;
