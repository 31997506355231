import React from "react";
import ReactDOM from "react-dom";
// import __ from "lodash";

import { SelectionLayer } from "./styled";
import {
  findElementByAttr,
  findParentByClass,
} from "../../../../../../../../../../utility/common";

import {
  ActionTypes,
  initialSelectionState,
  selectionReducer,
} from "./reducer";

import {
  getLayerPosition,
  getLayerStyles,
  isValidChild,
  updateSelectedItems,
} from "./helpers";

const CellSelector = () => {
  const [state, dispatch] = React.useReducer(
    selectionReducer,
    initialSelectionState
  );

  const containerRef = React.useRef();
  const [scrollTop, setScrollTop] = React.useState(0);

  const layerStyles = getLayerStyles(containerRef?.current, scrollTop, state);

  const onMouseMove = React.useCallback(
    (e) => {
      if (!state?.isMousePressed) return;

      let target = e.target;
      target = findElementByAttr(e.target, "selectable");

      if (
        !state?.cellsContainer ||
        !isValidChild(state?.cellsContainer, target)
      ) {
        return;
      }

      // if (target) {
      //   let parent = containerRef?.current;

      //   let parentClientRect = parent.getBoundingClientRect();

      //   let elementClientRect = target.getBoundingClientRect();

      //   const outerSize = getMarginAndBoarderTopSize(parent);
      // }

      // containerRef.current.scrollBy({
      //   top: (containerRef.current.scrollTop += 20),
      //   behavior: "smooth",
      // });

      dispatch({
        type: ActionTypes.MouseMove,
        payload: {
          layerPosition: getLayerPosition(e),
        },
      });
    },
    [state?.cellsContainer, state?.isMousePressed]
  );

  const onMouseUp = React.useCallback(
    (e) => {
      if (state?.isMousePressed) {
        dispatch({
          type: ActionTypes.MouseUp,
        });

        containerRef?.current.removeEventListener("mousemove", onMouseMove);
        containerRef?.current.removeEventListener("mouseup", onMouseUp);
      }
    },
    [onMouseMove, state?.isMousePressed]
  );

  const resetSelectedItems = React.useCallback(() => {
    const els = document.querySelectorAll('[data-selected="true"]');
    [...els].forEach((node) => {
      node.dataset.selected = false;
    });
  }, []);

  const onMouseDown = React.useCallback(
    (e) => {
      // reset state
      dispatch({
        type: ActionTypes.Reset,
      });

      if (e.button !== 0) {
        const els = document.querySelectorAll('[data-selected="true"]');

        if (els.length > 1) {
          return null;
        }

        let target = e.target;
        target = findElementByAttr(e.target, "selectable");

        if (target) {
          resetSelectedItems();
          target.dataset.selected = true;
        }

        return null;
      }

      let isPressed = true;

      // Initialize
      dispatch({
        type: ActionTypes.Init,
        payload: {
          isMousePressed: isPressed,
        },
      });

      setScrollTop(0);

      if (!isPressed) return;

      let target = e.target;
      target = findElementByAttr(e.target, "selectable");

      if (!target) return null;

      const cellsContainer = findParentByClass(e.target, "cell__container");

      if (!isValidChild(cellsContainer, target)) return;

      resetSelectedItems();

      dispatch({
        type: ActionTypes.MouseDown,
        payload: {
          isMousePressed: true,
          layerPosition: getLayerPosition(e),
          width: target.clientWidth,
          maxDrawnWidth: target.clientWidth - e.layerX,
          cellsContainer,
        },
      });
    },
    [resetSelectedItems]
  );

  React.useLayoutEffect(() => {
    containerRef.current = document.querySelector(".scheduler__inner");
  }, []);

  React.useEffect(() => {
    if (!containerRef?.current) return;

    const onScroll = (e) => {
      setScrollTop(containerRef?.current.scrollTop);
    };

    containerRef?.current.addEventListener("scroll", onScroll);
    containerRef?.current.addEventListener("mousedown", onMouseDown);

    if (state?.isMousePressed) {
      containerRef?.current.addEventListener("mousemove", onMouseMove);
      containerRef?.current.addEventListener("mouseup", onMouseUp);
    } else {
      containerRef?.current.removeEventListener("mousemove", onMouseMove);
      containerRef?.current.removeEventListener("mouseup", onMouseUp);
    }

    return () => {
      containerRef?.current.removeEventListener("mousedown", onMouseDown);
      containerRef?.current.removeEventListener("mousemove", onMouseMove);
      containerRef?.current.removeEventListener("mouseup", onMouseUp);
      containerRef?.current.removeEventListener("scroll", onScroll);
    };
  }, [onMouseDown, onMouseMove, onMouseUp, state?.isMousePressed]);

  React.useEffect(() => {
    if (state?.isMousePressed) {
      updateSelectedItems(containerRef?.current, state);
    }
  }, [state]);

  return containerRef?.current
    ? ReactDOM.createPortal(
        <SelectionLayer
          className="selection__layer"
          style={{
            ...layerStyles,
            opacity: layerStyles?.width > 4 ? 1 : 0,
          }}
        />,
        containerRef?.current
      )
    : null;
};

export default CellSelector;
