import React from "react";
import "./styles.scss";

const TableRightActionHeader = () => {
  return (
    <div className="table__action table__action--header ">
      <div title="Create" className="action action--create icon icon-lp-plus icon-14"></div>
      <div title="View" className="action action--view">
        <svg
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="VisibilityOffIcon"
        >
          <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path>
        </svg>
      </div>
      <div title="Edit" className="action action--edit">
        <svg
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="VisibilityOffIcon"
        >
          <path
            d="M16.293 2.293C16.4805 2.10553 16.7348 2.00021 17 2.00021C17.2652 2.00021 17.5195 2.10553 17.707 2.293L21.707 6.293C21.8945 6.48053 21.9998 6.73484 21.9998 7C21.9998 7.26516 21.8945 7.51947 21.707 7.707L8.707 20.707C8.51951 20.8946 8.26519 20.9999 8 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V16C3.00006 15.7348 3.10545 15.4805 3.293 15.293L13.293 5.293L16.293 2.293V2.293ZM14 7.414L5 16.414V19H7.586L16.586 10L14 7.414ZM18 8.586L19.586 7L17 4.414L15.414 6L18 8.586Z"
            fill="black"
          />
        </svg>
      </div>
      <div title="Delete" className="action action--delete icon icon-lp-delete icon-14"></div>
      <div title="Execute" className="action action--execute icon icon-lp-page-setup icon-14"></div>
    </div>
  );
};

export default TableRightActionHeader;
