import React from "react";
import produce from "immer";

import * as Styled from "./styled";
import Typography from "../../../../Typography";
import UploadSection from "./UploadSection";
import { useFileManager } from "../../context/FileManagerContext";

const UploadSections = () => {
  const { value, sections, isPreviewMode } = useFileManager();

  const uploadSections = React.useMemo(() => {
    if (!sections.length) return [];

    return produce(sections, (draft) => {
      draft.forEach((sec) => {
        sec.files = value.filter(
          (file) => file.sectionValue === sec.value && !!file.isSorted
        );
      });
    });
  }, [sections, value]);

  const isFilesAvailable = React.useMemo(
    () => uploadSections.some((s) => s.files.length > 0),
    [uploadSections]
  );

  if (!isFilesAvailable) return null;

  return (
    <Styled.PreviewWrapper>
      {!isPreviewMode ? (
        <div className="preview__top">
          <Typography
            variant={Typography.font_variant.small}
            fontWeight={Typography.font_weight.medium}
            className="main__title"
          >
            Attached Documents
          </Typography>
        </div>
      ) : null}

      <div className="preview__content">
        {uploadSections.map((section, i) => {
          return (
            <UploadSection key={i} title={section.label} list={section.files} />
          );
        })}
      </div>
    </Styled.PreviewWrapper>
  );
};

export default UploadSections;
