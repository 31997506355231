import * as React from "react";
import { memo } from "react";
const SvgPdf = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.7388 4.63545H14.6353L14.7388 4.6213C14.7254 4.5202 14.6789 4.42593 14.6063 4.3526L10.297 0.149515C10.2732 0.125902 10.2469 0.104853 10.2183 0.0868935L10.1976 0.0747903C10.1755 0.0615031 10.1528 0.0492686 10.1293 0.0383483L10.092 0.0282184C10.0727 0.0200618 10.0526 0.0140102 10.032 0.0100641C9.99852 0.00361786 9.96455 0.000263198 9.9305 0H3.01406C2.87675 0 2.7451 0.0532155 2.64796 0.147936C2.55089 0.242657 2.49636 0.371191 2.49636 0.505182V5.93686C2.49636 6.11736 2.59505 6.2841 2.75521 6.37434C2.91537 6.46458 3.11275 6.46459 3.27291 6.37434C3.43306 6.28409 3.53176 6.11734 3.53176 5.93686V1.01642H9.4128V4.682C9.41334 4.81546 9.46774 4.94334 9.56427 5.03793C9.6608 5.13251 9.79164 5.18613 9.92842 5.18718L13.7097 5.20737V14.9896H3.53174V14.2076C3.53174 14.0271 3.43306 13.8604 3.27289 13.7701C3.11273 13.6799 2.91535 13.6799 2.7552 13.7701C2.59504 13.8604 2.49635 14.0271 2.49635 14.2076V15.4948C2.49635 15.6288 2.55088 15.7573 2.64795 15.852C2.74509 15.9468 2.87674 16 3.01405 16H14.2274C14.3647 16 14.4963 15.9468 14.5934 15.852C14.6905 15.7573 14.7451 15.6288 14.7451 15.4948V4.70421C14.745 4.68119 14.7429 4.65814 14.7388 4.63545ZM10.4482 4.17881V1.73173L12.9704 4.19094L10.4482 4.17881Z"
      fill="currentColor"
    />
    <path
      d="M7.12869 9.38614C7.0933 9.31905 7.03229 9.2682 6.95889 9.24472C6.85373 9.21282 6.74378 9.19841 6.63377 9.20229H6.28379V10.9543H6.63377C6.794 10.9689 6.95302 10.916 7.07064 10.8088C7.16784 10.6806 7.22083 10.5256 7.22184 10.3662V10.0024C7.22292 9.87831 7.21739 9.75426 7.20526 9.63066C7.19555 9.54521 7.16954 9.46232 7.12869 9.38614Z"
      fill="currentColor"
    />
    <path
      d="M4.21923 9.19417H3.78438V10.1419H4.21923C4.48846 10.1419 4.62098 9.98022 4.62098 9.64686C4.63453 9.52346 4.60224 9.39928 4.52984 9.29718C4.44659 9.22035 4.33321 9.18273 4.21923 9.19417Z"
      fill="currentColor"
    />
    <path
      d="M11.9143 7.04818H1.45886C1.32155 7.04818 1.1899 7.10139 1.09283 7.19611C0.995696 7.2909 0.941162 7.41937 0.941162 7.55336V12.591C0.941162 12.725 0.995696 12.8535 1.09283 12.9482C1.1899 13.043 1.32155 13.0962 1.45886 13.0962H11.9143C12.0515 13.0962 12.1832 13.043 12.2803 12.9482C12.3774 12.8535 12.432 12.725 12.432 12.591V7.55336C12.432 7.41937 12.3774 7.2909 12.2803 7.19611C12.1832 7.10139 12.0515 7.04818 11.9143 7.04818ZM5.01441 10.4288C4.80093 10.6229 4.51444 10.7217 4.22337 10.7016H3.78439V11.522H3.12588V8.63642H4.22344C4.92961 8.63642 5.28574 8.97794 5.28574 9.64678C5.30468 9.93219 5.20714 10.2133 5.01441 10.4288ZM7.84311 10.6713C7.81426 10.8348 7.75305 10.9913 7.66294 11.132C7.56816 11.2704 7.43018 11.3752 7.26954 11.4311C7.06253 11.4975 6.84534 11.5282 6.62754 11.522H5.62531V8.63641H6.6338C6.84378 8.63148 7.05329 8.65806 7.25504 8.71522C7.41177 8.76014 7.54968 8.85289 7.64844 8.97991C7.74396 9.1068 7.81029 9.25224 7.84311 9.40628C7.88194 9.6129 7.89933 9.82272 7.89488 10.0328C7.89758 10.2467 7.88025 10.4604 7.84311 10.6713ZM10.2494 9.20226H8.9924V9.96002H10.0278V10.5278H8.9924V11.522H8.33389V8.63642H10.2494V9.20226Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgPdf);
export default Memo;
