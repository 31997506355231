import { css } from "../../../../utility";
import "./tab-base-styles.scss";

const TabLink = (props) => {
    TabLink.displayName = "TAB_LINK";

  const { children, className, onClick, linkTo, isActive, isDisabled } = props;

  const isActiveClass = isActive ? "is--active" : null;

  const isDisabledClass = isDisabled ? "is--disabled" : null;

  const onClickHandler = () => {
    if (isDisabled) {
      return;
    }

    onClick(linkTo);
  };

  return (
    <li
      className={css(
        "tab__base__link",
        isActiveClass,
        isDisabledClass,
        className
      )}
      onClick={onClickHandler}
    >
      {children}
    </li>
  );
};

export default TabLink;
