import React from "react";
import ContentLoader from "react-content-loader";

const SkeletonElement = (props) => {
  const {
    width = 100,
    height = 100,
    children,
    backgroundColor = "#ebecef",
    foregroundColor = "#f5f5f7",
    speed = 1.5,
    ...restProps
  } = props;

  return (
    <ContentLoader
      speed={speed}
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
      viewBox={`0 0 ${width} ${height}`}
      {...restProps}
    >
      {children}
    </ContentLoader>
  );
};

export function Element({ x, y, width, height, rx, ry, ...otherProps }) {
  return (
    <rect
      x={x}
      y={y}
      width={width}
      height={height}
      rx={rx}
      ry={ry}
      {...otherProps}
    />
  );
}

export default SkeletonElement;
