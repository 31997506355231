import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import FromGroup from "../../../../controls/FromGroup";

// Form initial values
const initialValues = {
  companyEmail: "",
  password: "",
  healthDetails: "",
  bankDetails: "",
};

// Validation Schema
const validationSchema = Yup.object().shape({
  companyEmail: Yup.string().email("Email must be a valid email address"),
  password: Yup.string().when(
    "companyEmail",
    (val, yup) =>
      val &&
      yup
        .required("This field is required")
        .min(8, "Password must be at least 8 characters")
  ),
  healthDetails: Yup.string().required("This field is required"),
  bankDetails: Yup.string().required("This field is required"),
});

const Form = React.forwardRef((props, ref) => {
  const { onAfterSubmit, formDefaultValues = initialValues } = props;

  React.useImperativeHandle(ref, () => {
    return {
      onFormSubmit: () => {
        formik.handleSubmit();
      },
    };
  });

  const onSubmit = (values, actions) => {
    onAfterSubmit &&
      onAfterSubmit(values, () => {
        actions.resetForm();
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { touched, errors, values, handleChange, setFieldValue } = formik;

  // Set existing form values
  React.useMemo(() => {
    const { companyEmail, password, healthDetails, bankDetails } =
      formDefaultValues;

    setFieldValue("companyEmail", companyEmail);
    setFieldValue("password", password);
    setFieldValue("healthDetails", healthDetails);
    setFieldValue("bankDetails", bankDetails);
  }, [formDefaultValues, setFieldValue]);

  return (
    <form onSubmit={formik.handleSubmit} className="onboarding__form">
      <div className="row">
        <div className="col-12 col-sm-6">
          <FromGroup
            label="Company Email"
            inputHeightAuto
            error={touched.companyEmail && errors.companyEmail}
          >
            <input
              type="text"
              className="form-control"
              name="companyEmail"
              value={values.companyEmail}
              onChange={handleChange}
              autoFocus
            />
          </FromGroup>
        </div>

        <div className="col-12 col-sm-6">
          <FromGroup
            label="Password"
            inputHeightAuto
            error={touched.password && errors.password}
          >
            <input
              type="text"
              className="form-control"
              name="password"
              value={values.password}
              onChange={handleChange}
              disabled={values.email}
            />
          </FromGroup>
        </div>

        <div className="col-12 col-sm-12">
          <FromGroup
            label="Health Details"
            inputHeightAuto
            error={touched.healthDetails && errors.healthDetails}
          >
            <textarea
              name="healthDetails"
              className="form-control textarea-h--xs"
              value={values.healthDetails}
              onChange={handleChange}
            ></textarea>
          </FromGroup>
        </div>

        <div className="col-12 col-sm-12">
          <FromGroup
            label="Bank Details"
            inputHeightAuto
            error={touched.bankDetails && errors.bankDetails}
          >
            <textarea
              name="bankDetails"
              className="form-control textarea-h--xs"
              value={values.bankDetails}
              onChange={handleChange}
            ></textarea>
          </FromGroup>
        </div>

        {/* end */}
      </div>
    </form>
  );
});

export default Form;
