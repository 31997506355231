import React from "react";
import { Wrapper } from "./styled";
import Popover from "../../../../../../../../../common/components/Popover";
import IconButton from "../../../../../../../../../common/components/Buttons/IconButton";
import IconAdd1 from "../../../../../../../../../common/components/icons/Line/add-1";
import SelectionField from "../../../../../../../../../common/components/Form/SelectionField";

const AddColumnPopover = ({ anchorEl, onOutsideClick }) => {
  if (!anchorEl) return null;

  return (
    <Popover
      anchorEl={anchorEl}
      onOutsideClick={onOutsideClick}
      placement="bottom-end"
    >
      <Wrapper>
        <div className="p-inner">
          <div className="p-inner__header">
            <button className="btn__add-column">
              <IconButton
                as={"span"}
                icon={<IconAdd1 />}
                btnSize={16}
                btnIconSize={12}
              />
              Add Column
            </button>
          </div>
          <div className="p-inner__body">
            <div className="column-list">
              <div className="column-list__item">
                <SelectionField text="Pre-Production" />
              </div>
              <div className="column-list__item">
                <SelectionField text="Production" />
              </div>
              <div className="column-list__item">
                <SelectionField text="Post-Production" />
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </Popover>
  );
};

export default AddColumnPopover;
