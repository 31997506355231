import React from "react";
import { TextSm } from "../../styled";
import StatusCell from "./StatusCell";

const EpisodeCell = (props) => {
  const { table, cell, column, row, getValue } = props;

  return <StatusCell {...props}></StatusCell>;
};

export default EpisodeCell;
