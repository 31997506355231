import React from "react";
import { useRouteLoaderData } from "react-router-dom";
import { ROUTE_OPTIONS } from "../../../../../../constant/route-options";
import { SETTING_PERMISSION_MODULES } from "../../../../../../constant/setting_permission_module";
import { Permission } from "../../../../../../helpers/permission";
import { useSettingUserRoleStore } from "../../../../../../store/Setting/Permission";

import UserRolesForm from "./UserRolesForm";
import UserRolesTable from "./UserRolesTable";

const UserRoles = (props) => {
  const { variant = undefined } = props;

  const { permission } = useRouteLoaderData(
    ROUTE_OPTIONS.setting.children["tenant-permission"].id
  );
  const moduleType = SETTING_PERMISSION_MODULES.TenantPermission;
  const permissions = new Permission(permission);
  const userRolePermission = permissions.findByName(moduleType.UserRole.key);

  if (!variant) {
    throw new Error("variant props not defined for role");
  }

  const onLoadUserOptions = useSettingUserRoleStore(
    (state) => state.onLoadUserOptions
  );

  const onLoadRoleOptions = useSettingUserRoleStore(
    (state) => state.onLoadRoleOptions
  );

  const setVariant = useSettingUserRoleStore((state) => state.setVariant);
  const showForm = useSettingUserRoleStore((state) => state.showForm);

  React.useEffect(() => {
    setVariant(variant);
  }, [setVariant, variant]);

  React.useEffect(() => {
    onLoadUserOptions();
    onLoadRoleOptions();
  }, [onLoadUserOptions, onLoadRoleOptions]);

  return (
    <div className="user__roles__wrapper">
      {showForm &&
      (userRolePermission.permission.canAddNew ||
        userRolePermission.permission.canEdit) ? (
        <UserRolesForm />
      ) : null}

      {/* Table */}
      {userRolePermission.permission.canView ? <UserRolesTable /> : null}
    </div>
  );
};

export default UserRoles;
