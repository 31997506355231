import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.5802 0.00767332C10.2686 1.30648 8.6798 2.45711 6.82768 5.62674C6.07792 5.77695 5.33502 5.96478 4.58348 6.10521C3.68408 7.68096 2.77063 9.29318 2 10.6433C2.90271 10.2803 3.80563 9.91787 4.70851 9.55524L5.77333 9.872C4.99159 10.6915 4.53851 11.3904 4.347 12.215C4.1274 13.1608 4.247 14.2398 4.5278 15.8432C4.54685 15.9501 4.66877 16.0249 4.77123 15.9923C6.41108 15.4926 7.40111 15.0648 8.06932 14.3749C8.653 13.7722 8.96033 12.993 9.2398 11.8686L10.1085 12.7088C10.2495 13.681 10.3906 14.6531 10.5312 15.6254C11.4374 14.0378 12.3419 12.4612 13.1249 11.0933C12.8789 10.3627 12.6467 9.62693 12.4095 8.89331C14.2229 5.70744 14.5548 3.81462 13.8404 0.18721C13.8027 0.0314955 13.687 -0.0215458 13.5802 0.00767332ZM13.497 0.465253C14.1629 3.9855 13.8201 5.71268 11.9385 8.95122C11.4192 9.86821 10.3419 10.6868 9.16471 11.3048C7.94722 10.588 7.48017 10.355 6.37672 9.69029C6.32465 8.35878 6.48893 6.99782 7.01377 6.06812C8.8681 2.84549 10.3699 1.78095 13.497 0.465253ZM10.1821 4.3543C9.92091 4.8119 10.0768 5.40228 10.5284 5.66604C10.9801 5.92981 11.5635 5.77118 11.8247 5.31356C12.0859 4.85594 11.93 4.26556 11.4784 4.00178C10.9779 3.72456 10.3931 4.00807 10.1821 4.3543ZM8.66224 7.01708C8.40103 7.4747 8.55688 8.06509 9.00854 8.32888C9.4602 8.59263 10.0436 8.43398 10.3048 7.97634C10.566 7.51873 10.4101 6.92836 9.95849 6.66459C9.48879 6.40537 8.87323 6.67087 8.66224 7.01708ZM6.57455 6.08224C6.09981 7.05099 5.96028 8.30554 5.99278 9.53554C5.55834 9.40985 5.1258 9.27653 4.68999 9.15611C4.08448 9.4 3.47797 9.6412 2.87181 9.88341L4.82595 6.44792L6.57455 6.08224ZM12.1491 9.3377L12.7103 11.0523L10.746 14.4819C10.6508 13.8297 10.5535 13.1779 10.4596 12.5255C10.1391 12.2036 9.80942 11.8904 9.48332 11.574C10.566 10.9885 11.5662 10.2377 12.1491 9.3377Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
