import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.44438 8.00006L0.321906 1.87759C-0.107302 1.44838 -0.107302 0.751196 0.321906 0.322029C0.751279 -0.107343 1.4483 -0.107343 1.8776 0.322029L7.99994 6.44436L14.1224 0.321892C14.5516 -0.107316 15.2488 -0.107316 15.678 0.322053C16.1073 0.751261 16.1073 1.44845 15.678 1.87761L9.55564 8.00008L15.678 14.1224C16.1073 14.5516 16.1073 15.2488 15.678 15.678C15.2488 16.1073 14.5516 16.1073 14.1224 15.678L7.99994 9.55564L1.8776 15.678C1.44823 16.1073 0.75121 16.1073 0.321906 15.678C-0.107302 15.2488 -0.107302 14.5516 0.321906 14.1224L6.44438 8.00006Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
