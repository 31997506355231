export const SubmissionStatusTypes = {
  None: 0,
  Prepared: 1,
  Updated: 2,
  Submitted: 3,
  ChangeRequested: 4,
  Resubmitted: 5,
  Rejected: 6,
  Approved: 7,
};

export const CellTypes = {
  Due: 2,
  Submission: 1,
};
export const SubmissionActions = {
  Submit: 1,
  Resubmit: 2,
  Approved: 3,
  ChangeRequest: 4,
};

export const ColumType = {
  Deliverable: 1,
  Due: 3,
};

export const Units = [
  { label: "None", value: 0 },
  { label: "Day", value: 1 },
  { label: "Week", value: 2 },
  { label: "Months", value: 3 },
];
export const UnitsToDays = {
  0: 0,    //None
  1: 1,    // day
  2: 7,    // week
  3: 30    // month
};

export const Operators = [
  { label: "None", value: 0 },
  { label: "On", value: 1 },
  { label: "Before", value: 2 },
  { label: "After", value: 3 },
];
export function getSubmissionStatusName(value) {
  const statusName = Object.keys(SubmissionStatusTypes).find(
    (key) => SubmissionStatusTypes[key] === value
  );

  return statusName || "Unknown Status"; // Fallback if value is not found
}
