import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.01835 0C7.47603 0.000511376 6.95608 0.216171 6.57261 0.599645C6.18913 0.983119 5.97347 1.50307 5.97296 2.04539V2.07436H4.76775V3.32593H2V16H14.0367V3.32593H11.269V2.07436H10.0637V2.04539C10.0632 1.50307 9.84757 0.983119 9.46409 0.599645C9.08062 0.216171 8.56066 0.000511376 8.01835 0ZM13.071 4.29165V15.0343H2.96572V4.29165H4.76775V5.58571H11.269V4.29165H13.071ZM10.3032 3.04008V4.61999H5.73346V3.04008H6.93868V2.04539C6.93868 1.75904 7.05243 1.48442 7.25491 1.28195C7.45738 1.07947 7.732 0.965717 8.01835 0.965717C8.3047 0.965717 8.57931 1.07947 8.78179 1.28195C8.98427 1.48442 9.09802 1.75904 9.09802 2.04539V3.04008H10.3032ZM4.49155 6.93771H6.13713V7.90343H4.49155V6.93771ZM7.19556 6.93771H11.5451V7.90343H7.19556V6.93771ZM4.49155 8.86915H6.13713V9.83486H4.49155V8.86915ZM7.19556 8.86915H11.5451V9.83486H7.19556V8.86915ZM4.49155 10.8006H6.13713V11.7663H4.49155V10.8006ZM7.19556 10.8006H11.5451V11.7663H7.19556V10.8006ZM4.49155 12.732H6.13713V13.6977H4.49155V12.732ZM7.19556 12.732H11.5451V13.6977H7.19556V12.732Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
