import React from "react";
import { HeaderInfo, Wrapper } from "./styled";
import { useMilestone } from "../../context/MilestoneContext";

const Header = () => {
  const { headerInfo } = useMilestone();
  return (
    <Wrapper>
      <div className="cols">
        <InfoBlock label="Program/ Series Title">
          {headerInfo?.projectName}
        </InfoBlock>
        <InfoBlock label="Episodes">
          {headerInfo?.projectNoOfEpisodes}
        </InfoBlock>
        <InfoBlock label="Duration">{headerInfo?.duration}min</InfoBlock>
      </div>
      <div className="cols">
        <InfoBlock label="Update">0000/00/00</InfoBlock>
      </div>
    </Wrapper>
  );
};

function InfoBlock({ label, children }) {
  return (
    <HeaderInfo>
      <div className="label">{label}</div>
      <div className="content">{children}</div>
    </HeaderInfo>
  );
}

export default Header;
