import React from "react";
import { FileManagerProvider } from "./context/FileManagerContext";
import FileManager from "./FileManager";

const defaultAlowTypes = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "text/plain",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const FileUploadManager = (props) => {
  const {
    uploadSections = [],
    defaultValue = [],
    disabled,
    generalPlaceholderText,
    allowedTypesText = "Drag and drop or click here to upload file/s",
    allowTypes = defaultAlowTypes,
    name = "file__manager__input",
    onChange,
    isPreviewMode,
  } = props;

  const genDescription = React.useMemo(() => {
    const getLabels = uploadSections.map((s) => s.label);
    let text = "Allowed attachments: ";
    let secText = "";

    if (generalPlaceholderText) {
      return generalPlaceholderText;
    }

    if (getLabels.length > 1) {
      const last = getLabels?.pop();
      const others = getLabels?.join(", ");

      secText = `${others} and ${last}.`;

      return `${text} ${secText}`;
    }

    if (getLabels.length === 1) {
      return `${text} ${getLabels?.pop()}.`;
    }

    return allowedTypesText;
  }, [uploadSections, generalPlaceholderText, allowedTypesText]);

  return (
    <>
      <FileManagerProvider
        defaultSections={uploadSections}
        defaultValue={defaultValue}
        disabled={disabled}
        onChange={onChange}
        isPreviewMode={isPreviewMode}
      >
        <FileManager
          name={name}
          allowTypes={allowTypes}
          allowedTypesText={genDescription}
        />
      </FileManagerProvider>
    </>
  );
};

export default FileUploadManager;
