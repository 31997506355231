import React from "react";
import { VewDueWrapper, VewTypeButton } from "../styled";

const ViewDue = () => {
  return (
    <VewDueWrapper>
      <div className="label">View Due</div>
      <div className="buttons">
        <ViewType label="Today" />
        <ViewType label="This week" isActive />
        <ViewType label="Next week" />
      </div>
    </VewDueWrapper>
  );
};

export default ViewDue;

function ViewType({ label, isActive, ...rest }) {
  return (
    <VewTypeButton $isActive={isActive} {...rest}>
      {label}
    </VewTypeButton>
  );
}
