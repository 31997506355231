import styled from "styled-components";
import { responsive } from "../../../../../styled/utility";

export const Card = styled.div`
  background-color: var(--color-neutral-10);
  padding: var(--layout-spacing-16) var(--layout-spacing-16);

  border-radius: var(--layout-border-radius-12);

  max-width: 100%;
  width: 100%;

  @media ${responsive.lg} {
    max-width: min(940px, 75%);
    padding: var(--layout-spacing-28) var(--layout-spacing-28);
  }
`;

export const Header = styled.div`
  padding-bottom: var(--layout-spacing-16);
  border-bottom: 1px solid var(--layout-background-primary);
  display: flex;
  align-items: center;
  /* justify-content: space-between; */

  .end {
    flex: 1;

    .search__wrapper {
      --search-width: auto;
    }
  }

  .title {
    font-weight: 600 !important;
  }
`;

export const Content = styled.div`
  padding-top: var(--layout-spacing-24);
`;
