import * as React from "react";
import { memo } from "react";
const SvgOnboarding01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.0537 0C8.84186 0 10.2938 1.43444 10.2938 3.22259C10.2938 5.01075 8.84186 6.46339 7.0537 6.46339C5.26554 6.46339 3.83212 5.01041 3.83212 3.22259C3.83212 1.43477 5.26554 0 7.0537 0ZM15.7338 3.52034L13.0325 0.670685V1.8819H11.4123V5.15979H13.0325V6.371L14.3746 4.93589L15.7338 3.52034ZM3.42108 7.74845H10.7045C12.0267 7.74845 13.1259 8.84771 13.1259 10.1702V16H1V10.1702C1 8.84771 2.08072 7.74845 3.42108 7.74845Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgOnboarding01);
export default Memo;
