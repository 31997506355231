import * as React from "react";
import { memo } from "react";
const SvgFlipImage = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.33329 9.30383H11.6666C12.2188 9.30383 12.6667 8.76883 12.6667 8.1641V3.7818C12.6667 3.17665 12.2188 2.73038 11.6666 2.73038H10.3333C9.99998 2.73038 9.99998 2 8.99998 2H6.99997C6.3333 2 5.99996 2.73038 5.66663 2.73038H4.33329C3.7811 2.73038 3.33329 3.17665 3.33329 3.7818V8.1641C3.33329 8.76883 3.7811 9.30383 4.33329 9.30383ZM3.99996 3.78183C3.99996 3.57986 4.1491 3.4608 4.33329 3.4608H5.66663C6.3333 3.4608 6.41566 2.73041 7.08233 2.73041H8.99998C9.66664 2.73041 9.66664 3.4608 10.3333 3.4608H11.6666C11.8508 3.4608 12 3.57985 12 3.78183V8.16413C12 8.36587 11.8508 8.57348 11.6666 8.57348H4.33329C4.1491 8.57348 3.99996 8.36585 3.99996 8.16413V3.78183Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99997 8.16413C9.10446 8.16413 9.99998 7.18303 9.99998 5.97298C9.99998 4.76293 9.10446 3.78183 7.99997 3.78183C6.89548 3.78183 5.99997 4.76293 5.99997 5.97298C5.99997 7.18303 6.89548 8.16413 7.99997 8.16413ZM7.99997 4.51221C8.73631 4.51221 9.33331 5.16627 9.33331 5.97298C9.33331 6.77969 8.73631 7.43375 7.99997 7.43375C7.26364 7.43375 6.66663 6.77969 6.66663 5.97298C6.66663 5.16621 7.26364 4.51221 7.99997 4.51221Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66663 9.90153L6.19527 10.4179L6.76461 11.0417C3.30362 10.8074 0.666669 9.60957 0.666669 8.16413C0.666669 7.3881 1.42752 6.68328 2.66668 6.15999V5.44274C1.03056 6.11142 0 7.08245 0 8.16402C0 10.0011 2.97487 11.5166 6.84391 11.7732L6.19542 12.4836L6.66673 13L8.08076 11.4508L6.66663 9.90153Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3333 5.44269V6.15994C14.5723 6.68307 15.3333 7.38807 15.3333 8.16408C15.3333 9.59602 12.7465 10.7868 9.33331 11.0363V11.7645C13.1165 11.4745 16 9.97308 16 8.16386C16 7.08255 14.9693 6.1116 13.3333 5.44269Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgFlipImage);
export default Memo;
