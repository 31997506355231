import React from "react";
import * as Styled from "./styled";

const Steps = ({ activeStep, steps = [] }) => {
  return (
    <Styled.Container>
      <div className="step__card">
        <div className="steps__content">
          <div className="step__header">Steps</div>
          <div className="step__num__holder">
            {steps &&
              steps.map((step, i) => {
                return (
                  <Styled.StepNum
                    key={i}
                    $isActive={step?.isActive}
                    $isCompleted={step?.isCompleted}
                  >
                    {i + 1}
                  </Styled.StepNum>
                );
              })}

            {/* <Styled.StepNum state="current">1</Styled.StepNum>
            <Styled.StepNum state="completed">2</Styled.StepNum>
            <Styled.StepNum>3</Styled.StepNum>
            <Styled.StepNum>4</Styled.StepNum>
            <Styled.StepNum>5</Styled.StepNum>
            <Styled.StepNum>6</Styled.StepNum> */}
          </div>
        </div>
      </div>
    </Styled.Container>
  );
};

export default Steps;
