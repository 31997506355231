import React from "react";
import { StyledAddButton } from "./styled";
import IconAdd from "../../../../common/components/icons/Fill/add";
import IconButton from "../../../../common/components/Buttons/IconButton";

const AddButton = (props) => {
  const {
    label,
    size = "md",
    full = false,
    isLoading,
    onClick,
    disabled,
    ...otherProps
  } = props;
  return (
    <StyledAddButton
      type="button"
      onClick={!isLoading ? onClick : null}
      $size={size}
      $full={full}
      disabled={disabled || isLoading}
      {...otherProps}
    >
      {isLoading ? (
        "Creating..."
      ) : (
        <>
          <IconButton
            as="div"
            icon={<IconAdd />}
            btnIconSize={16}
            btnSize={16}
          />

          {label ? <span className="label">{label}</span> : null}
        </>
      )}
    </StyledAddButton>
  );
};

export default AddButton;
