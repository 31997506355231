import React from "react";
import { Link, NavLink } from "react-router-dom";
import { styleClass } from "../../../../../../../../utility/common";
import "./styles.scss";

const MenuLink = (props) => {
  const { className, onClick, text, to } = props;

  const linkContent = React.useMemo(() => {
    return (
      <>
        <div className="link__text">{text}</div>
        <div className="link__dashed"></div>
        <i
          className={styleClass("icon-arrow icon icon-lp-arrow-right-line")}
        ></i>
      </>
    );
  }, [text]);

  return (
    <li className={styleClass("menu__item__link", className)}>
      {to ? (
        <NavLink className="link" to={to}>
          {linkContent}
        </NavLink>
      ) : (
        <div className="link" onClick={onClick ? onClick : null}>
          {linkContent}
        </div>
      )}
    </li>
  );
};

export default MenuLink;
