import * as React from "react";
import { memo } from "react";
const SvgBookmark = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.60804 15.9972C1.52078 15.9975 1.43452 15.9791 1.35535 15.9434C1.24938 15.896 1.1596 15.82 1.09662 15.7244C1.03365 15.6289 1.00011 15.5178 1 15.4043V3.76844C1.00119 2.76934 1.40947 1.8115 2.13528 1.10503C2.86108 0.398567 3.84513 0.00116242 4.87157 0H10.1284C11.1549 0.00116242 12.1389 0.398567 12.8647 1.10503C13.5905 1.8115 13.9988 2.76934 14 3.76844V15.4076C14.0001 15.5212 13.9666 15.6325 13.9035 15.7281C13.8404 15.8237 13.7504 15.8996 13.6441 15.9467C13.5379 15.9938 13.42 16.0102 13.3044 15.9938C13.1889 15.9775 13.0807 15.9291 12.9926 15.8545L7.49887 11.2175L2.00512 15.8545C1.89518 15.9478 1.75389 15.9986 1.60804 15.9972ZM4.87157 1.18477C4.16786 1.18565 3.49323 1.45813 2.99564 1.94248C2.49804 2.42682 2.21809 3.08348 2.2172 3.76844V14.1097L7.10179 9.98659C7.2122 9.8926 7.35389 9.84081 7.50056 9.84081C7.64724 9.84081 7.78893 9.8926 7.89934 9.98659L12.7828 14.1097V3.76844C12.7819 3.08348 12.502 2.42682 12.0044 1.94248C11.5068 1.45813 10.8321 1.18565 10.1284 1.18477H4.87157Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgBookmark);
export default Memo;
