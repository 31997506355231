import styled from "styled-components";
import { fontStyle } from "../../../../../../styled/utility";

export const MessageWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  padding: 0 var(--layout-spacing-24);
  gap: var(--layout-spacing-8);

  ${({ layoutType }) => {
    if (layoutType === "rtl") {
      return `
        flex-direction: row-reverse;
      `;
    }
  }};

  .message__avatar {
  }

  .message__content__area {
    display: flex;
    flex-direction: column;
    flex: 1;

    &--head {
      margin-bottom: var(--layout-spacing-4);
      flex: 1;
      min-width: 0;
      display: flex;
      align-items: center;
      gap: var(--layout-spacing-8);

      ${({ layoutType }) => {
        if (layoutType === "rtl") {
          return `
            flex-direction: row-reverse;
          `;
        }
      }};

      .avatar__name {
        ${fontStyle("x-small-medium")};
        color: var(--font-default-black);
        font-weight: 500;
        min-width: 0px;
        display: flex;
      }

      .message__time {
        ${fontStyle("x-small-medium")};
        color: var(--font-tint-04);
      }
    }

    &--body {
      display: flex;
      gap: var(--layout-spacing-12);

      ${({ layoutType }) => {
        if (layoutType === "rtl") {
          return `
            flex-direction: row-reverse;
          `;
        }
      }};

      &:hover {
        .action {
          opacity: 1;
        }
      }

      .message {
        ${fontStyle("label-regular")};
        padding: var(--layout-spacing-12) var(--layout-spacing-16);
        border-radius: var(--layout-border-radius-8, 8px);

        ${({ layoutType }) => {
          if (layoutType === "ltr") {
            return `
              border-top-left-radius: 0;
              background: var(--color-white, #fff);
              color: var(--font-default-black);
            `;
          }

          return `
            border-top-right-radius: 0;
            background: var(--color-neutral-40, #DFE0E5);
            color: var(--color-primary-500, #01222B);
          `;
        }};
      }

      .action {
        opacity: 0;

        .menu-action {
          transform: rotate(90deg);
          color: var(--font-tint-04);
        }
      }
    }
  }
`;
