import * as React from "react";
import { memo } from "react";
const SvgContactInfo = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.27273 2.56V3.2H1.31818C1.15909 3.2 1 3.04 1 2.88C1 2.72 1.15909 2.56 1.31818 2.56H2.27273ZM1.31818 5.12C1.15909 5.12 1 5.28 1 5.44C1 5.6 1.15909 5.76 1.31818 5.76H2.27273V5.12H1.31818ZM1.31818 12.8C1.15909 12.8 1 12.96 1 13.12C1 13.28 1.15909 13.44 1.31818 13.44H2.27273V12.8H1.31818ZM1.31818 10.24C1.15909 10.24 1 10.4 1 10.56C1 10.72 1.15909 10.88 1.31818 10.88H2.27273V10.24H1.31818ZM1.31818 7.68C1.15909 7.68 1 7.84 1 8C1 8.16 1.15909 8.32 1.31818 8.32H2.27273V7.68H1.31818ZM15 1.28V14.72C15 15.4239 14.4272 16 13.7273 16H3.54545C2.84551 16 2.27273 15.4239 2.27273 14.72V13.44H3.86364C4.02273 13.44 4.18182 13.28 4.18182 13.12C4.18182 12.96 4.02273 12.8 3.86364 12.8H2.27273V10.88H3.86364C4.02273 10.88 4.18182 10.72 4.18182 10.56C4.18182 10.4 4.02273 10.24 3.86364 10.24H2.27273V8.32H3.86364C4.02273 8.32 4.18182 8.16 4.18182 8C4.18182 7.84 4.02273 7.68 3.86364 7.68H2.27273V5.76H3.86364C4.02273 5.76 4.18182 5.6 4.18182 5.44C4.18182 5.28 4.02273 5.12 3.86364 5.12H2.27273V3.2H3.86364C4.02273 3.2 4.18182 3.04 4.18182 2.88C4.18182 2.72 4.02273 2.56 3.86364 2.56H2.27273V1.28C2.27273 0.576053 2.84551 0 3.54545 0H13.7273C14.4272 0 15 0.576053 15 1.28ZM10.5455 3.84C10.5455 3.48803 10.2591 3.2 9.90909 3.2H9.08185C8.57278 3.2 8.1273 3.48803 7.93642 3.93605C7.55455 4.8 7.04545 5.98397 7.04545 8.00005C7.04545 10.0161 7.55452 11.2001 7.93642 12.0641C8.1273 12.5121 8.60457 12.8001 9.08185 12.8001H9.90909C10.2591 12.8001 10.5455 12.5121 10.5455 12.1601V10.5601C10.5455 10.2081 10.2591 9.92011 9.90909 9.92011H8.95455C8.95455 9.92011 8.31818 9.60011 8.31818 8.00011C8.31818 6.40011 8.95455 6.08011 8.95455 6.08011H9.90909C10.2591 6.08011 10.5455 5.79208 10.5455 5.44011V3.84Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgContactInfo);
export default Memo;
