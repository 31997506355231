import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.00008 16C10.8432 16 13.4955 14.4689 14.9171 12.0031C15.0895 11.708 14.9847 11.3328 14.6956 11.1607C14.4002 10.9884 14.0247 11.0931 13.8525 11.382C12.6463 13.4666 10.4002 14.764 8.00012 14.764C4.2647 14.764 1.23082 11.7325 1.23082 8C1.23082 4.2675 4.2647 1.23601 8.00012 1.23601C10.4002 1.23601 12.6463 2.53342 13.8586 4.618C14.031 4.91311 14.4064 5.01158 14.7017 4.83933C14.9971 4.66707 15.0956 4.29202 14.9232 3.99691C13.4955 1.53108 10.8432 0 8.00008 0C3.58773 0 0 3.58496 0 7.99381C0 12.4027 3.58777 15.9876 8.00008 15.9876V16Z"
      fill="currentColor"
    />
    <path
      d="M7.82162 5.98314C8.06166 5.74329 8.06166 5.35589 7.82162 5.11621C7.69851 4.99319 7.54466 4.93794 7.38463 4.93794C7.2246 4.93794 7.07075 4.99936 6.94764 5.11621L4.48608 7.57584C4.24603 7.81569 4.24603 8.20309 4.48608 8.44277L6.94764 10.9024C7.18768 11.1423 7.57538 11.1423 7.81526 10.9024C8.0553 10.6626 8.0553 10.2752 7.81526 10.0355L6.40605 8.62738L15.3846 8.62721C15.723 8.62721 16 8.35046 16 8.0123C16 7.67413 15.723 7.39739 15.3846 7.39739L6.40605 7.39739L7.81526 5.98929L7.82162 5.98314Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
