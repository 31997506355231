
// 
export const TemplateRoutePath = {
    id: "templates",
    path: "/templates",
    label: "Templates",
  
    AllTemplate: {
      path: "",
      id: "@templates/all",
      label: "",
    },

    CreateTemplate: {
      path: "create",
      id: "@templates/create",
      label: "New Templates",
    },

  };
  