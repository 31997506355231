import styled from "styled-components";

export const TextBase = styled.div`
  ${({
    variant = "large",
    weight = "regular",
    textColor,
    fontStyles = "normal",
  }) => `
        font-size: var(--${variant}-${weight}-font-size);
        font-weight: var(--${variant}-${weight}-font-weight);
        font-family: var(--${variant}-${weight}-font-family);
        line-height: var(--${variant}-${weight}-line-height);
        color: var(--font-${textColor});
        font-style: ${fontStyles};
    `};
`;
