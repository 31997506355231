import React from "react";
import { AnimatePresence, motion } from "framer-motion";

import { useNotification } from "../../context/NotificationContext";
import Popover from "../../common/components/Popover";
import { Container } from "./styled";
import Notifications from "../Notification";

const container = {
  visible: {
    opacity: 1,
    y: 0,
  },
  hidden: {
    opacity: 0,
    y: 20,
  },
};

const MotionContainer = motion(Container, {
  forwardMotionProps: true,
});

const NotificationPopover = () => {
  const { isVisible, count, notificationList } = useNotification();

  const placeElementRef = React.useRef();
  const isShow = placeElementRef.current && isVisible;

  React.useEffect(() => {
    const el = document.querySelector(".btn__notify");
    if (el) {
      placeElementRef.current = el;
    }

    return () => {
      placeElementRef.current = null;
    };
  }, []);

  // if (!isShow) return null;

  return (
    <AnimatePresence exitBeforeEnter>
      {isShow ? (
        <Popover
          anchorEl={placeElementRef.current}
          hideOverlay
          placement="bottom-end"
        >
          <MotionContainer
            key="as"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={container}
          >
            <Notifications />
          </MotionContainer>
        </Popover>
      ) : null}
    </AnimatePresence>
  );
};

export default NotificationPopover;
