import React from "react";

import * as Styled from "./styled";
import LeftPanel from "./components/LeftPanel";
import RightPanel from "./components/RightPanel/index,";
import useContainerDimensions from "../../../../../../hooks/useContainerDimensions";
import { useScheduleContext } from "./context/ScheduleContext";
import { useAppStore } from "../../../../../../store/AppStore/AppStore";
import AssigneePanel from "./components/AssigneePanel";

const SchedulerPanel = () => {
  const containerRef = React.useRef();
  const hideLoader = useAppStore((state) => state.hideLoader);
  const showLoader = useAppStore((state) => state.showLoader);

  const { height } = useContainerDimensions(containerRef);
  const {
    columnTopHeight,
    setScrollContainer,
    isFirstLoad,
    datesQuery,
    dates,
    setIsFirstLoad,
  } = useScheduleContext();

  React.useEffect(() => {
    containerRef?.current && setScrollContainer(containerRef?.current);
  }, [setScrollContainer]);

  React.useEffect(() => {
    if (isFirstLoad) {
      showLoader();

      if (!datesQuery?.isLoading && dates.length) {
        setIsFirstLoad(false);
        hideLoader();
      }
    }
  }, [
    hideLoader,
    dates,
    showLoader,
    isFirstLoad,
    datesQuery?.isLoading,
    setIsFirstLoad,
  ]);

  // React.useEffect(() => {
  //   if (!containerRef?.current) return;

  //   const { scrollHeight, offsetHeight } = containerRef?.current;

  //   const scrollTopPosition = (scrollHeight - offsetHeight) / 2;
  //   // move scroll
  //   containerRef.current.scrollTop = Math.min(scrollTopPosition, 7 * 32);
  // }, []);

  return (
    <>
      <Styled.Container
        ref={containerRef}
        $height={height}
        $columnTopHeight={columnTopHeight}
        className="scheduler__inner"
        onContextMenu={(e) => e.preventDefault()}
        $isLoading={
          !isFirstLoad && (datesQuery?.isLoading || datesQuery?.isFetching)
        }
      >
        <LeftPanel panelHeight={height} />

        <RightPanel />

        <AssigneePanel />
      </Styled.Container>
    </>
  );
};

export default SchedulerPanel;
