import styled from "styled-components";

export const ButtonContainer = styled.div`
  button {
    display: flex;
    width: 599px;
    padding: var(--layout-spacing-8, 8px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: var(--layout-borderRadius-4, 4px);
    border: 1px dashed var(--color-accent-300, #56b5bc);
    background: var(--color-accent-50, #eef8f8);

    color: var(--font-color-shade-accent, #3c7f84);
    /* XSmall/Medium */
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 15.4px */

    &:hover {
      background: var(--color-accent-100, #d1ecec);
      color: var(--color-text-hover, #333333);
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--layout-spacing-8, 8px);
    }

    .text {
      display: flex;
    }

    @media (min-width: 768px) {
      button {
        width: 50%;
      }
    }
  }
`;
