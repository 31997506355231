import React from "react";

import { useNavigation } from "react-day-picker";
import * as Styled from "../styled";
import Months from "./Months";
import Years from "./Years";

const DatePickerHeader = (props) => {
  const { goToMonth } = useNavigation();

  const onChangeHandler = (newMonth) => {
    goToMonth(newMonth);
  };

  return (
    <Styled.UIDatePickerHeader aria-labelledby={`${props.id}`}>
      <Years displayMonth={props.displayMonth} onChange={onChangeHandler} />
      <Months displayMonth={props.displayMonth} onChange={onChangeHandler} />
    </Styled.UIDatePickerHeader>
  );
};

export default DatePickerHeader;
