import React from "react";

import FileDropZone from "../FileDropZone";
import * as Styled from "./styled";
import { UnsortedFiles } from "./components/UnsortedFiles";
import { useFileManager } from "./context/FileManagerContext";
import UploadSections from "./components/UploadSections";

const FileManager = (props) => {
  const { name, allowTypes, allowedTypesText } = props;

  const { disabled, unsortedFiles, onFileUploaded, isPreviewMode } =
    useFileManager();

  return (
    <>
      <Styled.FileUploadManagerWrapper>
        {!isPreviewMode ? (
          <Styled.UploaderWrapper>
            <FileDropZone
              onFileUpload={onFileUploaded}
              disabled={disabled}
              autoServerUpload={true}
              allowedTypesText={allowedTypesText}
              allowTypes={allowTypes}
              name={name}
              // onUploadWithConfirmation={handleConfirmation}
            />
          </Styled.UploaderWrapper>
        ) : null}

        {/*
        ----------------------------------------
          Unsorted List
        ----------------------------------------
        */}

        {unsortedFiles.length > 0 && !isPreviewMode ? (
          <div className="unsorted__list__wrapper">
            <Styled.FileItemList>
              <UnsortedFiles files={unsortedFiles} />
            </Styled.FileItemList>
          </div>
        ) : null}

        {/*
        ----------------------------------------
         Upload Sections
        ----------------------------------------
        */}

        <UploadSections />

        {/* End */}
      </Styled.FileUploadManagerWrapper>
    </>
  );
};

export default FileManager;
