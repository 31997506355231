import React from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";
import * as Styled from "./styled";
import { motion, AnimatePresence } from "framer-motion";

const TooltipMotionWrapper = motion(Styled.TooltipWrapper);

const Tooltip = (props) => {
  const {
    text,
    referenceElement,
    placement,
    offset = { x: 0, y: 8 },
    isShow,
    tooltipAppendTo,
  } = props;

  const [popperElement, setPopperElement] = React.useState(null);

  const [arrowElement, setArrowElement] = React.useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement || "auto",
    modifiers: [
      {
        name: "arrow",
        options: {
          element: arrowElement,
        },
      },
      {
        name: "offset",
        options: {
          offset: [offset.x, offset.y],
        },
      },
    ],
  });

  const RenderComponent = (
    <TooltipMotionWrapper
      ref={setPopperElement}
      role="tooltip"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={styles.popper}
      {...attributes.popper}
    >
      {text}
      <div
        id="tooltip__arrow"
        ref={setArrowElement}
        style={styles.arrow}
        data-popper-arrow
      />
    </TooltipMotionWrapper>
  );

  return ReactDOM.createPortal(
    <AnimatePresence exitBeforeEnter>
      {isShow ? RenderComponent : null}
    </AnimatePresence>,
    tooltipAppendTo || document.body
  );
};

export default Tooltip;
