import * as React from "react";
import { memo } from "react";
const SvgRight = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.48847 13.2689C2.83738 13.8934 2.83738 14.9069 3.48847 15.5314C3.81399 15.8437 4.2403 16 4.66678 16C5.09325 16 5.51958 15.8437 5.84508 15.5312L12.5117 9.13124C13.1628 8.50671 13.1628 7.49318 12.5117 6.86874L5.84508 0.468785C5.19399 -0.156262 4.13939 -0.156262 3.48832 0.468785C2.83723 1.09331 2.83723 2.10685 3.48832 2.73129L8.97653 7.99999L3.48847 13.2689Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgRight);
export default Memo;
