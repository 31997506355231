import React from "react";
import { TextNormal } from "../../../../../../../styled/Common/Typography.styled";
import * as Styled from "./PopoverLayoutStyled";

const PopoverLayout = (props) => {
  const { children, ...otherProps } = props;

  return (
    <Styled.PopoverContainer id="schedule__calendar" {...otherProps}>
      {/* Header */}
      <div className="header">
        <div className="start">
          <TextNormal.SemiBold>Schedule</TextNormal.SemiBold>
        </div>

        <div className="end"></div>
      </div>

      {/* Separate line */}
      <Styled.PopoverDivided />

      {/* Contents */}
      <div className="content">{children}</div>
    </Styled.PopoverContainer>
  );
};

PopoverLayout.PopoverDivided = Styled.PopoverDivided;

export default PopoverLayout;
