import React from "react";

import {
  StyledMessageButton,
  StyledMessageContainer,
  Avatar as StyledAvatar,
} from "./styled";
import AvatarPlaceholderImage from "../../../../assets/img/avatar-placeholder.jpg";
import { IconMessage } from "../../icons";
import { styleClass } from "../../../../utility";

const Avatar = (props) => {
  const {
    size,
    src,
    alt,
    innerClassName,
    className,
    onClick,
    onMessageClick,
    messageBgColor,
    hasShadow,
    placeholderLetter,
  } = props;

  const sizeClass = React.useMemo(() => {
    if (size === "size-20") {
      return `avatar--size-20`;
    }

    if (size === "size-26") {
      return `avatar--size-26`;
    }

    if (size === "size-28") {
      return `avatar--size-28`;
    }

    if (size === "size-36") {
      return `avatar--size-36`;
    }

    if (size === "size-44") {
      return `avatar--size-44`;
    }

    if (size === "size-60") {
      return `avatar--size-60`;
    }

    return "";
  }, [size]);

  const shadowClass = hasShadow ? "avatar--shadow" : null;

  const Component = React.useMemo(() => {
    const onImageError = (e) => {
      e.currentTarget.src = AvatarPlaceholderImage;
    };

    return (
      <StyledAvatar
        className={styleClass("avatar", sizeClass, shadowClass, className)}
        onClick={onClick}
      >
        <div className={styleClass("avatar__inner", innerClassName)}>
          {src || !placeholderLetter ? (
            <img src={src || ""} alt={alt} onError={onImageError} />
          ) : (
            <div className="ph__letter">
              {placeholderLetter?.charAt(0) || "UN"}
            </div>
          )}
        </div>
      </StyledAvatar>
    );
  }, [
    alt,
    className,
    placeholderLetter,
    innerClassName,
    onClick,
    shadowClass,
    sizeClass,
    src,
  ]);

  const renderComponent = React.useMemo(() => {
    if (onMessageClick) {
      return (
        <StyledMessageContainer>
          <StyledMessageButton color={messageBgColor} onClick={onMessageClick}>
            <IconMessage />
          </StyledMessageButton>

          {Component}
        </StyledMessageContainer>
      );
    }
    return Component;
  }, [Component, onMessageClick, messageBgColor]);

  return renderComponent;
};

export default Avatar;
