import styled from "styled-components";
import { MAX_ROWS_PER_PAGE } from "../../../../utility";

export const Container = styled.div`
  --scheduler-panel-h: ${({ $height = 0 }) => `${$height}px`};
  --scheduler-panel-padding-y: var(--layout-spacing-8);

  /* Calendar column */
  --column-top-height: ${({ $columnTopHeight = 0 }) => `${$columnTopHeight}px`};
  --column-main-title-height: var(--layout-size-20);
  --column-border-color: #e9eaed;
  --column-cell-height: 32px;

  /* Calendar column */
  --column--head-text-color: var(--font-tint-04);
  --column-width: var(--layout-size-28);

  --min-scroll-height: ${`calc(${MAX_ROWS_PER_PAGE} * var(--column-cell-height))`};
  --weekend-bg-color: rgba(235, 236, 239, 0.2);

  display: flex;
  flex: 1;
  gap: var(--layout-spacing-16);
  padding-inline: var(--layout-spacing-16);
  margin-top: var(--scheduler-panel-padding-y);
  padding-bottom: var(--scheduler-panel-padding-y);
  min-width: 0;
  overflow-y: auto;
  transition: var(--animate-1);
  will-change: opacity;
  opacity: ${({ $isLoading }) => ($isLoading ? `0.4` : 1)};
  overflow-x: clip;
`;
