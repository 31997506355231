import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 0C5.87825 0 3.8435 0.84285 2.34316 2.34316C0.842819 3.84347 0 5.87825 0 8C0 10.1218 0.84285 12.1565 2.34316 13.6568C3.84347 15.1572 5.87825 16 8 16C10.1218 16 12.1565 15.1572 13.6568 13.6568C15.1572 12.1565 16 10.1218 16 8C16 6.59572 15.6304 5.21615 14.9282 4C14.2261 2.78386 13.2161 1.77387 12.0001 1.07185C10.7839 0.369707 9.40434 0 8 0ZM8 14.8571C6.18133 14.8571 4.43718 14.1347 3.15124 12.8488C1.8653 11.5628 1.14286 9.81867 1.14286 8C1.14286 6.18133 1.8653 4.43718 3.15124 3.15124C4.43718 1.8653 6.18133 1.14286 8 1.14286C9.81867 1.14286 11.5628 1.8653 12.8488 3.15124C14.1347 4.43718 14.8571 6.18133 14.8571 8C14.8571 9.81867 14.1347 11.5628 12.8488 12.8488C11.5628 14.1347 9.81867 14.8571 8 14.8571Z"
      fill="currentColor"
    />
    <path
      d="M11.8342 4.16579C11.7271 4.05936 11.5823 3.9996 11.4313 3.9996C11.2804 3.9996 11.1356 4.05937 11.0285 4.16579L7.99991 7.19436L4.97698 4.16579C4.82989 4.03984 4.62894 3.99692 4.44323 4.05186C4.25751 4.10674 4.11221 4.25204 4.05734 4.43775C4.0024 4.62347 4.04532 4.82442 4.17127 4.9715L7.1942 8.00007L4.16563 11.023C4.04468 11.1266 3.97247 11.2759 3.96635 11.435C3.96016 11.5941 4.02069 11.7485 4.13325 11.8611C4.24581 11.9737 4.40027 12.0342 4.55939 12.028C4.71849 12.0219 4.86777 11.9497 4.97135 11.8287L7.99992 8.80578L11.0229 11.8287C11.1699 11.9547 11.3709 11.9976 11.5566 11.9426C11.7423 11.8878 11.8876 11.7425 11.9425 11.5568C11.9974 11.371 11.9545 11.1701 11.8286 11.023L8.80562 8.00008L11.8286 4.97714C11.9375 4.8706 11.9994 4.725 12.0005 4.57262C12.0015 4.42024 11.9416 4.27383 11.8342 4.16579Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
