import styled from "styled-components";
import BaseModal from "../../../../../../../../shared/components/ui/BaseModal";
// import BaseDialog from "../../../../screens/Tenant/Permission/components/Dialogs/BaseDialog";

export const Modal = styled(BaseModal)`
  --modal-width: 100%;
  --modal-max-width: 690px;

  .modal__content {
    min-height: 0px;
  }
`;
