import styled from "styled-components";
import { fontStyle } from "../../../../../../../../../styled/utility";

export const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  padding: var(--layout-spacing-8, 8px);
  align-items: center;
  justify-content: space-between;

  border-radius: var(--layout-border-radius-4, 4px);
  background-color: ${({ $color }) => $color};

  .text {
    ${fontStyle("x-small-regular")};
    height: var(--layout-size-16);
    color: var(--font-default-black);
  }

  .icon {
    .button__icon {
      color: var(--color-accent-100);
    }
  }
`;
