import React from "react";
import { ItemGroupContainer } from "./styledItemGroup";

const ItemGroup = (props) => {
  const { children, title, count, viewType } = props;

  const render = React.useMemo(() => {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return {
          ...child,
          props: {
            ...child.props,
            viewType,
          },
        };
      }

      return null;
    });
  }, [children, viewType]);

  return (
    <ItemGroupContainer viewType={viewType}>
      <div className="group__header">
        <div className="group__header__title">
          {title}
          {count ? <span>({count})</span> : null}
        </div>
      </div>

      <div className="group__content">{render}</div>
    </ItemGroupContainer>
  );
};

export default ItemGroup;
