import styled from "styled-components";

export const ModalWrapper = styled.div`
  max-height: ${({ modalHeight }) => `${modalHeight}px`};
  min-width: ${({ modalWidth }) => `${modalWidth}px`};
  position: absolute;
  z-index: var(--zindex-modal);
  background-color: #fff;
  box-shadow: 0 4px 21px rgba(0, 0, 0, 0.07);
  user-select: none;
  border-radius: var(--layout-border-radius-16);
  display: flex;
  flex-direction: column;

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .close__btn {
    position: absolute;
    top: var(--layout-spacing-8);
    right: var(--layout-spacing-8);
    color: var(--font-tint-04);
    cursor: pointer;
    z-index: 1;

    &:hover {
      color: var(--font-tint-03);
    }
  }
  .view__btn {
    position: absolute;
    top: var(--layout-spacing-8);
    right: calc(var(--layout-spacing-8) + 55px);
    color: var(--font-tint-04);
    cursor: pointer;
    margin-top: 16px;
    z-index: 2;

    &:hover {
      color: var(--font-tint-03);
    }
  }  

  .draggable__modal__header {
    cursor: move;
  }
  .draggable__modal__content {
    overflow-y: auto;
    min-height: 100px;
  }

  ${({ modalCss }) => modalCss};
`;
