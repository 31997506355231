import React, { useState } from "react";
import ReactDOM from "react-dom";
//import "../../sass/project/project.sass";
import "../../assets/css/project.wizard.css";
import foldermin from "../../img/folder-min.png";
import Modal from "../../controls/modals/modal";
import close_icon from "../../img/close-circle.png";
import NewProject from "./new-project";
import { useNavigate } from "react-router";
import { ROUTES_PATH } from "../../../routes/paths";

const CreateNewProject = (props) => {
  const [show, setShow] = useState(true);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
    } catch (err) {
      console.log(err);
    }
  };

  const newProject = async (e) => {
    e.preventDefault();
    try {
      ReactDOM.unmountComponentAtNode(document.getElementById("containerBody"));
      // ReactDOM.render(
      //   <NewProject showWizard={true} />,
      //   document.getElementById("containerBody")
      // );
      navigate(`${ROUTES_PATH.ProjectRoutePath.path}?showWizard`)
      props.close();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <React.Fragment>
        <Modal
          show={props.show}
          onClose={() => props.close()}
          id="__project_create_popup"
        >
          <div className="content">
            <div className="menu__content" style={{ "minHeight": "90vh" }}>
              <div className="menu__content__panel">
                <div>
                  <span
                    style={{
                      float: "right",
                      width: "0px",
                      cursor: "pointer",
                      marginTop: "5px",
                    }}
                    onClick={() => props.close()}
                  >
                    <img alt="" src={close_icon} height={30} width={30} />
                  </span>
                </div>
                <div className="bigicon">
                  {" "}
                  <i
                    className="icon icon-lp-bx-duplicate icon-35"
                    style={{ border: "0px" }}
                  ></i>
                </div>
                <div className="menu__content__panel__text">
                  <h4>Projects</h4>
                  <p className="menu__content__panel__text__small">
                    All your projects in one place
                  </p>
                  <p className="menu__content__panel__text__medium">
                    if you don't have any project why don't we start
                  </p>
                  <p className="menu__content__panel__text__medium">
                    from scratch and build our project gradually
                  </p>
                </div>
                <div className="menu__content__panel__btns">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={newProject}
                  >
                    Create a new project
                  </button>
                  <p>or</p>
                  <a href="#">get start using a template </a>
                </div>
              </div>
              <div className="demo_btn">
                <a className="btn">
                  {" "}
                  <i className="icon icon-bs-film icon-13"> </i>Watch Demo
                </a>
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>

      {/* <React.Fragment>
        <Modal show={props.show} onClose={() => props.close()}>
          <div className="content">
            <div className="_wrapper">
              <div>
                <span
                  style={{
                    float: "right",
                    width: "0px",
                    cursor: "pointer",
                    marginTop: "5px",
                  }}
                  onClick={() => props.close()}
                >
                  <img src={close_icon} height={30} width={30} />
                </span>
              </div>
              <div className="p-logo">
                <img src={foldermin} alt="" width={50} height={50} />
              </div>
              <div className="title_container">
                <h2 className="t1">Projects</h2>
                <h3 className="t1-sub">All of your projects in one place</h3>
              </div>
              <div className="desc">
                <label>
                  If you don't have any project, why don't you start <br />
                  from scratch and build our project gradually
                </label>
              </div>
              <div id="container">
                <div>
                  <a className="create-new-btn" onClick={newProject}>
                    Create a new project
                  </a>
                </div>
                <div className="container-m"> &nbsp;&nbsp; or</div>
                <div className="container-r">
                  <a href="#" className="opt">
                    get start using a template
                  </a>
                </div>
              </div>
              <div id="wv">
                <button className="watchdemo-button">
                  <i className="fa-solid fa-film"></i>&nbsp;&nbsp; Watch Demo
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment> */}
    </>
  );
};

export default CreateNewProject;
