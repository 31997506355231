import React from "react";

import AuthCardLayout from "../../components/AuthLayout";
import Form from "./Form";

const ResetPassword = () => {
  return (
    <AuthCardLayout
      title="Reset your password"
      subTitle="create more secure password to protect yourself"
    >
      <Form />
    </AuthCardLayout>
  );
};

export default ResetPassword;
