import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";

import { getResearchDocumentsByProjectId } from "../../../../api";
import BaseDashboardBlock from "../../BaseDashboardBlock";
import DocumentCard from "../../../../../research-board/components/common/Modals/CreateNewDocumentModal/components/DocumentLists/DocumentCard";
import useIcon from "./../../../../../../common/components/IconSelector/hooks/useIcon";
import useResearchSearchParams from "../../../../../../hooks/useResearchSearchParams";
import { setGlobalProject } from "../../../../../../store/GlobalStore";
import { useAppStore } from "../../../../../../store/AppStore/AppStore";
import { ROUTE_OPTIONS } from "../../../../../../constant/route-options";

const DocumentWidget = ({ projectId }) => {
  const navigate = useNavigate();
  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);
  const findIconByName = useIcon();

  const { navigateResearchTab } = useResearchSearchParams();
  const [count] = React.useState(12);

  const gotoResearchDocuments = (e) => {
    try {
      showLoader();
      setGlobalProject(projectId).then(() => {
        hideLoader();
        navigate(
          `${ROUTE_OPTIONS.research_board.path}/documents?documentsDialog=show`
        );
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Get documents
  const { isLoading, data = [] } = useQuery(
    ["d_docs", projectId, count],
    () => getResearchDocumentsByProjectId(projectId, count),
    {
      enabled: !!projectId,
    }
  );

  return (
    <BaseDashboardBlock
      title="Documents"
      blockSpan={2}
      blockClassName="documents"
    >
      <div className="tab">
        <div className="tab_item disabled">Recently Work on</div>
        <div className="tab_item current">All Documents</div>
      </div>

      <div className="tab__content">
        {isLoading ? (
          <>Loading...</>
        ) : (
          <>
            {data?.length > 0 ? (
              <>
                {data.slice(0, 10).map((d, i) => {
                  // Get document icon
                  const Icon = findIconByName(d?.documentTypeIcon);

                  return (
                    <DocumentCard
                      key={i}
                      hideHeaderAction
                      name={d?.documentName}
                      documentIcon={d?.documentTypeIcon ? <Icon /> : null}
                      templateName={d?.documentTypeName}
                      onClick={(e) => {
                        navigateResearchTab(d);
                      }}
                    />
                  );
                })}

                {/* More button  */}
                {data.length >= 10 && (
                  <DocumentCard
                    className="more"
                    hideHeaderAction
                    name="More"
                    templateName="Click here to view more"
                    onClick={(e) => {
                      gotoResearchDocuments();
                    }}
                  />
                )}
              </>
            ) : (
              "No documents available. "
            )}
          </>
        )}
      </div>
    </BaseDashboardBlock>
  );
};

export default DocumentWidget;
