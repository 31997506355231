import * as React from "react";
import { memo } from "react";
const SvgShot = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2507_15852)">
      <path
        d="M8.0026 15.9999C6.42043 16.0006 4.87366 15.5318 3.55791 14.653C2.24214 13.7741 1.21657 12.5247 0.611079 11.0626C0.00547793 9.60067 -0.152839 7.99184 0.155975 6.43974C0.46486 4.88765 1.22706 3.46211 2.34619 2.34343C3.84633 0.842953 5.88102 0 8.00271 0C10.1244 0 12.1589 0.842967 13.6592 2.34343C15.1595 3.84389 16.0021 5.87905 16.0021 8.00122C16.0021 10.1234 15.1594 12.1584 13.6592 13.659C12.9181 14.404 12.0365 14.9945 11.0655 15.3964C10.0946 15.7982 9.05361 16.0034 8.00285 16L8.0026 15.9999ZM8.0026 1.06903C6.16496 1.0697 4.4028 1.8003 3.10368 3.1002C1.80447 4.40009 1.07468 6.16287 1.07454 8.00093C1.07443 9.83898 1.80414 11.6018 3.10326 12.9018C4.40292 14.2011 6.16524 14.931 8.0026 14.931C9.83996 14.931 11.6024 14.2011 12.9019 12.9018C14.201 11.6018 14.9307 9.83898 14.9307 8.00093C14.9305 6.16287 14.2007 4.40018 12.9015 3.1002C11.6024 1.8003 9.84024 1.06974 8.0026 1.06903Z"
        fill="currentColor"
      />
      <path
        d="M8.0026 11.2007C7.2625 11.2013 6.54511 10.9453 5.9726 10.4761C5.4001 10.007 5.00798 9.35387 4.86298 8.62798C4.71804 7.9021 4.82916 7.1484 5.17752 6.49528C5.52589 5.84217 6.08989 5.3301 6.77348 5.04628C7.45699 4.7625 8.21775 4.72456 8.92617 4.93891C9.63456 5.15332 10.2467 5.60675 10.6583 6.22193C11.0699 6.83716 11.2555 7.57608 11.1835 8.31281C11.1114 9.04957 10.7862 9.73849 10.2632 10.2622C9.66384 10.8624 8.85077 11.2 8.00271 11.2007L8.0026 11.2007ZM8.0026 5.86823C7.43671 5.86779 6.89381 6.09237 6.4935 6.49246C6.09322 6.89256 5.86835 7.4354 5.86841 8.00147C5.86847 8.56749 6.09343 9.11029 6.49383 9.51033C6.9004 9.89827 7.44069 10.1147 8.0025 10.1147C8.56431 10.1147 9.10467 9.89827 9.51117 9.51033C9.91157 9.11029 10.1365 8.56749 10.1366 8.00147C10.1366 7.43544 9.9118 6.8926 9.5115 6.49246C9.11121 6.09235 8.56831 5.86778 8.0024 5.86823H8.0026Z"
        fill="currentColor"
      />
      <path
        d="M9.50871 9.50851L14.4396 4.5765L15.1935 5.33059L10.2626 10.2626L9.50871 9.50851Z"
        fill="currentColor"
      />
      <path
        d="M4.57738 1.56133L5.33131 0.807232L10.2622 5.73924L9.50828 6.49334L4.57738 1.56133Z"
        fill="currentColor"
      />
      <path
        d="M0.808918 10.6717L5.73982 5.73969L6.49374 6.49378L1.56284 11.4258L0.808918 10.6717Z"
        fill="currentColor"
      />
      <path
        d="M5.7403 10.2632L6.49423 9.50907L11.4251 14.4411L10.6712 15.1952L5.7403 10.2632Z"
        fill="currentColor"
      />
      <path
        d="M9.88602 6.11594C10.927 7.15716 10.927 8.8453 9.88602 9.88648C8.84503 10.9277 7.15728 10.9277 6.11633 9.88648C5.07535 8.84526 5.07535 7.15712 6.11633 6.11594C7.15732 5.07472 8.84508 5.07472 9.88602 6.11594Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2507_15852">
        <rect
          width={16}
          height={16}
          fill="white"
          transform="translate(0.00213623)"
        />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgShot);
export default Memo;
