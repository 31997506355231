import styled from "styled-components";
import { StyledIconButton } from "../../../../../../styled/Common/Buttons";

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: var(--spacing-13);
  gap: var(--spacing-4);

  .divider {
    width: 1px;
    height: 24px;
    background-color: ${({ theme }) => theme.colors.color_1(10)};
  }

  .episode-selector {
    width: 134px;
  }
`;

export const StyledButton = styled(StyledIconButton)`
  --bx-btn-icon-color: ${({ theme }) => theme.actionColors.action_color_0()};

  .icon {
    color: var(--bx-btn-icon-color);
  }
`;
