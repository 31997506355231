import styled from "styled-components";
import { fontStyle } from "../../../../styled/utility";

import WizardModal from "../../../../common/components/UI/Modal/WizardModal";

export const ScheduleWizardModal = styled(WizardModal)`
  &.step_03 .form__card {
    background-color: transparent;

    .form__wrapper {
      padding: 0;
    }

    .form__button__wrapper {
      background-color: var(--layout-background-n20);
      border-radius: var(--layout-border-radius-8);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
`;

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: var(--layout-spacing-20);
  grid-column-gap: var(--layout-spacing-16);

  .grid-item-3 .grid-item-4 {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .field__group {
    ${fontStyle("small-medium")};
  }

  .grid-item {
    &.full {
      ${"" /* grid-column: 1/-1; */}
      grid-column: 1/-1;
    }

    &.half {
    }
  }
`;
