import API_ENDPOINTS from "../../../../../services/api-endpoints";
import API_Tenant from "../../../../../services/api-tenant";

export const getAllGenres = () => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Tenant.get(`${endpoints.PROJECT_GENRES_GETALL_BY_TENANT}`)
      .then((res) => {
        console.log("Genres :::", res);

        resolve(res.data.result);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
export const getAllGenresAsOptionSource = () => {
  return new Promise((resolve, reject) => {
    getAllGenres()
      .then((res) => {
        var mapedObjectList =
          res?.map((obj) => ({
            label: obj?.genresName || "",
            value: obj?.genresId || 0,
          })) || [];
        console.log("Genres options :::", mapedObjectList);

        resolve(mapedObjectList);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
