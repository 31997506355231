import React from "react";
import { ButtonContainer } from "./styled";

const IconButton = ({ text, icon, handleOnClick, ...props }) => {
  return (
    <ButtonContainer>
      <button {...props} onClick={handleOnClick}>
        {icon && (
          <span className="icon">
            {icon}
            {text && <span className="text">{text}</span>}
          </span>
        )}
      </button>
    </ButtonContainer>
  );
};

export default IconButton;
