import React from "react";

const useUserRolesData = () => {
  const data = JSON.parse(localStorage.getItem("user-roles")) || undefined;

  return React.useMemo(() => {
    return {
      userRoles: data,
    };
  }, [data]);
};

export default useUserRolesData;
