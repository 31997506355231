import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import FromGroup from "../../../../controls/FromGroup";
import LineInputGroup from "../../../Form/LineInputGroup";
import { useAppStore } from "../../../../../store/AppStore/AppStore";

const initialValues = {
  role: "",
  template: "",
  fee: "",
  instructions: "",
};

// Validation Schema
const validationSchema = Yup.object().shape({
  role: Yup.string().required("This field is required"),
  template: Yup.string().required("This field is required"),
  fee: Yup.string().required("This field is required"),
  //   instructions: Yup.string().required("This field is required"),
});

const Form = (props, ref) => {
  // Props
  const { onSubmittedValues, projectId, member } = props;

  const jobTitles = useAppStore((state) => state.jobTitles);

  const projectAssignTemplates = useAppStore(
    (state) => state.projectAssignTemplates
  );

  // Fn
  const getProjectAssignTemplates = useAppStore(
    (state) => state.getProjectAssignTemplates
  );

  const joinAsJobTitleId = member?.projectRoleId || 0;

  React.useImperativeHandle(ref, () => ({
    onSubmit: () => {
      formik.handleSubmit();
    },
  }));

  const onSubmit = (values) => {
    onSubmittedValues && onSubmittedValues(values);
    resetForm();
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const {
    touched,
    errors,
    values,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = formik;

  React.useEffect(() => {
    getProjectAssignTemplates(projectId);
  }, [getProjectAssignTemplates, projectId]);

  React.useEffect(() => {
    if (joinAsJobTitleId) {
      setFieldValue("role", joinAsJobTitleId);
    }
  }, [setFieldValue, joinAsJobTitleId]);

  return (
    <form className="inputs__wr" onSubmit={handleSubmit}>
      <LineInputGroup label="Job Title" error={touched.role && errors.role}>
        <select
          className="select"
          name="role"
          value={values.role}
          onChange={handleChange}
        >
          <option value="">Select</option>
          {/* render job titles */}
          {jobTitles.map((j, i) => (
            <option key={i} value={j.jobTitleId}>
              {j.label}
            </option>
          ))}
        </select>
      </LineInputGroup>

      <LineInputGroup
        label="Template"
        error={touched.template && errors.template}
      >
        <select
          className="select"
          name="template"
          value={values.template}
          onChange={handleChange}
        >
          <option value="">Select</option>
          {/* Render templates by project id */}
          {projectAssignTemplates.map((t, i) => (
            <option key={i} value={t.templateId}>
              {t.templateName}
            </option>
          ))}
        </select>
      </LineInputGroup>

      <LineInputGroup label="Fee" error={touched.fee && errors.fee}>
        <input
          type="text"
          value={values.fee}
          name="fee"
          placeholder="Hiring Fee"
          onChange={handleChange}
          maxLength={8}
        />
      </LineInputGroup>

      <FromGroup
        className="grid-col-1/3"
        label="HR Instructions"
        inputHeightAuto
        error={touched.instructions && errors.instructions}
      >
        <textarea
          className="form-control"
          name="instructions"
          value={values.instructions}
          onChange={handleChange}
          maxLength={500} //This must change after database table filde length is updated
        ></textarea>
        <div
          style={{
            position: "absolute",
            top: "-15px",
            right: "3px",
            fontSize: "12px",
            color: "inherit",
          }}
        >
          {values.instructions.length}/{500}
        </div>
      </FromGroup>
    </form>
  );
};

export default React.forwardRef(Form);
