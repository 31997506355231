import styled from "styled-components";
import { fontStyle } from "../../../../../../styled/utility";

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const VewDueWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: var(--layout-spacing-16);
  align-items: center;

  .label {
    ${fontStyle("small-medium")};
    font-weight: 500;
    color: var(--color-primary-300);
  }

  .buttons {
    gap: var(--layout-spacing-8);
    display: flex;
    align-items: center;
  }
`;

export const VewTypeButton = styled.button`
  ${fontStyle("small-regular")};
  display: flex;
  align-items: center;
  border: 1px solid #c1c4ce;
  background-color: var(--color-neutral-10);
  outline: none;
  border-radius: var(--layout-border-radius-4);
  padding: var(--layout-spacing-4) var(--layout-spacing-20);
  color: var(--color-neutral-50);

  ${({ $isActive }) => {
    if ($isActive) {
      return `
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            color: var(--color-neutral-10);
            border-color:var(--color-accent-200);
            background-color: var(--color-accent-200);
        `;
    }
  }};

  ${({ $isActive }) => {
    if (!$isActive) {
      return `
            &:hover {
                color: var(--color-primary-300);
            }
        `;
    }
  }};
`;
