import styled from "styled-components";
import IconButton from "../../../common/components/Buttons/IconButton";
import { StyledBoxButton } from "../../../styled/Common/Buttons";

export const ChatToggleButton = styled(IconButton)`
  position: fixed;
  right: var(--layout-spacing-16);
  bottom: var(--layout-spacing-20);
  z-index: 99;
  background-color: var(--layout-background-white);
  border-radius: 999px;
  box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.129);
  transition: var(--animate-1);
  opacity: 1;

  .button__icon {
    /* --icon-size: 24px; */
    --icon-button-icon-color: var(--font-color-shade-primary);
  }

  &:hover {
    box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.2);
  }

  &:active {
    background-color: var(--layout-background-n40);
    transition: var(--animate-1);
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.5);
  }

  ${({ $visibility }) => {
    if ($visibility) {
      return `
        transform: translateY(100px);
        opacity: 0;
      `;
    }
  }}
`;


export const MinimizeButton = styled(StyledBoxButton)`
  transition: var(--animate-1);
  opacity: var(--layout-opacity-50);

  &:hover {
    span {
      opacity: 0.5;
    }
  }

  span {
    width: 16px;
    display: flex;
    height: 2px;
    background: var(--font-default-black);
  }
`;