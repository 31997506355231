import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.3681 8.34261C14.3681 8.35831 14.608 8.13798 14.608 8.15378H16L13.5841 10.908L11.1361 8.15378H12.5281C12.5281 8.13809 12.8321 8.35842 12.8321 8.34261C12.8321 5.71427 10.7682 3.58957 8.22413 3.58957C7.07212 3.58957 6.01609 4.03025 5.21612 4.75425L4.19212 3.55815C5.28006 2.58238 6.6881 2 8.22413 2C11.6161 2 14.3681 4.84868 14.3681 8.34264L14.3681 8.34261Z"
      fill="currentColor"
    />
    <path
      d="M1.63204 8.65738C1.63204 8.64169 1.39209 8.86202 1.39209 8.84621H0L2.41598 6.09196L4.86398 8.84621H3.47201C3.47201 8.86191 3.16798 8.64158 3.16798 8.65738C3.16798 11.2857 5.23193 13.4104 7.77599 13.4104C8.92799 13.4104 9.98402 12.9698 10.784 12.2457L11.808 13.4419C10.7201 14.4176 9.31202 15 7.77599 15C4.38398 15 1.63198 12.1513 1.63198 8.65736L1.63204 8.65738Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
