import React from "react";

import AddNewMembers from "./screens/AddNewMembers";
import ChatMessage from "./screens/ChatMessage";
import AvatarList from "../../../../common/components/UI/AvatarList";
import Typography from "../../../../common/components/Typography";

import { TextEllipsis } from "../../../../styled/Common/styled.text";
import * as Styled from "./styled";
import {
  NewMemberDialog,
  handlerMessageVisibility,
  handlerMinimize,
  useChatBaseStore,
} from "../../Store/ChatBaseStore";
import PopoverDropdown, {
  PopoverDropdownMenuItem,
} from "../../../../common/components/UI/PopoverDropdown";

import IconButton from "../../../../common/components/Buttons/IconButton";
import IconArrowLeft from "../../../../common/components/icons/Line/arrow-left";
import IconMenu from "../../../../common/components/icons/Fill/menu";
import IconAdd4 from "../../../../common/components/icons/Line/Add/04";
import IconEdit1 from "../../../../common/components/icons/Line/Edit/01";
import IconLocked2 from "../../../../common/components/icons/Line/Locked/02";
import IconDelete1 from "../../../../common/components/icons/Line/Delete/01";
import ButtonMinimize from "../../Components/ButtonMinimize";

const MessageView = ({ visibility, onAnimationDone }, ref) => {
  const chatData = useChatBaseStore((state) => state.chatData);

  return (
    <>
      <Styled.MessageLayout
        ref={ref}
        animationKey="chat_view"
        visibility={visibility}
        header={
          <MessageHeader onMinimized={handlerMinimize} chatData={chatData} />
        }
        body={<MessageContent chatData={chatData} />}
        onAnimationDone={onAnimationDone}
      />
    </>
  );
};

function MessageHeader({ onMinimized, chatData }) {
  const onBack = React.useCallback(() => {
    NewMemberDialog().close();
    handlerMessageVisibility(false);
  }, []);

  const groupDropdownOptions = React.useMemo(() => {
    const option = [
      { label: "Permission", icon: IconLocked2, onClick: () => {} },
      { label: "Delete", icon: IconDelete1, onClick: () => {} },
    ];

    if (chatData) {
      if (chatData?.groupType === 2) {
        option.push(
          {
            label: "Add Members",
            icon: IconAdd4,
            onClick: () => {
              NewMemberDialog().open();
            },
          },
          { label: "Rename Group", icon: IconEdit1, onClick: () => {} }
        );
      }
    }
    return option;
  }, [chatData]);

  return (
    <Styled.MessageHeader>
      <div className="message__header">
        <div className="start">
          <IconButton
            className="back__button"
            icon={<IconArrowLeft />}
            onClick={onBack}
          />

          <Typography
            variant={Typography.font_variant.small}
            className="message__title"
            // onClick={() => onMinimized((prev) => !prev)}
          >
            <TextEllipsis>{chatData?.groupName}</TextEllipsis>
          </Typography>
        </div>

        <div className="center">
          <AvatarList
            className="chat__members"
            data={
              chatData?.chatGroupMembers?.map((m, i) => ({
                ...m,
                src: m.profileImage,
                name: m.fullName,
              })) || []
            }
            avatarSize="size-28"
          />
        </div>

        <div className="end">
          <PopoverDropdown
            dropdownButtonClassName="group__dropdown__btn"
            customDropdownIcon={<IconMenu />}
            options={groupDropdownOptions}
            render={(option, { key, closeDropdown }) => {
              const { label, icon: IconComponent } = option;

              return (
                <PopoverDropdownMenuItem
                  key={key}
                  icon={<IconComponent />}
                  label={label}
                  onClick={() => {
                    closeDropdown();
                    option.onClick();
                  }}
                />
              );
            }}
          />
          {/* <IconButton className="more__button" icon={<IconMenu />} /> */}

          <ButtonMinimize />
        </div>
      </div>
    </Styled.MessageHeader>
  );
}

function MessageContent({ chatData }) {
  return (
    <Styled.MessageContent>
      {/* <ChatMessage /> */}
      {chatData?.groupType === 1 ? (
        <ChatMessage />
      ) : chatData?.chatGroupMembers?.length === 0 ? (
        <AddNewMembers />
      ) : (
        <ChatMessage />
      )}
      {/* Add New Member */}
      {/* <AddNewMembers /> */}
    </Styled.MessageContent>
  );
}

export default React.forwardRef(MessageView);
