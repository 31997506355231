import BreadcrumbLink from "../../../components/ui-components/Breadcrumb/BreadcrumbLink";
import config from "./config";
import Scheduler from "..";
import { ChildrenRoutes } from "./childRoutes";
// import { permissionLoader } from "../../../routes/loaders";

export const SchedulerRoutes = {
  id: config.id,
  path: config.path,
  element: <Scheduler />,
  handle: {
    crumb: () => <BreadcrumbLink label={config.label} to={config.path} />,
  },
  children: ChildrenRoutes,
  loader: async () => {
    return true;
    // return await permissionLoader(config.id);
  },
};
