import React from "react";
import * as Styled from "./styled";

const ResearchCard = ({ icon, title, isSelected, ...otherProps }) => {
  return (
    <Styled.CardContainer $isSelected={isSelected} {...otherProps}>
      {icon && (
        <div className="icon__container">
          <img src={icon} alt="module icon" />
        </div>
      )}

      <div className="text__container">{title}</div>
    </Styled.CardContainer>
  );
};

export default ResearchCard;
