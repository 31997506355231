import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import { useTempUserStore } from "../../../../store/TempUserStore";
import FromGroup from "../../../controls/FromGroup";
import ModalBase from "../../ModalBase";
import { useAppStore } from "../../../../store/AppStore/AppStore";
import useUserData from "../../../../hooks/useUserData";
import { useGlobalStore } from "../../../../store/GlobalStore";
import { __addUserToProject, __sendInvitation } from "./api";
import { createDocument, generateUserDoc } from "../../../../Actions/Templates";
import { TEMPLATE_TYPES } from "../../../../constant/System";
import useDefaultTemplateDoc, {
  TEMPLATE_DOC_TYPES,
} from "../../../../hooks/useDefaultTemplateDoc";

// Form initial values
const initialValues = {
  email: "",
  name: "",
  role: "",
};

// Validation Schema
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("This field is required")
    .email("Email must be a valid email"),
  name: Yup.string().required("This field is required"),
  role: Yup.string().required("This field is required"),
});

const AddTemporaryUserModal = (props) => {
  const { userId } = useUserData();
  const jobTitles = useAppStore((state) => state.jobTitles);
  const loadJobTitles = useAppStore((state) => state.getJobTitles);
  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);
  const globalSelectedProjectId = useGlobalStore(
    (state) => state.selectedProjectId
  );

  const nameInputRef = React.useRef();

  // Status
  const visibility = useTempUserStore((state) => state.isModalOpen);

  // Functions
  const onClose = useTempUserStore((state) => state.onToggleModal);
  const createAccount = useTempUserStore((state) => state.createAccount);

  const ndaDefaultDoc = useDefaultTemplateDoc({
    templateType: TEMPLATE_DOC_TYPES.NDA,
  });

  const onSubmit = (values) => {
    if (!userId) return;

    if (!ndaDefaultDoc?.templateId) {
      toast.error("The default template of the NDA was not found");
      return;
    }

    showLoader();

    // Call
    createAccount({
      email: values.email,
      jobTitleId: values.role,
      name: values.name,
      invitedBy: userId,
    })
      .then((res) => {
        if (!res.isSuccess) {
          toast.error(res?.result?.message);
          hideLoader();
          return;
        }

        const data = {
          member: { ...res.result },
          userId: res?.result?.id,
          projectId: globalSelectedProjectId,
        };

        /**
         * Assign the user to the project
         */
        __addUserToProject(data).then((res) => {
          if (!res.data.isSuccess) {
            toast.error(res.data?.displayMessage);
            return;
          }

          const { userTenantId, memberUserId, projectId, projectMemberId } =
            res?.data?.result;

          /**
           * Generate user document
           */
          generateUserDoc(ndaDefaultDoc.templateId, userTenantId)
            .then((res) => {
              if (!res.isSuccess) {
                toast.error(res?.errorMessages);
                return;
              }

              const docURL = res?.result?.docURL;

              const createDocumentBody = {
                userDocumentId: 0,
                documentTypeId: TEMPLATE_TYPES.NDA,
                documentURL: docURL,
                projectId: projectId,
                senderNote: "",
                templateId: 1,
                userId: memberUserId,
                remarks: "",
              };

              /**
               * Create Document
               */
              createDocument(createDocumentBody)
                .then((res) => {
                  if (!res.isSuccess) {
                    toast.error(res.displayMessage);
                    return;
                  }

                  const userDocumentId = res?.result?.userDocumentId;

                  /**
                   * Send invitation
                   */
                  __sendInvitation({
                    projectMemberId,
                    userDocumentId,
                    projectId,
                    senderNote: "",
                  }).then((res) => {
                    if (!res.data.isSuccess) {
                      toast.error(res.data.displayMessage);
                      hideLoader();
                      return;
                    }

                    // toast.success(res?.displayMessage);
                    hideLoader();
                    formik.resetForm();
                    onClose && onClose();
                  });
                })
                .catch(() => {
                  hideLoader();
                });
            })
            .catch((err) => {
              hideLoader();
            });
        });
      })
      .catch((err) => {
        hideLoader();
        toast.error(err);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { errors, values, handleChange, touched, handleSubmit } = formik;

  React.useEffect(() => {
    if (nameInputRef.current && visibility) {
      nameInputRef.current.focus();
    }
  }, [nameInputRef, visibility]);

  React.useEffect(() => {
    loadJobTitles();
  }, [loadJobTitles]);

  return (
    <ModalBase
      visibility={visibility}
      onClose={onClose}
      title="Add Temporary User"
      subtitle=""
      size="xs"
    >
      <div className="temp__user__content">
        <form onSubmit={handleSubmit}>
          {/* Contents */}
          <ModalBase.Contents>
            <div className="row">
              <div className="col-12 col-sm-6">
                <FromGroup
                  label="Name"
                  inputHeightAuto
                  error={touched.name && errors.name}
                >
                  <input
                    ref={nameInputRef}
                    type="text"
                    className="form-control"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                </FromGroup>
              </div>

              <div className="col-12 col-sm-6">
                <FromGroup
                  label="Role / Position"
                  inputHeightAuto
                  error={touched.role && errors.role}
                >
                  <select
                    className="form-control"
                    name="role"
                    value={values.role}
                    onChange={handleChange}
                  >
                    <option value="">Select Role / Position</option>
                    {jobTitles.map((job, i) => (
                      <option key={i} value={job.value}>
                        {job.label}
                      </option>
                    ))}
                  </select>
                </FromGroup>
              </div>

              <div className="col-12 col-sm-12">
                <FromGroup
                  label="Email"
                  inputHeightAuto
                  error={touched.email && errors.email}
                >
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                </FromGroup>
              </div>
            </div>
          </ModalBase.Contents>

          {/* Footer */}
          <ModalBase.Footer>
            <button
              className="btn btn-green-light btn-lg text-white icon icon-lp-done btn--with-icon"
              type="submit"
            >
              Add User
            </button>
          </ModalBase.Footer>
        </form>
      </div>
    </ModalBase>
  );
};

export default AddTemporaryUserModal;
