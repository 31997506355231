// import API_ENDPOINTS from "../../../../../services/api-endpoints";
import API_ENDPOINTS from "../../../services/api-endpoints";
import API_Project from "../../../services/api-project";
import API_Research from "../../../services/api-research";

const endpoints = new API_ENDPOINTS();

/**
 * Get project by user and tenant
 * * GET
 * @param userId
 * @param tenantId
 * @return Promise
 */

export const getProjectsByTenantIdAndUserId = async (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await API_Project.get(
        `${endpoints.PROJECT_GETALL}/${params?.tenantId}/${params?.userId}`
      );

      if (!res?.data?.isSuccess) {
        resolve([]);
        return;
      }

      const data =
        res?.data?.result?.filter((p) => p?.projectMemberStatusId !== 20) || [];

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Project Research Document Details
 * * GET
 * @param projectId
 * @return Promise
 */

export const getResearchDocumentsByProjectId = (id, count = -1) => {
  return new Promise((resolve, reject) => {
    const path = `?projectId=${id}&count=${count}`;

    API_Research.get(`${endpoints.GET_ALL_RECENT_DOCUMENTS}${path}`)
      .then((res) => {
        if (!res?.data?.isSuccess) {
          resolve([]);
        }

        const data = res?.data?.result;

        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
