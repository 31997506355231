import React from "react";
import TanStackTable, {
  Elements,
  columnHelper,
} from "../../components/common/TanStackTable";
import SettingTab from "../../components/common/SettingTab";
import IconMenuTab from "../../components/common/IconMenuTab";
// import DataTable from "../../components/common/DataTable";
// import Card from "../../components/common/Card";
// import SettingCard from "../../components/common/SettingCard";
// import NewRoleForm from "../../components/common/NewRoleForm";
// import FormSection from "../../components/common/FormSection";

import SettingIcon from "../../../../common/components/icons/Fill/settings";
import BuildingIcon from "../../../../common/components/icons/Fill/Building/02";
import ContactIcon from "../../../../common/components/icons/Fill/contact-info";
import FavoriteIcon from "../../../../common/components/icons/Fill/favorite";
import PropIcon from "../../../../common/components/icons/Fill/prop";
import AttachmentIcon from "../../../../common/components/icons/Fill/attachment";
import DocumentIcon from "../../../../common/components/icons/Fill/document";

const defaultData = [
  {
    firstName: "tanner",
    lastName: "linsley",
    age: 24,
    visits: 100,
    status: "In Relationship",
    progress: 50,
    subRows: [
      {
        firstName: "sub tandy",
        lastName: "miller",
        age: 40,
        visits: 40,
        status: "Single",
        progress: 80,
      },
      {
        firstName: "sub tandy 2",
        lastName: "miller",
        age: 40,
        visits: 40,
        status: "Single",
        progress: 80,
      },
    ],
  },
  {
    firstName: "tandy",
    lastName: "miller",
    age: 40,
    visits: 40,
    status: "Single",
    progress: 80,
  },
  {
    firstName: "joe",
    lastName: "dirte",
    age: 45,
    visits: 20,
    status: "Complicated",
    progress: 10,
  },
];

const UIElements = () => {
  const columns = [
    columnHelper.accessor("firstName", {
      header: "First Name",
      cell: ({ row, getValue }) => {
        return (
          <>
            <Elements.Depth depth={row.depth} />

            {row?.getCanExpand() && (
              <Elements.RowExpandButton
                {...{
                  isExpanded: row.getIsExpanded(),
                  onClick: row.getToggleExpandedHandler(),
                }}
              />
            )}

            {getValue()}
          </>
        );
      },
    }),
    columnHelper.accessor((row) => row.lastName, {
      id: "lastName",
      header: () => {
        return (
          <Elements.Actions gap={12}>
            <Elements.ActionButton as="div" type="add" />
            <Elements.ActionButton as="div" type="view" />
            <Elements.ActionButton as="div" type="edit" />
            <Elements.ActionButton as="div" type="delete" />
          </Elements.Actions>
        );
      },
      cell: () => {
        return (
          <Elements.Actions gap={12}>
            <Elements.ToggleActionButton type="correct" isActive />
            <Elements.ToggleActionButton type="correct" />
            <Elements.ToggleActionButton type="correct" />
            <Elements.ToggleActionButton type="close" />
          </Elements.Actions>
        );
      },
      size: 300,
      enableSorting: false,
    }),
    columnHelper.accessor("age", {
      header: () => "Age",
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor("visits", {
      header: () => <span>Visits</span>,
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: ({ row, getValue }) => {
        return (
          <Elements.ChipItems>
            <Elements.Chip label="Admin" />
            <Elements.Chip label="R&D" />
          </Elements.ChipItems>
        );
      },
    }),
    columnHelper.accessor("progress", {
      header: "Profile Progress",
    }),
  ];

  return (
    <>
      {/* <SettingIconButton />
      <SettingIconButton isActive />

      <Card>
        <Card.Header>Head</Card.Header>
        <Card.Content>lorems asaslas kasa sask askas </Card.Content>
      </Card>

      <SettingCard heading="Setting Heading"></SettingCard>

      <FormSection title="Section">asasa</FormSection>

      <NewRoleForm />

      <DataTable /> */}

      <TanStackTable columns={columns} data={defaultData} />

      <SettingTab>
        <SettingTab.Panel label={"Role"}>
          <>Tab 1</>
        </SettingTab.Panel>
        <SettingTab.Panel label="User Role">user role 2</SettingTab.Panel>
        <SettingTab.Panel label="Tab 2">Tab 2</SettingTab.Panel>
      </SettingTab>

      <IconMenuTab>
        <IconMenuTab.Panel label={<IconMenuTab.Label icon={<SettingIcon />} />}>
          Setting Tab
        </IconMenuTab.Panel>

        <IconMenuTab.Panel
          label={<IconMenuTab.Label icon={<BuildingIcon />} />}
        >
          Building Icon Tab
        </IconMenuTab.Panel>

        <IconMenuTab.Panel label={<IconMenuTab.Label icon={<ContactIcon />} />}>
          Contact Icon Tab
        </IconMenuTab.Panel>

        <IconMenuTab.Panel
          label={<IconMenuTab.Label icon={<FavoriteIcon />} />}
        >
          Favorite Icon Tab
        </IconMenuTab.Panel>

        <IconMenuTab.Panel label={<IconMenuTab.Label icon={<PropIcon />} />}>
          Prop Icon Tab
        </IconMenuTab.Panel>

        <IconMenuTab.Panel
          label={<IconMenuTab.Label icon={<AttachmentIcon />} />}
        >
          Attachment Icon Tab
        </IconMenuTab.Panel>

        <IconMenuTab.Panel
          label={<IconMenuTab.Label icon={<DocumentIcon />} />}
        >
          Document Icon Tab
        </IconMenuTab.Panel>
      </IconMenuTab>

      {/* <Table>
        <Table.Head>
          <Table.TR>
            <Table.TH label="Role" />
            <Table.TH label="Role" />
            <Table.TH label="Role" />
          </Table.TR>
        </Table.Head>

        <Table.Body>
          <Table.TR>
            <Table.TD>
              <Table.Elements.RowExpandButton />
              Admin
            </Table.TD>
            <Table.TD>Admin</Table.TD>
            <Table.TD>
              <Table.Elements.Status />
            </Table.TD>
          </Table.TR>

          <Table.TR>
            <Table.TD>Admin</Table.TD>
            <Table.TD align="center">
              <Table.Elements.Actions>
                <Table.Elements.ActionButton type="setting" />
                <Table.Elements.ActionButton type="edit" />
                <Table.Elements.ActionButton type="duplicate" />
                <Table.Elements.ActionButton type="remove" />
                <Table.Elements.ActionButton type="update" />

                <Table.Elements.ToggleActionButton type="correct" isActive />
                <Table.Elements.ToggleActionButton type="close" />
              </Table.Elements.Actions>
            </Table.TD>
            <Table.TD>
              <Table.Elements.Status />
            </Table.TD>
          </Table.TR>
        </Table.Body>
      </Table> */}
    </>
  );
};

export default UIElements;
