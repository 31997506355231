import React from "react";
import { Outlet } from "react-router-dom";

import "../../assets/fonts/loop.css";
import "../../assets/css/settings.css";
import "../../assets/css/_layout.css";

import Search from "../../ui-components/pages-ui/Setting/SettingSidebar/Search";
import SettingSidebar from "../../ui-components/pages-ui/Setting/SettingSidebar";
import Menu from "../../ui-components/pages-ui/Setting/SettingSidebar/Menu";
import MenuItem from "../../ui-components/pages-ui/Setting/SettingSidebar/Menu/MenuItem";
import MenuLink from "../../ui-components/pages-ui/Setting/SettingSidebar/Menu/MenuItem/MenuLink";
import Container from "../../ui-components/Container";
import SettingContainer from "../../ui-components/pages-ui/Setting/SettingContainer";
import { usePermissionStore } from "../../../store/AppStore/PermissionStore";
import { ROUTE_OPTIONS } from "../../../constant/route-options";

const SetupContainer = () => {
  const [menuItems, setMenuItems] = React.useState([]);
  // Sidebar
  const routes = usePermissionStore((state) => state.menu);

  const findMenuByKey = usePermissionStore((state) => state.findMenuByKey);

  const SettingRoute = findMenuByKey(routes, "setting");

  // search
  // const s = data.filter(d=> d.toLocaleLowerCase().indexOf('darshaka')===0);

  React.useEffect(() => {
    setMenuItems(SettingRoute?.children || []);
  }, [SettingRoute]);

  const GroupLinks = ({ keys, title, iconClass }) => {
    const links = keys.map((key, i) => {
      const link = findMenuByKey(menuItems, key);

      if (!link) return null;

      const text = ROUTE_OPTIONS?.setting?.children[key]?.label;

      return (
        <MenuLink
          key={i}
          text={text}
          to={String(link.path || "").replace("/", "")}
        />
      );
    });

    const hasLink = links.some((el) => el !== null);

    return hasLink ? (
      <MenuItem title={title} iconClass={iconClass}>
        {links}
      </MenuItem>
    ) : null;
  };

  const sidebarComponent = React.useMemo(() => {
    const generalLinksKeys = ["system-rights", "system-generals"];

    const commonLinksKeys = [
      // "data-setup",
      // "system-client",
      // "system-company",
      // "project-setup",
      "my-profile",
    ];

    const tenantLinksKeys = [
      "tenant-permission",
      "tenant-client",
      "tenant-company",
      "tenant-project",
    ];

    const templateLinksKeys = ["template-general-settings"];

    return (
      <SettingSidebar>
        {/* Search */}
        <Search />

        {/* Menus */}
        <Menu>
          {/* General */}
          <GroupLinks
            title="General"
            keys={generalLinksKeys}
            iconClass="icon-lp-template"
          />

          {/* Common */}
          <GroupLinks
            title="Common"
            keys={commonLinksKeys}
            iconClass="icon-lp-template"
          />

          {/* Tenant */}
          <GroupLinks
            title="Tenant"
            keys={tenantLinksKeys}
            iconClass="icon-lp-template"
          />

          {/* Templates */}
          <GroupLinks
            title="Templates"
            keys={templateLinksKeys}
            iconClass="icon-lp-template"
          />

          {/* <MenuItem title="Templates" iconClass="icon-lp-template">
            <MenuLink
              text="Permission"
              to={ROUTES_PATH.SettingRoutePath.TenantPermission.path}
            />
            <MenuLink text="General Settings" to="template-general-settings" />
          </MenuItem> */}

          {/* <MenuItem title="General" iconClass="icon-lp-template">
            <MenuLink text="System Rights" to="system-rights" />
            <MenuLink text="System Generals" to="system-generals" />
          </MenuItem> */}

          {/* Common */}
          {/* <MenuItem title="Common" iconClass="icon-lp-template">
            <MenuLink text="Data Setup" to="data-setup" />
            <MenuLink text="Client" to="system-client" />
            <MenuLink text="Company" to="system-company" />
            <MenuLink text="Project" to="project-setup" />
            <MenuLink text="My Profile" to="my-profile" />
          </MenuItem> */}

          {/* Tanent */}
          {/* <MenuItem title="Tenant" iconClass="icon-lp-template">
            <MenuLink
              text="Permission"
              to={ROUTES_PATH.SettingRoutePath.TenantPermission.path}
            />
            <MenuLink text="Client" to="tenant-client" />
            <MenuLink text="Company" to="tenant-company" />
            <MenuLink text="Project" to="tenant-project" />
          </MenuItem> */}

          {/* Templates */}
          {/* <MenuItem title="Templates" iconClass="icon-lp-template">
            <MenuLink text="General Settings" to="template-general-settings" />
          </MenuItem> */}
        </Menu>
      </SettingSidebar>
    );
  }, [menuItems]);

  return (
    <Container>
      <SettingContainer sidebar={<>{sidebarComponent}</>}>
        <Outlet />
      </SettingContainer>
    </Container>
  );
};

export default SetupContainer;
