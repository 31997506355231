import styled from "styled-components";
import { fontStyle } from "../../../../../../../../../../../styled/utility";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  border-radius: var(--layout-border-radius-8);
  background: var(--color-neutral-10);

  .field__group {
    &.actions {
      margin-top: var(--layout-spacing-16);

      .element {
        display: flex;
        align-items: center;
        gap: var(--layout-spacing-8);
        justify-content: flex-end;

        button {
          width: 100%;

          &.cancel__btn {
            width: 28%;
          }
        }
      }
    }
  }
`;

export const SubListWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--layout-spacing-16);

  .stage-control {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: var(--layout-spacing-16);

    .plus-btn {
      --icon-button-icon-color: var(--color-accent-50);
      background-color: var(--color-accent-300);
      border-radius: var(--layout-border-radius-4);

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .stage-list {
    display: grid;
    gap: var(--layout-spacing-8);

    &__item {
      display: flex;
      gap: var(--layout-spacing-4);
      align-items: center;

      &--label {
        flex: 1;
        background-color: var(--color-accent-50);
        ${fontStyle("x-small-regular")};
        padding: var(--layout-spacing-8);
        border-radius: var(--layout-border-radius-4);
        color: var(--font-default-black);
      }

      &--action {
        display: flex;
        gap: var(--layout-spacing-4);

        .icon__button__element {
          --icon-button-icon-color: var(--font-tint-04);

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
`;
