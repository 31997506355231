import create from "zustand";
import { devtools } from "zustand/middleware";
import {
  deleteRoleMenu,
  saveRoleMenu,
} from "../../../Actions/Setting/Permission";
import API from "../../../services/api-tenant";
import API_Globle from "../../../services/api";
import API_ENDPOINTS from "../../../services/api-endpoints";

const dummyData = [
  {
    role: { label: "HR", value: 1 },
    menus: [
      { label: "Project", value: 1 },
      { label: "Setting", value: 2 },
    ],
    action: "121",
    id: "121",
  },
];

const _roleOptions = [
  { label: "HR", value: 1 },
  { label: "Admin", value: 2 },
];

const _menuOptions = [
  { label: "Project", value: 1 },
  { label: "Setting", value: 2 },
];

let RoleMenuStore = (set, get) => ({
  isEditMode: false,
  roleOptions: [],
  menuOptions: [],
  data: [],
  showForm: true,
  formData: {
    roleId: null,
    menuIds: [],
    id: null,
  },

  onToggleForm: () => set((state) => ({ showForm: !state.showForm })),

  onEdit: (id) => {
    // Get selected data by id
    const selectedForm = get().data.find((obj) => obj.id === id);

    if (selectedForm) {
      const { role = null, menus = [] } = selectedForm;

      // update state
      set({
        isEditMode: true,
        showForm: true,
        formData: {
          roleId: role || null,
          menuIds: menus,
          id,
        },
      });
    }
  },

  onDelete: async (id) => {
    console.log("delete id ", id);
    const row = get().data.find((d) => d.id === id);
    console.log("row", row);
    return new Promise((resolve, reject) => {
      return deleteRoleMenu(id)
        .then((res) => {
          resolve(res);
          get().onLoadRoleMenuData();
        })
        .catch(() => {
          reject();
        });
    });
  },

  onFormSubmit: async (values) => {
    return new Promise((resolve, reject) => {
      let isEdit = get().isEditMode;
      let id = get().formData.id;
      let variant = get().variant;

      console.log("id ", id);
      console.log("isEdit ", isEdit);
      console.log("variant ", variant);
      console.log("values ", values);

      values.menuIds.forEach((menuId) => {
        let dataObject = {
          id: isEdit ? id : 0,
          roleId: values.roleId,
          superRoleId: 0,
          menuId: menuId,
        };

        return saveRoleMenu(dataObject)
          .then((res) => {
            resolve(res);
            get().onLoadTable();
          })
          .catch(() => {
            reject();
          });
      });

      //resolve("done");
    });
  },

  clearForm: (callback) => {
    set({
      isEditMode: false,
      formData: {
        roleId: null,
        roleIds: [],
        id: null,
      },
    });

    callback && callback();
  },

  onLoadDropdown: () => {
    // set({
    //   roleOptions: _roleOptions,
    //   menuOptions: _menuOptions,
    // });
    get().onLoadRoleOptions();
    get().onLoadMenuOptions();
  },

  onLoadTable: () => {
    //set({ data: dummyData });
    get().onLoadRoleMenuData();
  },

  onLoadRoleOptions: async () => {
    const endpoints = new API_ENDPOINTS();

    API.get(endpoints.PERMISSION_ROLE_GET_ALL).then((response) => {
      console.log("role response: ", response);
      if (response.data.isSuccess === true) {
        let roleList = [];
        let i = 0;
        response.data.result.forEach((element) => {
          let obj = {
            label: element.roleName,
            value: element.roleId,
          };
          roleList[i] = obj;
          i++;
        });

        set({ roleOptions: roleList });
      }
    });
  },
  onLoadMenuOptions: async () => {
    const endpoints = new API_ENDPOINTS();

    API.get(endpoints.PERMISSION_MENU_GET_ALL).then((response) => {
      console.log("menu response: ", response);
      if (response.data.isSuccess === true) {
        let menuList = [];
        let i = 0;
        response.data.result.forEach((element) => {
          let obj = {
            label: element.name,
            value: element.menuId,
          };
          menuList[i] = obj;
          i++;
        });

        set({ menuOptions: menuList });
      }
    });
  },
  onLoadRoleMenuData: async () => {
    const endpoints = new API_ENDPOINTS();
    let USER = JSON.parse(localStorage.getItem("user"));
    let tenantId = USER.result.tenantId;

    API.get(endpoints.PERMISSION_ROLEMENU_GET_ALL).then((response) => {
      console.log("Role Menu response: ", response);
      if (response.data.isSuccess === true) {
        let Menus = [];
        let Roles = [];
        let mappingList = [];
        let API_Result = response.data.result;

        API_Result.forEach((element) => {
          var obj = {
            id: element.id,
            roleId: element.roleId,
            menuList: API_Result.filter((r) => r.roleId === element.roleId),
          };

          var item =
            mappingList.find((f) => f.roleId === element.roleId) || null;
          console.log("[]", item);
          if (item === null) {
            mappingList.push(obj);
          }
        });

        API_Result = mappingList;

        let RoleMenuList = [];
        let i = 0;

        API.get(endpoints.PERMISSION_MENU_GET_ALL).then((response) => {
          console.log("menu responce: ", response);
          Menus = response.data.result;
          console.log("Menus:", Menus);

          API.get(endpoints.PERMISSION_ROLE_GET_ALL).then((response) => {
            console.log("role responce: ", response);
            Roles = response.data.result;

            API_Result.forEach((element) => {
              var role = Roles.find((u) => u.roleId === element.roleId);
              var menus = [];

              element.menuList.forEach((relement) => {
                var menuDetails = Menus.find(
                  (f) => f.menuId === relement.menuId
                );
                var menu = {
                  label: menuDetails.name,
                  value: menuDetails.menuId,
                };

                menus.push(menu);
              });

              let dataObject = {
                role: { label: role.roleName, value: role.roleId },
                menus: menus,
                action: element.id,
                id: element.id,
              };
              //var obj = UserRoleList.filter((o) => o.user.id === user.id);
              RoleMenuList[i] = dataObject;
              i++;
            });
            console.log("RoleMenuList:", RoleMenuList);

            set({ data: RoleMenuList });
          });
        });
      }
    });
  },
});

RoleMenuStore = devtools(RoleMenuStore);

export const useRoleMenuStore = create(RoleMenuStore);
