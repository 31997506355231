import React from "react";
import { StyledSwitchOuterWrapper, StyledSwitchWrapper } from "./styled";

const SwitchField = ({
  className,
  label,
  defaultChecked = false,
  onChange,
  ...other
}) => {
  const [isChecked, setIsChecked] = React.useState(defaultChecked);


  const onChangeHandler = React.useCallback((e)=>{
    onChange && onChange(e);
    setIsChecked(e.target.checked);
  },[onChange])

  return (
    <StyledSwitchOuterWrapper className={isChecked ? "is-checked" : ""}>
      <div className="inner">
        <div className="text">{label}</div>

        <StyledSwitchWrapper className={className}>
          <input type="checkbox" defaultChecked={defaultChecked} onChange={onChangeHandler} {...other} />
          <div className="circle"></div>
        </StyledSwitchWrapper>
      </div>
    </StyledSwitchOuterWrapper>
  );
};

export default SwitchField;
