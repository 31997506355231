import React from 'react'
import './styles.scss';

const Loader = () => {
  return (
    <div className='loader__wrapper'>
        <div className='loader'>Loading...</div>
    </div>
  )
}

export default Loader