import styled from "styled-components";
import { fontStyle } from "../../../../../../../../../../../../styled/utility";

export const Item = styled.div`
  display: flex;
  padding: var(--layout-spacing-8, 8px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--layout-spacing-8, 8px);
  background: var(--color-info-75, #a3dcfd);
  cursor: pointer;
  ${fontStyle("x-small-medium")};
  color: #404a5f;
`;
