import React from "react";
import { Button } from "./styled";
import IconButton from "../../../../../../../../common/components/Buttons/IconButton";
import IconArrowDown from "../../../../../../../../common/components/icons/Fill/arrow-down";

const HeaderButton = ({ label, ...otherProps }) => {
  return (
    <Button {...otherProps}>
      {label}
      <IconButton icon={<IconArrowDown />} btnIconSize={12} btnSize={12} />
    </Button>
  );
};

export default HeaderButton;
