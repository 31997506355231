import React from "react";
import * as Styled from "./styled";
import EventPanel from "./EventPanel";
import Calendar from "./Calendar";
import Footer from "./Footer";

const EventCalendar = (props) => {
  const { value, onSave, viewMode = false, hideMainNote = false } = props;

  const clRef = React.useRef();
  // const [height, setHeight] = React.useState(0);
  const [note, handleNoteChange] = React.useState(value?.note || "");
  const [modifiers, handleModifiers] = React.useState(value?.modifiers);
  const [currentSelectDate, handleDaySelect] = React.useState(new Date());
  const [shouldUpdate, setShouldUpdate] = React.useState(false);

  // Update changes
  React.useEffect(() => {
    if (!viewMode && !shouldUpdate) return;

    onSave && onSave({ note, modifiers });
    setShouldUpdate(false);

    return () => {
      setShouldUpdate(false);
    };
  }, [modifiers, shouldUpdate, note, onSave, viewMode]);

  return (
    <Styled.Container
    // style={{ "--calendar-height": height }}
    >
      <div className="cln__event__inner">
        <div className="start">
          <Calendar
            viewMode={viewMode}
            ref={clRef}
            modifiers={modifiers}
            handleModifiers={(modifiers) => {
              console.log("handleModifiers ", modifiers);
              handleModifiers(modifiers);
              setShouldUpdate(true);
            }}
            handleDaySelect={(date) => handleDaySelect(new Date(date))}
          />
        </div>
        <div className="divider"></div>
        <div className="end">
          <EventPanel currentSelectDate={currentSelectDate} />
        </div>
      </div>

      {!hideMainNote && (
        <div className="cln__event__note">
          <Footer
            note={note}
            handleNoteChange={(value) => {
              handleNoteChange(value);
              setShouldUpdate(true);
            }}
          />
        </div>
      )}
    </Styled.Container>
  );
};

export default EventCalendar;
