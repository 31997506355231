import * as React from "react";
import { memo } from "react";
const SvgArrowUp = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.7588 11.7536C16.0804 11.4258 16.0804 10.8938 15.7588 10.566L8.58216 3.24605C8.26077 2.91798 7.73922 2.91798 7.41785 3.24605L0.241248 10.566C0.0804182 10.73 -3.47288e-07 10.945 -3.5669e-07 11.1601C-3.66087e-07 11.3751 0.0804134 11.5902 0.241248 11.7539C0.562645 12.082 1.08419 12.082 1.40556 11.7539L8.00003 5.02776L14.5945 11.7537C14.9159 12.0818 15.4374 12.0818 15.7588 11.7537L15.7588 11.7536Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgArrowUp);
export default Memo;
