import API_ENDPOINTS from "../../services/api-endpoints";
import API_Project from "../../services/api-project";
import API_Research from "../../services/api-research";

// Get Project by tenant id
export const getAllProjectsByTenant = async () => {
  // Get user
  let USER = JSON.parse(localStorage.getItem("user"));
  let tenantId = USER.result.tenantId;

  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Project.get(`${endpoints.PROJECT_GETALL}/${tenantId}`)
      .then((res) => {
        // If not success, return [];
        if (!res?.data?.isSuccess) {
          resolve([]);
        }

        let data = res?.data?.result;

        const projects = data.map((item) => {
          return {
            ...item,
            name: item.projectName,
            value: item.projectId,
          };
        });

        resolve(projects);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Get Project by tenant id
export const getAllProjectsByTenantId = async (tenantId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Project.get(`${endpoints.PROJECT_GETALL}/${tenantId}`)
      .then((res) => {
        // If not success, return [];
        if (!res?.data?.isSuccess) {
          resolve([]);
        }

        let data = res?.data?.result;

        const projects = data.map((item) => {
          return {
            ...item,
            name: item.projectName,
            value: item.projectId,
          };
        });

        resolve(projects);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Get Project by tenant id and user id for Project Selector
export const getAllProjectsByTenantIdAndUserId = async (tenantId, userId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Project.get(`${endpoints.PROJECT_GETALL}/${tenantId}/${userId}`)
      .then((res) => {
        // If not success, return [];
        if (!res?.data?.isSuccess) {
          resolve([]);
        }

        let data = res?.data;

        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Get Project by tenant id and user id
export const getAllProjectsByTenantIdAndUserId_ProjectSelector = async (
  tenantId,
  userId
) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Project.get(`${endpoints.PROJECT_GETALL}/${tenantId}/${userId}`)
      .then((res) => {
        // If not success, return [];
        if (!res?.data?.isSuccess) {
          resolve([]);
        }

        let data = res?.data?.result;

        const projects = data.map((item) => {
          return {
            ...item,
            name: item.projectName,
            value: item.projectId,
          };
        });

        resolve(projects);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Save Contract Request
const contractRequestParams = {
  projectMemberID: 0,
  jobTitleId: 0,
  templateId: 0,
  contractFee: "",
  hrInstruction: "",
  startDate: "",
  endDate: "",
};

export const saveProjectContractRequest = (
  formData = contractRequestParams
) => {
  const _formData = {
    ...contractRequestParams,
    ...formData,
  };
  return new Promise((resolve, reject) => {
    const endpoints = new API_ENDPOINTS();
    API_Project.post(endpoints.CONTRACT_GENARATION_REQUEST, _formData)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Get Project Contract Request Info
export const getProjectContractRequestInfo = (contractRequestId) => {
  return new Promise((resolve, reject) => {
    const endpoints = new API_ENDPOINTS();
    API_Project.get(
      `${endpoints.GET_PROJECT_CONTRACT_REQUEST_INFO}?contractRequestId=${contractRequestId}`
    )
      .then((res) => {
        resolve(res?.data || null);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Get Project Contract Request Info
export const getProjectHRListByProjectId = (projectId) => {
  return new Promise((resolve, reject) => {
    const endpoints = new API_ENDPOINTS();
    API_Project.get(endpoints.PROJECT_GET_PROJECT_HR_LIST + "/" + projectId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getProjectHRListByName = (projectId, searchText) => {
  return new Promise((resolve, reject) => {
    const endpoints = new API_ENDPOINTS();

    API_Project.get(
      `${endpoints.PROJECT_GET_PROJECT_HR_LIST_BY_NAME}/${projectId}/${searchText}`
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * Set Contract Document Id
 * * GET
 * @param {docId or userDocumentId}:string
 * @param contractRequestId:string
 * @return Promise
 */
export const setContractDocumentByIds = (contractRequestId, docId) => {
  return new Promise((resolve, reject) => {
    const endpoints = new API_ENDPOINTS();

    API_Project.get(
      `${endpoints.SET_CONTRACT_DOCUMENT_ID}/${contractRequestId}/${docId}`
    )
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * Contract Generate
 * * POST
 * @param {contractRequestId,contractUrl,senderNote}:string
 * @return Promise
 */

const contractGenerateBody = {
  contractRequestId: 0,
  contractUrl: "",
  senderNote: "",
};

export const contractGenerate = (formData = contractGenerateBody) => {
  return new Promise((resolve, reject) => {
    const endpoints = new API_ENDPOINTS();

    const payload = {
      ...contractGenerateBody,
      ...formData,
    };

    API_Project.post(`${endpoints.PROJECT_CONTRACT_GENERATE}`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const contractAcceptOrRejectBody = {
  contractRequestId: 0,
  contractId: 0,
  isAcceptOrReject: 0,
  acceptOrRejectRemarks: "",
};

/**
 * Project Contract Accept Or Reject
 * * POST
 * @param {contractRequestId, contractId, acceptOrRejectRemarks, isAcceptOrReject= 1: Accept, 2: Reject, 3 : RejectAck} formData
 * @return Promise
 */

export const projectContractAcceptOrReject = (
  formData = contractAcceptOrRejectBody
) => {
  // Form body data
  const payload = {
    ...contractAcceptOrRejectBody,
    ...formData,
  };

  return new Promise((resolve, reject) => {
    const endpoints = new API_ENDPOINTS();

    API_Project.post(`${endpoints.PROJECT_CONTRACT_ACCEPT_OR_REJECT}`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const onboardingCompletionBody = {
  projectMemberId: 1,
  companyEmail: "",
  password: "",
  healthDetails: "",
  bankDetails: "",
};

/**
 * Project Onboarding Completion
 * * POST
 * @param {projectMemberId, companyEmail, password, healthDetails, bankDetails} formData
 * @return Promise
 */

export const projectOnboardingCompletion = (
  formData = onboardingCompletionBody
) => {
  // Form body data
  const payload = {
    ...onboardingCompletionBody,
    ...formData,
  };

  return new Promise((resolve, reject) => {
    const endpoints = new API_ENDPOINTS();

    API_Project.post(`${endpoints.PROJECT_ONBOARDING_COMPLETE}`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/**
 * Project Onboarding Completion Info
 * * GET
 * @param projectMemberId
 * @return Promise
 */

export const projectOnboardingCompleteInfo = (projectMemberId) => {
  return new Promise((resolve, reject) => {
    const endpoints = new API_ENDPOINTS();

    API_Project.get(
      `${endpoints.PROJECT_ONBOARDING_COMPLETE_INFO}/${projectMemberId}`
    )
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * Project Details
 * * GET
 * @param projectId
 * @return Promise
 */

export const getProjectByID = (id) => {
  return new Promise((resolve, reject) => {
    const endpoints = new API_ENDPOINTS();

    API_Project.get(`${endpoints.PROJECT_GETBYID}/${id}`)
      .then((res) => {
        console.log("prosss ", res);
        if (!res.data.isSuccess) {
          resolve(null);
        }

        const {
          companyInfo,
          projectDetails,
          projectMemberDetails,
          isUserInvolved,
          userInfo,
          language,
        } = res?.data?.result;

        const data = {
          userInfo,
          companyInfo: companyInfo,
          isUserInvolved,
          projectDetails: {
            ...projectDetails,
            clientId: projectDetails?.clientId || 0,
            duration: projectDetails?.duration || null,
            endDate: projectDetails?.endDate || null,
            contactPerson: {
              email: projectDetails?.projectContactPersonEmail || null,
              image: projectDetails?.projectContactPersonImage || null,
              jobTitle: projectDetails?.projectContactPersonJobTitle || null,
              name: projectDetails?.projectContactPersonName || null,
              telephoneNo:
                projectDetails?.projectContactPersonTelephoneNo || null,
            },
            estimateProductionDate:
              projectDetails?.estimateProductionDate || null,
            deckUrl: projectDetails?.projectDeckUrl || null,
            description: projectDetails?.projectDescription || null,
            projectId: projectDetails?.projectId || null,
            logo: projectDetails?.projectLogo || null,
            name: projectDetails?.projectName || null,
            noOfEpisodes: projectDetails?.projectNoOfEpisodes || 0,
            privacyId: projectDetails?.projectPrivacyId || null,
            trailerUrl: projectDetails?.projectTrailerUrl || null,
            typeId: projectDetails?.projectTypeId || null,
            startDate: projectDetails?.startDate || null,
            tenantId: projectDetails?.tenantId || null,
          },
          projectMembers: projectMemberDetails || [],
          language,
        };

        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/**
 * Project Research Document Details
 * * GET
 * @param projectId
 * @return Promise
 */

export const getProjectRecentResearchDocumentsByID = (id) => {
  return new Promise((resolve, reject) => {
    const endpoints = new API_ENDPOINTS();

    const path = `?projectId=` + id + `&count=10`;
    API_Research.get(`${endpoints.GET_ALL_RECENT_DOCUMENTS}${path}`)
      .then((res) => {
        console.log("prosss doc", res);
        if (!res.data.isSuccess) {
          resolve(null);
        }

        const data = res?.data?.result;

        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const projectMemberStatusBody = {
  projectMemberId: 0,
  projectMemberStatusId: 0,
  contractRequestId: 0,
  contractId: 0,
  contractDocUrl: null,
};

export const setProjectMemberStatus = (data) => {
  const payload = {
    ...projectMemberStatusBody,
    ...data,
  };

  return new Promise((resolve, reject) => {
    const endpoints = new API_ENDPOINTS();

    API_Project.post(`${endpoints.SET_PROJECT_MEMBER_STATUS}`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getProjectMemberByProjectMemberId = (projectMemberId) => {
  return new Promise((resolve, reject) => {
    const endpoints = new API_ENDPOINTS();

    API_Project.get(`${endpoints.PROJECT_MEMBER_GET_BY_ID}/${projectMemberId}`)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getProjectMembersListByProjectId = (projectId) => {
  return new Promise((resolve, reject) => {
    const endpoints = new API_ENDPOINTS();
    API_Project.get(
      `${endpoints.GET_ALL_PROJECT_MEMBERS_FOR_CHAT}/${projectId}`
    )
      .then((response) => {
        if (!response?.data?.isSuccess) {
          resolve([]);
        }

        let data = response?.data?.result;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
