import styled from "styled-components";
import { fontStyle } from "../../../styled/utility";
import AdvanceItem from "./AdvanceItem";

export const BaseItem = styled.div`
  background-color: var(--color-neutral-20);
  padding: var(--layout-spacing-16);
  border-radius: var(--layout-border-radius-12);
`;

export const PlainItemContainer = styled(BaseItem)`
  .pain-item {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__title,
    &__content {
      ${fontStyle("label-medium")};
      font-weight: 500;
      color: var(--font-default-color);
    }

    &__content {
      ${fontStyle("label-regular")};
      font-weight: 400;
    }
  }
`;

export const AdvItemContainer = styled(BaseItem)`
  .adv-item {
    display: flex;
    width: 100%;
    gap: var(--layout-spacing-20);
    max-width: 100%;
    overflow: hidden;

    &__left {
    }

    &__right {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: var(--layout-spacing-8);
    }

    &__content {
      display: block;
    }

    &__image {
      width: 100px;
      border-radius: var(--layout-border-radius-4);
      overflow: hidden;

      img {
        max-width: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    &__head {
      display: flex;
      justify-content: space-between;
      gap: var(--layout-spacing-4);
    }

    &__title {
      display: flex;
      flex-wrap: wrap;

      span {
        ${fontStyle("base-regular")};
        color: var(--color-primary-400);
        font-weight: 500;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__title span.subtitle {
      ${fontStyle("small-regular")};
      font-weight: 400;
      color: var(--font-default-black);

      &::before {
        content: "";
        display: inline-flex;
        width: 1px;
        background-color: var(--color-black);
        margin: 0px var(--layout-spacing-8);
        align-items: center;
        vertical-align: middle;
        padding: 8px 0;
      }
    }

    &__status {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: var(--layout-border-radius-4);
      border: 1px solid var(--layout-background-n50);
      padding: 2px var(--layout-spacing-4);
      align-self: flex-start;

      span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block;
        min-width: 0px;
        ${fontStyle("label-regular")};
        color: var(--font-tint-04);
      }
    }
  }
`;

export const OnboardingItemElement = styled(AdvanceItem)`
  .adv-item__image {
    height: 100px;

    img {
      height: 100%;
      max-height: 100%;
    }
  }

  .adv-item__content {
    flex: 1;
    display: flex;
    width: 100%;
  }
`;

export const OnboardingContent = styled.div`
  display: grid;
  gap: var(--layout-spacing-8);
  grid-template-columns: 1fr auto;
  width: 100%;

  .left-content {
    display: flex;
    flex-direction: column;
    gap: var(--layout-spacing-4);
  }

  .right-content {
    display: flex;
    align-self: flex-end;
  }

  .sm__text {
    ${fontStyle("small-regular")};
    font-weight: 400;
    color: var(--font-default-black);
  }

  .base__text {
    ${fontStyle("base-medium")};
    font-weight: 500;
    color: var(--font-default-black);
  }

  .text__gray {
    color: var(--color-neutral-90);
  }
  .text__secondary {
    color: var(--color-secondary-300);
  }
`;
