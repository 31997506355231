import styled from "styled-components";
import { fontStyle } from "../../../../../styled/utility";
import PopoverDropdown from "../../../../../common/components/UI/PopoverDropdown";

export const List = styled.div`
  width: 100%;
  gap: var(--layout-spacing-8);
  display: flex;
  flex-direction: column;

  .list__item__col {
    > div {
    }
  }

  .list__item {
    display: grid;
    grid-template-columns: minmax(auto, 100%) 1fr;
    gap: var(--layout-spacing-4);
    align-items: center;
    border-radius: var(--layout-borderRadius-8, 8px);
    background: var(--color-neutral-30, #ebecef);
    padding-left: var(--layout-size-24);

    &:hover {
      background: var(--color-neutral-40, #ebecef);
      /* background-color: var(--color-white, #fff); */
      /* background-color: var(--layout-background-n30); */
    }

    .mw-0 {
      min-width: 0px;
    }

    &__col {
      /* padding-bottom: var(--layout-spacing-4); */
      padding: var(--layout-spacing-12) var(--layout-spacing-16);
      display: flex;
      align-items: stretch;

      div.project-name {
        max-width: 100%;

        span {
          ${fontStyle("base-regular")};
          max-width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
        }
      }

      > div {
        ${fontStyle("base-regular")};
        display: flex;
        align-items: center;
      }

      &--right {
        > div {
          justify-content: flex-end;
        }
      }
    }
  }

  .tb__button {
    /* padding: var(--layout-spacing-4);
    height: var(--layout-size-28); */
  }

  .gap-8 {
    > div {
      gap: var(--layout-spacing-16);
    }
  }
`;

export const DropDownMenu = styled(PopoverDropdown)``;
