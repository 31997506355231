export class WebStorage {
  static set(key, value) {
    const val = value === null ? value : JSON.stringify(value);
    localStorage.setItem(key, val);
  }

  static get(key) {
    return JSON.parse(localStorage.getItem(key)) || null;
  }
}
