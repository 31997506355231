import styled from "styled-components";
import { fontStyle } from "../../../../../styled/utility";

export const Wrapper = styled.div`
  &:not(:last-child) {
    padding-bottom: var(--layout-spacing-16);
    border-bottom: 1px solid var(--font-tint-01);
  }

  .task-item-group {
    display: flex;
    flex-direction: column;
    gap: var(--layout-spacing-4);
    width: 100%;
    max-width: 100%;

    &__start {
      ${fontStyle("x-small-medium")};
      font-weight: 500;
      color: var(--font-tint-04);
    }

    &__content {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: var(--layout-spacing-8);
    }
  }
`;

export const TaskListWrapper = styled.div`
  gap: var(--layout-spacing-16);
  display: flex;
  flex-direction: column;
`;
