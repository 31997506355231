import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 7.27406L8.04155 15.2325C7.0443 16.2298 5.44088 16.2298 4.44363 15.2325L0.747938 11.5368C-0.249313 10.5396 -0.249313 8.93614 0.747938 7.93889L8.70639 0H13.458C14.1033 0 14.729 0.254201 15.1983 0.703941L15.2961 0.801711C15.7458 1.29056 16 1.89673 16 2.54201V7.27406ZM1.02169 8.21265C0.180874 9.05347 0.180874 10.4222 1.02169 11.2631L4.71738 14.9588C5.5582 15.7996 6.92698 15.7996 7.7678 14.9588L15.6089 7.11763V2.52246C15.6089 1.97495 15.3938 1.44699 15.0223 1.05591L14.9245 0.958142C14.5335 0.586618 14.0055 0.371525 13.458 0.371525H8.86282L1.02169 8.21265Z"
      fill="currentColor"
    />
    <path
      d="M13.282 4.4974C12.7932 4.98625 11.9914 4.98625 11.483 4.4974C10.9942 4.00856 10.9942 3.20684 11.483 2.69844C11.9719 2.20959 12.7736 2.20959 13.282 2.69844C13.7709 3.18729 13.7709 4.00856 13.282 4.4974ZM11.7764 2.9722C11.4439 3.30461 11.4439 3.87168 11.7764 4.20409C12.1088 4.53651 12.6758 4.53651 13.0082 4.20409C13.3407 3.87168 13.3407 3.30461 13.0082 2.9722C12.6563 2.63978 12.1088 2.63978 11.7764 2.9722Z"
      fill="currentColor"
    />
    <path
      d="M5.46043 10.9306L4.83471 11.5564L4.42408 11.1457L5.03025 10.5396C4.63917 10.1485 4.36541 9.69875 4.20898 9.19035L4.99114 8.40819C5.01069 8.60373 5.08891 8.85793 5.24534 9.15124C5.40177 9.44455 5.53865 9.67919 5.69508 9.87473L6.73144 8.83838C6.45768 8.2322 6.32081 7.74336 6.35991 7.39138C6.39902 7.03941 6.5359 6.72655 6.82921 6.43324C7.12252 6.13993 7.49404 5.9835 7.90467 5.9835C8.31531 5.9835 8.72594 6.13993 9.11702 6.4528L9.64497 5.92484L10.0556 6.33547L9.5472 6.84387C9.93828 7.27406 10.1729 7.62603 10.2511 7.93889L9.48854 8.7015C9.39077 8.27131 9.17568 7.88023 8.88237 7.50871L7.78735 8.60373C8.06111 9.17079 8.17843 9.64009 8.17843 10.0116C8.17843 10.3831 8.022 10.7156 7.74824 10.9893C7.43538 11.3022 7.08341 11.4586 6.67278 11.4586C6.2817 11.439 5.87107 11.2631 5.46043 10.9306ZM7.53315 8.01711L8.43263 7.11763C8.11977 6.90254 7.84601 6.90254 7.61137 7.13718C7.43538 7.31317 7.41583 7.62603 7.53315 8.01711ZM6.98564 9.40544L6.12527 10.2658C6.43813 10.5005 6.71189 10.5005 6.94653 10.2658C7.12252 10.0703 7.14207 9.79652 6.98564 9.40544Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
