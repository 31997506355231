import BreadcrumbLink from "../../../components/ui-components/Breadcrumb/BreadcrumbLink";
import config from "./config";
import { ChildrenRoutes } from "./childRoutes";
import MilestoneTracker from "..";

export const MilestoneTrackerRoutes = {
  id: config.id,
  path: config.path,
  element: <MilestoneTracker />,
  handle: {
    crumb: () => <BreadcrumbLink label={config.label} to={config.path} />,
  },
  children: ChildrenRoutes,
  loader: async () => {
    return true;
    // return await permissionLoader(config.id);
  },
};

export const MilestoneTrackerPaths = config;
