import styled from "styled-components";

export const CardContainer = styled.div`
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  padding: var(--layout-spacing-28) var(--layout-spacing-8);
  align-items: center;
  justify-content: center;
  border-radius: var(--layout-border-radius-12);
  cursor: pointer;

  ${({ $isSelected }) => {
    if ($isSelected) {
      return `
        box-shadow: 0 0 1px 1px var(--button-primary-active);
      `;
    }
  }}

  .icon__container {
    width: var(--layout-size-40);
    height: var(--layout-size-40);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      max-width: 100%;
    }
  }

  .text__container {
    margin-top: var(--layout-spacing-8);
  }
`;
