import React from "react";
import {
  useNavigate,
  useSearchParams,
  matchPath,
  useLocation,
  createSearchParams,
} from "react-router-dom";
import { ROUTE_OPTIONS } from "../constant/route-options";
import {
  MainDocumentModal,
  addNewTab,
} from "../pages/research-board/screens/ResearchDocuments/store/DocumentStore";

const useResearchSearchParams = () => {
  const [params, setParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const navigateResearchTab = React.useCallback(
    (payload) => {
      if (!payload) return;

      const getKey = () => {
        return `tab__key__${payload?.documentId}${payload?.projectId}${payload?.episodeId}${payload?.documentTypeId}`;
      };

      const data = {
        key: getKey(),
        id: payload?.documentId,
        documentId: payload?.documentId,
        icon: payload?.documentTypeIcon || payload?.icon || "",
        projectId: payload?.projectId,
        episodeId: payload?.episodeId,
        documentName: payload?.documentName,
        documentTypeId: payload?.documentTypeId,
        isTemplate: payload?.isTemplate,
        documentCopyId: payload?.documentCopyId || 0,
        isActive: payload?.isActive || true,
        group: 1,
        order: payload?.order === 0 ? 0 : 1,
        canClose: payload?.canClose === undefined ? true : payload?.canClose,
        tabVisibility: payload?.tabVisibility || false,
        templateId: payload?.templateId || 0,
      };

      const searchKeys = {
        key: encodeURIComponent(btoa(JSON.stringify(data))),
      };

      const isDocumentPath = matchPath(
        `${ROUTE_OPTIONS.research_board.path}/*`,
        pathname
      );

      if (isDocumentPath) {
        setParams(searchKeys);
      } else {
        navigate({
          pathname: `${ROUTE_OPTIONS.research_board.path}/${ROUTE_OPTIONS.research_board.children.documents.path}`,
          search: createSearchParams(searchKeys).toString(),
        });
      }
    },
    [navigate, setParams, pathname]
  );

  const removeParam = React.useCallback(() => {
    params.delete("key");
    setParams(params);
  }, [params, setParams]);

  const urlParamValues = React.useMemo(() => {
    if (params.get("key")) {
      return JSON.parse(atob(decodeURIComponent(params.get("key"))));
    }

    return null;
  }, [params]);

  React.useEffect(() => {
    if (urlParamValues) {
      addNewTab(urlParamValues);
    }
  }, [urlParamValues]);

  // Open Main documents dialog modal listener
  React.useEffect(() => {
    const dialogParam = params.get("documentsDialog");

    if (dialogParam && dialogParam === "show") {
      MainDocumentModal.open();
    }
  }, [params]);

  return {
    urlParamValues,
    navigateResearchTab,
    removeParam,
  };
};

export default useResearchSearchParams;
