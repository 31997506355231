import styled from "styled-components";
import { StyledBoxButton } from "../../../../../styled/Common/Buttons";

export const StyledArrowsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-1);
`;

export const StyledArrow = styled(StyledBoxButton)`
  --arrows-bg-color: ${({ theme }) => theme.colors.color_2(60)};
  --cta-arrow-icon-color: ${({ theme }) => theme.colors.white()};

  border-radius: var(--rounded-sm);
  background-color: var(--arrows-bg-color);
  transition: ${({ theme }) => theme.animate.primary};

  .icon {
    font-size: 12px;
    color: var(--cta-arrow-icon-color);
  }

  &:hover {
    --arrows-bg-color: ${({ theme }) => theme.colors.color_2()};
  }
  
  &:disabled {
    --arrows-bg-color: ${({ theme }) => theme.colors.color_2(30)};
  }
`;
