import React from "react";
import FieldControl from "../FieldControl";
import { onRefParser } from "../../../../utility";

const TextAreaField = (props, ref) => {
  const {
    inputOuterClass,
    className,
    size = "sm",
    value,
    disabled,
    placeholder = "Enter text",
    rows = 1,
    onInput,
    onChange,
    maxHeight = 160,
    minHeight = 90,
    // maxRows = 9,
    ...other
  } = props;

  const inputRef = React.useRef();
  // const [growRows, setGrowRows] = React.useState(Number(rows));
  const [currentValue, setCurrentValue] = React.useState(value);

  useAutoResize({ ref: inputRef, value: currentValue, maxHeight, minHeight });

  React.useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  // const onInputHandler = React.useCallback(() => {
  //   if (!value?.match) return;

  //   const matches = value.match(/\n/g);
  //   let breaks = matches ? matches?.length + 1 : rows;

  //   if (rows >= breaks) {
  //     setGrowRows(rows);
  //     return;
  //   }

  //   if (breaks > maxRows) {
  //     setGrowRows(maxRows);
  //     return;
  //   }

  //   setGrowRows(breaks);
  // }, [maxRows, rows, value]);

  // React.useEffect(() => {
  //   onInputHandler();
  // }, [onInputHandler]);

  return (
    <FieldControl
      inputOuterClass={["field__control__wrapper", inputOuterClass].join(" ")}
      size={size}
      isDisabled={disabled}
    >
      <textarea
        ref={(node) => onRefParser(node, ref, inputRef)}
        placeholder={placeholder}
        className={["textarea__field", className].join(" ")}
        disabled={disabled}
        // onInput={onInputHandler}
        value={currentValue}
        onChange={(e) => {
          onChange && onChange(e);
          setCurrentValue(e.target.value);
        }}
        rows={rows}
        {...other}
      />
    </FieldControl>
  );
};

function useAutoResize({ ref, value, maxHeight = 160, minHeight = 90 }) {
  React.useEffect(() => {
    if (ref?.current) {
      ref.current.style.resize = "none";
      ref.current.style.height = "0px";

      const calH = Math.max(minHeight, ref.current.scrollHeight);

      ref.current.style.height = `${calH}px`;
      ref.current.style.overflowY = "hidden";

      if (maxHeight <= ref.current.scrollHeight) {
        ref.current.style.maxHeight = `${maxHeight}px`;
        ref.current.style.overflowY = "auto";
      }
    }
  }, [maxHeight, value, minHeight, ref]);
}

export default React.forwardRef(TextAreaField);
