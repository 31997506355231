import React from "react";

import CardItem from "./CardItem";
import ItemGroup from "./ItemGroup";
import { StyledContainer } from "./styled";

const OnboardingItems = (props) => {
  const { children, viewType, layoutType } = props;

  const ListComponents = React.useMemo(() => {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return {
          ...child,
          props: {
            ...child.props,
            viewType,
          },
        };
      }

      return null;
    });
  }, [children, viewType]);

  return (
    <StyledContainer viewType={viewType} layoutType={layoutType}>
      {ListComponents}
    </StyledContainer>
  );
};

OnboardingItems.Item = CardItem;
OnboardingItems.ItemGroup = ItemGroup;

export default OnboardingItems;
