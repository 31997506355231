import * as React from "react";
import { memo } from "react";
const Svg04 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.8421 7.48201H8.51799V5.15852C8.51799 4.87249 8.28604 4.64054 8 4.64054C7.71396 4.64054 7.48201 4.87249 7.48201 5.15852V7.48201H5.15791C4.87187 7.48201 4.63992 7.71396 4.63992 8C4.63992 8.28604 4.87187 8.51799 5.15791 8.51799H7.48201V10.8415C7.48201 11.1275 7.71396 11.3595 8 11.3595C8.28604 11.3595 8.51799 11.1275 8.51799 10.8415V8.51799H10.8421C11.1281 8.51799 11.3601 8.28604 11.3601 8C11.3601 7.71396 11.1281 7.48201 10.8421 7.48201Z"
      fill="currentColor"
    />
    <path
      d="M8 0C3.58845 0 0 3.58906 0 8C0 12.4109 3.58845 16 8 16C12.4115 16 16 12.4109 16 8C16 3.58906 12.4115 0 8 0ZM8 14.964C4.16 14.964 1.03597 11.84 1.03597 8C1.03597 4.16 4.16 1.03597 8 1.03597C11.84 1.03597 14.964 4.16 14.964 8C14.964 11.84 11.84 14.964 8 14.964Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg04);
export default Memo;
